import React, { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../../dashboards/filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { urlConsultazione, urlReport } from "../../costanti";
import { Chart } from "devextreme-react";
import { MenuFbiVendite } from "../components/menu_Fbi_Vendite";
import { createItemFilterCrusc } from "./itemsFiltri/AnaliVendite";
import {
  trasform_no_marge,
  trasform_for_cards_charts_01,
} from "../util/trasformation";
import { cardC, CardCart } from "../components/card_chart";
import {
  Animation,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Series,
  Subtitle,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import { CruscottoGruppo_traform_2W } from "../util/trasformation";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { columnsAnaVendFascOraria } from "./colums/AnalVendi";
import { boxCardVendFascOra } from "./BoxCard/AnalVendite";

import "./style.css";

const dashboard13 = { dashboardID: 13 };

const AnalVendFascOraria = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardVendFascOra);
  const [valori2W, setValori2W] = useState<any>([]);
  const [valori3W, setValori3W] = useState<any>([]);
  const [valoriQOAY, setValoriQOAY] = useState<any>(null);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [chartDataMargine, setChartDataMargine] = useState<any>([]);
  const [totalValueMargine, setTotalValueMargine] = useState(0);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePopOne, setVisiblePopOne] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const [progetto, setProgetto] = useState<any[]>([]);
  const [societa, setSocieta] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;

  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    //console.log('vediamo parametri ricerca');
    //console.log(params);

    setParamsFilter(params);

    let newIDFAR: any = [];
    let newIDSettore: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

      let venditaDPC = params?.venditaDPC ? undefined : [0];
      let flagBusta = params?.flagBusta ? undefined : [0];

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.periodo?.al) {
      //alert('ypppp')
      params.periodo.al = params.periodo.al; //aggiornaDataFine(params.periodo.al);
    } else {
      //alert('nooooo')
    }

    if (params.societa && params.societa.length > 0) {
      console.log(societa);
      let objSocieta: any = [];

      params.societa.forEach((element: any) => {
        const oggettoTrovato = societa.find((obj) => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        });
      });
      params.societaNewV = objSocieta;
    }

    if (params.progetto && params.progetto.length > 0) {
      let objSocieta: any = [];

      params.progetto.forEach((element: any) => {
        const oggettoTrovato = progetto.find((obj) => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        });
      });
      params.progettoNewV = objSocieta;
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      operatori: params.operatori,
      progetto: params.progettoNewV,
      societa: params.societaNewV,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    const customizeTooltip = () => {
      return {
        text: `pollo`,
      };
    };

    setIsVisible(true);
    if (authReport) {
      const dashboardCall13 = {
        ...dashboard13,
        idFarList: params.idFar,
        params: parameters,
      };

      /**qui api test  */
      // const call1w = {
      //   ...api1w,
      //   idFarList: params.idFar,
      //   params: parameters,
      // };

      RestServices()
        .rest("POST", dashboardCall13, urlReport.getQueryDashboardAllData)
        .then((result) => {
          //console.log(result);
          setIsVisible(false);

          if (result) {
            // ID 66 BOX

            let datiPrimaParteCards = trasform_no_marge(
              result["66"][0],
              boxCardVendFascOra
            );

            setFila1cards(datiPrimaParteCards);

            // const traform = CruscottoGruppo_traform_2W(result["67"]);
            // setValori2W(traform);

             // ID 69 tabella
            setValori2W(result["69"]);

            // ID 67
            setValori3W(result["67"]);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            { position: "center", width: "auto", message: "Errore" + error },
            "error",
            4000
          );
        });
      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const itemsFields = createItemFilterCrusc(newFarmaList, progetto, societa);

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlReport.getProgetto)
        .then((result) => {
          //console.log('vediamo sytruttura');
          // console.log(result);

          if (result) {
            const progetti = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });

            setProgetto(result);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getSocieta)
        .then((result) => {
          if (result) {
            const societas = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });
            setSocieta(result);
          }
        });
    }
  };

  useEffect(() => {
    filters();
  }, [authReport]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };


  function formatLabel(arg: { argumentText: any; valueText: any }) {
    return `${arg.argumentText}: ${arg.valueText}%`;
  }

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  const onPointClick = (e: { target: any }) => {
    const point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  };

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  function formatAsPercentage(cellInfo: any) {
    let valoreTouse = cellInfo.value;
    return new Intl.NumberFormat("default", {
      style: "percent",
    }).format(cellInfo / 100);
  }

  const popVisibleOne = (testo: string) => {
    setVisiblePopOne(true);
  };

  let messOne = [
    "Numero di vendite medio giornaliero: Grafico che permette di monitorare il numero di vendite per fascia oraria e per giorno della settimana nel periodo selezionato. Il valore del numero di vendite è stato mediato in base alla frequenza del giorno della settimana (considerando solo i giorni lavorati: in cui è stata effettuata almeno una vendita) nel periodo selezionato. Il colore della bolla è determinato dalla battuta media pezzo effettuata in quella fascia oraria per quel giorno settimanale.  Il filtro per operatore permette di monitorare in quali condizioni i dipendenti performino meglio. Numero di vendite:   Rappresenta il numero di vendite effettuate. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate.",
  ];

 function getDayName(dayNumber:number) {
    const days = [
      "",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
      "Domenica",
    ];
    return days[dayNumber];
  }
  

  return (
    <section style={{ display: "flex", height: "98vh" }}>
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div
            style={{
              fontSize: "15px",
              alignItems: "left",
              marginTop: "10px",
              marginBottom: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h2>F.B.I</h2>
          </div>
          {/* select*/}

          <div
            style={{
              display: "block",
              gap: 5,
              padding: 10,
              borderRadius: 10,
              marginTop: 10,
              width: "auto",
            }}
          >
            <div>
              <MenuFbiVendite />
            </div>
          </div>

          <Popup
            visible={visiblePop}
            showCloseButton={true}
            onHiding={() => setVisiblePop(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
          >
            <div>
              <p>{testoPop}</p>
            </div>
          </Popup>

          <div className="grid-container-6-el">
            {fila1cards.map((el, i) => {
              return (
                <CardCart
                  testo={el.testo}
                  subTesto={el.subTesto}
                  key={i}
                  valore={el.valore}
                  subValore={el.subValore}
                  starter={el.starter}
                  typeShowValue={el.typeShowValue}
                  typeShowSubValue={el.typeShowSubValue}
                  testoPop={el.testoPop}
                />
              );
            })}
          </div>

          <div style={{ marginTop: "5px" }}>

         <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: 10 }}>Tabella riepilogativa</h6>

            <NewTabella
              idTabella="elenco_operatori"
              sync
              colonne={columnsAnaVendFascOraria}
              dataSource={valori2W}
              filterRow
              width={10}
              searchPanel={true}
              exportT={{
                enabled: true,
                fileName: "ElencoOperatori",
                formats: { exportFormats },
              }}
              activeSaver={true}
              columnC={true}
              rowAlternation={true}
              headerFilter
              wordWrapEnabled={true}
              onExporting={onExporting}
            />
          </div>

          <div
            style={{
              background: "#fff",
              padding: 10,
              display: "flex",
              paddingTop: 10,
              marginTop: "5px",
            }}
          >
            <div style={{ width: "100%" }}>
              <h6
                style={{
                  display: "flex",
                  color: "#5b6372",
                  fontFamily: "Arial, sans-serif",
                  marginBottom: "-15px",
                  marginLeft: "15px",
                }}
              >
                Numero di vendita medio giornaliero
              </h6>
              <div style={{ marginTop: "15px" }}>
                {valori3W.length > 0 ? (
                  <div>
                    <Chart id="bubbleChart" dataSource={valori3W}>
                      <CommonSeriesSettings
                        argumentField="ORA"
                        valueField="GIORNO_SETTIMANA"
                      />
                      <Series
                        type="bubble"
                        name="Bubble Series"
                        sizeField="NUMERO_VENDITE_MEDIO_GIORNALIERO"
                      ></Series>
                      <Tooltip
                        enabled={true}
                        customizeTooltip={({
                          argument,
                          value,
                          size,
                          point,
                        }: {
                          argument: any;
                          value: any;
                          size: any;
                          point: any;
                        }) => {
                          //console.log("cosa sono i dati");
                          //console.log(argument);
                          //console.log(value);
                          //console.log(size);
                          //console.log(point.data);

                           const giornoSenzaAccenti = getDayName(
                            point.data.GIORNO_SETTIMANA
                          );

                         // console.log(giornoSenzaAccenti);

                          return {
                            html: `
                          <meta charset="UTF-8" />
                          <div><p style='margin:0px'>Fascia oraria</p><p style='font-weight: bold;color:#72ddee'>${
                            point.data.ORA
                          }</p> 
                                      <hr>
                                      <div><p style='margin:0px'>Giorno della settimana</p><p style='font-weight: bold;color:#72ddee'>${giornoSenzaAccenti} </p> 
                                      </div>
                                      <hr>
                                      <div><p style='margin:0px'>Battuta media pezzo privato</p><p style='font-weight: bold;color:#72ddee'>€${point.data.BATTUTA_MEDIA_PEZZO_IVATA.toLocaleString(
                                        "it-IT",
                                        {
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}</p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Numero vendite medio</p><p style='font-weight: bold;color:#72ddee'>${
                                        point.data.NUMERO_VENDITE_MEDIO_GIORNALIERO
                                      }</p> 
                              </div>
                                      `,
                          };
                        }}
                      />

                      <ValueAxis>
                        <Label customizeText={(value:any) => {
                          const giorno = getDayName(Number(value.valueText))
                          return(
                            giorno
                          )
                          
                        }} />
                        <Title text="Gironi della settimana" />
                      </ValueAxis>
                      <ArgumentAxis>
                        <Title text="Orario" />
                      </ArgumentAxis>

                      <Legend visible={false} />
                    </Chart>
                  </div>
                ) : null}
              </div>
            </div>

            <div style={{}}>
              <p
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                  justifyContent: "left",
                }}
              >
                <span>
                  {" "}
                  <button
                    className="ibutton-lite"
                    style={{ border: "none" }}
                    onClick={() => popVisibleOne("")}
                  >
                    <FontAwesomeIcon
                      icon={faInfo}
                      style={{ fontSize: "11px", color: "black" }}
                    />
                  </button>
                </span>
              </p>
            </div>

            {chartDataMargine.length > 0 && (
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2
                  style={{ color: "#5b6372", fontFamily: "Arial, sans-serif" }}
                >
                  Totale:
                </h2>
                <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                  {totalValueMargine}
                </h2>
              </div>
            )}
          </div>

          <Popup
            visible={visiblePopOne}
            showCloseButton={true}
            onHiding={() => setVisiblePopOne(false)}
            width={"50%"}
            height={"50%"}
            showTitle={true}
            title="Dettagli widget"
          >
            <div>
              <p>{messOne}</p>
            </div>
          </Popup>
        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{ fontSize: "30px", color: "black" }}
        />
      </div>
      <section
        style={{
          background: "#fff",
          opacity: isFiltersExpanded ? 1 : 0,
          width: isFiltersExpanded ? "auto" : 10,
        }}
      >
        <div style={{}}>
          <FiltriVerticale
            ricerca={filterAction}
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>
    </section>
  );
};

export default AnalVendFascOraria;
