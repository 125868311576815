import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { BoxTestoCerca } from '../../widget/Editor';
import { TabellaSemplice } from '../../widget/Tabelle';
import { RicercaProdInterface, ProdBDInterface, CostoInterface, OfferteInterface, PrezziStoriciInterface, VenditaDettInterface, CaricoDettInterface } from '../../interfaces/bancaDati'
import { SelectFarm, TendinaFarm } from '../../widget/Select';
import { Loading } from '../../widget/Notifications';
import notify from 'devextreme/ui/notify';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { PannelloTab } from './TabPanel';
import Button from 'react-bootstrap/esm/Button';
import { OggettoInterface } from '../../interfaces/generiche';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { RestServices } from '../../services/restServices';
import { urlBancaDati, urlCommon } from '../costanti';
import { TabellaSempliceInterface } from '../../interfaces/tabelle';
import { useFetchProdFarm } from '../../hooks/useFetchDettProdBD';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';

const BancaDati = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[]
}) => {
    //al caricamento della pagina si fa collassare il menu laterale in modo da avere più spazio a disposizione per la pagina
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    //crea il titolo della pagina sotto la navbar
    const titolo = TitoloContenuto("Banca Dati");

    const authCommon = useAuth(urlCommon.auth);

    const placeholder = "Cerca un prodotto per codice, EAN, descrizione ...";
    const [inputCerca, setInputCerca] = useState<string>();

    const [farmacia, setFarmacia] = useState<number>(0);

    //rende visibile/invisibile il pannello di loading
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [parametri, setParametri] = useState<OggettoInterface>();
    // const [atcHierarchy, setAtcHierarchy] = useState<object>();
    const [prodotti, setProdotti] = useState<RicercaProdInterface[]>([]);
    const [rigaSelez, setRigaSelez] = useState<RicercaProdInterface>();
    const [listaFar, setLstFar] = useState<number[]>([]);
    const [dettagliProd, setDettagliProd] = useState<ProdBDInterface>();
    const [imaProd, setImaProd] = useState<string>("");
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [ultimoCosto, setUltimoCosto] = useState<CostoInterface[]>([]);
    const [offerte, setOfferte] = useState<OfferteInterface[]>([]);
    const [venditaDett, setVenditaDett] = useState<VenditaDettInterface[]>([]);
    const [caricoDett, setCaricoDett] = useState<CaricoDettInterface[]>([]);
    const [prezzoStorico, setPrezzoStorico] = useState<PrezziStoriciInterface[]>([]);

    useEffect(() => {
        listaFarmacie();
    }, [filtri])

    useEffect(() => {
        if (rigaSelez && rigaSelez.codCsf && listaFar.length > 0) {
            setParametri({
                codCsf: rigaSelez.codCsf,
                lstFar: listaFar
            });
        }
    }, [rigaSelez, listaFar])

    //restituisce la/e farmacia/e selezionata/e -> vale sia per singola farmacia che per selezione multipla
    const listaFarmacie = () => {
        let lstFar: number[] = [];
        if (filtri['idFar']) {
            lstFar.push(filtri['idFar']);
        } else if (filtri['lstFar']) {
            lstFar = filtri['lstFar'];
        }
        setLstFar(lstFar);
    }

    const { giacenzaDett, costoMedioDett, prezzoFarmacia, prezzoPromozione, giacenzaDettMagazz, lottoRiordino } = useFetchProdFarm(
        parametri,
        setIsVisible,
        setDettagliProd,
        setUltimoCosto,
        setOfferte,
        setPrezzoStorico,
        setImaProd,
        setVenditaDett,
        setCaricoDett
    )

    const propsTabCerca: TabellaSempliceInterface = {
        id: "tabellaRicerca",
        height: 640,
        dataSource: prodotti,
        filterRow: true,
        summary: {
            totalItems: [{
                name: "TotaleProdotti",
                showInColumn: "settInventario",
                displayFormat: "Tot: {0}",
                summaryType: "count"
            }]
        }
    }
    const columnsCerca = CreaColonne([
        { caption: "Sett", dataField: "settInventario", width: 50 },
        { caption: "Descrizione", dataField: "descrizione", sortOrder: "asc" },
        { caption: "Cod.Prod", dataField: "minsan" },
        { caption: "Cod.EAN", dataField: "ean" },
        { caption: "Codice CSF", dataField: "codCsf", visible: false }
    ]);

    //fa partire la ricerca del prodotto se il valore del box di ricerca ha lunghezza > 0
    const startRicerca = (value: string) => {
        setIsVisible(true);
        if (value.length > 0) {
            setIsVisible(true);
            const parametriCerca = {
                testo: value,
                lstFar: listaFarmacie(),
                soloInCommercio: btnInCommercio.soloInCommercio,
                soloInGiacenza: btnInGiacenza.soloInGiacenza
            }
            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    // svuotaCampi();
                    if (result.length === 1) {
                        setProdotti(result);
                        dettagliRighe(result[0]);
                    } else if (result.length > 1) { //se c'è più di un prodotto si setta lo stato prodotti e si svuota il tabPanel -> TODO: chiedere se lasciare visualizzati i campi precedenti o no
                        setProdotti(result);
                    } else { //non c'è stato alcun risultato corrispondente al valore inserito -> si setta prodotti = undefined e si svuotano i campi del tabPanel
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "error", 4000)
                        setProdotti([]);
                    }
                }).then(() => { setIsVisible(false) })
        } else {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Inserire il nome o il codice del prodotto da cercare" }, "error", 4000)
        }
    }

    // function svuotaCampi() {
    // setDettagliProd(undefined);
    // setImaProd(undefined);
    // setUltimoCosto(undefined);
    // setOfferte(undefined);
    // setMovimentazioneProdotti(undefined);
    // setPrezzoStorico(undefined);
    // }

    const dettagliRighe = (datiRighe: any) => {
        if (datiRighe && datiRighe.selectedRowsData) {
            const riga = datiRighe.selectedRowsData[0];
            setRigaSelez(riga);
        } else {
            setRigaSelez(datiRighe);
        }
    }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const btnGiacenzaDefault = {
        colore: "success",
        id: "tutti",
        valore: "Tutti",
        soloInGiacenza: false
    }
    const btnInCommercioDefault = {
        colore: "success",
        id: "nonInCommercio",
        valore: "Non In Commercio",
        soloInCommercio: false
    }

    const [btnInGiacenza, setBtnInGiacenza] = useState(btnGiacenzaDefault)
    const [btnInCommercio, setBtnInCommercio] = useState(btnInCommercioDefault)

    useEffect(() => {
        if (authCommon) {
            const paramComm = {
                categoria: "RICERCA_PRODOTTO",
                nome: "IN_COMMERCIO"
            }
            const paramGiac = {
                categoria: "RICERCA_PRODOTTO",
                nome: "SOLO_GIACENZA"
            }
            RestServices().rest('POST', paramComm, urlCommon.getRicercaProd).then(result => {
                if (result === true) {
                    cambiaValoreBtnCommercio();
                }
            })
            RestServices().rest('POST', paramGiac, urlCommon.getRicercaProd).then(result => {
                if (result === true) {
                    cambiaValoreBtnInGiacenza();
                }
            })
        }
    }, [authCommon])

    useEffect(() => {
        if (inputCerca) startRicerca(inputCerca)
    }, [btnInCommercio, btnInGiacenza])

    function cambiaValoreBtnInGiacenza() {
        const paramGiac = {
            categoria: "RICERCA_PRODOTTO",
            nome: "SOLO_GIACENZA",
            valore: true
        }
        switch (btnInGiacenza.id) {
            case "soloGiacenza":
                paramGiac.valore = false;
                RestServices().rest('POST', paramGiac, urlCommon.setRicercaProd);
                setBtnInGiacenza(btnGiacenzaDefault);
                break;
            case "tutti":
                RestServices().rest('POST', paramGiac, urlCommon.setRicercaProd);
                setBtnInGiacenza({
                    colore: "danger",
                    id: "soloGiacenza",
                    valore: "Solo Giacenza",
                    soloInGiacenza: true
                });
                break;
        }
    }

    function cambiaValoreBtnCommercio() {
        const paramComm = {
            categoria: "RICERCA_PRODOTTO",
            nome: "IN_COMMERCIO",
            valore: true
        }
        switch (btnInCommercio.id) {
            case "inCommercio":
                setBtnInCommercio(btnInCommercioDefault);
                paramComm.valore = false;
                RestServices().rest('POST', paramComm, urlCommon.setRicercaProd);
                break;
            case "nonInCommercio":
                RestServices().rest('POST', paramComm, urlCommon.setRicercaProd);
                setBtnInCommercio({
                    colore: "danger",
                    id: "inCommercio",
                    valore: "In Commercio",
                    soloInCommercio: true
                });
                break;
        }
    }

    return (
        <>
            {loadPanel}
            {titolo}
            <div className="contenutoPagina">
                <Row>
                    <Col sm="3">
                        <label htmlFor="ricerca">Ricerca Prodotto</label>
                        <BoxTestoCerca placeholder={placeholder} id="ricercaProdottiBD" startRicerca={startRicerca} setInputCerca={setInputCerca} inputCerca={inputCerca} />
                    </Col>
                    <Col sm="4">
                        {(props.datiFarmacie.length === 1) ? <label >Farmacia *</label> : <label title="Selezionare una o più farmacie. Cambiando la selezione verranno modificati i dettagli del prodotto">Farmacia *</label>}
                        {(props.datiFarmacie.length === 1) ? <SelectFarm datiFarmacie={props.datiFarmacie} id={'idFar'} impostaFiltri={impostaFiltri} displayExpr={true} /> :
                            <TendinaFarm datiFarmacie={props.datiFarmacie} id={'lstFar'} impostaFiltri={impostaFiltri} primaSelezionata={true} />}
                    </Col>
                </Row>

                <Row className="risultatiRicerca">
                    <Col sm="3">
                        <div className="bottoniFiltro">
                            <Button type="button" variant={btnInGiacenza.colore} id={btnInGiacenza.id} onClick={cambiaValoreBtnInGiacenza}>{btnInGiacenza.valore}</Button>
                            <Button type="button" variant={btnInCommercio.colore} id={btnInCommercio.id} onClick={cambiaValoreBtnCommercio}>{btnInCommercio.valore}</Button>
                        </div>
                        <TabellaSemplice propsTabella={propsTabCerca} columns={columnsCerca} dettagliRighe={dettagliRighe} />
                    </Col>
                    <Col sm="9" className="risultatiColDx">
                        <Row className="dettagli">
                            <PannelloTab
                                dettagliProd={dettagliProd}
                                imaProd={imaProd}
                                giacenzaDett={giacenzaDett}
                                costoMedioDett={costoMedioDett}
                                prezzoFarmacia={prezzoFarmacia}
                                prezzoPromozione={prezzoPromozione}
                                giacenzaDettMagazz={giacenzaDettMagazz}
                                lottoRiordino={lottoRiordino}
                                ultimoCosto={ultimoCosto}
                                offerte={offerte}
                                prezzoStorico={prezzoStorico}
                                caricoDett={caricoDett}
                                venditaDett={venditaDett}
                            />
                        </Row>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default BancaDati