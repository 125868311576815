import { RestServices } from "../../services/restServices";
import { urlCommon , urlConsultazione } from "../../contenuti/costanti";


export const ChiamataApi = async(string:string,type:string,optionalBoolean?:boolean ) => {
    switch (type) {
        case 'products':
            if(string?.length > 2){
                let result = await  RestServices().rest('POST', {query:string} , urlCommon.findProdotti)
                return  result
            }
        case 'ditta':
                if(string?.length > 2){
                    let result = await RestServices().rest('POST', { ragioneSociale: string, vediCodice: optionalBoolean } , urlConsultazione.findProdottiDitta)
                    return  result
                }   
        case 'fornitori':
            let queryForn = {
                'descFornitore':string,
                "ricercaAuto": true
            }
            if(string?.length > 2){
                let result = await  RestServices().rest('POST', queryForn , urlCommon.getListForn)
                return  result
            } 
        case 'provincie':
            let queryCom = {
                'testo': string,
            }
            if (string?.length > 2) {
                let result = await RestServices().rest('POST', queryCom, urlCommon.getListProvincie)
                return result
            } 
        case 'comuneAsl':
            let queryComAsl = {
                'testo': string,
            }
            if (string?.length > 2) {
                let result = await RestServices().rest('POST', queryComAsl, urlCommon.getListComuneAsl)
                return result
            } 
        default:
            return  null
    }
    

}