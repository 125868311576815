import React, { useState, useEffect } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { urlCommon, urlConsultazione } from "../../costanti";
import { useAuth } from "../../../hooks/useAuth";
import { RestServices } from "../../../services/restServices";
import notify from "devextreme/ui/notify";
import { Loading } from "../../../widget/Notifications";
import TextBox from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import { AutocompleteBox } from "../../../widget/Autocomplete"
import Form, { GroupItem } from "devextreme-react/form";
import { SimpleItem } from "devextreme-react/tree-list";


interface DialogProps {
    open: boolean;
    idAnaRecapitiEmail: number;
    close: () => void;
    idAnaGenerale: number;
    idAnaIndirizzi: number;
    closeAndReload: () => void;
}

export const DialogAnaFornCenEmail: React.FC<DialogProps> = ({
    open,
    idAnaRecapitiEmail,
    close,
    idAnaGenerale,
    idAnaIndirizzi,
    closeAndReload,
}) => {

    const auth = useAuth(urlCommon.auth);
    //const auth2 = useAuth(urlConsultazione.auth);
    const [openD, setOpenD] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [objctTOsave, setObjctTOsave] = useState<any>({});
    const [objctTOedit, setObjctTOedit] = useState<any>({});
    const [tipoEmail, setTipoEmail] = useState<any>([]);
    const [tipoLabelEmail, setTipoLabelEmail] = useState<any>([]);
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [listFar, setListFar] = useState<any>([])
    const [listAzi, setListAzi] = useState<any>([])

    useEffect(() => {
        if (auth) {
            setOpenD(open)
        }

    }, [auth])

    useEffect(() => {

        if (openD) {
            getEmail()
            getListTipoEmail()
            getListTipoLabelEmail()
            getListFar()
            getListAzi()
        }

    }, [openD])

    useEffect(() => {
        if (objctTOedit) {

            console.log(objctTOedit)

            if (objctTOedit.email?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit.idTipEmail > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit.idTipLabelEmail > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

        }

    }, [objctTOedit])


    const getEmail = () => {

        if (idAnaRecapitiEmail) {
            let params = { idAnaRecapitiEmail: idAnaRecapitiEmail };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getAnagraficaEmail)
                    .then(result => {
                        if (result?.esito) {
                            setObjctTOsave(result.response)
                            setObjctTOedit(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        }
        else {
            setObjctTOsave({ idAnaGenerale: idAnaGenerale, idAnaIndirizzo: idAnaIndirizzi });
        }

    }

    const getListTipoEmail = () => {

        //if (idAnaRecapitiEmail) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoEmail)
                    .then(result => {
                        if (result?.esito) {
                            setTipoEmail(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        //}

    }

    const getListTipoLabelEmail = () => {

        //if (idAnaRecapitiEmail) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoLabelEmail)
                    .then(result => {
                        if (result?.esito) {
                            setTipoLabelEmail(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }

        //}

    }

    const getListFar = () => {
        //if (idAnaRecapitiEmail) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlConsultazione.getListFar)
                    .then(result => {
                        if (result?.esito) {
                            setListFar(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        //}
    }

    const getListAzi = () => {
        //if (idAnaRecapitiEmail) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlConsultazione.getListAzienda)
                    .then(result => {
                        if (result?.esito) {
                            setListAzi(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        //}
    }

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value

        //console.log(objTo)

        setObjctTOsave(objTo)

        if (objTo.email?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.idTipEmail > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.idTipLabelEmail > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }


    }


    const salvaEmail = () => {
        setLoading(true)
        if (idAnaRecapitiEmail) {
            if (auth) {
                RestServices().rest('POST', objctTOsave, urlCommon.updateAnagraficaEmail)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                notify({ position: "center", width: "auto", message: 'Email aggiornata' }, "success", 4000)
                                closeAndReload();
                            }
                            else {
                                notify({ position: "center", width: "auto", message: 'Email non aggiornata' }, "error", 4000)
                            }
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }
        else {
            if (auth) {
                RestServices().rest('POST', objctTOsave, urlCommon.addAnagraficaEmail)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                notify({ position: "center", width: "auto", message: 'Email inserita' }, "success", 4000)
                                closeAndReload();
                            }
                            else {
                                notify({ position: "center", width: "auto", message: 'Email non inserita' }, "error", 4000)
                            }
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }

    }


    return (
        <>
            <Loading isVisible={loading} />

            <Popup
                visible={openD}
                showCloseButton={false}
                width={'70%'}
                height={'45%'}
                showTitle={true}
                title="Visualizzazione Email"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: "Chiudi",
                        onClick: () => close(),
                    }

                    }
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Salva',
                        disabled: disableButton,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => salvaEmail(),
                    }}
                />

                


                <div className='form-row '>
                    <div className="form-group col-md-4">
                        <label> EMAIL </label>
                        <TextBox valueChangeEvent="keyup"  value={objctTOsave.email} onValueChanged={(v) => cambiaValore('email', v.value)} placeholder="..." />
                    </div>

                    <div className="form-group col-md-4">
                        <label> NOTE </label>
                        <TextBox valueChangeEvent="keyup"  value={objctTOsave.noteAnaRecapitiEmail} onValueChanged={(v) => cambiaValore('noteAnaRecapitiEmail', v.value)} placeholder="..." />
                    </div>

                </div>

                <div className='form-row '>
                    <div className="form-group col-md-6">
                        <label> TIPO </label>
                        <SelectBox dataSource={tipoEmail} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipEmail} onValueChanged={(v) => cambiaValore('idTipEmail', v.value)} />
                    </div>

                    <div className="form-group col-md-6">
                        <label> IDENTIFICATIVO ETICHETTA </label>
                        <SelectBox dataSource={tipoLabelEmail} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipLabelEmail} onValueChanged={(v) => cambiaValore('idTipLabelEmail', v.value)} />
                    </div>

                </div>

                <div className='form-row '>
                    <div className="form-group col-md-6">
                        <label> VALIDITA' EMAIL (Entrambi i campi vuoti = Email di gruppo) (Azienda (vuoto = tutte)) </label>
                        <SelectBox disabled={ objctTOsave.flagDefault} dataSource={listAzi} displayExpr="ragioneSociale" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idAnaAziRecapitiEmail} onValueChanged={(v) => cambiaValore('idAnaAziRecapitiEmail', v.value)} />
                    </div>

                    <div className="form-group col-md-6">
                        <label> FARMACIA (vuoto = tutte) </label>
                        <SelectBox disabled={objctTOsave.flagDefault}  dataSource={listFar} displayExpr="descrizione" valueExpr="idFar" valueChangeEvent="keyup" value={objctTOsave?.idAnaFarRecapitiEmail} onValueChanged={(v) => cambiaValore('idAnaFarRecapitiEmail', v.value)} />
                    </div>
                </div>

            </Popup>



        </>
    )


}