import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import Autocomplete from "devextreme-react/autocomplete"
import SelectBox from "devextreme-react/select-box"
import CheckBox from "devextreme-react/check-box"
import TabPanel, { Item } from "devextreme-react/tab-panel"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import { confirm } from 'devextreme/ui/dialog';
import Button from 'react-bootstrap/Button'
import { useAuth } from "../../hooks/useAuth"
import { urlCommon, urlConsultazione } from "../costanti"
import { ElementOptionChanged } from "../../interfaces/componentOptions"
import { RestServices } from "../../services/restServices"
import { ChiamataApi } from "../../widget/nuova_ricerca/serviceAuto"
import { Filtri } from "../consultazione/interface"
import { Loading } from "../../widget/Notifications"
import { AutocompleteBox } from "../../widget/Autocomplete"
import { NewTabella } from "../../widget/nuova_tabella/newVersion"
import { DialogAnaFornCenIndirizzo } from "./dialog/popup_ana_forncen_indirizzo"
import { DialogAnaFornCenEmail } from "./dialog/popup_ana_forncen_email"
import { DialogAnaFornCenTelefono } from "./dialog/popup_ana_forncen_telefono"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons"
import { Label } from "devextreme-react/linear-gauge"
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili"
import Ricerca from "../../widget/nuova_ricerca"
import DropDownButton from "devextreme-react/drop-down-button"
import { DialogAnaFornCenImporta } from "./dialog/popup_ana_forncen_importa"



interface PageProps {
    typeEdit: boolean,
    edit: any;
    ricarica: () => void;
    close: () => void;
    tipologiaAmministrativa: any;
    tipologiaSettoreSanita: any;
    tipologiaCategoriaFornitore: any;
    tipologiaStatoApplicativo: any;
    tipologiaPagamento: any;
    setIndietro: () => void,
    typeInsert: boolean,
}

export const AnaFornitoreCentr: React.FC<PageProps> = ({
    close,
    edit,
    tipologiaAmministrativa,
    tipologiaSettoreSanita,
    tipologiaCategoriaFornitore,
    tipologiaStatoApplicativo,
    tipologiaPagamento,
    setIndietro,
    typeEdit,
    typeInsert
}) => {
    const [tabellaAssociazioni, setTabellaAssociazioni] = useState<any>([]);
    const [tabellaIndirizzi, setTabellaIndirizzi] = useState<any>([]);
    const [tabellaEmail, setTabellaEmail] = useState<any>([]);
    const [tabellaTelefoni, setTabellaTelefoni] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const auth = useAuth(urlCommon.auth);
    const [objctTOsave, setObjctTOsave] = useState<any>({});
    const [objctTOedit, setObjctTOedit] = useState<any>({});
    const [objctTOfilter, setObjctTOfilter] = useState<any>({});
    const [loadingGif, setLoadingGif] = useState<any>({})
    const [autoComp, setAutoComp] = useState<any>([])
    const [objPostRicerca, setObjPostRicerca] = useState<any>({})
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [open, setOpen] = useState<boolean>(false)
    const [openA, setOpenA] = useState<boolean>(true)

    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
        if (item.name === "selectedIndex" && item.value == 2) {

            cambiaValoreAssociazioniDefault('ragioneSociale', objctTOsave.ragioneSociale)
            cambiaValoreAssociazioniDefault('partitaIva', objctTOsave.partitaIva)

            ricercaAssociazioni(edit, objctTOsave.ragioneSociale, objctTOsave.partitaIva )

        }

    }
    const [objtipo, setObjtipo] = useState<any>(null)
    const [idDel, setIddell] = useState<number>(0)
    const [messDel, setMessDel] = useState<string>('')
    const [editIndirizzo, setImmediateIndirizzo] = useState<boolean>(false)
    const [openPopIndirizzo, setOpenPopIndirizzo] = useState<boolean>(false)
    const [openPopEmail, setOpenPopEmail] = useState<boolean>(false)
    const [editEmail, setImmediateEmail] = useState<boolean>(false)
    const [editTelefoni, setImmediateTelefoni] = useState<boolean>(false)
    const [openPopTelefoni, setOpenPopTelefoni] = useState<boolean>(false)
    const [openPopEliminaIndirizzo, setOpenPopEliminaIndirizzo] = useState<boolean>(false)
    const [openPopImporta, setOpenPopImporta] = useState<boolean>(false)
    const handleCloseConfermaEliminaIndirizzo = () => setOpenPopEliminaIndirizzo(false);
    const [idAnaIndirizzo, setIdAnaIndirizzo] = useState<number>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
    const [daBancaDati, setDaBancaDati] = useState<boolean>(false);
    const [daEsistente, setDaEsistente] = useState<boolean>(false);
    const [selectedAssocizioni, setSelectedAssociazioni] = useState<any>([])

    useEffect(() => {
        if (auth) {
            getDettaglioFornitore(edit)
            getIndirizzi(edit)
        }
        
    }, [auth])

    useEffect(() => {
        if (objctTOedit) {

            if (objctTOedit?.ragioneSociale?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit?.indirizzo?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit?.cap?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit?.idCategoriaFornitore > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit?.citta?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit?.idProvincia > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

        }

    }, [objctTOedit])

    useEffect(() => {
        if (objctTOsave) {

            if (objctTOsave?.ragioneSociale?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOsave?.indirizzo?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOsave?.cap?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOsave?.idCategoriaFornitore > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOsave?.citta?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOsave?.idProvincia > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

        }

    }, [objctTOsave])

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value


        setObjctTOsave(objTo)
        console.log(objTo)

        if (objTo.ragioneSociale?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.indirizzo?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.cap?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.idCategoriaFornitore > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.citta?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.idProvincia > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

    }

    const cambiaValoreAssociazioni = (tipo: string, value: string) => {
        let objTo = { ...objctTOfilter }
        objTo[tipo] = value

        setObjctTOfilter(objTo)

    }

    const cambiaValoreAssociazioniDefault = (tipo: string, value: string) => {
        let objTo = { ...objctTOfilter }
        objTo[tipo] = value

        setObjctTOfilter(objTo)

    }

    const getDettaglioFornitore = async (fornitore: any) => {

        if (fornitore) {
            let params = { idAnaGenerale: fornitore.idAnaGenFornCen };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getFornCen)
                    .then(result => {
                        if (result?.esito) {
                            setObjctTOsave(result.response)
                            setObjctTOedit(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        }

    }

    const getIndirizzi = async (fornitore: any) => {

        if (fornitore) {
            let params = { idAnaGenerale: fornitore.idAnaGenFornCen };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListIndirizzi)
                    .then(result => {
                        if (result?.esito) {
                            setTabellaIndirizzi(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }

    }

    const ricercaAssociazioni = async (fornitore: any, ragioneSociale: string|undefined, partitaIva: string|undefined) => {
        if (fornitore) {
            if ((ragioneSociale == undefined && partitaIva == undefined) && ((objctTOfilter.partitaIva == undefined || objctTOfilter.partitaIva.length == 0) && (objctTOfilter.ragioneSociale == undefined || objctTOfilter.ragioneSociale.length == 0))) {
                notify({ position: "center", width: "auto", message: 'Compilare uno tra ragione sociale e partita iva' }, "error", 4000)
                return;
            }
            let params = {};
            if (ragioneSociale == undefined && partitaIva == undefined)
                params = { idAnaGenerale: fornitore.idAnaGenFornCen, partitaIva: objctTOfilter.partitaIva, ragioneSociale: objctTOfilter.ragioneSociale };
            else
                params = { idAnaGenerale: fornitore.idAnaGenFornCen, partitaIva: partitaIva, ragioneSociale: ragioneSociale };
            if (auth) {
                setLoading(true)
                RestServices().rest('POST', params, urlCommon.getListFornLocAssociazione)
                    .then(result => {
                        if (result?.esito) {


                            let final = result.response


                            final.sort((a: any, b: any) => {
                                if (a.associato > b.associato) return -1;
                                if (a.associato < b.associato) return 1;
                                if (a.ragioneSocialeFar.toUpperCase() > b.ragioneSocialeFar.toUpperCase()) return 1;
                                if (a.ragioneSocialeFar.toUpperCase() < b.ragioneSocialeFar.toUpperCase()) return -1;
                            });


                            setTabellaAssociazioni(final)


                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }

    }

    useEffect(() => {
        //if (edit && auth) {
        //    getDettaglioFornitore(edit)
        //    getIndirizzi(edit)
        //}
    }, [edit, auth]);


    const remove = (id: string) => {
        let nuovoObj = { ...objctTOsave }
        delete nuovoObj[id]
        setObjctTOsave(nuovoObj)
    }

    const actions = async (string: string, typeApi: string, idVal?: string) => {
        let loadingg = { ...loadingGif }
        if (idVal) {
            loadingg[idVal] = true
            setLoadingGif(loadingg)
        }


        let risulatoCall = await ChiamataApi(string, typeApi)
        if (risulatoCall) {
            if (risulatoCall?.esito) {
                //console.log(risulatoCall.response);
                setAutoComp(risulatoCall.response)
                if (idVal) {
                    loadingg[idVal] = false
                    setLoadingGif(loadingg)
                }
            } else {
                if (idVal) {
                    loadingg[idVal] = false
                    setLoadingGif(loadingg)
                }
                notify({ position: 'center', width: "auto", message: risulatoCall.message }, "error", 4000)

            }
        }

    }


    const columnsAssociazioni = [
        { caption: "Id Associazione", dataField: "idAssociazione", visible: false },
        { caption: "Associato", dataField: "associato", dataType: "boolean" },
        { caption: "Preferito", dataField: "preferito", dataType: "boolean" },
        { caption: "Id Farmacia", dataField: "idFar", visible: false },
        { caption: "Id Ana Generale", dataField: "idAnaGeneraleFornitore", visible: false },
        { caption: "Farmacia", dataField: "ragioneSocialeFar"},
        { caption: "P.Iva", dataField: "partitaIvaFornitore" },
        { caption: "Fornitore", dataField: "ragioneSocialeFornitore" },
        { caption: "Gestionale", dataField: "gestionale" },
        { caption: "Cat.Fornitore", dataField: "categoriaFornitore" },
        { caption: "Cod.Ditta CSF", dataField: "codiceDittaCsf" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Dissocia",
                    icon: "minus",
                    onClick: (e: any) => {

                        let result = confirm("<i>Dissociare l\'elemento selezionato?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                               dissocia(e.row.data.idAssociazione);
                            } else {
                                return;
                            }
                        });


                    },
                    visible: (x: any) => visibleBDissocia(x)
                },
                {
                    hint: "Associa",
                    icon: "plus",
                    onClick: (e: any) => {

                        let result = confirm("<i>Associare l\'elemento selezionato?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                let ids: object[] = [];
                                let obj = { idAnaFornLoc: e.row.data.idAnaGeneraleFornitore, idFar: e.row.data.idFar  }
                                ids.push(obj);
                                associa(edit.idAnaGenFornCen, ids);
                            } else {
                                return;
                            }
                        });

                    },
                    visible: (x: any) => visibleBAssocia(x)
                },
                {
                    hint: "Preferito",
                    icon: "favorites",
                    onClick: (e: any) => {

                        let tmp = ''
                        if (e.row.data.preferito == true)
                            tmp = 'Rimuovere dai '
                        else
                            tmp = 'Aggiungere ai '
                        let result = confirm("<i>" + tmp + " preferiti l\'elemento selezionato?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                let obj = { idAssociazione: e.row.data.idAssociazione, preferito: !e.row.data.preferito }
                                preferiti(obj);
                            } else {
                                return;
                            }
                        });

                    },
                    visible: (x: any) => visibleBPreferito(x)
                },
                {
                    hint: "Invia",
                    icon: "exportselected",
                    onClick: (e: any) => {
                        let result = confirm("Inviare il fornitore selezionato alla farmacia?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                inviaFornitore(e.row.data.idAnaGeneraleFornitore, e.row.data.idFar)
                            } else {
                                return;
                            }
                        });

                        
                    },
                    visible: (x: any) => visibleBInvia(x)
                }
            ]
        }

    ];

    const visibleBAssocia = (e: any) => {
        if (e.row.data.associato == 0) {
            return true;
        } else {
            return false;
        }
    }

    const visibleBDissocia = (e: any) => {
        if (e.row.data.associato == 1) {
            return true;
        } else {
            return false;
        }
    }

    const visibleBPreferito = (e: any) => {
        if (e.row.data.associato == 1) {
            return true;
        } else {
            return false;
        }
    }

    const visibleBInvia = (e: any) => {
        if (e.row.data.associato == 1) {
            return false;
        } else {
            return true;
        }
    }

    const columnsIndirizzi = [
        { caption: "idAnaIndirizzi", dataField: "idAnaIndirizzi", visible: false },
        { caption: "Indirizzo", dataField: "indirizzo", width: 300 },
        { caption: "Numero", dataField: "numero", width: 70 },
        { caption: "Cap", dataField: "cap", width: 80 },
        { caption: "Citta", dataField: "citta" },
        { caption: "Provincia", dataField: "descrizioneProvincia" },
        { caption: "Tipo", dataField: "descTipIndirizzo" },
        { caption: "Email", dataField: "email" },
        { caption: "Telefono", dataField: "telefono" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        //console.log(e.row.data);
                        setObjtipo(e.row.data)
                        setImmediateIndirizzo(true)
                        setOpenPopIndirizzo(true)

                    }
                },
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        //setIddell(e.row.data.idAnaIndirizzi)
                        //setOpenPopEliminaIndirizzo(true)
                        let result = confirm("<i>Eliminare l\'elemento selezionato?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                eliminaIndirizzo(e.row.data.idAnaIndirizzi);
                            } else {
                                return;
                            }
                        });
                    },
                    visible: (x: any) => visibleBIndirizzo(x)
                }]
        }

    ];

    const visibleBIndirizzo = (e: any) => {
        if (e.row.data.idTipIndirizzo == 1) {
            return false;
        } else {
            return true;
        }
    }

    const columnsEmail = [
        { caption: "idAnaIndirizzi", dataField: "idAnaIndirizzo", visible: false },
        { caption: "Email", dataField: "email", width: 300 },
        { caption: "Note", dataField: "noteAnaRecapitiEmail", width: 70 },
        { caption: "Tipo", dataField: "descTipEmail" },
        { caption: "Identificativo etichetta", dataField: "descTipLabelEmail" },
        { caption: "Destinazione", dataField: "destinazione" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        setImmediateEmail(true)
                        setOpenPopEmail(true)

                    }
                },
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        let id = e.row.data.idAnaRecapitiEmail
                        let idAnaIndirizzo = e.row.data.idAnaIndirizzo
                        let result = confirm("<i>Eliminare l\'elemento selezionato?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                eliminaEmail(id, idAnaIndirizzo);
                            } else {
                                return;
                            }
                        });


                    }
                }]
        }

    ];

    const columnsTelefoni = [
        { caption: "idAnaIndirizzi", dataField: "idAnaIndirizzo", visible: false },
        { caption: "Numero telfonico", dataField: "numero", width: 300 },
        { caption: "Note", dataField: "noteAnaRecapitiTelefono", width: 70 },
        { caption: "Tipo", dataField: "descTipTelefono" },
        { caption: "Destinazione", dataField: "destinazione" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        //console.log(e.row.data);
                        setObjtipo(e.row.data)
                        setImmediateTelefoni(true)
                        setOpenPopTelefoni(true)

                    }
                },
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        let id = e.row.data.idAnaRecapitiTelefono
                        let idAnaIndirizzo = e.row.data.idAnaIndirizzo
                        let result = confirm("<i>Eliminare l\'elemento selezionato?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                eliminaTelefono(id, idAnaIndirizzo);
                            } else {
                                return;
                            }
                        });


                    }
                }]
        }

    ];

    const caricaDati = (idAnaIndirizzo: number | undefined) => {

        if (auth && idAnaIndirizzo) {
            setIdAnaIndirizzo(idAnaIndirizzo)
            let params = { idAnaIndirizzo: idAnaIndirizzo };
            setLoading(true)
            RestServices().rest('POST', params, urlCommon.getListEmail)
                .then(result => {
                    if (result?.esito) {
                        setTabellaEmail(result.response)
                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })
            setLoading(true)
            RestServices().rest('POST', params, urlCommon.getListTelefoni)
                .then(result => {
                    if (result?.esito) {
                        setTabellaTelefoni(result.response)
                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })
        }

    }

    const UpdateAnaFornitore = () => {

        let updater = {
            "ragioneSociale": objctTOsave.ragioneSociale,
            "idAnaGenerale": objctTOsave.idAnaGenerale,
            "cap": objctTOsave.cap,
            "cognomeTitolare": objctTOsave.cognomeTitolare,
            "nomeTitolare": objctTOsave.nomeTitolare,
            "codiceFiscale": objctTOsave.codiceFiscale,
            "partitaIva": objctTOsave.partitaIva,
            "codiceComuneAsl": objctTOsave.codiceComuneAsl,
            "codiceUfficioPA": objctTOsave.codiceUfficioPA,
            "idTipoSettoreSanita": objctTOsave.idTipoSettoreSanita,
            "idTipoAmministrativa": objctTOsave.idTipoAmministrativa,
            "idComuneAsl": objctTOsave.idComuneAsl,
            "budgetFornitore": objctTOsave.budgetFornitore,
            "idStatoApplicativo": objctTOsave.idStatoApplicativo,
            "idFornitore": objctTOsave.idFornitore,
            "raggruppamentoCommercialeFornitore": objctTOsave.raggruppamentoCommercialeFornitore,
            "giorniCadenzaRiordinoFornitore": objctTOsave.giorniCadenzaRiordinoFornitore,
            "giornoPreferenzialeFornitore": objctTOsave.giornoPreferenzialeFornitore,
            'giorniCalcoloCoperturaFornitore': objctTOsave.giorniCalcoloCoperturaFornitore,
            'giorniMediConsegnaFornitore': objctTOsave.giorniMediConsegnaFornitore,
            'valoreMinimoOrdineFornitore': objctTOsave.valoreMinimoOrdineFornitore,
            'idAnaFatturazione': objctTOsave.idAnaFatturazione,
            'idTipoModalitaPagamento': objctTOsave.idTipoModalitaPagamento,
            'idTipoDilazionePagamento': objctTOsave.idTipoDilazionePagamento,
            'idDittaBancaDati': objctTOsave.idDittaBancaDati,
            'idCategoriaFornitore': objctTOsave.idCategoriaFornitore,
            'idAnaIndirizzi': objctTOsave.idAnaIndirizzi,
            'indirizzo': objctTOsave.indirizzo,
            'numeroCivico': objctTOsave.numeroCivico,
            'citta': objctTOsave.citta,
            'idProvincia': objctTOsave.idProvincia,
            'idAnaRecapitoMail': objctTOsave.idAnaRecapitoMail,
            'email': objctTOsave.email,
            'noteEmail': objctTOsave.noteEmail,
            'idAnaRecapitoTelefono': objctTOsave.idAnaRecapitoTelefono,
            'telefono': objctTOsave.telefono,
            'noteTelefono': objctTOsave.noteTelefono,
            'flagTrattato': objctTOsave.flagTrattato,
            'idTipIndirizzo': objctTOsave.idTipIndirizzo,
            'idTipMail': objctTOsave.idTipMail,
            'idTipLabelMail': objctTOsave.idTipLabelMail,
            'flagDefaultMail': objctTOsave.flagDefaultMail,
            'idTipTelefono': objctTOsave.idTipTelefono,
            'flagDefaultTelefono': objctTOsave.flagDefaultTelefono,




        }


        if (auth) {

            RestServices().rest('POST', updater, urlCommon.updateFornitore)
                .then(result => {
                    if (result?.esito) {
                        notify({ position: "center", width: "auto", message: 'Aggiornamento eseguito con successo' }, "success", 4000)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }


                })
        }

    }


    const InsertAnaFornitore = () => {

        let inserter = {
            "ragioneSociale": objctTOsave.ragioneSociale,
            "cap": objctTOsave.cap,
            "cognomeTitolare": objctTOsave.cognomeTitolare,
            "nomeTitolare": objctTOsave.nomeTitolare,
            "codiceFiscale": objctTOsave.codiceFiscale,
            "partitaIva": objctTOsave.partitaIva,
            "codiceComuneAsl": objctTOsave.codiceComuneAsl,
            "codiceUfficioPA": objctTOsave.codiceUfficioPA,
            "idTipoSettoreSanita": objctTOsave.idTipoSettoreSanita,
            "idTipoAmministrativa": objctTOsave.idTipoAmministrativa,
            "idComuneAsl": objctTOsave.idComuneAsl,
            "budgetFornitore": objctTOsave.budgetFornitore,
            "idStatoApplicativo": objctTOsave.idStatoApplicativo,
            "idFornitore": objctTOsave.idFornitore,
            "raggruppamentoCommercialeFornitore": objctTOsave.raggruppamentoCommercialeFornitore,
            "giorniCadenzaRiordinoFornitore": objctTOsave.giorniCadenzaRiordinoFornitore,
            "giornoPreferenzialeFornitore": objctTOsave.giornoPreferenzialeFornitore,
            'giorniCalcoloCoperturaFornitore': objctTOsave.giorniCalcoloCoperturaFornitore,
            'giorniMediConsegnaFornitore': objctTOsave.giorniMediConsegnaFornitore,
            'valoreMinimoOrdineFornitore': objctTOsave.valoreMinimoOrdineFornitore,
            'idAnaFatturazione': objctTOsave.idAnaFatturazione,
            'idTipoModalitaPagamento': objctTOsave.idTipoModalitaPagamento,
            'idTipoDilazionePagamento': objctTOsave.idTipoDilazionePagamento,
            'idDittaBancaDati': objctTOsave.idDittaBancaDati,
            'idCategoriaFornitore': objctTOsave.idCategoriaFornitore,
            'idAnaIndirizzi': objctTOsave.idAnaIndirizzi,
            'indirizzo': objctTOsave.indirizzo,
            'numeroCivico': objctTOsave.numeroCivico,
            'citta': objctTOsave.citta,
            'idProvincia': objctTOsave.idProvincia,
            'idAnaRecapitoMail': objctTOsave.idAnaRecapitoMail,
            'email': objctTOsave.email,
            'noteEmail': objctTOsave.noteEmail,
            'idAnaRecapitoTelefono': objctTOsave.idAnaRecapitoTelefono,
            'telefono': objctTOsave.telefono,
            'noteTelefono': objctTOsave.noteTelefono,
            'flagTrattato': objctTOsave.flagTrattato,
            'idTipIndirizzo': objctTOsave.idTipIndirizzo,
            'idTipMail': objctTOsave.idTipMail,
            'idTipLabelMail': objctTOsave.idTipLabelMail,
            'flagDefaultMail': objctTOsave.flagDefaultMail,
            'idTipTelefono': objctTOsave.idTipTelefono,
            'flagDefaultTelefono': objctTOsave.flagDefaultTelefono,
        }


        if (auth) {

            RestServices().rest('POST', inserter, urlCommon.addFornitore)
                .then(result => {
                    if (result?.esito) {
                        notify({ position: "center", width: "auto", message: 'Inserimento eseguito con successo' }, "success", 4000)
                        setIndietro()
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }


                })
        }

    }


    const ricaricaAltroIndirizzo = () => {
        getIndirizzi(edit)
    }

    const eliminaIndirizzo = (id: number) => {

        setLoading(true)
        let params = { idAnaIndirizzo: id };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.eliminaIndirizzo)
                .then(result => {
                    if (result?.esito) {
                        setLoading(false)
                        ricaricaAltroIndirizzo()
                    } else {
                        setLoading(false)
                    }
                })

        }

    }

    const eliminaEmail = (id: number, idAnaIndirizzo: number) => {

        setLoading(true)
        let params = { idAnaRecapitiEmail: id };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.eliminaEmail)
                .then(result => {
                    if (result?.esito) {
                        setLoading(false)
                        caricaDati(idAnaIndirizzo)
                    } else {
                        setLoading(false)
                    }
                })

        }

    }

    const eliminaTelefono = (id: number, idAnaIndirizzo: number) => {

        setLoading(true)
        let params = { idAnaRecapitiTelefono: id };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.eliminaTelefono)
                .then(result => {
                    if (result?.esito) {
                        setLoading(false)
                        caricaDati(idAnaIndirizzo)
                    } else {
                        setLoading(false)
                    }
                })

        }

    }

    const onRowPrepared = (e: any) => {

        if (!e.data)
            return;
        if (e.data.associato == true || e.data.abbinato == true) {
            e.rowElement.style.backgroundColor = '#d1f0ea';
        }
        if (e.data.associato == true) {
            e.rowElement.style.fontWeight= 'bold';
        }
        if (e.data.abbinato == false) {
            e.rowElement.style.backgroundColor = '#fce6e8';
        }


    }

    const dissocia = (id: number) => {
        setLoading(true)
        let ids = []
        ids.push(id)
        let params = { idAssociazione: ids };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.dissociaFornitore)
                .then(result => {
                    if (result?.esito) {
                        setLoading(false)
                        //getAssociazioni(edit, params);
                        ricercaAssociazioni(edit, undefined, undefined)
                    } else {
                        setLoading(false)
                    }
                })

        }
    }

    const associa = (idAnaFornCen: number, listFornLoc: object[] ) => {
        setLoading(true)
        let params = { idAnaFornCen: idAnaFornCen, listFornLoc: listFornLoc  };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.associaFornitore)
                .then(result => {
                    if (result?.esito) {
                        setLoading(false)
                        //getAssociazioni(edit, params);
                        ricercaAssociazioni(edit, undefined, undefined)

                    } else {
                        setLoading(false)
                    }
                })

        }
    }

    const preferiti = (param: object) => {
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', param, urlCommon.setFornitorePreferito)
                .then(result => {
                    if (result?.esito) {
                        setLoading(false)
                        ricercaAssociazioni(edit, undefined, undefined)
                    } else {
                        setLoading(false)
                    }
                })

        }
    }


    const inviaFornitore = (idAnaGeneraleFornitore: number, idFar: number) => {
        let fornitori = [idAnaGeneraleFornitore];
        let farmacie = [idFar];


        let params = { farmacie: farmacie, fornitori: fornitori }

        if (auth) {
            RestServices().rest('POST', params, urlCommon.inviaFornitori)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            notify({ position: "center", width: "auto", message: 'Fornitore inviato correttamente' }, "success", 4000)
                            close();
                        }
                        else {
                            notify({ position: "center", width: "auto", message: 'Fornitore non inviato correttamente' }, "error", 4000)
                            setLoading(false)
                        }

                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const tagRicercha = () => {
        let tagArr = []
        for (const [key, value] of Object.entries(objctTOsave)) {
            //console.log(value); 
            if (key == 'ragioneSociale') {
                tagArr.push(objctTOsave.ragioneSociale)
            }

            if (key == 'codice') {
                tagArr.push(objctTOsave.codice)
            }

            if (key == 'partitaIva') {
                tagArr.push(objctTOsave.partitaIva)
            }

            if (key == 'codiceFiscale') {
                tagArr.push(objctTOsave.codiceFiscale)
            }

            if (key == 'indirizzo') {
                tagArr.push(objctTOsave.indirizzo)
            }

            if (key == 'citta') {
                tagArr.push(objctTOsave.citta)
            }



           
        }

        if (open) {
            return null
        } else {
            return (
                tagArr.map((tag, index) => {
                    return (
                        <div style={{ marginRight: 10, display: 'inline-block', background: ' #dfdfdf', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5 }} key={'tag' + index}>
                            <p style={{ fontSize: 13, margin: 0 }}>{tag}</p>
                        </div>
                    )
                })
            )
        }
    } 

    const buttonDropDownOptions = { width: 230 };

    const itemsImporta = ['da banca dati', 'da esistente (non associati)'];

    const onImportaClick = (e: any) => {
        if (e.itemData === 'da banca dati') {
            setDaBancaDati(true)
            setDaEsistente(false)
        }
            setDaBancaDati(true)
        if (e.itemData === 'da esistente (non associati)') {
            setDaEsistente(true)
            setDaBancaDati(false)
        }
            

        setOpenPopImporta(true)
    }

    const tagRicercaAssociazioni = () => {
        let tagArr = []
        for (const [key, value] of Object.entries(objctTOsave)) {
            //console.log(value); 
            if (key == 'ragioneSociale') {
                tagArr.push(objctTOsave.ragioneSociale)
            }

            if (key == 'partitaIva') {
                tagArr.push(objctTOsave.partitaIva)
            }
     
        }

        if (openA) {
            return null
        } else {
            return (
                tagArr.map((tag, index) => {
                    return (
                        <div style={{ marginRight: 10, display: 'inline-block', background: ' #dfdfdf', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5 }} key={'tag' + index}>
                            <p style={{ fontSize: 13, margin: 0 }}>{tag}</p>
                        </div>
                    )
                })
            )
        }
    } 


    const cambiaIdFornSel = (value: any) => {

        let ids: any = []

        value.map((forn: any) => {
            if (forn.associato === false)
                ids.push({ idFar: forn.idFar, idAnaFornLoc: forn.idAnaGeneraleFornitore })
        })

        setSelectedAssociazioni(ids);

    }


    return (
<>
            <Loading isVisible={loading} />
            <div style={{ background: 'white', padding: 10, borderRadius: 10, }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h4 style={{ fontSize: 22, margin: 0 }}>Dettaglio Fornitore centralizzato: {tagRicercha()}</h4></div>
                    <div><button style={{ border: 'none', background: 'none', fontSize: 20 }} onClick={() => setOpen(!open)}>{open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</button></div>
                </div>
                <div className={open ? 'main' : 'mainOut'}>
                    <div className="col-md-12">
                        <div className='form-row'>
                            <div className="form-group col-md-1">
                                <TextBox disabled value={objctTOsave.codice} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                            </div>
                            <div className="form-group col-md-3">
                                <TextBox disabled value={objctTOsave.ragioneSociale} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                            </div>

                            
                            {objctTOsave.partitaIva && <div className="form-group col-md-1">
                                <TextBox disabled value={objctTOsave.partitaIva} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                            </div>}
                            {objctTOsave.codiceFiscale && <div className="form-group col-md-1">
                                <TextBox disabled value={objctTOsave.codiceFiscale} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                            </div>}
                            
                            {
                                objctTOsave.indirizzo && <div className="form-group col-md-3">
                                    <TextBox disabled value={objctTOsave.indirizzo} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                                </div>
                            }
                            
                            {
                                objctTOsave.citta && 
                                <div className="form-group col-md-3">
                                    <TextBox disabled value={objctTOsave.citta} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                                </div>
                            }
                            

                        </div>


                    </div>
                </div>
            </div>
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div>
                    <Button className="btn btn-warning" variant="warning" onClick={() => {setIndietro()} }>INDIETRO </Button>
                </div>
                <div className="posbtn">
                    <Button className="btn btn-success" variant="success" onClick={() => {
                        if (typeInsert == true) {
                            InsertAnaFornitore()
                            
                        }
                            
                        else
                            UpdateAnaFornitore()
                    }}
                        disabled={disableButton}>SALVA </Button>
                </div>
            </div>
            &nbsp;
            <TabPanel
                selectedIndex={selectedIndex}
                loop={false}
                animationEnabled={true}
                onOptionChanged={onOptionChanged}


            >
                <Item title="Anagrafica">
                    <Row>&nbsp;</Row>
                    {edit === undefined && <DropDownButton
                        text="Importa"
                        icon="arrowdown"
                        dropDownOptions={buttonDropDownOptions}
                        items={itemsImporta}
                        onItemClick={onImportaClick}
                    />}
                    
                    <Row>&nbsp;</Row>
                    <ScrollView height="100%" width="100%">
                        <div className='form-row'>
                            <div className="form-group col-md-6">
                                <label> RAGIONE SOCIALE * </label>
                                <TextBox value={objctTOsave.ragioneSociale} onValueChanged={(v) => cambiaValore('ragioneSociale', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-6">
                                <label> CODICE </label>
                                <TextBox disabled value={objctTOsave.codice} onValueChanged={(v) => cambiaValore('codice', v.value)} placeholder="..." />
                            </div>
                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> NOME TITOLARE </label>
                                <TextBox value={objctTOsave.nomeTitolare} onValueChanged={(v) => cambiaValore('nomeTitolare', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-6">
                                <label> COGNOME TITOLARE </label>
                                <TextBox value={objctTOsave.cognomeTitolare} onValueChanged={(v) => cambiaValore('cognomeTitolare', v.value)} placeholder="..." />
                            </div>
                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-2">
                                <label> CODICE FISCALE </label>
                                <TextBox value={objctTOsave.codiceFiscale} onValueChanged={(v) => cambiaValore('codiceFiscale', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> PARTITA IVA </label>
                                <TextBox value={objctTOsave.partitaIva} onValueChanged={(v) => cambiaValore('partitaIva', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> TELEFONO </label>
                                <TextBox value={objctTOsave.telefono} onValueChanged={(v) => cambiaValore('telefono', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> NOTE TELEFONO </label>
                                <TextBox value={objctTOsave.noteTelefono} onValueChanged={(v) => cambiaValore('noteTelefono', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> EMAIL </label>
                                <TextBox value={objctTOsave.email} onValueChanged={(v) => cambiaValore('email', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> NOTE EMAIL </label>
                                <TextBox value={objctTOsave.noteEmail} onValueChanged={(v) => cambiaValore('noteEmail', v.value)} placeholder="..." />
                            </div>
                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-3">
                                <label> INDIRIZZO * </label>
                                <TextBox value={objctTOsave.indirizzo} onValueChanged={(v) => cambiaValore('indirizzo', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> NUMERO </label>
                                <TextBox value={objctTOsave.numeroCivico} onValueChanged={(v) => cambiaValore('numeroCivico', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> CAP * </label>
                                <TextBox value={objctTOsave.cap} onValueChanged={(v) => cambiaValore('cap', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> CITTA * </label>
                                <TextBox value={objctTOsave.citta} onValueChanged={(v) => cambiaValore('citta', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-2">
                                <label> PROVINCIA * </label>
                                <AutocompleteBox typeApi={"provincie"} idApi={"id"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idProvincia"} descExpr={"descrizioneProvincia"} idPost={"id"} descPost={"descrizione"} idControl={"province"} ></AutocompleteBox>
                            </div>

                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-3">
                                <label> CODICE ASL DI APPARTENENZA </label>
                                <TextBox value={objctTOsave.codiceComuneAsl} onValueChanged={(v) => cambiaValore('codiceComuneAsl', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> COMUNE ASL </label>
                                <AutocompleteBox typeApi={"comuneAsl"} idApi={"id"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idComuneAsl"} descExpr={"descrizioneComuneAsl"} idPost={"id"} descPost={"descrizione"} idControl={"comuneAsl"} ></AutocompleteBox>
                            </div>

                            <div className="form-group col-md-3">
                                <label> CODICE UFFICIO PA </label>
                                <TextBox value={objctTOsave.codiceUfficioPA} onValueChanged={(v) => cambiaValore('codiceUfficioPA', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> CODICE SITO LOGISTICO </label>
                                <TextBox value={objctTOsave.codiceNsis} onValueChanged={(v) => cambiaValore('codiceNsis', v.value)} placeholder="..." />
                            </div>

                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-3">
                                <label> TIPOLOGIA AMMINISTRATIVA </label>
                                <SelectBox dataSource={tipologiaAmministrativa} displayExpr="descrizione" valueExpr="idTipoAmministrativa" valueChangeEvent="keyup" value={objctTOsave?.idTipoAmministrativa} onValueChanged={(v) => cambiaValore('idTipoAmministrativa', v.value)} />
                            </div>

                            <div className="form-group col-md-3">
                                <label> BUDGET ANNUO </label>
                                <TextBox value={objctTOsave.budgetFornitore} onValueChanged={(v) => cambiaValore('budgetFornitore', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> MODALITA' DI PAGAMENTO </label>
                                <SelectBox dataSource={tipologiaPagamento} displayExpr="descrizione" valueExpr="idTipoModalitaPagamento" valueChangeEvent="keyup" value={objctTOsave?.idTipoModalitaPagamento} onValueChanged={(v) => cambiaValore('idTipoModalitaPagamento', v.value)} />
                            </div>

                            <div className="form-group col-md-3">
                                <label> FORNITORE FATTURAZIONE </label>
                                <AutocompleteBox typeApi={"fornitori"} idApi={"id"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idAnaFatturazione"} descExpr={"descrizioneAnaFatturazione"} idPost={"id"} descPost={"codice"} idControl={"fornitoreFatturazione"} ></AutocompleteBox>
                            </div>

                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-3">
                                <label> GIORNI CADENZA RIORDINO DIRETTO </label>
                                <TextBox value={objctTOsave.giorniCadenzaRiordinoFornitore} onValueChanged={(v) => cambiaValore('giorniCadenzaRiordinoFornitore', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> GIORNI PREFERENZIALE </label>
                                <TextBox value={objctTOsave.giornoPreferenzialeFornitore} onValueChanged={(v) => cambiaValore('giornoPreferenzialeFornitore', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> GIORNI PER CALCOLO COPERTURA </label>
                                <TextBox value={objctTOsave.giorniCalcoloCoperturaFornitore} onValueChanged={(v) => cambiaValore('giorniCalcoloCoperturaFornitore', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> GIORNI MEDI CONSEGNA MERCE </label>
                                <TextBox value={objctTOsave.giorniMediConsegnaFornitore} onValueChanged={(v) => cambiaValore('giorniMediConsegnaFornitore', v.value)} placeholder="..." />
                            </div>

                        </div>

                        <div className='form-row '>

                            <div className="form-group col-md-3">
                                <label> RAGGRUPPAMENTO COMMERCIALE </label>
                                <TextBox value={objctTOsave.raggruppamentoCommercialeFornitore} onValueChanged={(v) => cambiaValore('raggruppamentoCommercialeFornitore', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> SETTORE SANITA' </label>
                                <SelectBox dataSource={tipologiaSettoreSanita} displayExpr="descrizione" valueExpr="idTipoSettoreSanita" valueChangeEvent="keyup" value={objctTOsave?.idTipoSettoreSanita} onValueChanged={(v) => cambiaValore('idTipoSettoreSanita', v.value)} />
                            </div>

                            <div className="form-group col-md-3">
                                <label> VALORE IMPORTO MINIMO ORDINE </label>
                                <TextBox value={objctTOsave.valoreMinimoOrdineFornitore} onValueChanged={(v) => cambiaValore('valoreMinimoOrdineFornitore', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-3">
                                <label> STATO VALIDITA' PER APPLICATIVO FARMACIE </label>
                                <SelectBox dataSource={tipologiaStatoApplicativo} displayExpr="descrizione" valueExpr="idStatoApplicativo" valueChangeEvent="keyup" value={objctTOsave?.idStatoApplicativo} onValueChanged={(v) => cambiaValore('idStatoApplicativo', v.value)} />
                            </div>

                        </div>

                        <div className='form-row '>

                            <div className="form-group col-md-3">
                                <label> CODICE DITTA BANCA DATI </label>
                                {/*<AutocompleteBox typeApi={"ditta"} idApi={"id"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idDittaBancaDati"} descExpr={"descrizioneDittaBancaDati"} idPost={"codice"} descPost={"ragioneSociale"} idControl={"dittaBancaDati"} ></AutocompleteBox>*/}
                                <AutocompleteBox typeApi={"ditta"} idApi={"codice"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idDittaBancaDati"} descExpr={"descrizioneDittaBancaDati"} idPost={"codice"} descPost={"ragioneSociale"} idControl={"dittaBancaDati"} idOpt={"codice"} descOpt={"ragioneSociale"} width={400}></AutocompleteBox>
                            </div>

                            <div className="form-group col-md-2">
                                <label> CATEGORIA * </label>
                                <SelectBox dataSource={tipologiaCategoriaFornitore} displayExpr="descrizione" valueExpr="idCategoriaFornitore" valueChangeEvent="keyup" value={objctTOsave?.idCategoriaFornitore} onValueChanged={(v) => cambiaValore('idCategoriaFornitore', v.value)} />
                            </div>
                            <div className="form-group col-md-1">

                            </div>
                            <div className="form-group col-md-2">
                                <Row>
                                    <Col>
                                        <label> FORNITORE TRATTATO </label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <CheckBox value={objctTOsave?.flagTrattato} onValueChanged={(v) => cambiaValore('flagTrattato', v.value)} />
                                    </Col>
                                </Row>
                                

                            </div>

                        </div>



                    </ScrollView>
                </Item>

                <Item disabled={typeInsert} title="Indirizzi alternativi">
                    <Row>&nbsp;</Row>
                    <ScrollView height="100%" width="100%">
                        <Row>
                            <Col>
                                <label> ELENCO INDIRIZZI </label>
                            </Col>
                            <Col>
                                <Button id='btnNuovoindirizzo' className= "float-right" variant="success" onClick={() => { setObjtipo({ idAnaGenerale: objctTOsave.idAnaGenerale }); setOpenPopIndirizzo(true) }} >
                                    NUOVO INDIRIZZO
                                </Button>
                            </Col>
                        </Row>

                        <NewTabella
                            idTabella="indirizzi"
                            columnC={false}
                            activeSaver={false}
                            searchPanel={false}
                            sync
                            colonne={columnsIndirizzi}
                            dataSource={tabellaIndirizzi}
                            exportT={{
                                enabled: false,
                                fileName: "list_ind",
                            }}
                            paging={false}
                            height={240}
                            onclickRow={(datiRow: any) => caricaDati(datiRow.data?.idAnaIndirizzi)}
                            hidePager={ true}
                        />

                        <Row>&nbsp;</Row>
                        <Row>&nbsp;</Row>

                        <Row>
                            <Col>
                                <label> ELENCO EMAIL </label>
                            </Col>
                            <Col>
                                <Button id='btnNuovoEmail' className="float-right" variant="success" onClick={() => { if (idAnaIndirizzo == undefined || idAnaIndirizzo <= 0) { notify({ position: "center", width: "auto", message: 'Selezionare un indirizzo' }, "error", 4000); return } setObjtipo({ idAnaIndirizzi: idAnaIndirizzo, idAnaGenerale: objctTOsave.idAnaGenerale }); setOpenPopEmail(true) }} >
                                    NUOVA EMAIL
                                </Button>
                            </Col>
                        </Row>
                        <NewTabella
                            idTabella="email"
                            columnC={false}
                            activeSaver={false}
                            searchPanel={false}
                            sync
                            colonne={columnsEmail}
                            dataSource={tabellaEmail}
                            exportT={{
                                enabled: false,
                                fileName: "list_email",
                            }}
                            paging={false}
                            height={240}
                            hidePager={true}
                        />

                        <Row>&nbsp;</Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Col>
                                <label> ELENCO TELEFONI </label>
                            </Col>
                            <Col>
                                <Button id='btnNuovoTelefono' className="float-right" variant="success" onClick={() => { if (idAnaIndirizzo == undefined || idAnaIndirizzo <= 0) { notify({ position: "center", width: "auto", message: 'Selezionare un indirizzo' }, "error", 4000); return } setObjtipo({ idAnaIndirizzi: idAnaIndirizzo, idAnaGenerale: objctTOsave.idAnaGenerale }); setOpenPopTelefoni(true) }} >
                                    NUOVO TELEFONO
                                </Button>
                            </Col>
                        </Row>
                        <NewTabella
                            idTabella="telefoni"
                            columnC={false}
                            activeSaver={false}
                            searchPanel={false}
                            sync
                            colonne={columnsTelefoni}
                            dataSource={tabellaTelefoni}
                            exportT={{
                                enabled: false,
                                fileName: "list_telefoni",
                            }}
                            paging={false}
                            height={240}
                            hidePager={true}
                        />
                    </ScrollView>
                </Item>

                <Item disabled={typeInsert}  title="Associazioni">

                    <div style={{ background: 'white', padding: 10, borderRadius: 10, }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div><h4 style={{ fontSize: 22, margin: 0 }}>Filtri ricerca {tagRicercaAssociazioni()}</h4></div>
                            <div><button style={{ border: 'none', background: 'none', fontSize: 20 }} onClick={() => setOpenA(!openA)}>{openA ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</button></div>
                        </div>
                        <div className={openA ? 'main' : 'mainOut'}>
                            <div className="col-md-10">
                                <div className='form-row'>
                                    <div className="form-group col-md-3">
                                        <label> RAGIONE SOCIALE </label>
                                        <TextBox showClearButton={true}  defaultValue={objctTOsave.ragioneSociale} onValueChanged={(v) => cambiaValoreAssociazioni('ragioneSociale', v.value)} placeholder="..." />
                                    </div>
                                    <div className="form-group col-md-2">
                                        <label> PARTITA IVA </label>
                                        <TextBox showClearButton={true} defaultValue={objctTOsave.partitaIva} onValueChanged={(v) => cambiaValoreAssociazioni('partitaIva', v.value)} placeholder="..." />
                                    </div>
                                </div>
                            </div>
                            <div className='posbtn'>
                                <Button id='btnCerca' className="btn btn-success" variant="success" onClick={() => { ricercaAssociazioni(edit, undefined, undefined) }} >
                                    CERCA
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                        <div className="col-md-12">
                            <Row>
                                <Col md="1">
                                    <div className='title2'>
                                        <h6> Legenda Colori: </h6>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className='legAb1'>
                                        <h6 >  Abbinato </h6>
                                    </div>
                                </Col >
                                <Col md="3">
                                    <div className='legAb2'>
                                        <h6> Non Abbinato </h6>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className='legAb3'>
                                        <h6> <strong>Abbinato ad un forn. centr. della lista </strong></h6>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </div>

                    <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                        <div className="col-md-12">
                            <Row>
                                <Col className="col-md-12 text-right" >
                                    <Button id='btnAssocia' className="btn btn-primary" variant="primary" onClick={() => { associa(edit.idAnaGenFornCen, selectedAssocizioni) }} >
                                        ASSOCIA SELEZIONATI
                                    </Button>
                                </Col>
                                {/* <Col className="col-md-2 text-right">
                                    <Button id='btnDissocia' className="btn btn-primary" variant="primary" onClick={() => { }} >
                                        DISSOCIA SELEZIONATI
                                    </Button>
                                </Col>*/}
                            </Row>
                        </div>

                    </div>

                    <NewTabella
                        idTabella="associazioni"
                        sync
                        colonne={columnsAssociazioni}
                        dataSource={tabellaAssociazioni}
                        filterRow
                        exportT={{
                            enabled: false,
                            fileName: "list_ass",
                        }}
                        pageSize={10}
                        height={450}
                        onRowPrepared={onRowPrepared}
                        multyp={true}
                        selectedValue={(datiRow: any) => cambiaIdFornSel(datiRow.selectedRowKeys)}
                    //selectedRowKeys={selectedRowKeys}
                    //keyExpr= 'idAnaGeneraleFornitore'
                    />

                </Item>

            </TabPanel>

            {openPopIndirizzo ?
                <DialogAnaFornCenIndirizzo
                    idAnaIndirizzi={objtipo?.idAnaIndirizzi}
                    open={openPopIndirizzo}
                    close={() => { setOpenPopIndirizzo(false); setObjtipo({}) }}
                    idAnaGenerale={objtipo?.idAnaGenerale}
                    closeAndReload={() => { setOpenPopIndirizzo(false); setObjtipo({}); getIndirizzi(edit) }}
                /> : null
            }

            {openPopEmail ?
                <DialogAnaFornCenEmail
                    idAnaRecapitiEmail={objtipo?.idAnaRecapitiEmail}
                    open={openPopEmail}
                    close={() => { setOpenPopEmail(false); setObjtipo({}) }}
                    idAnaGenerale={objtipo?.idAnaGenerale}
                    idAnaIndirizzi={objtipo?.idAnaIndirizzi}
                    closeAndReload={() => { setOpenPopEmail(false); setObjtipo({}); caricaDati(idAnaIndirizzo) }}

                /> : null
            }

            {openPopTelefoni ?
                <DialogAnaFornCenTelefono
                    idAnaRecapitiTelefono={objtipo?.idAnaRecapitiTelefono}
                    open={openPopTelefoni}
                    close={() => { setOpenPopTelefoni(false); setObjtipo({}) }}
                    idAnaGenerale={objtipo?.idAnaGenerale}
                    idAnaIndirizzi={objtipo?.idAnaIndirizzi}
                    closeAndReload={() => { setOpenPopTelefoni(false); setObjtipo({}); caricaDati(idAnaIndirizzo) }}
                /> : null
            }

            {openPopImporta ?
                <DialogAnaFornCenImporta
                    open={openPopImporta}
                    close={() => { setOpenPopImporta(false); }}
                    closeAndChoice={(e: any) => {

                        setOpenPopImporta(false);
                        if (daBancaDati) {
                            let objToImport = {
                                ragioneSociale: e.descrizione,
                                partitaIva: e.partitaIva,
                                citta: e.citta,
                                indirizzo: e.indirizzo,
                                cap: e.cap
                            }
                            setObjctTOsave(objToImport)
                        }

                        if (daEsistente) {
                            let objToImport = {
                                ragioneSociale: e.ragioneSocialeFornLoc,
                                partitaIva: e.partitaIvaFornLoc,
                            }
                            setObjctTOsave(objToImport)
                        }
                        

                        
                    }}
                    daBancaDati={ daBancaDati}
                    daEsistente={ daEsistente}
                /> : null
            }

        </>


    );
};

export default AnaFornitoreCentr