import React, { useState, useEffect, useRef } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { NumberBox } from 'devextreme-react/number-box'
import { SelectBox } from 'devextreme-react/select-box'
import { RestServices } from "../../../services/restServices"
import { urlCommon, urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import DateBox from 'devextreme-react/date-box'
import CheckBox from 'devextreme-react/check-box'
import { DialogConferma } from '../../../widget/Notifications'
import Button from 'react-bootstrap/Button'
import AsyncSelect from "react-select/async"


import './style.css';
import TextArea from 'devextreme-react/text-area'


interface DialogProps {
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
}

export const DialogModificaAnomalia: React.FC<DialogProps> = ({ open, close, ricarica, edit, typeEdit}, ref) => {


    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD, setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [popAsk, setPopAsk] = useState(false)
    const [objPostRicerca, setObjPostRicerca] = useState<any>({})

  useEffect(() => {
    if (edit) {
     
      
      let objTo = { ...edit };
      setObjctTOsave(objTo);
    }
  }, [edit, openD]);

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value
    
        setObjctTOsave(objTo)

        if (objTo.risolta && objTo.falsaAnomalia) {
            setDisableButton(true)
        } else if (((objTo.falsaAnomalia || objTo.risolta) && !objTo.descrizioneRisoluzione) || (objTo.ignoraStessoTipoAnomalia && !objTo.dataFineIgnoraAnomalia) || (!objTo.ignoraStessoTipoAnomalia && objTo.dataFineIgnoraAnomalia)) {
            setDisableButton(true)
        } else {
            setDisableButton(false)
        }


    }

    const creaModificaAnomalia = (modifica?: boolean) => {
        //objctTOsave.falsaAnomalia = objctTOsave.operatore
        //objctTOsave.ignoraStessoTipoAnomalia = objctTOsave.nome
        objctTOsave.dataFineIgnoraAnomalia = objctTOsave.dataFineIgnoraAnomalia
        objctTOsave.descrizioneRisoluzione = objctTOsave.descrizioneRisoluzione

        if (objctTOsave.codTPH) {
            delete objctTOsave.codTPH
        }

        if (objctTOsave.usernameRisoluzione) {
            delete objctTOsave.usernameRisoluzione
        }

        if (objctTOsave.descrizioneFarmacia) {
            delete objctTOsave.descrizioneFarmacia
        }

        if (objctTOsave.anomaliaCodificata) {
            delete objctTOsave.anomaliaCodificata
        }

        if (objctTOsave.risolta) {
            delete objctTOsave.risolta
        }

        if (objctTOsave.chiusoAutomaticamente) {
            delete objctTOsave.chiusoAutomaticamente
        }

        if (objctTOsave.dataPrimaRilevazione) {
            delete objctTOsave.dataPrimaRilevazione
        }

        if (objctTOsave.dataUltimaRilevazione) {
            delete objctTOsave.dataUltimaRilevazione
        }

        if (objctTOsave.descrizioneAnomalia) {
            delete objctTOsave.descrizioneAnomalia
        }

        if (objctTOsave.nomeTipoAnomalia) {
            delete objctTOsave.nomeTipoAnomalia
        }

        if (objctTOsave.idFar) {
            delete objctTOsave.idFar
        }

        if (objctTOsave.idUtenteRisoluzione) {
            delete objctTOsave.idUtenteRisoluzione
        }

        if (objctTOsave.idTipoAnomalia) {
            delete objctTOsave.idTipoAnomalia
        }

        if (objctTOsave.dataRisoluzione) {
            delete objctTOsave.dataRisoluzione
        }

        if (objctTOsave.nota) {
            delete objctTOsave.nota
        }

        if (objctTOsave.codice) {
            delete objctTOsave.codice
        }

        if (auth) {
            if (objctTOsave.ignoraStessoTipoAnomalia) {
                objctTOsave.ignoraStessoTipoAnomalia = 1
            } else {
                objctTOsave.ignoraStessoTipoAnomalia = 0
            }

            if (objctTOsave.falsaAnomalia) {
                objctTOsave.falsaAnomalia = 1
            } else {
                objctTOsave.falsaAnomalia = 0
            }

            RestServices().rest('POST', objctTOsave, urlCommon.updateAnomalia)
                .then(result => {
                    if (result.esito) {
                        ricarica()
                        notify({ position: "center", width: "auto", message: "Anomalia risolta con successo." }, "success", 4000)
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                    }
                })

            setPopAsk(false);
            setOpenD(false);
        }
    }


    useEffect(() => {
    }, [auth]);


    useEffect(() => {
        setOpenD(open)
    }, [open])

    return (
        <>
            <Popup
                visible={openD}
                showCloseButton={false}
                width={'60%'}
                height={'80%'}
                showTitle={true}
                title="Gestione Anomalia"
            >

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Chiudi',
                        onClick: () => {   
                            close()                     
                        }
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={typeEdit ? {
                        text: 'Salva',
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        disabled: disableButton,
                        onClick: () => {
                            creaModificaAnomalia(true)                     
                        }
                    } : null}
                />


                {

                    <ScrollView width='100%' height='100%'>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group col-md-6">
                                    <label>RISOLTA</label>
                                    <div style={{ paddingLeft: "2px" }}>
                                        <CheckBox value={objctTOsave?.risolta == 1 ? true : false} onValueChanged={(v) => cambiaValore('risolta', v.value)} />
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>FALSA ANOMALIA</label>
                                    <div style={{ paddingLeft: "2px" }}>
                                        <CheckBox value={objctTOsave?.falsaAnomalia == 1 ? true : false} onValueChanged={(v) => cambiaValore('falsaAnomalia', v.value)} />
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>IGNORA STESSO TIPO ANOMALIA</label>
                                    <div style={{ paddingLeft: "2px" }}>
                                        <CheckBox value={objctTOsave?.ignoraStessoTipoAnomalia == 1 ? true : false} onValueChanged={(v) => cambiaValore('ignoraStessoTipoAnomalia', v.value)} />
                                    </div>
                                </div>
                            </div>                       
                            <div className="col-md-6" style={{ display: "flex", flexDirection: "column" }}>
                                <div className="form-group col-md-6">
                                    <label>DESCRIZIONE RISOLUZIONE</label>
                                    <TextArea valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.descrizioneRisoluzione}
                                        onValueChanged={(v) => cambiaValore('descrizioneRisoluzione', v.value)} placeholder="..."
                                        autoResizeEnabled={true} />
                                </div>
                                <div style={{ marginTop: '18px' }} className="form-group col-md-6">
                                    <label>DATA FINE IGNORA ANOMALIA</label>
                                    <DateBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave && objctTOsave.dataFineIgnoraAnomalia ? objctTOsave.dataFineIgnoraAnomalia : null}
                                        onValueChanged={(v) => cambiaValore('dataFineIgnoraAnomalia', v.value)} type="date" displayFormat="dd/MM/yyyy" />
                                </div>
                            </div>              
                        </div>
                    </ScrollView>}
            </Popup>
        </>
    )
}
