import { DataGrid } from 'devextreme-react'
import { Pager, Paging, Sorting } from 'devextreme-react/data-grid'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { cambiaFormatoValuta, parseAsMoney } from '../../funzioni/formatoVariabili'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle'
import { GiacenzaDettInterface, OrdineLiberoQuantitaInterface } from '../../interfaces/bancaDati'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { OggettoInterface } from '../../interfaces/generiche'
import { DatiTabelleGiacenzeInterface, DatiVendutoInterface } from '../../interfaces/ordineLibero'
import { RestServices } from '../../services/restServices'
import { urlB2B, urlConsultazione, urlOrdiniLiberi } from '../costanti'
import { DialogMovimenti } from './DialogMovimenti'


const CreaPannelloInfo = (props: {
    listaFarmacie: number[],
    giacenzeGruppo: GiacenzaDettInterface[],
    datiProdotto?: OggettoInterface,
    setIsLoadVisible: (isLoadVisible: boolean) => void,
    quantitaSelezionata: string | undefined,
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    flagVisualizzaVendutoGiacenza: boolean | undefined,
    flagVisualizzaVendutoGiacenzaTotale: boolean | undefined,
    flagVisualizzaUltimiAcquisti: boolean | undefined,
    flagVisualizzaListiniOfferte: boolean | undefined,
    flagVisualizzaTrasferimenti: boolean | undefined,
    flagVisualizzaIdentici: boolean | undefined,
    flagVisualizzaSimili: boolean | undefined,
    flagVisualizzaListiniOfferteIdentici: boolean | undefined,
    idOrdine: string | number | undefined,
    flagVisualizzaListiniOrdineLibero: boolean | undefined,
    idFornitore: number | undefined,
    flagOrdineB2B: boolean | undefined,
}) => {
    const [giacenzaTotale, setGiacenzaTotale] = useState(0);
    //const [quantitaTotaleInOrdine, setQuantitaTotaleInOrdine] = useState(0);
    const [giacenzaFarmacie, setGiacenzaFarmacie] = useState(0);
    const [vendutoTotale, setVendutoTotale] = useState(0);
    const [vendutoMedio, setVendutoMedio] = useState(0);
    const [vendutoTotalePrec, setVendutoTotalePrec] = useState(0);
    const [datiTabelleGiacenze, setDatiTabelleGiacenze] = useState<DatiVendutoInterface[]>();
    const [mese, setMese] = useState("");
    const [tabellaEvidenziata, setTabellaEvidenziata] = useState<string>();
    const [tabellaUltimiAcquisti, setTabellaUltimiAcquisti] = useState<string>();
    const [tabellaListiniOfferte, setTabellaListiniOfferte] = useState<string>();
    const [tabellaListiniOrdineLibero, setTabellaListiniOrdineLibero] = useState<string>();
    const [tabellaListiniOfferteIdentici, setTabellaListiniOfferteIdentici] = useState<string>();
    const [tabellaIdentici, setTabellaIdentici] = useState<string>();
    const [tabellaSimili, setTabellaSimili] = useState<string>();
    const [passato, setPassato] = useState<boolean>(false);
    const [openPopMovimenti, setOpenPopMovimenti] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null)
    const [totChiamate, setTotChiamate] = useState<number>(0);
    //const [quantitaInOrdine, setQuantitaInOrdine] = useState<OrdineLiberoQuantitaInterface[]>([]);
    //const [quantitaTotaleInOrdine, setQuantitaTotaleInOrdine] = useState<number>();

    const [numeroChiamateInfoTerminate, setNumeroChiamateInfoTerminate] = useState<number>(0);

    const handleCloseVendite = () => setOpenPopMovimenti(false);

    useEffect(() => {
        const meseNumero = new Date().getMonth();
        const elencoMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
        setMese(elencoMesi[meseNumero]);
        //console.log(props.flagVisualizzaVendutoGiacenza)
        //console.log(props.flagVisualizzaVendutoGiacenzaTotale)

        let tot = 0;

        if (props.flagVisualizzaUltimiAcquisti != null && Boolean(props.flagVisualizzaUltimiAcquisti) === true)
            tot += 1;

        if (props.flagVisualizzaListiniOfferte != null && Boolean(props.flagVisualizzaListiniOfferte) === true)
            tot += 1;

        if (props.flagVisualizzaListiniOfferteIdentici != null && Boolean(props.flagVisualizzaListiniOfferteIdentici) === true)
            tot += 1;

        if (props.flagVisualizzaIdentici != null && Boolean(props.flagVisualizzaIdentici) === true) 
            tot += 1;

        if (props.flagVisualizzaSimili != null && Boolean(props.flagVisualizzaSimili) === true)
            tot += 1;

        if (props.flagVisualizzaListiniOrdineLibero != null && Boolean(props.flagVisualizzaListiniOrdineLibero) === true && props.idFornitore)
            tot += 1;

        setTotChiamate(tot)

    }, [])

    useEffect(() => {
        //console.log(props)
        let chiamateInfo = 0;
        let sommaGiacenza = 0;
        let sommaGiacenzeFarmacie = 0;
        if (props.giacenzeGruppo.length > 0) {
            //console.log(props.giacenzeGruppo)
            for (let i = 0; i < props.giacenzeGruppo.length; i++) {
                const giacVend = props.giacenzeGruppo[i].giacenzaVendibili;
                const qtaInOrdine = props.giacenzeGruppo[i].qtaInOrdine;
                //console.log(giacVend)
                if (props.giacenzeGruppo[i].idFar != 0)
                    sommaGiacenza += giacVend;
                if (props.listaFarmacie) {
                    props.listaFarmacie.forEach(farm => {
                        if (props.giacenzeGruppo[i].idFar === farm) {
                            sommaGiacenzeFarmacie += props.giacenzeGruppo[i].giacenzaVendibili;
                        }
                    })
                }
            }
            setGiacenzaTotale(sommaGiacenza);
            setGiacenzaFarmacie(sommaGiacenzeFarmacie);
        }

        //se flagvisualizzagiacenzacendavenduto � abilitato allora prende tutte le farmacie del gruppo altrimenti solo quelle dell'ordine
        let idFar: any[] = [];
        if (Boolean(props.flagVisualizzaVendutoGiacenza) === true) {
            props.datiFarmacie?.forEach(function (value) {
                idFar.push(value.ID_FAR);
            });

        }
        else
            idFar = props.listaFarmacie

        if (props.flagVisualizzaUltimiAcquisti != null && Boolean(props.flagVisualizzaUltimiAcquisti) === true) {
            RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, idFar: idFar, top: 10, flagTrasferito: Boolean(props.flagVisualizzaTrasferimenti) }, urlConsultazione.getListMagazCarichi)
                .then(result => {
                    if (result?.esito) {

                        chiamateInfo = chiamateInfo +1
                        setTabellaUltimiAcquisti(result.response)
                        setNumeroChiamateInfoTerminate(chiamateInfo )
                        //props.setIsLoadVisible(false);
                    } else {
                        //props.setIsLoadVisible(false);
                    }
                })

        }

        if (props.flagVisualizzaListiniOfferte != null && Boolean(props.flagVisualizzaListiniOfferte) === true) {
            props.setIsLoadVisible(true);
            RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, flagIdentici:false}, urlConsultazione.getListiniOfferte)
                .then(result => {
                    if (result?.esito) {


                        setTabellaListiniOfferte(result.response)
                        chiamateInfo = chiamateInfo + 1
                        setNumeroChiamateInfoTerminate(chiamateInfo)
                        //props.setIsLoadVisible(false);
                    } else {
                        //props.setIsLoadVisible(false);
                    }
                })

        }

        if (props.flagVisualizzaListiniOfferteIdentici != null && Boolean(props.flagVisualizzaListiniOfferteIdentici) === true) {
            props.setIsLoadVisible(true);
            var far;
            if (Boolean(props.flagVisualizzaVendutoGiacenza))
                far = props.datiFarmacie?.map(({ ID_FAR }) => ID_FAR);
            else
                far = props.listaFarmacie;
            RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, flagIdentici: true, idFar: far }, urlConsultazione.getListiniOfferte)
                .then(result => {
                    if (result?.esito) {


                        setTabellaListiniOfferteIdentici(result.response)
                        chiamateInfo = chiamateInfo + 1
                        setNumeroChiamateInfoTerminate(chiamateInfo)
                        //props.setIsLoadVisible(false);
                    } else {
                        //props.setIsLoadVisible(false);
                    }
                })

        }

        if (props.flagVisualizzaIdentici != null && Boolean(props.flagVisualizzaIdentici) === true) {
            props.setIsLoadVisible(true);
            var far;
            if (Boolean(props.flagVisualizzaVendutoGiacenza))
                far = props.datiFarmacie?.map(({ ID_FAR }) => ID_FAR);
            else
                far = props.listaFarmacie;
            RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, idFar: far }, urlOrdiniLiberi.getListIdentici)
                .then(result => {
                    if (result) {
                        setTabellaIdentici(result)
                        chiamateInfo = chiamateInfo + 1
                        setNumeroChiamateInfoTerminate(chiamateInfo)
                        //props.setIsLoadVisible(false);
                    } else {
                        //props.setIsLoadVisible(false);
                    }
                })

        }

        if (props.flagVisualizzaSimili != null && Boolean(props.flagVisualizzaSimili) === true) {
            props.setIsLoadVisible(true);
            var far;
            if (Boolean(props.flagVisualizzaVendutoGiacenza))
                far = props.datiFarmacie?.map(({ ID_FAR }) => ID_FAR);
            else
                far = props.listaFarmacie;
            RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, idFar: far }, urlOrdiniLiberi.getListSimili)
                .then(result => {
                    if (result) {
                        setTabellaSimili(result)
                        chiamateInfo = chiamateInfo + 1
                        setNumeroChiamateInfoTerminate(chiamateInfo)
                        //props.setIsLoadVisible(false);
                    } else {
                        //props.setIsLoadVisible(false);
                    }
                })

        }

        if (props.flagVisualizzaListiniOrdineLibero != null && Boolean(props.flagVisualizzaListiniOrdineLibero) === true && props.idFornitore) {
            props.setIsLoadVisible(true);
            if (props.flagOrdineB2B == undefined || props.flagOrdineB2B == false) {
                RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, idFornitore: props.idFornitore }, urlOrdiniLiberi.getListinoProdottoDettaglio)
                    .then(result => {
                        if (result?.esito) {

                            setTabellaListiniOrdineLibero(result.response)
                            chiamateInfo = chiamateInfo + 1
                            setNumeroChiamateInfoTerminate(chiamateInfo)
                            //props.setIsLoadVisible(false);
                        } else {
                            //props.setIsLoadVisible(false);
                            setTabellaListiniOrdineLibero(undefined)
                        }
                    })
            } else if (props.flagOrdineB2B != undefined || props.flagOrdineB2B == true) {
                //listino ordine b2b
                RestServices().rest('POST', { codCsf: props.datiProdotto?.codCsf, idFornitore: props.idFornitore, lstFar: props.listaFarmacie }, urlB2B.getListinoProdottoDettaglio)
                    .then(result => {
                        if (result?.esito) {

                            setTabellaListiniOrdineLibero(result.response)
                            chiamateInfo = chiamateInfo + 1
                            setNumeroChiamateInfoTerminate(chiamateInfo)
                            //props.setIsLoadVisible(false);
                        } else {
                            //props.setIsLoadVisible(false);
                            setTabellaListiniOrdineLibero(undefined)
                        }
                    })
            }
            

        }

    }, [props.giacenzeGruppo, props.listaFarmacie, props.datiProdotto])

    useEffect(() => {
        //console.log("numeroChiamateInfoTerminate")
        //console.log(numeroChiamateInfoTerminate)
        if (numeroChiamateInfoTerminate === totChiamate) {
            //console.log(numeroChiamateInfoTerminate)
            //console.log(totChiamate)
            props.setIsLoadVisible(false);
        }
            

    }, [numeroChiamateInfoTerminate])



    //useEffect(() => {
    //    let sommaQuantitaInOrdine = 0;
    //    if (props.quantitaInOrdine.length > 0) {
    //        //console.log(props.quantitaInOrdine)
    //        for (let i = 0; i < props.quantitaInOrdine.length; i++) {
    //            const qtaInOrdine = props.quantitaInOrdine[i].quantita != undefined ? props.quantitaInOrdine[i].quantita : 0;
    //            sommaQuantitaInOrdine += qtaInOrdine;
    //        }

    //        setQuantitaTotaleInOrdine(sommaQuantitaInOrdine);
    //    }
    //}, [props.quantitaInOrdine, props.listaFarmacie])

    useEffect(() => {
        if (props.listaFarmacie && props.datiProdotto) {
            props.setIsLoadVisible(true);
            var far;
            if (Boolean(props.flagVisualizzaVendutoGiacenza))
                far = props.datiFarmacie?.map(({ ID_FAR }) => ID_FAR);
            else
                far = props.listaFarmacie;
            const param = {
                //lstFar: props.listaFarmacie,
                lstFar: far,
                codCsf: props.datiProdotto.codCsf,
                idOrdLib: props.idOrdine
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.getVenduto)
                .then(result => {
                    if (result) {
                        let sommaVenduto: number = 0;
                        let sommaVendutoGruppo: number = 0;
                        let sommaVendutoPrec: number = 0;
                        for (let i = 0; i < result.length; i++) {
                            //console.log(result[i]);
                            if (!result[i].magazzino && result[i].venduto[0]) {
                                sommaVendutoGruppo += result[i].venduto[0].TOT
                            }
                            if (!result[i].magazzino && result[i].venduto[0] && props.listaFarmacie.includes(result[i].idFar)) {
                                sommaVenduto += result[i].venduto[0].TOT;
                            }
                            if (!result[i].magazzino && result[i].venduto[1] && props.listaFarmacie.includes(result[i].idFar)) {
                                sommaVendutoPrec += result[i].venduto[1].TOT;
                            }
                        }
                        setDatiTabelleGiacenze(result);
                        setVendutoTotale(sommaVenduto);
                        setVendutoTotalePrec(sommaVendutoPrec);
                        var now: any = new Date();
                        var start: any = new Date(now.getFullYear(), 0, 0);
                        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diff / oneDay);
                        if (sommaVendutoGruppo > 0)
                            setVendutoMedio(Math.round((sommaVendutoGruppo / day) * 30));
                        else
                            setVendutoMedio(0);
                        props.setIsLoadVisible(false);
                    } else {
                        props.setIsLoadVisible(false);
                    }
                })


            //RestServices().rest('POST', param, urlOrdiniLiberi.getQuantitaOrdini).then(response => {
            //    //props.setIsLoadVisible(true);
            //    if (response) {
            //        if (response.quantitaTotale)
            //            setQuantitaTotaleInOrdine(response.quantitaTotale);
            //        else
            //            setQuantitaTotaleInOrdine(0);

            //        if (response.lstFarQuantita instanceof Array) {
            //            setQuantitaInOrdine(response.lstFarQuantita);
            //        }
            //        props.setIsLoadVisible(false);
            //    }

            //})



        }
    }, [props.listaFarmacie, props.datiProdotto])

    useEffect(() => {
        if (props.quantitaSelezionata) setTabellaEvidenziata(props.quantitaSelezionata)
    }, [props.quantitaSelezionata])

    const colonneTabUltimiAcquisti = useMemo(() => CreaColonne([
        { caption: "Cod.Far.", dataField: "codiceFarmacia", width: 40 },
        { caption: "Data DDT", dataField: "dataDDT", format: "dd/MM/yyyy", dataType: "date", width: 70 },
        { caption: "Num. DDT", dataField: "numeroDDT" },
        { caption: "Fornitore", dataField: "ragioneSocialeFornitore" },
        { caption: "Qta", dataField: "quantita" },
        { caption: "Qta Omaggio", dataField: "quantitaOmaggio" },
        { caption: "Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Costo Reale", dataField: "costoReale", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Sct.", dataField: "scosto", format: { type: "currency", precision: 2, currency: "EUR" }, width: 30 },
        { caption: "Sct. Reale", dataField: "scostoReale", format: { type: "currency", precision: 2, currency: "EUR" }, width: 30 },

    ]), [])

    const colonneTabIdentici = useMemo(() => CreaColonne([
        { caption: "Minsan", dataField: "codProd"},
        { caption: "Des.one", dataField: "descrizioneRidotta" },
        { caption: "Ditta", dataField: "ditta" },
        { caption: "Pzo.", dataField: "prezzoPubblico", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Gia.za Tot.", dataField: "giacenza", sortOrder: "desc" },
        { caption: "Ult. cto.", dataField: "ultimoCosto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Cod.Far.", dataField: "codiceFarmacia" },
        {
            caption: "", width: 50, type: "buttons", buttons: [
                {
                    hint: "Vendite",
                    icon: "chart",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        setOpenPopMovimenti(true)

                    }
                },
            ]
        }

    ]), [])

    const colonneTabListiniOfferte = useMemo(() => CreaColonne([
        { caption: "Fornitore", dataField: "fornitore", width: 100 },
        { caption: "Tipo", dataField: "tipoRecord" },
        { caption: "Qta. Minima", dataField: "quantitaMinima" },
        { caption: "Pzo. Rif.to", dataField: "prezzoBaseRiferimento", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Dt.Inizio", dataField: "dataInizio", format: "dd/MM/yyyy", dataType: "date", width: 70 },
        { caption: "Dt.Fine", dataField: "dataFine", format: "dd/MM/yyyy", dataType: "date", width: 70 }

    ]), [])

    const colonneTabListiniOrdineLibero = useMemo(() => CreaColonne([
        { caption: "Fornitore", dataField: "fornitore" },
        { caption: "Qta. Minima", dataField: "quantitaMinima" },
        { caption: "Qta. Massima", dataField: "quantitaMassima" },
        { caption: "Costo", dataField: "imponibile", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Dt.Inizio", dataField: "dataInizio", format: "dd/MM/yyyy", dataType: "date", width: 70 },
        { caption: "Dt.Fine", dataField: "dataFine", format: "dd/MM/yyyy", dataType: "date", width: 70 }

    ]), [])

    const colonneTabListiniOfferteIdentici = useMemo(() => CreaColonne([
        { caption: "Minsan", dataField: "codProd" },
        //{ caption: "Des.one", dataField: "descrizioneRidotta", width: 50 },
        //{ caption: "Ditta", dataField: "ditta", width: 50 },
        { caption: "Fornitore", dataField: "fornitore" },
        { caption: "Tipo", dataField: "tipoRecord", width: 20 },
        { caption: "Qta. Minima", dataField: "quantitaMinima", width: 20 },
        { caption: "Pzo. Rif.to", dataField: "prezzoBaseRiferimento", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 50 },
        { caption: "Dt.Inizio", dataField: "dataInizio", format: "dd/MM/yyyy", dataType: "date", width: 70 },
        { caption: "Dt.Fine", dataField: "dataFine", format: "dd/MM/yyyy", dataType: "date", width: 70 }

    ]), [])


    return (
        <>
            {/* 
            <DialogVendite
                dialogVenditeIsOpen={openPopMovimenti}
                edit={objtipo}
                handleCloseVendite={handleCloseVendite}
            />
           */ }
            <DialogMovimenti
                open={openPopMovimenti}
                edit={objtipo}
                close={handleCloseVendite}
            />

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>
                    
                    {Boolean(props.flagVisualizzaVendutoGiacenzaTotale) === true && datiTabelleGiacenze.map((giacenza: DatiVendutoInterface, index: number) => {

                        if (giacenza.idFar === 0 && giacenza.venduto && giacenza.venduto.length > 0)
                            //if (passato === false) {
                            //    setPassato(true)
                            return (
                                <div style={{ paddingTop: 20 }}>
                                <Col sm="12" key={giacenza.idFar} className="colonnaTabVenduto">
                                    <Tabelle
                                        giacenza={props.giacenzeGruppo}
                                        datiTabelleGiacenze={giacenza}
                                        index={index}
                                        mese={mese}
                                        tabellaEvidenziata={tabellaEvidenziata}
                                    />
                                    </Col>
                                </div>
                            )
                        //}
                    })
                }

                </>
                
            }

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>

                    {Boolean(props.flagVisualizzaUltimiAcquisti) === true && 
                        <>
                    <Col sm="12" className="colonnaTabVenduto">
                        <div style={{ marginLeft: 15, paddingTop: 10 }}>
                            <div className="labelTabella" style={{ "backgroundColor": "rgb(150, 255, 164)", "color": "black" }}><b>Ultimi acquisti</b></div>
                            <DataGrid
                                id={'ultimiAcquisti'}
                                showBorders={true}
                                showRowLines={true}
                                hoverStateEnabled={true}
                                dataSource={tabellaUltimiAcquisti}
                            >
                                {colonneTabUltimiAcquisti}
                            </DataGrid>

                        </div>
                    </Col>

                        </>
                }
                </>
            }

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>

                    {Boolean(props.flagVisualizzaListiniOfferte) === true &&
                        <>
                            <Col sm="12" className="colonnaTabVenduto">
                                <div style={{ marginLeft: 15, paddingTop: 10 }}>
                                    <div className="labelTabella" style={{ "backgroundColor": "rgb(150, 255, 164)", "color": "black" }}><b>Listini e Offerte</b></div>
                                    <DataGrid
                                        id={'listiniOfferte'}
                                        showBorders={true}
                                        showRowLines={true}
                                        hoverStateEnabled={true}
                                        dataSource={tabellaListiniOfferte}
                                    >
                                        {colonneTabListiniOfferte}
                                    </DataGrid>

                                </div>
                            </Col>
                        </>
                    }

                </>
            }

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>

                    {Boolean(props.flagVisualizzaListiniOrdineLibero) === true &&
                        <>
                            <Col sm="12" className="colonnaTabVenduto">
                                <div style={{ marginLeft: 15, paddingTop: 10 }}>
                            <div className="labelTabella" style={{ "backgroundColor": "rgb(150, 255, 164)", "color": "black" }}><b>{props.flagOrdineB2B == undefined || props.flagOrdineB2B == false ? "Listino fornitore ordine libero" : "Listino fornitore ordine B2B" }</b></div>
                                    <DataGrid
                                        id={'listiniOrdineLibero'}
                                        showBorders={true}
                                        showRowLines={true}
                                        hoverStateEnabled={true}
                                        dataSource={tabellaListiniOrdineLibero}
                                    >
                                        {colonneTabListiniOrdineLibero}
                                    </DataGrid>

                                </div>
                            </Col>
                        </>
                    }

                </>
            }

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>

                    {Boolean(props.flagVisualizzaListiniOfferteIdentici) === true &&
                        <>
                            <Col sm="12" className="colonnaTabVenduto">
                                <div style={{ marginLeft: 15, paddingTop: 10 }}>
                                <div className="labelTabella" style={{ "backgroundColor": "rgb(62, 146, 255)", "color": "black" }}><b>Listini e Offerte prodotti identici</b></div>
                                    <DataGrid
                                        id={'listiniOfferteIdentici'}
                                        showBorders={true}
                                        showRowLines={true}
                                        hoverStateEnabled={true}
                                        dataSource={tabellaListiniOfferteIdentici}
                            >
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={10} />
                                <Pager
                                    visible={true}
                                    allowedPageSizes={[10]}
                                    showPageSizeSelector={false}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                    {colonneTabListiniOfferteIdentici}
                                    </DataGrid>

                                </div>
                            </Col>
                        </>
                    }

                </>
            }

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>

                    {Boolean(props.flagVisualizzaIdentici) === true &&
                        <>
                            <Col sm="12" className="colonnaTabVenduto">
                                <div style={{ marginLeft: 15, paddingTop: 10 }}>
                                    <div className="labelTabella" style={{ "backgroundColor": "rgb(62, 146, 255)", "color": "black" }}><b>Prodotti identici (Importazione parallela)</b></div>
                                    <DataGrid
                                id={'prodottiIdentici'}
                                showBorders={true}
                                showRowLines={true}
                                hoverStateEnabled={true}
                                dataSource={tabellaIdentici}
                            >
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={10} />
                                <Pager
                                    visible={true}
                                    allowedPageSizes={[10]}
                                    showPageSizeSelector={false}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                        {colonneTabIdentici}
                                    </DataGrid>

                                </div>
                            </Col>
                        </>
                    }

                </>
            }

            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>

                    {Boolean(props.flagVisualizzaSimili) === true &&
                        <>
                            <Col sm="12" className="colonnaTabVenduto">
                                <div style={{ marginLeft: 15, paddingTop: 10 }}>
                                    <div className="labelTabella" style={{ "backgroundColor": "rgb(62, 146, 255)", "color": "black" }}><b>Prodotti simili</b></div>
                                    <DataGrid
                                        id={'prodottiSimili'}
                                        showBorders={true}
                                        showRowLines={true}
                                        hoverStateEnabled={true}
                                        dataSource={tabellaSimili}
                                        
                            >
                                <Sorting mode="multiple" />
                                <Paging defaultPageSize={10} />
                                <Pager
                                    visible={true}
                                    allowedPageSizes={[10]}
                                    showPageSizeSelector={false}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                        {colonneTabIdentici}
                                    </DataGrid>

                                </div>
                            </Col>
                        </>
                    }

                </>
            }

        </>
    )
}

export default CreaPannelloInfo;

const Tabelle = (props: {
    giacenza: GiacenzaDettInterface[],
    datiTabelleGiacenze: DatiVendutoInterface,
    index: number,
    mese: string,
    tabellaEvidenziata: string | undefined,
    //quantita: OrdineLiberoQuantitaInterface[],
}) => {
    const [datiGiacenzaFarm, setDatiGiacenzaFarm] = useState<GiacenzaDettInterface>();
    const [datiGriglia, setDatiGriglia] = useState<DatiTabelleGiacenzeInterface[]>();
    const [color, setColor] = useState("");
    const [coloreScritta, setColoreScritta] = useState("black");

    useEffect(() => {
        if (props.giacenza.length > 0 && props.datiTabelleGiacenze)
            for (let i = 0; i < props.giacenza.length; i++) {
                if (props.giacenza[i].idFar === props.datiTabelleGiacenze.idFar) {
                    setDatiGiacenzaFarm(props.giacenza[i])
                }
            }
    }, [props.giacenza])

    useEffect(() => {
        setDatiGriglia(props.datiTabelleGiacenze.venduto)
    }, [props.datiTabelleGiacenze])

    const colonneTabGiacenza = useMemo(() => CreaColonne([
        { caption: "", dataField: "ANNO", width: 35 },
        { caption: "Tot", dataField: "TOT", width: 35 },
        { caption: "Gen", dataField: "Gennaio", },
        { caption: "Feb", dataField: "Febbraio", },
        { caption: "Mar", dataField: "Marzo", },
        { caption: "Apr", dataField: "Aprile", },
        { caption: "Mag", dataField: "Maggio", },
        { caption: "Giu", dataField: "Giugno", },
        { caption: "Lug", dataField: "Luglio", },
        { caption: "Ago", dataField: "Agosto", },
        { caption: "Set", dataField: "Settembre", },
        { caption: "Ott", dataField: "Ottobre", },
        { caption: "Nov", dataField: "Novembre", },
        { caption: "Dic", dataField: "Dicembre", }
    ]), [])



    useEffect(() => {

        if (!props.tabellaEvidenziata || (props.tabellaEvidenziata && (props.tabellaEvidenziata !== props.datiTabelleGiacenze.idFar.toString() || props.tabellaEvidenziata === " "))) {
            if (props.index % 2 === 0) {
                setColor("lightgoldenrodyellow")
                setColoreScritta("black");
            } else {
                setColor("paleturquoise");
                setColoreScritta("black");
            }
        } else {
            if (props.tabellaEvidenziata === props.datiTabelleGiacenze.idFar.toString()) {
                setColor("red");
                setColoreScritta("white");
            }
        }
    }, [props.index, props.tabellaEvidenziata])

    const onCellPrepared = useCallback((e: any) => {
        e.cellElement.style.fontWeight = "900";
        if (e.column.dataField === props.mese) {
            e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
        }
    }, [])

    //{datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)} - Qta In Ordine : {parseAsMoney(props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar).length > 0 ? props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar)[0].quantita : 0)}</b></div>}
    return (
        <>
            <div style={{ marginLeft:15 }}>
                {datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": datiGiacenzaFarm.idFar === 0 ? "rgb(150, 255, 164)" : color, "color": coloreScritta }}><b>{datiGiacenzaFarm.idFar == 8847 ? 'NOX - Mag. [ECommerce]' : datiGiacenzaFarm.descrFarm} - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)}</b></div>}
                {datiGriglia && <DataGrid
                    id={props.datiTabelleGiacenze?.idFar.toString()}
                    showBorders={true}
                    showRowLines={true}
                    hoverStateEnabled={true}
                    dataSource={datiGriglia}
                    onCellPrepared={onCellPrepared}
                >
                    {colonneTabGiacenza}
                </DataGrid>
                }
            </div>
        </>
    )
}

const TabelleTrasferito = (props: {
    giacenza: GiacenzaDettInterface[],
    datiTabelleGiacenze: DatiVendutoInterface,
    index: number,
    mese: string,
    tabellaEvidenziata: string | undefined,
    //quantita: OrdineLiberoQuantitaInterface[],
}) => {
    const [datiGiacenzaFarm, setDatiGiacenzaFarm] = useState<GiacenzaDettInterface>();
    const [datiGrigliaTrasferito, setDatiGrigliaTrasferito] = useState<DatiTabelleGiacenzeInterface[]>();
    const [color, setColor] = useState("");
    const [coloreScritta, setColoreScritta] = useState("black");

    useEffect(() => {
        if (props.giacenza.length > 0 && props.datiTabelleGiacenze)
            for (let i = 0; i < props.giacenza.length; i++) {
                if (props.giacenza[i].idFar === props.datiTabelleGiacenze.idFar) {
                    setDatiGiacenzaFarm(props.giacenza[i])
                }
            }
    }, [props.giacenza])

    useEffect(() => {
        setDatiGrigliaTrasferito(props.datiTabelleGiacenze.trasferito)
    }, [props.datiTabelleGiacenze])

    const colonneTabGiacenza = useMemo(() => CreaColonne([
        { caption: "", dataField: "ANNO", width: 35 },
        { caption: "Tot", dataField: "TOT", width: 35 },
        { caption: "Gen", dataField: "Gennaio", },
        { caption: "Feb", dataField: "Febbraio", },
        { caption: "Mar", dataField: "Marzo", },
        { caption: "Apr", dataField: "Aprile", },
        { caption: "Mag", dataField: "Maggio", },
        { caption: "Giu", dataField: "Giugno", },
        { caption: "Lug", dataField: "Luglio", },
        { caption: "Ago", dataField: "Agosto", },
        { caption: "Set", dataField: "Settembre", },
        { caption: "Ott", dataField: "Ottobre", },
        { caption: "Nov", dataField: "Novembre", },
        { caption: "Dic", dataField: "Dicembre", }
    ]), [])

    useEffect(() => {

        if (!props.tabellaEvidenziata || (props.tabellaEvidenziata && (props.tabellaEvidenziata !== props.datiTabelleGiacenze.idFar.toString() || props.tabellaEvidenziata === " "))) {
            if (props.index % 2 === 0) {
                setColor("lightgoldenrodyellow")
                setColoreScritta("black");
            } else {
                setColor("paleturquoise");
                setColoreScritta("black");
            }
        } else {
            if (props.tabellaEvidenziata === props.datiTabelleGiacenze.idFar.toString()) {
                setColor("red");
                setColoreScritta("white");
            }
        }
    }, [props.index, props.tabellaEvidenziata])

    const onCellPrepared = useCallback((e: any) => {
        e.cellElement.style.fontWeight = "900";
        if (e.column.dataField === props.mese) {
            e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
        }
    }, [])

    //{datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)} - Qta In Ordine : {parseAsMoney(props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar).length > 0 ? props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar)[0].quantita : 0)}</b></div>}
    return (
        <>

            {(datiGrigliaTrasferito && datiGrigliaTrasferito.length && datiGiacenzaFarm) && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} [Trasferito] - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)}</b></div>}
            {(datiGrigliaTrasferito && datiGrigliaTrasferito.length > 0) && <DataGrid
                id={props.datiTabelleGiacenze?.idFar.toString()}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                dataSource={datiGrigliaTrasferito}
                onCellPrepared={onCellPrepared}
            >
                {colonneTabGiacenza}
            </DataGrid>
            }

        </>
    )
}
