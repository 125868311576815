import React from 'react';

export default function CustomItem(data:any) {
    return (
        <div>
            <p className="testo">
                La pagina di abbinameno Codice Articolo - Prodotto permette di definire e gestire le regole di associazione tra un codice articolo presente in fattura fornitore ed un prodotto.
                La regola sara' applicabile nel caso in cui in fattura sono presenti dei prodotti senza il relativo identificativo univoco (ministeriale o ean) ma con il codice articolo interno della ditta; in questi casi al codice articolo in fattura sara' associato automaticamente il prodotto definito appunto nella regola qualora essa sia rispettata.
                Le regole definite sono salvate ed applicate a livello di gruppo, quindi per tutte le farmacie che ne fanno parte.
                Le funzionalita' principali:<br></br>
                1.	<b>Cerca</b><br></br>
                    &emsp;Ricerca per ministeriale, ean, descrizione e codice articolo ditta: visualizza i risultati che soddisfano la parola inserita nella casella di testo.<br></br>
                2.	<b>Lista Abbinamenti</b><br></br>
                    &emsp;Elenca tutti gli abbinamenti(regole) definiti.<br></br>
                <br></br>
                Per ogni record mostrato in tabella ci sono due tasti funzione che permettono:<br></br>
                1.	<b>Abbinamento</b><br></br>
                    &emsp;Definizione della regola di abbinamento codice articolo - prodotto.<br></br>
                2.	<b>Disabbinamento</b><br></br>
                    &emsp;Disabbina la regola codice articolo - prodotto precedentemente definita.<br></br>
            </p>
        </div>
    );
}