import React from 'react'
import '../index.css'
import NavTop from './NavTop'
import Contenuto from '../Contenuto';
import Container from 'react-bootstrap/esm/Container';
import { ListaFarmacieInterface } from '../interfaces/farmacie';
import { DialogPassword } from '../Dialog/DialogPassword';
import { MenuInterface } from '../interfaces/menu';

const Corpo = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    handleClosePsw: () => void,
    showModalPsw: boolean,
    menu: MenuInterface
}) => {
    //mostra/nasconde la modal della versione -> TODO da cambiare contenuto e reinserire
    // const [showModal, setShow] = useState<boolean>(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    //definisce l'id della page-wrapper a seconda se il menu è collassato o meno
    let pageId = 'page-wrapper';
    if (!props.statoMenu) {
        pageId = 'page-wrapper-closed';
    }

    return (
        <Container fluid id={pageId} className="wrapper">
            <NavTop
                statoMenu={props.statoMenu}
                toggleMenu={() => props.toggleMenu(props.statoMenu)}
                isMyCsf={props.menu.userRole.includes("CSF") ? true : false }
            />
            <Contenuto
                toggleMenu={() => props.toggleMenu(props.statoMenu)}
                statoMenu={props.statoMenu}
                datiFarmacie={props.datiFarmacie}
                menu={props.menu}
            />
            {/* <DialogVersion showModal={showModal} handleClose={handleClose} /> */}
            <DialogPassword
                showModalPsw={props.showModalPsw}
                handleClosePsw={props.handleClosePsw}
            />
        </Container>
    )
}

export default Corpo