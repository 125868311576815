import React, { useState, useEffect } from 'react'
import { Position, Popup, ToolbarItem } from 'devextreme-react/popup'
import { DialogConferma } from '../../../widget/Notifications'
import FileUploader from 'devextreme-react/file-uploader'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import { NewTabella } from '../../../widget/nuova_tabella/newVersion'
import ScrollView from 'devextreme-react/scroll-view'
import loadGif from '../../../immagini/loading.gif';
import Image from 'react-bootstrap/Image'

//import './style.css';

interface DialogProps {
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
    flagCicloAttivo: boolean
}

export const DialogCaricaXml: React.FC<DialogProps> = ({ open, close, ricarica, edit, typeEdit, flagCicloAttivo }) => {

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD, setOpenD] = useState<boolean>(false)
    const [popupEsitoImportVisible, setPopupEsitoImportVisible] = useState<boolean>(false)
    const [popupEsitoImport, setPopupEsitoImport] = useState<boolean>(false)
    const [popupEsitoImportMsg, setPopupEsitoImportMsg] = useState<string>()
    const [idDel, setIddel] = useState<string | null>(null)
    const [messDel, setMessDel] = useState<string>('')
    const [popDel, setPopDel] = useState<boolean>(false)
    const [rowSelected, setRowSelected] = useState([])
    const auth = useAuth(urlConsultazione.auth)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [selectedFiles, setSelectedFiles] = useState<any>([])
    const [testLista, setTestLista] = useState<any>([])
    const [testListaSegnalazione, setTestListaSegnalazione] = useState<any>([])
    const [arrayCaricaStato, setArrayCaricaStato] = useState<any>([])
    const [asyncCall, setAsyncCall] = useState<boolean>(false)
    const [isSegnalazione, setIsSegnalazione] = useState<boolean>(false)
    const [numeroFileSelezionati, setNumeroFileSelezionati] = useState<number>()
    const [isLoading, setIsLoading] = useState<boolean>(false)


    const columns = [
        {
            caption: "Stato", dataField: "statoSchedulazione", width: 80, render: (e: any) => {
                switch (e.value) {
                    case 'C':
                        return (<div style={{ margin: "auto", textAlign: 'center' }} title="Sincronizzato" ><div className="circle-green"></div></div>)
                    case 'S':
                        return (<div style={{ margin: "auto", textAlign: 'center' }} title="Caricamento in corso"><img style={{ width: 17, height: 17 }} src={loadGif}/></div>)
                    case 'E':
                        return (<div style={{ margin: "auto", textAlign: 'center' }} title="Allerta" ><div className="circle-red"></div></div>)
                    default:
                        return (<div style={{ margin: "auto", textAlign: 'center' }} title="Sincronizzato" ><div className="circle-yellow"></div></div>)
                }
            }
        },
        { caption: "Nome File", dataField: "nomeFile", width: "80" },
        { caption: "Data car.", dataField: "dataCaricamento", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Tipo ", dataField: "tipo", width: "150" },
        { caption: "Dim. (bytes)", dataField: "dimensione", width: "100" },
        { caption: "Username", dataField: "username", width: "100" },
        { caption: "Segnalazione", dataField: "segnalazione", width: "100" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Disabilita",
                    icon: "trash",
                    onClick: (e: any) => {
                        //setIddel(e.row.data.nomeFile)
                        //setMessDel("il file"+e.row.data.nomeFile+" sta per essere eliminato. Confermare?")
                        //setPopDel(true)
                        newRemoveTipo(e.row.data.nomeFile)

                    }
                }


            ]
        }
    ];



    const onSelectedFilesChanged = (e: any) => {
        //console.log(e.value);
        setSelectedFiles(e.value)
        //this.setState({ selectedFiles: e.value });
    }


    const controlla = (arraResp: []) => {
        // console.log('risposta');
        // console.log(arraResp);
        // console.log(testLista);
        let newObjCall: any = []
        arraResp.forEach((obj: any) => {
            testLista.forEach((obj2: any) => {
                if (obj.fileName === obj2.mongoFileName) {
                    newObjCall.push(
                        {
                            fileName: obj2.fileName,
                            mongoFileName: obj2.mongoFileName,
                            idSchedulazione: obj.idSchedulazione
                        }
                    )
                }
            });
        });

        setArrayCaricaStato(newObjCall)
        setAsyncCall(true)
        //caricaFile(newObjCall)



    }

    const invia = () => {

        if (isSegnalazione == true) {
            notify({ position: "center", width: "auto", message: "Non e\' possibile importare i file, risolvere prima le segnalazioni" }, "error", 4000)
            return;
        }
        let fileList: any = []

        if (testLista.length > 0) {

            setIsLoading(true)
            notify({ position: "center", width: "auto", message: "Attendere il caricamento di tutti i file! Non chiudere" }, "warning", 7000)

            testLista.forEach((element: any) => {
                //console.log(element);


                let obj = {
                    fileName: element.mongoFileName,
                    // mongoFileName: element.mongoFileName,
                    //idFar: 0
                    flagCicloAttivo: flagCicloAttivo,
                }
                fileList.push(obj)
            });

            let objToUse = {
                fileList
            }

            //invia multiplo
            if (auth) {
                RestServices().rest('POST', objToUse, urlConsultazione.importFile)
                    .then(result => {
                        if (result?.esito) {
                            //console.log('risposta');
                            //console.log(result.response);
                            controlla(result.response)




                            //setTabellaPrincipale(result.response)
                            notify({ position: "center", width: "auto", message: "Attendere il caricamento di tutti i file! Non chiudere" }, "warning", 7000)
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                        }
                    })
            }
        }
        else {
            notify({ position: "center", width: "auto", message: 'Non hai selezionato file da importare' }, "error", 4000)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (asyncCall) {
            const interval = setInterval(() => {
                if (arrayCaricaStato.length > 0) {

                    caricaFile(arrayCaricaStato)
                } else {
                    caricaFile(arrayCaricaStato)
                }
            }, 8000);

            caricaFile(arrayCaricaStato)
            return () => clearInterval(interval);
        }
    }, [asyncCall])




    const caricaFileTEst = () => {
        //console.log('quiiii');

        if (auth) {
            let obj: any = {}
            let arrr: any = []

            // obj.files = [
            //     {
            //         "fileName": "testbeta.xml",
            //         "mongoFileName": "testbeta_20221205094405.xml",
            //         "idSchedulazione": 128608790

            //       }
            // ]

            RestServices().rest('POST', obj, urlConsultazione.getreadByGroupFatt)
                .then(result => {
                    if (result?.esito) {
                        //console.log('dati');
                        // console.log(result.response);
                        //console.log(result.response);
                        let found: boolean = false;
                        result.response.forEach((item: any) => {
                            if (item.segnalazione != undefined && item.segnalazione.length > 0) {
                                
                                found = true;
                            }
                        });

                        if (found)
                            setIsSegnalazione(true);
                        else
                            setIsSegnalazione(false);

                        setTabellaPrincipale(result.response)
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                    }
                })
        }
    }


    const caricaFile = (array?: any, arrayL?: any) => {
        if (auth) {
            let obj: any = {}
            let arrr: any = []

            //testLista
            if (array) {

                array.forEach((file: any) => {


                    /*arrr.push(
                        {
                            fileName:file,
                            mongoFileName:response,
                            //"mongoFileName": "test_20221205113045.rtf",
                            //idSchedulazione: null
                            
                        }
                    ) */
                });

                obj.files = array

            }
            RestServices().rest('POST', obj, urlConsultazione.getreadByGroupFatt)
                .then(result => {
                    if (result?.esito) {

                        //result.response.forEach((item: any) => {
                        //    console.log(item)
                        //    arrayL.forEach((item2: any) => {

                        //        console.log(item2)
                        //        if (item.nomeFile == item2.mongoFileName) {
                        //            item.segnalazione = item2.segnalazione
                        //        }


                        //    });

                        //});
                        let found: boolean = false;
                        result.response.forEach((item: any) => {
                            if (item.segnalazione != undefined && item.segnalazione.length > 0) {

                                found = true;
                            }
                        });

                        if (found)
                            setIsSegnalazione(true);
                        else
                            setIsSegnalazione(false);

                        setTabellaPrincipale(result.response);
                        if (arrayCaricaStato.length > 0) {
                            let numberStatus: number = arrayCaricaStato.length
                            let numberReturn: number = 0
                            result.response.forEach((element: any) => {
                                if (element.statoSchedulazione == 'E' || element.statoSchedulazione == 'C') {
                                    numberReturn = numberReturn + 1
                                }
                            });
                            if (numberStatus == numberReturn) {
                                //notify({ position: "center", width: "auto", message: "Importazione dei file completa" }, "success", 2000)
                                setPopupEsitoImport(true)
                                setPopupEsitoImportMsg("Importazione dei file completa")
                                setPopupEsitoImportVisible(true)
                                setAsyncCall(false)
                                setIsLoading(false)
                            }
                        }
                    } else {
                        //notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setPopupEsitoImport(false)
                        setPopupEsitoImportMsg(result.message)
                        setPopupEsitoImportVisible(true)
                    }
                })
        }
    }


    const removeTipo = () => {
        if (auth) {
            RestServices().rest('POST', { fileName: idDel }, urlConsultazione.deleteFileFatt)
                .then(result => {
                    if (result.esito) {
                        setPopDel(false)
                        setIddel(null)
                        caricaFile()
                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }


    const newRemoveTipo = (name: string) => {
        if (auth) {
            RestServices().rest('POST', { fileName: name }, urlConsultazione.deleteFileFatt)
                .then(result => {
                    if (result.esito) {
                        setIddel(null)
                        //console.log(result.esito);
                        let nuovaLista = [...testLista]
                        const pos = nuovaLista.map((e: any) => e.mongoFileName).indexOf(name);
                        if (pos > -1) {
                            nuovaLista.splice(pos, 1);
                        }
                        setTestLista(nuovaLista)
                        caricaFile(nuovaLista)



                        //caricaFile()          
                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }



    useEffect(() => {
        if (edit) {
            let objTo = { ...objctTOsave }
            objTo = edit
            setObjctTOsave(objTo)
        } else {
            setObjctTOsave(null)
        }

    }, [edit])


    const ricaricaTipi = () => {
        setOpenPop(false)
        // getTipologiaCluster()
    }

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value
        setObjctTOsave(objTo)

    }

    const onCellPrepared = (e: any) => {
        if (e.rowType === "data" && e.column && e.column.caption) {
            if (e.column.caption.startsWith("Segnalazione")) {
                if (e.row.data.segnalazione != undefined && e.row.data.segnalazione.length > 0) {
                    e.cellElement.style.backgroundColor = '#FA8148';
                }

            }

        }
    }


    useEffect(() => {
        if (typeEdit) {

        } else {
            //farmaList()
        }

    }, [typeEdit])

    useEffect(() => {
        if (auth && open) {


        }

        setOpenD(open)


    }, [auth])


    useEffect(() => {
        if (auth && open) {

            // caricaFileTEst() 
        }

    }, [open, auth])
    useEffect(() => {
        //caricaFile()

        setOpenD(open)
    }, [open])


    return (
        <>
            <DialogConferma
                dialogIsOpen={popDel}
                message={messDel}
                functionNext={() => removeTipo()}
                handleCloseConferma={() => { setPopDel(false); setIddel(null) }}
            />

            <Popup
                visible={popupEsitoImportVisible}
                showCloseButton={false}
                dragEnabled={false}
                showTitle={true}
                title="Avviso"
                width={300}
                height={210}
            >
                <Position

                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'CHIUDI',
                        onClick: () => setPopupEsitoImportVisible(false),
                    }}
                />

                <div className={popupEsitoImport ? "p-3 mb-2 bg-success text-white" : "p-3 mb-2 bg-warning text-dark"}>{popupEsitoImportMsg}</div>
            </Popup>

            <Popup
                visible={openD}
                showCloseButton={false}
                width={'80%'}
                height={'76%'}
                showTitle={true}
                className="modalDown"
                title="Importazione"
            >

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'CHIUDI',
                        disabled: isLoading,
                        onClick: () => close(),
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'IMPORTA',
                        class: 'btncreatipocluster',
                        disabled: isLoading,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => invia(),
                    }}
                />


                <div className='form-row '>
                    <div className="form-group col-md-4">
                        <div style={{ textAlign: 'left' }}>
                            <h3>Carica</h3>
                            <p>Carica nuovi file da importare!</p>
                            {selectedFiles != undefined && selectedFiles.length > 0 && <p>File selezionati: <b>{selectedFiles.length}</b></p>}
                        </div>
                        <FileUploader
                            className='removeP'
                            maxFileSize={20000000}
                            multiple={true}
                            accept={'*'}
                            uploadMode={'useButtons'}
                            onUploaded={(x: any) => {

                                // console.log('ok fatto');                              
                                // console.log(x);

                                let arrayL = [...testLista]
                                let arrayPost = [...testLista]

                                // console.log('resp');

                                //console.log(JSON.parse(x.request.response))
                                let datiRest = JSON.parse(x.request.response)

                                // console.log('array qui')
                                // console.log(arrayL)




                                // console.log(x.request?.response)

                                // arrayL.push(x.file?.name )

                                datiRest.files[0].forEach((filex: any) => {


                                    arrayPost.push({
                                        mongoFileName: filex.fileName,
                                        fileName: filex.realFileName,

                                    })
                                    arrayL.push({
                                        mongoFileName: filex.fileName,
                                        fileName: filex.realFileName,
                                        segnalazione: filex.segnalazione

                                    })


                                });


                                setTestLista(arrayPost)
                                caricaFile(arrayPost, arrayL)

                            }}

                            uploadUrl="https://www.cloud-farma.it/cfportal/uploadNew"
                            //uploadUrl="http://localhost:8080/cfportal/uploadNew"
                            onValueChanged={onSelectedFilesChanged} />

                    </div>


                    <div className="form-group col-md-8">
                        <div style={{ textAlign: 'left' }}>
                            <h3>Lista files   </h3>
                            <table><tr><td><p>Attendi l'importazione di ogni file! </p></td><td><div style={{ visibility: isLoading == true ? 'visible' : 'hidden', margin: "auto", textAlign: 'center' }} title="Caricamento in corso"><img style={{ verticalAlign: 'super', marginLeft: 20, width: 17, height: 17 }} src={loadGif} /></div></td><td><div style={{ visibility: isLoading == true ? 'visible' : 'hidden' } }><p>Caricamento in corso...</p></div></td></tr></table>
                        </div>
                        <div style={{ marginTop: 10, marginBottom: 10 }} id='tabellaCss'>
                            <NewTabella
                                idTabella='carica_file'
                                sync
                                colonne={columns}
                                dataSource={tabellaPrincipale}
                                filterRow
                                height={'40vh'}
                                searchPanel={false}
                                onCellPrepared={onCellPrepared}
                                exportT={{
                                    enabled: false,
                                    fileName: "carica_file"
                                }}
                            //columnC
                            />
                        </div>
                    </div>

                </div>



            </Popup>
        </>
    )


}
