import { basenameHost } from './../contenuti/costanti';
import { ErroreResponseInterface, OptionsRestInterface } from '../interfaces/generiche';

export const RestServices = () => {
    //ima serve per distinguere se le chiamate sono relative ad immagini o no in modo da poter applicare .blob() al risultato
    //invece di .json() come negli altri casi
    const rest = async (method: string, body: any, url: string, contentType?: string, ima?: boolean, bearer?: string) => {

        //inserito perché JSON.stringify di una stringa restituisce ""stringa"" e la chiamata va in errore
        if (typeof (body) !== "string") {
            body = JSON.stringify(body);
        }
        if (!contentType) {
            contentType = 'application/json'
        }

        //if (!bearer) {
        //    bearer = ''
        //}

        const options: OptionsRestInterface = {
            method: method,
            credentials: "include",
            headers: {
                'Content-Type': contentType,
               ...bearer && { 'Authorization': 'Bearer ' + bearer }
            },
            redirect: "follow"
        }
        if (method === "POST") {
            options.body = body
        }
        try {
            //console.log('mostro obj');
            //console.log(options);
            
            
            const response = await fetch(url, options);
            let errore: ErroreResponseInterface;
            switch (response.status) {
                case 200:
                    if (!ima) {
                        return await response.json().catch(() => {
                            if (process.env.NODE_ENV === "development") console.log("errore in response.json()");
                            return "errore in response.json()";
                        });
                    } else {
                        let arrayBit = await response.blob();
                        let url = URL.createObjectURL(arrayBit);
                        return url;
                    }
                case 204:
                    if (!ima) {
                        if (process.env.NODE_ENV === "development") console.log("No content");
                        return true;
                    } else {
                        return "nessuna immagine"; //visto che il risultato sia nel caso 200 che 204 è una stringa, permette di gestire le immagini di default
                    }
                    break;
                case 400:
                    return errore = await response.json();
                case 401:
                    window.location.href = basenameHost + "cfportal";
                    break;
                case 431:
                    if (process.env.NODE_ENV === "development") console.log(response.url);
                    break
                case 500:
                    return errore = await response.json();
            }
        } catch (e) {
           // console.log("errore");
        }
    };
    return { rest };
}