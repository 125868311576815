import React, { useEffect,  useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { createItemFilterCruscGruppo } from "./filter/FiltriGalileo";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import {  urlConsultazione, urlReport } from "../../costanti";
import { Chart } from "devextreme-react";
//import { MenuCruscotto } from "../components/menu_cruscotto"
import {
  Animation,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Series,
  Subtitle,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import { CruscottoGruppo_traform_2W, trasform_for_cards_charts_01 } from "../util/trasformation";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { AplicaValoreTO } from "../../../funzioni/operazioniSuTabelle";

import "./style.css";
import { cardC, CardCart } from "../components/card_chart";

const dashboard4 = { dashboardID: 4 };

enum typeShow {
  euro,
  percento,
}

interface cardV {
  testo: string;
  subTesto: string;
  keyValue: string | undefined;
  typeShowValue?: typeShow;
  testoPop?: string;
  second?: boolean;
}

const boxCard: cardV[] = [
  {
    testo: "Farmacie monitorate",
    subTesto: "",
    keyValue: "FARMACIE",
    testoPop: "Farmacie monitorate: Numero di farmacie selezionate",
  },
  {
    testo: "Valore del venduto",
    subTesto: "",
    keyValue: "VALORE_VENDUTO",
    typeShowValue: typeShow.euro,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. ",
  },
  {
    testo: "Margine",
    subTesto: "",
    keyValue: "MARGINE",
    typeShowValue: typeShow.euro,
    testoPop:
      "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Margine Percentuale",
    subTesto: "",
    keyValue: "MARGINE_PERCENTUALE",
    typeShowValue: typeShow.percento,
    testoPop:
      "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Volume vendita",
    subTesto: "",
    keyValue: "VOLUME_VENDITA",
    testoPop:
      "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato.",
  },
  {
    testo: "Numero vendite",
    subTesto: "",
    keyValue: "NUMERO_VENDITE",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui considerato è il prezzo di listino.",
  },
];


const boxCardAnaRicette: cardC[] = [
    {
        testo: "Venduto lordo",
        subTesto: "% sul venduto totale",
        valore: "VENDUTO_LORDO_SSNSL",
        subValore: "PERC_VENDUTO_TOTALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
    },
    {
        testo: "Imponibile Lordo",
        subTesto: "% sull'imponibile totale",
        valore: "IMPONIBILE_LORDO_SSNSL",
        subValore: "PERC_IMPONIBILE_TOTALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
    },

];

export const boxsecondRicette: cardC[] = [
    {
        testo: "Valore Ticket + Quota ricetta ",
        subTesto: "Delta anno precedente",
        valore: "TICKET_QUOTASL",
        subValore: "TICKET_QUOTA_PUNTUALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Decurtazione in DCR",
        valore: "DECURTAZIONE_DCRSL",
        typeShowValue: typeShow.euro,
        testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
        testo: "Previsione incasso DCR lordo IVA",
        subTesto: "Previsione incasso DCR netto IVA ",
        valore: "PREVISIONE_INCASSO_DCR_LORDO_IVASL",
        subValore: "PREVISIONE_INCASSO_DCR_NETTO_IVASL",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.euro,
        testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },

];

export const boxterzRicette: cardC[] = [
    {
        testo: "Numero di ricette lavorate",
        subTesto: "Delta anno precedente",
        valore: "NUMERO_RICETTESL",
        subValore: "NUMERO_RICETTE_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Valore medio per ricetta",
        subTesto: "Delta anno precedente",
        valore: "VALORE_MEDIO_RICETTASL",
        subValore: "VALORE_MEDIO_RICETTA_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        typeShowValue: typeShow.euro,
        testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
        testo: "Pezzi venduti SSN",
        subTesto: "Delta anno precedente",
        valore: "PEZZI_SSNSL",
        subValore: "PEZZI_SSN_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
        testo: "Numero medio pezzi per ricetta",
        subTesto: "Delta anno precedente",
        valore: "MEDIA_PEZZI_RICETTASL",
        subValore: "MEDIA_PEZZI_RICETTA_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
];

//da spostare
const convertTime = (data: Date) => {
  const anno = data.getFullYear();
  const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perché i mesi sono 0-based
  const giorno = data.getDate().toString().padStart(2, "0");
  const ore = data.getHours().toString().padStart(2, "0");
  const minuti = data.getMinutes().toString().padStart(2, "0");
  const secondi = data.getSeconds().toString().padStart(2, "0");

  const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

  return dataFormattata;
};

const GalileoCruscottoGruppo = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [valori2W, setValori2W] = useState<any>([]);
  const [valori3W, setValori3W] = useState<any>([]);
  const [valoriQ, setValoriQ] = useState<any>(null);
  const [valoriQO, setValoriQO] = useState<any>(null);
  const [valoriQAY, setValoriQAY] = useState<any>(null);
  const [valoriQOAY, setValoriQOAY] = useState<any>(null);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [chartDataMargine, setChartDataMargine] = useState<any>([]);
  const [totalValueMargine, setTotalValueMargine] = useState(0);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePopOne, setVisiblePopOne] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnaRicette);
    //const [fila1cards, setFila1cards] = useState<cardC[]>(boxprimoRicette);
    const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecondRicette);
    const [fila3cards, setFila3cards] = useState<cardC[]>(boxterzRicette);
  const loadPanel = <Loading isVisible={isVisible} />;


  const CardElement: React.FC<cardV> = ({
    testo,
    subTesto,
    keyValue,
    typeShowValue,
    testoPop,
    second,
  }) => {
    let valoreToUse;
    let valoreToUseOld;

    if (second) {
      if (valoriQAY && keyValue) {
        switch (typeShowValue) {
          case typeShow.euro:
            const valoreEuro = valoriQAY[keyValue];
            valoreToUse = `€ ${valoreEuro.toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`;
            break;
          case typeShow.percento:
            valoreToUse = valoriQAY[keyValue].toFixed(2) + "%";
            break;
          default:
            const valore = valoriQAY[keyValue];
            valoreToUse = valore.toLocaleString("it-IT", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            });
            break;
        }
      } else {
        valoreToUse = 0;
      }

      if (valoriQOAY && keyValue) {
        switch (typeShowValue) {
          case typeShow.euro:
            const valoreEuro = valoriQOAY[keyValue];
            valoreToUseOld = `€${valoreEuro.toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`;
            break;
          case typeShow.percento:
            valoreToUseOld = valoriQOAY[keyValue].toFixed(2) + "%";
            break;
          default:
            const valore = valoriQOAY[keyValue];
            valoreToUseOld = valore.toLocaleString("it-IT", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            });
            break;
        }
      } else {
        valoreToUse = 0;
        valoreToUseOld = 0;
      }
    } else {
      if (valoriQ && keyValue) {
        switch (typeShowValue) {
          case typeShow.euro:
            const valoreEuro = valoriQ[keyValue];
            valoreToUse = `€${valoreEuro.toLocaleString("it-IT", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`;
            break;
          case typeShow.percento:
            valoreToUse = valoriQ[keyValue].toFixed(2) + "%";
            break;
          default:
            const valore = valoriQ[keyValue];
            valoreToUse = valore.toLocaleString("it-IT", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 3,
            });
            break;
        }
      } else {
        valoreToUse = 0;
      }
    }

    return (
      <div style={{ background: "#fff" }} className="item border-solid">
        <p
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
          }}
        >
          {/* {second ? 'Progressivo anno corrente' : ' Periodo Selezionato'} */}
          <span>
            {" "}
            <button
              className="ibutton"
              style={{ border: "none" }}
              onClick={() => popVisible(testoPop ? testoPop : "")}
            >
              <FontAwesomeIcon
                icon={faInfo}
                style={{ fontSize: "11px", color: "black" }}
              />
            </button>
          </span>
        </p>
        <p style={{ margin: 0 }}>{testo}</p>
        <h4 style={{ margin: 0, fontSize: "24px", color: "#00CEE6" }}>
          {" "}
          {valoreToUse}
        </h4>

        <p>
          {subTesto}{" "}
          <span>
            <strong>{""}</strong>
          </span>
        </p>
      </div>
    );
  };




  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  function aggiornaOrarioFine(dataFine: any) {
    // Verifica se l'orario della data finale è mezzanotte (00:00)
    const dataFineAggiornata = new Date(dataFine);
    dataFineAggiornata.setHours(23, 59, 59);
    return dataFineAggiornata;
  }

  function aggiornaDataFine(dataFine: Date): Date {
    // Clona la data di partenza per non modificarla direttamente
    const nuovaDataFine = new Date(dataFine);

    // Aggiungi un giorno alla data
    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    //console.log('vediamo parametri ricerca');
    //console.log(params);


    setParamsFilter(params);

    let newIDFAR: any = [];
    let newIDSettore: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }


      let venditaDPC = params?.venditaDPC ? [] : [0];
      let flagBusta = params?.flagBusta ? [] : [0];

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.periodo?.al) {
      //alert('ypppp')
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    } else {
      //alert('nooooo')
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      operatori: params.operatori,
      venditaDPC: venditaDPC,
      flagBusta: flagBusta,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    const customizeTooltip = () => {
      return {
        text: `pollo`,
      };
    };

    setIsVisible(true);
    if (authReport) {


      const dashboardCall4 = {
        ...dashboard4,
        idFarList: params.idFar,
        params: parameters,
      };

      /**qui api test  */
      // const call1w = {
      //   ...api1w,
      //   idFarList: params.idFar,
      //   params: parameters,
      // };


      RestServices()
        .rest("POST", dashboardCall4, urlReport.getQueryDashboardAllData)
        .then((result) => {
          //console.log(result);
          setIsVisible(false);

          if (result) {

              let datiPrimaParteCards = trasform_for_cards_charts_01(result["128"], boxCardAnaRicette);
              let datiSecondaParteCards = trasform_for_cards_charts_01(
                  result["128"],
                  boxsecondRicette
              );
              let datiTerzaParteCards = trasform_for_cards_charts_01(
                  result["128"],
                  boxterzRicette
              );

              // 1 chartpie
              setFila1cards(datiPrimaParteCards);
              setFila2cards(datiSecondaParteCards);
              setFila3cards(datiTerzaParteCards);
            // ID 32 BOX

            setValoriQ(result["32"][0]);
            setValoriQO(result["32"][0]);

            // ID 33 
            const anno = params.periodo.dal.substring(0, 4);
              console.log("dddddd");
              console.log(anno);
              const traform = CruscottoGruppo_traform_2W(result["33"], anno);
            setValori2W(traform);

            // ID 34
            setValori3W(result["34"]);



          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            { position: "center", width: "auto", message: "Errore" + error },
            "error",
            4000
          );
        });
      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const filters = () => {
    if (auth) {

    }


  };

  useEffect(() => {
    filters();
  }, [auth]);

  const exportFormats = ["csv"];

  const onExporting = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("cruscotto_gruppo");
    
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
      customizeCell: function(options) {
        const { gridCell, excelCell } = options;
        if(gridCell?.rowType === 'data') {
            if(gridCell.column?.dataField === 'VALORE_VENDUTOAP' || 
             gridCell.column?.dataField === 'VALORE_VENDUTOCR' ||
             gridCell.column?.dataField === 'VALORE_VENDUTO_SSNCR' ||
             gridCell.column?.dataField === 'VALORE_VENDUTO_LIBERACR' ||
             gridCell.column?.dataField === 'VALORE_VENDUTO_NETTO_IVACR' ||
             gridCell.column?.dataField === 'VALORE_VENDUTO_NETTO_IVAAP' ||
             gridCell.column?.dataField === 'MARGINECR' ||
             gridCell.column?.dataField === 'MARGINEAP' ||
             gridCell.column?.dataField === 'MARGINEAP' ) {
                // Formatta il valore numerico
                const formattedValue = gridCell.value?.toLocaleString('fr-CH', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                });

                const formattedValueNew = formattedValue?.replace(/\s/g, '')

                excelCell.value = formattedValueNew;
            }
        }
    }
    }).then(function () {
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "cruscotto_gruppo.csv"
        );
      });
    });

  };

  const itemsFields = createItemFilterCruscGruppo (
    newFarmaList
);


  const onPointClick = (e: { target: any }) => {
    const point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  };

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);


  function formatAsPercentage(cellInfo: any) {
    let valoreTouse = cellInfo.value;
    return new Intl.NumberFormat("default", {
      style: "percent",
    }).format(cellInfo / 100);
  }

  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };

  const popVisibleOne = (testo: string) => {
    setVisiblePopOne(true);
  };


  let messOne = [
    "Grafico a bolle che permette di paragonare visivamente il portafoglio farmacie. Le farmacie sono schematizzate in funzione del valore del venduto e del margine. La grandezza della bolla dipende dal margine percentuale. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  ];

  const columnsOperatori = [
      { caption: "Codice", dataField: "ID_FARMACIACR", width: "auto" },
      { caption: "Farmacia", dataField: "FARM_DESCRIZIONECR", width: "auto" },
    {
      caption: "Numero giorni lavorati ",
        dataField: "GIORNI_LAVORATICR",
      width: "auto",
    },
    {
      caption: "Numero giorni lavorati anno precedente ",
      dataField: "GIORNI_LAVORATIAP",
      width: "auto",
    },
    {
      caption: "Valore del venduto periodo selezionato",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto SSN periodo selezionato",
      dataField: "VALORE_VENDUTO_SSNCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Libera periodo selezionato",
      dataField: "VALORE_VENDUTO_LIBERACR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto equiparato",
      dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile periodo selezionato",
      dataField: "VALORE_VENDUTO_NETTO_IVACR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile anno precedente",
      dataField: "VALORE_VENDUTO_NETTO_IVAAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezionato",
      dataField: "MARGINECR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezionato",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Numero vendite periodo selezionato",
      dataField: "NUMERO_VENDITECR",
      width: "auto",
    },
    {
      caption: "Numero vendite anno precedente",
      dataField: "NUMERO_VENDITEAP",
      width: "auto",
    },
    {
      caption: "Volume di vendita periodo selezionato",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
    },
    {
      caption: "Volume di vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "auto",
    },
    {
      caption: "Delta volume di vendita equiparato",
      dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "auto",
      },
      {
          caption: "Numero ricetta periodo selezionato",
          dataField: "NUMERO_RICETTECR",
          width: "auto",
      },
      {
          caption: "Valore medio ricetta periodo selezionato",
          dataField: "VALORE_MEDIO_RICETTACR",
          width: "auto",
          format: { type: "currency", precision: 2, currency: "EUR" },
      },
      {
          caption: "Media pezzi ricetta periodo selezionato",
          dataField: "MEDIA_PEZZI_RICETTACR",
          width: "auto",
          format: {
              type: "fixedPoint",
              precision: 2
          }
      },
      {
          caption: "Numero ricetta anno precedente",
          dataField: "NUMERO_RICETTEAP",
          width: "auto",
      },
      {
          caption: "Valore medio ricetta anno precedente",
          dataField: "VALORE_MEDIO_RICETTAAP",
          width: "auto",
          format: { type: "currency", precision: 2, currency: "EUR" },
      },
      {
          caption: "Media pezzi ricetta anno precedente",
          dataField: "MEDIA_PEZZI_RICETTAAP",
          width: "auto",
          format: {
              type: "fixedPoint",
              precision: 2
          }
      },
      {
          caption: "Delta media pezzi ricetta anno precedente",
          dataField: "MEDIA_PEZZI_RICETTA_PUNTUALEAP",
          width: "auto",
          customizeText: (cellInfo: any) =>
              AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
      },
      {
          caption: "Delta media pezzi ricetta periodo selezionato",
          dataField: "MEDIA_PEZZI_RICETTA_PUNTUALECR",
          width: "auto",
          customizeText: (cellInfo: any) =>
              AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
      },
      {
          caption: "Delta numero ricette anno precedente",
          dataField: "NUMERO_RICETTE_PUNTUALEAP",
          width: "auto",
          customizeText: (cellInfo: any) =>
              AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
      },
      {
          caption: "Delta numero ricette periodo selezionato",
          dataField: "NUMERO_RICETTE_PUNTUALECR",
          width: "auto",
          customizeText: (cellInfo: any) =>
              AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
      }
      ,
      {
          caption: "Delta valore medio ricetta periodo selezionato",
          dataField: "VALORE_MEDIO_RICETTA_PUNTUALECR",
          width: "auto",
          customizeText: (cellInfo: any) =>
              AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
      }
      ,
      {
          caption: "Delta valore medio ricetta anno precedente",
          dataField: "VALORE_MEDIO_RICETTA_PUNTUALEAP",
          width: "auto",
          customizeText: (cellInfo: any) =>
              AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
      }
  ];

   
  return (
    <section style={{ display: "flex", height: "98vh" }}>
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div
            style={{
              fontSize: "15px",
              alignItems: "left",
              marginTop: "10px",
              marginBottom: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h3>Cruscotto Gruppo</h3>
          </div>
          {/* select*/}

          <div
            style={{
              display: "block",
              gap: 5,
              padding: 10,
              borderRadius: 10,
              marginTop: 10,
              width: "auto",
            }}
          >

          </div>
          <Popup
            visible={visiblePop}
            showCloseButton={true}
            onHiding={() => setVisiblePop(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
          >
            <div>
              <p>{testoPop}</p>
            </div>
          </Popup>
          <div className="grid-container">
            {boxCard.map((el, i) => {
              return (
                <CardElement
                  testo={el.testo}
                  subTesto={el.subTesto}
                  typeShowValue={el.typeShowValue}
                  key={i}
                  keyValue={el.keyValue}
                  testoPop={el.testoPop}
                />
              );
            })}
                      </div>


                      <div>
                          <div className="grid-ibrid">
                              <div style={{ width: 'calc(166.9%)', paddingBottom: 5 }} className="grid-container-one">
                                  {fila1cards.map((el, i) => {
                                      return (
                                          <CardCart
                                              testo={el.testo}
                                              subTesto={el.subTesto}
                                              key={i}
                                              valore={el.valore}
                                              subValore={el.subValore}
                                              starter={el.starter}
                                              typeShowValue={el.typeShowValue}
                                              typeShowSubValue={el.typeShowSubValue}
                                              testoPop={el.testoPop}
                                          />
                                      );
                                  })}
                              </div>
                          </div>


                          <div style={{ fontSize: 16, width: "100%", position: 'relative' }} className="grid-container-3-cate">
                              {fila2cards.map((el, i) => {
                                  return (
                                      <CardCart
                                          testo={el.testo}
                                          subTesto={el.subTesto}
                                          key={i}
                                          valore={el.valore}
                                          subValore={el.subValore}
                                          starter={el.starter}
                                          typeShowValue={el.typeShowValue}
                                          typeShowSubValue={el.typeShowSubValue}
                                          testoPop={el.testoPop}
                                      />
                                  );
                              })}
                          </div>

                          <div style={{ width: 'calc(100%)', marginBottom: 20 }} className="grid-container-4-element">
                              {fila3cards.map((el, i) => {
                                  return (
                                      <CardCart
                                          testo={el.testo}
                                          subTesto={el.subTesto}
                                          key={i}
                                          valore={el.valore}
                                          subValore={el.subValore}
                                          starter={el.starter}
                                          typeShowValue={el.typeShowValue}
                                          typeShowSubValue={el.typeShowSubValue}
                                          testoPop={el.testoPop}
                                      />
                                  );
                              })}
                          </div>
                      </div>

          <div style={{ marginTop: 5 }}>
            <h6
              style={{
                color: "#5b6372",
                fontFamily: "Arial, sans-serif",
                marginBottom: "-20px",
                marginLeft: "10px",
                zIndex: 1,
                position: "relative"
              }}
            >
              Dettaglio gruppo farmacia
            </h6>

            <NewTabella
              idTabella="elenco_operatori"
              sync
              colonne={columnsOperatori}
              dataSource={valori2W}
              filterRow
              width={10}
              searchPanel={true}
              exportT={{
                enabled: true,
                fileName: "ElencoOperatori",
                formats: { exportFormats },
              }}
              activeSaver={true}
              columnC={true}
              rowAlternation={true}
              headerFilter
              wordWrapEnabled={true}
              onExporting={onExporting}
              summary={
                <Summary>
                  <TotalItem column="GIORNI_LAVORATICR" summaryType="sum" />
                  <TotalItem column="GIORNI_LAVORATIAP" summaryType="sum" />
                  <TotalItem
                    column="VALORE_VENDUTOCR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="VALORE_VENDUTO_SSNCR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="VALORE_VENDUTO_LIBERACR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="VALORE_VENDUTOAP"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column=""
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="VALORE_VENDUTO_NETTO_IVACR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="VALORE_VENDUTO_NETTO_IVAAP"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="MARGINECR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="MARGINEAP"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem column="NUMERO_VENDITECR" summaryType="sum" />
                  <TotalItem column="NUMERO_VENDITEAP" summaryType="sum" />
                  <TotalItem column="VOLUME_VENDITACR" summaryType="sum" />
                  <TotalItem column="VOLUME_VENDITAAP" summaryType="sum" />
                  <TotalItem
                    column="VALORE_VENDUTO_EQUIPARATO"
                    summaryType="sum"
                  />
                </Summary>
              }
            />
          </div>

        
            <div
              style={{
                background: "#fff",
                padding: 10,
                display: "flex",
                paddingTop: 10,
                marginTop: 10,
              }}
            >


              <div style={{ width: "100%" }}>
                <h6
                  style={{
                    display: "flex",
                    color: "#5b6372",
                    fontFamily: "Arial, sans-serif",
                    marginBottom: "-15px",
                    marginLeft: "15px",
                  }}
                >
                  Andamento margine rispetto al valore venduto
                </h6>
                <div style={{ marginTop: "15px" }}>
                  {valori3W.length > 0 ? (
                    <div>
                      <Chart id="bubbleChart" dataSource={valori3W}>
                        <CommonSeriesSettings
                          argumentField="MARGINE"
                          valueField="VALORE_VENDUTO"
                        />
                        <Series
                          type="bubble"
                          name="Bubble Series"
                          sizeField="MARGINE_PERCENTUALE"
                        ></Series>
                        <Tooltip
                          enabled={true}
                          customizeTooltip={({
                            argument,
                            value,
                            size,
                            point,
                          }: {
                            argument: any;
                            value: any;
                            size: any;
                            point: any;
                          }) => {
                            //console.log("cosa sono i dati");
                            //console.log(argument);
                            //console.log(value);
                            //console.log(size);
                           // console.log(point.data);

                            return {
                              html: `
                                      <div><p style='margin:0px'>Valore del venduto</p><p style='font-weight: bold; color:#72ddee'>€${point.data.MARGINE.toLocaleString(
                                "it-IT",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}<p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Margine</p><p style='font-weight: bold;color:#72ddee'>€${point.data.VALORE_VENDUTO.toLocaleString(
                                "it-IT",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}</p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Farmacia</p><p style='font-weight: bold;color:#72ddee'>${point.data.FARM_DESCRIZIONE
                                }</p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Margine percentuale</p><p style='font-weight: bold;color:#72ddee'>${point.data.MARGINE_PERCENTUALE.toFixed(2)
                                }%</p></div>`,
                            };
                          }}
                        />

                        <ValueAxis>
                          <Title text="Valore del venduto" />
                        </ValueAxis>
                        <ArgumentAxis>
                          <Title text="Margine" />
                        </ArgumentAxis>

                        <Legend visible={false} />
                      </Chart>
                    </div>
                  ) : null}
                </div>

              </div>

              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisibleOne("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>

              {chartDataMargine.length > 0 && (
                <div
                  style={{
                    width: "30%",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <h2
                    style={{ color: "#5b6372", fontFamily: "Arial, sans-serif" }}
                  >
                    Totale:
                  </h2>
                  <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                    {totalValueMargine}
                  </h2>
                </div>
              )}
            </div>

            <Popup
              visible={visiblePopOne}
              showCloseButton={true}
              onHiding={() => setVisiblePopOne(false)}
              width={"60%"}
              height={"60%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{messOne}</p>
              </div>
            </Popup>
        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{ fontSize: "30px", color: "black" }}
        />
      </div>
      <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>

        <div style={{}}>
          <FiltriVerticale
            ricerca={filterAction}
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>
    </section>
  );
};

export default GalileoCruscottoGruppo;
