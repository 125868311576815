import { useState, useEffect } from "react";
import { RestServices } from "../services/restServices";

export function useAuth(urlAuth: string) {
    const [auth, setAuth] = useState<boolean>();

    //lo useEffect con [] fa sì che la chiamata venga fatta una sola volta al caricamento della pagina
    useEffect(() => {
        RestServices().rest('GET', "", urlAuth, 'application/x-www-form-urlencoded')
            .then(result => {
                if (result) {
                    setAuth(result);
                }
            })
    }, [])

    return auth
}