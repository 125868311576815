import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import Ricerca from "../../widget/nuova_ricerca"
import {  Loading } from '../../widget/Notifications'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import { DialogMagMovime } from "./dialog/pop_mag_movime"
import {Summary, TotalItem} from 'devextreme-react/data-grid'
import {  trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import {
    useLocation
  } from "react-router-dom";


import './style.css'

const MagMovimentazione = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [newFarmaList,setNewFarmaList] = useState([])
    const [newOperatoreList,setnewOperatoreList] = useState([])
    const [idFarmacia,setIdFarmacia] = useState<any>({})
    const [objtipo,setObjtipo] = useState<any>(null)
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [edit,setImmediate] = useState<boolean>(false)
    const [dettagli,setDettagli] = useState<any>({})
    const [specific,setSpecific] = useState<boolean>(true)
    const auth = useAuth(urlConsultazione.auth)

    const { search }:any = useLocation();

   

    const itemsFields =[
        {type:'select' , idValue:'idFar' , label:'Farmacie ' , displayExpr:'descrizione', dataSource : newFarmaList ,width:350,require:true   },
        {type:'autocomplete'  , idValue:'codCsf' , label:'Prodotto ' , typeApi:'products',valueExpr:'text', apisearch:true, dataSource : {},width:300 ,require:true },
        {type:'date' , idValue:'dal' , label:'Dal' , dataSource : null  ,require:true , defValue : new Date((new Date().getFullYear()-1)+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()), width:250 },
        {type:'date' , idValue:'al' , label:'Al' , dataSource : null, require:true ,defValue :new Date(), width:250 },
        {type:'select' , idValue:'idOperatore' , displayExpr:'operatore', label:'Operatore ' , dataSource : newOperatoreList ,width:300 },
    ]


    const columns = [
        { caption: "Codice", dataField: "codProdotto", width: 100 , },
        { caption: "Codice EAN", dataField: "ean", width: 100},
        { caption: "Descrizione ", dataField: "descrizione" },
        { caption: "Data ", dataField: "data",format: 'dd/MM/yyyy HH:mm:ss', dataType: "date", width:180 ,sortOrder: "desc"},
        { caption: "Tipo di movimento", dataField: "tipoMovimento", width: 200  },
        { caption: "Q.ta Mov", dataField: "quantita", width: 60 },
        { caption: "Giac. dopo Mov. ", dataField: "giacenza", width: 60 },
        { caption: "Operatore", dataField: "operatore", width: 130 },
        { caption: "Terminale", dataField: "terminale",width: 140 },
        { caption: "Magazzino", dataField: "magazzino" },
        { caption: "Azioni",  type: "buttons", buttons: [
            {
                hint: "Vendite/acquisti",
                icon: "chart",
                onClick: (e: any) => {
                    let objTouse = {...e.row.data}
                    setObjtipo(objTouse)
                    setOpenPop(true)   
                            
                }
            },
        ],width: 100  },

    ]


    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {                     
                       //console.log(result.response);
                       setNewFarmaList(result.response)                    
                   }else{                               
                      // console.log(result);                    
                   }
               })     
       }
   }


   const operList = (obj?:any) =>{
    if (auth) {
        if(obj?.idFar){
            let idF = obj?.idFar.idFar;
            RestServices().rest('POST', {idFarmacia:idF } , urlConsultazione.getListOperatore)
           .then(result => {
               if (result?.esito) {                     
                   //console.log(result.response);
                   setnewOperatoreList(result.response)                    
               }else{                               
                  // console.log(result);                    
               }
           }) 
        }else{
            setnewOperatoreList([]) 
        }
        
   }
}

   const operListOld = () =>{
    if (auth) {
       RestServices().rest('POST', {idFarmacia  : idFarmacia} , urlConsultazione.getListOperatore)
           .then(result => {
               if (result?.esito) {                     
                   //console.log(result.response);
                   setnewOperatoreList(result.response)                    
               }else{                               
                  // console.log(result);                    
               }
           })     
   }
}

    const filterAction = (objFiltro:Filtri) =>{
        let params = {...objFiltro}     
        /*if(params.dal){
            params.dal = trasformaDateTimeInLocale(params.dal , true);
          }else{
             let nixioAnno:any = new Date().getFullYear()+'-01-01' 
             params.dal = trasformaDateTimeInLocale(nixioAnno , true);
           }

           if(params.al){
               params.al = trasformaDateTimeInLocale(params.al , true);
  
              }else{
               let oggi:any = new Date() 
               params.al = trasformaDateTimeInLocale(oggi , true);
             }     
           if(objFiltro.idFar){
               params.idFarm = Number(params.idFar?.idFar)
           }else{
               delete params.idFar
           }  */
        //    if(objFiltro.codCSF){
        //     params.codCSF = Number(params.codCSF?.codCSF)
        // }else{
        //     delete params.idFar
        // }  

        if(params.dal){
            params.dal = trasformaDateTimeInLocale(params.dal , true);
        }
        if(params.al){
            params.al = trasformaDateTimeInLocale(params.al , true);
        }
        if(params.idFar){
            let farmaciaDato = {...params.idFar}
            params.idFarm = Number(params.idFar?.idFar)
            setIdFarmacia(farmaciaDato)
            delete params.idFar
        }else{
            setIdFarmacia(null)
            delete params.idFar
        }
        if(params.codCsf){
            params.codCSF = params.codCsf.id;
            delete params.codCsf
        }
        if(params.idOperatore){
            params.idOperatore = params.idOperatore.id;
            
        }else{
            delete params.idOperatore
        }

        
        //console.log('parametri');
       // console.log(params);
        
        
             
            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListMagMovimenti)
                        .then(result => {
                            if (result?.esito) {
                                setTabellaPrincipale(result.response)
                                setLoading(false)
                            }else{
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })             
            }     
    
 }


    useEffect(()=>{
        if(!search){
            setSpecific(false)
        }
        if(search && auth){
            //alert('searchParams')
            //console.log('shooooww');
            let vediamo:any = new URLSearchParams(search)
            let idFarmaciaP:any = vediamo.get('idFarm');
            let codCsfP:any =  vediamo.get('codCsf');
            let r_farma =  vediamo.get('r_farma');
            let d_p =  vediamo.get('d_p');


            setDettagli({
                n_farma:r_farma,
                d_prod:d_p
            })
            setSpecific(true)

            if (idFarmaciaP && codCsfP) {
                setIdFarmacia(idFarmaciaP)
                aperturaSpecifica(idFarmaciaP,codCsfP)
            }
        }else{
            if(auth){
               
            }
           
        }
       
        
        
    },[search,auth])

    useEffect(() => { 
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, [])

    useEffect(()=>{
        farmaList()
        //operList()
       
    },[auth])

    const ricarica =  () =>{
        setImmediate(false)
        setObjtipo(null)
        setOpenPop(false)
    }


    const aperturaSpecifica = (idFarm:any,codCsf:any) =>{
       // alert('pppp')
        let params = {
            idFarm:Number(idFarm),
            codCSF:codCsf,
            dal: trasformaDateTimeInLocale(new Date((new Date().getFullYear()-1)+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate()) , true),
            al: trasformaDateTimeInLocale(new Date() , true)
        }
        if (auth) {
            RestServices().rest('POST', params , urlConsultazione.getListMagMovimenti)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })             
    } 
    }



    return (
        <>
        <DialogMagMovime farmacia={idFarmacia} edit={objtipo} close={()=>{setOpenPop(false);setImmediate(false);setObjtipo(null)}} ricarica={()=>{ricarica();}}   open={openPop} />
        
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> Movimentazione Magazzino  <span className='subt' ></span> </h3>
             </div>
             
             {specific ? (
                <div style={{background:'white',padding: 10,borderRadius: 10,}}>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}>
                    <div style={{display: 'flex',justifyContent: 'space-between'}}><h4 style={{fontSize:22,margin:0}}>Dettaglio: </h4>
                    <div style={{marginLeft:10, marginRight:10, display: 'inline-block',background:' #dfdfdf', padding: 5, paddingLeft: 10,paddingRight: 10, borderRadius: 5}} >
                          <p style={{fontSize:13,margin:0}}>Farmacia: {dettagli?.n_farma}</p>
                      </div>
                      <div style={{marginLeft:10, marginRight:10, display: 'inline-block',background:' #dfdfdf', padding: 5, paddingLeft: 10,paddingRight: 10, borderRadius: 5}} >
                          <p style={{fontSize:13,margin:0}}>Prodotto: {dettagli?.d_prod}</p>
                      </div>
                      <div style={{marginLeft:10, marginRight:10, display: 'inline-block',background:' #dfdfdf', padding: 5, paddingLeft: 10,paddingRight: 10, borderRadius: 5}} >
                          <p style={{fontSize:13,margin:0}}>Dal: {trasformaDateTimeInLocale((new Date((new Date().getFullYear()-1)+'-'+(new Date().getMonth()+1)+'-'+new Date().getDate())),true)}</p>
                      </div>
                      <div style={{marginLeft:10, marginRight:10, display: 'inline-block',background:' #dfdfdf', padding: 5, paddingLeft: 10,paddingRight: 10, borderRadius: 5}} >
                          <p style={{fontSize:13,margin:0}}>Al: {trasformaDateTimeInLocale((new Date()),true)}</p>
                      </div>
           
                
                    </div>
                
                    <div><button style={{border:'none',padding:10}} className='btn btn-success btn btn-success' onClick={()=>{window.open("/app/consultazione/movimentazione" , '_self' )}}>Nuova Ricerca</button></div>
                    </div>
                </div>
             ) : (
                <Ricerca ricerca={(filtro)=>filterAction(filtro)} async={(filtro)=>operList(filtro)}   items={itemsFields}  />

             )}

            <NewTabella 
                idTabella='elenco_magazz_movime'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "vendita_consultazione"
                }}
                height='75vh'
                activeSaver
                columnC
                summary={
                    <Summary>
                        <TotalItem column="dataVendita" summaryType="count" />
                        <TotalItem column="importoVendita" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} /> 
                        <TotalItem column="scontoVendita" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                    </Summary>} 
            />


      </>
       )
}

export default MagMovimentazione