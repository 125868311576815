import React, { useState, useEffect, useRef  } from 'react'
import { Popup,  ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import Form, {
    ButtonItem,
    GroupItem,
    SimpleItem,
    Label,
    Item,
    CompareRule,
    EmailRule,
    PatternRule,
    RangeRule,
    RequiredRule,
    StringLengthRule,
    AsyncRule,
  } from 'devextreme-react/form';
import { text } from '@fortawesome/fontawesome-svg-core'

interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
    idBanca:number,
    listaFarmacie:any
} 




const customer = {
    Intestatario: '',
    codice: '',
    contoCorrente: '',
    iban: '',
    descrizione: '',
    idFar: '',
    idAzi: '',
  };
  
export const DialogGestConto:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit,idBanca,listaFarmacie},ref)  => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [azienda, setAzienda] = useState<any>([])
    const [formRef, setFormRef] = useState<any>();


    

    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            //console.log('mostro edit');
            //console.log(edit);   
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({
            "intestatario": '',
            "saldoIniziale": '',
            "codice": '',
            "iban":'',
            "idBanca": idBanca,
            "contoCorrente": '',
            "descrizione": '',
            "idAzi": '',
            "idFar": ''}) 
        }

    },[edit])



    const cambiaValore = (tipo:string,value:string) =>{
            let objTo = {...objctTOsave}
            objTo[tipo] = value
            setObjctTOsave(objTo)
            //console.log(objTo);   
            // obbligatorietà
            if(objTo.idAzi && objTo.intestatario?.length > 3 && objTo.iban?.length > 6 && objTo.contoCorrente?.length > 4  ){
                setDisableButton(false)
            }else{
                setDisableButton(true)
        }
    }


    const creaModifica = (modifica?:boolean) =>{
        objctTOsave.intestatario = objctTOsave.intestatario.toUpperCase()
        if (auth) {
            if(edit){
                let objj = {
                    "idConto": objctTOsave.id,
                    "intestatario": objctTOsave.intestatario,
                    "codice":objctTOsave.codice,
                    "iban":objctTOsave.iban,
                    "idBanca": objctTOsave.idBanca,
                    "contoCorrente": objctTOsave.contoCorrente,
                    "descrizione": objctTOsave.descrizione,
                    "idAzi": objctTOsave.idAzi,
                    "saldoIniziale": objctTOsave.saldoIniziale,
                    "idFar": objctTOsave.idFar
                  }
                
               RestServices().rest('POST', objj , urlConsultazione.putConto)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave({
                        "intestatario": '',
                        "codice": '',
                        "iban":'',
                        "idBanca": idBanca,
                        "contoCorrente": '',
                        "descrizione": '',
                        "saldoIniziale": '',
                        "idAzi": '',
                        "idFar": ''})
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }else{

                objctTOsave.idBanca = idBanca
               /* if(objctTOsave.idFar){
                    objctTOsave.idFar = objctTOsave.idFar.idFar
                }*/
                
                RestServices().rest('POST', objctTOsave , urlConsultazione.postConto)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave({"intestatario": '',
                        "iban":'',
                        "codice":'',
                        "idBanca": idBanca,
                        "contoCorrente": '',
                        "saldoIniziale": '',
                        "descrizione": '',
                        "idAzi": '',
                        "idFar": ''})
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }    
       } 
    }

    const getListAzie = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListAzienda)
               .then(result => {
                   if (result?.esito) {                  
                       setAzienda(result.response)                   
                   }else{                              
                       //console.log(result);                       
                   }
               })     
       }
   }


   

    useEffect(()=>{
        setOpenD(open)
        getListAzie()
    },[auth])


    useEffect(()=>{
        setOpenD(open)
    },[open])


    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'75%'}
          showTitle={true}
          title="Gestione Conto Corrente"
        >
            
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=> close()
              }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={typeEdit ? {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:disableButton,
                onClick: ()=>creaModifica(true),
              }: {
                text: 'CREA',
                class:'btncreatipocluster',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                //onClick: ()=>creaModifica(),
                onClick: ()=> formRef.dispatchEvent(
                    new Event("submit")
                ),
              }}
          />
        <ScrollView width='100%' height='100%'>
        <form  ref={ref => setFormRef(ref)}  onSubmit={(e)=>{
          e.preventDefault();
          //console.log(objctTOsave)
          creaModifica()
          }}>
        <Form
            colCount={2}
            formData={objctTOsave}
            readOnly={false}
            showColonAfterLabel={true}
            showValidationSummary={false}
            validationGroup="customerData"
            labelLocation={'top'}
          >
            
            
                <SimpleItem label={{text:'Intestatario'}}  editorOptions={{placeholder:'Inserire almeno 4 carateri per nome e cognome'}} dataField="intestatario" editorType="dxTextBox">
                <RequiredRule message="Intestatario richiesto" />
                <PatternRule message="Inserire almeno 4 carateri per nome e cognome" pattern={'( *?[0-9a-zA-Z] *?){4,}'} />
              </SimpleItem>

              <SimpleItem label={{text:'Codice'}}  editorOptions={{placeholder:'Inserire almeno 4 caratteri '}} dataField="codice" editorType="dxTextBox">
                <RequiredRule message="Intestatario richiesto" />
                <PatternRule message="Inserire almeno 4 caratteri " pattern={'( *?[0-9a-zA-Z] *?){4,}'} />
              </SimpleItem>

              <SimpleItem label={{text:'Conto corrente'}} editorOptions={{placeholder:'Inserire obbligatoriamente 12 caratteri'}} dataField="contoCorrente"  editorType='dxTextBox'>
                <RequiredRule message="Conto corrente obbligatorio" />
                <PatternRule message="Il conto deve essere di 12 numeri" pattern={'^[A-Za-z0-9]{12}$'} />
              </SimpleItem>
              
              
            <SimpleItem  label={{text:'Iban'}} editorOptions={{placeholder:'Inserire obbligatoriamente 27 caratteri'}} dataField="iban">
                <RequiredRule message="Iban è obbligatorio" />
                                <PatternRule message="Inserisci il formato corretto" pattern={/^(?:(?:IT|SM)\d{2}[A-Z]\d{10}[0-9A-Z]{12}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/i} />
              </SimpleItem>

              <SimpleItem label={{text:'Descrizione'}} editorOptions={{placeholder:'Inserire almeno 4 caratteri'}} dataField="descrizione">
                <PatternRule message="Inserire almeno 4 caratteri per nome e cognome" pattern={'( *?[0-9a-zA-Z] *?){4,}'} />
                <RequiredRule message="Descrizione è richiesta" />
              </SimpleItem>

              <Item label={{text:'Azienda'}} dataField="idAzi" editorType="dxSelectBox" editorOptions={{ items: azienda, searchEnabled: true, valueExpr:"id" , displayExpr:"codice"  }} 
              validationRules={[
                { type: 'required', message: 'Azienda è richiesta.' },
              ]} />
              <Item label={{text:'Farmacia'}} dataField="idFar" editorType="dxSelectBox" editorOptions={{ items: listaFarmacie, searchEnabled: true, valueExpr:"idFar", displayExpr:"descrizione" }} validationRules={[
                { type: 'required', message: 'Farmacia richiesta.' },
              ]}  />

       
           <SimpleItem label={{text:'Saldo iniziale'}}  dataField="saldoIniziale">         
              </SimpleItem>
          

            <ButtonItem cssClass='fixButton' horizontalAlignment="left"
              buttonOptions={{
                text: 'Salva',
                type: 'success',
                useSubmitBehavior: true,
              }}
            />
            
          </Form>
          </form>
          </ScrollView>
        
        
        </Popup>
        </>
      )

  
}
