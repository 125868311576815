import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../../interfaces/farmacie";
import { RestServices } from "../../../../services/restServices";
import { Filtri } from "../../../consultazione/interface";
import FiltriVerticale, { ConvertForSelect } from "../../../dashboards/filters/FiltriVerticaleNewVersione";
import { PieChart } from "devextreme-react";
import { Title, Export, Font, Series, HoverStyle, Tooltip } from "devextreme-react/pie-chart";
import { cardC, CardCart, typeShow } from "../../components/card_chart";
import { trasform_for_cards_charts_01, transformCategory, transformDitta } from "../../util/trasformation";
import { createItemFilterAnalVendCategory } from "../../operativo/ItemsFiltri/FiltriAnalOperativo";
import { AplicaValoreTO, getDayName } from "../../../../funzioni/operazioniSuTabelle";
import { urlBancaDati, urlConsultazione, urlReport } from "../../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../../widget/nuova_tabella/newBusineVersion";

import "../../operativo/style.css";

const boxCardVendCategory: cardC[] = [
    {
        testo: "Numero giorni lavorati",
        subTesto: "Numero giorni lavorati anno precedente",
        valore: "GIORNI_LAVORATISL",
        subValore: "GIORNI_LAVORATIPR",
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente � coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Valore del venduto",
        subTesto: "Delta anno precedente",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        valore: "VALORE_VENDUTOSL",
        subValore: "VALORE_VENDUTO_PUNTUALEPR",
        testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
        testo: "Imponibile ",
        subTesto: "Delta anno precedente",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        valore: "VALORE_VENDUTO_NETTO_IVAPR",
        subValore: "",
        testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
        testo: "Margine",
        subTesto: "Delta anno precedente",
        valore: "MARGINESL",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        subValore: "MARGINE_PUNTUALEPR",
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente � coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Margine % ",
        subTesto: "Delta anno precedente",
        typeShowValue: typeShow.percento,
        typeShowSubValue: typeShow.percento,
        valore: "MARGINE_PERCENTUALESL",
        subValore: "MARGINE_PERCENTUALE_PUNTUALEPR",
        testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
        testo: "Volume di vendita",
        subTesto: "Delta anno precedente",
        typeShowSubValue: typeShow.percento,
        valore: "VOLUME_VENDITASL",
        subValore: "VOLUME_VENDITA_PUNTUALEPR",
        testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
];
interface DialogProps {
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
}

const GalileoVenCategory = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardVendCategory);
    const [settori, setSettori] = useState<any[]>([]);
    const [category, setCategory] = useState<any[]>([]);
    const [ditte, setDitte] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [pie, setPie] = useState<any[]>([]);
    const [secondpie, setSecondPie] = useState<any[]>([]);
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const dashboard37 = { dashboardID: 37 };

    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let venditaDPC = params?.venditaDPC ? [] : [0];
        let flagBusta = params?.flagBusta ? [] : [0];

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall37 = {
                ...dashboard37,
                idFarList: params.idFar,
                params: parameters,
            };



            RestServices()
                .rest("POST", dashboardCall37, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {

                        let datiPrimaParteCards = trasform_for_cards_charts_01(result["104"], boxCardVendCategory);
                        setFila1cards(datiPrimaParteCards);

                        const anno = params.periodo.dal.substring(0, 4)

                        let datiPrimaTabella = transformCategory(result["105"], anno);
                        console.log(datiPrimaTabella);
                        setValoriTabella1(datiPrimaTabella);

                        let datiSecondaTabella = transformDitta(result["127"], anno);
                        console.log(datiSecondaTabella);
                        setValoriTabella2(datiSecondaTabella);


                        setPie(result["37"]);



                        // ID 37
                        const chartSSNPie = result["37"]
                            .map((item: any) => {
                                const isSSN = item.VEN_FLAG_SSN;
                                const value = item.VALORE_VENDUTO;
                                return {
                                    SSN: isSSN,
                                    VALORE_VENDUTO: value,
                                    SSNDESC: isSSN ? "SSN" : "Libera",
                                };
                            })
                            .reverse();

                        // 1 chartPie ID 37

                        const total = chartSSNPie.reduce(
                            (sum: any, item: { VALORE_VENDUTO: any }) =>
                                sum + item.VALORE_VENDUTO,
                            0
                        );

                        // Aggiunta della percentuale per ogni valore
                        const chartSSNPieConPercentuale = chartSSNPie.map(
                            (item: { VALORE_VENDUTO: number }) => {
                                // Calcolo della percentuale per ogni valore
                                const percentuale = (
                                    (item.VALORE_VENDUTO / total) *
                                    100
                                ).toFixed(2);

                                return {
                                    ...item,
                                    PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
                                };
                            }
                        );

                        setSecondPie(chartSSNPieConPercentuale);


                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });


            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });


        }
    };

    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };

    let messOne = ["Val 1 Pie chart"];
    let messTwo = ["Val 2 Pie chart"];

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);


    const itemsFields = [
       
            { type: "dateRange", idValue: "periodo", width: "auto", require: true },
            {
                type: "newSelectorFarma",
                idValue: "idFar",
                label: "Farmacie ",
                displayExpr: "text",
                dataSource: ConvertForSelect(newFarmaList),
                idValueGrid: "idFar",
                require: true,
            },
            {
                type: "selectWithLevels",
                noId: true,
                id: "category",
                label: "Category",
                displayExpr: "descrizione",
                dataSource: category,
                idValueGrid: "category" as any,
            },
            {
                type: "selectMultiplaMoreColon",
                id: "codiceSettore",
                label: "Settore di inventario ",
                displayExpr: "descrizione",
                dataSource: settori,
                idValueGrid: "codiceSettore" as any,
            },
            {
                type: "selectMultiplaMoreColon",
                id: "ditta",
                label: "Ditta",
                displayExpr: "descrizione",
                allowSelectAll: false,
                dataSource: ditte,
                idValueGrid: "ditta" as any,
            },
            //{
                //type: "ckBox",
                //idValue: "venditaDPC",
                //label: "Includi DPC",
                //dataSource: null,
                //width: 200,
            //},
            //{
                //type: "ckBox",
                //idValue: "flagBusta",
                //label: "Includi BUSTE",
                //dataSource: null,
                //width: 200,
            //},
        ];


    const filters = () => {
        if (auth) {

            RestServices()
                .rest("POST", "", urlBancaDati.getSettore)
                .then((result) => {
                    if (result) setSettori(result);
                });

            RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
                // console.log('vediamoooo categorie');
                // console.log(result);
                if (result) setCategory(result);
            })

            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );
                        // console.log('da togliere vediamo ditte');
                        // console.log(ditteWithCodiceDescrizione);

                        setDitte(ditteWithCodiceDescrizione);
                    }
                });
        }

        const ssn = { id: 1, descrizione: "SSN" };
        const libera = { id: 0, descrizione: "Libera" };

        const vendite = [libera, ssn];
        setTipologiaVendita(vendite);
    };

    useEffect(() => {
        filters();
    }, [auth]);

    const columnsAnalisiVendOperativo = [
        {
            caption: "Category",
            dataField: "PRO_DESC_CATEGORY1AP",
            width: 200,
            sortOrder: "asc",
            fixed: true,
        },
        {
            caption: "Incidenza % valore del venduto",
            dataField: "INCIDENZA_VALORE_VENDUTOCR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Valore del venduto periodo selezionato",
            dataField: "VALORE_VENDUTOCR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Valore del venduto anno precedente",
            dataField: "VALORE_VENDUTOAP",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Delta valore del venduto ",
            dataField: "VALORE_VENDUTO_PUNTUALECR",
            width: "auto",
            format: { precision: 2 },
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta valore del venduto equiparato",
            dataField: "VALORE_VENDUTO_EQUIPARATOCR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Margine periodo selezionato",
            dataField: "MARGINECR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Delta margine",
            dataField: "MARGINE_PUNTUALECR",
            width: "auto",
            format: { precision: 2 },
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta margine equiparato",
            dataField: "MARGINE_EQUIPARATOCR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Margine percentuale periodo selezionato",
            dataField: "MARGINE_PERCENTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta margine percentuale",
            dataField: "MARGINE_PUNTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Volume di vendita periodo selezionato",
            dataField: "VOLUME_VENDITACR",
            width: "auto",
        },
        {
            caption: "Delta volume di vendita",
            dataField: "VOLUME_VENDITA_PUNTUALECR",
            width: "auto",
            format: { precision: 2 },
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta volume di vendita equiparato",
            dataField: "VOLUME_VENDITA_EQUIPARATOCR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
    ];

    const columnsAnalisiVendOperativo2 = [
        {
            caption: "Ditta",
            dataField: "PRO_DESC_DITTA_TITOLAREAP",
            width: 200,
            sortOrder: "asc",
            fixed: true,
        },
        {
            caption: "Incidenza % valore del venduto",
            dataField: "INCIDENZA_VALORE_VENDUTOCR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Valore del venduto periodo selezionato",
            dataField: "VALORE_VENDUTOCR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Valore del venduto anno precedente",
            dataField: "VALORE_VENDUTOAP",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Delta valore del venduto ",
            dataField: "VALORE_VENDUTO_PUNTUALECR",
            width: "auto",
            format: { precision: 2 },
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta valore del venduto equiparato",
            dataField: "VALORE_VENDUTO_EQUIPARATOCR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Margine periodo selezionato",
            dataField: "MARGINECR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Delta margine",
            dataField: "MARGINE_PUNTUALECR",
            width: "auto",
            format: { precision: 2 },
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta margine equiparato",
            dataField: "MARGINE_EQUIPARATOCR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Margine percentuale periodo selezionato",
            dataField: "MARGINE_PERCENTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta margine percentuale",
            dataField: "MARGINE_PUNTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Volume di vendita periodo selezionato",
            dataField: "VOLUME_VENDITACR",
            width: "auto",
        },
        {
            caption: "Delta volume di vendita",
            dataField: "VOLUME_VENDITA_PUNTUALECR",
            width: "auto",
            format: { precision: 2 },
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
        {
            caption: "Delta volume di vendita equiparato",
            dataField: "VOLUME_VENDITA_EQUIPARATOCR",
            width: "auto",
            customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
        },
    ];

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabellaAnalisiVenditeCategory");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabellaAnalisiVenditeCategory.csv"
                );
            });
        });
    };



    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    return (
        <>
            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>Vendite Category</h2>
                        </div>
                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >
                            
                        </div>

                        {/* flexbox */}

                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>


                        <div style={{ paddingBottom: 5 }} className="grid-container-six">
                            {fila1cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>

                        <div style={{ marginTop: 5, paddingBottom: 20 }}>

                            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: 10 }}>Tabella Dettaglio per category</h6>

                            <NewTabella
                                height={"70vh"}
                                idTabella="GalileoVenditeCategory"
                                sync
                                colonne={columnsAnalisiVendOperativo}
                                dataSource={valoriTabella1}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "TabellaDettaglio",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem
                                            column="VALORE_VENDUTOCR"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                        <TotalItem
                                            column="VALORE_VENDUTOAP"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                        <TotalItem
                                            column="MARGINECR"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                        <TotalItem
                                            column="VOLUME_VENDITACR"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                    </Summary>
                                }
                                />
                            </div>

                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        backgroundColor: "white",
                                        marginTop: 5,
                                        marginBottom: 10,
                                        paddingBottom: 10
                                    }}
                                >
                                   

                                    {/* 2 PieChart */}

                                    <div style={{ width: "45%" }}>
                                        <PieChart
                                            id="pie"
                                            dataSource={secondpie}
                                            palette="Green Mist"
                                            paletteExtensionMode="alternate"
                                            type="doughnut"

                                        //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
                                        >
                                            <Series argumentField="SSNDESC" valueField="PERCENTUALE">
                                                <HoverStyle color="rgb(255, 0, 0)" />
                                            </Series>
                                            <Tooltip
                                                enabled={true}
                                                customizeTooltip={({
                                                    argument,
                                                    value,
                                                }: {
                                                    argument: any;
                                                    value: any;
                                                }) => {
                                                    const dataItem = secondpie.find(
                                                        (item: any) => item.SSNDESC === argument
                                                    );
                                                    const dataSourceValue = dataItem
                                                        ? dataItem.VALORE_VENDUTO
                                                        : 0;
                                                    const formattedValue = new Intl.NumberFormat("it-IT", {
                                                        style: "currency",
                                                        currency: "EUR",
                                                    }).format(dataSourceValue);
                                                    const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                                                    const percentualeFormattata =
                                                        percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                                                    return {
                                                        text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                                                    };
                                                }}
                                            />
                                            <Title text="Valore del venduto per tipologia di vendita">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Title>

                                            <Export enabled={true} />
                                        </PieChart>
                                    </div>
                                    <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                                        <button
                                            className="ibutton-lite-two"
                                            style={{ border: "none" }}
                                            onClick={() => popVisible2("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                </div>
                            </div>
                            <div style={{ marginTop: 5, paddingBottom: 20 }}>

                                <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: 10 }}>Tabella Dettaglio per Ditta</h6>

                                <NewTabella
                                    height={"70vh"}
                                    idTabella="GalileoVenditeCategory"
                                    sync
                                    colonne={columnsAnalisiVendOperativo2}
                                    dataSource={valoriTabella2}
                                    rowAlternation={true}
                                    filterRow
                                    width={500}
                                    searchPanel={true}
                                    exportT={{
                                        enabled: true,
                                        fileName: "TabellaDettaglio",
                                        formats: { exportFormats },
                                    }}
                                    activeSaver={true}
                                    columnC={true}
                                    headerFilter
                                    wordWrapEnabled={true}
                                    onExporting={onExporting}
                                    summary={
                                        <Summary>
                                            <TotalItem
                                                column="VALORE_VENDUTOCR"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", currency: "EUR" }}
                                            />
                                            <TotalItem
                                                column="VALORE_VENDUTOAP"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", currency: "EUR" }}
                                            />
                                            <TotalItem
                                                column="MARGINECR"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", currency: "EUR" }}
                                            />
                                            <TotalItem
                                                column="VOLUME_VENDITACR"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", currency: "EUR" }}
                                            />
                                        </Summary>
                                    }
                                />
                            </div>
                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
                <Popup
                    visible={visiblePop1}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop1(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messOne}</p>
                    </div>
                </Popup>
                <Popup
                    visible={visiblePop2}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop2(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messTwo}</p>
                    </div>
                </Popup>
            </section>
        </>
    );
};

export default GalileoVenCategory;
