import { DataGrid } from 'devextreme-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getDescrFarmFromId } from '../../funzioni/anagrafica'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { DatiTabelleGiacenzeInterface } from '../../interfaces/ordineLibero'
import { RestServices } from '../../services/restServices'
import { urlCommon } from '../costanti'
import { Column } from 'devextreme-react/data-grid'

const GrigliaVendutoRda = (props: {
    codiceCsfSelez: number | undefined,
    farmSelez: number[] | undefined,
    setIsLoadVisible: (isLoadVisible: boolean) => void,
    listaFarmacie: ListaFarmacieInterface[],
    id?: string,
    acquistato?: boolean,
    soloAcquistato?:boolean
}) => {
    const [datiVenduto, setDatiVenduto] = useState<DatiTabelleGiacenzeInterface[]>();
    const [datiAcquistato, setDatiAcquistato] = useState<DatiTabelleGiacenzeInterface[]>();
    const [mese, setMese] = useState("");

    useEffect(() => {
        const meseNumero = new Date().getMonth();
        const elencoMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
        setMese(elencoMesi[meseNumero]);
    }, [])

    useEffect(() => {
        if (props.codiceCsfSelez && props.farmSelez) {
            props.setIsLoadVisible(true);
            const param = {
                lstFar: props.farmSelez,
                codCsf: props.codiceCsfSelez
            }
            if (props.soloAcquistato != undefined && props.soloAcquistato != true) {
                RestServices().rest('POST', param, urlCommon.getVenduto)
                    .then(result => {
                        if (result) {
                            if (result[0]) {
                                setDatiVenduto(result[0].venduto);
                                props.setIsLoadVisible(false);
                            }

                        } else {
                            props.setIsLoadVisible(false);
                        }

                    })
            }
            

            if (props.acquistato) {
                RestServices().rest('POST', param, urlCommon.getAcquistato)
                    .then(result => {
                        if (result) {
                            if (result[0]) {
                                setDatiAcquistato(result[0].acquistato);
                                props.setIsLoadVisible(false);
                            }

                        } else {
                            props.setIsLoadVisible(false);
                        }

                    })
            }
        }
    }, [props.codiceCsfSelez, props.farmSelez])

    return (
        <>
            {(datiVenduto) && (props.acquistato == undefined || props.acquistato == false) && 
                <Griglia
                farmacia={props.farmSelez}
                datiVenduto={datiVenduto}
                mese={mese}
                listaFarmacie={props.listaFarmacie}
                id={ props.id}
                />
            }

            {(datiAcquistato) && (props.acquistato != undefined && props.acquistato == true) && (props.soloAcquistato != undefined && props.soloAcquistato == true) &&
                <Griglia
                    farmacia={props.farmSelez}
                    datiVenduto={datiAcquistato}
                    mese={mese}
                    listaFarmacie={props.listaFarmacie}
                    id={props.id}
                />
            }


            {(datiVenduto) && (props.acquistato != undefined && props.acquistato == true) && (props.soloAcquistato == undefined || props.soloAcquistato == false) &&
                <GrigliaVendutoAcquistato
                    farmacia={props.farmSelez}
                datiVenduto={datiVenduto}
                datiAcquistato={datiAcquistato }
                    mese={mese}
                    listaFarmacie={props.listaFarmacie}
                    id={props.id}
                />
            }

        </>
    )
}

export default GrigliaVendutoRda;


const Griglia = (props: {
    datiVenduto: DatiTabelleGiacenzeInterface[],
    mese: string,
    farmacia: number[] | undefined,
    listaFarmacie: ListaFarmacieInterface[],
    id?:string
}) => {
    const [datiGriglia, setDatiGriglia] = useState<DatiTabelleGiacenzeInterface[]>();
    const [descrizioneFarmacia, setDescrizioneFarmacia] = useState("");

    useEffect(() => {
        setDatiGriglia(props.datiVenduto)
    }, [props.datiVenduto])

    useEffect(() => {
        if (props.farmacia && props.farmacia[0] != 0) {
            const descr = getDescrFarmFromId(props.listaFarmacie, props.farmacia[0]);
            setDescrizioneFarmacia(descr);
        } else if (props.farmacia && props.farmacia[0] == 0) {
            setDescrizioneFarmacia("Magazzino Centrale");
        }

    }, [props.farmacia])

    const colonneTabVenduto = useMemo(() => CreaColonne([
        { caption: "", dataField: "ANNO" },
        { caption: "Tot", dataField: "TOT" },
        { caption: "Gen", dataField: "Gennaio", },
        { caption: "Feb", dataField: "Febbraio", },
        { caption: "Mar", dataField: "Marzo", },
        { caption: "Apr", dataField: "Aprile", },
        { caption: "Mag", dataField: "Maggio", },
        { caption: "Giu", dataField: "Giugno", },
        { caption: "Lug", dataField: "Luglio", },
        { caption: "Ago", dataField: "Agosto", },
        { caption: "Set", dataField: "Settembre", },
        { caption: "Ott", dataField: "Ottobre", },
        { caption: "Nov", dataField: "Novembre", },
        { caption: "Dic", dataField: "Dicembre", }
    ]), [])

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "data" && e.column && e.column.dataField) {
            if (e.column.dataField === "ANNO" || e.column.dataField === "TOT") {
                e.cellElement.style.fontWeight = "900";
            } else if (e.column.dataField === props.mese) {
                //e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
                e.cellElement.style.backgroundColor = "rgb(255, 255, 0)"
            }
        }
    }, [])

    return (
        <>
            <div id={props.id}><b>{descrizioneFarmacia}</b></div>
            {(datiGriglia && props.farmacia) && <DataGrid
                id={props.farmacia[0].toString()}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                dataSource={datiGriglia}
                onCellPrepared={onCellPrepared}
            >
                {colonneTabVenduto}
            </DataGrid>}
        </>
    )
}

const GrigliaVendutoAcquistato = (props: {
    datiVenduto: DatiTabelleGiacenzeInterface[],
    mese: string,
    farmacia: number[] | undefined,
    listaFarmacie: ListaFarmacieInterface[],
    id?: string,
    datiAcquistato?: DatiTabelleGiacenzeInterface[],

}) => {
    const [datiGriglia, setDatiGriglia] = useState<DatiTabelleGiacenzeInterface[]>();
    const [descrizioneFarmacia, setDescrizioneFarmacia] = useState("");

    useEffect(() => {
        //merge dei due
        let datiMerge: any [] = [];
        props.datiVenduto.forEach((ven) => {
            let anno = ven.ANNO

            let totVen = ven.TOT
            let gennaioV = ven.Gennaio
            let febbraioV = ven.Febbraio
            let marzoV = ven.Marzo
            let aprileV = ven.Aprile
            let maggioV = ven.Maggio
            let giugnoV = ven.Giugno
            let luglioV = ven.Luglio
            let agostoV = ven.Agosto
            let settembreV = ven.Settembre
            let ottobreV = ven.Ottobre
            let novembreV = ven.Novembre
            let dicembreV = ven.Dicembre


            let totAcq = 0
            let gennaioA = 0
            let febbraioA = 0
            let marzoA = 0
            let aprileA = 0
            let maggioA = 0
            let giugnoA = 0
            let luglioA = 0
            let agostoA = 0
            let settembreA =0
            let ottobreA = 0
            let novembreA =0
            let dicembreA = 0

            //trovare gli acquisti
            if (props.datiAcquistato) {
                let f = props.datiAcquistato.filter((acq) => {
                    return acq.ANNO == anno;
                })

                 totAcq = f[0].TOT
                 gennaioA = f[0].Gennaio
                 febbraioA = f[0].Febbraio
                 marzoA = f[0].Marzo
                 aprileA = f[0].Aprile
                 maggioA = f[0].Maggio
                 giugnoA = f[0].Giugno
                 luglioA = f[0].Luglio
                 agostoA = f[0].Agosto
                 settembreA = f[0].Settembre
                 ottobreA = f[0].Ottobre
                 novembreA = f[0].Novembre
                 dicembreA = f[0].Dicembre
            }


            let obj = {
                ANNO: anno,
                TOT: totVen,
                TOTA: totAcq,
                Gennaio: gennaioV,
                Febbraio: febbraioV,
                Marzo: marzoV,
                Aprile: aprileV,
                Maggio: maggioV,
                Giugno: giugnoV,
                Luglio: luglioV,
                Agosto: agostoV,
                Settembre: settembreV,
                Ottobre: ottobreV,
                Novembre: novembreV,
                Dicembre: dicembreV,
                GennaioA: gennaioA,
                FebbraioA: febbraioA,
                MarzoA: marzoA,
                AprileA: aprileA,
                MaggioA: maggioA,
                GiugnoA: giugnoA,
                LuglioA: luglioA,
                AgostoA: agostoA,
                SettembreA: settembreA,
                OttobreA: ottobreA,
                NovembreA: novembreA,
                DicembreA: dicembreA


                
            }
            datiMerge.push(obj)

        })
        setDatiGriglia(datiMerge)
    }, [props.datiVenduto, props.datiAcquistato])

    useEffect(() => {
        if (props.farmacia && props.farmacia[0] != 0) {
            const descr = getDescrFarmFromId(props.listaFarmacie, props.farmacia[0]);
            setDescrizioneFarmacia(descr);
        } else if (props.farmacia && props.farmacia[0] == 0) {
            setDescrizioneFarmacia("Magazzino Centrale");
        }

    }, [props.farmacia])

    //const colonneTabVenduto = useMemo(() => CreaColonne([
    //    { caption: "", dataField: "ANNO" },
    //    { caption: "Tot", dataField: "TOT" },
    //    { caption: "Gen", dataField: "Gennaio", },
    //    { caption: "Feb", dataField: "Febbraio", },
    //    { caption: "Mar", dataField: "Marzo", },
    //    { caption: "Apr", dataField: "Aprile", },
    //    { caption: "Mag", dataField: "Maggio", },
    //    { caption: "Giu", dataField: "Giugno", },
    //    { caption: "Lug", dataField: "Luglio", },
    //    { caption: "Ago", dataField: "Agosto", },
    //    { caption: "Set", dataField: "Settembre", },
    //    { caption: "Ott", dataField: "Ottobre", },
    //    { caption: "Nov", dataField: "Novembre", },
    //    { caption: "Dic", dataField: "Dicembre", }
    //]), [])

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "data" && e.column && e.column.dataField) {
            if (e.column.dataField === "ANNO" || e.column.dataField === "TOT" || e.column.dataField === "TOTA") {
                e.cellElement.style.fontWeight = "900";
            } else if (e.column.dataField === props.mese || e.column.dataField === props.mese + 'A') {
                //e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
                e.cellElement.style.backgroundColor = "rgb(255, 255, 0)"
            }
            else if (e.column.caption === 'A') {
                e.cellElement.style.backgroundColor = "rgb(235, 181, 181)"
            }
            else if (e.column.caption === 'V') {
                e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
            }
        }
    }, [])

    return (
        <>
            <div id={props.id}><b>{descrizioneFarmacia}</b></div>
            {(datiGriglia && props.farmacia) && <DataGrid
                id={props.farmacia[0].toString()}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                dataSource={datiGriglia}
                onCellPrepared={onCellPrepared}
            >
                <Column dataField="ANNO" caption="">
                </Column>
                <Column caption="Tot">
                    <Column
                        dataField="TOT"
                        caption="V"
                    />
                    <Column
                        dataField="TOTA"
                        caption="A"
                    />
                </Column>
                <Column caption="Gen">
                    <Column
                        dataField="Gennaio"
                        caption="V"
                    />
                    <Column
                        dataField="GennaioA"
                        caption="A"
                    />
                </Column>
                <Column caption="Feb">
                    <Column
                        dataField="Febbraio"
                        caption="V"
                    />
                    <Column
                        dataField="FebbraioA"
                        caption="A"
                    />
                </Column>
                <Column caption="Mar">
                    <Column
                        dataField="Marzo"
                        caption="V"
                    />
                    <Column
                        dataField="MarzoA"
                        caption="A"
                    />
                </Column>
                <Column caption="Apr">
                    <Column
                        dataField="Aprile"
                        caption="V"
                    />
                    <Column
                        dataField="AprileA"
                        caption="A"
                    />
                </Column>
                <Column caption="Mag">
                    <Column
                        dataField="Maggio"
                        caption="V"
                    />
                    <Column
                        dataField="MaggioA"
                        caption="A"
                    />
                </Column>
                <Column caption="Giu">
                    <Column
                        dataField="Giugno"
                        caption="V"
                    />
                    <Column
                        dataField="GiugnoA"
                        caption="A"
                    />
                </Column>
                <Column caption="Lug">
                    <Column
                        dataField="Luglio"
                        caption="V"
                    />
                    <Column
                        dataField="LuglioA"
                        caption="A"
                    />
                </Column>
                <Column caption="Ago">
                    <Column
                        dataField="Agosto"
                        caption="V"
                    />
                    <Column
                        dataField="AgostoA"
                        caption="A"
                    />
                </Column>
                <Column caption="Set">
                    <Column
                        dataField="Settembre"
                        caption="V"
                    />
                    <Column
                        dataField="SettembreA"
                        caption="A"
                    />
                </Column>
                <Column caption="Ott">
                    <Column
                        dataField="Ottobre"
                        caption="V"
                    />
                    <Column
                        dataField="OttobreA"
                        caption="A"
                    />
                </Column>
                <Column caption="Nov">
                    <Column
                        dataField="Novembre"
                        caption="V"
                    />
                    <Column
                        dataField="NovembreA"
                        caption="A"
                    />
                </Column>
                <Column caption="Dic">
                    <Column
                        dataField="Dicembre"
                        caption="V"
                    />
                    <Column
                        dataField="DicembreA"
                        caption="A"
                    />
                </Column>
            </DataGrid>}
        </>
    )
}