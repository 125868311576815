import React, { useState, useEffect, useRef   } from 'react'
import TextBox from 'devextreme-react/text-box'
import AsyncSelect from "react-select/async"
import { RestServices } from "../../services/restServices"
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import Button from 'devextreme-react/button'

import {
    Validator,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    EmailRule
    
  } from 'devextreme-react/validator';


import '../../widget/nuova_ricerca/style.css'


const AnaGruppo = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

        
    const auth = useAuth(urlConsultazione.auth);
    const [loading,setLoading] = useState<boolean>(false)
    const [infoG,setInfoG] = useState<any>(null)

   const getGruppo = () =>{
    if (auth) {
        //alert('inizio')
       RestServices().rest('POST', {} , urlConsultazione.getAnaGruppo)
           .then(result => {
               if (result?.esito) {               
                    //console.log(result.response);
                    setInfoG(result.response)                                                   
               }else{                                
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                       
               }
           })     
   }
 }



   
    const cambioValue = (value:any,key:string) =>{
        //console.log(value);    
        let newInfo = {...infoG}
        newInfo[key] = value
        //console.log(newInfo);       
        setInfoG(newInfo)
    }

   
    
    const salva = ()=>{
        let updater = {
            "id": infoG.id,
            "cap":  infoG.cap,
            "codice": infoG.codice,
            "descrizione": infoG.descrizione,
            "email": infoG.email,
            "fax": infoG.codfaxice,
            "indirizzo": infoG.indirizzo,
            "referente": infoG.referente,
            "sito": infoG.sito,
            "telefono": infoG.telefono,
            "telefonoReferente": infoG.referenteTelefono,
            'idComune':infoG.idComune,
            "piva":infoG.partitaIVA,
        }
        if (auth) {
            //alert('inizio')
           RestServices().rest('POST', updater , urlConsultazione.updateAnaGruppo)
               .then(result => {
                   if (result?.esito) { 
                       // alert('ok salvato')
                       notify({ position: "center", width: "auto", message: "Hai modificato i campi!"}, "success", 5000)
                                                              
                   }else{
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    setLoading(false)
                }
               })     
       }
    }

    const loadOptions = (
            inputValue: string,
            callback: (options: any) => void
            ) => {
                let idfartoUse = null
                //console.log(inputValue);
                         
                if(inputValue){
                    RestServices().rest('POST', {
                        filter:inputValue
                    } , urlConsultazione.getListComuni)
                    .then((result: { esito: any; response: any }) => {
                        if (result?.esito) {

                            let arrToUse:any = []
                            result.response.forEach((element:any) => {
                                arrToUse.push({value:element.id,label:element.comune+' ('+element.provincia+')'})
                            });
                            //console.log('vediamo reiuok');
                            
                            //console.log(result.response);
                            //console.log(arrToUse);
                                
                            callback(arrToUse);
                            
                        }else{
                                       
                            //console.log(result);
                            callback([{value:'pp',label:'no'}]);
                            
                        }
                        
                    }) 
                }
               
                
          };

    
    useEffect(() => {     
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    useEffect(()=>{
        if(auth){
            getGruppo()
        }
        
     },[auth])

    
    const defValue = () =>{
        alert(infoG?.cap)
        
        return {id:4234234234,label:infoG?.comune}
    } 


      
   
    return (
        <>
           

            <div  className='title'>
                <h3> ANAGRAFICA GRUPPO </h3>
             </div>
             <form action="your-action" onSubmit={(e)=>{
                    salva()
                    e.preventDefault();
                }}>
             <div className='form-row '> 
                <div className="form-group col-md-4">
                       <label> CODICE </label> 
                       <TextBox maxLength="11" onValueChanged={(e:any)=>cambioValue(e.value,'codice')}  value={infoG?.codice} valueChangeEvent="keyup" showClearButton={true}  placeholder="Campo obbligatorio max 10 caratteri" >
                        <Validator>
                            <RequiredRule message="Codice Obbligatoria" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                            <StringLengthRule message="Il codice deve essere massimo di 10 caratteri" max={10} />
                        </Validator>
                      </TextBox> 
                    </div>
                    <div className="form-group col-md-4">
                    <label> DESCRIZIONE </label> 
                       <TextBox maxLength="51" onValueChanged={(e:any)=>cambioValue(e.value,'descrizione')}  value={infoG?.descrizione}  valueChangeEvent="keyup" showClearButton={true}   placeholder="Campo obbligatorio max 50 caratteri" >
                        <Validator>
                            <RequiredRule message="DESCRIZIONE Obbligatoria" />
                            <StringLengthRule message="massimo di 50 caratteri" max={50} />
                        </Validator>   
                        </TextBox> 
                    </div>
                                     
                <div className="form-group col-md-4">
                       <label> PARTITA IVA </label> 
                       <TextBox maxLength="11" onValueChanged={(e:any)=>cambioValue(e.value,'partitaIVA')} value={infoG?.partitaIVA} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci solo numeri e max 11 caratteri" >
                         <Validator>
                            <PatternRule message="11 numeri" pattern={/^[0-9]{11,11}$/} />
                            <StringLengthRule message="11 caratteri obbligatori"  max={11} />
                        </Validator>
                        </TextBox> 
                    </div>
                    </div>

                    <div className='form-row '> 
                    <div className="form-group col-md-4">
                    <label> INDIRIZZO </label> 
                       <TextBox maxLength={101} onValueChanged={(e:any)=>cambioValue(e.value,'indirizzo')}  value={infoG?.indirizzo} valueChangeEvent="keyup" showClearButton={true}   placeholder="Inserisci..." >
                        <Validator>
                            <StringLengthRule message="massimo di 100 caratteri" max={100} />
                        </Validator>
                        </TextBox> 
                    </div>
                     
                    <div className="form-group col-md-4">
                       <label> CAP </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'cap')}  value={infoG?.cap} valueChangeEvent="keyup" showClearButton={true} maxLength='5'  placeholder="Inserisci ...." >
                        <Validator>
                            <PatternRule message="solo numeri" pattern={/^[0-9]{1,5}$/} />
                            <StringLengthRule message="massimo 5"  max={5} />
                        </Validator>
                        </TextBox> 
                    </div>

                    <div className="form-group col-md-4">
                   <label> COMUNE  </label> 
                   
                   {infoG?.comune ? (
                    
                    <AsyncSelect
                        //styles={customStyles}
                        placeholder="cerca"
                        isClearable= {true}
                        defaultValue={{id:322,label:infoG?.comune }}
                        loadOptions={loadOptions}
                        onChange={(x:any)=>cambioValue(x?.value,'idComune') }
                        /> 
                       
                   ) : (
                    <div>
                    <AsyncSelect
                         //styles={customStyles}
                         placeholder="cerca"
                         isClearable= {true}
                         loadOptions={loadOptions}
                         onChange={(x:any)=>cambioValue(x?.value,'idComune') }
                         /> 
                    </div>     

                   )}
                   
                   </div>
                    </div>




                    <div className='form-row '> 
                <div className="form-group col-md-4">
                       <label> TELEFONO </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'telefono')}  value={infoG?.telefono}  valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci ...." /> 
                    </div>

                    <div className="form-group col-md-4">
                    <label> FAX </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'fax')}  value={infoG?.fax}  valueChangeEvent="keyup" showClearButton={true}   placeholder="Inserisci..." /> 
                    </div>
                              
                <div className="form-group col-md-4">
                       <label> EMAIL </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'email')} value={infoG?.email} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci ...." >
                        <Validator>
                            <EmailRule message="Deve essere un formato email" />
                        </Validator>
                        </TextBox> 
                    </div>
                    </div>

                    <div className='form-row '> 
                    <div className="form-group col-md-4">
                    <label> SITO </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'sito')}  value={infoG?.sito} valueChangeEvent="keyup" showClearButton={true}   placeholder="Inserisci..." /> 
                    </div>
                    
                <div className="form-group col-md-4">
                       <label> REFERENTE </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'referente')} value={infoG?.referente} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci" /> 
                    </div>
                    <div className="form-group col-md-4">
                    <label> TEL. REFERENTE </label> 
                       <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'referenteTelefono')} value={infoG?.referenteTelefono}  valueChangeEvent="keyup" showClearButton={true}   placeholder="Inserisci..." /> 
                    </div>
                    </div>

                  <div style={{display:'flex', justifyContent: 'center', marginTop:7}}>
                  <Button
                    id="button"
                    text="SALVA"
                    type="success"
                    useSubmitBehavior={true} />
            <Button style={{ marginLeft:7}} className=''  onClick={()=>getGruppo()}   >
              ANNULLA
            </Button>
            </div>
            </form>
            
        </>
    )
}

export default  AnaGruppo
