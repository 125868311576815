import { AplicaValoreTO, getDayName } from "../../../../funzioni/operazioniSuTabelle";


export const columnsAnalisiVendDitta = [
    {
        caption: "Ditta",
        dataField: "PRO_DESC_DITTA_TITOLARE",
        width: "auto",
        fixed: true,
    },
    {
        caption: "Valore del venduto",
        dataField: "VALORE_VENDUTO",
        sortOrder: "desc",
        width: "auto",
        fixed: true,
        format: { type: "currency", precision: 2, currency: "EUR" }
    },
    {
        caption: "Pezzi",
        dataField: "PEZZI",
        width: 120,
    },
    {
        caption: "Margine",
        dataField: "MARGINE",
        width: 150,
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine Percentuale",
        dataField: "MARGINE_PERCENTUALE",
        width: 100,
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Sconto vendita",
        dataField: "SCONTO_VENDITA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Sconto percentuale",
        dataField: "SCONTO_VENDITA_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Battuta media pezzo",
        dataField: "BATTUTA_PEZZO_MEDIO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
];

export const columnsAnalisiVendOperativo = [
    {
      caption: "Category",
      dataField: "PRO_DESC_CATEGORY1AP",
      width: 200,
      sortOrder: "asc",
      fixed: true,
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
      customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    /*
    {
      caption: "Incidenza % valore del venduto indipendente da livello di navigazione ",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
      customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    */
    {
      caption: "Valore del venduto periodo selezionato",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto ",
      dataField: "VALORE_VENDUTO_PUNTUALECR",
      width: "auto",
      format: { precision: 2 },
      customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
      caption: "Delta valore del venduto equiparato",
        dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine periodo selezionato",
      dataField: "MARGINECR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Delta margine",
        dataField: "MARGINE_PUNTUALECR",
        width: "auto",
        format: { precision: 2 },
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta margine equiparato",
        dataField: "MARGINE_EQUIPARATOCR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Margine percentuale periodo selezionato",
        dataField: "MARGINE_PERCENTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta margine percentuale",
        dataField: "MARGINE_PUNTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Volume di vendita periodo selezionato",
        dataField: "VOLUME_VENDITACR",
        width: "auto",
    },
    {
        caption: "Delta volume di vendita",
        dataField: "VOLUME_VENDITA_PUNTUALECR",
        width: "auto",
        format: { precision: 2 },
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta volume di vendita equiparato",
        dataField: "VOLUME_VENDITA_EQUIPARATOCR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
];

export const columnsAnalisiVendInvenOperativo = [
    {
        caption: "Settore di inventario",
        dataField: "PRO_DESC_SETTORE_INVENTARIOAP",
        width: 200,
        sortOrder: "asc",
        fixed: true,
    },
    {
        caption: "Incidenza % valore del venduto",
        dataField: "INCIDENZA_VALORE_VENDUTOCR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    /*
    {
      caption: "Incidenza % valore del venduto indipendente da livello di navigazione ",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
      customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    */
    {
        caption: "Valore del venduto periodo selezionato",
        dataField: "VALORE_VENDUTOCR",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore del venduto anno precedente",
        dataField: "VALORE_VENDUTOAP",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Delta valore del venduto ",
        dataField: "VALORE_VENDUTO_PUNTUALECR",
        width: "auto",
        format: { precision: 2 },
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta valore del venduto equiparato",
        dataField: "VALORE_VENDUTO_EQUIPARATOCR",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Margine periodo selezionato",
        dataField: "MARGINECR",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Delta margine",
        dataField: "MARGINE_PUNTUALECR",
        width: "auto",
        format: { precision: 2 },
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta margine equiparato",
        dataField: "MARGINE_EQUIPARATOCR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Margine percentuale periodo selezionato",
        dataField: "MARGINE_PERCENTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta margine percentuale",
        dataField: "MARGINE_PUNTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Volume di vendita periodo selezionato",
        dataField: "VOLUME_VENDITACR",
        width: "auto",
    },
    {
        caption: "Delta volume di vendita",
        dataField: "VOLUME_VENDITA_PUNTUALECR",
        width: "auto",
        format: { precision: 2 },
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
    {
        caption: "Delta volume di vendita equiparato",
        dataField: "VOLUME_VENDITA_EQUIPARATOCR",
        width: "auto",
        customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")
    },
];

export const columnsAnalisiVendOperativo2 = [
    {
        caption: "Ditta",
        dataField: "PRO_DESC_DITTA_TITOLARE",
        width: "auto",
        fixed: true,
    },
    {
        caption: "Farmacia",
        dataField: "FARM_DESCRIZIONE",
        width: "auto",
        fixed: true,
    },
    {
        caption: "Mese",
        dataField: "ANNOMESE",
        width: 120,
        customizeText: (cellInfo: any) => {
            const stringValue = cellInfo.value.toString();
            const lastTwoDigits = stringValue.slice(-2);   
            const remainingString = stringValue.slice(0, -2);
            const formattedString = `${lastTwoDigits}/${remainingString}`;

            return formattedString;
        }
    },
    {
        caption: "Valore del venduto",
        dataField: "VALORE_VENDUTO",
        width: 170,
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Pezzi",
        dataField: "PEZZI",
        sortOrder: "desc",
        width: 100,
    },
    {
        caption: "Sconto vendita",
        dataField: "SCONTO_VENDITA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Sconto percentuale",
        dataField: "SCONTO_VENDITA_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Battuta media pezzo",
        dataField: "BATTUTA_PEZZO_MEDIO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    }
];

  export const columnsAnalisiPopMerce = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Codice merceologico",
      dataField: "PRO_COD_GRUPPO_MERCEOLOGICO1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export const columnsAnalisiPopCrossFarm = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Farmacia",
      dataField: "FARM_DESCRIZIONE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Ore lavorate",
      dataField: "ORE_LAVORATE",
      width: "auto",
    },
    {
      caption: "Media oraria lavorata",
      dataField: "MEDIA_ORARIA_GIORNALIERA",
      width: "auto",
    },
    {
      caption: "Numero vendite",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Numero righe di vendita",
      dataField: "NUMERO_RIGHE",
      width: "auto",
    },
    {
      caption: "Media righe vendita",
      dataField: "MEDIA_RIGHE_VENDITA",
      width: "auto",
    },
    {
      caption: "Media oraria righe di vendita",
      dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
      width: "auto",
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore vendita medio",
      dataField: "VALORE_VENDITA_MEDIO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
  ];

  export const columnsAnalisiPopOpeDitta = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Ditta",
      dataField: "PRO_DESC_DITTA_TITOLARE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },

  ];


  export const columnsAnalisiPopOpeDittaTwo = [
    {
      caption: "Ditta",
      dataField: "PRO_DESC_DITTA_TITOLARE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },


    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];


  export const columnsOpeAnaScoCatePop = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto ",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % sconto",
      dataField: "INCIDENZA_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del vendtuo ",
      dataField: "IMPORTO_LORDO_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export const columnsOpeAnaScoCate = [
    {
      caption: "Categoria",
      dataField: "PRO_DESC_CATEGORY1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto periodo selezione",
      dataField: "VEN_SCONTO_VENDITA",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale valore sconto",
      dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Incidenza margine",
      dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export const columnPopAnaScoCateg = [
    {
      caption: "Operatore",
      dataField: "OPERATORE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto ",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % sconto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto percentuale",
      dataField: "PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export const columnsAnaScoCateMerce  = [
    {
      caption: "Gruppo merceologico",
      dataField: "PRO_COD_GRUPPO_MERCEOLOGICO1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto periodo selezione",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale valore sconto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Sconto percentuale",
      dataField: "PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  // anal vend gruppo merceo

  export const columnsAnaVendiGruppMerce  = [
    {
      caption: "Gruppo merceologico",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      sortOrder: "asc",
      fixed: true
    },
    {
      caption: "Incidenza percentuale valore del venduto",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
      format: { precision: 2 },
      customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Incidenza % valore del venduto indipendente da livello di navigazione",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto periodo selezionato",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },

    },
    {
      caption: "Valore del venduto anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto",
      dataField: "VALORE_VENDUTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto equiparato",
      dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
         AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine periodo selezionato",
      dataField: "MARGINECR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },

    },
    {
      caption: "Delta margine",
      dataField: "MARGINE_PUNTUALECR",
      width: "auto",
      format: { precision: 2 } 
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
           AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezionato",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
          AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale",
      dataField: "MARGINE_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume di vendita periodo selezionato",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
          AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta volume di vendita equiparato",
      dataField: "VOLUME_VENDITA_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
         AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    
  ];

  export const columnsAnaAcquiCateg = [
    {
      caption: "Category",
      dataField: "PRO_DESC_CATEGORY1CR",
      width: "auto",
      sortOrder: "asc",
      fixed: true
    },
    {
      caption: "Costo d'acquisto periodo selezionato",
      dataField: "COSTO_ACQUISTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Costo d'acquisto anno precedente",
      dataField: "COSTO_ACQUISTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },

    },
    {
      caption: "Delta costo d'acquisto",
        dataField: "COSTO_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume d'acquisto periodo selezionato",
      dataField: "VOLUME_ACQUISTOCR",
      width: "auto",
      format: { precision: 0}
    },
    {
      caption: "Volume d'acquisto anno precedente",
      dataField: "VOLUME_ACQUISTOAP",
      width: "auto",
    },
    {
      caption: "Delta volume d'acquisto",
      dataField: "VOLUME_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    
  ];

export const columnsAnaAcquiGruMerc = [
    {
        caption: "Codice gruppo merceologico",
        dataField: "PRO_COD_GRUPPO_MERCEOLOGICO1CR",
        width: "auto",
        fixed: true
    },
    {
      caption: "Gruppo merceologico",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1CR",
      width: "auto",
      sortOrder: "asc",
      fixed: true
      },
    {
       caption: "Costo d'acquisto periodo selezionato",
       dataField: "COSTO_ACQUISTOCR",
       width: "auto",
       format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Costo d'acquisto anno precedente",
      dataField: "COSTO_ACQUISTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta costo d'acquisto",
      dataField: "COSTO_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume d'acquisto periodo selezionato",
      dataField: "VOLUME_ACQUISTOCR",
      width: "auto",
    },
    {
      caption: "Volume d'acquisto anno precedente",
      dataField: "VOLUME_ACQUISTOAP",
      width: "auto",
     
    },
    {
      caption: "Delta volume d'acquisto",
      dataField: "VOLUME_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    
];

export const columnsAnalisiAcquiSettoInvenOperativo = [
    {
        caption: "Settore di inventario",
        dataField: "PRO_DESC_SETTORE_INVENTARIOAP",
        width: "auto",
        sortOrder: "asc",
        fixed: true
    },
    {
        caption: "Costo d'acquisto periodo selezionato",
        dataField: "COSTO_ACQUISTOCR",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Costo d'acquisto anno precedente",
        dataField: "COSTO_ACQUISTOAP",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },

    },
    {
        caption: "Delta costo d'acquisto",
        dataField: "COSTO_ACQUISTO_PUNTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Volume d'acquisto periodo selezionato",
        dataField: "VOLUME_ACQUISTOCR",
        width: "auto",
        format: { precision: 0 }
    },
    {
        caption: "Volume d'acquisto anno precedente",
        dataField: "VOLUME_ACQUISTOAP",
        width: "auto",
    },
    {
        caption: "Delta volume d'acquisto",
        dataField: "VOLUME_ACQUISTO_PUNTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },

];

export const columnsAnalisiAcquiDittaOperativo = [
    {
        caption: "Ditta",
        dataField: "PRO_DESC_DITTA_TITOLAREAP",
        width: "auto",
        sortOrder: "asc",
        fixed: true
    },
    {
        caption: "Costo d'acquisto periodo selezionato",
        dataField: "COSTO_ACQUISTOCR",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Costo d'acquisto anno precedente",
        dataField: "COSTO_ACQUISTOAP",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },

    },
    {
        caption: "Delta costo d'acquisto",
        dataField: "COSTO_ACQUISTO_PUNTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Volume d'acquisto periodo selezionato",
        dataField: "VOLUME_ACQUISTOCR",
        width: "auto",
        format: { precision: 0 }
    },
    {
        caption: "Volume d'acquisto anno precedente",
        dataField: "VOLUME_ACQUISTOAP",
        width: "auto",
    },
    {
        caption: "Delta volume d'acquisto",
        dataField: "VOLUME_ACQUISTO_PUNTUALECR",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },

];

  export const columnsAnaFornDittaCate = [
    {
      caption: "Fornitore",
      dataField: "ANA_RAGIONE_SOCIALECR",
      fixed: true,
      sortOrder: "asc"
      },
      {
          caption: "Tipo",
          dataField: "ANA_DESCRIZIONE_CAT_FORNITORECR",
          fixed: true,
          sortOrder: "asc"
      },
      {
          caption: "Imponibile",
          dataField: "IMPONIBILECR",
          width: "auto",
          format: { type: "currency", precision: 2, currency: "EUR" },
      },
      {
          caption: "Ricavo",
          dataField: "RICAVOCR",
          width: "auto",
          format: { type: "currency", precision: 2, currency: "EUR" },
      },
      {
          caption: "Imposta",
          dataField: "IMPOSTACR",
          width: "auto",
          format: { type: "currency", precision: 2, currency: "EUR" },
      },
      
      {
          caption: "Numero carichi",
          dataField: "NUMERO_CARICHICR",
          width: "auto",
      },
    {
      caption: "Costo d'acquisto periodo selezionato",
      dataField: "COSTO_ACQUISTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale costo d'acquisto",
      dataField: "INCIDENZA_COSTO_ACQUISTOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Costo d'acquisto anno precedente",
      dataField: "COSTO_ACQUISTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta costo d'acquisto",
      dataField: "COSTO_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume d'acquisto periodo selezionato",
        dataField: "VOLUME_ACQUISTOCR",
      width: "auto",
      },
      {
          caption: "Volume d'acquisto omaggio",
          dataField: "VOLUME_ACQUISTO_OMAGGIOCR",
          width: "auto",
      },
    {
      caption: "Incidenza percentuale volume d'acquisto",
      dataField: "INCIDENZA_VOLUME_ACQUISTOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),

    },
    {
      caption: "Volume d'acquisto anno precedente",
      dataField: "VOLUME_ACQUISTOAP",
      width: "auto",
    },
    {
      caption: "Delta volume d'acquisto",
      dataField: "VOLUME_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
         AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export const columnsAnaFornDittaCate2 = [
    {
      caption: "Ditta",
      dataField: "PRO_DESC_DITTA_TITOLARECR",
      width: "auto",
      sortOrder: "asc",
      fixed: true
    },
    {
      caption: "Fornitore",
      dataField: "ANA_RAGIONE_SOCIALECR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Costo d'acquisto periodo selezionato",
      dataField: "COSTO_ACQUISTOCR",
      width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale costo d'acquisto relativa Ditta",
      dataField: "INCIDENZA_COSTO_ACQUISTO_DITTACR",
      width: "auto",
      customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Incidenza percentuale costo d'acquisto totale",
      dataField: "INCIDENZA_COSTO_ACQUISTOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Costo d'acquisto anno precedente",
      dataField: "COSTO_ACQUISTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta costo d'acquisto",
      dataField: "COSTO_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume d'acquisto periodo selezionato",
      dataField: "VOLUME_ACQUISTOCR",
      width: "auto",
     
    },
    {
      caption: "Volume d'acquisto anno precedente",
      dataField: "VOLUME_ACQUISTOAP",
      width: "auto",
      
    },
    {
      caption: "Delta volume d'acquisto",
      dataField: "VOLUME_ACQUISTO_PUNTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },

    
  ];

  export const columnsAnalmargMedi = [
    {
      caption: "Classificazione",
      dataField: "PRO_DESC_CATEGORY1",
      width: "auto",
      fixed: true
    }, 
    {
      caption: "Margine percentuale",
      dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Prodotto con marigine inferiore alla soglia indicata",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },  
  ];

  export const columnsAnalVendSoMarg = [
    {
      caption: "Classificazione",
      dataField: "PRO_DESC_CATEGORY1",
      width: "auto",
      fixed: true
    }, 
    {
      caption: "Margine percentuale",
      dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Prodotto con marigine inferiore alla soglia indicata",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },  
  ];


  export const columnsAnaVendFascOrariaOper = [
    { caption: "Farmacia", dataField: "FARM_DESCRIZIONEAP", width: "auto" },
    { caption: "Codice", dataField: "FARM_CODICEAP", width: "auto" },
    {
      caption: "Copertura giorni lavorati",
      dataField: "GIORNI_LAVORATICR",
      width: "auto",
    },
    {
      caption: "Copertura giorni lavorati anno precedente",
      dataField: "GIORNI_LAVORATIAP",
      width: "auto",
    },
    {
      caption: "Valore del venduto periodo selezionato",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto SSN periodo selezionato",
      dataField: "VALORE_VENDUTO_SSNCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Libera periodo selezionato",
      dataField: "VALORE_VENDUTO_LIBERACR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto equiparato",
      dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile periodo selezionato",
      dataField: "VALORE_VENDUTO_NETTO_IVACR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile anno precedente",
      dataField: "VALORE_VENDUTO_NETTO_IVAAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezionato",
      dataField: "MARGINECR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezionato",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Numero vendite periodo selezionato",
      dataField: "NUMERO_VENDITECR",
      width: "auto",
    },
    {
      caption: "Numero vendite anno precedente",
      dataField: "NUMERO_VENDITEAP",
      width: "auto",
    },
    {
      caption: "Volume di vendita periodo selezionato",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
    },
    {
      caption: "Volume di vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "auto",
    },
    {
      caption: "Delta volume di vendita equiparato",
      dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "auto",
    },
];

export const columnsAnaVendFascOraria = [
    { caption: "Codice", dataField: "FARM_CODICE", width: "auto" },
    {
        caption: "Giorno della settimana",
        dataField: "GIORNO_SETTIMANA",
        width: "auto",
        customizeText: (value: any) => {
            const giorno = getDayName(Number(value.valueText))
            return (
                giorno
            )
        }
    },
    {
        caption: "Copertura giorni lavorati",
        dataField: "FREQUENZA_NEL_PERIODO",
        width: 80,
    },
    {
        caption: "Numero vendite",
        dataField: "NUMERO_VENDITE",
        width: "auto",
    },
    {
        caption: "Numero vendite medio giornaliero",
        dataField: "NUMERO_VENDITE_MEDIO_GIORNALIERO",
        width: "auto",
        format: { precision: 2 },

    },
    {
        caption: "Numero vendite medio per operatore",
        dataField: "NUMERO_VENDITE_MEDIO_OPERATORE",
        width: "auto",
        format: { precision: 2 },

    },
    {
        caption: "Valore del venduto ivato",
        dataField: "VENDUTO_LORDO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore del venduto medio giornaliero ivato",
        dataField: "VENDUTO_LORDO_MEDIO_GIORNALIERO_IVATO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore del venduto medio per operatore ivato ",
        dataField: "VENDUTO_LORDO_MEDIO_OPERATORE_IVATO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Numero ore lavorate",
        dataField: "ORE_LAVORATE",
        width: 80,
    },
    {
        caption: "Righe Vendite lavorate per ora ",
        dataField: "RIGHE_VENDITA",
        width: 80,
    },
];
  
  // pop anal ricette

  export const columnPopAnaRicette = [
    {
      caption: "Farmacia",
      dataField: "FARMACIA",
      width: "auto",
      fixed: true
    },
    {
      caption: "Periodo",
      dataField: "DATA",
      width: "auto",
      fixed: true
    },
    {
      caption: "Venduto lordo",
      dataField: "VENDUTO_LORDO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile lordo",
      dataField: "IMPONIBILE_LORDO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Decurtazione in DCR",
      dataField: "DCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Venduto netto decurtazione SSN",
      dataField: "VENDUTO_SSN",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile netto decurtazione SSN",
      dataField: "IMPONIBILE_SSN",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore Ticket + Quota ricetta",
      dataField: "DCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Previsione incasso DCR",
      dataField: "DCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Numero ricette lavorate ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: " Valore medio per ricetta",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Pezzi venduti SSN",
      dataField: "FARMACIA",
      width: "auto",
    },
    {
      caption: "Numero medio pezzi per ricetta",
      dataField: "FARMACIA",
      width: "auto",
    },
   
  ];

  export const columnsAnaTrasferime  = [
    {
      caption: "Contenitore",
      dataField: "PRO_COD_GRUPPO_MERCEOLOGICO1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Volume di vendita periodo selezione",
      dataField: "VALORE_SCONTO",
      width: "auto",
    },
    
    {
      caption: "Valore del venduto periodo selezione",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile periodo selezione",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    
    {
      caption: "Margine periodo selezione",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Prodotti contenuti",
      dataField: "VALORE_SCONTO",
      width: "auto",
    },
    {
      caption: "Battuta media pezzo",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", currency: "EUR" },
    },
    
];

export const columnsAnaVenduto = [
    {
        caption: "Farmacia",
        dataField: "FARM_DESCRIZIONE",
        width: 250,
        fixed: true
    },
    {
        caption: "Anno",
        dataField: "ANNO",
        width: 150,
    },
    {
        caption: "Valore venduto",
        dataField: "VALORE_VENDUTO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" }
    },
    
    
];

export const columnsAnaPezzi = [
    {
        caption: "Farmacia",
        dataField: "FARM_DESCRIZIONE",
        width: 250,
        fixed: true
    },
    {
        caption: "Anno",
        dataField: "ANNO",
        width: 150,
    },
    {
        caption: "Pezzi",
        dataField: "PEZZI",
        width: "auto",
    },

    
];

export const columnsAnaPezzi2 = [
    {
        caption: "Anno",
        dataField: "ANNO",
        width: "auto",
    },
    {
        caption: "Mese",
        dataField: "MESE_TEXT",
        width: "auto",
    },
    {
        caption: "Pezzi",
        dataField: "PEZZI",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
];

export const columnsAnalVendiSottMargine = [
    {
        caption: "Classificazione Lv 1",
        dataField: "FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1",
        width: "auto",
        fixed: true
    },
    {
        caption: "margine % medio",
        dataField: "MARGINE_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Righe di vendita con margine inferiore alla soglia indicata",
        dataField: "NUMERO_RIGHE_SOTTO_MARGINE",
        width: 250,
    },
    {
        caption: "Importo vendita",
        dataField: "IMPORTO_VENDITA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Sconto",
        dataField: "SCONTO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Iva",
        dataField: "IVA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Costo",
        dataField: "COSTO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine",
        dataField: "MARGINE",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    }
];