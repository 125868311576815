import React, { useState, useEffect } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { urlCommon, urlConsultazione } from "../../costanti";
import { useAuth } from "../../../hooks/useAuth";
import { RestServices } from "../../../services/restServices";
import notify from "devextreme/ui/notify";
import { Loading } from "../../../widget/Notifications";
import TextBox from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import { AutocompleteBox } from "../../../widget/Autocomplete"
import Form, { GroupItem } from "devextreme-react/form";
import { SimpleItem } from "devextreme-react/tree-list";


interface DialogProps {
    open: boolean;
    idAnaRecapitiTelefono: number;
    close: () => void;
    idAnaGenerale: number;
    idAnaIndirizzi: number;
    closeAndReload: () => void
}

export const DialogAnaFornCenTelefono: React.FC<DialogProps> = ({
    open,
    idAnaRecapitiTelefono,
    close,
    idAnaGenerale,
    idAnaIndirizzi,
    closeAndReload
}) => {

    const auth = useAuth(urlCommon.auth);
    //const auth2 = useAuth(urlConsultazione.auth);
    const [openD, setOpenD] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [objctTOsave, setObjctTOsave] = useState<any>({});
    const [objctTOedit, setObjctTOedit] = useState<any>({});
    const [tipoTelefono, setTipoTelefono] = useState<any>([]);
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [listFar, setListFar] = useState<any>([])
    const [listAzi, setListAzi] = useState<any>([])

    useEffect(() => {
        if (auth) {
            setOpenD(open)
        }

    }, [auth])

    useEffect(() => {

        if (openD) {
            getTelefono()
            getListTipoTelefono()
            getListFar()
            getListAzi()
        }

    }, [openD])

    useEffect(() => {
        if (objctTOedit) {

            if (objctTOedit.numero?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit.idTipTelefono > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

        }

    }, [objctTOedit])


    const getTelefono = () => {

        if (idAnaRecapitiTelefono) {
            let params = { idAnaRecapitiTelefono: idAnaRecapitiTelefono };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getAnagraficaTelefono)
                    .then(result => {
                        if (result?.esito) {
                            setObjctTOsave(result.response)
                            setObjctTOedit(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        }
        else {
            setObjctTOsave({ idAnaGenerale: idAnaGenerale, idAnaIndirizzo: idAnaIndirizzi });
        }

    }

    const getListTipoTelefono = () => {

        //if (idAnaRecapitiTelefono) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoTelefono)
                    .then(result => {
                        if (result?.esito) {
                            setTipoTelefono(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        //}

    }


    const getListFar = () => {
        //if (idAnaRecapitiTelefono) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlConsultazione.getListFar)
                    .then(result => {
                        if (result?.esito) {
                            setListFar(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        //}
    }

    const getListAzi = () => {
        //if (idAnaRecapitiTelefono) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlConsultazione.getListAzienda)
                    .then(result => {
                        if (result?.esito) {
                            setListAzi(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        //}
    }

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value

        //console.log(objTo)

        setObjctTOsave(objTo)

        if (objTo.numero?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.idTipTelefono > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }


    }


    const salvaTelefono = () => {
        setLoading(true)
        if (idAnaRecapitiTelefono) {
            if (auth) {
                RestServices().rest('POST', objctTOsave, urlCommon.updateAnagraficaTelefono)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                notify({ position: "center", width: "auto", message: 'Telefono aggiornato' }, "success", 4000)
                                closeAndReload();
                            }
                            else {
                                notify({ position: "center", width: "auto", message: 'Telefono non aggiornato' }, "error", 4000)
                            }
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }
        else {
            if (auth) {
                RestServices().rest('POST', objctTOsave, urlCommon.addAnagraficaTelefono)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                notify({ position: "center", width: "auto", message: 'Telefono inserito' }, "success", 4000)
                                closeAndReload();
                            }
                            else {
                                notify({ position: "center", width: "auto", message: 'Telefono non inserito' }, "error", 4000)
                            }
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }

    }


    return (
        <>


            <Popup
                visible={openD}
                showCloseButton={false}
                width={'70%'}
                height={'45%'}
                showTitle={true}
                title="Visualizzazione Telefono"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: "Chiudi",
                        onClick: () => close(),
                    }

                    }
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Salva',
                        disabled: disableButton,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => salvaTelefono(),
                    }}
                />

                <Loading isVisible={loading} />


                <div className='form-row '>
                    <div className="form-group col-md-4">
                        <label> NUMERO TELEFONICO </label>
                        <TextBox valueChangeEvent="keyup"  value={objctTOsave.numero} onValueChanged={(v) => cambiaValore('numero', v.value)} placeholder="..." />
                    </div>

                    <div className="form-group col-md-4">
                        <label> NOTE </label>
                        <TextBox valueChangeEvent="keyup"  value={objctTOsave.noteAnaRecapitiTelefono} onValueChanged={(v) => cambiaValore('noteAnaRecapitiTelefono', v.value)} placeholder="..." />
                    </div>

                </div>

                <div className='form-row '>
                    <div className="form-group col-md-6">
                        <label> TIPO </label>
                        <SelectBox dataSource={tipoTelefono} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipTelefono} onValueChanged={(v) => cambiaValore('idTipTelefono', v.value)} />
                    </div>


                </div>

                <div className='form-row '>
                    <div className="form-group col-md-6">
                        <label> VALIDITA' TELEFONO (Entrambi i campi vuoti = Telefono di gruppo) (Azienda (vuoto = tutte)) </label>
                        <SelectBox disabled={objctTOedit.flagDefault} dataSource={listAzi} displayExpr="ragioneSociale" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idAnaAziRecapitiTelefono} onValueChanged={(v) => cambiaValore('idAnaAziRecapitiTelefono', v.value)} />
                    </div>

                    <div className="form-group col-md-6">
                        <label> FARMACIA (vuoto = tutte) </label>
                        <SelectBox disabled={objctTOedit.flagDefault}  dataSource={listFar} displayExpr="descrizione" valueExpr="idFar" valueChangeEvent="keyup" value={objctTOsave?.idAnaFarRecapitiTelefono} onValueChanged={(v) => cambiaValore('idAnaFarRecapitiTelefono', v.value)} />
                    </div>
                </div>

            </Popup>



        </>
    )


}