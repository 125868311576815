import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Connector, Title, Export, Font, Format, Label, HoverStyle, Size, SmallValuesGrouping, Tooltip } from "devextreme-react/pie-chart";
import { MenuFbiAcquisti } from "../components/menu_Fbi_Acquisti"
import Chart, { Annotation, CommonSeriesSettings, Legend, Series } from 'devextreme-react/chart';
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import ".././style.css";


const dashboard39 = { dashboardID: 39 };

//da spostare
const convertTime = (data: Date) => {
    const anno = data.getFullYear();
    const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perch� i mesi sono 0-based
    const giorno = data.getDate().toString().padStart(2, "0");
    const ore = data.getHours().toString().padStart(2, "0");
    const minuti = data.getMinutes().toString().padStart(2, "0");
    const secondi = data.getSeconds().toString().padStart(2, "0");

    const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

    return dataFormattata;
};

const FbiAnaAcquiSintetica = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [valori4W, setValori4W] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");

    const [pie, setPie] = useState<any[]>([]);
    const [secondpie, setSecondPie] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePopOne, setVisiblePopOne] = useState(false);


    const [visiblePopCHT, setVisiblePopCHT] = useState(false);
    const [visiblePopCHT2, setVisiblePopCHTtwo] = useState(false);

    const [visiblePopPie, setVisiblePopPie] = useState(false);
    const [visiblePopPie2, setVisiblePopPietwo] = useState(false);
    const [visiblePopPie3, setVisiblePopPiethree] = useState(false);
    const [visiblePopPie4, setVisiblePopPiefour] = useState(false);
    const [visiblePopPie5, setVisiblePopPiefive] = useState(false);

    const [tipologiaProdotto, setTipologiaProdotto] = useState<any[]>([]);

    const [totalValue, setTotalValue] = useState(0);
    const [totalValue2, setTotalValue2] = useState(0);
    const [chartDataCosto, setChartDataCosto] = useState<any>([]);
    const [chartDataFornitore, setChartDataFornitore] = useState<any>([]);
    const chartRef = useRef<any>(null);
    // ??? const [chartKey, setChartKey] = useState(false);

    const [chartDataPezzi, setChartDataPezzi] = useState<any>([]);
    const [chartDataProduttore, setChartDataProduttore] = useState<any>([]);
    // ??const chartRefMargine = useRef<any>(null);
    // ??const [chartKeyMargine, setChartKeyMargine] = useState(false);

    const chartRefDitta = useRef<any>(null);
    //??const [chartKeyDitta, setChartKeyDitta] = useState(false);

    const [chartDataRTEPie, setChartDataRTEPie] = useState<any>([]);
    const [chartDataFTEPie, setChartDataFTEPie] = useState<any>([]);
    

    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale � mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const mmonthNames = [
        "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
        "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
    ];

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        console.log(params.sesso);

        const parameters = {

            tipologiaProdotto: params.tipologiaProdotto,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        console.log(parameters);
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall39 = {
                ...dashboard39,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall39, urlReport.getQueryDashboardAllData)
                .then((result) => {

                    setIsVisible(false);
                    if (result) {

                        setChartDataCosto(result["129"]);
                        setChartDataPezzi(result["129"].reverse());
                        setChartDataFornitore(result["131"]);
                        setChartDataProduttore(result["132"]);
                       

                        //  chartpie
                        setChartDataRTEPie(result["130"]);

                        setChartDataFTEPie(result["130"]);
            
                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);

                        //CHART COSTO TOTALE

                        const total = result["129"]
                            .slice(12, 23)
                            .reduce(
                                (acc: number, item: any) => acc + item.COSTO_ACQUISTO,
                                0
                            );
                        const formattedTotal = total.toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigit: 0,
                        });

                        setTotalValue(formattedTotal);

                        //CHART PEZZI TOTALE

                        const total2 = result["129"]
                            .slice(12, 23)
                            .reduce((acc: number, item: any) => acc + item.PEZZI_ACQUISTATI_PRECEDENTE, 0);
                        const formattedTotaL2 = total2.toLocaleString("it-IT", {
                            minimumFractionsDigits: 0,
                        });

                        setTotalValue2(formattedTotaL2);


                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            const inizioAnno = new Date();

            inizioAnno.setMonth(0);
            inizioAnno.setDate(1);

        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };



    const popVisibleCHT = (test: string) => {
        setVisiblePopCHT(true);
    }

    const popVisibleCHT2 = (test: string) => {
        setVisiblePopCHTtwo(true);
    }

    let CHTOne = [``]

    let CHTtwo = [``]



    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };

    const popVisiblePie = (testo: string) => {
        setVisiblePopPie(true);
    };

    const popVisiblePie2 = (testo: string) => {
        setVisiblePopPietwo(true);
    };

    const popVisiblePie3 = (testo: string) => {
        setVisiblePopPiethree(true);
    }

    const popVisiblePie4 = (testo: string) => {
        setVisiblePopPiefour(true);
    }

    const popVisiblePie5 = (testo: string) => {
        setVisiblePopPiefive(true);
    }


    let messOne = [``];

    let messTwo = [``];

    let messThree = [``]

    let messFour = [``]

    let messSix = [``]

    let messSeven = [``]


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();

    }, [auth]);

    const filters = () => {
        if (auth) {
            RestServices()
                .rest("POST", "", urlReport.getTipologiaProdotto)
                .then((result) => {
                    if (result) {
                        const prodotti = result.map((oggetto: { descrizione: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.descrizione // Aggiungi la nuova propriet� id
                            };
                        });

                        setTipologiaProdotto(prodotti)
                    }
                });
           

            console.log();
        }
    };

    useEffect(() => {
        filters();
    }, [authReport]);


    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        {
            type: "selectWithLevels",
            noId: true,
            id: "tipologiaProdotto",
            label: "Tipologia prodotto",
            displayExpr: "tipologiaProdotto",
            dataSource: tipologiaProdotto,
            idValueGrid: "tipologiaProdotto" as any,
        },
    ];


    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    return (<section style={{ display: 'flex', justifyContent: 'space-between', height: '93vh' }}>
        {loadPanel}
        {loadinCambio ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                <LoadIndicator id="small-indicator" height={50} width={50} />
            </div>
        ) : (

            <section id='scrollerelement' style={{ width: '100%', overflow: 'scroll' }}>
                <div style={{ fontSize: '14px', alignItems: 'left', margin: '10px', color: 'rgb(80, 84, 92)' }}>
                    <h2>Analisi Acquisti Sintetica</h2>
                    </div>


                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                    >

                        <div>
                            <MenuFbiAcquisti />
                        </div>
                    </div>

                <div
                    style={{
                        display: "block",
                        gap: 5,
                        padding: 10,
                        borderRadius: 10,
                        marginTop: 20,
                        width: "auto",
                    }}
                >


                    <div>
                        <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                            <div style={{ width: "80%", margin: "10px" }}>
                                <Chart dataSource={chartDataCosto} ref={chartRef}>
                                    <CommonSeriesSettings
                                        argumentField="MESE"
                                        type="bar"
                                        hoverMode="allArgumentPoints"
                                        selectionMode="allArgumentPoints"
                                    >
                                        <Label visible={true}>
                                            <Format precision={0} />
                                        </Label>
                                    </CommonSeriesSettings>

                                    <Series
                                        valueField="COSTO_ACQUISTO_PRECEDENTE"
                                        type="bar"
                                        name="2022/2023"
                                        color="rgb(215, 140, 245)"
                                    />

                                    <Series
                                        valueField="COSTO_ACQUISTO"
                                        type="bar"
                                        color="rgb(25, 231, 255)"
                                        name="2023/2024"
                                    />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({
                                            value,
                                            argument,
                                        }: {
                                            value: any;
                                            argument: any;
                                        }) => {
                                            const formattedValue = parseFloat(value).toLocaleString(
                                                "it-IT",
                                                {
                                                    style: "currency",
                                                    currency: "EUR",
                                                    minimumFractionDigits: 0,
                                                }
                                            );

                                            return {
                                                text: `Costo acquisti: ${formattedValue}`,
                                            };
                                        }}
                                    />
                                    <Title text="Andamento costo d'acquisto">
                                        <Font color="#5b6372" size="20" weight="400" />
                                    </Title>
                                    <Legend
                                        verticalAlignment="bottom"
                                        horizontalAlignment="center"
                                    ></Legend>
                                    <Export fileName="Andamento_costo_acquisti" enabled={true} />
                                </Chart>
                            </div>

                            <div style={{}}>
                                <p
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                        justifyContent: "left",
                                    }}
                                >
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisibleCHT("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>

                            {chartDataCosto.length > 0 && (
                                <div
                                    style={{
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale:
                                    </h2>
                                    <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                                        {totalValue}
                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>


                    <div>
                        <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                            <div style={{ width: "80%", margin: 10, marginBottom: 5 }}>
                                <Chart dataSource={chartDataPezzi} ref={chartRef}>
                                    <CommonSeriesSettings
                                        argumentField="MESE"
                                        type="bar"
                                        hoverMode="allArgumentPoints"
                                        selectionMode="allArgumentPoints"
                                    >
                                        <Label visible={true}>
                                        </Label>
                                    </CommonSeriesSettings>

                                    <Series
                                        valueField="PEZZI_ACQUISTATI_PRECEDENTE"
                                        type="bar"
                                        name="2022/2023"
                                        color="rgb(215, 140, 245)"
                                    />

                                    <Series
                                        valueField="PEZZI_ACQUISTATI"
                                        type="bar"
                                        color="rgb(25, 231, 255)"
                                        name="2023/2024"
                                    />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({
                                            value,
                                            argument,
                                        }: {
                                            value: any;
                                            argument: any;
                                        }) => {
                                            const formattedValue = parseFloat(value).toLocaleString(
                                                "it-IT",
                                                {
                                                    minimumFractionDigits: 2,
                                                }
                                            );

                                            return {
                                                text: `Pezzi acquisti: ${formattedValue}`,
                                            };
                                        }}
                                    />
                                    <Title text="Andamento pezzi acquistati">
                                        <Font color="#5b6372" size="20" weight="400" />
                                    </Title>
                                    <Legend
                                        verticalAlignment="bottom"
                                        horizontalAlignment="center"
                                    ></Legend>
                                    <Export fileName="Andamento_pezzi_acquisti" enabled={true} />
                                </Chart>
                            </div>

                            <div style={{}}>
                                <p
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                        justifyContent: "left",
                                    }}
                                >
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisibleCHT2("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>
                            {chartDataPezzi.length > 0 && (
                                <div
                                    style={{
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale:
                                    </h2>
                                    <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                                        {totalValue2}
                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <Popup
                    visible={visiblePop}
                    showCloseButton={true}
                    width={"40%"}
                    height={"60%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{testoPop}</p>
                    </div>
                </Popup>


                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        marginTop: 5,
                        paddingBottom: 10
                    }}
                >
                    <div style={{ width: "55%", marginLeft: 20 }}>
                        <PieChart
                            id="pie"
                            dataSource={chartDataRTEPie}
                            palette="Green Mist"
                            paletteExtensionMode="blend"
                            type="doughnut"

                        >
                            <Series
                                argumentField="FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1"
                                valueField="COSTO_ACQUISTO"
                            >
                                <HoverStyle color="rgb(255, 0, 0)" />
                            </Series>

                            {<Tooltip
                                enabled={true}
                                customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                    const dataItem = chartDataRTEPie.find((item: any) => item.FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1 === argument);
                                    const dataSourceValue = dataItem ? dataItem.COSTO_ACQUISTO : 0;
                                    const formattedValue = new Intl.NumberFormat('it-IT', {style: "currency", currency: "EUR" }).format(dataSourceValue);

                                    return {
                                        html: `<p>Tipologia Prodotto: ${argument}<p><p>Costo d'acquisto: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                    };
                                }}
                            />}
                            <Title
                                text="Costo d'acquisto per Tipologia prodotto"
                            >
                                <Font color="#5b6372" size="16" weight="400" />
                            </Title>

                            <Export fileName="Costo_d'acquisto_per_tipologia_prodotto" enabled={true} />
                        </PieChart>
                    </div>

                    <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                        <button
                            className="ibutton-lite-two"
                            style={{ border: "none" }}
                            onClick={() => popVisiblePie("")}
                        >
                            <FontAwesomeIcon
                                icon={faInfo}
                                style={{ fontSize: "11px", color: "black" }}
                            />
                        </button>
                    </div>


                    <div style={{ width: "55%" }}>
                        <PieChart
                            id="pie"
                            dataSource={chartDataFTEPie}
                            palette="Green Mist"
                            paletteExtensionMode="alternate"
                            type="doughnut"

                        >
                           <Series argumentField="FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1" valueField="PEZZI_ACQUISTATI">
                           <HoverStyle color="rgb(255, 0, 0)" />
                            </Series>
                            {<Tooltip
                                enabled={true}
                                customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                    const dataItem = chartDataFTEPie.find((item: any) => item.FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1 === argument);
                                    const dataSourceValue = dataItem ? dataItem.PEZZI_ACQUISTATI : 0;
                                    const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                    return {
                                        html: `<p>Prodotto: ${argument}<p><p>Pezzi acquistati: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                    };
                                }}
                            />}
                            <Title text="Pezzi acquistati per Tipologia prodotto">
                                <Font color="#5b6372" size="16" weight="400" />
                            </Title>

                            <Export fileName="Pezzi_acquisti_per_Tipologia_prodotto" enabled={true} />
                        </PieChart>
                    </div>
                    <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                        <button
                            className="ibutton-lite-two"
                            style={{ border: "none" }}
                            onClick={() => popVisiblePie2("")}
                        >
                            <FontAwesomeIcon
                                icon={faInfo}
                                style={{ fontSize: "11px", color: "black" }}
                            />
                        </button>
                    </div>
                    </div> 

                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                    >

                        <div>
                            <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                                <div style={{ width: "80%", margin: "10px" }}>
                                    <Chart dataSource={chartDataFornitore.slice(0, 20)} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="ANA_RAGIONE_SOCIALE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                        >
                                            <Label visible={true}>
                                                <Format precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="COSTO_ACQUISTO"
                                            type="bar"
                                            name="Fornitore"
                                            color="rgb(25, 231, 255)"
                                        />

                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                );

                                                return {
                                                    html: `<p>Fornitore: ${argument}<p><p>Costo d'acquisto: ${formattedValue}</p>`,

                                                };
                                            }}
                                        />
                                        <Title text="Costo d'acquisto per Fornitore">
                                            <Font color="#5b6372" size="20" weight="400" />
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="Costo_d'acquisto_per_Fornitore " enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisibleCHT("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                       

                        <div>
                            <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                                <div style={{ width: "80%", margin: 10, marginBottom: 5 }}>
                                    <Chart dataSource={chartDataProduttore.slice(0, 20)} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="PRO_DESC_DITTA_TITOLARE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                        >
                                            <Label visible={true}>
                                                <Format type="currency" currency="EUR" precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="COSTO_ACQUISTO"
                                            type="bar"
                                            name="Produttore"
                                            color="rgb(25, 231, 255)"
                                        />

                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        style: "currency",
                                                        currency: "EUR",
                                                        minimumFractionDigits: 2,
                                                    }
                                                );

                                                return {
                                                    html: `<p>Produttore: ${argument}<p><p>Costo d'acquisto': ${formattedValue}</p>`,

                                                };
                                            }}
                                        />
                                        <Title text="Costo d'acquisto per Produttore">
                                            <Font color="#5b6372" size="20" weight="400" />
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="Costo_d'acquisto_per_Produttore" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisibleCHT2("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                             </div>
                        </div>
                    </div>
           </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon
                icon={faCaretUp}
                rotation={270}
                onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                style={{ fontSize: "30px", color: "black" }}
            />
        </div>
        <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10, marginLeft: 1 }}>
            <FiltriVerticale
                ricerca={filterAction}
                items={itemsFields}
                paramsFilter={paramsFilter}
            />
        </section>
        <Popup
            visible={visiblePopCHT}
            showCloseButton={true}
            onHiding={() => setVisiblePopCHT(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{CHTOne}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePopCHT2}
            showCloseButton={true}
            onHiding={() => setVisiblePopCHTtwo(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{CHTtwo}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePop1}
            showCloseButton={true}
            onHiding={() => setVisiblePop1(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{messOne}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePop2}
            showCloseButton={true}
            onHiding={() => setVisiblePop2(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{messTwo}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePopPie}
            showCloseButton={true}
            onHiding={() => setVisiblePopPie(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{messThree}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePopPie2}
            showCloseButton={true}
            onHiding={() => setVisiblePopPietwo(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{messFour}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePopPie4}
            showCloseButton={true}
            onHiding={() => setVisiblePopPiefour(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{messSix}</p>
            </div>
        </Popup>
        <Popup
            visible={visiblePopPie5}
            showCloseButton={true}
            onHiding={() => setVisiblePopPiefive(false)}
            width={"40%"}
            height={"40%"}
            showTitle={true}
            title="Dettagli widget"
        >
            <div>
                <p>{messSeven}</p>
            </div>
        </Popup>
    </section>

    );
};

export default FbiAnaAcquiSintetica;
