import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import {  Loading } from '../../widget/Notifications'
import { DialogConferma } from '../../widget/Notifications'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'



const DownLoad = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
     
    const auth = useAuth(urlConsultazione.auth)
    const [tabella,setTabella] = useState([])
    const [loading,setLoading] = useState<boolean>(false)
    const [messDel,setMessDel] = useState<string>('')
    const [idDel,setIddell] = useState<number>(0)
    const [popDel,setPopDel] = useState<boolean>(false)

    const columns = [
        { caption: "Nome File", dataField: "fileName", sortOrder:"asc", sortIndex:1 },
        { caption: "Data caricamento", dataField: "loadDate", format: "dd/MM/yyyy", dataType: "date", width: 120, sortOrder:"desc", sortIndex:0},
        { caption: "Tipo", dataField: "dateTypeName", width: 120  },  
        { caption: "Username", dataField: "userName",  width: 130  },
        { caption: "Azioni", type: "buttons", width: 150, buttons: [
            {
                hint: "Disabilita",
                icon: "download",
                onClick: (e: any) => {
                    //console.log(JSON.stringify(e.row.data))
                    //getData(e.row.data)
                    window.location.replace("https://www.cloud-farma.it/cfportal/export?nof="+e.row.data.fileName+"&_id="+e.row.data.id)
           
                }
            },
            {
                hint: "Disabilita",
                icon: "trash",
                onClick: (e: any) => {
                    setIddell(e.row.data.id)
                    setMessDel("Il File "+e.row.data.fileName+" Sta per essere eliminato. Confermare?")
                    setPopDel(true)
                    
                }
            }
        ]
    
    },
    ]

    const getData = (obj:any) =>{

            RestServices().rest('POST', { 
                id : obj.id,
                }
                 , urlConsultazione.getDataFile)
                .then(result => {
                   if(result.esito){
                       // console.log(result.response);
                        
                       //ricarica()          
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })   
          
    }

    const removeTipo = () =>{
        if (auth) {
            RestServices().rest('POST', { id : idDel} , urlConsultazione.deleteDataFile)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       notify({ position: "center", width: "auto", message: "Hai cancellato il file!"}, "success", 5000)
                       ricarica()          
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })   
         } 
    }



    const getDati =() =>{
        setLoading(true)
        if (auth) {     
                let tipiDatiList =  [
                    "VAL_INVENTARIO",
                    "ESTRAZIONE_DATI"
                  ]
            RestServices().rest('POST', { tipiDatiList } , urlConsultazione.getReadFiles)
                .then(result => {
                    //console.log(result);
                    if (result?.esito) {
                        //console.log(result);
                        setTabella(result.response)
                        setLoading(false)             
                    }
                    else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                  
                }) 
                
         }
    }


    const ricarica = () =>{
        getDati()
    }



    useEffect(() => { 
        if (props.statoMenu) {
          //  props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(()=>{
        getDati()
    },[auth])

    

    return (
        <>
               <DialogConferma
                    dialogIsOpen={popDel}
                    message={messDel}
                    functionNext={()=>removeTipo()}
                    handleCloseConferma={()=>{setPopDel(false);setIddell(0)}}
                /> 
                   
    
           <Loading isVisible={loading} />
            <div  className='title'>
                <h3> DOWNLOAD  </h3>
             </div>
            <div style={{background:'#fff',display:'flex',gap:10, alignItems: 'baseline',paddingTop:5,padding:5}}>
                <h6 style={{margin:0}}>Elenco Files</h6>
                <p style={{margin:0, fontSize:12,fontWeight:'lighter'}}>Elenco dei files scaricabili presenti sul sistema</p>
            </div>
            <div>
            <NewTabella 
                    idTabella='download_dati'
                    sync
                    colonne={columns} 
                    dataSource={tabella} 
                    filterRow
                    height='70vh'
                    searchPanel={true}
                   // onclickRow={(datiRow:any)=>setIdCarico(datiRow.data?.idCarico)}
                    exportT={{
                        enabled: false,
                        fileName: "download_dati"
                    }}
                />
            </div> 

        </>

        
    )
}

export default DownLoad