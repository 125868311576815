import React, { useEffect, useState } from 'react';
import './index.css';
import '../node_modules/devextreme/dist/css/dx.common.css'
import '../node_modules/devextreme/dist/css/dx.light.css';
import SideDrawer from './struttura_pagina/SideDrawer';
import Corpo from './struttura_pagina/Corpo';
import { useDatiFarm } from './hooks/useDatiFarm';
import itMessages from 'devextreme/localization/messages/it.json';
import { locale, loadMessages } from 'devextreme/localization';
import { useMenu } from './hooks/useMenu';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faTachometerAlt,faBriefcase, faTh, faBalanceScale, faCloud, faChartLine, faSitemap, faPaste, faFolderOpen, faCheck, faPoll, faTruck   } from '@fortawesome/free-solid-svg-icons';
import { faCalendarAlt, faMobile, faAddressCard, faFileAlt, faWrench, faCog, faCube, faThLarge, faUser,faUsers, faDatabase, faEdit, faUndo , faChartBar } from '@fortawesome/free-solid-svg-icons';
import { MenuInterface } from './interfaces/menu';
import { ListaFarmacieInterface } from './interfaces/farmacie';
import CorpoCgm from './struttura_pagina_cgm/Corpo';

library.add(fab, faUndo, faCalendarAlt, faFileAlt, faAddressCard,
  faTachometerAlt, faCheck, faTh, faBalanceScale, faCloud, faChartBar,
  faChartLine, faSitemap, faPaste, faFolderOpen, faMobile , faTruck,
  faEdit, faWrench, faCog, faCube, faThLarge, faUser, faDatabase, faPoll, faBriefcase, faUsers)

function App() {
  //impostazione lingua italiano per i componenti DevExtreme
  loadMessages(itMessages);

    locale(navigator.language);

    const isCgm = window.location.href.includes("sfbd");



    useEffect(() => {  }, [])

  //statoMenu = true -> sidebar laterale espansa
  const [statoMenu, setStatoMenu] = useState<boolean>(true)
  const toggleMenu = (stato: boolean) => {
    setStatoMenu(!stato)
  }
  //chiama servizio che crea menu
  const getMenu = useMenu();
  //chiama servizio che restituisce le farmacie del gruppo
  const getDatiFarmacie = useDatiFarm();

  const [menu, setMenu] = useState<MenuInterface>();
  useEffect(() => {
    if (getMenu) setMenu(getMenu);
  }, [getMenu])

  const [datiFarmacie, setDatiFarmacie] = useState<ListaFarmacieInterface[]>([]);
  useEffect(() => {
    if (getDatiFarmacie) setDatiFarmacie(getDatiFarmacie);
  }, [getDatiFarmacie])
    
  //visualizzazione modal cambio password
  const [showModalPsw, setShowModalPsw] = useState<boolean>(false)
  const handleClosePsw = () => setShowModalPsw(false)
  const handleShowPsw = () => setShowModalPsw(true)

  return (
    <>
      {(menu) &&
              <>
              {!isCgm && <>
                  <SideDrawer
                  statoMenu={statoMenu}
                  menu={menu}
                  handleShowPsw={handleShowPsw}
                    />  
                  <Corpo
                toggleMenu={toggleMenu}
                statoMenu={statoMenu}
                datiFarmacie={datiFarmacie}
                handleClosePsw={handleClosePsw}
                showModalPsw={showModalPsw}
                menu={menu}
                  />
              </>
              }

              {isCgm &&
                  <CorpoCgm
                        toggleMenu={toggleMenu}
                        statoMenu={statoMenu}
                        datiFarmacie={datiFarmacie}
                        handleClosePsw={handleClosePsw}
                        showModalPsw={showModalPsw}
                        menu={menu}
                  />
              }
          
          
        </>
      }
    </>
  );
}

export default App;