import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import RadioGroup from 'devextreme-react/radio-group';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { Loading } from '../../widget/Notifications';
import { SelectFarm } from '../../widget/Select';
import { RestServices } from '../../services/restServices';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { OggettoInterface } from '../../interfaces/generiche';
import { ConfigurazioneTrasferimentiFarmaciaInterface } from '../../interfaces/trasferimenti';
import { urlTrasferimenti } from '../costanti';
import { CheckBox } from 'devextreme-react';
import { Label } from 'devextreme-react/bar-gauge';
import { BoxFiltro, Check } from '../../widget/Editor';
import { PannelloTab } from './TabPanel';
import { useAuth } from '../../hooks/useAuth';

const ConfigurazioneParametri =
    (
        props:
            {
                statoMenu: boolean,
                toggleMenu: (stato: boolean) => void,
                datiFarmacie: ListaFarmacieInterface[],
                contenuto: string
            }
    ) => {
        //al caricamento della pagina si fa collassare il menu laterale in modo da avere pi� spazio a disposizione per la pagina
        useEffect(() => {
            if (props.statoMenu) {
                props.toggleMenu(!props.statoMenu);
            }
        }, [])

        const auth = useAuth(urlTrasferimenti.auth);

        //crea il titolo della pagina sotto la navbar
        let titolo = TitoloContenuto(props.contenuto);

        //rende visibile/invisibile il pannello di loading
        const [isVisible, setIsVisible] = useState(false);
        const loadPanel = <Loading isVisible={isVisible} />

        //configurazioneTrasferimentiFarm � l'array dei parametri per la farmacia
        const [configurazioneTrasferimentiFarm, setConfigurazioneTrasferimentiFarm] = useState<ConfigurazioneTrasferimentiFarmaciaInterface[]>();
        const [configurazioneTrasferimentiFarmCosto, setConfigurazioneTrasferimentiFarmCosto] = useState<ConfigurazioneTrasferimentiFarmaciaInterface[]>();
        const [configurazioneTrasferimentiFarmLista, setConfigurazioneTrasferimentiFarmLista] = useState<number[]>();
        const [configurazioneTrasferimentiListinoInVigore, setConfigurazioneTrasferimentiListinoInVigore] = useState<string>();
        const [configurazioneTrasferimentiAbilitaListino, setConfigurazioneTrasferimentiAbilitaListino] = useState<boolean>();
        const [configurazioneTrasferimentiAnagrafiche, setConfigurazioneTrasferimentiAnagrafiche] = useState<any[]>();
        const [anagrafiche, setAnagrafiche] = useState<any[]>();

        //configurazione selezionata
        const [selectedConfig, setSelectedConfig] = useState<String>();

        //idFarm � lo stato da memorizzare nei filtri mentre farmacia � lo stato proprio del componente tendina farmacia
        const [idFarm, setIdFarm] = useState<number>();
        const impostaFiltri = (newFiltro: OggettoInterface) => {

            if (newFiltro["anagrafiche"]) {

                let array: any[] = []
                newFiltro["anagrafiche"].forEach((tmp: any) => {

                    let obj = { idFarmacia: tmp.idFarmacia, cpAnagrafica: tmp.cpAnagrafica, isAbilitato: tmp.isAbilitato, ragioneSociale: tmp.ragioneSocialeFarmacia };
                    
                    array.push(obj);
                })
                setAnagrafiche(array)
            }

            if (newFiltro["idFar"]) {
                let idFar = parseInt(newFiltro["idFar"])
                setIdFarm(idFar)
                
            }
            if (newFiltro["lstFar"]) {
                let lstFar = newFiltro["lstFar"];
                setConfigurazioneTrasferimentiFarmLista(lstFar);
            }
            //if (newFiltro["abilitaListino"]) {
                let abilitaListino = newFiltro["abilitaListino"];
                setConfigurazioneTrasferimentiAbilitaListino(abilitaListino);
            //}
            if (configurazioneTrasferimentiFarm !== undefined) {

                let newConfigurazione = configurazioneTrasferimentiFarm;
                configurazioneTrasferimentiFarm.forEach((value, index) => {
                    
                    let codice = value.codice;
                    //confrontare il valore "valore" con l'edit di riferimento
                    if (("edit_" + codice) in newFiltro) {
                        if (newFiltro["edit_" + codice] !== value.valore) {
                            //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                            newConfigurazione[index] = { ...newConfigurazione[index], valore: newFiltro["edit_" + codice] };
                        }
                    }
                    

                    //confrontare il valore "attivo" con il check di riferimento
                    // non pi� valido poich� si utilizzano il radio group al posto dei checkbox
                    //if (newFiltro["check_" + codice] !== value.attivo) {
                    //    //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                    //    newConfigurazione[index] = { ...newConfigurazione[index], attivo: newFiltro["check_" + codice] };
                    //}
                    
                });
                //aggiorno l'oggetto attuale
                setConfigurazioneTrasferimentiFarm(newConfigurazione);
            }

            if (configurazioneTrasferimentiFarmCosto !== undefined) {

                let newConfigurazioneCosto = configurazioneTrasferimentiFarmCosto;
                configurazioneTrasferimentiFarmCosto.forEach((value, index) => {
                    
                    let codice = value.codice;
                    //confrontare il valore "valore" con l'edit di riferimento
                    if (("edit_" + codice) in newFiltro) {
                        if (newFiltro["edit_" + codice] !== value.valoreTipoCosto) {
                            //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                            newConfigurazioneCosto[index] = { ...newConfigurazioneCosto[index], valoreTipoCosto: newFiltro["edit_" + codice] };
                        }
                    }

                    
                    //confrontare il valore "valore" con l'edit di riferimento
                    if (("edit_" + codice) in newFiltro) {
                        if (newFiltro["edit_" + codice] !== value.idAnaCosto) {
                            //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                            newConfigurazioneCosto[index] = { ...newConfigurazioneCosto[index], idAnaCosto: newFiltro["edit_" + codice] };
                        }
                    }

                    
                    //confrontare il valore "attivo" con il check di riferimento
                    // non pi� valido poich� si utilizzano il radio group al posto dei checkbox
                    //if (newFiltro["check_" + codice] !== value.attivo) {
                    //    //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                    //    newConfigurazione[index] = { ...newConfigurazione[index], attivo: newFiltro["check_" + codice] };
                    //}

                });
                //aggiorno l'oggetto attuale
                setConfigurazioneTrasferimentiFarmCosto(newConfigurazioneCosto);
            }

            
        }
        //chiamate effettuate a seconda del componente da visualizzare
        let listConfig: string[] = [];
        
        const chiamate = (farmacia: number) => {
            setIsVisible(true);
            RestServices().rest('POST', farmacia, urlTrasferimenti.getParametri)
                .then(result => {
                    switch (props.contenuto) {
                        case "Configurazione trasferimento merce":
                            setConfigurazioneTrasferimentiFarm(result.disponibilita);
                            setConfigurazioneTrasferimentiFarmCosto(result.costo);
                            setConfigurazioneTrasferimentiFarmLista(result.farmacie);
                            setConfigurazioneTrasferimentiListinoInVigore(result.listinoInVigore);
                            setConfigurazioneTrasferimentiAbilitaListino(result.abilitaListino);
                            setConfigurazioneTrasferimentiAnagrafiche(result.anagrafiche)
                            //result.disponibilita.forEach((value: ConfigurazioneTrasferimentiFarmaciaInterface) => {
                            //    let id = "edit_" + value.codice;
                            //    let filtro = {
                            //        [id]: value.valore,
                            //    }
                            //    impostaFiltri(filtro)
                            //});

                            //result.costo.forEach((value: ConfigurazioneTrasferimentiFarmaciaInterface) => {
                            //    let id = "edit_" + value.codice;
                            //    let filtro = {
                            //        [id]: value.valoreTipoCosto,
                            //    }
                            //    impostaFiltri(filtro)
                            //});

                            break;
                    }
                })
                .then(() => {
                    setIsVisible(false);
                });
        }

        //se c'� una farmacia nel gruppo, le chiamate ai servizio partono in automatico
        useEffect(() => {
            if (props.datiFarmacie.length === 1) {
                const farm = props.datiFarmacie[0].ID_FAR;
                chiamate(farm);
            }
        }, [props.datiFarmacie])

        useEffect(() => {
            if (idFarm)
                startCerca()
        }, [idFarm])

        //se ci sono pi� farmacie, la ricerca parte tramite apposito bottone e si fa un controllo sulla presenza della selezione della farmacia
        const startCerca = () => {
            if (!idFarm) {
                notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, 'error', 4000);
            } else {
                chiamate(idFarm);
            }
        };

        //salvataggio parametri
        const salvaParametri = () => {

            let arrayFar : number[] = []
            anagrafiche?.forEach((tmp: any) => {

                if (tmp.isAbilitato && (!arrayFar.includes(tmp.idFarmacia)))
                    arrayFar.push(tmp.idFarmacia)
            })


            const param = {
                disponibilita: configurazioneTrasferimentiFarm,
                costo: configurazioneTrasferimentiFarmCosto,
                farmacie: arrayFar,
                abilitaListino: configurazioneTrasferimentiAbilitaListino,
                anagrafiche: anagrafiche
            }

            RestServices().rest('POST', param, urlTrasferimenti.setParametri)
                .then(result => {
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "Configurazione salvata" }, "success", 4000)
                    }
                    else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }

        //configurazione selezionata
        const changedSelectedConfig = (e: any) => {
            setSelectedConfig(e.value);
            //alert(e.value);
            if (configurazioneTrasferimentiFarm !== undefined) {

                let newConfigurazione = configurazioneTrasferimentiFarm;
                configurazioneTrasferimentiFarm.forEach((value, index) => {
                    if (value.caption == e.value) {
                        //alert(value.caption);
                        //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                        newConfigurazione[index] = { ...newConfigurazione[index], attivo: true };
                    }
                    else
                        newConfigurazione[index] = { ...newConfigurazione[index], attivo: false };

                });
                //aggiorno l'oggetto attuale
                setConfigurazioneTrasferimentiFarm(newConfigurazione);
                //alert(JSON.stringify(newConfigurazione));
            }
        }
        return (
            <>
                {loadPanel}
                {titolo}

                <p className="testo">
                    Configurazione delle farmacie del gruppo abilitate a ricevere ordini di trasferimento merce da altre farmacie.<br></br>
                    Le opzioni attualmente configurabili sono:<br></br>
                    1.	<b>Impostazione disponibilita'</b><br></br>
                    &emsp;Definisce i criteri della farmacia "grossista" secondo cui rendere o meno la merce richiesta disponibile .<br></br>
                    2.	<b>Impostazione costo</b><br></br>
                    &emsp;Definisce i criteri di costo che la farmacia "grossista" applichera' ai prodotti interessati all'ordine.<br></br>
                    3.	<b>Farmacie abilitate</b><br></br>
                    &emsp;Definisce le farmacie abilitate a richiedere merce alla farmacia selezionata.<br></br>
                </p>

                <div className="contenutoPagina">
                    <Row>
                        <Col sm='4'>
                            <label >Farmacia *</label>
                            <SelectFarm datiFarmacie={props.datiFarmacie} id={'idFar'} impostaFiltri={impostaFiltri} displayExpr={true} />
                        </Col>
                        {/*  {(props.datiFarmacie.length !== 1) &&
                            <Col sm="1">
                                <Button type="button" variant="success" id="btnCercaConfigurazioneTrasferimenti" onClick={startCerca}>Cerca</Button>
                            </Col>}
                            */}
                        {(idFarm) &&
                            <Col sm="1" className="btnSalva">
                                <Button type="button" variant="info" id="btnSalvaConfigurazioneTrasferimenti" onClick={salvaParametri}>Salva   </Button>
                            </Col>
                        }
                    </Row>
                    &nbsp;
                    {/* <Row>
                        <Col sm="1" className="btnSalva">
                            <Button type="button" variant="info" id="btnSalvaConfigurazioneTrasferimenti" onClick={salvaParametri}>Salva   </Button>
                        </Col>
                    </Row>


                    {(configurazioneTrasferimentiFarm !== undefined && configurazioneTrasferimentiFarmLista !== undefined && selectedConfig !== undefined) ?

                        (
                            <Row className="row-top-buffer">
                                <Col sm="4">
                                    <RadioGroup items={configurazioneTrasferimentiFarmLista} value={selectedConfig} onValueChanged={changedSelectedConfig }></RadioGroup>
                                </Col>

                            </Row>

                        )
                    :
                    <></>
                    }
                    */}
                    {(configurazioneTrasferimentiFarm !== undefined) && 
                        <Row>
                        {/*
                            <Col sm="1">
                                <label className="labelTrasf">{configurazioneTrasferimentiFarm.filter(config => config.caption == selectedConfig).map(config => config.descrizione)}</label>
                            </Col>
                            <Col sm="1" className="editTrasf">
                            {
                                (configurazioneTrasferimentiFarm.filter(config => config.caption == selectedConfig).map(config => config.chiave)[0] as string !== undefined) ?
                               <BoxFiltro
                                        id={"edit_" + configurazioneTrasferimentiFarm.filter(config => config.caption == selectedConfig).map(config => config.codice)}
                                        impostaFiltri={impostaFiltri}
                                        value={configurazioneTrasferimentiFarm.filter(config => config.caption == selectedConfig).map(config => config.valore)[0] as string}
                                /> :
                                    <></>
                            }
                        </Col>
                        <Col sm="2"></Col>
                            <Col sm="1">
                                <Button type="button" variant="success" id="btnSalva" onClick={salvaParametri}>Salva</Button>
                            </Col>*/}
                        </Row>
                    }
                    <Row>&nbsp;</Row>
                    {(configurazioneTrasferimentiFarm !== undefined && configurazioneTrasferimentiFarmCosto !== undefined) ?
                        (
                            <div>
                            <Row>
                                <Col sm="12">
                                    <Row>
                                        <PannelloTab
                                                parametriCosto={configurazioneTrasferimentiFarmCosto}
                                                parametriDisponibilita={configurazioneTrasferimentiFarm}
                                                impostaFiltri={impostaFiltri}
                                                datiFarmacie={props.datiFarmacie}
                                                farmacieAbilitate={configurazioneTrasferimentiFarmLista}
                                                listinoInVigore={configurazioneTrasferimentiListinoInVigore}
                                                abilitaListino={configurazioneTrasferimentiAbilitaListino}
                                                anagrafiche={configurazioneTrasferimentiAnagrafiche}
                                                idFarm={idFarm }
                                        />
                                    </Row>
                                </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                            
                            </div>
                        ) :
                        <></>
                    }
                </div>
            </>
        )
    }

export default ConfigurazioneParametri