import React, { useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/esm/Modal';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { BoxFiltro, BoxTextArea, EditorHtml} from '../../widget/Editor';
import { OggettoInterface } from '../../interfaces/generiche';
import { useState } from 'react';
import { FarmaciaSelezionataNewsletter } from '../../interfaces/newsletter';
import notify from "devextreme/ui/notify";
import { RestServices } from '../../services/restServices';
import { urlAnagrafiche } from '../costanti';
import { DialogConferma } from '../../widget/Notifications';
import FileUploader from 'devextreme-react/file-uploader';

export const DialogNuovaNewsletter = (props: {
    isDialogNuovaNewsletter: boolean,
    setIsDialogNuovaNewsletter: (isDialogNuovaNewsletter: boolean) => void
    handleCloseDialogNuovaNewsletter: () => void,
    farmacieSelezionate: FarmaciaSelezionataNewsletter[] | undefined,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    //const defaultValue = "<p>Salve Gentile cliente,</p><p>bla bla bla</p><p>bla bla bla</p>&nbsp;&nbsp; <p>Cliccando sul link di sotto &egrave possibile disattivare la ricezione delle newsletter</p><p>--link per disdetta newsletter(non cancellare o modificare questa riga)--</p>";
    const editorHtmlRef = useRef(null);
    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [messaggioConferma, setMessaggioConferma] = useState<any>();
    const [buttonClicked, setButtonClicked] = useState<number>();
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [defaultValue, setDefaultValue] = useState<string|undefined>();

    useEffect(() => {
        
        if (props.isDialogNuovaNewsletter) {
           
            
        }
    }, [props.isDialogNuovaNewsletter])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogNuovaNewsletter(false);
    }

    const functionNext = () => {
        setDialogIsOpen(false);
        if (buttonClicked == 1) {
            inviaNewsletter()
        }
        else if (buttonClicked == 2) {
            inviaNewsletterTest();
        }
    }

    const inviaNewsletterConferma = () => {
        setButtonClicked(1);
        setMessaggioConferma("Inviare la newsletter alle " + props.farmacieSelezionate?.length + " farmacie selezionate?")
        setDialogIsOpen(true);
    }

    const inviaNewsletterTestConferma = () => {
        setButtonClicked(2);
        setMessaggioConferma("Inviare la newsletter di test all'indirizzo " + filtri?.emailTest + "?")
        setDialogIsOpen(true);
    }

    const inviaNewsletter = () => {
        if (filtri?.oggetto == undefined) {
            notify({ position: "center", width: "auto", message: "Inserire l'oggetto" }, 'error', 4000);
            return;
        }
        if (filtri?.testo == undefined) {
            notify({ position: "center", width: "auto", message: "Inserire il testo" }, 'error', 4000);
            return;
        }
        if (props.farmacieSelezionate == undefined) {
            notify({ position: "center", width: "auto", message: "Nessuna farmacia selezionata" }, 'error', 4000);
            return;
        }
        const param = {
            farmacie: props.farmacieSelezionate,
            oggetto: filtri.oggetto,
            testo: filtri.testo,
            test: false,
            emailTest: ""
        }
        setDefaultValue(filtri.testo);
        props.setIsVisible(true);
        RestServices().rest('POST', param, urlAnagrafiche.inviaNewsletter)
            .then(result => {
                props.setIsVisible(false);
                if (result != undefined) {
                    if (result.ok) {
                        notify({ position: "center", width: "auto", message: "L'invio delle email e' stato schedulato" }, 'info', 2500);
                    } else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })

    }

    const inviaNewsletterTest = () => {
        if (filtri?.oggetto == undefined) {
            notify({ position: "center", width: "auto", message: "Inserire l'oggetto" }, 'error', 4000);
            return;
        }
        if (filtri?.emailTest == undefined) {
            notify({ position: "center", width: "auto", message: "Inserire l'email di test" }, 'error', 4000);
            return;
        }
        if (filtri?.testo == undefined) {
            notify({ position: "center", width: "auto", message: "Inserire il testo" }, 'error', 4000);
            return;
        }

        let testo = localStorage.getItem('editorValue');
            
        const param = {
            oggetto: filtri.oggetto,
            testo: filtri.testo,
            test: true,
            emailTest: filtri.emailTest,
            farmacie: []
        }
        setDefaultValue(filtri.testo);
        props.setIsVisible(true);
        RestServices().rest('POST', param, urlAnagrafiche.inviaNewsletter)
            .then(result => {
                props.setIsVisible(false);
                if (result != undefined) {
                    if (result.ok) {
                        notify({ position: "center", width: "auto", message: "L'invio delle email e' stato schedulato" }, 'info', 2500);
                    } else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const onEditorValueChange = (e: any) => {
        localStorage.setItem('editorValue', e.value);
    }


    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
    const headerValues = [false, 1, 2, 3, 4, 5];
    return (
        <>
            <Modal
                show={props.isDialogNuovaNewsletter}
                onHide={handleClose}
                dialogClassName="dialogNuovaNewsletter"
                size="xl"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Nuova newsletter</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="2" className= "labelCreaNuovaNewsletter">
                            <span>Oggetto:</span>
                        </Col>
                        <Col sm="10">
                            <BoxFiltro id="oggetto" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm="2" className="labelCreaNuovaNewsletter">
                            <span>Testo(html):</span>
                        </Col>
                        <Col sm="10">
                            {/*<EditorHtml
                                id="editorNewsletter"
                                defaultValue="<p>Salve Gentile cliente,</p><p>bla bla bla</p><p>bla bla bla</p>&nbsp;&nbsp; <p>Cliccando sul link di sotto &egrave possibile disattivare la ricezione delle newsletter</p><p>--link per disdetta newsletter(non cancellare o modificare questa riga)--</p>"
                                impostaFiltri={impostaFiltri}
                            />*/}
                                
                            {/*<HtmlEditor*/}
                            {/*    height={575}*/}
                            {/*    defaultValue={localStorage.getItem('editorValue')}*/}
                            {/*    id="editorNewsletter"*/}
                            {/*    onValueChanged={onEditorValueChange}*/}
                            {/*    ref={editorHtmlRef}*/}
                            {/*>*/}
                            {/*    <MediaResizing enabled={true} />*/}
                            {/*    <Toolbar multiline={true}>*/}
                            {/*        <Item formatName="undo" />*/}
                            {/*        <Item formatName="redo" />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item*/}
                            {/*            formatName="size"*/}
                            {/*            formatValues={sizeValues}*/}
                            {/*        />*/}
                            {/*        <Item*/}
                            {/*            formatName="font"*/}
                            {/*            formatValues={fontValues}*/}
                            {/*        />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item formatName="bold" />*/}
                            {/*        <Item formatName="italic" />*/}
                            {/*        <Item formatName="strike" />*/}
                            {/*        <Item formatName="underline" />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item formatName="alignLeft" />*/}
                            {/*        <Item formatName="alignCenter" />*/}
                            {/*        <Item formatName="alignRight" />*/}
                            {/*        <Item formatName="alignJustify" />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item formatName="orderedList" />*/}
                            {/*        <Item formatName="bulletList" />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item*/}
                            {/*            formatName="header"*/}
                            {/*            formatValues={headerValues}*/}
                            {/*        />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item formatName="color" />*/}
                            {/*        <Item formatName="background" />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item formatName="link" />*/}
                            {/*        <Item formatName="image" />*/}
                            {/*        <Item formatName="separator" />*/}
                            {/*        <Item formatName="clear" />*/}
                            {/*        <Item formatName="codeBlock" />*/}
                            {/*        <Item formatName="blockquote" />*/}
                            {/*    </Toolbar>*/}
                            {/*</HtmlEditor>*/}

                            <BoxTextArea id="testo" impostaFiltri={impostaFiltri} value={defaultValue} />

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                        <Col sm="2" className="labelCreaNuovaNewsletter mr-auto">
                            <span>Email di test</span>
                        </Col>
                        <Col sm="4" className="mr-auto">
                            <BoxFiltro id="emailTest" impostaFiltri={impostaFiltri} />
                        </Col>
                        <Button id="btnInviaNewsletter" variant="info" onClick={inviaNewsletterTestConferma} >
                                Invia newsletter test
                        </Button>
                        <Button id="btnInviaNewsletter" variant="success" onClick={inviaNewsletterConferma} >
                                Invia newsletter
                        </Button>
                        <Button id="btnChiudiDialogNuovaNewsletter" variant="danger" onClick={() => props.handleCloseDialogNuovaNewsletter()}>
                            Chiudi
                        </Button>


                </Modal.Footer>
            </Modal>

            <DialogConferma
                dialogIsOpen={dialogIsOpen}
                message={messaggioConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />
        </>
        )
}


export const DialogUploadFileNewsletter = (props: {
    isDialogUploadFileNewsletter: boolean,
    setIsDialogUploadFileNewsletter: (isDialogUploadFileNewsletter: boolean) => void
    handleCloseDialogUploadFileNewsletter: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [uploadUrl, setUploadUrl] = useState<any>();

    const fileExtensions = ['.jpg', '.jpeg', '.png'];

    useEffect(() => {

        if (props.isDialogUploadFileNewsletter) {
            setUploadUrl(urlAnagrafiche.uploadFile);
        }
    }, [props.isDialogUploadFileNewsletter])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogUploadFileNewsletter(false);
    }

    const aggiungiParametroFile = (e: any) => {
        if (e.value.length > 0) {
            setUploadUrl(updateQueryStringParameter(uploadUrl, "nomeFile", e.value[0].name))
            e.component.option("uploadUrl", uploadUrl);
        }
        
    }

    const updateQueryStringParameter =  (uri:any, key: any, value: any) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
           // console.log("1:" + uri.replace(re, '$1' + key + "=" + value + '$2'));
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
           // console.log("2:" + uri + separator + key + "=" + value);
            return uri + separator + key + "=" + value;
        }
    }
    return (
        <>
            <Modal
                show={props.isDialogUploadFileNewsletter}
                onHide={handleClose}
                dialogClassName="dialogNuovaNewsletter"
                size="lg"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Upload File</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-uploader-block">
                        <FileUploader
                            selectButtonText="Seleziona file" labelText=""
                            /*accept="image/*"*/
                            uploadMode="useButtons"
                            uploadUrl={uploadUrl}
                            onValueChanged={aggiungiParametroFile}
                            /*allowedFileExtensions={fileExtensions}*/
                                maxFileSize={2000000}
                        />
                        <Row>
                            <Col>
                                {/*<span className="noteFileUploadNewsletter">{'File permessi: '}
                            <span>.jpg, .jpeg, .png</span>
                            .
                        </span>*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                        <span className="noteFileUploadNewsletter">{'Dimensione massima file: '}
                            <span>2 MB</span>
                            .
                        </span>
                            </Col>
                            </Row>
                        </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogNuovaNewsletter" variant="danger" onClick={() => props.handleCloseDialogUploadFileNewsletter()}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}