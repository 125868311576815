import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { trasform_no_marge } from "../util/trasformation";
import { urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Title, Export, Font, Format, HoverStyle, Tooltip } from "devextreme-react/pie-chart";
import Chart, { CommonSeriesSettings, Legend, Series, Label } from 'devextreme-react/chart';
import { Loading } from "../../../widget/Notifications";


const dashboard14 = { dashboardID: 14 };

export interface boxCard {
    testoPop?: string;
}

export interface boxsecond {
    testoPop?: string;
}

interface cardV {
    testo: string;
    subTesto: string;
    keyValue: string | undefined;
    typeShowValue?: typeShow;
    testoPop?: string;
    second?: boolean;
}

const boxCard: cardC[] = [
    {
        testo: "Numero ricette",
        valore: "NUMERO_RICETTE",
        testoPop:
            "Numero ricette: Rappresenta il numero di ricette lavorate dalla farmacia nel periodo selezionato. Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del numero ricette per sesso e fasce d'et�."
    },
    {
        testo: "Numero pezzi",
        valore: "NUMERO_PEZZI",
        testoPop: "Numero pezzi: Rappresenta il numero di pezzi erogati dietro ricetta dalla farmacia nel periodo selezionato. Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del numero pezzi per sesso e fasce d'et�.",
    },
    {
        testo: "Assistiti distinti serviti",
        subTesto: "% Ricette con CF registrato",
        valore: "ASSISTITI_DISTINTI_SERVITI",
        subValore: "PERCENTUALE_RICETTE_CF",
        typeShowSubValue: typeShow.percento,
        testoPop: `Assistiti distinti serviti: Rappresenta il numero di assistiti distinti serviti dalla farmacia per l'erogazione delle ricette, nel periodo selezionato. 
                   La % ricette con CF registrato indica la percentuale del numero di ricette su cui il dato relativo al codice fiscale consente di determinare l'univocit� del cliente.
                   Nel caso in cui questa percentuale sia inferiore al 100% allora alcune ricette non hanno un codice fiscale utile per determinare informazioni anagrafiche e quindi non si riesce ad individuare univocamente un cliente. Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del numero di assistiti distinti per sesso e fasce d'et�.`,
    },
];

const boxsecond: cardC[] = [
    {
        testo: "Numero medio ricette per assistito distinto",
        subTesto: "Numero medio ricette per assistito distinto equiparato",
        valore: "NUMERO_RICETTE_MEDIO_ASSISTITO_DISTINTO",
        testoPop: `Numero medio ricette per assistito distinto: Rappresenta il numero medio di ricette erogate ad ogni assistito distinto servito dalla farmacia nel periodo selezionato. Questo indicatore dev'essere per� studiato considerando la % ricette con CF registrato che indica la percentuale del numero di ricette su cui il dato relativo al codice fiscale consente di determinare l'univocit� del cliente. 
                   Questo perch� nel caso in cui tale percentuale sia inferiore al 100% allora alcune ricette non hanno un codice fiscale utile per determinare informazioni anagrafiche e quindi non si riesce ad individuare univocamente un cliente. 
                   Il numero medio ricette per assistito distinto equiparato considera il dato solo per quelle ricette su cui il codice fiscale consente di estrarre informazioni anagrafiche. 
                   Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del numero medio di ricette per assistito distinto per sesso e fasce d'et�.`
    },
    {
        testo: "Numero medio pezzi per ricetta",
        valore: "NUMERO_PEZZI_MEDIO_RICETTA",
        testoPop: "Numero medio pezzi per ricetta: Rappresenta il numero medio di pezzi erogati per ricetta dalla farmacia nel periodo selezionato.",
    },
    {
        testo: "Numero medio pezzi per assistito distinto",
        subTesto: "Numero medio pezzi per assistito distinto equiparato",
        valore: "NUMERO_PEZZI_MEDIO_ASSISTITO_DISTINTO",
        subValore: "",
        testoPop: `Numero medio pezzi per assistito distinto: Rappresenta il numero medio di pezzi venduti ad ogni assistito distinto servito dalla farmacia nel periodo selezionato. 
                   Questo indicatore dev'essere per� studiato considerando la % ricette con CF registrato che indica la percentuale del numero di ricette su cui il dato relativo al codice fiscale consente di determinare l'univocit� del cliente. 
                   Questo perch� nel caso in cui tale percentuale sia inferiore al 100% allora alcune ricette non hanno un codice fiscale utile per determinare informazioni anagrafiche e quindi non si riesce ad individuare univocamente un cliente.
                   Il numero medio pezzi per assistito distinto equiparato considera il dato solo per quelle ricette su cui il codice fiscale consente di estrarre informazioni anagrafiche. 
                   Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del numero medio di pezzi per assistito distinto per sesso e fasce d'et�.`
    },
];

const box3: cardC[] = [
    {
        testo: "Lordo",
        valore: "LORDO",
        typeShowValue: typeShow.euro,
        testoPop: `Lordo: Rappresenta il valore lordo delle ricette lavorate dalla farmacia nel periodo selezionato, Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del valore lordo per sesso e fasce d'et�.`,
    },
    {
        testo: "Sconto regione",
        typeShowValue: typeShow.euro,
        valore: "SCONTI_REGIONE",
        testoPop: `Sconto regione: Rappresenta lo sconto applicato dalla regione alle ricette lavorate dalla farmacia nel periodo selezionato, 
             Gli sconti considerati sono: Sconto DL. 182,
             Sconto Industria,
             Sconto PayBack, Sconto SSN,
             Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione dello sconto regione per sesso e fasce d'et�.`
    },
    {
        testo: "Fatturato",
        typeShowValue: typeShow.euro,
        valore: "FATTURATO",
        testoPop: `Fatturato: Rappresenta il fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato. Il fatturato � calcolato come differenza tra il valore lordo e lo sconto regione.
             Gli sconti considerati dallo sconto regione sono:
             Sconto DL. 182
             Sconto Industria
             Sconto PayBack
             Sconto SSN
             Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del fatturato per sesso e fasce d'et�.`,
    },
    {
        testo: "Ticket",
        typeShowValue: typeShow.euro,
        valore: "TICKET",
        testoPop: `Ticket: Rappresenta il ticket incassato anticipatamente dalle ricette lavorate dalla farmacia nel periodo selezionato. 
                   Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del ticket per sesso e fasce d'et�.`,
    },
    {
        testo: "Netto a liquidare",
        typeShowValue: typeShow.euro,
        valore: "NETTO_A_LIQUIDARE",
        testoPop: `Netto a liquidare:
                   Rappresenta il netto a liquidare generato dalle ricette lavorate dalla farmacia nel periodo selezionato.
                   Il netto a liquidare � calcolato come differenza tra il fatturato e il ticket, rappresenta quanto l'ASL liquider� alla farmacia.
                   Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del netto a liquidare per sesso e fasce d'et�.`,
    },
];

const box4: cardC[] = [
    {
        testo: "Fatturato medio per ricetta",
        typeShowValue: typeShow.euro,
        valore: "FATTURATO_MEDIO_RICETTA",
        testoPop: `Fatturato medio per ricetta: Rappresenta il fatturato medio generato per ricetta lavorata dalla farmacia nel periodo selezionato. Il fatturato � calcolato come differenza tra il valore lordo e lo sconto regione. 
             Gli sconti considerati dallo sconto regione sono:
             Sconto DL. 182
             Sconto Industria
             Sconto PayBack
             Sconto SSN
             Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del fatturato medio per ricetta per sesso e fasce d'et�.`
    },
    {
        testo: "Ticket medio per ricetta",
        typeShowValue: typeShow.euro,
        valore: "TICKET_MEDIO_RICETTA",
        testoPop: `Ticket medio per ricetta: Rappresenta il ticket medio incassato per ricetta lavorata dalla farmacia nel periodo selezionato.
                  Cliccando sull'indicatore � possibile richiamare una dashboard di approfondimento con la distribuzione del ticket medio per ricetta per sesso e fasce d'et�.`
    },
];



//da spostare
const convertTime = (data: Date) => {
    const anno = data.getFullYear();
    const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perch� i mesi sono 0-based
    const giorno = data.getDate().toString().padStart(2, "0");
    const ore = data.getHours().toString().padStart(2, "0");
    const minuti = data.getMinutes().toString().padStart(2, "0");
    const secondi = data.getSeconds().toString().padStart(2, "0");

    const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

    return dataFormattata;
};

const AnalisiRicette = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [valori4W, setValori4W] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCard);
    const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecond);
    const [fila3cards, setFila3cards] = useState<cardC[]>(box3);
    const [fila4cards, setFila4cards] = useState<cardC[]>(box4);

    const [pie, setPie] = useState<any[]>([]);
    const [secondpie, setSecondPie] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePopOne, setVisiblePopOne] = useState(false);


    const [visiblePopCHT, setVisiblePopCHT] = useState(false);
    const [visiblePopCHT2, setVisiblePopCHTtwo] = useState(false);

    const [visiblePopPie, setVisiblePopPie] = useState(false);
    const [visiblePopPie2, setVisiblePopPietwo] = useState(false);
    const [visiblePopPie3, setVisiblePopPiethree] = useState(false);
    const [visiblePopPie4, setVisiblePopPiefour] = useState(false);
    const [visiblePopPie5, setVisiblePopPiefive] = useState(false);

    const [tipoRaggruppamento, setTipoRaggruppamento] = useState<any[]>([]);
    const [sesso, setSesso] = useState<any[]>([]);
    const [eta, setEta] = useState<any[]>([]);
    const [flagGenerico, setFlagGenerico] = useState<any[]>([]);

    const [totalValue, setTotalValue] = useState(0);
    const [totalValueMargine, setTotalValueMargine] = useState(0);
    const [chartData, setChartData] = useState<any>([]);
    const chartRef = useRef<any>(null);
    // ??? const [chartKey, setChartKey] = useState(false);

    const [chartDataMargine, setChartDataMargine] = useState<any>([]);
    // ??const chartRefMargine = useRef<any>(null);
    // ??const [chartKeyMargine, setChartKeyMargine] = useState(false);

    const [chartDataNumeroVend, setChartDataNumeroVend] = useState<any>([]);
    // ??const chartRefNumeroVend = useRef<any>(null);
    // ??const [chartKeyNumeroVend, setChartKeyNumeroVend] = useState(false);

    const [chartDataPezzi, setChartDataPezzi] = useState<any>([]);
    //??const chartRefPezzi = useRef<any>(null);
    //?? const [chartKeyPezzi, setChartKeyPezzi] = useState(false);

    const chartRefDitta = useRef<any>(null);
    //??const [chartKeyDitta, setChartKeyDitta] = useState(false);

    const [chartDataRTEPie, setChartDataRTEPie] = useState<any>([]);
    const [chartDataFTEPie, setChartDataFTEPie] = useState<any>([]);
    const [chartDataASLPie, setChartDataASLPie] = useState<any>([]);
    const [chartDataRSPPie, setChartDataRSPPie] = useState<any>([]);
    const [chartDataFSPPie, setChartDataFSPPie] = useState<any>([]);
    const [chartDataRFEPie, setChartDataRFEPie] = useState<any>([]);
    const [chartDataFFEPie, setChartDataFFEPie] = useState<any>([]);

    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale � mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const mmonthNames = [
        "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
        "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
    ];

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        console.log(params.sesso);

        const parameters = {
            dal: params.periodo.dal.toLocaleString().substring(0, 6),
            al: params.periodo.al.toLocaleString().substring(6, 0),
            tipoRaggruppamento: params.tipoRaggruppamento,
            sesso: params.sesso,
            eta: params.eta,
            flagGenerico: params.flagGenerico
        };

        console.log(parameters);
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall14 = {
                ...dashboard14,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall14, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    console.log(result)
                    setIsVisible(false);
                    if (result) {

                        console.log(result);
                        setChartData(result["70"]);
                        setChartDataMargine(result["70"].reverse());
                        //setChartDataNumeroVend(result[""].reverse());
                        //setChartDataPezzi(result[""].reverse());

                        //  chartpie
                        setChartDataRTEPie(result["86"]);

                        setChartDataFTEPie(result["86"]);

                        setChartDataASLPie(result["71"]);

                        setChartDataRSPPie(result["71"]);

                        setChartDataFSPPie(result["71"]);

                        setChartDataRFEPie(result["72"]);

                        setChartDataFFEPie(result["72"]);

                        let datiPrimaParteCards = trasform_no_marge(
                            result["68"]["0"],
                            boxCard
                        );


                        let datiSecondaParteCards = trasform_no_marge(
                            result["68"]["0"],
                            boxsecond
                        );

                        let dati3Cards = trasform_no_marge(
                            result["68"]["0"],
                            box3
                        );

                        let dati4Cards = trasform_no_marge(
                            result["68"]["0"],
                            box4
                        );

                        console.log(result[68]);

                        setFila1cards(datiPrimaParteCards);
                        setFila2cards(datiSecondaParteCards);
                        setFila3cards(dati3Cards);
                        setFila4cards(dati4Cards);

                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);

                        const total = result["70"]
                            .reverse()
                            .slice(12, 23)
                            .reduce(
                                (acc: number, item: any) => acc + item.NUMERO_RICETTE,
                                0
                            );
                        const formattedTotal = total.toLocaleString("it-IT", {
                            minimumFractionDigit: 0,
                        });

                        setTotalValue(formattedTotal);


                        const total2 = result["70"]
                            .reverse()
                            .slice(12, 23)
                            .reduce((acc: number, item: any) => acc + item.FATTURATO, 0);
                        const formattedTotaLMargine = total2.toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionsDigits: 0,
                        });

                        setTotalValueMargine(formattedTotaLMargine);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            const inizioAnno = new Date();

            inizioAnno.setMonth(0);
            inizioAnno.setDate(1);

        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };



    const popVisibleCHT = (test: string) => {
        setVisiblePopCHT(true);
    }

    const popVisibleCHT2 = (test: string) => {
        setVisiblePopCHTtwo(true);
    }

    let CHTOne = [`Il grafico a colonne mostra l'andamento del numero ricette lavorate dalla farmacia negli ultimi 12 mesi rolling, compreso il mese corrente.
                  Ogni mese � visivamente comparato con la propria performance dell'anno precedente.`]

    let CHTtwo = [`Il grafico a colonne mostra l'andamento del fatturato relativo alle ricette lavorate dalla farmacia negli ultimi 12 mesi rolling, compreso il mese corrente.
                  Ogni mese � visivamente comparato con la propria performance dell'anno precedente.`]



    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };

    const popVisiblePie = (testo: string) => {
        setVisiblePopPie(true);
    };

    const popVisiblePie2 = (testo: string) => {
        setVisiblePopPietwo(true);
    };

    const popVisiblePie3 = (testo: string) => {
        setVisiblePopPiethree(true);
    }

    const popVisiblePie4 = (testo: string) => {
        setVisiblePopPiefour(true);
    }

    const popVisiblePie5 = (testo: string) => {
        setVisiblePopPiefive(true);
    }


    let messOne = [`Distribuzione ricette per Sesso paziente:
                    Rappresenta la distribuzione per Sesso del numero di ricette lavorate dalla farmacia nel periodo selezionato.`];

    let messTwo = [`Distribuzione fatturato per Sesso paziente:
                    Rappresenta la distribuzione per Sesso del fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato.`];

    let messThree = [`Distribuzione ricette per tipologia farmaco erogato:
                      Rappresenta la distribuzione per tipologia farmaco erogato del numero di ricette lavorate dalla farmacia nel periodo selezionato.`]

    let messFour = [`Distribuzione fatturato per per tipologia farmaco erogato:
                     Rappresenta la distribuzione per tipologia farmaco erogato del fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato.`]

    let messSix = [`Distribuzione ricette per Fasce d'et� paziente:
                    Rappresenta la distribuzione per Fasce d'et� del numero di ricette lavorate dalla farmacia nel periodo selezionato.`]

    let messSeven = [`Distribuzione fatturato per Fasce d'et� paziente:
                      Rappresenta la distribuzione per Fasce d'et� del fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato.`]


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();

    }, [auth]);

    const filters = () => {
        if (auth) {

            const nondefinito = { id: 1, descrizione: 'Non Definito' }
            const ssn = { id: 2, descrizione: 'SSN' }
            const int = { id: 3, descrizione: `INT` }
            const dpc = { id: 4, descrizione: `DPC` }
            const intd = { id: 5, descrizione: `INTD` }
            const irc = { id: 6, descrizione: `IRC` }
            const cel = { id: 7, descrizione: `CEL` }
            const die = { id: 8, descrizione: `DIE` }
            const pro = { id: 9, descrizione: `PRO` }
            const care = { id: 10, descrizione: `CARE` }

            const tipoRaggruppamento = [nondefinito, ssn, int, dpc, intd, irc, cel, die, pro, care];
            setTipoRaggruppamento(tipoRaggruppamento);

            const male = { id: `M`, descrizione: `M` }
            const female = { id: `F`, descrizione: `F` }

            const sesso = [male, female]
            setSesso(sesso);

            const a = { id: `A`, codice: `A`, descrizione: `Neonati, 0 - 3 anni` }
            const b = { id: `B`, codice: `B`, descrizione: `Bambini, 3 - 11 anni` }
            const c = { id: `C`, codice: `C`, descrizione: `Adolescenti, 11 - 17 anni` }
            const d = { id: `D`, codice: `D`, descrizione: `Giovani adulti, 18 - 34 anni`}
            const e = { id: `E`, codice: `E`, descrizione: `Adulti, 35 - 44 anni` }
            const f = { id: `F`, codice: `F`, descrizione: `Adulti maturi, 45 - 59 anni` }
            const g = { id: `G`, codice: `G`, descrizione: `Giovani anziani, 60 - 74 anni`}
            const h = { id: `H`, codice: `H`, descrizione: `Anziani, 74 - 84 anni` }
            const i = { id: `I`, codice: `I`, descrizione: `Grandi anziani, 85+ anni` }
            const l = { id: `L`, codice: `L`, descrizione: `Eta non disponibile` }

            const eta = [a, b, c, d, e, f, g, h, i, l]
            setEta(eta)

            const fb = { id: 0, descrizione: "Ricetta con soli farmaci branded" }
            const fe = { id: 1, descrizione: "Ricetta con soli farmaci equivalenti" }

            const flagGenerico = [fb, fe]
            setFlagGenerico(flagGenerico)

            console.log();
        }
    };

    useEffect(() => {
        filters();


    }, [auth]);


    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "eta",
            label: "Fascia d'eta del paziente",
            displayExpr: "descrizione",
            dataSource: eta,
            idValueGrid: "eta" as any,
        },
        {
            type: "selectWithLevels",
            id: "sesso",
            label: "Sesso del paziente",
            noId: true,
            displayExpr: "descrizione",
            dataSource: sesso,
            idValueGrid: "sesso" as any,
        },
        {
            type: "selectWithLevels",
            id: "tipoRaggruppamento",
            label: "Tipo raggruppamento",
            noId: true,
            displayExpr: "descrizione",
            dataSource: tipoRaggruppamento,
            idValueGrid: "tipoRaggruppamento" as any,
        },
    ];


    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);
    
    return (<section style={{ display: 'flex', justifyContent: 'space-between', height: '115vh' }}>
        {loadPanel}
        {loadinCambio ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
                <LoadIndicator id="small-indicator" height={50} width={50} />
            </div>
        ) : (

            <section id='scrollerelement' style={{ width: '100%', overflow: 'scroll', height: "auto" }}>
                <div style={{ fontSize: '14px', alignItems: 'left', margin: '10px', color: 'rgb(80, 84, 92)' }}>
                    <h2>BusinessLab Smart - Analisi Ricette</h2>
                    </div>

                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 20,
                            width: "auto",
                        }}
                    >


                        <div style={{ backgroundColor: 'white', marginLeft: -10, marginRight: -10, marginBottom: 5 }}>

                       
                            <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                                <div style={{ width: "95%", margin: "10px" }}>
                                    <Chart dataSource={chartData} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            selectionMode="allArgumentPoints"
                                            barPadding={0.2}
                                        >
                                            <Label visible={true}>
                                                <Format precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="NUMERO_RICETTE_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)">

                                        </Series>

                                        

                                        <Series
                                            valueField="NUMERO_RICETTE"
                                            type="bar"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                        <Label visible={true} verticalOffset={80}></Label>

                                        </Series>
                                        

                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                );

                                                return {
                                                    text: `Numero ricette: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Numero ricette - Ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="20" weight="400" />
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="Numero_Ricette_Ultimi_12_Mesi" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisibleCHT("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>

                            </div>

                            {chartDataMargine.length > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginTop: 15
                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValue}</span>
                                    </h2>
                                </div>
                            )}

                        </div>

                       
                        <div style={{ backgroundColor: 'white', marginLeft: -10, marginRight: -10, marginBottom: 5 }}>

                        
                            <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                                <div style={{ width: "95%", margin: 10, marginBottom: 5 }}>
                                    <Chart dataSource={chartDataMargine} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            selectionMode="allArgumentPoints"
                                        >
                                            <Label visible={true}>
                                                <Format type="currency" currency="EUR" precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="FATTURATO_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)">
                                        </Series>

                                        

                                        <Series
                                            valueField="FATTURATO"
                                            type="bar"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                        <Label visible={true} verticalOffset={80}></Label>

                                        </Series>
                                        

                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        style: "currency",
                                                        currency: "EUR",
                                                        minimumFractionDigits: 2,
                                                    }
                                                );

                                                return {
                                                    text: `Fatturato: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Fatturato - Ultimi 12 messi rolling">
                                            <Font color="#5b6372" size="20" weight="400" />
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="Fatturato_Ultimi_12_Mesi" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisibleCHT2("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                                
                            </div>
                            {chartDataMargine.length > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        marginTop: 15

                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueMargine}</span>
                                    </h2>
                                </div>
                            )}

                        </div>

                        
                    </div>
                    


                  
                   
                    
                    

                    <Popup
                        visible={visiblePop}
                        showCloseButton={true}
                        width={"40%"}
                        height={"60%"}
                        showTitle={true}
                        title="Dettagli widget"
                    >
                        <div>
                            <p>{testoPop}</p>
                        </div>
                    </Popup>      

                     <div
                        style={{
                            display: "block",
                            gap: 5,
                            borderRadius: 10,
                            marginTop: 5,
                            marginBottom: 10,
                            width: "100%",
                        }}
                    >
                   

                        <div style={{ paddingBottom: 5 }} className="grid-container-3-cate">
                            {fila1cards.map((el, i) => {
                                console.log("ciao")
                                console.log(el)
                                
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>

                        <div className="grid-container-3-cate">
                            {fila2cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>

                        <div style={{ fontSize: 16, width: 'calc(99.9%)', marginTop: 5, position: 'relative' }} className="grid-first">
                            {fila3cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>


                        <div className="grid-ibrid">
                            <div style={{ width: 'calc(166.9%)', paddingBottom: 5 }} className="grid-container-one">
                                {fila4cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                      </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5,
                            paddingBottom: 10
                        }}
                    >
                        <div style={{ width: "55%", marginLeft: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataRTEPie}
                                palette="Green Mist"
                                paletteExtensionMode="blend"
                                type="doughnut"

                            >
                                <Series
                                    argumentField="TIPO_RICETTA"
                                    valueField="NUMERO_RICETTE"
                                >
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>

                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        const dataItem = chartDataRTEPie.find((item: any) => item.TIPO_RICETTA === argument);
                                        const dataSourceValue = dataItem ? dataItem.NUMERO_RICETTE : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                        return {
                                            html: `<p>Tipo ricetta: ${argument}<p><p>Numero ricette: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title
                                    text="Distribuzione ricette per tipologia farmaco erogato"
                                >
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export fileName="Distribuzione ricette per tipologia farmaco erogato" enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>


                        <div style={{ width: "55%" }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataFTEPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series argumentField="TIPO_RICETTA" valueField="FATTURATO">
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>
                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        const dataItem = chartDataFTEPie.find((item: any) => item.TIPO_RICETTA === argument);
                                        const dataSourceValue = dataItem ? dataItem.FATTURATO : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                        return {
                                            html: `<p>Tipo ricetta: ${argument}<p><p>Fatturato: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title text="Distribuzione fatturato per tipologia farmaco erogato">
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export fileName="Distribuzione fatturato per tipologia farmaco erogato" enabled={true} />
                            </PieChart>
                        </div>
                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie2("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>

                    </div>


                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5,
                            paddingBottom: 10
                        }}
                    >

                        <div style={{ width: "45%" , marginTop: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataRSPPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series
                                    argumentField="SESSO"
                                    valueField="NUMERO_RICETTE"
                                >
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>

                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        const dataItem = chartDataRSPPie.find((item: any) => item.SESSO === argument);
                                        const dataSourceValue = dataItem ? dataItem.NUMERO_RICETTE : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                        return {
                                            html: `<p>Sesso: ${argument}<p><p>Numero ricette: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title
                                    text="Distribuzione ricette per sesso paziente"
                                >
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export fileName="Distribuzione ricette per sesso paziente" enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisible1("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>


                        <div style={{ width: "45%", marginTop: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataFSPPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series
                                    argumentField="SESSO"
                                    valueField="FATTURATO"
                                >
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>

                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        const dataItem = chartDataFSPPie.find((item: any) => item.SESSO === argument);
                                        const dataSourceValue = dataItem ? dataItem.FATTURATO : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                        return {
                                            html: `<p>Sesso: ${argument}<p><p>Fatturato: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title
                                    text="Distribuzione fatturato per sesso paziente"
                                >
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export fileName="Distribuzione fatturato per sesso paziente" enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisible2("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5,
                            paddingBottom: 20
                        }}
                    >
                        <div style={{ width: "45%", marginTop: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataRFEPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series
                                    argumentField="ETA_RANGE"
                                    valueField="NUMERO_RICETTE"
                                >
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>

                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        const dataItem = chartDataRFEPie.find((item: any) => item.ETA_RANGE === argument);
                                        const dataSourceValue = dataItem ? dataItem.NUMERO_RICETTE : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                        return {
                                            html: `<p>Eta: ${argument}<p><p>Numero ricette: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title
                                    text="Distribuzione ricette per fasce d'eta"
                                >
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export fileName="Distribuzione ricette per fasce d'eta" enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie4("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>

                        <div style={{ width: "45%", marginTop: 30 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataFFEPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series argumentField="ETA_RANGE" valueField="FATTURATO">
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>
                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        const dataItem = chartDataFFEPie.find((item: any) => item.ETA_RANGE === argument);
                                        const dataSourceValue = dataItem ? dataItem.FATTURATO : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                        return {
                                            html: `<p>Eta: ${argument}<p><p>Fatturato: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title text="Distribuzione fatturato per fasce d' eta">
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export fileName="Distribuzione fatturato per fasce d'eta" enabled={true} />
                            </PieChart>
                        </div>
                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie5("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>
                    </div>
                </section>
            )}

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10, marginLeft: 1, height: "115vh"}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
            </section>
            <Popup
                visible={visiblePopCHT}
                showCloseButton={true}
                onHiding={() => setVisiblePopCHT(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{CHTOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopCHT2}
                showCloseButton={true}
                onHiding={() => setVisiblePopCHTtwo(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{CHTtwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop1}
                showCloseButton={true}
                onHiding={() => setVisiblePop1(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie}
                showCloseButton={true}
                onHiding={() => setVisiblePopPie(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie2}
                showCloseButton={true}
                onHiding={() => setVisiblePopPietwo(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messFour}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie4}
                showCloseButton={true}
                onHiding={() => setVisiblePopPiefour(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messSix}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie5}
                showCloseButton={true}
                onHiding={() => setVisiblePopPiefive(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messSeven}</p>
                </div>
            </Popup>
        </section>

    );
};

export default AnalisiRicette;
