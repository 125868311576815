import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import notify from 'devextreme/ui/notify';
import { RestServices } from '../services/restServices';
import { urlChangePsw } from '../contenuti/costanti';

export const DialogPassword = (props: { showModalPsw: boolean, handleClosePsw: () => void }) => {
    //visibilità delle tre password: vecchia, nuova e nuova ripetuta
    const [isOldVisible, setIsOldVisible] = useState<boolean>(false)
    const [isNewVisible, setIsNewVisible] = useState<boolean>(false)
    const [isCheckVisible, setIsCheckVisible] = useState<boolean>(false)

    const initialState = {
        oldPsw: "",
        newPsw: "",
        checkPsw: ""
    }
    //form rappresenta lo stato di tutti e tre gli input messi insieme, in modo da avere già l'oggetto da inviare al servizio
    const [form, setForm] = useState(initialState);
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setForm({ ...form, [name]: value })
    }
    //regex per il controllo password -> lunghezza 10, almeno una minuscola, una maiuscola, un numero e un simbolo
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{10,10})");
    //si fanno i vari controlli sulle password prima di chiamare il servizio -> tutti i campi devono essere inseriti, 
    //la nuova password deve essere diversa dalla vecchia e deve rispettare le regole della regex e la password ripetuta
    //deve essere uguale alla nuova password
    const inviaNuovaPsw = () => {
        if (form.oldPsw === form.newPsw) {
            notify({ position: "center", width: "auto", message: "La nuova password non può essere uguale alla vecchia" }, "error", 3000);
            return;
        } else if (form.oldPsw === "" || form.newPsw === "" || form.checkPsw === "") {
            notify({ position: "center", width: "auto", message: "Inserire le password in tutti i campi" }, "error", 4000);
            return;
        } else if (!strongRegex.test(form.newPsw) || form.newPsw.length !== 10) {
            notify({ position: "center", width: "auto", message: "La nuova password deve avere lunghezza=10 e deve contenere almeno 1 maiuscola, 1 minuscola, 1 numero e 1 simbolo" }, "error", 4000);
            return;
        } else if (form.newPsw !== form.checkPsw) {
            notify({ position: "center", width: "auto", message: "Il valore della password di conferma non corrisponde a quello della nuova password" }, "error", 4000);
            return;
        } else {
            RestServices().rest('POST', form, urlChangePsw)
                .then(result => {
                    if (result) {
                        notify({ position: "center", width: "auto", message: "Password aggiornata con successo" }, "success", 4000);
                        chiudiModalPsw();
                    } else {
                        notify({ position: "center", width: "auto", message: "Errore nell'aggiornamento della password, riprovare" }, "error", 4000);
                    }
                })
        }
    }
    //prima di chiudere la modal si resetta lo stato del form cioè di tutti e tre gli input
    function chiudiModalPsw() {
        setForm(initialState);
        props.handleClosePsw();
    }

    return (
        <Modal
            show={props.showModalPsw}
            onHide={chiudiModalPsw}
            dialogClassName="dialogPassword"
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title className="titoloPassword">
                    <FontAwesomeIcon icon={faKey} className="iconaPwd" /><br />
                    <h4 className="titoloDialogPassword">Cambio Password</h4>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bodyPassword">
                <div>
                    <div className="inputField">
                        <input
                            name="oldPsw"
                            type={isOldVisible ? "text" : "password"}
                            placeholder="Password Attuale"
                            onChange={handleInputChange}
                            value={form.oldPsw}
                        />
                        <span className="pswVisible" onClick={() => setIsOldVisible(!isOldVisible)}>
                            <span>
                                {isOldVisible ? <FontAwesomeIcon icon={faEyeSlash} title="Cliccare per mostrare la password" /> : <FontAwesomeIcon icon={faEye} title="Cliccare per nascondere la password" />}
                            </span>
                        </span>
                    </div>
                    <div className="inputField">
                        <input
                            name="newPsw"
                            id="newPsw"
                            type={isNewVisible ? "text" : "password"}
                            placeholder="Nuova Password"
                            onChange={handleInputChange}
                            value={form.newPsw}
                            min={10}
                            max={10}
                        />
                        <span className="pswVisible" onClick={() => setIsNewVisible(!isNewVisible)}>
                            <span>
                                {isNewVisible ? <FontAwesomeIcon icon={faEyeSlash} title="Cliccare per mostrare la password" /> : <FontAwesomeIcon icon={faEye} title="Cliccare per nascondere la password" />}
                            </span>
                        </span>
                    </div>
                    <div className="inputField">
                        <input
                            name="checkPsw"
                            type={isCheckVisible ? "text" : "password"}
                            placeholder="Conferma Password"
                            onChange={handleInputChange}
                            value={form.checkPsw}
                        />
                        <span className="pswVisible" onClick={() => setIsCheckVisible(!isCheckVisible)}>
                            <span>
                                {isCheckVisible ? <FontAwesomeIcon icon={faEyeSlash} title="Cliccare per mostrare la password" /> : <FontAwesomeIcon icon={faEye} title="Cliccare per nascondere la password" />}
                            </span>
                        </span>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btnPassword" type="submit" variant="success" onClick={inviaNuovaPsw}>
                    <FontAwesomeIcon icon={faSave} className="icon" /> Salva
            </Button>
                <Button className="btnChiudiPassword" variant="info" onClick={chiudiModalPsw}>
                    <FontAwesomeIcon icon="undo" className="icon" /> Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    )
}