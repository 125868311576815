import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { testApi } from "../util/testRisp";
import { AplicaValoreTO } from "../../../funzioni/operazioniSuTabelle";
import { MenuCruscotto } from "../components/menu_cruscotto"
import {
  urlBancaDati,
  urlCommon,
  urlConsultazione,
  urlReport,
} from "../../costanti";
import { CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { LoadIndicator } from "devextreme-react/load-indicator";
import {
  Connector,
  Title,
  Export,
  Font,
  Series,
  Format,
  Label,
  HoverStyle,
  Size,
  SmallValuesGrouping,
  Tooltip,
} from "devextreme-react/pie-chart";

import {
  Annotation
} from 'devextreme-react/chart';
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";

import "./styleAnalisi.css";
import { log } from "console";

const dashboard5 = { dashboardID: 5 };

export interface boxCard {
  testoPop?: string;
}

export interface boxsecond {
  testoPop?: string;
}

interface cardV {
  testo: string;
  subTesto: string;
  keyValue: string | undefined;
  typeShowValue?: typeShow;
  testoPop?: string;
  second?: boolean;
}

const boxCard: cardC[] = [
  {
    testo: "Valore del venduto",
    subTesto: "Delta anno precedente",
    valore: "VALORE_VENDUTOSL",
    subValore: "VALORE_VENDUTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    typeShowValue: typeShow.euro,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine",
    subTesto: "Delta precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINESL",
    subValore: "MARGINE_PUNTUALEPR",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
        Delta margine:
        Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Margine percentuale",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.percento,
    valore: "MARGINE_PERCENTUALESL",
    subValore: "MARGINE_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Numero vendite",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_VENDITESL",
    subValore: "NUMERO_VENDITE_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
            
            Delta margine:
            Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Volume di vendita",
    subTesto: "Delta anno precedente",
    valore: "VOLUME_VENDITASL",
    subValore: "VOLUME_VENDITA_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

const boxsecond: cardC[] = [
  {
    testo: "Delta equiparato",
    valore: "VALORE_VENDUTO_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Delta equiparato",
    valore: "MARGINE_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Delta equiparato vendite",
    valore: "NUMERO_VENDITE_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
            
            Delta margine:
            Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Delta equiparato volume di vendita",
    valore: "VOLUME_VENDITA_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

const box3: cardC[] = [
  {
    testo: "Valore medio per vendita",
    subTesto: "Delta anno precedente",
    valore: "VALORE_MEDIO_VENDITESL",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    subValore: "VALORE_MEDIO_VENDITE_PUNTUALEPR",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Valore medio prezzo",
    subTesto: "Delta precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "VALORE_MEDIO_PEZZISL",
    subValore: "VALORE_MEDIO_PEZZI_PUNTUALEPR",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
        Delta margine:
        Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Margine medio per venduto",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE_MEDIO_VENDITASL",
    subValore: "MARGINE_MEDIO_VENDITA_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Margine medio per pezzo",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE_MEDIO_PEZZISL",
    subValore: "MARGINE_MEDIO_PEZZI_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

const boxCassa: cardC[] = [
  {
    testo: "SCONTO CASSA",
    valore: "SCONTO_CASSA",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
]


const boxGlavorati: cardC[] = [
  {
    testo: "Numero giorni lavorati",
    valore: "GIORNI_LAVORATISL",
    subTesto: "Numero giorni lavorati anno precedente",
    subValore: "GIORNI_LAVORATIPR",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
]


//da spostare
const convertTime = (data: Date) => {
  const anno = data.getFullYear();
  const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perché i mesi sono 0-based
  const giorno = data.getDate().toString().padStart(2, "0");
  const ore = data.getHours().toString().padStart(2, "0");
  const minuti = data.getMinutes().toString().padStart(2, "0");
  const secondi = data.getSeconds().toString().padStart(2, "0");

  const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

  return dataFormattata;
};

const AnalisiVenditeEvol = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [valori4W, setValori4W] = useState<any>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCard);
  const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecond);
  const [fila3cards, setFila3cards] = useState<cardC[]>(box3);
  const [filaCassacards, setFilaCassacards] = useState<cardC[]>(boxCassa);
  const [filaGiorniLav, setFilaGiorniLav] = useState<cardC[]>(boxGlavorati);
  const [pie, setPie] = useState<any[]>([]);
  const [secondpie, setSecondPie] = useState<any[]>([]);
  const [settori, setSettori] = useState<any[]>([]);
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [visiblePop1, setVisiblePop1] = useState(false);
  const [visiblePop2, setVisiblePop2] = useState(false);



  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  function aggiornaOrarioFine(dataFine: any) {
    // Verifica se l'orario della data finale è mezzanotte (00:00)
    const dataFineAggiornata = new Date(dataFine);
    dataFineAggiornata.setHours(23, 59, 59);
    return dataFineAggiornata;
  }

  function aggiornaDataFine(dataFine: Date): Date {
    // Clona la data di partenza per non modificarla direttamente
    const nuovaDataFine = new Date(dataFine);

    // Aggiungi un giorno alla data
    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];
    let newIDSettore: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

      let venditaDPC = params?.venditaDPC ? [] : [0];
      let flagBusta = params?.flagBusta ? [] : [0];

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      venditaDPC: venditaDPC,
      flagBusta: flagBusta,
      operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
      };


    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {

      const dashboardCall5 = {
        ...dashboard5,
        idFarList: params.idFar,
        params: parameters,
      };

      // call Global

      RestServices()
        .rest("POST", dashboardCall5, urlReport.getQueryDashboardAllData)
        .then((result) => {

          if (result) {

            // box sconto cassa
              let fixedCassaCard = [
                  {
                      testo: "SCONTO CASSA",
                      valore: '€' + (result["35"]["0"]).SCONTO_CASSA
                          ? (result["35"]["0"]).SCONTO_CASSA.toLocaleString("it-IT", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                          })
                          : '€0.00',
                      starter: true,
                      testoPop:
                          "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
                  },
              ];


            //  chartpie
            setPie(result["36"]);

   

             // ID 37
             const chartSSNPie = result["37"]
             .map((item: any) => {
               const isSSN = item.VEN_FLAG_SSN;
               const value = item.VALORE_VENDUTO ;
               return {
                 SSN: isSSN,
                 VALORE_VENDUTO : value,
                 SSNDESC: isSSN ? "SSN" : "Libera",
               };
             })
             .reverse();

            // 1 chartPie ID 37

            const total = chartSSNPie.reduce(
             (sum: any, item: { VALORE_VENDUTO: any }) =>
               sum + item.VALORE_VENDUTO,
             0
           );

           // Aggiunta della percentuale per ogni valore
           const chartSSNPieConPercentuale = chartSSNPie.map(
             (item: { VALORE_VENDUTO: number }) => {
               // Calcolo della percentuale per ogni valore
               const percentuale = (
                 (item.VALORE_VENDUTO / total) *
                 100
               ).toFixed(2);

               return {
                 ...item,
                 PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
               };
             }
           );

           setSecondPie(chartSSNPieConPercentuale);

            // const dataSourceWithLabels = result["37"].map((item: any) => ({
            //   ...item,
            //   Label: item.VEN_FLAG_SSN ? "SSN" : "Libera"
            // }));

            // setThirdpie(dataSourceWithLabels);

            // tabella
            const anno = params.periodo.dal.substring(0, 4);
            const traform = CruscottoGruppo_traform_analisi(result["38"], anno);
            setValori4W(traform);


            let datiPrimaParteCards = trasform_for_cards_charts_01(
              result["40"],
              boxCard
            );

            let datiSecondaParteCards = trasform_for_cards_charts_01(
              result["40"],
              boxsecond
            );

            let dati3Cards = trasform_for_cards_charts_01(
              result["40"],
              box3
            );

            let dati4Cards = trasform_for_cards_charts_01(
              result["40"],
              boxGlavorati
            );


            setFila1cards(datiPrimaParteCards);
            setFilaCassacards(fixedCassaCard);
            setFila2cards(datiSecondaParteCards);
            setFila3cards(dati3Cards);
            setFilaGiorniLav(dati4Cards);

            setIsVisible(false);
            setParamsFilter(params);
            setIsFiltersExpanded(false);


          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }

        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            { position: "center", width: "auto", message: "Errore" + error },
            "error",
            4000
          );
        });

      //cambio data
      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  const popVisible1 = (testo: string) => {
    setVisiblePop1(true);
  };
  const popVisible2 = (testo: string) => {
    setVisiblePop2(true);
  };

  let messOne = ["Val 1 Pie chart"];
  let messTwo = ["Val 2 Pie chart"];

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlBancaDati.getGruppoMerceologico)
        .then((result) => {
          if (result) setGruppoMercFilters(result);
        });
    }
    RestServices()
      .rest("POST", "", urlBancaDati.getSettore)
      .then((result) => {
        if (result) setSettori(result);
      });

    const ssn = { id: 1, descrizione: "SSN" };
    const libera = { id: 0, descrizione: "Libera" };

    const vendite = [libera, ssn];
    setTipologiaVendita(vendite);
  };

  useEffect(() => {
    filters();
  }, [auth]);



    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };

  const itemsFields = [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];

  function formatLabel(arg: { argumentText: any; valueText: any }) {
    return `${arg.argumentText}: ${arg.valueText}%`;
  }

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

    const columnsAnalisi = [
        {
            caption: "Codice Gruppo Merceologico",
            dataField: "PRO_COD_GRUPPO_MERCEOLOGICO1AP",
            width: "auto",
            fixed: true
        },
    {
      caption: "Gruppo Merceologico",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true,
      sortOrder: "asc",
    },
    {
      caption: "Incidenza % valore venduto",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto equiparato",
       dataField: "VALORE_VENDUTO_EQUIPARATOCR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile lordo sconti SSN periodo selezione",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile netto sconti SSN periodo selezione",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile lordo sconti SSN anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile netto sconti SSN anno precedente",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume vendita periodo selezione",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
    },
    {
      caption: "Volume vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "80px",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "80px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];


  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };



  return (
    <section style={{ display: "flex", height: "115vh" }}>
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{ width: "100%", overflow: "scroll", height: "115vh" }}
        >
          <div
            style={{
              fontSize: "15px",
              alignItems: "left",
              marginTop: "10px",
              marginBottom: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h2>BusinessLab Evolution - Cruscotto Direzionale</h2>
          </div>

          <div
            style={{
              display: "block",
              gap: 5,
              padding: 10,
              borderRadius: 10,
              marginTop: 10,
              width: "auto",
            }}
          >

            <div>
              <MenuCruscotto />
            </div>
           
          </div>

          <Popup
            visible={visiblePop}
            showCloseButton={true}
            //onHiding={() => doSomething()}
            width={"40%"}
            height={"60%"}
            showTitle={true}
            title="Dettagli widget"
          >
            <div>
              <p>{testoPop}</p>
            </div>
          </Popup>

          <div>
            <div className="grid-first">
              {fila1cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>
            <div className="grid-container-sec">
              {fila2cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            {/*ultima riga */}
            <div className="grid-ibrid">
              <div className="grid-container-sec">
                {fila3cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>

              <div className="grid-container-one">
                {filaCassacards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
               
                
                  {filaGiorniLav.map((el, i) => {
                    return (
                      <CardCart
                        testo={el.testo}
                        subTesto={el.subTesto}
                        key={i}
                        valore={el.valore}
                        subValore={el.subValore}
                        starter={el.starter}
                        typeShowValue={el.typeShowValue}
                        typeShowSubValue={el.typeShowSubValue}
                        testoPop={el.testoPop}
                      />
                    );
                  })}
                </div>
              
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
               marginTop: 5 
            }}
          >
            {/* <div style={{ width: "30%", backgroundColor:"fff" }}>
              <PieChart
                id="pie"
                // dataSource={chartDataThird}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
              >
                <Series
                  argumentField="PRO_DESC_SETTORE_INVENTARIO"
                  valueField="PERCENTUALE"
                >
                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>
    
                <Title
                  text="Valore del venduto per documento di chiusura di vendita"
                >
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div> */}

            {/* <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisible1("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </div> */}
             {/* 1 PieChart */}
            <div style={{ width: "45%" }}>
              <PieChart
                id="pie"
                dataSource={pie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
              >
                <Series
                  argumentField="VEN_IVA"
                  valueField="VALORE_VENDUTO"
                >
                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>

                {<Tooltip
                  enabled={true}
                  customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                    console.log('vediamo value');
                    console.log(percentText);


                    const dataItem = pie.find((item: any) => item.VEN_IVA === argument);
                    const dataSourceValue = dataItem ? dataItem.VALORE_VENDUTO : 0;
                    const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dataSourceValue);

                    return {
                      html: `<p>Valore del venduto - ${argument}</p><p>${formattedValue} / ${(percentText)}</p>`,
                    };
                  }}
                />}
                <Title
                  text="Valore del venduto per percentuali d'IVA"
                >
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div>

            <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisible1("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </div>

               {/* 2 PieChart */}

            <div style={{ width: "45%" }}>
            <PieChart
                id="pie"
                dataSource={secondpie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
              >
                <Series argumentField="SSNDESC" valueField="PERCENTUALE">
                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>
                <Tooltip
                  enabled={true}
                  customizeTooltip={({
                    argument,
                    value,
                  }: {
                    argument: any;
                    value: any;
                  }) => {
                    const dataItem = secondpie.find(
                      (item: any) => item.SSNDESC === argument
                    );
                    const dataSourceValue = dataItem
                      ? dataItem.VALORE_VENDUTO
                      : 0;
                    const formattedValue = new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(dataSourceValue);
                    const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                    const percentualeFormattata =
                      percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                    return {
                      text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                    };
                  }}
                />
                <Title text="Valore del venduto per tipologia di vendita">
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div>
            <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisible2("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </div>
          </div>
          
          {/* new tabella*/}
          <div style={{  marginTop: "5px"}} >   
          <NewTabella
            height={"70vh"}
            idTabella="analisi_prodotti"
            sync
            colonne={columnsAnalisi}
            dataSource={valori4W}
            rowAlternation={true}
            filterRow
            width={500}
            searchPanel={true}
            exportT={{
              enabled: true,
              fileName: "AnalisiProdotti",
              formats: { exportFormats },
            }}
            activeSaver={true}
            columnC={true}
            headerFilter
            wordWrapEnabled={true}
            onExporting={onExporting}
          />
           </div>
        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{ fontSize: "30px", color: "black" }}
        />
      </div>
      <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
        <div style={{}}>
          <FiltriVerticale
            ricerca={filterAction}
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>
      <Popup
        visible={visiblePop1}
        showCloseButton={true}
        onHiding={() => setVisiblePop1(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messOne}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop2}
        showCloseButton={true}
        onHiding={() => setVisiblePop2(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messTwo}</p>
        </div>
      </Popup>
    </section>

  );
};

export default AnalisiVenditeEvol;
