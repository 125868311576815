import { AplicaValoreTO, getDayName } from "../../../../funzioni/operazioniSuTabelle";

export const columnsAnaliVendSinte = [
    {
      caption: "Descrizione farmacia",
      dataField: "FARM_DESCRIZIONE",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Pezzi venduti",
      dataField: "PEZZI_VENDUTI",
      width: "auto",
    },
    {
      caption: "Righe di vendita (referenze)",
      dataField: "RIGHE_VENDITA",
      width: "auto",
    },
    {
      caption: "Incidenza % valore venduto Ivato",
      dataField: "INCIDENZA_VALORE_VENDUTO_IVATO_NETTO_SCONTI",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto Ivato lordo sconti",
      dataField: "VALORE_VENDUTO_IVATO_LORDO_SCONTI",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" }
    },
    {
      caption: "Valore del venduto Ivato netto sconti",
      dataField: "VALORE_VENDUTO_IVATO_NETTO_SCONTI",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" }
    },
    {
      caption: "Imponibile",
      dataField: "IMPONIBILE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" }
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" }
    },
    {
      caption: "Margine percentuale ",
      dataField: "MARGINE_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Numero medio pezzi per scontrino",
      dataField: "MEDIA_PEZZI_SCONTRINO",
      width: "auto",
      format: { precision: 2 },
    },
    {
      caption: "Scontrino medio Ivato",
      dataField: "MEDIA_VALORE_SCONTRINO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Numero medio righe vendita per scontrino",
      dataField: "MEDIA_PEZZI_SCONTRINO",
      width: "auto",
      format: { precision: 2 },
    },
    {
      caption: "Valore del venduto Ivato netto sconti, SSN",
      dataField: "VALORE_VENDUTO_IVATO_NETTO_SCONTI_SSN",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Ivato netto sconti, Libera",
      dataField: "VALORE_VENDUTO_IVATO_NETTO_SCONTI_LIBERA",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },

    {
      caption: "% Fatturato Ivato Etico",
      dataField: "FATTURATO_IVATO_Etico_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
    {
      caption: "% Fatturato Ivato Generico",
      dataField: "FATTURATO_IVATO_Generico_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },

    {
      caption: "% Fatturato Ivato Sop",
      dataField: "FATTURATO_IVATO_SOP_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Fatturato Ivato Parafarmaco",
      dataField: "FATTURATO_IVATO_Parafarmaco_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Fatturato Ivato Veterinario",
      dataField: "FATTURATO_IVATO_Veterinari_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Fatturato Ivato Altro",
      dataField: "FATTURATO_IVATO_Altro_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const columnsAnalisiDettVendite = [
    {
      caption: "Tipologia prodotto ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % valore venduto Ivato",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto Ivato periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto ivato equiparato",
      dataField: "",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezione",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine % periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume vendita periodo selezione",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
     
    },
    {
      caption: "Volume vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "80px",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "80px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const columnsAnalisiDettVenditeReport = [
    {
      caption: "Farmacia",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Fatturato totale (netto sconti) ivato",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore sconti ivato",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine contribuzione totale",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Numero vendite (scontrini)",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
    },
    {
      caption: "Numero ricette",
      dataField: "",
      width: "auto",
    },
    {
      caption: "Numero pezzi",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "auto",
    },
    {
      caption: "% Etico / Totale",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Generico /Totale",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Commerciale / Totale",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Pezzi medi per scontrino",
      dataField: "MARGINECR",
      width: "auto",
    },
    {
      caption: "Pezzi medi commerciale (no SSN) per scontrino",
      dataField: "MARGINEAP",
      width: "auto",
    },
    {
      caption: "Valore scontrino medio",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore scontrino commerciale",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
  ];

  export const columnsAnalisiVendOpera = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Giorni lavorati",
      dataField: "GIORNI_LAVORATI",
      width: "auto",
    },
    {
      caption: "Ore lavorate",
      dataField: "ORE_LAVORATE",
      width: "auto",
    },
    {
      caption: "Media oraria giornaliera",
      dataField: "MEDIA_ORARIA_GIORNALIERA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Numero vendite",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Numero di righe di vendita",
      dataField: "NUMERO_RIGHE",
      width: "auto",
    },
    {
      caption: "% Vendite SSN",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Vendite Miste",
      dataField: "PERC_VENDITE_MISTE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Vendite Libere",
      dataField: "PERC_VENDITE_LIBERE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media righe vendita",
      dataField: "MEDIA_RIGHE_VENDITA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Media oraria righe di vendita",
      dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Venduto SSN ivato",
      dataField: "PERC_VENDUTO_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Venduto Vendita libera ivato",
      dataField: "PERC_VENDUTO_VENDITA_LIBERA",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Pezzi",
      dataField: "PEZZI",
      width: "auto",
    },
    {
      caption: "Valore vendita medio",
      dataField: "VALORE_VENDITA_MEDIO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
  ];

  
  export const columnsAnaVendFascOraria  = [
    {     
     caption: "Giorno della settimana",
     dataField:  "GIORNO_SETTIMANA",
      width: "auto",
      customizeText:(value:any) => {
        const giorno = getDayName(Number(value.valueText))
        return(
          giorno
        )}    
    },
    {
      caption: "Copertura giorni lavorati",
      dataField: "FREQUENZA_NEL_PERIODO",
      width: 80,
    },
    {
      caption: "Numero vendite",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Numero vendite medio giornaliero",
      dataField: "NUMERO_VENDITE_MEDIO_GIORNALIERO",
      width: "auto",
    },
    {
      caption: "Numero vendite medio per operatore",
      dataField: "NUMERO_VENDITE_MEDIO_OPERATORE",
      width: "auto",
    },
    {
      caption: "Valore del venduto ivato",
      dataField: "VENDUTO_LORDO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto medio giornaliero ivato",
      dataField: "VENDUTO_LORDO_MEDIO_GIORNALIERO_IVATO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "valore del venduto medio per operatore ivato ",
      dataField: "VENDUTO_LORDO_MEDIO_OPERATORE_IVATO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Numero ore lavorate",
      dataField: "ORE_LAVORATE",
      width: 80,
    },
    {
      caption: "righe Vendite lavorate per ora ",
      dataField: "RIGHE_VENDITA",
      width: 80,
    },
  ];

   export const columnsAnalScontiVendi = [
    {
      caption: "Tipologia prodotto",
      dataField: "FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto periodo selezione",
      dataField: "VEN_SCONTO_VENDITA_TOTALE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale valore sconto",
      dataField: "INCIDENZA_PERCENTUALE_VALORE_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERCENTUALE_IVATO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export const columnsAnalVendiSottMargine = [
    {
      caption: "Classificazione Lv 1",
      dataField: "FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1",
      width: "auto",
      fixed: true
    },
    {
      caption: "margine % medio",
      dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Righe di vendita con margine inferiore alla soglia indicata",
      dataField: "NUMERO_RIGHE_SOTTO_MARGINE",
      width: "auto",
    },
    {
      caption: "Importo vendita",
      dataField: "IMPORTO_VENDITA",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto",
      dataField: "SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Iva",
      dataField: "IVA",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Costo",
      dataField: "COSTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    }
  ];

  export const columnsAnalmargMediVend = [
    {
      caption: "Classificazione",
      dataField: "PRO_DESC_CATEGORY1",
      width: "auto",
      fixed: true
    }, 
    {
      caption: "Margine percentuale",
      dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Prodotto con marigine inferiore alla soglia indicata",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },  
  ];


  export const columnsAnaTrasferime  = [
    {
      caption: "Farmacia ricevente",
      dataField: "FARM_DESCRIZIONE",
      width: "auto",
      fixed: true,
      groupIndex:0,
      
    },
    {
      caption: "Mese",
      dataField: "ANNOMESE",
      width: "auto",
      groupIndex:1,
    },
    
    {
      caption: "Fornitore",
      dataField: "ANA_RAGIONE_SOCIALE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Costo del prodotto",
      dataField: "COSTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    
    {
      caption: "Numero pezzi",
      dataField: "QUANTITA",
      width: "auto",
    },
    
  ];
  
  export const columnsAnalisiPopVendOper = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      groupIndex:1,
      fixed: true
    },
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Classificazione Lv.1",
      dataField: "FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1",
      width: "auto",
    },
    {
      caption: "Valore del venduto Ivato",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto Ivato",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  // export const columnsAnalisiPopCrossFarm = [
  //   {
  //     caption: "Operatore",
  //     dataField: "OPERATORE_USERNAME",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Farmacia",
  //     dataField: "FARM_DESCRIZIONE",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Ore lavorate",
  //     dataField: "ORE_LAVORATE",
  //     width: "auto",
  //   },
  //   {
  //     caption: "Media oraria lavorata",
  //     dataField: "MEDIA_ORARIA_GIORNALIERA",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //     cellInfo?.value?.toFixed(2),
  //   },
  //   {
  //     caption: "Numero vendite",
  //     dataField: "NUMERO_VENDITE",
  //     width: "auto",
  //   },
  //   {
  //     caption: "Numero righe di vendita",
  //     dataField: "NUMERO_RIGHE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //     cellInfo?.value?.toFixed(2),
  //   },
  //   {
  //     caption: "Media righe vendita",
  //     dataField: "MEDIA_RIGHE_VENDITA",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //     cellInfo?.value?.toFixed(2),
  //   },
  //   {
  //     caption: "Media oraria righe di vendita",
  //     dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //     cellInfo?.value?.toFixed(2),
  //   },
  //   {
  //     caption: "Valore del venduto",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Incidenza % valore del venduto",
  //     dataField: "INCIDENZA_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Media oraria valore del venduto",
  //     dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Margine",
  //     dataField: "MARGINE",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "% Incidenza margine",
  //     dataField: "INCIDENZA_MARGINE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Margine percentuale",
  //     dataField: "INCIDENZA_MARGINE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Valore vendita medio",
  //     dataField: "VALORE_VENDITA_MEDIO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  // ];

  // export const columnsAnalisiPopOpeDitta = [
  //   {
  //     caption: "Operatore",
  //     dataField: "OPERATORE_USERNAME",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Ditta",
  //     dataField: "PRO_DESC_DITTA_TITOLARE",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore del venduto",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
      
  //   },
  //   {
  //     caption: "Incidenza % valore del venduto",
  //     dataField: "INCIDENZA_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Media oraria valore del venduto",
  //     dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Margine",
  //     dataField: "MARGINE",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "% Incidenza margine",
  //     dataField: "INCIDENZA_MARGINE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Margine percentuale",
  //     dataField: "INCIDENZA_MARGINE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },

  // ];


  // export const columnsAnalisiPopOpeDittaTwo = [
  //   {
  //     caption: "Ditta",
  //     dataField: "PRO_DESC_DITTA_TITOLARE",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Operatore",
  //     dataField: "OPERATORE_USERNAME",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore del venduto",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //   },
  //   {
  //     caption: "Incidenza % valore del venduto",
  //     dataField: "INCIDENZA_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },


  //   {
  //     caption: "Media oraria valore del venduto",
  //     dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Margine",
  //     dataField: "MARGINE",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "% Incidenza margine",
  //     dataField: "INCIDENZA_MARGINE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Margine percentuale",
  //     dataField: "INCIDENZA_MARGINE",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  // ];


  // export const columnsOpeAnaScoCatePop = [
  //   {
  //     caption: "Operatore",
  //     dataField: "OPERATORE_USERNAME",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore sconto ",
  //     dataField: "VALORE_SCONTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Incidenza % sconto",
  //     dataField: "INCIDENZA_SCONTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Valore del vendtuo ",
  //     dataField: "IMPORTO_LORDO_SCONTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Sconto percentuale",
  //     dataField: "SCONTO_PERC",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
   
  // ];

  // export const columnsOpeAnaScoCate = [
  //   {
  //     caption: "Categoria",
  //     dataField: "PRO_DESC_CATEGORY1",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore sconto periodo selezione",
  //     dataField: "VEN_SCONTO_VENDITA",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Incidenza percentuale valore sconto",
  //     dataField: "INC_PERC_SCONTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Sconto percentuale",
  //     dataField: "SCONTO_PERC",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "% Incidenza margine",
  //     dataField: "INC_PERC_SCONTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  // ];

  // export const columnPopAnaScoCateg = [
  //   {
  //     caption: "Operatore",
  //     dataField: "OPERATORE",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore sconto ",
  //     dataField: "VALORE_SCONTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Incidenza % sconto",
  //     dataField: "INCIDENZA_VENDUTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Valore del venduto ",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Sconto percentuale",
  //     dataField: "PERC_SCONTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
   
  // ];

  // export const columnPopAnaScoVendGruMerc = [
  //   {
  //     caption: "Operatore",
  //     dataField: "OPERATORE_USERNAME",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore sconto ",
  //     dataField: "VALORE_SCONTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Incidenza % sconto",
  //     dataField: "INCIDENZA_SCONTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Import lordo sconto ",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Sconto percentuale",
  //     dataField: "SCONTO_PERC",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
   
  // ];

  // export const columnsAnaScoCateMerce  = [
  //   {
  //     caption: "Gruppo merceologico",
  //     dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Valore sconto periodo selezione",
  //     dataField: "VEN_SCONTO_VENDITA",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Incidenza percentuale valore sconto",
  //     dataField: "INC_PERC_SCONTO",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  //   {
  //     caption: "Sconto percentuale",
  //     dataField: "SCONTO_PERC",
  //     width: "auto",
  //     customizeText: (cellInfo: any) =>
  //       AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  //   },
  // ];

  // pop anal ricette

  // export const columnPopAnaRicette = [
  //   {
  //     caption: "Farmacia",
  //     dataField: "FARMACIA",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Periodo",
  //     dataField: "DATA",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Venduto lordo",
  //     dataField: "VENDUTO_LORDO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Imponibile lordo",
  //     dataField: "IMPONIBILE_LORDO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Decurtazione in DCR",
  //     dataField: "DCR",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Venduto netto decurtazione SSN",
  //     dataField: "VENDUTO_SSN",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Imponibile netto decurtazione SSN",
  //     dataField: "IMPONIBILE_SSN",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Valore Ticket + Quota ricetta",
  //     dataField: "DCR",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Previsione incasso DCR",
  //     dataField: "DCR",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Numero ricette lavorate ",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: " Valore medio per ricetta",
  //     dataField: "VALORE_VENDUTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Pezzi venduti SSN",
  //     dataField: "FARMACIA",
  //     width: "auto",
  //   },
  //   {
  //     caption: "Numero medio pezzi per ricetta",
  //     dataField: "FARMACIA",
  //     width: "auto",
  //   },
   
  // ];

  // export const columnsAnaTrasferime  = [
  //   {
  //     caption: "Farmacia ricevente",
  //     dataField: "FARM",
  //     width: "auto",
  //     fixed: true
  //   },
  //   {
  //     caption: "Mese",
  //     dataField: "MESE",
  //     width: "auto",
  //   },
    
  //   {
  //     caption: "Fornitore",
  //     dataField: "FORNITORE",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
  //   {
  //     caption: "Costo del prodotto",
  //     dataField: "COSTO_PRODOTTO",
  //     width: "auto",
  //     format: { type: "currency", precision: 2, currency: "EUR" },
  //   },
    
  //   {
  //     caption: "Numero pezzi",
  //     dataField: "NUMERO_PEZZI",
  //     width: "auto",
  //   },
    
  // ];