import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import Row from "react-bootstrap/esm/Row"
import { urlCommon, urlConsultazione } from "../../costanti";
import { useAuth } from "../../../hooks/useAuth";
import { RestServices } from "../../../services/restServices";
import notify from "devextreme/ui/notify";
import { Loading } from "../../../widget/Notifications";
import { NewTabella } from "../../../widget/nuova_tabella/newVersion";

interface DialogProps {
    open: boolean;
    idAnaGenFornCen: number;
    close: () => void;
}

export const DialogAnaFornCenInvia: React.FC<DialogProps> = ({
    open,
    idAnaGenFornCen,
    close,
}) => {


    const auth = useAuth(urlCommon.auth);
    const [openD, setOpenD] = useState<boolean>(false);
    const [elencoFarmacie, setElencoFarmacie] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [selectedFar, setSelectedFar] = useState<any>([])

    useEffect(() => {
        if (auth) {
            setOpenD(open)
        }
        //getListAssociazioniFarmacie()
    }, [auth])


    useEffect(() => {
        if (openD)
            getListAssociazioniFarmacie()

    }, [openD])

    useEffect(() => {
        if (selectedFar.length > 0)
            setDisableButton(false)
        else
            setDisableButton(true)
    }, [selectedFar])

    const getListAssociazioniFarmacie = () => {
        
        if (idAnaGenFornCen) {
            console.log("get list")
            let params = { idAnaGenFornCen: idAnaGenFornCen };
            if (auth) {
                setLoading(true)
                RestServices().rest('POST', params, urlCommon.getListAssociazioniFarmacie)
                    .then(result => {
                        if (result?.esito) {
                            setElencoFarmacie(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }

    }

    const columnsFarmacie = [
        { caption: "Id Farmacia", dataField: "idFar", visible: false },
        { caption: "Codice Farmacia", dataField: "codice"},
        { caption: "Ragione Sociale Farmacia", dataField: "descrizione"},
        { caption: "Partita IVA", dataField: "partitaIva" },
        { caption: "Num. Assoc.", dataField: "numeroAssociazioni" }

    ];


    const inviaFornitore = () => {
        let fornitori = [idAnaGenFornCen];
        let farmacie : any[] = [];

        selectedFar.forEach((element:any) => {
            farmacie.push(element.idFar)
        })

        let params = { farmacie: farmacie, fornitori: fornitori }

        if (auth) {
            RestServices().rest('POST', params, urlCommon.inviaFornitori)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            notify({ position: "center", width: "auto", message: 'Fornitori inviati correttamente' }, "success", 4000)
                            close();
                        }
                        else {
                            notify({ position: "center", width: "auto", message: 'Fornitori non inviati correttamente' }, "error", 4000)
                            setLoading(false)
                        }
                        
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }


    const onRowPrepared = (e: any) => {

        if (!e.data)
            return;
        if (e.data.numeroAssociazioni == 0) {
            e.rowElement.style.backgroundColor = 'rgb(252,230,232,255)';
        }
        else if (e.data.numeroAssociazioni > 0)
            e.rowElement.style.backgroundColor = 'rgb(221,244,239,255)';


    }

    return (

    <>
            <Popup
                visible={openD}
                showCloseButton={false}
                width={'70%'}
                height={'80%'}
                showTitle={true}
                title="Invio anagrafica centralizzata"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: "Chiudi",
                        onClick: () => close(),
                    }

                    }
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Invia',
                        disabled: disableButton,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => inviaFornitore(),
                    }}
                />
                <Loading isVisible={loading} />

                <Row>&nbsp;</Row>
                <label> ELENCO FARMACIE </label>
                <NewTabella
                    idTabella="farmacieAssociazioni"
                    sync
                    colonne={columnsFarmacie}
                    dataSource={elencoFarmacie}
                    filterRow
                    exportT={{
                        enabled: false,
                        fileName: "list_farmacie_associazioni",
                    }}
                    height={500}
                    multyp
                    selectedValue={(datiRow: any) => setSelectedFar(datiRow.selectedRowKeys)}
                    onRowPrepared={onRowPrepared}
                />


            </Popup>
    </>
    )
}

