import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../../interfaces/farmacie";
import { RestServices } from "../../../../services/restServices";
import FiltriVerticale from "../../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../../components/card_chart";
import { trasform_for_cards_charts_01 } from "../../util/trasformation";
import { urlConsultazione, urlReport } from "../../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import "./styleIntelli.css";
import { createItemFilterAnalRicette } from "../ItemsFiltri/analisiPerOperatore";
import { DialogAnalRicette } from "../dialog/PopAnaRicette";

const boxCardAnaRicette: cardC[] = [
    {
        testo: "Venduto lordo",
        subTesto: "% sul venduto totale",
        valore: "VENDUTO_LORDO_SSNSL",
        subValore: "PERC_VENDUTO_TOTALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
    },
    {
        testo: "Imponibile Lordo",
        subTesto: "% sull'imponibile totale",
        valore: "IMPONIBILE_LORDO_SSNSL",
        subValore: "PERC_IMPONIBILE_TOTALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.",
    },

];

export const boxsecondRicette: cardC[] = [
    {
        testo: "Valore Ticket + Quota ricetta ",
        subTesto: "Delta anno precedente",
        valore: "TICKET_QUOTASL",
        subValore: "TICKET_QUOTA_PUNTUALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente � coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Decurtazione in DCR",
        valore: "DECURTAZIONE_DCRSL",
        typeShowValue: typeShow.euro,
        testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
        testo: "Previsione incasso DCR lordo IVA",
        subTesto: "Previsione incasso DCR netto IVA ",
        valore: "PREVISIONE_INCASSO_DCR_LORDO_IVASL",
        subValore: "PREVISIONE_INCASSO_DCR_NETTO_IVASL",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.euro,
        testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },

];

export const boxterzRicette: cardC[] = [
    {
        testo: "Numero di ricette lavorate",
        subTesto: "Delta anno precedente",
        valore: "NUMERO_RICETTESL",
        subValore: "NUMERO_RICETTE_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente � coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Valore medio per ricetta",
        subTesto: "Delta anno precedente",
        valore: "VALORE_MEDIO_RICETTASL",
        subValore: "VALORE_MEDIO_RICETTA_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        typeShowValue: typeShow.euro,
        testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
        testo: "Pezzi venduti SSN",
        subTesto: "Delta anno precedente",
        valore: "PEZZI_SSNSL",
        subValore: "PEZZI_SSN_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
        testo: "Numero medio pezzi per ricetta",
        subTesto: "Delta anno precedente",
        valore: "MEDIA_PEZZI_RICETTASL",
        subValore: "MEDIA_PEZZI_RICETTA_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato � quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
];

const GalileoVenAnalisiRicette = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnaRicette);
    //const [fila1cards, setFila1cards] = useState<cardC[]>(boxprimoRicette);
    const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecondRicette);
    const [fila3cards, setFila3cards] = useState<cardC[]>(boxterzRicette);
    const [firstpie, setFirstpie] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [openPopTwo, setOpenPopTwo] = useState<boolean>(false);
    const [openPopThree, setOpenPopThree] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const dashboard38 = { dashboardID: 38 };

    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }


        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            venditaSSN: params.venditaSSN,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall38 = {
                ...dashboard38,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            //console.log('mostro call');
            //console.log(dashboardCall9);

            RestServices()
                .rest("POST", dashboardCall38, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {


                        let datiPrimaParteCards = trasform_for_cards_charts_01(result["128"], boxCardAnaRicette);
                        let datiSecondaParteCards = trasform_for_cards_charts_01(
                            result["128"],
                            boxsecondRicette
                        );
                        let datiTerzaParteCards = trasform_for_cards_charts_01(
                            result["128"],
                            boxterzRicette
                        );

                        let arrayTousePie = [
                            { label: 'SSN', value: result["128"][0]?.VENDUTO_DECURTATO_SSN_SSN },
                            { label: 'MISTA', value: result["128"][0]?.VENDUTO_DECURTATO_SSN_MISTA },
                        ]

                        // 1 chartpie
                        setFirstpie(arrayTousePie);
                        setFila1cards(datiPrimaParteCards);
                        setFila2cards(datiSecondaParteCards);
                        setFila3cards(datiTerzaParteCards);


                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1� gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 � gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });


        }
    };

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
        filters();
    }, [auth]);

    const filters = () => {
        if (auth) {

            const SOLO_SSN = { id: 1, descrizione: "SSN" };
            const MISTA = { id: 0, descrizione: "MISTA" };

            const vendite = [SOLO_SSN, MISTA];
            setTipologiaVendita(vendite);
        }
    };

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabellaGalileoVenAnalisiRicette");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabellaGalileoAnalisiRicette.csv"
                );
            });
        });
    };

    const itemsFields = createItemFilterAnalRicette(
        newFarmaList,
        tipologiaVendita,
    );

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible = (testo: string) => {
        setVisiblePop(true);
        setTestoPop(testo);
    };

    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };

    let mess2 = ["val pieChart"];


    return (
        <>
            <DialogAnalRicette
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            />


            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>Vendite Analisi Ricette</h2>
                        </div>
                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >
                           
                        </div>

                        {/* flexbox */}

                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>

                        <div>
                            <div className="grid-ibrid">
                                <div style={{ width: 'calc(166.9%)', paddingBottom: 5 }} className="grid-container-one">
                                {fila1cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                              </div>
                           </div>


                           <div style={{ fontSize: 16, width: "100%", position: 'relative' }} className="grid-container-3-cate">
                                {fila2cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                            </div>

                           <div style={{ width: 'calc(100%)', marginBottom: 20 }} className="grid-container-4-element">
                                {fila3cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                                </div>
                            </div>
                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{mess2}</p>
                </div>
            </Popup>
        </>
    );
};

export default GalileoVenAnalisiRicette;
