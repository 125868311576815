import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import Ricerca from "../../widget/nuova_ricerca"
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione//interface'
import {  legendByarray } from '../../funzioni/formatoVariabili'
import './style.css';


const columns = [
    { caption: "Farmacia", dataField: "descrix", width:300 },
    { caption: "Cod. Prodotto", dataField: "codiceProdotto", sortOrder:"asc"},
    { caption: "Ean", dataField: "ean" },  
    { caption: "Descrizione", dataField: "descrizioneRidotta", sortOrder: "asc" },  
 ];


const GestCentProdPers = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {


    
    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [idFarmacia,setIdFarmacia] = useState<number | null>(null)
    const [newFarmaList,setNewFarmaList] = useState([])

    const itemsFields =[
        {type:'select' , idValue:'idFar' , label:'Farmacie' , displayExpr:'descrizione', dataSource : newFarmaList ,width:350,require:false  },
    ]
    

    const getListFarm = () =>{
        if (auth) {
           return RestServices().rest('POST', {} , urlConsultazione.getListFar)
        }
   }
   

    const caricamentiNuovaRicerca = async(objFiltro:Filtri) =>{
        setLoading(true)
        let farmacieLista = await getListFarm()
        if (farmacieLista?.esito) { 
             if(farmacieLista){
                let params = {...objFiltro}  
                setIdFarmacia(null)
                if(params.idFar){
                    params.idFarmacia = Number(params.idFar.idFar)
                    delete params.idFar
                    let listaTabella = await RestServices().rest('POST', params , urlConsultazione.getListProd)
                    if (listaTabella?.esito) {
                        let valoreFormatt = legendByarray(
                            listaTabella.response,
                            farmacieLista.response,
                            'idFar',
                            'farmacia',
                            'descrix',
                            ['descrizione'])
                       setTabellaPrincipale(valoreFormatt)
                       setLoading(false)
                    }else{
                        notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)

                    }
                }else{
                    let listaTabella = await RestServices().rest('POST', {} , urlConsultazione.getListProd)
                    if (listaTabella?.esito) {
                        let valoreFormatt = legendByarray(
                            listaTabella.response,
                            farmacieLista.response,
                            'idFar',
                            'farmacia',
                            'descrix',
                            ['descrizione'])
                       setTabellaPrincipale(valoreFormatt)
                       setLoading(false)
                    }else{
                        notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)

                    }
                }
                }
                }else{
                    if(farmacieLista?.message){
                        notify({ position: "center", width: "auto", message: farmacieLista.message}, "error", 4000)
                        
                    }            
                }

            }


    const getListFarmOk = async() =>{
        if (auth) {
        let farmacie:any = await getListFarm()
        if (farmacie.esito) {
                                       
            setNewFarmaList(farmacie.response)
            
        }else{
            notify({ position: "center", width: "auto", message: farmacie.message}, "error", 4000)

        }}
   }


   useEffect(()=>{
       getListFarmOk()
       caricamentiNuovaRicerca({})
    },[auth])

    useEffect(() => {
        if (idFarmacia) {
            caricamentiNuovaRicerca({idFarmacia  : idFarmacia})
        }
    }, [idFarmacia]);
    
    useEffect(() => {   
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

  
   
    return (
        <>

        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> GESTIONE PRODOTTI PERSONALIZZATI  <span className='subt' ></span> </h3>
             </div>

             <Ricerca ricerca={(filtro)=>caricamentiNuovaRicerca(filtro)}  items={itemsFields}  />
                   
            <NewTabella 
                idTabella='gest_prodotti_person'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale}    
                filterRow
                searchPanel={true}
                onclickRow={(datiRow:any)=>null}       
                exportT={{
                    enabled: true,
                    fileName: "gest_prodotti_person"
                }}
                activeSaver={true}
                columnC={true}
                
            />
           
        </>
    )
}

export default  GestCentProdPers