import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface, ParametriFarmacia } from "../../interfaces/farmacie";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaInfoFarmacia, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from 'devextreme/ui/notify';
import { urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { useAuth } from "../../hooks/useAuth";


const ElencoParametriFarmacia = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlMonitoraggio.auth);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
            //startCerca();
            //getInfoFarmacia();

        }
    }, []);

    useEffect(() => {
        if (auth) {
            getInfoFarmacia();
            startCerca();
        }
        
    }, [auth]);



    const titolo = TitoloContenuto("Elenco parametri farmacia");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoParametri, setElencoParametri] = useState<ParametriFarmacia[]>();
    const [infoFarmacia, setInfoFarmacia] = useState<any>();

    //useEffect(() => {
    //    startCerca();
    //}, [infoFarmacia]);

    const startCerca = () => {
        setIsVisible(true);
        const param = {};
        const query = new URLSearchParams(window.location.search);
        const codTph = query.get('codTph');



        RestServices().rest('POST', param, urlMonitoraggio.getElencoParametriFarmacia + "?codTph=" + codTph)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setElencoParametri(result)

                    } else {
                        setElencoParametri(result);

                    }

                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })


    }

    const propsTabElencoParametri: TabellaCompletaInterface = {
        id: "elenco_parametri_farmacia",
        dataSource: elencoParametri,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {

            totalItems: [{
                name: "TotaleParametri",
                showInColumn: "Nome parametro",
                displayFormat: "Tot.Parametri: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoParametriFarmacia"
        }
    }

    const columnsParametri = CreaColonne([
        { caption: "Nome parametro", dataField: "NOMEPARAM", visible: true, width: 250 },
        { caption: "Tipo parametro", dataField: "TIPOPARAM", visible: true, width: 60 },
        { caption: "Valore parametro", dataField: "VALOREPARAM", visible: true },
        { caption: "Nome modulo", dataField: "NOMEMOD", width: 250 },
        { caption: "Operatore", dataField: "OPERATORE", width: 100 },
        { caption: "Terminale", dataField: "TERMINALE", width: 100 },
        { caption: "Ind.valore", dataField: "INDICEVALORE", width: 60 },
        { caption: "Data import.", dataField: "DATAIMPORTAZIONE", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data ins.", dataField: "DATAINSERIMENTO", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data agg.", dataField: "DATAAGGIORNAMENTO", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" }

    ])

    const getInfoFarmacia = () => {
        //setIsVisible(true);
        const param = {};
        const query = new URLSearchParams(window.location.search);
        const codTph = query.get('codTph');
        RestServices().rest('POST', param, urlMonitoraggio.getInfoFarmacia + "?codTph=" + codTph)
            .then(result => {
                //      setIsVisible(false);
                if (result) {
                    if (result.Ok === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setInfoFarmacia(result)
                    } else {
                        setInfoFarmacia(result);
                    }
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }




    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.disdettaNewsletter == true)
                e.component.deselectRows(row); return;
        })
    }

    const editorOptions = {
        readOnly: true,
    };

    return (
        <>
            {titolo}
            {loadPanel}
            <Form
                colCount={2}
                id="form"
                formData={infoFarmacia}
            >
                <GroupItem colCount={3} colSpan={2} caption="">
                    <SimpleItem dataField="Ragione Sociale" editorOptions={editorOptions}> {infoFarmacia?.ragioneSociale}</SimpleItem>
                    <SimpleItem dataField="Ragione Sociale Aggiunta" editorOptions={editorOptions}> {infoFarmacia?.ragioneSociale}</SimpleItem>
                    <SimpleItem dataField="Codice Telepharma" editorOptions={editorOptions}> {infoFarmacia?.codTph}</SimpleItem>
                    <SimpleItem dataField="Partita Iva" editorOptions={editorOptions}> {infoFarmacia?.partitaIva}</SimpleItem>
                    <SimpleItem colSpan={2} dataField="Codice fiscale" editorOptions={editorOptions}> {infoFarmacia?.codiceFiscale}</SimpleItem>
                    <SimpleItem dataField="Indirizzo" editorOptions={editorOptions}> {infoFarmacia?.indirizzoCompleto}</SimpleItem>
                    <SimpleItem dataField="Telefono" editorOptions={editorOptions}> {infoFarmacia?.telefono}</SimpleItem>
                    <SimpleItem dataField="Email" editorOptions={editorOptions}> {infoFarmacia?.email}</SimpleItem>
                </GroupItem>
            </Form>
            <div className="contenutoPagina">
                <Row className="tabellaFornitori" id="rigaTabInfoFarmacia">
                    <Col sm="12" className="infoFarmacia">
                        {/*<TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            columnsDetail={columnsFarmacieDettaglio}
                            urlDetail={urlAnagrafiche.getAnagraficaClienteNewsletterDettaglio + '?idCli='}
                            keyDetail={"idCliente"}
                            key={"idCli"}
                        />*/}

                        <TabellaCompletaInfoFarmacia
                            propsTabella={propsTabElencoParametri}
                            columns={columnsParametri}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            onSelectionChanged={onSelectionChanged}
                            togliFilterPanel={false}
                            searchPanel={true}
                            groupPanel={true}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )

}

export default ElencoParametriFarmacia;