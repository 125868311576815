import { useState, useEffect } from "react";
import { MenuInterface } from "../interfaces/menu";
import { RestServices } from "../services/restServices";
import { urlMenu } from '../contenuti/costanti'

export function useMenu() {
    const [menu, setMenu] = useState<MenuInterface>();

    //lo useEffect con [] fa sì che la chiamata venga fatta una sola volta al caricamento della pagina
    useEffect(() => {
        RestServices().rest('POST', "", urlMenu)
            .then(result => {
                if (result) {
                    setMenu(result);
                }
            })
    }, [])

    return menu
}