import React from "react";
import { useEffect, useMemo, useState } from "react";
import { getCodiceFarmFromId } from "../../funzioni/anagrafica";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OggettoInterface } from "../../interfaces/generiche";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaOrdiniLiberi } from "../../widget/Tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { DialogConferma, Loading } from "../../widget/Notifications";
import { urlPrezzoPromozione } from "../costanti";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import Button from 'react-bootstrap/esm/Button';
import { TendinaFarm } from "../../widget/Select";

const InvioPrezziPromozione = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [colonnePrezzo, setColonnePrezzo] = useState<string[]>();
    const [elencoPrezzoPromozione, setElencoPrezzoPromozione] = useState<1>();
    const titolo = TitoloContenuto("Invio Prezzi Promozione");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [messageConferma, setMessageConferma] = useState<string>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [items, setItems] = useState<any>();
    const [elencoSelezionati, setElencoSelezionati] = useState<any>();
    const [idOperazione, setIdOperazione] = useState<number>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

    }, []);

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (isLoaded) {
            setIsPageLoaded(true);
            cerca();
        }
    }, [isLoaded]);

    useEffect(() => {

      
        setFiltri({
            lstFar: props.datiFarmacie.map(function (object) { return object.ID_FAR })
        })


    }, [props.datiFarmacie])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleCloseConferma = () => setDialogIsOpen(false);

    const creaColonneDinamiche = () => {

        if (props.datiFarmacie.length > 0 ) {
            const primeColonne: any[] = [
                { caption: "Ids", dataField: "ids", visible: false, },
                { caption: "Descrizione", dataField: "descrizione", allowEditing: false, width: 275 },
                { caption: "Cod. Prod.", dataField: "codProd", allowEditing: false, alignment: "center" },
                { caption: "Ean", dataField: "ean", allowEditing: false, alignment: "center" },
                { caption: "Cod. Csf", dataField: "codCsf", allowEditing: false, alignment: "center", visible: false },
            ];
            const aggiuntaColonneFarm = primeColonne;
            const colonnePrz: string[] = [];
            if (filtri.lstFar) {
                for (let i = 0; i < filtri.lstFar.length; i++) {
                    const idFar = filtri.lstFar[i];

                    console.log("crea");
                    console.log(props.datiFarmacie)
                    console.log(idFar)

                    let codice = getCodiceFarmFromId(props.datiFarmacie, idFar);
                    colonnePrz.push(`${idFar}`);
                    aggiuntaColonneFarm.push({
                        caption: `Prz.${codice}`,
                        dataField: `farPre.${idFar}.p`,
                        allowEditing: false,
                        dataType: "number",
                        alignment: "center"
                    })
                }
                setColonnePrezzo(colonnePrz);
            }
            return aggiuntaColonneFarm;
        }
        
    }

    const columnsPrezzoPromozione = useMemo(() => CreaColonne(creaColonneDinamiche()), [elencoPrezzoPromozione, filtri.lstFar])

    const propsTabPrezzoPomozione = {
        id: "elenco_prezzo_promozione",
        dataSource: elencoPrezzoPromozione,
        selection: "multiple",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        export: {
            enabled: true,
            fileName: "Prezzo_Promozione"
        }
    }


    const cerca = () => {

        if (filtri.lstFar == undefined || filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia." }, "error", 4000)
            return;
        }

       

        const param = {
            lstFar: filtri.lstFar
        }
        setIsVisible(true);

        RestServices().rest('POST', param, urlPrezzoPromozione.getListInvioPrezzoPromozione).then(result => {
            setIsVisible(false);
            if (result) {
                setElencoPrezzoPromozione(result.result);
            } else if (result !== true) {
                notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
            }
        })
    }

    const elimina = () => {

        var keys = tabellaInstance.instance.getSelectedRowKeys();

        if (keys.length == 0) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare almeno un prodotto" }, "error", 4000);
            return
        }

        var items = new Array();
        for (var i = 0; i < keys.length; i++) {
            items.push(keys[i].ids);
        }

        setIdOperazione(-1);

        setItems(items);

        setMessageConferma("I prezzi selezionati stanno per essere eliminati. Confermare?");

        setDialogIsOpen(true);

        

    }
    const functionNext = () => {
        setDialogIsOpen(false);

        setIsVisible(true);
        

        if (idOperazione == -1) {

            const param = {
                lstId: items,
                lstFar: filtri.lstFar
            }

            RestServices().rest('POST', param, urlPrezzoPromozione.deletePrezzo).then(result => {

                if (result) {
                    if (result.esito == true) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Eliminazione completata" }, "success", 5000);
                    }
                    else if (result.esito == false) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                    }
                    setTimeout(() => {
                        cerca();
                    }, 3000);

                }
            })
        }

        if (idOperazione == 1) {

            const param = {
                list: elencoSelezionati,
                lstFar: filtri.lstFar
            }

            RestServices().rest('POST', param, urlPrezzoPromozione.inviaPrezzo).then(result => {

                if (result) {
                    if (result.esito == true) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Invio completato" }, "success", 5000);
                    }
                    else if (result.esito == false) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                    }
                    setTimeout(() => {
                        cerca();
                    }, 3000);
                }
            })
        }

        
    }

    const invia = () => {

        //invia selezionati
        var keys = tabellaInstance.instance.getSelectedRowKeys();

        if (keys.length == 0) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare almeno un prodotto" }, "error", 4000);
            return
        }

        //var items = new Array();
        var oggettoSelezionato;
        var elencoSelezionatiLocale = new Array();

        for (var i = 0; i < keys.length; i++) {
            console.log(keys[i].farPre);
            oggettoSelezionato = {
                ids: keys[i].ids,
                codCsf: keys[i].codCsf,
                codProd: keys[i].codProd,
                farPre: keys[i].farPre,
                //far: Array.from(keys[i].farPre.keys())
            }
            elencoSelezionatiLocale.push(oggettoSelezionato);
            //items.push(keys[i].ids);
        }

        //setItems(items);
        setElencoSelezionati(elencoSelezionatiLocale);
        setIdOperazione(1);

        setMessageConferma("I prezzi selezionati stanno per essere inviati in farmacia. Confermare?");

        setDialogIsOpen(true);
    }


    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row>
                    <Col sm="6">
                        <label>Farmacia</label>
                        <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} valueGrid={filtri.lstFar} />
                    </Col>
                    <Col sm="5">    
                    </Col>
                    <Col sm="1">
                        <Button type="button" variant="success" id="btnCerca" onClick={cerca}>Cerca</Button>
                    </Col>
                </Row>
                <Row id="rigaBottoniProdottoPrezzoPromozione">
                    <Col sm='1'>
                        <Button type="button" variant="danger" id="btnCopiaRif" onClick={elimina}>Elimina</Button>
                    </Col>
                    <Col sm='11'>
                        <Button type="button" variant="success" id="btnInviaPrezziPromozione" onClick={invia}>Invia</Button>
                    </Col>
                </Row>
                
                <Row >
                    <Col sm='12' className="tabellaFornitori">

                        <TabellaOrdiniLiberi
                            propsTabella={propsTabPrezzoPomozione}
                            //onRowUpdating={onRowUpdating}
                            setTabellaInstance={setTabellaInstance}
                            columns={columnsPrezzoPromozione}
                            enterKeyDirection="row"
                        //onCellPrepared={onCellPrepared}
                        //onContentReady={onContentReady}
                        //onCellClick={onCellClick}
                        //onFocusedCellChanged={onFocusedCellChanged}
                        //onKeyDown={onKeyDown}
                        //selectedRowKeys={selectedRowKeys}
                        //onEditingStart={onEditingStart}
                        //onRowPrepared={onRowPrepared}
                        />

                    </Col>
                </Row>

                <DialogConferma
                    dialogIsOpen={dialogIsOpen}
                    message={messageConferma}
                    functionNext={functionNext}
                    handleCloseConferma={handleCloseConferma}
                />

            </div>
        </>
    )
}

export default InvioPrezziPromozione;