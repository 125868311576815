import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import { useAuth } from '../../hooks/useAuth';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import {  urlB2B, urlCommon, urlConsultazione } from '../costanti';
import Button from 'react-bootstrap/Button'
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { Filtri } from '../consultazione/interface';
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import ListinoB2B from './ListinoB2B';
import { confirm } from 'devextreme/ui/dialog';
import SingoloListinoOrdineB2B from './SingoloListinoB2B';
import { Loading } from '../../widget/Notifications';

const ElencoLisitniB2B = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {


    const auth = useAuth(urlCommon.auth)

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [renderp, setRenderp] = useState<any>(1);
    const [newFarmaList, setNewFarmaList] = useState([])
    const [paramsRicerca, setParamsRicerca] = useState<any>({})
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)
    const [idListino, setIdListino] = useState<number>(0);

    useEffect(() => {
        if (auth)
            farmaList()

        filterAction({
            dal: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'),
            al: new Date()
        })

        //setTabellaPrincipale(dataSourceTest)

    }, [auth]);


    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
            //console.log(element);
            newArr.push({ value: element.idFar, label: element.descrizione })

        });
        return newArr
    }

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {

                    }
                })
        }
    }


    const columnsListini = [
        { caption: "Id", dataField: "id", visible: false },
        { caption: "IdFarGrossista", dataField: "idFarGrossista", visible: false },
        { caption: "Farmacia/Grossista", dataField: "farmacia", visible: true },
        { caption: "Titolo", dataField: "titolo" },
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Farmacie abilitate", dataField: "farmacie" },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "date", sortOrder: "desc" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                //{
                //    hint: "Elenco download",
                //    icon: "fields",
                //    onClick: (e: any) => {
                //        const nomeListino = e.row.data.titolo;
                //        const dataListino = e.row.data.data
                //        const idListino = e.row.data.id;
                //        //setNomeListinoSelez(nomeListino);
                //        //setDataListinoSelez(dataListino);
                //        //setIdListinoSelez(idListino);
                //        //setIsDialogLogListinoOpen(true);
                //    }
                //},
                {
                    hint: "Dettaglio listino",
                    icon: "search",
                    onClick: (e: any) => {
                        const idListino = e.row.data.id;
                        //const path = basenameHostNewTabs + "ordiniB2B/listino_dettaglio?idListino=" + idListino;
                        //window.open(path);
                        setIdListino(idListino)
                        setRenderp(3)
                    }
                },
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        const idListino = e.row.data.id;

                        let result = confirm("<i>Eliminare il listino?</i>", "Attenzione");
                        result.then((dialogResult) => {
                            if (dialogResult) {
                                eliminaListino(idListino)

                                return;
                            } else {
                                return;
                            }
                        });
                    }
                }
            ]
        }

    ];

    const eliminaListino = (id: number) => {

        let param = {idListino : id}

        if (auth) {
            setLoading(true)
            RestServices().rest('POST', param, urlB2B.deleteListino)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            getList(paramsRicerca)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    

    const itemsFields = [
        { type: 'select2', idValue: 'idFarmacie', label: 'Farmacie abilitate ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 350 },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, defValue: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'), width: 250, require: true },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null, defValue: new Date(), width: 250, require: true },

    ]


    const filterAction = (objFiltro: Filtri) => {
        setLoading(true)
        let params = { ...objFiltro }

        if (params.idFarmacie) {

            let newArr: number[] = []
            params.idFarmacie.forEach((element: any) => {
                //console.log(element);
                newArr.push(element.value)

            });

            params.idFar = newArr
        }

        delete params.idFarmacie;
        setParamsRicerca(params)

        getList(params)

    }

    const getList = (par: any) => {

        setLoading(true)
        if (auth) {
            RestServices().rest('POST', par, urlB2B.getListini)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setTabellaPrincipale(result.response)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const ricarica = () => {
        if (paramsRicerca)
            getList(paramsRicerca)

    }

    const render = () => {

        switch (renderp) {
            case 1:
                return (<>

                    <Loading isVisible={loading} />
                    <div className='title'>
                        {/*<h3> LIVELLAMENTO MAGAZZINO  <span className='subt' > Registro fatturazione elettronica</span> </h3>*/}
                        <h3> ELENCO LISTINI B2B </h3>
                    </div>
                    <div style={{ width: '250' }}>
                        <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
                    </div>
                    <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                        <div className="col-md-11">


                        </div>
                        <div className="col-md-1 text-right">
                            <Button className="float-right" id='btnNuovoListino' variant="success" onClick={() => { setRenderp(2) }} >
                                NUOVO
                            </Button>
                        </div>

                    </div>

                    <NewTabella
                        idTabella='liv_mag'
                        sync
                        colonne={columnsListini}
                        dataSource={tabellaPrincipale}
                        filterRow
                        searchPanel={true}
                        onclickRow={() => null}
                        exportT={{
                            enabled: true,
                            fileName: "liv_mag"
                        }}
                        activeSaver={true}
                        columnC={true}
                    />

                </>)
            case 2:
                return (<>
                    
                     
                    <ListinoB2B
                        typeEdit={edit}
                        edit={undefined}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        setIndietro={() => { setRenderp(1); ricarica() }}
                        typeInsert={true}
                        farmacie={newFarmaList }
                    >

                    </ListinoB2B>
                    
                </>)
            case 3:
                return (
                    <>
                        <SingoloListinoOrdineB2B
                            idListino={idListino}
                            setIndietro={() => { setRenderp(1); ricarica() }}
                        >
                        </SingoloListinoOrdineB2B>
                    </>
                )
            default:
                return (<></>)

        }
    }
    return render()

}


export default ElencoLisitniB2B