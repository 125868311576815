import React, { Fragment, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faComments, faHome, faSignOutAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import { urlCfPortalLogout, urlGestioneUdiV2, urlHomePortal, urlInfoCsf, urlLogOut, urlRicercaSito } from '../contenuti/costanti';
import Image from 'react-bootstrap/Image'
import profile_image from '../immagini/profile_small.jpg'
import { MenuInterface } from '../interfaces/menu';
import qr from '../immagini/qr.png';
import cgm from '../immagini/cgm_logo.png';
import { Dropdown } from 'react-bootstrap';
import dropdown from '../immagini/dropdown_white.png';
import { RestServices } from '../services/restServices';
import notify from 'devextreme/ui/notify';
import { Player } from '../widget/Player';

const NavTopV2 = (props: { statoMenu: boolean, menu: MenuInterface | undefined, toggleMenu: (stato: boolean) => void }) => {
    //classi css per la navtop -> quando si chiude il menu laterale (statoMenu), si cambia classe per aggiornare le dimensioni della navtop
    let navTopClasses = 'navbar-top-links-cgm'
    if (!props.statoMenu) {
        navTopClasses = 'navbar-top-links closed'
    }
    //ricerca delle voci di menu nel sito -> TODO al momento non funziona!
    const [ricerca, setRicerca] = useState<string>();
    const [openPlayer, setOpenPlayer] = useState<boolean>(false)
    const valoreRicerca = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRicerca(e.target.value)
    }
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            let url = urlRicercaSito + "?qs=" + ricerca;
            window.location.assign(encodeURIComponent(url));
        }
    }


    return (
        <>
            <div className="row" style={{ backgroundColor: '#083C72', height: 8 }}>
            </div>
            <div className="row" style={{ backgroundColor: '#083C72', height: 32 }}>
                <div className="col" >
                    <div className="d-inline-block" >
                        <div id="logo-qr-cgm">
                            <Image src={qr} style={{ height: 24, marginLeft:18, marginBottom:4 }} />
                        </div>
                    </div>
                    <div className="d-inline-block">
                        <span className="title-cgm  inter-bold">Gestione UDI</span>
                    </div>
                </div>
                <div className="col">
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Image src={cgm} style={{ height: 24 }} />
                   </div>
                </div>
                <div className="col" >
                    <div style={{ display: 'flex', justifyContent: 'right', marginRight: -10 }}>
                    
                        {props.menu && <div className="d-inline-block" >
                            <table>
                                <tr>
                                    <td>
                                        <div>
                                            <i onClick={() => setOpenPlayer(true)} className="fa fa-question" style={{ cursor: 'pointer', fontSize: 'x-large', color: 'white', marginRight: '16px' }} ></i>
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            <Image style={{ height: 24 }} roundedCircle alt="image" src={profile_image}></Image>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                            
                        
                        </div>}
                        {props.menu && <div className="d-inline-block">

                            
                            <Dropdown style={{marginTop:-10} }>
                                    <Dropdown.Toggle id="btnLogout">
                                    <span className="user-logged-cgm inter-regular" style={{marginLeft: '-5px' }}>
                                            {props.menu.userName}
                                        </span>
                                        <img
                                            src={dropdown}
                                            className="custom-icon"

                                        />
                                    </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item style={{ fontSize: 16, padding: 16 }} onClick={() => { window.open(urlCfPortalLogout + "?sfbd=1", '_self') }}>LogOut </Dropdown.Item>
                                    </Dropdown.Menu>
                            </Dropdown>
                            
                                
                            
                        </div>}
                    </div>
                </div>
            </div>
            <div className="row" style={{ backgroundColor: '#083C72', height: 8 }}>
                
            </div>
            <Player
                isDialogPlayerOpen={openPlayer}
                setIsDialogPlayerOpen={setOpenPlayer}
                url={"https://youtu.be/4x2bMD2uRaI"}
                path={"" }
            />

        </>
    )
}

export default NavTopV2