import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_no_marge, trasform_for_cards_charts_01 } from "../util/trasformation";
import { MenuCruscotto } from "../components/menu_cruscotto"
import Button from "react-bootstrap/Button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Chart, PieChart } from 'devextreme-react';
import {
    Animation,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Label,
    Legend,
    Series,
    Subtitle,
    Title,
    Tooltip,
    ValueAxis
} from 'devextreme-react/chart';
import { Connector, Font, Format, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';
import DataGrid, { Column } from 'devextreme-react/data-grid';


import "./style.css";
import { createItemFilterAnaTransf, createItemFilterRepVenEffet } from "../evolution/ItemsFiltri/analisiPerOperatore";
import { columnsAnaTrasferime } from "../evolution/Colums/analisiPerOperatore";
import { columnsRepVenditeEffettuate, columnsRepVenditeEffettuate2 } from "./col/ColGalileo";


const RepVenditeEffettuate = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [operatori, setOperatori] = useState<any[]>([]);
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const chartRef = useRef<any>(null);
    const [chartDataMargine, setChartDataMargine] = useState<any>([]);
    const chartRefMargine = useRef<any>(null);
    const [chartKeyMargine, setChartKeyMargine] = useState(false);
    const [totalValueMargine, setTotalValueMargine] = useState(0);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);
    const [visiblePopOne, setVisiblePopOne] = useState(false);
    const [visiblePopPie, setVisiblePopPie] = useState(false);
    const [visiblePopPieChart, setVisiblePopPieChart] = useState(false)
    const [testoPop, setTestoPop] = useState<string>("");
    const [chartDataFirst, setChartDataFirst] = useState<any>([]);
    const chartRefFirst = useRef<any>(null);
    //const [ditte, setDitte] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaSconto, setTipologiaSconto] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);
    const loadPanel = <Loading isVisible={isVisible} />;
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [daysOfWeek, setDaysOfWeek] = useState<any[]>([]);

    const dashboard21= { dashboardID: 21 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }



    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        };

        // let venditaDPC = [0];
        // let flagBusta = [0];

        // if (params?.venditaDPC) {
        //     venditaDPC = [1];
        // }
        // if (params?.flagBusta) {
        //     flagBusta = [1];
        // }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            // venditaDPC: venditaDPC,
            // flagBusta: flagBusta,
            operatori: params.operatori,
            giorniSettimana: params.giorniSettimana,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", "")
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall21 = {
                ...dashboard21,
                //idFarList: params.idFar,
                idFarList: params.idFar,
                params: parameters,
            };

            console.log('mostro call');
            console.log(dashboardCall21);

            RestServices()
                .rest("POST", dashboardCall21, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {

                        let datiPrimaTabelle = result["90"];
                        let datiSecondaTabelle = result["91"];



                        //setValoriTabella1(testObj);
                        setValoriTabella1(datiPrimaTabelle);
                        setValoriTabella2(datiSecondaTabelle);


                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1� gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 � gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };




    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        const days = [
            { id: 1, descrizione: "Lunedi" },
            { id: 2, descrizione: "Martedi" },
            { id: 3, descrizione: "Mercoledi" },
            { id: 4, descrizione: "Giovedi" },
            { id: 5, descrizione: "Venerdi" },
            { id: 6, descrizione: "Sabato" },
            { id: 7, descrizione: "Domenica" }
        ];

        // Aggiorna lo stato con l'array popolato
        setDaysOfWeek(days);

        console.log(daysOfWeek);
        farmaList();
    }, [auth]);

    const filters = () => {
        // if (auth) {
        //     RestServices()
        //         .rest("POST", "", urlBancaDati.getGruppoMerceologico)
        //         .then((result) => {
        //             if (result) setGruppoMercFilters(result);
        //         });
        // }

        // const ssn = { id: 1, descrizione: "SSN" };
        // const libera = { id: 0, descrizione: "Libera" };

        // const vendite = [libera, ssn];
        // setTipologiaVendita(vendite);
    };

    useEffect(() => {
        filters();
    }, [auth]);

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabella_operatori.csv"
                );
            });
        });
    };

    const itemsFields = createItemFilterRepVenEffet(
        newFarmaList,
        daysOfWeek

    );

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };
    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };


    let messOne = ["Valore sconto per tipologia di sconto: Rappresenta la distribuzione del valore sconto, nel periodo selezionato, in funzione della tipologia di sconto applicata in vendita. Valore sconto: Rappresenta lo sconto totale applicato in vendita."];
    let messTwo = ["Andamento valore sconto: Descrive l'andamento mensile del valore sconto negli ultimi 12 mesi rolling. Valore sconto anno corrente: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Valore sconto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente � coerente a quello selezionato per l'anno corrente. "];
    let messThree = ["Valore sconto per operatore: Rappresenta la distribuzione del valore sconto, nel periodo selezionato, in funzione dell'operatore erogante lo sconto. Valore sconto: Rappresenta lo sconto totale applicato in vendita."];


    return (
        <>
            {/* <DialogAnaScoCateg
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            /> */}

            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>Reportistica - Vendite effettuate </h2>
                        </div>
                        {/* select*/}

               


                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>



                        <div style={{ marginTop: '5px' }}>

                            <h6
                                style={{
                                    color: "#5b6372",
                                    fontFamily: "Arial, sans-serif",
                                    marginBottom: "-20px",
                                    marginLeft: "10px",
                                    zIndex: 1,
                                    position: "relative"
                                }}
                            >
                                Tabella x anno e mese
                            </h6>



                            <NewTabella
                                idTabella='elenco_operatori'
                                sync
                                colonne={columnsRepVenditeEffettuate}
                                dataSource={valoriTabella1}
                                filterRow
                                width={10}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "Vendite effettuate anno mese",
                                    formats: { exportFormats }
                                }}
                                activeSaver={true}
                                columnC={true}
                                rowAlternation={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                            />

                                <NewTabella
                                    idTabella='elenco_operatori'
                                    sync
                                    colonne={columnsRepVenditeEffettuate2}
                                    dataSource={valoriTabella2}
                                    filterRow
                                    width={10}
                                    searchPanel={true}
                                    exportT={{
                                        enabled: true,
                                        fileName: "Vendite effettuate giorno",
                                        formats: { exportFormats }
                                    }}
                                    activeSaver={true}
                                    columnC={true}
                                    rowAlternation={true}
                                    headerFilter
                                    wordWrapEnabled={true}
                                    onExporting={onExporting}
                                />
                            {/* <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>  */}


                            {/* <div className="containButtonAna">
                                <Button
                                    id="btnCercax"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        setOpenPop(true);
                                        console.log("dialog");
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi sconti per operatori
                                </Button>
                            </div> */}


                        </div>


                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
            <Popup
                visible={visiblePop}
                showCloseButton={true}
                onHiding={() => setVisiblePop(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>


        </>
    );
};


export default RepVenditeEffettuate;
