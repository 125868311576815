import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import { DialogInfoFarmacia, DialogInfoFarmaciaMasterDetail } from "./Dialog";
import { TabellaCompletaInterface, TabellaSempliceInterface } from "../../interfaces/tabelle";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import Button from 'react-bootstrap/esm/Button';
import { TabellaCompleta, TabellaSemplice } from "../../widget/Tabelle";
import { useAuth } from "../../hooks/useAuth";


const InfoFarmacia = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const titolo = TitoloContenuto("Informazioni farmacia: ");
    const [isVisible, setIsVisible] = useState(false);
    const [infoFarmacia, setInfoFarmacia] = useState<any>();
    const loadPanel = <Loading isVisible={isVisible} />
    const [dialogInfoDatabaseOpen, setDialogInfoDatabaseOpen] = useState(false);
    const [dialogInfoTerminaliOpen, setDialogInfoTerminaliOpen] = useState(false);
    const [dialogInfoFilesystemOpen, setDialogInfoFilesystemOpen] = useState(false);
    const [dialogInfoSoftwareOpen, setDialogInfoSoftwareOpen] = useState(false);

    const auth = useAuth(urlMonitoraggio.auth);

    useEffect(() => {
        if (props.statoMenu && auth) {
            props.toggleMenu(!props.statoMenu);
            startCerca();
        }
    }, [auth]);

    const editorOptions = {
        readOnly: true,
    };

    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        const query = new URLSearchParams(window.location.search);
        const codTph = query.get('codTph');
        RestServices().rest('POST', param, urlMonitoraggio.getInfoFarmacia + "?codTph=" + codTph)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result.Ok === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setInfoFarmacia(result)
                    } else {
                        setInfoFarmacia(result);
                    }
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    //const propsInfoDatabase: TabellaSempliceInterface = {
    //    id: "infoDatabase",
    //    dataSource: infoFarmacia?.payload?.SqlServerInfo?.DatabasesInfo,
    //    filterRow: true,
    //    pagination: 10,
    //}

    const propsInfoDatabase: TabellaSempliceInterface = {
        id: "infoDatabase",
        dataSource: infoFarmacia?.payload?.SqlServerInfo?.DatabasesInfo,
        pagination: 10,
        filterRow: false,
    }

    const columnsInfoDatabase = CreaColonne([
        { caption: "Nome database", dataField: "DatabaseName", alignment: "right" },
        { caption: "Accesso utente", dataField: "UserAccess", alignment: "right" },
        { caption: "Stato", dataField: "Status", alignment: "right" },
        { caption: "Spazio totale (MB)", dataField: "TotalSize", alignment:"right" },
        { caption: "Spazio dati (MB)", dataField: "DataSize", alignment: "right" },
        { caption: "Spazio dati utilizzato (MB)", dataField: "DataUsedSize", alignment: "right" },
        { caption: "Spazio log (MB)", dataField: "LogSize", alignment: "right" },
        { caption: "Spazio log utilizzato (MB)", dataField: "LogUsedSize", alignment: "right" },
        { caption: "Data ultimo backup", dataField: "BackupLastDate", alignment: "right", format: "dd/MM/yyyy HH:mm:ss", dataType: "date"  }
    ])


    const propsInfoTerminali: TabellaSempliceInterface = {
        id: "infoTerminali",
        dataSource: infoFarmacia?.payload?.TerminalsInfo,
        filterRow: true,
        pagination: 10,
    }

    const columnsInfoTerminali = CreaColonne([
        { caption: "Id", dataField: "TerminalID" },
        { caption: "Nome terminale", dataField: "TerminalName" },
        { caption: "Nome macchina", dataField: "MachineName" },
        { caption: "E' server", dataField: "IsServer" },
        { caption: "Ospita db", dataField: "HostsRdbms", dataType: "boolean" },
        { caption: "DB hostname", dataField: "RdbmsHostName" },
        { caption: "DB hostname istanza", dataField: "RdbmsInstanceName" },
        { caption: "Data ultimo tracciamento", dataField: "LastTrackingDate", format: "MM/dd/yyyy HH:mm:ss", dataType: "date" },
    ])

    const propsInfoFilesystem: TabellaSempliceInterface = {
        id: "infoFilesystem",
        dataSource: infoFarmacia?.payload?.ServerInfo?.FileSystemsInfo,
        filterRow: false,
        pagination: 10,
    }

    const columnsInfoFilesystem = CreaColonne([
        { caption: "Disco", dataField: "Drive", alignment: "right" },
        { caption: "Spazio totale (GB)", dataField: "TotalSpaceInByte", alignment:"right" },
        { caption: "Spazio disponibile (GB)", dataField: "AvailableFreeSpaceInByte", alignment: "right" },
        
    ])

    const propsInfoSoftware: TabellaSempliceInterface = {
        id: "infoSoftware",
        dataSource: infoFarmacia?.payload?.ManagementSystemInfo?.SoftwareComponentsInfo,
        filterRow: true,
        pagination: 10,
    }

    const columnsInfoSoftware = CreaColonne([
        { caption: "Tipo", dataField: "ComponentTypeDescr" },
        { caption: "Path", dataField: "ComponentPath" },
        { caption: "Nome", dataField: "ComponentName" },
        { caption: "Versione", dataField: "ComponentVersion" },
        { caption: "Data", dataField: "ComponentDate", format: "MM/dd/yyyy HH:mm:ss", dataType: "date" },

    ])

    const columnsInfoDetailIndex = CreaColonne([
        { caption: "Nome schema", dataField: "SchemaName" },
        { caption: "Nome tabella", dataField: "TableName" },
        { caption: "Nome indice", dataField: "IndexName" },
        { caption: "AllocationUnitType", dataField: "AllocationUnitType" },
        { caption: "Percentuale media di frammentazione", dataField: "AverageFragmentationPercentage" },
        { caption: "PageCount", dataField: "PageCount" },
        

    ])

    const vediInfoDatabase = () => {
        setDialogInfoDatabaseOpen(true);
    }

    const vediInfoTerminali = () => {
        setDialogInfoTerminaliOpen(true);
    }

    const vediInfoFilesystem = () => {
        setDialogInfoFilesystemOpen(true);
    }

    const vediInfoSoftware = () => {
        setDialogInfoSoftwareOpen(true);
    }

    const vediInfo = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(infoFarmacia.payload)
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "data.json";

        link.click();


    }
    
    return (
        <>
            {loadPanel}
            {infoFarmacia &&
                <div className="contenutoPagina">
                    {/*<div className="dx-fieldset">*/}
                    {/*    <div className="dx-fieldset-header">Informazioni Sql Server</div>*/}
                    {/*    <div className="dx-field-label">Edizione</div>*/}
                    {/*    <div className="dx-field-value-static">{infoFarmacia?.payload?.SqlServerInfo?.RdbmsInfo?.Edition}</div>*/}
                    {/*    <div className="dx-field-label">Versione</div>*/}
                    {/*    <div className="dx-field-value-static">{infoFarmacia?.payload?.SqlServerInfo?.RdbmsInfo?.ProductVersion}</div>*/}
                    {/*    <div className="dx-field-label">Info versione</div>*/}
                    {/*    <div className="dx-field-value-static">{infoFarmacia?.payload?.SqlServerInfo?.RdbmsInfo?.VerboseVersionInfo}</div>*/}
                {/*</div>*/}
                <Form
                    colCount={2}
                    id="form"
                    formData={infoFarmacia}
                >

                    <GroupItem colCount={3} colSpan={2} caption="Informazioni Farmacia">
                        <SimpleItem dataField="Ragione Sociale" editorOptions={editorOptions}> {infoFarmacia?.ragioneSociale}</SimpleItem>
                        <SimpleItem dataField="Ragione Sociale Aggiunta" editorOptions={editorOptions}> {infoFarmacia?.ragioneSociale}</SimpleItem>
                        <SimpleItem dataField="Codice Telepharma" editorOptions={editorOptions}> {infoFarmacia?.codTph}</SimpleItem>
                        <SimpleItem dataField="Partita Iva" editorOptions={editorOptions}> {infoFarmacia?.partitaIva}</SimpleItem>
                        <SimpleItem colSpan={2} dataField="Codice fiscale" editorOptions={editorOptions}> {infoFarmacia?.codiceFiscale}</SimpleItem>
                        <SimpleItem dataField="Scarica informazioni complete" editorOptions={editorOptions}> <Button variant="success" onClick={vediInfo} id="vediInfoDatabase">Scarica</Button></SimpleItem>
                    </GroupItem>

                    <GroupItem colCount={1} colSpan={2} caption="Informazioni Server">
                        <SimpleItem dataField="Ora di sistema" editorOptions={editorOptions}> {infoFarmacia?.payload?.ServerInfo?.ServerSytemDateTime}</SimpleItem>
                        {/*<SimpleItem dataField="Info file system" editorOptions={editorOptions}> <Button variant="primary" onClick={vediInfoFilesystem} id="vediInfoDatabase">Vedi</Button></SimpleItem>*/}
                        <SimpleItem dataField="Info file system" editorOptions={editorOptions}>
                            <TabellaSemplice propsTabella={propsInfoFilesystem} columns={columnsInfoFilesystem} />
                        </SimpleItem>
                        
                    </GroupItem>

                    <GroupItem colCount={2} colSpan={2} caption="Informazioni Terminali">
                    <SimpleItem dataField="Info dei terminali" editorOptions={editorOptions}> <Button variant="primary" onClick={vediInfoTerminali} id="vediInfoDatabase">Vedi</Button></SimpleItem>
                    </GroupItem>

                    <GroupItem colCount={2} colSpan={2} caption="Informazioni Sql Server">
                        <SimpleItem dataField="Edizione" editorOptions={editorOptions}> {infoFarmacia?.payload?.SqlServerInfo?.RdbmsInfo?.Edition}</SimpleItem>
                        <SimpleItem dataField="Versione" editorOptions={editorOptions}> {infoFarmacia?.payload?.SqlServerInfo?.RdbmsInfo?.ProductVersion}</SimpleItem>
                        <SimpleItem colSpan={2} dataField="Info versione" editorOptions={editorOptions}> {infoFarmacia?.payload?.SqlServerInfo?.RdbmsInfo?.VerboseVersionInfo}</SimpleItem>
                        {/*<SimpleItem dataField="Info dei database" editorOptions={editorOptions}> <Button variant="primary" onClick={vediInfoDatabase} id="vediInfoDatabase">Vedi</Button></SimpleItem>*/}
                        <SimpleItem colSpan={2} dataField="Info dei database" editorOptions={editorOptions}>
                            <TabellaSemplice  propsTabella={propsInfoDatabase} columns={columnsInfoDatabase} />
                        </SimpleItem>
                    </GroupItem>

                    <GroupItem colCount={2} colSpan={2} caption="Informazioni di sistema">
                        <SimpleItem colSpan={2} dataField="Tipo di sistema" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.ManagementSystemTypeDescr}</SimpleItem>
                        <SimpleItem dataField="Numero release" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.InstalledRelease?.ReleaseNumber}</SimpleItem>
                        <SimpleItem dataField="Data release" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.InstalledRelease?.ReleaseDate}</SimpleItem>
                        <SimpleItem dataField="Ultima release di Banca Dati" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.LastBancaDatiInstalledRelease?.ReleaseNumber}</SimpleItem>
                        <SimpleItem dataField="Data ultima release di Banca Dati" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.LastBancaDatiInstalledRelease?.ReleaseDate}</SimpleItem>
                        <SimpleItem dataField="Utente ultima login" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.LastLogin?.UserInfo?.UserName}</SimpleItem>
                        <SimpleItem dataField="Data ultima login" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.LastLogin?.LoginDate}</SimpleItem>
                        <SimpleItem dataField="Info componenti software" editorOptions={editorOptions}> <Button variant="primary" onClick={vediInfoSoftware} id="vediInfoDatabase">Vedi</Button></SimpleItem>
                    </GroupItem>

                    <GroupItem colCount={3} colSpan={2} caption="Informazioni storiche">
                        <SimpleItem dataField="Data prima vendita" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.FirstSaleDate}</SimpleItem>
                        <SimpleItem dataField="Data ultima vendita" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.LastSaleDate}</SimpleItem>
                        <SimpleItem dataField="Profondita' storica vendite (gg.)" editorOptions={editorOptions}> {infoFarmacia?.ManagementSystemInfo?.payload?.HistoricalDataDepth?.SaleDataDepthInDays}</SimpleItem>
                        <SimpleItem dataField="Data primo movimento magazzino" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.FirstWarehouseMovementDate}</SimpleItem>
                        <SimpleItem dataField="Data ultimo movimento magazzino" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.LastWarehouseMovementDate}</SimpleItem>
                        <SimpleItem dataField="Profondita' storica movimenti (gg.)" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.WarehouseMovementDataDepthInDays}</SimpleItem>
                        <SimpleItem dataField="Data primo ordine" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.FirstOrderDate}</SimpleItem>
                        <SimpleItem dataField="Data ultimo ordine" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.LastOrderDate}</SimpleItem>
                        <SimpleItem dataField="Profondita' storica ordini (gg.)" editorOptions={editorOptions}> {infoFarmacia?.payload?.ManagementSystemInfo?.HistoricalDataDepth?.OrderDataDepthInDays}</SimpleItem>
                    </GroupItem>
                </Form>

                {/* <DialogInfoFarmacia
                    setDialogInfoFarmacia={setDialogInfoDatabaseOpen}
                    dialogInfoFarmaciaOpen={dialogInfoDatabaseOpen}
                    column={columnsInfoDatabase}
                    propsTab={propsInfoDatabase}
                    titolo="Info dei Database"
                />*/}

                {/*<DialogInfoFarmaciaMasterDetail*/}
                {/*    setDialogInfoFarmacia={setDialogInfoDatabaseOpen}*/}
                {/*    dialogInfoFarmaciaOpen={dialogInfoDatabaseOpen}*/}
                {/*    column={columnsInfoDatabase}*/}
                {/*    columnDetail={columnsInfoDetailIndex}*/}
                {/*    propsTab={propsInfoDatabase}*/}
                {/*    titolo="Info dei Database"*/}
                {/*/>*/}

                <DialogInfoFarmacia
                    setDialogInfoFarmacia={setDialogInfoTerminaliOpen}
                    dialogInfoFarmaciaOpen={dialogInfoTerminaliOpen}
                    column={columnsInfoTerminali}
                    propsTab={propsInfoTerminali}
                    titolo="Info dei Terminali"
                />

                <DialogInfoFarmacia
                    setDialogInfoFarmacia={setDialogInfoFilesystemOpen}
                    dialogInfoFarmaciaOpen={dialogInfoFilesystemOpen}
                    column={columnsInfoFilesystem}
                    propsTab={propsInfoFilesystem}
                    titolo="Info Filesystem"
                />

                <DialogInfoFarmacia
                    setDialogInfoFarmacia={setDialogInfoSoftwareOpen}
                    dialogInfoFarmaciaOpen={dialogInfoSoftwareOpen}
                    column={columnsInfoSoftware}
                    propsTab={propsInfoSoftware}
                    titolo="Info Software"
                />
                </div>


            }
                
            
            
        </>
    )

}

export default InfoFarmacia;