// queries All Anali perf operat

export const AllGlobalNew =
{
   "dashboardID": 1,
   "idFarList": [],
   "params": {},
}


// queries All Crusco gruppo

export const AllGlobalCruscoGruppo =
{
   "dashboardID": 4,
   "idFarList": [],
   "params": {},
}


// queries Analisi vendite

export const AllGlobalAnaliVendite =
{
   "dashboardID": 5,
   "idFarList": [],
   "params": {},
}

export const AllGlobalAnaliScontiVendite =
{
   "dashboardID": 6,
   "idFarList": [],
   "params": {},
}


export const AllGlobalAnaliScontiVendiGrupMerc =
{
   "dashboardID": 7,
   "idFarList": [],
   "params": {},
}

export const AllGlobalCruscottoAndaFarm =
{
   "dashboardID": 8,
   "idFarList": [],
   "params": {},
}

export const AllGlobalRicette =
{
   "dashboardID": 9,
   "idFarList": [],
   "params": {},
}

export const AllGlobalTransfe =
{
   "dashboardID": 10,
   "idFarList": [],
   "params": {},
}



export const firstWidget = {
   idFarList: [],
   queryID: 31,
   params: [],
   filterDetailsIDs: [
      {
         select: true,
         filterValueList: [[]],
         id: 423,
         where: false,
         orderBy: null,
         groupBy: false,
      },
      {
         select: true,
         filterValueList: [[]],
         id: 424,
         where: false,
         groupBy: false,
         orderBy: null,
      },
      {
         select: true,
         filterValueList: [[]],
         id: 425,
         where: false,
         groupBy: false,
         orderBy: null,
      },
      {
         select: true,
         filterValueList: [[]],
         id: 426,
         where: false,
         groupBy: false,
         orderBy: null,
      },
      {
         select: true,
         filterValueList: [[]],
         id: 427,
         where: false,
         groupBy: false,
         orderBy: null,
      },
      {
         select: true,
         filterValueList: [[]],
         id: 428,
         where: false,
         groupBy: false,
         orderBy: null,
      },
      {
         select: true,
         filterValueList: [[]],
         id: 429,
         where: false,
         groupBy: false,
         orderBy: null,
      },
      {
         id: 421,
         select: false,
         where: true,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 439,
         select: true,
         where: false,
         groupBy: true,
         filterValueList: [[]],
         orderBy: null,
      },
   ],
};

export const firstWidget_andamentoGruppo = {
   queryID: 32,
   idFarList: [],
   filterDetailsIDs: [
      {
         id: 451,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 452,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 453,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 454,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 456,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 459,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 460,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
   ],
   params: [],
};

export const secondWidget_andamentoGruppo = {
   queryID: 33,
   filterDetailsIDs: [
      {
         id: 466,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 467,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 468,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 469,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 470,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 472,
         select: true,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 473,
         select: false,
         where: true,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 474,
         select: false,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 477,
         select: true,
         where: false,
         groupBy: true,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 476,
         select: true,
         where: false,
         groupBy: true,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 478,
         select: true,
         where: false,
         groupBy: true,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 479,
         select: false,
         where: false,
         groupBy: true,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 480,
         select: false,
         where: false,
         groupBy: true,
         filterValueList: [[]],
         orderBy: null,
      },
      {
         id: 482,
         select: false,
         where: false,
         groupBy: false,
         filterValueList: [[]],
         orderBy: null,
      },
   ],
   idFarList: [],
   params: [],
};

export const terzoWidget_andamentoGruppo = {
   "queryID": 34,
   "filterDetailsIDs": [
      {
         "id": 485,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
      {
         "id": 486,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
      {
         "id": 491,
         "select": false,
         "where": true,
         "groupBy": false,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
      {
         "id": 492,
         "select": true,
         "where": false,
         "groupBy": true,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
      {
         "id": 493,
         "select": true,
         "where": false,
         "groupBy": true,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
      {
         "id": 507,
         "select": false,
         "where": false,
         "groupBy": true,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
      {
         "id": 489,
         "select": false,
         "where": false,
         "groupBy": true,
         "filterValueList": [
            []
         ],
         "orderBy": null
      }
   ],
   idFarList: [],
   params: [],
};


// ANALISI PERFORMANCE PER OPERATORE POP



export const fifthWidget_analisiPerfOpera = {
   "queryID": 44,
   "idFarList": [],
   "filterDetailsIDs": [
      {
         "id": 707,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 706,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 705,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 704,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 703,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 702,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 701,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 699,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      },
      {
         "id": 698,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [[]],
         "orderBy": null
      }
   ],
   "params": [],
}

// ANALISI PERFORMANCE PER OPERATORE POP

export const Pop_ANALISI_OPERA_GRU_MERC = {
   "queryID": 42,
   "idFarList": [],
   "filterDetailsIDs": [
      {
         "id": 658,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 659,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 660,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 661,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 662,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 663,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 663,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 664,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 665,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 666,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 667,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 751,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            []
         ],
         "orderBy": null
      },
   ],
   "params": [],
}

// CHART PIES pop

export const Pop_ANALISI_OPERA_Chart_pies = {
   "queryID": 43,
   "idFarList": [],
   "filterDetailsIDs": [
      {
         "id": 688,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 689,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 690,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 691,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 771,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            []
         ],
         "orderBy": null
      }
   ],
   "params": [],
}


// POP OPER DITTA TITOLARE

export const Pop_OPER_DITTA_TIT =
{
   "queryID": 44,
   "idFarList": [],
   "filterDetailsIDs": [
      {
         "id": 707,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 706,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 705,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 704,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 703,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 702,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 701,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 699,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 698,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      }
   ],
   "params": [],
}

// CROSS FARMACIA POP

export const Pop_Cross_Farm =
{
   "queryID": 45,
   "idFarList": [],
   "filterDetailsIDs": [
      {
         "id": 710,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 711,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 718,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 721,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 722,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 723,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 724,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 725,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 726,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 727,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 728,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 729,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 730,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 731,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 732,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 733,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      },
      {
         "id": 734,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
            [

            ]
         ],
         "orderBy": null
      }
   ],
   "params": []
}


// POP OPER DITTA TITOLARE

export const Pop_Ana_Sco_Vend_Categ   =
{
   "queryID": 50,
   "idFarList": [],
   "filterDetailsIDs": [
      {
          "id": 768,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      },{
          "id": 767,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      },{
          "id": 766,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      },{
          "id": 765,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      },{
          "id": 763,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      },{
          "id": 762,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      },{
          "id": 764,
          "select": true,
          "where": false,
          "groupBy": false,
          "filterValueList": [
              []
          ],
          "orderBy": null
      }
  ],
   "params": [],
}


// POP NANAL SCON VEND GRUPP MERC

export const Pop_Ana_Sco_Vend_Gruppo_Merc    =
{
   "queryID": 55,
   "idFarList": [],
   "filterDetailsIDs": [
      {
         "id": 842,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     },{
         "id": 843,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     },{
         "id": 844,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     },{
         "id": 845,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     },{
         "id": 846,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     },{
         "id": 847,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     },{
         "id": 848,
         "select": true,
         "where": false,
         "groupBy": false,
         "filterValueList": [
             []
         ],
         "orderBy": null
     }
  ],
   "params": [],
}