import React from 'react';

export default function CustomItem(data: any) {
    return (
        <div>
            <p className="testo">
                Registrazione e conservazione dell' identificativo unico del dispositivo (UDI) per i dispositivi medici e per i dispositivi medico-diagnostici in vitro (IVD).<br></br>
                Istruzione per la registrazione:<br></br>
                1.	<b>Verifica</b><br></br>
                &emsp;Digitare il codice ministeriale del prodotto per verificare se e' prevista la registrazione del codice UDI. In caso affermativo procedere con la registrazione del movimento<br></br>
                2.	<b>Compilazione</b><br></br>
                &emsp;Compilazione obbligatoria dei seguenti campi per la registrazione<br></br>
                <br></br>
                <ul style={{ listStyleType: 'disc' }}>
                    <li><b>Farmacia</b></li>
                    <li><b>Codice UDI</b></li>
                    <li><b>Data</b></li>
                    <li><b>Tipo movimento</b></li>
                </ul>
            </p>
        </div>
    );
}