import React, { useEffect, useState } from 'react'
import { DialogConferma, Loading } from '../../../widget/Notifications'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione, urlFidelityBe } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import { ListaFarmacieInterface } from '../../../interfaces/farmacie'
import { RadioGroup } from 'devextreme-react/radio-group'
import Button from 'react-bootstrap/Button'
import { CheckBox } from 'devextreme-react/check-box'
import { Filtri } from '../../consultazione/interface'

import '.././style.css';
import ConfigParamCalcolo from './CouponFidelity'


const ConfigCoupon = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    //const firstAuth = useAuth(urlConsultazione.auth);
    const auth = useAuth(urlFidelityBe.auth);
    const [loading, setLoading] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>()
    const [fetchInfo, setFetchInfo] = useState<any>()
    const [fecthUpdate, setFetchUpdate] = useState<any>()
    const [renderp, setRenderp] = useState<any>();
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [openPop2, setOpenPop2] = useState<boolean>(false);
    const [openPop3, setOpenPop3] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);
    const [farmacie, setFarmacie] = useState<any>([])
    const [popConferma, setPopConferma] = useState<boolean>(false)
    const [messConferma, setMessConferma] = useState<string>('')


    const setCheck = () => {

        let model = {
            idCluster: 1
        }
        RestServices().rest('POST', model, urlFidelityBe.getGenericsInfor)
            .then(result => {
                if (result) {
                    setObjctTOsave(result.response)
                } else {
                    // handle error
                }
            })

    }

    const updateCheck = () => {
        let model = {
            idCluster: 1,
            flagFasceFidelity: objctTOsave.flagFasceFidelity ? false : true

        }
        RestServices().rest('POST', model, urlFidelityBe.updateCreateMode)
            .then(result => {
                if (result) {

                } else {
                    //handle error
                }
            })
    }

    const ricarica = () => {
        setOpenPop(false);
        //caricaDati()
    };

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value

        console.log(2222)
        console.log(objTo[tipo])
        setObjctTOsave(objTo);
        //console.log(objTo);  


    }

    useEffect(() => {
        if (props.statoMenu) {
            //  props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            setCheck()
        }
    }, [auth])

    const render = () => {
        switch (renderp) {
            case 2:
                return (

                    <ConfigParamCalcolo
                        rowData={objtipo}
                        setIndietro={() => { setRenderp("default") }}
                    >
                    </ConfigParamCalcolo>
                )

            default:
                return (
                    <>

                        <Loading isVisible={loading} />

                        <DialogConferma

                            dialogIsOpen={popConferma}
                            message={messConferma}
                            functionNext={() => setCheck()}
                            handleCloseConferma={() => { setPopConferma(false) }}

                        />

                        <div className='title'>
                            <h3> Configurazione Coupon  <span className='subt' > </span> </h3>
                        </div>      

                        <div style={{ background: '#fff', padding: 10, borderRadius: 10, bottom: 20 }}>
                            <div style={{}}>

                                <CheckBox text={'Permetti la creazione di coupon tramite i punti delle fidelity'} onValueChanged={(x) => {
                                    cambiaValore('flagFasceFidelity', x.value)
                                    updateCheck()
                                }
                                } value={objctTOsave?.flagFasceFidelity} defaultValue={false} />

                                {objctTOsave?.flagFasceFidelity ? (
                                    <div className="form-group">
                                        <div style={{ width: 400 }}>
                                            <Button style={{ marginTop: 10 }} onClick={() => {
                                                setRenderp(2)
                                            }}>
                                                IMPOSTA I PARAMETRI DI CALCOLO
                                            </Button>
                                        </div>
                                    </div>
                                ) : null}



                            </div>
                        </div>
                    </>
                )
        }
    }

    return render()

}

export default ConfigCoupon
