import React, { useEffect, useState } from 'react'
import Select from 'react-select'


// import './style.css';


const StatBusinessLabDashb = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    
    let options:any = [
        {label:'Analisi Magazzino', value:'5eb53696a2eb6e15f8c2598a'},
        {label:'Osservatorio Margini', value:'5cb47dcdc9486212789fb9e4'},
        {label:'Analisi Gruppo Merceologico per Fascia Oraria', value:'5d93052f3f8efb1f28292b3d'}
       
   ]

   const [selection,setSelection] = useState<string>('5eb53696a2eb6e15f8c2598a')

    useEffect(() => {
       
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    return (
        <>      
        <div className='title'>
                <h4> BusinessLAB <span className='subt' > Statistiche Operative</span>  </h4>
             </div>
             <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={options[0]}
                    isClearable={false}
                    isSearchable={true}
                    onChange={(s)=>{
                       // console.log(s.value);
                        setSelection(s.value)
                        
                    }}
                    name="color"
                    options={options}
                />    
            <div style={{ height: '80vh', marginTop: 10 }}>
                <iframe
                    id="ifm"
                    width="100%"
                    height="100%"
                    src={`https://sss.cloud-farma.it/app/main#/dashboards/${selection}?embed=true`}
                ></iframe>
            </div>
           
        </>
    )
}

export default StatBusinessLabDashb