import React, { useState, useRef, useEffect ,useMemo } from 'react'
import { SelectBox } from 'devextreme-react/select-box'
import DateBox from 'devextreme-react/date-box'
import { NumberBox } from 'devextreme-react/number-box'
import { RadioGroup } from 'devextreme-react/radio-group'
import { Autocomplete } from 'devextreme-react/autocomplete'
import TextBox from 'devextreme-react/text-box'
import Button from 'react-bootstrap/Button'
import { Loading } from '../../widget/Notifications'
import { ChiamataApi } from './serviceAuto'
import notify from 'devextreme/ui/notify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown , faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { cambiaFormatoLocalDateTimeToEuropeo,  trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { CheckBox } from 'devextreme-react/check-box'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Select from 'react-select'

import './style.css';
import { SelezioneMultiplaFarmacie } from '../Select'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle'
import { OggettoInterface } from '../../interfaces/generiche'
import { Filtri } from '../../contenuti/consultazione/interface'

interface RicercaProps  {
    items: any,
    advace?: boolean,
    async?:(newFiltro: object) => void,
    ricerca: (newFiltro: object) => void,
    loadingc?:boolean
} 

export const FarmDropdown:React.FC<RicercaProps>  = React.memo(({items,ricerca,async,advace,loadingc}) => {
    

    const [objPostRicerca , setObjPostRicerca ] = useState<any>({})
    const [open,setOpen] = useState<boolean>(true)
    const [autoComp, setAutoComp ] = useState<any>([])
    const [loading,setLoading] = useState<any>({})
    const [openMore,setOpenMore] = useState<boolean>(false)
    const [loadingcx,setLoadingcx] = useState<any>(false)

    const [filterValues, setFilterValues] = useState<Filtri>({});

    useEffect(()=>{
        setLoadingcx(loadingc)
    },[loadingc])

    useEffect(()=>{
        items.forEach((element:any) => {
            if(element.idValue == "idFar" && element.dataSource.length == 1){
                let objPostRicercaN = {...objPostRicerca}
                objPostRicercaN.idFar = element.dataSource[0]
                setObjPostRicerca(objPostRicercaN)
            }
            
        });
        /*objPostRicerca.dal = new Date()
        setObjPostRicerca(objPostRicerca)*/
        items.forEach((element: any) => {
            if(element.defValue){
                objPostRicerca[element.idValue] = element.defValue
                setObjPostRicerca(objPostRicerca)
            }
        });
               
    },[])

    const actions = async (string: string, typeApi: string, idVal?: string) => {
        let loadingg = {...loading}
        if(idVal){
          loadingg[idVal] = true
          setLoading(loadingg)
        }
        
        
        let risulatoCall = await ChiamataApi(string,typeApi)
        if(risulatoCall){
            if (risulatoCall?.esito) {
                //console.log(risulatoCall.response);
                setAutoComp(risulatoCall.response)
                if(idVal){
                    loadingg[idVal] = false
                    setLoading(loadingg)
                  }
            }else{
                if(idVal){
                    loadingg[idVal] = false
                    setLoading(loadingg)
                  }
                notify({ position: 'center', width: "auto", message: risulatoCall.message }, "error", 4000)

            }
            console.log(loading)
        }
        
    }


    const tagRicercha = () => {   
        let tagArr = []    
        for (const [key, value] of Object.entries(objPostRicerca)) {            
            //console.log(value); 
            if(key == 'flagEntrata'){
                tagArr.push('Entrate')
            }
            if(key == 'flagUscita'){
                tagArr.push('Uscite')
            }
            if(key == 'trattati'){
                if(value){
                    tagArr.push('Trattati')
                }
            }
            if(key == 'descrizioneProdotto'){
                tagArr.push(value)
            }
            if(key == 'atc'){
                tagArr.push(value)
            }  
            if(key == 'numeroDoc'){
                tagArr.push(value)
            }   
            if(key == 'cedente'){
                tagArr.push(value)
            } 
            if (key == 'cessionario') {
                tagArr.push(value)
            }

            let valoreDaUsare:any = value

            if(Array.isArray(valoreDaUsare)){
                if(key == 'idFar'){
                    valoreDaUsare.forEach(element => {
                        tagArr.push(element.label)
                    });
                    
                }
            }
            

            if(typeof valoreDaUsare === 'object' &&
            valoreDaUsare !== null &&
            !Array.isArray(valoreDaUsare) ){
                //console.log(valoreDaUsare);
                //console.log(key);           
                if(key == 'codDitta'){
                    tagArr.push(valoreDaUsare.ragioneSociale)
                }
                if(key == 'idFar'){
                    tagArr.push(valoreDaUsare.descrizione)
                }
                if (key == 'idAzi') {
                    tagArr.push(valoreDaUsare.descrizione)
                }
                if(key == 'codCsf'){
                    tagArr.push(valoreDaUsare.text)
                }
                if(key == 'idConto'){
                    tagArr.push(valoreDaUsare.conto)
                }
                if(key == 'idCausale'){
                    tagArr.push(valoreDaUsare.descrizioneBreve)
                }
                if(key == 'descrizioneProdotto'){
                    tagArr.push(valoreDaUsare.text)
                }
                if(key == 'numeroDoc'){
                    tagArr.push(valoreDaUsare.text)
                }
                if(key == 'id_TIF'){
                    tagArr.push(valoreDaUsare.descrizione_BREVE)
                }
                if(key == 'cedente'){
                    tagArr.push(valoreDaUsare.text)
                }
                if (key == 'cessionario') {
                    tagArr.push(valoreDaUsare.text)
                }
                if(key == 'codiceSettore'){
                    tagArr.push(valoreDaUsare.descrizioneSettore)
                }
                if(key == 'codiceGruppo'){
                    tagArr.push(valoreDaUsare.descrizioneGruppo)
                }
                if(key == 'codiceCategory'){
                    tagArr.push(valoreDaUsare.descrizioneCategory)
                }
                 if (key == 'idFornitore'){
                    tagArr.push(valoreDaUsare.ragioneSociale)
                }

                if (key == 'codGruppoMerceologico'){
                    tagArr.push(valoreDaUsare.descGruppoMerceologico)
                }
                if (key == 'idStato') {
                    tagArr.push(valoreDaUsare.descrizione)
                }

                 // if(key == 'filtroClasseA'){
                //     tagArr.push(valoreDaUsare.descrizioneCondizione)
                // }
                // if(key == 'filtroClasseC'){
                //     tagArr.push(valoreDaUsare.descrizioneCondizione)
                // }
                // if(key == 'filtroGenerici'){
                //     tagArr.push(valoreDaUsare.descrizioneCondizione)
                // }
                // if(key == 'filtroIntegratori'){
                //     tagArr.push(valoreDaUsare.descrizioneCondizione)
                // }
                // if(key == 'filtroOmeopatici'){
                //     tagArr.push(valoreDaUsare.descrizioneCondizione)
                // }
                // if(key == 'filtroSopOtc'){
                //     tagArr.push(valoreDaUsare.descrizioneCondizione)
                // }

                // if(key == 'dataInizioInvio'){
                //     let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                //     tagArr.push( dataFrom)
                // }

                // if(key == 'dataFineInvio'){
                //     let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                //     tagArr.push( dataFrom)
                // }

                
                if(key == 'dataInizioDoc'){
                    let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                    tagArr.push( dataFrom)
                }
                if(key == 'dataFineDoc'){
                    let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                    tagArr.push( dataFrom)
                }
      
                if(key == 'data'){
                    let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                    tagArr.push( dataFrom)
                }
                if(key == 'dal'){
                    let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                    tagArr.push( dataFrom)
                }
                // try tag Al 
                if(key == 'al'){
                    let dataFrom = cambiaFormatoLocalDateTimeToEuropeo(valoreDaUsare , false)
                    tagArr.push( dataFrom)
                }      
                       
                
            }
          }

          if(open){
            return null
          }else{
            return(
                tagArr.map((tag,index)=>{
                  return(
                      <div style={{marginRight:10, display: 'inline-block',background:' #dfdfdf', padding: 5, paddingLeft: 10,paddingRight: 10, borderRadius: 5}} key={'tag'+index}>
                          <p style={{fontSize:13,margin:0}}>{tag}</p>
                      </div>
                  )
                })
            )
          }    
    } 

     const remove = (id:string) =>{
        let nuovoObj = {...objPostRicerca}
        delete nuovoObj[id]
        setObjPostRicerca(nuovoObj)
     }
    const populateObjPost = (id:string , value:any) =>{
        let nuovoObj = {...objPostRicerca}
        nuovoObj[id] = value
        if(objPostRicerca.type == 'autocomplete'){
       
        }
        if(async && id == 'idFar'){
            async(nuovoObj)
        }
        
        
        setObjPostRicerca(nuovoObj)
    }


    const colonneGridGeneriche = CreaColonne([
        { dataField: "ID_FAR", caption: "id", visible: false },
        { dataField: "CODICE", caption: "CODICE", width: 120 },
        { dataField: "DESCRIZIONE", caption: "DESCRIZIONE", sortOrder: "asc" },
        
    ])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        //let newF = { ...filtri, ...newFiltro }
        //setFiltri(newF);

        //console.log(newFiltro)

        setFilterValues({});
        if (newFiltro.idFar.length > 0 )
            populateObjPost("idFarmacia", newFiltro);
        else
            remove("idFarmacia")

    }


    const createInput = (obj:any,index:number) =>{
        switch (obj.type) {
            case 'string':
                return(
                    <div style={{width:obj.width ? obj.width : null }}>
                        <label>{obj.label} {obj.require ? <span>*</span> : null}</label>
                        <TextBox onEnterKey={(e) => { if (obj.enterSearch === true) ricerca(objPostRicerca)  } } showClearButton={true} onValueChanged={(v) => populateObjPost(obj.idValue, v.value)} key={'input_' + index} placeholder="Inserisci..." defaultValue={obj.defaultValue} /> 
                    </div>
                )  
            case 'number':
                return(
                    <div style={{width:obj.width ? obj.width : null }}>
                       <label>{obj.label} {obj.require ? <span>*</span> : null}</label> 
                       <NumberBox showClearButton={true} defaultValue={null}  onValueChanged={(v)=>populateObjPost(obj.idValue, v.value)}  key={'input_'+index} /> 
                    </div>
                )      
            case 'select':
                return(
                    <div style={{width:obj.width ? obj.width : null }}>
                       <label>{obj.label} {obj.require ? <span>*</span> : null}</label> 
                        <SelectBox dataSource={obj.dataSource}
                            showClearButton={true}
                            displayExpr={obj.displayExpr}
                            value={obj.idValue == "idFar" && obj.dataSource.length == 1 ? obj.dataSource[0] : obj.defaultValue}
                            valueExpr={obj.valueExpr}
                            searchEnabled={true}
                            onValueChanged={(v) => populateObjPost(obj.idValue, v.value)}
                            grouped={obj.grouped != undefined ? obj.grouped : false}
                            
                    />
                    </div>
                )
            case 'select2':
                    return(
                        <div style={{width:obj.width ? obj.width : null }}>
                            <label>{obj.label} {obj.require ? <span>*</span> : null}</label>
                            <Select placeholder="Seleziona..." menuPortalTarget={document.body} menuPosition={'fixed'} styles={{
                            multiValue: (styles, { data }) => {
                                //console.log(data);         
                                return {
                                  ...styles,
                                    maxWidth: 70
                                };
                              },
                            }} onChange={(x) => populateObjPost(obj.idValue, x)} isMulti options={obj.dataSource} className="basic-multi-select"
                                classNamePrefix="select" />
 
                          {/* <SelectBox dataSource={obj.dataSource}
                            showClearButton={true}
                            displayExpr={obj.displayExpr}
                            defaultValue={obj.idValue == "idFar" && obj.dataSource.length == 1 ? obj.dataSource[0] : null}
                            searchEnabled={true}
                            onValueChanged={(v)=>populateObjPost(obj.idValue, v.value)}
                    />*/}
                        </div>
                ) 

            case 'selectFarmacie':
                return (
                    <div style={{ width: obj.width ? obj.width : null }}>
                        <label>{obj.label} {obj.require ? <span>*</span> : null}</label>
                        <SelezioneMultiplaFarmacie
                            dataSource={obj.dataSource}
                            id="idFar"
                            impostaFiltri={impostaFiltri}
                            tutteSelezionate={
                                !objPostRicerca?.idFar ||
                                    !filterValues.idFar ||
                                    JSON.stringify(filterValues.idFar) === "{}"
                                    ? true
                                    : false
                            }
                            column={colonneGridGeneriche}
                            selection="multiple"
                            primaSelezionata={false}
                            valueGrid={filterValues.idFar}

                        />
                    </div>
                )
            
            case 'date':
                return(
                    <div style={{width:obj.width ? obj.width : null }}>
                       <label>{obj.label} {obj.require ? <span>*</span> : null}</label> 
                       <DateBox showClearButton={obj.clear != undefined ? obj.clear : true} onValueChanged={(v)=>populateObjPost(obj.idValue, v.value)} defaultValue={obj.defValue ? obj.defValue : null} type="date" displayFormat="dd/MM/yyyy" />
                    </div>
                )
            case 'ckBox':
                return(
                    <div style={{width:obj.width ? obj.width : null }}>
                        <label>{obj.label} {obj.require ? <span>*</span> : null}</label>
                        <br></br> 
                        <CheckBox  defaultValue={obj.defValue ? true :false } onValueChanged={(v)=>populateObjPost(obj.idValue, v.value) } />
                    </div>
                )    
                case 'radioButton':
                    return(
                        <div style={{width:obj.width ? obj.width : null }}>
                            <label>{obj.label} {obj.require ? <span>*</span> : null}</label>
                            <br></br> 
                            <RadioGroup  onValueChanged={(v)=>populateObjPost(obj.idValue, v.value) } />
                        </div>
                    )    
            case 'autocomplete':
                if(objPostRicerca[obj.idValue]){
                    return(
                        <div style={{width:obj.width}}>
                            <label>{obj.label}</label> {obj.defValue }
                            <div className='autoBtn'>
                                <div className='troncamento'>
                                {obj.defValue ? obj.defValue : objPostRicerca[obj.idValue][obj.valueExpr] }    
                                </div>                       
                                <button onClick={()=>remove(obj.idValue)} style={{border:'none',fontSize:14,background:'#fff',color:'black',borderRadius:10,marginLeft:3,height: 18 , width: 18}}>x</button>
                            </div>
                        </div>
                    )
                }else{
                    return(
                        <div style={{width:obj.width ? obj.width : null }} >
                            <label>{obj.label} {obj.require ? <span>*</span> : null}</label> 
                            <div style={{position:'relative'}}>
                            <Autocomplete 
                            placeholder="Per favore inserisci 2 o più caratteri" 
                            displayExpr={'id'}
                            valueExpr={obj.valueExpr}
                            dataSource={autoComp} 
                            onItemClick={(v)=>populateObjPost(obj.idValue, v.itemData)} 
                            onValueChanged={(p)=>actions(p.value,obj.typeApi,obj.idValue)}/>
                            {loading[obj.idValue] ? (
                               <img style={{width:30,height:30,position:'absolute',top:3,right:2}} src='https://media3.giphy.com/media/3oEjI6SIIHBdRxXI40/200w.gif?cid=82a1493bvt3ie5rmhndsmobp0937yl13qq2ns64iczpazc4e&rid=200w.gif&ct=g' />

                            ) : null}
                            </div>
                        </div>
                    )
                }
            case 'autocompleteFornitori':
                
                if(objPostRicerca[obj.idValue]){
                    return(
                        <div style={{width:obj.width ? obj.width : null }}>
                            <label>{obj.label}</label> 
                            <div className='autoBtn'>
                                <div className='troncamento'>
                                {objPostRicerca[obj.idValue].text}
                                </div>
                                
                                <button onClick={()=>remove(obj.idValue)} style={{border:'none',fontSize:12,background:'#fff',color:'blacl',borderRadius:10,marginLeft:3,height: 18 , width: 18}}>x</button>
                            </div>
                        </div>
                    )
                }else{
                    return(
                        <div style={{width:obj.width ? obj.width : null }}>
                            <label>{obj.label} {obj.require ? <span>*</span> : null}</label> 
                            <Autocomplete 
                            displayExpr={'id'}
                            valueExpr="text"
                            dataSource={autoComp} 
                            onItemClick={(v)=>populateObjPost(obj.idValue, v.itemData)} 
                            onValueChanged={(p)=>actions(p.value,'for')}/>
                        </div>
                    )
                }    
        
            default:
                break;
        }
    }
    
    
    const okRicerca = () =>{
        let valore = true
        let require11BoolVal 
        let require12BoolVal 
        let require21BoolVal 
        let require22BoolVal 
        items.forEach((element:any) => {
            if (element.require){
                if(!objPostRicerca[element.idValue]){
                    valore =  false
                }
            }
            if (element.require11) {
                if (!objPostRicerca[element.idValue]) {
                    require11BoolVal = false
                } else
                    require11BoolVal = true
            }
            if (element.require12) {
                if (!objPostRicerca[element.idValue]) {
                    require12BoolVal = false
                } else
                    require12BoolVal = true
            }
            if (element.require21) {
                if (!objPostRicerca[element.idValue]) {
                    require21BoolVal = false
                } else
                    require21BoolVal = true
            }
            if (element.require22) {
                if (!objPostRicerca[element.idValue]) {
                    require22BoolVal = false
                } else
                    require22BoolVal = true
            }
        });

        if ((require11BoolVal == true) && (require12BoolVal == true))
            valore = true
        if ((require21BoolVal == true) && (require22BoolVal == true))
            valore = true

        return valore
    }
    
    return (
        <div style={{background:'white',padding: 10 ,borderRadius: 10,}}>
            <div style={{display: 'flex',justifyContent: 'space-between'}}>
                <div><h4 style={{fontSize:22,margin:0}}>Filtri ricerca: {tagRicercha()}</h4></div>
                
                <div><button style={{border:'none',background:'none',fontSize:20}} onClick={()=>setOpen(!open)}>{open ? <FontAwesomeIcon icon={faAngleUp} /> :  <FontAwesomeIcon icon={faAngleDown} /> }</button></div>

            </div>
           
            <div className={open ? 'main' : 'mainOut'}>     
                <Row>
                    <Col sm="10">
                        <div style={{ position: 'relative' }} className='gridFilterRicerca'>
                            {loadingcx ? (
                                <div style={{ textAlign: 'center' }}>
                                    <Loading isVisible={true} />
                                </div>

                            ) : null}

                            {items.map((inputObj: any, index: number) => {
                                if (advace) {
                                    if (openMore) {
                                        return (
                                            <div key={index}>
                                                {createInput(inputObj, index)}
                                            </div>
                                        )
                                    } else {
                                        if (inputObj.advace) {
                                            return null
                                        } else {
                                            return (
                                                <div key={index}>
                                                    {createInput(inputObj, index)}
                                                </div>
                                            )
                                        }
                                    }

                                } else {
                                    return (
                                        <div key={index}>
                                            {createInput(inputObj, index)}
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </Col>
                    <Col sm="2">
                        <div className='posbtn'>
                            {okRicerca() ? (
                                <Button id='btnCerca' className="btn btn-success" variant="success" onClick={() => { ricerca(objPostRicerca) }} >
                                    CERCA
                                </Button>
                            ) : (
                                <Button disabled id='btnCerca' className="btn btn-success" variant="success"  >
                                    CERCA
                                </Button>
                            )}

                        </div>
                    </Col>
                </Row>
                
        

        
        
    </div>
     <div style={{padding:10,textAlign:'right'}}>
        {advace ? 
         openMore ? (
            <a style={{fontSize:17,color:'blue',cursor:'pointer'}} onClick={()=>setOpenMore(!openMore)}>Meno Filtri</a>
         ) : (
            <a style={{fontSize:17,color:'blue',cursor:'pointer'}} onClick={()=>setOpenMore(!openMore)}>Filtri Avanzati</a>
         ) : null }
     </div>
    
    </div>
    )
})

export default FarmDropdown

function chroma(color: any) {
    throw new Error('Function not implemented.');
}
