import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import Button from 'devextreme-react/button'
import { NewTabella } from '../../../widget/nuova_tabella/newVersion'


interface DialogProps  {
    open: boolean,
    edit:any,
    id:any,
    close: ()=>void
} 


export const DialogList:React.FC<DialogProps> = ({open,close,id,edit })  => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [profiloList,setProfiliUtenti] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])



    const columns = [
        { caption: "Username", dataField: "username"  },
        { caption: "Cognome", dataField: "cognome" },
        { caption: "Nome", dataField: "nome"  },
    ]

   



    const getProfUtenti = () =>{
        //setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getProfiliUtenti)
                .then(result => {
                    if (result?.esito) {
                        setProfiliUtenti(result.response)
                        setLoading(false)
                        //console.log(result);
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })      
         }
    }


    const getutenti = () =>{
       RestServices().rest('POST', {idProfile:String(id)} , urlConsultazione.getListUtenti)
            .then(result => {
               if(result.esito){
                setTabellaPrincipale(result.response)
                
               }
               else{
               // console.log(result);
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }
    
            }) 
   }


            useEffect(()=>{
               
                
                if(auth && open && id ){
                    //farmaList()
                    getutenti()
                }
            
            },[auth,open,id,])



    useEffect(()=>{
        setOpenD(open)
        
    },[auth, open])


    useEffect(()=>{
        if(edit){
             setObjctTOsave(edit) ;
             
        }
    },[edit, openD])



    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'70%'}
          showTitle={true}
          title="Utenti Profilo"
        >
        
           <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'CHIUDI',
                onClick: ()=>{close()},
              }}
          />

              
               <ScrollView width='100%' height='100%'>
             <div style={{textAlign:'center'}}>
              <p>Lista degli utenti associati al profilo {edit?.pn} </p>
              </div>

               <NewTabella
            idTabella="gest_lista_profili"
            sync
            searchPanel={true}
            colonne={columns}
            height='auto'
            hidePager={false}
            dataSource={tabellaPrincipale}
            onclickRow={(e)=>{
                //setIdUtente(e.data?.idUtente)
                //console.log(e.data);
                
            }}
            exportT={{
              enabled: true,
              fileName: "Gest_lista_profili",
            }} 
          />
           </ScrollView>    
          
        
        </Popup>
        </>
      )

  
}






     