import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { FiltriFornitoriLocInterface, FornitoriInterface } from '../../interfaces/anaFornLocali';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { RestServices } from '../../services/restServices';
import { DrawerFiltri } from '../../widget/Drawer';
import { BoxFiltro, Check } from '../../widget/Editor';
import { Loading } from '../../widget/Notifications';
import { SelectFarm } from '../../widget/Select';
import { TabellaCompleta, TabellaCompletaInfoFarmacia } from '../../widget/Tabelle';
import { urlAnagrafiche } from '../costanti';
import { OggettoInterface } from '../../interfaces/generiche';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { TabellaCompletaInterface } from '../../interfaces/tabelle';

const AnagraficaFornitoriLocali = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean
}) => {
    //al caricamento della pagina si fa collassare il menu laterale in modo da avere più spazio a disposizione per la pagina
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, [])

    //crea il titolo della pagina sotto la navbar
    const titolo = TitoloContenuto("Anagrafica Fornitori Locali");

    const idTabella = "anagrafica_fornitori_loc";

    //rende visibile/invisibile il pannello di loading
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    //stato di apertura di toolbar/drawer filtri
    const [open, setOpen] = useState(true);

    const [filtri, setFiltri] = useState<FiltriFornitoriLocInterface>({ trattata: true });
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const [fornitori, setFornitori] = useState<FornitoriInterface>();

    //si crea il riepilogo dei filtri a seconda di cosa viene selezionato
    const creaRiepilogoFiltri = () => {
        let descrFarm: string = "";
        if (filtri && filtri.idFar) {
            descrFarm = props.datiFarmacie.filter((item) => {
                return item.ID_FAR === filtri.idFar
            })[0].DESCRIZIONE
        }
        let trattati: string = "Si"

        return (
            <>
                {(descrFarm) && <span className="cerca">&nbsp;Farmacia: {descrFarm}</span>}
                {(filtri && filtri.ragSociale) && <span className="cerca">&nbsp;Ragione Sociale: {filtri.ragSociale}</span>}
                {(filtri && filtri.pIva) && <span className="cerca">&nbsp;Partita IVA: {filtri.pIva}</span>}
                {(filtri && filtri.trattata) && <span className="cerca">&nbsp;Trattati: {trattati}</span>}
            </>
        )
    }
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const propsTabella: TabellaCompletaInterface = {
        id: idTabella,
        dataSource: fornitori,
        selection: "none",
        headerFilterVisible: false,
        defaultFilterValue: null,
        
        autoWidth: true,
        summary: {
            totalItems: [{
                name: "TotaleFornitori",
                showInColumn: "Ragione Sociale",
                displayFormat: "Totale: {0}",
                summaryType: "count"
            }]
        },
        export: {
            fileName: "anagrafica_fornitori_loc",
            enabled: true
        }
    }
    const columnsFornitori = CreaColonne([
        { caption: "Ragione Sociale", dataField: "ragSociale", sortOrder: "asc" },
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Codice", dataField: "codice" },
        { caption: "Categoria", dataField: "categoria" },
        { caption: "Partita IVA", dataField: "pIva" },
        { caption: "Trattato", dataField: "trattato" },
        { caption: "Cod. Gest.", dataField: "codiceGestionale" },
        { caption: "Indirizzo", dataField: "indirizzo" },
        { caption: "Codice Farmacia", dataField: "codiceFar", visible: false }
    ]);

    //resistuisce il codice della farmacia a partire dall'id -> TODO: potrebbe essere comodo centralizzare la funzione
    const codiceFarm = () => {
        let codiceFar: string;
        if (filtri && filtri.idFar) {
            return codiceFar = props.datiFarmacie.filter((item) => {
                return item.ID_FAR === filtri.idFar
            })[0].CODICE
        }
    }

    //parte la ricerca dopo aver controllato che la farmacia sia stata selezionata
    const startCerca = () => {
        setIsVisible(true);
        if (!filtri || !filtri.idFar) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, 'error', 4000);
        } else {
            RestServices().rest('POST', filtri, urlAnagrafiche.getFornLoc)
                .then(result => {
                    if (result) {
                        setOpen(false);
                        let codiceFar = codiceFarm();
                        result.forEach((item: FornitoriInterface) => {
                            if (codiceFar) {
                                item.codiceFar = codiceFar;
                            }
                        })
                        setFornitori(result);
                    } else {
                        notify({ position: "center", width: "auto", message: "Nessun risultato" }, "error", 4000);
                    }
                }).then(() => { setIsVisible(false) })

        }
    };

    //funzione che definisce quali componenti devono essere integrati all'interno del drawer filtri
    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="3">
                    <label>Farmacia *</label>
                    <SelectFarm datiFarmacie={props.datiFarmacie} id={'idFar'} impostaFiltri={impostaFiltri} value={filtri.idFar} displayExpr={true} showClearButton={true} />
                </Col>
                <Col sm="2">
                    <label>Ragione Sociale</label>
                    <BoxFiltro id="ragSociale" impostaFiltri={impostaFiltri} value={filtri.ragSociale} />
                </Col>
                <Col sm="2">
                    <label>Partita IVA</label>
                    <BoxFiltro id="pIva" impostaFiltri={impostaFiltri} value={filtri.pIva} />
                </Col>
                <Col sm="2" className="checkFornTrattati">
                    <Check impostaFiltri={impostaFiltri} text="Solo Fornitori Trattati" id="trattata" checkValue={filtri.trattata} />
                </Col>
                <Col sm="3">
                    <Button type="button" variant="success" id="btnCercaFornitori" onClick={startCerca}>Cerca</Button>
                    <Button type="button" variant="info" id="btnAnnulla" onClick={() => setFiltri({})}>Annulla</Button>
                </Col>
            </Row>
        )
    }

    return (
        <>
            {loadPanel}
            {titolo}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaAnaFornLocali" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={open} setOpen={setOpen} />
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaCompletaInfoFarmacia
                            propsTabella={propsTabella}
                            searchPanel={true}
                            columns={columnsFornitori}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                           
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AnagraficaFornitoriLocali;