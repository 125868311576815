import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlCommon,urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { AplicaValoreTO } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'

import './style.css';


const columns = [
    { caption: "Numero", dataField: "numeroOrdine",  width: 100 },
    { caption: "Data Emissione", dataField: "dateEmissione", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", width: 160 },
    { caption: "Causale", dataField: "descCasuale", width: 150 },  
    { caption: "Stato Ordine", dataField: "statoOrdine"},
    { caption: "Note", dataField: "note" },
    { caption: "Cat. Fornitore", dataField: "catFornitore", width: 200  },
    { caption: "Cod. Farmacia", dataField: "codFar", width: 200  },
    { caption: "Des. Fornitore", dataField: "descFornitore", width: 300  },
    { caption: "Cod. Est.Fornitore", dataField: "codEstFornitore", width: 200  },
    
];

const columnsOrdini = [
    { caption: "Descrizione Prodotto", dataField: "descProdotto" , width: 350 },
    { caption: "Codice Prodotto", dataField: "codProd", width: 100},
    { caption: "Codice EAN", dataField: "codEan", width: 140},
    { caption: "Quantita", dataField: "quantita" },
    { caption: "Quantita Omaggio", dataField: "quantitaOmaggio" },
    { caption: "Prezzo Listino", dataField: "prezzoListino" ,format: { type: "currency", precision: 2, currency: "EUR" },width: 100 },
    { caption: "Prezzo Deivato", dataField: "prezzoDeivato",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Costo", dataField: "costoListino",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Iva", dataField: "iva",customizeText:(cellInfo:any)=> AplicaValoreTO(cellInfo, '%','iva','d'), width: 90 },
    { caption: "Sc.1 %", dataField: "sconto1" , width: 80},
    { caption: "Sc.2 %", dataField: "sconto2" , width: 80},
    { caption: "Sc.3 %", dataField: "sconto3" , width: 80},
    { caption: "Sc.4 %", dataField: "sconto4" , width: 80},
    { caption: "Sc.5 %", dataField: "sconto5" , width: 80},
    { caption: "Qta arrivo'", dataField: "quantitaArrivo" },
    { caption: "Data Doc.", dataField: "dataDocumento", format: "dd/MM/yyyy", dataType: "date", width: 160 },
    { caption: "Num. Doc.", dataField: "codiceDocumento", width: 100 },
    { caption: "Imponibile", dataField: "totaleImponibile" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Tot. Imposta", dataField: "totaleImposta" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Tot. Lordo", dataField: "totaleLordo" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Costo netto", dataField: "costoNetto" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
   
];


const Fatordini = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idOrdine,setIdOrdine] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [tabellaOrdini,setTabellaOrdini] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [causali,setCausali] = useState([])
    const [newFarmaList,setNewFarmaList] = useState([])

    const auth = useAuth(urlConsultazione.auth);
    

    const itemsFields = [
        {type:'select' , idValue:'idFar' , label:'Farmacie ' ,displayExpr:'descrizione', dataSource : newFarmaList, require:true  ,width:350 },
        {type:'date' , idValue:'dal' , label:'Dal' , dataSource : null, require:true , width:250 },
        {type:'date' , idValue:'al' , label:'Al' , dataSource : null, require:true , width:250 },
        {type:'autocomplete' , idValue:'idFornitore' , label:'Fornitore ' ,typeApi:'fornitori' ,valueExpr:'codice' , apisearch:true, dataSource : {} , width:300 },
        {type:'select' , idValue:'idCausale' , label:'Causale' ,displayExpr:'descrizione', dataSource : causali , width:250  },
        {type:'string' , idValue:'numeroOrdine' , label:'Num. Ordine' , dataSource : null, width:250  },
        {type:'autocomplete' , idValue:'codCsf' , label:'Prodotto' , typeApi:'products' ,valueExpr:'text' ,  apisearch:true, dataSource : {} , width:350},
    ]


    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {
                       //console.log(result.response);
                       setNewFarmaList(result.response)                   
                   }else{                      
                      // console.log(result);                 
                   }
               })     
       }
   }

    
    const getCausaliOrdine = () =>{
        if (auth) {
            RestServices().rest('POST', null , urlCommon.getCausaliOrdine)
                .then(result => {
                    if (result?.esito) {
                       // console.log(result.response);                       
                        setCausali(result.response)
                    }else{
                        //console.log(result);
                    }
                })     
        }
    }

    useEffect(() => {
       
        getCausaliOrdine()
        farmaList()
    }, [auth]);


    const getListaOrdiniAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', { idOrdine  : idOrdine} , urlConsultazione.getOrdine)
                .then(result => {
                    if (result?.esito) {
                       // console.log(result.response);
                                            
                        setTabellaOrdini(result.response?.dettaglio)
                        setLoading(false)
                    }else{
                       // console.log(result);
                        setLoading(false)
                    }
                })
        
    }
    }

    const filterAction = (objFiltro:Filtri) =>{
        let params = {...objFiltro}
        //console.log(params);
        setIdOrdine(null)
        if(params.dal ){
            params.idFar = Number(params.idFar?.idFar)
            params.dal = trasformaDateTimeInLocale(params.dal , true);
            params.al = trasformaDateTimeInLocale(params.al, true);
            if(objFiltro.codCsf){
                params.codCsf = Number(params.codCsf?.id)
            }
            if(objFiltro.idFornitore){
                params.idFornitore = objFiltro.idFornitore.id
            }
            if(objFiltro.idCausale){
                params.idCausale = objFiltro.idCausale.id
            }
            if(objFiltro?.numeroOrdine){
                params.numeroOrdine = objFiltro?.numeroOrdine
            }
            
           // console.log(params);
            
            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListOrdini)
                        .then(result => {
                            if (result?.esito) {
                                setTabellaPrincipale(result.response)
                                setLoading(false)
                            }else{
                                //console.log(result);
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        }
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    useEffect(() => {
        if (idOrdine) {
            getListaOrdiniAction()
        }
    }, [idOrdine]);
   
    return (
        <>
        <Loading isVisible={loading} />
            <div className='title'>
                <h3> ORDINI  <span className='subt' > Consultazione Ordini</span> </h3>
             </div>
             <div style={{width:'250'}}>
            <Ricerca ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  /> </div>

            <NewTabella 
                idTabella='elenco_fat_ordini'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                onclickRow={(datiRow:any)=>setIdOrdine(datiRow.data?.idOrdine)}
                exportT={{
                    enabled: true,
                    fileName: "fat_ordini"
                }}
                activeSaver
                columnC
                summary={
                    <Summary>
                        <TotalItem column="dateEmissione" summaryType="count" />
                    </Summary>}
                
                
            />
            <div id="">
                    {idOrdine ? (
                        <div style={{marginTop:10}}>
                            <h4> PRODOTTI</h4>
                             <NewTabella 
                             idTabella='elenco_ord_prod' 
                             exportT={{enabled: false}}  
                             colonne={columnsOrdini}  
                             dataSource={tabellaOrdini}
                             summary={
                                <Summary>
                                    <TotalItem column="quantita" summaryType="sum"  /> 
                                    <TotalItem column="prezzoListino" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                                    <TotalItem column="totaleImponibile" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                                </Summary>}
                             /> 
                        </div>    
                    )
                   
                    : null }
            </div>
           
        </>
    )
}

export default Fatordini


