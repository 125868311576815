import React, { useState } from 'react'
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { CaricoDettInterface, CostoDettInterface, CostoInterface, GiacenzaDettInterface, GiacenzaDettMagazzInterface, OfferteInterface, PrezziStoriciInterface, PrezzoFarmaciaInterface, PrezzoPromozioneInterface, ProdBDInterface, VenditaDettInterface } from '../../interfaces/bancaDati';
import { ElementOptionChanged } from '../../interfaces/componentOptions';
import { TabBase, TabMovimentazione, TabOfferte, TabPrezzoStorico, TabStorico } from './Tabs';

export const PannelloTab = (props: {
    dettagliProd?: ProdBDInterface,
    imaProd: string,
    giacenzaDett: GiacenzaDettInterface[],
    costoMedioDett: CostoDettInterface[],
    prezzoFarmacia: PrezzoFarmaciaInterface[],
    prezzoPromozione: PrezzoPromozioneInterface[],
    giacenzaDettMagazz: GiacenzaDettMagazzInterface[],
    lottoRiordino: number | undefined,
    ultimoCosto: CostoInterface[],
    offerte: OfferteInterface[],
    prezzoStorico: PrezziStoriciInterface[],
    caricoDett: CaricoDettInterface[],
    venditaDett: VenditaDettInterface[]
}) => {
    //permette di cambiare tab visualizzato a seconda di quale titolo si clicca
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    return (
        <TabPanel
            selectedIndex={selectedIndex}
            loop={false}
            animationEnabled={true}
            onOptionChanged={onOptionChanged}
        >
            <Item title="Info di base">
                <TabBase
                    dettagliProd={props.dettagliProd}
                    imaProd={props.imaProd}
                    giacenzaDett={props.giacenzaDett}
                    costoMedioDett={props.costoMedioDett}
                    prezzoFarmacia={props.prezzoFarmacia}
                    prezzoPromozione={props.prezzoPromozione}
                    giacenzaDettMagazz={props.giacenzaDettMagazz}
                    lottoRiordino={props.lottoRiordino}
                />
            </Item>
            {/* <Item title="Info del farmaco">
                {props.atcHierarchy && <TabFarmaco atcHierarchy={props.atcHierarchy} />}
            </Item> */}
            <Item title="Storico Prezzo Vendita">
                <TabPrezzoStorico prezzoStorico={props.prezzoStorico} />
            </Item>
            <Item title="Storico acquisti">
                <TabStorico ultimoCosto={props.ultimoCosto} />
            </Item>
            <Item title="Listini offerte fornitori">
                <TabOfferte offerte={props.offerte} />
            </Item>
            <Item title="Movimentazione prodotti">
                <TabMovimentazione caricoDett={props.caricoDett} venditaDett={props.venditaDett} />
            </Item>
        </TabPanel>
    )
}