import { cardC, typeShow } from "../components/card_chart";

interface Dato {
  ANNO: number;
  GIORNI_LAVORATI: number;
  COSTO: number;
  MARGINE: number;
  VALORE_VENDUTO_NETTO_IVA: number;
  VOLUME_VENDITA: number;
  VALORE_VENDUTO: number;
  FARM_CODICE: string;
  NUMERO_VENDITE: number;
  FARM_DESCRIZIONE: string;
  VALORE_VENDUTO_EQUIPARATO: number;
}

interface DatoAnalisi {
  ANNO: number;
  IMPONIBILE_SSN_LORDO_SCONTI: number;
  IMPONIBILE_SSN_NETTO_SCONTI: number;
  INCIDENZA_VALORE_VENDUTO: number;
  MARGINE: number;
  PRO_COD_GRUPPO_MERCEOLOGICO1: string;
  PRO_DESC_GRUPPO_MERCEOLOGICO1: number;
  VALORE_VENDUTO: number;
  VOLUME_VENDITA: number;
}

interface DatiFiltrati {
  FARM_CODICE: string;
  GIORNI_LAVORATI?: string;
  GIORNI_LAVORATIAP?: string;
  VALORE_VENDUTO?: string;
  VALORE_VENDUTO_SSN?: string;
  VALORE_VENDUTO_LIBERA?: string;
  VALORE_VENDUTOAP?: string;
  VALORE_VENDUTO_EQUIPARATO?: string;
  ALORE_VENDUTO_NETTO_IVA?: string;
  ALORE_VENDUTO_NETTO_IVAAP?: string;
  MARGINE?: string;
  MARGINEAP?: string;
  MARGINE_EQUIPARATO?: string;
  MARGINE_PERCENTUALE?: string;
  MARGINE_PERCENTUALEAP?: string;
  NUMERO_VENDITE?: string;
  NUMERO_VENDITEAP?: string;
  VOLUME_VENDITA?: string;
  VOLUME_VENDITAAP?: string;
  VALORE_VENDUTO_EQUIPARATOAP?: string;
  NUMERO_VENDITE_SCONTO?:string;
  NUMERO_VENDITE_SCONTOAP?:string;
  NUMERO_VENDITE_SCONTO_PUNTUALEAP?:string;
  VALORE_SCONTO?:string;
  VALORE_SCONTOAP?:string;
  PERC_SCONTOAP?:string;
  PERC_SCONTO?:string;
  PERC_SCONTO_PUNTUALE?:string;
  PERC_SCONTO_PUNTUALEAP?:string;
  VALORE_SCONTO_PUNTUALE?:string;
  VALORE_SCONTO_PUNTUALEAP?:string;
  PERC_VENDITE_SCONTO?:string;
  PERC_VENDITE_SCONTOAP?:string;

}

// dati tabella per farmacia

export const CruscottoGruppo_traform_2W = (obj: any, selectedYear: number) => {
    const datiFiltrati: { [key: string]: { ap?: any; cr?: any } } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const farmCodice = dato.FARM_CODICE;
        if (!datiFiltrati[farmCodice]) {
            datiFiltrati[farmCodice] = {};
        }



        if (Number(dato.ANNO) === selectedYear - 1) {
 
            datiFiltrati[farmCodice].ap = dato;
        } else {
            //(Number(dato.ANNO) === selectedYear) {
 
            datiFiltrati[farmCodice].cr = dato;
        }
    });


    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((farmCodice) => ({
        FARM_CODICE: farmCodice,
        Dati: datiFiltrati[farmCodice],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};





export const NewCruscottoGruppo_traform_2W = (obj: any) => {
    const datiFiltrati: { [key: string]: { ap?: Dato; cr?: Dato } } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: Dato) => {
        const farmCodice = dato.FARM_CODICE;
        if (!datiFiltrati[farmCodice]) {
            datiFiltrati[farmCodice] = {};
        }

        if (!datiFiltrati[farmCodice].ap) {
            datiFiltrati[farmCodice].ap = dato;
        } else {
            datiFiltrati[farmCodice].cr = dato;
        }
    });

    console.log("arriva questo");
    //const arrayDatiFiltrati = Object.values(datiFiltrati).filter((item) => item.ap && item.cr);

    //const arrayDatiFiltrati = Object.values(datiFiltrati).map((item) => item);

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((farmCodice) => ({
        FARM_CODICE: farmCodice,
        Dati: datiFiltrati[farmCodice],
    }));

    //console.log('tabella transformation');
    //console.log(arrayDatiFiltrati);

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        Object.keys(ap).map((apDato: any) => {
            let keyr = apDato + "AP";
            datiFiltratiNewElemento[keyr] = ap[apDato];
        });

        if (cr && ap) {
            // Trova l'anno massimo e minimo tra "AP" e "CR"
            const annoMaxAP = Math.max(ap.ANNO || 0, cr.ANNO || 0);
            const annoMinAP = Math.min(ap.ANNO || 0, cr.ANNO || 0);

            // Assegna i valori in base all'anno maggiore o minore
            Object.keys(cr).map((crDato: any) => {
                let keyr = crDato + (cr.ANNO === annoMaxAP ? "CR" : "PR");
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });

            Object.keys(ap).map((apDato: any) => {
                let keyr = apDato + (ap.ANNO === annoMinAP ? "PR" : "CR");
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });


    //console.log("_____ dato filter");

    //console.log(datiFiltratiNew);

    // console.log(arrayDatiFiltrati);

    return datiFiltratiNew;
};



// dati tabella analisi per gruppo merceologico

export const CruscottoGruppo_traform_analisi = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const gruppoMerceologico = dato.PRO_DESC_GRUPPO_MERCEOLOGICO1;
        if (!datiFiltrati[gruppoMerceologico]) {
            datiFiltrati[gruppoMerceologico] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[gruppoMerceologico].ap = dato;
        } else {
          
            datiFiltrati[gruppoMerceologico].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((gruppoMerceologico) => ({
        PRO_DESC_GRUPPO_MERCEOLOGICO1: gruppoMerceologico,
        Dati: datiFiltrati[gruppoMerceologico],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};


//dati category
export const transformCategory = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const category = dato.PRO_DESC_CATEGORY1; 
        if (!datiFiltrati[category]) {
            datiFiltrati[category] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[category].ap = dato;
        } else {
            datiFiltrati[category].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((category) => ({
        PRO_DESC_CATEGORY1: category,
        Dati: datiFiltrati[category],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};

export const transformCategory3 = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const category = dato.PRO_DESC_CATEGORY3;
        if (!datiFiltrati[category]) {
            datiFiltrati[category] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[category].ap = dato;
        } else {
            datiFiltrati[category].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((category) => ({
        PRO_DESC_CATEGORY3: category,
        Dati: datiFiltrati[category],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};

export const transformFornitore = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const fornitore = dato.ANA_RAGIONE_SOCIALE;
        if (!datiFiltrati[fornitore]) {
            datiFiltrati[fornitore] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[fornitore].ap = dato;
        } else {
            datiFiltrati[fornitore].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((fornitore) => ({
        ANA_RAGIONE_SOCIALE: fornitore,
        Dati: datiFiltrati[fornitore],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};


export const transformDitta = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const ditta = dato.PRO_DESC_DITTA_TITOLARE;
        if (!datiFiltrati[ditta]) {
            datiFiltrati[ditta] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[ditta].ap = dato;
        } else {
            datiFiltrati[ditta].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((ditta) => ({
        PRO_DESC_DITTA_TITOLARE: ditta,
        Dati: datiFiltrati[ditta],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};

export const transformProdotto = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const prodotto = dato.FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1;
        if (!datiFiltrati[prodotto]) {
            datiFiltrati[prodotto] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[prodotto].ap = dato;
        } else {
            datiFiltrati[prodotto].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((prodotto) => ({
        FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1: prodotto,
        Dati: datiFiltrati[prodotto],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};

export const transformSettore = (obj: any, selectedYear: number) => {
    const datiFiltrati: {
        [key: string]: { ap?: any; cr?: any };
    } = {};
    let datiFiltratiNew: { [key: string]: any }[] = [];

    obj.forEach((dato: any) => {
        const settore = dato.PRO_DESC_SETTORE_INVENTARIO;
        if (!datiFiltrati[settore]) {
            datiFiltrati[settore] = {};
        }

        if (dato.ANNO === selectedYear - 1) {
            datiFiltrati[settore].ap = dato;
        } else {
            datiFiltrati[settore].cr = dato;
        }
    });

    const arrayDatiFiltrati = Object.keys(datiFiltrati).map((settore) => ({
        PRO_DESC_SETTORE_INVENTARIO: settore,
        Dati: datiFiltrati[settore],
    }));

    arrayDatiFiltrati.forEach((dat: any) => {
        const datiFiltratiNewElemento: { [key: string]: any } = {};

        let ap = dat.Dati.ap;
        let cr = dat.Dati.cr;

        if (ap) {
            Object.keys(ap).forEach((apDato: any) => {
                let keyr = apDato + "AP";
                datiFiltratiNewElemento[keyr] = ap[apDato];
            });
        }

        if (cr) {
            Object.keys(cr).forEach((crDato: any) => {
                let keyr = crDato + "CR";
                datiFiltratiNewElemento[keyr] = cr[crDato];
            });
        }

        datiFiltratiNew.push(datiFiltratiNewElemento);
    });

    return datiFiltratiNew;
};





// dati trasformati per cards

export const trasform_for_cards_charts_01 = (
  data: any[],
  arrayStructure: cardC[]
) => {
  let datoNew: { [key: string]: any } = {};
  let arraysCardFormated: cardC[] = [];
  const minIndex = data.reduce((minIndex, currentItem, currentIndex) => {
    if (currentItem.ANNO < data[minIndex].ANNO) {
      return currentIndex;
    }
    return minIndex;
  }, 0);

  

  data.forEach((anno, index) => {
    const isMinAnno: boolean = minIndex == index ? true : false;
    if (isMinAnno) {
      const chiavi = Object.keys(anno);

        chiavi.forEach((chiave: any) => {  
           const valore = anno[chiave];
           const chiaveNew = chiave + "PR";
           datoNew[chiaveNew] = valore;
      });
    } else {
      const chiavi = Object.keys(anno);
      chiavi.forEach((chiave: any) => {
        const valore = anno[chiave];
        const chiaveNew = chiave + "SL";
        datoNew[chiaveNew] = valore;
      });
    }
  });


  arrayStructure.forEach((element: any) => {
    const chiavi = Object.keys(element);
    const newObj: cardC = {};

    
    let valueToUse = datoNew[element.valore];
    let subValueToUse = datoNew[element.subValore];

   
    switch (element.typeShowValue) {
      case typeShow.euro:
        valueToUse = `€ ${valueToUse?.toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;

        break;
      case typeShow.percento:
        valueToUse = valueToUse?.toFixed(2) + "%";
        if (subValueToUse) {
          
         
          
          subValueToUse = subValueToUse;
        }
        break;
      default:
        valueToUse = valueToUse?.toLocaleString("it-IT", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        break;
    }

    switch (element.typeShowSubValue) {
      case typeShow.euro:
        if (subValueToUse) {
          subValueToUse = `€ ${subValueToUse?.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }

        break;
      case typeShow.percento:
        if (subValueToUse) {
          subValueToUse = subValueToUse?.toFixed(2) + "%";
        }
        break;
      default:
        if (subValueToUse) {
          subValueToUse = subValueToUse?.toLocaleString("it-IT", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        }

        break;
    }

    chiavi.forEach((chiave: string) => {
      //console.log(chiave);
      //console.log(element[chiave]);
      newObj.testo = element.testo;
      newObj.subTesto = element.subTesto;
      newObj.valore = valueToUse;
      newObj.subValore = subValueToUse;
      newObj.starter = true;
      newObj.testoPop = element.testoPop;
    });

    arraysCardFormated.push(newObj);
  });

  //console.log('nuovo che arriva dalla tarsformazione');
  // console.log(datoNew);

  return arraysCardFormated;
};




export const trasform_no_marge = (data: any, arrayStructure: cardC[]) => {
    let arraysCardFormated: cardC[] = [];

    if (data === undefined) {
        data = [];
    }
  arrayStructure.forEach((element: any) => {
    const chiavi = Object.keys(element);
    const newObj: cardC = {};

    let valueToUse = data[element.valore];
    let subValueToUse = data[element.subValore];

    switch (element.typeShowValue) {
      case typeShow.euro:
        valueToUse = `€ ${valueToUse?.toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;

        break;
      case typeShow.percento:
        valueToUse = valueToUse?.toFixed(2) + "%";
        if (subValueToUse) {
          subValueToUse = subValueToUse?.toFixed(2) + "%";
        }
        break;
      default:
        valueToUse = valueToUse?.toLocaleString("it-IT", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        });

        break;
    }

    switch (element.typeShowSubValue) {
      case typeShow.euro:
        if (subValueToUse) {
          subValueToUse = `€ ${subValueToUse?.toLocaleString("it-IT", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`;
        }
        break;
      case typeShow.percento:
        if (subValueToUse) {
          subValueToUse = subValueToUse?.toFixed(2) + "%";
        }
        break;
      default:
        if (subValueToUse) {
          subValueToUse = subValueToUse?.toLocaleString("it-IT", {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          });
        }

        break;
    }

    chiavi.forEach((chiave: string) => {
      // console.log(chiave);
      //  console.log(element[chiave]);
      newObj.testo = element.testo;
      newObj.subTesto = element.subTesto;
      newObj.valore = valueToUse;
      newObj.subValore = subValueToUse;
      newObj.starter = true;
      newObj.testoPop = element.testoPop;
    });

    arraysCardFormated.push(newObj);
  });

  return arraysCardFormated;
};
