import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import TextBox from 'devextreme-react/text-box'
import { RestServices } from "../../../../services/restServices"
import { urlFidelityBe } from "../../../costanti"
import { useAuth } from '../../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'

interface DialogProps {
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
}

export const DialogNuovoFascia: React.FC<DialogProps> = ({ open, close, ricarica, edit, typeEdit }) => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD, setOpenD] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(true)


    const auth = useAuth(urlFidelityBe.auth);


    useEffect(() => {
        if (edit) {
            let objTo = { ...objctTOsave }
            objTo = edit
            setObjctTOsave(objTo)
        } else {
            setObjctTOsave({})
        }

    }, [edit])


    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value
       
        setObjctTOsave(objTo)
        //console.log(objTo);  

        if (objTo.punti && objTo.valore && objTo.giorniDurataValidita && objTo.spesaMinima) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }


    const creaNuovoFascia = (modifica?: any) => {
        RestServices().rest('POST', objctTOsave, urlFidelityBe.insertFasciaFidelity)
           .then(result => {
               if (result.esito) {
                   ricarica()
                   setObjctTOsave(null)
               } else {
                   notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
               }
           })
    }

    useEffect(() => {
     
        setOpenD(open)
        ricarica()
    }, [auth])


    useEffect(() => {
        setOpenD(open)
    }, [open])



    return (
        <>
            <Popup
                visible={openD}
                showCloseButton={false}
                width={'60%'}
                height={'60%'}
                showTitle={true}
                title="Nuovo Fascia"
            >

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Chiudi',
                        onClick: () => close(),
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={typeEdit ? {
                        text: 'CREA',
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        disabled: false,
                        onClick: () => creaNuovoFascia(true),
                    } : {
                        text: 'CREA',
                        class: 'btncreatipocluster',
                        disabled: disableButton,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => creaNuovoFascia(),
                    }}
                />

                {typeEdit ? (
                    <div>
                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> PUNTI INIZIALE </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.punti} onValueChanged={(v) => cambiaValore('punti', v.value)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label> VALORE </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.valore} onValueChanged={(v) => cambiaValore('valore', v.value)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label> PERCENTUALE </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.percentuale} onValueChanged={(v) => cambiaValore('percentuale', v.value)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label> DURATA VALIDITA DALLA CREAZIONE (GIORNI) </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.giorniDurataValidita} onValueChanged={(v) => cambiaValore('giorniDurataValidita', v.value)} />
                            </div>
                        </div>
                        <div className="form-group col-md-6" >
                            <label> SPESA MINIMA PER SPENDERE IL COUPON  </label>
                            <br></br>
                            <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.spesaMinima} onValueChanged={(v) => cambiaValore('spesaMinima', v.value)} placeholder="�" />

                        </div>
                        <div className="form-group col-md-6" >
                            <label> DESCRIZIONE  </label>
                            <br></br>
                            <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.descrizione} onValueChanged={(v) => cambiaValore('descrizione', v.value)} />

                        </div>
                        <div>
                        </div>
                    </div>
                ) : (
                    <div>

                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> PUNTI INIZIALE </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.punti : ''} onValueChanged={(v) => cambiaValore('punti', v.value)} />
                            </div>
                            <div className="form-group col-md-6">
                                <label> VALORE </label>
                                    <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.valore : ''} onValueChanged={(v) => cambiaValore('valore', v.value)} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label> PERCENTUALE </label>
                                    <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.percentuale : ''} onValueChanged={(v) => cambiaValore('percentuale', v.value)} />
                                </div>

                                <div className="form-group col-md-6">
                                    <label> DURATA VALIDITA DALLA CREAZIONE (GIORNI) </label>
                                    <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.giorniDurataValidita : ''} onValueChanged={(v) => cambiaValore('giorniDurataValidita', v.value)} />
                                </div>
                        

                                <div className="form-group col-md-6" >
                            <label> SPESA MINIMA PER SPENDERE IL COUPON  </label>
                                <br></br>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.spesaMinima : ''} onValueChanged={(v) => cambiaValore('spesaMinima', v.value)} />

                            </div>
                                <div className="form-group col-md-6" >
                                <label> DESCRIZIONE </label>
                                <br></br>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.descrizione : ''} onValueChanged={(v) => cambiaValore('descrizione', v.value)} />
                                </div>
                            </div>
                    </div>
                )}

            </Popup>
        </>
    )


}
