import { AplicaValoreTO } from "../../../../funzioni/operazioniSuTabelle";

export  const colAnalisimagazzino = [
    {
      caption: "Tipologia prodotto ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % valore venduto Ivato",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto Ivato periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto ivato equiparato",
      dataField: "",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezione",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine % periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume vendita periodo selezione",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
     
    },
    {
      caption: "Volume vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "80px",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "80px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const colAnalisimagazzino2 = [
    {
      caption: "Tipologia prodotto ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % valore venduto Ivato",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto Ivato periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto ivato equiparato",
      dataField: "",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezione",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine % periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume vendita periodo selezione",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
     
    },
    {
      caption: "Volume vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "80px",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "80px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const colOsserMargini = [
    {
      caption: "Farmacia ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Margini",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % margine",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine %",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    }, 
    {
      caption: "Sconto percentuale sul fatturato",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
   
  ];


  export  const colOsserMargini2 = [
    {
      caption: "Mese ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Numero giorni lavorati",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
    },
    {
      caption: "Valore del venduto ",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta equiparato imponibile sul mese prec.",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta equiparato margine sul mese prec.",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale sul mese prec.",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Pezzi venduti",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta equiparato pezzi venduti sul mese prec.",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Costo medio pezzo",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta costo medio pezzo sul mese prec.",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore medio pezzo",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore medio pezzo sul mese prec.",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];