import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie"
import React, { useEffect, useState } from 'react'
import { urlConsultazione, urlGestioneUdi, urlGestioneUdiV2 } from "../costanti";
import { RestServices } from "../../services/restServices";
import { Filtri } from "../consultazione/interface";
import notify from "devextreme/ui/notify";
import { Loading } from "../../widget/Notifications";
import Ricerca from "../../widget/nuova_ricerca"
import { NewTabella } from '../../widget/nuova_tabella/newVersionCgm';
import { confirm } from 'devextreme/ui/dialog';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropDownButton from "devextreme-react/drop-down-button";
import './style.css';
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import DevExpress from "devextreme";
import { Workbook } from "exceljs";
import { exportDataGrid } from "devextreme/excel_exporter";
import saveAs from "file-saver";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";
import { Dropdown, DropdownButton } from "react-bootstrap";
import dropdown from '../../immagini/dropdown_white.png';
import { trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili"

const ElencoUdi = (props: { datiFarmacie: ListaFarmacieInterface[], authUdi: boolean| undefined, authCommon: boolean|undefined }) => {

    //const auth = useAuth(urlGestioneUdi.auth);

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState<any>([])
    const [paramsFilter, setParamsFilter] = useState<any>()
    const [numeroRegistrazioni, setNumeroRegistrazioni] = useState<any>()
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    useEffect(() => {
        if (props.authUdi && props.authCommon)
            farmaList()

        filterAction({
            dal: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'),
            al: new Date(),
            idFar: newFarmaList.length == 1 ? newFarmaList[0].idFarmacia : undefined
        })

        //setTabellaPrincipale(dataSourceTest)

    }, [props.authUdi, props.authCommon]);

    const columns = [

        { caption: "Id", dataField: "id", visible: false },
        { caption: "Id Farmacia", dataField: "idFarmacia", visible: false },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "date", width: 120, sortOrder: "desc" },
        //{ caption: "Farmacia", dataField: "farmacia" },
        { caption: "Cod.Csf", dataField: "codCsf", visible: false },
        { caption: "Cod.Minsan", dataField: "codProd" },
        { caption: "Cod.EAN", dataField: "codEan" },
        //{ caption: "Prodotto", dataField: "prodotto" },
        //{ caption: "Ditta titolare", dataField: "ditta" },
        {
            caption: "Prodotto / Ditta", dataField: 'prodottoDitta', dataType: 'string'
        },
        { caption: "Cod.UDI", dataField: "codiceUdi" },
        { caption: "Tipo movimento", dataField: "tipoMovimento" },
        {
            caption: "", type: "buttons", buttons: [
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {

                        let rc = confirm("<i>Eliminare il movimento per il prodotto " + e.row.data.prodotto + "  ?</i > ", "Attenzione");
                        rc.then((dialogResult) => {
                            if (dialogResult) {
                                deleteMovimento(e.row.data.id)
                            }
                        })
                    },
                }
            ]
        }


    ]

    const farmaList = () => {
        if (props.authCommon) {
            RestServices().rest('POST', {}, urlConsultazione.getListFarSystem)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {

                    }
                })
        }
    }

    const filterAction = (objFiltro: Filtri) => {
        setLoading(true)
        let params = { ...objFiltro }

        //console.log(params)

        if (params.idFar) {

            params.idFar = params.idFar.idFar
        }

        setParamsFilter(params)
        getList(params)

    }

    function formatDateManually(date: Date): string {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
    }

    const getList = (par: any) => {
        
        let p = { ...par }

        
        p.dal = par.dal.setTime(par.dal.getTime() + (2 * 60 * 60 * 1000)); 
        p.al = par.al.setTime(par.al.getTime() + (2 * 60 * 60 * 1000)); 
        

        setLoading(true)
        if (props.authUdi) {
            RestServices().rest('POST', par, urlGestioneUdiV2.getList)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setTabellaPrincipale(result.response)
                            setNumeroRegistrazioni(result.response.length)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const deleteMovimento = (id: number) => {

        setLoading(true)
        if (props.authUdi) {
            let param = { id: id }
            RestServices().rest('POST', param, urlGestioneUdiV2.delete)
                .then(result => {
                    if (result?.esito) {
                        if (result.response.esito) {
                            setLoading(false)
                            getList(paramsFilter)
                        }
                        else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
            newArr.push({ value: element.idFar, label: element.descrizione })

        });
        return newArr
    }

    const onItemClick = (e: any) => {


        if (e.itemData === 'Excel') {
            //esportazione excel
            const workbook = new Workbook();
            const worksheet = workbook.addWorksheet('registro_udi');
            exportDataGrid({
                component: tabellaInstance.instance,
                worksheet: worksheet
            }).then(function () {
                workbook.xlsx.writeBuffer().then(function (buffer) {
                    saveAs(new Blob([buffer], { type: "application/octet-stream" }), "registro_udi.xlsx");
                });
            });
        }

        if (e.itemData === 'Pdf') {

            const doc = new jsPDF('l', 'mm', 'a4');

            const head = [['Data', 'Cod.Minsan', 'Cod.EAN', 'Prodotto/Ditta', 'Cod.UDI', 'Tipo movimento']]

            let data: any[] = [];
            
            let resultFiltered: any[] = []

            var filterExpression = tabellaInstance.instance.getCombinedFilter();  

            let listSort: any[]
            listSort = []
            var columns = tabellaInstance.instance.state().columns;
            let index = 0;
            columns.forEach((col: any) => {
                
                if (col.sortOrder != null && col.visible == true) {
                    let s: any
                    s = {}
                    s.id = col.dataField
                    s.sortOrder = col.sortOrder
                    s.index = index
                    listSort.push(s)
                    
                }

                if (col.visible == true) {
                    index++
                }
                
            });

            let sortProperty: string = listSort[0].id
            let sortOrder: string = listSort[0].sortOrder
            let sortIndex: number = listSort[0].index

            tabellaInstance.instance
                .getDataSource()
                .store()
                .load({ filter: filterExpression })
                .then((result: any) => {
                    resultFiltered = result
                });

            


            resultFiltered.forEach((item: any) => {
                let obj = {}
                obj = {
                    data: item.data,
                    codProd: item.codProd,
                    codEan: item.codEan,
                    prodottoDitta: item.prodottoDitta,
                    codiceUdi: item.codiceUdi,
                    tipoMovimento: item.tipoMovimento
                }

                data.push([item.data, item.codProd, item.codEan, item.prodottoDitta, item.codiceUdi, item.tipoMovimento])
            })

            data.sort((a: any, b: any) => {

                if (a[sortIndex] < b[sortIndex]) {
                    return sortOrder == 'asc'  ? -1 : 1;
                }
                if (a[sortIndex] > b[sortIndex]) {
                    return sortOrder == 'asc' ? 1 : -1;
                }

                return 0;
            });

            autoTable(doc, {
                head: head,
                body: data,
                didDrawCell: (data:any) => {},
            });

            // Footer i.e Page 1 of 4 
            // Get the number of pages
            const pageCount = (doc as any).internal.getNumberOfPages(); //was doc.internal.getNumberOfPages(); 
            // For each page, print the page number and the total pages
            for (let i = 1; i <= pageCount; i++) {
                doc.setFontSize(10);
                // Go to page i
                doc.setPage(i);
                var pageSize = doc.internal.pageSize;
                var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
                //footer
                doc.text('Pagina ' + String(i) + ' di ' + String(pageCount), doc.internal.pageSize.getWidth() / 2, pageHeight - 8); //data.settings.margin.left if you want it on the left
                //header
                doc.text('Report registro UDI  dal ' + cambiaFormatoLocalDateTimeToEuropeo(paramsFilter.dal) + ' al ' + cambiaFormatoLocalDateTimeToEuropeo(paramsFilter.al), 10, 5, { baseline: 'top' }); //data.settings.margin.left if you want it on the left
            }

            doc.save('registro_udi.pdf');

        }

    }


    const onToolbarFilterDate = (e: any) => {
        //console.log(e)
        let params = { ...paramsFilter }
        params.dal = e.dal
        params.al = e.al

        setParamsFilter(params)

        getList(params)
    }

    const onCellPrepared = (e: any) => {

        if (e.columnIndex == 3 && e.rowType == "data") {

            e.cellElement.innerHTML = "<table><tr><td>" + e.row.data.prodotto + "</td></tr><tr><td style='font-size: 16px; color:#6F7174'>" + e.row.data.ditta + "</td></tr></table>"
        }

        
    }

    return (
        <>
            <Loading isVisible={loading} />
            &nbsp;
            &nbsp;
            <Row style={{paddingLeft:24} }>
                <Col style={{ marginTop: 12}}>
                    <div style={{marginBottom:8} }>
                        <span style={{ color: "white", fontSize: 14, marginLeft: 20, fontWeight:400 }}> Registrazioni </span>
                        
                    </div>
                    <div>
                        <span style={{ color: "white", fontSize: 18, marginLeft: 20, fontWeight: 700, marginTop:16 }}> {numeroRegistrazioni} </span>
                    </div>
                </Col>
                <Col style={{ marginRight: 20 }}>

                    <Dropdown>
                        <Dropdown.Toggle  id="btnActions">
                            AZIONI
                            <img
                                src={dropdown}
                                className="custom-icon"
                                
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item style={{ fontSize:16, padding:16}} onClick={() => { let e: { itemData: string } = { itemData: 'Excel' }; onItemClick(e) }} >Excel</Dropdown.Item>
                            <Dropdown.Item style={{ fontSize: 16, padding: 16 }}  onClick={() => { let e: { itemData: string } = { itemData: 'Pdf' }; onItemClick(e) }} >Pdf</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                </Col>
            </Row>
            <Row>
            </Row>
            &nbsp;
            &nbsp;


                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <NewTabella
                        idTabella='elencoUdi'
                        sync
                        colonne={columns}
                        dataSource={tabellaPrincipale}
                        searchPanel={true}
                        onclickRow={() => null}
                        exportT={{
                            enabled: false,
                            fileName: "elencoUdi"
                        }}
                        activeSaver={true}
                        columnC={false}
                        rowAlternation={false}
                        searchPosition="before"
                        toolbar={true}
                        onlySearch={true}
                        searchPanelWidth={350}
                        allowToolbarDateFilter={true}
                        onToolbarFilterDate={(e) => { onToolbarFilterDate(e) }}
                        showColumnLines={false}
                        onCellPrepared={onCellPrepared}
                        tabReference={setTabellaInstance}
                    />
                </div>
            
            
        </>
    )

}

export default ElencoUdi