import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { cambiaFormatoLocalDateTimeToEuropeo } from '../../funzioni/formatoVariabili';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';
import { OggettoInterface } from '../../interfaces/generiche';
import { GetParametersInterface, ProprietaTabellaReportInterface } from '../../interfaces/report';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { DrawerFiltri } from '../../widget/Drawer';
import { Loading } from '../../widget/Notifications';
import { TabellaCompleta, TabellaCompletaMasterDetail } from '../../widget/Tabelle';
import { basenameHost, urlReport } from '../costanti';
import DialogParamsReport from './DialogParams';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const PaginaReport = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean
}) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);
    const auth = useAuth(urlReport.auth);
    
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [parametri, setParametri] = useState<GetParametersInterface>();
    const titolo = TitoloContenuto(parametri != undefined ? parametri?.titolo : "Report", parametri != undefined ? parametri?.descrizione : "");
    const [dataSourceSelect, setDataSourceSelect] = useState<OggettoInterface>({});
    const [dataSourceSelectTemp, setDataSourceSelectTemp] = useState<OggettoInterface>({});
    const [proprietaTabReport, setProprietaTabReport] = useState<ProprietaTabellaReportInterface>();
    const [report, setReport] = useState<any[]>();
    const [filtriUsati, setFiltriUsati] = useState<OggettoInterface>({});

    const [showModalParam, setShowModalParam] = useState<boolean>(false);
    const [open, setOpen] = useState(true);

    const urlParameter = window.location.href.split("?")[1];
    const codice = urlParameter.split("&")[0];
    const provenienza = urlParameter.split("&")[1];

    const handleClose = () => {
        setShowModalParam(false);
        //console.log(provenienza)
        if (provenienza === "true") {
            window.close();
        }
    }
    const handleShow = () => setShowModalParam(true);

    useEffect(() => {
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', codice, urlReport.reportParameters)
                .then(result => {
                    if (result) {
                        //console.log(result);
                        setIsVisible(false);
                        setParametri(result);
                        handleShow();
                    }
                })
        }
    }, [auth]);

    function sleep(time: any) {
        return new Promise((resolve) => setTimeout(resolve, time)
        )
    }

    useEffect(() => {
        const newDataSource: OggettoInterface = {};
        parametri?.params.forEach((parametro) => {
            if (parametro.endPoint) {
                
                RestServices().rest("POST", "", basenameHost + parametro.endPoint)
                    .then(result => {
                        if (result) {
                            newDataSource[parametro.codice] = result;
                            setDataSourceSelectTemp(newDataSource);
                        }              
                    })
            }
        })

        //setDataSourceSelect(dataSourceSelectTemp);

    }, [parametri])

    useEffect(() => {
        
        setDataSourceSelect(dataSourceSelectTemp);

    }, [dataSourceSelectTemp])

    const [tabellaInstance, setTabellaInstance] = useState();

    const propsTabellaReport = {
        id: `report_${codice}`,
        autoWidth: true,
        dataSource: report,
        selection: "none",
        headerFilterVisible: true,
        defaultFilterValue: null,
        export: { enabled: true, fileName: parametri?.titolo },
        summary: proprietaTabReport?.summary
    }
    const columnsReport = CreaColonne(proprietaTabReport?.columns);
    const columnsReportDetail = CreaColonne(proprietaTabReport?.columnsDetail)

    const onExporting = (e: any) => {
        var masterDetails: any[] = [];
        var workbook = new Workbook();
        var worksheet = workbook.addWorksheet('Employees');

        masterDetails = [];
        let masterRows: any[] = [];

        const topLeftCell = { row: 2, column: 2 };

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            topLeftCell: topLeftCell,
            customizeCell: function ({ gridCell, excelCell }) {
                if (gridCell) {
                    if (gridCell.column) {
                        if (gridCell.column.dataField === 'CODICE_FISCALE' && gridCell.rowType === 'data') {
                            masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
                        }
                    }
                }
            }
        }).then((cellRange) => {

            const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            let offset = 0;

            const insertRow = (index: any, offset: number, outlineLevel: number | undefined) => {
                const currentIndex = index + offset;
                const row = worksheet.insertRow(currentIndex, [], 'n');

                for (var j = worksheet.rowCount + 1; j > currentIndex; j--) {
                    worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
                }

                row.outlineLevel = outlineLevel;

                return row;
            }

            for (var i = 0; i < masterRows.length; i++) {
                let row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                let columnIndex = 0;
                if (cellRange)
                    if (cellRange.from)
                        if (cellRange.from.column)
                            columnIndex = cellRange.from.column + 1;

                if (report) {
                    let reportData = report.find((item: { ID: any; }) => item.ID === masterRows[i].data.ID);
                    const columns = ["COD_PROD", "PRODOTTO", "QUANTITA", "PREZZO"];
                    var columnMap = new Map();
                    columnMap.set("COD_PROD", "Cod. Prod");
                    columnMap.set("PRODOTTO", "Prodotto");
                    columnMap.set("QUANTITA", "Quantita");
                    columnMap.set("PREZZO", "Prezzo");

                    columns.forEach((columnName, currentColumnIndex) => {
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                            value: columnMap.get(columnName),
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E9E9E9' } },
                            font: { bold: true },
                            border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle }
                        });
                    });

                    let tmp = getReportDetail(reportData.ID);
                    if (tmp)
                    tmp.forEach((task: { [x: string]: any; }, index: any) => {
                        row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                        columns.forEach((columnName, currentColumnIndex) => {
                            Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                                value: task[columnName],
                                fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E9E9E9' } },
                                border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle }
                            });
                        });
                    });
                    row.height = 20;
                    row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                }
                
                offset--;

            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), parametri?.titolo + '.xlsx');
            });
        });
    }

    const getReportDetail = (id: any) => report?.filter((detail: { ID: any; }) => detail.ID === id)[0].detail;
    let tabella;
    if (report && parametri) {
        //console.log(parametri.titolo.indexOf("Dettaglio"));
        if (!parametri.isMasterDetail)
        {
            tabella = <TabellaCompleta propsTabella={propsTabellaReport} columns={columnsReport} setTabellaInstance={setTabellaInstance} />
        }else
        {
            tabella = <TabellaCompletaMasterDetail propsTabella={propsTabellaReport} columns={columnsReport} columnsDetail={columnsReportDetail} setTabellaInstance={setTabellaInstance} onExporting={onExporting} />
        }
    }
        
    

    return (
        <>
            {titolo}
            {loadPanel}
            {parametri &&
                <>
                    <div className="contenutoPagina">
                        {/* <DrawerFiltri templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={open} setOpen={setOpen} /> */}
                        <Row>
                        <Col sm='12' className="elencoReport">
                            {tabella}
                            </Col>
                        </Row>
                    </div>
                    <DialogParamsReport
                        showModal={showModalParam}
                        setShowModalParam={setShowModalParam}
                        handleClose={handleClose}
                        dataSourceSelect={dataSourceSelect}
                        titoloReport={parametri.titolo}
                        parametri={parametri}
                        codiceReport={codice}
                        setParametri={setParametri}
                        setReport={setReport}
                        setIsVisible={setIsVisible}
                        setFiltriUsati={setFiltriUsati}
                        setProprietaTabReport={setProprietaTabReport}
                    />
                </>
            }
        </>
    )
}

export default PaginaReport;