import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { FrameWrapper } from "../../widget/FrameWrapper";
import { urlCommon } from "../costanti";
import './style.css';

interface PageProps {
    setIndietro: () => void;
}

export const GuidaInstallazioneRevisione: React.FC<PageProps> = ({ setIndietro }) => {

    const auth = useAuth(urlCommon.auth);

    const tornaInCima = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const showIndex = () => {

        window.location.href = ('/app/DocumentazioniRevisioni/Documentazione')
    };



    useEffect(() => {
    }, [auth]);


    return (
        <>
            <br></br>

            <div style={{ textAlign: 'center', marginBottom: '5%', marginTop: '2%' }}>
                <h2><b>Guida all'installazione</b></h2>
            </div>


            <section style={{
                width: '80%', margin: '0 auto',
            }}>

                <div style={{ display: "flex", marginBottom: 30 }}>
                    <div style={{}}>
                        <Button /*className="custom-button"*/ variant="primary" onClick={() => { showIndex(); }}><b>Vai all'elenco delle Revisioni</b></Button>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                        <Button /*className="custom-button"*/ variant="success" onClick={() => { setIndietro(); }}><b>Torna Indietro</b></Button>
                    </div>
                </div>

                <div>
                        <div style={{ padding: 25, }}>
                            <div style={{ gap: 10, background: '#ffffff', padding: 25, borderRadius: 10, marginTop: 10 }}>
                                <FrameWrapper html={`https://news.csfsistemi.it/archives/395`} />
                            </div>
                        </div>

                    <div style={{ display: 'flex', justifyContent: 'center', }}>
                        <Button /*className="btn btn-warning"*/ variant="warning" onClick={() => { tornaInCima(); }}><b>Torna In Cima</b></Button>
                    </div>

                    <br />
                    <br />

                </div>

            </section>

        </>

    );

};


export default GuidaInstallazioneRevisione;