import React, { useEffect, useState } from 'react'
import { RestServices } from "../../../services/restServices"
import { urlFidelityBe } from "../../costanti"
import Button from 'react-bootstrap/Button'
import { useAuth } from '../../../hooks/useAuth'
import { Popup, ScrollView } from 'devextreme-react'
import NewTabella from '../../../widget/nuova_tabella'
import '../style.css';
import { DialogNuovoFascia } from './dialog/PopNuovoFascia'
import { AplicaValoreTO } from '../../../funzioni/operazioniSuTabelle'
import { DialogCalcoModifica } from './dialog/PopModificaFascia'


interface PageProps {
    rowData: any;
    setIndietro: () => void;
}


export const ConfigParamCalcolo: React.FC<PageProps> = ({
    rowData,
    setIndietro
}) => {


    const [openPop, setOpenPop] = useState<boolean>(false);
    const [openPop2, setOpenPop2] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);
    const [fasciaList, setFasciaList] = useState<any>([])
    const [edit, setImmediate] = useState<boolean>(false)
    const [isVisible, setIsVisible] = useState(false);
    const [renderp, setRenderp] = useState<any>();
    const auth = useAuth(urlFidelityBe.auth);


    const columns = [
        { caption: "Soglia minima punti", dataField: "punti", sortOrder: "asc" },
        { caption: "Sconto a valore", dataField: "valore", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto a percentuale", dataField: "percentuale", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d"), },
        { caption: "Durata validita dalla creazione (giorni)", dataField: "giorniDurataValidita" },
        { caption: "Spesa minima per spendere il coupon", dataField: "spesaMinima", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Descrizione", dataField: "descrizione" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        //console.log(e.row.data)
                        setOpenPop2(true)
                    }

                }]

        }

    ];

    const fascia = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlFidelityBe.getListFasceFidelity)
                .then(result => {
                    setIsVisible(false);

                    if (result) {
                        setIsVisible(false)
                        setFasciaList(result.response)
                    }
                })
        }
    }

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        fascia()
        //caricaDati()
    };

    useEffect(() => {
        if (auth) {
            //getReport()  
            fascia()
        }
    }, [auth])

     return (

         <>

             <section id='scrollerelement' style={{ width: '100%', overflow: 'scroll' }}>

                 <div className='title'>
                     <h3> Configurazione Parametri Di Calcolo <span className='subt' > </span> </h3>
                 </div>

                 <ScrollView width='100%' height='100%'>

                     <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                         <div>
                             <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro(); }}>INDIETRO </Button>
                         </div>
                     </div>


                     <DialogNuovoFascia typeEdit={edit} edit={objtipo} ricarica={() => ricarica()} close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }} open={openPop} />


                     <div className='' style={{ display: 'grid', gap: 5, marginTop: 5 }}>

                         <div style={{}}>
                             <div className='button_Fascia'>
                                 <Button onClick={() => { setOpenPop(true); console.log("dialog"); }} variant="primary" >
                                     AGGIUNGI NUOVO FASCIA
                                 </Button>
                             </div>

                             <DialogCalcoModifica
                                 typeEdit={edit}
                                 edit={objtipo}
                                 ricarica={() => ricarica()}
                                 close={() => { setOpenPop2(false); setImmediate(true); setObjtipo({}) }}
                                 open={openPop2}

                             />

                             <NewTabella
                                 idTabella='PopConfigParaCalcolo'
                                 sync
                                 height={'70vh'}
                                 headerFilter
                                 colonne={columns}
                                 dataSource={fasciaList}
                                 exportT={{
                                     enabled: true,
                                     fileName: "ConfigParaCalcolo"
                                 }}
                                 activeSaver={true}
                                 columnC={true}
                             />

                         </div>
                     </div>
                 </ScrollView>
             </section>

         </>

     )
}

export default ConfigParamCalcolo
