import { Filtri } from "../../consultazione/interface";
import notify from "devextreme/ui/notify";

function aggiornaDataFine(dataFine: Date): Date {
    // Clona la data di partenza per non modificarla direttamente
    const nuovaDataFine = new Date(dataFine);

    // Aggiungi un giorno alla data
    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

const scrollToTop = () => {
    const element = document.getElementById("scrollerelement");

    if (element) {
      element.scrollTo({
        top: 0,
        behavior: "smooth", // Abilita uno scorrimento fluido
      });
    }
  };

export  const filterActionexten = (objFiltro: Filtri,setParamsFilter:(parametro: any) => void,getCall:(params:any,parameters:any)=>void) => {
    scrollToTop();
    let params = { ...objFiltro };
     setParamsFilter(params);
    
     let newIDFAR: any = [];
    let newIDSettore: any = [];

    const monthNames = [
      "Gennaio",
      "Febbraio",
      "Marzo",
      "Aprile",
      "Maggio",
      "Giugno",
      "Luglio",
      "Agosto",
      "Settembre",
      "Ottobre",
      "Novembre",
      "Dicembre",
    ];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    console.log(parameters);
    
    if (
        params.idFar.length === 0 ||
        params.idFar === undefined ||
        params.periodo.dal === null ||
        params.periodo.al === null
      ) {
        notify(
          {
            position: "center",
            width: "auto",
            message: "Filtro per farmacia e periodo obbligatori.",
          },
          "error",
          4000
        );
        return;
      }

      getCall(params,parameters)
      


  };