import notify from "devextreme/ui/notify";
import React, { useState, useEffect } from "react";
import { useAuth } from "../../../hooks/useAuth";
import { RestServices } from "../../../services/restServices";
import { Loading } from "../../../widget/Notifications";
import { NewTabella } from "../../../widget/nuova_tabella/newVersion";
import { urlCommon } from "../../costanti";
import { Popup, ToolbarItem } from "devextreme-react/popup";

interface DialogProps {
    open: boolean;
    close: () => void;
    closeAndChoice: (e:any) => void;
    daBancaDati: boolean;
    daEsistente: boolean
}

export const DialogAnaFornCenImporta: React.FC<DialogProps> = ({
    open,
    close,
    closeAndChoice,
    daBancaDati,
    daEsistente
}) => {


    const auth = useAuth(urlCommon.auth);
    const [openD, setOpenD] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [bancaDati, setBancaDati] = useState<any>([]);
    const [esistente, setEsistente] = useState<any>([]);
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [fornitoreSelezionato, setFornitoreSelezionato] = useState<any>()

    useEffect(() => {
        if (auth) {
            setOpenD(open)
        }

    }, [auth])

    useEffect(() => {
        if (openD) {
            if (daBancaDati)
                getDaBancaDati()
            if (daEsistente)
                getDaEsistente()
        }

    }, [openD])


    const columnsBancaDati = [
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Indirizzo", dataField: "indirizzo" },
        { caption: "Citta", dataField: "citta" },
        { caption: "Partita IVA", dataField: "partitaIva" },

    ];

    const columnsEsistente = [
        { caption: "Cod.Farmacia", dataField: "codFar" },
        { caption: "Rag.Soc.Farmacia", dataField: "descFar" },
        { caption: "Rag.Soc.Fornitore", dataField: "ragioneSocialeFornLoc" },
        { caption: "Partita IVA", dataField: "partitaIvaFornLoc" },

    ];

    const getDaBancaDati = () => {

        setLoading(true)
        let params = { };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListDitte)
                .then(result => {
                    if (result?.esito) {
                        setBancaDati(result.response)
                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const getDaEsistente = () => {
        setLoading(true)
        let params = { associazioni:2 };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListFornLoc)
                .then(result => {
                    if (result?.esito) {
                        setEsistente(result.response)
                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const selectionChanged = (e:any) => {
        setDisableButton(false)
        setFornitoreSelezionato(e[0])
    }

    const selezionaFornitoreBancaDati = () => {
        if (fornitoreSelezionato !== undefined) {
            closeAndChoice(fornitoreSelezionato)
        }
    }

    return (
        <>
            <Popup
                visible={openD}
                showCloseButton={false}
                width={'70%'}
                height={'90%'}
                showTitle={true}
                title={daBancaDati ? "Selezione anagrafica da banca dati" : "Selezione anagrafica da esistente (non associati)" }
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: "Chiudi",
                        onClick: () => close(),
                    }

                    }
                />

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'OK',
                        disabled: disableButton,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => selezionaFornitoreBancaDati(),
                    }}
                />

                <Loading isVisible={loading} />
                {daBancaDati &&
                    <NewTabella
                        idTabella="bancaDati"
                        sync
                        colonne={columnsBancaDati}
                        dataSource={bancaDati}
                        filterRow
                        exportT={{
                            enabled: false,
                            fileName: "list_bc",
                        }}
                    pageSize={10}
                    selectedValue={(datiRow: any) => selectionChanged(datiRow.selectedRowKeys)}
                        
                    />
                }
                {daEsistente &&
                    <NewTabella
                        idTabella="esistente"
                        sync
                        colonne={columnsEsistente}
                        dataSource={esistente}
                        filterRow
                        exportT={{
                            enabled: false,
                            fileName: "list_es",
                        }}
                    pageSize={10}
                    selectedValue={(datiRow: any) => selectionChanged(datiRow.selectedRowKeys)}
                    />
                }

            </Popup>
            

        </>
    )
}