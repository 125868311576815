import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import DialogAggiungiProdOrdine from '../../common/DialogAggiungiProdOrdine';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { RicercaProdInterface } from '../../interfaces/bancaDati';
import { DettagliContenitoreInterface, TestataContenitoreInterface } from '../../interfaces/contenitore';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { OggettoInterface } from '../../interfaces/generiche';
import { TabellaCompletaInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { DrawerFiltri } from '../../widget/Drawer';
import { BoxTestoCerca } from '../../widget/Editor';
import { DialogConferma, Loading } from '../../widget/Notifications';
import { TabellaCompletaAlternate } from '../../widget/Tabelle';
import { urlBancaDati, urlContenitori } from '../costanti';
import { DialogNuovoContenitore } from './Dialog';
import TestataContenitore from './TestataContenitore';

const SingoloContenitore = (props: {
    toggleMenu(stato: boolean): any,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[]
}) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const idContenitore = urlParams.get('idContenitore');
    const contenitoreFarmacia = urlParams.get('contenitoreFarmacia');
    let idFar = urlParams.get('idFar');

    if (idFar == null)
        idFar = "0";

    const [isDialogNewOpen, setIsDialogNewOpen] = useState<boolean>(false);
    const handleCloseDialogNew = () => setIsDialogNewOpen(false);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

        //setIdContenitore(parseInt(urlParams.get('idContenitore')));
        //setContenitoreFarmacia(parseInt(urlParams.get('contenitoreFarmacia')));

        if (window.location.href.includes("?")) {
            setIsVisible(true);
            RestServices().rest('POST', null, urlContenitori.getContenitoreCompleto + '?idContenitore=' + idContenitore + '&contenitoreFarmacia=' + contenitoreFarmacia + '&idFar=' + idFar)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result.esito && result.response.testata) {
                            setTestata(result.response.testata);
                            setDettagliContenitore(result.response.lstContenitoreDettaglio);
                        } else if (result.esito == false) {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                        }
                    }
                    
                })
        } else {
            setIsDialogNewOpen(true);
        }



    }, []);

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [isLoadVisible, setIsLoadVisible] = useState(false);
    const [testata, setTestata] = useState<TestataContenitoreInterface>();
    const [dettagliContenitore, setDettagliContenitore] = useState<DettagliContenitoreInterface[]>([]);
    const [openDrawer, setOpenDrawer] = useState(true);
    const [inputCerca, setInputCerca] = useState<string>("");
    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const handleOpen = () => setIsDialogOpen(true);
    const [aggiornaTabDett, setAggiornaTabDett] = useState(1);
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const svuotaDialog = () => setCercaNuovoProd([]);
    const handleClose = () => setIsDialogOpen(false);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [allowEditing, setAllowEditing] = useState<boolean>(true);
    const [idCont, setIdCont] = useState<string | number>();
    const [idContDettDelete, setIdContDettDelete] = useState<string | number>();
    const [messageConferma, setMessageConferma] = useState<string>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);

    //const [idContenitore, setIdContenitore] = useState<number>();
    //const [contenitoreFarmacia, setContenitoreFarmacia] = useState<number>();

    const [newTestata, setNewTestata] = useState<TestataContenitoreInterface>({
        idContenitore: undefined,
        nome: undefined,
        descrizione: undefined,
        operatore: undefined,
        dataContenitore: undefined,
    });


    const propsTabContenitori: TabellaCompletaInterface = useMemo(() => ({
        id: "dettaglio_contenitore",
        dataSource: dettagliContenitore,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        pagination: 10,
        rowAlternationEnabled: false,
        summary: {
            totalItems: [{
                name: "TotaleContenitori",
                showInColumn: "Cod. Prod.",
                displayFormat: "Prodotti: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Contenitore"
        },
        editing: {
            mode: "cell",
            allowUpdating: allowEditing,
            selectTextOnEditStart: true,
        }
    }), [dettagliContenitore, allowEditing, aggiornaTabDett])

    const columnsDettaglio = CreaColonne([
        { caption: "Cod. Csf", dataField: "codCsf", visible: false },
        { caption: "Cod. Prod.", dataField: "codProd", visible: true },
        { caption: "Descrizione", dataField: "descrizione", },
        { caption: "Quantita'", dataField: "quantita", allowEditing: true },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [

                {
                    hint: "Elimina prodotto",
                    icon: "trash",
                    onClick: (e: any) => {
                        if (contenitoreFarmacia == "0") {
                            const idStato = e.row.data.idStato;
                            //setIdOrdDelete(e.row.data.idOrdine);
                            setIdContDettDelete(e.row.data.id);
                            setMessageConferma("Il prodotto " + e.row.data.descrizione + " sta per essere eliminato. Confermare?");
                            setDialogIsOpen(true);
                        } else {
                            notify({ position: "center", width: "auto", message: "Il prodotto non puo' essere eliminato" }, "info", 4000)
                        }
                        
                    }
                }]
        }
    ])

    const templateFiltri = () => {
        return (
            <Row id="testata">
                <TestataContenitore
                    testata={testata}
                />
            </Row>
        )
    }

    const oggettoRiga = useMemo(() => ({
        id: null,
        idContenitore: idContenitore,
        codCsf: null,
        descrizione: null,
        codProd: null,
        quantita: null,
    }), [])

    let nuovoDettaglio: DettagliContenitoreInterface[] = [];

    const insertProdotto = (codCsf: number) => {
        if (!codCsf) return;

            const paramInsert = { ...oggettoRiga, idContenitore: idContenitore, codCsf: codCsf }
            RestServices().rest('POST', paramInsert, urlContenitori.setDettaglio).then(result => {
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                    } else if (!result.message) {
                        nuovoDettaglio = dettagliContenitore;
                        nuovoDettaglio.unshift(result.response);
                        setDettagliContenitore(nuovoDettaglio);
                        setAggiornaTabDett(aggiornaTabDett + 1);
                        setInputCerca("");
                        setSelectedRowKeys([result]);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "info", 4000)
                    }
                } else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                } else {
                    notify({ position: "center", width: "auto", message: "Oops, c'e' stato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                }
            })
    }

    const onRowUpdating = (e: any) => {
        const param = {
            idContenitoreDettaglio: e.key.id,
            idContenitore: e.key.idContenitore,
        }
        if (e.newData.quantita === null) {
            e.newData.quantita = 0;
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            return;
        }
        
        if (e.newData.quantita < 0 ) {
            e.cancel = true;
            notify({ position: "center", width: "auto", message: "Non � consentito inserire valori negativi" }, "error", 4000)
            return;
        } else if (e.newData.quantita > 0 || e.newData.quantita === 0) {
            const updateRow = { ...e.key, ...e.newData };
            aggiornaQuantita(updateRow, e, param);
            aggiornaQuantita(updateRow, e, param);
        }
    }

    const aggiornaQuantita = (updateRow: OggettoInterface, e: any, param: OggettoInterface) => {
        if (e.newData.quantita === 0 || e.newData.quantita > 0) {
            RestServices().rest('POST', updateRow, urlContenitori.setDettaglio).then(result => {
                if (result) {
                    if (!result.response.id) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Non e' stato possibile modificare il campo desiderato. Si prega di riprovare" }, "error", 4000)
                    } else {
                        //eliminare la riga dalla griglia
                    }
                }
                
            })
        }
    }

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "header") {
            let tooltip = "";
            switch (e.column.dataField) {
                case "quantitaOmaggio":
                    tooltip = "Quantit� omaggio"
                    break;
                case "venduto":
                    tooltip = "Quantit� venduto ultimo mese"
                    break;
                case "vendutoMedioMensile":
                    tooltip = "Venduto medio ultimi 12 mesi"
                    break;
                case "totGiacenza":
                    tooltip = "Giacenza totale gruppo"
                    break;

            }
            e.cellElement.title = tooltip;
        }
        if (e.rowType === "data" && (e.column.dataField === "quantita" ) && contenitoreFarmacia == "0") {
            e.cellElement.style.backgroundColor = "paleturquoise";
        }
    }, [])

    const onCellClick = (e: any) => {
        if (openDrawer === true && contenitoreFarmacia == "0") setOpenDrawer(false);
        setSelectedRowKeys([e.row.data]);
        if (e.rowType === "data" && (e.column.dataField !== "quantita")) {
            
        }
    }

    const onFocusedCellChanged = useCallback((e: any) => {
        if (tabellaInstance && (e.column.dataField === "quantita")) {
            if (allowEditing && contenitoreFarmacia == "0") {
                tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
                tabellaInstance.instance.editCell(e.rowIndex, e.column.dataField);
            } else {
                tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
            }
        }
    }, [tabellaInstance, allowEditing])

    const onRowPrepared = useCallback((e: any) => {
        if (e.values) {
         
        }
    }, [])

    const startRicercaProdInBD = () => {

                setIsVisible(true);
                const parametriCerca = {
                    testo: inputCerca,
                    soloInCommercio: false,
                    soloInGiacenza: false
                }
                RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                    .then(result => {
                        setIsVisible(false);
                        if (result.length === 1) {
                            if (result[0].codCsf) {
                                insertProdotto(result[0].codCsf);
                            } else {
                                notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                            }
                        } else if (result.length > 1) {
                            setCercaNuovoProd(result);
                            handleOpen();
                        } else {
                            notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                            setCercaNuovoProd([]);
                        }
                    })
           
    }

    const handleCloseConferma = () => setDialogIsOpen(false);

    const functionNext = () => {
        setDialogIsOpen(false);
        //elimina prodotto
        const param = {
            id: idContDettDelete,
            quantita: 0
        }
        RestServices().rest('POST', param, urlContenitori.setDettaglio).then(result => {
            if (result) {
                if (!result.response.id) {
                    notify({ position: "center", width: "auto", message: "Non e' stato possibile eliminare il prodotto. Si prega di riprovare" }, "error", 4000)
                } else {
                    //eliminare la riga dalla griglia
                    const newElenco = dettagliContenitore.filter(riga => {
                        return riga.id !== idContDettDelete
                    })
                    setDettagliContenitore(newElenco);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                }
            }

        })
    }

    return (
        <>
            {loadPanel}
            {
                <>
                    <div className="contenutoPagina">
                        {isDialogNewOpen ?
                            <DialogNuovoContenitore
                                isDialogNewOpen={isDialogNewOpen}
                                handleCloseDialogNew={handleCloseDialogNew}
                                setNewTestata={setNewTestata}
                                setIsVisible={setIsVisible}
                                setIdContenitore={setIdCont}
                            />
                            :
                            <>
                                <DrawerFiltri
                                    id="testataContenitore"
                                    templateFiltri={templateFiltri}
                                    testoDrawer="Dettaglio Contenitore"
                                    open={openDrawer}
                                    setOpen={setOpenDrawer}
                                />
                                <Row id="rigaRicerca">
                                    <Col sm='3' className="ordineRda">
                                        <span>Ricerca prodotto (codice, EAN, descrizione)</span>
                                    </Col>
                                    <Col sm='6' >
                                        <BoxTestoCerca placeholder="" disabled={ contenitoreFarmacia == "0" ? false:true} id="ricercaProdottiBD" setInputCerca={setInputCerca} startRicerca={startRicercaProdInBD} inputCerca={inputCerca} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm='12' className="ordineRda">
                                        {aggiornaTabDett &&
                                            <TabellaCompletaAlternate
                                                propsTabella={propsTabContenitori}
                                                onRowUpdating={onRowUpdating}
                                                setTabellaInstance={setTabellaInstance}
                                                selectedRowKeys={selectedRowKeys}
                                                columns={columnsDettaglio}
                                                onCellPrepared={onCellPrepared}
                                                onCellClick={onCellClick}
                                                onFocusedCellChanged={onFocusedCellChanged}
                                                onRowPrepared={onRowPrepared}
                                                rowAlternationEnabled={false}
                                            />}
                                    </Col>

                                </Row>
                            </>
                        }
                    </div>

                    {(cercaNuovoProd && inputCerca.length > 0 ) &&
                        <DialogAggiungiProdOrdine
                            isOpen={isDialogOpen}
                            handleClose={handleClose}
                            svuotaDialog={svuotaDialog}
                            cercaNuovoProd={cercaNuovoProd}
                            testoRicerca={inputCerca}
                            farmSelez={[]}
                            insertProdotto={insertProdotto}
                        />}

                    <DialogConferma
                        dialogIsOpen={dialogIsOpen}
                        message={messageConferma}
                        functionNext={functionNext}
                        handleCloseConferma={handleCloseConferma}
                    />
                </>
            }
        </>
    )
}

export default SingoloContenitore