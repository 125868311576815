import React, { useEffect, useState } from 'react'
import { DialogConferma } from '../../widget/Notifications'
import notify from 'devextreme/ui/notify'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { SelectBox } from 'devextreme-react/select-box'
import Button from 'react-bootstrap/Button'
import { CheckBox } from 'devextreme-react/check-box'
import { DialogNewProfilo } from "./dialog/Pop_new_profilo"
import { DialogEdit } from "./dialog/Pop_edit"
import { DialogList } from "./dialog/Pop_list"
import { AplicaValoreTO ,actionTables } from '../../funzioni/operazioniSuTabelle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {faGlobe,  faEuroSign, faTachometerAlt, faTh, faServer , faBalanceScale, faCloud, 
    faChartLine, faSitemap, faPaste, faFolderOpen, faCheck, faPoll, faFile,
    faTruck,  
    faUtensilSpoon} from '@fortawesome/free-solid-svg-icons'
import { faCalendarAlt, faMobileAlt, faAddressCard, faFileAlt, faWrench, faCog, faCube,
     faThLarge, faUser, faDatabase, faEdit, faUndo , faChartBar  } 
    from '@fortawesome/free-solid-svg-icons'
import { MenuInterface } from '../../interfaces/menu'
import '@fortawesome/fontawesome-free/css/all.css';




library.add(fab, faUndo, faCalendarAlt, faFileAlt, faAddressCard, faServer, faFile,
        faTachometerAlt, faCheck, faTh, faBalanceScale, faCloud, faChartBar,
        faChartLine, faSitemap, faPaste, faFolderOpen, faMobileAlt , faTruck,
        faEdit, faWrench, faCog, faCube, faThLarge, faUser, faDatabase, faPoll)


 

interface chekcField {
    label: string,
    fieldDatabase:string,
    defStatus?:boolean,
    callback: (v:boolean)=>void,
}

interface Profilo {
    id:string,
    nomeProfilo:string,
}

const Field = React.memo<chekcField>((promps) =>{
    let { label , defStatus  } = promps

    const valuec = (e:any) =>{
        promps.callback(e.value)
    }

    return(
        <div className="dx-field">
            <div style={{display:'flex',gap:10}} className="">
            <CheckBox text={label} onValueChanged={valuec} value={defStatus ? true : false} />
            <div className="">
            </div>
            </div>
        </div>
    )
})


   
const ProfiliUtenti = (props:  { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean, menu: MenuInterface}) => {
     
    const auth = useAuth(urlConsultazione.auth) 
    const [loading,setLoading] = useState<boolean>(false) 
    const [profiliUtentiList,setProfiliUtentiList] = useState<any>({})
    const [id,setId] = useState<number|null>(null)
    const [editOb,setEdit] = useState<any>(null)
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [openPopEdit , setOpenPopEdit ] = useState<boolean>(false)
    const [openPopList , setOpenPopList ] = useState<boolean>(false)
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)
    const [fieldCk, setFieldCk ] = useState<string[]>([])
    const [popDel,setPopDel] = useState<boolean>(false)
    const [idDel,setIddell] = useState<number>(0)
    const [messDel, setMessDel] = useState<string>('')
    const [mainMenu, setMainMenu] = useState<any>()



    const orderFill = () =>{
        let obj = {
            label:" Stato farmacie",
            fieldDatabase:"x-monitoraggio.x-stato-farm"
        }

        return obj
    }

    const gestioneCk = (id:string) =>{
        let array = [...fieldCk]
        const index = array.indexOf(id);
       // console.log('vedimao');
       // console.log(id);
        
       // console.log(index);
       // console.log('_____');  
        
        if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }else{
            array.push(id)
        }
        setFieldCk(array)
    }

    const checkIfTrue = (id:string) =>{
       // console.log('vediamp');
       // console.log(id);
      //  console.log(fieldCk);
        
        
        let exist = fieldCk.includes(id);
       // console.log(exist);
        
        if(exist){
            return true
        }else{
            return false
        }
    }

    const getProfUtenti = () =>{

        //setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getProfiliUtenti)
                .then(result => {
                    if (result?.esito) {
                        setProfiliUtentiList(result.response)
                        setLoading(false)
                       // console.log(result);
                       
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })      
         }
    }


    const getReadProfile = () =>{
       // setLoading(true)
        if (auth) {
            RestServices().rest('POST', { id } , urlConsultazione.readProfili)
                .then(result => {
                    if (result?.esito) {
                        //setTabella(result.response)
                        setLoading(false)

                        setFieldCk(result.response.pr)
                        setEdit(result.response)
                        
                        //console.log(result.response);
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        }
    }

    const removeTipo = () =>{

        if (auth) {
            RestServices().rest('POST', { id } , urlConsultazione.deleteProfili)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       ricarica()          
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })   
         } 
    }
 


    const SaveProf = ()=>{
        if (auth) {
            RestServices().rest('POST', { id ,resources:fieldCk } , urlConsultazione.saveProfili)
                .then(result => {
                   if(result.esito){
                    notify({ position: "center", width: "auto", message: "Hai modificato i campi!"}, "success", 5000)
                       ricarica()          
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })   
         } 
    }

    const ricarica = () =>{
        setOpenPop(false)  
        setOpenPopEdit(false)
        getProfUtenti()   
        //getMainMenu()
           
    }
    
    useEffect(()=>{
        if(id){
            getReadProfile()
           //setObjctTOsave({})
            
        }
    },[ id ]);
    
        useEffect(() => { 
            if (props.statoMenu) {            
              //  props.toggleMenu(!props.statoMenu);
            }
        }, []);
    
        useEffect(()=>{
            getProfUtenti()  
         },[auth])
    
        useEffect(()=>{
            if (auth) {    
                /*console.log(props.menu)*/
            }
        }, [auth])



    const items = () => {
        //if (mainMenu)
        //mainMenu.forEach((element: any) => {
        //    return <div key={element.id}>
        //        <h2>{element.text} {element.icon}</h2>
        //    </div>
        //})
        return (<div key="1">
            <h2>Prova</h2>
        </div>);
    }

        return (
            <>
            <DialogConferma
                    dialogIsOpen={popDel}
                    message={messDel}
                    functionNext={()=>removeTipo()}
                    handleCloseConferma={()=>{setPopDel(false);setIddell(0)}}
                /> 

             <DialogNewProfilo  typeEdit={editOb ? true :false} edit={editOb} ricarica={()=>ricarica()} close={()=>{setOpenPop(false);setObjtipo({});setImmediate(false)}} open={openPop} />
             
             <DialogEdit  typeEdit={true} edit={editOb} ricarica={()=>ricarica()} close={()=>{setOpenPopEdit(false);setObjtipo({});setImmediate(false)}} open={openPopEdit} />
             
             <DialogList edit={editOb} id={id} close={()=>{setOpenPopList(false);setObjtipo({});setImmediate(false)}} open={openPopList} />


             <Loading isVisible={loading} />
                <div className='title'>
                    <h3> Gestione profili  <span className='subt' > </span> </h3>
                </div>

                <div className='form-row '> 
                <div className="form-group col-sm-6 col-md-4 col-lg-6 col-xl-6">
             <div style={{ marginTop:10, width:300  }}>
                   <label> Selezionare il profilo  </label>  
                   <SelectBox dataSource={profiliUtentiList}  showDataBeforeSearch={true}  valueExpr="id"  displayExpr="nomeProfilo"   valueChangeEvent="keyup" showClearButton={false}  placeholder="Seleziona il profilo"
                   onValueChanged={(e:any)=>{setId(e.value)}} 
                      />                            
                    </div>
                    </div>

                    <div className=" form-group col-sm-6 col-md-4 col-lg-6 col-xl-6 text-center ">      
                    <Button style={{ marginTop:30, marginLeft:10 }} id="modalConfermaOk" type="success" variant="success"  onClick={()=>{setObjtipo({});setOpenPop(true)}} >
                        NUOVO
                    </Button>
                                 
                    <Button  disabled={!id} style={{ marginTop:30, marginLeft:10 }} id="modalConfermaOk" variant="info" onClick={()=>{setObjtipo({});setOpenPopEdit(true)}}>
                        MODIFICA
                    </Button>
                                   
                    <Button disabled={!id} style={{ marginTop:30, marginLeft:10 }} id="modalConfermaAnnulla" variant="danger" onClick={(e:any)=>
                          {
                        setMessDel("Il Profilo "+ editOb.pn  +" sta per essere eliminato. Confermare?")
                        setPopDel(true)
                        setIddell(e.id)

                    }}>
                        ELIMINA
                    </Button>
                   
                    <Button disabled={!id} style={{ marginTop:30, marginLeft:10 }} id="modalConfermaOk" variant="primary" onClick={()=>setOpenPopList(true)}>
                        UTENTI
                    </Button>
                   
                    </div>

        </div>

             <div style={{background:'#fff',padding:10, borderRadius:10, bottom:20}}>
                
                {id ? (
                    <>
                    <div>
                    <h4>Abilitazioni</h4>
                </div>
                <div className='tabellaObj'>
                <table>
                    <tbody>
                    <tr style={{fontSize:20}}>
                        <th style={{padding:10}}>Menu Principale</th>
                        <th style={{padding:10}}>Voci di menu</th>
                    </tr>  
                        {
                            props.menu.menuItems.ancestor.map(function (d: any) {

                                let item = props.menu.menuItems[d.id]
                                let icon = 'fa fa-' + d.icon
                                if (d.id != 'gestione_utente' && d.id != 'x-cloudfarma') {

                                return (
                                <tr>
                                    <td>
                                        <div style={{ padding: 10 }}>                                   
                                                {/*<p style={{ fontSize: 16, fontWeight: 'bold' }}> <FontAwesomeIcon icon={faTachometerAlt} size={'1x'} /> {d.text}</p>*/}
                                                <p style={{ fontSize: 16, fontWeight: 'bold' }}> <i className={icon} /> {d.text}</p>
                                        </div>
                                        </td>
                                    <td>
                                    {
                                            item.map(function (element: any) {
                                        
                                            return (
                                            
                                                    <div>
                                                        <Field defStatus={checkIfTrue(element.id)} callback={(_v: boolean) => gestioneCk(element.id)} label={element.text} fieldDatabase={element.id} ></Field>
                                                    </div>
                                            
                                            )
                                        })
                                            }
                                    </td>
                                </tr>
                                )
                        }
                    })} 



                    {/*versione statica*/}
                                        
                    {/*<tr>*/}
                    {/*    <td>                */}
                    {/*        <div style={{padding:10}}>                                   */}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faTachometerAlt} size={'1x'} /> Monitoraggio</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-monitoraggio.x-stato-farm')} callback={(_v: boolean) => gestioneCk('x-monitoraggio.x-stato-farm')} label='Stato farmacie' fieldDatabase='x-monitoraggio.x-stato-farm' ></Field>*/}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faDatabase} size={'1x'}/> Consultazione dati</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-vendite')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-vendite')} label='Vendite' fieldDatabase='x-consultazione-dati.x-vendite' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-ordini')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-ordini')} label='Ordini' fieldDatabase='x-consultazione-dati.x-ordini' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-carichi')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-carichi')} label='Carichi' fieldDatabase='x-consultazione-dati.x-carichi' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-giacenze')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-giacenze')} label='Giacenze' fieldDatabase='x-consultazione-dati.x-giacenze' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-movimenti')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-movimenti')} label='Movimenti' fieldDatabase='x-consultazione-dati.x-movimenti' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-fornitori_l')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-fornitori_l')} label='Fornitori locali' fieldDatabase='x-consultazione-dati.x-fornitori_l' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-consultazione-lis')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-consultazione-lis')} label='Listini' fieldDatabase='x-consultazione-dati.x-consultazione-lis' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-consultazione-dati.x-val_inventario')} callback={(_v: boolean) => gestioneCk('x-consultazione-dati.x-val_inventario')} label='Valorizzazione Inventario' fieldDatabase='x-consultazione-dati.x-val_inventario' ></Field>*/}

                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{ padding: 10 }}>*/}
                    {/*            <p style={{ fontSize: 16, fontWeight: 'bold' }}> <FontAwesomeIcon icon={faDatabase} size={'1x'} /> Contenitori</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-contenitori.x-contenitori-gestione')} callback={(_v: boolean) => gestioneCk('x-contenitori.x-contenitori-gestione')} label='Vendite' fieldDatabase='x-contenitori.x-contenitori-gestione' ></Field>                                    */}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faChartLine} size={'1x'} /> BusinessLab Lite</p>   */}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche-lite.x-vendite')} callback={(_v: boolean) => gestioneCk('x-statistiche-lite.x-vendite')} label='Vendite' fieldDatabase='x-statistiche-lite.x-vendite' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche-lite.x-acquisti')} callback={(_v: boolean) => gestioneCk('x-statistiche-lite.x-acquisti')} label='Acquisti' fieldDatabase='x-statistiche-lite.x-acquisti' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche-lite.x-mercato')} callback={(_v: boolean) => gestioneCk('x-statistiche-lite.x-mercato')} label='Mercato' fieldDatabase='x-statistiche-lite.x-mercato' ></Field>*/}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faChartLine} size={'1x'} /> BusinessLab Evolution</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche.x-businesslab')} callback={(_v:boolean)=>gestioneCk('x-statistiche.x-businesslab')} label='Cruscotto Direzionale' fieldDatabase='x-statistiche.x-businesslab' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche.x-operativo')} callback={(_v:boolean)=>gestioneCk('x-statistiche.x-operativo')} label='Cruscotto Operativo' fieldDatabase='x-statistiche.x-operativo' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche.x-sintesi')} callback={(_v:boolean)=>gestioneCk('x-statistiche.x-sintesi')} label='Reportistica' fieldDatabase='x-statistiche.x-sintesi' ></Field>*/}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faChartLine} size={'1x'} /> BusinessLab Extended</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche-ext.x-dashboard')} callback={(_v: boolean) => gestioneCk('x-statistiche-ext.x-dashboard')} label='Dashboard' fieldDatabase='x-statistiche-ext.x-dashboard' ></Field>*/}
                                
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faChartLine} size={'1x'} /> BusinessLab Smart</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-statistiche-smart.x-analisidir-smart')} callback={(_v:boolean)=>gestioneCk('x-statistiche-smart.x-analisidir-smart')} label='Analisi Direzionale' fieldDatabase='x-statistiche-smart.x-analisidir-smart' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faEdit} size={'1x'} /> Ordini</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini.x-proposte2')} callback={(_v:boolean)=>gestioneCk('x-ordini.x-proposte2')} label='Proposte ordini diretti' fieldDatabase='x-ordini.x-proposte2' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini.x-ordini')} callback={(_v:boolean)=>gestioneCk('x-ordini.x-ordini')} label='Consultazione Ordini' fieldDatabase='x-ordini.x-ordini' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini.x-acquisizione_lis')} callback={(_v:boolean)=>gestioneCk('x-ordini.x-acquisizione_lis')} label='Acquisizione Listini' fieldDatabase='x-ordini.x-acquisizione_lis' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini.x-consultazione_lis')} callback={(_v:boolean)=>gestioneCk('x-ordini.x-consultazione_lis')} label='Consultazione Listini' fieldDatabase='x-ordini.x-consultazione_lis' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini.x-trasferimenti')} callback={(_v:boolean)=>gestioneCk('x-ordini.x-trasferimenti')} label='Trasferimenti' fieldDatabase='x-ordini.x-trasferimenti' ></Field>*/}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faEdit} size={'1x'} /> Ordini Liberi</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini-liberi.x-gestione')} callback={(_v:boolean)=>gestioneCk('x-ordini-liberi.x-gestione')} label='Gestione Ordini' fieldDatabase='x-ordini-liberi.x-gestione' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faEdit} size={'1x'} /> Ordini RDA</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini-rda.x-gestione')} callback={(_v:boolean)=>gestioneCk('x-ordini-rda.x-gestione')} label='Gestione ordini' fieldDatabase='x-ordini-rda.x-gestione' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faFile} size={'1x'} /> Fatturaz. Elettronica</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-fatture.x-passive')} callback={(_v:boolean)=>gestioneCk('x-fatture.x-passive')} label='Fatture passive' fieldDatabase='x-fatture.x-passive' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-fatture.x-attive')} callback={(_v: boolean) => gestioneCk('x-fatture.x-attive')} label='Fatture attive' fieldDatabase='x-fatture.x-attive' ></Field> */}
                    {/*            <Field defStatus={checkIfTrue('x-fatture.x-einvoice')} callback={(_v: boolean) => gestioneCk('x-fatture.x-attive')} label='x-fatture.x-einvoice' fieldDatabase='x-fatture.x-einvoice' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{ padding: 10 }}>*/}
                    {/*            <p style={{ fontSize: 16, fontWeight: 'bold' }}> <FontAwesomeIcon icon={faFile} size={'1x'} /> Ordini NSO</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ordini-nso.x-nso')} callback={(_v: boolean) => gestioneCk('x-ordini-nso.x-nso')} label='Fatture passive' fieldDatabase='x-ordini-nso.x-nso' ></Field>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}


                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faPaste} size={'1x'} /> Controllo Fatture </p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-carichi.x-carichi')} callback={(_v:boolean)=>gestioneCk('x-carichi.x-carichi')} label='Carichi' fieldDatabase='x-carichi.x-carichi' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-carichi.x-resi')} callback={(_v:boolean)=>gestioneCk('x-carichi.x-resi')} label='Resi' fieldDatabase='x-carichi.x-resi' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-carichi.x-fatture')} callback={(_v:boolean)=>gestioneCk('x-carichi.x-fatture')} label='Fatture' fieldDatabase='x-carichi.x-fatture' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-carichi.x-pratiche')} callback={(_v:boolean)=>gestioneCk('x-carichi.x-pratiche')} label='Pratiche non conf.' fieldDatabase='x-carichi.x-pratiche' ></Field> */}
                    {/*            <Field defStatus={checkIfTrue('x-carichi.x-scadenzario')} callback={(_v:boolean)=>gestioneCk('x-carichi.x-scadenzario')} label='Scadenzario' fieldDatabase='x-carichi.x-scadenzario' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-carichi.x-sintesi')} callback={(_v:boolean)=>gestioneCk('x-carichi.x-sintesi')} label='Sintesi' fieldDatabase='x-carichi.x-sintesi' ></Field>  */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}


                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faTruck} size={'1x'} /> Trasferimento merce </p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-trasferimento-merce.x-trasferimento-configurazione')} callback={(_v:boolean)=>gestioneCk('x-trasferimento-merce.x-trasferimento-configurazione')} label='Configurazione' fieldDatabase='x-trasferimento-merce.x-trasferimento-configurazione' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-trasferimento-merce.x-trasferimento-consultazione')} callback={(_v:boolean)=>gestioneCk('x-trasferimento-merce.x-trasferimento-consultazione')} label='Consultazione' fieldDatabase='x-trasferimento-merce.x-trasferimento-consultazione' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-trasferimento-merce.x-trasferimento-listino')} callback={(_v:boolean)=>gestioneCk('x-trasferimento-merce.x-trasferimento-listino')} label='Listino' fieldDatabase='x-trasferimento-merce.x-trasferimento-listino' ></Field>*/}
                                
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{ padding: 10 }}>*/}
                    {/*            <p style={{ fontSize: 16, fontWeight: 'bold' }}> <FontAwesomeIcon icon={faTruck} size={'1x'} /> Ufficio </p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ufficio.x-fatture_attive')} callback={(_v: boolean) => gestioneCk('x-ufficio.x-fatture_attive')} label='Configurazione' fieldDatabase='x-ufficio.x-fatture_attive' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ufficio.x-fatture_passive')} callback={(_v: boolean) => gestioneCk('x-ufficio.x-fatture_passive')} label='Consultazione' fieldDatabase='x-ufficio.x-fatture_passive' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ufficio.x-scadenzario_attivo')} callback={(_v: boolean) => gestioneCk('x-ufficio.x-scadenzario_attivo')} label='Listino' fieldDatabase='x-ufficio.x-scadenzario_attivo' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ufficio.x-scadenzario_passivo')} callback={(_v: boolean) => gestioneCk('x-ufficio.x-scadenzario_passivo')} label='Listino' fieldDatabase='x-ufficio.x-scadenzario_passivo' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ufficio.x-primanota_contabile')} callback={(_v: boolean) => gestioneCk('x-ufficio.x-primanota_contabile')} label='Listino' fieldDatabase='x-ufficio.x-primanota_contabile' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-ufficio.x-primanota_causali')} callback={(_v: boolean) => gestioneCk('x-ufficio.x-primanota_causali')} label='Listino' fieldDatabase='xx-ufficio.x-primanota_causali' ></Field>*/}

                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faMobileAlt} size={'1x'} /> Pocket Farma </p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-mobile.x-pocketfarma')} callback={(_v:boolean)=>gestioneCk('x-mobile.x-pocketfarma')} label='Amministrazione' fieldDatabase='x-mobile.x-pocketfarma' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-mobile.x-prenotazioni')} callback={(_v:boolean)=>gestioneCk('x-mobile.x-prenotazioni')} label='Prenotazione' fieldDatabase='x-mobile.x-prenotazioni' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-mobile.x-clienti')} callback={(_v:boolean)=>gestioneCk('x-mobile.x-clienti')} label='Clienti & Comunicazioni' fieldDatabase='x-mobile.x-clienti' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-mobile.x-storico_sms')} callback={(_v:boolean)=>gestioneCk('x-mobile.x-storico_sms')} label='Storico SMS' fieldDatabase='x-mobile.x-storico_sms' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-mobile.x-impostazioni')} callback={(_v:boolean)=>gestioneCk('x-mobile.x-impostazioni')} label='Listino' fieldDatabase='x-mobile.x-impostazioni' ></Field>*/}
                                
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faFolderOpen} size={'1x'} /> Prezzi vendita</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-gestione.x-prezzi')} callback={(_v:boolean)=>gestioneCk('x-gestione.x-prezzi')} label='Allineamento prezzi' fieldDatabase='x-gestione.x-prezzi' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faFolderOpen} size={'1x'} /> Prezzi promozione</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-gestione-prezzi_promozione.x-prezzi-promozione')} callback={(_v:boolean)=>gestioneCk('x-gestione-prezzi_promozione.x-prezzi-promozione')} label='Gestione Prezzi Promozione' fieldDatabase='x-gestione-prezzi_promozione.x-prezzi-promozione' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faFolderOpen} size={'1x'} /> Report</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-report.x-predefiniti')} callback={(_v:boolean)=>gestioneCk('x-report.x-predefiniti')} label='Predefiniti' fieldDatabase='x-report.x-predefiniti' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faAddressCard} size={'1x'} /> Anagrafiche </p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-anagrafiche.x-gruppo')} callback={(_v:boolean)=>gestioneCk('x-anagrafiche.x-gruppo')} label='Gruppo' fieldDatabase='x-anagrafiche.x-gruppo' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-anagrafiche.x-aziende')} callback={(_v:boolean)=>gestioneCk('x-anagrafiche.x-aziende')} label='Aziende' fieldDatabase='x-anagrafiche.x-aziende' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-anagrafiche.x-farmacie')} callback={(_v:boolean)=>gestioneCk('x-anagrafiche.x-farmacie')} label='Farmacie' fieldDatabase='x-anagrafiche.x-farmacie' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-anagrafiche.x-fornitori')} callback={(_v:boolean)=>gestioneCk('x-anagrafiche.x-fornitori')} label='Fornitori Centralizzata' fieldDatabase='x-anagrafiche.x-fornitori' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-anagrafiche.x-fornitoril')} callback={(_v:boolean)=>gestioneCk('x-anagrafiche.x-fornitoril')} label='Fornitori Locale' fieldDatabase='x-anagrafiche.x-fornitoril' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-anagrafiche.x-banche')} callback={(_v:boolean)=>gestioneCk('x-anagrafiche.x-banche')} label='Banche' fieldDatabase='x-anagrafiche.x-banche' ></Field>*/}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faCalendarAlt} size={'1x'} /> Prenotazioni Ricette </p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-prenotazioni-ric.x-ricette')} callback={(_v:boolean)=>gestioneCk('x-prenotazioni-ric.x-ricette')} label='Gestione' fieldDatabase='x-prenotazioni-ric.x-ricette' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-prenotazioni-ric.x-ricette-conf')} callback={(_v:boolean)=>gestioneCk('x-prenotazioni-ric.x-ricette-conf')} label='Configurazione' fieldDatabase='x-prenotazioni-ric.x-ricette-conf' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-prenotazioni-ric.x-ricette-stat')} callback={(_v:boolean)=>gestioneCk('x-prenotazioni-ric.x-ricette-stat')} label='Statistiche' fieldDatabase='x-prenotazioni-ric.x-ricette-stat' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-prenotazioni-ric.x-ricette-storico-sms')} callback={(_v:boolean)=>gestioneCk('x-prenotazioni-ric.x-ricette-storico-sms')} label='Storico SMS' fieldDatabase='x-prenotazioni-ric.x-ricette-storico-sms' ></Field>*/}
                    {/*            <Field defStatus={checkIfTrue('x-prenotazioni-ric.x-ricette-stat-sms')} callback={(_v:boolean)=>gestioneCk('x-prenotazioni-ric.x-ricette-stat-sms')} label='Statistiche SMS' fieldDatabase='x-prenotazioni-ric.x-ricette-stat-sms' ></Field>*/}
                                
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}
                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faFolderOpen} size={'1x'} /> Screening Bergamo</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-screening.x-colon')} callback={(_v:boolean)=>gestioneCk('x-screening.x-colon')} label='Del Colon retto' fieldDatabase='x-screening.x-colon' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>*/}

                    {/*<tr>*/}
                    {/*    <td>*/}
                    {/*        <div style={{padding:10}}>*/}
                    {/*            <p style={{fontSize:16,fontWeight:'bold'}}> <FontAwesomeIcon  icon={faWrench} size={'1x'} /> Utility</p>*/}
                    {/*        </div>*/}
                    {/*    </td>*/}
                    {/*    <td>*/}
                    {/*        <div>*/}
                    {/*            <Field defStatus={checkIfTrue('x-utility.x-download')} callback={(_v:boolean)=>gestioneCk('x-utility.x-download')} label='Download' fieldDatabase='x-utility.x-download' ></Field> */}
                    {/*         </div>*/}
                    {/*    </td>*/}
                    {/*</tr>   */}
                    </tbody>                            
                    </table>

                            <div style={{marginTop:15, textAlign:'center', background:'#fff',padding:20}}>
                  <Button
                    id="button"
                    variant="success"               
                    onClick={()=>SaveProf()} >SALVA
                        </Button>    
                    <Button style={{ marginLeft:7}} 
                     id='button' 
                     variant="info" 
                     onClick={()=>getReadProfile()} >
                    ANNULLA
                    </Button>  

                    

                    </div>   

                        </div>
                        </>
                ) : null}
                

            </div>
                    
          
             
         </>
                
                )
             }
     
     export default ProfiliUtenti