import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreaColonne, CreaColonneNew } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OnRowDblClickInterface, TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaInfoFarmacia, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from 'devextreme/ui/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AnaFarmacia, FarmaciaSelezionataNewsletter } from "../../interfaces/newsletter";
import Button from 'react-bootstrap/esm/Button';
import ReactDOM from "react-dom";
import { Box, TileView } from "devextreme-react";
import { Item } from "devextreme-react/box";
import { OggettoInterface } from "../../interfaces/generiche";
import { SelezioneMultipla } from "../../widget/Select";
import { DialogMonitoraggioPlatinumAssegnaTicket, DialogMonitoraggioPlatinumRevisione, DialogNoteMon } from "./Dialog";
import { ReleaseCompletaInterface } from "../../interfaces/monitoraggio";
import { useAuth } from "../../hooks/useAuth";
import NewTabella from "../../widget/nuova_tabella"
import { Summary, TotalItem } from "devextreme-react/data-grid";

const ElencoClientiMonitoraggioBackupDBPlatinum = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlMonitoraggio.auth);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            startCerca();
        }
        setTitoloDialogAssegnaTicket("Assegnazione ticket")

    }, [auth]);



    const titolo = TitoloContenuto("Monitoraggio Backup Database Platinum");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [tabellaInstancAlert, setTabellaInstanceAlert] = useState<any>();
    const [elencoClienti, setElencoClienti] = useState<any[]>();
    const [elencoAlert, setElencoAlert] = useState<any[]>();
    const [elencoLivelliAlert, setElencoLivelliAlert] = useState<any[]>();
    const [elencoReleaseCompleta, setElencoReleaseCompleta] = useState<ReleaseCompletaInterface[]>();
    const [elencoReleaseFiltered, setElencoReleaseFiltered] = useState<any[]>();
    const [elencoRevisioni, setElencoRevisioni] = useState<any[]>();
    const [numeroClienti, setNumeroClienti] = useState<any>();
    const [numeroFarmacie, setNumeroFarmacie] = useState<any>();
    const [numeroParafarmacie, setNumeroParafarmacie] = useState<any>();
    const [numeroDispensari, setNumeroDispensari] = useState<any>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [dialogMonitoraggioPlatinumRevisioneOpen, setDialogMonitoraggioPlatinumRevisioneOpen] = useState(false);
    const [titoloDialog, setTitoloDialog] = useState<string>();
    const [titoloDialogAssegnaTicket, setTitoloDialogAssegnaTicket] = useState<string>();
    const [dialogMonitoraggioPlatinumAssegnaTicketOpen, setDialogMonitoraggioPlatinumAssegnaTicketOpenOpen] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState<any[]>();
    const [esitoAssegnaTicket, setEsitoAssegnaTicket] = useState(false);
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)


    useEffect(() => {
        if (esitoAssegnaTicket == true)
            startCerca();
    }, [esitoAssegnaTicket]);

    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {
            lstLivelliAlert: filtri.descrizione,
        };
        RestServices().rest('POST', param, urlMonitoraggio.getAnagraficaClientiMonitoraggioBackupDatabasePlatinum)
            .then(result => {
                setIsVisible(false);
                if (result) {

                    if (result.response) {

                        setElencoClienti(result.response.listaClienti);
                        setElencoAlert(result.response.listaClientiAlert);
                        setElencoLivelliAlert(result.response.elencoLivelliAlert);

                        //setElencoRelease(result.response.listaRelease);
                        //setElencoReleaseCompleta(result.response.listaReleaseCompleta);
                        setNumeroClienti(result.response.numeroClienti);
                        setNumeroFarmacie(result.response.numeroFarmacie);
                        setNumeroParafarmacie(result.response.numeroParafarmacie);
                        setNumeroDispensari(result.response.numeroDispensari);
                        //setElencoRevisioni(result.response.elencoRevisioni);
                    }

                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const assegnaTicket = () => {

        var keys = tabellaInstance.instance.getSelectedRowKeys();
        console.log(keys)
        if (keys.length == 0)
            notify({ position: "center", width: "auto", message: "Selezionare almeno un cliente" }, 'error', 4000);
        else
            setDialogMonitoraggioPlatinumAssegnaTicketOpenOpen(true);
    }

    const propsTabElencoFarmacie: TabellaCompletaInterface = {
        id: "elenco_monitoraggio_backupdatabase",
        dataSource: elencoClienti,
        selection: "multiple",
        autoWidth: true,
        defaultFilterValue: null,
        headerFilterVisible: true,
        summary: {

            totalItems: [{
                name: "TotaleFarmacie",
                showInColumn: "Cod.CSF",
                displayFormat: "Tot.: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoClientiMonitoraggioBackupDatabasePlatinum"
        }
    }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const columnsFarmacie = CreaColonne([
        { caption: "IdCliente", dataField: "idCliente", visible: false },
        { caption: "Cod.CSF", dataField: "codiceCliente", },
        { caption: "Cod.Tph", dataField: "codiceTelepharma" },
        { caption: "Data Ultimo backup", dataField: "dataUltimoBackup", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Aggiornament automatico attivo", dataField: "attivo", visible: true, dataType: "boolean", width: 90 },
        { caption: "Orario", dataField: "ora" },
        { caption: "Giorni", dataField: "giorni" },
        { caption: "Server", dataField: "terminale" },
        { caption: "Tipo Cliente", dataField: "tipoCliente" },
        { caption: "Tipo Platinum", dataField: "tipoPlatinum" },
        { caption: "Rag.Soc.", dataField: "ragioneSociale" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Comune", dataField: "comune" },
        { caption: "Concessionario", dataField: "concessionario" },
        { caption: "Ha disdetto platinum", dataField: "disdettaPlatinum", visible: true, dataType: "boolean", width: 90 },
        { caption: "Data termine Platinum", dataField: "dataDisdettaPlatinum", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Ticket aperto", dataField: "ticketAperto" },
        { caption: "Oper. assegnato", dataField: "operatoreAssegnato" },
        {
            caption: "Note", dataField: "note", width: 100, cellRender: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        { caption: "Data Aggior.", dataField: "dataAggiornamento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
    ])


    //const columnsFarmacie = [
    //    { caption: "IdCliente", dataField: "idCliente", visible: false },
    //    { caption: "Revisione", dataField: "releaseInstallata" },
    //    { caption: "Cod.CSF", dataField: "codiceCliente", },
    //    { caption: "Cod.Tph", dataField: "codiceTelepharma" },
    //    { caption: "Tipo Cliente", dataField: "tipoCliente" },
    //    { caption: "Tipo Platinum", dataField: "tipoPlatinum" },
    //    { caption: "Rag.Soc.", dataField: "ragioneSociale" },
    //    { caption: "Regione", dataField: "regione" },
    //    { caption: "Provincia", dataField: "provincia" },
    //    { caption: "Comune", dataField: "comune" },
    //    { caption: "Concessionario", dataField: "concessionario" },
    //    { caption: "Ticket aperto", dataField: "ticketAperto" },
    //    { caption: "Oper. assegnato", dataField: "operatoreAssegnato" },
    //    {
    //        caption: "Note", dataField: "note", width: 100, render: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
    //            onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
    //    },
    //    { caption: "Data Aggior.", dataField: "dataAggiornamento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
    //];

    const propsTabElencoAlert: TabellaCompletaInterface = {
        id: "elenco_info_alert_backup_database",
        dataSource: elencoAlert,
        selection: "single",
        autoWidth: true,
        defaultFilterValue: null,
        headerFilterVisible: false,
        summary: {

            totalItems: [{
                name: "TotaleAlerts",
                showInColumn: "Alert",
                displayFormat: "Tot.: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoClientiMonitoraggioBackupDatabasePlatinum"
        }
    }

    const columnsRelease = CreaColonne([
        { caption: "Alert", dataField: "alert", sortOrder: "desc" },
        { caption: "Totale", dataField: "numeroTotale" },
        { caption: "Num. Farmacie", dataField: "numeroFarmacie" },
        { caption: "Num. Dispensari", dataField: "numeroDispensari" },
        { caption: "Num. Parafarmacie", dataField: "numeroParafarmacie" },
    ])

    const colonneGridGeneriche = CreaColonne([
        //{ dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
    ])

    /*
    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        //if (rowDblClicked.data) {
        //    const path = basenameHostNewTabs + "apriOrdineRda?" + rowDblClicked.data.id;
        //    window.open(path);
        //}
        let rel = rowDblClicked.data.release;
        setElencoReleaseFiltered(elencoReleaseCompleta?.filter(item => item.releaseCompleta?.includes(rel)));
        setTitoloDialog(rowDblClicked.data.release);
        setDialogMonitoraggioPlatinumRevisioneOpen(true);
    }*/

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;
        selectedRows.forEach((row: any) => {
            if (row.nascosto == true)
                e.component.deselectRows(row); return;
        })

        var keys = tabellaInstance.instance.getSelectedRowKeys();
        setSelectedKeys(keys);
    }

    function renderTile(data: any) {
        return <div className="dx-tile" style={{ backgroundColor: "aqua" }}>
            {data.testo}
            {data.valore}
        </div>;
    }


    const ricarica = () => {
        setOpenPop2(false)
        setImmediate(false)
        startCerca()

    }

    return (
        <>
            {titolo}
            {loadPanel}
            &nbsp;
            <div className="contenutoPagina">

                <Row>
                    <Col sm="3">
                        <div className="leftcontent">
                            <span id="lblMonitoraggio" title="Numero Clienti con il gestionale farmacia Sistema F" className="lblMonitoraggioClienti">Numero Clienti Sistema F Platinum: <span className="badge badge-success">{String(Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(numeroClienti)).replace(",", ".")}</span></span>
                        </div>
                    </Col>
                    <Col sm="2">
                        <Row>
                            <span id="lblMonitoraggio" title="Numero Farmacie" className="lblMonitoraggioFarmacie">Farmacie: <span className="badge badge-success">{String(Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(numeroFarmacie)).replace(",", ".")}</span></span>
                        </Row>
                        &nbsp;
                        <Row>
                            <span id="lblMonitoraggio" title="Numero Dispensari" className="lblMonitoraggioDispensari">Dispensari: <span className="badge badge-success">{String(Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(numeroDispensari)).replace(",", ".")}</span></span>
                        </Row>
                        &nbsp;
                        <Row>
                            <span id="lblMonitoraggio" title="Numero Parafarmacie" className="lblMonitoraggioParafarmacie">Parafarmacie: <span className="badge badge-success">{String(Intl.NumberFormat('en-US', { minimumFractionDigits: 0 }).format(numeroParafarmacie)).replace(",", ".")}</span></span>
                        </Row>

                    </Col>
                </Row>

                &nbsp;

                <Row className="tabellaFornitori" id="rigaTabInfoAlert">
                    <Col sm="12" className="infoFarmacia">

                        <TabellaCompletaInfoFarmacia
                            searchPanel={true}
                            propsTabella={propsTabElencoAlert}
                            columns={columnsRelease}
                            setTabellaInstance={setTabellaInstanceAlert}
                            rowAlternationEnabled={false}
                            togliFilterPanel={true}
                        //onRowDblClick={onRowDblClick}

                        />
                    </Col>
                </Row>


                <Row className="alert" id="alert">
                    <Col sm="3">
                        <label>Alert</label>
                        <SelezioneMultipla
                            id="descrizione"
                            dataSource={elencoLivelliAlert}
                            impostaFiltri={impostaFiltri}
                            column={colonneGridGeneriche}
                            selection="multiple"
                            primaSelezionata={false}
                        ></SelezioneMultipla>
                    </Col>

                    <Col sm="1">
                        <Button type="button" variant="success" id="btnCercaMonitoraggio" onClick={startCerca}>Cerca</Button>
                    </Col>

                    <Col sm="8">
                        <Button type="button" variant="info" id="btnAssegnaMonitoraggio" onClick={assegnaTicket}>Assegna ticket</Button>
                    </Col>
                </Row>
                <Row className="tabellaFornitori" id="rigaTabInfoFarmacia">
                    <Col sm="12" className="infoFarmacia">

                        <TabellaCompletaInfoFarmacia
                            searchPanel={true}
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            togliFilterPanel={true}
                            onSelectionChanged={onSelectionChanged}

                        />


                    </Col>
                </Row>
            </div>

            <DialogMonitoraggioPlatinumAssegnaTicket
                setDialogMonitoraggioPlatinumAssegnaTicket={setDialogMonitoraggioPlatinumAssegnaTicketOpenOpen}
                dialogMonitoraggioPlatinumAssegnaTicketOpen={dialogMonitoraggioPlatinumAssegnaTicketOpen}
                titolo={titoloDialogAssegnaTicket}
                selectedKeys={selectedKeys}
                setEsitoAssegnaTicket={setEsitoAssegnaTicket}
            />

            <DialogNoteMon
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />
        </>
    )

}

export default ElencoClientiMonitoraggioBackupDBPlatinum;