import FiltriVerticale, {
  ConvertForSelect,
} from "../../../dashboards/filters/FiltriVerticaleNewVersione";

export const createItemFilterAnalVendCategory = (
  newFarmaList: any,
  category: any,
  ditte: any,
  settori: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "category",
      label: "Category",
      displayExpr: "descrizione",
      dataSource: category,
      idValueGrid: "category" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      allowSelectAll: false,
      dataSource: ditte,
      idValueGrid: "ditta" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnalVendGruMerc = (
  newFarmaList: any,
  gruppoMercFilters: any,
  ditte: any,
  settori: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      allowSelectAll: false,
      idValueGrid: "ditta" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnalVendDitta = (
  newFarmaList: any,
  tipologiaVendita: any,
  gruppoMercFilters: any,
  ditte: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      allowSelectAll: false,
      dataSource: ditte,
      idValueGrid: "ditta" as any,

    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnaSconCate = (
  newFarmaList: any,
  operatori: any,
  gruppoMercFilters: any,
  tipologiaSconto: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "selectWithLevels",
      id: "tipologiaSconto",
      label: "Tipologia di sconto",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaSconto,
      idValueGrid: "tipologiaSconto" as any,
    },
  ];
};

export const createItemFilterAnalRicette = (
  newFarmaList: any,
  tipologiaVendita: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
  ];
};

export const createItemFilterAnalAcquiGruMerc = (
  newFarmaList: any,
  category: any,
  codiciAtc: any,
  gruppoMercFilters: any,
  ditte: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "category",
      label: "Category",
      displayExpr: "descrizione",
      dataSource: category,
      idValueGrid: "category" as any,
    },
    //{
      //type: "selectWithLevels",
      //id: "gruppoMerc",
      //label: "Gruppo Merceologico",
      //displayExpr: "descrizione",
      //dataSource: gruppoMercFilters,
      //idValueGrid: "gruppoMerc" as any,
    //},
    {
      type: "selectWithLevels",
      id: "atc",
      label: "Codice ATC",
      displayExpr: "descrizione",
      dataSource: codiciAtc,
      idValueGrid: "atc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
      allowSelectAll: false
    },
  ];
};

export const createItemFilterAnalFornDittCate = (
  newFarmaList: any,
  category: any,
  codiciAtc: any,
  ditte: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "category",
      label: "Category",
      displayExpr: "descrizione",
      dataSource: category,
      idValueGrid: "category" as any,
    },
    {
      type: "selectWithLevels",
      id: "atc",
      label: "Codice ATC",
      displayExpr: "descrizione",
      dataSource: codiciAtc,
      idValueGrid: "atc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
      allowSelectAll: false,

    },
  ];
};

export const createItemFilterAnalFornDittGruppo = (
  newFarmaList: any,
  gruppoMercFilters: any,
  codiciAtc: any,
  ditte: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },

    {
      type: "selectWithLevels",
      id: "atc",
      label: "Codice ATC",
      displayExpr: "descrizione",
      dataSource: codiciAtc,
      idValueGrid: "atc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
      allowSelectAll: false,
    },
  ];
};

export const createItemFilterAnalMargMedi = (
  newFarmaList: any,
  gruppoMercFilters: any,
  codiciAtc: any,
  ditte: any,
  settori: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },

    {
      type: "selectWithLevels",
      id: "atc",
      label: "Codice ATC",
      displayExpr: "descrizione",
      dataSource: codiciAtc,
      idValueGrid: "atc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnalVendSotMarg = (
  newFarmaList: any,
  operatori: any,
  settori: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnalVenFascOrarOper = (
  newFarmaList: any,
  operatori: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
  ];
};

export const createItemFilterAnalProdFermi = (
  newFarmaList: any,
  gruppoMercFilters: any,
  ditte: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
    },
  ];
};



export const createItemFilterAnalVendSottMargOper = (
    newFarmaList: any,
    settori: any,
    operatori: any
   
) => {
    return [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        //{
        //type: "selectMultiplaMoreColon",
        //id: "progetto",
        //label: "Progetto",
        //displayExpr: "descrizione",
        //dataSource: progetto,
        //idValueGrid: "progetto" as any,
        //},
        //{
        //type: "selectMultiplaMoreColon",
        //id: "societa",
        //label: "Societa",
        //displayExpr: "descrizione",
        //dataSource: societa,
        //idValueGrid: "societa" as any,
        //},
        {
            type: "selectMultiplaMoreColon",
            id: "codiceSettore",
            label: "Settore di inventario ",
            displayExpr: "descrizione",
            dataSource: settori,
            idValueGrid: "codiceSettore" as any,
        },
        {
            type: "selectMultiplaMoreColonNoCodice",
            noId: true,
            id: "operatori",
            label: "Operatori",
            displayExpr: "operatore",
            dataSource: operatori,
            idValueGrid: "operatori" as any,
            allowSelectAll: false
        },
        {
            type: "ckBox",
            idValue: "venditaDPC",
            label: "Includi DPC",
            dataSource: null,
            width: 200,
        },
        {
            type: "ckBox",
            idValue: "flagBusta",
            label: "Includi BUSTE",
            dataSource: null,
            width: 200,
        },
    ];
};

export const createItemFilterCruscFasciaOrariaOper = (
    newFarmaList: any,
    operatori: any,
    giorniSettimana: any
) => {
    return [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        //{
        //type: "selectMultiplaMoreColon",
        //id: "progetto",
        //label: "Progetto",
        //displayExpr: "descrizione",
        //dataSource: progetto,
        //idValueGrid: "progetto" as any,
        //},
        {
            type: "selectMultiplaMoreColonNoCodice",
            noId: true,
            id: "operatori",
            label: "Operatori",
            displayExpr: "operatore",
            dataSource: operatori,
            idValueGrid: "operatori" as any,
            allowSelectAll: false
        },
        {
            type: "selectMultipla",
            id: "giorniSettimana",
            label: "Giorni settimana",
            displayExpr: "descrizione",
            dataSource: giorniSettimana,
            idValueGrid: "giorniSettimana" as any,
        },
        //{
        //type: "selectMultiplaMoreColon",
        //id: "societa",
        //label: "Societa",
        //displayExpr: "descrizione",
        //dataSource: societa,
        //idValueGrid: "societa" as any,
        //},
    ];
};