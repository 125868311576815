import React, { useState, useEffect } from "react"
import notify from "devextreme/ui/notify"
import NewTabella from "../../../widget/nuova_tabella"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import { AplicaValoreTO } from '../../../funzioni/operazioniSuTabelle'
import { legendByarray } from '../../../funzioni/formatoVariabili'
//import { DialogTransGiac } from "../dialog_giacenze/pop_trans_giac"
import { DialogTransGiacNew } from "../dialog_giacenze/pop_trans_giac_new"
import { DialogDettGiac } from "../dialog_giacenze/pop_dett_mag"
import { DialogProdMagazz } from "../dialog_giacenze/pop_prod_magazz"
import { DialogMagazzCarichi } from "../dialog_giacenze/pop_magazz_carichi"




interface TabellaProps {
  open: boolean,
  edit: any,
  ricarica: () => void,
  close: ()=>void
}

export const TabellaPlus: React.FC<TabellaProps> = (props:any) => {
  const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([]);
  const [openPop , setOpenPop ] = useState<boolean>(false)
  const [openPop2 , setOpenPop2 ] = useState<boolean>(false)
  const [openPop3 , setOpenPop3 ] = useState<boolean>(false)
  const [openPop4 , setOpenPop4 ] = useState<boolean>(false)
  const [openPop5 , setOpenPop5 ] = useState<boolean>(false)
  const [objtipo,setObjtipo] = useState<any>(null)
  const [edit,setImmediate] = useState<boolean>(false)
  const [loading, setLoading] = useState <boolean>(false);
  const auth = useAuth(urlConsultazione.auth);
  const [objctTOsave, setObjctTOsave] = useState<any>({});



  const visibleB = (e:any) =>{  
    //console.log(e.row.data);
    //console.log(e.row.data.giacenza);  
    if(e.row.data.giacenza > 0){
      return true;
    }else{
        return false;
    }     
  }


    const columns = [
        { caption: "IdFarmacia", dataField: "idFar", visible: false },
        { caption: "", width:230},
    { caption: "Ditta", dataField: "descrizioneFarmacia"  },
    { caption: "Giacenza", dataField: "giacenza", width: 100 },
    { caption: "Di Cui Invendibili" , dataField: 'giacenzaInvendibili',width: 100},
    { caption: "Prezzo Banca Dati" , dataField: 'prezzoListino',format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Prezzo farmacia" , dataField: 'prezzoListino',format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Costo ultimo m.p." , dataField: 'costoMedioPonderato',format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Data costo ultimo m.p." , dataField: 'dataCostoAcquisto', format: "dd/MM/yyyy", dataType: "date", width: 98 },
    { caption: "Azioni",  type: "buttons", buttons: [
        {
            hint: "Vendite/acquisti",
            icon: "chart",
           // visible:(x:any)=>visibleB(x),
            onClick: (e: any) => {
              setObjtipo(e.row.data)
              setOpenPop2(true) 
            }
        },
        {
          hint: "Trasferimenti",
          icon: "increaseindent",
          onClick: (e: any) => {
            let objToUseFor = {
              codCsf:e.row.data.codCsf,
              idFarmaDest: e.row.data.idFar ,
            }
            let datoUsabile = {...e.row.data}
            datoUsabile.codCsf = props?.data?.data.codCsf
            datoUsabile.generalCostoMedioPonderato = props?.data?.data.costoMedioPonderato
            datoUsabile.generalPrezzoListino = props?.data?.data.prezzoListino
            datoUsabile.showFarma = props?.data?.data?.farmaMulty
            datoUsabile.idFarDestinatario = e.row.data.idFar
            setObjtipo(datoUsabile)
            setOpenPop(true) 
          }
      },
      {
        hint: "Magazzini",
        icon: "columnfield",
       // visible:(x:any)=>visibleB(x),
        onClick: (e: any) => {     
          let datoUsabile = {...e.row.data}
          datoUsabile.codCsf = props?.data?.data.codCsf
          datoUsabile.generalCostoMedioPonderato = props?.data?.data.costoMedioPonderato
          datoUsabile.generalPrezzoListino = props?.data?.data.prezzoListino
          setObjtipo(datoUsabile)
          setOpenPop4(true) 
        }
    },
    {
      hint: "Movimentazione",
      icon: "folder",
      onClick: (e: any)  => {
        //console.log(e.row.data);     
        window.open("/app/consultazione/movimentazione?idFarm="+e.row.data.idFar+"&codCsf="+e.row.data.codCsf+'&r_farma='+e.row.data.descrizioneFarmacia+"&d_p="+e.row.data.descrizioneProdotto, "_blank");
                  
    }   
  },
  {
    hint: "Lista carichi",
    icon: "box",
   // visible:(x:any)=>visibleB(x),
    onClick: (e: any) => {
          let datoUsabile = {...e.row.data}
          datoUsabile.codCsf = props?.data?.data.codCsf
          datoUsabile.generalCostoMedioPonderato = props?.data?.data.costoMedioPonderato
            datoUsabile.generalPrezzoListino = props?.data?.data.prezzoListino
          setObjtipo(datoUsabile)
          setOpenPop5(true) 
    }
  }
    ], width: 200 }];


  const getListFarm = () =>{
    if (auth) {
       return RestServices().rest('POST', {} , urlConsultazione.getListFar)
    }
}

 

  const caricaDati = async() =>{
    if (auth) {
      //console.log(props?.data?.data?.farmaMulty);

        //console.log("caricadati")
        console.log(props?.data?.data)
      let parametri:any = {
          codCsfList: props?.data?.data.codCsfList
      }
      if(props?.data?.data?.farmaMulty.length > 0){
        parametri.idFar = props?.data?.data?.farmaMulty
      }
      //props?.data?.data?.farmaMulty
      let farmacieLista = await getListFarm()
      RestServices().rest('POST', parametri , urlConsultazione.getListGiacenzaProdFarma)
      .then(result => {
          if (result?.esito) {            
             // console.log(result.response);                        
              let valoreFormatt = legendByarray(
                result.response,
                farmacieLista.response,
                'idFar',
                'idFar',
                'descrix',
                ['descrizione'])

              setTabellaPrincipale(valoreFormatt)
              setLoading(false)
          }else{
              notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
              setLoading(false)
          }
      })
   }
}

  useEffect(() => {
   //console.log(props?.data?.data);
   
   
  }, []);

  useEffect(() => {
    
  }, []);

  useEffect(() => {
    if (auth) {
      caricaDati()
  
}
  }, [auth]);

  const ricarica =  () =>{
    setImmediate(false)
    setObjtipo(null)
    setOpenPop(false)
    setOpenPop2(false)
    setOpenPop3(false)
}

  return (
    <>

{openPop ? <DialogTransGiacNew  edit={objtipo} close={()=>{setOpenPop(false);setImmediate(false);setObjtipo(null)}} ricarica={()=>{ricarica();}}   open={openPop} /> : null } 

    {/* <DialogMagGiace  edit={objtipo} close={()=>{setOpenPop3(false);setImmediate(false);setObjtipo(null)}} ricarica={()=>{ricarica();}}   open={openPop3} /> */}
   
     <DialogDettGiac  edit={objtipo} close={()=>{setOpenPop2(false);setImmediate(false);setObjtipo(null)}} ricarica={()=>{ricarica();}}   open={openPop2} />

     <DialogProdMagazz  edit={objtipo} close={()=>{setOpenPop4(false);setImmediate(false);setObjtipo(null)}} ricarica={()=>{ricarica();}}   open={openPop4} />
     
     <DialogMagazzCarichi  edit={objtipo} close={()=>{setOpenPop5(false);setImmediate(false);setObjtipo(null)}} ricarica={()=>{ricarica();}}   open={openPop5} />

        <div style={{ width: '100%'}}>
          <NewTabella
            idTabella="elenco_list_farma_popup_plus"
            colonne={columns}
            width={'auto'}
            height={'auto'}
            pageSize={600}
            hidePager={false}
            dataSource={tabellaPrincipale}
            allowColumnResizing={true}
            hideColumnHeaders={true}
            specialPd={0.1}
            exportT={{
              enabled: false,
              fileName: "list_farm",
            }}
          />
        </div>
        </>
  )
};
