import notify from "devextreme/ui/notify";
import { default as React, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useAuth } from "../../hooks/useAuth";
import { RestServices } from "../../services/restServices";
import { Loading } from "../../widget/Notifications";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { urlCommon } from "../costanti";

interface PageProps {
    rowData: any;
    setIndietro: () => void;
}

export const ElencoEsportazioneFarmacia: React.FC<PageProps> = ({rowData,setIndietro}) => {

    const [isVisible, setIsVisible] = useState(false);
    const [esportazioniDettagli, setEsportazioniDettagli] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState<any>({});

    const auth = useAuth(urlCommon.auth);


    const exportDetails = () => {

        let idFar = rowData.idFar;
        //let idFar = 12333342;
        
        let codiceFlusso = rowData.codiceFlusso;

        //console.log(idFar)
        //console.log(codiceFlusso)

        let model = {
            idFar: idFar,
            codiceFlusso: codiceFlusso

        }

        //console.log(model);

        setParams(model);

        //console.log(params)

        if (auth) {
            RestServices().rest('POST', params, urlCommon.getExportDetails)
                .then(result => {
                    setIsVisible(false);

                    if (result) {
                        setIsVisible(false);
                        setEsportazioniDettagli(result.response);

                        if (result.response.length === 0) {

                            notify({ position: "center", width: "auto", message: "Nessun dettaglio da mostrare" }, "error", 4000)
                            setIndietro();
                        }

                    } else {
                        
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })

        }

    }

    useEffect(() => {
        exportDetails();
        //console.log(rowData)
    }, [auth]);

    const columnsEsportazioniDettagli = [
        { caption: "Data Inizio", dataField: "dataInizio", format: "dd/MM/yyyy HH:mm:ss", dataType: "datetime" },
        { caption: "Data Fine", dataField: "dataFine", format: "dd/MM/yyyy HH:mm:ss", dataType: "datetime" },
        { caption: "Nome File", dataField: "nomeFile" },
        { caption: "Esito", dataField: "esitoResponse" },

    ];

    return (
        <>
            <Loading isVisible={loading} />
            <div style={{ background: 'white', padding: 10, borderRadius: 10, }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h4 style={{ fontSize: 22, margin: 0 }}>Dettaglio Esportazione</h4></div>
                    {/*<div><button style={{ border: 'none', background: 'none', fontSize: 20 }} onClick={() => setOpen(!open)}>{open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</button></div>*/}
                </div>
            </div>
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div>
                    <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro(); }}>INDIETRO </Button>
                </div>
                <div>
                    <h4> <span style={{ fontWeight: 'bold' }}>{rowData.codiceFarmacia} {rowData.descrizioneFarmacia} - Nome Esportazione: {rowData.nomeFlusso}</span></h4>
                </div>

            </div>


            
            
            <NewTabella
                idTabella='elenco_dettagli_esportazioni'
                sync
                colonne={columnsEsportazioniDettagli}
                dataSource={esportazioniDettagli}
                filterRow
                searchPanel={true}
                exportT={{ enabled: true, fileName: "ElencoEsportazioniDettagli" }}
                activeSaver={true}
                columnC={true}
                headerFilter
                wordWrapEnabled={true}
            />


            </>

     );
};

      export default ElencoEsportazioneFarmacia;


