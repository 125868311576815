import React, { useEffect, useState } from 'react'
import { DialogConferma, Loading } from '../../widget/Notifications'
import notify from 'devextreme/ui/notify'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RadioGroup } from 'devextreme-react/radio-group'
import Button from 'react-bootstrap/Button'
import { CheckBox } from 'devextreme-react/check-box'
import DateBox from 'devextreme-react/date-box'
import TextBox from 'devextreme-react/text-box'
import { convertForSelectFarmacie ,trasformaDateTimeInLocaleNewF } from '../../funzioni/formatoVariabili'
import Select from 'react-select';




interface chekcField {
    label: string,
    fieldDatabase:string,
    defStatus?:boolean,
    callback: (v:boolean)=>void,
}

        const Field = React.memo<chekcField>((promps) =>{
            let { label , defStatus  } = promps

            const valuec = (e:any) =>{
                promps.callback(e.value)
            }

            return(
                <div className="dx-field">
                    <div style={{display:'flex',gap:10}} className="">
                    <CheckBox text={label} onValueChanged={valuec} value={defStatus ? true : false} />
                    <div className="">
                    </div>
                    </div>
                </div>
            )
        })


                


const ValorInventa = (props:  { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
     
    const auth = useAuth(urlConsultazione.auth) 
    const [loading,setLoading] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>()
    const [fieldCk, setFieldCk ] = useState<string[]>([])
    const [farmacie,setFarmacie] = useState<any>([])
    const [popConferma,setPopConferma] = useState<boolean>(false)
    const [messConferma,setMessConferma] = useState<string>('')


    const gestioneCk = (id:string) =>{
        let array = [...fieldCk]
        const index = array.indexOf(id);
       
        if (index > -1) { // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }else{
            array.push(id)
        }
        setFieldCk(array)
    }

    const checkIfTrue = (id:string) =>{
        
        
        let exist = fieldCk.includes(id);
        
        if(exist){
            return true
        }else{
            return false
        }
    }


    const setAvviaRichiesta = () =>{
        let ObjToUse:any = {}
        //console.log('pppp');
        if(objctTOsave.tipoDettaglio){
            ObjToUse.tipoDettaglio =  objctTOsave.tipoDettaglio
        }
        if(objctTOsave?.idFarmacie){
            let arrIds:any = []
            objctTOsave.idFarmacie.forEach((element:any) => {
                arrIds.push(element.value)
            });
            ObjToUse.idFarmacie =  arrIds
        }
        
        if(objctTOsave?.tipoDettaglio > 1){
            if(objctTOsave?.vendibili){
                ObjToUse.intermedioVendibili = true
                ObjToUse.raggruppatoVendibili = true
            }else{
                ObjToUse.intermedioVendibili = false
                ObjToUse.raggruppatoVendibili = false
            }
    
            if(objctTOsave?.nonVendibili){
                ObjToUse.intermedioNonVendibili = true
                ObjToUse.raggruppatononVendibili = true
    
            }else{
                ObjToUse.intermedioNonVendibili = false
                ObjToUse.raggruppatononVendibili = false
            }
        }

        
        if(objctTOsave.costoUltimo){
            ObjToUse.costoUltimo = true
        }else{
            ObjToUse.costoUltimo = false
        }
        if(objctTOsave.costoPonderato){
            ObjToUse.costoPonderato = true

        }else{
            ObjToUse.costoPonderato = false
        }
        if(objctTOsave.costoMedio){
            ObjToUse.costoMedio = true
        }else{
            ObjToUse.costoMedio = false
        }
        if(objctTOsave.costoGestionale){
            ObjToUse.costoGestionale = true
        }else{
            ObjToUse.costoGestionale = false
        }
        if(objctTOsave.dataCompetenza){
            ObjToUse.dataCompetenza = false
        }else{
            ObjToUse.dataCompetenza = false
        }
        if(objctTOsave.nomeFile){
            ObjToUse.nomeFile = objctTOsave.nomeFile
        }
        if(objctTOsave.dataRiferimento){
            ObjToUse.dataRiferimento = trasformaDateTimeInLocaleNewF(objctTOsave.dataRiferimento, true)
        }else{
            ObjToUse.dataRiferimento =  trasformaDateTimeInLocaleNewF(new Date() , true)
        }
        
        //console.log(ObjToUse);


        if(ObjToUse?.tipoDettaglio && ObjToUse?.idFarmacie?.length > 0 && (ObjToUse?.costoMedio || ObjToUse?.costoGestionale || ObjToUse?.costoUltimo || ObjToUse?.costoPonderato )){
            setLoading(true)
            RestServices().rest('POST', ObjToUse , urlConsultazione.getReportValInvent)
            .then(result => {
            if(result.esito){
               // console.log('resultss okkkkk');
               // console.log(result.response);
                setLoading(false)
                setPopConferma(false)
                notify({ position: "center", width: "auto", message: 'Richiesta presa in carico, il Report sara\' disponibile fra qualche minuto nella pagina dei Download'}, "success", 4000)
                setObjctTOsave({})
                                
            }
            else{
                //console.log(result);
                setLoading(false)
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }    
            }) 
        }else{
            setLoading(false)
            notify({ position: "center", width: "auto", message: 'Devi inserire almeno una farmacia e un tipo di valorizzazione'}, "warning", 4000)
        }

         

        
    }

    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {
                       //console.log(result.response);
                       setFarmacie(result.response)                                    
                   }else{
  
                   }
               })     
       }
   }

   const getReport = () =>{
    let obj = {
        "tipoDettaglio": 1,
        "idFarmacie": [17],
        "intermedioVendibili": false,
        "intermedioNonVendibili": false,
        "raggruppatoVendibili": false, 
        "raggruppatononVendibili": false,
        "costoUltimo": true,
        "costoPonderato": false,
        "costoMedio": false,
        "costoGestionale": false,
        "dataRiferimento": "09/11/2022",
        "nomeFile": "testCris222222"
    }
    if (auth) {
        RestServices().rest('POST', obj , urlConsultazione.getReportValInvent)
            .then(result => {
               if(result.esito){
                  // console.log('resultss marco');
                  // console.log(result.response);
                                   
               }
               else{
                //console.log(result);
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }    
            })   
     } 
}

   const radioGroup = [
    { id: 1, text: 'Singolo magazzino' },
    { id: 2, text: 'Aggregato per tipologia magazzino' },
    { id: 3, text: 'Aggregato per azienda e tipologia magazzino' }
   ]



    const cambiaValore = (tipo:string,value:string) =>{
        let objTo = {...objctTOsave}
        objTo[tipo] = value
        setObjctTOsave(objTo)
        //console.log(objTo);   
       
}
    

    useEffect(() => { 
        if (props.statoMenu) {            
          //  props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(()=>{
        //getProfUtenti()   
     },[auth])

    useEffect(()=>{
        if(auth){   
            //getReport()  
            farmaList()
        }
    },[auth])

  

    return (
        <>

                   <DialogConferma
                    dialogIsOpen={popConferma}
                    message={messConferma}
                    functionNext={()=>setAvviaRichiesta()}
                    handleCloseConferma={()=>{setPopConferma(false)}}
                />

          <Loading isVisible={loading} />
                <div className='title'>
                    <h3> Valorizzazione Inventario  <span className='subt' > </span> </h3>
                </div>


                <div style={{background:'#fff',padding:10, borderRadius:10, bottom:20}}>

                <div>
                    <h5>Seleziona dettaglio</h5>
                </div>

                <div style={{marginTop:8}}>
              
                <div className='form-row '> 
                <div className="form-group col-md-3">
                     <RadioGroup onValueChanged={(x)=>cambiaValore('tipoDettaglio',x.value.id)} value={objctTOsave?.tipoDettaglio ? radioGroup[objctTOsave?.tipoDettaglio-1] : null} dataSource={radioGroup}    /> 
               </div>
               {objctTOsave?.tipoDettaglio > 0 ? (
                <div className="form-group col-md-6">
                <label> FARMACIE </label> 
                   <div style={{width:400}}>
                     <Select styles={{
                             multiValue: (styles, { data }) => {
                                 //console.log(data);         
                                 return {
                                   ...styles,
                                    maxWidth: 80,
                                 };
                               },
                            }} onChange={(v:any)=>cambiaValore('idFarmacie',v)} isMulti  options={convertForSelectFarmacie(farmacie)} />
                     </div>
                </div>
               ) : null}
               
               {objctTOsave?.tipoDettaglio > 1 ? (
                 <div className="form-group col-md-3">
                 <label> Tipologia di magazzino </label> 
                 
                         <div style={{marginBottom:10}}>
                            <CheckBox text={'Prodotti vendibili'} onValueChanged={(x)=>cambiaValore('vendibili',x.value)}   value={objctTOsave?.vendibili ? objctTOsave?.vendibili : false} />
                        </div>
                     
                     
                         <div>
                            <CheckBox text={'Prodotti non vendibili'} onValueChanged={(x)=>cambiaValore('nonVendibili',x.value)}   value={objctTOsave?.nonVendibili ? objctTOsave?.nonVendibili : false} />

                        </div>
                     
 
                 </div>
               ) : (
                null
               )}
              

           </div>

           
         
           <hr></hr>

           <div style={{marginBottom:20}}>
                    <h5>Seleziona il tipo di valorizzazione</h5>
                </div>
                <div style={{display:'flex',gap:20}}>
                    <CheckBox text={'Costo ultimo'} onValueChanged={(x)=>cambiaValore('costoUltimo',x.value)} value={objctTOsave?.costoUltimo ? objctTOsave?.costoUltimo : false} />
                    <CheckBox text={'Costo medio ponderato continuo'} onValueChanged={(x)=>cambiaValore('costoPonderato',x.value)} value={objctTOsave?.costoPonderato ? objctTOsave?.costoPonderato : false} />
                    <CheckBox text={'Costo medio annuo'} onValueChanged={(x)=>cambiaValore('costoMedio',x.value)} value={objctTOsave?.costoMedio ? objctTOsave?.costoMedio : false} />
                    <CheckBox text={'Costo gestionale'} onValueChanged={(x)=>cambiaValore('costoGestionale',x.value)} value={objctTOsave?.costoGestionale ? objctTOsave?.costoGestionale : false} />

                </div>

              <hr></hr>

              <div style={{marginBottom:20}}>
                    <h5> Seleziona la data di riferimento e il nome del file</h5>
                </div>
                <div className='form-row '>
                      <div className="form-group col-md-6">        
                     <DateBox valueChangeEvent="keyup" showClearButton={false}  value={objctTOsave && objctTOsave.dataRiferimento ?  objctTOsave.dataRiferimento : new Date()}  onValueChanged={(v)=>cambiaValore('dataRiferimento',v.value) }  type="date" displayFormat="dd/MM/yyyy" />   
                     <td>
                        <div style={{marginTop:20}}>
                            <CheckBox text={"Utilizza la data di competenza per la generazione dell'inventario (per i soli acquisti se presente viene utilizzata la data di ricezione merce, altrimenti la data del documento di trasporto)."} onValueChanged={(x)=>cambiaValore('dataCompetenza',x.value)}  value={objctTOsave?.dataCompetenza ? objctTOsave?.dataCompetenza : false} />

                        </div>
                    </td>
                       </div>
                       <div className="form-group col-md-6">

                       <TextBox maxLength="20" onValueChanged={(e:any)=>cambiaValore('nomeFile',e.value)}  value={objctTOsave?.nomeFile} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci il nome del file" />
                       </div>

                       </div>
             

                </div>
             

                <div style={{textAlign:'center'}}>  

                    <Button disabled={objctTOsave?.tipoDettaglio ? false :true} style={{ marginTop:30, marginLeft:10 , width:200 }} id="modalConfermaOk" type="success" variant="success" 
                      onClick={() => {
                          if (objctTOsave?.tipoDettaglio && (objctTOsave?.tipoDettaglio == 2 || objctTOsave?.tipoDettaglio == 3)) {
                              if ((objctTOsave?.vendibili == undefined && objctTOsave?.nonVendibili == undefined) || (objctTOsave?.vendibili == false && objctTOsave?.nonVendibili == false) ||
                                  (objctTOsave?.vendibili == false && objctTOsave?.nonVendibili == undefined) || (objctTOsave?.vendibili == undefined && objctTOsave?.nonVendibili == false))
                              {
                                  notify({ position: "center", width: "auto", message: "Per il dettaglio aggregato e' obbligatorio selezionare la tipologia di magazzino" }, "error", 4000)
                                  return;
                              }
                          }
                      setPopConferma(true)
                      setMessConferma ( "Creare una procedura batch per l'elaborazione di un REPORT contenente la valorizzazione del magazzino, relativa ai filtri impostati? Confermare? ")
                      }  
                    } >
                       AVVIA RICHIESTA
                    </Button>
                                 
                    <Button onClick={()=>setObjctTOsave({})}  style={{ marginTop:30, marginLeft:10,  width:200 }} id="modalConfermaOk" variant="info" >
                       ANNULLA
                    </Button>

                </div>

                

                </div>

                </>

               

        )
   }

export default ValorInventa
