
import React, { useEffect } from 'react'



const InfoOrdini = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   

    useEffect(() => { 
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

   
    return (
        <>
     
             <div className='form-row '> 
                <div className="form-group col-md-6">
                 
                <div style={{  marginLeft:10 , marginTop:20, color:'#337ab7'   }}>
                <h3 className='font-size:6vw'>Ordine centralizzato</h3>
                </div>

                <div style={{ padding:10, marginRight:30 , marginTop:20 , borderRadius:10 , backgroundColor:'#fff', color:'#337ab7' }}  >
                  <h5 className='font-size:2vw '>
                  Con la piattaforma <strong>CloudFarma</strong> e' possibile emettere ordini centralizzati con la massima flessibilita' guidati con un sistema evoluto di calcolo del fabbisogno.
                     </h5>
                     </div> 
                     
                </div>   
             <div className="form-group col-md-6" >
                 <div style={{ marginRight:10 , marginTop:20 , borderRadius:20  }}  >
                 <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_ordini_arg.png" alt="ordini" />

                     </div>
                     <div style={{ marginRight:10 , marginTop:20, borderRadius:20   }}  >
                     <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_ordini.png" alt="ordini" />
                 </div>
            </div> 
            </div> 
           
        </>
    )
}

export default InfoOrdini