import React, { useEffect, useState } from 'react'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { CarichiInterface, ClasseAInterface, GruppiInterface, OggettoInterface, SettoriInterface } from "../../interfaces/generiche";
import RadioGroup from "devextreme-react/radio-group";
import TextBox from "devextreme-react/text-box"
import Select from 'react-select';
import CheckBox from "devextreme-react/check-box"
import { convertForSelectClasse, convertForDefaultSingle, convertForDefaultMultiple, convertForSelectFarmacie, convertForSelectGruppo, convertForSelectLinea, convertForSelectSettore, trasformaDateTimeInLocaleNewF, convertForDefaultMultipleDitta } from '../../funzioni/formatoVariabili';
import { AutocompleteBox } from '../../widget/Autocomplete';
import { Selezione, SelezioneMultipla, SelezioneMultiplaGerarchica } from '../../widget/Select';
import Button from 'react-bootstrap/Button'
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import { urlBancaDati, urlCommon, urlConsultazione } from '../costanti';
import { useAuth } from '../../hooks/useAuth';
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { Loading } from '../../widget/Notifications';
import { BoxFiltro } from '../../widget/Editor';

interface PageProps {
    typeEdit: boolean,
    edit: any;
    ricarica: () => void;
    close: () => void;
    setIndietro: () => void,
    typeInsert: boolean,
    farmList: any[],
    viewMode: boolean
}


export const RichiestaLivellamentoMagazzino: React.FC<PageProps> = ({
    close,
    edit,
    setIndietro,
    typeEdit,
    typeInsert,
    farmList,
    viewMode
}) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [classeA, setClasseA] = useState<ClasseAInterface[]>()
    const [classeC, setClasseC] = useState<ClasseAInterface[]>()
    const [sopOtc, setSopOtc] = useState<ClasseAInterface[]>()
    //const [farmacie, setFarmacie] = useState<any>([])
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [infoRipartizione, setInfoRipartizione] = useState<any>();
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [hideTabellaRipartizione, setHideTabellaRipartizione] = useState<boolean>(true)
    const [selectedIndex, setSelectedIndex] = useState<any>()
    const [gruppi, setGruppi] = useState<GruppiInterface[]>();
    const [settori, setSettori] = useState<SettoriInterface[]>();
    const [linea, setLinea] = useState<any[]>()
    const [idDitta, setIdDitta] = useState<any>();
    const [farmacieSelezionate, setFarmacieSelezionate] = useState<any[]>()
    const auth = useAuth(urlCommon.auth);
    const [carichi, setCarichi] = useState<CarichiInterface[]>();
    const [giorniCopertura, setGiorniCopertura] = useState<any[]>();
    const [viewModeElaborato, setViewModeElaborato] = useState<boolean>(false);
    const [richiestaVuota, setRichiestaVuota] = useState<boolean>(false);
    const [flagImposta, setFlagImposta] = useState<boolean>(false);
    function padTo2Digits(num: number) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date: any) {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('/');
    }

    useEffect(() => {
        if (auth) {

            getDettaglioRichiesta(edit)
            

            setLoading(true)
            cambiaValore('idFarmacie', farmList as unknown as string, 'Select')

            if (!gruppi || gruppi == undefined)
                RestServices().rest('POST', "", urlBancaDati.getGruppoMerceologico).then(result => {
                    if (result) setGruppi(result);
                })
            if (!settori || settori == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                    if (result) setSettori(result);
                })

            //farmaList()

            if (!classeA || classeA == undefined)
                RestServices().rest('POST', "", urlBancaDati.getClasseA).then(result => {
                    if (result) setClasseA(result);
                })
            if (!classeC || classeC == undefined)
                RestServices().rest('POST', "", urlBancaDati.getClasseC).then(result => {
                    if (result) setClasseC(result);
                })
            if (!sopOtc || sopOtc == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSopOtc).then(result => {
                    if (result) setSopOtc(result);
                })

            if (!giorniCopertura || giorniCopertura == undefined) {


                let param = {idLivellamento: undefined, idTipoRiaprtizione: undefined};
                if (viewMode)
                    param = { idLivellamento: edit.id, idTipoRiaprtizione: undefined}
                RestServices().rest('POST', param, urlCommon.getGiorniCopertura).then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setGiorniCopertura(result.response);
                            
                        }
                        else {

                        }
                    } else {

                    }
                })

            }

            if (!carichi || carichi == undefined) {

                let newArr: any = []
                farmList.forEach((element: any) => {
                    newArr.push(element.value)

                });
            
                //let params = {
                //    dal: getDefaultDal(),
                //    al: getDefaultAl(),
                //    //idFar: 0

                //};

                let params = {}

                if (!viewMode) {
                    //params = {
                    //    dal: getDefaultDal(),
                    //    al: getDefaultAl(),
                    //}

                    //RestServices().rest('POST', params, urlConsultazione.getListCarichi).then(result => {
                    //    if (result) {
                    //        let carArr: any[] = [];
                    //        result.response.forEach((carico: any) => {

                    //            var data = new Date(carico.dataBolla);
                    //            let car = {

                    //                id: carico.idCarico,
                    //                codiceFarmacia: carico.codFarmacia,
                    //                fornitore: carico.ragioneSocialeFornitore,
                    //                numeroBolla: carico.numeroBolla,
                    //                dataBolla: carico.dataBolla,
                    //                descrizione: carico.numeroBolla + ' del ' + formatDate(data)
                    //            }
                    //            carArr.push(car)
                    //        });
                    //        setCarichi(carArr);
                    //        setLoading(false)
                    //    }
                    //    else {
                    //        setLoading(false)
                    //    }

                    //})
                    setLoading(false)
                }
                else
                    setLoading(false)

                
                
               
            }

        }
    }, [auth])


    const getDettaglioRichiesta = async (richiesta: any) => {

        if (richiesta) {
            let params = { id: richiesta.id };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getRichiesta)
                    .then(result => {
                        if (result?.esito) {
                            setObjctTOsave(result.response)
                            //console.log(result.response)
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                    })

            }

        }
    }


    const getDefaultDal = () => {

        var d = new Date();
        d.setMonth(d.getMonth() - 3);

        return d;

    }

    const getDefaultAl = () => {

        var d = new Date();
        return d;
    }

    useEffect(() => {


        //setLoading(true)
        //console.log(objctTOsave)
        if (objctTOsave.idCarico != null && viewMode) {
            if (viewMode) {
                let params = {
                    
                    idCarico: objctTOsave.idCarico
                }
                setLoading(true)
                RestServices().rest('POST', params, urlConsultazione.getListCarichi).then(result => {
                    if (result) {
                        let carArr: any[] = [];
                        result.response.forEach((carico: any) => {

                            var data = new Date(carico.dataBolla);
                            let car = {

                                id: carico.idCarico,
                                codiceFarmacia: carico.codFarmacia,
                                fornitore: carico.ragioneSocialeFornitore,
                                numeroBolla: carico.numeroBolla,
                                dataBolla: carico.dataBolla,
                                descrizione: carico.numeroBolla + ' del ' + formatDate(data)
                            }
                            carArr.push(car)
                        });
                        setCarichi(carArr);
                        setLoading(false)
                    }

                })


            }
        }

        if (objctTOsave.idDittaBancaDati != null) {

            if (idDitta == undefined || idDitta != objctTOsave.idDittaBancaDati) {
                let param = { idDittaTitolare: objctTOsave.idDittaBancaDati }
                setLoading(true)
                RestServices().rest('POST', param, urlConsultazione.findProdottiDitta).then(result => {
                    if (result) {
                        setLinea(result.response);
                        setLoading(false)
                    }
                        
                        
                        setIdDitta(objctTOsave.idDittaBancaDati)
                })
            }


        }
        else {
            setLinea([]);
        }

        if (objctTOsave.idFarmacie) {
            //console.log(objctTOsave)
            let listItemFar: any[] = []

            objctTOsave.idFarmacie.forEach((element: any) => {
                listItemFar.push({ idFarmacia: element.value, farmacia: element.label })
            });

            /*setFarmacieSelezionate(listItemFar)*/
        }

        if (objctTOsave.flagRichiestaVuota == undefined || objctTOsave.flagRichiestaVuota == false)
            if (objctTOsave.idDittaBancaDati == undefined ||  objctTOsave.titolo == undefined || objctTOsave.idFarmacie == undefined || selectedIndex == undefined) {
                setDisableButton(true)
            }
            else
                setDisableButton(false)

        if (objctTOsave.flagRichiestaVuota != undefined && objctTOsave.flagRichiestaVuota == true)
            if ( objctTOsave.titolo == undefined || objctTOsave.idFarmacie == undefined ){
                setDisableButton(true)
            }
            else
                setDisableButton(false)


        if (objctTOsave.idTipoRipartizione && viewMode && !viewModeElaborato) {
            groupBoxValueChanged((Number(objctTOsave.idTipoRipartizione) - 1))
            setViewModeElaborato(true)
            
        }

        setRichiestaVuota(objctTOsave.flagRichiestaVuota)

        
        

    }, [objctTOsave])


    const impostaFiltri = (newFiltro: OggettoInterface) => {
        //console.log(newFiltro)
        if (newFiltro.idCarico) {

            cambiaValore('idCarico', newFiltro.idCarico)
        }
        else {
            let array = Object.getOwnPropertyNames(newFiltro);

            array.forEach((element: any) => {
                cambiaValore(element as string, newFiltro[element] )    

            });
            
        }

    }

    //const farmaList = () => {
    //    if (auth) {
    //        RestServices().rest('POST', {}, urlConsultazione.getListFar)
    //            .then(result => {
    //                if (result?.esito) {
    //                    //console.log(result.response);
    //                    setFarmacie(result.response)
    //                    setFarmacieSelect(convertForSelectFarmacie(result.response))
    //                } else {

    //                }
    //            })
    //    }
    //}

    const cambiaValore = (tipo: string, value: string, tipoControllo?: string) => {
        let objTo = { ...objctTOsave }

        if (tipoControllo === 'Select') {
            //console.log(value)
            //value � un array
            let myArray = value as unknown as Array<any>;
            let ids: any[] = [];
            myArray.forEach((element: any) => {
                ids.push(element.value)
                
            });
            if (tipo === 'idFarmacie') {
                setFarmacieSelezionate(myArray)
            }
            
            objTo[tipo] = ids;

        } else if (tipoControllo === 'SelectSingle') {
            let v : any = value as unknown as any
            objTo[tipo] = v.value;
        } else if (tipo.startsWith('giorniCoperturaCliente_'))
        {
            let idFarmacia = tipo.substring(tipo.indexOf('_') + 1, tipo.length)
            let arrGiorniCoperturaCliente: any[] = objTo['giorniCoperturaCliente']
            let found: boolean = false
            if (arrGiorniCoperturaCliente === undefined) {
                arrGiorniCoperturaCliente = []
            }
            arrGiorniCoperturaCliente.forEach((el: any) => {
                if (el.idFarmacia === Number(idFarmacia)) {
                    el.giorniCopertura = Number(value)
                    found = true
                }
            });
            if (!found) {
                arrGiorniCoperturaCliente.push({ idFarmacia: Number(idFarmacia), giorniCopertura: Number(value)})
            }

            objTo['giorniCoperturaCliente'] = arrGiorniCoperturaCliente
        } else if (tipo.startsWith('giorniCoperturaFornitore_')) {
            let idFarmacia = tipo.substring(tipo.indexOf('_') + 1, tipo.length)
            let arrGiorniCoperturaFornitore: any[] = objTo['giorniCoperturaFornitore']
            let found: boolean = false
            if (arrGiorniCoperturaFornitore === undefined) {
                arrGiorniCoperturaFornitore = []
            }
            arrGiorniCoperturaFornitore.forEach((el: any) => {
                if (el.idFarmacia === Number(idFarmacia)) {
                    el.giorniCopertura = Number(value)
                    found = true
                }
            });
            if (!found) {
                arrGiorniCoperturaFornitore.push({ idFarmacia: Number(idFarmacia), giorniCopertura: Number(value) })
            }

            objTo['giorniCoperturaFornitore'] = arrGiorniCoperturaFornitore
        }
        else {
            objTo[tipo] = value
        }

        //console.log(objTo)

        setObjctTOsave(objTo)
    }


    const colonneGridIncludiEscludi = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120, visible: false }
    ])

    const creaLivellamentoMagazzino = () => {

    }

    //const parametri = ['RIPARTISCI LE GIACENZE EQUAMENTE TRA TUTTE LE SEDI' , 'RIPARTISCI TUTTE LE ECCEDENZE TRA LE SEDI' , 'RIPARTISCI LE ECCEDENZE IN BASE AI GIORNI DI COPERTURA DELLE ALTRE SEDI' ];
    const parametri = [{ id: 0, text: 'RIPARTISCI LE GIACENZE EQUAMENTE TRA TUTTE LE SEDI' }, { id: 1, text: 'RIPARTISCI TUTTE LE ECCEDENZE TRA LE SEDI' }, { id: 2, text: 'RIPARTISCI LE ECCEDENZE IN BASE AI GIORNI DI COPERTURA DELLE ALTRE SEDI' }];
    const parametriInfo = ['Ripartisce uniformemente le giacenze dei prodotti tra le sedi selezionate', 'Ripartisce i prodotti tra le sedi selezionate garantendo il magazzino per i giorni di copertura inseriti', 'Ripartisce le eccedenze dei prodotti tra le sedi selezione in base ai giorni di copertura delle sedi da rifornire'  ]

    const insertRichiesta = () => {
        //console.log(objctTOsave)
        //console.log(richiestaVuota)
        if (selectedIndex == 1) {
            //cancellare dalloggetto la propriet� giorni copertura cliente
            delete objctTOsave['giorniCoperturaCliente']; 
        }
        if (selectedIndex == 0) {
            //cancellare dalloggetto la propriet� giorni copertura cliente
            delete objctTOsave['giorniCoperturaCliente'];
            delete objctTOsave['giorniCoperturaFornitore'];
        }

        delete objctTOsave['impostaGiorniCoperturaCliente'];
        delete objctTOsave['impostaGiorniCoperturaFornitore'];

        let param: {} = {};
        if (richiestaVuota == undefined || richiestaVuota == false )
            //param = { ...objctTOsave }
            Object.assign(param, objctTOsave)
        else
            param = { titolo: objctTOsave.titolo, idFarmacie: objctTOsave.idFarmacie, flagRichiestaVuota: objctTOsave.flagRichiestaVuota }

        if (auth) {
            RestServices().rest('POST', param, urlCommon.addLivellamentoMagazzino)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            notify({ position: "center", width: "auto", message: 'Richiesta inserita correttamente. Sara\' elaborata a breve' }, "success", 4000)
                            setIndietro()
                        }
                        else {
                            notify({ position: "center", width: "auto", message: 'Errore nell\'inserimento della richiesta' }, "error", 4000)
                        }
                    } else {
                        notify({ position: "center", width: "auto", message: 'Errore nell\'inserimento della richiesta' }, "error", 4000)
                    }
                })
        }
    }

    const updateRichiesta = () => {

    }

    const groupBoxValueChanged = (index: number) => {

        setSelectedIndex(index)
        setInfoRipartizione(parametriInfo[index])
        if (index == 1) {
            setHideTabellaRipartizione(true)
        }
        else
            setHideTabellaRipartizione(false)


        let tmp = { ...objctTOsave }
        // + 1 perche l'indice parte da 0 mentre sul db parte da 1
        tmp.idTipoRipartizione = index + 1



        let giorniCoperturaFilter: any[] = [];
        if (giorniCopertura && giorniCopertura.filter(f => f.idTipoRipartizione == tmp.idTipoRipartizione)[0]) {
            giorniCoperturaFilter = giorniCopertura.filter(f => f.idTipoRipartizione == tmp.idTipoRipartizione)[0].giorni
            if (giorniCoperturaFilter) {
                giorniCoperturaFilter.forEach((element: any) => {
                    let idFarmacia = element.idFarmacia;
                    let giorniCoperturaCliente = element.giorniCoperturaCliente
                    let giorniCoperturaFornitore = element.giorniCoperturaFornitore

                    let arrGiorniCoperturaCliente: any[] = tmp['giorniCoperturaCliente']
                    let found: boolean = false
                    if (arrGiorniCoperturaCliente === undefined) {
                        arrGiorniCoperturaCliente = []
                    }
                    arrGiorniCoperturaCliente.forEach((el: any) => {
                        if (el.idFarmacia === Number(idFarmacia)) {
                            el.giorniCopertura = Number(giorniCoperturaCliente)
                            found = true
                        }
                    });
                    if (!found) {
                        arrGiorniCoperturaCliente.push({ idFarmacia: Number(idFarmacia), giorniCopertura: Number(giorniCoperturaCliente) })
                    }

                    tmp['giorniCoperturaCliente'] = arrGiorniCoperturaCliente



                    let arrGiorniCoperturaFornitore: any[] = tmp['giorniCoperturaFornitore']
                    found = false;
                    if (arrGiorniCoperturaFornitore === undefined) {
                        arrGiorniCoperturaFornitore = []
                    }
                    arrGiorniCoperturaFornitore.forEach((el: any) => {
                        if (el.idFarmacia === Number(idFarmacia)) {
                            el.giorniCopertura = Number(giorniCoperturaFornitore)
                            found = true
                        }
                    });
                    if (!found) {
                        arrGiorniCoperturaFornitore.push({ idFarmacia: Number(idFarmacia), giorniCopertura: Number(giorniCoperturaFornitore) })
                    }

                    tmp['giorniCoperturaFornitore'] = arrGiorniCoperturaFornitore

                    //cambiaValore('giorniCoperturaFornitore_' + idFarmacia, giorniCoperturaFornitore)
                    //cambiaValore('giorniCoperturaCliente_' + idFarmacia, giorniCoperturaCliente)


                });
            }

        }

        //console.log(tmp)

        if (!viewMode && !viewModeElaborato)
            setObjctTOsave(tmp)

    }

    const onValueChanged = (e: any) => {
        groupBoxValueChanged(e.value)

    }

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const columns = [
        { caption: "Id Farmacia", dataField: "idFarmacia", visible: false },
        //{ caption: "Id Fornitore", dataField: "idAnagraficaFornitore", visible: false },
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Giorni copertura fornitore", dataField: "giorniCoperturaFornitore" },
        { caption: "Giorni copertura cliente", dataField: "giorniCoperturaCliente" },
        
    ]

    const columns2 = [
        { caption: "Id Farmacia", dataField: "idFarmacia", visible: false },
        //{ caption: "Id Fornitore", dataField: "idAnagraficaFornitore", visible: false },
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Giorni copertura fornitore", dataField: "giorniCoperturaFornitore" },
    ]

    const colonneGridCarichi = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "codiceFarmacia", caption: "Cod.Far", sortOrder: "asc" },
        { dataField: "fornitore", caption: "Fornitore", width: 120 },
        { dataField: "numeroBolla", caption: "Num.Bolla", width: 120 },
        { dataField: "dataBolla", caption: "Dt.Bolla", format: "dd/MM/yyyy", dataType: "date", width: 90 }
    ])



    function creaRighe() {

        let arr: any[] = [];
        if (selectedIndex != undefined && selectedIndex !== 0) {

            
            farmacieSelezionate && farmacieSelezionate.forEach((farmacia: any) => {
                let giorniCoperturaFilter : any[] = [];
                if (giorniCopertura && giorniCopertura.filter(f => f.idTipoRipartizione == selectedIndex +1)[0]) {

                        giorniCoperturaFilter = giorniCopertura?.filter(f => f.idTipoRipartizione == selectedIndex + 1)[0].giorni 
                }

                

                arr.push(

                    <div className="form-group col-md-12">
                        <Row>
                            <Col md="3">
                                {farmacia.label}
                            </Col>
                            <Col md="3">
                                <BoxFiltro
                                    id={'giorniCoperturaFornitore_' + farmacia.value}
                                    impostaFiltri={impostaFiltri}
                                    //value={giorniCoperturaFilter != undefined && giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0] != undefined ? giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaFornitore : flagImposta == true && objctTOsave["impostaGiorniCoperturaFornitore"] ? objctTOsave["impostaGiorniCoperturaFornitore"] : ""}
                                    value={flagImposta == true && objctTOsave["impostaGiorniCoperturaFornitore"] ? objctTOsave["impostaGiorniCoperturaFornitore"] : giorniCoperturaFilter != undefined && giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0] != undefined ? giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaFornitore : ""}

                                    disabled={viewMode || edit !== undefined}
                                />
                            </Col>
                            {selectedIndex != 1 && <Col md="3">
                                {/*<TextBox disabled={viewMode || edit !== undefined} id={'giorniCoperturaCliente_' + farmacia.value} onValueChanged={(v) => cambiaValore('giorniCoperturaCliente_' + farmacia.value, v.value)} placeholder="..." defaultValue={giorniCoperturaFilter != undefined && giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0] != undefined ? giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaCliente : ""} />*/}
                                <BoxFiltro
                                    id={'giorniCoperturaCliente_' + farmacia.value}
                                    impostaFiltri={impostaFiltri}
                                    //value={giorniCoperturaFilter != undefined && giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0] != undefined ? giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaCliente : ""}
                                    value={flagImposta == true && objctTOsave["impostaGiorniCoperturaCliente"] ? objctTOsave["impostaGiorniCoperturaCliente"] : giorniCoperturaFilter != undefined && giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0] != undefined ? giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaCliente : ""}
                                    disabled={viewMode || edit !== undefined}
                                />

                            </Col>}
                            
                            
                        </Row>

                    </div>
                );

            });


            return arr;
        }
        

    }

    const loadCarichi = () => {
        let params = {}
        if (!viewMode) {
            params = {
                dal: getDefaultDal(),
                al: getDefaultAl(),
            }
            setLoading(true)
            RestServices().rest('POST', params, urlConsultazione.getListCarichi).then(result => {
                if (result) {
                    let carArr: any[] = [];
                    result.response.forEach((carico: any) => {

                        var data = new Date(carico.dataBolla);
                        let car = {

                            id: carico.idCarico,
                            codiceFarmacia: carico.codFarmacia,
                            fornitore: carico.ragioneSocialeFornitore,
                            numeroBolla: carico.numeroBolla,
                            dataBolla: carico.dataBolla,
                            descrizione: carico.numeroBolla + ' del ' + formatDate(data)
                        }
                        carArr.push(car)
                    });
                    setCarichi(carArr);
                    setLoading(false)
                }
                else {
                    setLoading(false)
                }

            })

        }

    }

    const onBtnImpostaFornitoreClick = () => {

        setFlagImposta(true)
        //per tutte le farmacie impostare il giorno nei filtri
        farmacieSelezionate && farmacieSelezionate.forEach((farmacia: any) => {

            let filtro : any = {}
            let id: string = 'giorniCoperturaFornitore_' + farmacia.value
            if (objctTOsave.impostaGiorniCoperturaFornitore && objctTOsave.impostaGiorniCoperturaFornitore !== "" && objctTOsave.impostaGiorniCoperturaFornitore >= 0) {
                filtro[id] = objctTOsave.impostaGiorniCoperturaFornitore
            }
                
            else {
                // ripristinare il valore iniziale di default

                let giorniCoperturaFilter: any[] = [];
                if (giorniCopertura && giorniCopertura.filter(f => f.idTipoRipartizione == selectedIndex + 1)[0]) {

                    giorniCoperturaFilter = giorniCopertura?.filter(f => f.idTipoRipartizione == selectedIndex + 1)[0].giorni
                    filtro[id] = giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaFornitore
                }
            }

            //console.log(filtro)

            impostaFiltri(filtro)

        })

        
    }

    const onBtnImpostaClienteClick = () => {


        setFlagImposta(true)
        //per tutte le farmacie impostare il giorno nei filtri
        farmacieSelezionate && farmacieSelezionate.forEach((farmacia: any) => {

            let filtro: any = {}
            let id: string = 'giorniCoperturaCliente_' + farmacia.value
            if (objctTOsave.impostaGiorniCoperturaCliente && objctTOsave.impostaGiorniCoperturaCliente !== "" &&objctTOsave.impostaGiorniCoperturaCliente >= 0)
                filtro[id] = objctTOsave.impostaGiorniCoperturaCliente
            else {
                // ripristinare il valore iniziale di default

                let giorniCoperturaFilter: any[] = [];
                if (giorniCopertura && giorniCopertura.filter(f => f.idTipoRipartizione == selectedIndex + 1)[0]) {

                    giorniCoperturaFilter = giorniCopertura?.filter(f => f.idTipoRipartizione == selectedIndex + 1)[0].giorni
                    
                    filtro[id] = giorniCoperturaFilter.filter((f: { idFarmacia: any; }) => f.idFarmacia == farmacia.value)[0].giorniCoperturaCliente
                }
            }

            //console.log(filtro)

            impostaFiltri(filtro)

        })

    }

    return (
        <>
            <Loading isVisible={loading} />
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div>
                    <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro() }}>INDIETRO </Button>
                </div>
                {!viewMode && <div className="posbtn">
                     <Button className="btn btn-success" variant="success" onClick={() => {
                        if (typeInsert == true) {
                            insertRichiesta()
                        }

                        else
                            updateRichiesta()
                    }}
                        disabled={disableButton}>SALVA </Button>
                </div>
                }
            </div>

            <div style={{ background: '#fff', padding: 10, borderRadius: 10, bottom: 20, width: '100%' }}>

                <div className='form-row'>
                    <div className="form-group col-md-4">
                        <label> * Campi obbligatori </label>
                    </div>
                </div>

                <hr style={{marginTop:-5} }></hr>

                <div className='form-row' style={{marginTop:16} }>
                    <div className="form-group col-md-4">
                        <label> RICHIESTA VUOTA </label>
                        <CheckBox disabled={viewMode || edit !== undefined} value={objctTOsave.flagRichiestaVuota != undefined ? objctTOsave?.flagRichiestaVuota: false} onValueChanged={(v) => cambiaValore('flagRichiestaVuota', v.value)} />
                    </div>
                </div>

                <div style={{marginTop:24} }>
                    <h5>INTESTAZIONE </h5>
                </div>

                <div className='form-row' style={{ marginBottom: 20 }}>
                    <div className="form-group col-md-6">
                        <label> TITOLO * </label>
                        <TextBox maxLength={100} disabled={viewMode || edit !== undefined} value={objctTOsave.titolo} onValueChanged={(v) => cambiaValore('titolo', v.value)} placeholder="..." />
                    </div>
                    <div className="form-group col-md-6">
                        <label> SEDI *</label>
                        <div >
                            <Select styles={{
                                multiValue: (styles, { data }) => {
                                    //console.log(data);         
                                    return {
                                        ...styles,
                                        width: 80,
                                    };
                                },
                            }} isDisabled={viewMode || edit !== undefined} placeholder="Seleziona..."
                                onChange={(v: any) => cambiaValore('idFarmacie', v, 'Select')} isMulti
                                options={farmList} defaultValue={farmList} />
                        </div>
                    </div>

                </div>

                <div className='form-row'>


                    <div className="form-group col-md-6">
                        <label> DESCRIZIONE </label>
                        <TextBox maxLength={255 } disabled={viewMode || edit !== undefined} value={objctTOsave.descrizione} onValueChanged={(v) => cambiaValore('descrizione', v.value)} placeholder="..." />
                    </div>

                    <div className="form-group col-md-6">
                        <label> NOTE </label>
                        <TextBox maxLength={255}  disabled={viewMode || edit !== undefined}  value={objctTOsave.note} onValueChanged={(v) => cambiaValore('note', v.value)} placeholder="..." />
                    </div>

                </div>

                <hr></hr>
                

                <div style={{visibility: richiestaVuota == undefined || richiestaVuota == false ? 'visible' : 'hidden'} }>
                    <div style={{ marginTop: 24 }}>
                        <h5>FILTRI </h5>
                    </div>
                    <div className='form-row' style={{ marginBottom: 20 }} >
                        <div className="form-group col-md-6">
                            <label> DITTA * </label>
                            <AutocompleteBox disabled={viewMode || edit !== undefined}
                                typeApi={"ditta"} idApi={"codice"} objctTOsave={objctTOsave}
                                setObjctTOsave={setObjctTOsave} idExpr={"idDittaBancaDati"}
                                descExpr={"descrizioneDittaBancaDati"} idPost={"codice"}
                                descPost={"ragioneSociale"} idControl={"dittaBancaDati"}
                                idOpt={"codice"} descOpt={"ragioneSociale"} width={600}></AutocompleteBox>
                        </div>

                        <div className="form-group col-md-6">
                            <label> LINEA </label>
                            <div >
                                <Select styles={{
                                    multiValue: (styles, { data }) => {
                                        //console.log(data);         
                                        return {
                                            ...styles,
                                            width: 'auto',
                                        };
                                    },
                                }} isDisabled={viewMode || edit !== undefined}
                                    placeholder="Seleziona..."
                                    onChange={(v: any) => cambiaValore('idDittaLinea', v, 'Select')} isMulti
                                    options={convertForSelectLinea(linea)}
                                    noOptionsMessage={({ inputValue }) => !inputValue ? "Nessun risultato" : "Nessun risultato"}
                                    value={viewMode ? convertForDefaultMultipleDitta(objctTOsave.idLinea, linea) : undefined}
                                //value={{value:"219", label:"CIAO"}}
                                />
                            </div>

                        </div>
                    </div>

                    <div className='form-row'>
                        <div className="form-group col-md-6">
                            <label> SETTORE INVENTARIO </label>
                            <div >
                                <Select styles={{
                                    multiValue: (styles, { data }) => {
                                        //console.log(data);         
                                        return {
                                            ...styles,
                                            width: 'auto',
                                        };
                                    },
                                }} isDisabled={viewMode || edit !== undefined} placeholder="Seleziona..."
                                    onChange={(v: any) => cambiaValore('idSettore', v, 'Select')} isMulti
                                    value={viewMode ? convertForDefaultMultiple(objctTOsave.idSettore, settori) : undefined}
                                    options={convertForSelectSettore(settori)} />
                            </div>
                        </div>

                        <div className="form-group col-md-6">
                            <label> GRUPPO MERCEOLOGICO </label>
                            <div >
                                <Select styles={{
                                    multiValue: (styles, { data }) => {
                                        //console.log(data);         
                                        return {
                                            ...styles,
                                            width: 'auto',
                                        };
                                    },
                                }} isDisabled={viewMode || edit !== undefined}
                                    isClearable={true} placeholder="Seleziona..."
                                    onChange={(v: any) => cambiaValore('idGruppo', v, 'Select')}
                                    value={viewMode ? convertForDefaultMultiple(objctTOsave.idGruppo, gruppi) : undefined}
                                    isMulti={true} options={convertForSelectGruppo(gruppi)} />
                            </div>

                        </div>
                    </div>

                    <div className='form-row'>
                        <div className="form-group col-md-4">
                            <label> CLASSE A </label>
                            <div >
                                <Select styles={{
                                    multiValue: (styles, { data }) => {
                                        //console.log(data);         
                                        return {
                                            ...styles,
                                            width: 'auto',
                                        };
                                    },
                                }}
                                    isDisabled={viewMode || edit !== undefined} isClearable={true}
                                    placeholder="Seleziona..." onChange={(v: any) => cambiaValore('idClasseA', v, 'SelectSingle')}
                                    isMulti={false} options={convertForSelectClasse(classeA)}
                                    //defaultValue={classeA && edit.idClasseA && convertForDefaultSingle(edit.idClasseA, classeA)}
                                    value={viewMode ? convertForDefaultSingle(objctTOsave.idClasseA, classeA) : undefined}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label> CLASSE C </label>
                            <div >
                                <Select styles={{
                                    multiValue: (styles, { data }) => {
                                        //console.log(data);         
                                        return {
                                            ...styles,
                                            width: 'auto',
                                        };
                                    },
                                }} isDisabled={viewMode || edit !== undefined} isClearable={true}
                                    placeholder="Seleziona..."
                                    onChange={(v: any) => cambiaValore('idClasseC', v, 'SelectSingle')}
                                    isMulti={false} options={convertForSelectClasse(classeC)}
                                    value={viewMode ? convertForDefaultSingle(objctTOsave.idClasseC, classeC) : undefined}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4">
                            <label> SOP/OTC </label>
                            <div >
                                <Select styles={{
                                    multiValue: (styles, { data }) => {
                                        //console.log(data);         
                                        return {
                                            ...styles,
                                            width: 'auto',
                                        };
                                    },
                                }} isDisabled={viewMode || edit !== undefined} isClearable={true}
                                    placeholder="Seleziona..."
                                    onChange={(v: any) => cambiaValore('idSopOtc', v, 'SelectSingle')}
                                    isMulti={false} options={convertForSelectClasse(sopOtc)}
                                    value={viewMode ? convertForDefaultSingle(objctTOsave.idSopOtc, sopOtc) : undefined}
                                />
                            </div>
                        </div>
                    </div>


                    <div className='form-row'>

                        <div className="form-group col-md-4">
                            <label> CARICO </label>
                            <SelezioneMultipla
                                id="idCarico"
                                dataSource={carichi}
                                impostaFiltri={impostaFiltri}
                                column={colonneGridCarichi}
                                selection="multiple"
                                disabled={viewMode || edit !== undefined}
                                valueGrid={viewMode ? objctTOsave.idCarico : undefined}
                                loadOnOpenedFlag={true}
                                onOpened={loadCarichi }

                            ></SelezioneMultipla>
                        </div>
                    </div>


                    <hr></hr>
                    <div style={{ marginTop: 24 }}>
                        <h5>PARAMETRI DI CALCOLO</h5>
                    </div>
                    &nbsp;
                    <div className='form-row'>
                        <div className="form-group col-md-4">
                            <label> EQUIVALENTI </label>
                            <CheckBox disabled={viewMode || edit !== undefined} value={objctTOsave?.flagEquivalenti != undefined ? objctTOsave?.flagEquivalenti : false} onValueChanged={(v) => cambiaValore('flagEquivalenti', v.value)} />
                        </div>

                        <div className="form-group col-md-4">
                            <label> ALTERNATIVI </label>
                            <CheckBox disabled={viewMode || edit !== undefined} value={objctTOsave?.flagAlternativi != undefined ? objctTOsave?.flagAlternativi : false} onValueChanged={(v) => cambiaValore('flagAlternativi', v.value)} />
                        </div>

                        <div className="form-group col-md-4">
                            <label> ANALOGHI </label>

                            <CheckBox disabled={viewMode || edit !== undefined} value={objctTOsave?.flagAnaloghi != undefined ? objctTOsave?.flagAnaloghi : false} onValueChanged={(v) => cambiaValore('flagAnaloghi', v.value)} />
                        </div>
                    </div>
                    <div className='form-row' style={{ marginBottom: 20 }} >
                        <div className="form-group col-md-6">
                            <label> </label>
                            <RadioGroup disabled={viewMode || edit !== undefined}
                                items={parametri}
                                //value={viewMode ? parametri[Number(objctTOsave.idTipoRipartizione - 1)] : parametri[-1]}
                                //defaultValue={viewMode && objctTOsave.idTipoRipartizione ? Number(objctTOsave.idTipoRipartizione - 1) : -1}
                                value={viewMode ? Number(objctTOsave?.idTipoRipartizione) - 1 : undefined}
                                layout="vertical"
                                onValueChanged={onValueChanged}
                                valueExpr="id" displayExpr="text"
                            />
                        </div>

                    </div>

                    <div className='form-row' style={{ marginBottom: 20 }} >
                        <div className="form-group col-md-6">
                            <p style={{ fontStyle: 'italic' }}>{infoRipartizione}</p>
                        </div>
                    </div>

                    {
                        (selectedIndex != undefined && selectedIndex != 0) &&
                        <div className="form-group col-md-12">

                            <Row>
                                <Col md="3">
                                    <label>
                                        FARMACIA
                                    </label>
                                </Col>
                                <Col md="1">
                                    <label>
                                        GIORNI COPERTURA FORNITORE
                                    </label>
                                    </Col>
                                    <Col md="1">
                                        <BoxFiltro
                                            id={'impostaGiorniCoperturaFornitore'}
                                            impostaFiltri={impostaFiltri}
                                            disabled={viewMode || edit !== undefined}
                                        />
                                    </Col>
                                    <Col md="1">
                                        <Button
                                            style={{ float: 'right' }}
                                            variant="success"
                                            onClick={onBtnImpostaFornitoreClick}
                                            title={"Spalma i giorni di copertura"}
                                        >IMPOSTA</Button>
                                    </Col>
                                    {selectedIndex != 1 &&
                                        <>
                                            <Col md="1">
                                                <label>
                                                    GIORNI COPERTURA CLIENTE
                                                </label>
                                        
                                        </Col>
                                        <Col md="1">
                                            <BoxFiltro
                                                id={'impostaGiorniCoperturaCliente'}
                                                impostaFiltri={impostaFiltri}
                                                disabled={viewMode || edit !== undefined}
                                            />
                                        </Col>
                                        <Col md="1">
                                            <Button
                                                style={{ float: 'right' }}
                                                variant="success"
                                                onClick={onBtnImpostaClienteClick}
                                                title={"Spalma i giorni di copertura"}
                                            >IMPOSTA</Button>
                                        </Col>
                                    </>  
                                    }


                            </Row>
                        </div>
                    }
                    {creaRighe()}

                </div>

                


            </div>
        </>
    )

}


export default RichiestaLivellamentoMagazzino 