import React, { useEffect, useRef, useState } from "react"
import { Loading } from "../../widget/Notifications"
import Button from 'react-bootstrap/Button'
import { urlConsultazione, urlGestioneUdi, urlGestioneUdiV2 } from "../costanti";
import { useAuth } from "../../hooks/useAuth";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import DateBox from 'devextreme-react/date-box'
import Select from 'react-select';
import { convertForSelectTipoMovimentoRegistrazioneUdi } from "../../funzioni/formatoVariabili";
import { confirm } from 'devextreme/ui/dialog';
import { SelectBox } from 'devextreme-react/select-box'
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { Accordion, TextBox } from "devextreme-react";
import { Center } from "devextreme-react/map";
import Image from 'react-bootstrap/Image';
import bar from '../../immagini/bar.png';
import dropdown from '../../immagini/dropdown.png';
import ImageIcon from "./ImageIcon";

const InserimentoUdi = (props: { datiFarmacie: ListaFarmacieInterface[], authUdi:boolean|undefined, authCommon:boolean | undefined }) => {

    //const auth = useAuth(urlGestioneUdi.auth);

    const [loading, setLoading] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [daRegistrare, setDaRegistrare] = useState<boolean>(false)
    const [prodottoTrovato, setProdottoTrovato] = useState<boolean>(false)
    const [farmacie, setFarmacie] = useState<any>([])
    const [farmacieSelezionate, setFarmacieSelezionate] = useState<any[]>()
    const [codCsf, setCodCsf] = useState<number>()
    const [tipoMovimento, setTipoMovimento] = useState<any[]>()
    const [disableConferma, setDisableConferma] = useState<boolean>(true)
    const [infoProdotto, setInfoProdotto] = useState<string>()
    const [infoRegistra, setInfoRegistra] = useState<string>()
    const [selectedFarm, setSelectFarm] = useState(null)

    const [codiceProdotto, setCodiceProdotto] = useState("")
    const [codiceUdi, setCodiceUdi] = useState("")
    const [data, setData] = useState<any>()
    const [tipoMovimentoS, setTipoMovimentoS] = useState("")
    const [farmaciaS, setFarmaciaS] = useState("")

    const [infoDescrizioneProdotto, setInfoDescrizioneProdotto] = useState<string>()
    const [infoDittaProdotto, setInfoDittaProdotto] = useState<string>()
    const [infoMinsanProdotto, setInfoMinsanProdotto] = useState<string>()
    const [infoEanProdotto, setInfoEanProdotto] = useState<string>()
    const [onType, setOnType] = useState<boolean>(false);


    const now = new Date();

    useEffect(() => {
        setData(now)
        tipoMovimentoList()
    }, []);


    const infos = [{
        'ID': 1,
        'InfoName': 'Informazioni',
        'Testo': '',
    }]

    useEffect(() => {
        if (props.authUdi) {
            //per cgm ad una credenziali corrispone una farmacia, quindi si suppone che farmaList restituisca sempre una farmacia
            farmaList()

            objctTOsave.data = now


        }
    }, [props.authUdi])



    const cambiaValore = (tipo: string, value: string, tipoControllo?: string) => {
        let objTo = { ...objctTOsave }

        if (tipoControllo === 'Select') {
            //console.log(value)
            //value � un array
            let myArray = value as unknown as Array<any>;
            let ids: any[] = [];
            myArray.forEach((element: any) => {
                ids.push(element.value)

            });
            if (tipo === 'idFarmacie') {
                setFarmacieSelezionate(myArray)
            }

            objTo[tipo] = ids;

        } else if (tipoControllo === 'SelectSingle') {
            let v: any = value as unknown as any
            objTo[tipo] = v.value;
        } else {
            objTo[tipo] = value
        }

        setObjctTOsave(objTo)
       //console.log(objTo)


        if (objTo.codiceUdi == undefined || objTo.codiceUdi == "" || codCsf == undefined || objTo.data == undefined || objTo.tipoMovimento == undefined)
            setDisableConferma(true)
        else
            setDisableConferma(false)
    }

    const farmaList = () => {
        if (props.authCommon) {
            RestServices().rest('POST', {}, urlConsultazione.getListFarSystem)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setFarmacie(result.response)
                        if (result.response.length === 1) {
                            setSelectFarm(result.response[0].idFar)
                            cambiaValore("idFarmacia", result.response[0].idFar)

                        }

                    } else {

                    }
                })
        }
    }

    const tipoMovimentoList = () => {

        let array = []

        array.push({ id: 1, descrizione: 'Acquisizione' })
        array.push({ id: 2, descrizione: 'Cessione' })

        setTipoMovimento(array)

    }

    const salvaRegistrazione = () => {


        let rc = confirm("<i>Registrare il movimento per il prodotto " + objctTOsave.codiceProdotto + "  ?</i > ", "Attenzione");
        rc.then((dialogResult) => {
            if (dialogResult) {

                if (props.authUdi) {

                    //console.log(objctTOsave)
                    //console.log(tipoMovimento)
                    //console.log(objctTOsave.tipoMovimento.value)

                    let params = {
                        codCsf: codCsf,
                        idFarmacia: objctTOsave.idFarmacia,
                        codiceUdi: objctTOsave.codiceUdi,
                        data: objctTOsave.data,
                        tipoMovimento: tipoMovimento?.filter((item) => {
                            return item.id === objctTOsave.tipoMovimento
                        })[0].descrizione
                    }

                    setLoading(true)
                    RestServices().rest('POST', params, urlGestioneUdiV2.salvaRegistrazione)
                        .then(result => {
                            if (result?.esito) {
                                if (result.response) {
                                    setLoading(false)
                                    setDaRegistrare(result.response)
                                    if (result.response.esito === true) {
                                        notify({ position: "center", width: "auto", message: "Movimento registrato con successo" }, "success", 4000)

                                        let obj = { ...objctTOsave }
                                        if (farmacie.length > 1)
                                            obj.idFarmacia = undefined
                                        obj.codiceProdotto = undefined
                                        obj.codiceUdi = undefined
                                        obj.data = now
                                        obj.tipoMovimento = undefined

                                        setObjctTOsave(obj)

                                        setDaRegistrare(false)
                                        setInfoProdotto("")
                                        setInfoRegistra("")
                                        setDisableConferma(true)
                                        setInfoDescrizioneProdotto("")
                                        setInfoDittaProdotto("")
                                        setInfoEanProdotto("")
                                        setInfoMinsanProdotto("")

                                        setCodiceProdotto("")
                                        setCodiceUdi("")
                                        setData(new Date())
                                        if (farmacie.length > 1)
                                            if (farmacie.length > 1) {
                                                setFarmaciaS("")
                                                setSelectFarm(null)
                                            }
                                        setTipoMovimentoS("")
                                        setProdottoTrovato(false)
                                        setDaRegistrare(false)

                                    }

                                    else
                                        notify({ position: "center", width: "auto", message: "Movimento non registrato" }, "error", 4000)
                                }

                            } else {
                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                setLoading(false)
                            }
                        })

                }

            } else {
                return
            }
        });



    }

    const verificaCodiceProdotto = () => {

        //console.log(props.authUdi)
        if (props.authUdi && props.authCommon) {

            if (objctTOsave.codiceProdotto == undefined || (objctTOsave.codiceProdotto.length == 0 || objctTOsave.codiceProdotto.length < 9 || objctTOsave.codiceProdotto.length > 13 || (objctTOsave.codiceProdotto.length > 9 && objctTOsave.codiceProdotto.length < 13))) {
                notify({ position: "center", width: "auto", message: "Inserire un codice ministeriale o ean valido" }, "error", 4000)
                setDaRegistrare(false)
                setDisableConferma(true)
                return;
            }

            setLoading(true)
            let params = { codProd: objctTOsave.codiceProdotto }
            RestServices().rest('POST', params, urlGestioneUdiV2.verificaProdotto)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setDaRegistrare(result.response.registrare)
                            setCodCsf(result.response.codCsf)
                            setInfoProdotto(result.response.infoProdotto)
                            setInfoRegistra(result.response.infoRegistra)
                            setInfoDescrizioneProdotto(result.response.infoDescrizioneProdotto)
                            setInfoDittaProdotto(result.response.infoDittaProdotto)
                            setInfoMinsanProdotto(result.response.infoMinsanProdotto)
                            setInfoEanProdotto(result.response.infoEanProdotto)
                            setProdottoTrovato(true)
                            //if (result.response.registrare === true)
                            //    //notify({ position: "center", width: "auto", message: "Per il prodotto e' obbligatoria la registrazione del movimento" }, "success", 4000)
                            //else
                            //    //notify({ position: "center", width: "auto", message: "Per il prodotto non e' richiesta la registrazione del movimento" }, "error", 4000)
                        }

                    } else {
                        setDaRegistrare(false)
                        setCodCsf(undefined)
                        setInfoProdotto(undefined)
                        setInfoRegistra(undefined)
                        setInfoDescrizioneProdotto(undefined)
                        setInfoDittaProdotto(undefined)
                        setInfoMinsanProdotto(undefined)
                        setInfoEanProdotto(undefined)
                        setProdottoTrovato(false)
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const tipoMovimentoRender = (data:any) => {
        //console.log(data)
        return (
            <div className="tipoMovimento">
                <div className="tipoMovimentoItem">{data.descrizione}</div>
            </div>
        );
    }

    return (
        <>
            <Loading isVisible={loading} />
            &nbsp;
            <div className="cgmInput">
                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 4, marginTop: 10, height: '750px' }} className="form-group">

                    {/* form verifica */}

                        <div className="row" style={{marginTop:16} }>
                        <div className="col">
                        
                        </div>
                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                            <label className="inter-semibold" style={{ fontSize: 20, width:480}}> Verifica se il prodotto necessita di registrazione </label>
                        </div>
                        <div className="col">
                       
                        </div>
                    </div>
                        <div className="row" style={{ marginTop: 16 }}>
                        <div className="col">
                       
                        </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <TextBox width="340" height="56" value={codiceProdotto} valueChangeEvent="keyup blur change focusout" showClearButton={true} onValueChanged={(v) => { cambiaValore('codiceProdotto', v.value); setCodiceProdotto(v.value); if (v.value.length > 0) setOnType(true); if (v.value.length <= 0) setOnType(false) ; }} maxLength='13' placeholder="Codice EAN o Minsan" />
                                <Button title="Verifica che il prodotto preveda la registrazione" id={!onType ? "btnVerificaCodiceProdottoCgm" : "btnVerificaCodiceProdottoCgmCheck"} variant="info" onClick={() => { verificaCodiceProdotto() }}> VERIFICA</Button>
                        </div>
                        <div className="col">
                        
                        </div>
                    </div>
                        <div className="row" style={{ marginTop: 16 }}>
                        <div className="col">

                        </div>
                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                            <label className="inter-semibold" style={{ fontSize: 16, width: 480, height:24 }}>{infoDescrizioneProdotto}</label>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                        <div className="row">
                        <div className="col">

                        </div>
                        <div className="col" style={{ display: 'flex', justifyContent: 'center', marginTop:8, marginBottom:-12 }}>
                                <label className="inter-semibold"  style={{ fontSize: 14, width: 480, height: 24, color:'#6F7174' }} >{infoDittaProdotto}</label>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                        <div style={{ display: !prodottoTrovato ? 'none' : 'inline'}}>
                        <hr
                            style={{ borderTop: "1px solid lightgrey", width:480 }}
                        ></hr>
                    </div>
                        <div className="row" style={{ marginTop: 16 }}>
                        <div className="col">

                        </div>
                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label className="inter-regular" style={{ fontSize: 12, width: 480 }}>{infoMinsanProdotto && <span style={{ fontSize: 12, height: 24, letterSpacing: 0, color: '#6F7174' }} className={"badge badge-infoProdotto"}><Image src={bar} height={16} width={16} style={{ marginBottom: 2 }} /> Minsan: {infoMinsanProdotto}</span>} {infoEanProdotto && <span style={{ fontSize: 12, height: 24, letterSpacing: 0, color: '#6F7174' }} className={"badge badge-infoProdotto"}><Image src={bar} height={16} width={16} style={{ marginBottom: 2 }} /> EAN: {infoEanProdotto}</span>}</label>
                        </div>
                        <div className="col">

                        </div>
                    </div>
                        <div className="row">
                        <div className="col">

                        </div>
                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                {infoRegistra && <span style={{ fontSize: 15, width: 480, height:24 }} className={daRegistrare ? "badge badge-successProdotto" : "badge badge-danger"}><i className={daRegistrare ? "fa fa-check" : "fa fa-x"}></i> {infoRegistra}</span>}
                        </div>
                        <div className="col">

                        </div>
                        </div>

                        {/* form registrazione */}
                        <div style={{ display: !prodottoTrovato && !daRegistrare ? 'none' : 'inline', marginTop: 16}}>
                            <hr
                                style={{ borderTop: "1px solid lightgrey", width: 480 }}
                            ></hr>
                        </div>

                        <div style={{ display: !daRegistrare ? 'none' : 'inline', marginTop:16 }}>
                            <div className="row" style={{ marginTop: 16 }}>
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center'}}>
                                    <label style={{ fontSize: 20, width: 480 }}> Registrazione prodotto </label>
                                </div>
                                <div className="col">

                                </div>
                                </div>
                            <div className="row" style={{ marginTop: 16 }}>
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>

                                        <TextBox width="480" height="56" value={codiceUdi} disabled={!daRegistrare} valueChangeEvent="keyup" showClearButton={true} onValueChanged={(v) => { cambiaValore('codiceUdi', v.value); setCodiceUdi(v.value) }} maxLength='100' placeholder="Codice UDI" />
                                    </div>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="row" style={{ marginTop: 16, marginBottom:16 }}>
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>

                                        <table>
                                            <tr>
                                                <td style={{ paddingRight:16 }}>
                                                    <DateBox id="dataMovimento" width="200" height="56" placeholder="Data movimento" value={data} disabled={!daRegistrare} valueChangeEvent="keyup" showClearButton={true} onValueChanged={(v) => { cambiaValore('data', v.value); setData(v.value) }} type="date" displayFormat="dd/MM/yyyy" />
                                                </td>
                                                <td>
                                                    <SelectBox dataSource={tipoMovimento}
                                                        value={tipoMovimentoS}
                                                        showDataBeforeSearch={true}
                                                        valueExpr="id"
                                                        displayExpr="descrizione"
                                                        valueChangeEvent="keyup"
                                                        showClearButton={false}
                                                        placeholder="Tipo movimento"
                                                        disabled={!daRegistrare}
                                                        onValueChanged={(e: any) => { cambiaValore("tipoMovimento", e.value); setTipoMovimentoS(e.value) }}
                                                        width="263"
                                                        height="56"
                                                        dropDownButtonRender={ImageIcon}
                                                        //style={{paddingTop:8} }
                                                        itemRender={tipoMovimentoRender}

                                                    />
                                                </td>
                                            </tr>
                                        </table>
                                    
                                           
                                    </div>
                                </div>
                                <div className="col">

                                </div>
                            </div>
                            <div className="row">
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: 480, display: 'flex', justifyContent: 'right' }}>
                                        <Button 
                                            id={disableConferma ? "btnRegistraProdottoCgm" : "btnRegistraProdottoCgmCheck"}
                                            onClick={() => {
                                            salvaRegistrazione()
                                        }}
                                            disabled={disableConferma}>REGISTRA </Button>
                                    </div>

                                </div>
                                <div className="col">

                                </div>
                            </div>
                        </div>

                    </div>

                
                    
                </div>

            </div>
           


        </>
    )
}
export default InserimentoUdi