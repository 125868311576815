import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import {urlBancaDati, urlCommon, urlConsultazione, urlReport } from "../../costanti";
import { firstWidget } from "../queries/queriesCruscotto";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { MenuCruscotto } from "../components/menu_cruscotto"
import { CircularGauge, Scale, Label, RangeContainer, Range, Export, Title, Font, Size } from "devextreme-react/circular-gauge";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import "./style.css";

enum typeShow {
  euro,
  percento,
}

interface cardG {
  testo: string;
  keyValue: string;
  second?: boolean;
  testoPop?: string;
  puntale?: string
}

interface cardV {
  testo: string;
  subTesto: string;
  keyValue: string | undefined;
  typeShowValue?: typeShow;
  testoPop?: string;
  second?: boolean;
}

interface cardG {
  testo: string;
  keyValue: string;
  second?: boolean;
  testoPop?: string;
  puntale?: string
}

const boxCard: cardV[] = [
    {
        testo: "Numero giorni di lavoro",
        subTesto: "Anno precedente",
        keyValue: "GIORNI_LAVORATI",
        testoPop:
            "Il numero di giorni lavorati viene calcolato basandosi sulle vendite effettuate dalla farmacia. Se la farmacia in una data ha chiuso almeno una vendita, e questa vendita ha importo superiore a 50 €, allora la data è conteggiata come lavorativa.",
    },
    {
        testo: "Valore del venduto",
        subTesto: "Anno precedente",
        keyValue: "VALORE_VENDUTO",
        typeShowValue: typeShow.euro,
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Margine",
        subTesto: "Anno precedente",
        keyValue: "MARGINE",
        typeShowValue: typeShow.euro,
        testoPop:
            "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Margine Percentuale",
        subTesto: "Anno precedente",
        keyValue: "MARGINE_PERCENTUALE",
        typeShowValue: typeShow.percento,
        testoPop:
            "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },

    {
        testo: "Volume vendita",
        subTesto: "Anno precedente",
        keyValue: "VOLUME_VENDITA",
        testoPop:
            "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato. Volume di vendita anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Numero vendite",
        subTesto: "Anno precedente",
        keyValue: "NUMERO_VENDITE",
        testoPop:
            "Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero di vendite anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
];

const boxCardAllY: cardV[] = [
    {
        testo: "Numero giorni di lavoro",
        subTesto: "Anno precedente",
        keyValue: "GIORNI_LAVORATI",
        testoPop:
            "Il numero di giorni lavorati viene calcolato basandosi sulle vendite effettuate dalla farmacia. Se la farmacia in una data ha chiuso almeno una vendita, e questa vendita ha importo superiore a 50 €, allora la data è conteggiata come lavorativa.",
    },
    {
        testo: "Valore del venduto",
        subTesto: "Anno precedente",
        keyValue: "VALORE_VENDUTO",
        typeShowValue: typeShow.euro,
        testoPop:
            "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },

    {
        testo: "Margine",
        subTesto: "Anno precedente",
        keyValue: "MARGINE",
        typeShowValue: typeShow.euro,
        testoPop:
            "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Margine Percentuale",
        subTesto: "Anno precedente",
        keyValue: "MARGINE_PERCENTUALE",
        typeShowValue: typeShow.percento,
        testoPop:
            "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },

    {
        testo: "Volume di vendita",
        subTesto: "Anno precedente",
        keyValue: "VOLUME_VENDITA",
        testoPop:
            "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato. Volume di vendita anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
        testo: "Numero vendite",
        subTesto: "Anno precedente",
        keyValue: "NUMERO_VENDITE",
        testoPop:
            "Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero di vendite anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
];

const gaugeAllY: cardG[] = [
    {
        testo: "Venduto",
        keyValue: "VALORE_VENDUTO_EQUIPARATO",
        puntale: "VALORE_VENDUTO_PUNTUALE",
        testoPop:
            "Delta valore del venduto: Rappresenta il delta percentuale tra il valore del venduto anno corrente e anno precedente nel periodo selezionato.Il delta viene calcolato considerando entrambi i valori al lordo della DCR in quanto il valore netto è disponibile solo dall'installazione della revisione 10. Delta valore del venduto equiparato: Rappresenta il delta percentuale tra valore del venduto anno corrente ed il valore del venduto equiparato nel periodo selezionato. Il valore del venduto equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un valore del venduto di 1000 €, contro i 950 € dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
    {
        testo: "Margine",
        keyValue: "MARGINE_EQUIPARATO",
        puntale: "MARGINE_PUNTUALE",
        testoPop:
            "Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato. Il delta viene calcolato considerando entrambi i valori al lordo della DCR in quanto il valore netto è disponibile solo dall'installazione della revisione 10.Delta margine equiparato: Rappresenta il delta percentuale tra margine anno corrente e margine equiparato nel periodo selezionato. Il margine equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un margine di 1000 €, contro i 950 € dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
    {
        testo: "Volume di vendita",
        keyValue: "VOLUME_VENDITA_EQUIPARATO",
        puntale: "VOLUME_VENDITA_PUNTUALE",
        testoPop:
            "Delta volume di vendita: Rappresenta il delta percentuale tra il volume di vendita anno corrente e anno precedente nel periodo selezionato. Delta volume di vendita equiparato: Rappresenta il delta percentuale tra volume di vendita anno corrente e volume di vendita equiparato nel periodo selezionato. Il volume di vendita equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un venduto 1000 pezzi, contro i 950 dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
    {
        testo: "Numero di vendite",
        keyValue: "NUMERO_VENDITE_EQUIPARATO",
        puntale: "NUMERO_VENDITE_PUNTUALE",
        testoPop:
            "Delta numero vendite: Rappresenta il delta percentuale tra il numero di vendite anno corrente e anno precedente nel periodo selezionato. Delta numero vendite equiparato: Rappresenta il delta percentuale tra il numero di vendite anno corrente e numero di vendite equiparato nel periodo selezionato. Il numero di vendite equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho effettuato 1000 vendite, contro le 950 dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
];

//da spostare
const convertTime = (data: Date) => {
  const anno = data.getFullYear();
  const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perché i mesi sono 0-based
  const giorno = data.getDate().toString().padStart(2, "0");
  const ore = data.getHours().toString().padStart(2, "0");
  const minuti = data.getMinutes().toString().padStart(2, "0");
  const secondi = data.getSeconds().toString().padStart(2, "0");

  const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

  return dataFormattata;
};

const CruscottoDireEvol = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [valoriQ, setValoriQ] = useState<any>(null);
  const [valoriQO, setValoriQO] = useState<any>(null);
  const [valoriQAY, setValoriQAY] = useState<any>(null);
  const [valoriQOAY, setValoriQOAY] = useState<any>(null);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;

  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };

  const dashboard8 = { dashboardID: 8 };

  const Gauce: React.FC<cardG> = ({ testo, keyValue, second, testoPop, puntale }) => {
    let valoreToUse = 0;
    let valorePuntale = 0;
    let color;
    let colorText;
    if (second) {
      if (valoriQOAY && keyValue) {
        valoreToUse = valoriQOAY[keyValue]?.toFixed(2);
        if (puntale) {
          console.log('cosa sono');

          valorePuntale = valoriQOAY[puntale]?.toFixed(2);
        }

      }
    } else {
      if (valoriQO && keyValue) {
        valoreToUse = valoriQO[keyValue]?.toFixed(2);
        if (puntale) {
          valorePuntale = valoriQO[puntale]?.toFixed(2);

        }
      }
    }

    if (valoreToUse >= -100 && valoreToUse < -30) {
      color = "#CE2029";
      colorText = "#fff";
    } else if (valoreToUse >= -30 && valoreToUse < 0) {
      color = "#FFD700";
      colorText = "#000";
    } else if (valoreToUse >= 0 && valoreToUse < 100) {
      color = "#228B22";
      colorText = "#fff";
    } else {
      // Valore non rientra in nessun intervallo specifico
      // Gestisci il caso generico qui
    }

    return (
      <div style={{ background: "#fff" }} className="grid-item border-solid">
        <div style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
          <button
            className="ibutton"
            style={{ border: "none" }}
            onClick={() => popVisible(testoPop ? testoPop : "")}
          >
            <FontAwesomeIcon
              icon={faInfo}
              style={{ fontSize: "10px", color: "black" }}
            />
          </button>
        </div>
        <CircularGauge id="gauge" value={valoreToUse}>
          <Size height={200} />
          <Scale startValue={-100} endValue={100} tickInterval={10}>
            <Label customizeText={""} />
          </Scale>
          <RangeContainer>
            <Range startValue={-100} endValue={-30} color="#CE2029" />
            <Range startValue={-30} endValue={0} color="#FFD700" />
            <Range startValue={1} endValue={100} color="#228B22" />
          </RangeContainer>
          <Export enabled={true} />
          <Title text={testo}>
          <Font color="#5b6372" size="16" weight="400" />
          </Title>
        </CircularGauge>
        <div style={{ background: color, color: colorText }}>
          <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>
            {valoreToUse}%
          </p>
        </div>
        Delta puntuale <strong>{valorePuntale}%</strong>
      </div>
    );
  };

    const CardElement: React.FC<cardV> = ({
        testo,
        subTesto,
        keyValue,
        typeShowValue,
        testoPop,
        second,
    }) => {
        let valoreToUse;
        let valoreToUseOld;

        const getFormattedValue = (value: number, type: any) => {
            switch (type) {
                case typeShow.euro:
                    return `€${value.toLocaleString("it-IT", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                    })}`;
                case typeShow.percento:
                    return `${value.toFixed(2)}%`;
                default:
                    return value.toLocaleString("it-IT", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 3,
                    });
            }
        };

        if (second) {
            if (valoriQAY && keyValue) {
                const valore = valoriQAY[keyValue];
                if (valore !== undefined && valore !== null) {
                    valoreToUse = getFormattedValue(valore, typeShowValue);
                } else {               
                    valoreToUse = 0;
                }
            } else {      
                valoreToUse = 0;
            }

            if (valoriQOAY && keyValue) {
                const valore = valoriQOAY[keyValue];
                if (valore !== undefined && valore !== null) {
                    valoreToUseOld = getFormattedValue(valore, typeShowValue);
                } else {
                    valoreToUseOld = 0;
                }
            } else {
                valoreToUseOld = 0;
            }
        } else {
            if (valoriQ && keyValue) {
                const valore = valoriQ[keyValue];
                if (valore !== undefined && valore !== null) {
                    valoreToUse = getFormattedValue(valore, typeShowValue);
                } else {
                    valoreToUse = 0;
                }
            } else {
                valoreToUse = 0;
            }

            if (valoriQO && keyValue) {
                const valore = valoriQO[keyValue];
                if (valore !== undefined && valore !== null) {
                    valoreToUseOld = getFormattedValue(valore, typeShowValue);
                } else {
                    valoreToUseOld = 0;
                }
            } else {
                valoreToUseOld = 0;
            }
        }

    return (
      <div style={{ background: "#fff",display: 'flex',flexDirection: 'column', justifyContent: 'space-between' }} className="item border-solid">
        <p
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",

          }}
        >
          {second ? "Progressivo anno corrente" : " Periodo Selezionato"}
          <span>
            {" "}
            <button
              className="ibutton"
              style={{ border: "none" }}
              onClick={() => popVisible(testoPop ? testoPop : "")}
            >
              <FontAwesomeIcon
                icon={faInfo}
                style={{ fontSize: "11px", color: "black" }}
              />
            </button>
          </span>
        </p>
        <p style={{ margin: 0, color:"#5b6372" }}>{testo}</p>
        <h4 style={{ margin: 0, fontSize: "24px", color: "#00CEE6" }}>
          {" "}
          {valoreToUse}
        </h4>
        <hr></hr>
        <div style={{background:'#efefef'}}><p>
          {subTesto}{" "}
          <span>
            <strong>{valoreToUseOld}</strong>
          </span>
        </p></div>
      </div>
    );
  };

  const testApicrusc = {
    queryID: firstWidget.queryID,
    idFarList: [17, 19],
    filterDetailsIDs: firstWidget.filterDetailsIDs.map((column) => {
      const { select, where, groupBy, filterValueList, orderBy } = column;

      const dwhColumn = {
        id: column.id,
        select,
        where,
        groupBy,
        filterValueList,
        orderBy,
      };

      return dwhColumn;
    }),
  };



  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  function aggiornaOrarioFine(dataFine: any) {
    // Verifica se l'orario della data finale è mezzanotte (00:00)
    const dataFineAggiornata = new Date(dataFine);
    dataFineAggiornata.setHours(23, 59, 59);
    return dataFineAggiornata;
  }

  function aggiornaDataFine(dataFine: Date): Date {
    // Clona la data di partenza per non modificarla direttamente
    const nuovaDataFine = new Date(dataFine);

    // Aggiungi un giorno alla data
    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };
    console.log('vediamo i valori ');
    
    setParamsFilter(params);

    let newIDFAR: any = [];
    let newIDSettore: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      /**qui api test  */
      const testapi = {
        ...testApicrusc,
        idFarList: params.idFar,
        params: parameters,
      };

      const dashboardCall8 = {
        ...dashboard8,
        idFarList: params.idFar,
        params: parameters,
    };

      console.log("mostro api ");
      console.log(testapi);


      RestServices()
        .rest("POST", dashboardCall8, urlReport.getQueryDashboardAllData)
        .then((result) => {
          //console.log(result);
          setIsVisible(false);

          if (result) {
            //console.log(result[0]);

            setValoriQ(result[31][0]);
            setValoriQO(result[31][1]);
            setValoriQAY(result[62][0]);
            setValoriQOAY(result[62][1]);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            { position: "center", width: "auto", message: "Errore" + error },
            "error",
            4000
          );
        });


      //cambio data

      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      const oggi = new Date();

      testapi.params.dal = convertTime(inizioAnno);
      testapi.params.al = convertTime(oggi);

      console.log("new api");
      console.log(testapi);

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlBancaDati.getGruppoMerceologico)
        .then((result) => {
          if (result) setGruppoMercFilters(result);
        });
    }
  };

  useEffect(() => {
    filters();
  }, [auth]);

  const exportFormats = ["csv"];

  const onExporting = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("tabella_operatori");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-csv
      // https://github.com/exceljs/exceljs#reading-csv
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "tabella_operatori.csv"
        );
      });
    });
  };

  const itemsFields = [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
  ];

  function formatLabel(arg: { argumentText: any; valueText: any }) {
    return `${arg.argumentText}: ${arg.valueText}%`;
  }

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  const onPointClick = (e: { target: any }) => {
    const point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  };

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  const valoreGmargineFront = (second?: boolean) => {
    let valoreToUse =
      valoriQ["MARGINE_PERCENTUALE"] -
      valoriQO["MARGINE_PERCENTUALE"]?.toFixed(2);

    if (second) {
      valoreToUse =
        valoriQAY["MARGINE_PERCENTUALE"] -
        valoriQOAY["MARGINE_PERCENTUALE"]?.toFixed(2);
    }

    let color: string = "#fff";
    let colorText: string = "#fff";
    if (valoreToUse >= -100 && valoreToUse < -30) {
      color = "#CE2029";
      colorText = "#fff";
    } else if (valoreToUse >= -30 && valoreToUse < 0) {
      color = "#FFD700";
      colorText = "#000";
    } else if (valoreToUse >= 0 && valoreToUse < 100) {
      color = "#228B22";
      colorText = "#fff";
    } else {
      color = "#228B22";
      colorText = "#fff";
      // Valore non rientra in nessun intervallo specifico
      // Gestisci il caso generico qui
    }
    return {
      value: valoreToUse,
      color,
      colorText,
    };
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);


  return (
    <section style={{ display: "flex", height: "98vh" }}>
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div
            style={{
              fontSize: "15px",
              alignItems: "left",
              marginTop: "10px",
              marginBottom: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h2>BusinessLab Evolution - Cruscotto Direzionale</h2>
          </div>
          {/* select*/}

          <div
            style={{
              display: "block",
              gap: 5,
              padding: 10,
              borderRadius: 10,
              marginTop: 10,
              width: "auto",
            }}
          >

            <div>
              <MenuCruscotto />
            </div>
          </div>

        
          <div style={{}}>
            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"50%"}
              height={"50%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup>

            <div className="grid-container">
              {boxCard.map((el, i) => {
                return (
                  <CardElement
                    testo={el.testo}
                    subTesto={el.subTesto}
                    typeShowValue={el.typeShowValue}
                    key={i}
                    keyValue={el.keyValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            <div style={{ marginTop: 5 }} className="grid-container-ally">
              {boxCardAllY.map((el, i) => {
                return (
                  <CardElement
                    testo={el.testo}
                    subTesto={el.subTesto}
                    typeShowValue={el.typeShowValue}
                    key={i}
                    keyValue={el.keyValue}
                    testoPop={el.testoPop}
                    second={true}
                  />
                );
              })}
            </div>
          </div>

          {/* gauce 1 */}

          <div style={{ textAlign: "center", padding: 30 }}>
            <h6>
              Andamento puntuale periodo selezionato rispetto l'anno precedente
            </h6>
          </div>

          <div style={{ marginBottom: 5 }} className="grid-container-2">
            <div
              style={{ background: "#fff" }}
              className="grid-item-2 "
            >
              <div style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
                <button
                  className="ibutton-gen"
                  style={{ border: "none" }}
                  onClick={() => popVisible(testoPop ? testoPop : "")}
                >
                  <FontAwesomeIcon
                    icon={faInfo}
                    style={{ fontSize: "10px", color: "black" }}
                  />
                </button>
              </div>
              <CircularGauge
                id="gauge"
                value={valoriQ && valoriQO ? valoreGmargineFront().value : 0}
              >
                <Size height={200} />
                <Scale startValue={-100} endValue={100} tickInterval={10}>
                  <Label customizeText={""} />
                </Scale>
                <RangeContainer>
                  <Range startValue={-100} endValue={-30} color="#CE2029" />
                  <Range startValue={-30} endValue={0} color="#FFD700" />
                  <Range startValue={1} endValue={100} color="#228B22" />
                </RangeContainer>
                <Export enabled={true} />
                <Title text={"Margine percentuale"}>
                <Font color="#5b6372" size="16" weight="400" />
                </Title>
              </CircularGauge>

              <div
                style={{
                  background:
                    valoriQ && valoriQO ? valoreGmargineFront().color : "none",
                  color:
                    valoriQ && valoriQO
                      ? valoreGmargineFront().colorText
                      : "none",
                }}
              >
                <p style={{ fontSize: 16, fontWeight: "bold", margin: 0 }}>
                  {valoriQ && valoriQO
                    ? valoreGmargineFront().value?.toFixed(2)
                    : 0}
                  %
                </p>
              </div>
            </div>

            {gaugeAllY.map((g, i) => {
              return (
                <Gauce
                  key={"g_" + i}
                  testo={g.testo}
                  puntale={g.puntale}
                  testoPop={g.testoPop}
                  keyValue={g.keyValue}
                />
              );
            })}
          </div>

          <div style={{ textAlign: "center", padding: 30 }}>
            <h6>Andamento progressivo annuo</h6>
          </div>

          <div style={{ marginBottom: 5 }} className="grid-container-2">
            <div
              style={{ background: "#fff" }}
              className="grid-item-2"
            >
              <div style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
                <button
                  className="ibutton-gen"
                  style={{ border: "none" }}
                  onClick={() => popVisible(testoPop ? testoPop : "")}
                >
                  <FontAwesomeIcon
                    icon={faInfo}
                    style={{ fontSize: "10px", color: "black" }}
                  />
                </button>
              </div>

              <CircularGauge
                id="gauge"
                value={valoriQAY && valoriQOAY ? valoreGmargineFront(true).value : 0}
              >
                <Size height={200} />
                <Scale startValue={-100} endValue={100} tickInterval={10}>
                  <Label customizeText={""} />
                </Scale>
                <RangeContainer>
                  <Range startValue={-100} endValue={-30} color="#CE2029" />
                  <Range startValue={-30} endValue={0} color="#FFD700" />
                  <Range startValue={1} endValue={100} color="#228B22" />
                </RangeContainer>
                <Export enabled={true} />
                <Title text={"Margine percentuale"}>
                <Font color="#5b6372" size="16" weight="400" />
                </Title>
              </CircularGauge>
              <div
                style={{
                  background:
                    valoriQAY && valoriQOAY ? valoreGmargineFront(true).color : "none",
                  color:
                    valoriQAY && valoriQOAY
                      ? valoreGmargineFront(true).colorText
                      : "none",
                }}
              >
                <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>
                  {valoriQAY && valoriQOAY
                    ? valoreGmargineFront(true).value?.toFixed(2)
                    : 0}
                  %
                </p>
              </div>
            </div>

            {gaugeAllY.map((g, i) => {
              return (
                <Gauce
                  second={true}
                  key={"g_" + i}
                  puntale={g.puntale}
                  testoPop={g.testoPop}
                  testo={g.testo}
                  keyValue={g.keyValue}
                />
              );
            })}
          </div>


        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{ fontSize: "30px", color: "black" }}
        />
      </div>
      <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
        <div style={{}}>
          <FiltriVerticale
            ricerca={filterAction}
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>
    </section>
  );
};

export default CruscottoDireEvol;
