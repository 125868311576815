import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useAuth } from '../../hooks/useAuth';
import { Loading } from '../../widget/Notifications';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { DialogConsegnaProvetta, DialogElencoConsegne, DialogElencoProvetta, DialogFaq, DialogRestituzioneProvetta } from './Dialog';
import { ListaVettoriInterface, ScreeningInterface } from '../../interfaces/screening';
import { urlScreening } from '../costanti';
import { RestServices } from '../../services/restServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons'
import { faArrowAltCircleRight } from '@fortawesome/free-regular-svg-icons'
import { faArrowAltCircleDown } from '@fortawesome/free-regular-svg-icons'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import { faAddressBook } from '@fortawesome/free-regular-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import federfarmabergamo_logo_header from '../../immagini/federfarmabergamo_logo_header.jpg';
import Image from 'react-bootstrap/esm/Image';

const Screening =
    (
        props:
            {
                statoMenu: boolean,
                toggleMenu: (stato: boolean) => void,
                //datiFarmacie: ListaFarmacieInterface[],
                contenuto: string
            }
    ) => {
        const auth = useAuth(urlScreening.auth);
        const [datiFarmacie, setDatiFarmacie] = useState<ListaFarmacieInterface[]>();
        const [vettori, setVettori] = useState<ListaVettoriInterface[]>();
        //al caricamento della pagina si fa collassare il menu laterale in modo da avere pi� spazio a disposizione per la pagina
        useEffect(() => {
            if (props.statoMenu) {
                props.toggleMenu(!props.statoMenu);
            }
        }, [])


        useEffect(() => {
            if (auth) {
                const param = {};
                RestServices().rest('POST', param, urlScreening.getFar)
                    .then(result => {
                        setDatiFarmacie(result);
                    })
            }
        }, [auth]);

        //crea il titolo della pagina sotto la navbar
        let titolo = TitoloContenuto(props.contenuto);

        //rende visibile/invisibile il pannello di loading
        const [isVisible, setIsVisible] = useState(false);

        const [newConsegnaProvetta, setNewConsegnaProvetta] = useState<ScreeningInterface>({
            codiceFiscale: undefined,
            lstFar: undefined,
            numeroProvetta: undefined,
            numeroProvettaLettera: undefined,
            dataNascita: undefined,
            vettore: undefined,
            nascosti: undefined,
        });

        const loadPanel = <Loading isVisible={isVisible} />
        const [isDialogConsegnaProvettaOpen, setIsDialogConsegnaProvettaOpen] = useState<boolean>(false);
        const [isDialogRestituzioneProvettaOpen, setIsDialogRestituzioneProvettaOpen] = useState<boolean>(false);
        const [isDialogElencoProvettaOpen, setIsDialogElencoProvettaOpen] = useState<boolean>(false);
        const [isDialogElencoConsegneOpen, setIsDialogElencoConsegneOpen] = useState<boolean>(false);
        const [isDialogFaqOpen, setIsDialogFaqOpen] = useState<boolean>(false);
        const handleCloseDialogConsegnaProvetta = () => setIsDialogConsegnaProvettaOpen(false);
        const handleCloseDialogRestituzioneProvetta = () => setIsDialogRestituzioneProvettaOpen(false);
        const handleCloseDialogElencoProvetta = () => setIsDialogElencoProvettaOpen(false);
        const handleCloseDialogElencoConsegne = () => setIsDialogElencoConsegneOpen(false);
        const handleCloseDialogFaq = () => setIsDialogFaqOpen(false);


        const apriConsegnaProvetta = () => {
            setIsDialogConsegnaProvettaOpen(true);
        }
        const apriRestituzioneProvetta = () => {
            setIsDialogRestituzioneProvettaOpen(true);
        }
        const apriElencoProvetta = () => {
            setIsDialogElencoProvettaOpen(true);
        }
        const apriConsultaConsegne = () => {
            setIsDialogElencoConsegneOpen(true);
        }
        const apriFaq = () => {
            setIsDialogFaqOpen(true);
        }

        useEffect(() => {
            //RestServices().rest('POST', "", urlScreening.getVettori).then(result => {
            //        if (result) {
            //            setVettori(result);
            //        }
            //    })
            setVettori([{ "ID": 1, "DESCRIZIONE": "CEF - Cooperativa Esercenti Farmacia / Filiale di Bergamo" },
                { "ID": 2, "DESCRIZIONE": "UNICO La farmacia dei farmacisti S.p.A." },
                { "ID": 3, "DESCRIZIONE": "Farvima Medicinali S.p.A. - Lombardia" },
                { "ID": 4, "DESCRIZIONE": "Comifar Distribuzione - Lombardia" },
                { "ID": 5, "DESCRIZIONE": "Club salute" },
                { "ID": 6, "DESCRIZIONE": "Alliance Healthcare Italia" },
                { "ID": 7, "DESCRIZIONE": "Farmalvarion" }]);

        }, [])

        return (
            <>
                {loadPanel}
                <br/>
                <div id="wrapper">
                    <div id="centerHorizontally">
                        <Image src={federfarmabergamo_logo_header}/>
                    </div>
                </div>
                <div id="wrapper">
                    <div id="centerHorizontally">
                        <h3 className="titoloScreening"> {titolo}</h3>
                    </div>
                </div>
                <br/>
                <div id="wrapper">
                    <div id="centerHorizontally">
                        <a id="option_button" className="btn btn-success" onClick={apriConsegnaProvetta}>
                            <div className="dx-tile-image">
                                <br />
                                <h5 className="m-xs font-bold"><FontAwesomeIcon icon={faArrowAltCircleRight} className="userFileIcon" /> Consegna il kit</h5>
                                <small>Consegna del kit di screening al cliente</small>
                            </div>
                        </a>
                        <a id="option_button" className="btn btn-info" onClick={apriRestituzioneProvetta}>
                            <div className="dx-tile-image">
                                <br />
                                <h5 className="m-xs font-bold"><FontAwesomeIcon icon={faArrowAltCircleDown} className="userFileIcon" /> Ritira il campione</h5>
                                <small>Ritiro del campione del cliente</small>
                            </div>
                        </a>
                        <a id="option_button" className="btn btn-primary" onClick={apriElencoProvetta}>
                            <div className="dx-tile-image">
                                <br />
                                <h5 className="m-xs font-bold"><FontAwesomeIcon icon={faClipboard} className="userFileIcon" /> Consegna al vettore</h5>
                                <small>Consegna dei campioni al vettore</small>
                            </div>
                        </a>
                        <a id="option_button" className="btn btn-warning" onClick={apriConsultaConsegne}>
                            <div className="dx-tile-image">
                                <br />
                                <h5 className="m-xs font-bold"><FontAwesomeIcon icon={faAddressBook} className="userFileIcon" /> Storico consegne al vettore</h5>
                                <small>Consultazione delle consegne effettuate al vettore</small>
                            </div>
                        </a>
                        <a id="option_button" className="btn btn-secondary" onClick={apriFaq}>
                            <div className="dx-tile-image">
                                <br />
                                <h5 className="m-xs font-bold"><FontAwesomeIcon icon={faQuestionCircle} className="userFileIcon" /> FAQ</h5>
                                <small>Domande frequenti</small>
                            </div>
                        </a>
                    </div>
                </div>
                <br />
                <br />
                <p className="testo">
                    Funzionalit&agrave; della piattaforma CloudFarma messe a disposizione dalla Federfarma di Bergamo per la gestione del programma di screening per la diagnosi precoce dei tumori del colon-retto in farmacia, 
                    screening effettuato sulla popolazione in et&agrave; compresa tra i 50 e i 74 anni della provincia di Bergamo.
                    Il progetto nasce dalla collaborazione di Federfarma Bergamo con Regione Lombardia, l&rsquo;ATS Agenzia di Tutela della Salute di Bergamo e i Medici di Famiglia.
                    Il cittadino &egrave; stato invitato dall&rsquo;ATS, con un&rsquo;apposita lettera, a recarsi in Farmacia per il ritiro senza impegnativa, n&eacute; pagamento di alcun contributo, 
                    di quanto serve per effettuare il test a casa senza alcun tipo di preparazione.<br></br>
                    Le opzioni soprariportate sono funzionali alla gestione delle 3 diverse fasi operative:<br></br>
                    1.	<b>Consegna il kit</b><br></br>
                    &emsp;La prima fase consiste nell&rsquo;acquisizione del codice fiscale del cittadino, del codice univoco del kit e della consegna del kit per l&rsquo;effettuazione del test.<br></br>
                    2.	<b>Ritira il campione</b><br></br>
                    &emsp;In questa fase il cittadino porta il campione in farmacia dove viene acquisito il codice fiscale, il codice riportato sulla lettera di invito e il codice univoco del kit.<br></br>
                    3.	<b>Consegna al vettore</b><br></br>
                    &emsp;In questa fase il Farmacista inserisce la data di consegna del kit e il nome del vettore per il trasporto.<br></br>
                    4.	<b>Storico consegne al vettore</b><br></br>
                    &emsp;In questa ultima fase il Farmacista consulta le consegne dei kit effettuate al vettore.<br></br>
                </p>

                <DialogConsegnaProvetta
                    datiFarmacie={datiFarmacie}
                    isDialogConsegnaProvettaOpen={isDialogConsegnaProvettaOpen}
                    setIsDialogConsegnaProvettaOpen={setIsDialogConsegnaProvettaOpen}
                    setNewConsegnaProvetta={setNewConsegnaProvetta}
                    handleCloseDialogConsegnaProvetta={handleCloseDialogConsegnaProvetta}
                    setIsVisible={setIsVisible}
                />

                <DialogRestituzioneProvetta
                    datiFarmacie={datiFarmacie}
                    isDialogRestituzioneProvettaOpen={isDialogRestituzioneProvettaOpen}
                    setIsDialogRestituzioneProvettaOpen={setIsDialogRestituzioneProvettaOpen}
                    handleCloseDialogRestituzioneProvetta={handleCloseDialogRestituzioneProvetta}
                    setIsVisible={setIsVisible}
                />

                <DialogElencoProvetta
                    datiFarmacie={datiFarmacie}
                    isDialogElencoProvettaOpen={isDialogElencoProvettaOpen}
                    setIsDialogElencoProvettaOpen={setIsDialogElencoProvettaOpen}
                    handleCloseDialogElencoProvetta={handleCloseDialogElencoProvetta}
                    setIsVisible={setIsVisible}
                    vettori={vettori}
                />

                <DialogElencoConsegne
                    datiFarmacie={datiFarmacie}
                    isDialogElencoConsegneOpen={isDialogElencoConsegneOpen}
                    setIsDialogElencoConsegneOpen={setIsDialogElencoConsegneOpen}
                    handleCloseDialogElencoConsegne={handleCloseDialogElencoConsegne}
                    setIsVisible={setIsVisible}
                    idFar={(datiFarmacie) && (datiFarmacie.length > 0) ? datiFarmacie[0]?.ID_FAR: undefined}
                />

                <DialogFaq
                    isDialogFaqOpen={isDialogFaqOpen}
                    setIsDialogFaqOpen={setIsDialogFaqOpen}
                    handleCloseDialogFaq={handleCloseDialogFaq}
                    setIsVisible={setIsVisible}
                />
                
            </>
        )
    }

export default Screening
