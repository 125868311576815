import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import Ricerca from "../../widget/nuova_ricerca"
import {  Loading } from '../../widget/Notifications'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import { AplicaValoreTO } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'
import { DialogMagGiace } from "./dialog_giacenze/pop_mag_giace"
import { TabellaPlus } from "./Tabelle_plus/tabella_plus"
import Button from 'react-bootstrap/Button'

import './style.css'
import { DialogRiepGiace } from './dialog_giacenze/pop_riep_giac'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'



const MagGiacenze = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean, datiFarmacie: ListaFarmacieInterface[]}) => {
    const [idGiacenza,setIdGiacenza] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [tabellaGiacenza,setTabellaGiacenza] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [newGiaceList,setNewGiaceList] = useState([])
    const [ListSettInven,setListSettInven] = useState([])
    const [ListGrupMerc,setListGrupMerc] = useState([])
    const [ListCategory,setListCategory] = useState([])
    const [objtipo,setObjtipo] = useState<any>(null)
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [openPopRiep, setOpenPopRiep] = useState<boolean>(false)
    const [listcondprod,setListCondPro] = useState([])
    const [edit,setImmediate] = useState<boolean>(false)
    const [farmaProps, setFarmaProps] = useState<any>([])
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [lstFar, setLstFar] = useState<any>([])
   const [paramsFilter, setParamsFilter] = useState<any>([])



const columns = [
    { caption: "MINSAN", dataField: "codProd",width: 100 },
    { caption: "Cod.EAN", dataField: "ean",width: 115 },
    { caption: "Descrizione ", dataField: "descEstesaProdotto" },
    { caption: "Ditta", dataField: "descDittaTitolare", width: 200  },
    { caption: "Giacenza", dataField: "giacenza", width: 100 },
    { caption: "Di Cui Invendibili" , dataField: 'invendibili',width: 100},
    { caption: "Prezzo Banca Dati" , dataField: 'prezzoListino',format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Prezzo farmacia" , dataField: 'prezzoListino',format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Costo ultimo m.p." , dataField: 'costoMedioPonderato',format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Data costo ultimo m.p." , dataField: 'dataCostoAcquisto',format: "dd/MM/yyyy", dataType: "date", width: 100 },
    { caption: "Azioni", width: 200,  type: "buttons", buttons: [
        {
            hint: "Vendite/acquisti",
            icon: "chart",
            onClick: (e: any) => {
                setObjtipo(e.row.data)
                setOpenPop(true)   
                        
            }
        },
    ] },
 ];
// const columnsGiacenza = [
//     { caption: "Tipo vendita", dataField: "tipoVendita" },
//     { caption: "Codice Prodotto", dataField: "codProd"},
//     { caption: "Descrizione Prodotto", dataField: "descrizioneProd" , width: 400 },
//     { caption: "Quantita'", dataField: "quantita" },
//     { caption: "Iva", dataField: "iva",customizeText:(cellInfo:any)=> AplicaValoreTO(cellInfo, '%','iva','d') },
//     { caption: "Prezzo Vendita", dataField: "prezzoUnitario",format: { type: "currency", precision: 2, currency: "EUR" } },
//     { caption: "Prezzo di Riferimento", dataField: "prezzoRiferimento",format: { type: "currency", precision: 2, currency: "EUR" } },
//     { caption: "Ticket", dataField: "ticket",width: 150  ,format: { type: "currency", precision: 2, currency: "EUR" }},
//     { caption: "Importo Quota Prodotto",width: 150   , dataField: "importoQuotaProdotto",format: { type: "currency", precision: 2, currency: "EUR" } },
//     { caption: "Sconto", dataField: "sconto", width:150 ,format: { type: "currency", precision: 2, currency: "EUR" } }
// ];



    

    const ConvertForSelect = (arr:any) =>{
        let newArr:any = []
        arr.forEach((element:any) => {
            //console.log(element);
            newArr.push({value:element.idFar,label:element.descrizione})
            
        });
        return newArr
    }

    const itemsFields =[
        {type:'string' , idValue:'descrizioneProdotto' , label:'Descrizione o codice prodotto' ,width:350, enterSearch: true  }, 
        {type:'autocomplete' , idValue:'codDitta' , label:'Ditta' ,typeApi:'ditta' ,valueExpr:'ragioneSociale' , apisearch:true, dataSource : {} ,width:350 },   
        {type:'select2' , idValue:'idFar' , label:'Farmacie ' , displayExpr:'descrizione', dataSource : ConvertForSelect(newGiaceList) ,width:390  },
        {type:'string' , idValue:'atc' , label:'Classificazione ATC',width:270 ,advace:true  }, 
        {type:'select' , idValue:'codiceSettore' , label:'Settore Inventario' , displayExpr:'descrizioneSettore', dataSource : ListSettInven ,width:270,advace:true   },
        {type:'select' , idValue:'codiceGruppo' , label:'Gruppo Merceologico' , displayExpr:'descrizioneGruppo' , dataSource : ListGrupMerc ,width:270,advace:true    },
        {type:'select' , idValue:'codiceCategory' , label:'Category' , displayExpr:'descrizioneCategory' , dataSource : ListCategory ,width:270,advace:true   },
        {type:'select' , idValue:'filtroClasseA' , label:'Classe A' ,  dataSource : listcondprod , displayExpr:'descrizioneCondizione' ,width:270,advace:true   },
        {type:'select' , idValue:'filtroClasseC' , label:'Classe C' ,  dataSource : listcondprod , displayExpr:'descrizioneCondizione' ,width:270,advace:true   },
        {type:'select' , idValue:'filtroSopOtc' , label:'SOP-OTC' ,  dataSource : listcondprod , displayExpr:'descrizioneCondizione'  ,width:270,advace:true   },
        {type:'select' , idValue:'filtroGenerici' , label:'Genereci' ,  dataSource : listcondprod , displayExpr:'descrizioneCondizione' ,width:270 ,advace:true  },
        {type:'select' , idValue:'filtroOmeopatici' , label:'Omeopatici' ,  dataSource : listcondprod , displayExpr:'descrizioneCondizione' ,width:270,advace:true   },
        { type: 'select', idValue: 'filtroIntegratori', label: 'Integratori', dataSource: listcondprod, displayExpr: 'descrizioneCondizione', width: 270, advace: true },
        { type: 'ckBox', idValue: 'trattati', label: 'Soltanto prodotti trattati', defValue: true },

    ]


    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {                     
                       //console.log(result.response);
                       setNewGiaceList(result.response)
                       
                   }else{                               
                       //console.log(result);
                      
                   }
               })     
       }
    }

    const ListCondiProdo = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListCondiProdo)
               .then(result => {
                   if (result?.esito) {
                       
                      // console.log(result.response);
                       setListCondPro(result.response)                     
                   }else{                              
                      // console.log(result);
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   }
               })     
       }
   }


    
    const getListSettInventario = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListSettInven)
               .then(result => {
                   if (result?.esito) {                 
                      // console.log(result.response);
                       setListSettInven(result.response)
                       
                   }else{                               
                      // console.log(result);
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   }
               })     
       }
   }

   const getListGrupMerc = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListGruppoMerce)
           .then(result => {
               if (result?.esito) {                 
                  // console.log(result.response);
                   setListGrupMerc(result.response)
                   
               }else{                               
                  // console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}

const getListCategory = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListCate)
           .then(result => {
               if (result?.esito) {                 
                   //console.log(result.response);
                   setListCategory(result.response)
                   
               }else{                               
                  // console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}




    const getGiacenzaProdottiAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {idGiacenza  : idGiacenza} , urlConsultazione.getListGiacenzaProdFarma)
                .then(result => {
                    if (result?.esito) {
                        setTabellaGiacenza(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);                
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    }
                })
        
    }
    }

    useEffect(() => {

        if (tabellaPrincipale.length > 0)
            setDisableButton(false)
        else
            setDisableButton(true)

    }, [tabellaPrincipale])

    const filterAction = (objFiltro:Filtri) =>{
        //console.log('paramess');
        //console.log(objFiltro);
        let farmaDaPass:any = []
        let params = {...objFiltro}     
        setIdGiacenza(null)  
           
        
        if (params.descrizioneProdotto || objFiltro.codDitta){
            
            if(objFiltro.codDitta){
                params.codDitta = params.codDitta?.codice
            }
            if(objFiltro.idFar){
                
                let newIDFAR:any = []  
                objFiltro.idFar.forEach((element:any) => {
                    newIDFAR.push(element.value)
                });
                farmaDaPass = newIDFAR
                params.idFar = newIDFAR

                //console.log( params.idFar);
                
                if( params.idFar.length < 1){
                    delete params.idFar
                }
                
            }
            if(objFiltro.codiceSettore){
                params.codiceSettore = objFiltro.codiceSettore?.codiceSettore
            }
            else{
                delete params.codiceSettore
            } 
            if(objFiltro.codiceGruppo){
                params.codiceGruppo = objFiltro.codiceGruppo?.codiceGruppo
            }
            else{
                delete params.codiceGruppo
            } 

            if(objFiltro.codiceCategory){
                params.codiceCategory = objFiltro.codiceCategory?.codiceCategory
            }
            else{
                delete params.codiceCategory
            } 
            if(objFiltro.atc){
                params.atc = objFiltro.atc
            }
            else{
                delete params.atc
            } 
            if(objFiltro.filtroClasseA){
                params.filtroClasseA = objFiltro.filtroClasseA?.idCondizione
            }
            else{
                delete params.filtroClasseA
            } 

            if(objFiltro.filtroClasseC){
                params.filtroClasseC = objFiltro.filtroClasseC?.idCondizione
            }
            else{
                delete params.filtroClasseC
            } 
            if(objFiltro.filtroSopOtc){
                params.filtroSopOtc = objFiltro.filtroSopOtc?.idCondizione
            }
            else{
                delete params.filtroSopOtc
            }
            if(objFiltro.filtroGenerici){
                params.filtroGenerici = objFiltro.filtroGenerici?.idCondizione
            }
            else{
                delete params.filtroGenerici
            }
            if(objFiltro.filtroOmeopatici){
                params.filtroOmeopatici = objFiltro.filtroOmeopatici?.idCondizione
            }
            else{
                delete params.filtroOmeopatici
            }
            if(objFiltro.filtroIntegratori){
                params.filtroIntegratori = objFiltro.filtroIntegratori?.idCondizione
            }
            else{
                delete params.filtroIntegratori
            }

            params.limit = true

            setParamsFilter(params)

            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListGiacenzaProd)
                        .then(result => {
                            if (result?.esito) {
                                let newA:any = []
                                result.response.list.forEach((element:any) => {
                                    let eee:any = element
                                    eee.farmaMulty = farmaDaPass;
                                    newA.push(eee)
                                });
                                if (result.response.limit)
                                    notify({ position: "center", width: "auto", message: "La ricerca ha prodotto troppi risultati. Limitata la visualizzazione a 500 record" }, "info", 4000)    
                                setTabellaPrincipale(newA)
                                setLstFar(result.response.lstFar)
                                setLoading(false)
                            }else{
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        }else{
            //let errore = ''
            //if(!params.idFar){
            //    errore= errore+' specificare Farmacie'
            //}

            let errore = 'Specificare uno tra prodotto o ditta'

            notify({ position: 'center', width: "auto", message: errore }, "warning", 4000)
        }
    }


   

    useEffect(()=>{
        farmaList()
        ListCondiProdo()
        getListSettInventario()
        getListGrupMerc()
        getListCategory()
                  
    },[auth])
    
    useEffect(() => { 
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    useEffect(() => {
        if (idGiacenza) {
            getGiacenzaProdottiAction()
        }
    }, [idGiacenza]);
    

        const ricarica =  () =>{
            setImmediate(false)
            setObjtipo(null)
            setOpenPop(false)
        }
    
   
    return (
        <>
            <DialogMagGiace edit={objtipo} close={() => { setOpenPop(false); setImmediate(false); setObjtipo(null) }} ricarica={() => { ricarica(); }} open={openPop} />
            <DialogRiepGiace edit={objtipo} close={() => { setOpenPopRiep(false); }} ricarica={() => { }} open={openPopRiep} datiFarmacie={props.datiFarmacie} lstFar={lstFar} params={ paramsFilter} />

        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> GIACENZE  <span className='subt' > CONSULTAZIONE GIACENZE prodotti in magazzino</span> </h3>
             </div>
                         
                <Ricerca advace ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
                <div className="vedi-btn">
                    <Button className="btn btn-success" variant="success" onClick={() => {
                        setOpenPopRiep(true)
                    }}
                        disabled={disableButton}>VEDI RIEPILOGO </Button>
                </div>
                

            <NewTabella 
                idTabella='elenco_giacenza_consultazione'
                sync
                width='100%'
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                componetPlus={TabellaPlus}
                allowColumnResizing={true}
                onclickRow={(datiRow:any)=>setIdGiacenza(datiRow.data?.idGiacenza)}
                exportT={{
                    enabled: true,
                    fileName: "giacenza_consultazione"
                }}
                tabelDett
                activeSaver
                columnC
                summary={
                    <Summary>
                        <TotalItem column="dataVendita" summaryType="count" />
                        <TotalItem column="importoVendita" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} /> 
                        <TotalItem column="scontoVendita" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                    </Summary>} 
                />
        </>
    )
}

export default MagGiacenze