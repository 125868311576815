import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import RadioGroup from 'devextreme-react/radio-group';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { ConfigurazioneTrasferimentiFarmaciaInterface } from "../../interfaces/trasferimenti";
import { BoxFiltro, Check, NumberBoxFiltro } from '../../widget/Editor';
import { OggettoInterface } from '../../interfaces/generiche';
import { SelectFarm, TendinaFarm } from '../../widget/Select';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { CheckBox, List, NumberBox, Validator } from 'devextreme-react';
import ArrayStore from 'devextreme/data/array_store';
import { Item } from 'devextreme-react/list';
import { urlTrasferimenti } from '../costanti';
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { DialogGrossista, DialogLogListino } from './Dialog';

export const TabDisponibilita = (props: { parametriDisponibilita?: ConfigurazioneTrasferimentiFarmaciaInterface[], impostaFiltri: (newFiltro: object) => void }) => {


    let listConfig: string[] = [];
    const [selectedConfig, setSelectedConfig] = useState<String>();
    const [configurazioneTrasferimentiFarmLista, setConfigurazioneTrasferimentiFarmLista] = useState<String[]>();


    useEffect(() => {
        if (props.parametriDisponibilita) {
            props.parametriDisponibilita.forEach((value: ConfigurazioneTrasferimentiFarmaciaInterface) => {
                if (value.attivo) {
                    setSelectedConfig(value.caption);
                }
                listConfig.push(value.caption);
            });
            setConfigurazioneTrasferimentiFarmLista(listConfig);
        }
    }, [props.parametriDisponibilita])

    //configurazione selezionata
    const changedSelectedConfig = (e: any) => {
        setSelectedConfig(e.value);
        //alert(e.value);
        if (props.parametriDisponibilita !== undefined) {

            let newConfigurazione = props.parametriDisponibilita;
            props.parametriDisponibilita.forEach((value, index) => {
                if (value.caption == e.value) {
                    //alert(value.caption);
                    //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                    newConfigurazione[index] = { ...newConfigurazione[index], attivo: true };
                }
                else
                    newConfigurazione[index] = { ...newConfigurazione[index], attivo: false };

            });
        }
    }


    return (
        <>
            <div className="contenutoPagina">

                {(props.parametriDisponibilita !== undefined && configurazioneTrasferimentiFarmLista !== undefined && selectedConfig !== undefined) ?

                    (
                        <Row className="row-top-buffer">
                            <Col sm="4">
                                <RadioGroup items={configurazioneTrasferimentiFarmLista} value={selectedConfig} onValueChanged={changedSelectedConfig}></RadioGroup>
                            </Col>

                        </Row>

                    )
                    :
                    <></>
                }
                <Row>&nbsp;</Row>
                {(props.parametriDisponibilita !== undefined) &&
                    <Row>
                    <Col sm="12">
                        <span className="trasfInfo">{props.parametriDisponibilita.filter(config => config.caption == selectedConfig).map(config => config.descrizioneEstesa)}</span>
                        </Col>
                    </Row>
                }
                {(props.parametriDisponibilita !== undefined) &&
                    <Row>
                        <Col sm="3">
                        <label className="labelTrasf">{props.parametriDisponibilita.filter(config => config.caption == selectedConfig).map(config => config.descrizione)}</label>
                        </Col>
                        <Col sm="2" className="editTrasf">
                            {
                            (props.parametriDisponibilita.filter(config => config.caption == selectedConfig).map(config => config.chiave)[0] as string !== undefined) ?

                            <NumberBoxFiltro
                                        id={"edit_" + props.parametriDisponibilita.filter(config => config.caption == selectedConfig).map(config => config.codice)}
                                        impostaFiltri={props.impostaFiltri}
                                        value={props.parametriDisponibilita.filter(config => config.caption == selectedConfig).map(config => config.valore)[0] as string}
                                        max={365}
                                        format={"000"} step={1 } />
                            :
                                    <></>
                            }
                        </Col>
                    </Row>
                }
            </div>

        </>
    )
}

export const TabCosto = (props: { datiFarmacie: ListaFarmacieInterface[], parametriCosto?: ConfigurazioneTrasferimentiFarmaciaInterface[], impostaFiltri: (newFiltro: object) => void }) => {

    let listConfig: string[] = [];
    const [selectedConfig, setSelectedConfig] = useState<String>();
    const [configurazioneTrasferimentiFarmLista, setConfigurazioneTrasferimentiFarmLista] = useState<String[]>();

    useEffect(() => {
        if (props.parametriCosto) {
            props.parametriCosto.forEach((value: ConfigurazioneTrasferimentiFarmaciaInterface) => {
                if (value.attivo) {
                    setSelectedConfig(value.caption);
                }
                listConfig.push(value.caption);
            });
            setConfigurazioneTrasferimentiFarmLista(listConfig);
        }
    }, [props.parametriCosto])

    //configurazione selezionata
    const changedSelectedConfig = (e: any) => {
        setSelectedConfig(e.value);
        //alert(e.value);
        if (props.parametriCosto !== undefined) {

            let newConfigurazione = props.parametriCosto;
            props.parametriCosto.forEach((value, index) => {
                if (value.caption == e.value) {
                    //alert(value.caption);
                    //c'� stato un cambiamento rispetto all'oggetto iniziale, aggiornare l'oggetto copia
                    newConfigurazione[index] = { ...newConfigurazione[index], attivo: true };
                }
                else
                    newConfigurazione[index] = { ...newConfigurazione[index], attivo: false };

            });
        }
    }

    return (
        <>
            <div className="contenutoPagina">

                {(props.parametriCosto !== undefined && configurazioneTrasferimentiFarmLista !== undefined && selectedConfig !== undefined) ?

                    (
                        <Row className="row-top-buffer">
                            <Col sm="4">
                                <RadioGroup items={configurazioneTrasferimentiFarmLista} value={selectedConfig} onValueChanged={changedSelectedConfig}></RadioGroup>
                            </Col>

                        </Row>

                    )
                    :
                    <></>
                }
                <Row>&nbsp;</Row>
                {(props.parametriCosto !== undefined) &&
                    <Row>
                        <Col sm="12">
                            <span className="trasfInfo">{props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.descrizioneEstesa)}</span>
                        </Col>
                    </Row>
                }

                {(props.parametriCosto !== undefined) &&
                    <Row>
                        <Col sm="3">
                            <label className="labelTrasf">{props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.descrizione)}</label>
                        </Col>


                        {(props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.chiave)[0] as string !== undefined &&
                            props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)[0] as string !== 'FCULT'
                        ) ?
                            <Col sm="2" className="editTrasf">
                                <NumberBoxFiltro
                                    id={"edit_" + props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)}
                                    impostaFiltri={props.impostaFiltri}
                                    value={props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.valoreTipoCosto)[0] as string}
                                    max={100}
                                    format={"#0.00"}
                                    step={0.01 }
                                />
                            </Col>
                            : 

                            props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)[0] as string === 'FCULT'
                                ?
                                <Col sm="8" className="editTrasf">
                                    <SelectFarm datiFarmacie={props.datiFarmacie}
                                        id={"edit_" + props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)}
                                        impostaFiltri={props.impostaFiltri}
                                        value={props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.idAnaCosto)[0] as number}
                                        displayExpr={true} />
                                </Col>
                                :
                                <></>

                        }

                        {/*{(props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.chiave)[0] as string !== undefined &&*/}
                        {/*    props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)[0] as string === 'FCULT'*/}
                        {/*) ?*/}
                        {/*    <Col sm="4" className="editTrasf">*/}
                        {/*        <SelectFarm datiFarmacie={props.datiFarmacie} id={'idFar'} impostaFiltri={props.impostaFiltri} displayExpr={true} />*/}
                        {/*    </Col>*/}
                        {/*    :*/}
                        {/*    <></>*/}
                        {/*}*/}

                        {/*<Col sm="2" className="editTrasf">*/}
                        {/*    {*/}
                        {/*        (props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.chiave)[0] as string !== undefined && */}
                        {/*            props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)[0] as string !== 'FCULT'*/}
                        {/*        ) ?*/}
                        {/*            <BoxFiltro*/}
                        {/*                id={"edit_" + props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.codice)}*/}
                        {/*                impostaFiltri={props.impostaFiltri}*/}
                        {/*                value={props.parametriCosto.filter(config => config.caption == selectedConfig).map(config => config.valoreTipoCosto)[0] as string}*/}
                        {/*            /> :*/}

                                    

                        {/*            <></>*/}
                        {/*    }*/}

                        {/*</Col>*/}
                    </Row>
                }
            </div>

        </>
    )
}


export const TabFarmacieAbilitate = (props: { farmacie?: number[], datiFarmacie: ListaFarmacieInterface[], impostaFiltri: (newFiltro: object) => void }) => {


    return (
        <>
            <div className="contenutoPagina">
                <Row>
                    <Col sm="2" className="labelFarmaciaTrasferimento">
                        <span>Farmacie:</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm="10">
                        <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={props.impostaFiltri} valueGrid={ props.farmacie}/>
                    </Col>
                </Row>

              
            </div>

        </>
    )
}

export const TabFarmacieAbilitateV2 = (props: {
    idFarm?: number,
    anagrafiche?: any[],
    farmacie?: number[],
    datiFarmacie: ListaFarmacieInterface[],
    impostaFiltri: (newFiltro: object) => void
}) => {

    const [selectedItemKeys, setSelectedItemKeys] = useState<number[]>();
    const [openPopGrossista, setOpenPopGrossista] = useState<boolean>(false)
    const [idFarmaciaGrossista, setIdFarmaciaGrossista] = useState<number>()
    const [idFarmaciaAbilitata, setIdFarmaciaAbilitata] = useState<number>()
    const [farmaciaGrossista, setFarmaciaGrossista] = useState<string>("")
    const [farmacieAbilitate, setFarmacieAbilitate] = useState<any[]>()



    useEffect(() => {

        
        let newObj = { anagrafiche: farmacieAbilitate }

        props.impostaFiltri(newObj)

        //let array: number[] = []
        //farmacieAbilitate?.forEach((f: any) => {
        //    if (f.isAbilitato && (array.length == 0 || !array.includes(f.idFarmacia)))
        //        array.push(f.idFarmacia)
        //})

        //let newObj2 = {lstFar: array}
        //props.impostaFiltri(newObj2)
        

    }, [farmacieAbilitate])

    //useEffect(() => {

    //    if (props.datiFarmacie && props.farmacie) {

    //        let selected: number[] = Array();
    //        props.datiFarmacie?.forEach((punto: any) => {
    //            if (props.farmacie?.includes(punto.ID_FAR)) {
    //                selected.push(punto.ID_FAR);
    //            }
    //        })

    //        setSelectedItemKeys(selected);
    //    }

        
    //}, [props.datiFarmacie, props.farmacie])



    useEffect(() => {

        //compre array 
        let array: any[] = []
        
        if (props.datiFarmacie) {

            
            props.datiFarmacie?.forEach((punto: any) => {
                let idFarmacia;
                let ragioneSocialeFarmacia;
                let isAbilitato;
                let grossista;
                let cpAnagrafica;


                idFarmacia = punto.ID_FAR
                ragioneSocialeFarmacia = punto.DESCRIZIONE

                if (idFarmacia == props.idFarm) return;

                if (props.farmacie) {
                    if (props.farmacie.includes(punto.ID_FAR))
                        isAbilitato = true;
                }

                if (props.anagrafiche ) {
                    let tmp = props.anagrafiche.filter(ana => { return ana.idFarmacia === punto.ID_FAR })
                    let descrizione = "";
                    if (tmp && tmp.length > 0) {
                        //console.log(ragioneSociale)
                        descrizione = tmp[0].ragioneSociale
                        cpAnagrafica = tmp[0].cpAnagrafica
                        if (tmp[0].partitaIva)
                            descrizione = descrizione + " (" + tmp[0].partitaIva + ")"
                    }

                    grossista = descrizione
                 
                }

                let obj = { idFarmacia: idFarmacia, ragioneSocialeFarmacia: ragioneSocialeFarmacia, isAbilitato: isAbilitato, grossista: grossista, cpAnagrafica: cpAnagrafica };

                

                array.push(obj)
                
            })

            //console.log(array)

            setFarmacieAbilitate(array)
        }


    }, [props.datiFarmacie, props.farmacie, props.anagrafiche])

    

   

    const btnCambiaClick = (e: any, idFarmaciaAbilitata: string, farmacia: string) => {

        //console.log(id)
        //chiamata rest per elenco grossisti in farmacia
        let s = idFarmaciaAbilitata.split('_')
        setIdFarmaciaGrossista(props.idFarm)
        setIdFarmaciaAbilitata(Number(s[1]))
        setOpenPopGrossista(true)
        //setFarmaciaGrossista(farmacia)
        setFarmaciaGrossista("")

    }

    const btnPulisciClick = (e: any, idFarmaciaAbilitata: string, farmacia: string) => {

        //disassocia il grossista e disabilita la farmacia abilitata

        let s = idFarmaciaAbilitata.split('_')
        let id = Number(s[1])
        setIdFarmaciaAbilitata(id)

        //console.log(idFarmaciaAbilitata)
        //console.log(farmacieAbilitate)

        let arr: any[] = [];

        if (farmacieAbilitate && id) {
            farmacieAbilitate.forEach((f: any) => {
                if (f.idFarmacia === id) {
                    let obj = { idFarmacia: f.idFarmacia, ragioneSocialeFarmacia: f.ragioneSocialeFarmacia, isAbilitato: false, grossista: "", cpAnagrafica : undefined };
                    arr.push(obj)
                }
                else {
                    arr.push(f)
                }
            })

            setFarmacieAbilitate(arr)
        }


    }

    const updateGrossista = (e: any) => {

        //console.log(e)
        //console.log(farmacieAbilitate)
        //console.log(idFarmaciaGrossista)
        //console.log(idFarmaciaAbilitata)
        let arr: any[] = [];

        if (farmacieAbilitate && idFarmaciaAbilitata && e.currentSelectedRowKeys) {
            farmacieAbilitate.forEach((f: any) => {
                if (f.idFarmacia === idFarmaciaAbilitata) {
                    let obj = {
                        idFarmacia: f.idFarmacia, ragioneSocialeFarmacia: f.ragioneSocialeFarmacia,
                        isAbilitato: f.isAbilitato, grossista: e.currentSelectedRowKeys[0].ragioneSociale,
                        cpAnagrafica: e.currentSelectedRowKeys[0].cpAnabase
                    };
                    arr.push(obj)
                }
                else {
                    arr.push(f)
                }
            })

            setFarmacieAbilitate(arr)
        }

    }

    const checkValueChanged = (e: any, idFarmacia: number) =>
    {
        //console.log(idFarmacia)
        //console.log(e)

        //console.log(farmacieAbilitate)

        let arr: any[] = [];

        if (farmacieAbilitate && idFarmacia) {
            farmacieAbilitate.forEach((f: any) => {
                if (f.idFarmacia === idFarmacia) {
                    let obj = {
                        idFarmacia: f.idFarmacia, ragioneSocialeFarmacia: f.ragioneSocialeFarmacia,
                        isAbilitato: e.value, grossista: f.grossista,
                        cpAnagrafica: f.cpAnagrafica
                    };
                    arr.push(obj)
                }
                else {
                    arr.push(f)
                }
            })

            setFarmacieAbilitate(arr)
        }

        //console.log(arr)
    }

    return (
        <>


            
            <DialogGrossista
                close={() => { setOpenPopGrossista(false); }}
                open={openPopGrossista}
                idFarmacia={idFarmaciaGrossista}
                farmacia={farmaciaGrossista}
                selezionato={(e: any) => { updateGrossista(e) }}
            />

            <div className="contenutoPagina">

                <Row style={{marginBottom:20} }>
                    <Col sm="3">
                        <span style={{ fontWeight: 'bold'}}>FARMACIA</span>
                    </Col>
                    <Col sm="1">
                        <span style={{ fontWeight: 'bold' }}>ABILITATA</span>
                    </Col>
                    <Col sm="3">
                        <span style={{ fontWeight: 'bold' }}>GROSSISTA</span>
                    </Col>
                </Row>
                {props.datiFarmacie && farmacieAbilitate &&

                    props.datiFarmacie.map((value: ListaFarmacieInterface) => {


                        if (value.ID_FAR == props.idFarm) return;
                        //console.log("AA")
                        //console.log(farmacieAbilitate)
                        //console.log(value.ID_FAR)

                        //let row: any = farmacieAbilitate.filter((f ) =>  f.idFarmacia == value.ID_FAR )

                        let row: any = {};

                        farmacieAbilitate.map((a: any) => {
                            if (a.idFarmacia === value.ID_FAR) {
                                row = a
                                //console.log("trovato")
                                //console.log(row)
                            }
                                
                        })

                        

                        return (
                            <Row style={{ marginBottom: 20 }}>
                                <Col sm="3">
                                    <div style={{ fontStyle: 'italic' }}>{row.ragioneSocialeFarmacia}</div>
                                </Col>
                                <Col sm="1">
                                    <CheckBox id={"chk_" + row.idFarmacia} value={row.isAbilitato} onValueChanged={(e: any) => { checkValueChanged(e , row.idFarmacia) }}></CheckBox>
                                </Col>
                                <Col sm="2">
                                    <div id={"grossista_" + row.idFarmacia} style={{ fontStyle: 'italic' }}>{row.grossista}</div>

                                </Col>
                                <Col sm="2">
                                    <table>
                                        <tr>
                                            <td>
                                                <Button onClick={(e: any) => { btnCambiaClick(e, "btn_" + row.idFarmacia, row.ragioneSocialeFarmacia) }} id={"btn_" + row.idFarmacia} style={{ height: 35, textAlign: "center" }} title={"Scegli il grossista da associare" } >CAMBIA</Button>
                                            </td>
                                            <td style={{ marginLeft: 5 }}>
                                                <Button variant="warning" onClick={(e: any) => { btnPulisciClick(e, "btn_" + row.idFarmacia, row.ragioneSocialeFarmacia) }} id={"btn_" + row.idFarmacia} style={{ height: 35, textAlign: "center" }} title={"Pulisci riga configurata"}>PULISCI</Button>
                                            </td>
                                        </tr>
                                    </table>
                                    
                                </Col>
                                
                            </Row>
                            )
                        })


                    }


            </div>
        </>
    )
}

export const TabListino = (props: { abilitaListino?: boolean , listinoInVigore?: string, impostaFiltri: (newFiltro: object) => void }) => {


    return (
        <>
            <div className="contenutoPagina">
                <Row>
                    <Col>
                        <span style={{ cursor: "help" }} title="">Abilita utilizzo del listino </span>
                        &nbsp;
                        <Check id="abilitaListino" impostaFiltri={props.impostaFiltri} disabled={props.listinoInVigore?.startsWith('NESSUNO,')} checkValue={props.abilitaListino} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="checkPrezzoInfo">Le farmacie utilizzeranno il listino attualmente in vigore caricato dalla farmacia grossista</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="checkPrezzoInfo">Listino in vigore: <b>{props.listinoInVigore}</b></span>
                   </Col>
                </Row>
                
            </div>

        </>
    )
}
