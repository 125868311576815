import React, { useEffect, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { FornitoriCentrInterface, OggettoInterface } from "../../interfaces/generiche";
import { OnRowDblClickInterface, TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { DrawerFiltri } from "../../widget/Drawer";
import { DialogConferma, Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlAnagrafiche, urlOrdiniLiberi, urlTrasferimenti } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaAlternate } from "../../widget/Tabelle";
import { SelectFarm, Selezione } from "../../widget/Select";
import { BoxDataOra } from "../../widget/Editor";
import Button from 'react-bootstrap/esm/Button';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { ElencoListiniInterface } from "../../interfaces/ordineLibero";
import { DialogImportListino } from "./Dialog";



const ElencoListiniOrdineLibero = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const titolo = TitoloContenuto("Elenco Listini");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoListini, setElencoListini] = useState<ElencoListiniInterface[]>();
    const [isDialogImportaOpen, setIsDialogImportaOpen] = useState(false);
    const [isDialogLogListinoOpen, setIsDialogLogListinoOpen] = useState(false);
    const handleCloseDialogImporta = () => { setIsDialogImportaOpen(false); startCerca(); }
    const handleCloseDialogLogListino = () => { setIsDialogLogListinoOpen(false); }
    const [idListinoSelez, setIdListinoSelez] = useState<number>();
    const [nomeListinoSelez, setNomeListinoSelez] = useState<string>();
    const [dataListinoSelez, setDataListinoSelez] = useState<Date>();
    const [inVigore, setInVigore] = useState<boolean | undefined>();
    const [isConfermaDeleteOpen, setIsConfermaDeleteOpen] = useState(false);
    const [isConfermaOpen, setIsConfermaOpen] = useState(false);
    const [message, setMessage] = useState<string>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const handleCloseConferma = () => setIsConfermaOpen(false);
    const handleCloseConfermaDelete = () => setIsConfermaDeleteOpen(false);
    const [fornitori, setFornitori] = useState<FornitoriCentrInterface[]>([])

    const [openDrawer, setOpenDrawer] = useState(true);

    const auth = useAuth(urlOrdiniLiberi.auth);

    const [filtri, setFiltri] = useState<OggettoInterface>({});

    const [data, setData] = useState<string>();

    useEffect(() => {
        if (auth) {
            setIsVisible(true);
            RestServices().rest('POST', "", urlAnagrafiche.getFornitoreCentral).then(result => {
                if (result) {
                    setFornitori(result);
                    setIsVisible(false);
                }
                    
            })
        }
    }, [auth]);

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const propsTabElencoListini: TabellaCompletaInterface = {
        id: "elenco_listini",
        dataSource: elencoListini,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,

        export: {
            enabled: true,
            fileName: "Listini"
        }
    }

    const columnsListini = CreaColonne([
        { caption: "Id", dataField: "id", visible: false },
        { caption: "IdAnagrafica", dataField: "idAnagrafica", visible: false },
        { caption: "Ditta", dataField: "ditta", visible: true },
        { caption: "Titolo", dataField: "titolo" },
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "In vigore", dataField: "inVigore" },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "date", sortOrder: "desc" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Attiva",
                    icon: "isblank",
                    onClick: (e: any) => {
                        const idListino = e.row.data.id;
                        setIdListinoSelez(idListino);
                        setInVigore(!e.row.data.inVigore);

                        setIsConfermaOpen(true);
                    }
                },
                {
                    hint: "Elenco download",
                    icon: "fields",
                    onClick: (e: any) => {
                        const nomeListino = e.row.data.titolo;
                        const dataListino = e.row.data.data
                        const idListino = e.row.data.id;
                        setNomeListinoSelez(nomeListino);
                        setDataListinoSelez(dataListino);
                        setIdListinoSelez(idListino);
                        setIsDialogLogListinoOpen(true);
                    }
                },
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        const idListino = e.row.data.id;
                        setIdListinoSelez(idListino);
                        setIsConfermaDeleteOpen(true);
                    }
                }
            ]
        }

    ])

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="3">
                    <label>Fornitore/Ditta</label>
                    <Selezione
                        dataSource={fornitori}
                        id="idAnagrafica"
                        impostaFiltri={impostaFiltri}
                        displayExpr="RAGIONE_SOCIALE"
                        valueExpr="ID"
                        searchEnabled={true}
                    />
                </Col>
                <Col sm="2">
                    <label>Data</label>
                    <BoxDataOra id="data" impostaFiltri={impostaFiltri} typeDate="date" value={data} />
                </Col>
                <Col sm="2">
                    <Button type="button" variant="success" id="btnCerca" onClick={startCerca}>Cerca</Button>
                </Col>
            </Row>
        )
    }

    const creaRiepilogoFiltri = () => {
        let descrFarm: string;
        if (props.datiFarmacie && filtri['idFar']) {
            descrFarm = props.datiFarmacie.filter((item) => {
                return item.ID_FAR === filtri['idFar']
            })[0].DESCRIZIONE
        }
        const elencoFarmSelez = () => {
            if (filtri.idFar.length === props.datiFarmacie.length) {
                return "Tutte"
            } else {
                return <span className="cerca" key={descrFarm}>{descrFarm}&nbsp;</span>
            }
        }

        return (
            <>
                {(filtri.idFar) && <span className="cerca">&nbsp;Farmacia: {elencoFarmSelez()}</span>}
                {(filtri.data) && <span className="cerca">&nbsp;Data: {cambiaFormatoLocalDateTimeToEuropeo(filtri.data)}</span>}
            </>
        )
    }

    const apriImporta = () => {
        setIsDialogImportaOpen(true)
    }

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            const path = basenameHostNewTabs + "ordiniLiberi/listino_dettaglio?idListino=" + rowDblClicked.data.id;
            window.open(path);
        }
    }

    const onRowPrepared = (e: any) => {
        if (e.values) {

            if (e.data.inVigore == 1) {
                e.rowElement.style.backgroundColor = '#AAFF9B';
            }

        }
    }

    const onCellPrepared = (e: any) => {
        if (e.column.type == "buttons")
            if (e.data)
                if (e.data.inVigore == true) {
                    //var elm = e.cellElement.find('a:contains(Edit)');
                    e.cellElement.firstElementChild.className = "dx-link dx-icon-isnotblank dx-link-icon";
                    e.cellElement.firstElementChild.attributes[2].nodeValue = "Disattiva listino";
                    //e.cellElement.innerHTML = e.cellElement.innerHTML.replace("Nascondi", "Visualizza");
                    setMessage("In seguito a questa operazione il listino sara' disattivato. Si vuole procedere?")
                }

    }



    const modificaListino = () => {
        if (!idListinoSelez) return;
        const param = {
            id: idListinoSelez,
            inVigore: inVigore
        }
        RestServices().rest('POST', param, urlOrdiniLiberi.setListinoInVigore).then(result => {
            if (result) {
                if (result.esito === true) {
                    if (result.response.esito == true) {
                        if (elencoListini) {
                            const newElenco = elencoListini;
                            newElenco.forEach(riga => {
                                if (riga.id === idListinoSelez) {
                                    riga.inVigore = inVigore;
                                }
                                else if (inVigore) {
                                    riga.inVigore = !inVigore;
                                }

                            })
                            setElencoListini(newElenco);
                            setIsConfermaOpen(false);
                            notify({ position: "center", width: "auto", message: "Il listino e' stato aggiornato con successo" }, 'success', 3000);
                            startCerca()
                        }
                    }

                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            }
            else {
                notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
            }
        })
    }

    const eliminaListino = () => {
        if (!idListinoSelez) return;
        const param = {
            id: idListinoSelez,
        }
        RestServices().rest('POST', param, urlOrdiniLiberi.deleteListino).then(result => {
            if (result) {
                if (result.esito === true) {
                    if (result.response.esito == true) {
                        handleCloseConfermaDelete();
                        startCerca()
                    }

                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            }
            else {
                notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
            }
        })
    }

    const startCerca = () => {

        if (filtri) {

            //if (!filtri.idFar) {
            //    notify({ position: "center", width: "auto", message: "Selezionare la farmacia grossista" }, 'error', 4000);
            //    return;
            //}

            setIsVisible(true);
            const param = {
                idAnagrafica: filtri.idAnagrafica,
                dataListino: filtri.data,
            }

            param.dataListino = trasformaDateTimeInLocale(param.dataListino, true);
            RestServices().rest('POST', param, urlOrdiniLiberi.getListini)
                .then(result => {
                    if (result) {
                        setIsVisible(false);
                        //if (result.esito) {
                        if (result.esito === true) {
                            setElencoListini(result.response)
                        } else {
                            setElencoListini(result.response);
                        }
                        //}

                    } else if (result && result.message) {
                        notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                        setIsVisible(false);
                    }
                })
        }


    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaOrdiniRda" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={openDrawer} setOpen={setOpenDrawer} />
                <Row>
                    <Col sm="6">
                        <div className="leftcontent">
                            <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                            &nbsp;
                            <span id="lblStatoTrasmesso" title="Listino attualmente attivo ed in vigore" className="lblStatoTrasmesso">Listino attivo</span>
                            &nbsp;
                        </div>
                    </Col>
                    <Col sm='6' >
                        <Button type="button" variant="primary" id="btnImportaListinoFarmaclick" onClick={apriImporta}>Importa listino</Button>
                    </Col>
                </Row>
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaCompletaAlternate
                            propsTabella={propsTabElencoListini}
                            columns={columnsListini}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={false}
                            onRowDblClick={onRowDblClick}
                            onRowPrepared={onRowPrepared}
                            onCellPrepared={onCellPrepared}
                        />
                    </Col>
                </Row>
            </div>

            <DialogImportListino
                isDialogImportListino={isDialogImportaOpen}
                datiFarmacie={props.datiFarmacie}
                setIsDialogImportListino={setIsDialogImportaOpen}
                handleCloseDialogImportListino={handleCloseDialogImporta}
                setIsVisible={setIsVisible}
                fornitori={fornitori }
            />

            <DialogConferma
                dialogIsOpen={isConfermaOpen}
                message={inVigore ? "In seguito a questa operazione il listino sara' attivato. Si vuole procedere?" : "In seguito a questa operazione il listino sara' disattivato. Si vuole procedere?"}
                functionNext={modificaListino}
                handleCloseConferma={handleCloseConferma}
            />

            <DialogConferma
                dialogIsOpen={isConfermaDeleteOpen}
                message={"In seguito a questa operazione il listino sara' eliminato. Si vuole procedere?"}
                functionNext={eliminaListino}
                handleCloseConferma={handleCloseConfermaDelete}
            />
        </>
    )

}

export default ElencoListiniOrdineLibero;