import FiltriVerticale, {
    ConvertForSelect,
  } from "../../filters/FiltriVerticaleNewVersione";
  
  export const createItemFilterAnalMagazz = (newFarmaList: any, category: any, ditte: any,gruppoMercFilters:any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },  
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        }, 
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        },   
        {
          type: "selectWithLevels",
          id: "gruppoMerc",
          label: "Gruppo Merceologico",
          displayExpr: "descrizione",
          dataSource: gruppoMercFilters,
          idValueGrid: "gruppoMerc" as any,
        },
        
      ]
    )
  }

  export const createItemFilterOssMargini = (newFarmaList: any, ditte: any,gruppoMercFilters:any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },  
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },   
        {
          type: "selectWithLevels",
          id: "gruppoMerc",
          label: "Gruppo Merceologico",
          displayExpr: "descrizione",
          dataSource: gruppoMercFilters,
          idValueGrid: "gruppoMerc" as any,
        },
        {
          type: "ckBox",
          idValue: "venditaDPC",
          label: "Includi DPC",
          dataSource: null,
          width: 200,
        },
        {
          type: "ckBox",
          idValue: "flagBusta",
          label: "Includi BUSTE",
          dataSource: null,
          width: 200,
        },
        
      ]
    )
  }