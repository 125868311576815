import React, { useEffect, useState } from 'react'
import { Loading } from '../../widget/Notifications'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { SelectFarm, Selezione } from '../../widget/Select';
import { BoxDataOra, BoxFiltro } from '../../widget/Editor';
import Button from 'react-bootstrap/esm/Button';
import notify from 'devextreme/ui/notify';
import { DatiMongoInterface } from '../../interfaces/xadm_mongodb';
import { useFarmMongo } from '../../hooks/useDatiFarm';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { RestServices } from '../../services/restServices';
import { urlFarmMongo } from '../costanti';
import { ErroreResponseInterface, OggettoInterface } from '../../interfaces/generiche';
import { trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { ListaFarmacieInterface} from '../../interfaces/farmacie';

export const XadmMondoDb = () => {
    //crea il titolo della pagina sotto la navbar
    const titolo = TitoloContenuto("Consultazione Database MongoDb");

    const getDatiFarmacie = useFarmMongo();
    const [datiFarmacie, setDatiFarmacie] = useState<ListaFarmacieInterface[]>([]);
    useEffect(() => {
        if (getDatiFarmacie) {
            setDatiFarmacie(getDatiFarmacie);
            var dateDal = new Date();
            dateDal.setDate(dateDal.getDate() - 2);
            var dateAl = new Date();
            dateAl.setDate(dateAl.getDate() + 1);
            setDal(dateDal.toString());
            setAl(dateAl.toString());
            setFiltri({
                dal: dateDal.toString(),
                al: dateAl.toString()
            })
            
        }
        
    }, [getDatiFarmacie])
    //rende visibile/invisibile il pannello di loading
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }
    const [risultati, setRisultati] = useState<DatiMongoInterface[]>();
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();
    const typeDate = "datetime";
    const noDataText = "Caricamento in corso";
    const displayExpr = false;
    const tipi = ["Request", "Response"];
    let param;
    //fa partire la ricerca solo se i campi farmacia, data inizio e data fine sono valorizzati
    const startCerca = () => {
        setIsVisible(true);
        console.log(filtri)
        if (filtri && filtri.idFar && filtri.dal && filtri.al) {
            param = JSON.parse(JSON.stringify(filtri));
            param.dal = trasformaDateTimeInLocale(param.dal);
            param.al = trasformaDateTimeInLocale(param.al);
            RestServices().rest('POST', param, urlFarmMongo.datiFarm).then(result => {
                setIsVisible(false)
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                    } else if (result && result.message && result.severity) {
                        notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                    } else {
                        setRisultati(result);
                    }
                    // }


                    // if (result === true) {
                    //     notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                    // } else if (result && result.message && result.severity) {
                    //     notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                    // } else if (result !== true) {
                    //     setRisultati(result);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops, si è verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
                }
            })
        } else {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare la farmacia e le date" }, "error", 4000)
        }
    };

    function Lista() {
        if (risultati) {
            const ListaRisultati = risultati.map((risultato, index) =>
                <>
                    <li key={risultato.tphUser}><span className="titolo">TPH User: </span>{risultato.tphUser}</li>
                    <li key={risultato.uuid}><span className="titolo">UUID: </span>{risultato.uuid}</li>
                    <li key={risultato.tipo}><span className="titolo">Tipo: </span>{risultato.tipo}</li>
                    <li key={risultato.data}><span className="titolo">Data: </span>{risultato.data}</li>
                    <li key={risultato.className}><span className="titolo">Nome Classe: </span>{risultato.className}</li>
                    <li key={risultato.methodName}><span className="titolo">Nome Metodo: </span>{risultato.methodName}</li>
                    <li key={risultato.path}><span className="titolo">Path: </span>{risultato.path}</li>
                    <li key={index}><span className="titolo">Lavorato: </span>{JSON.stringify(risultato.lavorato)}</li>
                    <li key={`payload_${index}`}><span className="titolo">Payload: </span>{JSON.stringify(risultato.payload)}</li>
                    <li key={`queryParam_${index}`}><span className="titolo">Parametri Query: </span>{JSON.stringify(risultato.queryParam)}</li>
                    <li key={`riga_${index}`}><div className="riga"></div></li>
                </>
            )
            return <ul className="listaRisultati">{ListaRisultati}</ul>
        }
    }

    return (
        <>
            {loadPanel}
            {titolo}
            <div className="contenutoPagina">
                <Row className="rigaFiltri">
                    <Col sm="4">
                        <label>Farmacia *</label>
                        <SelectFarm datiFarmacie={datiFarmacie} id={'idFar'} impostaFiltri={impostaFiltri} noDataText={noDataText} displayExpr={displayExpr} showCodTph={true} showGes={true} />
                    </Col>
                    <Col sm="2">
                        <label>Dal *</label>
                        <BoxDataOra typeDate={typeDate} impostaFiltri={impostaFiltri} id="dal" value={dal}/>
                    </Col>
                    <Col sm="2">
                        <label>Al *</label>
                        <BoxDataOra typeDate={typeDate} impostaFiltri={impostaFiltri} id="al" value={al}/>
                    </Col>
                    <Col sm="1">
                        <label>Tipo</label>
                        <Selezione impostaFiltri={impostaFiltri} dataSource={tipi} id="tipo" />
                    </Col>
                    <Col sm="2">
                        <label>Path</label>
                        <BoxFiltro id="path" impostaFiltri={impostaFiltri} />
                    </Col>
                    <Col sm="1">
                        <Button type="button" variant="success" id="btnCerca" onClick={startCerca}>Cerca</Button>
                    </Col>
                </Row>
                <Row className="risultati">
                    {risultati ? <h4>Risultati: {risultati.length}</h4> : <h4>Nessun risultato</h4>}
                </Row>
                <Row className="tabella">
                    <Col sm="12">
                        {Lista()}
                    </Col>
                </Row>
            </div>
        </>
    )
}