import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import AsyncSelect from "react-select/async"
import { SelectBox } from 'devextreme-react/select-box'
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { RadioGroup } from 'devextreme-react/radio-group'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import { faAngleUp ,faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { CheckBox } from 'devextreme-react/check-box'
import Button from 'devextreme-react/button'
import {
    Validator,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    EmailRule  
  } from 'devextreme-react/validator';

//import './style.css';


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    //listaFarmacie:any
} 

export const DialogAnagFarmacie:React.FC<DialogProps> = ({open,close,ricarica,edit})  => {

    const [loading,setLoading] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>(edit)
    const [openD,setOpenD] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const auth = useAuth(urlConsultazione.auth)
    const [tipologia,setTipologia] = useState<any>([])
    const [pVendita,setPVendita] = useState<any>([])
    const [listDisl,setListDisl] = useState<any>([])
    const [listAsl,setListAsl] = useState<any>([])
    const [listTipoStru,setListTipoStru] = useState<any>([])
    const [baciUtenz, setListbaciUtenz] = useState<any>([])
    const [fasciFatt, setListFacsFatt] = useState<any>([])
    const [supVendita, setListSupVend] = useState<any>([])
    const [anaForn, setListAnaForn] = useState<any>([])
    const [ulterioriDatiOpen,setulterioriDatiOpen] = useState<boolean>(true)


const getAnaForn = () =>{
    if (auth) {
       RestServices().rest('POST', {
        idFar:edit?.id
       } , urlConsultazione.getAnaFornRifFarm )
           .then(result => {
               if (result?.esito) {                         
                setListAnaForn(result.response)                                         
               }else{                                
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
               }
           })     
   }
}


const getSupVend = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListSupVen )
           .then(result => {
               if (result?.esito) {                         
                setListSupVend(result.response)                                         
               }else{                                
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
               }
           })     
   }
}

const getFascFatt = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListFasFatt)
           .then(result => {
               if (result?.esito) {                         
                setListFacsFatt(result.response)                                         
               }else{                                
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
               }
           })     
   }
}

const getListbaci = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListBacUtenz)
           .then(result => {
               if (result?.esito) {                         
                setListbaciUtenz(result.response)                                         
               }else{                                
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
               }
           })     
   }
}
   

    const getListDisl = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListDisloc)
               .then(result => {
                   if (result?.esito) {                         
                    setListDisl(result.response)                                         
                   }else{                                
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
                   }
               })     
       }
    }

    const getListAsl = () =>{    

        if (auth) {  
           RestServices().rest('POST', { idFar:edit?.id } , urlConsultazione.getListAsl)
               .then(result => {
                   if (result?.esito) {                         
                    setListAsl(result.response)                                         
                   }else{                                
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
                   }
               })     
       }
      
    }
    

    const getListTipoStru = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListTipoStrut)
               .then(result => {
                   if (result?.esito) {                         
                    setListTipoStru(result.response)                                         
                   }else{                                
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
                   }
               })     
       }
    }

    const getPVendite = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListPuntoVendita)
               .then(result => {
                   if (result?.esito) {                    
                        setPVendita(result.response)                      
                   }else{                                
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                   
                   }
               })     
       }
    }


    const getTipologia = () =>{
        if (auth) {
           
           RestServices().rest('POST', { } , urlConsultazione.getListTipologiaFarmacia)
               .then(result => {
                   if (result?.esito) {               
                             
                        setTipologia(result.response)                      
                   }else{                                
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                      
                   }
               })     
       }
    }


    const UpdateAnaFarmacie = () =>{
        //alert(JSON.stringify(objctTOsave))

        let objToUse = {
            "id": objctTOsave.id,
            "cap": objctTOsave.cap,
            "codice": objctTOsave.codice,
            "descrizione": objctTOsave.descrizione,
            "email": objctTOsave.email,
            "fax": objctTOsave.fax,
            "idComune": objctTOsave.idComune,
            "indirizzo": objctTOsave.indirizzo,
            "referente": objctTOsave.referente,
            "sito": objctTOsave.sito,
            "telefono":  objctTOsave.telefono,
            "telefonoReferente": objctTOsave.telefonoReferente,
            "codiceFiscale": objctTOsave.codiceFiscale,
            "titolare": objctTOsave.titolare,
            "idTipologiaFarmacia": objctTOsave.idTipologiaFarmacia,
            "idTipoPuntoVendita": objctTOsave.idTipoPuntoVendita,
            "latitudine": objctTOsave.latitudine,
            "longitudine": objctTOsave.longitudine,
            "codiceNSIS": objctTOsave.codiceNSIS,
            "piva": objctTOsave.piva,
            "codiceASL": objctTOsave.codiceASL,
            "numeroCasse": objctTOsave.numeroCasse,
            "idASL" : objctTOsave.idASL,
            "idDislocazione" : objctTOsave.idDislocazione,
            "idTipoStruttura": objctTOsave.idTipoStruttura,
            "idBacinoUtenze" : objctTOsave.idBacinoUtenze,
            "idFasciaFatturato": objctTOsave.idFasciaFatturato,
            "idAnaGeneraleForn" : objctTOsave.idAnaGeneraleForn,
            "ragioneSociale" : objctTOsave.ragioneSociale ,
            //"fornitoreRagioneSociale" :  objctTOsave.fornitoreRagioneSociale,
            "idSuperficieVen": objctTOsave.idSuperficieVen,
            "flagPrivacy":objctTOsave.flagPrivacy == 1 ? 1 : 0

          }
        
        if (auth) {
           //console.log(objctTOsave);
           RestServices().rest('POST', objToUse , urlConsultazione.updateFarmacie)
               .then(result => {
                   if (result?.esito) { 
                    ricarica()  
                                           
                   }else{                              
                      setLoading(false)
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   }
               })     
       }
    }

    const loadOptions = (
        inputValue: string,
        callback: (options: any) => void
        ) => {
            let idfartoUse = null
            //console.log(inputValue);
                   
            if(inputValue){
                RestServices().rest('POST', {
                    filter:inputValue
                } , urlConsultazione.getListComuni)
                .then((result: { esito: any; response: any }) => {
                    if (result?.esito) {

                        let arrToUse:any = []
                        result.response.forEach((element:any) => {
                            arrToUse.push({value:element.id,label:element.comune+' ('+element.provincia+')'})
                        });
                       callback(arrToUse);
                        
                    }else{
                                   
                        callback([]);
                        
                    }
                    
                }) 
            }
           
            
      };


    const asyncFOrnitori = (
        inputValue: string,
        callback: (options: any) => void
        ) => {
            let idfartoUse = null
            //console.log(inputValue);
                   
            if(inputValue){
                RestServices().rest('POST', {
                    idFar:objctTOsave?.id,
                    query:inputValue
                } , urlConsultazione.getAnaFornRifFarm)
                .then((result: { esito: any; response: any }) => {
                    if (result?.esito) {

                        let arrToUse:any = []
                        result.response.forEach((element:any) => {
                            arrToUse.push({value:element.idFornitore,label:element.ragioneSociale})
                        });
                       callback(arrToUse);
                        
                    }else{
                                   
                        callback([]);
                        
                    }
                    
                }) 
            }
           
            
      };  

  

    const cambiaValore = (tipo:string,value:string) =>{
        let objTo = {...objctTOsave}
        if(tipo == 'idAnaGeneraleForn'){
            objTo.fornitoreRagioneSociale = null
        }
        objTo[tipo] = value   
        setObjctTOsave(objTo)
  }


    useEffect(()=>{
        if(edit){
            //console.log('dato');
            //console.log(edit);
            
            
            setObjctTOsave(edit) ;      
        }else{      
            setObjctTOsave({})
        }

    },[edit, openD])

    useEffect(()=>{
        if(edit){
             setObjctTOsave(edit) ;
        }
    },[edit, openD])




    useEffect(()=>{
         if(auth && edit){
            getTipologia()
            getPVendite()
            getListDisl()
            getListAsl()
            getListTipoStru()
            getListbaci()
            getFascFatt()
            getSupVend()
            getAnaForn()
        }
       
    },[auth,edit])


    useEffect(()=>{
        setOpenD(open)
        //farmaList()
        
    },[open])


    return (
        <>
       
   
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'80%'}
          showTitle={true}
          title="Modifica Anagrafica Farmacie"
        >
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'CHIUDI',
                onClick: ()=>{close()},
              }}
          />
          {/* <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={ {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:disableButton,
                onClick: ()=>UpdateAnaFarmacie(),
              }}
          /> */}

        
               <ScrollView width='100%' height='100%'>

               <form action="your-action" onSubmit={(e)=>{
                UpdateAnaFarmacie()

                e.preventDefault();
            }}>
                <div className='form-row '> 
                <div className="form-group col-md-6">
                      <label> CODICE </label> 
                      <TextBox maxLength="10" value={objctTOsave?.codice} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(e:any)=>cambiaValore('codice',e.value)} placeholder="Campo obbligatorio - max 10 caratteri"  > 
                      <Validator>
                            <RequiredRule message="Codice Obbligatorio" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                            <StringLengthRule message="Il codice deve essere massimo di 10 caratteri" max={10} />
                        </Validator>
                      </TextBox> 
                   </div>

                   <div className="form-group col-md-6">
                   <label> DESCRIZIONE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.descrizione} onValueChanged={(e:any)=>cambiaValore('descrizione',e.value) }  placeholder="Inserire obbligatoriamente min 4 caratteri" >
                      <Validator>
                            <RequiredRule message="Descrizione Obbligatorio" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                            <StringLengthRule message="Il codice deve essere massimo di 50 caratteri" max={50} />
                        </Validator> </TextBox> 
                   </div>
                   </div>

                   <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> P. IVA </label> 
                      <TextBox maxLength="11" valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.piva} onValueChanged={(e:any)=>cambiaValore('piva',e.value) }   placeholder="Inserire obbligatoriamente max 11 caratteri" >
                      <Validator>
                            <RequiredRule message="P.iva Obbligatorio" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                            <StringLengthRule message="Il codice deve essere massimo di 11 caratteri" max={11} />
                        </Validator> </TextBox>  
                   </div>
                   <div className="form-group col-md-6">
                   <label> CODICE FISCALE </label> 
                   <TextBox maxLength="16" valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.codiceFiscale} onValueChanged={(e:any)=>cambiaValore('codiceFiscale',e.value) }   placeholder="Inserire i 16 caratteri del codice fiscale" /> 
                    </div>
                             
                    </div> 

                    <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> TITOLARE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.titolare} onValueChanged={(e:any)=>cambiaValore('titolare',e.value) }   placeholder="Inserisci..." /> 
                   </div>
                    
                   <div className="form-group col-md-6">
                   <label> INDIRIZZO </label> 
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.indirizzo} onValueChanged={(e:any)=>cambiaValore('indirizzo',e.value) }   placeholder="Inserire obbligatoriamente min 4 caratteri" >
                   <Validator>
                            <RequiredRule message="Indirizzo Obbligatorio" />            
                        </Validator> </TextBox> 
                    </div>
                   
                    </div> 

                    <div className='form-row' >
                   <div className="form-group col-md-6"  >
                   <label> CAP </label> 
                      <TextBox maxLength="5"  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.cap} onValueChanged={(e:any)=>cambiaValore('cap',e.value) }   placeholder="Inserire obbligatoriamente 5 caratteri" >
                      <Validator>
                            <RequiredRule message="Cap Obbligatorio" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                            <StringLengthRule message="Il cap deve essere 5 caratteri" max={5} />
                        </Validator> </TextBox>  
                   </div>

                   <div className="form-group col-md-6">
                   <label> COMUNE  </label> 
                   {objctTOsave?.comune ? (
                    <>
                    <AsyncSelect
                        placeholder="Campo Obbligatorio"
                        isClearable= {true}
                        defaultValue={{id:objctTOsave?.idcomune,label:objctTOsave?.comune}}
                        loadOptions={loadOptions}
                        onChange={(x:any)=>cambiaValore('idComune',x?.value) }
                        />
                      </>   
                   ) : (
                    <div>
                    <AsyncSelect
                         placeholder="Campo Obbligatorio"
                         isClearable= {true}
                         loadOptions={loadOptions}
                         onChange={(x:any)=>cambiaValore('idComune',x?.value)}
                         /> 
                         </div>
                   )}
                    
                   </div>
                   </div>


                   <div className='form-row '>
                    <div className="form-group col-md-6">
                   <label> TELEFONO  </label> 
                      <TextBox maxLength="15"  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.telefono} onValueChanged={(e:any)=>cambiaValore('telefono',e.value) }   placeholder="Inserisci..." >
                      <Validator>
                            <RequiredRule message="Telefono Obbligatorio" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}                 
                        </Validator> </TextBox>  
                   </div>
                    
                   <div className="form-group col-md-6">
                   <label> FAX </label> 
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.fax} onValueChanged={(e:any)=>cambiaValore('fax',e.value) }   placeholder="Inserisci..." /> 
                    </div>
                    </div> 

                    <div className='form-row '>
                    <div className="form-group col-md-6">
                   <label> EMAIL </label> 
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.email} onValueChanged={(e:any)=>cambiaValore('email',e.value) }   placeholder="Inserisci email" >
                   <Validator>
                       <RequiredRule message="Email Obbligatoria" />
                            <EmailRule message="Deve essere un formato email" />
                        </Validator> 
                        </TextBox>  
                    </div>      
                   <div className="form-group col-md-6">
                   <label> SITO </label> 
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.sito} onValueChanged={(e:any)=>cambiaValore('sito',e.value) }   placeholder="Inserisci..." /> 
                    </div>
                    </div> 

                    <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> REFERENTE  </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.referente} onValueChanged={(e:any)=>cambiaValore('referente',e.value) }   placeholder="Inserisci..." /> 
                   </div>
                    
                   <div className="form-group col-md-6">
                   <label> TELEFONO REFERENTE </label> 
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.telefonoReferente} onValueChanged={(e:any)=>cambiaValore('telefonoReferente',e.value) }   placeholder="Inserisci..." /> 
                    </div>
                    </div>  

                    <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> TIPOLOGIA </label>
                    <SelectBox dataSource={tipologia} showDataBeforeSearch={true}  valueExpr="id"  displayExpr="tipologiaFarmacia"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idTipologiaFarmacia} onValueChanged={(e:any)=>cambiaValore('idTipologiaFarmacia',e.value) }    >
                    <Validator>
                       <RequiredRule message="Tipologia Obbligatoria" />
                        </Validator> 
                        </SelectBox>              
                   </div>

                   <div className="form-group col-md-6">
                   <label> TIPO PUNTO VENDITA </label> 
                   <SelectBox dataSource={pVendita} showDataBeforeSearch={true}  valueExpr="id"  displayExpr="tipoPuntoVendita"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idTipoPuntoVendita} onValueChanged={(e:any)=>cambiaValore('idTipoPuntoVendita',e.value) }    >
                   <Validator>
                       <RequiredRule message="Tipo punto vendita Obbligatorio" />
                        </Validator> 
                        </SelectBox>    
                    
                   </div>
                   </div>

                   <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> LATITUDINE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.latitudine} onValueChanged={(e:any)=>cambiaValore('latitudine',e.value) }   placeholder="Inserisci..." /> 
                   </div>
                    
                   <div className="form-group col-md-6">
                   <label> LONGITUDINE </label> 
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.longitudine} onValueChanged={(e:any)=>cambiaValore('longitudine',e.value) }   placeholder="Inserisci..." /> 
                    </div>
                    </div>
    

                    <div className='form-row '>                      
                   <div className="form-group col-md-6">
                   <label> Codice NSIS </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.codiceNSIS} onValueChanged={(e:any)=>cambiaValore('codiceNSIS',e.value) }  placeholder="Inserisci..." />  
                    </div>
                    <div className='form-row '>              
                   <div className="form-group col-md-6">
                   <label>  </label>                
                    </div>
                    </div> 
                    </div> 

                    <TabPanel
                        loop={false}
                        animationEnabled={true}
                       // onOptionChanged={onOptionChanged}   
                        width={'100%'} 
                        height={'100%'}          
                    >
                        <Item title="ULTERIORI DATI" >


                   <div style={{ marginTop:20, marginRight:10, marginLeft:10}}>
                        <div className='form-row ' >                      
                   <div className="form-group col-md-6">
                   <label> CODICE ASL </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.codiceASL} onValueChanged={(e:any)=>cambiaValore('codiceASL',e.value) }  placeholder="Codice ASL" />  
                    </div>
                               
                   <div className="form-group col-md-6">
                   <label> NUMERO CASSE </label>  
                   <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.numeroCasse} onValueChanged={(e:any)=>cambiaValore('numeroCasse',e.value) }  placeholder="Numero casse" />              
                    </div>
                    </div>

                    <div className='form-row '>                      
                   <div className="form-group col-md-6">
                   <label> ASL </label> 
                   <SelectBox dataSource={listAsl} showDataBeforeSearch={true}  valueExpr="idField"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idASL} onValueChanged={(e:any)=>cambiaValore('idASL',e.value) }    />  
                                    
                    </div>  
            
                   <div className="form-group col-md-6">
                   <label> DISLOCAZIONE </label>  
                   <SelectBox dataSource={listDisl} showDataBeforeSearch={true}  valueExpr="idField"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idDislocazione} onValueChanged={(e:any)=>cambiaValore('idDislocazione',e.value) }    />  
                                  
                    </div>
                    </div>

                    <div className='form-row '>                      
                   <div className="form-group col-md-6">
                   <label> TIPO STRUTTURA </label> 
                   <SelectBox dataSource={listTipoStru} showDataBeforeSearch={true}  valueExpr="idField"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idTipoStruttura} onValueChanged={(e:any)=>cambiaValore('idTipoStruttura',e.value) }    />                   
                    </div>                  
                   <div className="form-group col-md-6">
                   <label> BACINO UTENZE </label>  
                   <SelectBox dataSource={baciUtenz} showDataBeforeSearch={true}  valueExpr="idField"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idBacinoUtenze} onValueChanged={(e:any)=>cambiaValore('idBacinoUtenze',e.value) }    />                  
                    </div>
                    </div>

                    <div className='form-row '>                      
                   <div className="form-group col-md-6">
                   <label> FASCIA FATTURATO </label> 
                   <SelectBox dataSource={fasciFatt} showDataBeforeSearch={true}  valueExpr="idField"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idFasciaFatturato} onValueChanged={(e:any)=>cambiaValore('idFasciaFatturato',e.value) }    />                 
                    </div>                  
                   <div className="form-group col-md-6">
                   <label> ANAGRAFICA FORNITORE RIF.</label>  
                   {objctTOsave?.idAnaGeneraleForn && objctTOsave.fornitoreRagioneSociale ? (
                    <>
                    <AsyncSelect
                        isClearable= {true}
                        defaultValue={{id:objctTOsave?.idAnaGeneraleForn,label:objctTOsave?.fornitoreRagioneSociale}}
                        loadOptions={asyncFOrnitori}
                        onChange={(x:any)=>cambiaValore('idAnaGeneraleForn',x?.value) }
                        />
                      </>   
                   ) : (
                    <div>
                    <AsyncSelect
                         isClearable= {true}
                         loadOptions={asyncFOrnitori}
                         onChange={(x:any)=>cambiaValore('idAnaGeneraleForn',x?.value)}
                         /> 
                         </div>
                   )}
                    </div>
                    </div>

                     <div className='form-row '>                      
                   <div className="form-group col-md-6">
                   <label> SUPERFICE VENDITA </label> 
                   <SelectBox dataSource={supVendita} showDataBeforeSearch={true}  valueExpr="idField"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idSuperficieVen} onValueChanged={(e:any)=>cambiaValore('idSuperficieVen',e.value) }    />                
                    </div>  

                   <div className="form-group col-md-6">
                   <label> CONSENSO PRIVACY </label>  

                   <div style={{marginTop:8}}>
                    {objctTOsave?.flagPrivacy == 1 ? (
                         <CheckBox  onValueChanged={(e:any)=>cambiaValore('flagPrivacy',e.value) } defaultValue={true} />
                    ): (
                        <div>
                            <CheckBox  onValueChanged={(e:any)=>cambiaValore('flagPrivacy',e.value) } defaultValue={false} />
                        </div>
                    )}                                 
                   </div>

                    </div>
                    
                    </div> 

                    </div>
                   

                        </Item>

                 </TabPanel>


                    <div style={{position:'fixed',bottom:22,right:130,zIndex:2, width:100}}>
                  <Button
                    id="button"
                    text="SALVA"
                    type="success"
                    width={200}            
                    useSubmitBehavior={true} />      
            </div>
                    </form>
                        
  
    
           </ScrollView>
           

        </Popup>
        </>
      )

  
}
