import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import { DialogConferma, Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
import { DialogTipoCausaPrimaNota } from "./dialog/pop_tipo_prima_nota"
import { DialogNascondiTipo } from "./dialog/Pop_nascondi_tipo"
import './style.css';



const GestTipoCausale = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlConsultazione.auth);    
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [nacoste,setNascoste] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [openPop2 , setOpenPop2 ] = useState<boolean>(false)
    const [popDel,setPopDel] = useState<boolean>(false)
    const [idDel,setIddel] = useState<number>(0)
    const [messDel,setMessDel] = useState<string>('')
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(true)

    const columns = [ 
        { caption: "Codice", dataField: "codice" },  
        { caption: "Standard", dataField: "flagDefault" },
        { caption: "Entrata", dataField: "flagEntrata" },  
        { caption: "Uscita", dataField: "flagUscita" },    
        { caption: "Nome", dataField: "descrizioneBreve" },   
        { caption: "Descrizione", dataField: "descrizioneEstesa" }, 
        { caption: "Azioni",  type: "buttons", buttons: [

            {
                hint: "Nascondi",
                icon: "minus",
                visible:(x:any)=>isVisible(x),
                onClick: (e: any) => {
                    setObjtipo(e.row.data)
                   // console.log(e.row.data);
                    actionNasc(e.row.data.id)
                    
                                              
                }
            },
            {
                hint: "Modifica",
                icon: "edit",
                visible:(x:any)=>isEditVisible(x),
                onClick: (e: any) => {
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setOpenPop(true)                            
                }
            },
            {
                hint: "Disabilita",
                icon: "trash",
                visible:(x:any)=>isEditVisible(x),
                onClick: (e: any) => {
                    //console.log(e.row.data);           
                    setIddel(e.row.data.id)
                    setMessDel("La tipologia "+e.row.data.descrizioneBreve+" sta per essere eliminata. Confermare?")
                    setPopDel(true)                
                }
            }]  },
     ];



     const isNascosto = (id:number) =>{
           // console.log('vediamo');
           // console.log(id);        
            const pos = nacoste.map((e:any) => e.idCausale).indexOf(id);
           // console.log(pos);
            
            if(pos > -1){
                return true
            }else{
                return false
            }
     }
   
     const isEditVisible = (e:any) =>{
        if(e.row.data.flagDefault){
          return false;
        }else{
            return true ;
        }     
      }


      const isVisible = (e:any) =>{
        if(e.row.data.flagDefault){
            if(isNascosto(e.row.data.id)){
                return false
            }else{
                return true;
            }
          
        }else{
            return false ;
        }     
      }

    //   const Nascondi = (e:any) =>{
    //     if(e.row.data.flagDefault){
    //       return true;
    //     }else{
    //         return false ;
    //     }     
    //   }


    const causNasc = () =>{
        if (auth) {
            setLoading(true)
            RestServices().rest('POST', {} , urlConsultazione.getListCausaNascostaPN)
                .then(result => {
                    if (result?.esito) {
                        
                       // console.log('nascoste');
                       // console.log(result.response);
                        setNascoste(result.response)
                        
                        
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }

    }
    const getTipoCasuaAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListTipoCausaPrimaNota)
                .then(result => {
                    if (result?.esito) {
                        let newArr:any = []
                        result.response.forEach((element: any) => {
                            if (element.items) {
                                element.items.forEach((el: any) => {
                                    let obj = el;
                                    if (!el.flagDefault) {
                                        obj.flagDefault = false
                                    }
                                    if (!el.flagEntrata) {
                                        obj.flagEntrata = false
                                    }
                                    if (!el.flagUscita) {
                                        obj.flagUscita = false
                                    }
                                    newArr.push(el)
                                })
                            }
                            
                            
                        });

                        setTabellaPrincipale(newArr)
                        setLoading(false)
                        //ricarica()
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }
    }

    const getTipoCasuaActionRicarica = () =>{
        setLoading(true)
        RestServices().rest('POST', {} , urlConsultazione.getListTipoCausaPrimaNota)
                .then(result => {
                    if (result?.esito) {
                        let newArr:any = []
                        result.response.forEach((element:any) => {
                            if (element.items) {
                                element.items.forEach((el: any) => {
                                    let obj = el;
                                    if (!el.flagDefault) {
                                        obj.flagDefault = false
                                    }
                                    if (!el.flagEntrata) {
                                        obj.flagEntrata = false
                                    }
                                    if (!el.flagUscita) {
                                        obj.flagUscita = false
                                    }
                                    newArr.push(el)
                                })
                            }
                            
                        });

                        setTabellaPrincipale(newArr)
                        setLoading(false)
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }


    const actionNasc = (id:number) =>{
        RestServices().rest('POST', {idCausalePrimaNota:id} , urlConsultazione.addCausaNascostaPN)
                .then(result => {
                   if(result.esito){
                       //setPopDel(false)
                       getTipoCasuaActionRicarica()
                       notify({ position: "center", width: "auto", message: 'Causale nascosta con successo'}, "success", 4000)

                   }
                   else{
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    setLoading(false)
                }
        
                })
        
    }

    

    const removeTipo = () =>{
        if (auth) {
            RestServices().rest('POST', {idCausale:idDel} , urlConsultazione.deleteTipoCausaPrimaNota)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       ricarica()
                   }
                   else{
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    setLoading(false)
                }
        
                })
        
       } 
    }
   

    const ricarica = () =>{
        setOpenPop(false)  
        setImmediate(false);
        getTipoCasuaAction()
        setOpenPop2(false)  
    }
    


   useEffect(()=>{
        causNasc()
         getTipoCasuaAction()
    },[auth])
    
    useEffect(() => { 
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

  
    return (
        <>
             <DialogConferma
                    dialogIsOpen={popDel}
                    message={messDel}
                    functionNext={()=>removeTipo()}
                    handleCloseConferma={()=>{setPopDel(false);setIddel(0)}}
                />


         <DialogTipoCausaPrimaNota typeEdit={edit} edit={objtipo} ricarica={()=>ricarica()} close={()=>{setOpenPop(false);setObjtipo({});setImmediate(false)}} open={openPop} />
           
         <DialogNascondiTipo  typeEdit={edit} edit={objtipo} ricarica={()=>ricarica()} close={()=>{setOpenPop2(false);setObjtipo({});setImmediate(false);getTipoCasuaActionRicarica()}} open={openPop2} />

        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> CAUSALI PRIMA NOTA <span className='subt' ></span> </h3>
             </div>

             <div className='button_primabanche'>
             <Button  className=''    variant="primary" onClick={()=>setOpenPop(true)} >
               NUOVA CAUSALE
            </Button>
            <Button style={{marginLeft:10}} className=''    variant="primary" onClick={()=>setOpenPop2(true)} >
               MOSTRA NASCOSTE
            </Button>
            {/* NUOVO BOTTONE PER POP NASCOSTE */}

            {/* <Button   className='btnbanca'   variant="warning" onClick={()=>setOpenPop2(true)} >
               TIPOLOGIE NASCOSTE
            </Button> */}
            </div>
               
            
            <NewTabella 
                idTabella='Gest_tipo_casuale'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow  
                headerFilter
                searchPanel={true}  
                exportT={{
                    enabled: true,
                    fileName: "Gest_tipo_casuale"
                }}
                activeSaver={true}
                columnC={true}           
            />

           
        </>
    )
}

export default  GestTipoCausale


