import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { MenuCruscotto } from "../components/menu_cruscotto";
import Button from "react-bootstrap/Button";
import { DialogAnalRicette } from "./dialog/PopAnaRicette";
import { createItemFilterAnalRicette } from "./ItemsFiltri/analisiPerOperatore";
import {  urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import {  PieChart } from 'devextreme-react';
import { boxCardAnaRicette, boxsecondRicette, boxterzRicette } from "./BoxCard/analisiPerOperatore";
import {
  Export,
  Font,
  Series,
  Subtitle,
  Title,
  Tooltip,
  ValueAxis,
  HoverStyle
} from 'devextreme-react/chart';
import "./styleAnalisi.css";


const CruscottoAnaliRicetteEvo = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePop2, setVisiblePop2] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnaRicette);
  //const [fila1cards, setFila1cards] = useState<cardC[]>(boxprimoRicette);
  const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecondRicette);
  const [fila3cards, setFila3cards] = useState<cardC[]>(boxterzRicette);
  const [firstpie, setFirstpie] = useState<any[]>([]);
  const [settori, setSettori] = useState<any[]>([]);
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [openPopTwo, setOpenPopTwo] = useState<boolean>(false);
  const [openPopThree, setOpenPopThree] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);

  const dashboard9 = { dashboardID: 9 };

  function aggiornaDataFine(dataFine: Date): Date {
    const nuovaDataFine = new Date(dataFine);

    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    // let venditaDPC = [0];
    // let flagBusta = [0];

    // if (params?.venditaDPC) {
    //   venditaDPC = [1];
    // }
    // if (params?.flagBusta) {
    //   flagBusta = [1];
    // }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      // venditaDPC: venditaDPC,
      // flagBusta: flagBusta,
      operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall9 = {
        ...dashboard9,
        idFarList: params.idFar,
        params: parameters,
      };

      //new call

      //console.log('mostro call');
      //console.log(dashboardCall9);

      RestServices()
        .rest("POST", dashboardCall9, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {
            
            
            let datiPrimaParteCards = trasform_for_cards_charts_01(result["56"], boxCardAnaRicette);
            let datiSecondaParteCards = trasform_for_cards_charts_01(
              result["56"],
              boxsecondRicette
            );
            let datiTerzaParteCards = trasform_for_cards_charts_01(
              result["56"],
              boxterzRicette
            );

            let arrayTousePie = [
              {label:'SSN',value:result["56"][0]?.VENDUTO_DECURTATO_SSN_SSN},
              {label:'MISTA',value:result["56"][0]?.VENDUTO_DECURTATO_SSN_MISTA},
          ]

           // 1 chartpie
            setFirstpie(arrayTousePie);
                  

            setFila1cards(datiPrimaParteCards);
            setFila2cards(datiSecondaParteCards);
            setFila3cards(datiTerzaParteCards);


            setIsVisible(false);

          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });



      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });


    }
  };




  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const filters = () => {
    if (auth) {

    const SOLO_SSN = { id: 1, descrizione: "SSN" };
    const MISTA = { id: 0, descrizione: "MISTA" };

    const vendite = [SOLO_SSN, MISTA];
    setTipologiaVendita(vendite);
    }
  };

  useEffect(() => {
    filters();
  }, [auth]);

  const exportFormats = ["csv"];

  const onExporting = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("tabella_operatori");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-csv
      // https://github.com/exceljs/exceljs#reading-csv
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "tabella_operatori.csv"
        );
      });
    });
  };

  const itemsFields = createItemFilterAnalRicette(
    newFarmaList,
    tipologiaVendita,
  );

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };

  const popVisible2 = (testo: string) => {
    setVisiblePop2(true);
  };

  let mess2 = ["val pieChart "];


  return (
    <>
      <DialogAnalRicette
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        auth={auth}
        close={() => {
          setOpenPop(false);
          setObjtipo(null);
        }}
        filterData={paramsFilter}
        open={openPop}
      />


      <section style={{ display: "flex", height: "93vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >
              <h2>BusinessLab Evolution - Cruscotto Direzionale</h2>
            </div>
            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuCruscotto />
              </div>
            </div>

            {/* flexbox */}

            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"40%"}
              height={"40%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup>

            <div>
              <div className="grid-container-sec">
                {fila1cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>
              <div className="grid-container-sec">
                {fila2cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
                <div style={{height:240,padding:10,background: "#fff"}} className="newPieRice">
                  <PieChart
                    id="pie"
                    dataSource={firstpie}
                    palette="Green Mist"
                    paletteExtensionMode="alternate"
                    type="doughnut"

                  //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                  >

                    {/* <Series
                      argumentField="Label"
                      valueField="VALORE_VENDUTO"
                    > */}

                      <Series
                        argumentField="label"
                        valueField="value"
                      >
                        <HoverStyle color="rgb(255, 0, 0)" />
                      </Series>

                      {<Tooltip
                        enabled={true}
                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                          console.log('vediamo value');
                          console.log(percentText);
                          
                          const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);

                          return {
                            html: `<p>Valore del venduto SSN - ${argument} </p><p>${formattedValue} / ${(percentText)}</p>`,
                          };
                        }}
                      />}
                      <Title
                        text="Vendite decurtato SSN per tipologia"
                      >
                        <Font color="#5b6372" size="16" weight="400" />
                      </Title>

                      <Export enabled={true} />
                  </PieChart>
                  <div style={{ display: "flex" }}>
                    <button
                      className="ibutton-lite-two"
                      style={{ border: "none" }}
                      onClick={() => popVisible2("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div className="grid-container-sec">
                {fila3cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>
            </div>

            {/* <div className="containButtonAna">
              <div className="buttonCerca">
                <Button
                  className="btn btn-primary"
                  variant="primary"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  onClick={() => {
                    console.log("dialog3");
                    setOpenPop(true);
                    //ricerca(objPostRicerca);
                  }}
                >
                  Confronto farmacie
                </Button>
              </div>
            </div> */}

          </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
          <div style={{}}>
            <FiltriVerticale
              ricerca={filterAction}
              items={itemsFields}
              paramsFilter={paramsFilter}
            />
          </div>
        </section>
      </section>
      <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{mess2}</p>
                </div>
            </Popup>
    </>
  );
};

export default CruscottoAnaliRicetteEvo;
