import { faAngleDown, faAngleUp, faCheck, faClipboardList, faSearch, faTag, faUndo, faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextBox from 'devextreme-react/text-box';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { getCodiceFarmFromId } from "../../funzioni/anagrafica";
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { OggettoInterface } from '../../interfaces/generiche';
import { TabellaCompletaInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { Loading } from '../../widget/Notifications';
import { Selezione, SelezioneMultiplaAny } from '../../widget/Select';
import { TabellaMasterDetailed } from '../../widget/Tabelle';
import { urlAnagrafiche, urlBancaDati, urlConsultazione, urlPrezzoPromozione } from '../costanti';
import { AssegnaDialog } from './Dialog/AssegnaDialog';
import { InviaPrezziDialog } from './Dialog/InviaPrezziDialog';
import { ProdottoDialog } from './Dialog/NuovoProdottoDialog';
import { RifermentoDialog } from './Dialog/RiferimentoDialog';
import './style.css';


const AllineamentoPrez = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const auth = useAuth(urlConsultazione.auth);

    const [openSection, setOpenSection] = useState<boolean>(true)
    const [openSection1, setOpenSection1] = useState(true);

    const [ListGrupMercProd, setListGrupMercProd] = useState([])
    const [ListCategory, setListCategory] = useState([])
    const [prodottoLabel, setProdottoLabel] = useState<string>("");
    const [categoriaLabel, setcategoriaLabel] = useState<string>("");
    const [prodotti, setProdotti] = useState<any[]>([]);
    const [fatture, setFatture] = useState<any[]>([]);
    const [carichi, setCarichi] = useState<any[]>([]);
    const [ditta, setDitta] = useState<any[]>([]);
    const [ultimiCosti, setUltimiCosti] = useState<any[]>([]);
    const [selectedIdRadioGroup, setSelectedIdRadioGroup] = useState<string>("1");
    const [selectedIdRadioGroup2, setSelectedIdRadioGroup2] = useState<string>("2");
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const [rowModificate, setRowModificate] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const [idFarList, setIdFarList] = useState<any[]>([]);
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [colonnePrezzi, setColonnePrezzi] = useState<any[]>([]);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [originalData, setOriginalData] = useState<any[]>([]);
    const [highlightedColumns, setHighlightedColumns] = useState<string[]>([]);
    const [selectedProdotto, setSelectedProdotto] = useState<any>(null);
    const [selectedValues, setSelectedValues] = useState<{ sconto?: number; margine?: number; idFar?: string[] }>({});

    //Rifermento Dialog Pop Up

    const [isDialogImpostazioniOpen, setIsDialogImpostazioniOpen] = useState(false);
    const [isDialogRiferimentoOpen, setIsDialogRiferimentoOpen] = useState(false);
    const handleCloseDialogRiferimento = () => { setIsDialogImpostazioniOpen(false); }

    // Nuovo Prodotto Pop Up
    const [isDialogProdottoOpen, setIsDialogProdottoOpen] = useState(false);
    const handleCloseDialogProdotto = () => { setIsDialogImpostazioniOpen(false); }

    // Assegna Dialog Pop Up
    const [isDialogAssegnaOpen, setIsDialogAssegnaOpen] = useState(false);
    const handleCloseDialogAssegna = () => { setIsDialogImpostazioniOpen(false); }

    // Invia Prezzi Dialog Pop Up
    const [isDialogInviaPrezziOpen, setIsDialogInviaPrezziOpen] = useState(false);
    const handleCloseDialogInviaPrezzi = () => { setIsDialogInviaPrezziOpen(false); }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const checkEmptyResponse = (response: any[]) => {
        if (response.length === 0)
            return notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto risultati." }, "error", 4000);
    }

    const propsTabPrima: TabellaCompletaInterface = {
        id: "elenco_ordine_rda",
        dataSource: (prodotti && prodotti.length > 0) ? prodotti : [],
        selection: "multiple",
        headerFilterVisible: false,
        defaultFilterValue: null,
        autoWidth: true,
        export: {
            enabled: false,
            fileName: "AllineamentoPrez"
        },
    };

    const propsTabSeconda: TabellaCompletaInterface = {
        id: "elenco_ordine_rda2",
        dataSource: (ultimiCosti && ultimiCosti.length > 0) ? ultimiCosti : [],
        selection: "none",
        headerFilterVisible: false,
        defaultFilterValue: null,
        export: {
            enabled: false,
            fileName: "AllineamentoPrez"
        },
    }

    const handleEditorPreparing = (e: any) => {
        if (e.dataField === "mar" && e.parentType === "dataRow") {
            e.editorOptions.value = parseFloat(e.value).toFixed(2); 
        }
    };

    const creaColonnePrezzi = () => {
        const columnsPrezzi: any [] = [
            {
                caption: "Inviato",
                dataField: "s",
                width: 100,
                allowEditing: false,
                cellRender: (cellData: any) => {
                    return cellData.value ? (
                        <span style={{ color: 'blue' }}>&#x2714;</span>
                    ) : (
                        <span style={{ color: 'red' }}>&#x2716;</span>
                    );
                },
            },
            { caption: "Cod.Prodotto", dataField: "c", allowEditing: false },
            { caption: "Descrizione", dataField: "d", allowEditing: false, width: 300 },
            { caption: "Prezzo BD", dataField: "l", allowEditing: false, format: { type: "currency", precision: 2, currency: "EUR" } },
            { caption: "Nuovo prezzo farmacia", dataField: "ppu", format: { type: "currency", precision: 2, currency: "EUR" } },
            { caption: "% Sconto", dataField: "sct", format: { type: "#0.##'%'" } },
            { caption: "Ultimo costo", dataField: "utc", allowEditing: false, format: { type: "currency", precision: 2, currency: "EUR" } },
            {
                caption: "% Margine",
                dataField: "mar",
                format: { type: "#0.##'%'" }, // Display format
                editorOptions: {
                    showClearButton: false
                },
                setCellValue: (newData: any, value: any) => {
                    newData.mar = parseFloat(value.toFixed(2)); // Store the rounded value
                },
            }
        ];

        const aggiuntaColonneFarm = columnsPrezzi;
        const idFarList: any[] = [];

        if (props.datiFarmacie) {
            // Sort pharmacies so that the selected pharmacy appears first
            const sortedFarmacie = [...props.datiFarmacie].sort((a, b) => {
                if (a.ID_FAR === filtri.idFarRiferimento) return -1;
                if (b.ID_FAR === filtri.idFarRiferimento) return 1;
                return 0;
            });

            for (let i = 0; i < sortedFarmacie.length; i++) {
                const idFar = sortedFarmacie[i].ID_FAR;
                let codice = getCodiceFarmFromId(sortedFarmacie, idFar);

                idFarList.push(idFar);

                aggiuntaColonneFarm.push({
                    caption: `${codice}`,
                    dataField: `${idFar}`,
                    allowEditing: false,
                    dataType: "number",
                    alignment: "center",
                    format: { type: "currency", precision: 2, currency: "EUR" },
                });
            }

            setIdFarList(idFarList);
        }

        return aggiuntaColonneFarm;
    };

    const columnsPrezzi = useMemo(() => CreaColonne(creaColonnePrezzi().map(column => {
        return {
            ...column,
            cellRender: (cellData: any) => {
                if (column.dataField === "s") {
                    return cellData.value ? (
                        <span style={{ color: 'blue' }}>&#x2714;</span>
                    ) : (
                        <span style={{ color: 'red' }}>&#x2716;</span>
                    );
                }

                const isHighlighted = highlightedColumns.includes(column.dataField);
                return (
                    <div style={{ border: isHighlighted ? '2px solid #83b883' : 'none', padding: '2px' }}>
                        {cellData.text}
                    </div>
                );
            }
        };
    })), [filtri.idFarRiferimento, props.datiFarmacie, highlightedColumns]);

    const columnsCosti = CreaColonne([
        { caption: "Cod.far.", dataField: "codiceFarmacia", allowEditing: false },
        { caption: "Data DDT", dataField: "dataDDT", dataType: "date", format: { type: "dd/MM/yyyy", }, allowEditing: false },
        { caption: "Num.DDT", dataField: "numeroDDT", allowEditing: false },
        { caption: "Forn", dataField: "ragioneSocialeFornitore", allowEditing: false },
        { caption: "Data car.", dataField: "dataCarico", dataType: "date", format: { type: "dd/MM/yyyy", }, allowEditing: false },
        { caption: "Qta", dataField: "quantita", allowEditing: false },
        { caption: "Qta.omg.", dataField: "quantitaOmaggio", allowEditing: false },
        { caption: "Costo.", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" }, allowEditing: false },
        { caption: "Costo reale", dataField: "costoReale", format: { type: "currency", precision: 2, currency: "EUR" }, allowEditing: false },
        { caption: "Sconto", dataField: "sconto", format: { type: "#0.##'%'", }, allowEditing: false },
        { caption: "Sconto reale", dataField: "scontoReale", format: { type: "#0.##'%'", }, allowEditing: false },
    ])

    const getImpostazioniPrezzoPromozione = () => {
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', filtri?.idFar, urlPrezzoPromozione.getFarmaciaRiferimentoRest).then(result => {
                if (result) {
                    impostaFiltri({ idFarRiferimento: result.response });
                    setIsVisible(false);
                }
            });
        }
    }

    const getSettInven = () => {
        setIsVisible(true);
        if (auth) {
            RestServices().rest("POST", {}, urlBancaDati.getSettore)
                .then((result) => {
                    if (result) {
                        setSettori(result);
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                });
        }
    }

    const getGrupMerc = () => {
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getGrupMercProd)
                .then(result => {
                    if (result) {
                        setListGrupMercProd(result.response)
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const getListCategory = () => {
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListCate)
                .then(result => {
                    if (result) {
                        setListCategory(result.response)
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const resetHighlights = () => {
        setHighlightedColumns([]);
    };

    const getPrezziFarmacia = (soloFattureCarichi: boolean) => {
        setSelectedRowKeys([]);
        resetHighlights();

        let model = {};

        if (!soloFattureCarichi) {
            model = {
                ifr: filtri.idFarRiferimento,
                rf1: selectedIdRadioGroup,
                rf2: selectedIdRadioGroup2,
                deat: filtri.classificazioneATC,
                codi: filtri.codiceDitta,
                cogm: filtri.codiciGruppoMerceologico,
                si: filtri.codiceSettori,
                dec: filtri.prodotto,
                cora: filtri.codiceCategory,
                ica: filtri.codiceCarichi,
                ifa: filtri.codiceFatture,
            };
        } else if (soloFattureCarichi) {
            if (filtri.codiceFatture || filtri.codiceCarichi) {
                model = {
                    ifr: filtri.idFarRiferimento,
                    rf1: selectedIdRadioGroup,
                    rf2: selectedIdRadioGroup2,
                    ifa: filtri.codiceFatture,
                    ica: filtri.codiceCarichi
                };
            }
            else {
                return notify({ position: "center", width: "auto", message: "Devi selezionare almeno una fattura o un carico" }, "error", 4000)
            }
        } else {
            return notify({ position: "center", width: "auto", message: "Errore" }, "error", 4000)
        }

        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', model, urlAnagrafiche.getPrezziFarmacia)
                .then(result => {
                    if (result) {
                        const newData = result.response.map((item: any) => ({ ...item }));
                        setProdotti(newData);
                        setOriginalData(newData);
                        setIsVisible(false);
                        checkEmptyResponse(result.response);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const findFatture = () => {
        let dal = new Date();
        dal.setMonth(dal.getMonth() - 6);

        const dalGiorno = dal.getDate();
        const dalMese = dal.getMonth() + 1;
        const dalAnno = dal.getFullYear();

        const dalFormatted = `${dalGiorno}/${dalMese}/${dalAnno}`;

        let al = new Date();
        const AlGiorno = al.getDate();
        const AlMese = al.getMonth() + 1;
        const AlAnno = al.getFullYear();

        const alFormatted = `${AlGiorno}/${AlMese}/${AlAnno}`;

        let model = {
            'dal': dalFormatted,
            'al': alFormatted,
            'DA_LAVORARE': false,
            'fornitorimerci': true,
        };

        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', model, urlAnagrafiche.find)
                .then(result => {
                    if (result) {
                        setFatture(result.response)
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const getListCarichi = () => {
        let dal = new Date();
        dal.setMonth(dal.getMonth() - 6);
        let al = new Date();

        let model = {
            'dal': dal,
            'al': al,
        };

        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', model, urlConsultazione.getListCarichi)
                .then(result => {
                    if (result) {
                        setCarichi(result.response)
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const findProdottiDitta = (e: string) => {
        let model = {
            'q': e,
        };

        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', model, urlBancaDati.findProdottiDitta)
                .then(result => {
                    if (result) {
                        setDitta(result.response)
                        setIsVisible(false);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    };

    const getListMagazCarichi = (datiRow: any) => {
        if (idFarList && idFarList.length > 0) {
            let model = {
                'codCsf': datiRow.i,
                'flagTrasferito': datiRow.s,
                'idFar': idFarList,
                'top': 10,
            };

            setIsVisible(true);
            if (auth) {
                RestServices().rest('POST', model, urlConsultazione.getListMagazCarichi)
                    .then(result => {
                        if (result.response) {
                            setUltimiCosti(result.response)
                            setIsVisible(false);
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000);
                        }
                    })
            }
        }
        else {
            return notify({ position: "center", width: "auto", message: "Errore durante il recupero del codice Farmacia" }, "error", 4000);
        }
    };

    const formatDate = (e: any) => {
        if (!e) {
            return "Nessuna Data";
        }
        else {
            const [anno, mese, giorno] = e.split('-');
            e = `${giorno}/${mese}/${anno}`;
            return e;
        }
    };

    const controlloInputCerca = (propName: string, value: string) => {
        if (value.trim() !== "") {
            if (propName === "prodotto")
                setProdottoLabel(value);
            else if (propName === "classificazioneATC") {
                setcategoriaLabel(value);
            }

            setFiltri(prevFiltri => ({
                ...prevFiltri,
                [propName]: value.trim()
            }));
        } else {
            setProdottoLabel("");
            setcategoriaLabel("");
            setFiltri(prevFiltri => {
                const { [propName]: _, ...restoFiltri } = prevFiltri;
                return restoFiltri;
            });
        }
    };

    const onSavePrezzo = (dati: any, campoModificato: number) => {
        if (props.datiFarmacie && props.datiFarmacie.length > 0) {
            let nuovoPrezzo = dati.ppu;
            let percentualeSconto = dati.sct;
            let percentualeMargine = dati.mar;
            const prezzoBancaDati = dati.l;
            const ultimoCosto = dati.utc;

            if (nuovoPrezzo === null)
                nuovoPrezzo = 0;

            if (percentualeSconto === null)
                percentualeSconto = 0;

            if (percentualeMargine === null)
                percentualeMargine = 0;

            switch (campoModificato) {
                case 1:
                    percentualeSconto = ((prezzoBancaDati - nuovoPrezzo) / prezzoBancaDati) * 100;
                    if (percentualeSconto < 0)
                        percentualeSconto = 0.00;

                    percentualeMargine = ((nuovoPrezzo - ultimoCosto) / nuovoPrezzo) * 100;
                    if (percentualeMargine < 0)
                        percentualeMargine = 0.00;

                    return {
                        ...dati,
                        ppu: nuovoPrezzo,
                        sct: percentualeSconto,
                        mar: percentualeMargine,
                    };

                case 2:
                    nuovoPrezzo = prezzoBancaDati - ((prezzoBancaDati / 100) * percentualeSconto);
                    percentualeMargine = ((nuovoPrezzo - ultimoCosto) / nuovoPrezzo) * 100;
                    if (percentualeMargine < 0)
                        percentualeMargine = 0.00;

                    return {
                        ...dati,
                        ppu: nuovoPrezzo,
                        sct: percentualeSconto,
                        mar: percentualeMargine,
                    };

                case 3:
                    nuovoPrezzo = ultimoCosto / (1 - (percentualeMargine / 100));
                    percentualeSconto = ((prezzoBancaDati - nuovoPrezzo) / prezzoBancaDati) * 100;
                    if (percentualeSconto < 0)
                        percentualeSconto = 0.00;

                    return {
                        ...dati,
                        ppu: nuovoPrezzo,
                        sct: percentualeSconto,
                        mar: percentualeMargine,
                    };

                default:
                    return notify({ position: "center", width: "auto", message: "Errore" }, "error", 4000);
            }
        }
        return notify({ position: "center", width: "auto", message: "Errore" }, "error", 4000);
    };

    const handleAssegnaClick = (selectedValues: { sconto?: number; margine?: number; idFar?: string[] }, columnsToHighlight: string[]) => {
        setSelectedValues(selectedValues);
        updateDataGrid(selectedValues);
        setTimeout(() => {
            const columnsToHighlight = ['ppu', 'sct', 'mar'];
            setHighlightedColumns(columnsToHighlight);
        })
    };

    const updateDataGrid = (selectedValues: { sconto?: number; margine?: number; idFar?: string[] }) => {
        const { sconto, margine, idFar } = selectedValues;

        if (sconto !== undefined && sconto > 0) {
            const updatedProdotti = prodotti.map((item) => {
                const prezzoBancaDati = item.l;
                const ultimoCosto = item.utc;
                const nuovoPrezzo = prezzoBancaDati - ((prezzoBancaDati / 100) * sconto);
                const margine = ((nuovoPrezzo - ultimoCosto) / nuovoPrezzo) * 100;

                return {
                    ...item,
                    ppu: nuovoPrezzo,
                    sct: sconto,
                    mar: margine < 0 ? 0.00 : margine,
                };
            });

            setProdotti(updatedProdotti);
        } else if (margine !== undefined && margine > 0) {
            const updatedProdotti = prodotti.map((item) => {
                const prezzoBancaDati = item.l;
                const ultimoCosto = item.utc;
                const nuovoPrezzo = ultimoCosto / (1 - (margine / 100));
                const sconto = ((prezzoBancaDati - nuovoPrezzo) / prezzoBancaDati) * 100;

                return {
                    ...item,
                    ppu: nuovoPrezzo,
                    sct: sconto < 0 ? 0.00 : sconto,
                    mar: margine,
                };
            });

            setProdotti(updatedProdotti);
        } else if (idFar && idFar.length > 0) {
            const updatedProdotti = prodotti.map((item) => {
                const prezzoBancaDati = item.l;
                const ultimoCosto = item.utc;
                const nuovoPrezzo = item[idFar[0]];
                const sconto = ((prezzoBancaDati - nuovoPrezzo) / prezzoBancaDati) * 100;
                const margine = ((nuovoPrezzo - ultimoCosto) / nuovoPrezzo) * 100;

                return {
                    ...item,
                    ppu: nuovoPrezzo,
                    sct: sconto < 0 ? 0.00 : sconto,
                    mar: margine < 0 ? 0.00 : margine,
                };
            });

            setProdotti(updatedProdotti);
        }
    };

    const copiaPrezziDaRiferimento = () => {
        if (!filtri.idFarRiferimento) {
            notify({ position: "center", width: "auto", message: "Nessuna farmacia di riferimento selezionata" }, "error", 4000);
            return;
        }
        setHighlightedColumns([]);

        setTimeout(() => {
            const columnsToHighlight = ['ppu', 'sct', 'mar'];
            setHighlightedColumns(columnsToHighlight);

            const updatedProdotti = prodotti.map((item) => {
                const referencePrice = item[filtri.idFarRiferimento];

                if (referencePrice === null || referencePrice === undefined) {
                    return item;
                }

                const prezzoBancaDati = item.l;
                const ultimoCosto = item.utc;

                const percentualeSconto = ((prezzoBancaDati - referencePrice) / prezzoBancaDati) * 100;
                const percentualeMargine = ((referencePrice - ultimoCosto) / referencePrice) * 100;

                return {
                    ...item,
                    ppu: referencePrice,
                    sct: percentualeSconto < 0 ? 0.00 : percentualeSconto,
                    mar: percentualeMargine < 0 ? 0.00 : percentualeMargine,
                };
            });

            setProdotti(updatedProdotti);
            setRowModificate(updatedProdotti);
            setSelectedRowKeys([]);
            notify({ position: "center", width: "auto", message: "Prezzi Copiati Con Successo" }, "success", 4000);
        }, 10); 
    };

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "data" && e.column && e.column.dataField) {
            const referenceColumnDataField = filtri.idFarRiferimento;
            const referenceColumnIndex = colonnePrezzi.findIndex(
                (column) => column.dataField === referenceColumnDataField
            );

            const effectiveReferenceColumnIndex = referenceColumnIndex >= 0 ? referenceColumnIndex : 8;

            if (e.column.index === effectiveReferenceColumnIndex) {
                e.cellElement.style.backgroundColor = "#ffffe6";
                return;
            }

            if (e.column.index > effectiveReferenceColumnIndex) {
                const referenceValue = e.data[colonnePrezzi[effectiveReferenceColumnIndex].dataField];
                const currentValue = e.value;

                if (currentValue === referenceValue) {
                    e.cellElement.style.backgroundColor = "";
                } else if (currentValue < referenceValue) {
                    e.cellElement.style.backgroundColor = "lightgreen";
                } else if (currentValue > referenceValue) {
                    e.cellElement.style.backgroundColor = "red";
                }
            }
        }
    }, [filtri.idFarRiferimento, colonnePrezzi]);

    const apriRiferimento = () => {
        setIsDialogRiferimentoOpen(true)
    }

    const apriAssegna = () => {
        setIsDialogAssegnaOpen(true)
    }

    const apriProdotto = () => {
        setIsDialogProdottoOpen(true)
    }

    const apriInviaPrezzi = () => {
        setIsDialogInviaPrezziOpen(true)
    }

    const reset = () => {
        setProdottoLabel("");
        setcategoriaLabel("");

        let svuotaFiltro: OggettoInterface = {};

        if (filtri.idFarRiferimento)
            svuotaFiltro.idFarRiferimento = filtri.idFarRiferimento;

        if (filtri.codiceFatture)
            svuotaFiltro.codiceFatture = filtri.codiceFatture;

        if (filtri.codiceCarichi)
            svuotaFiltro.codiceCarichi = filtri.codiceCarichi;

        setFiltri(svuotaFiltro);

        getGrupMerc();
        getSettInven();
        getListCategory();
        setSelectedIdRadioGroup("1");
        setSelectedIdRadioGroup2("2");
        setDitta([]);
    }

    const functionNextImpostazioni = () => {
        setIsDialogImpostazioniOpen(false);
        window.location.reload();
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

        getSettInven()
        getGrupMerc()
        getListCategory()
        getImpostazioniPrezzoPromozione()
    }, [auth])

    useEffect(() => {
        const newColumnsPrezzi = creaColonnePrezzi();
        setColonnePrezzi(newColumnsPrezzi);
    }, [filtri.idFarRiferimento, props.datiFarmacie]);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement) {
                activeElement.blur();
            }
            setTimeout(() => {
                const button = document.querySelector('.btn-cerpro') as HTMLButtonElement;
                if (button) {
                    button.click();
                }
            }, 100);
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <>
            <ProdottoDialog
                isDialogImpostazioniProdotto={isDialogProdottoOpen}
                setIsDialogImpostazioniProdotto={setIsDialogProdottoOpen}
                handleCloseDialogProdotto={handleCloseDialogProdotto}
                datiFarmacie={props.datiFarmacie}
                functionNext={functionNextImpostazioni}
            />

            <RifermentoDialog
                isDialogImpostazioniRiferimento={isDialogRiferimentoOpen}
                setIsDialogImpostazioniRiferimento={setIsDialogRiferimentoOpen}
                handleCloseDialogRiferimento={handleCloseDialogRiferimento}
                datiFarmacie={props.datiFarmacie}
                functionNext={functionNextImpostazioni}
                filtri={filtri}
                impostaFiltri={impostaFiltri}
            />

            <AssegnaDialog
                isDialogImpostazioniAssegna={isDialogAssegnaOpen}
                setIsDialogImpostazioniAssegna={setIsDialogAssegnaOpen}
                handleCloseDialogAssegna={handleCloseDialogAssegna}
                datiFarmacie={props.datiFarmacie}
                functionNext={functionNextImpostazioni}
                impostaFiltri={impostaFiltri}
                filtri={filtri}
                onAssegnaClick={handleAssegnaClick}
            />

            <InviaPrezziDialog
                isDialogInviaPrezzi={isDialogInviaPrezziOpen}
                setIsDialogInviaPrezzi={setIsDialogInviaPrezziOpen}
                handleCloseDialogInviaPrezzi={handleCloseDialogInviaPrezzi}
                datiFarmacie={props.datiFarmacie}
                functionNext={functionNextImpostazioni}
                oggetti={rowModificate}
                selectedRowCount={selectedRowKeys.length}
            />

            <div className='title'>
                <h5> <p> Prezzi <span className="sub-topic"> Prezzi dei prodotti comparati</span></p></h5>
            </div>

            {loadPanel}

            <div style={{ background: 'white', padding: 10, borderTop: '1px solid #ccc', borderRadius: '5px' }}>
                <h6>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className="main-topic"> Filtro <span className="sub-topic"> Criteri di ricerca </span></p>
                        <button className="chevron-up" onClick={() => setOpenSection(!openSection)}> {openSection ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />} </button>
                    </div>
                </h6>

                <hr style={{ borderTop: "1px solid lightgrey", width: "auto", marginTop: -15 }} />

                <div style={{ transition: 'all 0.3s', opacity: openSection ? 1 : 0, height: openSection ? 'auto' : 0, overflow: 'hidden' }}>
                    <div style={{ display: 'flex', marginBottom: '1%', marginTop: '1%' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <label style={{ marginRight: '20px' }}>MAGAZZINO:</label>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ marginRight: '25px' }}>
                                    <input style={{ transform: 'scale(1.5)', cursor: 'pointer' }} type="radio" value={"1"} checked={selectedIdRadioGroup === "1"} onClick={() => setSelectedIdRadioGroup("1")} />
                                    <label style={{ marginLeft: '10px' }}>Trattati</label>
                                </div>
                                <div>
                                    <input style={{ transform: 'scale(1.5)', cursor: 'pointer' }} type="radio" value={"2"} checked={selectedIdRadioGroup === "2"} onClick={() => setSelectedIdRadioGroup("2")} />
                                    <label style={{ marginLeft: '10px' }}>In giacenza</label>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '2.6%' }}>
                            <label style={{ marginRight: '10px' }}>PREZZI:</label>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ marginRight: '30px' }}>
                                    <input style={{ transform: 'scale(1.5)', cursor: 'pointer' }} type="radio" value={"1"} checked={selectedIdRadioGroup2 === "1"} onClick={() => setSelectedIdRadioGroup2("1")} />
                                    <label style={{ marginLeft: '10px' }}>Tutti</label>
                                </div>
                                <div>
                                    <input style={{ transform: 'scale(1.5)', cursor: 'pointer' }} type="radio" value={"2"} checked={selectedIdRadioGroup2 === "2"} onClick={() => setSelectedIdRadioGroup2("2")} />
                                    <label style={{ marginLeft: '10px' }}>Solo differenze</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{ marginBottom: '5px' }}>
                        <Row className="d-flex align-items-center">
                            <Col sm="3" className="column-filters">
                                <label>Prodotto (descrizione, codice AIC o EAN)</label>
                                <TextBox placeholder="Inserisci un prodotto" value={prodottoLabel} onValueChanged={(e) => controlloInputCerca("prodotto", e.value)} />
                            </Col>

                            <Col sm="3" className="column-filters">
                                <label>Ditta</label>
                                <Selezione id="codiceDitta" impostaFiltri={impostaFiltri} placeholder="Cerca Ditta..." dataSource={ditta} displayExpr="text" valueExpr="id" isService={true} onChange={(valoreDigitato) => { if (valoreDigitato.length >= 2) { findProdottiDitta(valoreDigitato); } }} />
                            </Col>

                            <Col sm="3" className="column-filters">
                                <label>Classificazione ATC</label>
                                <TextBox placeholder="Inserisci classificazione ATC" value={categoriaLabel} onValueChanged={(e) => controlloInputCerca("classificazioneATC", e.value)} />
                            </Col>

                            <Col className="button-wrapper">
                                <Button type="button" variant="success" className="btn-cerpro" onClick={() => { getPrezziFarmacia(false); }}>
                                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '3px' }} /> <span className="btn-text"> Cerca </span>
                                </Button>
                            </Col>

                            <Col className="button-wrapper">
                                <Button type="button" variant="primary" className="btn-cerpro" onClick={apriProdotto}>
                                    <FontAwesomeIcon icon={faClipboardList} style={{ marginRight: '3px' }} /> <span className="btn-text"> Nuovo Prodotto </span>
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <Row>
                        <Col sm="3" className="column-filters">
                            <label>Settore Inventario</label>
                            <Selezione id="codiceSettori" impostaFiltri={impostaFiltri} placeholder="Seleziona Settore Inventario" dataSource={settori} displayExpr="descrizione" valueExpr="id" searchEnabled={true} clearButton={true} />
                        </Col>

                        <Col sm="3" className="column-filters">
                            <label>Gruppo Merceologico</label>
                            <Selezione id="codiciGruppoMerceologico" impostaFiltri={impostaFiltri} placeholder="Seleziona Gruppo Merceologico" dataSource={ListGrupMercProd} displayExpr="descGruppoMerceologico" valueExpr="codGruppoMerceologico" searchEnabled={true} clearButton={true} />
                        </Col>

                        <Col sm="3" className="column-filters">
                            <label>Category</label>
                            <Selezione id="codiceCategory" impostaFiltri={impostaFiltri} placeholder="Seleziona categoria" dataSource={ListCategory} displayExpr="descrizioneCategory" valueExpr="codiceCategory" searchEnabled={true} clearButton={true} />
                        </Col>

                        <Col className="button-wrapper">
                            <Button type="button" variant="info" className="btn-anurifer" onClick={() => { reset(); }}>
                                <FontAwesomeIcon icon={faUndo} style={{ marginRight: '3px' }} /> <span className="btn-text"> Annulla </span>
                            </Button>
                        </Col>

                        <Col className="button-wrapper">
                            <Button type="button" variant="primary" className="btn-anurifer" onClick={apriRiferimento}>
                                <FontAwesomeIcon icon={faTag} style={{ marginRight: '3px' }} /> <span className="btn-text"> Farmacia Riferimento </span>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div style={{ background: 'white', padding: 10, marginTop: 10, borderTop: '1px solid #ccc', borderRadius: '5px' }}>
                <h6>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p className="main-topic"> Filtro <span className="sub-topic"> Imposta il prezzo partendo dai prodotti presenti da una fattura o da una bolla </span></p>
                        <button className="chevron-up" onClick={() => setOpenSection1(!openSection1)}> {openSection1 ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />} </button>
                    </div>
                </h6>

                <hr style={{ borderTop: "1px solid lightgrey", width: "auto", marginTop: -15, marginBottom: 10 }} />

                <div style={{ transition: 'all 0.3s', opacity: openSection1 ? 1 : 0, height: openSection1 ? 'auto' : 0, overflow: 'hidden' }}>
                    <Row className="d-flex align-items-center">
                        <Col sm="3">
                            <div style={{ display: 'flex' }}>
                                <label><b>Fatture</b></label>

                                {/*{(fatture && fatture.length > 0) && (*/}
                                {/*    <>*/}

                                {/*        <Button onClick={() => { findFatture() }} type="button" variant="secondary" style={{ width: '40%', height: '100%', marginLeft: '2%', }}>*/}
                                {/*            <FontAwesomeIcon icon={faUndo} /> Ricarica Fatture*/}
                                {/*        </Button>*/}

                                {/*    </>*/}
                                {/*)}*/}

                            </div>
                            <SelezioneMultiplaAny
                                id="codiceFatture"
                                impostaFiltri={impostaFiltri}
                                dataSource={fatture}
                                displayExpr="nod"
                                valueExpr="idr"
                                selection="single"
                                columns={[
                                    { caption: "Ragione Sociale Fornitore", dataField: "csd", with: '100%' },
                                    { caption: "Numero Fattura", dataField: "nod", with: 'auto' },
                                    {
                                        caption: "Data Fattura", dataField: "dti", with: 'auto', format: { type: "date" },
                                        render: (e: any) => {

                                            return formatDate(e.value);
                                        }
                                    },
                                ]}
                                onOpen={() => { if (!fatture || fatture.length === 0) { findFatture() }; }}
                            />
                        </Col>

                        <Col sm="3">
                            <div style={{ display: 'flex' }}>
                                <label><b>Bolla di trasporto</b></label>
                                {/*{(carichi && carichi.length > 0) && (*/}
                                {/*    <>*/}

                                {/*        <Button onClick={() => { getListCarichi() }} type="button" variant="secondary" style={{ width: '40%', height: '100%', marginLeft: '2%', }}>*/}
                                {/*            <FontAwesomeIcon icon={faUndo} /> Ricarica Carichi*/}
                                {/*        </Button>*/}

                                {/*    </>*/}
                                {/*)}*/}
                            </div>
                            <SelezioneMultiplaAny
                                id="codiceCarichi"
                                impostaFiltri={impostaFiltri}
                                dataSource={carichi}
                                primaSelezionata={false}
                                displayExpr="ragioneSocialeFornitore"
                                valueExpr="idCarico"
                                selection="single"
                                columns={[
                                    { caption: "Ragione Sociale Fornitore", dataField: "ragioneSocialeFornitore", with: 'auto' },
                                    { caption: "Numero Bolla", dataField: "numeroBolla", with: 'auto' },
                                    {
                                        caption: "Data Bolla", dataField: "dataBolla", with: 'auto', format: { type: "date" },
                                        render: (e: any) => {

                                            return formatDate(e.value);
                                        }
                                    },
                                ]}
                                onOpen={() => { if (!carichi || carichi.length === 0) { getListCarichi() }; }}
                            />
                        </Col>

                        <Col>
                            <Button type="button" variant="success" id="btnCerca" style={{ width: 145, marginTop: 25 }} onClick={() => { getPrezziFarmacia(true); }}>
                                <FontAwesomeIcon icon={faSearch} /> Cerca
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>

            <div style={{ background: 'white', padding: 5, borderTop: '1px solid #ccc', marginTop: 10, borderRadius: '5px' }}>
                <h6 style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ marginTop: 20 }}> Prezzi <span className="sub-topic"> Comparazione </span> </p>
                        <Button type="button" variant="primary" id="btnCerca" style={{ width: 140, marginLeft: 15 }} onClick={apriAssegna}>
                            <FontAwesomeIcon type="button" icon={faTag} /> Assegna
                        </Button>
                        <Button type="button" variant="primary" id="btnCerca" style={{ width: 280, marginLeft: 15 }} onClick={copiaPrezziDaRiferimento}>
                            <FontAwesomeIcon type="button" icon={faCopy} /> Copia Prezzi da Farm. Riferimento
                        </Button>
                    </div>
                </h6>

                <Button type="button" variant="success" className="tabella-btn" onClick={() => { apriInviaPrezzi(); }}>
                    <FontAwesomeIcon icon={faCheck} style={{ marginRight: '3px' }} /> <span> Invia Prezzi </span>
                </Button>

                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaMasterDetailed
                            propsTabella={propsTabPrima}
                            columns={columnsPrezzi}
                            setTabellaInstance={setTabellaInstance}
                            onRowUpdating={(dati: any) => {
                                let campoModificato = 0;
                                if ("ppu" in dati.newData)
                                    campoModificato = 1;

                                if ("sct" in dati.newData)
                                    campoModificato = 2;

                                if ("mar" in dati.newData)
                                    campoModificato = 3;

                                const recordAggiornato = { ...dati.oldData, ...dati.newData };
                                const updatedRecord = onSavePrezzo(recordAggiornato, campoModificato);

                                setRowModificate((prevRowModificate) => [...prevRowModificate, updatedRecord]);
                                setProdotti((prevProdotti) =>
                                    prevProdotti.map((item) =>
                                        item.i === updatedRecord.i ? { ...updatedRecord } : { ...item }
                                    )
                                );
                            }}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            selectedRowKeys={selectedRowKeys}
                            onToolbarPreparing={false}
                            searchPanel={false}
                            onEditorPreparing={handleEditorPreparing}
                            enableColumnChooser={false}
                            onCellPrepared={onCellPrepared}
                            onSelectionChanged={(datiRows: any) => setSelectedRowKeys(datiRows.selectedRowKeys)}
                            onRowClick={(datiRow: any) => {
                                getListMagazCarichi(datiRow.data);
                                setSelectedProdotto(datiRow.data);
                            }}
                        />
                    </Col>
                </Row>
            </div>

            <div style={{ background: 'white', padding: 5, borderTop: '1px solid #ccc', marginTop: 10, borderRadius: '5px' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className="main-topic"> Costi <span className="sub-topic"> Ultimi </span> </p>
                </div>

                {selectedProdotto && (
                    <div style={{ background: '#f0f0f0', borderRadius: '5px', fontSize: '1rem', fontWeight: 'bold', color: '#333', textTransform: 'uppercase', marginBottom: 10 }}>
                        <p> {selectedProdotto.c} - {selectedProdotto.d} </p>
                    </div>
                )}

                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaMasterDetailed
                            propsTabella={propsTabSeconda}
                            columns={columnsCosti}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                            selectedRowKeys={selectedRowKeys}
                            enableColumnChooser={false}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default AllineamentoPrez;