import notify from "devextreme/ui/notify";
import React, { useEffect } from "react";
import { useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import {  ConfigurazioneDatiAltreFarmacieInterface, ConfigurazioneDatiAltreFarmacieParInterface, ConfigurazioneTipoOrdinamentoDatiAltreFarmacieInterface } from "../../interfaces/datiAltreFarmacie";
import { ListaFarmacieInterface } from "../../interfaces/farmacie"
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlConfigurazioni } from "../costanti";
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Check } from "../../widget/Editor";
import { Selezione } from "../../widget/Select";
import { OggettoInterface } from "../../interfaces/generiche";
import Button from 'react-bootstrap/esm/Button';
import { Table } from "react-bootstrap";

const ConfigurazioneDatiAltreFarmacie =
    (
        props:
            {
                statoMenu: boolean,
                toggleMenu: (stato: boolean) => void,
            }
    ) => {

        useEffect(() => {
            if (props.statoMenu) {
                props.toggleMenu(!props.statoMenu);
            }
        }, [])

        const newImpostazioni = {
            flagVisualizzaFarmacia: undefined,
            ordinamentoFarmacia: undefined,
            flagVisualizzaGiacenza: undefined,
            ordinamentoGiacenza: undefined,
            flagVisualizzaGiacenzaMagazzino: undefined,
            ordinamentoGiacenzaMagazzino: undefined,
            flagVisualizzaGiacenzaEsposizione: undefined,
            ordinamentoGiacenzaEsposizione: undefined,
            flagVisualizzaGiacenzaAutomatico: undefined,
            ordinamentoGiacenzaAutomatico: undefined,
            flagVisualizzaVendutoAnno: undefined,
            ordinamentoVendutoAnno: undefined,
            flagVisualizzaVendutoMese: undefined,
            ordinamentoVendutoMese: undefined,
            flagVisualizzaVendutoUltimiTreMesi: undefined,
            ordinamentoVendutoUltimiTreMesi: undefined
        }

        const auth = useAuth(urlConfigurazioni.auth);
        const titolo = TitoloContenuto("Configurazione dati altre farmacie");
        const [isVisible, setIsVisible] = useState(false);
        const loadPanel = <Loading isVisible={isVisible} />
        const [configFarmacia, setConfigFarmacia] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configGiacenza, setConfigGiacenza] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configGiacenzaMagazzino, setConfigGiacenzaMagazzino] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configGiacenzaEsposizione, setConfigGiacenzaEsposizione] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configGiacenzaAutomatico, setConfigGiacenzaAutomatico] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configVendutoAnno, setConfigVendutoAnno] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configVendutoMese, setConfigVendutoMese] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configVendutoUltimiTreMesi, setConfigVendutoUltimiTreMesi] = useState<ConfigurazioneDatiAltreFarmacieParInterface>();
        const [configTipoOrdinamentoDatiAltreFarmacie, setConfigTipoOrdinmentoDatiAltreFarmacie] = useState<ConfigurazioneTipoOrdinamentoDatiAltreFarmacieInterface[]>();
        const [filtri, setFiltri] = useState<ConfigurazioneDatiAltreFarmacieInterface>(newImpostazioni);
        const impostaFiltri = (newFiltro: OggettoInterface) => {
            let newF = { ...filtri, ...newFiltro }
            setFiltri(newF);
        }
        

        useEffect(() => {
            if (auth) {
                startCercaConfigurazioni();
                startCercaTipoOrdinamentoDatiAltreFarmacie();
            }
        }, [auth]);

        //useEffect(() => {
        //    console.log("1");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaFarmacia: configFarmacia?.visualizza, ordinamentoFarmacia: configFarmacia?.ordinamento });
        //}, [configFarmacia])

        //useEffect(() => {
        //    console.log("2");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaGiacenza: configGiacenza?.visualizza, ordinamentoGiacenza: configGiacenza?.ordinamento });
        //}, [configGiacenza])

        //useEffect(() => {
        //    console.log("3");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaGiacenzaMagazzino: configGiacenzaMagazzino?.visualizza, ordinamentoGiacenzaMagazzino: configGiacenzaMagazzino?.ordinamento });
        //}, [configGiacenzaMagazzino])

        //useEffect(() => {
        //    console.log("4");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaGiacenzaEsposizione: configGiacenzaEsposizione?.visualizza, ordinamentoGiacenzaEsposizione: configGiacenzaEsposizione?.ordinamento });
        //}, [configGiacenzaEsposizione])

        //useEffect(() => {
        //    console.log("5");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaGiacenzaAutomatico: configGiacenzaAutomatico?.visualizza, ordinamentoGiacenzaAutomatico: configGiacenzaAutomatico?.ordinamento });
        //}, [configGiacenzaAutomatico])

        //useEffect(() => {
        //    console.log("6");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaVendutoAnno: configVendutoAnno?.visualizza, ordinamentoVendutoAnno: configVendutoAnno?.ordinamento });
        //}, [configVendutoAnno])

        //useEffect(() => {
        //    console.log("7");
        //    console.log(filtri);
        //    setFiltri({ flagVisualizzaVendutoMese: configVendutoMese?.visualizza, ordinamentoVendutoMese: configVendutoMese?.ordinamento });
        //}, [configVendutoMese])

        const startCercaConfigurazioni = () => {

            //setConfigFarmacia(undefined);
            //setConfigGiacenza(undefined);
            //setConfigGiacenzaMagazzino(undefined);
            //setConfigGiacenzaEsposizione(undefined);
            //setConfigGiacenzaAutomatico(undefined);
            //setConfigVendutoAnno(undefined);
            //setConfigVendutoMese(undefined);

            setIsVisible(true);
            const param = {};
            RestServices().rest('POST', param, urlConfigurazioni.getConfigurazioneDatiAltreFarmacie)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result.esito === true) {
                            if (result.response) {
                                

                                if (result.response.farmacia) {
                                    setConfigFarmacia(result.response.farmacia)
                                }
                                if (result.response.giacenza) {
                                    setConfigGiacenza(result.response.giacenza)
                                }
                                if (result.response.giacenzaMagazzino) {
                                    setConfigGiacenzaMagazzino(result.response.giacenzaMagazzino)
                                }
                                if (result.response.giacenzaEsposizione) {
                                    setConfigGiacenzaEsposizione(result.response.giacenzaEsposizione)
                                }
                                if (result.response.giacenzaAutomatico) {
                                    setConfigGiacenzaAutomatico(result.response.giacenzaAutomatico)
                                }
                                if (result.response.vendutoAnnoInCorso) {
                                    setConfigVendutoAnno(result.response.vendutoAnnoInCorso)
                                }
                                if (result.response.vendutoMeseInCorso) {
                                    setConfigVendutoMese(result.response.vendutoMeseInCorso)
                                }
                                if (result.response.vendutoMeseInCorso) {
                                    setConfigVendutoUltimiTreMesi(result.response.vendutoUltimiTreMesi)
                                }

                                setFiltri({
                                    flagVisualizzaFarmacia: result.response.farmacia?.visualizza, ordinamentoFarmacia: result.response.farmacia?.ordinamento,
                                    flagVisualizzaGiacenza: result.response.giacenza?.visualizza, ordinamentoGiacenza: result.response.giacenza?.ordinamento,
                                    flagVisualizzaGiacenzaMagazzino: result.response.giacenzaMagazzino?.visualizza, ordinamentoGiacenzaMagazzino: result.response.giacenzaMagazzino?.ordinamento,
                                    flagVisualizzaGiacenzaEsposizione: result.response.giacenzaEsposizione?.visualizza, ordinamentoGiacenzaEsposizione: result.response.giacenzaEsposizione?.ordinamento,
                                    flagVisualizzaGiacenzaAutomatico: result.response.giacenzaAutomatico?.visualizza, ordinamentoGiacenzaAutomatico: result.response.giacenzaAutomatico?.ordinamento,
                                    flagVisualizzaVendutoAnno: result.response.vendutoAnnoInCorso?.visualizza, ordinamentoVendutoAnno: result.response.vendutoAnnoInCorso?.ordinamento,
                                    flagVisualizzaVendutoMese: result.response.vendutoMeseInCorso?.visualizza, ordinamentoVendutoMese: result.response.vendutoMeseInCorso?.ordinamento,
                                    flagVisualizzaVendutoUltimiTreMesi: result.response.vendutoUltimiTreMesi?.visualizza, ordinamentoVendutoUltimiTreMesi: result.response.vendutoUltimiTreMesi?.ordinamento
                                });
                            }
                        }
                        else if (result && result.message && result.esito == false) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                        }
                    }
                     else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                })
        }

        const startCercaTipoOrdinamentoDatiAltreFarmacie = () => {

            setIsVisible(true);
            const param = {};
            RestServices().rest('POST', param, urlConfigurazioni.getTipoOrdinamentoDatiAltreFarmacie)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result.esito === true) {
                            if (result.response) {
                                setConfigTipoOrdinmentoDatiAltreFarmacie(result.response);
                            }
                        }
                        else if (result && result.message && result.esito == false) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                        }
                    }
                    else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                })
        }

        const salvaParametri = () => {
            setIsVisible(true);
            const param = {
                farmacia: {
                    visualizza: filtri?.flagVisualizzaFarmacia,
                    ordinamento: filtri?.ordinamentoFarmacia
                },
                giacenza: {
                    visualizza: filtri?.flagVisualizzaGiacenza,
                    ordinamento: filtri?.ordinamentoGiacenza
                },
                giacenzaMagazzino: {
                    visualizza: filtri?.flagVisualizzaGiacenzaMagazzino,
                    ordinamento: filtri?.ordinamentoGiacenzaMagazzino
                },
                giacenzaEsposizione: {
                    visualizza: filtri?.flagVisualizzaGiacenzaEsposizione,
                    ordinamento: filtri?.ordinamentoGiacenzaEsposizione
                },
                giacenzaAutomatico: {
                    visualizza: filtri?.flagVisualizzaGiacenzaAutomatico,
                    ordinamento: filtri?.ordinamentoGiacenzaAutomatico
                },
                vendutoAnnoInCorso: {
                    visualizza: filtri?.flagVisualizzaVendutoAnno,
                    ordinamento: filtri?.ordinamentoVendutoAnno
                },
                vendutoMeseInCorso: {
                    visualizza: filtri?.flagVisualizzaVendutoMese,
                    ordinamento: filtri?.ordinamentoVendutoMese
                },
                vendutoUltimiTreMesi: {
                    visualizza: filtri?.flagVisualizzaVendutoUltimiTreMesi,
                    ordinamento: filtri?.ordinamentoVendutoUltimiTreMesi
                }
            };
            RestServices().rest('POST', param, urlConfigurazioni.setConfigurazioneDatiAltreFarmacie)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result.esito === true) {
                            notify({ position: "center", width: "auto", message: "Salvataggio avvenuto con successo" }, 'success', 4000);
                            startCercaConfigurazioni();
                        }
                        else if (result && result.message && result.esito == false) {
                            notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                        }
                    }
                    else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                })



        }

        return (
            <>

                {titolo}
                {loadPanel}
                <div className="contenutoPagina">
                    <table className="tableDatiAltreFarmacie">
                        <thead>
                            <tr >
                                <th className="tableDatiAltreFarmacieVisualizza">
                                    Visualizza
                                </th>
                                <th>
                                    Campo
                                </th>
                                <th>
                                    Ordinamento
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td className="tableDatiAltreFarmacieVisualizza">
                                    <Check disabled={true} id="flagVisualizzaFarmacia" impostaFiltri={impostaFiltri} checkValue={configFarmacia?.visualizza} />
                                </td>
                                <td>
                                    Farmacia
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoFarmacia"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configFarmacia?.ordinamento}
                                    />
                                </td>
                                {/*<Row className="rigaCreaNuovoOrdLib">*/}
                                {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                                {/*        <span>Visualizza farmacia: </span>*/}
                                {/*    </Col>*/}
                                {/*    <Col sm="1">*/}
                                {/*        <Check id="flagVisualizzaFarmacia" impostaFiltri={impostaFiltri} checkValue={configFarmacia?.visualizza} />*/}
                                {/*    </Col>*/}
                                {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                                {/*        <span>Ordinamento: </span>*/}
                                {/*    </Col>*/}
                                {/*    <Col sm="2">*/}
                                {/*        <Selezione*/}
                                {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                                {/*            id="ordinamentoFarmacia"*/}
                                {/*            impostaFiltri={impostaFiltri}*/}
                                {/*            displayExpr="descrizione"*/}
                                {/*            valueExpr="id"*/}
                                {/*            searchEnabled={true}*/}
                                {/*            value={configFarmacia?.ordinamento}*/}
                                {/*        />*/}
                                {/*    </Col>*/}
                                {/*    <Col sm="5">*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaGiacenza" impostaFiltri={impostaFiltri} checkValue={configGiacenza?.visualizza} />
                                </td>
                                <td>
                                    Giacenza
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoGiacenza"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configGiacenza?.ordinamento}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaGiacenzaMagazzino" impostaFiltri={impostaFiltri} checkValue={configGiacenzaMagazzino?.visualizza} />
                                </td>
                                <td>
                                    Giacenza magazzino
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoGiacenzaMagazzino"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configGiacenzaMagazzino?.ordinamento}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaGiacenzaEsposizione" impostaFiltri={impostaFiltri} checkValue={configGiacenzaEsposizione?.visualizza} />
                                </td>
                                <td>
                                    Giacenza esposizione
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoGiacenzaEsposizione"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configGiacenzaEsposizione?.ordinamento}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaGiacenzaAutomatico" impostaFiltri={impostaFiltri} checkValue={configGiacenzaAutomatico?.visualizza} />
                                </td>
                                <td>
                                    Giacenza automatico
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoGiacenzaAutomatico"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configGiacenzaAutomatico?.ordinamento}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaVendutoAnno" impostaFiltri={impostaFiltri} checkValue={configVendutoAnno?.visualizza} />
                                </td>
                                <td>
                                    Venduto anno in corso
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoVendutoAnno"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configVendutoAnno?.ordinamento}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaVendutoMese" impostaFiltri={impostaFiltri} checkValue={configVendutoMese?.visualizza} />
                                </td>
                                <td>
                                    Venduto mese in corso
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoVendutoMese"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configVendutoMese?.ordinamento}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Check id="flagVisualizzaVendutoUltimiTreMesi" impostaFiltri={impostaFiltri} checkValue={configVendutoUltimiTreMesi?.visualizza} />
                                </td>
                                <td>
                                    Venduto ultimi tre mesi
                                </td>
                                <td>
                                    <Selezione
                                        dataSource={configTipoOrdinamentoDatiAltreFarmacie}
                                        id="ordinamentoVendutoUltimiTreMesi"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="descrizione"
                                        valueExpr="id"
                                        searchEnabled={true}
                                        value={configVendutoUltimiTreMesi?.ordinamento}
                                    />
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    {/*<Row>&nbsp;</Row>*/}
                    
                    {/*<Row className="rigaCreaNuovoOrdLib">*/}
                    {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Visualizza giacenza: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1">*/}
                    {/*        <Check id="flagVisualizzaGiacenza" impostaFiltri={impostaFiltri} checkValue={configGiacenza?.visualizza} />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Ordinamento: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="2">*/}
                    {/*        <Selezione*/}
                    {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                    {/*            id="ordinamentoGiacenza"*/}
                    {/*            impostaFiltri={impostaFiltri}*/}
                    {/*            displayExpr="descrizione"*/}
                    {/*            valueExpr="id"*/}
                    {/*            searchEnabled={true}*/}
                    {/*            value={configGiacenza?.ordinamento}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="5">*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row className="rigaCreaNuovoOrdLib">*/}
                    {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Visualizza giacenza magazzino: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1">*/}
                    {/*        <Check id="flagVisualizzaGiacenzaMagazzino" impostaFiltri={impostaFiltri} checkValue={configGiacenzaMagazzino?.visualizza} />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Ordinamento: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="2">*/}
                    {/*        <Selezione*/}
                    {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                    {/*            id="ordinamentoGiacenzaMagazzino"*/}
                    {/*            impostaFiltri={impostaFiltri}*/}
                    {/*            displayExpr="descrizione"*/}
                    {/*            valueExpr="id"*/}
                    {/*            searchEnabled={true}*/}
                    {/*            value={configGiacenzaMagazzino?.ordinamento}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="5">*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row className="rigaCreaNuovoOrdLib">*/}
                    {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Visualizza giacenza esposizione: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1">*/}
                    {/*        <Check id="flagVisualizzaGiacenzaEsposizione" impostaFiltri={impostaFiltri} checkValue={configGiacenzaEsposizione?.visualizza} />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Ordinamento: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="2">*/}
                    {/*        <Selezione*/}
                    {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                    {/*            id="ordinamentoGiacenzaEsposizione"*/}
                    {/*            impostaFiltri={impostaFiltri}*/}
                    {/*            displayExpr="descrizione"*/}
                    {/*            valueExpr="id"*/}
                    {/*            searchEnabled={true}*/}
                    {/*            value={configGiacenzaEsposizione?.ordinamento}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="5">*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    {/*<Row className="rigaCreaNuovoOrdLib">*/}
                    {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Visualizza giacenza automatico: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1">*/}
                    {/*        <Check id="flagVisualizzaGiacenzaAutomatico" impostaFiltri={impostaFiltri} checkValue={configGiacenzaAutomatico?.visualizza} />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Ordinamento: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="2">*/}
                    {/*        <Selezione*/}
                    {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                    {/*            id="ordinamentoGiacenzaAutomatico"*/}
                    {/*            impostaFiltri={impostaFiltri}*/}
                    {/*            displayExpr="descrizione"*/}
                    {/*            valueExpr="id"*/}
                    {/*            searchEnabled={true}*/}
                    {/*            value={configGiacenzaAutomatico?.ordinamento}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="5">*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    {/*<Row className="rigaCreaNuovoOrdLib">*/}
                    {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Visualizza venduto anno in corso: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1">*/}
                    {/*        <Check id="flagVisualizzaVendutoAnno" impostaFiltri={impostaFiltri} checkValue={configVendutoAnno?.visualizza} />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Ordinamento: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="2">*/}
                    {/*        <Selezione*/}
                    {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                    {/*            id="ordinamentoVendutoAnno"*/}
                    {/*            impostaFiltri={impostaFiltri}*/}
                    {/*            displayExpr="descrizione"*/}
                    {/*            valueExpr="id"*/}
                    {/*            searchEnabled={true}*/}
                    {/*            value={configVendutoAnno?.ordinamento}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="5">*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                    {/*<Row className="rigaCreaNuovoOrdLib">*/}
                    {/*    <Col sm="3" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Visualizza venduto mese in corso: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1">*/}
                    {/*        <Check id="flagVisualizzaVendutoMese" impostaFiltri={impostaFiltri} checkValue={configVendutoMese?.visualizza} />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="1" className="labelCreaNuovoOrdLib">*/}
                    {/*        <span>Ordinamento: </span>*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="2">*/}
                    {/*        <Selezione*/}
                    {/*            dataSource={configTipoOrdinamentoDatiAltreFarmacie}*/}
                    {/*            id="ordinamentoVendutoMese"*/}
                    {/*            impostaFiltri={impostaFiltri}*/}
                    {/*            displayExpr="descrizione"*/}
                    {/*            valueExpr="id"*/}
                    {/*            searchEnabled={true}*/}
                    {/*            value={configVendutoMese?.ordinamento}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col sm="5">*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm="1">
                            <Button type="button" variant="info" id="btnSalvaDatiAltreFarmacie" onClick={salvaParametri}>Salva   </Button>
                        </Col>

                        
                        
                    </Row>
                </div>

            </>

            )
    }

export default ConfigurazioneDatiAltreFarmacie