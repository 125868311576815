import React, { useEffect, useRef, useState } from "react";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { SettoriInterface } from "../../interfaces/generiche";
import Modal from 'react-bootstrap/esm/Modal';
import DataGrid, { Selection, FilterRow, Column, Scrolling } from 'devextreme-react/data-grid'
import { DropDownBox } from "devextreme-react";


export const DialogTest = (props: {
    isDialogFiltraProdottiOpen: boolean,
    handleCloseDialogFiltraProdotti: () => void,
    
}) => {

    
    const [settori, setSettori] = useState<SettoriInterface[]>();

    useEffect(() => {

        if (props.isDialogFiltraProdottiOpen) {
            setSettori([{
                "id": "D",
                "codice": "D",
                "descrizione": "Cosmesi e Profumeria"
            }, {
                "id": "C",
                "codice": "C",
                "descrizione": "Dietetici ed Acque Minerali"
            }, {
                "id": "H",
                "codice": "H",
                "descrizione": "Erboristeria"
            }, {
                "id": "E",
                "codice": "E",
                "descrizione": "Galenici e Sostanze Medicinali"
            }, {
                "id": "B",
                "codice": "B",
                "descrizione": "Medicazione e Sanitari"
            }, {
                "id": "G",
                "codice": "G",
                "descrizione": "Omeopatici"
            }, {
                "id": "A",
                "codice": "A",
                "descrizione": "Specialita'"
            }, {
                "id": "F",
                "codice": "F",
                "descrizione": "Varie"
            }
            ]);
        }

    }, [props.isDialogFiltraProdottiOpen])


    const gridColumns = ['id', 'descrizione', 'codice'];

    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={settori}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                ref={dropGrid}
                keyExpr="id"
                height="100%"
            >
                <Selection mode={"multiple"} />
                <FilterRow visible={true} />
                <Scrolling mode="infinite" />
                {gridColumns}
            </DataGrid>
        )
    }

    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value) {
            dropGrid.current.instance.deselectAll();
        } else {
            dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    return (
        <>
            <Modal
                show={props.isDialogFiltraProdottiOpen}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Filtri prodotti nuovo Ordine Libero</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>

                        <Col sm="6">
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Settore di inventario: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <DropDownBox
                                        ref={dropDown}
                                        value={valueGrid}
                                        valueExpr="id"
                                        displayExpr="descrizione"
                                        showClearButton={true}
                                        contentRender={dataGridRender}
                                        onValueChanged={syncDataGridSelection}
                                        deferRendering={false}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    
                </Modal.Footer>
            </Modal>
        </>
    )
}