import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie"
import React, { useEffect, useState } from 'react'
import { urlCommon, urlConsultazione, urlGestioneUdi } from "../costanti";
import { RestServices } from "../../services/restServices";
import { Filtri } from "../consultazione/interface";
import notify from "devextreme/ui/notify";
import { Loading } from "../../widget/Notifications";
import Ricerca from "../../widget/nuova_ricerca"
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import { confirm } from 'devextreme/ui/dialog';
import { trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";


const ElencoRegistrazioniUdi = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlGestioneUdi.auth);
    const auth2 = useAuth(urlCommon.auth);

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState<any>([])
    const [paramsFilter, setParamsFilter] = useState<any>()

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    const getDefaultDal = () => {


        //ritorna la dta iniziale del trimetre corrente
        var month = new Date().getMonth() + 1;

        if (month < 4)
            return new Date().getFullYear() + '-' + (1) + '-01'

        else if (month < 7)
            return new Date().getFullYear() + '-' + (4) + '-01'
        else if (month < 10)
            return new Date().getFullYear() + '-' + (7) + '-01'
        else if (month < 13)
            return new Date().getFullYear() + '-' + (10) + '-01'

    }

    const getDefaultAl = () => {

        //ritorna la dta finale del giorno corrente

        return new Date();

        ////ritorna la dta finale del trimetre corrente
        //var month = new Date().getMonth() + 1;

        //if (month < 4)
        //    return new Date().getFullYear() + '-' + (3) + '-31'

        //else if (month < 7)
        //    return new Date().getFullYear() + '-' + (6) + '-30'
        //else if (month < 10)
        //    return new Date().getFullYear() + '-' + (9) + '-30'
        //else if (month < 13)
        //    return new Date().getFullYear() + '-' + (12) + '-31'
    }


    useEffect(() => {
        if (auth && auth2)
            farmaList()

        filterAction({
            dal: getDefaultDal(),
            al: getDefaultAl(),
            idFar: newFarmaList.length == 1 ? newFarmaList[0].idFarmacia : undefined
        })

        //setTabellaPrincipale(dataSourceTest)

    }, [auth, auth2]);

    const columns = [
    
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Id Farmacia", dataField: "idFarmacia", visible: false },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "date", width: 90, sortOrder: "desc" },
        //{ caption: "Farmacia", dataField: "farmacia" },
        { caption: "Cod.Csf", dataField: "codCsf", visible: false },
        { caption: "Cod.Min.", dataField: "codProd" },
        { caption: "Cod.Ean", dataField: "codEan" },
        { caption: "Prodotto", dataField: "prodotto" },
        { caption: "Ditta titolare", dataField: "ditta" },
        { caption: "Cod.Udi", dataField: "codiceUdi" },
        { caption: "Tipo Movimento", dataField: "tipoMovimento" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {

                        let rc = confirm("<i>Eliminare il movimento per il prodotto " + e.row.data.prodotto + "  ?</i > ", "Attenzione");
                        rc.then((dialogResult) => {
                            if (dialogResult) {
                                deleteMovimento(e.row.data.id)
                            }
                        })
                    },
                }
            ]
        }
        
        
    ]

    const farmaList = () => {
        if (auth2) {
            RestServices().rest('POST', {}, urlConsultazione.getListFarSystem)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {

                    }
                })
        }
    }

    const filterAction = (objFiltro: Filtri) => {
        
        let params = { ...objFiltro }

        //console.log(params)

        if (params.idFar) {

            params.idFar = params.idFar.idFar
        }

        params.dal = trasformaDateTimeInLocale(params.dal, true);
        params.al = trasformaDateTimeInLocale(params.al, true);

        setParamsFilter(params)
        if (params.dal == undefined || params.al == undefined) {
            notify({ position: "center", width: "auto", message: 'Le date sono obbligatorie' }, "error", 4000)
            return;
        }
        getList(params)

    }

    const getList = (par: any) => {

        setLoading(true)
        if (auth) {
            RestServices().rest('POST', par, urlGestioneUdi.getList)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setTabellaPrincipale(result.response)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const deleteMovimento = (id: number) => {

        setLoading(true)
        if (auth) {
            let param = { id: id }
            RestServices().rest('POST', param, urlGestioneUdi.delete)
                .then(result => {
                    if (result?.esito) {
                        if (result.response.esito) {
                            setLoading(false)
                            getList(paramsFilter)
                        }
                        else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
            //console.log(element);
            newArr.push({ value: element.idFar, label: element.descrizione })

        });
        return newArr
    }

    const itemsFields = [
        { type: 'select', idValue: 'idFar', label: 'Farmacia ', displayExpr: 'descrizione', dataSource: newFarmaList, width: 350, },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, defValue: getDefaultDal(), width: 250, clear: false },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null, defValue: getDefaultAl(), width: 250, clear:false },

    ]

    

    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> ELENCO REGISTRAZIONI UDI </h3>
            </div>
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>
            <NewTabella
                idTabella='elencoUdi'
                sync
                colonne={columns}
                dataSource={tabellaPrincipale}
                searchPanel={true}
                onclickRow={() => null}
                exportT={{
                    enabled: true,
                    fileName: "elencoUdi"
                }}
                activeSaver={true}
                columnC={true}
                rowAlternation={true}
            />
        </>
    )

}

export default ElencoRegistrazioniUdi