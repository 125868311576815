import React, { useEffect, useState } from 'react'
import Select from 'react-select'

//import './style.css';


const StatBusinessLabReport = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    

    let options:any = [
         {label:'Vendite effettuate', value:'5d66309f5b7b8b0fe83ddf77'},
         {label:'Analisi Trasferimenti', value:'605b05c8b29ac414646030e6'}
        
    ]

    const [selection,setSelection] = useState<string>('5d66309f5b7b8b0fe83ddf77')

    useEffect(() => {
       
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    

    return (
        <>   
            <div >
            <div className='title'>
                <h4> BusinessLAB <span className='subt' > Statistiche Operative</span>  </h4>
             </div>

                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={options[0]}
                    isClearable={false}
                    isSearchable={true}
                    onChange={(s)=>{
                       // console.log(s.value);
                        setSelection(s.value)
                        
                    }}
                    name="color"
                    options={options}
                />   
            </div>  

            <div style={{ height: '80vh', marginTop: 10 }}>
                <iframe
                    id="ifm"
                    width="100%"
                    height="100%"
                    src={`https://sss.cloud-farma.it/app/main#/dashboards/${selection}?embed=true`}
                ></iframe>
            </div>
           
        </>
    )
}

export default StatBusinessLabReport