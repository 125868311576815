import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"

//import "./style.css";

interface DialogProps {
  open: boolean,
  edit: any,
  typeEdit: boolean,
  ricarica: () => void,
  close: ()=>void,
}


export const DialogFarmRif: React.FC<DialogProps> = ({ open, close,edit,ricarica,typeEdit}) => {

  const [openD, setOpenD] = useState <boolean>(false)
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})
  const [farmGruppo,setFarmGruppo] = useState([])


// const UpdateListfarm = () =>{
//    if (auth) {
//       //console.log(objctTOsave);
//       RestServices().rest('POST', {
//        idFar:objctTOsave.idFar ,
//        idFattura:objctTOsave.id ,
//        Piva:objctTOsave.Piva ,
//        } , urlConsultazione.updateFarmRif)
//           .then(result => {
//               if (result?.esito) { 
//                ricarica()  
                                       
//               }else{                              
//                   //console.log(result);
//                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
//               }
//           })     
//   }
//}

    const UpdateListfarm = (idFarm: number, piva:string) => {
        if (auth) {
            //console.log(objctTOsave);
            RestServices().rest('POST', {
                idFar: idFarm,
                idFattura: objctTOsave.id,
                pIVA: piva,
            }, urlConsultazione.updateFarmRif)
                .then(result => {
                    if (result?.esito) {
                        ricarica()

                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

const ListDati = () =>{
    if (auth) {
      //console.log(e.data)
       RestServices().rest('POST', { idFar:edit?.idAnagrafica, partitaIVA:edit?.idFiscaleIVA } , urlConsultazione.getFarmGruppo)
           .then(result => {
               if (result?.esito) {                     
                  // console.log(result.response);
                   setFarmGruppo(result.response)                    
               }else{                               
                  // console.log(result);                    
               }
           })     
   }
}



 const cambiaValore = (tipo:string,value:string) =>{
          let objTo = {...objctTOsave}
          objTo[tipo] = value
          setObjctTOsave(objTo)
    }


  useEffect(() => {
    if (edit) {
      //console.log(edit);   
      let objTo = { ...objctTOsave };
      objTo.id = edit.id
      objTo.partitaIva = edit.partitaIVA
      objTo.IdFar = edit.idFar
      setObjctTOsave(objTo);
    }
  }, [edit, openD]);

  useEffect(() => { 
          setOpenD(open);
        if(open && auth){
            ListDati()
        }
    
  }, [open,auth]);



  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'60%'}
      height={'70%'}
      showTitle={true}
      title=""
    >
     <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Annulla',
                onClick: ()=>close(),
              }}       
          />
           {/* <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Salva',
                elementAttr:{
                  class:'btncreatecluster',
                },
                onClick: ()=>UpdateNote(),
              }
            }
            /> */}

      <ScrollView height="100%"  width="100%">
        <div style={{ width: '100%'}}>
        <div  style={{textAlign:'center'}}>
                <h3>Selezionare una Farmacia di Riferimento  </h3>
                <span className='subt' >Seleziona la farmacia di riferimento per la fattura</span>
             </div>
              
             <div style={{marginTop:'8px'}}>
             {farmGruppo.map((item:any,i)=>{
                 return (
                     <div style={{ display: 'flex', gap: 20, justifyContent: 'space-between', paddingBottom: 20 }} key={'fama_' + i}>
                        <button style={{ fontWeight: 'bold', fontSize: 16 }} onClick={() => { UpdateListfarm(item.idFar, item.piva) }}>P.iva {item.piva} - {item.descrizione} </button>                 
                    </div>
                    
                )
            })}

                          
                </div>  
                  
        </div>
        
            
      </ScrollView>
    </Popup>
  );
};