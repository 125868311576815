import notify from "devextreme/ui/notify";
import React, { useEffect, useMemo, useState } from "react";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OggettoInterface } from "../../interfaces/generiche";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlPrezzoPromozione } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { TabellaCompleta } from "../../widget/Tabelle";
import { TendinaFarm } from "../../widget/Select";
import Button from 'react-bootstrap/esm/Button';
import { BoxDataOra } from "../../widget/Editor";
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";


const LogPrezziPromozione = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
	
    const titolo = TitoloContenuto("Prezzi Promozione");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [elencoLogPrezziPromozione, setElencoLogPrezziPromozione] = useState<1>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState(true);
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (isLoaded) {
            setIsPageLoaded(true);
            cerca();
        }
    }, [isLoaded]);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

        const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        setDal(dataInizio.toString());
        //setAl((new Date()).toString());
        if (props.datiFarmacie && props.datiFarmacie.length > 0) {
            setFiltri({
                dal: dataInizio.toString(),
                //al: (new Date()).toString()
            })
        }
        setFiltri({
            lstFar: props.datiFarmacie.map(function (object) { return object.ID_FAR }),
            dal: dataInizio.toString()
        })

        

    }, [props.datiFarmacie]);

    

    const propsTabLogPrezziPromozione: TabellaCompletaInterface = useMemo(() => ({
        id: "elenco_log_prezzi_promozione",
        dataSource: elencoLogPrezziPromozione,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        pagination: 20,
        rowAlternationEnabled: false,
        export: {
            enabled: true,
            fileName: "Log_Prezzi_Promozione"
        }
    }), [elencoLogPrezziPromozione])

    const columnsLogPrezziPromozione = CreaColonne([
        { caption: "Id", dataField: "id", visible: false, allowEditing: false },
        { caption: "IdPreLis", dataField: "idPreLis", visible: false, allowEditing: false },
        { caption: "Prodotto", dataField: "prodotto", visible: true, allowEditing: false },
        { caption: "CodCsf", dataField: "codCsf", visible: false, allowEditing: false },
        { caption: "Cod.Prod.", dataField: "codProd", visible: true, allowEditing: false },
        { caption: "Cod.Ean.", dataField: "codEan", visible: true, allowEditing: false },
        { caption: "Farmacia", dataField: "farmacia", width: 750, allowEditing: false },
        { caption: "Prezzo", dataField: "prezzo", format: { currency: "EUR", precision: 2, type: "fixedPoint", allowEditing: false } },
        { caption: "Qta.", dataField: "quantita", allowEditing: false },
        { caption: "Data inizio", dataField: "dataInizio", format: "dd/MM/yyyy", dataType: "date", allowEditing: false },
        { caption: "Data fine", dataField: "dataFine", format: "dd/MM/yyyy", dataType: "date", allowEditing: false },
        { caption: "Data invio", dataField: "dataInvio", format: "dd/MM/yyyy HH:mm:ss", sortOrder: "desc", dataType: "date", allowEditing: false },
        { caption: "Data ricezione", dataField: "dataRicezione", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", allowEditing: false },
        { caption: "Utente agg.", dataField: "utenteAggiornamento", visible: true, allowEditing: false },
        { caption: "Data agg.", dataField: "dataAggiornamento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", allowEditing: false },
    ])

    const cerca = () => {

        

        if (filtri.lstFar == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia" }, "error", 4000)
            return;
        }

        if (filtri.dal == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare la data inizio" }, "error", 4000)
            return;
        }

        const param = {
            lstFar: filtri.lstFar,
            dataInizio: trasformaDateTimeInLocale(filtri.dal),
            dataFine: trasformaDateTimeInLocale(filtri.al),
            
            
        }

        setIsVisible(true);
        RestServices().rest('POST', param, urlPrezzoPromozione.getListLogPrezzoPromozione).then(result => {
            setIsVisible(false);
            if (result) {
                setElencoLogPrezziPromozione(result.result);
            } else if (result !== true) {
                notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
            }
        })

	}

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row>
                    <Col sm="6">
                        <label>Farmacia</label>
                        <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} valueGrid={filtri.lstFar} />
                    </Col>
                    <Col sm="2">
                        <label>Data inizio: </label>
                        <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                    </Col>
                    <Col sm="2">
                        <label>Data fine: </label>
                        <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={al} />
                    </Col>
                    <Col sm="1">
                    </Col>
                    <Col sm="1">
                        <Button type="button" variant="success" id="btnCerca" onClick={cerca}>Cerca</Button>
                    </Col>
                </Row>
                <Row className="tabellaFornitori">
                    <Col sm='12' >
                        <TabellaCompleta
                            propsTabella={propsTabLogPrezziPromozione}
                            //onRowUpdating={onRowUpdating}
                            setTabellaInstance={setTabellaInstance}
                            columns={columnsLogPrezziPromozione}
                            enterKeyDirection="row"
                            rowAlternationEnabled={false}
                            //onCellPrepared={onCellPrepared}
                            //onContentReady={onContentReady}
                            //onCellClick={onCellClick}
                            //onFocusedCellChanged={onFocusedCellChanged}
                        //onKeyDown={onKeyDown}
                        //selectedRowKeys={selectedRowKeys}
                        //onEditingStart={onEditingStart}
                        //onRowPrepared={onRowPrepared}
                        />

                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LogPrezziPromozione;