import { cardC, typeShow, cardV , cardG } from "../../components/card_chart";


export const boxCardSinte: cardC[] = [
  {
    testo: "Fatturato ivato farmacia",
    subTesto: "Delta anno precedente",
    valore: "VALORE_VENDUTO",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    subValore: "VALORE_VENDUTO_MEDIA_OPERATORE",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine % farmacia",
    subTesto: "Media per operatore",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE",
    subValore: "MARGINE_MEDIA_OPERATORE",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Pezzi venduti farmacia",
    subTesto: "Delta anno precedente",
    typeShowSubValue: typeShow.percento,
    valore: "ORE_LAVORATE",
    subValore: "ORE_LAVORATE_MEDIA_OPERATORE",
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },

];

export const boxSecondSinte: cardC[] = [
  {
    testo: "Scontrino medio Ivato farmacia",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "VALORE_VENDUTO_MEDIA_ORARIA",
    subValore: "ORE_LAVORATE_MEDIA_OPERATORE",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Scontrino medio Ivato mercato",
    valore: "MARGINE_MEDIA_ORARIA",
    typeShowValue: typeShow.euro,

    testoPop: `Margine: Rappresenta il valore dei prodotti venduti al `,
  },

];

export const boxCard: cardC[] = [
  {
    testo: "Numero giorni di lavoro",
    subTesto: "Numero giorni lavorati anno prec.",
    second2:"Periodo Selezionato",
    valore: "GIORNI_LAVORATISL",
    subValore:"GIORNI_LAVORATIPR",  
    testoPop:
      "Il numero di giorni lavorati viene calcolato basandosi sulle vendite effettuate dalla farmacia. Se la farmacia in una data ha chiuso almeno una vendita, e questa vendita ha importo superiore a 50 €, allora la data è conteggiata come lavorativa.",
  },
  {
    testo: "Valore del venduto Ivato",
    subTesto: "Anno precedente",
    second2:"Periodo Selezionato",
    valore: "VALORE_VENDUTO_NETTO_IVASL",
    subValore:"VALORE_VENDUTO_NETTO_IVAPR",
    typeShowValue: typeShow.percento,
    
    testoPop:
      "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine",
    subTesto: "Anno precedente",
    second2:"Periodo Selezionato",
    valore: "MARGINESL",
    subValore:"MARGINEPR",
    typeShowValue: typeShow.euro,
    testoPop:
      "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine Percentuale",
    subTesto: "Anno precedente",
    second2:"Periodo Selezionato",
    valore: "MARGINE_PERCENTUALESL",
    subValore:"MARGINE_PERCENTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Volume vendita",
    subTesto: "Anno precedente",
    second2:"Periodo Selezionato",
    valore: "VOLUME_VENDITASL",
    subValore:"VOLUME_VENDITAPR",
    testoPop:
      "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato. Volume di vendita anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Numero vendite",
    subTesto: "Anno precedente",
    second2:"Periodo Selezionato",
    valore: "NUMERO_VENDITESL",
    subValore:"NUMERO_VENDITEPR",
    testoPop:
      "Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero di vendite anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
];

export const boxCardAllY: cardC[] = [
  {
    testo: "Numero giorni di lavoro",
    subTesto: "Anno precedente",
    second2:"Progressivo anno corrente",
    valore: "GIORNI_LAVORATISL",
    subValore:"GIORNI_LAVORATIPR",
    testoPop:
      "Il numero di giorni lavorati viene calcolato basandosi sulle vendite effettuate dalla farmacia. Se la farmacia in una data ha chiuso almeno una vendita, e questa vendita ha importo superiore a 50 €, allora la data è conteggiata come lavorativa.",
  },
  {
    testo: "Valore del venduto Ivato",
    subTesto: "Anno precedente",
    second2:"Progressivo anno corrente",
    valore: "VALORE_VENDUTO_NETTO_IVASL",
    subValore:"VALORE_VENDUTO_NETTO_IVAPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    testoPop:
      "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine",
    subTesto: "Anno precedente",
    second2:"Progressivo anno corrente",
    valore: "MARGINESL",
    subValore:"MARGINEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    testoPop:
      "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine Percentuale",
    subTesto: "Anno precedente",
    second2:"Progressivo anno corrente",
    valore: "MARGINE_PERCENTUALESL",
    subValore:"MARGINE_PERCENTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Volume vendita",
    subTesto: "Anno precedente",
    second2:"Progressivo anno corrente",
    valore: "VOLUME_VENDITASL",
    subValore:"VOLUME_VENDITAPR",
    testoPop:
      "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato. Volume di vendita anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Numero vendite",
    subTesto: "Anno precedente",
    second2:"Progressivo anno corrente",
    valore: "NUMERO_VENDITESL",
    subValore:"NUMERO_VENDITEPR",
    testoPop:
      "Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero di vendite anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
];

export const gaugeAllY: cardG[] = [
  {
    testo: "Venduto Ivato",
    keyValue: "VALORE_VENDUTO_EQUIPARATO",
    puntale: "VALORE_VENDUTO_PUNTUALE",
    testoPop:
      "Delta valore del venduto: Rappresenta il delta percentuale tra il valore del venduto anno corrente e anno precedente nel periodo selezionato.Il delta viene calcolato considerando entrambi i valori al lordo della DCR in quanto il valore netto è disponibile solo dall'installazione della revisione 10. Delta valore del venduto equiparato: Rappresenta il delta percentuale tra valore del venduto anno corrente ed il valore del venduto equiparato nel periodo selezionato. Il valore del venduto equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un valore del venduto di 1000 €, contro i 950 € dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
  },
  {
    testo: "Margine",
    keyValue: "MARGINE_EQUIPARATO",
    puntale: "MARGINE_PUNTUALE",
    testoPop:
      "Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato. Il delta viene calcolato considerando entrambi i valori al lordo della DCR in quanto il valore netto è disponibile solo dall'installazione della revisione 10.Delta margine equiparato: Rappresenta il delta percentuale tra margine anno corrente e margine equiparato nel periodo selezionato. Il margine equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un margine di 1000 €, contro i 950 € dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
  },
  {
    testo: "Volume di vendita",
    keyValue: "VOLUME_VENDITA_EQUIPARATO",
    puntale: "VOLUME_VENDITA_PUNTUALE",
    testoPop:
      "Delta volume di vendita: Rappresenta il delta percentuale tra il volume di vendita anno corrente e anno precedente nel periodo selezionato. Delta volume di vendita equiparato: Rappresenta il delta percentuale tra volume di vendita anno corrente e volume di vendita equiparato nel periodo selezionato. Il volume di vendita equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un venduto 1000 pezzi, contro i 950 dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
  },
  {
    testo: "Numero di vendite",
    keyValue: "NUMERO_VENDITE_EQUIPARATO",
    puntale: "NUMERO_VENDITE_PUNTUALE",
    testoPop:
      "Delta numero vendite: Rappresenta il delta percentuale tra il numero di vendite anno corrente e anno precedente nel periodo selezionato. Delta numero vendite equiparato: Rappresenta il delta percentuale tra il numero di vendite anno corrente e numero di vendite equiparato nel periodo selezionato. Il numero di vendite equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho effettuato 1000 vendite, contro le 950 dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
  },
];

// box card anal dett vendite

export const boxCardAnaDett: cardC[] = [
  {
    testo: "Valore del venduto ivato",
    subTesto: "Delta anno precedente",
    valore: "VALORE_VENDUTOSL",
    subValore: "VALORE_VENDUTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    typeShowValue:typeShow.euro,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine",
    subTesto: "Delta precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINESL",
    subValore: "MARGINE_PUNTUALEPR",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Margine percentuale",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE_PERCENTUALESL",
    subValore: "MARGINE_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Numero vendite",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_VENDITESL",
    subValore: "NUMERO_VENDITE_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Volume di vendita",
    subTesto: "Delta anno precedente",
    valore: "VOLUME_VENDITASL",
    subValore: "VOLUME_VENDITA_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
];

export const boxsecond: cardC[] = [
  {
    testo: "Delta equiparato",
    valore: "VALORE_VENDUTO_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Delta equiparato",
    valore: "MARGINE_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Delta equiparato vendite",
    valore: "NUMERO_VENDITE_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
            
            Delta margine:
            Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Delta equiparato volume di vendita",
    valore: "VOLUME_VENDITA_EQUIPARATOSL",
    typeShowValue: typeShow.percento,
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

export const box3: cardC[] = [
  {
    testo: "Valore medio per vendita",
    subTesto: "Delta anno precedente",
    valore: "VALORE_MEDIO_VENDITESL",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    subValore: "VALORE_MEDIO_VENDITE_PUNTUALEPR",
    testoPop:
      "Valore medio per vendita: Rappresenta il rapporto tra valore del venduto e numero di vendite nel periodo selezionato. Valore medio per vendita: Rappresenta il rapporto tra valore del venduto e numero di vendite nel periodo selezionato. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta valore medio per vendita: Rappresenta il delta percentuale tra il valore medio per vendita anno corrente e anno precedente nel periodo selezionato.",
  },
  {
    testo: "Valore medio pezzo ivato",
    subTesto: "Delta precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "VALORE_MEDIO_PEZZISL",
    subValore: "VALORE_MEDIO_PEZZI_PUNTUALEPR",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
        Delta margine:
        Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Margine medio per vendita",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE_MEDIO_VENDITASL",
    subValore: "MARGINE_MEDIO_VENDITA_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Margine medio per pezzo",
    subTesto: "Delta anno precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE_MEDIO_PEZZISL",
    subValore: "MARGINE_MEDIO_PEZZI_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

export const boxCassa: cardC[] = [
  {
    testo: "SCONTO CASSA",
    valore: "SCONTO_CASSA",
    typeShowValue: typeShow.euro,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
]

export const boxGlavorati: cardC[] = [
  {
    testo: "Numero giorni lavorati",
    valore: "GIORNI_LAVORATISL",
    subTesto: "Numero giorni lavorati anno precedente",
    subValore: "GIORNI_LAVORATIPR",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
]

export const boxCardAnaVendOper: cardC[] = [
  {
    testo: "Valore del venduto",
    subTesto: "Media per operatore",
    valore: "VALORE_VENDUTO",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    subValore: "VALORE_VENDUTO_MEDIA_OPERATORE",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine",
    subTesto: "Media per operatore",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    valore: "MARGINE",
    subValore: "MARGINE_MEDIA_OPERATORE",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Ore lavorate",
    subTesto: "Media per operatore",
    valore: "ORE_LAVORATE",
    subValore: "ORE_LAVORATE_MEDIA_OPERATORE",
    testoPop: "Numero ore lavorate:  Rappresenta la somma delle ore lavorate dagli operatori nel periodo selezionato.  Un operatore ha lavorato in una fascia oraria se in quell'intervallo ha chiuso una vendita. Nel caso in cui avessimo 2 operatori in farmacia ed entrambi avessero effettuato una vendita in una fascia oraria, il numero di ore lavorate sarebbe pari a 2. Esempio: un operatore ha un turno dalle 08:00 alle 13:00, in questo intervallo vende dalle 08:00 alle 12:00 e poi si dedica all'inventario: numero ore lavorate = 4.  Numero ore lavorate medio per operatore: Rapporto tra il numero totale di ore lavorate ed il numero degli operatori."
  },
  {
    testo: "Numero righe vendita",
    subTesto: "Media per operatore",
    valore: "NUMERO_RIGHE",
    subValore: "NUMERO_RIGHE_MEDIA_OPERATORE",
    testoPop: "Righe di vendita: Rappresenta il numero di prodotti (non univoci) venduti nel periodo selezionato. Esempio: Consideriamo una vendita composta da 2 aspirine (stesso prodotto), 2 creme solari (stesso prodotto) e 1 pacco di cerotti: numero pezzi = 5  numero righe di vendita = 3  Numero righe medio per operatore:  Valore calcolato come media ponderata in base al numero di ore lavorate da ciascun operatore. Ciò significa che se avessimo un numero di righe pari a 100 e 3 operatori, il numero di righe medio per operatore non sarà 100 / 3 = 33,33 ; ma sarà calcolato ponderando le ore lavorate. L'obiettivo è evitare che operatori che hanno lavorato solo poche ore vadano ad impattare fortemente il numero medio."
  },
];


export const boxsecondAnaVendOper: cardC[] = [
  {
    testo: "Valore del venduto medio orario",
    valore: "VALORE_VENDUTO_MEDIA_ORARIA",
    typeShowValue: typeShow.euro,
    testoPop:"Valore venduto medio orario: Rapporto tra il valore del venduto ed il numero di ore lavorate nel periodo selezionato. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui considerato è il prezzo di listino. Numero ore lavorate:  Rappresenta la somma delle ore lavorate dagli operatori. Un operatore ha lavorato in una fascia oraria se in quell'intervallo ha chiuso una vendita. Nel caso in cui avessimo 2 operatori in farmacia ed entrambi avessero effettuato una vendita in una fascia oraria, il numero di ore lavorate sarebbe pari a 2. Esempio: un operatore ha un turno dalle 08:00 alle 13:00, in questo intervallo vende dalle 08:00 alle 12:00 e poi si dedica all'inventario: numero ore lavorate = 4.",
  },
  {
    testo: "Margine medio orario",
    valore: "MARGINE_MEDIA_ORARIA",
    typeShowValue: typeShow.euro,
    testoPop: "Margine medio orario: Rapporto tra il margine ed il numero di ore lavorate nel periodo selezionato.  Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Nel caso di prodotti erogati dietro ricetta, il valore lordo considerato è il prezzo di listino. Numero ore lavorate: Rappresenta la somma delle ore lavorate dagli operatori.  Un operatore ha lavorato in una fascia oraria se in quell'intervallo ha chiuso una vendita. Nel caso in cui avessimo 2 operatori in farmacia ed entrambi avessero effettuato una vendita in una fascia oraria, il numero di ore lavorate sarebbe pari a 2. Esempio: un operatore ha un turno dalle 08:00 alle 13:00, in questo intervallo vende dalle 08:00 alle 12:00 e poi si dedica all'inventario: numero ore lavorate = 4."
  },
  {
    testo: "Numero vendite medio orario",
    valore: "NUMERO_VENDITE_MEDIA_ORARIA",
    testoPop: "Numero di vendite medio orario: Rapporto tra il numero di vendite ed il numero di ore lavorate nel periodo selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero ore lavorate:  Rappresenta la somma delle ore lavorate dagli operatori.  Un operatore ha lavorato in una fascia oraria se in quell'intervallo ha chiuso una vendita. Nel caso in cui avessimo 2 operatori in farmacia ed entrambi avessero effettuato una vendita in una fascia oraria, il numero di ore lavorate sarebbe pari a 2. Esempio: un operatore ha un turno dalle 08:00 alle 13:00, in questo intervallo vende dalle 08:00 alle 12:00 e poi si dedica all'inventario: numero ore lavorate = 4."
  },
  {
    testo: "Numero righe di vendita media orario",
    valore: "NUMERO_RIGHE_MEDIA_ORARIA",
    testoPop: "Numero righe di vendita medio orario: Rapporto tra il numero di righe di vendita ed il numero di ore lavorate nel periodo selezionato.  Righe di vendita:  Rappresenta il numero di prodotti (non univoci) venduti.  Esempio: Consideriamo una vendita composta da 2 aspirine (stesso prodotto), 2 creme solari (stesso prodotto) e 1 pacco di cerotti: numero pezzi = 5  numero righe di vendita = 3  Numero ore lavorate:  Rappresenta la somma delle ore lavorate dagli operatori.  Un operatore ha lavorato in una fascia oraria se in quell'intervallo ha chiuso una vendita. Nel caso in cui avessimo 2 operatori in farmacia ed entrambi avessero effettuato una vendita in una fascia oraria, il numero di ore lavorate sarebbe pari a 2.  Esempio: un operatore ha un turno dalle 08:00 alle 13:00, in questo intervallo vende dalle 08:00 alle 12:00 e poi si dedica all'inventario: numero ore lavorate = 4."
  }
];


export const boxCardVendFascOra: cardC[] = [
  {
    testo: "Valore venduto ivato",  
    valore: "VALORE_VENDUTO",
    typeShowValue: typeShow.euro,
    testoPop: "Valore del venduto:Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Valore venduto medio per operatore",
    valore: "VALORE_VENDUTO_MEDIO_OPERATORE_IVATO",
    typeShowValue: typeShow.euro,
    testoPop: "Valore del venduto per operatore: Valore calcolato come media ponderata in base al numero di ore lavorate da ciascun operatore. Ciò significa che se avessimo un valore del venduto pari a 100 € e 3 operatori, il valore del venduto medio per operatore non sarà 100 / 3 = 33,33 € ; ma sarà calcolato ponderando le ore lavorate . L'obiettivo è evitare che operatori che hanno lavorato solo poche ore vadano ad impattare fortemente il valore medio. Valore del venduto:  Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. ",
  },
  {
    testo: "Numero di vendite",
    valore: "NUMERO_VENDITE",
    testoPop:
      "Numero di vendite:Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate.",
  },
  {
    testo: "Numero vendite medio per operatore",
    valore: "NUMERO_VENDITE_MEDIO_OPERATORE",
    testoPop:
      "Numero vendite per operatore:  Numero calcolato come media ponderata in base al numero di ore lavorate da ciascun operatore. Ciò significa che se avessimo 100 vendite e 3 operatori, il numero vendite medio per operatore non sarà 100 / 3 = 33,33 ; ma sarà calcolato ponderando le ore lavorate. L'obiettivo è evitare che operatori che hanno lavorato solo poche ore vadano ad impattare fortemente il numero medio. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate.",
  },
  {
    testo: "Numero ore lavorate",
    valore: "ORE_LAVORATE",
    testoPop:
      "Numero ore lavorate: Rappresenta la somma delle ore lavorate dagli operatori nel periodo selezionato. Un operatore ha lavorato in una fascia oraria se in quell'intervallo ha chiuso una vendita. Nel caso in cui avessimo 2 operatori in farmacia ed entrambi avessero effettuato una vendita in una fascia oraria, il numero di ore lavorate sarebbe pari a 2. Esempio: un operatore ha un turno dalle 08:00 alle 13:00, in questo intervallo vende dalle 08:00 alle 12:00 e poi si dedica all'inventario: numero ore lavorate = 4.",
  },
  {
    testo: "Righe di vendita lavorate per ora",
    valore: "NUMERO_RIGHE",
    testoPop:
      "Righe di vendita lavorate per ora: Rapporto tra il numero di righe di vendita ed il numero di ore lavorate dagli operatori. Righe di vendita: Rappresenta il numero di prodotti (non univoci) venduti nel periodo selezionato. Esempio: Consideriamo una vendita composta da 2 aspirine (stesso prodotto), 2 creme solari (stesso prodotto) e 1 pacco di cerotti: numero pezzi = 5 numero righe di vendita = 3",
  },
];

export const boxCardAnalScontiVend: cardC[] = [
  {
    testo: "Valore sconto ",
    subTesto: "Delta anno precedente",
    valore: "VALORE_SCONTOSL",
    subValore: "VALORE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
  },
  {
    testo: "Sconto percentuale (su ivato)",
    subTesto: "Delta anno precedente",
    valore: "PERC_SCONTOSL",
    subValore: "PERC_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Numero vendite con sconto",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_VENDITE_SCONTOSL",
    subValore: "NUMERO_VENDITE_SCONTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Numero vendite con sconto: Rappresenta il numero di vendite con sconto nel periodo selezionato. Delta numero vendite con sconto: Rappresenta il delta percentuale tra il numero di vendite con sconto anno corrente e anno precedente, nel periodo selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },
  {
    testo: "% di vendite con sconto ",
    subTesto: "Delta anno precedente",
    valore: "PERC_VENDITE_SCONTOSL",
    subValore: "PERC_VENDITE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "% di vendite con sconto: Rappresenta il rapporto tra il numero di vendite con sconto ed il numero di vendite totale. Il periodo considerato è quello selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },

];

// ana sconti vendite ricette 


export const boxCardAnaVendRicette: cardC[] = [
  {
    testo: "Valore Ticket + Quota ricetta ",
    valore: "TICKET_QUOTASL",  
    typeShowValue: typeShow.euro,
    testoPop: "Valore decurtato SSN per tipologia di vendita: Rappresenta la distribuzione del valore del venduto in SSN, nel periodo selezionato, in funzione della tipologia di vendita. Le due tipologie di vendita SSN considerate sono: Vendite SSN, in cui sono venduti solo prodotti dietro ricetta (SSN); Vendite miste, in cui sono presenti sia prodotti erogati dietro ricetta, sia prodotti di vendita libera. Il costrutto alla base della torta è l'aggregato vendita, non il singolo prodotto. "
  },
];

export const boxsecondAnaVendRicette: cardC[] = [
  {
    testo: "Numero ricette lavorate ",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_RICETTESL",
    subValore: "NUMERO_RICETTE_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: "Numero ricette lavorate: Rappresenta il numero di ricette gestite nel periodo selezionato. Delta numero ricette lavorate: Rappresenta il delta percentuale tra il numero di ricette lavorate anno corrente e anno precedente, nel periodo selezionato."
  },
  {
    testo: "Valore medio ivato per ricetta",
    subTesto: "Delta anno precedente",
    valore: "VALORE_IVATO_MEDIO_RICETTASL",
    subValore: "VALORE_IVATO_MEDIO_RICETTA_PUNTUALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:" Valore medio per ricetta: Rappresenta il rapporto tra il valore venduto decurtato SSN ed il numero di ricette lavorate, nel periodo selezionato.  Delta valore medio per ricetta: Rappresenta il delta percentuale tra il valore medio per ricetta anno corrente e anno precedente, nel periodo selezionato. Venduto decurtato SSN: Rappresenta il valore dei prodotti erogati dietro ricetta. Il valore qui considerato è al netto della DCR per le vendite successive all'installazione della revisione 10, lordo IVA, nel periodo selezionato. Numero ricette lavorate: Rappresenta il numero di ricette gestite.",
  },
  {
    testo: "Pezzi venduti SSN",
    subTesto: "Delta anno precedente",
    valore: "PEZZI_SSNSL",
    subValore: "PEZZI_SSN_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop:"Numero pezzi venduti SSN: Rappresenta il numero di pezzi venduti dietro ricetta, nel periodo selezionato. Delta numero pezzi venduti in SSN: Rappresenta il delta percentuale tra il numero pezzi venduti in SSN anno corrente e anno precedente, nel periodo selezionato."
  },
  {
    testo: "Numero medi pezzi per ricetta ",
    subTesto: "Delta anno precedente",
    valore: "PEZZI_SSN_MEDIO_RICETTASL",
    subValore: "PEZZI_SSN_MEDIO_RICETTA_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop:"Numero medio pezzi per ricetta: Rappresenta il rapporto tra il numero di pezzi venduti in SSN ed il numero di ricette lavorate, nel periodo selezionato. Delta numero medio pezzi ricetta: Rappresenta il delta percentuale tra il numero medio pezzi per ricetta anno corrente e anno precedente, nel periodo selezionato. Numero pezzi venduti SSN: Rappresenta il numero di pezzi venduti dietro ricetta. Numero ricette lavorate: Rappresenta il numero di ricette gestite."
  },

];

export const boxcardAnaVendSottMargine: cardC[] = [
  {
    testo: "Righe di vendita con margine inferiore ", 
    subTesto: "% sul Totale righe di vendita",
    valore: "NUMERO_RIGHE_SOTTO_MARGINE",
    subValore: "PERC_VENDITE_SCONTO_PUNTUALE",  
    typeShowValue: typeShow.euro,
    testoPop:
      "Valore sconto: Rappresenta lo sconto "
  },

];

export const boxcardAnaMargiMediVendi: cardC[] = [
  {
    testo: "Prodotti con margine inferiore ", 
    subTesto: "% sul Totale prodotti",
    valore: "VALORE_SCONTOSL",
    subValore: "PERC_VENDITE_SCONTO_PUNTUALEPR",  
    typeShowValue: typeShow.euro,
    testoPop:
      "Valore sconto: Rappresenta lo sconto "
  },

];


// FBI stato farmacia

export const boxCardStaFarmOne: cardC[] = [
  {
    testo: "Fatturato Ivato Farmacia",
    subTesto: "Delta anno precedente",
    valore: "FATTURATO_IVATO_FARMACIASL",
    subValore: "VALORE_VENDUTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    typeShowValue:typeShow.euro,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine % Farmacia",
    subTesto: "Delta precedente",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    valore: "MARGINE_PERCENTUALESL",
    subValore: "MARGINE_PERCENTUALE_PUNTUALEPR",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Pezzi venduti farmacia",
    subTesto: "Delta anno precedente",
    valore: "PEZZI_VENDUTI_FARMACIASL",
    subValore: "MARGINE_PUNTUALEPR",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  
];



export const boxsecondStatFarm: cardC[] = [
  {
    testo: "Scontrino medio Ivato farmacia ",
    subTesto: "Delta anno precedente",
    valore: "IMPORTO_LORDO_IVA_SCONTRINOSL",
    subValore: "VALORE_VENDUTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    typeShowValue:typeShow.euro,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Scontrino medio Ivato mercato", 
    typeShowValue: typeShow.euro,   
    valore: "MARGINESL",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
];

export const box3StatoFarm: cardC[] = [
  {
    testo: "Numero medio pezzi per scontrino farmacia",
    subTesto: "Delta anno precedente",
    valore: "PEZZI_MEDIO_SCONTRINISL",
    typeShowSubValue: typeShow.percento,
    subValore: "PEZZI_MEDIO_SCONTRINI_PUNTUALEPR",
    testoPop:
      "Valore medio per vendita: Rappresenta il rapporto tra valore del venduto e numero di vendite nel periodo selezionato. Valore medio per vendita: Rappresenta il rapporto tra valore del venduto e numero di vendite nel periodo selezionato. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta valore medio per vendita: Rappresenta il delta percentuale tra il valore medio per vendita anno corrente e anno precedente nel periodo selezionato.",
  },
  {
    testo: "Numero medio pezzi per scontrino mercato",
    subTesto: "Delta anno precedente",
    valore: "PEZZI_MEDIO_SCONTRINISL",
    typeShowSubValue: typeShow.percento,
    subValore: "PEZZI_MEDIO_SCONTRINI_PUNTUALEPR",
    testoPop: `Margine:
        Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
        Delta margine:
        Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },

];





