import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import ".././style.css";
import { Summary, TotalItem } from "devextreme-react/data-grid";


const dashboard46 = { dashboardID: 46 };

//da spostare
const convertTime = (data: Date) => {
    const anno = data.getFullYear();
    const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perch� i mesi sono 0-based
    const giorno = data.getDate().toString().padStart(2, "0");
    const ore = data.getHours().toString().padStart(2, "0");
    const minuti = data.getMinutes().toString().padStart(2, "0");
    const secondi = data.getSeconds().toString().padStart(2, "0");

    const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

    return dataFormattata;
};

const ReportTariffate = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [newReportList, setNewReportList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const loadPanel = <Loading isVisible={isVisible} />;


    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale � mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const mmonthNames = [
        "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
        "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
    ];

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let tipoVenditaParam: any = [];
        tipoVenditaParam.push("LIB");

        //console.log(params.statoRicette);

        let model: any = [];


        if (params.statoRicette === undefined || !params.statoRicette) {
            model.push(8);
            model.push(9);
        }

        const parameters = {
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
            tipoVendita: tipoVenditaParam,
            statoRicette: model
        };


        console.log(parameters);
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {

            

            const dashboardCall46 = {
                ...dashboard46,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall46, urlReport.getQueryDashboardAllData)
                .then((result) => {

                    setIsVisible(false);
                    if (result) {

                        const newReportList = (result["140"]);
                        setNewReportList(newReportList);


                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);



                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            const inizioAnno = new Date();

            inizioAnno.setMonth(0);
            inizioAnno.setDate(1);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };



    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();


    }, [auth]);

    const filters = () => {
        if (auth) {



            console.log();
        }
    };

    useEffect(() => {
        filters();


    }, [auth]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("AnalisiRicetteNonTariffate");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {  
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "ReportAnalisiRicetteNonTariffate.xlsx"
                );
            });
        });
    };

    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },

        { type: "ckBox", idValue:"statoRicette", label: "Includi le Ricette Annullate", width: "auto", require: false},
    ];


    const Columns = [

        { caption: "Codice", dataField: "FARM_CODICE", width: "auto", fixed: true },
        { caption: "Farmacia", dataField: "FARM_DESCRIZIONE", width: "auto", fixed: true },
        { caption: "Data Vendita", dataField: "MIN_DATA_VEN", format: "dd/MM/yyyy HH:mm:ss", dataType: "datetime", width: "auto" },
        { caption: "Tipo Ven.", dataField: "TIPO_VENDITA", width: "auto" },
        { caption: "Codice Ricetta", dataField: "CODICE_RICETTA", width: "auto" },
        { caption: "Importo Lordo", dataField: "IMPORTO_LORDO", width: "auto" },
        { caption: "Importo Netto", dataField: "IMPORTO_NETTO", width: "auto" },
        { caption: "Ticket", dataField: "TICKET", width: "auto" },
        { caption: "Quota Ricetta", dataField: "QUOTA_RICETTA", width: "auto"},
        { caption: "Mese Tariffazione", dataField: "MESE_TARIFFAZIONE", width: "auto" },
        { caption: "Anno Tariffazione", dataField: "ANNO_TARIFFAZIONE", width: "auto" },
        { caption: "Annullate", dataField: "STATO_RICETTA_ANNULLATA", width: "auto" },
    ];



    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    return (
        <section style={{ display: "flex", justifyContent: "space-between", height: "98vh" }}>
            {loadPanel}
            {loadinCambio ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                </div>
            ) : (
                <section
                    id="scrollerelement"
                    style={{ width: "100%", overflow: "scroll" }}
                >
                    <div
                        style={{
                            fontSize: "15px",
                            alignItems: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "rgb(80, 84, 92)",
                        }}
                    >
                        <h3> Report ricette non tariffate  <span className='subt' > Estrazione ricette non tariffate</span> </h3>
                    </div>

                    <div style={{ marginTop: "5px", marginBottom: 40 }} >
                        <NewTabella
                            height={"85vh"}
                            idTabella="Report_tariffate"
                            sync
                            colonne={Columns}
                            dataSource={newReportList}
                            filterRow
                            headerFilter
                            width={500}
                            exportT={{
                                enabled: true,
                                fileName: "ReportTariffate",
                                formats: { exportFormats },
                            }}
                            activeSaver
                            onExporting={onExporting}
                            summary={
                                <Summary>
                                    <TotalItem column="FARM_DESCRIZIONE" summaryType="count" />
                                    <TotalItem column="IMPORTO_NETTO" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />
                                    <TotalItem column="IMPORTO_LORDO" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />
                                    <TotalItem column="QUOTA_RICETTA" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />
                                    <TotalItem column="TICKET" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />
                                    <TotalItem column="IVA" summaryType="sum" />
                                    <TotalItem column="QUANTITA" summaryType="sum" />
                                 </Summary>}

                        />
                    </div>

                </section>
            )}

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                <div style={{}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
                </div>
            </section>
        </section>

    );
};

export default ReportTariffate;
