import React, { useState } from 'react'
import '../index.css'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faComments, faHome, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { urlHomePortal, urlHomePortalMyCsf, urlInfoCsf, urlLogOut, urlRicercaSito } from '../contenuti/costanti';

const NavTop = (props: { statoMenu: boolean, toggleMenu: (stato: boolean) => void, isMyCsf: boolean }) => {
    //classi css per la navtop -> quando si chiude il menu laterale (statoMenu), si cambia classe per aggiornare le dimensioni della navtop
    let navTopClasses = 'navbar-top-links'
    if (!props.statoMenu) {
        navTopClasses = 'navbar-top-links closed'
    }
    //ricerca delle voci di menu nel sito -> TODO al momento non funziona!
    const [ricerca, setRicerca] = useState<string>();
    const valoreRicerca = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRicerca(e.target.value)
    }
    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            let url = urlRicercaSito + "?qs=" + ricerca;
            window.location.assign(encodeURIComponent(url));
        }
    }

    return (
        <Row className="nav-top-row border-bottom">
            <Col md="12" className="nav-top-col">
                <nav className="navbar-static-top" role="navigation">
                    <div className="navbar-header">
                        <div className="navbar-minimalize btn btn-menu" onClick={() => props.toggleMenu(props.statoMenu)}>
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                        <form className="ricerca" role="search">
                            <div className="div-ricerca">
                                <input type="text" placeholder="Cerca voce di menu'..." className="input-cerca" onChange={valoreRicerca} onKeyDown={handleKeyDown}></input>
                            </div>
                        </form>  {/*TODO controlla funzionamento della search*/}
                    </div>
                    <ul className={navTopClasses}>
                        {/* <li className="link"> <div onClick={props.handleShow}>Versione: 1.1.0 </div> | </li> */}
                        <li className="link"> <a href={urlInfoCsf}><FontAwesomeIcon icon={faComments} className="icona-link" /> Parla con CSF </a> | </li>
                        {/* <li className="link"><Link to="/"><FontAwesomeIcon icon={faHome} className="icona-link" /> Home Page </Link> | </li> */}
                        <li className="link"> <a href={props.isMyCsf === false ? urlHomePortal : urlHomePortalMyCsf}><FontAwesomeIcon icon={faHome} className="icona-link" /> Home Page </a> | </li>
                        {/* <li className="link" onClick={props.logout}><Link to="/login"><FontAwesomeIcon icon={faSignOutAlt} className="icona-link" /> Logout </Link></li> */}
                        <li className="link"> <a href={urlLogOut}><FontAwesomeIcon icon={faSignOutAlt} className="icona-link" /> Logout </a></li>
                    </ul>
                </nav>
            </Col>
        </Row>
    )
}

export default NavTop