import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import { DialogConferma, Loading } from '../../widget/Notifications';
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
import {DialogCluster} from "./dialog/popup_tipo_cluster"

import './style.css';


const Gestclustertipo = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    

    const columns = [
        { caption: "Codice", dataField: "codice" },
        { caption: "Descrizione", dataField: "descrizione" },  
        { caption: "Azioni",    type: "buttons", buttons: [
            {
                hint: "Informazioni Ordine Libero",
                icon: "edit",
                onClick: (e: any) => {
                    //console.log(e.row.data);
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setOpenPop(true)                  
                }
            },
            {
                hint: "Informazioni Ordine Libero",
                icon: "trash",
                onClick: (e: any) => {
                    //console.log(e.row.data);
                    setIddell(e.row.data.id)
                    setMessDel("La tipologia "+e.row.data.codice+" sta per essere eliminato. Confermare?")
                    setPopDel(true)
                    
                }
            }]  },
    ];

    
    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([]) 
    const [loading,setLoading] = useState<boolean>(false)
    const [ openPop , setOpenPop ] = useState<boolean>(false)
    const [popDel,setPopDel] = useState<boolean>(false)
    const [idDel,setIddell] = useState<number>(0)
    const [messDel,setMessDel] = useState<string>('')
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)



    const removeTipo = () =>{
        if (auth) {
            RestServices().rest('POST', { id : idDel} , urlConsultazione.deleteTipoCluster)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       getTipologiaCluster()
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })
        
    } 
    }

    const ricarica = () =>{
        setOpenPop(false)
        getTipologiaCluster()
        setImmediate(false);
        setObjtipo(null);               

    }


    const getTipologiaCluster = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListTipologiaCluster)
                .then(result => {
                    //console.log(result);                               
                    if (result?.esito) {                      
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }
    }

   
       
    useEffect(()=>{
        getTipologiaCluster()
    },[auth])
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


  
   
    return (
        <>
        <DialogConferma
                    dialogIsOpen={popDel}
                    message={messDel}
                    functionNext={()=>removeTipo()}
                    handleCloseConferma={()=>{setPopDel(false);setIddell(0)}}
                />
            
         <DialogCluster typeEdit={edit} edit={objtipo} ricarica={()=>{ricarica();}} close={()=>{setOpenPop(false);setImmediate(false);setObjtipo(null)}} open={openPop} />
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> GESTIONE TIPOLOGIA CLUSTER  <span className='subt' ></span> </h3>
             </div>
             <Button  className='butt_tipo_cluster'   variant="primary" onClick={()=>{setOpenPop(true)}} >
               NUOVA TIPOLOGIA
           </Button>
               
            
            <NewTabella 
                idTabella='elenco_gest_cluster'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale}  
                searchPanel={true}         
                exportT={{
                    enabled: true,
                    fileName: "tipo_cluster"
                }}
                activeSaver={true}
                columnC={true}
            />
           
        </>
    )
}

export default Gestclustertipo