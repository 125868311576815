import notify from "devextreme/ui/notify";
import React, { useEffect, useMemo, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { DettagliListinoInterface, ElencoListiniInterface } from "../../interfaces/trasferimenti";
import { RestServices } from "../../services/restServices";
import { urlTrasferimenti } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import TestataContenitore from "../contenitori/TestataContenitore";
import TestataListino from "./TestataListino";
import { Loading } from "../../widget/Notifications";
import { DrawerFiltri } from "../../widget/Drawer";
import { TabellaCompletaAlternate } from "../../widget/Tabelle";

const SingoloListino = (props: {
    toggleMenu(stato: boolean): any,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[]
}) => {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    const idListino = urlParams.get('idListino');
    let idFar = urlParams.get('idFar');


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

        //setIdContenitore(parseInt(urlParams.get('idContenitore')));
        //setContenitoreFarmacia(parseInt(urlParams.get('contenitoreFarmacia')));

        if (window.location.href.includes("?")) {
            const param = {
                idListino:idListino
            }
            setIsVisible(true);
            RestServices().rest('POST', param, urlTrasferimenti.getListinoCompleto)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result.esito && result.response.testata) {
                            setTestata(result.response.testata);
                            setDettagliListino(result.response.lstListinoDettaglio);
                        } else if (result.esito == false) {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                        }
                    }

                })
        } 

    }, []);


    const [isVisible, setIsVisible] = useState(false);
    const [testata, setTestata] = useState<ElencoListiniInterface>();
    const [dettagliListino, setDettagliListino] = useState<DettagliListinoInterface[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />
    const [openDrawer, setOpenDrawer] = useState(true);
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const propsTabListinoDettaglio: TabellaCompletaInterface = useMemo(() => ({
        id: "dettaglio_listino",
        dataSource: dettagliListino,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        pagination: 10,
        rowAlternationEnabled: false,
        summary: {
            totalItems: [{
                name: "TotaleProdotti",
                showInColumn: "Cod. Prod.",
                displayFormat: "Prodotti: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Listino"
        }
    }), [dettagliListino])

    const columnsDettaglio = CreaColonne([
        { caption: "Cod. Csf", dataField: "codCsf", visible: false },
        { caption: "Cod. Prod.", dataField: "codProd", visible: true },
        { caption: "Descrizione", dataField: "prodotto", },
        { caption: "Imponibile", dataField: "imponibile", allowEditing: true, format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
    ])

    const templateFiltri = () => {
        return (
            <Row id="testata">
                <TestataListino
                    testata={testata}
                />
            </Row>
        )
    }

    return (
        <>
            {loadPanel}
            {
                <>
                    <div className="contenutoPagina">
                        {
                         
                            <>
                                <DrawerFiltri
                                    id="testataListino"
                                    templateFiltri={templateFiltri}
                                    testoDrawer="Dettaglio Listino"
                                    open={openDrawer}
                                    setOpen={setOpenDrawer}
                                />
                                <Row>
                                    <Col sm='12' className="ordineRda">
                                        {
                                            <TabellaCompletaAlternate
                                                propsTabella={propsTabListinoDettaglio}
                                                setTabellaInstance={setTabellaInstance}
                                                columns={columnsDettaglio}
                                                rowAlternationEnabled={false}
                                            />}
                                    </Col>

                                </Row>
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default SingoloListino