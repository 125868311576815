import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { Chart, Series, ArgumentAxis, CommonSeriesSettings, CommonAxisSettings, Grid, Export,
  Legend, Margin, Tooltip, Label, Format} from 'devextreme-react/chart'
  import PieChart, {Font, Connector } from 'devextreme-react/pie-chart'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import './style.css'
import { colors } from 'react-select/dist/declarations/src/theme'
import src from 'react-select/dist/declarations/src'

import stc from 'string-to-color'

function getRandomColor(name:any) {
  // get first alphabet in upper case
  const firstAlphabet = name.charAt(0).toLowerCase();
 
  // get the ASCII code of the character
  const asciiCode = firstAlphabet.charCodeAt(0);
 
  // number that contains 3 times ASCII value of character -- unique for every alphabet
  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();
 
  var num = Math.round(0xffffff * parseInt(colorNum));
  var r = num >> 16 & 355;
  var g = num >> 8 & 355;
  var b = num & 355;
 
  return {
    color: 'rgb(' + r + ', ' + g + ', ' + b + ')',
    character: firstAlphabet.toUpperCase()
  };
}


  
const ReportGrafic = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

 

    const auth = useAuth(urlConsultazione.auth);
    const [loading,setLoading] = useState<boolean>(false)
    const [newFarmaList,setNewFarmaList] = useState([])
    const [chart,setChart] = useState({architectureSpline:[],dataSourcePie:[],dataSourceSpline:[]})
    const [conti, setConti] = useState<any>([])
    const [objColor, setObjColor] = useState<any>({})
    


    const ConvertForSelect = (arr:any) =>{
      let newArr:any = []
      arr.forEach((element:any) => {
          //console.log(element);
          newArr.push({value:element.id,label:element.conto})
          
      });
      return newArr
  }

    const itemsFields =[
      {type:'select2' , idValue:'idConto' , label:'Conto ' , displayExpr:'descrizione', dataSource : ConvertForSelect(conti) ,width:350  },
      {type:'date' , idValue:'dal' , label:'Dal' , defValue : new Date(new Date().getFullYear()+'-01-01') , require:false  },
      {type:'date' , idValue:'al' , label:'Al' ,  defValue : new Date()},  
    ]


    const getConto = () =>{
      if (auth) {
         RestServices().rest('POST', {} , urlConsultazione.getListConti)
             .then(result => {
                 if (result?.esito) {   
                     //console.log(result.response);
                     setConti(result.response)                     
                 }else{                           
                     //console.log(result); 
                     notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                 
                 }
             })     
     }
 }
    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {                    
                      // console.log(result.response);
                       setNewFarmaList(result.response)                    
                   }else{                                
                       //console.log(result);                   
                   }
               })     
       }
   }


   const getChart = (body?:any) =>{
    let bodyUse = body ? body : {}
    if (auth) {
       RestServices().rest('POST', bodyUse , urlConsultazione.getChart)
           .then(result => {
               if (result?.esito) {   
                  // console.log('remort cart') 
                 //  console.log(result.response);
                   let architectureSplineN:any = []
                   let dataSourcePieN:any =[]
                   let dataSourceSplineN:any =[]

                   result.response.dataSourcePie.forEach((element:any) => {
                      if(element.SALDOCONTO != 0){
                        let obj = element
                        obj.nacolor = element.CONTO;
                        obj.CONTO = '('+ element.CONTO.slice(-5) + ')'+ ' '  + element.CONTO.split('-')[0] ;
                        dataSourcePieN.push(obj)
                      }
                   });
                   
                   setChart({
                    architectureSpline:result.response.architectureSpline,
                    dataSourcePie:dataSourcePieN,
                    dataSourceSpline:result.response.dataSourceSpline,

                   })                    
               }else{                                
                  // console.log(result);   
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                                 
               }
           })     
   }
}

  const filtroCharts = (value:any) =>{
        let valueTo:any ={}
        if(value.dal){
          valueTo.dal = value.daldataSourcePie
        }
        if(value.al){
          valueTo.al = value.al
        }
        if(value.idConto){
          let arrr:any = []
          value.idConto.forEach((element:any) => {
            arrr.push(element.value)
          });
          valueTo.idConto = arrr
        }
        //console.log(valueTo);  
        getChart(valueTo)
      }

   useEffect(()=>{
       farmaList()
       getChart ()
       getConto()
    },[auth])
    
    useEffect(() => {   
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    function customizeText(arg: { valueText: any; percentText: any; }) {
        return `${arg.valueText} (${arg.percentText})`;
      }

    
    return (
        <>
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> REPORT GRAFICO<span className='subt' ></span> </h3>
             </div>
             <Ricerca ricerca={(filtro)=>filtroCharts(filtro)}  items={itemsFields}  />
             <Chart
          palette="Violet" 
          dataSource={chart.dataSourceSpline}        
          title="Andamento"
        >
          <CommonSeriesSettings
            argumentField="meseAnno"
            type={'spline'}
          />
          <CommonAxisSettings>
            <Grid visible={true} />
          </CommonAxisSettings>
          {
            chart.architectureSpline.map((item:any) => <Series
              color={stc(item.name)}
              key={item.value}
              valueField={item.value}
              name={ '('+  item.name.slice(-5) + ')'+ ' '  + item.name.split('-')[0] }/>)
          }
          <Margin bottom={20} />
          <ArgumentAxis
            allowDecimals={false}
            axisDivisionFactor={60}
          >
            <Label>
              <Format type="decimal" />
            </Label>
          </ArgumentAxis>
          <Legend
            verticalAlignment="top"
            horizontalAlignment="right"
          />
          <Export enabled={true} />
          <Tooltip enabled={true} />
        </Chart>    

        <br></br>

        <PieChart id="pie"
        //palette="Bright"
        dataSource={chart.dataSourcePie}
        customizePoint={(point) => {
          let colll:any = stc(point.data.nacolor)
          return {
            color:colll
          }

          /*if(point.argument == "BANCA INTESA - 100573088990"){
            console.log('devo diventaere giallo');
            
            return {
              color: "yellow"
          }

          }else{
            return {}
          }*/
          
          }
        }
        title="Saldi"
      >
        <Legend
          orientation="horizontal"
          itemTextPosition="right"
          horizontalAlignment="center"
          verticalAlignment="bottom"
          columnCount={4} />
        <Export enabled={true} />
        <Series argumentField="CONTO" valueField="SALDOCONTO">
        <Label
            visible={true}
            position="columns"
            customizeText={customizeText}>
            <Font size={16} />
            <Connector visible={true} width={0.5} />
          </Label>
        </Series>
      </PieChart>
                                   
        </>
    )
}

export default  ReportGrafic


