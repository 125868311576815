import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import { AplicaValoreTO } from '../../../funzioni/operazioniSuTabelle'
import { legendByarray } from '../../../funzioni/formatoVariabili'


import "../style.css";
import { NewTabella } from "../../../widget/nuova_tabella/newVersion"

interface DialogProps { 
  open: boolean,
  edit: any,
  ricarica: () => void,
  close: ()=>void,
}


export const DialogTransGiacNew: React.FC<DialogProps> = ( { open, close,edit,ricarica}) => {

  const [updateInvio,setUpdateInvio] = useState<any>(null)
  const [openD, setOpenD] = useState <boolean>(false)
  const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})
  const [loading, setLoading] = useState <boolean>(false)
  const [modificheObj, setModificheObj] = useState <any>({ 
    codCsf: 0,
    idFarmaDest: 0,
    invio: true,
      dettaglio: []
  })

    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [rowIndex, setRowIndex] = useState<number>();

  const columns =  [
      { caption: "Farmacia",editC:false, dataField: "descrizioneFarmacia" },
      { caption: "Giacenza", editC:false, dataField: "giacenza", width: 100 },
      { caption: "Costo ultimo m.p.", editC: false, dataField: 'costoMedioPonderato', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
      { caption: "% ricarico", dataField: 'percentualeRicarico', editC:true, width: 100 },
      { caption: "Costo ricaricato", dataField: 'costoRicaricato', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
      { caption: "Quantità",dataType:'number', editC:true, dataField: "quant" , width: 100},

  ]


 const getListFarm = () =>{
  if (auth) {
     return RestServices().rest('POST', {} , urlConsultazione.getListFar)
  }
}


    const creaObjModifica = (obb: any) => {

      let objMod = {...modificheObj}
  
      const index = objMod.dettaglio.findIndex((object:any) => {
        return object.idFar === obb.data.idFar;
      });


        //calcolo del costo ricaricato
        let parametri: any = {
            costo: obb.data.costoMedioPonderato,
            percentualeRicarico: obb.data.percentualeRicarico
        }
        let costoRicaricato = obb.data.costoRicaricato;
        RestServices().rest('POST', parametri, urlConsultazione.calcolaCostoRicaricato)
            .then(result => {
                if (result) {
                    if (result.esito) {
                        if (result.response.costoRicaricato) {
                            costoRicaricato = result.response.costoRicaricato
                            tabellaInstance.instance.cellValue(obb.component.getRowIndexByKey(obb.key), "costoRicaricato", costoRicaricato);
                        }
                        
                    }
                }
            })
        
      if(index > -1){
        if(obb.data.quant){
          objMod.dettaglio[index] = {
            "idFar": obb.data.idFar,
            "costoMedioPonderato":obb.data.costoMedioPonderato,
              "quantita": obb.data.quant,
              "percentualeRicarico": obb.data.percentualeRicarico,
              "costoRicaricato": obb.data.costoRicaricato
          }
        }else{
          objMod.dettaglio.splice(index, 1)
        }
   
      }else{
        objMod.dettaglio.push({
          "idFar": obb.data.idFar,
          "costoMedioPonderato":obb.data.costoMedioPonderato,
          "quantita": obb.data.quant,
          "percentualeRicarico": obb.data.percentualeRicarico,
          "costoRicaricato": obb.data.costoRicaricato
        })
      }
  
    setModificheObj(objMod)

  
}

const caricaDati = async() =>{

    //console.log("caricadati")

  if (auth) {
    let farmacieLista = await getListFarm()

    let parametri:any = {
      codCsf:edit.codCsf
    }
   if(edit.showFarma){
      parametri.idFar = edit.showFarma
    }
    if (edit.idFarDestinatario) {
        parametri.idFarDestinatario = edit.idFarDestinatario
    }
    
    RestServices().rest('POST', parametri , urlConsultazione.getListGiacenzaProdFarma)
    .then(result => {
        if (result?.esito) {
            let valoreFormatt = legendByarray(
              result.response,
              farmacieLista.response,
              'idFar',
              'idFar',
              'descrix',
              ['descrizione'])
          
             
            let arrRemove:any = []
            valoreFormatt.forEach((farma:any) => {
              if(farma.idFar != edit.idFar){
                arrRemove.push(farma)
              }
            });
         
            //console.log(valoreFormatt);
            
            setTabellaPrincipale(arrRemove)
            setLoading(false)
        }else{
            notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            setLoading(false)
        }
    })
 }
}





 const cambiaValore = (tipo:string,value:string) =>{
          let objTo = {...objctTOsave}
          objTo[tipo] = value
          setObjctTOsave(objTo)
    }



  useEffect(() => {
    if (edit) {
      //console.log(edit);     
      let objTo = { ...objctTOsave };
      objTo.descrizioneProdotto = edit.descrizioneProdotto;
      objTo.codProd = edit.codProd;
      objTo.descDittaTitolare = edit.codCsf;
      objTo.giacenza = edit.giacenza;
      objTo.prezzoListino = edit.prezzoListino;
      objTo.costoAcquisto = edit.costoAcquisto;
      objTo.costoMedioPonderato = edit.costoMedioPonderato;
      objTo.descrix = edit.descrix;
      objTo.generalCostoMedioPonderato = edit.generalCostoMedioPonderato
      objTo.generalPrezzoListino = edit.generalPrezzoListino


      setObjctTOsave(objTo);
      
      setModificheObj({ 
        codCsf: edit.codCsf,
        idFarmaDest: edit.idFar,
        invio: true,
          dettaglio: []
      })

      
    }
  }, [edit, openD]);

  useEffect(() => {
        // if(open){
           setOpenD(open);
        // }
        if(open && auth){
          caricaDati()
        }
    
  }, [open,auth]);

  useEffect(() => {
  }, [auth]);


  const actionConferma = (invio?:boolean) =>{
  setTimeout(
      function() {
    //console.log(tabellaPrincipale);
    let erroreQ = false

    tabellaPrincipale.forEach((element:any) => {
        if(element.quant){
          if(element.giacenza < element.quant){
            erroreQ = true
          }
        }
        
    });
    
    if(erroreQ){
      notify({ position: "center", width: "auto", message:'controlla le quantita! la giacenza deve essere maggiore'}, "warning", 4000)

    }else{
      let obbj = {...modificheObj}
      if(invio){
        obbj.invio = false
      }
      RestServices().rest('POST', obbj , urlConsultazione.updateTrasfe)
      .then(result => {
          if (result?.esito) {
              //console.log('dati');  
              notify({ position: "center", width: "auto", message: result.response.esitoMessaggioTrasferimento}, "success", 4000)
              close()
          }else{
              notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
          }
      })
    }
      }, 500);
    }


  useEffect(()=>{
    
    },[open])

    const renderTitle = () => {
      return (
        <h5> Trasferimenti alla Farmacia :{objctTOsave.descrix}</h5>
      );
    }

    const onEditingStart = (e: any) => {
        //setRowIndex(e.component.getRowIndexByKey(e.key));
    }

  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'75%'}
      height={'85%'}
      showTitle={true}
      titleRender={renderTitle}
      
    >
          <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Conferma',
                elementAttr:{
                  class:'btncreatecluster',
              },
                onClick: ()=>actionConferma(true),
              }
            }
          />
          <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Conferma e Invia',
                elementAttr:{
                  class:'btncreatecluster',
              },
              onClick: ()=>actionConferma(),
              }
            }
          />
           <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }
            }
          />

      <ScrollView height="100%"  width="100%">    

      <div className='form-row '> 
      <div className="form-group col-md-2">
          <label> CODICE Minsan </label>
          <TextBox disabled value={objctTOsave.codProd}  />
        </div>

        <div className="form-group col-md-4">
          <label> Descrizione Prodotto </label>
          <TextBox disabled value={objctTOsave.descrizioneProdotto} />
        </div>

        <div className="form-group col-md-2">
          <label> Giacenza Farmacia </label>
          <TextBox disabled value={objctTOsave.giacenza}  />
        </div>
        <div className="form-group col-md-2">
          <label> Prezzo banca Dati </label>
          <TextBox disabled value={objctTOsave.generalPrezzoListino}  />
        </div>

        <div className="form-group col-md-2">
          <label> Costo m.p. </label>
          <TextBox disabled value={objctTOsave.generalCostoMedioPonderato}  />
        </div>
        </div>

        

        
        <div style={{ width: '100%'}}>
        <div  className=''>
                <h3> DALLE FARMACIE<span className='subt' ></span> </h3>
             </div>
          <NewTabella
                      idTabella="farm"
                      sync
                      colonne={columns}
                      height={'auto'}
                      hidePager={false}
                      dataSource={tabellaPrincipale}
                      onRowUpdated={(x) => creaObjModifica(x)}
                      //allowColumnResizing={true}
                      //hideColumnHeaders={true}
                      //specialPd={0.1}
                      exportT={{
                          enabled: false,
                          fileName: "list_farm",
                      }}
                      tabReference={setTabellaInstance}
                      onEditingStart={onEditingStart }
          />
        </div>
        
              


      </ScrollView>
    </Popup>
  );
};