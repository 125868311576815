import React, { useCallback, useEffect, useRef, useState } from 'react'
import notify from 'devextreme/ui/notify'
import { RestServices } from "../services/restServices"
import { urlConsultazione } from "./costanti"
import { useAuth } from '../hooks/useAuth'
import Image from 'react-bootstrap/Image'
import { cambiaFormatoLocalDateTimeToEuropeo, legendByarray } from '../funzioni/formatoVariabili'
import { Chart, Series, CommonSeriesSettings, Label, Format, Legend, Export, Point, HoverStyle, Connector, Tooltip } from 'devextreme-react/chart'
import List, { ItemDragging } from 'devextreme-react/list';
import './contenuti.css'
import { FrameWrapper } from '../widget/FrameWrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { Hide } from 'devextreme-react/data-grid'
import { Anchor } from 'exceljs'
import { Overlay } from 'react-bootstrap'
import Header from '../login/Header'
import { HideEvent } from 'devextreme-react/popover'
import { Center } from 'devextreme-react/map'
import { PointHoverStyle } from 'devextreme-react/polar-chart'
import { Position } from 'devextreme-react/date-box'



const HomePage = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean, flagMyCsf?: boolean }) => {

    const auth = useAuth(urlConsultazione.auth)
    const [newFarmaList, setNewFarmaList] = useState<any>([])
    const [newFarmaListC, setNewFarmaListC] = useState<any>([])
    const [famaListData, setFarmaListData] = useState([])
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isNewExpanded, setIsNewExpanded] = useState(true);
    const [rotateChevron, setRotateChevron] = useState(false);
    const [acquisti, setAcquisti] = useState([])
    const [vendite, setVendite] = useState([])
    const [selectedFarm, setSelectFarm] = useState(null)
    const [selectedFarmCart, setSelectFarmCart] = useState(null)
    const [datiMonitor, setDatiMonit] = useState<any>({})
    const [listItemFarma, setListItemFarma] = useState<any>([])
    const [load, setLoad] = useState<boolean>(true)
    const [defaultItem, setDefaultItem] = useState<any>([]);
    const ref = useRef<any>(null)
    const [height, setHeight] = useState<any>(0);
    const [sstart, setSstart] = useState<boolean | undefined>(true)

    const getAndamentoVendite = () => {
        let obj: any = {
            listFar: []
        }
        if (selectedFarmCart) {
            if (selectedFarmCart != 99999999999999) {
                obj.listFar = [selectedFarmCart]
            }
        }
        if (auth) {
            return RestServices().rest('POST', obj, urlConsultazione.getAndamentoVendite)
        }
    }

    const getAndamentoAcquisti = () => {
        let obj: any = {
            listFar: []
        }
        if (selectedFarmCart) {
            if (selectedFarmCart != 99999999999999) {
                obj.listFar = [selectedFarmCart]

            }
        }
        if (auth) {
            return RestServices().rest('POST', obj, urlConsultazione.getAndamentoAcquisti)
        }
    }

    const getListMoniFarmacie = () => {
        if (auth) {
            return RestServices().rest('POST', {}, urlConsultazione.getListMonitFarmacie)

        }

    }


    const getStatusMobileFarm = () => {
        let obj: any = {
            idFar: null
        }
        if (selectedFarm) {
            obj.idFar = selectedFarm
        }
        if (auth) {
            return RestServices().rest('POST', obj, urlConsultazione.getStatusMobileFarm)

        }

    }

    const caricaVendite = async () => {
        if (auth) {
            let vendite = await getAndamentoVendite();

            setVendite(vendite.response)
        }

    }

    const caricaAcquisti = async () => {
        if (auth) {
            let acquisti = await getAndamentoAcquisti();

            setAcquisti(acquisti.response)
        }

    }

    const caricaDati = async (start?: boolean) => {
        if (auth) {
            setSstart(start)
            try {
                let farmacieLista = []
                if (start) {

                    let res = await RestServices().rest('POST', {}, urlConsultazione.getListFar)
                    farmacieLista = res.response
                }

                else
                    farmacieLista = [...newFarmaList]


                let listaTabella = await getListMoniFarmacie()
                let datiMonit = await getStatusMobileFarm()
                // console.log('monit');
                // console.log(datiMonit);
                if (!datiMonit.esito) {
                    notify({ position: "center", width: "auto", message: datiMonit.message }, "error", 4000)
                }
                setDatiMonit(datiMonit.response ? datiMonit.response : {})

                //let vendite = await getAndamentoVendite()
                //let acquisti = await getAndamentoAcquisti()

                //setVendite(vendite.response)
                //setAcquisti(acquisti.response)
                // console.log('ve');
                // console.log(vendite.response);

                //if (start)
                setNewFarmaList(farmacieLista)

                let arr = [...farmacieLista]
                arr.push({
                    idFar: 99999999999999,
                    descrizione: 'Tutte'
                })
                setNewFarmaListC(arr)
                if (start) {
                    setSelectFarm(farmacieLista[0].idFar)
                }



                if (listaTabella?.esito) {
                    let tabellaNew: any = []

                    let valoreFormatt = legendByarray(
                        listaTabella.response,
                        farmacieLista,
                        'idFar',
                        'idFarm',
                        'codiceCF',
                        ['codice'])

                    // console.log('famacieee');
                    // console.log(valoreFormatt);

                    setFarmaListData(valoreFormatt)


                }
                else {
                    notify({ position: "center", width: "auto", message: listaTabella.message }, "error", 4000)

                }
            } catch (error) {
                notify({ position: "center", width: "auto", message: 'errrorr' }, "error", 4000)

            }
        }
    }




    useEffect(() => {
        if (props.statoMenu) {

            //  props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            if (selectedFarm) {
                //alert(selectedFarm)
            }
            caricaDati(false)
            // getStatusMobileFarm()
        }
    }, [selectedFarm, selectedFarmCart])

    useEffect(() => {


        let listItem: any[] = [];

        newFarmaList.forEach((element: any) => {

            let data: string | undefined = "";
            let date: Date | undefined = undefined;
            famaListData.forEach((f: any) => {
                if (f.idFarm === element.idFar) {
                    //console.log(f.idFarm)
                    //console.log(f)
                    if (f.dataFineCaricamento != undefined) {
                        data = cambiaFormatoLocalDateTimeToEuropeo(f.dataFineCaricamento, true)
                        date = f.dataFineCaricamento
                    }
                }

            })

            let item = { id: element.idFar, text: element.codice + ' - ' + element.ragioneSociale, data: data, date: date }
            listItem.push(item);

        });


        let sorted = listItem.sort((a, b) => (a.text < b.text ? -1 : 1));


        let reload = false;

        // trova differenze
        listItemFarma.forEach((a: any) => {
            let id = a.id;
            let text = a.text
            let data = a.data

            sorted.forEach((b: any) => {
                if (b.id == id) {
                    if (b.text != text || b.data != data) {
                        reload = true;
                    }


                }
            })

        })

        if (((listItemFarma == undefined || listItemFarma.length == 0) || reload
        )) {
            setListItemFarma(sorted)
            let selectedDefault: number[] = [];
            if (sorted != undefined && sorted.length > 0) {
                if (sstart) {
                    selectedDefault.push(sorted[0].id)
                    setDefaultItem(selectedDefault)
                }

                //console.log(selectedDefault)
            }

        }



    }, [newFarmaList])

    useEffect(() => {
        if (auth) {
            if (selectedFarm) {
                //alert(selectedFarm)
            }

            caricaVendite()
            caricaAcquisti()

            caricaDati(true)
            // getStatusMobileFarm()

            setLoad(false)
        }
    }, [auth])

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 100); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isNewExpanded]);

    const onItemClick = (e: any) => {

        setSelectFarm(e.itemData.id)
        let a: any[] = []
        a.push(e.itemData.id)
        //console.log("B")
        setDefaultItem(a)

    }


    const ListItem = (data: any) => {
        let color = 'g';

        var d1: any = new Date(new Date().toISOString());
        var d2: any = new Date(data.date);

        var diff = d1 - d2;


        //var diff = Math.abs(new Date().toISOString() - data.data);
        var diffDays = Math.ceil(diff / (1000 * 3600 * 24));

        if (diffDays >= 7)
            color = 'r'

        if (diffDays >= 2 && diffDays < 7)
            color = 'y'

        if (auth && data)
            return (
                <div>
                    <b>{data.text}</b>

                    <br />
                    {/* <div className="row">
                    <div className="col-md-2">
                        
                    </div>
                    <div className="col-md-10 text-right">
                        <div className={'clr' + color} ></div>
                        <p style={{ margin: '0px', fontSize: 13, fontStyle: 'italic', textAlign: 'right', display: 'inline-block' }}>Ultimo Agg. ricevuto: {data.data} </p>
                    </div>
                </div>
                */}
                    <div style={{ display: 'flex', gap: 8, alignItems: 'right', margin: 5 }}>
                        <div className={'clr' + color} ></div>
                        <p style={{ marginTop: '4px', fontSize: 13, fontStyle: 'italic', textAlign: 'right', display: 'inline-block' }}>Ultimo Agg: {data.data} </p>
                    </div>
                </div>
            );
    };


    return (<section style={{ display: 'flex', justifyContent: 'space-between', transition: 'transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.20s ease-in-out', }}>
        {loadinCambio ? (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
            </div>
        ) : (

            <section id='scrollerelement' style={{ width: '100%', overflow: 'scroll' }}>

                <div style={{ marginTop: 10, textAlign: 'center' }}>
                    <Image
                        src="https://www.cloud-farma.it/cfportal/img/logocf.png"
                        rounded
                    />
                </div>

                <div style={{ fontSize: 15, marginBottom: 40, marginTop: 20, textAlign: 'center' }}><p style={{ margin: 0 }}>Piattaforma Cloud di centralizzazione delle farmacie flessibile, completa e semplice da utilizzare.</p>
                    <p style={{ fontSize: 15, margin: 0 }}>Avanzato sistema di monitoraggio e controllo in tempo reale che consente di monitorare i sistemi presenti nella tua farmacia.</p>
                </div>

                {false ? (
                    null
                ) : (

                    <div className="row">
                        <div className="col-md-12">
                            <div style={{ gap: 10, background: 'white', padding: 5, borderRadius: 10, marginTop: 10 }}>

                                <div className="row">
                                    <div className="col-12 col-md-4">
                                        <List
                                            dataSource={listItemFarma}
                                            keyExpr="id"
                                            onItemClick={onItemClick}
                                            itemRender={ListItem}
                                            selectedItemKeys={defaultItem}
                                            scrollingEnabled={true}
                                            height={530}
                                            pageLoadMode={"scrollBottom"}
                                            showScrollbar={"never"}
                                        >
                                            <ItemDragging
                                                allowReordering={false}
                                            />
                                        </List>

                                    </div>

                                    <div className="col-12 col-md-8" style={{ borderLeft: '1px solid lightgrey' }}>
                                        <div className="row">
                                            <h6 style={{ marginLeft: 80 }}>DATI GESTIONALE</h6>
                                        </div>
                                        <div className="row">


                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            id="Layer_1"
                                                            enableBackground="new 0 0 128 128"
                                                            viewBox="0 0 128 128"
                                                            xmlSpace="preserve"
                                                        >
                                                            <circle cx={64} cy={64} r={50} fill="#589fff" />
                                                            <circle
                                                                cx={64}
                                                                cy={64}
                                                                r={50}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <path
                                                                fill="none"
                                                                stroke="#fff"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={8}
                                                                d="M19,64c0-24.9,20.1-45,45-45"
                                                                opacity={0.4}
                                                            />
                                                            <path
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={8}
                                                                d="M109,64c0,24.9-20.1,45-45,45"
                                                                opacity={0.2}
                                                            />
                                                            <circle cx={64} cy={64} r={40} fill="#fff" />
                                                            <polygon fill="#5e61a3" points="85.2 75.5 63.3 68.4 67 60.6 86.4 73.2" />
                                                            <line
                                                                x1={47.9}
                                                                x2={63.5}
                                                                y1={42.7}
                                                                y2={63.5}
                                                                fill="none"
                                                                stroke="#ff5751"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={4}
                                                            />
                                                            <polygon
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                                points="85.2 75.5 63.3 68.4 67 60.6 86.4 73.2"
                                                            />
                                                            <circle
                                                                cx={64}
                                                                cy={64}
                                                                r={40}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <circle cx={64} cy={64} r={5.7} fill="#ffc408" />
                                                            <circle
                                                                cx={64}
                                                                cy={64}
                                                                r={5.7}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={64}
                                                                x2={64}
                                                                y1={31}
                                                                y2={32}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={4}
                                                            />
                                                            <line
                                                                x1={64}
                                                                x2={64}
                                                                y1={96}
                                                                y2={97}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={4}
                                                            />
                                                            <line
                                                                x1={31}
                                                                x2={32}
                                                                y1={64}
                                                                y2={64}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={4}
                                                            />
                                                            <line
                                                                x1={96}
                                                                x2={97}
                                                                y1={64}
                                                                y2={64}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={4}
                                                            />
                                                            <line
                                                                x1={46.6}
                                                                x2={48}
                                                                y1={33.9}
                                                                y2={36.3}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={80}
                                                                x2={81.4}
                                                                y1={91.7}
                                                                y2={94.2}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={34}
                                                                x2={36.3}
                                                                y1={81.3}
                                                                y2={80}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={91.7}
                                                                x2={93.9}
                                                                y1={48}
                                                                y2={46.7}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={34.3}
                                                                x2={36.3}
                                                                y1={46.9}
                                                                y2={48}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={91.7}
                                                                x2={94}
                                                                y1={80}
                                                                y2={81.3}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={46.8}
                                                                x2={48}
                                                                y1={93.8}
                                                                y2={91.7}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                            <line
                                                                x1={80}
                                                                x2={81.3}
                                                                y1={36.3}
                                                                y2={34}
                                                                fill="none"
                                                                stroke="#242c88"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                strokeMiterlimit={10}
                                                                strokeWidth={2}
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', margin: 5, width: 150 }}>
                                                            <div className={'clr' + datiMonitor?.colorSessione}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Sessione</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultimo avvio:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.sessioneTime}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 48 48"
                                                        >
                                                            <g data-name="Layer 1 copy">
                                                                <rect
                                                                    width={37.447}
                                                                    height={21.434}
                                                                    x={7.859}
                                                                    y={10.844}
                                                                    fill="#569f7d"
                                                                    transform="rotate(-10.943 26.58 21.563)"
                                                                />
                                                                <rect
                                                                    width={37.447}
                                                                    height={21.434}
                                                                    x={4.372}
                                                                    y={15.125}
                                                                    fill="#60b18b"
                                                                    transform="rotate(-5.938 23.096 25.837)"
                                                                />
                                                                <rect width={37.447} height={21.434} x={1} y={19.081} fill="#8ed8b5" />
                                                                <path
                                                                    fill="#b1dfbc"
                                                                    d="M35,34.478A3.007,3.007,0,0,0,32,37.485H7.453a3.007,3.007,0,0,0-3.008-3.007V25.119a3.008,3.008,0,0,0,3.008-3.008H32A3.007,3.007,0,0,0,35,25.119Z"
                                                                />
                                                                <circle cx={19.724} cy={29.798} r={4.512} fill="#8ed8b5" />
                                                                <polygon
                                                                    fill="#60b18b"
                                                                    points="41.346 9.771 45.046 28.907 47 28.529 42.931 7.485 6.165 14.594 6.534 16.502 41.346 9.771"
                                                                />
                                                                <polygon
                                                                    fill="#8ed8b5"
                                                                    points="39.025 15.532 41.024 34.752 42.827 34.565 40.61 13.246 3.364 17.12 3.582 19.219 39.025 15.532"
                                                                />
                                                                <polygon
                                                                    fill="#b1dfbc"
                                                                    points="1 19.081 1 21.111 36.4 21.111 36.4 40.515 38.447 40.515 38.447 19.081 1 19.081"
                                                                />
                                                                <path
                                                                    fill="#8ed8b5"
                                                                    d="M5.268 24.99a2.987 2.987 0 0 1-.823.129v1.214A3 3 0 0 0 5.268 24.99zM30.748 37.485H32a2.981 2.981 0 0 1 .136-.849A2.988 2.988 0 0 0 30.748 37.485z"
                                                                />
                                                                <path
                                                                    fill="#c1ecd0"
                                                                    d="M32,22.111H7.453a2.989,2.989,0,0,1-.8,2.03h23.3a3.007,3.007,0,0,0,3.008,3.007v8.145A2.988,2.988,0,0,1,35,34.478V25.119A3.007,3.007,0,0,1,32,22.111Z"
                                                                />
                                                                <path
                                                                    fill="#b1dfbc"
                                                                    d="M19.724,25.286a4.5,4.5,0,0,0-4.019,2.5,4.456,4.456,0,0,1,1.971-.472,4.512,4.512,0,0,1,4.512,4.512,4.459,4.459,0,0,1-.493,2.01,4.5,4.5,0,0,0-1.971-8.552Z"
                                                                />
                                                            </g>
                                                            <g data-name="Layer 1 copy 2">
                                                                <path
                                                                    fill="#1c1c1b"
                                                                    d="M38.447,41.515H1a1,1,0,0,1-1-1V19.081a1,1,0,0,1,1-1H38.447a1,1,0,0,1,1,1V40.515A1,1,0,0,1,38.447,41.515ZM2,39.515H37.447V20.081H2Z"
                                                                />
                                                                <path
                                                                    fill="#1c1c1b"
                                                                    d="M32,38.485H7.453a1,1,0,0,1-1-1,2.01,2.01,0,0,0-2.008-2.007,1,1,0,0,1-1-1V25.119a1,1,0,0,1,1-1,2.011,2.011,0,0,0,2.008-2.008,1,1,0,0,1,1-1H32a1,1,0,0,1,1,1A2.01,2.01,0,0,0,35,24.119a1,1,0,0,1,1,1v9.359a1,1,0,0,1-1,1,2.009,2.009,0,0,0-2.007,2.007A1,1,0,0,1,32,38.485Zm-23.669-2h22.8A4.025,4.025,0,0,1,34,33.6V25.992a4.023,4.023,0,0,1-2.881-2.881H8.326a4.021,4.021,0,0,1-2.881,2.881V33.6A4.023,4.023,0,0,1,8.326,36.485Z"
                                                                />
                                                                <path
                                                                    fill="#1c1c1b"
                                                                    d="M19.724,35.31A5.512,5.512,0,1,1,25.235,29.8,5.519,5.519,0,0,1,19.724,35.31Zm0-9.023A3.512,3.512,0,1,0,23.235,29.8,3.515,3.515,0,0,0,19.724,26.287Z"
                                                                />
                                                                <path
                                                                    fill="#1c1c1b"
                                                                    d="M40.819,35.788a1,1,0,0,1-.1-1.995l1.154-.121-2.03-19.327L7.65,17.725a1,1,0,0,1-.209-1.99l33.184-3.484a1,1,0,0,1,1.1.89l2.239,21.317a1,1,0,0,1-.89,1.1l-2.149.226C40.889,35.786,40.854,35.788,40.819,35.788Z"
                                                                />
                                                                <path
                                                                    fill="#1c1c1b"
                                                                    d="M45.367,29.848a1,1,0,0,1-.188-1.982l.651-.125L42.168,8.655,10.827,14.667A1,1,0,1,1,10.45,12.7L42.774,6.5a1,1,0,0,1,1.17.793l4.038,21.051a1,1,0,0,1-.794,1.17l-1.632.313A1.024,1.024,0,0,1,45.367,29.848Z"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 4, alignItems: 'center', marginTop: 5, width: 150 }}>
                                                            <div className={'clr' + datiMonitor?.colorVendite}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Vendite</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultima vendita:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.venditeTime}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            enableBackground="new 0 0 52 52"
                                                            viewBox="0 0 52 52"
                                                        >
                                                            <path
                                                                fill="#C7D6E5"
                                                                d="M49.98908,6.18741v5.20632h-8.35954V6.18741c0-1.15691,0.46502-2.20048,1.22503-2.96042      c0.74859-0.75994,1.7921-1.22503,2.94907-1.22503C48.11752,2.00196,49.98908,3.87353,49.98908,6.18741z"
                                                            />
                                                            <path
                                                                d="M47.10188,2.20767c-0.62995,0.19999-1.19991,0.55995-1.64988,1.01993c-0.75994,0.75988-1.22987,1.79983-1.22987,2.9597      v5.20956h-2.58979V6.18729c0-1.15987,0.45998-2.19982,1.21992-2.9597c0.74994-0.75994,1.78982-1.22993,2.94969-1.22993      C46.25192,1.99766,46.69189,2.06766,47.10188,2.20767z"
                                                                opacity={0.05}
                                                            />
                                                            <path
                                                                fill="#E4F1FF"
                                                                d="M12.48689,2.00196h33.31675c-1.15697,0-2.20048,0.46509-2.94907,1.22503          c-0.76001,0.75994-1.22503,1.80352-1.22503,2.96042V44.8092c0,2.85838-2.32524,5.18361-5.18361,5.18361H7.30328V7.18557          C7.30328,4.32719,9.62851,2.00196,12.48689,2.00196z"
                                                            />
                                                            <path
                                                                d="M45.80507,1.99902c-1.15997,0-2.19995,0.46997-2.94995,1.22998c-0.76001,0.76001-1.22003,1.79999-1.22003,2.96002          v38.62c0,2.87-2.32001,5.17999-5.17999,5.17999H7.30507v-2.90997c8.67004,0,16.53003-3.51001,22.22003-9.20001          C35.2051,32.19903,38.72512,24.349,38.72512,15.669V1.99902H45.80507z"
                                                                opacity={0.05}
                                                            />
                                                            <path
                                                                fill="#D2E2F2"
                                                                d="M45.93974 2.00196v.01135c-.04535-.01135-.09076-.01135-.1361-.01135H45.93974zM2.00045 44.81405l29.34295-.0001v.01135c.00001 2.84703 2.32524 5.17226 5.17225 5.17226l-29.34295.0001c-2.8583.00001-5.17225-2.32523-5.17226-5.17225V44.81405z"
                                                            />
                                                            <path
                                                                fill="#D2E2F2"
                                                                d="M2.00045,44.81405l29.34295-0.0001v0.01135c0.00001,2.84703,2.32524,5.17226,5.17225,5.17226           l-29.34295,0.0001c-2.8583,0.00001-5.17225-2.32523-5.17226-5.17225V44.81405z"
                                                            />
                                                            <path
                                                                fill="#C7D6E5"
                                                                d="M2.00045,44.81405l29.34295-0.0001v0.01135c0.00001,2.84703,2.32524,5.17226,5.17225,5.17226      l-29.34295,0.0001c-2.8583,0.00001-5.17225-2.32523-5.17226-5.17225V44.81405z"
                                                            />
                                                            <path
                                                                d="M36.5128,49.99338H7.1751c-2.13982,0-3.98963-1.30988-4.76959-3.15969c0.61994,0.27,1.30988,0.40994,2.01984,0.40994      h27.52786C32.82313,48.8835,34.54295,49.99338,36.5128,49.99338z"
                                                                opacity={0.05}
                                                            />
                                                            <path
                                                                fill="#293C66"
                                                                d="M45.93945,1.00209h-0.13574h-0.00488h-0.00098h-0.00098H45.7959h-0.00488H12.4873     c-3.40918,0-6.18359,2.77393-6.18359,6.18359V43.8141H2c-0.55273,0-1,0.45898-1,1.01123     c0,3.40332,2.76953,6.17236,6.17285,6.17236h29.34277c0.00684,0,0.01465,0.00049,0.01953,0     c0.02203,0,0.04041-0.01111,0.06207-0.01251c3.33905-0.08124,6.03265-2.81726,6.03265-6.17596V12.39369H50     c0.55273,0,1-0.44775,1-1V6.18764C51,3.21896,48.49252,1.00209,45.93945,1.00209z M3.12012,45.8141h27.30273     c0.19592,1.21368,0.74835,2.31024,1.54492,3.17871c-2.54111,0.0005,9.19378-0.00181-24.79492,0.00488     C5.21484,48.9977,3.56738,47.64272,3.12012,45.8141z M40.62988,44.80922c0,2.30304-1.87134,4.1767-4.17303,4.18256     c-2.27307-0.03235-4.1131-1.88593-4.1131-4.17767c0-0.55225-0.44727-1-1-1H8.30371V7.18568     c0-2.30664,1.87695-4.18359,4.18359-4.18359h29.2334c-0.6284,0.80554-1.09082,2.0152-1.09082,3.18555     C40.62988,16.01445,40.62988,34.98583,40.62988,44.80922z M49,10.39369h-6.37012V6.18764c0-4.20945,6.37012-4.14612,6.37012,0     V10.39369z"
                                                            />
                                                            <g>
                                                                <path
                                                                    fill="#F6C560"
                                                                    d="M17.92496,8.80769v5.00406c0,0.28207-0.22076,0.50282-0.5029,0.50282h-5.00405      c-0.28207,0-0.50282-0.22076-0.50282-0.50282V8.80769c0-0.28207,0.22076-0.50282,0.50282-0.50282h5.00405      C17.7042,8.30487,17.92496,8.52562,17.92496,8.80769z"
                                                                />
                                                                <path
                                                                    fill="#D2E2F2"
                                                                    d="M37.01189,9.88744v2.85694c0,0.23648-0.19911,0.42321-0.43559,0.42321H20.97495       c-0.23648,0-0.42321-0.18672-0.42321-0.42321V9.88744c0-0.23648,0.18672-0.43559,0.42321-0.43559H36.5763       C36.81278,9.45185,37.01189,9.65096,37.01189,9.88744z"
                                                                />
                                                                <path
                                                                    d="M37.01765,9.89291v2.85772c0,0.23303-0.20855,0.41696-0.44159,0.41696H20.97529       c-0.23303,0-0.42931-0.18393-0.42931-0.41696v-1.05475h11.86011c1.43496,0,2.66144-0.93214,3.10295-2.2322h1.06703       C36.8091,9.46367,37.01765,9.65988,37.01765,9.89291z"
                                                                    opacity={0.05}
                                                                />
                                                                <path
                                                                    fill="#F6C560"
                                                                    d="M17.92496,17.56899v5.00406c0,0.28207-0.22076,0.50282-0.5029,0.50282h-5.00405      c-0.28207,0-0.50282-0.22076-0.50282-0.50282v-5.00406c0-0.28207,0.22076-0.50282,0.50282-0.50282h5.00405      C17.7042,17.06617,17.92496,17.28693,17.92496,17.56899z"
                                                                />
                                                                <path
                                                                    fill="#D2E2F2"
                                                                    d="M37.01189,18.64874v2.85694c0,0.23648-0.19911,0.42321-0.43559,0.42321H20.97495       c-0.23648,0-0.42321-0.18673-0.42321-0.42321v-2.85694c0-0.23648,0.18672-0.43559,0.42321-0.43559H36.5763       C36.81278,18.21315,37.01189,18.41226,37.01189,18.64874z"
                                                                />
                                                                <path
                                                                    d="M37.01765,18.65421v2.85772c0,0.23303-0.20855,0.41696-0.44159,0.41696H20.97529       c-0.23303,0-0.42931-0.18393-0.42931-0.41696v-1.05475h11.86011c1.43496,0,2.66144-0.93213,3.10295-2.2322h1.06703       C36.8091,18.22497,37.01765,18.42118,37.01765,18.65421z"
                                                                    opacity={0.05}
                                                                />
                                                                <path
                                                                    fill="#F6C560"
                                                                    d="M17.92496,35.0916v5.00406c0,0.28207-0.22076,0.50282-0.5029,0.50282h-5.00405      c-0.28207,0-0.50282-0.22076-0.50282-0.50282V35.0916c0-0.28207,0.22076-0.50282,0.50282-0.50282h5.00405      C17.7042,34.58878,17.92496,34.80953,17.92496,35.0916z"
                                                                />
                                                                <path
                                                                    fill="#D2E2F2"
                                                                    d="M37.01189,36.17134v2.85694c0,0.23648-0.19911,0.42321-0.43559,0.42321H20.97495       c-0.23648,0-0.42321-0.18673-0.42321-0.42321v-2.85694c0-0.23648,0.18672-0.43559,0.42321-0.43559H36.5763       C36.81278,35.73576,37.01189,35.93486,37.01189,36.17134z"
                                                                />
                                                                <path
                                                                    d="M37.01765,36.17682v2.85772c0,0.23303-0.20855,0.41696-0.44159,0.41696H20.97529       c-0.23303,0-0.42931-0.18393-0.42931-0.41696v-1.05475h11.86011c1.43496,0,2.66144-0.93214,3.10295-2.2322h1.06703       C36.8091,35.74758,37.01765,35.94378,37.01765,36.17682z"
                                                                    opacity={0.05}
                                                                />
                                                                <g>
                                                                    <path
                                                                        fill="#F6C560"
                                                                        d="M17.92496,26.3303v5.00406c0,0.28207-0.22076,0.50282-0.5029,0.50282h-5.00405      c-0.28207,0-0.50282-0.22076-0.50282-0.50282V26.3303c0-0.28207,0.22076-0.50282,0.50282-0.50282h5.00405      C17.7042,25.82747,17.92496,26.04823,17.92496,26.3303z"
                                                                    />
                                                                    <path
                                                                        d="M17.92238 8.80987v5c0 .27997-.22003.5-.5.5h-5c-.28998 0-.51001-.22003-.51001-.5v-.94h.29999c2.35999 0 4.26001-1.91003 4.26001-4.26001V8.30987h.95001C17.70235 8.30987 17.92238 8.52984 17.92238 8.80987zM17.92238 17.56988v5c0 .28998-.22003.50995-.5.50995h-5c-.28998 0-.51001-.21997-.51001-.50995v-.94h.29999c2.35999 0 4.26001-1.91003 4.26001-4.26001v-.29999h.95001C17.70235 17.06988 17.92238 17.28985 17.92238 17.56988zM17.92238 35.08984v5.01001c0 .28003-.22003.5-.5.5h-5c-.28998 0-.51001-.21997-.51001-.5v-.95001h.29999c2.35999 0 4.26001-1.90997 4.26001-4.26001v-.29999h.95001C17.70235 34.58984 17.92238 34.80987 17.92238 35.08984zM17.92238 26.32983v5c0 .29004-.22003.51001-.5.51001h-5c-.28998 0-.51001-.21997-.51001-.51001v-.94h.29999c2.35999 0 4.26001-1.90997 4.26001-4.25995v-.30005h.95001C17.70235 25.82983 17.92238 26.04986 17.92238 26.32983z"
                                                                        opacity={0.05}
                                                                    />
                                                                    <path
                                                                        fill="#D2E2F2"
                                                                        d="M37.01189,27.41004v2.85694c0,0.23648-0.19911,0.42321-0.43559,0.42321H20.97495       c-0.23648,0-0.42321-0.18673-0.42321-0.42321v-2.85694c0-0.23648,0.18672-0.43559,0.42321-0.43559H36.5763       C36.81278,26.97445,37.01189,27.17356,37.01189,27.41004z"
                                                                    />
                                                                    <path
                                                                        d="M37.01765,27.41551v2.85772c0,0.23303-0.20855,0.41696-0.44159,0.41696H20.97529       c-0.23303,0-0.42931-0.18393-0.42931-0.41696v-1.05475h11.86011c1.43496,0,2.66144-0.93213,3.10295-2.2322h1.06703       C36.8091,26.98628,37.01765,27.18248,37.01765,27.41551z"
                                                                        opacity={0.05}
                                                                    />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', margin: 5, width: 150 }}>
                                                            <div className={'clr' + datiMonitor?.colorOrdini}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Ordini</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultimo ordine:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.ordiniTime}</strong>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            data-name="Layer 1"
                                                            viewBox="0 0 68 68"
                                                        >
                                                            <path fill="#f6c358" d="M34 20.5v8l-31-6v-8l31 6z" />
                                                            <path d="M34 29.5h-.19l-31-6a1 1 0 0 1-.81-1v-8a1 1 0 0 1 1.19-1l31 6a1 1 0 0 1 .81 1v8a1 1 0 0 1-1 1zM4 21.67l29 5.61v-6L4 15.71z" />
                                                            <path fill="#fcd462" d="M34 28.5v31l-31-6v-31l31 6z" />
                                                            <path d="M34 60.5h-.19l-31-6a1 1 0 0 1-.81-1v-31a1 1 0 0 1 1.19-1l31 6a1 1 0 0 1 .81 1v31a1 1 0 0 1-1 1zM4 52.67l29 5.61v-29L4 23.71z" />
                                                            <path fill="#f6c358" d="M65 14.5v8l-31 6v-8l31-6z" />
                                                            <path d="M34 29.5a1 1 0 0 1-1-1v-8a1 1 0 0 1 .81-1l31-6a1 1 0 0 1 1.19 1v8a1 1 0 0 1-.81 1l-31 6zm1-8.17v6l29-5.61v-6z" />
                                                            <path fill="#fcd462" d="M65 22.5v31l-31 6v-31l31-6z" />
                                                            <path d="M34 60.5a1 1 0 0 1-1-1v-31a1 1 0 0 1 .81-1l31-6a1 1 0 0 1 1.19 1v31a1 1 0 0 1-.81 1l-31 6zm1-31.17v29l29-5.61v-29z" />
                                                            <path fill="#fcd462" d="M65 14.5l-31 6-31-6 31-6 31 6z" />
                                                            <path d="M34 21.5h-.19l-31-6a1 1 0 0 1 0-2l31-6a1 1 0 0 1 .38 0l31 6a1 1 0 0 1 0 2l-31 6zm-25.74-7l25.74 5 25.74-5L34 9.52z" />
                                                            <path fill="#fcd462" d="M49.5 17.5l-30.68-6.05" />
                                                            <path d="M49.5 18.5h-.19l-30.69-6.07a1 1 0 1 1 .39-2l30.68 6.05a1 1 0 0 1-.19 2z" />
                                                            <path fill="#fcd462" d="M49.5 17.5v11" />
                                                            <path d="M49.5 29.5a1 1 0 0 1-1-1v-11a1 1 0 0 1 2 0v11a1 1 0 0 1-1 1z" />
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', margin: 5, width: 150 }}>
                                                            <div className={'clr' + datiMonitor?.colorMerci}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Carico Merci</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultimo carico:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.caricoMerciTime}</strong>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <hr
                                                style={{ borderTop: "1px solid lightgrey", width: 600, paddingTop: 20 }}
                                            ></hr>
                                        </div>

                                        <div className="row">
                                            <h6 style={{ marginLeft: 80 }}>DATI SISTEMA</h6>
                                        </div>
                                        <div className="row">

                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            viewBox="0 0 50 50"
                                                        >
                                                            <g data-name="Lineal Color">
                                                                <path
                                                                    fill="#e8ebf7"
                                                                    d="M5,11H45a0,0,0,0,1,0,0V45a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V11A0,0,0,0,1,5,11Z"
                                                                />
                                                                <circle cx={13} cy={19} r={4} fill="#f5c265" />
                                                                <path fill="#d38d78" d="M29,39a4,4,0,1,1-4-4,4,4,0,0,1,4,4" />
                                                                <rect width={8} height={8} x={9} y={35} fill="#f47979" rx={2} />
                                                                <rect
                                                                    width={7.071}
                                                                    height={7.071}
                                                                    x={21.464}
                                                                    y={15.464}
                                                                    fill="#5999c1"
                                                                    rx={1}
                                                                    transform="rotate(45 25 19)"
                                                                />
                                                                <rect
                                                                    width={7.071}
                                                                    height={7.071}
                                                                    x={33.172}
                                                                    y={35.464}
                                                                    fill="#f5c265"
                                                                    rx={1}
                                                                    transform="rotate(45 36.707 39)"
                                                                />
                                                                <path
                                                                    fill="#c9ccf6"
                                                                    d="M7,3H43a2,2,0,0,1,2,2v6a0,0,0,0,1,0,0H5a0,0,0,0,1,0,0V5A2,2,0,0,1,7,3Z"
                                                                />
                                                                <path d="M43,2H7A3.00328,3.00328,0,0,0,4,5V45a3.00328,3.00328,0,0,0,3,3H43a3.00328,3.00328,0,0,0,3-3V5A3.00328,3.00328,0,0,0,43,2Zm1,3v5H40.6182l-3-6H43A1.00094,1.00094,0,0,1,44,5Zm-9.3818,5-3-6h3.7636l3,6ZM7,4H29.3818l3,6H6V5A1.00094,1.00094,0,0,1,7,4ZM43,46H7a1.00094,1.00094,0,0,1-1-1V12H44V45A1.00094,1.00094,0,0,1,43,46Z" />
                                                                <path d="M9 8h2a1 1 0 000-2H9A1 1 0 009 8zM15 8h2a1 1 0 000-2H15a1 1 0 000 2zM21 8h2a1 1 0 000-2H21a1 1 0 000 2zM38.1211 34a2.001 2.001 0 00-2.8281 0l-3.586 3.5859A1.9766 1.9766 0 0031.3981 38H29.8989A5.01424 5.01424 0 0026 34.1011V24.3089A1.97055 1.97055 0 0026.4141 24L30 20.4141a2.00108 2.00108 0 000-2.8282L26.4141 14a2.00108 2.00108 0 00-2.8282 0L20 17.5859A1.9771 1.9771 0 0019.691 18H17.8989A5 5 0 1012 23.8989V34H11a3.00328 3.00328 0 00-3 3v4a3.00328 3.00328 0 003 3h4a3.00328 3.00328 0 003-3V37a3.00328 3.00328 0 00-3-3H14V23.8989A5.01424 5.01424 0 0017.8989 20H19.691a1.9771 1.9771 0 00.309.4141L23.5859 24A1.97055 1.97055 0 0024 24.3089v9.7922A5 5 0 1029.8989 40h1.4992a1.9766 1.9766 0 00.3089.4141L35.293 44a2.00151 2.00151 0 002.8281 0l3.5859-3.5859a2.00108 2.00108 0 000-2.8282zM16 37v4a1.00094 1.00094 0 01-1 1H11a1.00094 1.00094 0 01-1-1V37a1.00094 1.00094 0 011-1h4A1.00094 1.00094 0 0116 37zM13 22a3 3 0 113-3A3.00328 3.00328 0 0113 22zm8.4141-3L25 15.4141 28.5859 19 25.001 22.5869zM25 42a3 3 0 113-3A3.00328 3.00328 0 0125 42zm11.708.5869L33.1211 39l3.5859-3.5859L40.293 39zM35 17h6a1 1 0 000-2H35a1 1 0 000 2zM35 21h6a1 1 0 000-2H35a1 1 0 000 2zM41 23H37a1 1 0 000 2h4a1 1 0 000-2z" />
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', margin: 5, width: 150 }}>
                                                            <div className={'clr' + datiMonitor?.colorGestionale}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Sistema F</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultimo aggiornamento:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.gestionaleInfo}</strong>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10, marginLeft: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            data-name="Layer 1"
                                                            viewBox="0 0 512 512"

                                                        >
                                                            <g data-name="&lt;Group&gt;">
                                                                <g data-name="&lt;Group&gt;">
                                                                    <path
                                                                        fill="#fedb41"
                                                                        d="M271.92,163.99a82.208,82.208,0,0,1-6.08-17.84l-22.82-2.21V113.23l22.82-2.2a85.038,85.038,0,0,1,11.82-27.98L263.1,65.49l6.31-6.29,15.4-15.37,17.56,14.56a81.827,81.827,0,0,1,28.02-11.78l2.2-22.77h30.76l2.2,22.77a84.218,84.218,0,0,1,28.02,11.78l17.56-14.56,21.45,21.66L418.02,83.05a81.962,81.962,0,0,1,11.82,27.98l22.81,2.2v30.71l-22.54,2.21a84.92,84.92,0,0,1-11.83,27.97l14.56,17.56-21.71,21.67-17.56-14.56a81.837,81.837,0,0,1-23.61,10.7q-2.19.6-4.41,1.08l-2.2,22.77H332.59l-2.2-22.77a84.3,84.3,0,0,1-28.02-11.78l-17.56,14.56L263.1,191.68l14.56-17.56A83.071,83.071,0,0,1,271.92,163.99Zm130.97-35.4a55.03,55.03,0,1,0-55.03,54.95A54.99,54.99,0,0,0,402.89,128.59Z"
                                                                        data-name="&lt;Group&gt;"
                                                                    />
                                                                    <ellipse
                                                                        cx={347.86}
                                                                        cy={128.59}
                                                                        fill="#00efd1"
                                                                        data-name="&lt;Group&gt;"
                                                                        rx={55.03}
                                                                        ry={54.95}
                                                                    />
                                                                    <path
                                                                        fill="#00efd1"
                                                                        d="M369.96,324.72V431.95c0,21.56-33.47,40.26-82.63,49.69a389.167,389.167,0,0,1-72.68,6.52c-85.79,0-155.3-25.14-155.3-56.21V324.72c0,31.06,69.51,56.2,155.3,56.2S369.96,355.78,369.96,324.72Z"
                                                                        data-name="&lt;Group&gt;"
                                                                    />
                                                                    <path
                                                                        fill="#00acea"
                                                                        d="M369.96,322.5v2.22c0,31.06-69.52,56.2-155.31,56.2s-155.3-25.14-155.3-56.2V218.22c0,31.06,69.51,56.21,155.3,56.21,71.04,0,130.93-17.25,149.42-40.82,3.84-4.89,5.89-10.05,5.89-15.39Z"
                                                                        data-name="&lt;Group&gt;"
                                                                    />
                                                                    <path
                                                                        fill="#00efd1"
                                                                        d="M363.35,233.34l.72.27c-18.49,23.57-78.38,40.82-149.42,40.82-85.79,0-155.3-25.15-155.3-56.21V111.72c0,31.05,69.53,56.21,155.3,56.21a406.13,406.13,0,0,0,57.27-3.94,83.071,83.071,0,0,0,5.74,10.13L263.1,191.68l21.71,21.67,17.56-14.56a84.3,84.3,0,0,0,28.02,11.78l2.2,22.77Z"
                                                                        data-name="&lt;Group&gt;"
                                                                    />
                                                                    <path
                                                                        fill="#00acea"
                                                                        d="M263.1,65.49l14.56,17.56a85.038,85.038,0,0,0-11.82,27.98l-22.82,2.2v30.71l22.82,2.21a82.208,82.208,0,0,0,6.08,17.84,406.13,406.13,0,0,1-57.27,3.94c-85.77,0-155.3-25.16-155.3-56.21s69.53-56.2,155.3-56.2a406.47,406.47,0,0,1,54.66,3.58l.1.1Z"
                                                                        data-name="&lt;Group&gt;"
                                                                    />
                                                                </g>
                                                                <g data-name="&lt;Group&gt;">
                                                                    <path
                                                                        fill="#f4b844"
                                                                        d="M241 213H189a7 7 0 0 0 0 14h52a7 7 0 0 0 0-14zM241 319H189a7 7 0 0 0 0 14h52a7 7 0 0 0 0-14zM241 427H189a7 7 0 0 0 0 14h52a7 7 0 0 0 0-14z"
                                                                        data-name="&lt;Path&gt;"
                                                                    />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', margin: 5, width: 160 }}>
                                                            <div className={'clr' + datiMonitor?.colorBancaDati}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Banca Dati</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultimo aggiornamento:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.bancaDatiInfo}</strong>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row">
                                            <div className="form-group col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                                                <div style={{ justifyContent: 'center', marginTop: 5, display: 'flex', gap: 10 }}>
                                                    <div style={{ display: 'flex', height: 50 }}>
                                                        <svg
                                                            width="100%"
                                                            height="100%"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                                            fillRule="evenodd"
                                                            strokeLinecap="round"
                                                            strokeMiterlimit={10}
                                                            clipRule="evenodd"
                                                            viewBox="0 0 411 402"
                                                        >
                                                            <rect width={409.887} height={400.738} x={0.561} y={0.919} fill="none" />
                                                            <ellipse
                                                                cx={194.723}
                                                                cy={314.768}
                                                                fill="#2f94fb"
                                                                fillOpacity={0.329}
                                                                rx={148}
                                                                ry={34.5}
                                                            />
                                                            <path
                                                                fill="url(#_Linear1)"
                                                                d="M352.723,295.268l-0.159,0l0.159,1.5c0,20.71 -42.517,42.5 -157.5,42.5c-106.964,0 -157.5,-21.79 -157.5,-42.5l0.16,-1.5l-0.16,0l0,-60l315,0l0,60Z"
                                                            />
                                                            <path
                                                                fill="#f0f7ff"
                                                                d="M195.223,197.268c86.985,0 157.5,16.789 157.5,37.5c0,20.71 -41.306,42.5 -157.5,42.5c-117.818,0 -157.5,-21.79 -157.5,-42.5c0,-20.711 70.516,-37.5 157.5,-37.5Z"
                                                            />
                                                            <ellipse cx={194.723} cy={235.268} fill="#91d4ff" rx={148} ry={35} />
                                                            <ellipse
                                                                cx={194.723}
                                                                cy={225.268}
                                                                fill="#2f94fb"
                                                                fillOpacity={0.988}
                                                                rx={148}
                                                                ry={35}
                                                            />
                                                            <path
                                                                fill="url(#_Linear2)"
                                                                d="M352.723,207.268l-0.159,0l0.159,1.5c0,20.71 -42.517,42.5 -157.5,42.5c-106.964,0 -157.5,-21.79 -157.5,-42.5l0.16,-1.5l-0.16,0l0,-60l315,0l0,60Z"
                                                            />
                                                            <path
                                                                fill="#f0f7ff"
                                                                d="M195.223,110.268c86.985,0 157.5,16.789 157.5,37.5c0,20.71 -41.306,42.5 -157.5,42.5c-117.818,0 -157.5,-21.79 -157.5,-42.5c0,-20.711 70.516,-37.5 157.5,-37.5Z"
                                                            />
                                                            <ellipse cx={194.723} cy={147.268} fill="url(#_Radial3)" rx={148} ry={35} />
                                                            <ellipse
                                                                cx={194.723}
                                                                cy={134.268}
                                                                fill="#2f94fb"
                                                                fillOpacity={0.812}
                                                                rx={148}
                                                                ry={35}
                                                            />
                                                            <path
                                                                fill="url(#_Linear4)"
                                                                d="M352.723,116.268l-0.159,0l0.159,1.5c0,20.71 -42.517,42.5 -157.5,42.5c-106.964,0 -157.5,-21.79 -157.5,-42.5l0.16,-1.5l-0.16,0l0,-60l315,0l0,60Z"
                                                            />
                                                            <path
                                                                fill="#f0f7ff"
                                                                d="M195.223,19.268c86.985,0 157.5,16.789 157.5,37.5c0,20.71 -41.306,42.5 -157.5,42.5c-117.818,0 -157.5,-21.79 -157.5,-42.5c0,-20.711 70.516,-37.5 157.5,-37.5Z"
                                                            />
                                                            <ellipse cx={194.723} cy={56.268} fill="url(#_Linear5)" rx={142} ry={31} />
                                                            <circle cx={302.723} cy={296.268} r={67} fill="url(#_Radial6)" />
                                                            <path
                                                                fill="url(#_Linear7)"
                                                                d="M302.723,211.268c46.913,0 85,38.087 85,85c0,46.913 -38.087,85 -85,85c-46.912,0 -85,-38.087 -85,-85c0,-46.913 38.088,-85 85,-85Zm0,19c36.427,0 66,29.573 66,66c0,36.426 -29.573,66 -66,66c-36.426,0 -66,-29.574 -66,-66c0,-36.427 29.574,-66 66,-66Z"
                                                            />
                                                            <path
                                                                fill="#fff"
                                                                fillRule="nonzero"
                                                                d="M303.48 218.6c2.281.333 2.305 1.352 2.41 2.399.347 3.451-6.072 2.854-11.072 3.428-23.395 2.69-44.922 17.41-56.068 38.456 0 0-.679 1.137-1.835 1.372-2.205.447-4.026-1.808-2.439-4.746 13.381-24.494 40.044-40.737 68.396-40.92 0 0 .301-.003.608.011zM228.132 289.178c.524.067.607.118.828.215 2.291 1.008 1.399 4.963 1.452 8.486.108 7.315 1.328 14.592 3.587 21.555 0 0 .231 2.674-1.64 3.348-3.252 1.172-4.609-4.7-5.778-10.513-1.341-6.667-1.797-13.549-1.394-20.278.04-.661.562-2.818 2.773-2.823.057.003.115.007.172.01z"
                                                            />
                                                            <path
                                                                fill="none"
                                                                stroke="#cce6ff"
                                                                strokeWidth={5.36}
                                                                d="M352.082 321.532c-10.151 19.069-30.376 32.07-53.67 32.07M356.042 274.946c1.943 5.875 2.993 12.149 2.993 18.666 0 1.314-.043 2.618-.127 3.911"
                                                            />
                                                            <path
                                                                fill="#17bc00"
                                                                d="M307.723,248.268c0,-3.864 -3.136,-7 -7,-7c-3.863,0 -7,3.136 -7,7l0,52c0,3.863 3.137,7 7,7c3.864,0 7,-3.137 7,-7l0,-52Z"
                                                            />
                                                            <path
                                                                fill="#0bbc00"
                                                                d="M340.723,300.268c0,-3.864 -3.136,-7 -7,-7l-33,0c-3.863,0 -7,3.136 -7,7c0,3.863 3.137,7 7,7l33,0c3.864,0 7,-3.137 7,-7Z"
                                                            />
                                                            <defs>
                                                                <linearGradient
                                                                    id="_Linear1"
                                                                    x2={1}
                                                                    gradientTransform="translate(39.521 278.316) scale(312.846)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#98d7ff" />
                                                                    <stop offset={0.11} stopColor="#d2e9ff" />
                                                                    <stop offset={0.37} stopColor="#e8f4ff" />
                                                                    <stop offset={0.44} stopColor="#d4e9ff" />
                                                                    <stop offset={0.68} stopColor="#cce6ff" />
                                                                    <stop offset={0.84} stopColor="#a5d5ff" />
                                                                    <stop offset={1} stopColor="#4cb9ff" />
                                                                </linearGradient>
                                                                <linearGradient
                                                                    id="_Linear2"
                                                                    x2={1}
                                                                    gradientTransform="translate(39.521 190.316) scale(312.846)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#98d7ff" />
                                                                    <stop offset={0.11} stopColor="#d2e9ff" />
                                                                    <stop offset={0.37} stopColor="#e8f4ff" />
                                                                    <stop offset={0.44} stopColor="#d4e9ff" />
                                                                    <stop offset={0.68} stopColor="#cce6ff" />
                                                                    <stop offset={0.84} stopColor="#a5d5ff" />
                                                                    <stop offset={1} stopColor="#4cb9ff" />
                                                                </linearGradient>
                                                                <linearGradient
                                                                    id="_Linear4"
                                                                    x2={1}
                                                                    gradientTransform="translate(39.521 99.316) scale(312.846)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#98d7ff" />
                                                                    <stop offset={0.11} stopColor="#d2e9ff" />
                                                                    <stop offset={0.37} stopColor="#e8f4ff" />
                                                                    <stop offset={0.44} stopColor="#d4e9ff" />
                                                                    <stop offset={0.68} stopColor="#cce6ff" />
                                                                    <stop offset={0.84} stopColor="#a5d5ff" />
                                                                    <stop offset={1} stopColor="#4cb9ff" />
                                                                </linearGradient>
                                                                <linearGradient
                                                                    id="_Linear5"
                                                                    x2={1}
                                                                    gradientTransform="rotate(90 90.805 119.081) scale(58.191)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#cae5ff" />
                                                                    <stop offset={1} stopColor="#adf" />
                                                                </linearGradient>
                                                                <linearGradient
                                                                    id="_Linear7"
                                                                    x2={1}
                                                                    gradientTransform="rotate(90 31.094 341.935) scale(113.664)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#2684fb" />
                                                                    <stop offset={1} stopColor="#0563c5" />
                                                                </linearGradient>
                                                                <radialGradient
                                                                    id="_Radial3"
                                                                    cx={0}
                                                                    cy={0}
                                                                    r={1}
                                                                    gradientTransform="translate(180.814 217.87) scale(287.112)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#97d7ff" stopOpacity={0.988} />
                                                                    <stop offset={0.47} stopColor="#6ec6ff" stopOpacity={0.988} />
                                                                    <stop offset={1} stopColor="#0097ff" stopOpacity={0.988} />
                                                                </radialGradient>
                                                                <radialGradient
                                                                    id="_Radial6"
                                                                    cx={0}
                                                                    cy={0}
                                                                    r={1}
                                                                    gradientTransform="translate(303.066 294.26) scale(79.3329)"
                                                                    gradientUnits="userSpaceOnUse"
                                                                >
                                                                    <stop offset={0} stopColor="#fff" />
                                                                    <stop offset={0.55} stopColor="#f5f9fe" />
                                                                    <stop offset={1} stopColor="#d0e4fc" />
                                                                </radialGradient>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <div>
                                                        <div style={{ display: 'flex', gap: 8, alignItems: 'center', margin: 5, width: 150 }}>
                                                            <div className={'clr' + datiMonitor?.colorBackup}></div>
                                                            <h5 style={{ margin: 0 }} className='font-size:6vw'>Backup</h5>
                                                        </div>
                                                        <p style={{ marginTop: 20, marginBottom: 0 }}>Ultimo:</p>
                                                        <strong style={{ marginTop: 0, marginBottom: 0 }}>{datiMonitor.backupTime}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                                {props.flagMyCsf === false &&

                                    <div>
                                        <div className="row">
                                            <div className="col-12 col-md-4" style={{ textAlign: 'end', marginTop: 5 }} >
                                                <a href="/app/monitoraggio/farmacie" style={{ fontStyle: 'italic' }}>Vai al monitoraggio</a>
                                            </div>

                                        </div>
                                        <div className="row" style={{ marginTop: 25, display: "flex" }} >
                                            <hr
                                                style={{ borderTop: "1px solid lightgrey", width: 1024, paddingTop: 20 }}
                                            ></hr>
                                        </div>


                                        <div style={{ width: "auto" }}>
                                            {vendite ? (
                                                <div>
                                                    <Chart id="chart"
                                                        title="Andamento Valore del venduto netto sconto - ultimi 12 mesi"
                                                        dataSource={vendite}

                                                    //onPointClick={this.onPointClick}
                                                    >
                                                        <CommonSeriesSettings
                                                            argumentField="mese"
                                                            type="bar"
                                                            selectionMode="allArgumentPoints"
                                                            barPadding={0.2}
                                                        >

                                                            <Label visible={true} >
                                                                <Format type='currency' currency='EUR' precision={0} />
                                                            </Label>

                                                        </CommonSeriesSettings>

                                                        <Series
                                                            valueField="before"
                                                            name="2022/2023"
                                                            color="rgb(215, 140, 245)"
                                                            type="line" >

                                                        <Label visible={true} verticalOffset={-20}></Label>
                                                        </Series>

                                                        <Series
                                                            argumentField="mese"
                                                            valueField="now"
                                                            name="2023/2024"
                                                            color="rgb(25, 231, 255)">

                                                            <Label visible={true} verticalOffset={80}> </Label>

                                                        </Series>


                                                        <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                                                        <Export enabled={true} />
                                                    </Chart>

                                                </div>
                                            ) : null}
                                        </div>


                                        <div className="col-12 col-md-12" style={{ textAlign: 'right' }}>
                                            <a href="/app/businesslab/lite/vendite" style={{ fontStyle: 'italic' }}>Vai a BusinessLab Lite</a>
                                        </div>
                                        <div style={{ width: "auto" }}>
                                            {vendite ? (
                                                <Chart id="chartNew"
                                                    title="Andamento Costo d'acquisto - ultimi 12 mesi rolling"
                                                    dataSource={acquisti}
                                                //onPointClick={this.onPointClick}
                                                >

                                                    <CommonSeriesSettings
                                                        argumentField="mese"
                                                        type="bar"
                                                        selectionMode="allArgumentPoints"
                                                        barPadding={0.2}

                                                    >

                                                        <Label visible={true}>
                                                            <Format type='currency' currency='EUR' precision={0} />

                                                        </Label>

                                                    </CommonSeriesSettings>

                                                    <Series
                                                        valueField="before"
                                                        name="2022/2023"
                                                        color="rgb(215, 140, 245)"
                                                        type="line">

                                                    <Label visible={true} verticalOffset={20}> </Label>

                                                    </Series>

                                                    <Series
                                                        argumentField="mese"
                                                        valueField="now"
                                                        name="2023/2024"
                                                        color="rgb(25, 231, 255)"
                                                    >

                                                        <Label visible={true} verticalOffset={80}> </Label>


                                                    </Series>



                                                    <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                                                    <Export enabled={true} />
                                                </Chart>
                                            ) : null}

                                        </div>
                                        <div className="col-12 col-md-12" style={{ textAlign: 'right' }}>
                                            <a href="/app/businesslab/lite/acquisti" style={{ fontStyle: 'italic' }}>Vai a BusinessLab Lite</a>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                )}

            </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "flex-start", marginTop: 170 }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={isNewExpanded ? 90 : 270}
                    onClick={() => setIsNewExpanded(!isNewExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>

        <section style={{
            opacity: isNewExpanded ? 1 : 0, width: isNewExpanded ? 'auto' : 10, marginLeft: 1, transition: 'transform 0.6s cubic-bezier(0.25, 0.1, 0.25, 1), opacity 0.6s ease-in-out', marginTop: 160 }}>
                <div className="col-md-14">
                    <div style={{ paddingRight: 400 }}></div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/76"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/143"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/146"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/150"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/154"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/157"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/161"}></FrameWrapper>
                    </div>

                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                        <FrameWrapper html={"https://news.csfsistemi.it/archives/164"}></FrameWrapper>
                    </div>
                </div>
        </section>
    </section>
    );
};

export default HomePage