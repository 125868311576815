import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../../dashboards/filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { boxcardAnaVendSottMargine } from "./Boxcards/AnalOperativo";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { testApi } from "../util/testRisp";
import { columnsAnalVendiSottMargine } from "./Colums/ColAnalOperativo";
import { MenuCruscottoOperativo } from "../components/menu_operativo";
import { createItemFilterAnalVendSottMargOper } from "./ItemsFiltri/FiltriAnalOperativo";
import { AplicaValoreTO, getDayName } from "../../../funzioni/operazioniSuTabelle";
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from "../../costanti";
import { CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Connector, Title, Export, Font, Series, Format, Label, HoverStyle, Size, SmallValuesGrouping, Tooltip } from "devextreme-react/pie-chart";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";

import "./style.css";

const dashboard17 = { dashboardID: 17 };

export interface boxCard {
    testoPop?: string;
}

export interface boxsecond {
    testoPop?: string;
}

const AnalisiVendiSottMargine = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [tabella, setTabella] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(
        boxcardAnaVendSottMargine
    );
    const [pie, setPie] = useState<any[]>([]);
    const [progetto, setProgetto] = useState<any[]>([]);
    const [societa, setSocieta] = useState<any[]>([]);
    const [operatori, setOperatori] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);

    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale è mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];
        let newIDSettore: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let venditaDPC = params?.venditaDPC ? [] : [0];
        let flagBusta = params?.flagBusta ? [] : [0];

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al; //aggiornaDataFine(params.periodo.al);
        }


        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            societa: params.societaNewV,
            operatori: params.operatori,
            venditaDPC: venditaDPC,
            flagBusta: flagBusta,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall17 = {
                ...dashboard17,
                //idFarList: [9671, 5809, 9217, 7394],
                idFarList: params.idFar,
                params: parameters,
            };

            RestServices()
                .rest("POST", dashboardCall17, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {
                        // box righe vend sott margine
                        let fixedSottmarg = [
                            {
                                testo: "Righe di vendita con margine inferiore",
                                subTesto: "% sul Totale righe di vendita",
                                valore: result["78"].reduce(
                                    (acc: number, prodotto: any) =>
                                        acc + prodotto["NUMERO_RIGHE_SOTTO_MARGINE"],
                                    0
                                ),
                                subValore: (result["78"].reduce(
                                    (acc: number, prodotto: any) =>
                                        acc + prodotto["NUMERO_RIGHE_TOTALE_PERCENTUALE"],
                                    0
                                ) / 100).toLocaleString('default', {
                                    style: 'percent',
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                }),
                                starter: true,
                                testoPop: "Sotto Margine",
                            },
                        ];

                        console.log("somma");
                        console.log(fixedSottmarg);

                        setFila1cards(fixedSottmarg);

                        setPie(result["78"]);

                        // tabella
                        setTabella(result["78"]);

                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);
                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            //cambio data
            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1° gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };

    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };
    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    let messOne = ["numero vendite sotto margine"];
    let messTwo = ["Analisi CìVed 222"];
    let messThree = ["Analisi CìVed 3333"];

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const filters = () => {
        if (auth) {
            RestServices()
                .rest("POST", "", urlBancaDati.getSettore)
                .then((result) => {
                    if (result) setSettori(result);
                });

            RestServices()
                .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
                .then((result) => {
                    if (result.esito) {
                        const operatori = result.response.map(
                            (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                                //console.log(ope.idFarmacia);
                                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                                //console.log(farmaciaCorrispondente);
                                return {
                                    ...ope,
                                    id: `${ope.operatore}`,
                                    descrizione: `${ope.operatore}`,
                                    codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                                };
                            }
                        );
                        setOperatori(operatori);
                        // console.log('operatori');
                        // console.log(operatori);
                    }
                });
        }
    };

    useEffect(() => {
        filters();
    }, [auth]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };

    function formatLabel(arg: { argumentText: any; valueText: any }) {
        return `${arg.argumentText}: ${arg.valueText}%`;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const itemsFields = createItemFilterAnalVendSottMargOper(
        newFarmaList,
        settori,
        operatori,
    );

    const popVisible = (testo: string) => {
        setVisiblePop(true);
        setTestoPop(testo);
    };

    return (
        <section style={{ display: "flex", height: "98vh" }}>
            {loadPanel}
            {loadinCambio ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                </div>
            ) : (
                <section
                    id="scrollerelement"
                    style={{ width: "100%", overflow: "scroll" }}
                >
                    <div
                        style={{
                            fontSize: "15px",
                            alignItems: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "rgb(80, 84, 92)",
                        }}
                    >
                     <h2>BusinessLab Evolution - Cruscotto Operativo</h2>
                    </div>

                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                    >
                      <div>
                          <MenuCruscottoOperativo />
                      </div>
                    </div>

                    <Popup
                        visible={visiblePop}
                        showCloseButton={true}
                        //onHiding={() => doSomething()}
                        width={"40%"}
                        height={"60%"}
                        showTitle={true}
                        title="Dettagli widget"
                    >
                        <div>
                            <p>{testoPop}</p>
                        </div>
                    </Popup>

                    <div>
                        <div style={{ width: "100%", marginBottom: 20 }}>

                        <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 17, marginTop: 10 }}>Tabella riepilogativa vendite sotto margine</h6>

                            <NewTabella
                                height={"50vh"}
                                idTabella="AnalVendSotMargOper"
                                sync
                                colonne={columnsAnalVendiSottMargine}
                                dataSource={tabella}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "VenditeSottoMargine",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                        <TotalItem column="OMAGGI" summaryType="sum" />
                                        <TotalItem
                                            column="COSTO"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                    </Summary>
                                }
                            />
                        </div>

                        <div style={{ marginTop: 5, paddingBottom: 15 }} className="grid-sot-2-margi">
                            <div
                                style={{
                                    display: "flex",
                                    backgroundColor: "white",
                                    width: "100%",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <PieChart
                                    id="pie"
                                    dataSource={pie}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                                >


                                    <Series argumentField="NUMERO_RIGHE_SOTTO_MARGINE"
                                        valueField="NUMERO_RIGHE_TOTALE">
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>

                                    {
                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                argument,
                                                value,
                                                percentText,
                                            }: {
                                                argument: any;
                                                value: any;
                                                percentText: any;
                                            }) => {
                                                console.log("vediamo value");
                                                console.log(percentText);

                                                const dataItem = pie.find(
                                                    (item: any) => item.NUMERO_RIGHE_SOTTO_MARGINE === argument
                                                );
                                                const dataSourceValue = dataItem
                                                    ? dataItem.NUMERO_RIGHE_TOTALE
                                                    : 0;
                                                //const formattedValue = new Intl.NumberFormat().format(dataSourceValue);

                                                return {
                                                    html: `<p>Righe di vendita con margine inferiore alla soglia indicata - ${argument} / ${percentText}</p>`,
                                                };
                                            }}
                                        />
                                    }
                                    <Title text="Numero vendite sotto margine per operatore">
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                        <Export fileName="Numero vendite sotto margine per operatore" enabled={true} />
                                </PieChart>
                                <div style={{ marginBottom: "360px" }}>
                                    <button
                                        className="ibutton-lite-two"
                                        style={{ border: "none" }}
                                        onClick={() => popVisible1("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                                </div>
                            </div>

                            <div style={{ height: "100%" }}>
                                {fila1cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </section>
            )}

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section
                style={{
                    background: "#fff",
                    opacity: isFiltersExpanded ? 1 : 0,
                    width: isFiltersExpanded ? "auto" : 10,
                }}
            >
                <div style={{}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
                </div>
            </section>
            <Popup
                visible={visiblePop1}
                showCloseButton={true}
                onHiding={() => setVisiblePop1(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop3}
                showCloseButton={true}
                onHiding={() => setVisiblePop3(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
        </section>
    );
};

export default AnalisiVendiSottMargine;
