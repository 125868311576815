import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { RadioGroup } from 'devextreme-react/radio-group'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import NewTabella from "../../../widget/nuova_tabella"
import { AplicaValoreTO } from '../../../funzioni/operazioniSuTabelle'
import {  Loading } from '../../../widget/Notifications'
import { faTachometerAlt, faTh, faBalanceScale, faCloud, faChartLine, faSitemap, faPaste, faFolderOpen, faCheck, faPoll, faTruck   } from '@fortawesome/free-solid-svg-icons';
import { legendByarray } from '../../../funzioni/formatoVariabili'


import "../style.css"

interface DialogProps {
  open: boolean,
  edit: any,
  ricarica: () => void,
  close: ()=>void,
}

const objMesi:any = {
  1:'Gen',
  2:'Feb',
  3:'Mar',
  4:'Apr',
  5:'Mag',
  6:'Giu',
  7:'Lug',
  8:'Ago',
  9:'Set',
  10:'Ott',
  11:'Nov',
  12:'Dic',
}

const objMesiInv:any = {
  12:'Dic',
  11:'Nov',
  10:'Ott',
  9:'Set',
  8:'Ago',
  7:'Lug',
  6:'Giu',
  5:'Mag',
  4:'Apr',
  3:'Mar',
  2:'Feb',
  1:'Gen',
}

function magic(value:string){
  //alert(value)
  let booo = Object.keys(objMesi).find(key => objMesi[key] === value);
  return booo
}
function oggetInv() {
  let index = new Date().getMonth()+1
  var prima = new Array;
  var dopo = new Array;
  var total = new Array;



  
  for (const [key, value] of Object.entries(objMesiInv)) {
    if(Number(key) <= index){
      prima.push(value);
    }else{
      dopo.push(value);
    }

  }

  prima.reverse()
  dopo.reverse()
  total = [...prima,...dopo]
  
  
  total.splice(0, 0, "Anno");
  total[total.length] = 'Totale'

  console.log(Object.assign({}, total));
  
  
  return Object.assign({}, total);
}

function reverseArr(input:any) {
  let index = new Date().getMonth()+1
  var prima = new Array;
  var dopo = new Array;
  var total = new Array;



  
  for (const [key, value] of Object.entries(objMesiInv)) {
    //console.log(`${key}: ${value}`);
    //console.log(value);
    if(Number(key) <= index){
      prima.push(value);
    }else{
      dopo.push(value);
    }

  }

  prima.reverse()
  dopo.reverse()
  total = [...prima,...dopo]
  
  
  total.splice(0, 0, "Anno");
  total[total.length] = 'Totale'

  console.log(Object.assign({}, total));
  

  return total;
}


export const DialogDettGiac: React.FC<DialogProps> = ({ open, close,edit,ricarica}) => {


  const [openD, setOpenD] = useState <boolean>(false)
  const [loading, setLoading] = useState <boolean>(false)
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})
  const [propTab,setPropTab] = useState<number>(1)
  const [tabelleInfo,setTabelleInfo] = useState<any>(null)
  const [carichiA,setCarichi] = useState<any>([])
  const [vendite,setVendite] = useState<any>([])
  const [pannelIndex,setPannelIndex] = useState<number>(0)
  const [legendInv,setLegendInv] = useState<any>({})

  const columns = [
    { caption: "Data Vendita", dataField: "dataOraVendita", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
    { caption: "Operatore", dataField: "username" },
    { caption: "Qta", dataField: "quantita" },
    { caption: "Prezzo", dataField: "prezzoPubblicoUnitario",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Sconto", dataField: "totSconto" },
  ]


    const columnsCarichi = [
        { caption: "Data DDT", dataField: "dataCarico", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Num. DDT", dataField: "numeroDDTFornitore" },
        { caption: "Ricev. DDT", dataField: "dataRicevimentoDDTFornitore", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data Mov.", dataField: "dataCarico", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Fornitore", dataField: "ragioneSocialeFornitore" },
        { caption: "Qta", dataField: "quantita" },
        { caption: "Oma.", dataField: "quantitaOmaggio" },
        { caption: "Costo", dataField: "costo" },
        { caption: "Cos. Net. Oma.", dataField: "costoReale" },
        {
            caption: "Azioni", width: 200, type: "buttons", buttons: [
                {
                    hint: "Dettaglio carico",
                    icon: "chart",
                    onClick: (e: any) => {
                        window.open("https://www.cloud-farma.it/cfportal/fat_carichi.jsp?id=" + e.row.data.idCarico);

                    }
                },
            ]
        }
  ];
  
    let propr = [{ id: 0, text: 'FINESTRA MOBILE' },
    { id: 1, text: 'ANNO SOLARE' }]



    const getDettVenditeProdottoGiorno = (mese:any,anno:any)=>{
      //console.log(mese);
      //console.log(anno);
      let meseU =  mese < 10 ? '0'+mese : mese
      let dal = anno+'-'+meseU+'-'+'01'
      let al = anno+'-'+meseU+'-'+'30'
      
      //console.log(dal);
     // console.log(al);
      
      setLoading(true)
      RestServices().rest('POST', {
        codCsf:edit.codCsf,
        dal:dal,
        al:al,
        idFar:0,
      } , urlConsultazione.getDettVenditeProdottoGiorno)
      .then(result => {
          if (result?.esito) {               
              //console.log(result.response);
              setVendite(result.response)
              setPannelIndex(0)
              setLoading(false)
          }else{
            setLoading(false)
              notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
          }
      })
    } 

    const carichi = (mese:any,anno:any)=>{
      setLoading(true)
      //console.log('carichi');
      //console.log(mese);
      //console.log(anno);
      let meseU =  mese < 10 ? '0'+mese : mese
      let dal = anno+'-'+meseU+'-'+'01'
      let al = anno+'-'+meseU+'-'+'30'
      RestServices().rest('POST', {
        codCsf:edit.codCsf,
        dal:dal,
        al:al,
        idFar:0,
      } , urlConsultazione.getCarichiDett)
      .then(result => {
        setLoading(false)
          if (result?.esito) {       
             // console.log(result.response);
              setCarichi(result.response)
              setPannelIndex(1)
          }else{
              notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
          }
      })
    } 


    const caricaDati = async(mobile?:boolean) =>{
      let query:any = {
        codCsf:edit.codCsf,
        annoSolare: true,
        idFar:edit.idFar
      }
      if(mobile){
        query = {
            codCsf:edit.codCsf,
            annoSolare: false,
            idFar: edit.idFar
        }
      }
      //setLoading(false)
      RestServices().rest('POST', query , urlConsultazione.getListDettVendiAcquiProdMese)
      .then(result => {
          if (result?.esito) {
              /*if(mobile){
                let leggg = oggetInv()
                result.response.acquistato = []
                result.response.venduto = []
              }*/
              setTabelleInfo(result.response)            
             // console.log(result.response);
          }else{
              notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
          }
      })
   }
     const getListFarm = () =>{
      if (auth) {
         return RestServices().rest('POST', {} , urlConsultazione.getListFar)
      }
    }



    const cambiaProp = (valore:number)=>{
      setPropTab(valore)
      if(valore == 0){
        setLegendInv(oggetInv())
        caricaDati(true)
      }else{
        caricaDati()
      }
      
    }

    const tabellaInternaInver = (value:any) =>{
      const newV = reverseArr(value)
      
      return(
        newV.map((h:any)=>{
          if(h == 'Totale' ){
            return(
              <th style={{fontWeight:'bold',textAlign:'right'}}>{h}</th>
            )
          }else if(h == 'Anno'){
            return(
              <th style={{textAlign:'center'}}>{h}</th>
            )
          }else{
            return(
              <th style={{textAlign:'right'}}>{h}</th>
            )
          }
          
        })
      )
    }
  const tabellaInterna = (value:any,reverse?:boolean) =>{
    let newV = value
    if(reverse){
      newV = value.reverse()
    }
    return(
      newV.map((h:any)=>{
        if(h == 'Totale' ){
          return(
            <th style={{fontWeight:'bold',textAlign:'right'}}>{h}</th>
          )
        }else if(h == 'Anno'){
          return(
            <th style={{textAlign:'center'}}>{h}</th>
          )
        }else{
          return(
            <th style={{textAlign:'right'}}>{h}</th>
          )
        }
        
      })
    )
  }

  
  

    const cambiaValore = (tipo:string,value:string) =>{
      let objTo = {...objctTOsave}
      objTo[tipo] = value
      setObjctTOsave(objTo)
   
}



  useEffect(() => {
    if (edit) {
      let objTo = { ...objctTOsave };
      objTo.descrizioneProdotto = edit.descrizioneProdotto;
      objTo.codProd = edit.codProd; 
      objTo.descrix = edit.descrix;
      setObjctTOsave(objTo);
    }
  }, [edit, openD]);

  useEffect(() => { 
        if(open && auth){
          caricaDati()
        }
    
  }, [open,auth]);

  useEffect(() => {
    
  }, [auth]);

  useEffect(()=>{
    setOpenD(open)
    },[open])



  return (
    <>
  
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'75%'}
      height={'85%'}
      showTitle={true}
      title="Dettaglio vendite e acquisti mensili ultimi tre anni"
    >

       <Loading isVisible={loading} />
     <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
              // {
              //   text: 'Modifica',             
              //   disabled:false,
              //   onClick: (e: any) => {console.log();
              //   };         
          />

      <ScrollView height="100%"  width="100%">
      <div className='form-row '> 
      <div className="form-group col-md-4">
          <label> CODICE Minsan </label>
          <TextBox disabled value={objctTOsave.codProd}  />
        </div>

        <div className="form-group col-md-4">
          <label> Descrizione Prodotto </label>
          <TextBox disabled value={objctTOsave.descrizioneProdotto} />
        </div>

        <div className="form-group col-md-4">
          <label> Farmacia </label>
          <TextBox disabled value={objctTOsave.descrix}  />
        </div>        
        </div>

        
        <div style={{}} className="">      
        <br></br>     
          <RadioGroup onValueChanged={(x)=>cambiaProp(x.value.id)} dataSource={propr} layout="horizontal" value={propr[propTab]}   />                  
        </div>
        
     

        <div style={{ width: '100%'}}>
        <div style={{fontWeight:'bold' }}>
        DETTAGLIO VENDITE MENSILI - ULTIMI TRE ANNI
        </div>

        {propTab == 1 ? (
          <div style={{gap:10,background: 'white',padding: 10,borderRadius: 10}}>
             
          <table id="dlgvenacq-tablevenm" className="table table-bordered table-hover b2x-table fixed-layout" >
           <thead>
             <tr>
               {tabelleInfo ? tabellaInterna(tabelleInfo?.header) : null}
             </tr>
             </thead>
             {tabelleInfo?.venduto.map((anno:any)=>{
               return(
                 <tbody>
               <tr>
                 
                 <td style={{textAlign:'center'}}><strong>{anno.anno}</strong></td>
                 {anno?.dettaglio.map((d:any)=>{
                  return(
                   <th onClick={()=>getDettVenditeProdottoGiorno(d.mese,anno.anno)} style={{textAlign:'right'}}>{d.qtaMese}</th>
                 )
                 })
                 }
                 <td style={{fontWeight:'bold',textAlign:'right'}}><strong>{anno.qtaTot}</strong></td>
               </tr>
               </tbody>
               )
             })}
             
           </table>

   </div>
        ) : (
          <div style={{gap:10,background: 'white',padding: 10,borderRadius: 10}}>
             
          <table id="dlgvenacq-tablevenm" className="table table-bordered table-hover b2x-table fixed-layout" >
           <thead>
             <tr>
               {tabelleInfo ? tabellaInternaInver(tabelleInfo?.header) : null}
             </tr>
             </thead>
             {tabelleInfo?.venduto.map((anno:any)=>{
               return(
                 <tbody>
               <tr>
                 
                 <td style={{textAlign:'center'}}><strong>{anno.anno}</strong></td>
                 {Object.keys(legendInv).map((key, index) => {
                   
                      let inddM = magic(legendInv[key])
                      if(inddM){
                        return (
                          <th onClick={()=>getDettVenditeProdottoGiorno(Number(inddM),anno.anno)} style={{textAlign:'right'}}>{anno?.dettaglio[Number(inddM)-1].qtaMese}</th>
                        )
                      }
                      
                    } )}
                
                 <td style={{fontWeight:'bold',textAlign:'right'}}><strong>{anno.qtaTot}</strong></td>
               </tr>
               </tbody>
               )
             })}
             
           </table>

   </div>
        )}

      </div>

      <div style={{ width: '100%'}}>
          <div style={{fontWeight:'bold' }}>
        DETTAGLIO ACQUISTI MENSILI - ULTIMI TRE ANNI
        </div>

        {propTab == 1 ? (
          <div style={{gap:10,background: 'white',padding: 10,borderRadius: 10,marginTop:10, marginBottom:10}}>
             
          <table id="dlgvenacq-tablevenm" className="table table-bordered table-hover b2x-table fixed-layout" >
           <thead>
             <tr>
               {tabelleInfo ? tabellaInterna(tabelleInfo?.header) : null}
             </tr>
             </thead>
             {tabelleInfo?.acquistato.map((anno:any)=>{
               return(
                 <tbody>
               <tr>
                 
                 <td style={{textAlign:'center'}}><strong>{anno.anno}</strong></td>
                 {anno?.dettaglio.map((d:any)=>{
                  return(
                   <th onClick={()=>carichi(d.mese,anno.anno)} style={{textAlign:'right'}}>{d.qtaMese}</th>
                 )
                 })
                 }
                 <td style={{fontWeight:'bold',textAlign:'right'}}><strong>{anno.qtaTot}</strong></td>
               </tr>
               </tbody>
               )
             })}
             
           </table>
         
   </div>
        ) : (
<div style={{gap:10,background: 'white',padding: 10,borderRadius: 10,marginTop:10, marginBottom:10}}>
             
             <table id="dlgvenacq-tablevenm" className="table table-bordered table-hover b2x-table fixed-layout" >
              <thead>
                <tr>
                  {tabelleInfo ? tabellaInternaInver(tabelleInfo?.header) : null}
                </tr>
                </thead>
                {tabelleInfo?.acquistato.map((anno:any)=>{
                  return(
                    <tbody>
                  <tr>
                    <td style={{textAlign:'center'}}><strong>{anno.anno}</strong></td>
                    {Object.keys(legendInv).map((key, index) => {
                      
                      let inddM = magic(legendInv[key])
                      if(inddM){
                        return (
                          <th onClick={()=>carichi(Number(inddM),anno.anno)} style={{textAlign:'right'}}>{anno?.dettaglio[Number(inddM)-1].qtaMese}</th>
                        )
                      }
                      
                    } )}

                   
                    <td style={{fontWeight:'bold',textAlign:'right'}}><strong>{anno.qtaTot}</strong></td>
                  </tr>
                  </tbody>
                  )
                })}
                
              </table>
            
      </div>
        )
      }
        
        </div>


             
        <TabPanel
                        selectedIndex={pannelIndex}
                        loop={false}
                        animationEnabled={true}
                       // onOptionChanged={onOptionChanged}   
                        width={'100%'} 
                        height={'300px'}          
                    >
                        <Item title="VENDITE" icon="exportselected">
                        
                          <NewTabella 
                                idTabella='elenco_vendita_consultazione'
                                colonne={columns} 
                                dataSource={vendite} 
                                filterRow
                                onclickRow={(datiRow:any)=>console.log()}
                                exportT={{
                                    enabled: false,
                                    fileName: "vendita_consultazione"
                                }}
                            />

                       </Item>

                       <Item title="CARICHI" icon="box">
                      
                       <NewTabella 
                                idTabella='carichi'
                                colonne={columnsCarichi} 
                                dataSource={carichiA} 
                                filterRow
                                onclickRow={(datiRow:any)=>console.log()}
                                exportT={{
                                    enabled: false,
                                    fileName: "vendita_consultazione"
                                }}
                            />
                               
                       </Item>

                        </TabPanel>
           
      </ScrollView>
    </Popup>
    </>
  );
};