import Form, { RangeRule, SimpleItem } from 'devextreme-react/form';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { SelectFarm, Selezione, TendinaFarm } from '../../widget/Select';
import { RestServices } from '../../services/restServices';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { OggettoInterface } from '../../interfaces/generiche';
import { ListaVettoriInterface, ScreeningConsegnaInterface, ScreeningFaqInterface, ScreeningInterface, ScreeningKitInterface } from '../../interfaces/screening';
import { BoxDataOra, BoxFiltro, Check } from '../../widget/Editor';
import notify from 'devextreme/ui/notify';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { ColumnButtonOnClickInterface, TabellaCompletaInterface, TabellaSempliceInterface } from '../../interfaces/tabelle';
import { TabellaCompleta, TabellaSemplice } from '../../widget/Tabelle';
import ArrayStore from 'devextreme/data/array_store';
import { Chart } from 'devextreme-react/range-selector';
import { urlScreening } from '../costanti';

export const DialogConsegnaProvetta = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogConsegnaProvettaOpen: boolean,
    setIsDialogConsegnaProvettaOpen: (isDialogConsegnaProvettaOpen: boolean) => void
    handleCloseDialogConsegnaProvetta: () => void,
    setNewConsegnaProvetta: (datiConsegna: ScreeningInterface) => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const newConsegnaProvetta = {
        codiceFiscale: undefined,
        lstFar: [],
        numeroProvetta: undefined,
        numeroProvettaLettera: undefined,
        dataNascita: undefined,
        vettore: undefined,
        nascosti: undefined,
    }

    const [dataNascita, setDataNascita] = useState<string | undefined>(undefined);
    const [filtri, setFiltri] = useState<ScreeningInterface>(newConsegnaProvetta);
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        if (filtri && filtri.codiceFiscale) {
            var codiceFiscale = filtri.codiceFiscale.toUpperCase();
            let [anno, giorno] = [parseInt(codiceFiscale.substring(6, 8)), parseInt(codiceFiscale.substring(9, 11))];
            var giornoString;
            var mese;
            if (giorno > 40) {
                giorno -= 40;
                giornoString = (String(giorno).length == 1 ? "0" + String(giorno) : String(giorno));
            }
            else
                giornoString = (String(giorno).length == 1 ? "0" + String(giorno) : String(giorno));

            var meseChar = String(codiceFiscale.charAt(8));
            switch (meseChar) {
                case 'A':
                    mese = '01';
                    break;
                case 'B':
                    mese = '02';
                    break;
                case 'C':
                    mese = '03';
                    break;
                case 'D':
                    mese = '04';
                    break;
                case 'E':
                    mese = '05';
                    break;
                case 'H':
                    mese = '06';
                    break;
                case 'L':
                    mese = '07';
                    break;
                case 'M':
                    mese = '08';
                    break;
                case 'P':
                    mese = '09';
                    break;
                case 'R':
                    mese = '10';
                    break;
                case 'S':
                    mese = '11';
                    break;
                case 'T':
                    mese = '12';
                    break;
                default: break;
            }
            if (mese && giornoString && anno)
                setDataNascita(giornoString + "-" + mese + "-" + (anno < 20 ? "20" : "19") + anno);
            else
                setDataNascita("");

        }
    }, [filtri.codiceFiscale])

    const handleClose = () => {
        props.setIsDialogConsegnaProvettaOpen(false);
        setDataNascita("");
        setFiltri(newConsegnaProvetta);
    }

    const creaConsegnaProvetta = () => {
        if (!filtri.codiceFiscale) {
            notify({ position: "center", width: "auto", message: "Inserire il codice fiscale" }, "error", 4000)
        } else if (!filtri.lstFar) {
            notify({ position: "center", width: "auto", message: "Selezionare la farmacia" }, "error", 4000)
        } else if (!filtri.numeroProvetta) {
            notify({ position: "center", width: "auto", message: "Inserire il numero provetta" }, "error", 4000)
        } else if (filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare la farmacia" }, "error", 4000)
        }else {

            props.setNewConsegnaProvetta(filtri);
            const param = {
                idFar: filtri.lstFar,
                codiceFiscale: filtri.codiceFiscale.toUpperCase(),
                codiceKit: filtri.numeroProvetta
            }
            RestServices().rest('POST', param, urlScreening.consegnaKit).then(result => {
                if (result.esito) {
                    notify({ position: "center", width: "auto", message: result.message }, "success", 4000);
                    setDataNascita("");
                    setFiltri(newConsegnaProvetta);
                    props.setIsDialogConsegnaProvettaOpen(false);
                } else {
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                }
            })
        }
    }

    return (
        <>
            <Modal
                show={props.isDialogConsegnaProvettaOpen}
                onHide={handleClose}
                dialogClassName="dialogConsegnaProvetta"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Consegna il kit</h3>
                        <h3 style={{ color: '#2A32F4' }}><b>Ricordare al cliente che la restituzione puo' essere effettuata dal lunedi' al giovedi'</b></h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="2" className="labelCreaConsegnaProvetta">
                            <span>Farmacia</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <SelectFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} displayExpr={ true} />
                        </Col> 
                    </Row>
                    <Row>
                        <Col sm="6" className="labelCreaConsegnaProvetta">
                            <span>Inserisci il codice fiscale presente sulla lettera di invito</span>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm="6">
                            <BoxFiltro id="codiceFiscale" impostaFiltri={impostaFiltri} />
                        </Col> 
                        <Col sm="3" className="labelCreaConsegnaProvetta">
                            <span>Riscontra data di nascita</span>
                        </Col>
                        <Col sm="3">
                            <Col sm="6" className="labelCreaConsegnaProvetta">
                                <span>{dataNascita}</span> 
                            </Col>
                        </Col>
                    </Row>
                        
                    <Row>
                        <Col sm="4" className="labelCreaConsegnaProvetta">
                            <span>Inserisci il codice univoco del kit</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <BoxFiltro id="numeroProvetta" impostaFiltri={impostaFiltri} />
                        </Col>
                        </Row> 
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiConsegnaProvetta" variant="success" onClick={creaConsegnaProvetta} >
                        Salva
                    </Button>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}



export const DialogRestituzioneProvetta = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogRestituzioneProvettaOpen: boolean,
    setIsDialogRestituzioneProvettaOpen: (isDialogRestituzioneProvettaOpen: boolean) => void
    handleCloseDialogRestituzioneProvetta: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const newRestituzioneProvetta = {
        codiceFiscale: undefined,
        lstFar: [],
        numeroProvetta: undefined,
        numeroProvettaLettera: undefined,
        dataNascita: undefined,
        vettore: undefined,
        nascosti: undefined,
    }
    const [filtri, setFiltri] = useState<ScreeningInterface>(newRestituzioneProvetta);
    const [dataNascita, setDataNascita] = useState<string | undefined>(undefined);
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        if (filtri && filtri.codiceFiscale) {
            var codiceFiscale = filtri.codiceFiscale.toUpperCase();
            let [anno, giorno] = [parseInt(codiceFiscale.substring(6, 8)), parseInt(codiceFiscale.substring(9, 11))];
            var giornoString;
            var mese;
            if (giorno > 40) {
                giorno -= 40;
                giornoString = (String(giorno).length == 1 ? "0" + String(giorno) : String(giorno) );
            }
            else
                giornoString = (String(giorno).length == 1 ? "0" + String(giorno) : String(giorno));

            var meseChar = String(codiceFiscale.charAt(8));
            switch (meseChar) {
                case 'A':
                    mese = '01';
                    break;
                case 'B':
                    mese = '02';
                    break;
                case 'C':
                    mese = '03';
                    break;
                case 'D':
                    mese = '04';
                    break;
                case 'E':
                    mese = '05';
                    break;
                case 'H':
                    mese = '06';
                    break;
                case 'L':
                    mese = '07';
                    break;
                case 'M':
                    mese = '08';
                    break;
                case 'P':
                    mese = '09';
                    break;
                case 'R':
                    mese = '10';
                    break;
                case 'S':
                    mese = '11';
                    break;
                case 'T':
                    mese = '12';
                    break;
                default: break;
            }
            if (mese && giornoString && anno)
                setDataNascita(giornoString + "-" + mese + "-" + (anno < 20 ? "20" : "19") + anno);
            else
                setDataNascita("");

        }
    }, [filtri.codiceFiscale])

    const handleClose = () => {
        props.setIsDialogRestituzioneProvettaOpen(false);
        setDataNascita("");
        setFiltri(newRestituzioneProvetta);
    }

    const creaRestituzioneProvetta = () => {
        if (!filtri.codiceFiscale) {
            notify({ position: "center", width: "auto", message: "Inserire il codice fiscale" }, "error", 4000)
        } else if (!filtri.lstFar) {
            notify({ position: "center", width: "auto", message: "Selezionare la farmacia" }, "error", 4000)
        } else if (!filtri.numeroProvetta) {
            notify({ position: "center", width: "auto", message: "Inserire il il codice presente sul kit" }, "error", 4000)
        } else if (!filtri.numeroProvettaLettera) {
            notify({ position: "center", width: "auto", message: "Inserire il codice univoco presente sulla lettera" }, "error", 4000)
        } else if (filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare la farmacia" }, "error", 4000)
        }
        else {
            const param = {
                idFar: filtri.lstFar,
                codiceFiscale: filtri.codiceFiscale.toUpperCase(),
                codiceKit: filtri.numeroProvetta,
                codiceKitLetteraInvito: filtri.numeroProvettaLettera
            }
            RestServices().rest('POST', param, urlScreening.ritiraKit).then(result => {
                if (result.esito) {
                    notify({ position: "center", width: "auto", message: result.message }, "success", 4000);
                    setDataNascita("");
                    setFiltri(newRestituzioneProvetta);
                    props.setIsDialogRestituzioneProvettaOpen(false);
                } else {
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                }
            })
        }
    }

    return (
        <>
            <Modal
                show={props.isDialogRestituzioneProvettaOpen}
                onHide={handleClose}
                dialogClassName="dialogConsegnaProvetta"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Restituisci provetta</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="2" className="labelCreaConsegnaProvetta">
                            <span>Farmacia</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <SelectFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} displayExpr={true}/>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6" className="labelCreaConsegnaProvetta">
                            <span>Inserisci il codice fiscale presente sulla lettera di invito</span>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm="6">
                            <BoxFiltro id="codiceFiscale" impostaFiltri={impostaFiltri} />
                        </Col>
                        <Col sm="3" className="labelCreaConsegnaProvetta">
                            <span>Riscontra data di nascita</span>
                        </Col>
                        <Col sm="3">
                            <Col sm="6" className="labelCreaConsegnaProvetta">
                                <span>{dataNascita}</span>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" className="labelCreaConsegnaProvetta">
                            <span>Inserisci il codice univoco del Kit presente sulla lettera di invito</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <BoxFiltro id="numeroProvettaLettera" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" className="labelCreaConsegnaProvetta">
                            <span>Inserisci il codice univoco presente sul Kit</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <BoxFiltro id="numeroProvetta" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiConsegnaProvetta" variant="success" onClick={creaRestituzioneProvetta} >
                        Salva
                    </Button>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={() => props.setIsDialogRestituzioneProvettaOpen(false)}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}

export const DialogElencoProvetta = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogElencoProvettaOpen: boolean,
    setIsDialogElencoProvettaOpen: (isDialogElencoProvettaOpen: boolean) => void
    handleCloseDialogElencoProvetta: () => void,
    setIsVisible: (isVisible: boolean) => void,
    vettori: ListaVettoriInterface[] | undefined,
}) => {

    const newElencoProvetta = {
        codiceFiscale: undefined,
        lstFar: [],
        numeroProvetta: undefined,
        numeroProvettaLettera: undefined,
        dataNascita: undefined,
        vettore: undefined,
        nascosti: undefined,
    }
    const [filtri, setFiltri] = useState<ScreeningInterface>(newElencoProvetta);
    const [elencoKit, setElencoKit] = useState<ScreeningKitInterface[]>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [vettore, setVettore] = useState<string>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        filtri.vettore = undefined;
        setElencoKit(undefined);
        setTabellaInstance(undefined)
        props.setIsDialogElencoProvettaOpen(false);
        
    }


    const stampaElencoProvetta = () => {
        if (!filtri.vettore)
            notify({ position: "center", width: "auto", message: "Inserire il vettore" }, "error", 4000);
        else {
            var keys = tabellaInstance.instance.getSelectedRowKeys();
            if (keys.length == 0)
                notify({ position: "center", width: "auto", message: "Selezionare almeno un kit" }, "error", 4000);
            else {
                var codici = new Array();
                for (var i = 0; i < keys.length; i++) {
                    codici.push(keys[i].codiceKit);
                }
                const param = {
                    idFar: filtri.lstFar,
                    codiceKitList: codici,
                    vettore: filtri.vettore
                };
                RestServices().rest('POST', param, urlScreening.vettore).then(result => {
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "Salvataggio eeguito con successo" }, "success", 2000)
                        const url = urlScreening.report + '?idFar=' + param.idFar + '&codici=' + param.codiceKitList;
                        setVettore("");
                        window.open(url);
                        props.setIsDialogElencoProvettaOpen(false)
                        handleClose();
                    } else {
                        notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                    }
                })
            }
        }
    }

    const cerca = () => {
        const param = {};
        if (filtri.lstFar && filtri.lstFar.length != 0) {
            RestServices().rest('POST', param, urlScreening.cerca + "/" + filtri.lstFar + "/" + filtri.nascosti).then(result => {
                if (result) {
                    setElencoKit(result);
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                }
            })
        }
    }

    const propsTabCodiciKit: TabellaCompletaInterface = {
        id: "Screening_codici_kit",
        dataSource: elencoKit,
        pagination: 10,
        selection: "multiple",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: true,
    }
    const columnsCodiciKit = CreaColonne([
        { caption: "Codice Kit", dataField: "codiceKit" },
        { caption: "Data consegna Kit", dataField: "dataConsegna", format: "dd/MM/yyyy HH:mm:ss", dataType: "date"  },
        //{ caption: "Farmacia consegna", dataField: "ragioneSocialeFarConsegna" },
        { caption: "Data ritiro campione", dataField: "dataRitiro", format: "dd/MM/yyyy HH:mm:ss", dataType: "date"  },
        //{ caption: "Farmacia ritiro", dataField: "ragioneSocialeFarRitiro" },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Nascondi kit",
                icon: "remove",
                onClick: (e: any) => {
                    const codici = e.row.data.codiceKit;
                    const nascondi = !(e.row.data.nascosto);
                    const param = {
                        codiceKit: codici,
                        nascondi: nascondi,
                    };
                    RestServices().rest('POST', param, urlScreening.visibileKit).then(result => {
                        if (result.esito) {
                            cerca();
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                    })
                }
            }
            ]
        }
    ])

    const onRowPrepared = (e: any) => {
        if (e.data)
            if (e.data.nascosto == true && e.rowType != 'header') {
                e.rowElement.style.color = 'silver';
            }
    }

    const onEditorPreparing = (e: any) => {
        if (e.parentType == "dataRow") {
            e.editorOptions.disabled = e.row.data.nascosto  
        }
    }

    const onSelectionChanged =  (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.nascosto == true)
                e.component.deselectRows(row); return;
        })
    }  

   const onCellPrepared = (e: any) => {
       console.log(e);
       if (e.column.type == "buttons")
           if (e.data)
               if (e.data.nascosto == true) {
                   //var elm = e.cellElement.find('a:contains(Edit)');
                   e.cellElement.firstElementChild.className = "dx-link dx-icon-todo dx-link-icon";
                   e.cellElement.firstElementChild.attributes[2].nodeValue = "Visualizza kit";
                   //e.cellElement.innerHTML = e.cellElement.innerHTML.replace("Nascondi", "Visualizza");
               }
                
        }  

                return (
        <>
            <Modal
                show={props.isDialogElencoProvettaOpen}
                onHide={handleClose}
                dialogClassName="dialogElencoProvetta"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Consegna al vettore</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <Row>
                        <Col sm="6" className="labelCreaConsegnaProvetta">
                            <span>Farmacia</span>
                        </Col>
                        <Col sm="2" className="labelCreaConsegnaProvetta">
                            <span>Visualizza nascosti</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6">
                            <SelectFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} displayExpr={true}/>
                        </Col>
                        <Col sm="2">
                            <Check id="nascosti" impostaFiltri={impostaFiltri} checkValue={filtri.nascosti}/>
                        </Col>
                        <Col sm="2">
                            <Button id="btnAggiungiConsegnaProvetta" variant="success" onClick={cerca} >
                                Cerca kit
                            </Button>
                        </Col>
                    </Row>
                    &nbsp;
                    &nbsp;
                    <TabellaCompleta propsTabella={propsTabCodiciKit} columns={columnsCodiciKit} setTabellaInstance={setTabellaInstance} selectedRowKeys={selectedRowKeys} onSelectionChanged={onSelectionChanged} onEditorPreparing={onEditorPreparing} onRowPrepared={onRowPrepared} onCellPrepared={ onCellPrepared} />

                </Modal.Body>
                <Modal.Footer>
                    <Col sm="2" className="labelCreaConsegnaProvetta mr-auto">
                        <span>Inserisci il vettore</span>
                    </Col>
                    {/*
                    <Col sm="5" className="mr-auto">
                        <BoxFiltro id="vettore" impostaFiltri={impostaFiltri} value={vettore} />
                    </Col>
                    */}  
                    <Col sm="5" className="mr-auto">
                        <Selezione
                            dataSource={props.vettori}
                            id="vettore"
                            impostaFiltri={impostaFiltri}
                            displayExpr="DESCRIZIONE"
                            valueExpr="ID"
                            searchEnabled={true}
                        />
                    </Col>
                    <Button id="btnAggiungiConsegnaProvetta" variant="success" onClick={stampaElencoProvetta} >
                        Consegna al vettore
                    </Button>

                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export const DialogElencoConsegne = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    idFar?:number,
    isDialogElencoConsegneOpen: boolean,
    setIsDialogElencoConsegneOpen: (isDialogElencoConsegneOpen: boolean) => void
    handleCloseDialogElencoConsegne: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {


    const newElencoProvetta = {
        codiceFiscale: undefined,
        lstFar: [],
        numeroProvetta: undefined,
        numeroProvettaLettera: undefined,
        dataNascita: undefined,
        vettore: undefined,
        nascosti: undefined,
    }

    const [elencoConsegneKit, setElencoConsegneKit] = useState<ScreeningConsegnaInterface[]>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [filtri, setFiltri] = useState<ScreeningInterface>(newElencoProvetta);

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }


    const handleClose = () => {
        setElencoConsegneKit(undefined);
        setTabellaInstance(undefined)
        props.setIsDialogElencoConsegneOpen(false);

    }

    const cerca = () => {
        const param = {};
        if (props.idFar && props.idFar != 0) {
            const param = {
                
            };
            RestServices().rest('POST', param, urlScreening.getConsultaConsegne + "/" + props.idFar).then(result => {
                if (result) {
                    setElencoConsegneKit(result);
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                }
            })
        }
    }

    const propsTabCodiciKit: TabellaCompletaInterface = {
        id: "Screening_consegne_kit",
        dataSource: elencoConsegneKit,
        pagination: 10,
        selection: "none",
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: true,
        export: {
            enabled: true,
            fileName: "Consegne_kit"
        }
    }

    const columnsCodiciKit = CreaColonne([
        { caption: "Data consegna al vettore", dataField: "DATA_VETTORE", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "desc" },
        { caption: "Vettore", dataField: "VETTORE" },
        { caption: "Num. campioni", dataField: "NUMERO_CAMPIONI" },
        { caption: "Codice kit list", dataField: "CODICE_KIT_LIS", visible: false },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Stampa PDF",
                icon: "pdffile",
                onClick: (e: any) => {
                    const codici = e.row.data.CODICE_KIT_LIST;
                    if (props.datiFarmacie)
                        window.open(urlScreening.report + '?idFar=' + props.datiFarmacie[0].ID_FAR + '&codici=' + codici, "_blank");
                }
            }
         ]
        }

    ])

    return (
        <>
            <Modal
                show={props.isDialogElencoConsegneOpen}
                onHide={handleClose}
                dialogClassName="dialogElencoConsegne"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Consulta consegne</h3>
                    </Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                   <Row>
                        <Col sm="2" className="labelCreaConsegnaProvetta">
                        <span>Farmacia</span>
                    </Col>
                    </Row>
                <Row>
                    <Col sm="6">
                        <SelectFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} displayExpr={true} />
                    </Col>
                    <Col sm="2">
                        <Button id="btnAggiungiConsegnaProvetta" variant="success" onClick={cerca} >
                            Cerca
                            </Button>
                    </Col>
                </Row>
                    &nbsp;
                    &nbsp;
                    <TabellaCompleta propsTabella={propsTabCodiciKit} columns={columnsCodiciKit} setTabellaInstance={setTabellaInstance} selectedRowKeys={selectedRowKeys} />

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}



export const DialogFaq = (props: {
    isDialogFaqOpen: boolean,
    setIsDialogFaqOpen: (isDialogFaqOpen: boolean) => void
    handleCloseDialogFaq: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const [elencoFaq, setElencoFaq] = useState<ScreeningFaqInterface[]>();
    const handleClose = () => {
        props.setIsDialogFaqOpen(false);
    }


    useEffect(() => {
        const param = {};
        if (props.isDialogFaqOpen) {
            RestServices().rest('POST', param, urlScreening.getElencoFaq).then(result => {
                if (result) {
                    setElencoFaq(result);
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                }
            })
        }
    }, [props.isDialogFaqOpen])


    return (
        <>
            <Modal
                show={props.isDialogFaqOpen}
                onHide={handleClose}
                size="xl"
                dialogClassName="dialogFaq"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloFaq">
                        <h3>FAQ</h3>
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                   
                    {elencoFaq != undefined && elencoFaq.map((item, index) => {
                       return( <div>
                                <Row>
                                <Col sm="2" className="labelDomandaFaq">
                                    <span>Domanda: </span>
                                </Col>
                                <Col sm="10" className="labelRispostaFaq">
                                    <span>{ item.domanda}</span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="2" className="labelDomandaFaq">
                                    <span>Risposta: </span>
                                </Col>
                                <Col sm="10" className="labelRispostaFaq">
                                    <span>{ item.risposta}</span>
                                </Col>
                           </Row>
                           <Row>&nbsp;</Row>
                         </div>)
                    })}
                    
                   

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}