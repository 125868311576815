import { Summary, TotalItem } from 'devextreme-react/data-grid'
import { RadioGroup } from 'devextreme-react/radio-group'
import notify from 'devextreme/ui/notify'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { cambiaFormatoLocalDateTimeToEuropeo, getCurrentDateTime, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import { useAuth } from '../../hooks/useAuth'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { DialogInfo, Loading } from '../../widget/Notifications'
import Ricerca from "../../widget/nuova_ricerca"
import { Filtri } from '../consultazione/interface'
import { urlConsultazione } from "../costanti"
import { DialogData } from "./dialog/pop_data"
import { DialogDataSaldo } from "./dialog/pop_dele_dat_saldo"
import { DialogNotePass } from "./dialog/pop_note_pass"
import { DiaPagamPass } from "./dialog/pop_pag_pass"

import { faFilePdf, faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { confirm } from 'devextreme/ui/dialog'
import { Workbook } from 'exceljs'
import saveAs from 'file-saver'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { DialogElencoPagamenti } from './dialog/pap_pag_elenco'
import './style.css'




const FattPassive = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idScadenzario, setIdScadenzario] = useState<number | null>(null)
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [propTab, setPropTab] = useState<number>(0)
    const [importo, setImporto] = useState<number>(0)
    const [saldate, setSaldate] = useState<number>(0)
    const [scadute, setScadute] = useState<number>(0)
    const [scadenzaO, setScadenzaO] = useState<number>(0)
    const [ScadenzaM, setScadenzaM] = useState<number>(0)
    const [nonScaduti, setNonScaduti] = useState<number>(0)
    const [nCredito, setNCredito] = useState<number>(0)
    const [summaryObj, setSummaryObj] = useState<any>({})
    const [scadenzario, setScadenzario] = useState<any>({})
    const [dataDalObj, setdataDalObj] = useState<any>({})
    const [dataAlObj, setdataAlObj] = useState<any>({})
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [openPop3, setOpenPop3] = useState<boolean>(false)
    const [openPopSaldo, setOpenPopSaldo] = useState<boolean>(false)
    const [openPopPagamento, setOpenPopPagamento] = useState<boolean>(false)
    const [edit, setImmediate] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [paramsRicerca, setParamsRicerca] = useState<any>({})
    const [messDel, setMessDel] = useState<string>('')
    const [openPopInfo, setOpenPopInfo] = useState<boolean>(false)
    const [importoFooter, setImportoFooter] = useState<number>(0)
    const [saldateFooter, setSaldateFooter] = useState<number>(0)
    const [scaduteFooter, setScaduteFooter] = useState<number>(0)
    const [scadenzaOFooter, setScadenzaOFooter] = useState<number>(0)
    const [ScadenzaMFooter, setScadenzaMFooter] = useState<number>(0)
    const [nonScadutiFooter, setNonScadutiFooter] = useState<number>(0)
    const [nCreditoFooter, setNCreditoFooter] = useState<number>(0)
    const [selectedScad, setSelectedScad] = useState<any>([])
    const [maxSelezionati, setMaxSelezionati] = useState<number>(0)
    const [max, setMax] = useState<number>(0)
    const [disabledPulsanti, setDisabledPulsanti] = useState<boolean>()
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [statoList, setStatoList] = useState([]);
    const [gruppo, setGruppo] = useState<number>();
    const [selectedIdScadAction, setSelectedIdScadAction] = useState<number>();
    const [titoloPagamento, setTitoloPagamento] = useState<string>();

    const auth = useAuth(urlConsultazione.auth)


    const columns = [

        {
            caption: "Scadenza", dataField: "scadeIl", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date", width: 150, render: (e: any) => {
                // console.log('valore data');
                // console.log(e);
                return (
                    <a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                        onClick={() => { setOpenPop(true); setObjtipo(e.data); setImmediate(true) }}>{e.text}</a>
                )
            }
        },
        {
            caption: "", width: 40, type: "buttons", buttons: [
                {
                    hint: "",
                    icon: "info",
                    visible: (x: any) => isIconVisible(x),
                    onClick: (e: any) => {
                        if (e.row.data.avviso) {
                            setMessDel(e.row.data.avviso)
                            setOpenPopInfo(true)
                            // notify({ position: "center", width: "auto", message:e.row.data.avviso }, "info", 7000) 
                        }
                    }
                },]
        },
        { caption: "Data Rif.", dataField: "dataRiferimento", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Giorni", dataField: "giorni", dataType: "number" },
        { caption: "Importo", dataField: "importo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 90, dataType: "number" },
        { caption: "Farmacia", dataField: "farmacia", width: 200, dataType: "string" },
        { caption: "Fornitore", dataField: "intestatario", width: 300, dataType: "string" },
        { caption: "Cat. Fornitore", dataField: "categoriaFornitore", width: 120, dataType: "string" },
        { caption: "Tipo Doc.", dataField: "tipoDocumento", width: 130, dataType: "string" },
        { caption: "Num. Doc.", dataField: "numeroDocumento", /*width: 100,*/ dataType: "string" },
        { caption: "Data Doc.", dataField: "data", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Totale", dataField: "totale", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Imponibile", dataField: "imponibile", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Imposta", dataField: "imposta", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Stato", dataField: "stato", width: 120, dataType: "string" },
        { caption: "Pagato", dataField: "importoPagato", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Residuo", dataField: "importoResiduo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Stato pagamento", dataField: "statoPagamentoScadenza", width: 120, dataType: "string" },
        {
            caption: "Data Pag.", dataField: "dataSaldo", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date", width: 95, //render:(e:any)=>{
            //    return(
            //        <a style={{ cursor:'pointer', fontWeight:'bold',color:'blue'}}
            //         onClick={()=>{setOpenPopSaldo(true);setObjtipo(e.data); setImmediate(true)}}>{e.text}</a>
            //    )
            //}
        },
        { caption: "Pagamento", dataField: "pagamento", width: 120, dataType: "string" },
        { caption: "Riferimento", dataField: "riferimento", width: 120, dataType: "string" },
        { caption: "Banca", dataField: "banca", width: 130, dataType: "string" },
        { caption: "C/C", dataField: "contoCorrente", width: 130, dataType: "string" },
        { caption: "Nota pagamento", dataField: "annotazione", width: 130, dataType: "string" },
        { caption: "Scostamento", dataField: "scostamento", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        {
            caption: "Annotazione", dataField: "note", width: 100, dataType: "string", render: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Vedi pagamenti",
                    icon: "bulletlist",
                    onClick: (e: any) => {
                        //console.log (e)
                        if (e.row.data) {
                            setSelectedIdScadAction(e.row.data.idScadenzario)
                            setTitoloPagamento("Fattura n. " + e.row.data.numeroDocumento + " del " + cambiaFormatoLocalDateTimeToEuropeo(e.row.data.data, false) + " - " + e.row.data.intestatario)
                            setOpenPopPagamento(true)
                        }


                    },
                }
            ]
        }

    ];


    const isIconVisible = (e: any) => {
        if (e.row.data.avviso) {
            return true;
        } else {
            return false;
        }
    }


    const getDefaultDal = () => {

        //ritorna la dta iniziale del trimetre corrente
        var month = new Date().getMonth() + 1;

        if (month < 4)
            return new Date().getFullYear() + '-' + (1) + '-01'

        else if (month < 7)
            return new Date().getFullYear() + '-' + (4) + '-01'
        else if (month < 10)
            return new Date().getFullYear() + '-' + (7) + '-01'
        else if (month < 13)
            return new Date().getFullYear() + '-' + (10) + '-01'

    }

    const getDefaultAl = () => {

        //ritorna la dta iniziale del trimetre corrente
        var month = new Date().getMonth() + 1;

        if (month < 4)
            return new Date().getFullYear() + '-' + (3) + '-31'

        else if (month < 7)
            return new Date().getFullYear() + '-' + (6) + '-30'
        else if (month < 10)
            return new Date().getFullYear() + '-' + (9) + '-30'
        else if (month < 13)
            return new Date().getFullYear() + '-' + (12) + '-31'
    }

    const itemsFields = [
        { type: 'select', idValue: 'idFar', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: newFarmaList, width: 350 },
        { type: 'string', idValue: 'fornitore', label: 'Fornitore ', dataSource: null, width: 350 },
        { type: 'select', idValue: 'idStato', label: 'Stato ', displayExpr: 'descrizione', dataSource: statoList, width: 350 },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, require: true, defValue: getDefaultDal(), width: 250 },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null, require: true, defValue: getDefaultAl(), width: 250 },


    ]


    const isPrintIconVisible = (e: any) => {
        if (e.row.data.linkFattura) {
            return true;
        } else {
            return false;
        }
    }


    const onRowPrepared = (e: any) => {
        if (e.rowType == "totalFooter") {
            e.rowElement.deleteCell(1);
            e.rowElement.deleteCell(1);
            e.rowElement.cells[0].colSpan = 20;
            e.rowElement.cells[0].innerHTML = "<div style='gap: 16px; display: flex; flex-wrap: wrap; background: white; padding: 10px; border-radius: 10px; margin-top: 4px;'><p style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong> Elementi Totali:  </strong>  </p><p id='elementiTotali' style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong >" + tabellaPrincipale.length +
                "</strong> </p><p style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong> Elementi Selezionati:  </strong>  </p><p id='elementiSelezionati' style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong > " + selectedScad.length +
                "</strong> </p><p style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong> Totali parziali:  </strong>  </p><p id='importo' style='font-size: 16px; margin: 10px 0px 0px; color: black;'>Importo: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importoFooter) +
                "</strong> </p><p id='saldate' style='font-size: 16px; margin: 10px; color: black;'>Saldati: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldateFooter) +
                " </strong> </p> <p id='scadute' style='font-size: 16px; margin: 10px; color: black;'>Scaduti: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scaduteFooter) +
                " </strong> </p> <p id='scadonoOggi' style='font-size: 16px; margin: 10px; color: black;'> Scadono oggi: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaOFooter) +
                " </strong> </p><p id = 'scadonoMese' style='font-size: 16px; margin: 10px; color: black;'>Scadono nel mese: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaMFooter) +
                " </strong> </p><p id='nonScadute' style='font-size: 16px; margin: 10px; color: black;'>Non ancora scaduti: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScadutiFooter) +
                " </strong> </p><p id='noteCredito' style='font-size: 16px; margin: 10px; color: black;'>Note Di credito:  " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nCreditoFooter) + " </p> </div>"
        }
        if (e.values) {
            if (e.data.idTipoDocumento == '4' || e.data.idTipoDocumento == '8') {
                e.rowElement.style.backgroundColor = 'rgb(176, 249, 255)';
            }
            else if (e.data.statoPagamento == "I") {
                e.rowElement.style.backgroundColor = 'rgb(244, 246, 137)';
            }
            else if (e.data.statoPagamento == 'M') {
                e.rowElement.style.backgroundColor = 'rgb(255, 204, 102)';
            }
            else if (e.data.statoPagamento == 'P') {
                e.rowElement.style.backgroundColor = 'rgb(150, 255, 164)';
            }
            else if (e.data.statoPagamento == 'S') {
                e.rowElement.style.backgroundColor = 'rgb(252, 230, 232)';
            }

        }
    }



    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)

                    } else {

                    }
                })
        }
    }

    const statoListDataSource = () => {

        let stati: any = [];

        stati.push({ idStato: "D", descrizione: "Da abbinare" });
        stati.push({ idStato: "A", descrizione: "Abbinata" });
        stati.push({ idStato: "P", descrizione: "Parzialmente abbinata" });
        stati.push({ idStato: "N", descrizione: "Non abbinata" });
        stati.push({ idStato: "Q", descrizione: "Approvata" });
        stati.push({ idStato: "C", descrizione: "Contabilizzata" });

        setStatoList(stati)

    }




    const filterAction = (objFiltro: Filtri, tab?: number) => {
        localStorage.clear();
        setLoading(true)
        let params = { ...objFiltro }
        setParamsRicerca(params)
        setIdScadenzario(null)
        if (!tab) {
            setPropTab(0)
        }
        //console.log(params);
        if (params.idFar) {
            params.idFar = Number(params.idFar?.idFar)
        }
        if (params.idStato) {
            params.idStato = (Object)(params.idStato).idStato
        }

        params.dal = trasformaDateTimeInLocale(params.dal, true);
        params.al = trasformaDateTimeInLocale(params.al, true);
        params.flagCicloAttivo = false

        //console.log(params)
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', params, urlConsultazione.getListScad)
                .then(result => {
                    if (result?.esito) {

                        setLoading(false)

                        let entrate = result.response.list.reduce((accumulator: any, object: any) => {
                            if (object.importo) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator;
                            }
                        }, 0);

                        let dataDal = cambiaFormatoLocalDateTimeToEuropeo(params.dal);
                        let dataAl = cambiaFormatoLocalDateTimeToEuropeo(params.al);

                        setSummaryObj({ entrate })
                        setdataDalObj({ dataDal })
                        setdataAlObj({ dataAl })

                        let listaFiltrata: any = []
                        result.response.list.forEach((dato: any) => {
                            //console.log(dato);

                            let dataScadenza = new Date(dato.scadeIl)
                            let dataOggi = new Date()
                            let meseScadenza = dataScadenza.getMonth()
                            let meseCurrent = new Date().getMonth()
                            let scaduta, scadonoMese, scadonoOggi: boolean;
                            scaduta = false
                            scadonoMese = false
                            scadonoOggi = false

                            if (dataScadenza < new Date())
                                scaduta = true
                            else if (dataScadenza <= new Date(dataOggi.getTime() + (1000 * 60 * 60 * 48)))
                                scadonoOggi = true
                            else if (meseScadenza === meseCurrent)
                                scadonoMese = true

                            if (tab && tab != 0) {
                                switch (tab) {
                                    case 0:
                                        listaFiltrata.push(dato)
                                        break;
                                    case 1:
                                        if (dato.statoPagamento == 'P') {
                                            listaFiltrata.push(dato)
                                        }
                                        break;
                                    case 2: if (dato.statoPagamento == 'S' || (dato.statoPagamento == 'C' && scaduta == true)) {
                                        listaFiltrata.push(dato)
                                    }
                                        break;
                                    case 3:
                                        if (dato.statoPagamento == 'I' || (dato.statoPagamento == 'C' && scadonoOggi == true)) {
                                            listaFiltrata.push(dato)
                                        }
                                        break;
                                    case 4:
                                        if ((dato.statoPagamento == 'M') || (dato.statoPagamento == 'C' && scadonoMese == true)) {
                                            listaFiltrata.push(dato)
                                        }
                                        break;
                                    case 5:
                                        if (dato.statoPagamento == 'N') {
                                            listaFiltrata.push(dato)
                                        }
                                        break;
                                    case 6:
                                        if (dato.idTipoDocumento == '4' || dato.idTipoDocumento == '8') {
                                            listaFiltrata.push(dato)
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            } else {
                                listaFiltrata.push(dato)
                            }
                        });

                        setTabellaPrincipale(listaFiltrata)
                        setMax(listaFiltrata.length)
                        setScadenzario(result.response)

                        setSaldate(result.response.saldate)
                        setImporto(result.response.totImporto)
                        setScadute(result.response.scadute)
                        setScadenzaO(result.response.inScadenzaOggi)
                        setScadenzaM(result.response.inScadenzaQuestoMese)
                        setNonScaduti(result.response.nonScadute)
                        setNCredito(result.response.noteDiCredito)
                        setGruppo(result.response.gruppo)

                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {

        farmaList()
        statoListDataSource()
        setOpenPopInfo(false)
        filterAction({
            dal: getDefaultDal(),
            al: getDefaultAl()
        })
    }, [auth]);


    useEffect(() => {

        if (selectedScad && selectedScad.length > 0) {
            setObjtipo(selectedScad[0])

            setDisabledPulsanti(false)
            //console.log(selectedScad)
            //console.log(objtipo)

        }
        else {
            setDisabledPulsanti(true)
            setObjtipo(undefined)
        }

        if (selectedScad && selectedScad.length > 1) {



            setDisabledPulsanti(true)
        }

    }, [selectedScad])

    let propr = [{ id: 0, text: 'Tutte' },
    { id: 1, text: 'Saldati' },
    { id: 2, text: 'Scaduti' },
    { id: 3, text: 'Scadono oggi' },
    { id: 4, text: 'Scadono nel mese' },
    { id: 5, text: 'Non ancora scaduti' },
    { id: 6, text: 'Nota di credito' }

    ]

    const caricaTabella = (value: number) => {

        setPropTab(value);

        if (paramsRicerca.dal) {

            if (paramsRicerca.idFar) {

                filterAction({ ...paramsRicerca, idFar: paramsRicerca }, value);

            } else {

                filterAction(paramsRicerca, value)
            }

        }

        // caricaDati(value == 0 ? true : false)
    }

    const ricarica = () => {
        setOpenPop(false)
        setOpenPop2(false)
        setOpenPop3(false)
        setImmediate(false)
        setOpenPopSaldo(false)
        filterAction(paramsRicerca)

    }

    const getTotalItems = (options: any) => {
        if (options.name == "rowsSummary") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = tabellaPrincipale.length
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryImporto") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importoFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummarySaldati") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldateFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryScaduti") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scaduteFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryScadonoOggi") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaOFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryScadonoQuestoMese") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaMFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryNonAncoraScaduti") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScadutiFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryNoteDiCredito") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = nCreditoFooter
                    break;
                case "finalize":
                    break;
            }
        }

    }

    const onExporting = (e: any) => {

        var workbook = new Workbook();
        var worksheet = workbook.addWorksheet('ScadenzarioFatturePassive');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function ({ gridCell, excelCell }) {
                if (gridCell) {

                    if (gridCell.column) {
                        if (gridCell.column.dataField === 'totale') {

                            if (gridCell.rowType == 'data' && excelCell.value != null && excelCell.value != undefined && gridCell.data && gridCell.data.tipoDocumento.toUpperCase().includes('CREDITO')) {
                                if (excelCell.value > 0)
                                    excelCell.value = -excelCell.value;
                            }
                        }
                        if (gridCell.column.dataField === 'imposta') {
                            if (gridCell.rowType == 'data' && excelCell.value != null && excelCell.value != undefined && gridCell.data && gridCell.data.tipoDocumento.toUpperCase().includes('CREDITO')) {
                                if (excelCell.value > 0)
                                    excelCell.value = -excelCell.value;
                            }
                        }
                        if (gridCell.column.dataField === 'imponibile') {
                            if (gridCell.rowType == 'data' && excelCell.value != null && excelCell.value != undefined && gridCell.data && gridCell.data.tipoDocumento.toUpperCase().includes('CREDITO')) {
                                if (excelCell.value > 0)
                                    excelCell.value = -excelCell.value;
                            }
                        }
                    }
                }
            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Scad_fatt_passive' + getCurrentDateTime() + '.xlsx');
            });
        });
    }

    const onPagamentoClick = () => {

        if (selectedScad && selectedScad.length > 1) {

            let found: boolean = false;
            //selectedScad.forEach((dato: any) => {
            //    if (dato.idTipoPagamento != 0 && dato.importoResiduo == 0 ) {
            //        found = true
            //    }


            //})
            if (found) {
                let result = confirm("<i>Le scadenze saldate saranno sovrascritte. Procedere?</i>", "Attenzione");
                result.then((dialogResult) => {
                    if (dialogResult) {
                        setOpenPop3(true);
                        setImmediate(true)
                    } else {
                        return;
                    }
                });
            }
            else {
                setOpenPop3(true);
                setImmediate(true)
            }

        }
        else {
            setOpenPop3(true);
            setImmediate(true)
        }


    }

    const onInviaDocFinanceClick = () => {

        //schedulazione job flusso doc finance

        //1. DOCFINANCE


        let result = confirm("<i>Inviare i dati a DocFinance?</i>", "Attenzione");
        result.then((dialogResult) => {
            if (dialogResult) {
                if (auth) {
                    RestServices().rest('POST', { idTipoInvio: 1 }, urlConsultazione.inviaFlussoScadenzario)
                        .then(result => {
                            if (result.esito) {
                                notify({ position: "center", width: "auto", message: "Elemento schedulato per l'invio" }, "success", 4000);

                            }
                            else {
                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            }

                        })
                }
            } else {
                return;
            }
        });



    }

    const onReportClick = () => {

        //console.log(max);

        if (max !== selectedScad.length && selectedScad.length !== 0) {

            if (selectedScad.length > 0 && selectedScad.length < 31) {

                let model: any = [];
                // If there are selected items, map through them and add their ids to the model array
                selectedScad.map((item: any) => {
                    const idScad = item.idScadenzario;
                    model.push(idScad);
                });

                let url = urlConsultazione.reportScadenzario + 'dal=' + paramsRicerca.dal + '&al=' + paramsRicerca.al + '&flagCicloAttivo=false';

                if (paramsRicerca.idFar !== undefined)
                    url = url + '&idFar=' + paramsRicerca.idFar;
                if (paramsRicerca.fornitore !== undefined)
                    url = url + '&fornitore=' + paramsRicerca.fornitore;

                url = url + '&idList=' + model;

                window.open(url);

            } else {

                notify({ position: "center", width: "auto", message: "Errore, superato numero elementi selezionabili per il report (30)" }, "error", 4000);
            }

        } else {

            let url = urlConsultazione.reportScadenzario + 'dal=' + paramsRicerca.dal + '&al=' + paramsRicerca.al + '&flagCicloAttivo=false';

            if (paramsRicerca.idFar != undefined)
                url = url + '&idFar=' + paramsRicerca.idFar

            if (paramsRicerca.fornitore != undefined)
                url = url + '&fornitore=' + paramsRicerca.fornitore

            window.open(url);

        }
    }





    const onDeleteIncassiClick = () => {

        if (objtipo) {
            setSelectedIdScadAction(objtipo.idScadenzario)
            setTitoloPagamento("Fattura n. " + objtipo.numeroDocumento + " del " + cambiaFormatoLocalDateTimeToEuropeo(objtipo.data, false) + " - " + objtipo.intestatario)
            setOpenPopPagamento(true)
        }
    };


    return (
        <>
            <DialogInfo
                dialogIsOpen={openPopInfo}
                message={messDel}
                functionNext={() => ('')}
                handleCloseConferma={() => { setOpenPopInfo(false) }}
            />

            <DialogDataSaldo
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPopSaldo(false); setObjtipo({}); setImmediate(false) }}
                open={openPopSaldo} />

            <DialogData
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }}
                open={openPop} />

            <DialogNotePass
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />

            <DialogElencoPagamenti
                close={() => { setOpenPopPagamento(false); }}
                open={openPopPagamento}
                idScadenzario={selectedIdScadAction}
                titolo={titoloPagamento}
                ricarica={() => { filterAction(paramsRicerca) }}

            />


            {openPop3 && setObjtipo ? (
                <DiaPagamPass
                    typeEdit={edit}
                    edit={objtipo}
                    ricarica={() => ricarica()}
                    close={() => { setOpenPop3(false); setObjtipo({}); setImmediate(false) }}
                    open={openPop3}
                    selected={selectedScad}
                />
            ) : null}

            <Loading isVisible={loading} />
            <div className='title'>
                {/*<h3> SCADENZARIO DOCUMENTI RICEVUTI  <span className='subt' > Consultazione Scadenzario</span> </h3>*/}
                <h3> SCADENZARIO DOCUMENTI RICEVUTI </h3>
            </div>
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 4 }} >
                <div className='title'>
                    <h6> Filtro rapido stato pagamento: </h6>
                </div>
                <div style={{ marginTop: 8 }}>
                    <RadioGroup onValueChanged={(x) => caricaTabella(x.value.id)} dataSource={propr} layout="horizontal" value={propr[propTab]} />
                </div>

            </div>
            <div style={{ gap: 16, display: 'flex', flexWrap: 'wrap', background: 'white', padding: 10, borderRadius: 10, marginTop: 4 }}>
                <p style={{ fontSize: 16, margin: 0, color: 'black', marginTop: 10 }}  >Dal:<strong> {dataDalObj.dataDal} </strong>  </p>
                <p style={{ fontSize: 16, margin: 0, color: 'black', marginTop: 10 }}  >Al: <strong>{dataAlObj.dataAl} </strong> </p>
                <p style={{ fontSize: 16, margin: 0, color: 'black', marginTop: 10 }}>Importo: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importo)} </strong> </p>
                <div className='leg5'>  <p style={{ fontSize: 16, margin: 0, color: 'black' }}>Saldati: <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldate)} </strong> </p>   </div>
                <div className='leg4'><p style={{ fontSize: 16, margin: 0, color: 'black' }}>Scaduti: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadute)} </strong> </p> </div>
                <div className='leg1'> <p style={{ fontSize: 16, margin: 0, color: 'black' }}> Scadono oggi: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaO)} </strong> </p></div>
                <div className='leg2'><p style={{ fontSize: 16, margin: 0, color: 'black' }}>Scadono nel mese: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaM)} </strong> </p> </div>
                <div className='leg6'> <p style={{ fontSize: 16, margin: 0, color: 'black' }}>Non ancora scaduti: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScaduti)} </strong> </p> </div>
                <div className='leg3'><p style={{ fontSize: 16, margin: 0, color: 'black' }}>Note Di credito: <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nCredito)} </strong> </p>  </div>
            </div>



            <div className='button_primabanche'>
                <Button disabled={objtipo ? false : true} onClick={onPagamentoClick} variant="primary" >
                    PAGAMENTO
                </Button>
                <Button style={{ marginLeft: 5 }} disabled={objtipo && (objtipo.statoPagamentoScadenza === "Saldata" || objtipo.statoPagamentoScadenza === "Parzialmente pagata") ? false : true} title="Annulla Incasso" onClick={() => { onDeleteIncassiClick() }} >
                    <FontAwesomeIcon icon={faTrashAlt} className="pdfFileIcon" />
                    ANNULLA INCASSI
                </Button>
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.linkAbbinamento && !disabledPulsanti ? false : true}
                    onClick={() => { window.open(objtipo.linkAbbinamento, "_blank") }}
                    variant="primary" >
                    ABBINAMENTO
                </Button>
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.linkQuadratura && !disabledPulsanti ? false : true}
                    onClick={() => { window.open(objtipo.linkQuadratura, "_blank") }}
                    variant="primary" >
                    QUADRATURA
                </Button>
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.linkFattura && !disabledPulsanti ? false : true}
                    onClick={() => { window.open(objtipo.linkFattura, "_blank") }}
                    variant="primary" >
                    VISUALIZZA
                </Button>
                <Button style={{ marginLeft: 5 }} title="Esport tutti i dati pdf" onClick={onReportClick} disabled={!(tabellaPrincipale.length > 0)} >
                    <FontAwesomeIcon icon={faFilePdf} className="pdfFileIcon" />
                    REPORT
                </Button>
                {gruppo && gruppo === 90007 &&
                    <Button style={{ marginLeft: 5 }} title="Invia flussi a DocFinance" onClick={onInviaDocFinanceClick} >
                        INVIA TUTTE LE SCADENZE A DOCFINANCE
                    </Button>
                }
            </div>

            <NewTabella
                idTabella='scade_fatt_passive'
                sync
                multyp
                tabReference={setTabellaInstance}
                colonne={columns}
                dataSource={tabellaPrincipale}
                allowColumnReordering={true}
                filterRow
                headerFilter
                searchPanel={true}
                toolbar={true}
                exportT={{
                    enabled: true,
                    fileName: "esportazione_scadenzario_passivo_" + getCurrentDateTime()
                }}
                activeSaver
                columnC
                onExporting={onExporting}
                selectedValue={(datiRow: any) => setSelectedScad(datiRow.selectedRowKeys)}
                filteredDataShow={(istanza) => {
                    const dataV: any = istanza.component.getDataSource();
                    //let entrate = dataV._items.reduce((accumulator: any, object: any) => {
                    //    if (object.importo) {
                    //        return accumulator + object.importo;
                    //    } else {
                    //        return accumulator;
                    //    }
                    //}, 0);

                    let saldate = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'P') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }
                    }, 0);

                    let totScadute = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'S') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totOggi = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'I') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);


                    let totProssimeMese = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'M') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totSuccessive = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'N') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totNote = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'C') {
                            //if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                            //    return accumulator + object.importo;
                            //} else {
                            return accumulator - object.importo;
                            //}
                        } else {
                            return accumulator
                        }

                    }, 0);




                    let entrate = saldate + totScadute + totOggi + totProssimeMese + totSuccessive + totNote
                    setSaldateFooter(saldate)
                    setImportoFooter(entrate)
                    setScaduteFooter(totScadute)
                    setScadenzaOFooter(totOggi)
                    setScadenzaMFooter(totProssimeMese)
                    setNonScadutiFooter(totSuccessive)
                    setNCreditoFooter(totNote)
                    //let el2 = document.getElementById('footer')
                    //if (el2 != undefined)
                    //    el2.innerText = 'Elmenti totali: ' + tabellaPrincipale.length + '&emsp;&emsp;&emsp;Importo: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entrate);
                    let el3 = document.getElementById('importo')
                    if (el3 != undefined)
                        el3.innerText = 'Importo: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entrate);
                    let el4 = document.getElementById('saldate')
                    if (el4 != undefined)
                        el4.innerText = 'Saldati: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldate);
                    let el5 = document.getElementById('scadute')
                    if (el5 != undefined)
                        el5.innerText = 'Scaduti: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totScadute);
                    let el6 = document.getElementById('scadonoOggi')
                    if (el6 != undefined)
                        el6.innerText = 'Scadono oggi: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totOggi);
                    let el7 = document.getElementById('scadonoMese')
                    if (el7 != undefined)
                        el7.innerText = 'Scadono nel mese: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totProssimeMese);
                    let el8 = document.getElementById('nonScadute')
                    if (el8 != undefined)
                        el8.innerText = 'Non ancora scaduti: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totSuccessive);
                    let el9 = document.getElementById('noteCredito')
                    if (el9 != undefined)
                        el9.innerText = 'Note di credito: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totNote);

                }}


                onclickRow={(e) => {
                    var component = e.component,
                        prevClickTime = component.lastClickTime;
                    component.lastClickTime = new Date();
                    if (prevClickTime && (component.lastClickTime - prevClickTime < 300)) {
                        //Double click code  
                        //console.log(e.data);
                        setOpenPop3(true);
                        // setOpenLink(true);
                        setObjtipo(e.data)
                    }
                    else {
                        //setObjtipo(e.data)
                        //Single click code  

                    }
                }}


                summary={
                    <Summary calculateCustomSummary={getTotalItems}>

                        <TotalItem key="id"

                            name="rowsSummary"
                            displayFormat="Elementi totali: {0}"
                            summaryType="custom"
                            showInColumn="scadeIl" />
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryImporto"*/}
                        {/*    displayFormat="Importo: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="importo" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummarySaldati"*/}
                        {/*    displayFormat="Saldati: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="farmacia" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryScaduti"*/}
                        {/*    displayFormat="Scaduti: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="intestatario" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryScadonoOggi"*/}
                        {/*    displayFormat="Scadono oggi: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="categoriaFornitore" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryScadonoQuestoMese"*/}
                        {/*    displayFormat="Scadono nel mese: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="tipoDocumento" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryNonAncoraScaduti"*/}
                        {/*    displayFormat="Non scaduti: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="numeroDocumento" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryNoteDiCredito"*/}
                        {/*    displayFormat="Note di credito: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="dataDocumento" />*/}

                    </Summary>
                }
                onRowPrepared={onRowPrepared}

            />
            {
                //    <div style={{ gap: 16, display: 'flex', flexWrap: 'wrap', background: 'white', padding: 10, borderRadius: 10, marginTop: 4 }}>
                //        <p style={{ fontSize: 16, margin: 0, color: 'black', marginTop: 10, marginLeft:115 }}  ><strong>Totali parziali: </strong>  </p>
                //    <p style={{ fontSize: 16, margin: 0, color: 'black', marginTop: 10 }}  ><strong></strong> </p>
                //    <p style={{ fontSize: 16, margin: 0, color: 'black', marginTop: 10 }}>Importo: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importoFooter)} </strong> </p>
                //    <div className='leg5'>  <p style={{ fontSize: 16, margin: 0, color: 'black' }}>Saldati: <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldateFooter)} </strong> </p>   </div>
                //    <div className='leg4'><p style={{ fontSize: 16, margin: 0, color: 'black' }}>Scaduti: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scaduteFooter)} </strong> </p> </div>
                //    <div className='leg1'> <p style={{ fontSize: 16, margin: 0, color: 'black' }}> Scadono oggi: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaOFooter)} </strong> </p></div>
                //    <div className='leg2'><p style={{ fontSize: 16, margin: 0, color: 'black' }}>Scadono nel mese: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaMFooter)} </strong> </p> </div>
                //    <div className='leg6'> <p style={{ fontSize: 16, margin: 0, color: 'black' }}>Non ancora scaduti: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScadutiFooter)} </strong> </p> </div>
                //    <div className='leg3'><p style={{ fontSize: 16, margin: 0, color: 'black' }}>Note Di credito: <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nCreditoFooter)} </strong> </p>  </div>
                //</div>
            }
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div className='title2'>
                    <h6> Legenda Colori: </h6>
                </div>
                <div className='leg5'>
                    <h6 >  Saldati </h6>
                </div>
                <div className='leg4'>
                    <h6> Scaduti </h6>
                </div>
                <div className='leg1'>
                    <h6>  Scadono oggi </h6>
                </div>
                <div className='leg2'>
                    <h6> Scadono nel mese </h6>
                </div>
                <div className='leg6'>
                    <h6> Non ancora scaduti  </h6>
                </div>
                <div className='leg3'>
                    <h6> Note di credito  </h6>
                </div>
            </div>


        </>
    )
}

export default FattPassive

