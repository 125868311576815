import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { useAuth } from '../../hooks/useAuth'
import { ElencoReportInterface } from '../../interfaces/report';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { Loading } from '../../widget/Notifications';
import { basenameHostNewTabs, urlReport } from '../costanti';
import { TabellaCompleta } from '../../widget/Tabelle';
import { OnRowDblClickInterface } from '../../interfaces/tabelle';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';

const Report = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean
}) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);
    const auth = useAuth(urlReport.auth);
    useEffect(() => {
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', "", urlReport.reportList)
                .then(result => {
                    if (result) {
                        setIsVisible(false);
                        setElencoReport(result);
                    }
                })
        }
    }, [auth]);

    const titolo = TitoloContenuto("Report");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [elencoReport, setElencoReport] = useState<ElencoReportInterface[]>([]);

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data && rowDblClicked.data.codice) {
            const path = basenameHostNewTabs + "apriReport?" + rowDblClicked.data.codice + "&true";
            window.open(path);
        }
    }
    const [tabellaInstance, setTabellaInstance] = useState();

    const propsTabElencoReport = {
        id: "elenco_report",
        dataSource: elencoReport,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: false,
        summary: {
            groupItems: [{
                summaryType: "count",
                column: "Categoria"
            }]
        },
        export: {
            enabled: false
        }
    }
    const columnsElencoReport = CreaColonne([
        { caption: "Categoria", dataField: "categoria", sortOrder: "asc", groupIndex: 0 },
        { caption: "Titolo", dataField: "titolo", sortOrder: "asc" },
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Data Creazione", dataField: "dataCreazione", format: "dd/MM/yyyy", dataType: "date" },
    ])

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row>
                    <Col sm='12' className="elencoReport">
                        <TabellaCompleta propsTabella={propsTabElencoReport} columns={columnsElencoReport} onRowDblClick={onRowDblClick} setTabellaInstance={setTabellaInstance} />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Report