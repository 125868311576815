import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import { AplicaValoreTO } from '../../../funzioni/operazioniSuTabelle'
import { legendByarray } from '../../../funzioni/formatoVariabili'

import "../style.css";
import { NewTabella } from "../../../widget/nuova_tabella/newVersion"

interface DialogProps { 
  open: boolean,
  edit: any,
  ricarica: () => void,
  close: ()=>void,
}


export const DialogProdMagazz: React.FC<DialogProps> = ( { open, close,edit,ricarica}) => {

  const [updateInvio,setUpdateInvio] = useState<any>(null)
  const [openD, setOpenD] = useState <boolean>(false)
  const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})
  const [loading, setLoading] = useState <boolean>(false)

  const columns =  [
    { caption: "Magazzino", dataField: "descrizioneMagazzino" },
    { caption: "Giacenza", dataField: "giacenza", width: 100 },

]


  const getListFarm = () =>{
    if (auth) {
       return RestServices().rest('POST', {} , urlConsultazione.getListFar)
    }
  }


  const caricaDati = async() =>{
    if (auth) {
      let farmacieLista = await getListFarm()
      RestServices().rest('POST', {codCsf:edit.codCsf, idFar:edit.idFar } , urlConsultazione.getListGiacenzaProdMagazz)
      .then(result => {
          if (result?.esito) {
              let valoreFormatt = legendByarray(
                result.response,
                farmacieLista.response,
                'idFar',
                'idFar',
                'descrix',
                ['descrizione'])
            
              setTabellaPrincipale(valoreFormatt)
              setLoading(false)
          }else{
              notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
              setLoading(false)
          }
      })
   }
  }
  


  const cambiaValore = (tipo:string,value:string) =>{
    let objTo = {...objctTOsave}
    objTo[tipo] = value
    setObjctTOsave(objTo)
}



useEffect(() => {
if (edit) {
            let objTo = { ...objctTOsave };
            objTo.descrizioneProdotto = edit.descrizioneProdotto;
            objTo.codProd = edit.codProd;
            objTo.descDittaTitolare = edit.codCsf;
            objTo.giacenza = edit.giacenza;
            objTo.prezzoListino = edit.prezzoListino;
            objTo.costoAcquisto = edit.costoAcquisto;
            objTo.generalCostoMedioPonderato = edit.generalCostoMedioPonderato;
            objTo.generalPrezzoListino = edit.generalPrezzoListino;
            objTo.descrix = edit.descrix;
            setObjctTOsave(objTo);
            }
            }, [edit, openD]);

useEffect(() => {
            // if(open){
                setOpenD(open);
            // }
            if(open && auth){
                
            }

            }, [open,auth]);


            useEffect(()=>{
                caricaDati()
                },[open])




  const renderTitle = () => {
    return (
      <h5> Dettaglio Magazzini Farmacia :{objctTOsave.descrix}</h5>
    );
}

return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'75%'}
      height={'85%'}
      showTitle={true}
      titleRender={renderTitle}
      
    >
         <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }
            }
          />

<ScrollView height="100%"  width="100%">   


<div className='form-row '> 
      <div className="form-group col-md-2">
          <label> CODICE Minsan </label>
          <TextBox disabled value={objctTOsave.codProd}  />
        </div>

        <div className="form-group col-md-4">
          <label> Descrizione Prodotto </label>
          <TextBox disabled value={objctTOsave.descrizioneProdotto} />
        </div>

        <div className="form-group col-md-2">
          <label> Giacenza Farmacia </label>
          <TextBox disabled value={objctTOsave.giacenza}  />
        </div>
        <div className="form-group col-md-2">
          <label> Prezzo Banca Dati </label>
          <TextBox disabled value={objctTOsave.generalPrezzoListino}  />
        </div>

        <div className="form-group col-md-2">
          <label> Costo Ultimo </label>
          <TextBox disabled value={objctTOsave.generalCostoMedioPonderato}  />
        </div>
        </div>

        

        
        <div style={{ width: '100%'}}>
        <div  className=''>
                <h3> DETTAGLIO MAGAZZINO  <span className='subt' ></span> </h3>
             </div>
          <NewTabella
            idTabella="farm"
            sync
            colonne={columns}
            height={'auto'}
            hidePager={false}
            dataSource={tabellaPrincipale}
            //allowColumnResizing={true}
            //hideColumnHeaders={true}
            //specialPd={0.1}
            exportT={{
              enabled: false,
              fileName: "list_farm",
            }} 
          />

        </div>
        
            
      </ScrollView>

    </Popup>
     );
    };