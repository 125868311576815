import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Alert } from "react-bootstrap";
import { Popup } from "devextreme-react/popup";

export enum typeShow {
  euro,
  percento
}

export interface cardG {
  testo: string;
  keyValue: string;
  second?: boolean;
  testoPop?: string;
  puntale?: string
}


export interface cardV {
  testo?: string;
  subTesto?: string;
  keyValue?: string | undefined;
  valore?: string;
  subValore?: string;
  typeShowValue?: typeShow;
  typeShowSubValue?: typeShow;
  testoPop?: string;
  second?: boolean;
  second2?: string;
  starter?: boolean;
}

export interface cardC {
  testo?: string;
  subTesto?: string;
  keyValue?: string | undefined;
  valore?: string;
  subValore?: string;
  testoPop?: string;
  second?: boolean;
  second2?: string;
  starter?: boolean;
  typeShowValue?: typeShow;
  typeShowSubValue?: typeShow;
}

export const CardCart: React.FC<cardC> = ({
  testo,
  subTesto,
  valore,
  subValore,
  starter,
  testoPop,
  second2
}) => {
  let valoreToUse;
  let valoreToUseOld;

  const [visiblePop, setVisiblePop] = useState(false);

  return (
    <div
      style={{
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
      className="item border-solid"
    >
      <Popup
        visible={visiblePop}
        showCloseButton={true}
        onHiding={() => setVisiblePop(false)}
        width={"50%"}
        height={"50%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{testoPop}</p>
        </div>
      </Popup>
      <div style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}>
        <div>
          <p
            style={{
              display: "flex",
              alignItems: "right",
              justifyContent: "right",
              marginRight: "5px"
            }}
          >
            {second2 ? (
              <p style={{ padding: 2 }}>{second2}</p>
            ) : (
              <p></p>
            )}
            <span>
              {" "}
              <button
                className="ibutton"
                style={{ border: "none" }}
                onClick={() => setVisiblePop(true)}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </span>
          </p>
          <p style={{ margin: 0 }}>{testo}</p>
          <h4 style={{ margin: 0, fontSize: "24px", color: "#00CEE6" }}>
            {" "}
            {starter ? valore : 0}
          </h4>
        </div>
        {subValore ? (
          <div style={{ background: "#efefef", marginTop: 10, padding: 5 }}>
            <p>
              {" "}
              {subTesto}{" "}
              <span>
                <strong>{starter ? subValore : 0}</strong>
              </span>
            </p>
          </div>
        ) : (
          <p></p>
        )}
      </div>
    </div>
  );
};
