import React, { useEffect, useState } from 'react'
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { legendByarray } from '../../funzioni/formatoVariabili'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'


//import './style.css';


const MonitFarmacie = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    
    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    
    const columns = [
        { caption: "Stato", dataField: "statoFarmacia" , width: 100, render:(e:any)=>{
         switch (e.value) {
            case 0:
                return(<div style={{margin: "auto"}} title="Sincronizzato" className="circle-green"></div>)
            case 1:
                return(<div style={{margin: "auto"}} title="Ritardo" className="circle-yellow"></div>)
            case 2:
                return(<div style={{margin: "auto"}} title="Allerta" className="circle-red"></div>)
            case 3:
                return(<div style={{margin: "auto"}} title="Mai Sincronizzato" className="circle-grey"></div>)
            case 4:
                return(<div style={{margin: "auto"}} title="Non Gestito" className="circle-black"></div>)
            case 5:
                return(<div style={{margin: "auto",textAlign: 'center'}}  title="Caricamento in corso"><img style={{width:17,height:17}} src='/app/loading.gif' /></div>)
            case 6:
                return(<div style={{margin: "auto",textAlign: 'center'}}  title="Errore"><svg style={{width:20,height:20}} className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg></div>)
            default:
                return(<div style={{margin: "auto"}} title="Sincronizzato" className="circle-green"></div>)
         }   
        }},
        { caption: "Codice Farmacia", dataField: "codiceCF", width: 100 ,sortOrder: "asc" },
        { caption: "Descrizione Farmacia", dataField: "descFarm"   },
        { caption: "Gestionale", dataField: "descGes" },
        { caption: "Data Ultimo File Ricevuto", dataField: "dataFineCaricamento" ,  format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data Ultima Vendita", dataField: "dataUltimaVendita", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data Ultimo Ordine", dataField: "dataUltimoOrdine", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        
    ];
    

  


      
        const getListMoniFarmacie = () =>{
            if (auth) {
                return  RestServices().rest('POST', {  } , urlConsultazione.getListMonitFarmacie)
                    
                      }

                }



    

    const caricaDati = async() =>{
        if (auth){
            let farmacieLista = await  RestServices().rest('POST', {} , urlConsultazione.getListFar)
            //console.log('farmacie');
            //console.log(farmacieLista.response);
                     
            let listaTabella = await getListMoniFarmacie() 
            //let farmacieLista = await farmaList()
                   
            if (listaTabella?.esito) { 
                let tabellaNew:any = []

                let valoreFormatt = legendByarray(
                         listaTabella.response,
                         farmacieLista.response,
                            'idFar',
                             'idFarm',
                             'codiceCF',
                             ['codice'])

                        //console.log('nuovo objjjj');
                        //console.log(valoreFormatt);
                        const sortValue = valoreFormatt.sort((a:any,b:any) => (a.codiceCF > b.codiceCF) ? 1 : ((b.codiceCF > a.codiceCF) ? -1 : 0))

                        //console.log(valoreFormatt);
                         
                        setTabellaPrincipale(sortValue)
                        setLoading(false) 

               
            }
                  
            else{
                notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)
                setLoading(false)
            }
        }
    }    

  



    useEffect(()=>{
       caricaDati() 
        
    },[auth])
    
    useEffect(() => {
       if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

 
   
    return (
        <>
             
        <Loading isVisible={loading} />
        <div  className='title_cluster'>
                <h3> MONITORAGGIO FARMACIE </h3>
             </div>
              
             
            <NewTabella 
                idTabella='Ana_farmacie'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                searchPanel={true}
                exportT={{
                    enabled: false,
                    fileName: "Anagraf_farmacie"
                }}
                height='90vh'
                
            />
        </>
    )
}

export default MonitFarmacie