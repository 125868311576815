import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { DialogInfo, Loading  } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlCommon,urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione/interface'
import Button from 'react-bootstrap/Button'
import { RadioGroup } from 'devextreme-react/radio-group'
import { Link } from 'react-router-dom'
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale, getCurrentDateTime } from '../../funzioni/formatoVariabili';
import { AplicaValoreTO, actionTables } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'
import { DialogNote } from "./dialog/pop_note"
import { DialogDataAttiva } from "./dialog/Pop_dataAtt"
import {  DiaPagamAtt } from "./dialog/pop_pag_att"
import { DialogDataSaldo } from "./dialog/pop_dele_dat_saldo"
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { confirm } from 'devextreme/ui/dialog';

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'


const FattAttive = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idScadenzario,setIdScadenzario] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [newFarmaList,setNewFarmaList] = useState([])
    const [propTab,setPropTab] = useState<number>(0)
    const [summaryObj,setSummaryObj] = useState<any>({})
    const [scadenzario,setScadenzario] = useState<any>({})
    const [dataDalObj,setdataDalObj] = useState<any>({})
    const [dataAlObj,setdataAlObj] = useState<any>({})
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [openPop2 , setOpenPop2 ] = useState<boolean>(false)
    const [openPop3 , setOpenPop3 ] = useState<boolean>(false)
    const [openPopSaldo , setOpenPopSaldo ] = useState<boolean>(false)
    const [edit,setImmediate] = useState<boolean>(false)
    const [objtipo,setObjtipo] = useState<any>(null)
    const [paramsRicerca,setParamsRicerca] = useState<any>({})
    const [messDel,setMessDel] = useState<string>('')
    const [openPopInfo , setOpenPopInfo ] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [importo,setImporto] = useState<number>(0)
    const [saldate,setSaldate] = useState<number>(0)
    const [scadute,setScadute] = useState<number>(0)
    const [scadenzaO,setScadenzaO] = useState<number>(0)
    const [ScadenzaM,setScadenzaM] = useState<number>(0)
    const [nonScaduti,setNonScaduti] = useState<number>(0)
    const [nCredito, setNCredito] = useState<number>(0)
    const [importoFooter, setImportoFooter] = useState<number>(0)
    const [saldateFooter, setSaldateFooter] = useState<number>(0)
    const [scaduteFooter, setScaduteFooter] = useState<number>(0)
    const [scadenzaOFooter, setScadenzaOFooter] = useState<number>(0)
    const [ScadenzaMFooter, setScadenzaMFooter] = useState<number>(0)
    const [nonScadutiFooter, setNonScadutiFooter] = useState<number>(0)
    const [nCreditoFooter, setNCreditoFooter] = useState<number>(0)
    const [selectedScad, setSelectedScad] = useState<any>([])
    const [disabledPulsanti, setDisabledPulsanti] = useState<boolean>()


    const columns = [
        
         { caption: "Scade il", dataField: "scadeIl", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date", width: 150, render:(e:any)=>{
            // console.log('valore data');
            // console.log(e);
            return(
                <a style={{ cursor:'pointer', fontWeight:'bold',color:'blue'}}
                 onClick={()=>{setOpenPop(true);setObjtipo(e.data); setImmediate(true)}}>{e.text}</a>
            )
        }},
        { caption: "",width: 40,  type: "buttons", buttons: [
            {
                hint: "Avviso",
                icon: "info",
                visible:(x:any)=>isIconVisible(x),
                onClick: (e: any)  => {
                    if(e.row.data.avviso){
                        setMessDel(e.row.data.avviso)
                        setOpenPopInfo(true)
                        // notify({ position: "center", width: "auto", message:e.row.data.avviso }, "info", 7000) 
                     }
                }
            },] 
          },
        { caption: "Data Rif.", dataField: "dataRiferimento", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Num. Doc.", dataField: "numeroDocumento", /*width: 60,*/ dataType: "string" },
        { caption: "Data Doc.", dataField: "data", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Tipo Doc.", dataField: "tipoDocumento", width: 100, dataType: "string" },
        { caption: "Importo Rata", dataField: "importo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 90,dataType: "number" },
        { caption: "Importo Doc.", dataField: "totale", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Imponibile Doc.", dataField: "imponibile", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Imposta Doc.", dataField: "imposta", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100, dataType: "number" },
        { caption: "Farmacia", dataField: "farmacia", width: 200, dataType: "string" },
        { caption: "Cessionario/Committente (Cliente)", dataField: "intestatario", width: 260, dataType: "string" },
        //{ caption: "Cat. Fornitore", dataField: "categoriaFornitore", width: 120 },
        {
            caption: "Data Incasso", dataField: "dataSaldo", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date", width: 95, //render: (e: any) => {
            //    // console.log('valore data');
            //    // console.log(e); 
            //    return (
            //        <a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
            //            onClick={() => onDeleteIncassoClick(e.data)}>

            //            {e.text}
            //        </a>
            //    )
            //}
        },
        { caption: "Tipo Incasso", dataField: "pagamento", width: 140, dataType: "string" },
        { caption: "Riferimento", dataField: "riferimento", width: 120, dataType: "string" },
        { caption: "Banca", dataField: "banca", width: 130, dataType: "string" },
        { caption: "C/C", dataField: "concoCorrente", width: 130, dataType: "string" },
        { caption: "Note", dataField: "annotazione", width: 130, dataType: "string" }, 
        //{ caption: "Stato", dataField: "stato", width: 80 },
        {
            caption: "Annotazione", dataField: "note", width: 100, dataType: "string", render: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Elimina scadenza",
                    icon: "trash",
                    onClick: (e: any) => {
                        //console.log (e)
                        if (e.row.data) {
                            let resultD = confirm("<i>Eliminare la scadenza?</i>", "Attenzione");
                            resultD.then((dialogResult) => {
                                if (dialogResult) {
                                    //elimina
                                    setLoading(true)
                                    let param = { flagCicloAttivo: true, idScadenzario: e.row.data.idScadenzario }
                                    RestServices().rest('POST', param, urlConsultazione.deleteScadenza)
                                        .then(result => {
                                            if (result?.esito) {
                                                if (result.response.esito == true) {
                                                    notify({ position: "center", width: "auto", message: "Scadenza eliminata con successo" }, "success", 4000)
                                                    setLoading(false)
                                                    ricarica()
                                                }

                                            } else {
                                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                                setLoading(false)
                                            }
                                        })


                                } else {
                                    return;
                                }
                            });
                        }


                    },
                }
            ]
        }
        //{ caption: "Giorni", dataField: "giorni" }, 
        //{ caption: "Scadenzario", dataField: "idScadenzario", width: 60 }, 

    ];



    
    const isIconVisible = (e:any) =>{
        if(e.row.data.avviso){
          return true;
        }else{
            return false;
        }     
      }


    const getDefaultDal = () => {

        //ritorna la dta iniziale del trimetre corrente
        var month = new Date().getMonth() + 1;

        if (month < 4)
            return new Date().getFullYear() + '-' + (1) + '-01'

        else if (month < 7)
            return new Date().getFullYear() + '-' + (4) + '-01'
        else if (month < 10)
            return new Date().getFullYear() + '-' + (7) + '-01'
        else if (month < 13)
            return new Date().getFullYear() + '-' + (10) + '-01'

    }

    const getDefaultAl = () => {

        //ritorna la dta iniziale del trimetre corrente
        var month = new Date().getMonth() + 1;

        if (month < 4)
            return new Date().getFullYear() + '-' + (3) + '-31'

        else if (month < 7)
            return new Date().getFullYear() + '-' + (6) + '-30'
        else if (month < 10)
            return new Date().getFullYear() + '-' + (9) + '-30'
        else if (month < 13)
            return new Date().getFullYear() + '-' + (12) + '-31'
    }

    const itemsFields = [
        { type: 'select', idValue: 'idFar', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: newFarmaList, width: 350 },
        { type: 'string', idValue: 'fornitore', label: 'Cessionario/Committente ', dataSource: null, width: 350 },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, require: true, defValue: getDefaultDal(), width: 250 },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null, require: true, defValue: getDefaultAl(), width: 250 },
        
    ]

    


    const onRowPrepared = (e: any) => {

        if (e.rowType == "totalFooter") {
            //console.log("footer")
            e.rowElement.deleteCell(1);
            e.rowElement.deleteCell(1);
            e.rowElement.cells[0].colSpan = 20;
            e.rowElement.cells[0].innerHTML = "<div style='gap: 16px; display: flex; flex-wrap: wrap; background: white; padding: 10px; border-radius: 10px; margin-top: 4px;'><p style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong> Elementi Totali:  </strong>  </p><p id='elementiTotali' style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong >" + tabellaPrincipale.length + "</strong> </p><p style='font-size: 16px; margin: 10px 0px 0px; color: black;'><strong> Totali parziali:  </strong>  </p><p id='importo' style='font-size: 16px; margin: 10px 0px 0px; color: black;'>Importo: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importoFooter) + "</strong> </p><p id='saldate' style='font-size: 16px; margin: 10px; color: black;'>Saldati: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldateFooter) + " </strong> </p> <p id='scadute' style='font-size: 16px; margin: 10px; color: black;'>Scaduti: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scaduteFooter) + " </strong> </p> <p id='scadonoOggi' style='font-size: 16px; margin: 10px; color: black;'> Scadono oggi: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaOFooter) + " </strong> </p><p id = 'scadonoMese' style='font-size: 16px; margin: 10px; color: black;'>Scadono nel mese: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaMFooter) + " </strong> </p><p id='nonScadute' style='font-size: 16px; margin: 10px; color: black;'>Non ancora scaduti: <strong> " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScadutiFooter) + " </strong> </p><p id='noteCredito' style='font-size: 16px; margin: 10px; color: black;'>Note Di credito:  " + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nCreditoFooter) + " </p> </div>"
        }

        if (e.values) {
            if ( e.data.idTipoDocumento == '4' || e.data.idTipoDocumento == '8') {
                e.rowElement.style.backgroundColor = 'rgb(176, 249, 255)';
            }
            else if (e.data.statoPagamento == "I" ) {
                e.rowElement.style.backgroundColor = 'rgb(244, 246, 137)';
            }
            else if (e.data.statoPagamento == 'M') {
                e.rowElement.style.backgroundColor = 'rgb(255, 204, 102)';
            }
            else if (e.data.statoPagamento == 'P') {
                    e.rowElement.style.backgroundColor = 'rgb(150, 255, 164)';
            }
            else if (e.data.statoPagamento == 'S') {
                e.rowElement.style.backgroundColor = 'rgb(252, 230, 232)';
            }
            
            
        }
    }


    
    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {
                       //console.log(result.response);
                       setNewFarmaList(result.response)
                                         
                   }else{
  
                   }
               })     
       }
   }


   

    const filterAction = (objFiltro:Filtri,tab?:number) =>{
        setLoading(true)
        setObjtipo(null)
        let params = {...objFiltro}
        setParamsRicerca(params)
        setIdScadenzario(null)
        if(!tab){
            setPropTab(0)
        }
        //console.log(params);
            if(params.idFar){
                params.idFar = Number(params.idFar?.idFar)
            }     
            params.dal = trasformaDateTimeInLocale(params.dal , true);
            params.al = trasformaDateTimeInLocale(params.al, true);
            params.flagCicloAttivo = true
            
            //console.log(params);  
            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListScad)
                        .then(result => {
                            if (result?.esito) {
                              
                                setLoading(false)   

                                let entrate = result.response.list.reduce((accumulator:any, object:any) => {
                                    if( object.importo){
                                        return accumulator + object.importo;
                                    }else{
                                        return accumulator ;
                                    }                                
                                  }, 0);
                                let dataDal = cambiaFormatoLocalDateTimeToEuropeo (params.dal);
                                let dataAl = cambiaFormatoLocalDateTimeToEuropeo (params.al);
                    
                                setSummaryObj({entrate})
                                setdataDalObj({dataDal})
                                setdataAlObj({dataAl})                           

                                let listaFiltrata:any = []
                                result.response.list.forEach((dato:any) => {
                                    //console.log(dato);

                                    let dataScadenza = new Date(dato.scadeIl)
                                    let dataOggi = new Date()
                                    let meseScadenza = dataScadenza.getMonth()
                                    let meseCurrent = new Date().getMonth()
                                    let scaduta, scadonoMese, scadonoOggi: boolean;
                                    scaduta = false
                                    scadonoMese = false
                                    scadonoOggi = false

                                    if (dataScadenza < new Date())
                                        scaduta = true
                                    else if (dataScadenza <= new Date(dataOggi.getTime() + (1000 * 60 * 60 * 48)))
                                        scadonoOggi = true
                                    else if (meseScadenza === meseCurrent)
                                        scadonoMese = true


                                    if(tab && tab != 0){
                                        switch (tab) {
                                            case 0:
                                                listaFiltrata.push(dato)
                                                break;
                                            case 1:
                                                if(dato.statoPagamento == 'P'){
                                                    listaFiltrata.push(dato)
                                                }                            
                                                break;
                                            case 2: if (dato.statoPagamento == 'S' || (dato.statoPagamento == 'C' && scaduta == true)){
                                                listaFiltrata.push(dato)
                                              }
                                                 break;                      
                                            case 3:
                                                if (dato.statoPagamento == 'I' || (dato.statoPagamento == 'C' && scadonoOggi == true)){
                                                    listaFiltrata.push(dato)
                                                }
                                                break;
                                            case 4:
                                                if (dato.statoPagamento == 'M' || (dato.statoPagamento == 'C' && scadonoMese == true)){
                                                    listaFiltrata.push(dato)
                                                }
                                                break;
                                            case 5:
                                                if(dato.statoPagamento == 'N'){
                                                    listaFiltrata.push(dato)
                                                }
                                                break; 
                                            case 6:
                                                if( dato.idTipoDocumento == '4' || dato.idTipoDocumento == '8' ){
                                                    listaFiltrata.push(dato)
                                                }
                                                break;                                       
                                            default:
                                                break;
                                        }
                                    }else{
                                        listaFiltrata.push(dato)
                                    }                               
                                });
                                setTabellaPrincipale(listaFiltrata)                          
                                setScadenzario(result.response) 

                                
                                setSaldate(result.response.saldate)
                                setImporto(result.response.totImporto)
                                setScadute(result.response.scadute)
                                setScadenzaO(result.response.inScadenzaOggi)
                                setScadenzaM(result.response.inScadenzaQuestoMese)
                                setNonScaduti(result.response.nonScadute)
                                setNCredito(result.response.noteDiCredito)
                                
                            }else{
                                //console.log(result);
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        
    }
    

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {         
        farmaList()
        setOpenPopInfo(false)
        filterAction ({
            dal: getDefaultDal(),
            al: getDefaultAl()
         })
    }, [auth]);
    

    useEffect(() => {

        if (selectedScad && selectedScad.length > 0) {
            setObjtipo(selectedScad[0])

            setDisabledPulsanti(false)
            //console.log(selectedScad)
            //console.log(objtipo)
        }
        else {
            setDisabledPulsanti(true)
            setObjtipo(undefined)
        }

        if (selectedScad && selectedScad.length > 1) {
            setDisabledPulsanti(true)
        }

    }, [selectedScad])


     let propr = [{ id: 0, text: 'Tutte' },
     { id: 1, text: 'Saldati' },
     { id: 2, text: 'Scaduti'},
     { id: 3, text: 'Scadono oggi'},
     { id: 4, text: 'Scadono nel mese'},
     { id: 5, text: 'Non ancora scaduti'},
     { id: 6, text: 'Nota di credito'}
    ]

    const caricaTabella = (value: number) => {

        setPropTab(value);

        if (paramsRicerca.dal) {

            if (paramsRicerca.idFar) {

                filterAction({ ...paramsRicerca, idFar: paramsRicerca }, value);

            } else {

                filterAction(paramsRicerca, value)
            }

        }

        // caricaDati(value == 0 ? true : false)
    }

      const ricarica = () =>{
        setOpenPop(false)    
        setOpenPop2(false)
        setOpenPop3(false)
        setImmediate(false)
        setOpenPopSaldo(false)
        filterAction(paramsRicerca)
                 
    }
    
    const getTotalItems = (options: any) => {
        if (options.name == "rowsSummary") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = tabellaPrincipale.length
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryImporto") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importoFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummarySaldati") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldateFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryScaduti") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scaduteFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryScadonoOggi") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaOFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryScadonoQuestoMese") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaMFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryNonAncoraScaduti") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScadutiFooter)
                    break;
                case "finalize":
                    break;
            }
        }

        if (options.name == "rowsSummaryNoteDiCredito") {
            switch (options.summaryProcess) {
                case "start":
                    options.totalValue = 0;
                    break;
                case "calculate":
                    options.totalValue = nCreditoFooter
                    break;
                case "finalize":
                    break;
            }
        }
    }

    const onPagamentoClick = () => {

        if (selectedScad && selectedScad.length > 1) {

            let found: boolean = false;
            selectedScad.forEach((dato: any) => {
                if (dato.idTipoPagamento !== 0) {
                    found = true
                }


            })
            if (found) {
                let result = confirm("<i>I pagamenti gia' registrati saranno sovrascritti. Procedere?</i>", "Attenzione");
                result.then((dialogResult) => {
                    if (dialogResult) {
                        setOpenPop3(true);
                        setImmediate(true)
                    } else {
                        return;
                    }
                });
            }
            else {
                setOpenPop3(true);
                setImmediate(true)
            }

        }
        else {
            setOpenPop3(true);
            setImmediate(true)
        }


    }


    const onReportClick = () => {


        //console.log(params);
        //if (auth) {
        //    RestServices().rest('POST', paramsRicerca, urlConsultazione.report)
        //        .then(result => {
        //        });

        //}

        let url = urlConsultazione.reportScadenzario + 'dal=' + paramsRicerca.dal + '&al=' + paramsRicerca.al + '&flagCicloAttivo=true';
        if (paramsRicerca.idFar != undefined)
            url = url + '&idFar=' + paramsRicerca.idFar
        if (paramsRicerca.fornitore != undefined)
            url = url + '&fornitore=' + paramsRicerca.fornitore

        window.open(url);



    }


    const onDeleteIncassoClick = () => {

            setOpenPopSaldo(true);
            /*setObjtipo(data);*/
            setImmediate(true);
    };

    

    return (
        <>

             <DialogInfo
                    dialogIsOpen={openPopInfo}
                    message={messDel}  
                    functionNext={()=>('')}        
                    handleCloseConferma={()=>{setOpenPopInfo(false)}}
                />
        
       <DialogDataSaldo
             typeEdit={edit} 
             edit={objtipo} 
             flagAttive={true}
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPopSaldo(false);setObjtipo({});setImmediate(false)}}
             open={openPopSaldo} /> 

        <DialogDataAttiva
             typeEdit={edit} 
             edit={objtipo} 
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPop(false);setObjtipo({});setImmediate(false)}}
             open={openPop} /> 

              <DialogNote
             typeEdit={edit} 
             edit={objtipo} 
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPop2(false);setObjtipo({});setImmediate(false)}}
             open={openPop2} /> 

            {openPop3 && setObjtipo  ? (
                <DiaPagamAtt
                typeEdit={edit} 
                edit={objtipo} 
                ricarica={()=>ricarica()} 
                close={()=>{setOpenPop3(false);setObjtipo({});setImmediate(false)}}
                open={openPop3}
                selected={selectedScad}/> 
            ) : null}
             
        <Loading isVisible={loading} />
            <div className='title'>
                {/*<h3> FATTURE ATTIVE <span className='subt' > Consultazione Scadenzario</span> </h3>*/}
                <h3> SCADENZARIO DOCUMENTI EMESSI </h3>
             </div>
             <div style={{width:'250'}}>
            <Ricerca ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  />
             </div>
            <div style={{gap:10,display:'flex',background: 'white',padding:10,borderRadius: 10,marginTop:4}} >   
            <div className='title'>
                <h6> Filtro rapido stato pagamento: </h6>
             </div>  
             <div style={{marginTop:8}}>
           <RadioGroup onValueChanged={(x)=>caricaTabella(x.value.id)} dataSource={propr} layout="horizontal" value={propr[propTab]}   />  
           </div>
                  
        </div>
        <div style={{gap:16,display:'flex',flexWrap: 'wrap',background: 'white',padding: 10,borderRadius: 10,marginTop:4}}>
               <p style={{fontSize:16,margin:0,color:'black', marginTop:10}}  >Dal: <strong> {dataDalObj.dataDal} </strong>  </p>
               <p style={{fontSize:16,margin:0,color:'black', marginTop:10}}  >Al: <strong>{dataAlObj.dataAl} </strong>  </p>
               <p style={{fontSize:16,margin:0,color:'black', marginTop:10}}>Importo: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importo)}  </strong> </p>
               <div className='leg5'>  <p style={{fontSize:16,margin:0,color:'black'}}>Saldati: <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldate)}</strong> </p>  </div> 
               <div className='leg4'> <p style={{fontSize:16,margin:0,color:'black'}}>Scaduti: <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadute) } </strong> </p> </div>
               <div className='leg1'><p style={{fontSize:16,margin:0,color:'black'}}>Scadono oggi: <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaO)}</strong> </p></div>
               <div className='leg2'> <p style={{fontSize:16,margin:0,color:'black'}}>Scadono nel mese: <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaM)}</strong> </p> </div>
               <div className='leg6'><p style={{fontSize:16,margin:0,color:'black'}}>Non ancora scaduti: <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScaduti)}</strong> </p> </div> 
               <div className='leg3'> <p style={{fontSize:16,margin:0,color:'black'}}>Note di credito: <strong>{new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nCredito)}</strong> </p>  </div>      
             </div>

            <div className='button_primabanche'>
                <Button disabled={objtipo ? false : true} onClick={() => { onPagamentoClick() }} variant="primary" >
                    INCASSO
                </Button>
                <Button disabled={objtipo && 'dataSaldo' in objtipo && objtipo.dataSaldo !== null ? false : true} style={{ marginLeft: 5 }} title="Annulla Incasso" onClick={() => { onDeleteIncassoClick() }} >
                    <FontAwesomeIcon icon={faTrashAlt} className="pdfFileIcon" />
                    ANNULLA INCASSO
                </Button> 
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.linkFattura && !disabledPulsanti  ? false : true} 
               onClick={()=>{  window.open(objtipo.linkFattura, "_blank") }}
                variant="primary" >
                 VISUALIZZA
                </Button>
                <Button style={{ marginLeft: 5 }} title="Esporta tutti i dati pdf" onClick={onReportClick} disabled={!(tabellaPrincipale.length > 0)} >
                    <FontAwesomeIcon icon={faFilePdf} className="pdfFileIcon" />
                    REPORT
                </Button>
           </div>

            <NewTabella
                idTabella='elenco_scad_fat_attive'
                sync
                colonne={columns}
                dataSource={tabellaPrincipale}
                allowColumnReordering={true}
                filterRow
                headerFilter
                hidePager={false}
                searchPanel={true}
                multyp
                exportT={{
                    enabled: true,
                    fileName: "esportazione_scadenzario_attivo_" + getCurrentDateTime()
                }}
                activeSaver
                columnC
                selectedValue={(datiRow: any) => setSelectedScad(datiRow.selectedRowKeys)}
                filteredDataShow={(istanza) => {
                    //console.log('cipolotto');
                    const dataV: any = istanza.component.getDataSource();
                    // console.log(dataV._items);


                    //let entrate = dataV._items.reduce((accumulator:any, object:any) => {
                    //    if( object.importo){
                    //        return accumulator + object.importo;
                    //    }else{
                    //        return accumulator ;
                    //    }
                    //  }, 0);


                    let saldate = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'P') {
                            if (object.totale && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.totale;
                            } else {
                                return accumulator - object.totale;
                            }
                        } else {
                            return accumulator
                        }
                    }, 0);

                    let totScadute = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'S') {
                            if (object.totale && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.totale;
                            } else {
                                return accumulator - object.totale;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totOggi = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'I') {
                            if (object.totale && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.totale;
                            } else {
                                return accumulator - object.totale;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);
                    let totProssimeMese = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'M') {
                            if (object.totale && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.totale;
                            } else {
                                return accumulator - object.totale;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);
                    let totSuccessive = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'N') {
                            if (object.totale && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.totale;
                            } else {
                                return accumulator - object.totale;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);
                    let totNote = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'C') {
                            //    if( object.importo &&  object.idTipoDocumento != 4 && object.idTipoDocumento != 8){
                            //        return accumulator + object.importo;
                            //    }else{
                            return accumulator - object.totale;
                            //    } 
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let entrate = saldate + totScadute + totOggi + totProssimeMese + totSuccessive + totNote
                    setSaldateFooter(saldate)
                    setImportoFooter(entrate)
                    setScaduteFooter(totScadute)
                    setScadenzaOFooter(totOggi)
                    setScadenzaMFooter(totProssimeMese)
                    setNonScadutiFooter(totSuccessive)
                    setNCreditoFooter(totNote)

                    let el3 = document.getElementById('importo')
                    if (el3 != undefined)
                        el3.innerText = 'Importo: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(entrate);
                    let el4 = document.getElementById('saldate')
                    if (el4 != undefined)
                        el4.innerText = 'Saldati: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldate);
                    let el5 = document.getElementById('scadute')
                    if (el5 != undefined)
                        el5.innerText = 'Scaduti: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totScadute);
                    let el6 = document.getElementById('scadonoOggi')
                    if (el6 != undefined)
                        el6.innerText = 'Scadono oggi: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totOggi);
                    let el7 = document.getElementById('scadonoMese')
                    if (el7 != undefined)
                        el7.innerText = 'Scadono nel mese: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totProssimeMese);
                    let el8 = document.getElementById('nonScadute')
                    if (el8 != undefined)
                        el8.innerText = 'Non ancora scaduti: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totSuccessive);
                    let el9 = document.getElementById('noteCredito')
                    if (el9 != undefined)
                        el9.innerText = 'Note di credito: ' + Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(totNote);

                    //setSummaryObj({})


                    //console.log('dati filtrati');
                    //console.log(filtrati);



                }}

                onclickRow={(e) => {
                    var component = e.component,
                        prevClickTime = component.lastClickTime;
                    component.lastClickTime = new Date();
                    if (prevClickTime && (component.lastClickTime - prevClickTime < 300)) {
                        //Double click code
                        //console.log(e.data);

                            setOpenPop3(true);

                        setObjtipo(e.data)
                        // setOpenLink(true);
                        
                    }
                    else {
                        //setObjtipo(e.data)
                        //Single click code  

                    }
                }}



                summary={
                    <Summary calculateCustomSummary={getTotalItems}>
                        <TotalItem
                            name="rowsSummary"
                            displayFormat="Elementi totali: {0}"
                            summaryType="custom"
                            showInColumn="scadeIl" />
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryImporto"*/}
                        {/*    displayFormat="Importo: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="data" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummarySaldati"*/}
                        {/*    displayFormat="Saldati: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="tipoDocumento" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryScaduti"*/}
                        {/*    displayFormat="Scaduti: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="importo" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryScadonoOggi"*/}
                        {/*    displayFormat="Scadono oggi: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="totale" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryScadonoQuestoMese"*/}
                        {/*    displayFormat="Scadono nel mese: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="imponibile" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryNonAncoraScaduti"*/}
                        {/*    displayFormat="Non scaduti: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="imposta" />*/}
                        {/*<TotalItem*/}
                        {/*    name="rowsSummaryNoteDiCredito"*/}
                        {/*    displayFormat="Note di credito: {0}"*/}
                        {/*    summaryType="custom"*/}
                        {/*    showInColumn="farmacia" />*/}
                    </Summary>
                }
                onRowPrepared={onRowPrepared}   
                              
            />
          <div style={{gap:10,display:'flex',background: 'white',padding:10,borderRadius: 10,marginTop:10}} className="form-group">   
            <div className='title2'>
                <h6> Legenda Colori: </h6>
             </div>  
             <div className='leg5'>
           <h6 >  Saldati </h6> 
           </div> 
           <div className='leg4'>
           <h6> Scaduti </h6>   
           </div>   
             <div className='leg1'>
             <h6>  Scadono oggi </h6> 
           </div>   
           <div className='leg2'>
           <h6> Scadono nel mese </h6> 
           </div>
           <div className='leg6'>
            <h6> Non ancora scaduti  </h6>   
           </div>
           <div className='leg3'>
           <h6> Note di credito  </h6>   
           </div> 
        </div>

        </>
    )
}

export default FattAttive

