import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { colAnalMagazzino, colAnalMagazzino2 } from "./col/ColGalileo";
import Button from "react-bootstrap/Button";
//import { DialogAnaScoCatMerc } from "./dialog/PopAnaScoCatMerc";
import { createItemFilterVendCategory } from "./filter/FiltriGalileo";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { boxAnalMagazzino } from "./boxcards/CardsGalileo";
import {  Chart } from "devextreme-react";
import {
    Animation,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    HoverStyle,
    Label,
    Legend,
    Series,
    Subtitle,
    Title,
    Tooltip,
    ValueAxis,
    Font
} from "devextreme-react/chart";

import "./style.css";


const GalileoAnaliMagazzino = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [operatori, setOperatori] = useState<any[]>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxAnalMagazzino);
    const [chartData, setChartData] = useState<any>([]);
    const [pie, setPie] = useState<any[]>([]);
    const [valoriBoobble, setValoriBoobble] = useState<any>([]);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [visiblePopOne, setVisiblePopOne] = useState(false);
    const [ditte, setDitte] = useState<any[]>([]);
    const [category, setCategory] = useState<any[]>([]);
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);
    const loadPanel = <Loading isVisible={isVisible} />;
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);

    const dashboard7 = { dashboardID: 7 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    function getCategoryByLevel(categoryList: any[], level: number) {
        return categoryList.filter((category) => category.length === level * 1);
    }



    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };
        //console.log('vediamo i parametri yo');
        //console.log(params);
        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let venditaDPC = params?.venditaDPC ? undefined : [0];
        let flagBusta = params?.flagBusta ? undefined : [0];

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        let category1;
        let category2;
        let category3;
        let category4;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.category) {
            const categoryList = params.category;
            category1 = getCategoryByLevel(categoryList, 1);
            category2 = getCategoryByLevel(categoryList, 2);
            category3 = getCategoryByLevel(categoryList, 3);
            category4 = getCategoryByLevel(categoryList, 4);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            category1: category1,
            category2: category2,
            category3: category3,
            category4: category4,
            ditta: params.ditta,
            venditaDPC: venditaDPC,
            flagBusta: flagBusta,
            operatori: params.operatori,
            tipologiaSconto: params.tipologiaSconto,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall7 = {
                ...dashboard7,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            console.log('mostro call');
            console.log(dashboardCall7);

            RestServices()
                .rest("POST", dashboardCall7, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {

                        let datiPrimaParteCards = trasform_for_cards_charts_01(result["51"], boxAnalMagazzino);

                        //let datiPrimaTabelle = result["0"];
                        let datiPrimaTabelle = result["54"];

                        //let datiPrimaTabelle = result["0"];
                        let datiPrimaTabelle2 = result["54"];

                        setFila1cards(datiPrimaParteCards);

                        setValoriTabella1(datiPrimaTabelle);
                        setValoriTabella2(datiPrimaTabelle2);

                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1° gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });

        }
    };




    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const itemsFields = createItemFilterVendCategory(
        newFarmaList,
        category,
        ditte
    );

    const filters = () => {
        if (auth) {
            RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
                // console.log('vediamoooo categorie');
                // console.log(result);
                if (result) setCategory(result);
            })

            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );
                        // console.log('da togliere vediamo ditte');
                        // console.log(ditteWithCodiceDescrizione);

                        setDitte(ditteWithCodiceDescrizione);
                    }
                });

            RestServices()
                .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
                .then((result) => {
                    if (result.esito) {
                        const operatori = result.response.map(
                            (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                                //console.log(ope.idFarmacia);
                                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                                //console.log(farmaciaCorrispondente);
                                return {
                                    ...ope,
                                    id: `${ope.operatore}`,
                                    descrizione: `${ope.operatore}`,
                                    codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                                };
                            }
                        );
                        setOperatori(operatori);
                        // console.log('operatori');
                        // console.log(operatori);
                    }
                });


        }


    };

    useEffect(() => {
        filters();
    }, [auth]);



    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabella_operatori.csv"
                );
            });
        });
    };


    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };

    const popVisibleOne = (testo: string) => {
        setVisiblePopOne(true);
    };

    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    let messPie = ["PieChart"];

    let messOne = ["Booble chart"];

    return (
        <>
            {/* <DialogAnaScoCatMerc
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            /> */}

            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>GALILEOPRO - Analisi Magazzino </h2>
                        </div>

                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >
                            
                        </div>

                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>


                        <div className="grid-container-five">
                            {fila1cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>


                        <div style={{ marginTop: '5px' }}>
                            <h6
                                style={{
                                    color: "#5b6372",
                                    fontFamily: "Arial, sans-serif",
                                    marginBottom: "-20px",
                                    marginLeft: "10px",
                                    zIndex: 1,
                                    position: "relative"
                                }}
                            >
                                Distribuzione giacenza attuale per Ditta
                            </h6>
                            <NewTabella
                                idTabella='elenco_operatori'
                                sync
                                colonne={colAnalMagazzino}
                                dataSource={valoriTabella1}
                                filterRow
                                width={10}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "ElencoOperatori",
                                    formats: { exportFormats }
                                }}
                                activeSaver={true}
                                columnC={true}
                                rowAlternation={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                            //   summary={
                            //     <Summary>    
                            //       <TotalItem column="VALORE_VENDUTOCR" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                            //       <TotalItem column="NUMERO_VENDITECR" summaryType="sum" />

                            //       <TotalItem column="VALORE_VENDUTO_EQUIPARATO" summaryType="sum" />
                            //     </Summary>
                            //   }
                            />


                            {/* <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>  */}

                            {/* <div className="containButtonAna">
                                <Button
                                    id="btnCercax"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        setOpenPop(true);
                                        console.log("dialog");
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi sconti per operatori
                                </Button>
                            </div> */}


                        </div>

                        <div style={{ marginTop: '5px' }}>
                            <h6
                                style={{
                                    color: "#5b6372",
                                    fontFamily: "Arial, sans-serif",
                                    marginBottom: "-20px",
                                    marginLeft: "10px",
                                    zIndex: 1,
                                    position: "relative"
                                }}
                            >
                                Distribuzione giacenza attuale per Category
                            </h6>
                            <NewTabella
                                idTabella='elenco_operatori'
                                sync
                                colonne={colAnalMagazzino2}
                                dataSource={valoriTabella2}
                                filterRow
                                width={10}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "ElencoOperatori",
                                    formats: { exportFormats }
                                }}
                                activeSaver={true}
                                columnC={true}
                                rowAlternation={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                            //   summary={
                            //     <Summary>    
                            //       <TotalItem column="VALORE_VENDUTOCR" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                            //       <TotalItem column="NUMERO_VENDITECR" summaryType="sum" />

                            //       <TotalItem column="VALORE_VENDUTO_EQUIPARATO" summaryType="sum" />
                            //     </Summary>
                            //   }
                            />


                            {/* <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>  */}

                            {/* <div className="containButtonAna">
                                <Button
                                    id="btnCercax"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        setOpenPop(true);
                                        console.log("dialog");
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi sconti per operatori
                                </Button>
                            </div> */}


                        </div>


                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
                <Popup
                    visible={visiblePopOne}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopOne(false)}
                    width={"40%"}
                    height={"60%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messOne}</p>
                    </div>
                </Popup>
                <Popup
                    visible={visiblePop3}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop3(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messPie}</p>
                    </div>
                </Popup>
            </section>
        </>
    );
};


export default GalileoAnaliMagazzino;
