import React, { useState } from 'react';
import Toolbar, { Item } from 'devextreme-react/toolbar';
import Drawer from 'devextreme-react/drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/esm/Button';

export const DrawerFiltri = (props: {
    templateFiltri: any,
    creaRiepilogoFiltri?: any,
    open: boolean,
    setOpen: (open: boolean) => void,
    id: string,
    testoDrawer?: string,
    testoAvviso?: string,
    testoFiltro?: string,
    avvisoFattureScadenza?: boolean,
    openPopUpFattureScadenza?: boolean,
    setOpenPopupFattureScadenza?: (openPopUpFattureScadenza: boolean) => void,
    avvisoProdottiSegnalazione?: boolean,
    openPopUpProdottiSegnalazione?: boolean,
    setOpenPopupProdottiSegnalazione?: (openPopUpProdottiSegnalazione: boolean) => void,
    avvisoFattureNote?: boolean,
    openPopUpFattureNote?: boolean,
    setOpenPopupFattureNote?: (openPopUpFattureNote: boolean) => void,
    

}) => {
    //funzione che restituisce il riepilogo filtri -> necessita della props creaRiepilogoFiltri, da specificare di volta in volta
    function renderLabel() {
        return (
            (props.creaRiepilogoFiltri)
                ?
                <Row>
                    <Col><h5 className="tagFiltri">{props.testoFiltro ? props.testoFiltro : 'Filtri di ricerca'}: <props.creaRiepilogoFiltri /></h5></Col>
                    
                </Row>

                
                :
                (props.testoAvviso) ? <h5 className="tagFiltri">{props.testoDrawer}   <FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-sm" title="Avviso" /><span title="Avviso" className="lblAvvisoRDA">{props.testoAvviso}</span></h5> : <h5 className="tagFiltri">{props.testoDrawer}</h5>
        )
    }

    //opzioni toolbar
    const optionToolbar = {
        icon: "chevrondown",
        onClick: () => props.setOpen(!props.open)
    }

    const addButtonOptionsNote = {
        icon: 'info',
        text: 'Ci sono fatture del fornitore con note',
        visible: props.avvisoFattureNote,
        onClick: () => {
            if (props.setOpenPopupFattureNote != undefined)
                props.setOpenPopupFattureNote(!props.open)
        },
    };

    const addButtonOptions = {
        icon: 'info',
        text: 'Ci sono fatture del fornitore non saldate',
        visible: props.avvisoFattureScadenza,
        onClick: () => {
            if (props.setOpenPopupFattureScadenza != undefined)
                props.setOpenPopupFattureScadenza(!props.open)
        },
    };

    const addButtonSegnalazioni = {
        icon: 'info',
        text: 'Ci sono prodotti del fornitore con segnalazioni',
        visible: props.avvisoProdottiSegnalazione,
        onClick: () => {
            if (props.setOpenPopupProdottiSegnalazione != undefined)
                props.setOpenPopupProdottiSegnalazione(!props.open)
        },
    };

    return (
        <>
            <Toolbar>
                <Item
                    location='before'
                    locateInMenu='never'
                    render={renderLabel}
                />
                <Item location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={addButtonOptionsNote} />
                <Item location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={addButtonOptions} />
                <Item location="after"
                    locateInMenu="auto"
                    widget="dxButton"
                    options={addButtonSegnalazioni} />
                <Item
                    location='after'
                    widget='dxButton'
                    options={optionToolbar}
                />
            </Toolbar>

            <Drawer
                revealMode="expand"
                position="top"
                openedStateMode="shrink"
                opened={props.open}
                closeOnOutsideClick={false}
                render={props.templateFiltri}
                className="contenitoreDrawer"
                id={props.id}
            ></Drawer>
        </>
    )
}
