import React, { useEffect, useState } from 'react'
import Select from 'react-select'


//import './style.css';


const StatBusinessLabOper = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    

    let options:any = [
        {label:'Analisi vendite per Ditta', value:'6006b5f7d900a8147c72003e'},
        {label:'Analisi vendite per Category (Banca Dati CSF)', value:'5af54545f101a50820b7b2f9'},
        {label:'Analisi vendite per Gruppo Merceologico (Banca Dati CSF)', value:'5bbcae400a9f7232acd87ac0'},
        {label:'Analisi acquisti per Category (Banca Dati CSF)', value:'5af55065f101a50820b7b863'},
        {label:'Analisi acquisti per Gruppo Merceologico (Banca Dati CSF)', value:'5bae45c41c15911fa02fe6ed'},
        {label:'Analisi fornitori per Ditta & Category', value:'5b6d6da5014ca926f4fb2e5b'},
        {label:'Analisi fornitori per Ditta & Gruppo Merceologico', value:'5bae49001c15911fa02fe734'},
        {label:'Analisi margini medi', value:'5b8e64b94850701d38f5f6d7'},
        {label:'Analisi vendite sotto margine', value:'5b922cc7c3d7d00ae8203ced'},
        {label:'Analisi vendite per fascia oraria', value:'5b96972dbce139067886fe6e'},
        {label:'Analisi prodotti fermi', value:'5d076b1bdc09e91a80989697'}
       
   ]

    useEffect(() => {
       
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const [selection,setSelection] = useState<string>('6006b5f7d900a8147c72003e')


    return (
        <>   
         <div >
            <div className='title'>
                <h4> BusinessLAB <span className='subt' > Cruscotto Operativo</span>  </h4>
             </div>
                <label >Selezionare il cruscotto operativo:</label> 
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={options[0]}
                    isClearable={false}
                    isSearchable={true}
                    onChange={(s)=>{
                       // console.log(s.value);
                        setSelection(s.value)
                        
                    }}
                    name="color"
                    options={options}
                />   
            </div>     

        
            <div style={{ height: '80vh', marginTop: 10 }}>
                <iframe
                    id="ifm"
                    width="100%"
                    height="100%"
                    src={`https://sss.cloud-farma.it/app/main#/dashboards/${selection}?embed=true`}
                ></iframe>
            </div>
           
        </>
    )
}

export default StatBusinessLabOper