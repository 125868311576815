import notify from 'devextreme/ui/notify'
import React, { useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/Button'
import { useAuth } from '../../hooks/useAuth'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { DialogConferma, Loading } from '../../widget/Notifications'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { urlConsultazione } from "../costanti"
import { DialogNewUtente } from "./dialog/Pop_new_utente"

const Utenti = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlConsultazione.auth)
    const [loading, setLoading] = useState<boolean>(false)
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [tabellaPrincipaleBackup, setTabellaPrincipaleBackup] = useState<any>([])
    const [tabellaSessioni, setTabellaSessioni] = useState<any>([])
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)
    const [authTutti, setAuthTutti] = useState<boolean>(false)
    const [idDel, setIddel] = useState<number>(0)
    const [messDel, setMessDel] = useState<string>('')
    const [popDel, setPopDel] = useState<boolean>(false)
    const [reset, setReset] = useState<boolean>(false)
    const [idUtente, setIdUtente] = useState<number | null>(null)


    const columns = [
        { caption: "UserName", dataField: "username" },
        { caption: "Cognome", dataField: "cognome" },
        { caption: "Nome", dataField: "nome", },
        { caption: "Profilo", dataField: "nomeProfilo", },
        { caption: "Telefono", dataField: "telefono", },
        { caption: "Email", dataField: "email", },
        {
            caption: "Stato", dataField: "attivo", render: (e: any) => {
                switch (e.value) {
                    case true:
                        return (<div style={{ margin: "auto" }} title=""><button style={{ color: 'white', background: '#1ab394', border: '1px solid #1ab394', borderRadius: 4 }}>Attiva</button></div>)
                    case false:
                        return (<div style={{ margin: "auto" }} title=""><button style={{ color: 'white', background: '#ed5565', border: '1px solid #ed5565', borderRadius: 4 }}>Disattiva</button></div>)
                }
            }
        },
        /*{
            caption: "Autenticazione a Due Fattori", dataField: "attivo", render: (e: any) => { //attivo come dataField è solo temporaneo, in attesa della modifica del servizio e di estrapolare il vero valore booleano
                if (e.data) {
                    return (<div style={{ margin: "auto" }}><input style={{ transform: 'scale(1.5)', cursor: 'pointer', }} type="checkbox" checked={e.data.attivo} onClick={() => { abilitaAuthSingolo(e.data); }} /></div>)
                }
            },
        },*/
        {
            caption: "Azioni", dataField: "", width: 280, render: (e: any) => {
                return (<div style={{ display: 'flex', gap: 5, justifyContent: 'center' }}>
                    <button style={{ color: 'inherit', background: 'white', border: '1px solid #e7eaec', borderRadius: 4 }} onClick={() => { { setImmediate(true); setOpenPop(true); setObjtipo(e.row.data) } }}>Dettagli</button>
                    {e.row.data?.attivo ? (
                        <button style={{ color: 'white', background: '#f8ac59', border: '1px solid #f8ac59', borderRadius: 4, width: '64px' }} onClick={() => cambioStato(e.row.data, false)}>Disattiva</button>
                    ) : (
                        <button style={{ color: 'white', background: '#1ab394', border: '1px solid #1ab394', borderRadius: 4, width: '64px' }} onClick={() => cambioStato(e.row.data, true)}>Attiva</button>
                    )}
                    <button style={{ color: 'white', background: '#23c6c8', border: '1px solid #23c6c8', borderRadius: 4 }} onClick={() => {
                        //console.log(e.row.data);
                        setObjtipo(e.row.data)
                        setMessDel("L'utente " + e.row.data.cognome + "  " + e.row.data.nome + " sta per essere resettato. Confermare?")
                        setPopDel(true)
                        setReset(true)
                    }}>Reset</button>
                </div>)
            }
        },
    ];


    const columnsSessioni = [
        { caption: "Data Login", dataField: "loginData", width: 200 },
        { caption: "Ora Login", dataField: "loginOra", width: 90 },
        { caption: "Data Logout", dataField: "logoutData", width: 200 },
        { caption: "Ora Logout", dataField: "logoutOra" },
        { caption: "Accesso", dataField: "postazione" },


    ];


    const cambioStato = (ob: any, st: boolean) => {
        let obbjj = {
            idUtente: ob.idUtente,
            username: ob.username,
            attivo: st

        }

        if (auth) {
            //console.log(objctTOsave);
            RestServices().rest('POST', obbjj, urlConsultazione.updateUtente)
                .then(result => {
                    if (result?.esito) {
                        ricarica()

                    } else {
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }


    const getListUtenti = () => {
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListUtenti)
                .then(result => {
                    if (result?.esito) {

                        const aggiuntaCampoModificato = result.response.map((item: any) => ({
                            ...item,
                            modificato: false
                        }));

                        setTabellaPrincipale(aggiuntaCampoModificato)
                        setTabellaPrincipaleBackup(aggiuntaCampoModificato)
                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }


    const getSessioniUtenti = () => {
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', { idUtente }, urlConsultazione.getSessioniUtenti)
                .then(result => {
                    if (result?.esito) {
                        setTabellaSessioni(result.response)
                        setLoading(false)
                        //console.log(result);
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })
        }
    }

    const removeUtente = (reset?: boolean) => {
        if (auth) {
            if (reset) {
                RestServices().rest('POST', { idUtente: objtipo.idUtente, username: objtipo.username }, urlConsultazione.resetUtente)
                    .then(result => {
                        if (result.esito) {
                            setPopDel(false)
                            ricarica()
                            notify({ position: "center", width: "auto", message: "La password dell'utente è stata resettata." }, "success", 4000)

                        }
                        else {
                            // console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }

                    })
            } else {
                RestServices().rest('POST', { idUtente: objtipo.idUtente, username: objtipo.username }, urlConsultazione.deleteUtente)
                    .then(result => {
                        if (result.esito) {
                            setPopDel(false)
                            ricarica()

                        }
                        else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }

                    })
            }


        }
    }

    const abilitaAuthTutti = (abilita: boolean) => {

        setTabellaPrincipale((prevTabellaPrincipale: any) =>

            prevTabellaPrincipale.map((item: any, index: number) => {

                const originale = tabellaPrincipaleBackup[index];
                const modificato = abilita !== originale.attivo;

                return {
                    ...item,
                    attivo: abilita,
                    modificato: modificato,
                };
            })
        );
        setAuthTutti(abilita);
    };



    const abilitaAuthSingolo = (rowData: any) => {
        if (rowData) {

            setTabellaPrincipale((prevTabellaPrincipale: any) =>
                prevTabellaPrincipale.map((item: any) => {
                    if (item.idUtente === rowData.idUtente) {

                        return {
                            ...item,
                            attivo: !item.attivo,
                            modificato: !item.modificato,
                        };
                    }
                    return item;
                })
            );
        }
    };


    const ricarica = () => {
        getListUtenti()
        setReset(false)
        setObjtipo(null)
        setOpenPop(false)
    }


    useEffect(() => {

        if (tabellaPrincipale && tabellaPrincipale.length > 0) {

            if (tabellaPrincipale.every((item: any) => item.attivo === true))
                setAuthTutti(true);
            else if (tabellaPrincipale.every((item: any) => item.attivo === false))
                setAuthTutti(false);

        }
    }, [tabellaPrincipale]);


    useEffect(() => {
        if (idUtente) {
            getSessioniUtenti()
        }
    }, [idUtente])


    useEffect(() => {
        if (auth) {
            getListUtenti()

        }
    }, [auth])


    const sonoDiversi = useMemo(

        () => JSON.stringify(tabellaPrincipale) !== JSON.stringify(tabellaPrincipaleBackup),

        [tabellaPrincipale, tabellaPrincipaleBackup]
    );


    return (
        <>
            <DialogConferma
                dialogIsOpen={popDel}
                message={messDel}
                functionNext={() => removeUtente(reset)}
                handleCloseConferma={() => { setPopDel(false); setObjtipo({}) }}
            />

            <DialogNewUtente oggettoUsername={objtipo} typeEdit={edit} edit={objtipo} ricarica={() => ricarica()} close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }} open={openPop} />

            <Loading isVisible={loading} />
            <div className='title'>
                <h3> Gestione utenti  <span className='subt' > Account del portale Cloudfarma</span> </h3>
            </div>

            <div style={{ marginTop: '2%', }}>

                <NewTabella
                    idTabella="gest_utenti"
                    title={
                        <>
                            <div style={{ display: 'flex', }}>
                                <div>
                                    <Button onClick={() => { setObjtipo({}); setOpenPop(true) }} variant="primary" >
                                        NUOVO
                                    </Button>
                                </div>

                                {/*
                                <div style={{ marginLeft: '1%', }} >
                                    <Button onClick={() => { abilitaAuthTutti(!authTutti); }} variant={!authTutti ? 'success' : 'danger'} > {!authTutti ? 'Attiva' : 'Disattiva'} </Button>
                                </div>
                                


                                <div style={{ marginLeft: '1%', }} >

                                    <div>
                                        Autenticazione a due fattori per tutti gli utenti
                                    </div>

                                </div>
                                */}
                            </div>

                            {sonoDiversi && tabellaPrincipale.length > 0 && tabellaPrincipaleBackup.length > 0 &&

                                <>

                                    <div style={{ marginTop: '3%', textAlign: 'center', }}>

                                        <div>
                                            Salvare le Modifiche?
                                        </div>

                                        <div style={{ display: 'flex', marginTop: '1%', justifyContent: 'center' }}>

                                            <div>
                                                <Button onClick={() => { }} variant="success" >
                                                    Salva
                                                </Button>
                                            </div>

                                            <div style={{ marginLeft: '3%' }}>
                                                <Button onClick={() => { setTabellaPrincipale(tabellaPrincipaleBackup); setAuthTutti(false); }} variant="danger" >
                                                    Annulla
                                                </Button>
                                            </div>

                                        </div>

                                    </div>

                                </>

                            }

                        </>
                    }
                    sync
                    searchPanel={true}
                    colonne={columns}
                    height='70vh'
                    hidePager={false}
                    dataSource={tabellaPrincipale}
                    onCellClick={(e) => {
                        //Blocca l'evento solo se si clicca sulla colonna con indice 7 ovvero quella relativa alla checkbox dell'autenticazione a due fattori
                        if (e.column.index !== 7)
                            setIdUtente(e.data?.idUtente);
                    }}
                    exportT={{
                        enabled: true,
                        fileName: "Gest_utenti",
                    }}
                    onCellPrepared={(e: any) => {
                        if (e.rowType === "data" && e.column.caption === "Autenticazione a Due Fattori") {
                            e.cellElement.style.backgroundColor = e.data.modificato ? '#6bddbc' : '';
                        }
                    }
                    }
                />

                {
                    idUtente ? (
                        <>
                            <div className='title'>
                                <h3> Sessioni  <span className='subt' > Ultimi 100 accessi</span> </h3>
                            </div>
                            <NewTabella
                                idTabella="gest_sessioni"
                                sync
                                colonne={columnsSessioni}
                                height='70vh'
                                hidePager={false}
                                dataSource={tabellaSessioni}
                                exportT={{
                                    enabled: false,
                                    fileName: "Gest_sessioni",
                                }}
                            />
                        </>

                    ) : (
                        null
                    )
                }

            </div >
        </>

    )
}

export default Utenti