import DataGrid, { Column, Editing, Export, FilterRow, HeaderFilter, MasterDetail, Pager, Paging, SearchPanel, Selection } from 'devextreme-react/data-grid';
import LoadPanel from 'devextreme-react/load-panel';
import { dxToolbarOptions } from 'devextreme/ui/toolbar';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { urlLayoutTabelle } from '../../contenuti/costanti';
import { setStatoTabella } from '../../funzioni/operazioniSuTabelle';
import { RestServices } from '../../services/restServices';
import './style.css';

export const NewTabella = (props: {
    idTabella: string,
    title?: any,
    sync?: boolean
    looading?: boolean,
    dataSource: any,
    colonne: any,
    filterRow?: boolean,
    headerFilter?: boolean,
    searchPanel?: boolean,
    onclickRow?: (selectedRowsData: any) => void,
    onCellClick?: (selectedRowsData: any) => void,
    selectedValue?: (selectedRowsData: any) => void,
    onRowUpdated?: (row: any) => void,
    onRowUpdating?: (row: any) => void,
    exportT?: any
    columnC?: boolean
    activeSaver?: boolean
    multyp?: boolean
    keyExpr?: string
    defaultSelectedRowKeys?: any
    selectedRowKeys?: any
    summary?: any
    height?: string | number,
    width?: string | number,
    tabelDett?: boolean,
    allowColumnResizing?: boolean,
    allowColumnReordering?: boolean,
    hideColumnHeaders?: boolean,
    hidePager?: boolean,
    componetPlus?: any,
    specialPd?: number,
    onRowPrepared?: any,
    onCellPrepared?: any;
    filteredDataShow?: (istanza: any) => void,
    tabReference?: (instance: any) => void,
    onEditingStart?: any,
    pagination?: number,
    wordWrapEnabled?: boolean,
    rowAlternation?: boolean,
    onExporting?: any,
    onFocusedCellChanged?: any,
    pageSize?: number,
    paging?: boolean,
    toolbar?: boolean,
    hide?: boolean,
    showPageSizeSelector?: boolean
    searchPosition?: string
    onlySearch?: boolean
    searchPanelWidth?: number
    allowToolbarDateFilter?: boolean,
    onToolbarFilterDate?: (e: any) => void,
    showColumnLines?: boolean,
    onRowDblClick?: (row: any) => void,
    scrolling?: {
        useNative?: boolean;
        showScrollbar?: 'always' | 'onHover' | 'never';
    };
}) => {

    const tabRef = useRef<any>(null);
    const [carica, setCarica] = useState(false)
    const [pagination, setPagination] = useState(50);
    const [enablePagination, setEnablePagination] = useState(true);
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);

    const [toolbarFilterDate, setToolbarFilterDate] = useState<any>()
    const [dal, setDal] = useState<any>(new Date());
    const [al, setAl] = useState<any>(new Date());

    useEffect(() => {
        if (tabRef.current)
            if (props.tabReference)
                props.tabReference(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props && props.pagination) setPagination(props.pagination);
    }, [props.pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.multyp,
            showCheckBoxesMode: 'always'
        }
    }, []);

    useEffect(() => {
        if (props && props.hidePager === false) setEnablePagination(false);
    }, [props.hidePager])

    useEffect(() => {

        var currentDate = new Date();
        var prevDate = new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01');
        let filterDate = { ...toolbarFilterDate }
        filterDate.dal = prevDate
        filterDate.al = currentDate
        setDal(prevDate)
        setAl(currentDate)
        setToolbarFilterDate(filterDate)

    }, [props.allowToolbarDateFilter])

    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);

    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);

    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.headerFilter
        }
    }, []);

    const columnChooserOptions = useMemo(() => {
        return {
            enabled: props.columnC
        }
    }, [])

    const editing = useMemo(() => {
        return {
            mode: "cell",
            allowUpdating: true
        }
    }, [])


    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items && (props.onlySearch == false || props.onlySearch == undefined)) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();

                        setStatoTabella(props.idTabella, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                            // console.log("ripristina stato");
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.idTabella);
                        }
                    }
                }
            )


        }

        if (e.toolbarOptions && e.toolbarOptions.items && props.allowToolbarDateFilter) {
            var currentDate = new Date();
            let filterDate = { ...toolbarFilterDate }
            filterDate.dal = currentDate
            filterDate.al = currentDate
            //setToolbarFilterDate(filterDate)
            //props.onToolbarFilterDate && props.onToolbarFilterDate(filterDate)

            e.toolbarOptions.items.push({
                location: "before",
                html: "<div>dal</div>",
            })



            e.toolbarOptions.items.push({
                label: "dal",
                location: "before",
                widget: "dxDateBox",
                options: {
                    type: "date",
                    value: dal,
                    onValueChanged: function (e: any) {
                        let filterDate = { ...toolbarFilterDate }
                        filterDate.dal = e.value
                        setDal(e.value)
                        setToolbarFilterDate(filterDate)
                        props.onToolbarFilterDate && props.onToolbarFilterDate(filterDate)
                    }
                }
            })

            e.toolbarOptions.items.push({
                location: "before",
                html: "<div>al</div>",
            })

            e.toolbarOptions.items.push({
                location: "before",
                widget: "dxDateBox",
                options: {
                    type: "date",
                    value: al,
                    onValueChanged: function (e: any) {
                        let filterDate = { ...toolbarFilterDate }
                        filterDate.al = e.value
                        setAl(e.value)
                        setToolbarFilterDate(filterDate)
                        props.onToolbarFilterDate && props.onToolbarFilterDate(filterDate)
                    }
                }
            })
        }

        if (props.onlySearch && props.onlySearch == true && props.searchPosition && e.toolbarOptions && e.toolbarOptions.items) {
            let toolbarItems = e.toolbarOptions.items;

            // Modifies an existing item
            toolbarItems.forEach(function (item) {
                if (item.name === "searchPanel") {
                    item.location = props.searchPosition;
                    item.width = 200;
                }
            });
        }


    }

    const getStatoTabella = () => {
        if (!props.idTabella) return;
        RestServices().rest('POST', props.idTabella, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        setCarica(true)
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {

            if (result === true) {
                setCarica(false)
            }
        });
    }

    useEffect(() => {
        getStatoTabella();
    }, [props.idTabella]);

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])


    const allowedPageSizes = [5, 10, 20, 50];

    return (
        <>
            <div id='tabellaCss'>
                {props.looading ? (
                    <div id='qui'>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            shading={false}
                            visible={true}
                            showPane={true}
                            showIndicator={true}
                            position={{ of: '#qui' }}
                        />
                    </div>
                ) : (
                    <div style={{ marginTop: 5, padding: props.specialPd ? props.specialPd : 10, borderRadius: 10, background: '#fff' }}>
                        {props.title && <h4 style={{ margin: 0, fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic' }}>{props.title}</h4>}
                        {props.dataSource.length == 1500 ? (
                            <div >
                                <p>*i dati sono stati limitati a 1500 elementi, affina la ricerca per dati migliori</p>
                            </div>
                        ) : null}
                        {carica ? (
                            null
                        ) : (
                            <DataGrid
                                ref={tabRef}
                                id={props.idTabella}
                                showBorders={true}
                                showRowLines={true}
                                hoverStateEnabled={true}
                                keyExpr={props.keyExpr ? props.keyExpr : undefined}
                                rowAlternationEnabled={props.rowAlternation}
                                columnAutoWidth={true}
                                height={props.height ? props.height : '70vh'}
                                dataSource={props.dataSource}
                                filterSyncEnabled={true}
                                filterPanel={filterPanelOptions}
                                defaultFilterValue={null}
                                columnChooser={columnChooserOptions}
                                allowColumnReordering={props.allowColumnReordering}
                                allowColumnResizing={props.allowColumnResizing}
                                onToolbarPreparing={props.toolbar == true ? onToolbarPreparing : undefined}
                                onRowClick={props.onclickRow}
                                onCellClick={props.onCellClick}
                                onRowUpdated={props.onRowUpdated ? props.onRowUpdated : () => console.log()}
                                onRowPrepared={props.onRowPrepared}
                                onCellPrepared={props.onCellPrepared}
                                selectedRowKeys={props.selectedRowKeys ? props.selectedRowKeys : undefined}
                                onEditingStart={props.onEditingStart}
                                onSelectionChanged={props.selectedValue}
                                wordWrapEnabled={wordWrapEnabled}
                                repaintChangesOnly={true}
                                export={props.exportT}
                                paging={paginationOptions}
                                //paging={{ enabled: props.paging }}
                                onRowUpdating={props.onRowUpdating}
                                visible={!props.hide}
                                onRowDblClick={props.onRowDblClick}
                                onContentReady={props.filteredDataShow ? (x) => { if (props.filteredDataShow) props.filteredDataShow(x) } : undefined}
                                onExporting={props.onExporting}
                                onFocusedCellChanged={props.onFocusedCellChanged}
                                showColumnLines={props.showColumnLines ? props.showColumnLines : true}

                                scrolling={props.scrolling}

                            >
                                {props.summary ? props.summary : null}
                                <Paging defaultPageSize={props.pageSize} />
                                <Pager
                                    visible={props.hidePager ? false : true}
                                    showPageSizeSelector={props.showPageSizeSelector !== undefined ? props.showPageSizeSelector : true}
                                    allowedPageSizes={allowedPageSizes}
                                    showInfo={true} />
                                <FilterRow visible={props.filterRow ? true : false} />
                                <HeaderFilter visible={props.headerFilter ? true : false} />
                                <Export enabled={true} />
                                <SearchPanel visible={props.searchPanel ? true : false}
                                    width={props.searchPanelWidth ? props.searchPanelWidth : 150}
                                    placeholder="Cerca..." />
                                <Selection
                                    mode={props.multyp ? "multiple" : 'single'}
                                />
                                <Editing
                                    mode="cell"
                                    allowUpdating={true}
                                    selectTextOnEditStart={true} />
                                {props.tabelDett ? (
                                    <MasterDetail
                                        enabled={true}
                                        component={props.componetPlus}
                                    />
                                ) : null}

                                {props.colonne.map((colonna: any) => {
                                    //console.log(colonna);
                                    return <Column
                                        sortOrder={colonna.sortOrder ? colonna.sortOrder : null}
                                        allowEditing={colonna.editC ? colonna.editC : false}
                                        dataField={colonna.dataField}
                                        caption={colonna.caption}
                                        cellRender={colonna.render ? colonna.render : null}
                                        width={colonna.width ? colonna.width : null}
                                        format={colonna.format ? colonna.format : null}
                                        dataType={colonna.dataType ? colonna.dataType : null}
                                        customizeText={colonna.customizeText ? colonna.customizeText : null}
                                        type={colonna.type}
                                        buttons={colonna.buttons ? colonna.buttons : null}
                                        visible={colonna.visible != undefined ? colonna.visible : true}
                                        fixed={colonna.fixed != false ? colonna.fixed : false}

                                    />
                                }
                                )}
                            </DataGrid>
                        )}

                    </div>
                )}
            </div>
        </>
    )
}