import React, { useEffect, useState } from 'react'
import { LoadPanel } from 'devextreme-react/load-panel'
import Modal from 'react-bootstrap/esm/Modal'
import Button from 'react-bootstrap/Button'
import { OggettoInterface } from '../interfaces/generiche'

//componente che permette di visualizzare/nascondere l'elemento di loading della pagina e l'overlay grigio
export const Loading = (props: { isVisible: boolean, position?: string }) => {
    const [position, setPosition] = useState<OggettoInterface>()
    useEffect(() => {
        if (props.position) {
            setPosition({ of: props.position })
        } else {
            setPosition({ my: 'center', at: 'center', of: 'window' })
        }
    }, [props.position])
    return (
        <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            showIndicator={true}
            showPane={true}
            shading={true}
            visible={props.isVisible}
            closeOnOutsideClick={false}
            position={position}
        />
    )
}

export const DialogInfoOk = (props: {
    dialogIsOpen: boolean,
    message: any | undefined,
    functionNext: () => void,
    handleCloseConferma: () => void
}) => {
    const handleClose = () => {
        props.handleCloseConferma();
    }
    return (
        <Modal
            show={props.dialogIsOpen}
            onHide={handleClose}
            dialogClassName="dialogConferma"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <div id="dialogConfermaBody">
                    {props.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button id="modalConfermaAnnulla" variant="info" onClick={handleClose} >
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogInfo = (props: {
    dialogIsOpen: boolean,
    message: string | undefined,
    functionNext: () => void,
    handleCloseConferma: () => void
}) => {
    const handleClose = () => {
        props.handleCloseConferma();
    }
    return (
        <Modal
            show={props.dialogIsOpen}
            onHide={handleClose}
            dialogClassName="dialogConferma"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <div id="dialogConfermaBody">
                    {props.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
              <Button id="modalConfermaAnnulla" variant="info" onClick={handleClose} >
                    CHIUDI
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogConferma = (props: {
    dialogIsOpen: boolean,
    message: string | undefined,
    functionNext: () => void,
    handleCloseConferma: () => void
}) => {
    const handleClose = () => {
        props.handleCloseConferma();
    }
    return (
        <Modal
            show={props.dialogIsOpen}
            onHide={handleClose}
            dialogClassName="dialogConferma"
            centered={true}
            backdrop="static"
            style={{ display: "block"}}
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <div id="dialogConfermaBody">
                    {props.message}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button id="modalConfermaOk" variant="success" onClick={props.functionNext}>
                    OK
                </Button>
                <Button id="modalConfermaAnnulla" variant="danger" onClick={handleClose} >
                    Annulla
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogConfermaPrezzoPromozione = (props: {
    dialogIsOpen: boolean,
    message: string | undefined,
    subMessage: string | undefined,
    subMessage2: string | undefined,
    functionNext: (tipo: any) => void,
    handleCloseConferma: () => void
}) => {
    const handleClose = () => {
        props.handleCloseConferma();
    }
    return (
        <Modal
            show={props.dialogIsOpen}
            onHide={handleClose}
            dialogClassName="dialogConferma"
            centered={true}
            backdrop="static"
            size="lg"
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <div id="dialogConfermaBody">
                    {props.message}
                </div>
                {props.subMessage &&
                    <div id="dialogConfermaBody">
                        {props.subMessage}
                    </div>
                }
                {props.subMessage2 &&
                    <div id="dialogConfermaBody">
                        {props.subMessage2}
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button id="modalConfermaProcedi" variant="success" onClick={() => props.functionNext(1)}>
                    Procedi
                </Button>
                <Button id="modalIgnora" variant="success" onClick={() => props.functionNext(2)}>
                    Ignora
                </Button>
                <Button id="modalConfermaAnnulla" variant="danger" onClick={handleClose} >
                    Annulla
                </Button>

            </Modal.Footer>
        </Modal>
    )
}