import React, { useEffect, useState } from 'react'


import './style.css';


const MercatoBusiness = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   

    
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    
   
    return (
        <>
       
             <div style={{height:'80vh'}}>
                 <embed id="ifm"  width="100%" height="100%" src="https://sss.cloud-farma.it/app/main#/dashboards/5d9470914077f91ebcd0f021?embed=true" ></embed>
            </div>      
           
        </>
    )
}

export default MercatoBusiness