import React, { useState, useEffect, useRef } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { ElementOptionChanged } from '../../../interfaces/componentOptions'
import { SelectBox } from 'devextreme-react/select-box'


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
    listaFarmacie:any,
} 

export const DialogImpostazioni:React.FC<DialogProps> = ({open,close, edit, listaFarmacie})  => {

    const formRef:any = useRef();
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const [causaleSetDef, setCausaleSetDef] = useState<any>([])
    const [causali, setCausali] = useState<any>([])
    const [causaliPass, setCausaliPass] = useState<any>([])
    const [deff,setDef] = useState<any>({attiva:null,passiva:null})
    const [newDeff,setNewDef] = useState<any>({attiva:null,passiva:null})



    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({}) 
        }
    },[edit])


    const setCausaleDefault  = async() =>{
       // console.log(newDeff);
        try {
            if(newDeff.attiva){
                const attiveDef = await RestServices().rest('POST', 
                {
                    "idCausale": newDeff.attiva,
                    "flagCicloAttivo": true
                  } , urlConsultazione.setCausaleDefault)

            }
            if(newDeff.passiva){
                const passivaDef = await RestServices().rest('POST', {
                    "idCausale": newDeff.passiva,
                    "flagCicloAttivo": false
                  } , urlConsultazione.setCausaleDefault)

            }
            
            
            //alert('ookkkk') 
            close()
          } catch (error) {
            console.error(error);
            notify({ position: "center", width: "auto", message:"Qualcosa è andato storto..."}, "error", 4000) 
          }
            

    }

    const cambiaValore = (tipo:string,value:any) =>{
            let objTo = {...newDeff}
            objTo[tipo] = value
            //console.log(objTo);
            setNewDef(objTo)
    }


    const getCausali = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListTipoCausaPrimaNota)
               .then(result => {               
                   if (result.esito == true) {                       
                       let attivi: any = [];
                       let passivi:any = [];
                       let defObj:any = {attiva:null,passiva:null}
                        //let defAttivo:number|null
                        //let defPassivo:number|null
                       //console.log(result.response);

                       //Per Causali Passive, si cicla dentro ogni elemento della risposta (responseItem) e si prosegue ciclando all'interno di tali elementi (item)
                       //E qui viene controllato il flag, negli elementi passivi il flagUscita è true quindi si cicla di conseguenza come visto
                       //Ovviamente si procede ugualmente per Causali Attive

                       result.response.forEach((responseItem: any) => {
                           responseItem.items.forEach((item: any) => {

                               if (item.flagUscita == true) {

                                   defObj.passiva = item

                                   passivi.push(item);

                               } else if(item.flagEntrata == true) {

                                   defObj.attiva = item

                                   attivi.push(item);

                               }
                           });
                       });
                           
                       //result.response.forEach((element: any) => {
                       //    if (element.flagUscita == true && element.flagDefault == true && element.flagEntrata == false) {
                       //        if (element.flagDefault) {
                       //            defObj.passiva = element
                       //        }
                       //        passivi.push(element)

                       //    }
                       //    else if (element.flagEntrata == true && element.flagDefault == true && element.flagUscita == false) {
                       //        if (element.flagDefault) {
                       //            defObj.attiva = element
                       //        }
                       //        attivi.push(element)
                       //    }
                       //});
                       
                       setCausali(attivi)
                       setCausaliPass(passivi)
                       //setDef(defObj)
                       //setCausali(result.response)  

                   }else{                           
                       //console.log(result); 
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                 
                   }
               })     
       }
   }


    const getCausualeDef = async() =>{
        if (auth ) {
            try {         
                const attiveDef = await  RestServices().rest('POST', { flagCicloAttivo: true } , urlConsultazione.getCausaleDefault)
                const passeDef = await  RestServices().rest('POST', { flagCicloAttivo: false } , urlConsultazione.getCausaleDefault)
                //console.log('vedooo');
                
                //console.log(attiveDef.response.idCausale);
                //console.log(passeDef);
                
                let defObj:any = {attiva:attiveDef.response?.idCausale,passiva:passeDef.response?.idCausale}
                setDef(defObj)

               // alert('ookkkk') 
              } catch (error) {
                console.error(error);
                notify({ position: "center", width: "auto", message:"Qualcosa è andato storto..."}, "error", 4000) 
              }    
       }
   }

   const setCausualeDef = () =>{
    if (auth )  {
       RestServices().rest('POST', { } , urlConsultazione.setCausaleDefault)
           .then(result => {
               if (result?.esito) {                    
                   //console.log(result.response);
                   setCausaleSetDef(result.response)                     
               }else{                           
                   //console.log(result); 
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                 
               }
           })     
   }
}




  

    useEffect(()=>{
        setOpenD(open)
    },[auth])


    useEffect(()=>{
        setOpenD(open)
        getCausali()
        getCausualeDef()
        //setCausualeDef()
    },[open])

    

    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }



    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'60%'}
          showTitle={true}
          title="Impostazioni">
           

             {/* <div  className='title'>
                <h4> SACDENZARIO </h4>
             </div> */}

                <div style={{ display: 'flex', top:10,left:10, zIndex:2, marginRight:10, marginBottom:20 }}>
                      <h6>Seleziona le causali con le quali le fatture, impostate come pagate nello scadenzario, vengono inserite nella prima nota. Attivo per le fatture provenienti dallo scadenzario attivo, passivo per lo scadenzario passivo.</h6>
                        </div>

                <div className='form-row' style={{ marginTop: 10, marginLeft: 10, marginRight: 10 }}>
                    <div className="form-group col-md-6">
                        <label>ATTIVO </label> 
                        {deff.attiva ? (
                           <SelectBox  ref={formRef} displayExpr="descrizioneBreve"  defaultValue={deff.attiva} valueExpr="id"  searchEnabled={true} dataSource={causali} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('attiva',v.value)}   /> 

                        ) : (
                            <div><SelectBox  ref={formRef} displayExpr="descrizioneBreve"  valueExpr="id"  searchEnabled={true} dataSource={causali} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('attiva',v.value)}   /></div> 

                        )}
                        </div> 
                            
                        <>           
                       <div className="form-group col-md-6">
                            <label>PASSIVO</label>
                       {deff.passiva ? (
                           <SelectBox  ref={formRef} displayExpr="descrizioneBreve"  defaultValue={deff.passiva} valueExpr="id"  searchEnabled={true} dataSource={causaliPass} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('passiva',v.value)}   /> 

                        ) : (
                            <div><SelectBox  ref={formRef} displayExpr="descrizioneBreve"  valueExpr="id"  searchEnabled={true} dataSource={causaliPass} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('passiva',v.value)}   /></div> 

                            )}

                 </div>
                        </>

                    <label style={{ marginTop: 20 }}> Nota: In caso di registrazione incasso da Scadenzario <span style={{ color: 'red' }}>Passivo</span> su Note di credito verrà usata di default la causale <span style={{ color: 'red' }}>'Nota di Credito'</span> e verrà registrato nei movimenti in <span style={{ color: 'red' }}>entrata</span>.
                        Invece in  caso di registrazione incasso da Scadenzario <span style={{ color: 'red' }}>Attivo</span> su Note di credito verrà usata di default la causale <span style={{ color: 'red' }}>'Nota di Credito Attiva'</span> e verrà registrato nei movimenti in <span style={{ color: 'red' }}>uscita</span>.
                    </label>

                    </div>
        
             {/* <TabPanel
                        selectedIndex={selectedIndex}
                        loop={false}
                        animationEnabled={true}
                        onOptionChanged={onOptionChanged}   
                        width={'100%'}    
                        height={'80%'}        
                    >
                        
                        <Item title="SCADENZARIO" icon="">

                        <div className='form-row' style={{marginTop:10,marginLeft:10, marginRight:10}}> 
                   <div className="form-group col-md-6">
                        <label>ATTIVO </label> 
                        {deff.attiva ? (
                           <SelectBox  ref={formRef} displayExpr="descrizioneBreve"  defaultValue={deff.attiva} valueExpr="id"  searchEnabled={true} dataSource={causali} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('attiva',v.value)}   /> 

                        ) : (
                            <div><SelectBox  ref={formRef} displayExpr="descrizioneBreve"  valueExpr="id"  searchEnabled={true} dataSource={causali} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('attiva',v.value)}   /></div> 

                        )}
                        </div> 
                            
                        <>           
                       <div className="form-group col-md-6">
                       <label>PASSIVO </label> 
                       {deff.passiva ? (
                           <SelectBox  ref={formRef} displayExpr="descrizioneBreve"  defaultValue={deff.passiva} valueExpr="id"  searchEnabled={true} dataSource={causaliPass} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('passiva',v.value)}   /> 

                        ) : (
                            <div><SelectBox  ref={formRef} displayExpr="descrizioneBreve"  valueExpr="id"  searchEnabled={true} dataSource={causaliPass} valueChangeEvent="keyup" showClearButton={true}  onValueChanged={(v)=>cambiaValore('passiva',v.value)}   /></div> 

                        )}


                        </div>
                        </>
                        <div style={{position:'fixed',top:100,left:20, zIndex:2, marginRight:20 }}>
                      <h6>SELEZIONA LE CAUSALI CON LE QUALI LE FATTURE, IMPOSTATE COME PAGATE NELLO SCADENZARIO, VENGONO INSERITE NELLA PRIMA NOTA. ATTIVO PER LE FATTURE PROVENIENTI DALLO SCADENZARIO ATTIVO, PASSIVO PER LO SCADENZARIO PASSIVO.</h6>
                        </div>
                 
                    </div>           
                       </Item>

                       
                        </TabPanel> */}
                        
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options=  {{
                text: 'SALVA',
                class:'btncreatipocluster',
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>setCausaleDefault(),
            }}
              
          />
          
        
        </Popup>
        </>
      )

  
}
