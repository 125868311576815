import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { Chart, PieChart } from 'devextreme-react';
//import { boxCard, boxsecond } from "./BoxCard/analisiPerOperatore";
import { Animation, ArgumentAxis, CommonSeriesSettings, Export, Format, Font, Label, Legend, Series, Subtitle, Title, Tooltip, ValueAxis } from 'devextreme-react/chart';
import { Connector, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';

import { ListaFarmacieInterface } from "../../../../interfaces/farmacie";
import { urlConsultazione, urlReport } from "../../../costanti";
import { Filtri } from "../../../consultazione/interface";
import { useAuth } from "../../../../hooks/useAuth";
import { Loading } from "../../../../widget/Notifications";
import { RestServices } from "../../../../services/restServices";
import { createItemFilterAnalVendDitta } from "../../operativo/ItemsFiltri/FiltriAnalOperativo";
import { ConvertForSelect } from "../../filters/FiltriVerticaleNewVersione";
import { NewTabella } from "../../../../widget/nuova_tabella/newBusineVersion";
import FiltriVerticale from "../../filters/FiltriVerticaleNewVersione";
import { AplicaValoreTO } from "../../../../funzioni/operazioniSuTabelle";

const MercatoGalileoInterni = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {

    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop5, setVisiblePop5] = useState(false);
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const [tabella, setTabella] = useState<any[]>([]);

    const dashboard33 = { dashboardID: 33 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardID33 = {
                ...dashboard33,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            //console.log('mostro call');
            //console.log(dashboardCall1);

            RestServices()
                .rest("POST", dashboardID33, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {

                        let tabella = result[123]; 
                        setTabella(tabella);

                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };




    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

 
    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabellaMercato");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabellaMercato.csv"
                );
            });
        });
    };

    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
    ]

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible5 = (testo: string) => {
        setVisiblePop5(true);
    };


    const columns = [
        {
            caption: "Provincia sigla",
            dataField: "FARM_PROVINCIA_SIGLA",
            width: "auto",
            fixed: true
        },
        {
            caption: "Provincia",
            dataField: "FARM_PROVINCIA",
            width: "auto",
            fixed: true
        },
        {
            caption: "Prodotto",
            dataField: "PRO_DESCRIZIONE_ESTESA",
            width: "auto",
            fixed: true,
            sortOrder: 'asc'
        },
        ,
        {
            caption: "MinSan",
            dataField: "PRO_COD_PROD",
            width: "auto",
            fixed: true
        },
        {
            caption: "Prezzo medio",
            dataField: "PREZZO_MEDIO",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Prezzo max",
            dataField: "PREZZO_MAX",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Prezzo min",
            dataField: "PREZZO_MIN",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Incidenza % a valore al totale provincia",
            dataField: "INCIDENZA_VALORE_VENDITA_FARM_PROVINCIA",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        }
    ];


    return (
        <>
            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                                <h2>Mercato</h2>
                        </div>
                        {/* flexbox */}


                        {/* 2 tabella*/}
                        <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginBottom: 10, marginLeft: '5px' }}>Tabella dettaglio per province</h6>
                        <div style={{ marginTop: "5px", marginBottom: 20 }}>
                            <NewTabella
                                height={"70vh"}
                                idTabella="analisi_mercato_interni"
                                sync
                                colonne={columns}
                                dataSource={tabella}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiMercato",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                wordWrapEnabled={true}
                                headerFilter
                                    onExporting={onExporting}
                                    summary={
                                        <Summary>
                                            <TotalItem
                                                column="INCIDENZA_VALORE_VENDITA_FARM_PROVINCIA"
                                                summaryType="sum"
                                            />
                                        </Summary>
                                    }
                            />
                        </div>
                      
                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
        </>
    );
};

export default MercatoGalileoInterni;
