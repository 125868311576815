import { VenditaDettInterface, CaricoDettInterface } from './../interfaces/bancaDati';
import { OggettoInterface } from '../interfaces/generiche';
import { ProdBDInterface, GiacenzaDettInterface, CostoDettInterface, PrezzoFarmaciaInterface, PrezzoPromozioneInterface, GiacenzaDettMagazzInterface } from '../interfaces/bancaDati';
import { CostoInterface, OfferteInterface, PrezziStoriciInterface } from '../interfaces/bancaDati';
import { RestServices } from "../services/restServices";
import { urlAnagrafiche, urlBancaDati } from "../contenuti/costanti";
import { useCallback, useState, useEffect } from 'react';

export const useFetchProdFarm = (
    parametri: OggettoInterface | undefined,
    setIsVisible: (visible: boolean) => void,
    setDettagliProd: (dettProd: ProdBDInterface) => void,
    setUltimoCosto: (ultimoCosto: CostoInterface[]) => void,
    setOfferte: (offerte: OfferteInterface[]) => void,
    setPrezzoStorico: (prezzoStorico: PrezziStoriciInterface[]) => void,
    setImaProd: (imaProd: string) => void,
    setVenditaDett: (venditaDett: VenditaDettInterface[]) => void,
    setCaricoDett: (caricoDett: CaricoDettInterface[]) => void
) => {
    const [giacenzaDett, setGiacenzaDett] = useState<GiacenzaDettInterface[]>([]);
    const [costoMedioDett, setCostoMedioDett] = useState<CostoDettInterface[]>([]);
    const [prezzoFarmacia, setPrezzoFarmacia] = useState<PrezzoFarmaciaInterface[]>([]);
    const [prezzoPromozione, setPrezzoPromozione] = useState<PrezzoPromozioneInterface[]>([]);
    const [giacenzaDettMagazz, setGiacenzaDettMagazz] = useState<GiacenzaDettMagazzInterface[]>([]);
    const [lottoRiordino, setLottoRiordino] = useState<number>();

    useEffect(() => {
        if (parametri) {
            getDettProd();
        }
    }, [parametri])

    const getDettProd = useCallback(() => {
        setIsVisible(true);

        RestServices().rest('POST', parametri?.codCsf, urlBancaDati.getProd).then(result => {
            if (result) {
                setDettagliProd({ ...result });
                setIsVisible(false);
            }
        });

        RestServices().rest('POST', parametri?.codCsf, urlBancaDati.immagineProd, undefined, true).then(res => {
            if (res === "nessuna immagine") {
                setImaProd("");
            } else {
                setImaProd(res);
            }
        })

        RestServices().rest('POST', parametri, urlBancaDati.getGiacenzaDett).then(result => {
            if (result.length > 0) setGiacenzaDett(result);
        })

        RestServices().rest('POST', parametri, urlBancaDati.getCostoMedioDett).then(result => {
            if (result.length > 0) setCostoMedioDett(result);
        })

        RestServices().rest('POST', parametri, urlBancaDati.getPrezzoFarmacia).then(result => {
            if (result.length > 0) setPrezzoFarmacia(result);
        })

        RestServices().rest('POST', parametri, urlBancaDati.getPrezzoPromozione).then(result => {
            if (result.length > 0) setPrezzoPromozione(result);
        })

        RestServices().rest('POST', parametri, urlBancaDati.getGiacenzaDettMagazz).then(result => {
            if (result.length > 0) {
                let getDescrizioneMagazz: { [key: number]: string } = {};
                (async () => {
                    for (let i = 0; i < result[0].giacenze.length; i++) {
                        let giacenza = result[0].giacenze[i];
                        let idMagazzino = giacenza.idMag;
                        if (idMagazzino) {
                            if (getDescrizioneMagazz[idMagazzino] === undefined) {
                                let res = await RestServices().rest('POST', idMagazzino, urlAnagrafiche.getDescrizioneMagazzino)
                                if (res) {
                                    getDescrizioneMagazz[idMagazzino] = res.descrizione;
                                    giacenza.descrizione = res.descrizione;
                                }
                            } else {
                                giacenza.descrizione = getDescrizioneMagazz[idMagazzino];
                            }
                        }
                    }
                })();
            }
            setGiacenzaDettMagazz(result);
        })

        RestServices().rest('POST', parametri, urlBancaDati.getLottoRiordino).then(result => {
            if (result.length > 0) setLottoRiordino(result[0].lottoRiordino)
        })

        RestServices().rest('POST', parametri, urlBancaDati.getUltimoCosto).then(result => {
            if (result.length > 0) {
                let getRagioneSociale: { [key: number]: string } = {};
                (async () => {
                    for (let i = 0; i < result[0].costi.length; i++) {
                        let costo = result[0].costi[i];
                        let idFornitore = costo.idAnaGenerale;
                        if (getRagioneSociale[idFornitore] === undefined) {
                            let result = await RestServices().rest('POST', idFornitore, urlAnagrafiche.getAnagraficaFornitore)
                            if (result) {
                                getRagioneSociale[idFornitore] = result.ragioneSociale;
                                costo.ragioneSociale = result.ragioneSociale;
                            }
                        } else {
                            costo.ragioneSociale = getRagioneSociale[idFornitore];
                        }
                    }
                })();
                setUltimoCosto(result[0].costi);
            }
        })

        RestServices().rest('POST', parametri, urlBancaDati.getVenditaMovimDett).then(result => {
            if (result.length > 0) {
                setVenditaDett(result);
            }
        })

        RestServices().rest('POST', parametri, urlBancaDati.getCaricoMovimDett).then(result => {
            if (result.length > 0) {
                setCaricoDett(result);
            }
        })

        RestServices().rest('POST', parametri, urlBancaDati.getOfferte).then(result => {
            if (result.length > 0) {
                let getRagioneSociale: { [key: number]: string } = {};
                (async () => {
                    for (let i = 0; i < result[0].offerte.length; i++) {
                        let offerte = result[0].offerte[i];
                        let idFornitore = offerte.idAnaGenerale;
                        if (getRagioneSociale[idFornitore] === undefined) {
                            let result = await RestServices().rest('POST', idFornitore, urlAnagrafiche.getAnagraficaFornitore)
                            if (result) {
                                getRagioneSociale[idFornitore] = result.ragioneSociale;
                                offerte.ragioneSociale = result.ragioneSociale;
                            }
                        } else {
                            offerte.ragioneSociale = getRagioneSociale[idFornitore];
                        }
                    }
                })();
                setOfferte(result[0].offerte);
            }
        })

        RestServices().rest('POST', parametri, urlBancaDati.getPrezzoStoricoFarm).then(result => {
            if (result.length > 0) {
                setPrezzoStorico(result[0].prezziStorici);
            }
        })

        //     if (dettagliProd && dettagliProd.codAtc) {
        //         RestServices().rest('POST', dettagliProd.codAtc, urlBancaDati.atcHierarchy).then(risultato => {
        //             if (risultato) {
        //                 setAtcHierarchy(risultato.atcHierarchy);
        //             }
        //         })
        //     }
    }, [parametri]);

    return { giacenzaDett, costoMedioDett, prezzoFarmacia, prezzoPromozione, giacenzaDettMagazz, lottoRiordino }
}