import { TagBox, TreeView } from 'devextreme-react';
import { CheckBox } from "devextreme-react/check-box";
import DataGrid, { Column, FilterRow, Selection } from 'devextreme-react/data-grid';
import DropDownBox from 'devextreme-react/drop-down-box';
import SelectBox from 'devextreme-react/select-box';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { request } from 'https';
import React, { useEffect, useRef, useState } from 'react';
import { urlReport } from '../contenuti/costanti';
import { SelectOptionChangeInterface } from '../interfaces/componentOptions';
import { ListaFarmacieInterface } from '../interfaces/farmacie';
import { OggettoInterface } from '../interfaces/generiche';
import { ValueGridInterface } from '../interfaces/tabelle';
import { RestServices } from '../services/restServices';



// func per Farm

// export const ConvertForSelect = (arr: any) => {
//     let newArr: any = []
//     arr.forEach((element: any) => {
//         newArr.push({ DESCRIZIONE: element.ragioneSociale, isSelected: element.isSelected ? true : false, ID_FAR: element.idFar, CODICE: element.codice })
//     });
//     return newArr
//   }

export const TendinaFarm = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    tutteSelezionate?: boolean,
    showOnlyCod?: boolean,
    disabled?: boolean | undefined
    placeholder?: string
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    useEffect(() => {
        if (props.valueGrid) {
            setValueGrid(props.valueGrid);
        }
    }, [props.valueGrid])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.datiFarmacie && props.primaSelezionata && props.datiFarmacie.length > 0) {
            setValueGrid([props.datiFarmacie[0].ID_FAR]);
            impostaValoreFiltro([props.datiFarmacie[0].ID_FAR]);
        }
    }, [props.datiFarmacie])

    useEffect(() => {
        if (props.datiFarmacie && props.tutteSelezionate && props.datiFarmacie.length > 0) {
            const tutteLeFarmacie = props.datiFarmacie.map(farmacia => farmacia.ID_FAR);
            setValueGrid(tutteLeFarmacie);
            impostaValoreFiltro(tutteLeFarmacie);
        }
    }, [props.datiFarmacie, props.tutteSelezionate])

    //imposta il valore della tendina -> questo componente è usato per la selezione multipla delle farmacie quindi si ha una
    //lista di id e non un singolo valore
    const cambiaValore = (e: ValueGridInterface) => {
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: number[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={props.datiFarmacie}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="ID_FAR"
                scrolling={{ mode: "infinite" }}
                height="100%"
            >
                <Selection mode="multiple" />
                <FilterRow visible={true} />
                <Column
                    dataField="DESCRIZIONE"
                    caption="Descrizione"
                    sortOrder="asc"
                />
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        //console.log(dropGrid)
        if (!dropGrid) return;
        if (!e.value) {
            if (dropGrid.current != null)
                dropGrid.current.instance.deselectAll();
        } else {
            if (dropGrid.current != null)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }
    return (
        <DropDownBox
            ref={dropDown}
            dataSource={props.datiFarmacie}
            value={valueGrid}
            valueExpr="ID_FAR"
            displayExpr={props.showOnlyCod ? "CODICE" : "DESCRIZIONE"}
            showClearButton={true}
            contentRender={dataGridRender}
            onValueChanged={syncDataGridSelection}
            disabled={props.disabled}
            placeholder={props.placeholder}

        />
    )
}


export const TendinaFarmNew = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    tutteSelezionate?: boolean,
    showOnlyCod?: boolean,
    disabled?: boolean | undefined
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    useEffect(() => {
        if (props.valueGrid) {
            setValueGrid(props.valueGrid);
            impostaValoreFiltro(props.valueGrid)
        }
    }, [props.valueGrid])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.datiFarmacie && props.primaSelezionata && props.datiFarmacie.length > 0) {
            setValueGrid([props.datiFarmacie[0].ID_FAR]);
            impostaValoreFiltro([props.datiFarmacie[0].ID_FAR]);
        }
    }, [props.datiFarmacie])

    useEffect(() => {
        if (props.datiFarmacie && props.tutteSelezionate && props.datiFarmacie.length > 0) {
            const tutteLeFarmacie = props.datiFarmacie.map(farmacia => farmacia.ID_FAR);
            setValueGrid(tutteLeFarmacie);
            impostaValoreFiltro(tutteLeFarmacie);
        }
    }, [props.datiFarmacie, props.tutteSelezionate])

    //imposta il valore della tendina -> questo componente è usato per la selezione multipla delle farmacie quindi si ha una
    //lista di id e non un singolo valore
    const cambiaValore = (e: ValueGridInterface) => {
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: number[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    const dataGridRender = () => {


        return (
            <DataGrid
                dataSource={props.datiFarmacie}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="ID_FAR"
                scrolling={{ mode: "infinite" }}
                height="100%"
            >
                <Selection mode="multiple" />
                <FilterRow visible={true} />
                <Column
                    dataField="CODICE"
                    caption="codice"
                    sortOrder="asc"
                    width={100}
                />
                <Column
                    dataField="DESCRIZIONE"
                    caption="Descrizione"
                />


            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        //console.log(dropGrid)
        if (!dropGrid) return;
        if (!e.value) {
            if (dropGrid.current != null)
                dropGrid.current.instance.deselectAll();
        } else {
            if (dropGrid.current != null)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }
    return (
        <DropDownBox
            ref={dropDown}
            dataSource={props.datiFarmacie}
            value={valueGrid}
            valueExpr="ID_FAR"
            displayExpr={props.showOnlyCod ? "CODICE" : "DESCRIZIONE"}
            showClearButton={true}
            contentRender={dataGridRender}
            onValueChanged={syncDataGridSelection}
            disabled={props.disabled}
        />
    )
}

export const SelectFarm = (props: {
    datiFarmacie?: ListaFarmacieInterface[],
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    value?: number,
    noDataText?: string,
    displayExpr?: boolean,
    showClearButton?: boolean,
    disabled?: boolean,
    showCodTph?: boolean,
    showGes?: boolean
}) => {
    const [idFar, setIdFar] = useState<number>();

    useEffect(() => {
        setIdFar(props.value)
    }, [props.value])
    //imposta il valore della select -> usata per selezioni singole di farmacie quindi restituisce un singolo numero (id)
    const cambiaValore = (e: SelectOptionChangeInterface) => {
        let newFiltro: OggettoInterface = {};
        newFiltro[props.id] = e.value;
        props.impostaFiltri(newFiltro);
        setIdFar(e.value);
    }
    //se c'è una sola farmacia nel gruppo, essa viene selezionata automaticamente e l'id viene inviato all'oggetto filtri
    useEffect(() => {
        if (props.datiFarmacie && props.datiFarmacie.length === 1) {
            const idFarmacia = props.datiFarmacie[0].ID_FAR;
            setIdFar(idFarmacia);
            let newFiltro: OggettoInterface = {};
            newFiltro[props.id] = idFarmacia;
            props.impostaFiltri(newFiltro);
        }
    }, [props.datiFarmacie]);

    //si cambia il testo visualizzato nella tendina dopo la selezione -> se props.displayExpr = true si vede solo la descrizione
    //della farmacia mentre se props.displayExpr = false si ha "id - descrizione"
    let espressione: string | ((item: any) => string);
    if (props.displayExpr) {
        espressione = "DESCRIZIONE";
    } else if (props.showCodTph && props.showGes) {
        espressione = function (item) {
            return item && item.ID_FAR + ' - ' + item.CODICE + ' - ' + item.DESCRIZIONE + ' - ' + item.GESTIONALE
        }
    } else if (props.showCodTph) {
        espressione = function (item) {
            return item && item.ID_FAR + ' - ' + item.CODICE + ' - ' + item.DESCRIZIONE
        }
    }
    else if (props.showGes) {
        espressione = function (item) {
            return item && item.ID_FAR + ' - ' + item.DESCRIZIONE + ' - ' + item.GESTIONALE
        }
    }
    else if (props.showGes) {
        espressione = function (item) {
            return item && item.ID_FAR + ' - ' + item.DESCRIZIONE + ' - ' + item.GESTIONALE
        }
    }
    else {
        espressione = function (item) {
            return item && item.ID_FAR + ' - ' + item.DESCRIZIONE
        }
    }

    return (
        <SelectBox
            displayExpr={espressione}
            valueExpr="ID_FAR"
            dataSource={props.datiFarmacie}
            onValueChanged={cambiaValore}
            placeholder=" "
            searchEnabled={true}
            noDataText={props.noDataText}
            value={idFar}
            showClearButton={props.showClearButton}
            disabled={props.disabled}
        />
    )
}

export const Selezione = (props: {
    dataSource: string | any[] | DataSource | DataSourceOptions | undefined,
    displayExpr?: string,
    valueExpr?: string,
    impostaFiltri?: (newFiltro: object) => void,
    id: string,
    value?: any,
    placeholder?: string,
    onChange?: (valoreDigitato: string) => void,
    searchEnabled?: boolean,
    isService?: boolean,
    isSelezioneMultipla?: boolean,
    clearButton?: boolean,
    defaultValue?: any,
    disabled?: boolean
}) => {
    const [dataSource, setDataSource] = useState<string | any[] | DataSource | DataSourceOptions | undefined>(undefined);
    const [valore, setValore] = useState<any | null>(null);
    const [searchEnabled, setSearchEnabled] = useState(false);
    const [isService, setIsService] = useState(false);
    const [isSelezioneMultipla, setIsSelezioneMultipla] = useState(false);
    const [clearButton, setClearButton] = useState(false);
    const delay = useRef<number | undefined>(undefined);

    useEffect(() => {

        if (!props.isSelezioneMultipla) {
            setValore(props.value)
            setDataSource(props.dataSource);

        } else {
            setDataSource(props.dataSource);
        }

    }, [props.isSelezioneMultipla, props.dataSource, props.value]);



    useEffect(() => {
        if (props.searchEnabled === true) setSearchEnabled(true);
        if (props.clearButton === true) setClearButton(true);

        if (props.isService === true) {
            setIsService(true);
            setSearchEnabled(true);
            setClearButton(true);

            if (props.isSelezioneMultipla === true) {
                setIsSelezioneMultipla(true);
            }
        }

    }, [props.searchEnabled, props.clearButton, props.isService, props.isSelezioneMultipla])

    //si imposta il valore della selezione effettuata -> poiché è a selezione singola, restituisce un unico numero (id)
    const cambiaValore = (e: any) => {

        if (e.value) {

            const param = props.id;
            let newFiltro: OggettoInterface = {};
            newFiltro[param] = e.value

            if (props.impostaFiltri)
                props.impostaFiltri(newFiltro);

            setValore(e.value);

        } else {

            setValore(undefined);
            if (props.impostaFiltri)
                props.impostaFiltri({ [props.id]: undefined });
        }

    }



    // handleOptionChanged trasforma il componente SelectBox in un componente
    // che determina un servizio grazie agli attributi props aggiunti, nello specifico isService
    // che abilita il campo di ricerca setSearchEnabled(true) che fungerà da ricerca per un servizio esterno, poi accende
    // setClearButton(true) che è il bottone della select box per svuotare l'elemento,
    // il tutto definito al momento della dichiarazione, basta dichiarare isService={true}, e poi nella props aggiunta onChange, mettere ciò che si vuole, aggiunto anche placeholder, facoltativo ma utile
    // <Selezione id="codiceDitta" placeholder="Cerca Ditta" dataSource={ditta} displayExpr="text" valueExpr="id" isService={true} onChange={(valoreDigitato) => { if (valoreDigitato.length >= 2) { findProdottiDitta(valoreDigitato); } }} />


    const handleOptionChanged = (args: any) => {

        //comanda isService - sul componente va messo isService={true} 
        if (isService && args.name === 'text') {

            if (!isSelezioneMultipla) {

                // è l'oggetto selezionato dalla select box dopo il click
                const currentValue = args.component.option('value');

                //ciò che viene scritto in tempo reale nel campo di testo
                const testoDigitato = args.value ?? "";

                let temp = undefined;

                if (currentValue !== undefined)
                    temp = currentValue;
                else {

                    // resetta il timer ad ogni digitazione riparte, una volta smesso
                    // di digitare il servizio viene chiamato
                    if (delay.current) {
                        clearTimeout(delay.current);
                    }
                    // scatena l'evento onChange dopo qualche secondo per dare il tempo
                    // all'utente di scrivere ciò che vuole filtrare in label e il servizio
                    // aggiornerà il datasource
                    delay.current = window.setTimeout(() => {
                        props.onChange?.(testoDigitato);
                    }, 700);
                }

                if ((temp === currentValue && currentValue === args.component.option('selectedItem.id')) && args.value === "") {

                    // resetta il datasource se la label viene svuotata con backspace,
                    // oppure col tasto clear button abilitato nella select box
                    setDataSource([]);

                    // richiama il metodo cambia valore passando undefined così svuota la label
                    // visivamente e pulisce l'interfaccia impostaFiltri
                    cambiaValore({ value: undefined });

                    // chiude il menù a tendina della selectbox e toglie il focus da esso,
                    // essenziale perchè capitava che una volta svuotato il datasource, ma
                    // col menù a tendina aperto, restavano presenti solo visivamente i record della vecchia chiamata
                    // con questa istruzione l'utente è costretto a riaprire il menù a tendina così che il datasource sia quello aggiornato
                    setTimeout(() => {
                        args.component.option('opened', false);
                        args.component.blur();
                    }, 0)
                }

                //console.log("SINGOLA => currentValue: " + currentValue + " testodigitato: " + testoDigitato + " temp: " + temp);
                //console.log("TIPO => currentValue: " + typeof currentValue + " testodigitato: " + typeof testoDigitato + " temp: " + typeof temp);

            } else {

                const currentValue = Array.isArray(args.component.option('value')) && args.component.option('value').length === 0 ? undefined : args.component.option('value');

                const testoDigitatoArgs = (Array.isArray(args.value) && args.value.length === 0) || args.value === undefined ? "" : args.value;

                let testoDigitato = testoDigitatoArgs;

                if (Array.isArray(currentValue) && currentValue.length > 0) {

                    if (currentValue.includes(testoDigitato)) {
                        testoDigitato = "";
                    }
                }

                if (testoDigitato !== "") {

                    if (delay.current) {
                        clearTimeout(delay.current);
                    }


                    delay.current = window.setTimeout(() => {
                        props.onChange?.(testoDigitatoArgs);
                    }, 700);

                }


                if ((Array.isArray(args.component.option('value')) && args.component.option('value').length === 0) && (Array.isArray(args.component.option('items')) && args.component.option('items').length > 0)) {

                    setDataSource([]);

                    cambiaValore({ value: [] });

                    setTimeout(() => {
                        args.component.option('opened', false);
                        args.component.blur();
                    }, 0)

                }

                //console.log("MULTIPLA => currentValue: " + currentValue + " testodigitatoArgs: " + testoDigitatoArgs);
                //console.log("TIPO => currentValue: " + typeof currentValue + " testodigitato: " + typeof testoDigitato);
            }
        }
    };


    return (
        props.isSelezioneMultipla ? (
            <TagBox
                displayExpr={props.displayExpr}
                valueExpr={props.valueExpr}
                dataSource={dataSource}
                showClearButton={clearButton}
                placeholder={props.placeholder}
                value={valore}
                searchEnabled={searchEnabled}
                onValueChanged={cambiaValore}
                defaultValue={props.defaultValue}
                disabled={props.disabled}
                onOptionChanged={handleOptionChanged}
            />
        ) : (
            <SelectBox
                displayExpr={props.displayExpr}
                valueExpr={props.valueExpr}
                dataSource={dataSource}
                showClearButton={clearButton}
                onValueChanged={cambiaValore}
                placeholder={props.placeholder}
                value={valore}
                searchEnabled={searchEnabled}
                defaultValue={props.defaultValue}
                disabled={props.disabled}
                onOptionChanged={handleOptionChanged}
            />

        )
    )
};

export const SelezionePagamento = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    column: any,
    selection: string;
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])

    const cambiaValore = (e: ValueGridInterface) => {
        //console.log(e);
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: number[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={props.valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="ID"
                scrolling={{ mode: "infinite" }}
                height="100%"
            >
                <Selection mode={props.selection} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value) {
            dropGrid.current.instance.deselectAll();
        } else {
            dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }
    return (
        <DropDownBox
            ref={dropDown}
            dataSource={props.dataSource}
            value={valueGrid}
            valueExpr="ID"
            displayExpr="DESCRIZIONE"
            showClearButton={true}
            contentRender={dataGridRender}
            onValueChanged={syncDataGridSelection}
        />
    )
}


export const SelezioneMultiplaGerarchica = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: string[],
    primaSelezionata?: boolean,
    showCheckBoxesMode: "none" | "normal" | "selectAll" | undefined,
    selectionMode: "multiple" | "single" | undefined,
    selectNodesRecursive: boolean,
    selectByClick: boolean,


}) => {
    //ref per la tendina dropDown e per la treeView al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropTree = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<string[]>();

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])

    const cambiaValore = (e: any) => {

        const listaId = e.component.getSelectedNodes()
            .map((node: { itemData: any; }) => node.itemData.id);

        setValueGrid(listaId)
        impostaValoreFiltro(listaId)

    }

    const impostaValoreFiltro = (listaId: number[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    function renderTreeViewItem(item: any) {
        return `${item.descrizione} (${item.id})`;
    }

    function displayExpression(item: any) {
        return `${item.descrizione} (${item.id})`;
    }

    const treeViewRender = () => {
        return (
            <TreeView
                keyExpr="id"
                parentIdExpr="idPadre"
                dataStructure="plain"
                items={props.dataSource}
                selectNodesRecursive={props.selectNodesRecursive}
                selectByClick={props.selectByClick}
                showCheckBoxesMode={props.showCheckBoxesMode}
                selectionMode={props.selectionMode}
                itemRender={renderTreeViewItem}
                onItemSelectionChanged={cambiaValore}
                ref={dropTree}
            />
        )
    }


    //permette di sincronizzare i due componenti, tendina e grid
    const syncTreeViewSelection = (e: any) => {

        if (!dropTree.current) return;
        if (!e.value && dropTree.current) {
            dropTree.current.instance.unselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropTree.current)
                dropTree.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }
    return (
        <DropDownBox
            ref={dropDown}
            dataSource={props.dataSource}
            value={valueGrid}
            valueExpr="id"
            displayExpr={displayExpression}
            placeholder="Seleziona..."
            showClearButton={true}
            contentRender={treeViewRender}
            onValueChanged={syncTreeViewSelection}
            deferRendering={false}
        />
    )
}

export const SelezioneMultiplaGerarchicaNew = (props: {
    dataSource: any[] | undefined,
    id: string,
    noId?: boolean,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: string[],
    primaSelezionata?: boolean,
    showCheckBoxesMode: "none" | "normal" | "selectAll" | undefined,
    selectionMode: "multiple" | "single" | undefined,
    selectNodesRecursive: boolean,
    selectByClick: boolean,
    reset?: boolean,


}) => {
    //ref per la tendina dropDown e per la treeView al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropTree = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<string[]>();

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])


    useEffect(() => {
        if (props.reset) {
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset])


    const cambiaValore = (e: any) => {

        const listaId = e.component.getSelectedNodes()
            .map((node: { itemData: any; }) => node.itemData.id);

        setValueGrid(listaId)
        impostaValoreFiltro(listaId)

    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    function renderTreeViewItem(item: any) {
        if (props.noId) {
            return `${item.descrizione}`;

        } else {
            return `${item.id} - ${item.descrizione}`;

        }
    }

    function displayExpression(item: any) {

        if (item !== undefined) {
            return `${item.descrizione}`;
        } else return ``;
    }

    const treeViewRender = () => {
        return (
            <TreeView
                keyExpr="id"
                parentIdExpr="idPadre"
                dataStructure="plain"
                items={props.dataSource}
                selectNodesRecursive={props.selectNodesRecursive}
                selectByClick={props.selectByClick}
                showCheckBoxesMode={props.showCheckBoxesMode}
                selectionMode={props.selectionMode}
                itemRender={renderTreeViewItem}
                onItemSelectionChanged={cambiaValore}
                ref={dropTree}

            />
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncTreeViewSelection = (e: any) => {

        if (!dropTree.current) return;
        if (!e.value && dropTree.current) {
            dropTree.current.instance.unselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropTree.current)
                dropTree.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }




    return (
        <>
            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valueGrid}
                valueExpr="id"
                displayExpr={displayExpression}
                placeholder="Seleziona..."
                showClearButton={true}
                contentRender={treeViewRender}
                onValueChanged={syncTreeViewSelection}
                deferRendering={false}
            />
        </>
    )
}


export const SelezioneMultipla = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    column: any,
    selection: string;
    onValueChanged?: (v: any) => void,
    disabled?: boolean | undefined,
    reset?: boolean,
    displayExpr?: string | undefined,
    valueExpr?: string | undefined,
    loadOnOpenedFlag?: boolean | undefined
    onOpened?: (v: any) => void,
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();



    useEffect(() => {
        if (props.reset) {
            //alert('oooo')
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset])

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.primaSelezionata && (props.dataSource != undefined && props.dataSource.length > 0)) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource])

    const cambiaValore = (e: ValueGridInterface) => {
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        // console.log(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }


    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr={props.valueExpr != undefined ? props.valueExpr : "id"}
                scrolling={{ mode: "infinite" }}
                height="100%"

            >
                <Selection mode={props.selection} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value && dropGrid.current) {
            dropGrid.current.instance.deselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropGrid.current)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    function displayExpression(item: any) {

        if (item !== undefined) {
            return `${item.id} - ${item.descrizione}`;
        } else return ``;
    }




    return (
        <>

            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valueGrid}
                valueExpr={props.valueExpr != undefined ? props.valueExpr : "id"}
                displayExpr={props.displayExpr != undefined ? props.displayExpr : "descrizione"}
                showClearButton={true}
                placeholder="Seleziona..."
                contentRender={dataGridRender}
                onValueChanged={syncDataGridSelection}
                deferRendering={false}
                disabled={props.disabled}
                onOpened={props.loadOnOpenedFlag && props.loadOnOpenedFlag == true ? props.onOpened : undefined}
            />
        </>
    )
}



export const SelezioneMultiplaPlusColon = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    column: any,
    selection: string;
    onValueChanged?: (v: any) => void,
    disabled?: boolean | undefined
    reset?: boolean
    allowSelectAll?: boolean
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])


    useEffect(() => {
        if (props.reset) {
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.primaSelezionata && (props.dataSource != undefined && props.dataSource.length > 0)) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource])

    const cambiaValore = (e: ValueGridInterface) => {
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        //console.log(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }


    const dataGridRender = () => {

        return (
            <DataGrid
                columns={['codice', 'descrizione']}
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="id"
                scrolling={{ mode: "infinite" }}
                height="100%"


            >
                <Selection mode={props.selection} allowSelectAll={props.allowSelectAll} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value && dropGrid.current) {
            dropGrid.current.instance.deselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropGrid.current)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    function displayExpression(item: any) {

        if (item !== undefined) {
            return `${item.id} - ${item.descrizione}`;
        } else return ``;
    }


    return (
        <>

            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valueGrid}
                valueExpr="id"
                displayExpr="descrizione"
                showClearButton={true}
                placeholder="Seleziona..."
                contentRender={dataGridRender}
                onValueChanged={syncDataGridSelection}
                deferRendering={false}
                disabled={props.disabled}
            />
        </>
    )
}


export const SelezioneMultiplaPlusColonNoCodice = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    column: any,
    selection: string;
    onValueChanged?: (v: any) => void,
    disabled?: boolean | undefined
    reset?: boolean
    allowSelectAll?: boolean
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])


    useEffect(() => {
        if (props.reset) {
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.primaSelezionata && (props.dataSource != undefined && props.dataSource.length > 0)) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource])

    const cambiaValore = (e: ValueGridInterface) => {
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        //console.log(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }


    const dataGridRender = () => {

        return (
            <DataGrid
                columns={['descrizione']}
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="id"
                scrolling={{ mode: "infinite" }}
                height="100%"


            >
                <Selection mode={props.selection} allowSelectAll={props.allowSelectAll} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value && dropGrid.current) {
            dropGrid.current.instance.deselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropGrid.current)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    function displayExpression(item: any) {

        if (item !== undefined) {
            return `${item.id} - ${item.descrizione}`;
        } else return ``;
    }


    return (
        <>

            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valueGrid}
                valueExpr="id"
                displayExpr="descrizione"
                showClearButton={true}
                placeholder="Seleziona..."
                contentRender={dataGridRender}
                onValueChanged={syncDataGridSelection}
                deferRendering={false}
                disabled={props.disabled}
            />
        </>
    )
}




export const SelezioneMultiplaPlusColonNew = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    column: any,
    selection: string;
    onValueChanged?: (v: any) => void,
    disabled?: boolean | undefined
    reset?: boolean
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<any[]>();

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])


    useEffect(() => {
        if (props.reset) {
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.primaSelezionata && (props.dataSource != undefined && props.dataSource.length > 0)) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource])

    const cambiaValore = (e: ValueGridInterface) => {
        //console.log('cambia vediamo ');
        //console.log(e);

        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push({
                    id: e.selectedRowKeys[i],
                    codice: e.selectedRowKeys[i]
                })
            }
        }
        setValueGrid(listaId)
        //console.log(listaId)
        //impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }


    const dataGridRender = () => {

        return (
            <DataGrid
                columns={['codice', 'descrizione']}
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="id"
                scrolling={{ mode: "infinite" }}
                height="100%"


            >
                <Selection mode={props.selection} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value && dropGrid.current) {
            dropGrid.current.instance.deselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropGrid.current)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    function displayExpression(item: any) {

        if (item !== undefined) {
            return `${item.id} - ${item.descrizione}`;
        } else return ``;
    }


    return (
        <>
            {JSON.stringify(props.dataSource)}
            {JSON.stringify(valueGrid)}
            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valueGrid}
                valueExpr="codice"
                displayExpr="codice"
                showClearButton={true}
                placeholder="Seleziona..."
                contentRender={dataGridRender}
                onValueChanged={syncDataGridSelection}
                deferRendering={false}
                disabled={props.disabled}
            />
        </>
    )
}

export const SelezioneFarvima = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    column: any,
    selection: string;
    request: any[],
    newFarms: any[] | undefined,
    disabled?: boolean | undefined
    reset?: boolean
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<any[]>();
    const [newFarms, setNewFarms] = useState<any>([])


    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid])


    useEffect(() => {
        if (props.reset) {
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.primaSelezionata && (props.dataSource != undefined && props.dataSource.length > 0)) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource])

    const cambiaValore = (e: ValueGridInterface) => {
        RestServices().rest("POST", request, urlReport.extractFarvimaPharmacies)
            .then((result) => {
                if (result) {
                    newFarms(result)
                }
            })

        //newFarms = result of the call
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push({
                    id: e.selectedRowKeys[i],
                    codice: e.selectedRowKeys[i]
                })
            }
        }
        setValueGrid(listaId)
        //console.log(listaId)
        //impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }


    const dataGridRender = () => {

        return (
            <DataGrid
                columns={['codice', 'descrizione']}
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="id"
                scrolling={{ mode: "infinite" }}
                height="100%"


            >
                <Selection mode={props.selection} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value && dropGrid.current) {
            dropGrid.current.instance.deselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropGrid.current)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    function displayExpression(item: any) {

        if (item !== undefined) {
            return `${item.id} - ${item.descrizione}`;
        } else return ``;
    }


    return (
        <>
            {JSON.stringify(props.dataSource)}
            {JSON.stringify(valueGrid)}
            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valueGrid}
                valueExpr="codice"
                displayExpr="codice"
                showClearButton={true}
                placeholder="Seleziona..."
                contentRender={dataGridRender}
                onValueChanged={syncDataGridSelection}
                deferRendering={false}
                disabled={props.disabled}
            />
        </>
    )
}


export const SelezioneMultiplaFarmacie = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    primaSelezionata?: boolean,
    tutteSelezionate?: boolean,
    column: any,
    selection: string;
    placeholder?: string,
    disabled?: boolean | undefined


}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const dropDown = useRef<any>(null)
    const dropGrid = useRef<any>(null)

    const [valueGrid, setValueGrid] = useState<number[]>();

    //useEffect(() => {
    //    if (props.valueGrid) setValueGrid(props.valueGrid);
    //}, [props.valueGrid])

    useEffect(() => {
        if (props.valueGrid) {
            setValueGrid(props.valueGrid);
            impostaValoreFiltro(props.valueGrid)
        }
    }, [props.valueGrid])

    //primaSelezionata è una prop opzionale che permette di selezionare in automatico la prima farmacia della lista
    useEffect(() => {
        if (props.primaSelezionata && (props.dataSource != undefined && props.dataSource.length > 0)) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource])

    useEffect(() => {
        if (props.dataSource && props.tutteSelezionate && props.dataSource.length > 0) {
            if (valueGrid == null) {  //controllo valueGrid aggiunto in maniera tale che ogni volta che scatta l'evento della ricerca col button, 
                const tutteLeFarmacie = props.dataSource.map(farmacia => farmacia.ID_FAR); //la grid non venga sempre settata con tutte le farmacia. Controllando se è vuota, questo funzionerà solo al caricamento della pagina.
                setValueGrid(tutteLeFarmacie); // Nelle future ricerche, se viene inserita solo una farmacia, e quindi la grid non è vuota, non verrà rimosso l'elemento, e quindi non verrà riempita togliendo il filtro scelto dall'utente.
                impostaValoreFiltro(tutteLeFarmacie);
            }
        }
    }, [props.dataSource])



    const cambiaValore = (e: ValueGridInterface) => {
        let listaId = [];
        if (e.selectedRowKeys) {
            for (let i = 0; i < e.selectedRowKeys.length; i++) {
                listaId.push(e.selectedRowKeys[i])
            }
        }
        setValueGrid(listaId)
        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: number[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    const dataGridRender = () => {
        return (
            <DataGrid
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                keyExpr="ID_FAR"
                scrolling={{ mode: "infinite" }}
                height="100%"

            >
                <Selection mode={props.selection} />
                <FilterRow visible={true} />
                {props.column}
            </DataGrid>
        )
    }
    //permette di sincronizzare i due componenti, tendina e grid
    const syncDataGridSelection = (e: any) => {
        if (!dropGrid) return;
        if (!e.value && dropGrid.current) {
            dropGrid.current.instance.deselectAll();
            impostaValoreFiltro([]);
        } else {
            if (dropGrid.current)
                dropGrid.current.instance.selectItem(e.value)
        }
        setValueGrid(e.value)
    }

    return (
        <DropDownBox
            ref={dropDown}
            dataSource={props.dataSource}
            value={valueGrid}
            valueExpr="ID_FAR"
            displayExpr="CODICE"
            showClearButton={true}
            contentRender={dataGridRender}
            onValueChanged={syncDataGridSelection}
            deferRendering={false}
            placeholder={props.placeholder}
            disabled={props.disabled}
        />
    )
}


export const SelezioneGerarchica = (props: {
    dataSource: any[] | undefined,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    width: number;
    height: number,
    showCheckBoxesMode: "none" | "normal" | "selectAll" | undefined,
    selectionMode: "multiple" | "single" | undefined,
    selectNodesRecursive: boolean,
    selectByClick: boolean,
}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)
    const treeview = useRef<any>(null)

    const cambiaValore = (e: any) => {

        const listaId = e.component.getSelectedNodes()
            .map((node: { itemData: any; }) => node.itemData.id);
        //console.log(selected);

        impostaValoreFiltro(listaId)
    }

    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    }

    function renderTreeViewItem(item: any) {
        return `${item.descrizione} (${item.id})`;
    }

    return (
        <TreeView
            width={props.width}
            height={props.height}
            items={props.dataSource}
            selectNodesRecursive={props.selectNodesRecursive}
            selectByClick={props.selectByClick}
            showCheckBoxesMode={props.showCheckBoxesMode}
            selectionMode={props.selectionMode}
            itemRender={renderTreeViewItem}
            //onSelectionChanged={cambiaValore}
            onItemSelectionChanged={cambiaValore}
            ref={treeview}
        />
    )
}



export const SelezioneCheckBox = (props: {
    onValueChanged: (v: any) => void,
    reset?: boolean

}) => {
    //ref per la tendina dropDown e per la tabella al suo interno (grid)

    const ckBox = useRef<any>(null)


    useEffect(() => {
        if (props.reset) {
            //ckBox.current.instance.option('value', null);  
            ckBox.current.instance.option('value', false);
        }
    }, [props.reset])

    return (
        <CheckBox
            ref={ckBox}
            defaultValue={false}
            onValueChanged={(v) => props.onValueChanged(v)}
        />
    )
}

export const SelezioneMultiplaAny = (props: {
    dataSource: any[] | undefined,
    displayExpr?: string,
    valueExpr?: string,
    id: string,
    impostaFiltri?: (newFiltro: OggettoInterface) => void,
    valueGrid?: number[],
    value?: any,
    selection: string;
    primaSelezionata?: boolean,
    columns: any[],
    onValueChanged?: (v: any) => void,
    onOpen?: React.MouseEventHandler<HTMLElement>;
    disabled?: boolean | undefined
    reset?: boolean
}) => {
    const dropDown = useRef<any>(null);
    const dropGrid = useRef<any>(null);

    const [valueGrid, setValueGrid] = useState<any[]>();
    const [valore, setValore] = useState<any | null>(null);

    useEffect(() => {
        setValore(props.value)
    }, [props.value, props.dataSource]);

    useEffect(() => {
        if (props.valueGrid) setValueGrid(props.valueGrid);
    }, [props.valueGrid]);

    useEffect(() => {
        if (props.reset) {
            dropDown.current.instance.option('value', null);
        }
    }, [props.reset]);

    useEffect(() => {
        if (props.primaSelezionata && props.dataSource && props.dataSource.length > 0) {
            setValueGrid([props.dataSource[0].id]);
            impostaValoreFiltro([props.dataSource[0].id]);
        }
    }, [props.dataSource]);


    //const cambiaValore = (e: ValueGridInterface) => {
    //    let listaId = [];
    //    if (e.selectedRowKeys) {
    //        for (let i = 0; i < e.selectedRowKeys.length; i++) {
    //            listaId.push({
    //                id: e.selectedRowKeys[i],
    //                codice: e.selectedRowKeys[i]
    //            });
    //        }
    //    }
    //    setValueGrid(listaId);
    //};


    const cambiaValore = (e: ValueGridInterface) => {

        if (props.selection === "single") {

            //console.log("selezione singola")

            // Gestione selezione singola
            const selectedKey = (e.selectedRowKeys && e.selectedRowKeys.length > 0)
                ? e.selectedRowKeys[0]
                : undefined;

            if (selectedKey) {
                const param = props.id;
                let newFiltro: OggettoInterface = {};
                newFiltro[param] = selectedKey;

                if (props.impostaFiltri) {
                    props.impostaFiltri(newFiltro);
                }

                setValore(selectedKey);
                setValueGrid([selectedKey]);
            } else {
                setValore(undefined);
                setValueGrid(undefined);

                if (props.impostaFiltri) {
                    props.impostaFiltri({ [props.id]: undefined });
                }
            }
        } else if (props.selection === "multiple") {

            //console.log("selezione multipla")

            if (e.selectedRowKeys && e.selectedRowKeys.length > 0) {

                if (props.impostaFiltri) {
                    props.impostaFiltri(e.selectedRowKeys);
                }

                setValore(e.selectedRowKeys);
                setValueGrid(e.selectedRowKeys);

            } else {

                setValore(undefined);
                setValueGrid(undefined);

                if (props.impostaFiltri) {
                    props.impostaFiltri({ [props.id]: undefined });
                }
            }
        }
    };



    const impostaValoreFiltro = (listaId: string[]) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = listaId;
        if (props.impostaFiltri) props.impostaFiltri(newFiltro);
    };

    const dataGridRender = () => {

        return (
            <DataGrid
                keyExpr={props.valueExpr}
                dataSource={props.dataSource}
                hoverStateEnabled={true}
                selectedRowKeys={valueGrid}
                onSelectionChanged={cambiaValore}
                ref={dropGrid}
                id="id"
                scrolling={{ mode: "infinite" }}
                height="100%"
            >
                {props.columns.map((colonna: any, index: number) => (

                    <Column
                        key={index}
                        sortOrder={colonna.sortOrder ? colonna.sortOrder : null}
                        allowEditing={colonna.editC ? colonna.editC : false}
                        dataField={colonna.dataField}
                        caption={colonna.caption}
                        cellRender={colonna.render ? colonna.render : null}
                        width={colonna.width ? colonna.width : null}
                        format={colonna.format ? colonna.format : null}
                        dataType={colonna.dataType ? colonna.dataType : null}
                        customizeText={colonna.customizeText ? colonna.customizeText : null}
                        type={colonna.type}
                        buttons={colonna.buttons ? colonna.buttons : null}
                        visible={colonna.visible !== undefined ? colonna.visible : true}
                        fixed={colonna.fixed !== false ? colonna.fixed : false}
                    />
                ))}

                <Selection mode={props.selection} />
                <FilterRow visible={true} />
            </DataGrid>
        );
    };

    //const syncDataGridSelection = (e: any) => {
    //    if (!dropGrid) return;
    //    if (!e.value && dropGrid.current) {
    //        dropGrid.current.instance.deselectAll();
    //        impostaValoreFiltro([]);
    //    } else {
    //        if (dropGrid.current)
    //            dropGrid.current.instance.selectItem(e.value);
    //    }
    //    setValueGrid(e.value);
    //};

    const handleValueChanged = (e: any) => {

        const newValue = e.value;

        setValore(newValue);

        if (newValue == null || (Array.isArray(newValue) && newValue.length === 0)) {

            setValueGrid([]);
            if (dropGrid.current) {
                dropGrid.current.instance.deselectAll();
            }
            if (props.impostaFiltri) {
                props.impostaFiltri({ [props.id]: undefined });
            }

        } else {

            if (props.selection === "single") {

                setValueGrid([newValue]);
                if (props.impostaFiltri) {
                    props.impostaFiltri({ [props.id]: newValue });
                }
            } else if (props.selection === "multiple") {

                setValueGrid(newValue);
                if (props.impostaFiltri) {
                    props.impostaFiltri(newValue);
                }
            }

            if (dropGrid.current) {

                if (props.selection === "single") {

                    dropGrid.current.instance.selectRows([newValue], false);
                }
                else {
                    dropGrid.current.instance.selectRows(newValue, false);
                }
            }
        }
    };

    return (
        <>
            <DropDownBox
                ref={dropDown}
                dataSource={props.dataSource}
                value={valore}
                valueExpr={props.valueExpr}
                displayExpr={props.displayExpr}
                showClearButton={true}
                placeholder="Seleziona..."
                contentRender={dataGridRender}
                onValueChanged={handleValueChanged}
                deferRendering={false}
                disabled={props.disabled}
                onOpened={(e: any) => {
                    if (props.onOpen) {
                        props.onOpen(e);
                    }
                }}
            />
        </>
    );
};

