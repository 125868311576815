import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import Ricerca from "../../widget/nuova_ricerca"
import {  Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import { legendByarray } from '../../funzioni/formatoVariabili'



import './style.css';


const columns = [
    { caption: "Farmacia", dataField: "descrix", width:400 },
    { caption: "Abilitato", dataField: "abilitato",width:100 , dataType: "boolean" },
    { caption: "Operatore", dataField: "operatore", sortOrder:"asc" },
    { caption: "Nome", dataField: "nome" },  
    { caption: "Codice Fiscale", dataField: "codiceFiscale" }
];


const ConsultazioneOperatori = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idFarmacia,setIdFarmacia] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth);
    const [newFarmaList,setNewFarmaList] = useState([])
   



    const itemsFields =[
        {type:'select' , idValue:'idFar' , label:'Farmacie ' , displayExpr:'descrizione', dataSource : newFarmaList ,width:350,require:false  },
    ]


    

    const caricamentiNuovaRicerca = async(objFiltro:Filtri) =>{
        setLoading(true)
        let farmacieLista = await getListFarm()
        if (farmacieLista?.esito) { 
             if(farmacieLista){
                let params = {...objFiltro}  
                setIdFarmacia(null)
                if(params.idFar){
                    params.idFarmacia = Number(params.idFar.idFar)
                    delete params.idFar
                    let listaTabella = await RestServices().rest('POST', params , urlConsultazione.getListOperatore)
                    if (listaTabella?.esito) {
                        let valoreFormatt = legendByarray(
                            listaTabella.response,
                            farmacieLista.response,
                            'idFar',
                            'idFarmacia',
                            'descrix',
                            ['descrizione'])
                       setTabellaPrincipale(valoreFormatt)
                       setLoading(false)
                    }else{
                        notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)

                    }
                }else{
                    let listaTabella = await RestServices().rest('POST', {} , urlConsultazione.getListOperatore)
                    if (listaTabella?.esito) {
                        let valoreFormatt = legendByarray(
                            listaTabella.response,
                            farmacieLista.response,
                            'idFar',
                            'idFarmacia',
                            'descrix',
                            ['descrizione'])
                       setTabellaPrincipale(valoreFormatt)
                       setLoading(false)
                    }else{
                        notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)

                    }
                }
        }
        }else{
            if(farmacieLista?.message){
                notify({ position: "center", width: "auto", message: farmacieLista.message}, "error", 4000)
                
            }            
        }

    }

    
    const getListFarm = () =>{
        if (auth) {
           return RestServices().rest('POST', {} , urlConsultazione.getListFar)
        }
   }
   
    
    const getListFarmOk = () =>{
         if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListFar)
                .then(result => {                 
                    if (result?.esito) {                     
                        setNewFarmaList(result.response)                  
                    }
                })
    }
    }
    
   
    const getOperatoriAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {idFarmacia  : idFarmacia} , urlConsultazione.getListOperatore)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }
    }





    useEffect(()=>{
        getListFarmOk()
        caricamentiNuovaRicerca({})
    },[auth])
    
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (idFarmacia) {
            getOperatoriAction()
        }
    }, [idFarmacia]);


   
    return (
        <>
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> CONSULTAZIONE OPERATORI </h3>
             </div>
             
            <Ricerca ricerca={(filtro)=>caricamentiNuovaRicerca(filtro)}  items={itemsFields}  />
            
            <NewTabella 
                idTabella='consultazione_operatori'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                onclickRow={(datiRow:any)=>null}
                exportT={{
                    enabled: true,
                    fileName: "consultazione_operatori"
                }}
                activeSaver={true}
                columnC={true}
            />
           
        </>
    )
}

export default ConsultazioneOperatori