import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { urlCommon } from "../costanti";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReceipt, faBookmark } from "@fortawesome/free-solid-svg-icons";
import './style.css';

const Documentazione = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [isVisible, setIsVisible] = useState(false);
    const [documentazione, setDocumentazione] = useState<any[]>([]);
    const auth = useAuth(urlCommon.auth);
    const history = useHistory();

    const showReleaseNote = () => {
        window.location.href = '/app/DocumentazioniRevisioni/ReleaseNote';
    };

    const documentazioneList = () => {
        setIsVisible(true);
        if (auth) {
            RestServices()
                .rest("POST", {}, urlCommon.getElencoRevisioni)
                .then((result) => {
                    if (result?.esito) {
                        setDocumentazione(result.response);
                    } else {
                        setIsVisible(false);
                    }
                });
        }
    };

    useEffect(() => {
        documentazioneList();
    }, [auth]);

    const showRevisione = (revisioneRilascio: any) => {
        history.push(`/DocumentazioniRevisioni/ElencoArticoli/${revisioneRilascio}`);
    };

    return (
        <>
            <div className="page-header">
                <h2><b>Documentazione Revisioni SistemaF Platinum</b></h2>
            </div>

            <div className="document-container">
                {/*<div className="release-note-section">*/}
                {/*    <p className="release-note-title">Vai alla Pagina Di Release Note</p>*/}
                {/*    <Button*/}
                {/*        variant="success"*/}
                {/*        onClick={showReleaseNote}*/}
                {/*    >*/}
                {/*        <FontAwesomeIcon icon={faBookmark} className="icon-spacing" />*/}
                {/*        <b>- Pagina Di Release Note</b>*/}
                {/*    </Button>*/}
                {/*</div>*/}

                {documentazione.map((revisione, index) => (
                    <div key={index} className="revision-section">
                        <p className="revision-title">
                            {`Guarda Revisione ${revisione.revisioneRilascio}`}
                        </p>
                        <Button
                            variant="primary"
                            onClick={() => showRevisione(revisione.revisioneRilascio)}
                        >
                            <FontAwesomeIcon icon={faReceipt} className="icon-spacing" />
                            {`- Revisione ${revisione.revisioneRilascio}`}
                        </Button>
                    </div>
                ))}
            </div>
        </>
    );
};

export default Documentazione;