import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione/interface'
import Button from 'react-bootstrap/Button'
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocaleNewF } from '../../funzioni/formatoVariabili';
import { AplicaValoreTO, actionTables } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'
import { DialogConferma, DialogInfoOk } from '../../widget/Notifications'
import { DialogNoteAttive } from "./dialog/Pop_note_attive"
// import { DialogNotePass } from "./dialog/pop_note_pass"
// import { DiaPagamPass } from "./dialog/pop_pag_pass"
// import { DialogDataSaldo } from "./dialog/pop_dele_dat_saldo"

import './style.css';
import { DialogCaricaXml } from './dialog/Pop_carica_fatt'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'



const FattElettronicaAttive = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   
    
    const [popDel,setPopDel] = useState<boolean>(false)
    const [messDel,setMessDel] = useState<string>('')
    const [messError,setMessError] = useState<any>(null)
    const [idFattura,setIddel] = useState<number>(0)
    const [popInvia,setPopInvia] = useState<boolean>(false)
    const [messInvia,setMessInvia] = useState<string>('')
    const [popErrore,setPopErrore] = useState<boolean>(false)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [newFarmaList,setNewFarmaList] = useState([])
    const [fattTipiList,setFattTipiList] = useState([])
    const [propTab,setPropTab] = useState<number>(0)
    const [openPop2 , setOpenPop2 ] = useState<boolean>(false)
    const [edit,setImmediate] = useState<boolean>(false)
    const [objtipo,setObjtipo] = useState<any>(null)
    const [paramsRicerca,setParamsRicerca] = useState<any>({})
    const [openPop3, setOpenPop3] = useState<boolean>(false)

    const auth = useAuth(urlConsultazione.auth)


    const columns = [

        {
            caption: "Errore", dataField: "erroreF", render: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => {
                    //console.log('DATA');
                    // console.log(e.row.data);
                    setPopErrore(true);
                    setMessError(<p style={{ textAlign: 'center' }}> <h3 style={{ color: 'red' }}>ERRORE</h3> <span >{e.row.data.errore}</span></p>)
                }}>
                {e.value} </a>)
        }, 
        { caption: "Num. Doc.", dataField: "numeroDoc", width: 160 },
        { caption: "Data Doc.", dataField: "dataDoc", format: "dd/MM/yyyy", dataType: "date", width: 90, sortOrder: "desc" },
        { caption: "Tipo Doc.", dataField: "descrizioneBreve", width: 200 },
        { caption: "Data invio", dataField: "dataInsert", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Progr. invio", dataField: "progInvio" }, 
        { caption: "Stato ", dataField: "descrizioneStato", width: 280 },
        { caption: "Importo", dataType: "number", dataField: "importo",format: { type: "currency", precision: 2, currency: "EUR" }, width: 90},
        { caption: "Imponibile", dataType:"number", dataField: "imponibile" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Imposta", dataType: "number", dataField: "imposta" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Cessionario/Committente (Cliente)", dataField: "ccDenominazione", width: 200 },
        { caption: "P.Iva Cliente", dataField: "codFiscale" }, 
        { caption: "Ricevuta SDI", dataField: "ricevuta" }, 
        { caption: "Id Doc.", dataField: "idEsterno" },  
        { caption: "Farmacia", dataField: "denominazione", width: 230 },   
         
        
    ];

    
    

    const itemsFields = [
        {type:'select' , idValue:'idFar' , label:'Farmacie ' ,displayExpr:'descrizione', dataSource : newFarmaList  ,width:350 },
        {type:'string' , idValue:'numeroDoc' , label:'Numero Documento' , dataSource : null, width:200  },
        { type: 'date', idValue: 'dataInizioDoc', label: 'Data documento iniziale', dataSource: null, require: true, defValue: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'), width: 250, require11: true },
        { type: 'date', idValue: 'dataFineDoc', label: 'Data documento finale', dataSource: null, require: true, defValue: new Date(), width: 250, require12: true },
        {type:'select' , idValue:'id_TIF' , label:'Tipo documento' ,displayExpr:'descrizione_BREVE', dataSource : fattTipiList , width:250  },
        { type: 'string', idValue: 'cessionario', label:'Cessionario/Committente' , dataSource : null, width:200  },
        { type: 'date', idValue: 'dataInizioInvio', label: 'Data invio iniziale', dataSource: null, width: 200, require21: true },
        { type: 'date', idValue: 'dataFineInvio', label: 'Data invio finale', dataSource: null, width: 200, require22: true },
        
    ]

   

    const onRowPrepared = (e: any) => {
        if (e.values) {
            if ( e.data.descrizioneStato   == "Da inviare" ) {
                e.rowElement.style.backgroundColor = 'rgb(176, 249, 255)';
            }
            else if (e.data.erroreF == "*" ) {
                e.rowElement.style.backgroundColor = 'rgb(252, 230, 232)';
            }
            else if (e.data.scartata == "*" ) {
                e.rowElement.style.backgroundColor = 'rgb(255, 204, 102)';
            }
            
            
        }
    }

    

    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {
                       //console.log(result.response);
                       setNewFarmaList(result.response)
                                         
                   }else{
                      
                   }
               })     
       }
   }


   const fattureList = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getTipiFatture)
           .then(result => {
               if (result?.esito) {
                //    console.log('dati');
                //    console.log(result.response);
                   setFattTipiList(result.response)
                                     
               }else{
                  
               }
           })     
   }
}

   

   const filterAction = (objFiltro:Filtri) =>{
    setLoading(true)
    let params = {...objFiltro}
    setParamsRicerca(params)

    //console.log(params);

    if(params.idFar){
        params.idFar = Number(params.idFar?.idFar)
    }
    if(objFiltro?.numeroDoc){
        params.numeroDoc = objFiltro?.numeroDoc
    }

    if(params.id_TIF){
        params.tipoDoc = params.id_TIF.codice
        delete params.id_TIF
    }else{
        delete params.id_TIF
    }
    if(objFiltro?.cedente){
        params.cedente = objFiltro?.cedente
    }

    params.dataInizioInvio = trasformaDateTimeInLocaleNewF(params.dataInizioInvio , true);
    params.dataFineInvio = trasformaDateTimeInLocaleNewF(params.dataFineInvio , true);

    params.dataInizioDoc = trasformaDateTimeInLocaleNewF(params.dataInizioDoc , true);
    params.dataFineDoc = trasformaDateTimeInLocaleNewF(params.dataFineDoc , true);
    params.tipoFattura = "attive"
    
    setParamsRicerca(params)
                
        //console.log(params);  
        setLoading(true)
        if (auth) {
                RestServices().rest('POST' , params , urlConsultazione.findFatture)
                    .then(result => {
                        if (result?.esito) {
                            setLoading(false)   
                            setTabellaPrincipale(result.response)
                            // console.log('pass 1');
                            // console.log(result.response);
                            
                        }else{
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                            setLoading(false)
                        }
                    })
            
        }
    
}

const ricaricaAfter = () =>{
    if (auth) {
        RestServices().rest('POST' , paramsRicerca , urlConsultazione.findFatture)
            .then(result => {
                if (result?.esito) {
                  
                    setLoading(false)   
                    setTabellaPrincipale(result.response)
                                    
                }else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    setLoading(false)
                }
            })
    
}
}

const removeTipo = () =>{
    if (auth) {
        RestServices().rest('POST', { idFattura  } , urlConsultazione.deleteFatture)
            .then(result => {
               if(result.esito){
                   setPopDel(false)
                   ricarica()          
               }
               else{
                //console.log(result);
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }    
            })   
     } 
}

const inviaFattura = () =>{
    if (auth) {
        RestServices().rest('POST', { idFattura  } , urlConsultazione.addFatture)
            .then(result => {
               if(result.esito){
                   setPopInvia(false)
                   ricarica()          
               }
               else{
                //console.log(result);
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }    
            })   
     } 
}
    

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {     
        
        farmaList()
        fattureList()
       
    }, [auth]);
    
   
    

    const caricaTabella = (value:number) =>{
        setPropTab(value)
        if(paramsRicerca.dal){
            //filterAction(paramsRicerca,value)
        }
        
       // caricaDati(value == 0 ? true : false)
      }

      const ricarica = () =>{
        setPopDel(false)    
        setOpenPop2(false)
        ricaricaAfter()
        setOpenPop3(false)
                 
    }
    
    const getTotalItems = (options: any) => {
        switch (options.summaryProcess) {
            case "start":
                options.totalValue = 0;
                break;
            case "calculate":
                options.totalValue = tabellaPrincipale.length
                break;
            case "finalize":
                break;
        }
    }

    return (
        <>

              <DialogInfoOk
                    dialogIsOpen={popErrore}
                    message={messError}
                    functionNext={()=>('')}
                    handleCloseConferma={()=>{setPopErrore(false)}}
                />

                 <DialogConferma
                    dialogIsOpen={popDel}
                    message={messDel}
                    functionNext={()=>removeTipo()}
                    handleCloseConferma={()=>{setPopDel(false);setIddel(0)}}
                />

             <DialogConferma
                    dialogIsOpen={popInvia}
                    message={messInvia}
                    functionNext={()=>inviaFattura()}
                    handleCloseConferma={()=>{setPopInvia(false);setIddel(0)}}
                />

                


        <DialogNoteAttive
             typeEdit={edit} 
             edit={objtipo} 
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPop2(false);setObjtipo({});setImmediate(false)}}
             open={openPop2} /> 


            {openPop3 ? (
                <DialogCaricaXml
                    typeEdit={edit}
                    edit={objtipo}
                    ricarica={() => ricarica()}
                    close={() => { setOpenPop3(false); setObjtipo({}); setImmediate(false) }}
                    open={openPop3}
                    flagCicloAttivo={true }                />
            ) : null}

        <Loading isVisible={loading} />
            <div className='title'>
                {/*<h3> FATTURE ATTIVE  <span className='subt' > Registro fatturazione elettronica</span> </h3>*/}
                <h3> ELENCO DOCUMENTI EMESSI </h3>
             </div>
             <div style={{width:'250'}}>
            <Ricerca ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  />
             </div>
           
              
             <div className='button_fatt_pass' > 
             <Button  disabled={objtipo && objtipo.fileToken? false : true} 
               onClick={()=>{  window.open('https://www.cloud-farma.it/cfportal/view/'+objtipo.fileToken+'_htm', "_blank") }}
                variant="primary" >
                 VISUALIZZA
              </Button>
              <Button style={{marginLeft:5}} disabled={objtipo && objtipo.fileToken ? false : true} 
               onClick={()=>{  window.open('https://www.cloud-farma.it/cfportal/view/'+objtipo.fileToken, "_blank") }}
                variant="primary" >
                 SORGENTE 
              </Button>
              <Button style={{marginLeft:5}} disabled={objtipo && objtipo.fileToken ? false : true} 
                onClick={()=>{  window.open('https://www.cloud-farma.it/cfportal/view/'+objtipo.fileToken+'_pdf', "_blank") }}
                variant="primary" >
                 ALLEGATO PDF
              </Button>          
              <Button style={{marginLeft:5}} disabled={objtipo ? false : true} 
              variant="danger"
              onClick={()=>
                {
              setMessDel("La fattura sta per essere eliminata. Confermare?")
              setPopDel(true)
            //   console.log('pass 2');
            //   console.log('qui prendo id da objtipo.id')
            //   console.log(objtipo.id);
            //   console.log('lo salvo in setIddel')
              
              setIddel(objtipo.id)
              
              }} 
         >
                 ELIMINA
              </Button> 
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.flagHaProvider ? false : true}  variant="primary" 
               onClick={()=>
                {
              setMessInvia("La fattura sta per essere spedita. Confermare?")
              setPopInvia(true)

              //console.log(objtipo.id);
              setIddel(objtipo.id);
              
              

              }} >
                 INVIA
              </Button>  
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.flagHaProvider && objtipo.ricevuta ? false : true} 
               onClick={()=>{  window.open('https://www.cloud-farma.it/cfportal/view/'+objtipo.ricevuta+'_htm', "_blank") }}
                variant="primary" >
                 RICEVUTA SDI
                </Button>

                <Button style={{ marginLeft: 5 }} onClick={() => { setOpenPop3(true); setImmediate(true) }} variant="primary" >
                    CARICA FILE "XML"
                </Button>

            </div> 

            
            
         

            <NewTabella 
                idTabella='elettr_fatt_attive'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                allowColumnReordering={true}
                filterRow
                headerFilter
                toolbar={true}
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "elettr_fatt_attive"
                }}
                onRowPrepared={onRowPrepared}  
                activeSaver
                columnC
                onclickRow={(e)=>{      
                    // console.log('pass 1');    
                    setObjtipo(e.data) 
                  
            } }
                summary={
                    <Summary calculateCustomSummary={getTotalItems}>
                        <TotalItem
                            name="rowsSummary"
                            displayFormat="Elementi totali: {0}"
                            summaryType="custom"
                            showInColumn="numeroDoc" />
                    </Summary>
                }
                
                              
            />
            
             <div style={{gap:10,display:'flex',background: 'white',padding:10,borderRadius: 10,marginTop:10}} className="form-group">   
            <div className='title2'>
                <h6> Legenda Colori: </h6>
             </div>  
             <div className='leg2'>
             <h6> Documento scartato </h6> 
           </div>   
           <div className='leg4'>
           <h6> Documento con errori </h6> 
           </div>
           <div className='leg3'>
           <h6> Documento da inviare </h6> 
           </div>
           
           
          
        </div>
          
           
        </>
    )
}

export default FattElettronicaAttive

