import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { SelectBox } from 'devextreme-react/select-box'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import Button from 'devextreme-react/button'
import Select from 'react-select'
import { useHistory } from 'react-router-dom';

import {
    Validator,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    EmailRule
} from 'devextreme-react/validator';
import { NewTabella } from '../../../widget/nuova_tabella/newVersion'


interface DialogProps {
    oggettoUsername: any,
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
}

interface Profilo {
    id: string
    nomeProfilo: string
}

export const DialogNewUtente: React.FC<DialogProps> = ({ oggettoUsername, open, close, ricarica, edit, typeEdit }) => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [username, setUsername] = useState<any>()
    const [openD, setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [profili, setProfili] = useState<Profilo[]>([])
    const [farma, setFarma] = useState<any[]>([])

    const history = useHistory();

    useEffect(() => {
        if (edit) {
            getFarmaSelect()

        } else {
            setObjctTOsave({})
        }

    }, [edit])

    useEffect(() => {
        if (auth && open) {
            farmaList()
            getProfili()
        }

    }, [auth, open])


    const farmaList = () => {

        RestServices().rest('POST', {}, urlConsultazione.getListFar)
            .then(result => {
                if (result?.esito) {
                    //console.log(result.response);
                    setFarma(result.response)

                } else {
                    //console.log(result);

                }
            })
    }

    const getProfili = () => {
        RestServices().rest('POST', {}, urlConsultazione.getProfiliUtenti)
            .then(result => {
                if (result.esito) {
                    setProfili(result.response)

                }
                else {
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                }

            })
    }


    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
            //console.log(element);
            newArr.push({ value: element.idFar, label: element.descrizione })

        });
        return newArr
    }

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value

        if (tipo == 'elencoFarmacie')
            objTo['listFarmacie'] = value
        //console.log('objTo');
        //console.log(objTo);

        setObjctTOsave(objTo)
        //console.log(objTo);   
        if (objTo.abi?.length > 3 && objTo.cab?.length > 3 && objTo.ragioneSociale?.length > 4 && objTo.filiale?.length > 3) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }
    }

    const sortedFarma = () => {
        const selectedRowKeys = objctTOsave?.listFarmacie || [];

        return farma.sort((x, y) => {
            const isSelectedX = selectedRowKeys.includes(x.idFar);
            const isSelectedY = selectedRowKeys.includes(y.idFar);

            if (isSelectedX && !isSelectedY) return -1;
            if (!isSelectedX && isSelectedY) return 1;
            return 0;
        });
    };

    const getFarmaSelect = async () => {
        let objTo = { ...edit }
        const farmacie = await RestServices().rest('POST', {}, urlConsultazione.getListFar)


        let oblValue: any[] = []
        edit?.listFarmacie?.forEach((id: number) => {
            // console.log(id);

            farmacie?.response?.forEach((element: any) => {
                // console.log(element);

                if (element.idFar === id) {
                    oblValue.push({ label: element.codice, value: element.idFar })
                }
            });
        });
        objTo.elencoFarmacie = oblValue


        setObjctTOsave(objTo)


    }



    const CreateUpdateUtente = (edit?: boolean) => {
        //console.log(objctTOsave);
        //alert(JSON.stringify(objctTOsave))
        objctTOsave.nome = objctTOsave.nome.toUpperCase()
        objctTOsave.cognome = objctTOsave.cognome.toUpperCase()

        if (typeEdit) {
            let objToUseEdit: any = {
                "idUtente": objctTOsave.idUtente,
                "username": objctTOsave.username,
                "cognome": objctTOsave.cognome,
                "nome": objctTOsave.nome,
                "telefono": objctTOsave.telefono,
                "email": objctTOsave.email,
                "profilo": objctTOsave.profilo,
                "elencoFarmacie": objctTOsave.elencoFarmacie
            }


            if (auth) {
                RestServices().rest('POST', objToUseEdit, urlConsultazione.updateUtente)
                    .then(result => {
                        if (result?.esito) {
                            ricarica()

                        } else {
                            setLoading(false)
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                    })
            }

        } else {


            let objToUse: any = {
                //"id": objctTOsave.id,
                //"username":'pppp',
                "cognome": objctTOsave.cognome,
                "nome": objctTOsave.nome,
                "telefono": objctTOsave.telefono,
                "email": objctTOsave.email,
                "profilo": objctTOsave.profilo,
                "elencoFarmacie": objctTOsave.elencoFarmacie
            }

            if (auth) {
                RestServices().rest('POST', objToUse, urlConsultazione.addUtente)
                    .then(result => {
                        if (result?.esito) {
                            ricarica()

                        } else {
                            setLoading(false)
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                    })
            }



        }


    }


    useEffect(() => {
        setOpenD(open)
    }, [auth])


    useEffect(() => {
        setOpenD(open)
    }, [open])

    const columns = [
        { caption: "Id", dataField: "idFar", visible: false },
        { caption: "Codice", dataField: "codice" },
        { caption: "Farmacia", dataField: "ragioneSociale" },

    ];

    const goToAutenticazione2FA = () => {
        history.push('/consultazione/Autenticazione2FA', { oggettoUsername });
    };

    return (
        <>
            <Popup
                visible={openD}
                showCloseButton={false}
                width={'60%'}
                height={'60%'}
                showTitle={true}
                title="Gestione Utente"
            >
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>

                    {/*<Button style={{ position: 'fixed', bottom: 22, right: '80%', left: '5%', zIndex: 2, width: 100 }}*/}
                    {/*    id="button"*/}
                    {/*    text="Autenticazione a Due Fattori"*/}
                    {/*    type="danger"*/}
                    {/*    width={225}*/}
                    {/*    useSubmitBehavior={true}*/}
                    {/*    onClick={() => { goToAutenticazione2FA() }}*/}
                    {/*/>*/}

                    <Button style={{ position: 'fixed', bottom: 22, right: 130, zIndex: 2, width: 100 }}
                        id="button"
                        text={typeEdit ? "Modifica" : "SALVA"}
                        type="success"
                        width={100}
                        useSubmitBehavior={true}
                        onClick={() => { CreateUpdateUtente(false) }}

                    />


                </div>



                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'CHIUDI',
                        onClick: () => { close() },
                    }}
                />



                <ScrollView width='100%' height='100%'>

                    <form action="your-action" onSubmit={(e) => {
                        e.preventDefault();
                        CreateUpdateUtente(edit)
                    }}>

                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> COGNOME </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.cognome} onValueChanged={(v) => cambiaValore('cognome', v.value)} placeholder="Campo obbligatorio" >
                                    <Validator>
                                        <RequiredRule message="Nome obbligatorio" />
                                        {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                                        <StringLengthRule message="Il codice deve essere massimo di 10 caratteri" max={20} />
                                    </Validator>
                                </TextBox>
                            </div>
                            <div className="form-group col-md-6">
                                <label> NOME </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.nome} onValueChanged={(v) => cambiaValore('nome', v.value)} placeholder="Campo obbligatorio"  >
                                    <Validator>
                                        <RequiredRule message="Cognome obbligatorio" />
                                        {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                                        <StringLengthRule message="Il Cognome deve essere massimo di 20 caratteri" max={20} />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> TELEFONO </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.telefono} onValueChanged={(v) => cambiaValore('telefono', v.value)} placeholder="...." />
                            </div>

                            <div className="form-group col-md-6" >
                                <label> EMAIL </label>
                                <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.email} onValueChanged={(v) => cambiaValore('email', v.value)} placeholder="Campo obbligatorio" >
                                    <Validator>
                                        <RequiredRule message="Email Obbligatoria" />
                                        <EmailRule message="Deve essere un formato email" />
                                    </Validator>
                                </TextBox>
                            </div>
                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-6" >
                                <label> PROFILO </label>
                                <SelectBox dataSource={profili} showDataBeforeSearch={true} valueExpr="id" displayExpr="nomeProfilo" valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.profilo} onValueChanged={(e: any) => cambiaValore('profilo', e.value)} isValid={!!objctTOsave?.profilo}  // Check if value is present
                                    validationMessageMode="auto" />
                            </div>
                            {typeEdit ? (
                                <div className="form-group col-md-6">
                                    <label> USERNAME </label>
                                    <TextBox disabled={!typeEdit} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.username} onValueChanged={(v) => cambiaValore('username', v.value)} />
                                </div>
                            ) :
                                (
                                    <div className="form-group col-md-6" >
                                    </div>
                                )
                            }
                        </div>
                        <div className='form-row '>
                            <div className="form-group col-md-12">
                                <label> FARMACIE </label>
                                <NewTabella
                                    idTabella='elencoFarmacie'
                                    height={'40vh'}
                                    sync
                                    multyp
                                    colonne={columns}
                                    keyExpr="idFar"
                                    dataSource={sortedFarma()}
                                    selectedRowKeys={objctTOsave?.listFarmacie?.length > 0 ? objctTOsave.listFarmacie : []}
                                    filterRow
                                    selectedValue={(datiRow: any) => cambiaValore('elencoFarmacie', datiRow.selectedRowKeys)}
                                    exportT={{
                                        enabled: false,
                                        fileName: ""
                                    }}
                                    pageSize={5}
                                    scrolling={{
                                        useNative: false,
                                        showScrollbar: 'onHover'
                                    }}
                                />


                            </div>
                        </div>
                        <div>
                        </div>
                    </form>
                </ScrollView>


            </Popup>
        </>
    )
}






