import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { GetParametersInterface, ParametersReportInterface, ProprietaTabellaReportInterface } from '../../interfaces/report'
import { BoxDataOra, Check } from '../../widget/Editor'
import { SelectFarm, Selezione, TendinaFarm } from '../../widget/Select'
import { RestServices } from '../../services/restServices'
import { basenameHost, urlReport } from '../costanti'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import { OggettoInterface } from '../../interfaces/generiche'
import { trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import notify from 'devextreme/ui/notify';

const DialogParamsReport = (props: {
    showModal: boolean,
    handleClose: () => void,
    setShowModalParam: (show: boolean) => void,
    parametri: GetParametersInterface,
    codiceReport: string,
    setParametri: (parametro: GetParametersInterface) => void,
    dataSourceSelect: OggettoInterface;
    titoloReport: string,
    setReport: (report: any) => void,
    setIsVisible: (isVisible: boolean) => void,
    setFiltriUsati: (filtriUsati: OggettoInterface) => void,
    setProprietaTabReport: (proprietaTab: ProprietaTabellaReportInterface) => void
}) => {
    const [statoModal, setStatoModal] = useState<number>(1);

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }
    const chiudiModal = () => {
        props.handleClose();
        setFiltri({});
    }
    const [backBtn, setBackBtn] = useState("Annulla");
    const [nextBtn, setNextBtn] = useState("Avanti");

    useEffect(() => {
        if (props.parametri.params.length == 0)
            getReport();
    }, [])

    const back = () => {
        if (statoModal === 2) {
            setBackBtn("Annulla");
            setNextBtn("Avanti");
            setStatoModal(1);
        } else if (statoModal === 1) {
            setBackBtn("Annulla");
            chiudiModal();
        } else {
            setStatoModal(statoModal - 1);
            setNextBtn("Avanti");
        }
    }
    const next = () => {
        const cod = props.parametri.params[statoModal - 1].codice;
        if (filtri[cod]) {
            if (statoModal === props.parametri.params.length - 1) {
                setNextBtn("Esegui");
                setBackBtn("Indietro");
                setStatoModal(statoModal + 1);
            } else if (statoModal >= props.parametri.params.length) {
                getReport();
            } else {
                setStatoModal(statoModal + 1);
                setBackBtn("Indietro");
            }
        } else {
            notify({ position: "center", width: "auto", message: "Inserire il parametro richiesto" }, "warning", 4000);
        }

    }
    const getReport = () => {
        props.setIsVisible(true);
        if (props.parametri.endPoint) {
            const usaEndPointReport = props.parametri.endPoint.substring(1); //la costante basenameHost contiene già / alla fine
            let parametri: OggettoInterface = {
                codReport: props.codiceReport
            };
            for (const property in filtri) {
                if (filtri[property] instanceof Date) {
                    const dataOra = trasformaDateTimeInLocale(filtri[property]);
                    parametri[property] = dataOra;
                } else {
                    parametri[property] = filtri[property];
                }
            }
            RestServices().rest("POST", parametri, urlReport.getTabella)
                .then(result => {
                    if (result) {
                        props.setProprietaTabReport(result);
                        RestServices().rest("POST", parametri, basenameHost + usaEndPointReport)
                            .then(result => {
                                if (result) {
                                    props.setIsVisible(false);
                                    props.setShowModalParam(false);
                                    props.setFiltriUsati(parametri);
                                    setFiltri({});
                                    setStatoModal(1);
                                    props.setReport(result);
                                } else if (result && result.message && result.severity) {
                                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                                }
                            })
                    }
                })
        }
    }

    return (
        <>
            <Modal
                show={props.showModal}
                onHide={props.handleClose}
                dialogClassName="dialogParamsReport"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloParamsReport">
                        {
                            props.parametri.params.length > 0
                            && 
                            <h3>{props.titoloReport}</h3>
                        }
                        {
                            props.parametri.params.length == 0
                            &&
                            <h3>Elaborazione in corso...</h3>
                        }
                        {props.parametri.params.length > 0  &&
                            <h6>Inserire i parametri necessari per completare la richiesta - {statoModal} di {props.parametri.params.length}</h6>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalBody statoModal={statoModal} parametro={props.parametri.params[statoModal - 1]} filtri={filtri} impostaFiltri={impostaFiltri} dataSourceSelect={props.dataSourceSelect} />
                </Modal.Body>
                <Modal.Footer>
                    {props.parametri.params.length > 0 &&
                        <Button id="btnIndietroVersion" variant="danger" onClick={back} >
                            {backBtn}
                        </Button>
                    }
                    {props.parametri.params.length > 0 &&
                        <Button id="btnAvantiVersion" variant="success" onClick={next}>
                            {nextBtn}
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DialogParamsReport

const ModalBody = (props: {
    parametro: ParametersReportInterface,
    statoModal: number,
    impostaFiltri: (newFiltro: object) => void,
    filtri: OggettoInterface,
    dataSourceSelect: OggettoInterface
}) => {
    let elemento: JSX.Element = <></>;
    if (props.parametro != undefined) {
        //console.log(props.parametro);
        //console.log(props.parametro.codice);
        //console.log("log di props.dataSourceSelect:");
        //console.log(props.dataSourceSelect);
        //console.log("log di props.dataSourceSelect[props.parametro.codice]:");
        //console.log(props.dataSourceSelect[props.parametro.codice]);
        //console.log(props.dataSourceSelect.idCont);

        //console.log(props.parametro.tipo);

        //Object.keys(props.dataSourceSelect).map((d, key) => {
        //    //console.log(key)
        //    //console.log(d)
        //    console.log("ciao")
        //})

        //var keys = Object.keys(props.dataSourceSelect);
        //console.log(keys);

        switch (props.parametro.tipo) {
            
            case "CHECK":
                if (props.filtri && props.filtri[props.parametro.codice]) {
                    elemento = <Check id={props.parametro.codice} impostaFiltri={props.impostaFiltri} text={props.parametro.nome} checkValue={props.filtri[props.parametro.codice]} />
                    break;
                } else {
                    elemento = <Check id={props.parametro.codice} impostaFiltri={props.impostaFiltri} text={props.parametro.nome} checkValue={false} />
                    break;
                }
            case 'SELECT_FAR':
                if (props.parametro.lsParam) {
                    //console.log(props)
                    if (props.filtri[props.parametro.codice]) {
                        elemento = <TendinaFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} impostaFiltri={props.impostaFiltri} id={props.parametro.codice} valueGrid={props.filtri[props.parametro.codice]} />
                        break;
                    } else {
                        elemento = <TendinaFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} impostaFiltri={props.impostaFiltri} id={props.parametro.codice} />
                        break;
                    }
                } else {
                    if (props.filtri[props.parametro.codice]) {
                        elemento = <SelectFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} id={props.parametro.codice} impostaFiltri={props.impostaFiltri} value={props.filtri[props.parametro.codice]} displayExpr={true} />
                        break;
                    } else {
                        elemento = <SelectFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} id={props.parametro.codice} impostaFiltri={props.impostaFiltri} displayExpr={true} />
                        break;
                    }
                }
            case 'SELECT_RSA':
                if (props.parametro.lsParam) {
                    if (props.filtri[props.parametro.codice]) {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="descrizione" valueExpr="idRsa" searchEnabled={true} />
                        //elemento = <TendinaFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} impostaFiltri={props.impostaFiltri} id={props.parametro.codice} valueGrid={props.filtri[props.parametro.codice]} />
                        break;
                    } else {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="descrizione" valueExpr="idRsa" searchEnabled={true} />
                        //elemento = <TendinaFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} impostaFiltri={props.impostaFiltri} id={props.parametro.codice} />
                        break;
                    }
                } else {

                    if (props.filtri[props.parametro.codice]) {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="descrizione" valueExpr="idRsa" searchEnabled={true}  />
                        //elemento = <SelectFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} id={props.parametro.codice} impostaFiltri={props.impostaFiltri} value={props.filtri[props.parametro.codice]} displayExpr={true} />
                        break;
                    } else {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="descrizione" valueExpr="idRsa" searchEnabled={true} />
                        //elemento = <SelectFarm datiFarmacie={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} id={props.parametro.codice} impostaFiltri={props.impostaFiltri} displayExpr={true} />
                        break;
                    }
                }
            case 'SELECT':
                if (props.parametro.lsParam) {
                    //console.log(props)
                    if (props.filtri[props.parametro.codice]) {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="DESCRIZIONE" valueExpr="ID" searchEnabled={true} />
                        break;
                    } else {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="DESCRIZIONE" valueExpr="ID" searchEnabled={true} />
                        break;
                    }
                } else {

                    if (props.filtri[props.parametro.codice]) {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="DESCRIZIONE" valueExpr="ID" searchEnabled={true} />
                        break;
                    } else {
                        elemento = <Selezione id={props.parametro.codice} impostaFiltri={props.impostaFiltri} dataSource={(props.dataSourceSelect) && props.dataSourceSelect[props.parametro.codice]} displayExpr="DESCRIZIONE" valueExpr="ID" searchEnabled={true} />
                        break;
                    }
                }
            case 'DATE':
                if (props.filtri[props.parametro.codice]) {
                    elemento = <BoxDataOra typeDate="date" impostaFiltri={props.impostaFiltri} id={props.parametro.codice} value={props.filtri[props.parametro.codice]} />
                    break;
                } else {
                    elemento = <BoxDataOra typeDate="date" impostaFiltri={props.impostaFiltri} id={props.parametro.codice} />
                    break;
                }
            case 'DATETIME':
                if (props.filtri[props.parametro.codice]) {
                    elemento = <BoxDataOra typeDate="datetime" impostaFiltri={props.impostaFiltri} id={props.parametro.codice} value={props.filtri[props.parametro.codice]} />
                    break;
                } else {
                    elemento = <BoxDataOra typeDate="datetime" impostaFiltri={props.impostaFiltri} id={props.parametro.codice} />
                    break;
                }
            default:
                elemento = <div></div>
                break;
        }

    }
    else
        elemento = <div></div>
    
    return (
        <>
            <Row>
                <Col sm="12">
                    <h6>{props.parametro?.descrizione}</h6>
                </Col>
            </Row>
            <Row>
                <Col sm="12">
                    {elemento}
                </Col>
            </Row>
        </>
    )
}