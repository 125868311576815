
import React, { useEffect } from 'react'



const InfoMonitoraggio = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   

    useEffect(() => { 
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

   
    return (
        <>
    
             <div className='form-row '> 
                <div className="form-group col-md-6">
                 
                <div style={{  marginLeft:10 , marginTop:20, color:'#337ab7'   }}>
                <h3 className='font-size:6vw'>Monitoraggio Sistemi</h3>
                </div>

                <div style={{ padding:10, marginRight:30 , marginTop:20 , borderRadius:10 , backgroundColor:'#fff', color:'#337ab7' }}  >
                  <h5 className='font-size:2vw '>
                  La piattaforma <strong>CloudFarma</strong> consente un monitoraggio dei sistemi di farmacia eseguito in tempo reale.
                     </h5>
                     <h5 className='font-size:2vw '>
                     Essa si avvale del sistema <strong>Sentinel</strong> progettato per una evoluta gestione dei sistemi periferici.
                     </h5>
                     </div> 
                     
                </div>   
             <div className="form-group col-md-6" >
                 <div style={{ marginRight:10 , marginTop:20 , borderRadius:20  }}  >
                 <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_sentinel_arg.png" alt="monitoraggio" />

                     </div>
                     <div style={{ marginRight:10 , marginTop:20, borderRadius:20   }}  >
                     <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_sentinel.png" alt="monitoraggio" />
                 </div>
            </div> 
            </div> 
           
        </>
    )
}

export default InfoMonitoraggio