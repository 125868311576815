import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import { DialogTest } from './Dialog';

const Test = (props: {

}) => {

    const [isDialogFiltraProdottiOpen, setIsDialogFiltraProdottiOpen] = useState<boolean>(false);
    const handleOpenDialogFiltraProdotti = () => setIsDialogFiltraProdottiOpen(true);

    const openDialogFitroProdotti = () => {
        handleOpenDialogFiltraProdotti();
    }
    const handleCloseDialogFiltraProdotti = () => {
        setIsDialogFiltraProdottiOpen(false);
    }

    return (
        <>
            <Button id="btnIncludiProdotti" variant="success" onClick={openDialogFitroProdotti} disabled={false} >
                Filtra prodotti
            </Button>

             <DialogTest
                handleCloseDialogFiltraProdotti={handleCloseDialogFiltraProdotti}
                isDialogFiltraProdottiOpen={isDialogFiltraProdottiOpen}
            />
        </>
    )
}

export default Test