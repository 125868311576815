import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { AnagraficaFarmaciaInterface, ContrattiFarmaciaInterface, ServiziFarmaciaInterface } from '../../interfaces/myCsf';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { OggettoInterface } from '../../interfaces/generiche';
import { Loading } from '../../widget/Notifications';
import { SelectFarm } from '../../widget/Select';
import { RestServices } from '../../services/restServices';
import ContrattiEServizi from './CotrattiEServizi';
import AnagraficaFarmacia from './AnagraficaFarmacia';
import AnagraficaFarmacia2 from './AnagraficaFarmacia2';
import * as Costanti from '../costanti';

const AnagraficaMyCsf = (props: {
    statoMenu: boolean,
    toggleMenu: (stato: boolean) => void,
    datiFarmacie: ListaFarmacieInterface[],
    contenuto: string,
    url: string
}) => {
    //al caricamento della pagina si fa collassare il menu laterale in modo da avere più spazio a disposizione per la pagina
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, [])
    //crea il titolo della pagina sotto la navbar
    let titolo = TitoloContenuto(props.contenuto);

    //rende visibile/invisibile il pannello di loading
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    //idFarm è lo stato da memorizzare nei filtri mentre farmacia è lo stato proprio del componente tendina farmacia
    //TODO controllare se si può eliminare farmacia in modo da avere solo idFarm o se ci sono problemi di render tra i componenti
    const [idFarm, setIdFarm] = useState<number>();
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        if (newFiltro["idFar"]) {
            let idFar = parseInt(newFiltro["idFar"])
            setIdFarm(idFar)
        }
    }
    //stati diversi a seconda di quale componente (pagina) vuole essere visualizzato
    const [anagraficaFarm, setAnagraficaFarm] = useState<AnagraficaFarmaciaInterface>();
    const [contrattiFarm, setContrattiFarm] = useState<ContrattiFarmaciaInterface[]>([]);
    const [serviziFarm, setServiziFarm] = useState<ServiziFarmaciaInterface[]>([]);
    const [anaConcessionari, setAnaConcessionari] = useState<AnagraficaFarmaciaInterface>();
    const [contrattiConcessionari, setContrattiConcessionari] = useState<ContrattiFarmaciaInterface[]>([]);
    const [serviziConcessionari, setServiziConcessionari] = useState<ServiziFarmaciaInterface[]>([]);
    //chiamate effettuate a seconda del componente da visualizzare
    const chiamate = (farmacia: number) => {
        setIsVisible(true);
        RestServices().rest('POST', farmacia, props.url)
            .then(result => {
                switch (props.contenuto) {
                    case "Anagrafica Farmacia":
                        setAnagraficaFarm(result);
                        break;
                    case "Contratti e Servizi Farmacia":
                        setContrattiFarm(result);
                        RestServices().rest('POST', farmacia, Costanti.urlmyCsf.serviziFarm).then(result => {
                            if (result.message) {
                                notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                            } else {
                                setServiziFarm(result.abilitazioni);
                            }
                        })
                        break;
                    case "Anagrafica Concessionari":
                        setAnaConcessionari(result);
                        break;
                    case "Contratti e Servizi Concessionari":
                        setContrattiConcessionari(result);
                        RestServices().rest('POST', farmacia, Costanti.urlmyCsf.serviziConcess).then(result => setServiziConcessionari(result.abilitazioni));
                        break;
                }
            })
            .then(() => {
                setIsVisible(false)
            });
    }
    //se c'è una farmacia nel gruppo, le chiamate ai servizio partono in automatico
    useEffect(() => {
        if (props.datiFarmacie.length === 1) {
            const farm = props.datiFarmacie[0].ID_FAR;
            chiamate(farm);
        }
    }, [props.datiFarmacie])
    //se ci sono più farmacie, la ricerca parte tramite apposito bottone e si fa un controllo sulla presenza della selezione della farmacia
    const startCerca = () => {
        if (!idFarm) {
            notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, 'error', 4000);
        } else {
            chiamate(idFarm);
        }
    };
    //permette di fare la render del componente voluto a seconda della prop passata
    function SwitchContenuto() {
        switch (props.contenuto) {
            case "Anagrafica Farmacia":
                return <AnagraficaFarmacia2 anagrafica={anagraficaFarm} concessionari={false} idFar={idFarm} />;
            case "Contratti e Servizi Farmacia":
                return <ContrattiEServizi contratti={contrattiFarm} servizi={serviziFarm} concessionari={false} />;
            case "Anagrafica Concessionari":
                return <AnagraficaFarmacia2 anagrafica={anaConcessionari} concessionari={true} idFar={idFarm}/>;
            case "Contratti e Servizi Concessionari":
                return <ContrattiEServizi contratti={contrattiConcessionari} servizi={serviziConcessionari} concessionari={true} />;
        }
    }

    return (
        <>
            {loadPanel}
            {titolo}
            <div className="contenutoPagina">
                <Row>
                    <Col sm='4'>
                        <label >Farmacia *</label>
                        <SelectFarm datiFarmacie={props.datiFarmacie} id={'idFar'} impostaFiltri={impostaFiltri} displayExpr={true} />
                    </Col>
                    {(props.datiFarmacie.length !== 1) &&
                        <Col sm="3">
                            <Button type="button" variant="success" id="btnCerca" onClick={startCerca}>Cerca</Button>
                        </Col>}
                </Row>
                {SwitchContenuto()}
            </div>
        </>
    )
}

export default AnagraficaMyCsf