import React, { useEffect, useState } from 'react'
import { Loading } from '../../widget/Notifications'
import { useAuth } from '../../hooks/useAuth'
import { urlB2B } from '../costanti'
import { Filtri } from '../consultazione/interface'
import notify from 'devextreme/ui/notify'
import { RestServices } from '../../services/restServices'
import { convertForSelectFarmacie } from '../../funzioni/formatoVariabili'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import Ricerca from "../../widget/nuova_ricerca"
import { OnRowDblClickInterface } from '../../interfaces/tabelle'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import DateBox from 'devextreme-react/date-box'
import { SelectBox } from 'devextreme-react/select-box'
import Select from 'react-select'

const ElencoOrdiniB2B = (props: {
    setIdOrdineDettaglio: (idOrdine: number) => void,
    approva: (idOrdine: number, idStato: number) => void,
    reload: boolean,
    setFiltriElenco: (filtri: any) => void,
    filtriElenco: any,
    farmaList: any[],
    farmaListSelect: any[],
    listStato: any[],
    auth: boolean | undefined,
    getList: boolean | undefined
}) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [paramsRicerca, setParamsRicerca] = useState<any>({})
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [idOrdineDettaglio, setIdOrdineDettaglio] = useState<any>()
    const [open, setOpen] = useState<boolean>(true)
    const [filtriFarmSelected, setFiltriFarmSelected] = useState<any[]>([])

    useEffect(() => {
        if (props.auth && props.reload == false) {

            filterAction({
                dal: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'),
                al: new Date()
            })
        }

    }, [props.auth]);

    useEffect(() => {



        if (props.filtriElenco) {
            setParamsRicerca(props.filtriElenco)
        }
            

    }, [props.filtriElenco])

    useEffect(() => {
        
        if (props.reload == true) {
            let farmSelected: any[] = []
            if (props.filtriElenco && props.filtriElenco.idFarmacie && props.filtriElenco.idFarmacie.length > 0 && props.farmaList) {
                props.filtriElenco.idFarmacie.forEach((item: any) => {
                    let obj = {}

                    let filtered = props.farmaList.filter((fl: any) => {
                        return fl.idFar === item
                    })
                    if (filtered && filtered.length == 1) {
                        let far: any = filtered[0]
                        obj = { value: far.idFar, label: far.descrizione  }
                        farmSelected.push(obj)
                    }

                    setFiltriFarmSelected(farmSelected)

                })
            }

            //se reload vuol dire che devo valorizzare paramsRicerca
            setParamsRicerca({
                dal: props.filtriElenco && props.filtriElenco.dal ? props.filtriElenco.dal : new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01'),
                al: props.filtriElenco && props.filtriElenco.al ? props.filtriElenco.al : new Date(),
                idStato: props.filtriElenco && props.filtriElenco.idStato ? props.filtriElenco.idStato : undefined,
                idFarmacie: props.filtriElenco && props.filtriElenco.idFarmacie && props.filtriElenco.idFarmacie.length > 0 ? farmSelected : []
            })

            filterAction({
                dal: props.filtriElenco && props.filtriElenco.dal ? props.filtriElenco.dal : new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01'),
                al: props.filtriElenco && props.filtriElenco.al ? props.filtriElenco.al : new Date(),
                idStato: props.filtriElenco && props.filtriElenco.idStato ? props.filtriElenco.idStato : undefined,
                idFarmacie: props.filtriElenco && props.filtriElenco.idFarmacie && props.filtriElenco.idFarmacie.length > 0 ? farmSelected : []
            })

        }


    }, [props.reload])


    const filterAction = (objFiltro: Filtri) => {

        if (new Date(objFiltro.dal) > new Date(objFiltro.al)) {
            notify({ position: "center", width: "auto", message: "La data inizio non puo' essere maggiore della data fine" }, 'error', 4000);
        }
        else {
            setLoading(true)
            let params = { ...objFiltro }

            if (params.idFarmacie) {

                let newArr: number[] = []
                params.idFarmacie.forEach((element: any) => {
                    newArr.push(element.value)
                });

                params.idFarmacie = newArr
            }

            if (props.reload && paramsRicerca && paramsRicerca.idFarmacie) {
                let newArr: number[] = []
                paramsRicerca.idFarmacie.forEach((element: any) => {
                    if (element.hasOwnProperty('value'))
                        newArr.push(element.value)
                });
                if (newArr && newArr.length > 0)
                    params.idFarmacie = newArr
                else
                    params.idFarmacie = paramsRicerca.idFarmacie
                
            }

            setParamsRicerca(params)
            props.setFiltriElenco(params)

            getList(params)
        }


    }

    useEffect(() => {

        if (props.getList && props.getList == true) {
            //console.log("getList")
            getList(paramsRicerca)
        }


    }, [props.getList])

    const getList = (par: any) => {

        setLoading(true)
        if (props.auth) {
            RestServices().rest('POST', par, urlB2B.getListOrdiniB2B)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setTabellaPrincipale(result.response)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const columns = [

        { caption: "Id", dataField: "id", visible: false },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "date", width: 90, sortOrder: "desc" },
        { caption: "Titolo", dataField: "titolo" },
        { caption: "Farmacie", dataField: "farmacie" },
        { caption: "Stato", dataField: "stato" },
        { caption: "Tot. prodotti", dataField: "totaleProdotti" },
        { caption: "Tot. qta.", dataField: "totaleQuantita" },
        { caption: "Tot. qta. omaggio", dataField: "totaleQuantitaOmaggio" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Vedi",
                    icon: "bulletlist",
                    onClick: (e: any) => {
                        setIdOrdineDettaglio(e.row.data.id)
                    },
                },
                {
                    hint: "Approva",
                    icon: "todo",
                    onClick: (e: any) => {
                        props.approva(e.row.data.id,2)

                    }
                },
                {
                    hint: "Rifiuta",
                    icon: "undo",
                    onClick: (e: any) => {
                        props.approva(e.row.data.id, 3)

                    }
                }
            ]
        }
        
    ]

    useEffect(() => {

        if (idOrdineDettaglio) {
            props.setIdOrdineDettaglio(idOrdineDettaglio)
        }

    },[idOrdineDettaglio])

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            setIdOrdineDettaglio(rowDblClicked.data.id)
        }
    }

    const cambiaValore = (tipo: string, value: string,) => {
        let objTo = { ...paramsRicerca }

        objTo[tipo] = value

        setParamsRicerca(objTo)
            
    }
    return (
        <>
            <Loading isVisible={loading} />
            {/*<div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>*/}
            <div>
            <div className={open ? 'main' : 'mainOut'}>
                <Row>
                    <Col sm="10">
                        <div style={{ position: 'relative' }} className='gridFilterRicerca'>

                                <div style={{ width: 350 }}>
                                    <label>Farmacie</label>
                                    <Select placeholder="Seleziona..." menuPortalTarget={document.body} menuPosition={'fixed'} styles={{
                                        multiValue: (styles, { data }) => {       
                                            return {
                                                ...styles,
                                                maxWidth: 70
                                            };
                                        },
                                    }} onChange={(x: any) => { cambiaValore("idFarmacie", x); setFiltriFarmSelected(x) }}
                                        isMulti
                                        options={props.farmaListSelect}
                                        className="basic-multi-select"
                                        value={filtriFarmSelected && filtriFarmSelected.length > 0 ? filtriFarmSelected : undefined}
                                        classNamePrefix="select" />

                                </div>

                                <div style={{ width: 250}}>
                                    <label>Dal</label>
                                    <DateBox showClearButton={undefined} onValueChanged={(v) => cambiaValore("dal", v.value)} defaultValue={props.filtriElenco && props.filtriElenco.dal ? props.filtriElenco.dal : new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01')} type="date" displayFormat="dd/MM/yyyy" />
                                </div>

                                <div style={{ width: 250 }}>
                                    <label>Al</label>
                                    <DateBox showClearButton={undefined} onValueChanged={(v) => cambiaValore("al", v.value)} defaultValue={props.filtriElenco && props.filtriElenco.al ? props.filtriElenco.al : new Date()} type="date" displayFormat="dd/MM/yyyy" />
                                </div>
                                <div style={{ width:250 }}>
                                    <label>Stato</label>
                                    <SelectBox dataSource={props.listStato}
                                        showClearButton={true}
                                        displayExpr={"descrizione"}
                                        valueExpr={"idStato"}
                                        searchEnabled={true}
                                        onValueChanged={(v) => cambiaValore("idStato", v.value)}
                                        grouped={false}
                                        defaultValue={props.filtriElenco && props.filtriElenco.idStato ? props.filtriElenco.idStato : undefined}

                                    />
                                </div>


                        </div>
                    </Col>
                    <Col sm="2">
                        <div className='posbtn'>
                                <Button id='btnCerca' className="btn btn-success" variant="success" onClick={() => {filterAction(paramsRicerca)  }} >
                                    CERCA
                                </Button>

                        </div>
                    </Col>
                </Row>





            </div>

        </div >

            <NewTabella
                idTabella='elenco_ordini'
                sync
                colonne={columns}
                dataSource={tabellaPrincipale}
                filterRow
                searchPanel={true}
                onclickRow={() => null}
                exportT={{
                    enabled: true,
                    fileName: "elenco_ordini"
                }}
                activeSaver={true}
                columnC={true}
                onRowDblClick={onRowDblClick }
            />
        </>
    )
}

export default ElencoOrdiniB2B 


