import React, { useState } from "react";
import { useEffect } from "react";
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ProdottoAbbinamentoInterface } from "../../interfaces/prodottoAbbinamento";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { BoxTestoCerca } from "../../widget/Editor";
import { TabellaCompleta } from "../../widget/Tabelle";
import Button from 'react-bootstrap/esm/Button';
import { Loading } from "../../widget/Notifications";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { FornitoriCentrInterface, OggettoInterface } from "../../interfaces/generiche";
import notify from "devextreme/ui/notify";
import { RestServices } from "../../services/restServices";
import { urlAnagrafiche, urlProdottoAbbinamento } from "../costanti";
import { DialogElencoFatturaAbbina, DialogElencoFatturaDisabbina } from "./Dialog";
import { Accordion } from "devextreme-react";
import CustomTitle from "./CustomTitle";
import CustomItem from "./CustomItem";


const ProdottoAbbinamento = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean, contenuto: string }) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    let titolo = TitoloContenuto(props.contenuto);

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [inputCerca, setInputCerca] = useState<string>("");
    const [searchBoxRefInstance, setSearchBoxRefInstance] = useState<any>();
    const [elencoProdotti, setElencoProdotto] = useState<ProdottoAbbinamentoInterface[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [isDialogDisabbinaOpen, setIsDialogDisabbinaOpen] = useState<boolean>(false);
    
    const [isDialogAbbinaOpen, setIsDialogAbbinaOpen] = useState<boolean>(false);
    
    const [codCsf, setCodCsf] = useState<number|undefined>();
    const [ean, setEan] = useState<string | undefined>();
    const [idFatProdottoAbbinamento, setIdFatProdottoAbbinamento] = useState<number | undefined>();
    const [codProd, setCodProd] = useState<string | undefined>();
    const [descrizione, setDescrizione] = useState<string | undefined>();
    const [eanProdotto, setEanProdotto] = useState<string | undefined>();
    const [partitaIvaFornitore, setPartitaIvaFornitore] = useState<string | undefined>();
    const [partitaIvaDitta, setPartitaIvaDitta] = useState<string | undefined>();
    const [bottoneCliccato, setBottoneCliccato] = useState<number | undefined>();
    

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    //useEffect(() => {
    //    setIsVisible(true);
    //    RestServices().rest('POST', "", urlAnagrafiche.getFornitoreCentral).then(result => {
    //        if (result) setFornitori(result);
    //    })
    //    setIsVisible(false);
    //},[])

    const handleCloseDialogAbbina = (aggiorna:boolean) => {
        setIsDialogAbbinaOpen(false);
        if (aggiorna) {
            switch (bottoneCliccato) {
                case 1:
                    startRicercaProd();
                    break;
                case 2:
                    startRicercaProdAbb();
                    break;
                default:
                    startRicercaProd();
                    break;
            }
        }
        
    }

    const handleCloseDialogDisabbina = (aggiorna: boolean) => {
        setIsDialogDisabbinaOpen(false);
        if (aggiorna) {
            switch (bottoneCliccato) {
                case 1:
                    startRicercaProd();
                    break;
                case 2:
                    startRicercaProdAbb();
                    break;
                default:
                    startRicercaProd();
                    break;
            }
        }
        
    }

    const startRicercaProd = () => {

        setBottoneCliccato(1);

        if (inputCerca) {
            if (!inputCerca) {
                notify({ position: "center", width: "auto", message: "Inserire il testo da ricercare" }, 'error', 4000);
            } else {
                setIsVisible(true);
                const param = {
                    testo: inputCerca,
                    lstFar: undefined,
                    codCsf: undefined,
                    soloInCommercio: undefined,
                    soloInGiacenza: undefined
                }
                RestServices().rest('POST', param, urlProdottoAbbinamento.getProdottoAbbinamento)
                    .then(result => {
                        setIsVisible(false);
                        if (result) {
                            if (result.esito === true) {
                                if (result.message) {
                                    notify({ position: "center", width: "auto", message: result.message }, 'info', 2500);
                                }
                                setElencoProdotto(result.listResult)
                            }
                            else if (result.esito === false) {
                                notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                            }
                        }
                        else {
                            notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                        }
                    })
            }
        } else {
            notify({ position: "center", width: "auto", message: "Inserire i parametri di ricerca richiesti" }, 'error', 4000);
        }
    }


    const startRicercaProdAbb = () => {

        setBottoneCliccato(2);

        setIsVisible(true);
        const param = {};

        RestServices().rest('POST', param, urlProdottoAbbinamento.getListaProdottoAbbinato)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result.esito === true) {
                        if (result.message) {
                            notify({ position: "center", width: "auto", message: result.message }, 'info', 2500);
                        }
                        setElencoProdotto(result.listResult)
                    }
                    else if (result.esito === false) {
                        notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                    }
                }
                else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })

    }

    const aggiungiAbbinamento = () => {
        setEan(undefined);
        setCodCsf(undefined);
        setIdFatProdottoAbbinamento(undefined);
        setCodProd(undefined)
        setDescrizione(undefined)
        setEanProdotto(undefined);
        setPartitaIvaFornitore(undefined);
        setPartitaIvaDitta(undefined);
        setIsDialogAbbinaOpen(true);
    }


    const propsTabProdottoAbbinamento: TabellaCompletaInterface = {
        id: "prodotto_abbinamento",
        dataSource: elencoProdotti,
        pagination: 15,
        selection: "none",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: true,
        enablePagination: true,
        autoWidth: true,
        summary: {

            totalItems: [{
                name: "TotaleRecord",
                showInColumn: "Cod. Art. gruppo",
                displayFormat: "Totale: {0}",
                summaryType: "count"
            }]
        },
    }

    const columnsProdottoAbbinamento = CreaColonne([
        { caption: "Id Abbinamento", dataField: "idFatProdottoAbbinamento", visible: false },
        { caption: "Codice Ministeriale", dataField: "codProd" },
        { caption: "EAN", dataField: "codEan" },
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Ditta", dataField: "ditta" },
        { caption: "P.Iva ditta", dataField: "partitaIvaDitta" },
        { caption: "Cod. Art. banca dati", dataField: "codArtBd" },
        { caption: "Fornitore", dataField: "fornitore" },
        { caption: "P.Iva fornitore", dataField: "partitaIvaFornitore" },
        { caption: "Cod. Art. gruppo", dataField: "codArtGruppo" },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Abbina",
                icon: "add",
                onClick: (e: any) => {
                    setCodCsf(e.row.data.codCsf);
                    setEan(e.row.data.codArtGruppo);
                    setIdFatProdottoAbbinamento(e.row.data.idFatProdottoAbbinamento);
                    setCodProd(e.row.data.codProd)
                    setDescrizione(e.row.data.descrizione)
                    setEanProdotto(e.row.data.codEan);
                    setPartitaIvaFornitore(e.row.data.partitaIvaFornitore);
                    setPartitaIvaDitta(e.row.data.partitaIvaDitta);
                    setIsDialogAbbinaOpen(true);
                }
            },
                {
                    hint: "Disabbina",
                    icon: "remove",
                    onClick: (e: any) => {
                        setCodCsf(e.row.data.codCsf);
                        setEan(e.row.data.codArtGruppo);
                        setIdFatProdottoAbbinamento(e.row.data.idFatProdottoAbbinamento);
                        setDescrizione(e.row.data.descrizione)
                        setPartitaIvaFornitore(e.row.data.partitaIvaFornitore);
                        setPartitaIvaDitta(e.row.data.partitaIvaDitta);
                        if (e.row.data.idFatProdottoAbbinamento == 0 || e.row.data.idFatProdottoAbbinamento == null || e.row.data.idFatProdottoAbbinamento == undefined)
                            notify({ position: "center", width: "auto", message: 'non e\' presente l\'abbinamento' }, 'error', 2000);
                        else
                            setIsDialogDisabbinaOpen(true);
                    }
                }
            ]
        }
    ])

    const infos = [{
        'ID': 1,
        'InfoName': 'Informazioni',
        'Testo': '702 SW 8th Street',
    }]

    return (
        <>
            {loadPanel}
            <h3 className="titoloProdottoAbbinamento"> {titolo}</h3>
            <div id="accordion">
                <Accordion
                    dataSource={infos}
                    collapsible={true}
                    multiple={false}
                    animationDuration={100}
                    itemTitleRender={CustomTitle}
                    itemRender={CustomItem}
                />
            </div>
            {/* <p className="testo">
                La pagina di abbinameno Codice Articolo - Prodotto permette di definire e gestire le regole di associazione tra un codice articolo presente in fattura fornitore ed un prodotto.
                La regola sara' applicabile nel caso in cui in fattura sono presenti dei prodotti senza il relativo identificativo univoco (ministeriale o ean) ma con il codice articolo interno della ditta; in questi casi al codice articolo in fattura sara' associato automaticamente il prodotto definito appunto nella regola qualora essa sia rispettata.
                Le regole definite sono salvate ed applicate a livello di gruppo, quindi per tutte le farmacie che ne fanno parte.
                Le funzionalita' principali:<br></br>
                1.	<b>Cerca</b><br></br>
                    &emsp;Ricerca per ministeriale, ean, descrizione e codice articolo ditta: visualizza i risultati che soddisfano la parola inserita nella casella di testo<br></br>
                2.	<b>Lista Abbinamenti</b><br></br>
                    &emsp;Elenca tutti gli abbinamenti(regole) definite<br></br>
                Per ogni record mostrato in tabella ci sono due tasti funzione che permettono:<br></br>
                1.	<b>Abbinamento</b><br></br>
                    &emsp;Definizione della regola di abbinamento codice articolo - prodotto<br></br>
                2.	<b>Disabbinamento</b><br></br>
                    &emsp;Disabbina la regola codice articolo - prodotto precedentemente definita<br></br>
            </p>
            */}
            &nbsp;
            <Row id="rigaRicercaProdottoAbbinamento">
                <Col sm='3' className="prodottoAbbinamento">
                    <span>Ricerca prodotto (codice, EAN, descrizione, codice articolo ditta)</span>
                </Col>
                <Col sm='4' className="boxCerca">
                    <BoxTestoCerca
                        placeholder=""
                        id="ricercaProdottiBD"
                        setInputCerca={setInputCerca}
                        startRicerca={startRicercaProd}
                        inputCerca={inputCerca}
                        setSearchBoxRefInstance={setSearchBoxRefInstance}
                    />
                </Col>
                {/*<Col sm="1">*/}

                {/*</Col>*/}
                <Col sm="1">
                    <Button type="button" variant="success" id="cercaProdotti" onClick={startRicercaProd}>Cerca</Button>
                </Col>
                <Col sm="4">
                    <Button type="button" variant="info" id="cercaProdottiAbbinati" onClick={startRicercaProdAbb}>Lista abbinamenti</Button>
                    <Button type="button" variant="secondary" id="aggiungiAbbinamento" onClick={aggiungiAbbinamento}>Aggiungi abbinamento</Button>
                </Col>
                {/*<Col sm="2">
                    <Button type="button" variant="secondary" id="aggiungiAbbinamento" onClick={aggiungiAbbinamento}>Aggiungi abbinamento</Button>
                </Col>*/}
            </Row>
            <Row>
                <Col>
                    <TabellaCompleta propsTabella={propsTabProdottoAbbinamento} columns={columnsProdottoAbbinamento} setTabellaInstance={setTabellaInstance}/>
                </Col>
            </Row>

            <DialogElencoFatturaDisabbina
                isDialogDisabbinaOpen={isDialogDisabbinaOpen}
                setIsDialogDisabbinaOpen={setIsDialogDisabbinaOpen}
                handleCloseDialogDisabbina={handleCloseDialogDisabbina}
                setIsVisible={setIsVisible}
                codCsf={codCsf}
                ean={ean}
                idFatProdottoAbbinamento={idFatProdottoAbbinamento}
                descrizione={descrizione}
                partitaIvaFornitore={partitaIvaFornitore}
                partitaIvaDitta={partitaIvaDitta}
            />
            <DialogElencoFatturaAbbina
                isDialogAbbinaOpen={isDialogAbbinaOpen}
                setIsDialogAbbinaOpen={setIsDialogAbbinaOpen}
                handleCloseDialogAbbina={handleCloseDialogAbbina}
                setIsVisible={setIsVisible}
                codCsf={codCsf}
                ean={ean}
                idFatProdottoAbbinamento={idFatProdottoAbbinamento}
                codProd={codProd}
                descrizione={descrizione}
                eanProdotto={eanProdotto}
                partitaIvaFornitore={partitaIvaFornitore}
                partitaIvaDitta={partitaIvaDitta}
            />

        </>
    )
}

export default ProdottoAbbinamento