import notify from "devextreme/ui/notify";
import { OggettoInterface } from "../../../interfaces/generiche";
import { RestServices } from "../../../services/restServices";
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { Loading } from "../../../widget/Notifications";
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { urlConsultazione } from "../../costanti";
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import { TabellaSempliceInterface } from "../../../interfaces/tabelle";
import { TabellaSemplice } from "../../../widget/Tabelle";

export const DialogLogInvioProd = (props: {
    dialogLogInvioProdOpen: boolean,
    setDialogLogInvioProdOpen: (dialogLogInvioProdOpen: boolean) => void,
    idProdottoGruppo: number | string | undefined,
    handleCloseDialogLogInvioProd: () => void
    message: string
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoLog, setElencoLog] = useState<any>();


    const handleOpen = () => {

            logInvioProdotto();

        }


    const logInvioProdotto = () => {
        if (props.idProdottoGruppo) {
            setIsVisible(true)
            const url = urlConsultazione.logInvioProdottoGruppo;
            const param = {
                idProdottoGruppo: props.idProdottoGruppo
            }
            RestServices().rest('POST', param, url).then(result => {
                if (result) {
                    setIsVisible(false)
                    if (result.esito) {
                        setElencoLog(result.response);
                    }
                    
                }
            })
        }

    }

    const propsTabLogInvioProdotto: TabellaSempliceInterface = {
        id: "logInvioProdotto",
        dataSource: elencoLog,
        filterRow: true,
        pagination: 5,
    }

    const columnsLogInvioProdotto = CreaColonne([
        { caption: "Farmacia", dataField: "descrizioneFarmacia" },
        { caption: "Data invio", dataField: "dataInvio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder:"asc" },
        { caption: "Data ricezione", dataField: "dataRicezione", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Stato", dataField: "stato" },
    ])

    const handleClose = () => {
        props.setDialogLogInvioProdOpen(false);
    }

    return (
        <>
            <Modal
                show={props.dialogLogInvioProdOpen}
                onHide={props.handleCloseDialogLogInvioProd}
                dialogClassName="dialogTrasmettiOrdLib"
                centered={true}
                backdrop="static"
                onEntered={handleOpen}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdLib">
                        <h3>{ props.message}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row id="tabellaLogEmail">

                        <TabellaSemplice propsTabella={propsTabLogInvioProdotto} columns={columnsLogInvioProdotto} />

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}