import { basename } from "path";

//prima di fare il war per il rilascio va messo REACT_APP_PROD = "true" e REACT_APP_ENV = "local8080" nel file .env
export const basenameHostNewTabs = process.env.REACT_APP_PROD === "false" ? (process.env.REACT_APP_ENV === "local3000" ? 'http://localhost:3000/app/' : 'http://localhost:8080/app/') : 'https://www.cloud-farma.it/app/';

export const basenameHost = process.env.NODE_ENV === "development" ? 'http://localhost:8080/' : 'https://www.cloud-farma.it/';
export const basenameHostServizi = process.env.NODE_ENV === "development" ? 'http://localhost:8080/' : 'https://servizi.cloud-farma.it/';
export const basenameHostAzure = process.env.NODE_ENV === "development" ? 'http://localhost:8080/' : 'https://cloud1.cloud-farma.it:8443/';

//export const basenameHost = 'https://www.cloud-farma.it/';
//export const basenameHostServizi = 'https://servizi.cloud-farma.it/';
//export const basenameHostAzure = 'https://cloud1.cloud-farma.it:8443/';

//export const basenameHost = 'http://localhost:8080/';

export const basenameCfPortal = basenameHost + "cfportal/v1/";
export const basenameCfPortalWs = basenameHost + "cfportal/ws/";

export const urlRicercaSito = basenameHost + 'cfportal/index.jsp';

export const urlLogOut = basenameHost + "cfportal/logout.jsp";

export const urlInfoCsf = basenameHost + "cfportal/info_csf.jsp";

export const urlHomePortal = basenameHost + "app/home/new";
export const urlHomePortalMyCsf = basenameHost + "app/home_myCsf";

export const urlCfPortlaLogin = basenameHost + "/cfportal/login";
export const urlCfPortalLogout = basenameHost + "cfportal/logout.jsp";

export const urlmyCsf = {
    anaFarmacie: basenameCfPortal + 'anaFar/getAnaFarmacie',
    contrattiFarm: basenameCfPortal + 'anaFar/getAnaContrattiFarmacie',
    serviziFarm: basenameCfPortal + 'anaFar/getAnaServiziFarmacie',
    anaConcess: basenameCfPortal + 'anaFar/getAnagraficaFarmaciaConcessionario',
    contrattiConcess: basenameCfPortal + 'anaFar/getAnagraficaContrattiFarmaciaConcessionario',
    serviziConcess: basenameCfPortal + 'anaFar/getAnagraficaServiziFarmaciaConcessionario',
    setAnaFarmacie: basenameCfPortal + 'anaFar/setAnaFarmacie'
}

export const urlMenu = basenameCfPortal + 'menu/getMenu';

export const urlFarmGruppo = basenameCfPortal + 'farmacie/getFar';

export const urlFarmMongo = {
    getFarm: basenameCfPortal + 'farDatiMongo/getFarMongo',
    datiFarm: basenameCfPortal + 'farDatiMongo/getDatiFar'
};

export const urlLayoutTabelle = {
    urlSetLayout: basenameCfPortal + 'layout/salvaLayout',
    urlGetLayout: basenameCfPortal + 'layout/leggiLayout',
    urlDeleteLayout: basenameCfPortal + 'layout/cancellaLayout'
}

export const urlChangePsw = basenameCfPortal + 'utenti/updatePassword';

export const urlAnagrafiche = {
    getFornLoc: basenameCfPortal + 'forn/getFornLoc',
    getAnagraficaFornitore: basenameCfPortal + 'forn/getAnagrafica',
    getDescrizioneMagazzino: basenameCfPortal + 'magazzino/getAnagrafica',
    getFornitoreCentral: basenameCfPortal + 'forn/getFornCentr',
    getEmailFornitore: basenameCfPortal + 'forn/getMail',
    getEmailFornitoreFarmacia: basenameCfPortal + 'forn/getMailFornitoreFarmacia',
    getEmailFornitoreGruppo: basenameCfPortal + 'forn/getMailFornitoreGruppo',
    getAnagraficaClientiNewsletter: basenameCfPortal + 'anaFar/getAnagraficaClientiNewsletter',
    inviaNewsletter: basenameCfPortal + 'anaFar/inviaNewsletter',
    getAnagraficaClienteNewsletterDettaglio: basenameCfPortal + 'anaFar/getAnagraficaClienteNewsletterDettaglio',
    getElencoNewsletter: basenameCfPortal + 'anaFar/getElencoNewsletter',
    getNewsletterDettaglio: basenameCfPortal + 'anaFar/getNewsletterDettaglio',
    getElencoImmaginiNewsletter: basenameCfPortal + 'anaFar/getElencoImmaginiNewsletter',
    uploadFile: basenameHost + 'cfportal/uploadFile',
    getAnagraficaClientiDoppioClient: basenameCfPortal + 'anaFar/getAnagraficaClientiDoppioClient',
    getAnagraficaClientiDoppioClientDettaglio: basenameCfPortal + 'anaFar/getAnagraficaClientiDoppioClientDettaglio',
    getPrezziFarmacia: basenameCfPortal + 'magazzino/getPrezziFarmacia',
    find: basenameCfPortal + 'fatturazione/find',
    pushMassivePrezzo: basenameCfPortal + 'magazzino/pushMassivePrezzo',
    
}

export const urlBancaDati = {
    cercaProd: basenameCfPortal + 'prodotto/cerca',
    getProd: basenameCfPortal + 'prodotto/getProdottoBD',
    getProdotti: basenameCfPortal + 'prodotto/getProdottiBD',
    immagineProd: basenameCfPortal + 'prodotto/immagini',
    getGiacenza: basenameCfPortal + 'prodotto/getProdottoGiacenza',
    getCostoMedio: basenameCfPortal + 'prodotto/getProdottoCostoMedio',
    getPrezzoMedio: basenameCfPortal + 'prodotto/getProdottoPrezzoMedioFarmacia',
    getVenditaMovimento: basenameCfPortal + 'prodotto/getProdottoVenditaMovimento',
    getCaricoMovimento: basenameCfPortal + 'prodotto/getProdottoCaricoMovimento',
    atcHierarchy: basenameCfPortal + 'ATCHierarchy/getHierarchy',
    getUltimoCosto: basenameCfPortal + 'prodotto/getProdottoUltimoCosto',
    getOfferte: basenameCfPortal + 'prodotto/getProdottoOfferte',
    getPrezzoFarmacia: basenameCfPortal + 'prodotto/getProdottoPrezzoFarmacia',
    getPrezzoPromozione: basenameCfPortal + 'prodotto/getProdottoPrezzoPromozione',
    getCostoMedioDett: basenameCfPortal + 'prodotto/getProdottoCostoMedioDettaglio',
    getGiacenzaDett: basenameCfPortal + 'prodotto/getProdottoGiacenzaDettaglio',
    getGiacenzaDettMagazz: basenameCfPortal + 'prodotto/getProdottoGiacenzaDettaglioMagazzino',
    getVenditaMovimDett: basenameCfPortal + 'prodotto/getProdottoVenditaMovimentoDettaglio',
    getCaricoMovimDett: basenameCfPortal + 'prodotto/getProdottoCaricoMovimentoDettaglio',
    getLottoRiordino: basenameCfPortal + 'prodotto/getProdottoLottoRiordino',
    getPrezzoStoricoFarm: basenameCfPortal + 'prodotto/getProdottoPrezzoStoricoFarmacia',
    getSettore: basenameCfPortal + 'prodotto/getSettore',
    getGruppo: basenameCfPortal + 'prodotto/getGruppo',
    getDitte: basenameCfPortal + 'prodotto/getDitte',
    getLinee: basenameCfPortal + 'prodotto/getLinee',
    getClasseA: basenameCfPortal + 'prodotto/getClasseA',
    getClasseC: basenameCfPortal + 'prodotto/getClasseC',
    getSopOtc: basenameCfPortal + 'prodotto/getSopOtc',
    getGenerici: basenameCfPortal + 'prodotto/getGenerici',
    getGruppoMerceologico: basenameCfPortal + 'prodotto/getGruppoMerceologico',
    getCategory: basenameCfPortal + 'prodotto/getCategory',
    getAtc: basenameCfPortal + 'prodotto/getAtc',
    getIva: basenameCfPortal + 'prodotto/getIva',
    getSegnalazione: basenameCfPortal + 'prodotto/getSegnalazione',
    findProdottiDitta: basenameCfPortal + 'prodotto/findDitta',
    advancedSearch: basenameCfPortal + 'prodotto/advancedSearch',

}

export const urlTorinoRsa = {
    getElencoRsa: basenameCfPortal + 'rsa/getListRSA',
    getRsa: basenameCfPortal + 'rsa/getRSA',
    editRsa: basenameCfPortal + 'rsa/editRSA',
    insertRsa: basenameCfPortal + 'rsa/insertRSA',
    cancellaCodFisc: basenameCfPortal + 'rsa/deleteCFToRSA',
    aggiungiCodFisc: basenameCfPortal + 'rsa/addCFToRSA'
}

/*------------------------------------------------------------------------------------------------------------------ */
/* COMMON */
export const basenameCommon = basenameHost + 'cfcommonws/v1/'

export const urlCommon = {
    auth: basenameCommon + 'auth',
    getVenduto: basenameCommon + 'vendite/getVenduto',
    getAcquistato: basenameCommon + 'carichi/getAcquistato',
    getListaPagamenti: basenameCommon + 'ordini/getListPagamenti',
    setRicercaProd: basenameCommon + 'prodotti/setParamUte',
    getRicercaProd: basenameCommon + 'prodotti/getParamBoolUte',
    findProdotti: basenameCommon + 'prodotti/findProdotti',
    getProdottoFarmaciaInfoLight: basenameCommon + 'prodotti/getProdottoFarmaciaInfoLight',
    getListForn: basenameCommon + 'fornitori/getListForn',
    getListFornLoc: basenameCommon + 'fornitori/getListFornLoc',
    getCausaliOrdine: basenameCommon + 'tipologie/getCausaliOrdine',
    getCausaliCarico: basenameCommon + 'tipologie/getCausaliCarico',

    getListAnomalie: basenameCommon + "anomalia/getList",
    updateAnomalia: basenameCommon + "anomalia/update",
    getListFornCen: basenameCommon + 'fornitori/getListFornCen',
    getFornCen: basenameCommon + 'fornitori/getFornCen',

    getListTipoAmministrativa: basenameCommon + 'opzioniTipo/getListTipoAmministrativa',
    getListTipiPagamentoNuovo: basenameCommon + 'opzioniTipo/getListTipiPagamentoNuovo',
    getListTipoSettoreSanita: basenameCommon + 'opzioniTipo/getListTipoSettoreSanita',
    getListTipoStatoApplicativo: basenameCommon + 'opzioniTipo/getListTipoStatoApplicativo',
    getListTipoCategoriaFornitore: basenameCommon + 'fornitori/getListCategorie',

    getListProvincie: basenameCommon + 'opzioniTipo/getListProvincie',
    getListComuneAsl: basenameCommon + 'opzioniTipo/getListComuneAsl',
    getListTipoIndirizzo: basenameCommon + 'opzioniTipo/getListTipoIndirizzo',
    getListTipoEmail: basenameCommon + 'opzioniTipo/getListTipoEmail',
    getListTipoLabelEmail: basenameCommon + 'opzioniTipo/getListTipoLabelEmail',
    getListTipoTelefono: basenameCommon + 'opzioniTipo/getListTipoTelefono',

    getListIndirizzi: basenameCommon + 'anagraficaIndirizzi/getListAnagraficaIndirizzi',
    getListEmail: basenameCommon + 'anagraficaIndirizzi/getListEmail',
    getListTelefoni: basenameCommon + 'anagraficaIndirizzi/getListTelefoni',
    updateFornitore: basenameCommon + 'fornitori/updateFornitore',
    addFornitore: basenameCommon + 'fornitori/addFornitore',
    abilitaDisabilitaFornitore: basenameCommon + 'fornitori/abilitaDisabilitaFornitore',
    eliminaIndirizzo: basenameCommon + 'anagraficaIndirizzi/deleteAltroIndirizzo',
    eliminaEmail: basenameCommon + 'anagraficaIndirizzi/deleteEmail',
    eliminaTelefono: basenameCommon + 'anagraficaIndirizzi/deleteTelefono',
    associaFornitore: basenameCommon + 'fornitori/associaFornitore',
    getListAssociazioniFarmacie: basenameCommon + 'fornitori/getListAssociazioniFarmacie',
    inviaFornitori: basenameCommon + 'fornitori/inviaFornitori',
    getIndirizzo: basenameCommon + 'anagraficaIndirizzi/getAnagraficaIndirizzo',
    updateAltroIndirizzo: basenameCommon + 'anagraficaIndirizzi/updateAltroIndirizzo',
    getAnagraficaEmail: basenameCommon + 'anagraficaIndirizzi/getAnagraficaEmail',
    updateAnagraficaEmail: basenameCommon + 'anagraficaIndirizzi/updateAnagraficaEmail',
    getAnagraficaTelefono: basenameCommon + 'anagraficaIndirizzi/getAnagraficaTelefono',
    updateAnagraficaTelefono: basenameCommon + 'anagraficaIndirizzi/updateAnagraficaTelefono',
    addAltroIndirizzo: basenameCommon + 'anagraficaIndirizzi/addAltroIndirizzo',
    addAnagraficaEmail: basenameCommon + 'anagraficaIndirizzi/addAnagraficaEmail',
    addAnagraficaTelefono: basenameCommon + 'anagraficaIndirizzi/addAnagraficaTelefono',
    getListFornLocAssociazione: basenameCommon + 'fornitori/getListFornLocAssociazione',
    dissociaFornitore: basenameCommon + 'fornitori/dissociaFornLoc',
    setFornitorePreferito: basenameCommon + 'fornitori/setFornitorePreferito',
    getListDitte: basenameCommon + 'fornitori/getListDitte',

    getListLivellamentoMagazzino: basenameCommon + 'livellamentoMagazzino/getList',
    addLivellamentoMagazzino: basenameCommon + 'livellamentoMagazzino/add',
    deleteLivellamentoMagazzino: basenameCommon + 'livellamentoMagazzino/delete',
    elaboraLivellamentoMagazzino: basenameCommon + 'livellamentoMagazzino/elabora',
    getListProdottiLivellamentoMagazzino: basenameCommon + 'livellamentoMagazzino/getListProdotti',
    getRichiesta: basenameCommon + 'livellamentoMagazzino/getRichiesta',
    getLivellamentoFarmacieProdotto: basenameCommon + 'livellamentoMagazzino/getLivellamentoFarmacieProdotto',
    aggiornaQuantita: basenameCommon + 'livellamentoMagazzino/updateQuantita',
    getLivellamentoFarmacieProdottoDettaglio: basenameCommon + 'livellamentoMagazzino/getLivellamentoFarmacieProdottoDettaglio',
    inviaLivellamentoMagazzino: basenameCommon + 'livellamentoMagazzino/invia',
    getGiorniCopertura: basenameCommon + 'livellamentoMagazzino/getGiorniCopertura',
    addProdotto: basenameCommon + 'livellamentoMagazzino/addProdotto',
    deleteProdotto: basenameCommon + 'livellamentoMagazzino/deleteProdotto',

    getListCate: basenameCommon + 'prodotti/getListCategory',

    getListExport: basenameCommon + 'Esportazione/getListExport',
    getExportDetails: basenameCommon + 'Esportazione/getExportDetails',

    getRegistratoreDiCassaList: basenameCommon + 'RegistratoreDiCassa/getRegistratoreDiCassaList',
    getDashboardInfo: basenameCommon + 'RegistratoreDiCassa/getDashboardInfo',
    getRegistratoreDiCassaListDettaglio: basenameCommon + 'RegistratoreDiCassa/getRegistratoreDiCassaDettaglioList',
    getScontrini: basenameCommon + 'RegistratoreDiCassa/getScontrini',
    getDettaglioMultivendita: basenameCommon + 'RegistratoreDiCassa/getDettaglioMultivendita',


    getArticoliDocumentazione: basenameCommon + 'Documentazione/getArticoliDocumentazione',
    getElencoRevisioni: basenameCommon + 'Documentazione/getElencoRevisioni',
    getReleaseNote: basenameCommon + 'Documentazione/getReleaseNote',

}

/*------------------------------------------------------------------------------------------------------------------ */
/* CFREPORTBE */
export const basenameReport = basenameHost + 'cfreportbe/v1/'

export const urlReport = {
    auth: basenameReport + 'auth',
    reportList: basenameReport + 'report/getList',
    reportParameters: basenameReport + 'report/getParameters',
    getReport: basenameReport + 'report/getResult',
    getTabella: basenameReport + 'report/getTabella',
    getQueryDashboardData: basenameReport + 'dwh/getData',
    getQueryDashboardAllData: basenameReport + 'dwh/getAllData',
    getFilterGruppoMerceologico: basenameReport + 'dwh/getDataFilterGruppoMerceologico',
    getTipologiaProdotto: basenameReport + 'dwh/getTipologiaProdotto',
    getTipologiaConFascia: basenameReport + 'dwh/getTipologiaConFascia',
    getCategoria: basenameReport + 'dwh/getCategoria',
    getSegmento: basenameReport + 'dwh/getSegmento',
    getBlocco1: basenameReport + 'dwh/getBlocco1',
    getBlocco2: basenameReport + 'dwh/getBlocco2',
    getProgetto: basenameReport + 'dwh/getProgetto',
    getSocieta: basenameReport + 'dwh/getSocieta',
    extractFarvimaPharmacies: basenameReport + 'dwh/extractFarvimaPharmacies'
}

/*------------------------------------------------------------------------------------------------------------------ */
/* CFORDINIRDABE */

export const basenameOrdiniRda = basenameHost + 'cfordinirdabe/v1/'

export const urlOrdiniRda = {
    auth: basenameOrdiniRda + 'auth',
    ordiniList: basenameOrdiniRda + 'ordineRDA/getList',
    getDettagliOrdine: basenameOrdiniRda + 'ordineRDA/getDettaglio',
    setDettagliOrdine: basenameOrdiniRda + 'ordineRDA/setDettaglio',
    deleteDettagliOrdine: basenameOrdiniRda + 'ordineRDA/deleteDettaglio',
    setStatoOrdine: basenameOrdiniRda + 'ordineRDA/setStato',
    stampaPdf: basenameOrdiniRda + 'ordineRDA/params?id=',
    stampaPdfDifferenze: basenameOrdiniRda + 'ordineRDA/params/diff?id=',
    getDifferenze: basenameOrdiniRda + 'ordineRDA/getDifferences',
    inviaFarmacia: basenameOrdiniRda + 'ordineRDA/inviaOrdine/',
    inviaTrasfFarmacia: basenameOrdiniRda + 'ordineRDA/inviaTrasfFarmacia/',
    inviaEmail: basenameOrdiniRda + 'ordineRDA/sendMail',
    getVendutoAnnuoMedio: basenameOrdiniRda + 'ordineRDA/getVenMedio',
    getVendutoMensile: basenameOrdiniRda + 'ordineRDA/getVen',
    updateCosto: basenameOrdiniRda + 'ordineRDA/updateCosto',
    updateSconto1: basenameOrdiniRda + 'ordineRDA/updateSconto1',
    updateScontoProdotti: basenameOrdiniRda + 'ordineRDA/updateScontoProdotti',
    updatePagamento: basenameOrdiniRda + 'ordineRDA/updateTipPag',
    annullaOrdine: basenameOrdiniRda + 'ordineRDA/annullaOrd',
    updateFornitore: basenameOrdiniRda + 'ordineRDA/updateForn',
    getListStatoRDA: basenameOrdiniRda + 'ordineRDA/getListStatoRda',
    getTotaleOrdiniValutazione: basenameOrdiniRda + 'ordineRDA/getTotaleOrdiniValutazione',
    getListEmail: basenameOrdiniRda + 'ordineRDA/getListEmail/',
    inviaOrdiniFornitore: basenameOrdiniRda + 'ordineRDA/inviaOrdiniFornitore',
    inviaOrdiniFarmacia: basenameOrdiniRda + 'ordineRDA/inviaOrdiniFarmacia',
    allineaCosto: basenameOrdiniRda + 'ordineRDA/allineaCosto',
    updateNote: basenameOrdiniRda + 'ordineRDA/updateNote',
    getQuantitaTrasf: basenameOrdiniRda + 'ordineRDA/getQuantitaTrasf',
    getQuantitaTrasfRichiesta: basenameOrdiniRda + 'ordineRDA/getQuantitaTrasfRichiesta',
    setQuantitaTrasf: basenameOrdiniRda + 'ordineRDA/setQuantitaTrasf',

    inviaMailMancanti: basenameOrdiniRda + 'ordineRDA/inviaMailOrdineFarmacieMancanti',

    getReportRda: basenameOrdiniRda + 'ordineRDA/getReportRda',

    creaOrdine: basenameOrdiniRda + 'ordineRDA/creaOrdine',
    clonaOrdine: basenameOrdiniRda + 'ordineRDA/clonaOrdine',
    getListAutorizzazioni: basenameOrdiniRda + 'ordineRDA/getListAutorizzazioni'
}

/*------------------------------------------------------------------------------------------------------------------ */
/* CFORDINILIBERIBE */

export const basenameOrdiniLiberi = basenameHost + 'cfordiniliberibe/v1/'

export const urlOrdiniLiberi = {
    auth: basenameOrdiniLiberi + 'auth',
    ordiniList: basenameOrdiniLiberi + 'ordLiberi/getList',
    creaOrdine: basenameOrdiniLiberi + 'ordLiberi/crea',
    getDettagliOrdine: basenameOrdiniLiberi + 'ordLiberi/getDettaglio',
    inserisciProdotto: basenameOrdiniLiberi + 'ordLiberi/insertDett',
    cambiaQuantita: basenameOrdiniLiberi + 'ordLiberi/updateQta',
    cambiaQuantitaV2: basenameOrdiniLiberi + 'ordLiberi/updateQtaV2',
    updateCosto: basenameOrdiniLiberi + 'ordLiberi/updateCosto',
    updateSconto: basenameOrdiniLiberi + 'ordLiberi/updateSconto',
    getVenduto: basenameOrdiniLiberi + 'ordLiberi/getVen',
    azzeraQuantita: basenameOrdiniLiberi + 'ordLiberi/azzeraQta',
    stampaPdfSingoli: basenameOrdiniLiberi + 'ordLiberi/reportFar?',
    stampaTuttiPdf: basenameOrdiniLiberi + 'ordLiberi/report?id=',
    eliminaOrdine: basenameOrdiniLiberi + 'ordLiberi/delete',
    getSconto: basenameOrdiniLiberi + 'ordLiberi/getUltimoSconto',
    setSconto: basenameOrdiniLiberi + 'ordLiberi/insertUltimoSconto',
    getCheckTrasmetti: basenameOrdiniLiberi + 'ordLiberi/getTrasmetti',
    setCheckTrasmetti: basenameOrdiniLiberi + 'ordLiberi/setTrasmetti',
    getCheckCostoTotale: basenameOrdiniLiberi + 'ordLiberi/getFlagCostoTotale',
    setCheckCostoTotale: basenameOrdiniLiberi + 'ordLiberi/setFlagCostoTotale',
    getCheckCostoFarmacia: basenameOrdiniLiberi + 'ordLiberi/getFlagCostoFarmacia',
    setCheckCostoFarmacia: basenameOrdiniLiberi + 'ordLiberi/setFlagCostoFarmacia',
    getUltimiCostoSconto: basenameOrdiniLiberi + 'ordLiberi/getUltimiCostoSconto',
    cancellaRighe: basenameOrdiniLiberi + 'ordLiberi/deleteDettaglio',
    updatePrezzo: basenameOrdiniLiberi + 'ordLiberi/updatePrezzo',
    updatePrezzoVendita: basenameOrdiniLiberi + 'ordLiberi/updatePrezzoVendita',
    getImpostazioni: basenameOrdiniLiberi + 'ordLiberi/getImpostazioni',
    setImpostazioni: basenameOrdiniLiberi + 'ordLiberi/setImpostazioni',
    getPrezzoListinoFarmacia: basenameOrdiniLiberi + 'ordLiberi/getPrezzoListinoFarmacia',
    getListStatoOrdineLibero: basenameOrdiniLiberi + 'ordLiberi/getListStatoOrdineLibero',
    getInfo: basenameOrdiniLiberi + 'ordLiberi/getInfo',
    setStato: basenameOrdiniLiberi + 'ordLiberi/setStato',
    updateFornitore: basenameOrdiniLiberi + 'ordLiberi/updateFornitore',
    getQuantitaOrdini: basenameOrdiniLiberi + 'ordLiberi/getQuantitaOrdini',
    updateQtaOmag: basenameOrdiniLiberi + 'ordLiberi/updateQtaOmaggio',
    gatFar: basenameOrdiniLiberi + 'ordLiberi/getFar',
    getListIdentici: basenameOrdiniLiberi + 'ordLiberi/getListIdentici',
    getListSimili: basenameOrdiniLiberi + 'ordLiberi/getListSimili',
    getListPrezzoVendita: basenameOrdiniLiberi + 'ordLiberi/getListPrezzoVendita',
    deletePrezzoVendita: basenameOrdiniLiberi + 'ordLiberi/deletePrezzoVendita',
    addListino: basenameOrdiniLiberi + 'ordLiberi/addListino',
    getListini: basenameOrdiniLiberi + 'ordLiberi/getListini',
    setListinoInVigore: basenameOrdiniLiberi + 'ordLiberi/setListinoInVigore',
    deleteListino: basenameOrdiniLiberi + 'ordLiberi/deleteListino',
    getListino: basenameOrdiniLiberi + 'ordLiberi/getListinoDettaglio',
    getListinoProdottoDettaglio: basenameOrdiniLiberi + 'ordLiberi/getListinoProdottoDettaglio',
    
}


/*------------------------------------------------------------------------------------------------------------------ */
/* CFTRASFERIMENTI_REST_BE */

export const basenameTrasferimenti = basenameHostAzure + 'cftrasfr/v1/'

export const urlTrasferimenti = {
    auth: basenameTrasferimenti + 'auth',
    getParametri: basenameTrasferimenti + 'trasferimenti/getParametri',
    setParametri: basenameTrasferimenti + 'trasferimenti/setParametri',
    getElenco: basenameTrasferimenti + 'trasferimenti/getElenco',
    getElencoFarmacie: basenameTrasferimenti + 'trasferimenti/getElencoFarmacie',
    setFarmacia: basenameTrasferimenti + 'trasferimenti/setFarmacia',
    getFar: basenameCfPortal + 'farmacie/getFar',
    getListini: basenameTrasferimenti + 'trasferimenti/getListini',
    importaDaFile: basenameTrasferimenti + 'trasferimenti/importListino',
    setListinoInVigore: basenameTrasferimenti + 'trasferimenti/setListinoInVigore',
    setDeleteListino: basenameTrasferimenti + 'trasferimenti/setDeleteListino',
    getListinoCompleto: basenameTrasferimenti + 'trasferimenti/getListinoCompleto',
    getListinoLog: basenameTrasferimenti + 'trasferimenti/getListinoLog',
    downloadListino: basenameTrasferimenti + 'trasferimenti/downloadListino',
    getListGrossista: basenameTrasferimenti + 'trasferimenti/getListGrossista'
}


/*------------------------------------------------------------------------------------------------------------------ */
/* CFSCREENINGBE */

export const basenameScreening = basenameHost + 'cfscreeningbe/v1/'

export const urlScreening = {
    auth: basenameScreening + 'auth',
    getFar: basenameScreening + 'kit/farmacie',
    consegnaKit: basenameScreening + 'kit/consegna',
    ritiraKit: basenameScreening + 'kit/ritira',
    cerca: basenameScreening + 'kit/cerca',
    vettore: basenameScreening + 'kit/vettore',
    report: basenameScreening + 'kit/report',
    getVettori: basenameScreening + 'kit/vettori',
    getConsultaConsegne: basenameScreening + 'kit/consultaConsegne',
    visibileKit: basenameScreening + 'kit/nascondi',
    getElencoFaq: basenameScreening + 'kit/elencoFaq'
}

/*------------------------------------------------------------------------------------------------------------------ */
/* PRODOTTOAbbinamento */

export const urlProdottoAbbinamento = {
    getProdottoAbbinamento: basenameCfPortal + 'prodottoAbbinamento/getProdottoAbbinamento',
    getFattureProdottoAbbinamento: basenameCfPortal + 'prodottoAbbinamento/getFattureProdottoAbbinamento',
    getFattureProdottoAbbinamentoNonAbbinate: basenameCfPortal + 'prodottoAbbinamento/getFattureProdottoAbbinamentoNonAbbinate',
    disabbinaProdotto: basenameCfPortal + 'prodottoAbbinamento/disabbinaProdotto',
    abbinaProdotto: basenameCfPortal + 'prodottoAbbinamento/abbinaProdotto',
    getListaProdottoAbbinato: basenameCfPortal + 'prodottoAbbinamento/getListaProdottoAbbinato'
}


/*------------------------------------------------------------------------------------------------------------------ */
/* MONITORAGGIO */

export const basenameMonitoraggio = basenameHost + 'cfmonitoraggiobe/v1/'

export const urlMonitoraggio = {
    auth: basenameMonitoraggio + 'auth',
    getListMonitoraggioFarmacia730: basenameMonitoraggio + 'monitoraggio/getListMonitoraggioFarmacia730',
    getListMonitoraggioFarmaciaDettaglio730: basenameMonitoraggio + 'monitoraggio/getListMonitoraggioFarmaciaDettaglio730',
    getListMonitoraggioFarmaciaParametri: basenameMonitoraggio + 'monitoraggio/getListMonitoraggioFarmaciaParametri',
    addTicketHelpDesk: basenameMonitoraggio + 'monitoraggio/addTicketHelpDesk',
    getAnagraficaClientiMonitoraggioPlatinum: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioPlatinum',
    getAnagraficaClientiMonitoraggioDatabasePlatinum: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioDatabasePlatinum',
    getAnagraficaClientiMonitoraggioSpazioDiscoPlatinum: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioSpazioDiscoPlatinum',
    getAnagraficaClientiMonitoraggioBackupDatabasePlatinum: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioBackupDatabasePlatinum',
    getAnagraficaClientiMonitoraggioBancaDatiPlatinum: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioBancaDatiPlatinum',
    getOperatoriHelpDesk: basenameMonitoraggio + 'monitoraggio/getOperatoriHelpDesk',
    addTicketEsternoMonitoraggioHelpDesk: basenameMonitoraggio + 'monitoraggio/addTicketEsternoMonitoraggioHelpDesk',
    updateNote: basenameMonitoraggio + 'monitoraggio/updateNote',
    getAnagraficaClientiArt34: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiArt34',
    getAnagraficaClienti730: basenameMonitoraggio + 'monitoraggio/getAnagraficaClienti730',
    getAnagraficaClientiMonitoraggioTelepharma2: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioTelepharma2',
    getAnagraficaClientiContrattiSmart: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiSmart',
    getAnagraficaClientiTariffazione: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiTariffazione',
    getAnagraficaClientiInfoFarmacie: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiInfoFarmacie',
    getElencoParametriFarmacia: basenameMonitoraggio + 'monitoraggio/getElencoParametriFarmacia',
    getInfoFarmacia: basenameMonitoraggio + 'monitoraggio/getInfoFarmacia',
    getDCRSmart: basenameMonitoraggio + 'monitoraggio/getDCRSmart',
    getAnagraficaClientiMonitoraggioNetFramework: basenameMonitoraggio + 'monitoraggio/getAnagraficaClientiMonitoraggioNetFramework',
    getTipoAnomalia: basenameMonitoraggio + 'monitoraggio/getTipoAnomalia',
    setAnomalia: basenameMonitoraggio + 'monitoraggio/setAnomalia',
}

/*------------------------------------------------------------------------------------------------------------------ */
/* NOTE RILASCIO */
export const basenameNoteRilascio = basenameHost + 'cfomniaws/v1/noterilascio/note/'

export const urlNoteRilascio = {
    getNoteRilascio: basenameNoteRilascio + 'getNoteRilascio/',
   
}

/*------------------------------------------------------------------------------------------------------------------ */
/* ORDINI NSO BE */
export const basenameOrdiniNso = basenameHostServizi + 'cfordininsobe/v1/'

export const urlOrdiniNso = {
    auth: basenameOrdiniNso + 'auth',
    ordiniList: basenameOrdiniNso + 'ordineNSO/getList',
    getListStatoNSO: basenameOrdiniNso + 'ordineNSO/getListStatoNso',
    getListTipoDocumentoNso: basenameOrdiniNso + 'ordineNSO/getListTipoDocumentoNso',
    getListStoricoDocumentoNso: basenameOrdiniNso + 'ordineNSO/getListStoricoDocumentoNso',
}


/*------------------------------------------------------------------------------------------------------------------ */
/* CONTENITORI */
export const urlContenitori = {
    getList: basenameCfPortal + 'contenitori/getList',
    getListFarmacie: basenameCfPortal + 'contenitori/getListFarmacie',
    getDettaglio: basenameCfPortal + 'contenitori/getDettaglio',
    getContenitoreCompleto: basenameCfPortal + 'contenitori/getContenitoreCompleto',
    delete: basenameCfPortal + 'contenitori/delete',
    setDettaglio: basenameCfPortal + 'contenitori/setDettaglio',
    creaContenitore: basenameCfPortal + 'contenitori/creaContenitore',
    import: basenameCfPortal + 'contenitori/importContenitore',
    invia: basenameCfPortal + 'contenitori/inviaContenitore',
    importaDaFile: basenameCfPortal + 'contenitori/importContenitoreFile'
}


/*------------------------------------------------------------------------------------------------------------------ */
/* PREZZO PROMOZIONE */
export const urlPrezzoPromozione = {
    getListPrezzoPromozione: basenameCfPortal + 'prezzoPromozione/getListPrezzoPromozione',
    getListModificaPrezzoPromozione: basenameCfPortal + 'prezzoPromozione/getListModificaPrezzoPromozione',
    getListLogPrezzoPromozione: basenameCfPortal + 'prezzoPromozione/getListLogPrezzoPromozione',
    auth: basenameCfPortal + 'auth',
    setPrezzoPromozione: basenameCfPortal + 'prezzoPromozione/setPrezzoPromozione',
    setPrezzoPromozioneDitta: basenameCfPortal + 'prezzoPromozione/setPrezzoPromozioneDitta',
    getListInvioPrezzoPromozione: basenameCfPortal + 'prezzoPromozione/getListInvioPrezzoPromozione',
    deletePrezzo: basenameCfPortal + 'prezzoPromozione/deletePrezzo',
    copiaDaRiferimento: basenameCfPortal + 'prezzoPromozione/copiaDaRiferimento',
    inviaPrezzo: basenameCfPortal + 'prezzoPromozione/inviaPrezzo',
    salvaFarmaciaRiferimento: basenameCfPortalWs + 'FARMACIE.updateRiferimento',
    getFarmaciaRiferimento: basenameCfPortalWs + 'FARMACIE.findRiferimento',
    getFarmaciaRiferimentoRest: basenameCfPortal + 'farmacie/findRiferimento',
    updateFarmaciaRiferimento: basenameCfPortal + 'farmacie/updateRiferimento',
    importaDaFile: basenameCfPortal + 'prezzoPromozione/importPrezzo'
}



/*------------------------------------------------------------------------------------------------------------------ */
/* CONSULTAZIONE */
export const urlConsultazione = {
    auth: basenameCommon + 'auth',
    getVenditaProdotti: basenameCommon + 'vendite/getVenditaProdotti',
    getListven: basenameCommon + 'vendite/getListVendite',
    getListOrdini: basenameCommon + 'ordini/getListOrdini',
    getOrdine: basenameCommon + 'ordini/getOrdine',
    hasOrdineLibero: basenameCommon + 'carichi/hasOrdineLibero',
    getListCarichi: basenameCommon + 'carichi/getListCarichi',
    getCarico: basenameCommon + 'carichi/getCarico',
    getCausaliOrdine: basenameCommon + 'tipologie/getCausaliOrdine',
    getCausaliCarico: basenameCommon + 'tipologie/getCausaliCarico',
    getListFar: basenameCommon + 'farmacie/getListFar',
    getListFarSystem: basenameCommon + 'farmacie/getListFarSystem',
    getListFarDetail : basenameCommon + 'farmacie/getListFarDetail',
    getListFarDett :basenameCommon + 'farmacie/getListFarDettaglio',
    getTipPuntoVend :basenameCommon + 'farmacie/getTipoPuntoVendita',
    getTipolFarmacia :basenameCommon + 'farmacie/getTipologiaFarmacia',
    getListTipologiaFarmacia :basenameCommon + 'farmacie/getListTipologiaFarmacia',
    getListPuntoVendita :basenameCommon + 'farmacie/getListTipoPuntoVendita',
    getDisloca :basenameCommon + 'farmacie/getDislocazione',
    getListDisloc :basenameCommon + 'farmacie/getListDislocazione',
    getListTipoStrut :basenameCommon + 'farmacie/getListTipoStruttura',
    getTipoStru :basenameCommon + 'farmacie/getTipoStruttura',
    getListBacUtenz :basenameCommon + 'farmacie/getListBacinoUtenze',
    getBacUtenz :basenameCommon + 'farmacie/getBacinoUtenze',
    getListFasFatt :basenameCommon + 'farmacie/getListFasciaFatturato',
    getFasFatt :basenameCommon + 'farmacie/getFasciaFatturato',
    getListSupVen :basenameCommon + 'farmacie/getListSuperficieVendita',
    getSupVend :basenameCommon + 'farmacie/getSuperficieVendita',
    getListAsl :basenameCommon + 'farmacie/getListASL',
    getFarmGruppo :basenameCommon + 'farmacie/getFarmacieDelGruppo',
    getAnaFornRifFarm :basenameCommon + 'fornitori/getAnaFornitoreRiferimentoFar',
    addFornFatt :basenameCommon + 'fornitori/creaFornitoreFattura',

    updateFarmacie: basenameCommon + 'farmacie/update',
    getGestFar :basenameCommon + 'farmacie/getGesFar',
    getListFar4TraceRole :basenameCommon + 'farmacie/getListFar4TraceRole',
    getAndamentoAcquisti :basenameCommon + 'monitoraggi/getAndamentoAcquisti',
    getAndamentoVendite :basenameCommon + 'monitoraggi/getAndamentoVendite',
    getListTipologiaCluster: basenameCommon + 'clusterTipi/getList',
    postCluster: basenameCommon +  'clusterTipi/add',
    putClusterTipologia: basenameCommon +  'clusterTipi/update',
    deleteTipoCluster: basenameCommon + 'clusterTipi/delete',
    getListCluster: basenameCommon + 'cluster/getList',
    deleteCluster: basenameCommon + 'cluster/delete',
    postClusterObj: basenameCommon +  'cluster/add',
    putClusterObj: basenameCommon +  'cluster/update',
    getListFarmCluster: basenameCommon + 'cluster/get',
    getListOperatore: basenameCommon + 'operatore/getList',
    getListOperatoreDistinct: basenameCommon + 'operatore/getListDistinct',
    postOperatore: basenameCommon + 'operatore/add',
    putOperatore: basenameCommon + 'operatore/update',
    deletOperatore: basenameCommon + 'operatore/delete',
    getListOperatoreGruppo: basenameCommon + 'operatoreGruppo/getList',
    getOperatoreGruppo: basenameCommon + 'operatoreGruppo/getOperatore',
    postOperatoreGruppo: basenameCommon + 'operatoreGruppo/add',
    putOperatoreGruppo: basenameCommon + 'operatoreGruppo/update',
    deleteOperatoreGruppo: basenameCommon + 'operatoreGruppo/delete',
    checkOperatoreGruppo: basenameCommon + 'operatoreGruppo/check',
    getListProd: basenameCommon + 'prodotti/getList',
    postProd: basenameCommon + 'prodotti/add',
    putProd: basenameCommon + 'prodotti/update',
    deleteProd: basenameCommon + 'prodotti/delete',
    getListProdGruppo: basenameCommon + 'prodottiGruppo/getList',
    getProdottoGruppo: basenameCommon + 'prodottiGruppo/getProdottoGruppo',
    postProdGruppo: basenameCommon + 'prodottiGruppo/add',
    putProdGruppo: basenameCommon + 'prodottiGruppo/update',
    deleteProdGruppo: basenameCommon + 'prodottiGruppo/delete',
    checkProdGruppo: basenameCommon + 'prodottiGruppo/verificaCodiceProdotto',
    generaProdGruppo: basenameCommon + 'prodottiGruppo/generaCodiceProdotto',
    getListBanche : basenameCommon + 'banche/getListBanche',
    postBanca: basenameCommon + 'banche/addBanca',
    putBanca : basenameCommon + 'banche/updateBanca',
    deleteBanca : basenameCommon + 'banche/deleteBanca',
    getListConti : basenameCommon + 'banche/getListConti',
    postConto: basenameCommon + 'banche/addConto',
    putConto : basenameCommon + 'banche/updateConto',
    deleteConto : basenameCommon + 'banche/deleteConto',
    getListTipoCausaPrimaNota : basenameCommon + 'tipologie/getListCausaliPrimaNota',
    postTipoCausaPrimaNota : basenameCommon + 'tipologie/addCausalePrimaNota',
    getTipoCausaCausaPrimaNota : basenameCommon + 'tipologie/getCausaliOrdine',
    deleteTipoCausaPrimaNota : basenameCommon + 'tipologie/deleteCausalePrimaNota',
    getCausaCarico : basenameCommon + 'tipologie/getCausaliCarico',
    putTipoCausaPrimaNota : basenameCommon + 'tipologie/updateCausalePrimaNota',
    getListCondiProdo : basenameCommon + 'tipologie/getListCondizioniProdotto',
    getListPrimaNota : basenameCommon + 'primaNota/getList',
    postPrimaNota : basenameCommon + 'primaNota/add',
    putPrimaNota : basenameCommon + 'primaNota/update',
    deletePrimaNota : basenameCommon + 'primaNota/delete',
    getCausaleDefault : basenameCommon + 'primaNota/getCausaleDefault',
    setCausaleDefault : basenameCommon + 'primaNota/setCausaleDefault',
    getChart : basenameCommon + 'primaNota/getChart',
    getListDettVendiAcquiProdMese: basenameCommon + 'magazzini/getDettVenAcqProdottoMese',
    getListDettVendiProdMese: basenameCommon + 'magazzini/getDettVenProdottoMese',
    getListGiacenzaProd: basenameCommon + 'magazzini/getGiacenzaProdotti',
    getRiepilogoGiacenzaProdotti: basenameCommon + 'magazzini/getRiepilogoGiacenzaProdotti',
    getListGiacenzaProdFarma: basenameCommon + 'magazzini/getGiacenzaProdottiInFarmacia',
    getListGiacenzaProdMagazz: basenameCommon + 'magazzini/getGiacenzaProdottiInMagazzino',
    getDettVenditeProdottoGiorno : basenameCommon + 'magazzini/getDettVenditeProdottoGiorno',
    getListMagazCarichi: basenameCommon + 'magazzini/getListaCarichi' ,
    getReportValInvent: basenameCommon + 'magazzini/creaReportValorizzazioneInventario',
    calcolaCostoRicaricato: basenameCommon + 'magazzini/calcolaCostoRicaricato',
    findProdottiDitta: basenameCommon + 'prodotti/findDitta',
    getListSettInven : basenameCommon + 'prodotti/getListSettoreInventario',
    getListGruppoMerce: basenameCommon + 'prodotti/getListGruppoMerceologico',
    getListCate: basenameCommon + 'prodotti/getListCategory',
    getListCondProd: basenameCommon + 'prodotti/getListCondizioniProdotto',
    updateTrasfe: basenameCommon + 'trasferimenti/salvaTrasferimento',
    getListAzienda : basenameCommon + 'aziende/readList',
    updateAzienda : basenameCommon + 'aziende/update',
    getListFatture : basenameCommon + 'fatture/getList',
    getCarichiDett : basenameCommon + 'ordiniProposta/getCarichiDett',
    getAnaGruppo : basenameCommon + 'gruppi/getGruppo',
    updateAnaGruppo : basenameCommon + 'gruppi/update',
    getListComuni : basenameCommon + 'comuni/getList',
    getListComune : basenameCommon + 'comuni/getComune', 
    getListMagMovimenti : basenameCommon + 'movimentazione/cercaMovimentazione',
    getListMonitFarmacie :  basenameCommon + 'monitoraggi/getStatoFarmacieLite',    
    getListTipoPagame :  basenameCommon + 'opzioniTipo/getListTipoPagamento', 
    getListScad: basenameCommon + 'scadenzario/getList',
    reportScadenzario: basenameCommon + 'scadenzario/report?',
    updateScadenzarioNota :  basenameCommon + 'scadenzario/updateNota',
    updateScadenza :  basenameCommon + 'scadenzario/updateScadenza',
    updateScadenzarioPagamento :  basenameCommon + 'scadenzario/updatePagamento',
    updateScadeMultiPagamento: basenameCommon + 'scadenzario/updateMultiPagamento',  
    inviaFlussoScadenzario: basenameCommon + 'scadenzario/invia',
    getListPagamenti: basenameCommon + 'scadenzario/getListPagamenti',
     
    deletePagamento: basenameCommon + 'scadenzario/deletePagamento',
    deleteScadenza: basenameCommon + 'scadenzario/deleteScadenza',
    inviaProdGruppo: basenameCommon + 'prodottiGruppo/inviaProdotto',
    getStatusMobileFarm : basenameCommon + 'mobile/getStatus',
    getListiniAcqui : basenameCommon + 'listini/getConsultazioneListini',
    getGrupMercProd : basenameCommon + 'listini/getGrpMercProd',
    getForniFarm: basenameCommon + 'listini/getFornitoriFarmacia',
    getListiniOfferte: basenameCommon + 'listini/getListiniOfferte',
    logInvioProdottoGruppo: basenameCommon + 'prodottiGruppo/logInvioProdotto',
    riepilogoInvioProdottoGruppo: basenameCommon + 'prodottiGruppo/riepilogoInvioProdotto',
    getListUtenti :  basenameCommon + 'utenti/getList',
    addUtente :  basenameCommon + 'utenti/add',
    updateUtente :  basenameCommon + 'utenti/update',
    deleteUtente :  basenameCommon + 'utenti/delete',
    resetUtente :  basenameCommon + 'utenti/reset',
    getSessioniUtenti :  basenameCommon + 'utenti/getSessioni',
    createProfili :  basenameCommon + 'profili/createProfile',
    getProfiliUtenti :  basenameCommon + 'profili/getProfiles',
    deleteProfili :  basenameCommon + 'profili/deleteProfile',
    saveProfili :  basenameCommon + 'profili/saveProfile',
    readProfili :  basenameCommon + 'profili/readProfile',
    renameProfilo :  basenameCommon + 'profili/renameProfile',

    getDataFile : basenameCommon + 'export/getDatiFile',
    getReadFiles : basenameCommon + 'export/readFilesPerGruppoeTipoDati',
    deleteDataFile : basenameCommon + 'export/deleteFile',
    importFile : basenameCommon + 'export/importFiles',
    getreadByGroupFatt : basenameCommon + 'export/readByGroupFatture',
    deleteFileFatt : basenameCommon + 'export/deleteFileFatture',
    
    getTipiFatture : basenameCommon + 'fatture/getTipiFatture',
    getListFatt : basenameCommon + 'fatture/getList',
    findFatture : basenameCommon + 'fatture/find',
    updateNoteFatture : basenameCommon + 'fatture/updateNote',
    updateFarmRif : basenameCommon + 'fatture/updateFarRif',
    deleteFatture : basenameCommon + 'fatture/deleteFattura',
    addFatture : basenameCommon + 'fatture/pushFattura',

    deleteCausaNascostaPN : basenameCommon + 'tipologie/deleteCausaleNascostaPrimaNota',
    addCausaNascostaPN : basenameCommon + 'tipologie/addCausaleNascostaPrimaNota',
    getListCausaNascostaPN: basenameCommon + 'tipologie/getListCausaliNascostePrimaNota',

    checkAbilitazioneControlloFatture: basenameCommon + 'fatture/hasControlloFatture',
    importaInControlloFattura: basenameCommon + 'fatture/importaInControlloFattura',
    getListFattAbb: basenameCommon + 'fatture/getListFattureAbbinamenti',
    getListFattAbbDett: basenameCommon + 'fatture/getListFattureAbbinamentiDettaglio',
    getListFattureNote: basenameCommon + 'fatture/getListFattureNote',

    getListSegnalazione: basenameCommon + 'fatture/getListSegnalazione',
    sendQuery: basenameCommon + "sendquery/send",
    readQueryResult: basenameCommon + "sendquery/read",
}

/*------------------------------------------------------------------------------------------------------------------ */
/* CONFIGURAZIONI */
export const urlConfigurazioni = {
    auth: basenameCommon + 'auth',
    getConfigurazioneDatiAltreFarmacie: basenameCommon + 'configurazioni/getConfigurazioneDatiAltreFarmacie',
    setConfigurazioneDatiAltreFarmacie: basenameCommon + 'configurazioni/setConfigurazioneDatiAltreFarmacie',
    getTipoOrdinamentoDatiAltreFarmacie: basenameCommon + 'configurazioni/getTipoOrdinamentoDatiAltreFarmacie'
}



/*------------------------------------------------------------------------------------------------------------------ */
/* BUSINESS INTELLLIGENCE */
export const urlBusinessIntelligence = {

    auth: basenameCommon + 'auth',
    getSelloutAssistant: basenameCommon + 'bi/getSelloutAssistant',

}


/*------------------------------------------------------------------------------------------------------------------ */
/* ORDINI B2B */
export const urlB2B = {

    auth: basenameCommon + 'auth',
    getParametri: basenameCommon + 'b2b/getParametri',
    setParametri: basenameCommon + 'b2b/setParametri',
    getListini: basenameCommon + 'b2b/getListini',
    importListino: basenameCommon + 'b2b/importListino',
    deleteListino: basenameCommon + 'b2b/deleteListino',
    getListino: basenameCommon + 'b2b/getListinoDettaglio',
    getListFornitore: basenameCommon + 'b2b/getListFornitore',
    getListinoProdottoDettaglio: basenameCommon + 'b2b/getListinoProdottoDettaglio',
    getFarmacie: basenameCommon + 'b2b/getFarmacie',
    getListFarB2B: basenameCommon + 'b2b/getListFarB2B',
    getOrdineB2B: basenameCommon + 'b2b/getOrdineB2B',
    getListFarFornitoreB2B: basenameCommon + 'b2b/getListFarFornitoreB2B',
    getListOrdiniB2B: basenameCommon + 'b2b/getListOrdiniB2B',
    getListStatoB2B: basenameCommon + 'b2b/getListStatoB2B',
    setStatoOrdineB2B: basenameCommon + 'b2b/setStatoOrdineB2B'
}


/*------------------------------------------------------------------------------------------------------------------ */
/* GESTIONE UDI */

export const basenameUdi = basenameHostServizi + 'cfudi/v1/'
export const basenameUdiV2 = basenameHostServizi + 'cfudi/v2/'

//export const basenameUdi = basenameHost + 'cfudi/v1/'

export const urlGestioneUdi = {

    auth: basenameUdi + 'auth',

    signUp: basenameUdi + 'login/signup',
    getList: basenameUdi + 'udi/getList',
    verificaProdotto: basenameUdi + 'udi/isRegistrare',
    salvaRegistrazione: basenameUdi + 'udi/setCodice',
    delete: basenameUdi + 'udi/delete',

}

export const urlGestioneUdiV2 = {

    auth: basenameUdiV2 + 'auth',

    signUp: basenameUdiV2 + 'login/signup',
    getList: basenameUdiV2 + 'udi/getList',
    verificaProdotto: basenameUdiV2 + 'udi/isRegistrare',
    salvaRegistrazione: basenameUdiV2 + 'udi/setCodice',
    delete: basenameUdiV2 + 'udi/delete',
    recuperoPassword: basenameUdiV2 + 'login/recuperoPassword'
}

/*------------------------------------------------------------------------------------------------------------------ */
/* COUPON */

export const basenameFidelity = basenameHost + 'cffidelitybe/v1/'
export const urlFidelityBe = {

    auth: basenameFidelity + 'auth',

    getList: basenameFidelity + 'fidelitycoupon/getList',
    getListMovimenti: basenameFidelity + 'fidelitycoupon/getListMovimenti',
    getGenericsInfor: basenameFidelity + 'fidelitycoupon/getGenericsInfo2',
    updateCreateMode: basenameFidelity + 'fidelitycoupon/updateCreateMode',
    updateCalcType: basenameFidelity + 'fidelitycoupon/updateCalcType',
    revocaCoupon: basenameFidelity + 'fidelitycoupon/revocaCoupon',
    getListTagli: basenameFidelity + 'fidelitycoupon/getListTagli',
    insertTaglio: basenameFidelity + 'fidelitycoupon/insertTaglio',
    getListFasceFidelity: basenameFidelity + 'fidelitycoupon/getListFasceFidelity2',
    insertFasciaFidelity: basenameFidelity + 'fidelitycoupon/insertFasciaFidelity',
    updateFidelityFascia: basenameFidelity + 'fidelitycoupon/updateFidelityFascia',
    getListFasce: basenameFidelity + 'fidelitycoupon/getListFasce',
    getClusterPharmacies: basenameFidelity + 'fidelitycoupon/getClusterPharmacies'
}