import DataGrid, { FilterRow, HeaderFilter, Selection, Paging, Pager, Export, SearchPanel, MasterDetail, Column, Editing, KeyboardNavigation,ColumnFixing } from 'devextreme-react/data-grid';
import { dxToolbarOptions } from 'devextreme/ui/toolbar'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RestServices } from '../../services/restServices'
import { urlLayoutTabelle } from '../../contenuti/costanti'
import { setStatoTabella } from '../../funzioni/operazioniSuTabelle';
import LoadPanel from 'devextreme-react/load-panel';
import { PageSize } from 'devextreme-react/diagram';
import './styleNewBusin.css';


function convertDateFormat(data:any) {
    // Assicurarsi che la stringa sia lunga 6 caratteri (AAAA + MM)
    let dateString = String(data.value)
    if(data.column.dataField == 'ANNOMESE'){
        
          // Estrai l'anno e il mese
          const year = dateString.substring(0, 4);
          const month = dateString.substring(4, 6);
        
          // Combina il mese e l'anno nel nuovo formato
          return `Mese: ${month}/${year}`;
    }else{
        return `${dateString}`;

    }
   
  }

export const NewTabella = (props: {
    idTabella: string,
    sync?: boolean
    looading?: boolean,
    dataSource: any,
    colonne: any,
    filterRow?: boolean,
    headerFilter?: boolean,
    searchPanel?: boolean,
    onclickRow?: (selectedRowsData: any) => void,
    selectedValue?: (selectedRowsData: any) => void,
    onRowUpdated?: (row: any) => void,
    onRowUpdating?: (row: any) => void,
    exportT?: any
    columnC?: boolean
    activeSaver?: boolean
    multyp?: boolean
    keyExpr?: string
    defaultSelectedRowKeys?: any
    selectedRowKeys?: any
    summary?: any
    height?: string | number,
    width?: string | number,
    tabelDett?: boolean,
    allowColumnResizing?: boolean,
    allowColumnReordering?: boolean,
    hideColumnHeaders?: boolean,
    hidePager?: boolean,
    componetPlus?: any,
    specialPd?: number,
    onRowPrepared?: any,
    onCellPrepared?: any;
    filteredDataShow?: (istanza: any) => void,
    tabReference?: (instance: any) => void,
    onEditingStart?: any,
    pagination?: number,
    wordWrapEnabled?: boolean,
    rowAlternation?: boolean,
    onExporting?: any,
    onFocusedCellChanged?: any,
    pageSize?: number,
    paging?: boolean,
    toolbar?: boolean,
    hide?: boolean,
    showPageSizeSelector?: boolean
}) => {

    const tabRef = useRef<any>(null);
    const [carica, setCarica] = useState(false)
    const [pagination, setPagination] = useState(50);
    const [enablePagination, setEnablePagination] = useState(true);
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);

    useEffect(() => {
        if (tabRef.current)
            if (props.tabReference)
                props.tabReference(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props && props.pagination) setPagination(props.pagination);
    }, [props.pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.multyp,
            showCheckBoxesMode: 'always'
        }
    }, []);

    useEffect(() => {
        if (props && props.hidePager === false) setEnablePagination(false);
    }, [props.hidePager])

    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);

    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);

    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.headerFilter
        }
    }, []);

    const columnChooserOptions = useMemo(() => {
        return {
            enabled: props.columnC
        }
    }, [])

    const editing = useMemo(() => {
        return {
            mode: "cell",
            allowUpdating: true
        }
    }, [])


    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();

                        setStatoTabella(props.idTabella, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                            // console.log("ripristina stato");
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.idTabella);
                        }
                    }
                }
            )
        }
    }

    const getStatoTabella = () => {
        if (!props.idTabella) return;
        RestServices().rest('POST', props.idTabella, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        setCarica(true)
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {

            if (result === true) {
                setCarica(false)
            }
        });
    }


    const renderGroupCell = (cellData:any) => {
        console.log('mostro dati group');
        console.log(cellData);
        
        
        return (
          <div style={{ backgroundColor: '#f5f5f5', padding: '0px', borderRadius: '5px',margin:0 }}>
            <p style={{color:'black',fontSize:18,margin:0}}> { convertDateFormat(cellData)} {cellData.groupContinuesMessage ? <span style={{fontSize:16,fontWeight:'lighter'}}>Continua nella {cellData.groupContinuesMessage}</span> : null}</p>
          </div>
        );
      };

    useEffect(() => {
        getStatoTabella();
    }, [props.idTabella]);

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])


    const allowedPageSizes = [5, 10, 20, 50];

    return (
        <>
            <div id='tabellaCss'>
            {props.looading ? (
                <div id='qui'>
                    <LoadPanel
                        shadingColor="rgba(0,0,0,0.4)"
                        shading={false}
                        visible={true}
                        showPane={true}
                        showIndicator={true}
                        position={{ of: '#qui' }}
                    />
                </div>
            ) : (
                <div style={{ marginTop: 2, padding: props.specialPd ? props.specialPd : 10, background: '#fff' }}>
                    {props.dataSource.length == 1500 ? (
                        <div >
                            <p>*i dati sono stati limitati a 1500 elementi, affina la ricerca per dati migliori</p>
                        </div>
                    ) : null}
                    {carica ? (
                        null
                    ) : (
            <DataGrid
                ref={tabRef}
                id={props.idTabella}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                keyExpr={props.keyExpr ? props.keyExpr : undefined}
                rowAlternationEnabled={props.rowAlternation}
                columnAutoWidth={true}
                height={props.height ? props.height : '70vh'}
                dataSource={props.dataSource}
                filterSyncEnabled={true}
                filterPanel={filterPanelOptions}
                defaultFilterValue={null}
                columnChooser={columnChooserOptions}
                allowColumnReordering={props.allowColumnReordering}
                allowColumnResizing={props.allowColumnResizing}
                onToolbarPreparing={props.toolbar == true ? onToolbarPreparing : undefined}
                onRowClick={props.onclickRow}
                onRowUpdated={props.onRowUpdated ? props.onRowUpdated : () => console.log()}
                onRowPrepared={props.onRowPrepared}
                onCellPrepared={props.onCellPrepared}
                selectedRowKeys={props.selectedRowKeys ? props.selectedRowKeys : undefined}
                onEditingStart={props.onEditingStart}
                onSelectionChanged={props.selectedValue}
                wordWrapEnabled={wordWrapEnabled}
                width={'100%'}
                repaintChangesOnly={true}
                export={props.exportT}
                //paging={paginationOptions}
                paging={{ enabled: props.paging }}
                onRowUpdating={props.onRowUpdating}
                visible={!props.hide }
                onContentReady={props.filteredDataShow ? (x) => { if (props.filteredDataShow) props.filteredDataShow(x) } : undefined}
                onExporting={props.onExporting}
                                        onFocusedCellChanged={props.onFocusedCellChanged}
                scrolling={{
                    useNative: false,
                    showScrollbar: 'always'
                }}
                                    >
                {props.summary ? props.summary : null}
                <Paging defaultPageSize={props.pageSize} />
                <Pager
                    visible={props.hidePager ? false : true}
                    showPageSizeSelector={props.showPageSizeSelector !== undefined ? props.showPageSizeSelector : true}
                    allowedPageSizes={allowedPageSizes}
                    showInfo={true} />
                <FilterRow visible={props.filterRow ? true : false} />
                <HeaderFilter visible={props.headerFilter ? true : false} />
                <Export enabled={true} />
                <SearchPanel visible={props.searchPanel ? true : false}
                    width={150}
                    placeholder="Cerca..." />
                <Selection
                    mode={props.multyp ? "multiple" : 'single'}
                />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    selectTextOnEditStart={true}                                        />
                {props.tabelDett ? (
                    <MasterDetail
                        enabled={true}
                        component={props.componetPlus}
                    />
                ) : null}

                {props.colonne.map((colonna: any) => {
                    //console.log(colonna);
                    return <Column
                        sortOrder={colonna.sortOrder ? colonna.sortOrder : null}
                        allowEditing={colonna.editC ? colonna.editC : false}
                        dataField={colonna.dataField}
                        caption={colonna.caption}
                        cellRender={colonna.render ? colonna.render : null}
                        width={colonna.width ? colonna.width : null}
                        format={colonna.format ? colonna.format : null}
                        dataType={colonna.dataType ? colonna.dataType : null}
                        customizeText={colonna.customizeText ? colonna.customizeText : null}
                        type={colonna.type}
                        buttons={colonna.buttons ? colonna.buttons : null}
                        visible={colonna.visible != undefined ? colonna.visible : true}
                        fixed={colonna.fixed != false ? colonna.fixed : false}
                        groupIndex={colonna.groupIndex != null ? colonna.groupIndex : null}
                        groupCellRender={renderGroupCell}

                    />
                }
                )}
                                </DataGrid>
                        )}

                    </div>
                )}  
            </div>
        </>
        )
}