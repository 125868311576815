import notify from "devextreme/ui/notify";
import { OggettoInterface } from "../../../interfaces/generiche";
import { RestServices } from "../../../services/restServices";
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { Loading } from "../../../widget/Notifications";
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { urlConsultazione } from "../../costanti";
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import { TabellaSempliceInterface } from "../../../interfaces/tabelle";
import { TabellaSemplice } from "../../../widget/Tabelle";


export const DialogRiepilogoInvioProd = (props: {
    dialogRiepilogoInvioProdOpen: boolean,
    setDialogRiepilogoInvioProdOpen: (dialogRiepilogoInvioProdOpen: boolean) => void,
    idProdottoGruppo: number[] | string[] | undefined,
    handleCloseDialogRiepilogoInvioProd: () => void
    message: string,
    codProd: string | undefined,
    codEan: string | undefined,
    descrizione: string | undefined,
    functionNext: () => void,
}) => {
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoRiepilogo, setElencoRiepilogo] = useState<any>();


    const handleOpen = () => {

        riepilogoInvioProdotto();

    }


    const riepilogoInvioProdotto = () => {

        //console.log(props)

        if (props.idProdottoGruppo) {
            setIsVisible(true)
            const url = urlConsultazione.riepilogoInvioProdottoGruppo;
            const param = {
                idProdottoGruppo: props.idProdottoGruppo
            }
            RestServices().rest('POST', param, url).then(result => {
                if (result) {
                    setIsVisible(false)
                    if (result.esito) {
                        setElencoRiepilogo(result.response);
                    }

                }
            })
        }

    }

    const propsTabRiepilogoInvioProdotto: TabellaSempliceInterface = {
        id: "riepilogoInvioProdotto",
        dataSource: elencoRiepilogo,
        filterRow: true,
        pagination: 5,
    }

    const columnsRiepilogoInvioProdotto = CreaColonne([
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Note", dataField: "note" },
    ])

    const columnsRiepilogoInvioProdotti = CreaColonne([
        { caption: "Cod.Prod", dataField: "codiceProdotto" },
        { caption: "Prodotto", dataField: "prodotto" },
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Note", dataField: "note" },
    ])

    const handleClose = () => {
        props.setDialogRiepilogoInvioProdOpen(false);
    }

    return (
        <>
            <Modal
                show={props.dialogRiepilogoInvioProdOpen}
                onHide={props.handleCloseDialogRiepilogoInvioProd}
                dialogClassName="dialogTrasmettiOrdLib"
                centered={true}
                backdrop="static"
                onEntered={handleOpen}
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdLib">
                        <h3>{props.message}</h3>
                        &nbsp;
                        {props.idProdottoGruppo && props.idProdottoGruppo.length == 1 && props.codProd && <Row>
                            <Col>
                                <h6>CODICE: {props.codProd}</h6>
                            </Col>
                            <Col>
                                <h6>DESRIZIONE: {props.descrizione}</h6>
                            </Col>
                            <Col>
                                <h6>EAN: {props.codEan}</h6>
                            </Col>
                        </Row>
                        }
                        

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row id="tabellaRiepilogoEmail">

                        <TabellaSemplice propsTabella={propsTabRiepilogoInvioProdotto} columns={props.idProdottoGruppo && props.idProdottoGruppo.length == 1 && props.codProd ? columnsRiepilogoInvioProdotto : columnsRiepilogoInvioProdotti} />

                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="modalConfermaOk" variant="success" onClick={props.functionNext}>
                        Invia
                    </Button>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}