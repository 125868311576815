import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import registratore from '../../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon5.png';
import documento from '../../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon6.png';
import chiusura from '../../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon7.png';
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { NewTabella } from "../../../widget/nuova_tabella/newVersion";
import '../style.css';

export const PopUp_Dettagli = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    typeData: number,
    dataSource: any[],
    isDialog: boolean,
    setIsDialog: (isDialog: boolean) => void,
}) => {

    const [columns, setColumns] = useState<any[]>([]);

    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);


    const closeDialog = () => {
        props.setIsDialog(false);
    }

    const typeColumns = (typeColumns: number) => {

        if (typeColumns === 1)
            setColumns([
                { caption: "Codice Farmacia", dataField: "codice", width: "auto", },
                { caption: "Descrizione Farmacia", dataField: "descrizione", width: "auto", },
                { caption: "Marca", dataField: "marca", width: "auto", },
                { caption: "Modello", dataField: "modello", width: "auto", },
                { caption: "Matricola", dataField: "matricola", width: "auto", },
            ]);

        else if (typeColumns === 2)
            setColumns([
                { caption: "Codice Farmacia", dataField: "codice", width: "auto", },
                { caption: "Descrizione Farmacia", dataField: "descrizione", width: "auto", },
                { caption: "Cod. Cliente", dataField: "codiceCliente", width: "auto", },
                { caption: "Cod. Fiscale", dataField: "codiceFiscale", width: "auto", },
                { caption: "Data", dataField: "data", dataType: "date", format: { type: "dd/MM/yyyy", }, width: "auto", },
                { caption: "Tipo Pagamento", dataField: "tipoPagamento", width: "auto", },
                { caption: "N. Documento", dataField: "numeroDocumento", width: "auto", },
                { caption: "Tipo Vendita", dataField: "tipoVendita", width: "auto", },
            ]);

        else if (typeColumns === 3)
            setColumns([
                { caption: "Codice Farmacia", dataField: "codice", width: "auto", },
                { caption: "Descrizione Farmacia", dataField: "descrizione", width: "auto", },
                { caption: "Data Ora", dataField: "data", dataType: "date", format: { type: "dd/MM/yyyy HH:ss:mm", }, },
                { caption: "Importo", dataField: "importoChiusura", format: { type: "currency", precision: 2, currency: "EUR" }, },

            ]);

    }

    useEffect(() => {
        typeColumns(props.typeData);
    }, [props.typeData]);


    return (
        <>
            <Modal
                show={props.isDialog}
                onHide={closeDialog}
                dialogClassName="dialogDettagli"
                size="xl"
                centered={false}
                backdrop={true}
            >
                <Modal.Header style={{
                    backgroundColor:
                        props.typeData === 1 ? '#5ab267'
                            : props.typeData === 2 ? '#ffbe40'
                                : props.typeData === 3 ? '#9129e3'
                                    : '#007bff',
                    color: '#fff',
                    borderTopLeftRadius: '50px',
                    borderTopRightRadius: '50px'
                }}>

                    <Modal.Title style={{ width: '100%', textAlign: 'center' }}>
                        <h2><strong>Maggiori Informazioni</strong></h2>
                    </Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <Row>

                        <Col sm="12">
                            <NewTabella
                                idTabella='dettagli'
                                title={
                                    <>
                                        {props.typeData === 1 ? (

                                            <>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="mr-4">Registratori di cassa</span>
                                                    <img src={registratore} alt="prova" width="10%" />
                                                </div>

                                                <br />
                                                <br />
                                            </>

                                        ) : props.typeData === 2 ? (

                                            <>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="mr-4">Documenti Emessi nel periodo: {lastMonth.toLocaleDateString()} | {today.toLocaleDateString()}</span>
                                                    <img src={documento} alt="prova" width="10%" />
                                                </div>

                                                <br />
                                                <br />

                                            </>

                                        ) : props.typeData === 3 ? (

                                            <>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <span className="mr-4">Chiusure Effettuate nel periodo: {lastMonth.toLocaleDateString()} | {today.toLocaleDateString()}</span>
                                                    <img src={chiusura} alt="prova" width="10%" />
                                                </div>

                                                <br />
                                                <br />

                                            </>

                                        ) : null}
                                    </>

                                }
                                sync
                                colonne={columns}
                                dataSource={props.dataSource}
                                filterRow
                                searchPanel={true}
                                exportT={{ enabled: true, fileName: "Info" }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                height="55vh"
                                wordWrapEnabled={true}
                            />
                        </Col>
                    </Row>


                </Modal.Body>

                <Modal.Footer style={{
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px'
                }}>

                    <Button variant="danger" onClick={closeDialog}>
                        Chuidi
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )

}