import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
export default function CustomTitle(data: any) {
    return (
        <>
            <Row>
                <Col sm="12" className="infoProdottoAbbinamento">
                    <h1><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Informazioni" />     Info</h1>
                </Col>
            </Row>
        </>

    );
}
