import React, { useState, useEffect } from "react"; 
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { OggettoInterface } from "../../../interfaces/generiche";
import { NumberBoxFiltro } from "../../../widget/Editor";
import { SelezioneMultiplaFarmacie } from '../../../widget/Select';
import '.././style.css';

interface AssegnaDialogProps {
    datiFarmacie: ListaFarmacieInterface[];
    isDialogImpostazioniAssegna: boolean;
    setIsDialogImpostazioniAssegna: (isOpen: boolean) => void;
    onAssegnaClick: (selectedValues: { sconto?: number; margine?: number; idFar?: string[] }, columnsToHighlight: string[]) => void;
    handleCloseDialogAssegna: () => void;
    impostaFiltri: (newFiltro: OggettoInterface) => void;
    filtri: OggettoInterface; 
    functionNext: () => void;
}

export const AssegnaDialog: React.FC<AssegnaDialogProps> = (props) => {
    const { filtri, impostaFiltri } = props;
    const [isScontoSet, setIsScontoSet] = useState(false);
    const [isMargineSet, setIsMargineSet] = useState(false);

    useEffect(() => {
        setIsScontoSet(filtri.sconto !== undefined && filtri.sconto !== "" && filtri.sconto !== 0);
        setIsMargineSet(filtri.margine !== undefined && filtri.margine !== "" && filtri.margine !== 0);
    }, [filtri]); 

    const isPharmacySelected = Array.isArray(filtri?.idFar) && filtri.idFar.length > 0;

    const handleClose = () => {
        props.setIsDialogImpostazioniAssegna(false);
    };

    const handleAssegnaClick = () => {
        const selectedValues = {
            sconto: filtri.sconto,
            margine: filtri.margine,
            idFar: filtri.idFar,
        };

        const columnsToHighlight = [];

        if (filtri.sconto !== undefined && filtri.sconto !== null && filtri.sconto !== 0) {
            columnsToHighlight.push("ppu", "mar", "sct");
        } else if (filtri.margine !== undefined && filtri.margine !== null && filtri.margine !== 0) {
            columnsToHighlight.push("ppu", "mar", "sct");
        } else if (filtri.idFar && filtri.idFar.length > 0) {
            columnsToHighlight.push("ppu", "mar", "sct");
        }

        props.onAssegnaClick(selectedValues, columnsToHighlight);
        props.setIsDialogImpostazioniAssegna(false);
    };

    const handleImpostaFiltri = (newFiltro: OggettoInterface) => {
        const updatedFiltro = { ...newFiltro };
        for (const key in updatedFiltro) {
            if (updatedFiltro[key] === null) {
                updatedFiltro[key] = 0;
            }
        }

        props.impostaFiltri(updatedFiltro);
    };

    const colonneGridGeneriche = CreaColonne([
        { dataField: "ID_FAR", caption: "id", visible: false },
        { dataField: "DESCRIZIONE", caption: "descrizione", sortOrder: "asc" },
        { dataField: "CODICE", caption: "codice", width: 120 }
    ]);

    return (
        <Modal
            show={props.isDialogImpostazioniAssegna}
            onHide={handleClose}
            dialogClassName="dialogAssegna"
            size="lg"
            centered={false}
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title className="titoloNewOrdineLibero">
                    <h2 className="main-title"> Assegna Nuovo Prezzo Farmacia </h2>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="rigaCreaNuovoOrdLib">
                    <Col sm="5">
                        <span style={{ fontSize: 12 }}>Copia prezzo farmacia da</span>
                        <SelezioneMultiplaFarmacie
                            dataSource={props.datiFarmacie}
                            id="idFar"
                            impostaFiltri={handleImpostaFiltri} 
                            column={colonneGridGeneriche}
                            selection="single"
                            primaSelezionata={false}
                            valueGrid={filtri?.idFar || []}
                            placeholder={"[ DEFAULT ]"}
                            disabled={isScontoSet || isMargineSet}
                        />
                    </Col>
                </Row>
                <Row className="rigaCreaNuovoOrdLib">
                    <Col sm="5">
                        <span style={{ fontSize: 12 }}>Percentuale sconto</span>
                        <NumberBoxFiltro
                            id={"sconto"}
                            impostaFiltri={handleImpostaFiltri} 
                            max={100}
                            step={0.01}
                            placeholder="0.00"
                            disabled={filtri.sconto === null ? false : (isPharmacySelected || isMargineSet)}
                            value={filtri.sconto || ""}
                        />
                    </Col>
                </Row>
                <Row className="rigaCreaNuovoOrdLib">
                    <Col sm="5">
                        <span style={{ fontSize: 12 }}>Percentuale margine</span>
                        <NumberBoxFiltro
                            id={"margine"}
                            impostaFiltri={handleImpostaFiltri} 
                            max={100}
                            step={0.01}
                            placeholder="0.00"
                            disabled={filtri.margine === null ? false : (isPharmacySelected || isScontoSet)}
                            value={filtri.margine || ""}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="PopFirst-btn" variant="success" onClick={handleAssegnaClick}>
                    Assegna
                </Button>
                <button className="PopSecond-btn" onClick={handleClose}>
                    Chiudi
                </button>
            </Modal.Footer>
        </Modal>
    );
};