import React, { useEffect, useRef, useState } from "react";
import SelectBox from "devextreme-react/select-box";

export enum typeShow {
  euro,
  percento,
}

export interface cardC {
  testo?: string;
  subTesto?: string;
  valore?: string;
  subValore?: string;
  testoPop?: string;
  second?: boolean;
  starter?: boolean;
  typeShowValue?: typeShow;
  typeShowSubValue?: typeShow;
}

export const MenuCruscottoOperativo = ({}) => {

  const selectBoxOptions = [
    {
      id: 1,
      text: "Analisi vendite per Ditta ",
      url: "/app/businesslab/evolution/AnalisiVenditeDitta",
    },
    {
      id: 2,
      text: "Analisi vendite per Category (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiVenditeCategory",
    },
    {
      id: 3,
      text: "Analisi vendite per settore Inventario (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiVenditeSettoreInventario",
    },
    {
      id: 4,
      text: "Analisi Vendite per Gruppo Merceologico (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiVenditeGruppoMerceologico",
    },
    {
      id: 5,
      text: "Analisi Acquisti per Category (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiAcquistiCategory",
    },
    {
      id: 6,
      text: "Analisi Acquisti per Gruppo Merceologico (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiAcquistiGruppoMerceologico",
    },
    {
      id: 7,
      text: "Analisi Acquisti per Settore Inventario (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiAcquistiPerSettoreInventario",
    },
    {
      id: 8,
      text: "Analisi Acquisti per Ditta",
      url: "/app/businesslab/evolution/AnalisiAcquistiPerDitta",
    },
    {
      id: 9,
      text: "Analisi fornitori Ditta & Gruppo Merceologico",
      url: "/app/businesslab/evolution/AnalisiFornitoriDittaGruppoMerceologico",
    },
    {
      id: 10,
      text: "Analisi margini medi",
      url: "/app/businesslab/evolution/AnalisiMarginiMedi",
    },
    {
      id: 11,
      text: "Analisi vendite sotto margine",
      url: "/app/businesslab/evolution/AnalisiVenditaSottoMargineOperativo",
    },
    {
      id: 12,
      text: "Analisi vendite per fascia oraria",
      url: "/app/businesslab/evolution/AnalisiVenditaFasciaOrariaOperativo",
    },
    {
      id: 13,
      text: "Analisi prodotti fermi",
      url: "/app/businesslab/evolution/AnalisiProdottiFermi",
    },
  ];

  const [value,setValue] = useState<number | null>()

  useEffect(() => {
    // Recupera l'URL corrente
    const currentUrl = new URL(window.location.href);
    var pathname = currentUrl.pathname;
    
    const pathSegments = currentUrl.pathname.split('/');
    const cruscottoDirezionale = pathSegments[pathSegments.length - 1];

    //alert(pathname)

    const index = selectBoxOptions.findIndex(option => option.url === pathname);

    
    if(index+1){
      setValue(index+1)
    }

    // Puoi ora gestire l'URL come desideri
  });


 

  const defaultValue = selectBoxOptions[1];

  const handleDropdownChange = (e: any) => {
    const urlToUse = selectBoxOptions[e.value-1].url
    setValue(e.value)
   window.open(urlToUse, "_self");
  };
    return (
        <div style={{ marginBottom: 15, marginLeft: -10, marginRight: -10 }}>
      <label> Selezionare il cruscotto operativo: </label>
      <div style={{ fontSize: "14px" }}>
        <SelectBox
          dataSource={selectBoxOptions}
          displayExpr="text"
          valueExpr="id"
          value={value}
          onValueChanged={handleDropdownChange}
        />
      </div>
    </div>
  );
};
