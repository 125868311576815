import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';
import { ElencoContenitoriInterface } from '../../interfaces/contenitore';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { OggettoInterface } from '../../interfaces/generiche';
import { OnRowDblClickInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { DialogConferma, Loading } from '../../widget/Notifications';
import { TabellaCompleta, TabellaCompletaAlternate } from '../../widget/Tabelle';
import { basenameHostNewTabs, urlContenitori, urlOrdiniLiberi } from '../costanti';
import { DialogElencoContenitori, DialogInviaContenitore, DialogUploadContenitore } from './Dialog';

const ElencoContenitori = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    //const auth = useAuth(urlContenitori.auth);

    const titolo = TitoloContenuto("Elenco Contenitori");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoContenitori, setElencoContenitori] = useState<ElencoContenitoriInterface[]>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogImportIsOpen, setDialogImportIsOpen] = useState(false);
    const [dialogImportFileIsOpen, setDialogImportFileIsOpen] = useState(false);
    const [dialogInviaIsOpen, setDialogInviaIsOpen] = useState(false);
    const [idContDelete, setIdContDelete] = useState<number>();
    const [idContImport, setIdContImport] = useState<number>();
    const [titoloContDelete, setTitoloContDelete] = useState<string>();
    const [titoloContImport, setTitoloContImport] = useState<string>();
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [messageConferma, setMessageConferma] = useState<string>();
    const [azione, setAzione] = useState<number>();
    const [isVisibleImport, setIsVisibleImport] = useState(false);
    const [isVisibleInvia, setIsVisibleInvia] = useState(false);
    const [idContInvio, setIdContInvio] = useState<number[] | undefined>();
    const handleCloseDialogImportaFile = () => { setDialogImportFileIsOpen(false); cerca(); }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        setDal(dataInizio.toString());
        setAl((new Date()).toString());
        if (props.datiFarmacie && props.datiFarmacie.length > 0) {
            setFiltri({
                dal: dataInizio.toString(),
                al: (new Date()).toString(),
                stato: -1
            })
        }
    }, [props.datiFarmacie])

    useEffect(() => {
        if (!dialogImportIsOpen)
            cerca();
    }, [dialogImportIsOpen])

    useEffect(() => {
        if (props.datiFarmacie) {
            const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
            setDal(dataInizio.toString());
            setAl((new Date()).toString());
            if (filtri && filtri.dal && filtri.al) cerca();
            else
                cerca();
        }

    }, [])

    const cerca = () => {
        if (props.datiFarmacie) {
            const param = {
                dal: filtri.dal,
                al: filtri.al,
                stato: filtri.stato
            }
            setIsVisible(true);
            param.dal = trasformaDateTimeInLocale(param.dal, true);
            param.al = trasformaDateTimeInLocale(param.al, true);
            RestServices().rest('POST', param, urlContenitori.getList).then(result => {
                setIsVisible(false);
                if (result.esito) {
                    setElencoContenitori(result.response);
                } else if (result !== true) {
                    notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
                }
            })
        }
    }

    const propsTabContenitori = {
        id: "elenco_contenitori",
        dataSource: elencoContenitori,
        selection: "multiple",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {
            totalItems: [{
                name: "TotaleContenitori",
                showInColumn: "Nome",
                displayFormat: "Contenitori: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Ordini_Contenitori"
        }
    }

    const columnsContenitori = CreaColonne([
        { caption: "Id", dataField: "idContenitore", visible: false },
        { caption: "Import", dataField: "flagImport", visible: false },
        { caption: "Nome", dataField: "nome" },
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Data Ora Ins.", dataField: "dataContenitore", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data Ora Agg.", dataField: "dataContenitoreModifica", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Num. occorrenze", dataField: "numeroOccorrenze" },
        { caption: "Operatore", dataField: "operatore" },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [
               
                {
                    hint: "Elimina Contenitore",
                    icon: "trash",
                    onClick: (e: any) => {
                        if (e.row.data.flagImport) {
                            notify({ position: "center", width: "auto", message: "Il contenitore non puo' essere eliminato." }, "error", 4000)
                            return;
                        }
                        setIdContDelete(e.row.data.idContenitore);
                        setTitoloContDelete(e.row.data.nome);
                        setMessageConferma("Il contenitore " + e.row.data.nome + " sta per essere eliminato. Confermare?");
                        setAzione(1);
                        setDialogIsOpen(true);
                        
                    }
                },
                //{
                    
                //    hint: "Importa Contenitore",
                //    icon: "arrowdown",
                //    onClick: (e: any) => {

                //        if (!e.row.data.flagImport) {
                //            notify({ position: "center", width: "auto", message: "Il contenitore non puo' essere importato." }, "error", 4000)
                //            return;
                //        }

                //        setIdContImport(e.row.data.idContenitore);
                //        setTitoloContImport(e.row.data.nome);
                //        setMessageConferma("Il contenitore " + e.row.data.nome + " sta per essere importato. Confermare?");
                //        setAzione(2);
                //        setDialogIsOpen(true);

                //    },
                //}
            ]
        }
    ])

    const functionNext = () => {
        setDialogIsOpen(false);
        if (azione == 1)
            eliminaContenitore();

        if (azione == 2)
            importaContenitore();
    }

    const functionNextImport = () => {
        setDialogImportIsOpen(false);
        setDialogImportFileIsOpen(false);
        cerca();
    }

    const eliminaContenitore = () => {
        const param = {

        };
        setIsVisible(true);
        RestServices().rest('POST', param, urlContenitori.delete + '?idContenitore=' + idContDelete).then(result => {
            if (result) {
                if (result.esito) {
                    if (elencoContenitori) {
                        const newElenco = elencoContenitori.filter(riga => {
                            return riga.idContenitore !== idContDelete
                        })
                        setElencoContenitori(newElenco);
                    }
                } else {
                    notify({ position: "center", width: "auto", message: "Non e' stato possibile eliminare il contenitore. Si prega di riprovare" }, "error", 4000)
                    return;
                }
            }
            
        })
        setIsVisible(false);
    }

    const importaContenitore = () => {
        const param = {

        };
        setIsVisible(true);
        RestServices().rest('POST', param, urlContenitori.import + '?idContenitore=' +idContImport).then(result => {
            if (result) {
                if (result.esito) {
                    notify({ position: "center", width: "auto", message: "Contenitore importato correttamente" }, "success", 4000)
                    cerca();
                }
                else {
                    notify({ position: "center", width: "auto", message: "Non e' stato possibile importare il contenitore. Si prega di riprovare" }, "error", 4000)
                    return;
                }
            }
            
        })
        setIsVisible(false);
    }

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            if (rowDblClicked.data.flagImport == 1) {
                notify({ position: "center", width: "auto", message: "Importare il contenitore per poterlo modificare." }, "error", 4000)
                return;
            }
            const path = basenameHostNewTabs + "contenitori/GetDettaglio?idContenitore=" + rowDblClicked.data.idContenitore + "&contenitoreFarmacia=0";
           // console.log("path:" + path);
            window.open(path);
        }
    }

    const onRowPrepared = useCallback((e: any) => {
        if (e.values) {
            if (e.rowType === "data" && e.rowType != 'header') {
            }
        }
    }, [])

    const handleCloseConferma = () => setDialogIsOpen(false);

    const handleCloseDialogElencoContenitori = () => {
        setDialogImportIsOpen(false);
        cerca();
    }

    const handleCloseDialogInvia = () => {
        setDialogInviaIsOpen(false);
        cerca();
    }
    

    const inviaContenitore = () => {
        var keys = tabellaInstance.instance.getSelectedRowKeys();

        if (keys.length == 0) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare almeno un ordine" }, "error", 4000);
        }

        var ids = new Array();
        for (var i = 0; i < keys.length; i++) {
            ids.push(keys[i].idContenitore);
        }

        setIdContInvio(ids);
        setDialogInviaIsOpen(true);
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <Row>
                <Col sm="12">
                    <div className="rightcontent">
                        <Button variant="success" id="btnNuovoContenitore" onClick={() => window.open(basenameHostNewTabs + "contenitori/GetDettaglio")}>Nuovo Contenitore</Button>
                        <Button variant="info" id="btnImportaContenitore" onClick={() => setDialogImportIsOpen(true)}>Importa Contenitore da farmacia</Button>
                        <Button variant="info" id="btnImportaContenitoreFile" onClick={() => setDialogImportFileIsOpen(true)}>Importa Contenitore da file</Button>
                        {/*<Button variant="primary" id="btnInviaContenitore" onClick={inviaContenitore}>Invia</Button>*/}
                    </div>
                    </Col>
                
            </Row>
            <div className="contenutoPagina">
                <Row>
                    <Col sm="6">
                        <div className="leftcontent">
                            <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Info" /> Doppio click sulla riga per visualizzare il dettaglio</span>
                            &nbsp;
                        </div>
                    </Col>
                </Row>
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        {<TabellaCompletaAlternate
                            propsTabella={propsTabContenitori}
                            columns={columnsContenitori}
                            onRowDblClick={onRowDblClick}
                            setTabellaInstance={setTabellaInstance}
                            wordWrapEnabled={true}
                            togliFilterPanel={true}
                            rowAlternationEnabled={false}
                            onRowPrepared={ onRowPrepared}
                        />}
                    </Col>
                </Row>
                <DialogConferma
                    dialogIsOpen={dialogIsOpen}
                    message={messageConferma}
                    functionNext={functionNext}
                    handleCloseConferma={handleCloseConferma}
                />

                <DialogElencoContenitori
                    datiFarmacie={props.datiFarmacie}
                    isDialogElencoContenitoriOpen={dialogImportIsOpen}
                    setIsDialogElencoContenitoriOpen={setDialogImportIsOpen}
                    functionNext={functionNextImport}
                    handleCloseDialogElencoContenitori={handleCloseDialogElencoContenitori}
                    setIsVisible={setIsVisibleImport}
                />

                <DialogUploadContenitore
                    isDialogUploadContenitore={dialogImportFileIsOpen}
                    setIsDialogUploadContenitore={setDialogImportFileIsOpen}
                    handleCloseDialogUploadContenitore={handleCloseDialogImportaFile}
                    setIsVisible={setIsVisible}
                    functionNext={functionNextImport}
                />

                <DialogInviaContenitore
                    datiFarmacie={props.datiFarmacie}
                    isDialogInviaContenitoreOpen={dialogInviaIsOpen}
                    setIsDialogInviaContenitoreOpen={setDialogInviaIsOpen}
                    handleCloseDialogInviaContenitore={handleCloseDialogInvia}
                    setIsVisible={setIsVisibleInvia}
                    idContenitore={idContInvio}
                    />
            </div>
        </>
    )

}

export default ElencoContenitori;