import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaInfoFarmacia, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from 'devextreme/ui/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AnaFarmacia, FarmaciaSelezionataNewsletter } from "../../interfaces/newsletter";
import Button from 'react-bootstrap/esm/Button';
import ReactDOM from "react-dom";
import { DialogAnomalieMon, DialogNoteMon } from "./Dialog";
import { useAuth } from "../../hooks/useAuth";

const ElencoClientiInfoFarmacie = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlMonitoraggio.auth);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            getTipoAnomalia();
            startCerca();
        }

    }, [auth]);

    const titolo = TitoloContenuto("Elenco clienti");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoFarmacie, setElencoFarmacie] = useState<AnaFarmacia[]>();
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [openPop3, setOpenPop3] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)
    const [tipoAnomalia, setTipoAnomalia] = useState<any[]>([])


    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        RestServices().rest('POST', param, urlMonitoraggio.getAnagraficaClientiInfoFarmacie)
            .then(result => {
                setIsVisible(false);
                if (result && result.length > 0) {
                    setElencoFarmacie(result);
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const getTipoAnomalia = () => {

        const param = {};
        RestServices().rest('POST', param, urlMonitoraggio.getTipoAnomalia)
            .then(result => {
                //setIsVisible(false);
                if (result && result.length > 0) {
                    setTipoAnomalia(result);
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })

    }

    const propsTabElencoFarmacie: TabellaCompletaInterface = {
        id: "elenco_info_farmacie",
        dataSource: elencoFarmacie,
        selection: "single",
        autoWidth: true,
        defaultFilterValue: ["platinum", "=", true],
        //height: function () {
        //    return window.innerHeight / 1.2;
        //},
        headerFilterVisible: true,
        summary: {

            totalItems: [{
                name: "TotaleFarmacie",
                showInColumn: "Cod.CSF",
                displayFormat: "Tot.: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoInfoFarmacie"
        }
    }

    const apriNuovaNewsletter = () => {

        //var keys = tabellaInstance.instance.getSelectedRowKeys();
        //if (keys.length == 0) {
        //    setIsVisible(false);
        //    notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia" }, "error", 4000);
        //}
        //else {

        //    var item;
        //    var farmacie = new Array();
        //    for (var i = 0; i < keys.length; i++) {
        //        item = { idCli: keys[i].idCliente, codTph: keys[i].codiceTelepharma, email: keys[i].email };
        //        farmacie.push(item);
        //    }
        //    setFarmacieSelezionate(farmacie);
        //    setIsDialogNuovaNewsletterOpen(true);
        //}


    }

    const apriStoricoNewsletter = () => {

        window.open(basenameHostNewTabs + "newsletter/ElencoNewsletter")
    }

    const apriImmaginiNewsletter = () => {
        window.open(basenameHostNewTabs + "newsletter/ElencoFileNewsletter")
    }

    const columnsFarmacie = CreaColonne([
        { caption: "IdCliente", dataField: "idCliente", visible: false },
        { caption: "Cod.CSF", dataField: "codiceCliente", visible: true, width: 60 },
        { caption: "Cod.Tph", dataField: "codiceTelepharma", visible: true },
        { caption: "Rag.Soc.", dataField: "ragioneSociale", width: 270},
        { caption: "Titolare", dataField: "titolare", width: 100 },
        { caption: "P.Iva", dataField: "partitaIva" },
        { caption: "Email", dataField: "email", width: 100 },
        { caption: "Regione", dataField: "regione", width: 90 },
        { caption: "Provincia", dataField: "provincia", width: 90 },
        { caption: "Comune", dataField: "comune", width: 90 },
        { caption: "Cap'", dataField: "cap" },
        { caption: "Concessionario", dataField: "concessionario", width: 100 },
        { caption: "Tipo Anagrafica", dataField: "tipoAnagrafica", width: 90 },
        { caption: "Tipo Cliente", dataField: "tipoCliente", width: 90 },
        { caption: "Ha platinum", dataField: "platinum", visible: true, dataType: "boolean", width:90 },
        { caption: "Ha disdetto platinum", dataField: "disdettaPlatinum", visible: true, dataType: "boolean", width: 90 },
        { caption: "Data termine Platinum", dataField: "dataDisdettaPlatinum", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data ultima vendita", dataField: "ultimaVendita", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Revisione", dataField: "releaseInstallata" },
        { caption: "Data Aggior.", dataField: "dataAggiornamento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Versione DB", dataField: "versioneSqlServer", width: 270 },
        { caption: "Stato DB", dataField: "statoDb" },
        { caption: "Stato Disco", dataField: "statoDisco" },
        { caption: "Stato Backup", dataField: "statoBackup" },
        {
            caption: "Note", dataField: "note", width: 100, cellRender: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        {
            caption: "Anomalia", dataField: "tipoAnomalia", width: 100, cellRender: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop3(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        { caption: "Data anomalia", dataField: "dataAnomalia", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Operatore anomalia", dataField: "operatoreAnomalia" },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [
                {
                    hint: "Parametri farmacia",
                    icon: "preferences",
                    onClick: (e: any) => {
                        const codTph = e.row.data.codiceTelepharma;
                        const path = basenameHostNewTabs + "infoFarmacie/ElencoParametriFarmacia?codTph=" + codTph;
                        window.open(path, "_blank");
                    }
                },
                {
                    hint: "Informazioni dettagliate farmacia",
                    icon: "info",
                    onClick: (e: any) => {
                        const codTph = e.row.data.codiceTelepharma;
                        const path = basenameHostNewTabs + "infoFarmacie/InfoFarmacia?codTph=" + codTph;
                        window.open(path, "_blank");
                    }
                },
                {
                    hint: "Dettaglio telepharma",
                    icon: "link",
                    onClick: (e: any) => {
                        const codTph = e.row.data.codiceTelepharma;
                        const path = "https://updates.telepharma.it/admin/stat_data.php";
                        //window.open(path, "_blank");

                        var mapForm = document.createElement("form");
                        mapForm.target = "_blank";
                        mapForm.method = "POST";
                        mapForm.action = path;

                        var mapInput = document.createElement("input");
                        mapInput.type = "hidden";
                        mapInput.name = "cu";
                        mapInput.id = "cu";
                        mapInput.value = codTph;

                        mapForm.appendChild(mapInput);
                        document.body.appendChild(mapForm);
                        mapForm.submit();
                    }
                }]
        }
      
    ])

    const columnsFarmacieDettaglio = CreaColonne([
        { caption: "Data", dataField: "dataNewsletter", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "desc" },
        { caption: "Oggetto", dataField: "oggetto" },
        { caption: "Messaggio", dataField: "messaggio" },
        { caption: "Esito invio", dataField: "esitoInvio" },
        { caption: "Esito messaggio", dataField: "esitoMessaggio" },
    ])

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.disdettaNewsletter == true)
                e.component.deselectRows(row); return;
        })
    }

    const onContentReady = (function (e: any) {


        //const node = ReactDOM.findDOMNode(null);
        //console.log(node)
        //// Get child nodes
        //if (node instanceof HTMLElement) {
        //    const child = node.querySelector('.dx-page-size');
        //    console.log(child);
        //}
        
        //var el = e.component.element().find('.dx-page-size').last();
        //el.text('All');
        //el.click(function () {
        //    e.component.pageSize(0);
        //    el.text('All');
        //});
    })

    const onCellPrepared = (e: any) => {
        
        e.cellElement.style.padding = "3px";
    }

    const ricarica = () => {
        setOpenPop2(false)
        setImmediate(false)
        startCerca()

    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row className="tabellaFornitori" id= "rigaTabInfoFarmacia">
                    <Col sm="12" className="infoFarmacia">
                        {/*<TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            columnsDetail={columnsFarmacieDettaglio}
                            urlDetail={urlAnagrafiche.getAnagraficaClienteNewsletterDettaglio + '?idCli='}
                            keyDetail={"idCliente"}
                            key={"idCli"}
                        />*/}

                        <TabellaCompletaInfoFarmacia
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            onSelectionChanged={onSelectionChanged}
                            togliFilterPanel={false}
                            searchPanel={true}
                            groupPanel={true}
                            onContentReady={onContentReady}
                            onCellPrepared={onCellPrepared}
                            
                        />
                    </Col>
                </Row>
            </div>

            <DialogNoteMon
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />

            <DialogAnomalieMon
                typeEdit={edit}
                edit={objtipo}
                tipoAnomaliaDataSource={tipoAnomalia}
                ricarica={() => ricarica()}
                close={() => { setOpenPop3(false); setObjtipo({}); setImmediate(false) }}
                open={openPop3} />

        </>
    )

}

export default ElencoClientiInfoFarmacie;