import notify from "devextreme/ui/notify";
import { default as React, useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useAuth } from "../../hooks/useAuth";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { urlCommon } from "../costanti";

interface PageProps {
    rowData: any;
    setIndietro: () => void;
}

export const DettaglioMultivendita: React.FC<PageProps> = ({ rowData, setIndietro }) => {

    const titolo = TitoloContenuto("Dettaglio Documenti");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [dettaglioMultivenditaTemp, setDettaglioMultivenditaTemp] = useState<any[]>([]);
    const [dettaglioMultivendita, setDettaglioMultivendita] = useState<any[]>([]);
    const [params, setParams] = useState<any>({});

    const auth = useAuth(urlCommon.auth);


    const exportDettaglioMultivendita = () => {

        let idFarValue = rowData.idFar;

        let idRegistratoreValue = rowData.idRegistratore;

        let numeroDiChiusuraValue = rowData.numeroDiChiusura;

        let numDocumentiStringhe = rowData.numeroDocumento ? rowData.numeroDocumento.trim() : '';
        let numDocumentiArray= [];


        if (numDocumentiStringhe.includes(",")) {

            numDocumentiStringhe = numDocumentiStringhe.split(", ").map((s : any) => Number(s.trim()));

            numDocumentiArray = numDocumentiStringhe;
        }
        else
        {
            numDocumentiArray = numDocumentiStringhe ? [Number(numDocumentiStringhe)] : [];
        }


        let model = {

            idFar: idFarValue,
            idRegistratore: idRegistratoreValue,
            numeroDiChiusura: numeroDiChiusuraValue,
            numeroDocumento: numDocumentiArray,
        }

        setParams(model);

        //console.log(params)
        setIsVisible(true);

        if (auth) {
            RestServices().rest('POST', params, urlCommon.getDettaglioMultivendita)
                .then(result => {


                    if (result) {

                        setDettaglioMultivenditaTemp(result.response);
                        setIsVisible(false);

                        if (result.response.length === 0) {

                            notify({ position: "center", width: "auto", message: "Nessun dettaglio da mostrare" }, "error", 1000)
                            setIsVisible(false);
                        }

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })

        }

    }


    useEffect(() => {
        exportDettaglioMultivendita();
    }, [auth]);


    useEffect(() => {

        const newResponse = dettaglioMultivenditaTemp.map(item => {

            const data = item.dataVendita;

            let formattedData = "Data non disponibile";

            if (data && data.includes('T')) {

                const [parteData, parteOrario] = data.split('T');

                const [anno, mese, giorno] = parteData.split('-');

                const [ore, minuti, secondi] = parteOrario.split(':');

                formattedData = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;
            }

            const Vendita = item.cpeVendita;

            let codiceVendita = "Nessuna vendita presente";

            if (Vendita)
                codiceVendita = Vendita;

            return {
                ...item,
                dataVendita: formattedData,
                cpeVendita: codiceVendita,
            };
        });

        setDettaglioMultivendita(newResponse);

    }, [dettaglioMultivenditaTemp]);


    const columnsDettaglioMultivendita = [

        //{ caption: "Numero di Chiusura", dataField: "numChiusura", },
        { caption: "Codice Vendita", dataField: "cpeVendita", },
        { caption: "Data Vendita", dataField: "dataVendita", },
        { caption: "Numero Documento", dataField: "numDocumento", width: "auto" },
        { caption: "Importo Pagato", dataField: "importoPagato", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Totale", dataField: "totComplessivo", format: { type: "currency", precision: 2, currency: "EUR" } },
    ];


    return (
        <>
            <br></br>
            {titolo}
            {loadPanel}

            <div style={{ marginTop: 15, display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                <div>
                    <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro(); }}> TORNA AGLI SCONTRINI </Button>
                </div>
            </div>

            <NewTabella
                idTabella='dettaglioMultivendita'
                sync
                colonne={columnsDettaglioMultivendita}
                dataSource={dettaglioMultivendita}
                filterRow
                scrolling={{
                    useNative: false,
                    showScrollbar: 'onHover'
                }}
                searchPanel={true}
                exportT={{ enabled: true, fileName: "ElencoEsportazioniDettagli" }}
                activeSaver={true}
                columnC={true}
                headerFilter
                wordWrapEnabled={true}
            />


        </>

    );
};

export default DettaglioMultivendita;


