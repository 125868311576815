import React, { useEffect } from 'react'


const Privacy = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

  useEffect(() => { 
    if (false) {
        props.toggleMenu(!props.statoMenu);
    }
}, []);

  return (
         <>
         <div style={{  marginLeft:10 , marginTop:20 ,color:'black'   }}>
           <h2>PRIVACY</h2>

          <h3>1. Titolare del trattamento </h3>
          <h5> Il titolare del trattamento dei dati raccolti tramite questo sito è CSF S.r.l., con sede legale in Italia, Via del Ciavattino snc, 03012 - Anagni (FR).</h5>
          <h5> Essa decide in modo autonomo sulle finalità e sulle modalità del trattamento, nonché sulle procedure di sicurezza da applicare per garantire la riservatezza, l'integrità e la disponibilità dei dati.</h5>

          <h3>  2. I Suoi diritti </h3>
          <h5> Lei ha sempre il diritto di ottenere da CSF la conferma del trattamento o meno di dati personali che La riguardano, anche se non ancora registrati, e la loro comunicazione in forma intelligibile.</h5>
          <h5> Inoltre, Lei ha sempre il diritto di ottenere da CSF:</h5>
          <ul>
            <h5> <li> l'aggiornamento, la rettificazione, l'integrazione dei Suoi dati personali; </li></h5>   
         <h5>   <li> la cancellazione, la trasformazione in forma anonima o il blocco dei Suoi dati personali trattati in violazione di legge, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali i dati sono stati raccolti o successivamente trattati;  </li></h5> 
         <h5>  <li>  l'attestazione che le operazioni di cui ai punti precedenti sono state portate a conoscenza, anche per quanto riguarda il loro contenuto, di coloro ai quali i dati sono stati comunicati o diffusi, eccettuato il caso in cui tale adempimento si rivela impossibile o comporta un impiego di mezzi manifestamente sproporzionato rispetto al diritto tutelato. </li></h5> 
         </ul>

         <h3>3. La nostra privacy policy in sintesi </h3>
          <h5> I principi su cui si basa la nostra privacy policy sono i seguenti:</h5>
          <ul>
            <h5> <li> trattare i dati esclusivamente per le finalità e secondo le modalità illustrate nell'informativa presentata all'atto della loro raccolta; </li></h5>   
         <h5>   <li> utilizzare i dati per finalità ulteriori rispetto a quelle per le quali i dati sono stati specificamente rilasciati solo ove presente il consenso espresso dell'utente;  </li></h5> 
         <h5>  <li>  rendere disponibili i dati a società terze solo per fini strumentali all'erogazione del servizio richiesto e nell'ambito di una nomina a responsabile del trattamento; non comunicare i dati, cederli o trasferirli a soggetti terzi per trattamenti propri di questi ultimi senza che gli utenti ne siano stati previamente informati e abbiano concesso il loro consenso; </li></h5> 
         <h5>  <li>  rispondere alle richieste di cancellazione, modifica, integrazione dei dati forniti, opposizione al trattamento dei dati per fini di invio di informazioni commerciali e pubblicitarie; </li></h5> 
         <h5>  <li>  assicurare una corretta e lecita gestione dei dati, salvaguardando la privacy degli utenti, nonché applicare misure idonee di sicurezza a tutela della riservatezza, dell'integrità e della disponibilità dei dati conferiti. </li></h5> 
         </ul>

         <h3>4. Come e perché trattiamo i Suoi dati personali </h3>
          <h5> I trattamenti sui dati personali sono eseguiti con modalità prevalentemente elettroniche e telematiche da CSF e da altri soggetti che, opportunamente selezionati quanto ad affidabilità e competenza, svolgono operazioni strumentali al perseguimento delle finalità strettamente connesse all'uso del sito web, dei suoi servizi e all'acquisto di prodotti tramite il sito web.</h5>
          <h5> Le specifiche finalità per le quali i dati sono trattati sono riepilogate, di volta in volta, nell'informativa ex art. 13, d. lgs 196/2003, che è presentata all'utente quando rilascia dati personali.</h5>

          <h3>5. Cookies </h3>
          <h5> La nostra applicazione potrebbe raccogliere dati non direttamente rilasciati dall'utente, come i cookies. Il cookie è un dispositivo che è trasmesso all'hard disk dell'utente; esso non contiene informazioni comprensibili ma permette di associare l'utente alle informazioni personali da esso rilasciate sul sito. Le informazioni personali associate ai cookies sono collocati sul nostro server e nessuno può accedere ai dati contenuti su di esso.</h5>

          <h3>6. Misure di sicurezza </h3>
          <h5> Adottiamo misure di sicurezza adeguate al fine di ridurre al minimo i rischi di distruzione o di perdita - anche accidentale - dei dati, di accesso non autorizzato o di trattamento non consentito o non conforme alle finalità di raccolta indicate nella nostra Sezione Privacy.</h5>

          <h3>7. Contatti </h3>
          <h5> Se desidera ricevere maggiori informazioni su come CSF tratta i Suoi dati personali, La preghiamo di scrivere un'e-mail all'indirizzo di posta elettronica <a href="mailto:cloudfarma@csfsistemi.it">cloudfarma@csfsistemi.it </a>.</h5>
           <h5> Per conoscere i Suoi diritti ed essere sempre aggiornato sulla normativa in materia di tutela delle persone rispetto al trattamento dei dati personali, Le consigliamo di visitare il sito web del Garante per la protezione dei dati personali all'indirizzo <a href="https://www.garanteprivacy.it/">www.garanteprivacy.it </a>.</h5>

          <h3>8. Legge applicabile </h3>
          <h5> Questa Sezione Privacy è regolata dalla legge italiana e in particolare dal Codice in materia di protezione dei dati personali (decreto legislativo 30 giugno 2003 n. 196) che disciplina il trattamento dei dati personali - anche detenuti all'estero - effettuato da chiunque è residente o ha sede in Italia</h5>
          </div>

           </>
  )
}

export default Privacy