import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlCommon, urlConsultazione} from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione/interface'
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { AplicaValoreTO } from '../../funzioni/operazioniSuTabelle'
import { Summary, TotalItem } from 'devextreme-react/data-grid'
import { TextArea } from 'devextreme-react';

import '../consultazione/style.css'
import { SendQueryDialog } from './SendQueryDialog'
import { AutoLayout } from 'devextreme-react/diagram'



const columns = [
    { caption: "ID", dataField: "id", width: "50" },
    { caption: "Codice", dataField: "codiceTelepharma", width: "90" },
    { caption: "Descrizione", dataField: "descrizione", width: "fixed"},
    { caption: "Titolare", dataField: "titolare", width: "fixed" },
    { caption: "Comune", dataField: "comune", width: "fixed" },

];


 
const SendQuery = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idFarList, setidFarList] = useState([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [query, setQuery] = useState<string>("");
    const [tokens, setTokens] = useState<any>([]);
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [edit, setImmediate] = useState<boolean>(false)
    const [isPopupVisible, setPopupVisibility] = useState(true);
    const [queryResult, setQueryResult] = useState([]);
    const [obj, setObj] = useState<any>();
    const auth = useAuth(urlConsultazione.auth);

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFarDett)
                .then(result => {
                    if (result?.esito) {
                        setNewFarmaList(result.response)
                    }

                })
        }
    }

    const sendQuery = () => {
        let farList: any = [];
        idFarList.forEach((element: any) => {
            farList.push(element.id);
        })

        const model = {
            idFarList: farList,
            query: query
        }

        if (farList?.length >= 1) {
            if (query?.length >= 1) {

                    RestServices().rest('POST', model, urlConsultazione.sendQuery)
                        .then(result => {
                            if (result) {
                                let newTokens: any = [];
                                result.response.forEach((element: any) => {
                                    newTokens.push(element.token);
                                });
                                setTokens(newTokens);
                                console.log(newTokens);

                                // Show notification message after query is successfully sent
                                notify({ position: "center", width: "auto", message: "Query Inviata" }, "success", 4000);
                            }
                        })
                        .catch((error) => {
                            notify({ position: "center", width: "auto", message: "Failed to send query" }, "error", 4000);
                        });
                } else {
                    notify({ position: "center", width: "auto", message: "Nessuna Query Inserita" }, "error", 4000);
                }
            } else {
            notify({ position: "center", width: "auto", message: "Seleziona un ID(s)" }, "error", 4000);
            }
    }

    const ricarica = () => {            
        setOpenPop(false)
        setImmediate(false);
    }

    const open = () => {
        setOpenPop(true)
        setImmediate(true);
    }


    useEffect(() => { 
        farmaList() 
        
    }, [auth]);


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> <p style={{ fontWeight: 'bolder', fontSize: 30 }}>Invia Query<span className='subt' ></span></p></h3>
            </div>
            

            <SendQueryDialog
                typeEdit={edit}
                edit={tokens}
                ricarica={() => ricarica()}
                close={() => {setOpenPop(false); setImmediate(true); setTokens(null) }}
                open={openPop}
            />

            <NewTabella
                idTabella='sendquery'
                sync
                multyp
                colonne={columns}
                dataSource={newFarmaList}
                filterRow
                searchPanel={true}
                selectedValue={(datiRow: any) => {
                    setidFarList(datiRow.selectedRowKeys)
                    
                    //console.log(idFarList);
                }}
                exportT={{
                    enabled: true,
                    fileName: "sendquery"
                }}
                activeSaver
                columnC
            />

            <div style={{ display: 'block', gap: 5, padding: 5, borderRadius: 5, marginTop: 0, width: '400px' }}></div>

            <TextArea valueChangeEvent="keyup" showClearButton={true} value={query}
           
                onValueChanged={(e) => setQuery(e?.value)}
                placeholder="..."
                autoResizeEnabled={true}/>
            
                

            <div style={{ display: 'block', gap: 5, padding: 2, borderRadius: 5, marginTop: 0, width: '400px' }}></div>

            <div>

                <button style={{ border: 'none', padding: 10, justifyContent: 'left' }} className='btn btn-success btn v' onClick={sendQuery}>Invia</button> <button style={{ border: 'none', padding: 10, }} className='btn btn-success btn v' onClick={open}>Risultato</button></div >

            
        </>

    )
}

export default SendQuery




