import React, { useState, useEffect  } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import TextBox from 'devextreme-react/text-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import './style.css';

interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

export const DialogCluster:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const auth = useAuth(urlConsultazione.auth)
    const [openD,setOpenD] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [disableButton, setDisableButton] = useState<boolean>(true)

    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo.descrizione = edit.descrizione
            setObjctTOsave(objTo)
            setDisableButton(true) 
        }else{
            let objTo = {...objctTOsave}
            objTo.descrizione = ''
            setObjctTOsave(objTo) 
            setDisableButton(true)
        }

    },[edit,openD])


    const cambiaValore = (tipo:string,value:string) =>{
        let objTo = {...objctTOsave}
        objTo[tipo] = value
        setObjctTOsave(objTo)
        if(objTo.codice?.length > 3 && objTo.descrizione?.length > 4){
            setDisableButton(false)
        }else{
            setDisableButton(true)
        }
    }
    
    const getNuovaTipologia = (modifica?:boolean) =>{
        objctTOsave.descrizione = objctTOsave.descrizione?.toUpperCase()
        if (auth) {
            if(modifica){
                RestServices().rest('POST', {id:edit.id ,descrizione:objctTOsave.descrizione} , urlConsultazione.putClusterTipologia)
                .then(result => {
                    if(result.esito){
                        ricarica()
                    }
                })
            }else{
                RestServices().rest('POST', objctTOsave , urlConsultazione.postCluster)
                .then(result => {
                    if(result.esito){
                        setObjctTOsave(null)
                        ricarica()
                    }
                })
            }
           
        
    } 
    }

    useEffect(()=>{
        setOpenD(open)
    },[open])


   return (
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'50%'}
          height={'70%'}
          showTitle={true}
          title="Tipologia Cluster"
        >
          
        <ToolbarItem
         widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />


        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={typeEdit ? {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:false,
                onClick: ()=>getNuovaTipologia(true),
              }: {
                text: 'CREA',
                class:'btncreatipocluster',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>getNuovaTipologia(),
              }}
         />
            {typeEdit ? (
            <>
               <div className=''>
                    <label> CODICE </label> 
                    <TextBox  disabled value={edit.codice} onValueChanged={(v)=>cambiaValore('codice',v.value) }    /> 
                </div>
                    <div style={{ marginTop:20}}>
                    <label> DESCRIZIONE </label> 
                        <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave.descrizione} onValueChanged={(v)=>cambiaValore('descrizione',v.value) }  placeholder="Inserisci..." /> 
                    </div>
        
           </>
            ) : (
            <>
                 <div className='codice'>
                    <label> CODICE </label> 
                    <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.value : ''}  onValueChanged={(v)=>cambiaValore('codice',v.value) } maxLength='10'  placeholder="Inserisci max 10 numeri"   /> 
                </div>
                <div style={{ marginTop:20}}>
                    <label> DESCRIZIONE </label> 
                    <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave ? objctTOsave?.descrizione : ''} onValueChanged={(v)=>cambiaValore('descrizione',v.value) }  placeholder="Inserisci..." /> 
                </div>
               
            </>
            )}
        </Popup>
      )

  
}
