import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';
import { trasformaDateInLocale } from "../../funzioni/formatoVariabili";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import Ricerca from "../../widget/nuova_ricerca";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { Filtri } from "../consultazione/interface";
import { urlCommon } from "../costanti";
import Button from 'react-bootstrap/esm/Button';

const RegistratoreDiCassa = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const titolo = TitoloContenuto("Registratore Di Cassa");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoCassa, setElencoCassa] = useState<any[]>([]);
    const [elencoCassaConDelta, setElencoCassaConDelta] = useState<any[]>([]);
    const [idFarList, setIdFarList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>();
    const [objtipo, setObjtipo] = useState<any>(null);
    const history = useHistory();


    const auth = useAuth(urlCommon.auth);

    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }

        console.log(params)

        if (params.idFarmacia) {

            params.idFarmacia = params.idFarmacia;

        }

        if (params.dal) {

            params.dal = trasformaDateInLocale(params.dal);

        }


        if (params.al) {

            params.al = trasformaDateInLocale(params.al);

        }


        let newArr: number[] = []
        params.idFarmacia.idFar.forEach((element: any) => {
            newArr.push(element);
        });


        let dal = params.dal;
        let al = params.al;

        const model = {
            listFar: newArr,
            dal: dal,
            al: al
        }

        setParamsFilter(params)

        setIsVisible(true)

        if (auth) {
            RestServices().rest('POST', model, urlCommon.getRegistratoreDiCassaList)
                .then(result => {

                    if (result) {
                        setElencoCassa(result.response);
                        setIsVisible(false);

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })
        }

    }


    const idFarmaList = () => {

        const idFarList: any[] = [];

        if (props.datiFarmacie) {
            for (let i = 0; i < props.datiFarmacie.length; i++) {

                const idFar = props.datiFarmacie[i].ID_FAR;

                idFarList.push(idFar);

            }

            setIdFarList(idFarList);
        }
    }



    const getRegistratoreDiCassaList = () => {

        let model = {
            listFar: idFarList,
            dal: trasformaDateInLocale(new Date(new Date().getFullYear() - 1 + '-01-01')),
            al: trasformaDateInLocale(new Date()),
        }

        setIsVisible(true);

        if (auth) {
            RestServices().rest('POST', model, urlCommon.getRegistratoreDiCassaList)
                .then(result => {

                    if (result) {
                        setElencoCassa(result.response);
                        setIsVisible(false);

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })
        }
    }


    const vaiPaginaDettaglio = (rowData: any) => {
        history.push('/RegistratoreDiCassa/ElencoRegistratoreDiCassaDettaglio', { rowData });
    };

    const tornaAllaDashboard = () => {
        history.push('/RegistratoreDiCassa/DashBoard',);
    };


    useEffect(() => {
        const newResponse = elencoCassa.map(item => {

            const delta = item.totChiusuraIncasso - item.totPlatinum;

            return {
                ...item,
                delta: delta
            };
        });

        setElencoCassaConDelta(newResponse);
    }, [elencoCassa]);



    const getDeltaCellColor = (e: any) => {
        if (e.rowType === "data" && e.column.caption === "Delta") {
            e.cellElement.style.color = e.data.delta === 0 ? 'green' : 'red';
            e.cellElement.style.fontWeight = 'bold';

        }
    };


    const columnsCassa = [
        { caption: "Codice Farmacia", dataField: "codiceFarmacia", width: "auto" },
        { caption: "Farmacia", dataField: "descrizioneFarmacia", /*width: "auto"*/ },
        { caption: "Giorno", dataField: "data", format: "dd/MM/yyyy", dataType: "datetime", /*width: "auto"*/ },
        { caption: "TOT. Casa Fiscale", dataField: "totChiusuraIncasso", format: { type: "currency", precision: 2, currency: "EUR" }, /*width: "auto"*/ },
        { caption: "TOT. Platinum", dataField: "totPlatinum", format: { type: "currency", precision: 2, currency: "EUR" }, /*width: "auto"*/ },
        { caption: "Delta", dataField: "delta", format: { type: "currency", precision: 2, currency: "EUR" }, getDeltaCellColor, },
        {
            caption: "Info Chiusure", type: "buttons", width: "auto",

            buttons: [
                {
                    hint: "Mostra tutte le chiusure del giorno",
                    icon: "info",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        //console.log(objtipo)
                        if (e.row.data) {
                            vaiPaginaDettaglio(e.row.data);
                        }
                    }
                }]
        }
    ];


    const itemsFields = [
        { type: 'selectFarmacie', idValue: 'idFarmacia', label: 'Farmacie', displayExpr: 'DESCRIZIONE', dataSource: props.datiFarmacie, width: 300, require: true, tutteSelezionate: true },
        { type: 'date', idValue: 'dal', label: 'Dal', defValue: new Date(new Date().getFullYear() - 1 + '-01-01'), require: true },
        { type: 'date', idValue: 'al', label: 'Al', defValue: new Date(), require: true },
    ];


    useEffect(() => {
        idFarmaList();

        if (idFarList && idFarList.length > 0)
            getRegistratoreDiCassaList();

    }, [auth]);




    return (
        <>
            <br></br>
            {titolo}
            <br></br>
            {loadPanel}
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>

            <NewTabella
                idTabella='cassa'
                title={
                    <>
                        <div>
                            <Button onClick={() => { tornaAllaDashboard(); }} variant="info" > <b>Torna alla DashBoard</b></Button>
                        </div>
                    </>
                }
                sync
                colonne={columnsCassa}
                dataSource={elencoCassaConDelta}
                filterRow
                searchPanel={true}
                exportT={{ enabled: true, fileName: "Cassa" }}
                activeSaver={true}
                columnC={true}
                headerFilter
                wordWrapEnabled={true}
                onCellPrepared={getDeltaCellColor}
            />

        </>

    );

};
export default RegistratoreDiCassa;