import FiltriVerticale, {
    ConvertForSelect,
  } from "../../filters/FiltriVerticaleNewVersione";
  
  export const createItemFilterVendStati = (newFarmaList: any, operatori: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "selectMultiplaMoreColonNoCodice",
          noId: true,
          id: "operatori",
          label: "Operatori",
          displayExpr: "operatore",
          dataSource: operatori,
          idValueGrid: "operatori" as any,
          allowSelectAll: false
        },
      ]
    )
  }

  export const createItemFilterAnalTrasfStat = (newFarmaList: any, operatori:any, category:any, gruppoMercFilters: any, ditte: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        }, 
        {
          type: "selectMultiplaMoreColonNoCodice",
          noId: true,
          id: "operatori",
          label: "Operatori",
          displayExpr: "operatore",
          dataSource: operatori,
          idValueGrid: "operatori" as any,
          allowSelectAll: false
        },
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        }, 
        {
          type: "selectWithLevels",
          id: "gruppoMerc",
          label: "Gruppo Merceologico",
          displayExpr: "descrizione",
          dataSource: gruppoMercFilters,
          idValueGrid: "gruppoMerc" as any,
        },       
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },
        
      ]
    )
  }

  
  