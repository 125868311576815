import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import Ricerca from "../../widget/nuova_ricerca"
import {  Loading } from '../../widget/Notifications'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import {  trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import { AplicaValoreTO } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'

import './style.css';




const columns = [
    { caption: "Data Vendita", dataField: "dataVendita", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
    { caption: "Importo Netto", dataField: "importoVendita" ,customizeText:(cellInfo:any)=> {
       return AplicaValoreTO(cellInfo,'€','valute')
    } },
    { caption: "Sconto Vendita", dataField: "scontoVendita" , format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Importo Altro", dataField: "importoAltro",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Operatore", dataField: "operatore" },
    { caption: "Terminale", dataField: "terminale" }
];

const columnsVendite = [
    { caption: "Tipo vendita", dataField: "tipoVendita" },
    { caption: "Codice Prodotto", dataField: "codProd"},
    { caption: "Descrizione Prodotto", dataField: "descrizioneProd" , width: 400 },
    { caption: "Quantita'", dataField: "quantita" },
    { caption: "Iva", dataField: "iva",customizeText:(cellInfo:any)=> AplicaValoreTO(cellInfo, '%','iva','d') },
    { caption: "Prezzo Vendita", dataField: "prezzoUnitario",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Prezzo di Riferimento", dataField: "prezzoRiferimento",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Ticket", dataField: "ticket",width: 150  ,format: { type: "currency", precision: 2, currency: "EUR" }},
    { caption: "Importo Quota Prodotto",width: 150   , dataField: "importoQuotaProdotto",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Sconto", dataField: "sconto", width:150 ,format: { type: "currency", precision: 2, currency: "EUR" } }
];

const Venconsultazione = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idVendita,setIdVendita] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [tabellaVendite,setTabellaVendite] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [newFarmaList,setNewFarmaList] = useState([])

    


    const itemsFields =[
        {type:'select' , idValue:'idFar' , label:'Farmacie ' , displayExpr:'descrizione', dataSource : newFarmaList ,width:350,require:true  },
        {type:'date' , idValue:'dal' , label:'Dal' , dataSource : null , require:true  },
        {type:'date' , idValue:'al' , label:'Al' , dataSource : null},
        {type:'autocomplete' , idValue:'codCsf' , label:'Prodotto ' , typeApi:'products',valueExpr:'text', apisearch:true, dataSource : {},width:300 },
    ]


    

    
    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {                     
                       //console.log(result.response);
                       setNewFarmaList(result.response)                    
                   }else{                               
                      // console.log(result);                    
                   }
               })     
       }
   }



    const getVenditaProdottiAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {idVendita  : idVendita} , urlConsultazione.getVenditaProdotti)
                .then(result => {
                    if (result?.esito) {
                        setTabellaVendite(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);                    
                        setLoading(false)
                    }
                })    
       }
    }

    const filterAction = (objFiltro:Filtri) =>{
        let params = {...objFiltro}     
        setIdVendita(null)
        if(params.dal && params.idFar){
            params.idFar = Number(params.idFar?.idFar)
            params.dal = trasformaDateTimeInLocale(params.dal , true);
            params.al = trasformaDateTimeInLocale(params.al, true);
            if(objFiltro.codCsf){
                params.codCsf = Number(params.codCsf?.id)
            }else{
                delete params.codCsf
            }       
            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListven)
                        .then(result => {
                            if (result?.esito) {
                                setTabellaPrincipale(result.response)
                                setLoading(false)
                            }else{
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        }else{
            let errore = ''
            if(!params.idFar){
                errore= errore+' specificare Farmacie'
            }
            if(!params.dal){
                if(errore != ''){
                    errore += ' , ' 
                }
                errore = errore+'specificare Dal'
            }
            notify({ position: 'center', width: "auto", message: errore }, "warning", 4000)

        }
    }



    useEffect(()=>{
        farmaList()
    },[auth])
    
    useEffect(() => { 
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    useEffect(() => {
        if (idVendita) {
            getVenditaProdottiAction()
        }
    }, [idVendita]);
    
   
    return (
        <>
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> VENDITE  <span className='subt' > Consultazione Vendite</span> </h3>
             </div>
                  
            <Ricerca ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  />

            <NewTabella 
                idTabella='elenco_vendita_consultazione'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                onclickRow={(datiRow:any)=>setIdVendita(datiRow.data?.idVendita)}
                exportT={{
                    enabled: true,
                    fileName: "vendita_consultazione"
                }}
                height='45vh'
                activeSaver
                columnC
                summary={
                    <Summary>
                        <TotalItem column="dataVendita" summaryType="count" />
                        <TotalItem column="importoVendita" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} /> 
                        <TotalItem column="scontoVendita" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                    </Summary>} 
            />
            
            <div id="">
                    {idVendita ? 
                    <NewTabella 
                    idTabella='elenco_ven_consultazione_prodotti'
                    exportT={{enabled: false}}  
                    colonne={columnsVendite}  
                    dataSource={tabellaVendite}
                    height='30vh'
                    summary={
                        <Summary>
                            <TotalItem column="tipoVendita" summaryType="count" />
                            <TotalItem column="quantita" summaryType="sum"  /> 
                            <TotalItem column="ticket" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                            <TotalItem column="importoQuotaProdotto" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} /> 
                            <TotalItem column="sconto" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                        </Summary>} 
                    
                    /> : null }
            </div>
        </>
    )
}

export default Venconsultazione