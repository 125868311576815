import React, { useEffect } from 'react'
import { MenuInterface, MenuItemInterface } from '../interfaces/menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { basenameHost } from '../contenuti/costanti'

const MenuItem = (props: {
    menu: MenuInterface,
    singMenu: MenuItemInterface,
    statoMenu: boolean,
    handleShowPsw: () => void,
    location: string,
    idMenuAperto: string,
    setIdMenuAperto: (idMenu: string) => void
}) => {
    //definizione degli id del primo e secondo livello di menu -> apertura automatica a seconda dell'url
    const idFiglio = props.location.replace("/", ".");
    const idPadre = props.location.split("/")[0];
    const idPadreX = "x-".concat(idPadre);
    const subUrl = idFiglio.split(".")[1];
    const idFiglioX = idPadreX + "." + "x-".concat(subUrl);
    //funzione che controlla se l'id del menu selezionato corrisponde a quella del menu aperto -> in questo modo quando si clicca sul
    //menu già aperto, la condizione è verificata e si pone l'id="" così che il livello si chiude; se invece si clicca su un livello
    //diverso da quello aperto, l'id cliccato diventa anche quello aperto e un eventuale livello precedentemente aperto si chiude
    const cambiaIdMenu = (id: string) => {
        if (props.idMenuAperto === id) {
            props.setIdMenuAperto("");
        } else {
            props.setIdMenuAperto(id);
        }
    }
    //stessa cosa di cambiaIdMenu ma avviene in automatico al cambiamento della location invece che al click su una voce di menu
    useEffect(() => {
        if (idPadre === props.singMenu.id) {
            props.setIdMenuAperto(idPadre);
        } else if (idPadreX === props.singMenu.id) {
            props.setIdMenuAperto(idPadreX);
        }
    }, [idPadre, idPadreX])
    //classi css per i vari livelli di menu, aperti e chiusi
    let elencoMenu = 'elencoMenu';
    let secondoLivello = 'second-level chiuso';
    let miniSecondoLiv = 'second-level chiuso';
    let listClosedClasses = 'list-closed';
    let voceMenu = 'voceMenu';
    //se l'id del primo livello di menu corrisponde a quello del menu aperto vengono cambiate le classi css degli elementi corrispondenti
    if (props.singMenu.id === props.idMenuAperto) {
        cambiaClassiMenu();
    }
    function cambiaClassiMenu() {
        secondoLivello = "second-level";
        elencoMenu = 'elencoMenu active';
        listClosedClasses = 'list-closed-active';
        miniSecondoLiv = 'mini-secondo-liv';
    }
    //rende attiva la voce di menu cliccata (cambia le classi css)
    const rendiAttivo = (idCliccato: string) => {
        let cercaVoceAttiva = document.getElementsByClassName("singolaVoce-active")
        for (let i = 0; i < cercaVoceAttiva.length; i++) {
            //if (cercaVoceAttiva[i].id === idCliccato) forceUpdate();
            cercaVoceAttiva[i].className = "singolaVoce"
        }
        let sottomenuCliccato = document.getElementById(idCliccato)
        if (sottomenuCliccato) {
            sottomenuCliccato.className = "singolaVoce-active"
        }
    }
    //si scorrono tutti i livelli di menu e per ognuno si controlla se l'url corrisponde all'id del secondo livello 
    //in modo da renderlo attivo al caricamento della pagina
    for (let i = 0; i < props.menu.menuItems[props.singMenu.id].length; i++) {
        let livello = props.menu.menuItems[props.singMenu.id][i];
        if (idFiglio === livello.id) {
            rendiAttivo(idFiglio);
        } else if (idFiglioX === livello.id) {
            rendiAttivo(idFiglioX);
        }
    }
    //rende attivo il livello Gestione Utente/Cambio Password -> è a parte perché è l'unico elemento del menu senza link (apre una modal
    //non una pagina)
    const menuCambioPassword = (idCliccato: string) => {
        rendiAttivo(idCliccato);
        props.handleShowPsw();
    }

    return (
        <li className={props.statoMenu ? elencoMenu : listClosedClasses} key={props.singMenu.id} id={props.singMenu.id}>
            <div className={props.statoMenu ? voceMenu : ""} onClick={() => cambiaIdMenu(props.singMenu.id)} title={props.statoMenu ? undefined : props.singMenu.text} >
                <FontAwesomeIcon icon={props.singMenu.icon} className={props.statoMenu ? 'iconaMenu' : 'iconaMenu-closed'} />
                {props.statoMenu &&
                    <>
                        <span className="link">{props.singMenu.text}</span>
                        {(props.singMenu.id === props.idMenuAperto) ? <FontAwesomeIcon icon={faAngleDown} className="arrow" /> : <FontAwesomeIcon icon={faAngleLeft} className="arrow" />}
                    </>
                }
            </div>

            {props.menu.menuItems[props.singMenu.id] && (
                <ul className={props.statoMenu ? secondoLivello : miniSecondoLiv}>
                    {props.menu.menuItems[props.singMenu.id].map((livello: MenuItemInterface) => (
                        <li key={livello.id}>
                            {livello.link.includes(".jsp") ? (
                                <a
                                    href={`${basenameHost}cfportal/${livello.link}`}
                                    className="singolaVoce"
                                    id={livello.id}
                                    onClick={() => { rendiAttivo(livello.id) }}
                                >
                                    {livello.text}
                                </a>
                            ) : livello.link !== "" && livello.link.startsWith("http") ? (
                                <a
                                    href={livello.link}
                                    className="singolaVoce"
                                    id={livello.id}
                                    onClick={() => { rendiAttivo(livello.id) }}
                                >
                                    {livello.text}
                                </a>
                            ) : livello.link === "" ? (
                                <div
                                    className="singolaVoce"
                                    id={livello.id}
                                    onClick={() => { menuCambioPassword(livello.id) }}
                                >
                                    {livello.text}
                                </div>
                            ) : (
                                <Link
                                    to={livello.link.replace("app/", "")}
                                    className="singolaVoce"
                                    id={livello.id}
                                    onClick={() => { rendiAttivo(livello.id) }}
                                >
                                    {livello.text}
                                </Link>
                            )}
                        </li>
                    ))}
                </ul>
            )}
        </li>
    )
}

export default MenuItem