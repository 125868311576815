import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { OggettoInterface } from '../../interfaces/generiche';
import { ElencoOrdiniLiberiInterface, PrioritaInterface, StatoOrdineLiberoInterface } from '../../interfaces/ordineLibero';
import { OnRowDblClickInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { DrawerFiltri } from '../../widget/Drawer';
import { BoxDataOra } from '../../widget/Editor';
import { DialogConferma, Loading } from '../../widget/Notifications';
import { Selezione } from '../../widget/Select';
import { TabellaCompleta, TabellaCompletaAlternate } from '../../widget/Tabelle';
import { basenameHostNewTabs, urlOrdiniLiberi } from '../costanti';
import { DialogImpostazioniOrdLib, DialogInfo } from './Dialog';

const ElencoOrdiniLiberi = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean, flagB2B?:boolean | undefined }) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const auth = useAuth(urlOrdiniLiberi.auth);

    const titolo = (props.flagB2B != undefined && props.flagB2B) ? TitoloContenuto("Ordini B2B") : TitoloContenuto("Ordini Liberi");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoOrdiniLiberi, setElencoOrdiniLiberi] = useState<ElencoOrdiniLiberiInterface[]>();
    const [aggiornaTabDett, setAggiornaTabDett] = useState(1);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [dialogInfoIsOpen, setDialogInfoIsOpen] = useState(false);
    const [idOrdDelete, setIdOrdDelete] = useState<number>();
    const [idOrdStato, setIdOrdStato] = useState<number>();
    const [idOrdInfo, setIdOrdInfo] = useState<number>();
    const [titoloOrdDelete, setTitoloOrdDelete] = useState<string>();
    const [titoloOrdStato, setTitoloOrdStato] = useState<string>();
    const [messageConferma, setMessageConferma] = useState<string>();
    const [isDialogImpostazioniOpen, setIsDialogImpostazioniOpen] = useState(false);
    const [listaPriorita, setListaPriorita] = useState<PrioritaInterface[]>();
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [listaStatoOrdineLibero, setListaStatoOrdineLibero] = useState<StatoOrdineLiberoInterface[]>();
    const [openDrawer, setOpenDrawer] = useState(true);
    const [idStatoClicked, setIdStatoClicked] = useState<number>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        setDal(dataInizio.toString());
        setAl((new Date()).toString());
        if (props.datiFarmacie && props.datiFarmacie.length > 0) {
            setFiltri({
                dal: dataInizio.toString(),
                al: (new Date()).toString(),
                stato: -1
            })
        }
    }, [props.datiFarmacie])

    useEffect(() => {
        if (auth) {
            const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
            setDal(dataInizio.toString());
            setAl((new Date()).toString());
            if (listaStatoOrdineLibero == undefined) {
                const param = {
                };
                RestServices().rest('POST', param, urlOrdiniLiberi.getListStatoOrdineLibero)
                    .then(result => {
                        if (result) {
                            setListaStatoOrdineLibero(result);
                        }
                    })
            }
            if (filtri && filtri.dal && filtri.al) cerca();
        }

    }, [auth])

    const cerca = () => {
        if (auth) {
            let flagOrdineB2B = false
            if (props.flagB2B != undefined && props.flagB2B == true)
                flagOrdineB2B = true
            const param = {
                dal: filtri.dal,
                al: filtri.al,
                stato: filtri.stato,
                flagOrdineB2B: flagOrdineB2B 
            }
            setIsVisible(true);
            param.dal = trasformaDateTimeInLocale(param.dal, true);
            param.al = trasformaDateTimeInLocale(param.al, true);
            RestServices().rest('POST', param, urlOrdiniLiberi.ordiniList).then(result => {
                setIsVisible(false);
                if (result ) {
                    setElencoOrdiniLiberi(result);
                } else if (result !== true) {
                    notify({ position: "center", width: "auto", message: "Oops, si è verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
                }
            })
            const lista = [
                {
                    ID: 1,
                    PRIORITA: '1',
                },
                {
                    ID: 2,
                    PRIORITA: '2',
                },
            ];
            setListaPriorita(lista);
        }
    }

    const propsTabOrdiniLiberi = {
        id: "elenco_ordini_liberi",
        dataSource: elencoOrdiniLiberi,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {
            totalItems: [{
                name: "TotaleOrdini",
                showInColumn: "Data Ora",
                displayFormat: "Ordini: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Ordini_Liberi"
        }
    }
    const columnsOrdiniLiberi = CreaColonne([
        { caption: "Data Ora", dataField: "dataEmissione", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Operatore", dataField: "operatore" },
        { caption: "Titolo", dataField: "titolo" },
        { caption: "Fornitore", dataField: "fornitore" },
        { caption: "Farmacie", dataField: "farmacie"},
        { caption: "Num. referenze", dataField: "referenze" },
        { caption: "Tot. Qta.", dataField: "quantita" },
        { caption: "Tot. prezzo", dataField: "prezzo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }},
        { caption: "Tot. costo", dataField: "costo", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Stato", dataField: "stato" },
        { caption: "Stato proposta", dataField: "statoProposta", visible: props.flagB2B == undefined || props.flagB2B == false ? true : false },
        { caption: "Stato approvazione", dataField: "statoApprovazione", visible: props.flagB2B == undefined || props.flagB2B == false ? false : true },
        { caption: "Data approvazione", dataField: "dataStatoB2B", visible: props.flagB2B == undefined || props.flagB2B == false ? false : true, format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Utente approvazione", dataField: "utenteStatoB2B", visible: props.flagB2B == undefined || props.flagB2B == false ? false : true },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [
                {
                    hint: "Informazioni Ordine Libero",
                    icon: "info",
                    onClick: (e: any) => {
                        setIdOrdInfo(e.row.data.idOrdine);
                        setTitoloOrdDelete(e.row.data.titolo);
                        setDialogInfoIsOpen(true);
                    }
                },
                {
                    hint: "Imposta come ricevuto",
                    icon: "download",
                    onClick: (e: any) => {
                        setIdOrdStato(e.row.data.idOrdine);
                        setTitoloOrdStato(e.row.data.titolo);
                        setMessageConferma("L'ordine " + e.row.data.titolo + " sta per essere impostato come ricevuto. Confermare?");
                        setIdStatoClicked(4);
                        setDialogIsOpen(true);
                    }
                },
                {
                    hint: "Elimina Ordine Libero",
                    icon: "trash",
                    onClick: (e: any) => {
                        const idStato = e.row.data.idStato;
                        if (idStato === 1 || idStato === 2) {
                            //setIdOrdDelete(e.row.data.idOrdine);
                            setTitoloOrdDelete(e.row.data.titolo);
                            setIdOrdStato(e.row.data.idOrdine);
                            setMessageConferma("L'ordine " + e.row.data.titolo + " sta per essere eliminato. Confermare?");
                            setIdStatoClicked(-1);
                            setDialogIsOpen(true);
                        } else if (idStato === 3) {
                            notify({ position: "center", width: "auto", message: "L'ordine è già stato trasmesso in farmacia e non può essere eliminato" }, "error", 4000)
                        }
                    }
            }]
        }
    ])

    const functionNext = () => {
        setDialogIsOpen(false);
        setStatoOrdine(idStatoClicked);
    }

    const eliminaOrdine = () => {
        RestServices().rest('POST', idOrdDelete, urlOrdiniLiberi.eliminaOrdine).then(result => {
            if (result === true) {
                if (elencoOrdiniLiberi) {
                    const newElenco = elencoOrdiniLiberi.filter(riga => {
                        return riga.idOrdine !== idOrdDelete
                    })
                    setElencoOrdiniLiberi(newElenco);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                }
            } else {
                notify({ position: "center", width: "auto", message: "Non è stato possibile azzerare le quantità. Si prega di riprovare" }, "error", 4000)
                return;
            }
        })
    }

    const setStatoOrdine = (idStato: number | undefined) => {

        if (idStato != undefined) {
            const param = {
                idOrdine: idOrdStato,
                stato: idStato
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.setStato).then(result => {
                if (result) {

                    if (result.esito === true) {
                        notify({ position: "center", width: "auto", message: result.message }, "success", 4000)
                        if (elencoOrdiniLiberi) {
                            if (idStato == 4) {
                                elencoOrdiniLiberi.forEach(function (item) {
                                    if (item.idOrdine == idOrdStato) {
                                        item.idStato = 4;
                                        item.stato = 'Ordine ricevuto'
                                    }
                                    setAggiornaTabDett(aggiornaTabDett + 1);
                                });
                            } else if (idStato == -1) {
                                const newElenco = elencoOrdiniLiberi.filter(riga => {
                                    return riga.idOrdine !== idOrdStato
                                })
                                setElencoOrdiniLiberi(newElenco);
                                setAggiornaTabDett(aggiornaTabDett + 1);
                            }

                            
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        return;
                    }
                }
            })
        }
  
    }

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            let path = basenameHostNewTabs + "apriOrdineLibero?" + rowDblClicked.data.idOrdine;

            if (props.flagB2B != undefined && props.flagB2B == true)
                path = basenameHostNewTabs + "apriOrdineB2B?" + rowDblClicked.data.idOrdine;

            window.open(path);
        }
    }

    const handleCloseConferma = () => setDialogIsOpen(false);

    const handleCloseDialogImpostazioni = () => setIsDialogImpostazioniOpen(false);

    const handleCloseInfo = () => setDialogInfoIsOpen(false);

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="2">
                    <label>Dal *</label>
                    <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                </Col>
                <Col sm="2">
                    <label>Al *</label>
                    <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={al} />
                </Col>
                <Col sm="2">
                    <label>Stato</label>
                    <Selezione id="stato" impostaFiltri={impostaFiltri} dataSource={listaStatoOrdineLibero} displayExpr="descrizione" valueExpr="idStato" searchEnabled={true} value={-1} />
                </Col>
                <Col sm="3">
                    <Button type="button" variant="success" id="btnCerca" onClick={cerca}>Cerca</Button>
                </Col>
            </Row>
        )
    }

    const creaRiepilogoFiltri = () => {
        return (
            <>
                {/*{(filtri.lstFar) && <span className="cerca">&nbsp;Farmacia: {elencoFarmSelez()}</span>}*/}
                {(filtri.dal) && <span className="cerca">&nbsp;Dal: {cambiaFormatoLocalDateTimeToEuropeo(filtri.dal)}</span>}
                {(filtri.al) && <span className="cerca">&nbsp;Al: {cambiaFormatoLocalDateTimeToEuropeo(filtri.al)}</span>}
                {(filtri.al) && <span className="cerca">&nbsp;Stato: {listaStatoOrdineLibero?.find(item => item.idStato == filtri.stato)?.descrizione}</span>}
            </>
        )
    }

    const onRowPrepared = (e: any) => {
        if (e.values) {
            if (e.data.idStato == 1 ) {
                e.rowElement.style.backgroundColor = '#95FFF8';
            }
            else if (e.data.idStato == 3) {
                e.rowElement.style.backgroundColor = '#AAFF9B';
            }
            else if (e.data.idStato == 2) {
                    e.rowElement.style.backgroundColor = '#FCFF92';
            }
            else if (e.data.idStato == 4) {
                e.rowElement.style.backgroundColor = '#6BC5FC';
            }
            
        }
    }
    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaOrdiniLiberi" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={openDrawer} setOpen={setOpenDrawer} />
                <Row>
                    <Col sm="6">
                        <div className="leftcontent">
                            <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                            &nbsp;
                            <span id="lblStatoCreato" title="Ordine creato" className="lblStatoCreato">Ordine creato</span>
                            &nbsp;
                             <span id="lblStatoTrasmessoParzialmente" title="Ordine trasmesso ad alcune farmacie" className="lblStatoTrasmessoParzialmente">Ordine trasmesso parzialmente</span>
                            &nbsp;
                            <span id="lblStatoTrasmesso" title="Ordine trasmesso a tutte le farmacie" className="lblStatoTrasmesso">Ordine trasmesso</span>
                            &nbsp;
                            <span id="lblStatoRicevuto" title="Ordine ricevuto" className="lblStatoRicevuto">Ordine ricevuto</span>
                            &nbsp;
                        </div>
                        </Col>
                    <Col sm='6' >
                        <Button variant="success" id="btnNuovoOrdLib" onClick={() => props.flagB2B != undefined && props.flagB2B == true ? window.open(basenameHostNewTabs + "apriOrdineB2B") : window.open(basenameHostNewTabs + "apriOrdineLibero")}>Nuovo Ordine</Button>
                        <Button variant="info" id="btImpostazioniOrdLib" onClick={() => props.flagB2B != undefined && props.flagB2B == true ? window.open(basenameHostNewTabs + "ordiniB2B/impostazioni?0") : window.open(basenameHostNewTabs + "ordiniLiberi/impostazioni?0")} >Impostazioni</Button>
                    </Col>
                </Row>
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        {aggiornaTabDett && <TabellaCompletaAlternate
                            propsTabella={propsTabOrdiniLiberi}
                            columns={columnsOrdiniLiberi}
                            onRowDblClick={onRowDblClick}
                            setTabellaInstance={setTabellaInstance}
                            wordWrapEnabled={true}
                            togliFilterPanel={true}
                            onRowPrepared={onRowPrepared}
                            rowAlternationEnabled={false}
                        />}
                    </Col>
                </Row>
                <DialogConferma
                    dialogIsOpen={dialogIsOpen}
                    message={messageConferma}
                    functionNext={functionNext}
                    handleCloseConferma={handleCloseConferma}
                />
                <DialogImpostazioniOrdLib
                    isDialogImpostazioniOrdLib={isDialogImpostazioniOpen}
                    setIsDialogImpostazioniOrdLib={setIsDialogImpostazioniOpen}
                    handleCloseDialogImpostazioniOrdLib={handleCloseDialogImpostazioni}
                    datiFarmacie={props.datiFarmacie}
                    priorita={listaPriorita}
                   
                />
                <DialogInfo
                    dialogInfoIsOpen={dialogInfoIsOpen}
                    idOrdine={idOrdInfo}
                    titoloOrdine={titoloOrdDelete}
                    handleCloseInfo={handleCloseInfo}
                />
            </div>
        </>
    )
}

export default ElencoOrdiniLiberi;