import FiltriVerticale, {
  ConvertForSelect,
} from "../../../dashboards/filters/FiltriVerticaleNewVersione";


export const createItemFilter = (newFarmaList: any, operatori: any, tipologiaVendita: any, gruppoMercFilters: any, ditte: any) => {
  return (
    [
      { type: "dateRange", idValue: "periodo", width: "auto", require: true },
      {
        type: "newSelectorFarma",
        idValue: "idFar",
        label: "Farmacie ",
        displayExpr: "text",
        dataSource: ConvertForSelect(newFarmaList),
        idValueGrid: "idFar",
        require: true,
      },
      {
        type: "selectMultiplaMoreColonNoCodice",
        noId: true,
        id: "operatori",
        label: "Operatori",
        displayExpr: "operatore",
        dataSource: operatori,
        idValueGrid: "operatori" as any,
        allowSelectAll: false
      },
      {
        type: "selectWithLevels",
        id: "venditaSSN",
        label: "Tipologia di vendita",
        noId: true,
        displayExpr: "descrizione",
        dataSource: tipologiaVendita,
        idValueGrid: "venditaSSN" as any,
      },
      {
        type: "selectWithLevels",
        id: "gruppoMerc",
        label: "Gruppo Merceologico",
        displayExpr: "descrizione",
        dataSource: gruppoMercFilters,
        idValueGrid: "gruppoMerc" as any,
      },
      {
        type: "selectMultiplaMoreColon",
        id: "ditta",
        label: "Ditta",
        displayExpr: "descrizione",
        dataSource: ditte,
        idValueGrid: "ditta" as any,
      },
      {
        type: "ckBox",
        idValue: "venditaDPC",
        label: "Includi DPC",
        dataSource: null,
        width: 200,
      },
      {
        type: "ckBox",
        idValue: "flagBusta",
        label: "Includi BUSTE",
        dataSource: null,
        width: 200,
      },
    ]
  )
}

export const createItemFilterAnaSconCate = (newFarmaList: any, operatori: any, codiciCategory: any, tipologiaSconto:any) => {
  return (
    [
      { type: "dateRange", idValue: "periodo", width: "auto", require: true },
      {
        type: "newSelectorFarma",
        idValue: "idFar",
        label: "Farmacie ",
        displayExpr: "text",
        dataSource: ConvertForSelect(newFarmaList),
        idValueGrid: "idFar",
        require: true,
      },
      {
        type: "selectMultiplaMoreColonNoCodice",
        noId: true,
        id: "operatori",
        label: "Operatori",
        displayExpr: "operatore",
        dataSource: operatori,
        idValueGrid: "operatori" as any,
        allowSelectAll: false
      },
      {
        type: "selectWithLevels",
        noId: true,
        id: "category",
        label: "Category",
        displayExpr: "descrizione",
        dataSource: codiciCategory,
        idValueGrid: "category" as any,
      },
      {
        type: "selectWithLevels",
        id: "tipologiaSconto",
        label: "Tipologia di sconto",
        noId: true,
        displayExpr: "descrizione",
        dataSource: tipologiaSconto,
        idValueGrid: "tipologiaSconto" as any,
      },
      
    ]
  )
}

export const createItemFilterAnaSconGrupMerc = (newFarmaList: any, operatori: any, gruppoMercFilters: any, tipologiaSconto:any) => {
  return (
    [
      { type: "dateRange", idValue: "periodo", width: "auto", require: true },
      {
        type: "newSelectorFarma",
        idValue: "idFar",
        label: "Farmacie ",
        displayExpr: "text",
        dataSource: ConvertForSelect(newFarmaList),
        idValueGrid: "idFar",
        require: true,
      },
      {
        type: "selectMultiplaMoreColonNoCodice",
        noId: true,
        id: "operatori",
        label: "Operatori",
        displayExpr: "operatore",
        dataSource: operatori,
        idValueGrid: "operatori" as any,
        allowSelectAll: false
      },
      {
        type: "selectWithLevels",
        id: "gruppoMerc",
        label: "Gruppo Merceologico",
        displayExpr: "descrizione",
        dataSource: gruppoMercFilters,
        idValueGrid: "gruppoMerc" as any,
      },
      {
        type: "selectWithLevels",
        id: "tipologiaSconto",
        label: "Tipologia di sconto",
        noId: true,
        displayExpr: "descrizione",
        dataSource: tipologiaSconto,
        idValueGrid: "tipologiaSconto" as any,
      },
      
    ]
  )
}


export const createItemFilterAnalRicette = (newFarmaList: any, tipologiaVendita: any) => {
  return (
    [
      { type: "dateRange", idValue: "periodo", width: "auto", require: true },
      {
        type: "newSelectorFarma",
        idValue: "idFar",
        label: "Farmacie ",
        displayExpr: "text",
        dataSource: ConvertForSelect(newFarmaList),
        idValueGrid: "idFar",
        require: true,
      },
      {
        type: "selectWithLevels",
        id: "venditaSSN",
        label: "Tipologia di vendita",
        noId: true,
        displayExpr: "descrizione",
        dataSource: tipologiaVendita,
        idValueGrid: "venditaSSN" as any,
      },

    ]
  )
}


export const createItemFilterAnaTransf = (newFarmaList: any) => {
  return (
    [
      { type: "dateRange", idValue: "periodo", width: "auto", require: true },
      {
        type: "newSelectorFarma",
        idValue: "idFar",
        label: "Farmacie ",
        displayExpr: "text",
        dataSource: ConvertForSelect(newFarmaList),
        idValueGrid: "idFar",
        require: true,
      },
     
      
    ]
  )
}

export const createItemFilterRepVenEffet = (newFarmaList: any, giorniSettimana: any) => {
    return (
        [
            { type: "dateRange", idValue: "periodo", width: "auto", require: true },
            {
                type: "newSelectorFarma",
                idValue: "idFar",
                label: "Farmacie ",
                displayExpr: "text",
                dataSource: ConvertForSelect(newFarmaList),
                idValueGrid: "idFar",
                require: true,
            },
            {
                type: "selectMultipla",
                id: "giorniSettimana",
                label: "Giorni settimana",
                displayExpr: "descrizione",
                dataSource: giorniSettimana,
                idValueGrid: "giorniSettimana" as any,
            },

        ]
    )
}