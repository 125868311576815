import React, { useEffect, useState ,useRef } from 'react'
import DataGrid , {FilterRow , Selection ,Paging ,Pager ,Export ,SearchPanel ,HeaderFilter ,MasterDetail} from 'devextreme-react/data-grid';
import { dxToolbarOptions } from 'devextreme/ui/toolbar'
import { LoadPanel } from 'devextreme-react/load-panel';
import { RestServices } from '../../services/restServices'
import { urlLayoutTabelle } from '../../contenuti/costanti'
import { setStatoTabella } from '../../funzioni/operazioniSuTabelle'


import './style.css';


interface NewtabellaProps  {
    idTabella:string,
    sync?:boolean
    looading?:boolean,
    dataSource:any,
    colonne:any,
    filterRow?:boolean,
    searchPanel?:boolean,
    headerFilter?:boolean,
    onclickRow?: (selectedRowsData: any) => void,
    selectedValue?: (selectedRowsData: any) => void,
    exportT?:any
    columnC?:boolean
    activeSaver?:boolean
    multyp?:boolean
    keyExpr?:string
    defaultSelectedRowKeys?:any
    selectedRowKeys?:any
    summary?:any
    height?:string|number,
    width?:string|number,
    tabelDett?:boolean,
    allowColumnResizing?:boolean,
    hideColumnHeaders?:boolean,
    hidePager?:boolean,
    componetPlus?:any,
    specialPd?: number,
    onCellPrepared?: any,
    pageSize?: number
}


const NewTabella:React.FC<NewtabellaProps> = ({
    idTabella,
    sync,
    looading,
    dataSource,
    colonne,
    filterRow,
    searchPanel,
    headerFilter,
    onclickRow,
    selectedValue,
    exportT,
    activeSaver,
    columnC,
    multyp,
    keyExpr,
    defaultSelectedRowKeys,
    selectedRowKeys,
    summary,
    height,
    width,
    tabelDett,
    componetPlus,
    allowColumnResizing,
    hideColumnHeaders,
    specialPd,
    hidePager,
    onCellPrepared,
    pageSize
}) => {

    const tabRef = useRef<any>(null);
    const [carica,setCarica] = useState(false)



    const prova = () =>{
        let uuuu = {
            "columns": [
              {
                "visibleIndex": 0,
                "dataField": "dataVendita",
                "dataType": "date",
                "visible": true,
                "sortOrder": "desc",
                "sortIndex": 0
              },
              {
                "visibleIndex": 1,
                "dataField": "importoVendita",
                "visible": false
              },
              {
                "visibleIndex": 2,
                "dataField": "scontoVendita",
                "visible": true
              },
              {
                "visibleIndex": 3,
                "dataField": "importoAltro",
                "visible": true
              },
              {
                "visibleIndex": 4,
                "dataField": "operatore",
                "visible": true
              },
              {
                "visibleIndex": 5,
                "dataField": "terminale",
                "visible": true
              }
            ],
            "allowedPageSizes": [],
            "filterPanel": {
              "filterEnabled": true
            },
            "filterValue": null,
            "searchText": "",
            "pageIndex": 0,
            "pageSize": 100
          }
        tabRef.current.instance.state(uuuu);
    }
    
    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        //console.log('salvo: '+idTabella);
                        
                        let stato = tabRef.current.instance.state();
                        setStatoTabella(idTabella, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: ()=>getStatoTabella()
                        
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                            deleteStatoTabella(idTabella);
                        }
                    }
                }
            )
        }
    }


    const getStatoTabella = () => {
      if (!sync) return;
      RestServices().rest('POST', idTabella, urlLayoutTabelle.urlGetLayout).then(result => {
          if (result) {
              //console.log(result);
              tabRef.current.instance.state(result);
          }
      });
    }

    const deleteStatoTabella = (id: string) => {
      if (!id) return;
      setCarica(true)
      RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {
          
          if (result === true) {
              setCarica(false)
          }
      });
  }

    useEffect(()=>{
      getStatoTabella()
    },[idTabella])

    const curr = JSON.stringify(dataSource, function (key, value) {
        if (key == "importoVendita" ) {
          return  "€" + value ;
        } 
        
      });

      const allowedPageSizes = [5, 10, 20, 50];
    
    return (
        <>
        <div id='tabellaCss'>
         { looading ? (
            <div id='qui'>
            <LoadPanel
              shadingColor="rgba(0,0,0,0.4)"
              shading={false}
              visible={true}
              showPane={true}
              showIndicator={true}
              position={{ of: '#qui' }}
            />
            </div>
         ) : (
            <div style={{marginTop:5, padding:specialPd ? specialPd :10,borderRadius:10, background:'#fff'}}>
                {dataSource.length == 1500 ? (
                <div >
                    <p>*i dati sono stati limitati a 1500 elementi, affina la ricerca per dati migliori</p>
                </div>
            ) : null}
            {carica ? (
              null
            ) : (
              <DataGrid
              height={height ? height : '70vh'}
              // width={width ? width : '94vw'}
              id={idTabella}
              ref={tabRef}
              selectedRowKeys={selectedRowKeys ? selectedRowKeys : undefined}
              keyExpr={keyExpr ? keyExpr : undefined}
              dataSource={dataSource}
              defaultColumns={colonne}
              showBorders={true}
              onRowClick={onclickRow}
              onSelectionChanged={selectedValue}
              showRowLines={true}
              hoverStateEnabled={true}
              rowAlternationEnabled
              allowColumnResizing={allowColumnResizing ? false : true}
              wordWrapEnabled={false}
              columnAutoWidth={true}
              export={exportT}
              columnChooser={ columnC ? { enabled: true } : {} }
              onToolbarPreparing={activeSaver ? onToolbarPreparing : undefined }
              showColumnHeaders={hideColumnHeaders ? false : true}
              onCellPrepared={onCellPrepared ? onCellPrepared : null}
              scrolling={{
                useNative:false,
                showScrollbar:'always'
              }}
              
              
          >
          {summary ? summary : null}
          <Paging defaultPageSize={pageSize} />
          
          <Pager
          visible={hidePager ? false : true}
          showPageSizeSelector={true}
          allowedPageSizes= {allowedPageSizes}      
          showInfo={true} />
          <FilterRow visible={ filterRow ? true : false}  />
          <HeaderFilter visible={ headerFilter ? true : false}  />
          <Export enabled={true} />
          <SearchPanel visible={ searchPanel ? true : false }
            width={150}
            placeholder="Cerca..." 
            //searchVisibleColumnsOnly={true}
            />
          <Selection 
          mode={multyp ? "multiple" : 'single'} 
          />
          {tabelDett ? (
            <MasterDetail
            enabled={true}
            component={componetPlus}
          />
          ) : null}
          
               
          </DataGrid>
            )}
                   
        </div>  
         )}      
            
        </div>
        </>
    )
}

export default NewTabella

