import { ListaFarmacieInterface } from "../../interfaces/farmacie"
import React, { useEffect, useState } from 'react'
import notify from 'devextreme/ui/notify'
import { RestServices } from "../../services/restServices"
import { urlCommon, urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
import { DialogConferma, Loading } from "../../widget/Notifications"
import { NewTabella } from "../../widget/nuova_tabella/newVersion"
import { Filtri } from "../consultazione/interface"
import { trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili"
import Ricerca from "../../widget/nuova_ricerca"
import { DialogAnaFornCenInvia } from "./dialog/popup_ana_forncen_invia"
import AnaFornitoreCentr from "./Anagrafica_fornitore_centralizzato"
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"

const AnaFornitoriCentr = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [objtipo, setObjtipo] = useState<any>(null)
    const [openPopEdit, setOpenPopEdit] = useState<boolean>(false)
    const [openPopInvia, setOpenPopInvia] = useState<boolean>(false)
    const [openPopDisabilitaAbilita, setOpenPopDisabilitaAbilita] = useState<boolean>(false)
    const [edit, setImmediate] = useState<boolean>(false)
    const [messDisabilitaAbilita, setMessDisabilitaAbilita] = useState<string>('')
    const [idDisabilitaAbilita, setIdDisabilitaAbilita] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [avviso, setAvviso] = useState<string>();
    const [tipoAmministrativa, setTipoAmministrativa] = useState<any>();
    const [tipoPagamento, setTipoPagamento] = useState<any>();
    const [tipoSettoreSanita, setTipoSettoreSanita] = useState<any>();
    const [tipoStatoApplicativo, setTipoStatoApplicativo] = useState<any>();
    const [tipoCategoriaFornitore, setTipoCategoriaFornitore] = useState<any>();
    const [attivo, setAttivo] = useState<boolean>();
    const [isConfermaOpen, setIsConfermaOpen] = useState(false);
    const handleCloseConferma = () => setOpenPopDisabilitaAbilita(false);
    const [paramsFilter, setParamsFilter] = useState<any>();
    const [renderp, setRenderp] = useState<any>();

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {

            getTipologiaAmministrativa();
            getTipologiaCategoriaFornitore();
            getTipologiaPagamento();
            getTipologiaSettoreSanita();
            getTipologiaStatoApplicativo();

        }
    }, [auth]);

    const columns = [

        { caption: "Codice", dataField: "codice" },
        { caption: "Ragione Sociale", dataField: "ragioneSociale", },
        { caption: "Partita IVA", dataField: "partitaIva", },
        { caption: "Indirizzo", dataField: "indirizzo" },
        { caption: "Citta", dataField: "citta" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Categoria Fornitore", dataField: "categoria" },
        { caption: "Trattato", dataField: "bTrattata", dataType: "boolean" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        //console.log(e.row.data);
                        setObjtipo(e.row.data)
                        setRenderp(2)

                    },
                    visible: (x: any) => visibleB(x)
                },
                {
                    hint: "Invia",
                    icon: "exportselected",
                    onClick: (e: any) => {
                        //console.log(e.row.data);
                        setObjtipo(e.row.data)
                        setOpenPopInvia(true)

                    },
                    visible: (x: any) => visibleB(x)
                },
                {
                    hint: "Disabilita",
                    icon: "isblank",
                    onClick: (e: any) => {
                        setIdDisabilitaAbilita(e.row.data.idAnaGenFornCen)
                        setMessDisabilitaAbilita("Disabilitare l\'elemento selezionato?")
                        setOpenPopDisabilitaAbilita(true)
                        setAttivo(e.row.data.attivo);
                    },

                }

            ]
        },
    ];

    const visibleB = (e: any) => {
        if (e.row.data.attivo == 0) {
            return false;
        } else {
            return true;
        }
    }

    const itemsFields = [
        { type: 'string', idValue: 'ragioneSociale', label: 'Ragione Sociale', dataSource: null, width: 350 },
        { type: 'string', idValue: 'partitaIva', label: 'Partita IVA', dataSource: null, width: 200 },
        { type: 'ckBox', idValue: 'trattati', label: 'Solo fornitori trattati', dataSource: null, width: 200, defValue: true },
        { type: 'ckBox', idValue: 'filtraTutti', label: 'Includi posizioni cancellate', dataSource: null, width: 200 },


    ]

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro }
        //console.log(params);


        if (objFiltro.partitaIva || objFiltro.partitaIva != '') {
            params.partitaIva = objFiltro.partitaIva
        }
        else {
            delete params.partitaIva
        }

        if (objFiltro.ragioneSociale || objFiltro.ragioneSociale != '') {
            params.ragioneSociale = objFiltro.ragioneSociale
        }
        else {
            delete params.ragioneSociale
        }
        if (objFiltro.partitaIva || objFiltro.partitaIva != '') {
            params.partitaIva = objFiltro.partitaIva
        }
        else {
            delete params.partitaIva
        }

        setParamsFilter(params)

        getFornitoriAction(params);


    }

    const getFornitoriAction = (params: any) => {
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListFornCen)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        let avviso: boolean = false;
                        result.response.forEach((item: any) => {
                            if (item.bTrattata == true && item.partitaIva == undefined) {
                                avviso = true;
                            }
                        });
                        if (avviso)
                            setAvviso("ATTENZIONE: ci sono fornitori trattati senza partita iva!");
                        if (result.messagge)
                            notify({ position: "center", width: "auto", message: result.message }, "success", 4000)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const getTipologiaAmministrativa = () => {
        setLoading(true)
        let params = {};
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListTipoAmministrativa)
                .then(result => {
                    if (result?.esito) {
                        setTipoAmministrativa(result.response)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        //notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const getTipologiaPagamento = () => {
        setLoading(true)
        let params = {};
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListTipiPagamentoNuovo)
                .then(result => {
                    if (result?.esito) {
                        setTipoPagamento(result.response)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        //notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const getTipologiaSettoreSanita = () => {
        setLoading(true)
        let params = {};
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListTipoSettoreSanita)
                .then(result => {
                    if (result?.esito) {
                        setTipoSettoreSanita(result.response)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        //notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const getTipologiaStatoApplicativo = () => {
        setLoading(true)
        let params = {};
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListTipoStatoApplicativo)
                .then(result => {
                    if (result?.esito) {
                        setTipoStatoApplicativo(result.response)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        //notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const getTipologiaCategoriaFornitore = () => {
        setLoading(true)
        let params = {};
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListTipoCategoriaFornitore)
                .then(result => {
                    if (result?.esito) {
                        setTipoCategoriaFornitore(result.response)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        //notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const onRowPrepared = (e: any) => {

        if (!e.data)
            return;
        if (e.data.associazioneFarmacie === false) {
            e.rowElement.style.backgroundColor = 'rgb(252,230,232,255)';
        }
        else if (e.data.associazioneFarmacie === true)
            e.rowElement.style.backgroundColor = 'rgb(221,244,239,255)';


    }

    const onCellPrepared = (e: any) => {
        if (e.column.type === "buttons")
            if (e.data) {

                if (e.data.attivo === false) {
                    //var elm = e.cellElement.find('a:contains(Edit)');
                    e.cellElement.children[0].className = "dx-link dx-icon-isnotblank dx-link-icon";
                    e.cellElement.children[0].attributes[2].nodeValue = "Abilita";
                    //e.cellElement.innerHTML = e.cellElement.innerHTML.replace("Nascondi", "Visualizza");
                    setMessDisabilitaAbilita("In seguito a questa operazione il fornitore sara' abilitato. Si vuole procedere?")
                }
                else if (e.data.attivo === true) {
                    //var elm = e.cellElement.find('a:contains(Edit)');
                    e.cellElement.children[2].className = "dx-link dx-icon-isblank dx-link-icon";
                    e.cellElement.children[2].attributes[2].nodeValue = "Disabilita";
                    //e.cellElement.innerHTML = e.cellElement.innerHTML.replace("Nascondi", "Visualizza");
                    setMessDisabilitaAbilita("In seguito a questa operazione il fornitore sara' disabilitato. Si vuole procedere?")
                }
            }


    }

    const ricarica = () => {
        setOpenPopEdit(false)
        if (paramsFilter)
            getFornitoriAction(paramsFilter)
        
    }

    const disabilitaAbilita = () => {

        setLoading(true)
        let params = { idAnaGenerale: idDisabilitaAbilita, abilita: !attivo };
        if (auth) {
            RestServices().rest('POST', params, urlCommon.abilitaDisabilitaFornitore)
                .then(result => {
                    if (result?.esito) {
                        setTipoPagamento(result.response)
                        setLoading(false)
                        setOpenPopDisabilitaAbilita(false)
                        ricarica()
                    } else {
                        //console.log(result);
                        //notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                        setOpenPopDisabilitaAbilita(false)
                    }
                })

        }

    }
    const render = () => {
        switch (renderp) {
            case 1:
                return (
                    <>
                        <Loading isVisible={loading} />
                        <div className='title'>
                            <h3> ANAGRAFICA FORNITORI  <span className='subt' > Anagrafica fornitori centralizzata</span> </h3>
                        </div>
                        <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />

                        <div style={{ gap: 16, display: 'flex', flexWrap: 'wrap', background: 'white', padding: 10, borderRadius: 10, marginTop: 4 }}>
                            <div> <p style={{ fontSize: 16, margin: 0, color: 'red' }}><strong>{avviso}</strong> </p>  </div>
                        </div>
                        <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                            <div className="col-md-8">
                                <Row>
                                    <Col md="2">
                                        <div className='title2'>
                                            <h6> Legenda Colori: </h6>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className='legAf1'>
                                            <h6 >  Associata a tutte le farmacie del gruppo </h6>
                                        </div>
                                    </Col >
                                    <Col md="4">
                                        <div className='legAf2'>
                                            <h6> Non associata a tutte le farmacie del gruppo </h6>
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                            <div className="col-md-4 text-right">
                                <Button className="float-right" id='btnNuovoFornitore' variant="success" onClick={() => { setRenderp(3) }} >
                                    NUOVO FORNITORE
                                </Button>
                            </div>

                        </div>
                       
                        <NewTabella
                            idTabella='ana_for_cen'
                            sync
                            colonne={columns}
                            dataSource={tabellaPrincipale}
                            filterRow
                            searchPanel={true}
                            onclickRow={() => null}
                            exportT={{
                                enabled: true,
                                fileName: "ana_for_cen"
                            }}
                            activeSaver={true}
                            columnC={true}
                            onRowPrepared={onRowPrepared}
                            onCellPrepared={onCellPrepared}
                        />

                        {openPopInvia ?
                            <DialogAnaFornCenInvia
                                idAnaGenFornCen={objtipo?.idAnaGenFornCen}
                                open={openPopInvia}
                                close={() => { setOpenPopInvia(false); setObjtipo({}) }}
                            /> : null
                        }



                        <DialogConferma
                            dialogIsOpen={openPopDisabilitaAbilita}
                            message={attivo ? "In seguito a questa operazione il fornitore sara' disabilitato. Si vuole procedere?" : "In seguito a questa operazione il fornitore sara' abilitato. Si vuole procedere?"}
                            functionNext={disabilitaAbilita}
                            handleCloseConferma={handleCloseConferma}
                        />
                    </>
                )
            case 2:
                
                return (
                    <AnaFornitoreCentr
                        typeEdit={edit}
                        edit={objtipo}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        tipologiaAmministrativa={tipoAmministrativa}
                        tipologiaCategoriaFornitore={tipoCategoriaFornitore}
                        tipologiaPagamento={tipoPagamento}
                        tipologiaSettoreSanita={tipoSettoreSanita}
                        tipologiaStatoApplicativo={tipoStatoApplicativo}
                        setIndietro={() => { setRenderp(1); ricarica() }}
                        typeInsert={false }
                    >
                    </AnaFornitoreCentr>
                )       

            case 3:
                return (
                    <AnaFornitoreCentr
                        typeEdit={edit}
                        edit={undefined}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        tipologiaAmministrativa={tipoAmministrativa}
                        tipologiaCategoriaFornitore={tipoCategoriaFornitore}
                        tipologiaPagamento={tipoPagamento}
                        tipologiaSettoreSanita={tipoSettoreSanita}
                        tipologiaStatoApplicativo={tipoStatoApplicativo}
                        setIndietro={() => { setRenderp(1); ricarica() }}
                        typeInsert={true}
                    >
                    </AnaFornitoreCentr>
                )       
            default:
                return (
                    <>
                        <Loading isVisible={loading} />
                        <div className='title'>
                            <h3> ANAGRAFICA FORNITORI  <span className='subt' > Anagrafica fornitori centralizzata</span> </h3>
                        </div>
                        <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />

                        <div style={{ gap: 16, display: 'flex', flexWrap: 'wrap', background: 'white', padding: 10, borderRadius: 10, marginTop: 4 }}>
                            <div> <p style={{ fontSize: 16, margin: 0, color: 'red' }}><strong>{avviso}</strong> </p>  </div>
                            
                        </div>
                        <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                            <div className="col-md-8">
                                <Row>
                                    <Col md="2">
                                        <div className='title2'>
                                            <h6> Legenda Colori: </h6>
                                        </div>
                                    </Col>
                                    <Col md="4">
                                        <div className='legAf1'>
                                            <h6 >  Associata a tutte le farmacie del gruppo </h6>
                                        </div>
                                    </Col >
                                    <Col md="4">
                                        <div className='legAf2'>
                                            <h6> Non associata a tutte le farmacie del gruppo </h6>
                                        </div>
                                    </Col>
                                    
                                </Row>

                            </div>
                            <div className="col-md-4 text-right">
                                <Button className="float-right"  id='btnNuovoFornitore' variant="success" onClick={() => { setRenderp(3) }} >
                                    NUOVO FORNITORE
                                </Button>
                            </div>

                        </div>
                        <NewTabella
                            idTabella='ana_for_cen'
                            sync
                            colonne={columns}
                            dataSource={tabellaPrincipale}
                            filterRow
                            searchPanel={true}
                            onclickRow={() => null}
                            exportT={{
                                enabled: true,
                                fileName: "ana_for_cen"
                            }}
                            activeSaver={true}
                            columnC={true}
                            onRowPrepared={onRowPrepared}
                            onCellPrepared={onCellPrepared}
                        />

                        {openPopInvia ?
                            <DialogAnaFornCenInvia
                                idAnaGenFornCen={objtipo?.idAnaGenFornCen}
                                open={openPopInvia}
                                close={() => { setOpenPopInvia(false); setObjtipo({}) }}
                            /> : null
                        }



                        <DialogConferma
                            dialogIsOpen={openPopDisabilitaAbilita}
                            message={attivo ? "In seguito a questa operazione il fornitore sara' disabilitato. Si vuole procedere?" : "In seguito a questa operazione il fornitore sara' abilitato. Si vuole procedere?"}
                            functionNext={disabilitaAbilita}
                            handleCloseConferma={handleCloseConferma}
                        />
                    </>
                )
        }
        
    }

    return render()

    
}

export default AnaFornitoriCentr