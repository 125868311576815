import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import { DialogConferma, Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
// import {DialogGest} from "./dialog/pop_ges_op_cent"
// import {DialogVisuOper} from "./dialog/popup_visu_oper"
import './style.css';



const GestClientiFarm = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const columns = [
        { caption: "Abilitato" , dataField: "abilitato",width:70 },
        { caption: "Operatore", dataField: "operatore" },
        { caption: "Nome", dataField: "nome" },  
        { caption: "Codice Fiscale", dataField: "codiceFiscale" },      
        { caption: "Azioni",  type: "buttons", buttons: [
            {
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {
                    //console.log(e.row.data);
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setOpenPop(true)
                    
                }
            },
            {
                hint: "Visualizza",
                icon: "search",
                onClick: (e: any) => {
                    //console.log(e.row.data);
                    setObjtipo(e.row.data)
                    setOpenPopVisualOpe(true)
                    
                }
            },
            {
                hint: "Disabilita",
                icon: "trash",
                onClick: (e: any) => {
                    setIddell(e.row.data.codice)
                    setMessDel("l'operatore "+e.row.data.operatore+" Sta per essere eliminato. Confermare?")
                    setPopDel(true)
                    
                }
            }]  },
    ];

    
    const auth = useAuth(urlConsultazione.auth); 
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [idFarmacia,setIdFarmacia] = useState<number | null>(null)
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [popDel,setPopDel] = useState<boolean>(false)
    const [openPopVisualOpe,setOpenPopVisualOpe] = useState<boolean>(false)
    const [idDel,setIddell] = useState<number>(0)
    const [messDel,setMessDel] = useState<string>('')
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)


    const getOperatoriAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {idFarmacia  : idFarmacia} , urlConsultazione.getListOperatoreGruppo)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })    
         }
    }


    const removeTipo = () =>{
        if (auth) {
            RestServices().rest('POST', { codice : idDel} , urlConsultazione.deleteOperatoreGruppo)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       ricarica()          
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })   
         } 
    }

   

    const ricarica = () =>{
        setOpenPop(false)  
        setOpenPopVisualOpe(false)
        getOperatoriAction()
    }


   useEffect(()=>{
        getOperatoriAction()
    },[auth])
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


  
   
    return (
        <>
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> GESTIONE CENTRALIZZATA CLIENTI  <span className='subt' ></span> </h3>
             </div>
        
             <Button  className='tipo_absolute' variant="primary" onClick={()=>setOpenPop(true)} >
               NUOVO
             </Button>

        <NewTabella 
                idTabella='gest_clienti_farm'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                onclickRow={()=>null}          
                exportT={{
                    enabled: true,
                    fileName: "gest_clienti_farm"
                }}
                activeSaver={true}
                columnC={true}              
            />
      </>

    )
}

export default  GestClientiFarm