import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../../interfaces/farmacie";
import { RestServices } from "../../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../../consultazione/interface";
import { AplicaValoreTO } from "../../../../funzioni/operazioniSuTabelle";
import { transformCategory3 } from "../../util/trasformation";
import { urlBancaDati, urlConsultazione, urlReport } from "../../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../../widget/nuova_tabella/newBusineVersion";
import ".././style.css";

const GalileoAcquistiInterni = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [ditte, setDitte] = useState<any[]>([]);
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const chartRef = useRef<any>(null);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [category, setCategory] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
  

    const dashboard32 = { dashboardID: 32 };

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall32 = {
                ...dashboard32,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            //console.log('mostro call');
            //console.log(dashboardCall1);

            RestServices()
                .rest("POST", dashboardCall32, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {

                        const anno = params.periodo.dal.substring(0, 4)

                        
                        let valoriTabella1 = transformCategory3(result["122"], anno);
                        console.log(valoriTabella1);
                        setValoriTabella1(valoriTabella1);
                       
                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1� gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 � gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });


        }
    };


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);


    const filters = () => {
        if (auth) {
            RestServices().rest('POST', "", urlBancaDati.getCategory)
                .then(result => {
                    // console.log('vediamoooo categorie');
                    // console.log(result);
                    if (result) setCategory(result);
                })


            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );
                        // console.log('da togliere vediamo ditte');
                        // console.log(ditteWithCodiceDescrizione);

                        setDitte(ditteWithCodiceDescrizione);
                    }
                });


        }

    };

    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
    ];

    

    const Acquisti = [
        { caption: "Category Lv. 1", dataField: "PRO_DESC_CATEGORY1AP", wdith: "auto" },
        { caption: "Category Lv. 2", dataField: "PRO_DESC_CATEGORY2AP", wdith: "auto" },
        { caption: "Category Lv. 3", dataField: "PRO_DESC_CATEGORY3AP", wdith: "auto" },
        { caption: "Category Lv. 4", dataField: "PRO_DESC_CATEGORY4AP", width: 90 },
        { caption: "Prodotto", dataField: "PRO_DESCRIZIONE_ESTESAAP", width: "auto" },
        { caption: "Min San", dataField: "PRO_COD_PRODAP", width: "auto" },
        { caption: "Costo medio d'acquisto", dataField: "COSTO_ACQUISTO_MEDIOCR", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Costo medio d'acquisto anno prec.", dataField: "COSTO_ACQUISTO_MEDIOAP", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Delta %", dataField: "COSTO_ACQUISTO_MEDIO_PUNTUALECR", width: "auto", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d") },
    ];

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabellaAcquistiInterni");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabellaAcquistiInterni.csv"
                );
            });
        });
    };

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    
    return (
        <>

            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>Acquisti</h2>
                        </div>


                        {/* flexbox */}

                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>


                        {/* tabella*/}
                        <div style={{ marginTop: 50 }}>

                            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 17, marginTop: 10 }}>Tabella Dettaglio</h6>


                            <NewTabella
                                height={"80vh"}
                                idTabella="analisi_prodotti"
                                sync
                                colonne={Acquisti}
                                dataSource={valoriTabella1}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "GalileoAcquistiInterni",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem
                                            column="COSTO_ACQUISTO_MEDIOCR"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                        <TotalItem
                                            column="COSTO_ACQUISTO_MEDIOAP"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                    </Summary>
                                }
                            />
                        </div>
                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>

        </>
    );
};

export default GalileoAcquistiInterni;
