import {Chart,CommonSeriesSettings,Export,Format,Label,Legend,Series} from 'devextreme-react/chart'
import PieChart, { Connector, Font } from 'devextreme-react/pie-chart'
import notify from 'devextreme/ui/notify'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { Loading } from '../../widget/Notifications'
import Ricerca from "../../widget/nuova_ricerca"
import { urlConsultazione } from "../costanti"
import './style.css'

import stc from 'string-to-color'
import { Filtri } from './interface'

//function getRandomColor(name:any) {
//  // get first alphabet in upper case
//  const firstAlphabet = name.charAt(0).toLowerCase();
 
//  // get the ASCII code of the character
//  const asciiCode = firstAlphabet.charCodeAt(0);
 
//  // number that contains 3 times ASCII value of character -- unique for every alphabet
//  const colorNum = asciiCode.toString() + asciiCode.toString() + asciiCode.toString();
 
//  var num = Math.round(0xffffff * parseInt(colorNum));
//  var r = num >> 16 & 355;
//  var g = num >> 8 & 355;
//  var b = num & 355;
 
//  return {
//    color: 'rgb(' + r + ', ' + g + ', ' + b + ')',
//    character: firstAlphabet.toUpperCase()
//  };
//}

const ReportGrafic = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

 

    const auth = useAuth(urlConsultazione.auth);
    const [loading,setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [paramsFilter, setParamsFilter] = useState<Filtri>();
    const [chart, setChart] = useState([])
    const [conti, setConti] = useState<any>([])
    const [objColor, setObjColor] = useState<any>({})
    const [isVisible, setIsVisible] = useState(false);
    


    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }
        

        if (params.idConto) {

            params.idConto = params.idConto;

        }

        let newArr: number[] = []
        params.idConto.forEach((element: any) => {
            newArr.push(element.value);
        });


        const model = {
            listConti: newArr
        }

        //console.log(newArr)

        setParamsFilter(params)
        
        setIsVisible(true)

        if (auth) {
            RestServices().rest('POST', model, urlConsultazione.getChart)
                .then(result => {
                    setIsVisible(false);

                    if (result) {
                        setIsVisible(false);
                        setChart(result.response);

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }

                })
        }

    }

    const ConvertForSelect = (arr:any) =>{
      let newArr:any = []
      arr.forEach((element:any) => {
          //console.log(element);
          newArr.push({ value: element.id, label: element.conto })
      });
      return newArr
  }

    const itemsFields = [
        { type: 'select2', idValue: 'idConto', label: 'Conto ', displayExpr: 'descrizione', dataSource: ConvertForSelect(conti), width: 350, require: true },
      //{type:'date' , idValue:'dal' , label:'Dal' , defValue : new Date(new Date().getFullYear()+'-01-01') , require:false  },
      //{type:'date' , idValue:'al' , label:'Al' ,  defValue : new Date()},  
    ]

    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {                    
                      // console.log(result.response);
                       setNewFarmaList(result.response)                    
                   }else{                                
                       //console.log(result);                   
                   }
               })     
       }
   }

    const getConto = () => {

        let model = {}

        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListConti)
                .then((result) => {
                    if (result?.esito) {

                        setConti(result.response);

                        //console.log(result.response)

                        let newArr: number[] = []
                        result.response.forEach((element: any) => {
                            newArr.push(element.id);
                        });

                        model = {
                            listConti: newArr
                        }

                        //console.log(model)

                    } else {
                        // handle error
                    }

                    RestServices().rest('POST', model, urlConsultazione.getChart)
                        .then(result => {
                            setIsVisible(false);

                            if (result) {
                                setIsVisible(false);
                                setChart(result.response);

                            } else {

                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                setIsVisible(false);
                            }
                        })

                });
        }
    };
    

   useEffect(()=>{
       farmaList()
       getConto()
   }, [auth])



    
    useEffect(() => {   
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    function customizeText(arg: { valueText: any; percentText: any; }) {
        return `${arg.valueText} (${arg.percentText})`;
      }


    
    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> GRAFICI CONTI<span className='subt'></span> </h3>
            </div>
            {<Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />}
            {chart ? (
                <Chart id="chart"
                    title="Andamento Conti Bancari"
                    dataSource={chart}
                >
                    <CommonSeriesSettings
                        argumentField="mese"
                        type="line"
                        hoverMode="allArgumentPoints"
                        selectionMode="allArgumentPoints"
                    >
                        <Label visible={true}>
                            <Format type='currency' currency='EUR' precision={0} />
                        </Label>
                    </CommonSeriesSettings>
                    <Series
                        valueField="saldoContoPrevious"
                        type="bar"
                        name="2023"
                        color="rgb(215, 140, 245)"
                    />
                    <Series
                        argumentField="mese"
                        valueField="saldoContoCurrent"
                        name="2024"
                        color="rgb(25, 231, 255)"
                    />
                    <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                    <Export enabled={true} />
                </Chart>
            ) : null}

            <br />

            <div style={{ justifyContent: 'center', marginBottom: 20, marginTop: 60 }}>

          {/*<div style={{ marginLeft : '10%' } }>*/}
            <PieChart id="pie"
                title="Saldo Corrente"
                    dataSource={conti}
                    width={400}
                customizePoint={(point) => {
                    let colll = stc(point.argument);
                    return {
                        color: colll
                    };
                }}
            >
                <Legend
                    orientation="horizontal"
                    itemTextPosition="right"
                    horizontalAlignment="center"
                    verticalAlignment="bottom"
                    columnCount={4}
                />
                <Export enabled={true} />
                <Series argumentField="conto" valueField="saldo">
                    <Label
                        visible={true}
                        position="columns"
                        customizeText={customizeText}
                    >
                        <Font size={13} />
                        <Connector visible={true} width={0.8} />
                        <Format
                            type="currency"
                            currency="EUR"
                        />
                    </Label>
                </Series>
                    </PieChart>

                {/*</div>*/}


            </div>
        </>
    )
}

export default ReportGrafic;




