import { FornitoriCentrInterface } from './../interfaces/generiche';
import { ListaFarmacieInterface } from './../interfaces/farmacie';

export function getDescrFarmFromId(listaFarmacie: ListaFarmacieInterface[], idFar: number) {
    let descrizione = listaFarmacie.filter((farm) => {
        return farm.ID_FAR === idFar
    })[0].DESCRIZIONE;
    if (descrizione.includes("Magazzino"))
        descrizione = descrizione.replace("Magazzino", "Mag."); 
    return descrizione;
}

export function getCodFarmFromId(listaFarmacie: ListaFarmacieInterface[], idFar: number) {
    let descrizione = listaFarmacie.filter((farm) => {
        return farm.ID_FAR === idFar
    })[0].CODICE;
    if (descrizione.includes("Magazzino"))
        descrizione = descrizione.replace("Magazzino", "Mag.");
    return descrizione;
}

export function getCodiceFarmFromId(listaFarmacie: ListaFarmacieInterface[] | undefined, idFar: number) {
    let codice = listaFarmacie?.filter((farm) => {
        return farm.ID_FAR === idFar
    })[0].CODICE;
    return codice;
}

export function getRagSocCentralFromId(listaFornitori: FornitoriCentrInterface[], idFornitore: number) {
    const fornitore = listaFornitori.filter(forn => {
        return forn.ID === idFornitore
    })[0].RAGIONE_SOCIALE
    return fornitore;
}