import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row'; import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { BoxDataOra } from '../../widget/Editor';
import Button from 'react-bootstrap/esm/Button';
import notify from 'devextreme/ui/notify';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { Loading } from '../../widget/Notifications';
import { useAuth } from '../../hooks/useAuth';
import { TabellaCompleta, TabellaCompletaMasterDetail } from '../../widget/Tabelle';
import { OggettoInterface } from '../../interfaces/generiche';
import { ElencoTrasferimentiInterface } from '../../interfaces/trasferimenti';
import { SelectFarm, Selezione, TendinaFarm } from '../../widget/Select';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { TabellaCompletaInterface } from '../../interfaces/tabelle';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { urlTrasferimenti } from '../costanti';
import { DrawerFiltri } from '../../widget/Drawer';
import { Workbook } from 'exceljs';
import { exportDataGrid } from 'devextreme/excel_exporter';
import saveAs from 'file-saver';


const RichiesteTrasferimenti = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const titolo = TitoloContenuto("Elenco merce trasferita");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const auth = useAuth(urlTrasferimenti.auth);

    const [openDrawer, setOpenDrawer] = useState(true);

    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [elencoTrasferimenti, setElencoTrasferimenti] = useState<ElencoTrasferimentiInterface[]>();
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();


    useEffect(() => {
        const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        setDal(dataInizio.toString());
        if (props.datiFarmacie && props.datiFarmacie.length > 0) {
            setFiltri({
                dal: dataInizio.toString(),
                al: (new Date()).toString()
            })
        }
    }, [props.datiFarmacie])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="3">
                    <label>Farmacia</label>
                    <SelectFarm datiFarmacie={props.datiFarmacie} id="idFar" impostaFiltri={impostaFiltri} />
                </Col>
                <Col sm="3">
                    <label>Grossista</label>
                    <SelectFarm datiFarmacie={props.datiFarmacie} id="idGro" impostaFiltri={impostaFiltri} />
                </Col>
                <Col sm="2">
                    <label>Dal *</label>
                    <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                </Col>
                <Col sm="2">
                    <label>Al *</label>
                    <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={(new Date()).toString()} />
                </Col>
                <Col sm="2">
                    <Button type="button" variant="success" id="btnCerca" onClick={startCerca}>Cerca</Button>
                </Col>
            </Row>
        )
    }

    const startCerca = () => {
        if (filtri) {

            if (!filtri.dal) {
                notify({ position: "center", width: "auto", message: "Selezionare la data di inizio" }, 'error', 4000);
            } else if (!filtri.al) {
                notify({ position: "center", width: "auto", message: "Selezionare la data di fine" }, 'error', 4000);
            } else if (new Date(filtri.dal) > new Date(filtri.al)) {
                notify({ position: "center", width: "auto", message: "La data inizio non può essere maggiore della data fine" }, 'error', 4000);
            }
            else {
                setIsVisible(true);
                const param = {
                    idFar: filtri.idFar,
                    idGro: filtri.idGro,
                    dal: filtri.dal,
                    al: filtri.al
                }
                param.dal = trasformaDateTimeInLocale(param.dal, true);
                param.al = trasformaDateTimeInLocale(param.al, true);
                RestServices().rest('POST', param, urlTrasferimenti.getElenco)
                    .then(result => {
                        setIsVisible(false);
                        if (result) {
                            if (result === true) {
                                notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                                setElencoTrasferimenti(result)
                            } else {
                                setElencoTrasferimenti(result);
                            }
                        }
                        else if (result && result.message && result.severity) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                        } else {
                            notify({ position: "center", width: "auto", message: "Oops c'� stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                        }
                    })
            }
        } else {
            notify({ position: "center", width: "auto", message: "Inserire i parametri di ricerca richiesti" }, 'error', 4000);
        }
    }


    const propsTabElencoTrasferimenti: TabellaCompletaInterface = {
        id: "elenco_trasferimenti",
        dataSource: elencoTrasferimenti,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        export: {
            enabled: true,
            fileName: "Trasferimenti"
        }
    }
    const columnsTrasferimenti = CreaColonne([
        { caption: "Id", dataField: "id", visible: false },
        { caption: "IdFar", dataField: "idFar", visible: false },
        { caption: "IdGro", dataField: "idGro", visible: false },
        { caption: "Farmacia", dataField: "ragioneSocialeFar" },
        { caption: "Grossista", dataField: "ragioneSocialeGro" },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "desc" },
        { caption: "Cod. Ordine Farmacia", dataField: "rifOrdineFarmacia" },
        { caption: "Note Farmacia", dataField: "noteOrdineFarmacia" },
        { caption: "Note Grossista", dataField: "noteOrdineGrossista" },
        { caption: "Note Corriere", dataField: "noteCorriere" },
        { caption: "IdEsitoServizio", dataField: "idEsitoServizio", visible: false },
        { caption: "EsitoServizio", dataField: "esitoServizio", visible: false },
        { caption: "Esito Servizio", dataField: "descrizioneEsitoServizio", visible: true }
    ])

    const columnsTrasferimentiDetail = CreaColonne([
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Cod. Ministeriale", dataField: "codMin" },
        { caption: "Prodotto", dataField: "prodotto"},
        { caption: "Qta. Richiesta", dataField: "quantitaRichiesta" },
        { caption: "Qta. Consegnata", dataField: "quantitaConsegnata" },
        { caption: "Qta. Mancante", dataField: "quantitaMancante" },
        { caption: "Pzo. Netto Unitario", dataField: "prezzoNettoUnitario" },
    ])

    const getReportDetail = (id: any) => elencoTrasferimenti?.filter((detail: { id: any; }) => detail.id === id)[0].detail;


    const onExporting = (e: any) => {
        var masterDetails: any[] = [];
        var workbook = new Workbook();
        var worksheet = workbook.addWorksheet('Trasferimenti');

        masterDetails = [];
        let masterRows: any[] = [];

        const topLeftCell = { row: 2, column: 2 };

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            topLeftCell: topLeftCell,
            customizeCell: function ({ gridCell, excelCell }) {
                if (gridCell) {
                    if (gridCell.column) {
                        if (gridCell.column.dataField === 'rifOrdineFarmacia' && gridCell.rowType === 'data') {
                            masterRows.push({ rowIndex: excelCell.fullAddress.row + 1, data: gridCell.data });
                        }
                    }
                }
            }
        }).then((cellRange) => {

            const borderStyle = { style: "thin", color: { argb: "FF7E7E7E" } };
            let offset = 0;

            const insertRow = (index: any, offset: number, outlineLevel: number | undefined) => {
                const currentIndex = index + offset;
                const row = worksheet.insertRow(currentIndex, [], 'n');

                for (var j = worksheet.rowCount + 1; j > currentIndex; j--) {
                    worksheet.getRow(j).outlineLevel = worksheet.getRow(j - 1).outlineLevel;
                }

                row.outlineLevel = outlineLevel;

                return row;
            }

            for (var i = 0; i < masterRows.length; i++) {
                let row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                let columnIndex = 0;
                if (cellRange)
                    if (cellRange.from)
                        if (cellRange.from.column)
                            columnIndex = cellRange.from.column + 1;

                if (elencoTrasferimenti) {
                    let reportData = elencoTrasferimenti.find((item: { id: any; }) => item.id === masterRows[i].data.id);
                    const columns = ["codMin", "prodotto", "quantitaRichiesta", "quantitaConsegnata", "quantitaMancante", "prezzoNettoUnitario"];
                    var columnMap = new Map();
                    columnMap.set("codMin", "Cod. Prod");
                    columnMap.set("prodotto", "Prodotto");
                    columnMap.set("quantitaRichiesta", "Qta. richiesta");
                    columnMap.set("quantitaConsegnata", "Qta. consegnata");
                    columnMap.set("quantitaMancante", "Qta. mancante");
                    columnMap.set("prezzoNettoUnitario", "Pzo. netto unitario");

                    columns.forEach((columnName, currentColumnIndex) => {
                        Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                            value: columnMap.get(columnName),
                            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E9E9E9' } },
                            font: { bold: true },
                            border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle }
                        });
                    });

                    if (reportData) {
                        let tmp = getReportDetail(reportData.id);
                        if (tmp)
                            tmp.forEach((task: { [x: string]: any; }, index: any) => {
                                row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                                columns.forEach((columnName, currentColumnIndex) => {
                                    Object.assign(row.getCell(columnIndex + currentColumnIndex), {
                                        value: task[columnName],
                                        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'E9E9E9' } },
                                        border: { bottom: borderStyle, left: borderStyle, right: borderStyle, top: borderStyle }
                                    });
                                });
                            });
                    }
                    
                    row.height = 20;
                    row = insertRow(masterRows[i].rowIndex + i, offset++, 2);
                }

                offset--;

            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ElencoTrasferimenti.xlsx');
            });
        });
    }

return (
    <>
        {titolo}
        {loadPanel}
        <div className="contenutoPagina">
            <DrawerFiltri id="paginaOrdiniRda" templateFiltri={templateFiltri} open={openDrawer} setOpen={setOpenDrawer} />
            <Row className="tabellaFornitori">
                <Col sm="12">
                    {/* <TabellaCompleta
                            propsTabella={propsTabElencoTrasferimenti}
                            columns={columnsTrasferimenti}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                    />*/}
                    <TabellaCompletaMasterDetail
                        propsTabella={propsTabElencoTrasferimenti}
                        columns={columnsTrasferimenti}
                        columnsDetail={columnsTrasferimentiDetail}
                        setTabellaInstance={setTabellaInstance}
                        rowAlternationEnabled={false}
                        allowColumnResizing={false}
                        onExporting={ onExporting}
                        />
                </Col>
            </Row>
        </div>
    </>
)
}

export default RichiesteTrasferimenti;