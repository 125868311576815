import { cardC, typeShow, } from "../../components/card_chart";


export const boxCard: cardC[] = [
  {
    testo: "Valore del venduto",
    subTesto: "Media per operatore",
    valore: "VALORE_VENDUTO",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    subValore: "VALORE_VENDUTO_MEDIA_OPERATORE",
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine",
    subTesto: "Media per operatore",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    valore: "MARGINE",
    subValore: "MARGINE_MEDIA_OPERATORE",
    testoPop: `Margine:
          Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
          Delta margine:
          Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Ore lavorate",
    subTesto: "Media per operatore",
    valore: "ORE_LAVORATE",
    subValore: "ORE_LAVORATE_MEDIA_OPERATORE",
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
            
            Delta margine:
            Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Numero righe vendita",
    subTesto: "Media per operatore",
    valore: "NUMERO_RIGHE",
    subValore: "NUMERO_RIGHE_MEDIA_OPERATORE",
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

export const boxsecond: cardC[] = [
  {
    testo: "Valore del venduto medio orario",
    valore: "VALORE_VENDUTO_MEDIA_ORARIA",
    typeShowValue: typeShow.euro,

    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Margine medio orario",
    valore: "MARGINE_MEDIA_ORARIA",
    typeShowValue: typeShow.euro,

    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Numero vendite medio orario",
    valore: "NUMERO_VENDITE_MEDIA_ORARIA",
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Numero righe di vendita media orario",
    valore: "NUMERO_RIGHE_MEDIA_ORARIA",
    testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
                
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
];

// ana sconti vendite categorie 

export const boxCardAnaSconti: cardC[] = [
  {
    testo: "Valore sconto ",
    subTesto: "Delta anno precedente",
    valore: "VALORE_SCONTOSL",
    subValore: "VALORE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
  },
  {
    testo: "Sconto percentuale",
    subTesto: "Delta anno precedente",
    valore: "PERC_SCONTOSL",
    subValore: "PERC_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Numero vendite con sconto",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_VENDITE_SCONTOSL",
    subValore: "NUMERO_VENDITE_SCONTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Numero vendite con sconto: Rappresenta il numero di vendite con sconto nel periodo selezionato. Delta numero vendite con sconto: Rappresenta il delta percentuale tra il numero di vendite con sconto anno corrente e anno precedente, nel periodo selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },
  {
    testo: "% di vendite con sconto ",
    subTesto: "Delta anno precedente",
    valore: "PERC_VENDITE_SCONTOSL",
    subValore: "PERC_VENDITE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "% di vendite con sconto: Rappresenta il rapporto tra il numero di vendite con sconto ed il numero di vendite totale. Il periodo considerato è quello selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },

];

// box card ana scon grupp merc
export const boxCardAnaSconGruppMerc: cardC[] = [
  {
    testo: "Valore sconto ",
    subTesto: "Delta anno precedente",
    valore: "VALORE_SCONTOSL",
    subValore: "VALORE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
  },
  {
    testo: "Sconto percentuale",
    subTesto: "Delta anno precedente",
    valore: "PERC_SCONTOSL",
    subValore: "PERC_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Numero vendite con sconto",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_VENDITE_SCONTOSL",
    subValore: "NUMERO_VENDITE_SCONTO_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Numero vendite con sconto: Rappresenta il numero di vendite con sconto nel periodo selezionato. Delta numero vendite con sconto: Rappresenta il delta percentuale tra il numero di vendite con sconto anno corrente e anno precedente, nel periodo selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },
  {
    testo: "% di vendite con sconto ",
    subTesto: "Delta anno precedente",
    valore: "PERC_VENDITE_SCONTOSL",
    subValore: "PERC_VENDITE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.percento,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "% di vendite con sconto: Rappresenta il rapporto tra il numero di vendite con sconto ed il numero di vendite totale. Il periodo considerato è quello selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },

];

// box card analisi Ricette

// ana sconti vendite categorie 

export const boxCardAnaRicette: cardC[] = [
  {
    testo: "Venduto lordo",
    subTesto: "% sul venduto totale",
    valore: "VENDUTO_LORDO_SSNSL",
    subValore: "PERC_VENDUTO_TOTALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
  },
  {
    testo: "Imponibile Lordo",
    subTesto: "% sull'imponibile totale",
    valore: "IMPONIBILE_LORDO_SSNSL",
    subValore: "PERC_IMPONIBILE_TOTALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  },
  {
    testo: "Venduto decurtato SSN",
    subTesto: "Delta anno precedente",
    valore: "VENDUTO_DECURTATO_SSNSL",
    subValore: "NUMERO_VENDITE_SCONTO_PUNTUALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Numero vendite con sconto: Rappresenta il numero di vendite con sconto nel periodo selezionato. Delta numero vendite con sconto: Rappresenta il delta percentuale tra il numero di vendite con sconto anno corrente e anno precedente, nel periodo selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },
  {
    testo: "Imponibile decurtato SSN",
    valore: "IMPONIBILE_DECURTATO_SSNSL",
    typeShowValue: typeShow.euro,
    testoPop:
      "% di vendite con sconto: Rappresenta il rapporto tra il numero di vendite con sconto ed il numero di vendite totale. Il periodo considerato è quello selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
  },

];

export const boxsecondRicette: cardC[] = [
  {
    testo: "Valore Ticket + Quota ricetta ",
    subTesto: "Delta anno precedente",
    valore: "TICKET_QUOTASL",
    subValore: "TICKET_QUOTA_PUNTUALEPR",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Decurtazione in DCR",
    valore: "DECURTAZIONE_DCRSL",
    typeShowValue: typeShow.euro,
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Previsione incasso DCR lordo IVA",
    subTesto: "Previsione incasso DCR netto IVA ",
    valore: "PREVISIONE_INCASSO_DCR_LORDO_IVASL",
    subValore: "PREVISIONE_INCASSO_DCR_NETTO_IVASL",
    typeShowValue: typeShow.euro,
    typeShowSubValue: typeShow.euro,
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  
];

export const boxterzRicette: cardC[] = [
  {
    testo: "Numero di ricette lavorate",
    subTesto: "Delta anno precedente",
    valore: "NUMERO_RICETTESL",
    subValore: "NUMERO_RICETTE_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop:
      "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  },
  {
    testo: "Valore medio per ricetta",
    subTesto: "Delta anno precedente",
    valore: "VALORE_MEDIO_RICETTASL",
    subValore: "VALORE_MEDIO_RICETTA_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    typeShowValue: typeShow.euro,
    testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Pezzi venduti SSN",
    subTesto: "Delta anno precedente",
    valore: "PEZZI_SSNSL",
    subValore: "PEZZI_SSN_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
  {
    testo: "Numero medio pezzi per ricetta",
    subTesto: "Delta anno precedente",
    valore: "MEDIA_PEZZI_RICETTASL",
    subValore: "MEDIA_PEZZI_RICETTA_PUNTUALEPR",
    typeShowSubValue: typeShow.percento,
    testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
  },
];

export const boxAnaContenitori: cardC[] = [
  {
    testo: "Contenitori configurati",
    valore: "NUMERO_VENDITE_MEDIA_ORARIA",
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },
  {
    testo: "Numero medio di referenze per contenitore",
    valore: "NUMERO_VENDITE_MEDIA_ORARIA",
    testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
        
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
  },


];