import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from "../../hooks/useAuth"
import { RestServices } from "../../services/restServices"
import { DialogConferma, Loading } from '../../widget/Notifications'
import { urlBancaDati, urlCommon } from "../costanti"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown, faAngleUp, faQuestionCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { RichiestaLivellamentoMagazzino } from '../../interfaces/livellamentoMagazzino'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { cambiaFormatoLocalDateTimeToEuropeo } from '../../funzioni/formatoVariabili'
import { Summary, TotalItem } from 'devextreme-react/data-grid'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import TabelleVendutoLight from '../consultazione/TabelleVendutoLight'
import { TabellaSemplice } from '../../widget/Tabelle'
import { GiacenzaDettInterface, RicercaProdInterface } from '../../interfaces/bancaDati'
import Button from 'react-bootstrap/Button'
import { confirm } from 'devextreme/ui/dialog';
import { AplicaValoreTO } from '../../funzioni/operazioniSuTabelle'
import { BoxTestoCerca } from '../../widget/Editor'
import DialogAggiungiProdOrdine from '../../common/DialogAggiungiProdOrdine'


const DettaglioRichiestaLivellamentoMagazzino = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlCommon.auth)

    const [tabellaProdotti, setTabellaProdotti] = useState<any>([])
    const [tabellaLivellamento, setTabellaLivellamento] = useState<any>([])
    const [tabellaLivellamentoProdottoInfo, setTabellaLivellamentoProdottoInfo] = useState<any>([])
    const [tabellaDettaglio, setTabellaDettaglio] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [richiesta, setRichiesta] = useState<RichiestaLivellamentoMagazzino>()
    const [idProdotto, setIdProdotto] = useState<number>()
    const [codCsf, setCodCsf] = useState<number>();
    const [codProd, setCodProd] = useState<number>();
    const [isLoadVisible, setIsLoadVisible] = useState(false);
    const loadPanelVenduto = <Loading isVisible={isLoadVisible} position="#rigaTabGiacenze" />
    const [listFarmacie, setListFarmacie] = useState<any[]>([])
    const [farmaciaSelezionata, setFarmaciaSelezionata] = useState<number>();
    const [idLivellamentoMagazzino, setIdLivellamentoMagazzino] = useState<number>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [oldQuantita, setOldQuantita] = useState<any>();
    const [oldQuantitaAvere, setOldQuantitaAvere] = useState<any>();
    const [statoLabel, setStatolabel] = useState<any>();
    const [inputCerca, setInputCerca] = useState<string>("");
    const [searchBoxRefInstance, setSearchBoxRefInstance] = useState<any>();
    const idDettaglio = window.location.href.split("?")[1];
    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [isDialogAddProdOpen, setIsDialogAddProdOpen] = useState<boolean>(false);
    const [avviso, setAvviso] = useState<string>();
    const [idDel, setIddell] = useState<number>(0)
    const [messDel, setMessDel] = useState<string>('')
    const [popDel, setPopDel] = useState<boolean>(false)
    const [selectedProd, setSelectedProd] = useState<any>([])

    const svuotaDialogAddProd = () => setCercaNuovoProd([]);
    const handleDialogAddProdClose = () => setIsDialogAddProdOpen(false);
    const handleDialogAddProdOpen = () => setIsDialogAddProdOpen(true);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            getRichiesta()
        }
    }, [auth])


    const columnsProdotti = [
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Cod.Prod", dataField: "codiceProdotto" },
        { caption: "Cod.Ean", dataField: "codiceEan" },
        { caption: "Prodotto", dataField: "prodotto" },
        { caption: "Ditta", dataField: "ditta" },
        { caption: "Giac.tot.att.", dataField: "giacenzaTotaleAttuale" },
        { caption: "Giac.tot.", dataField: "giacenzaTotaleElaborazione" },
        { caption: "Ecced.tot.", dataField: "eccedenzaTotale" },
        { caption: "G.cop.gruppo", dataField: "giorniCoperturaGruppo", format: { precision: 2 }, },
        { caption: "Qta. da trasf.", dataField: "quantitaTotaleTrasferire" },
        { caption: "Prezzo", dataField: "prezzo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Iva", dataField: "iva", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'iva', 'd') },
        { caption: "Ult. Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" } },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {

                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {

                        setIddell(e.row.data.id)
                        setMessDel("Il prodotto  " + e.row.data.prodotto + " sta per essere eliminato. Confermare?")
                        setPopDel(true)

                    },
                }
            ]
        }

    ];

    //const columnsLivellamento = [
    //    { caption: "Id", dataField: "id", visible: false },
    //    { caption: "", dataField: "farmacia", visible: true },
    //    { caption: "Giacenza", dataField: "giacenza" },
    //    { caption: "Ecced.", dataField: "eccedenza" },
    //    { caption: "Ult. Costo", dataField: "costo" },
    //    { caption: "Iva", dataField: "iva" },
    //    { caption: "P.zi ingresso", dataField: "quantitaRiceve", editC: true },
    //    { caption: "P.zi uscita", dataField: "quantitaInvia", editC: true },
    //    { caption: "Gi.ni copertura riceve", dataField: "giorniCoperturaRiceve" },
    //    { caption: "Gi.ni copertura invia", dataField: "giorniCoperturaInvia" },

    //];

    const columnsLivellamento = [
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Farmacia", dataField: "farmacia", visible: true, sortOrder: "asc" },
        { caption: "Giac.att.", dataField: "giacenzaAttuale" },
        { caption: "Giacenza", dataField: "giacenzaElaborazione" },
        { caption: "Ecced.", dataField: "eccedenza" },
        { caption: "Ult. Costo", dataField: "ultimoCosto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Iva", dataField: "iva", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'iva', 'd') },
        { caption: "Dt.Scad.", dataField: "dataScadenza", format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "G.gar.", dataField: "giorniCoperturaInvia" },
        { caption: "G.des.", dataField: "giorniCoperturaRiceve" },
        { caption: "P.zi dare", dataField: "quantitaInvia", editC: false },
        { caption: "P.zi avere", dataField: "quantitaRiceve", editC: false },
        { caption: "Ult. carico", dataField: "ultimoCarico", editC: false },
        //{ caption: "Farmacia riceve", dataField: "farmaciaRiceve", visible: true },
        //{ caption: "Q.ta trasf. tot.", dataField: "quantitaTotale", visible: true },
        
    ];

    const columnsLivellamentoProdottoInfo = [
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Id Avere", dataField: "idAvere", visible: false },
        { caption: "IdLiv", dataField: "idLivellamentoProdotti", visible: false },
        { caption: "IdFarmacia", dataField: "idFarmacia", visible: false },
        { caption: "", dataField: "farmacia", visible: true, sortOrder:"asc" },
        { caption: "Giac.", dataField: "giacenza" },
        { caption: "Ven.", dataField: "venduto" },
        { caption: "Ven. Medio", dataField: "vendutoMedio" },
        { caption: "P.zi dare", dataField: "quantitaTrasferita", editC: true },
        { caption: "P.zi avere", dataField: "quantitaTrasferitaAvere", editC: true },

    ];


    const getRichiesta = () => {
        if (auth) {
            setLoading(true)
            let param = { id: idDettaglio }
            RestServices().rest('POST', param, urlCommon.getRichiesta)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setTabellaProdotti(result.response.prodotti)
                        setRichiesta(result.response)
                        setStatolabel(result.response.stato)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                })
        }
    }

    const tagRicercha = () => {
        let tagArr = []
        if (richiesta) {
            for (const [key, value] of Object.entries(richiesta)) {
                //console.log(value); 
                if (key == 'titolo') {
                    tagArr.push(richiesta.titolo)
                }

                if (key == 'data') {
                    tagArr.push(richiesta?.data != undefined ? cambiaFormatoLocalDateTimeToEuropeo(richiesta?.data) : '')
                }

                //if (key == 'totaleProdotti') {
                //    tagArr.push(richiesta.totaleProdotti)
                //}

                if (key == 'dittaTitolare') {
                    tagArr.push(richiesta.dittaTitolare)
                }

                if (key == 'stato') {
                    //tagArr.push(richiesta.stato)
                    tagArr.push(statoLabel)
                }

            }
        }


        if (open) {
            return null
        } else {
            return (
                tagArr.map((tag, index) => {
                    return (
                        <div style={{ marginRight: 10, display: 'inline-block', background: ' #dfdfdf', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5 }} key={'tag' + index}>
                            <p style={{ fontSize: 13, margin: 0 }}>{tag}</p>
                        </div>
                    )
                })
            )
        }
    }

    const getLivellamentoFarmacieProdotto = () => {

        if (auth) {
            setLoading(true)
            let param = { id: idProdotto, idMagazzino: richiesta?.id, codCsf:codCsf, codProd: codProd }
            RestServices().rest('POST', param, urlCommon.getLivellamentoFarmacieProdotto)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        if (result.response.length == 0) {
                            setTabellaLivellamentoProdottoInfo([])
                        }
                            
                        setTabellaLivellamento(result.response)
                        //recupero id far
                        let lstFar: any[] = [];
                        let itemFar: any = {};
                        let itemFar2: any = {};
                        result.response.forEach((item: any) => {
                            itemFar = {};
                            itemFar.idFarmacia = item.idFarmacia;
                            itemFar.descrizioneFarmacia = item.farmacia;

                            if (lstFar.findIndex(e => e.idFarmacia === itemFar.idFarmacia) === -1)
                                lstFar.push(itemFar)
                            //lstFar.push(itemFar)
                        });
                        setListFarmacie(lstFar)
                        setLoading(false)
                    } else {
                        setLoading(false)
                    }
                })
        }

    }


    useEffect(() => {
        //console.log("use effect cod csf")
        if (codCsf) {
            getLivellamentoFarmacieProdotto()
            setTabellaLivellamentoProdottoInfo([])
            setFarmaciaSelezionata(undefined)
        }
    }, [codCsf]);


    const onFocusedCellChanged = useCallback((e: any) => {

        if (e.row) {
            setFarmaciaSelezionata(e.row.data.idFarmacia)
        }
        
        
    }, [tabellaLivellamento])

    const onCellPrepared = useCallback((e: any) => {

        //console.log(e)
        if (e.rowType === "data" && e.column && e.column.caption) {
            let tooltip = "";
            if (e.data.tooltipCosto && e.data.tooltipCosto.length > 0)
                tooltip = 'Ultimo costo carico - ' + e.data.tooltipCosto;
            e.cellElement.title = tooltip;
        }

        let tooltip = "";
        switch (e.column.dataField) {
            case "giorniCoperturaGruppo":
                tooltip = "Giorni di copertura delle farmacie coinvolete nella proposta"
                break;
            case "eccedenzaTotale":
                tooltip = "Eccedenza totale delle farmacie coinvolte nella proposta"
                break;
            case "quantitaTotaleTrasferire":
                tooltip = "Quantita' totale da trasferire"
                break;
            case "giacenzaTotaleAttuale":
                tooltip = "Giacenza totale attuale delle farmacie coinvolte nella proposta"
                break;
            case "giacenzaTotaleElaborazione":
                tooltip = "Giacenza totale al momento dell'elaborazione delle farmacie coinvolte nella proposta"
                break;
        }
        e.cellElement.title = tooltip;

        //if (e.rowType === "data" && (e.column.dataField === "quantitaInvia" || e.column.dataField === "quantitaRiceve" )) {
        //    e.cellElement.style.backgroundColor = "paleturquoise";
        //}

    }, [])

    const onCellPreparedDett = useCallback((e: any) => {

        let tooltip = "";
        switch (e.column.dataField) {
            case "giorniCoperturaInvia":
                tooltip = "Giorni di copertura da garantire nel magazzino"
                break;
            case "giorniCoperturaRiceve":
                tooltip = "Giorni di copertura desiderati da avere nel magazzino"
                break;
            case "giacenzaAttuale":
                tooltip = "Giacenza attuale della farmacia"
                break;
            case "giacenzaElaborazione":
                tooltip = "Giacenza della farmacia al momento dell'elaborazione"
                break;

        }
        e.cellElement.title = tooltip;


    }, [])

    const onCellPreparedInfo = useCallback((e: any) => {

        if (e.rowType === "header") {
            let tooltip = "";
            switch (e.column.dataField) {
                case "venduto":
                    tooltip = "Venduto ultimo mese"
                    break;
                case "vendutoMedio":
                    tooltip = "Venduto medio mensile calcolato sugli ultimi 12 mesi"
                    break;

            }
            e.cellElement.title = tooltip;
        }

        if (e.rowType === "data" && ((e.column.dataField === "quantitaTrasferita") || (e.column.dataField === "quantitaTrasferitaAvere"))) {
            e.cellElement.style.backgroundColor = "paleturquoise";
        }

    }, [])

    const getProdottoFarmaciaInfoLight = () => {

        if (auth) {
            var far;
            far = listFarmacie?.map(({ idFarmacia }) => idFarmacia);

            const params = {
                idFar: far,
                codCsf: codCsf
            }
            RestServices().rest('POST', params, urlCommon.getProdottoFarmaciaInfoLight).then(result => {

                if (result) {
                    if (result.esito) {
                        setTabellaLivellamentoProdottoInfo(result.response)
                    }
                    else {

                    }
                }
            })
        }
        
    }

    const getLivellamentoFarmacieProdottoDettaglio = () => {

        if (auth) {
            setLoading(true)
            const params = {
                idFarmacia: farmaciaSelezionata,
                codCsf: codCsf,
                id: idProdotto,
                idMagazzino: idDettaglio 
            }
            RestServices().rest('POST', params, urlCommon.getLivellamentoFarmacieProdottoDettaglio).then(result => {

                if (result) {
                    if (result.esito) {
                        setTabellaLivellamentoProdottoInfo(result.response)
                        setLoading(false)
                    }
                    else {
                        setLoading(false)
                    }
                }
            })
        }

    }

    //useEffect(() => {
    //    if (listFarmacie && listFarmacie.length > 0)
    //        getProdottoFarmaciaInfoLight()
            
    //}, [listFarmacie])    

    useEffect(() => {

        if (farmaciaSelezionata) {
            //getProdottoFarmaciaInfoLight()
            getLivellamentoFarmacieProdottoDettaglio()
        }

    }, [farmaciaSelezionata])

    const onRowUpdating = (e: any) => {

        //console.log(e)
        //console.log(farmaciaSelezionata)
        //console.log(e.key)
        //console.log(e.key.qtaFarTrasf)
        //console.log(e.newData.qtaFarTrasf)

        let id;
        let idFarmaciaRiceve;
        let idFarmaciaInvia;
        let quantita;
        let oldQuantitaDaVerificare: number = 0;

        if (e.newData.quantitaTrasferita != undefined && e.newData.quantitaTrasferita >= 0) {
            id = e.key.id
            idFarmaciaRiceve = e.key.idFarmacia
            idFarmaciaInvia = farmaciaSelezionata
            quantita = e.newData.quantitaTrasferita
            oldQuantitaDaVerificare = oldQuantita
        }

        if (e.newData.quantitaTrasferitaAvere != undefined && e.newData.quantitaTrasferitaAvere >= 0) {
            id = e.key.idAvere
            idFarmaciaRiceve = farmaciaSelezionata 
            idFarmaciaInvia = e.key.idFarmacia
            quantita = e.newData.quantitaTrasferitaAvere
            oldQuantitaDaVerificare = oldQuantitaAvere
        }
            

        let params = {
            id: id,
            quantita: quantita != undefined ? quantita : undefined ,
            idLivellamentoProdotti: idProdotto,
            idFarmaciaRiceve: idFarmaciaRiceve,
            idFarmaciaInvia: idFarmaciaInvia
        }

        RestServices().rest('POST', params, urlCommon.aggiornaQuantita).then(result => {

            if (result) {
                if (result.esito) {
                    //ricarica griglia prodotto
                    //getLivellamentoFarmacieProdotto()
                    //setFarmaciaSelezionata(params.idFarmaciaInvia)
                    let rowIndexFarmaciaInvia = tabellaInstance.instance.getRowIndexByKey(params.idFarmaciaInvia)
                    let rowIndexFarmaciaRiceve = tabellaInstance.instance.getRowIndexByKey(params.idFarmaciaRiceve)

                    let rows = tabellaInstance.instance.getVisibleRows();
                    let rowInvia = rows[rowIndexFarmaciaInvia];  
                    let rowRiceve = rows[rowIndexFarmaciaRiceve];  

                    //console.log(rows)
                    //console.log(rowIndexFarmaciaInvia)
                    //console.log(rowIndexFarmaciaRiceve)
                    //console.log(params)
                    //console.log("farmacia invia")
                    //console.log(rowInvia)
                    //console.log("farmacia riceve")
                    //console.log(rowRiceve)

                    //console.log("vecchia quantita")
                    //console.log(oldQuantita)

                    
                    
                    let quantitaInviaUpdate: number = 0;
                    let quantitaRiceveUpdate: number = 0;

                    let vecchiaQuantita = oldQuantitaDaVerificare
                    if (vecchiaQuantita == null || vecchiaQuantita == undefined)
                        vecchiaQuantita = 0

                    let quantitaInvia = rowInvia.data.quantitaInvia
                    if (quantitaInvia == null || quantitaInvia == undefined)
                        quantitaInvia = 0

                    let quantitaRiceve = rowRiceve.data.quantitaRiceve
                    if (quantitaRiceve == null || quantitaRiceve == undefined)
                        quantitaRiceve = 0



                    //console.log("quantita invia")
                    //console.log(quantitaInvia)
                    //console.log("quantita riceve")
                    //console.log(quantitaRiceve)


                    if (params.quantita > vecchiaQuantita) {
                        quantitaInviaUpdate = quantitaInvia + (params.quantita - vecchiaQuantita)
                        quantitaRiceveUpdate = quantitaRiceve + (params.quantita - vecchiaQuantita)
                    }

                    if (params.quantita < vecchiaQuantita) {
                        quantitaInviaUpdate = quantitaInvia - (vecchiaQuantita - params.quantita)
                        quantitaRiceveUpdate = quantitaRiceve - (vecchiaQuantita - params.quantita)
                    }
                    

                    if (quantitaInviaUpdate < 0) quantitaInviaUpdate = 0
                    if (quantitaRiceveUpdate < 0) quantitaRiceveUpdate = 0

                    //console.log("quantitaInviaUpdate")
                    //console.log(quantitaInviaUpdate)

                    //console.log("quantitaRiceveUpdate")
                    //console.log(quantitaRiceveUpdate)

                    tabellaInstance.instance.cellValue(rowIndexFarmaciaInvia, "quantitaInvia", quantitaInviaUpdate);
                    tabellaInstance.instance.cellValue(rowIndexFarmaciaRiceve, "quantitaRiceve", quantitaRiceveUpdate);
                    tabellaInstance.instance.saveEditData();

                    //tabellaInstance.instance.cellValue(obb.component.getRowIndexByKey(obb.key), "quantitaInvia",  params.quantita);
                }
                else {
                    notify({ position: "center", width: "auto", message: 'Attenzione!! quantita\' non aggiornata correttamente' }, "error", 4000)
                }
            }
        })
    }

    const btnInviaClick= () => {

        //1 - da elaborare
        //2 - elaborata
        //3 - inviata
        //2 - in elaborazione

        if (richiesta && (richiesta.idStato == 3 || richiesta.idStato == 5)) {
            let result = confirm("<i>Richiesta gia' inviata, inviare di nuovo?</i>", "Attenzione");
            result.then((dialogResult) => {
                if (dialogResult) {
                    inviaInFarmacia()
                    
                    return;
                } else {
                    return;
                }
            });
        } else {
            let result = confirm("<i>Inviare alle farmacie la richiesta?</i>", "Attenzione");
            result.then((dialogResult) => {
                if (dialogResult) {
                    inviaInFarmacia()
                    
                    return;
                } else {
                    return;
                }
            });
        }

        
        //notify({ position: "center", width: "auto", message: 'Funzione al momento non abilitata' }, "warning", 4000)
        return;

    }

    const btnElaboraSelezionatiClick = () => {

    }

    const btnEliminaClick = () => {

        let ids : any[] = []

        selectedProd.forEach((item: any) => {
            ids.push(item.id)
        })

        if (auth) {

            let param = { id: ids, idLivellamento: richiesta?.id }
            RestServices().rest('POST', param, urlCommon.deleteProdotto)
                .then(result => {
                    if (result.esito) {
                        setPopDel(false)
                        notify({ position: "center", width: "auto", message: "Elementi eliminati" }, "success", 4000)
                        setTabellaLivellamento([])
                        setTabellaLivellamentoProdottoInfo([])
                        setFarmaciaSelezionata(undefined)
                        getRichiesta()
                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }

    }

    const btnElaboraClick = () => {

        //1 - da elaborare
        //2 - elaborata
        //3 - inviata
        //4 - in elaborazione
        //5 - innvio in corso

        if (richiesta && (richiesta.idStato == 2 || richiesta.idStato == 3 || richiesta.idStato == 5 || richiesta.idStato == 4)) {
            let result = confirm("<i>Richiesta gia' elaborata, elaborarla di nuovo? <br/>L'elaborazione predecedente verra' sovrascritta ed eventuali prodotti aggiunti saranno persi</i>", "Attenzione");
            result.then((dialogResult) => {
                if (dialogResult) {
                    elabora()
                    return;
                } else {
                    return;
                }
            });
        } else {
            let result = confirm("<i>Elaborare la richiesta?</i>", "Attenzione");
            result.then((dialogResult) => {
                if (dialogResult) {
                    elabora()
                    return;
                } else {
                    return;
                }
            });
        }

        


    }

    const inviaInFarmacia = () => {

        // chiamata rest per schedulazione invio in farmacia
        if (auth) {
            RestServices().rest('POST', { idLivellamentoMagazzino: idDettaglio }, urlCommon.inviaLivellamentoMagazzino)
                .then(result => {
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "Elemento schedulato per l'invio" }, "success", 4000);
                        if (richiesta) {
                            richiesta.idStato = 5
                            richiesta.stato = 'Invio in corso'
                            setStatolabel("Invio in corso")
                        }

                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }
    }

    const elabora = () => {

        // chiamata rest per schedulazione elaborazione
        if (auth) {
            RestServices().rest('POST', { idLivellamentoMagazzino: idDettaglio }, urlCommon.elaboraLivellamentoMagazzino)
                .then(result => {
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "Elemento schedulato per l'elaborazione" }, "success", 4000);
                        if (richiesta) {
                            richiesta.idStato = 4
                            richiesta.stato = 'In elaborazione'
                            setStatolabel("In elaborazione")
                        }

                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }
    }

    const startRicercaProdInBD = () => {
        console.log("startRicercaProdInBD")
        console.log(richiesta)
        if (inputCerca.length > 0 && richiesta && richiesta.idFarmacia && richiesta.idFarmacia?.length > 0) {
            setLoading(true);
            const parametriCerca = {
                testo: inputCerca,
                lstFar: richiesta.idFarmacia,
                soloInCommercio: false,
                soloInGiacenza: false
            }
            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    setLoading(false);
                    if (result.length === 1) {
                        if (result[0].codCsf) {
                            insertProdotto(result[0].codCsf);
                        } else {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                        }
                    } else if (result.length > 1) {
                        setCercaNuovoProd(result);
                        handleDialogAddProdOpen();
                    } else {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                        setCercaNuovoProd([]);
                    }
                })
        }
    }

    const insertProdotto = (codCsf: number) => {
        if (!codCsf) {
            notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, 'error', 2500);
            return;
        }
        setLoading(true);
        RestServices().rest('POST', codCsf, urlBancaDati.getProd).then(result => {
            if (result === true) {
                setLoading(false);
                notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
            } else {
                let codCsf = result.codCsf;

                let param = { codCsf: codCsf, id: richiesta?.id }

                RestServices().rest('POST', param, urlCommon.addProdotto).then(res => {

                    setLoading(false);
                    if (res) {
                        if (res.esito) {
                            //ricaricare la pagina, o meglio ricariare la griglia dei prodotti
                            //setAvviso("Sono stati inseriti nuovi prodotti, elaborarli manuelam");
                            getRichiesta()
                        }
                        else {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                        }
                    }
                    else {
                        notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                    }
                })
            }
        })
    }

    const removeProdottoLivellamento = () => {
        if (auth) {
            let ids: any[] = []
            ids.push(idDel)

            let param = {id:ids, idLivellamento: richiesta?.id}
            RestServices().rest('POST',  param, urlCommon.deleteProdotto)
                .then(result => {
                    if (result.esito) {
                        setPopDel(false)
                        notify({ position: "center", width: "auto", message: "Elemento eliminato" }, "success", 4000)
                        setTabellaLivellamento([])
                        setTabellaLivellamentoProdottoInfo([])
                        setFarmaciaSelezionata(undefined)
                        getRichiesta()
                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }
    }

    return (
        <>
            <DialogConferma
                dialogIsOpen={popDel}
                message={messDel}
                functionNext={() => removeProdottoLivellamento()}
                handleCloseConferma={() => { setPopDel(false); setIddell(0) }}
            />

            {(cercaNuovoProd && inputCerca.length > 0 && richiesta && richiesta.idFarmacia && richiesta.idFarmacia.length > 0) &&
                <DialogAggiungiProdOrdine
                    isOpen={isDialogAddProdOpen}
                    handleClose={handleDialogAddProdClose}
                    svuotaDialog={svuotaDialogAddProd}
                    cercaNuovoProd={cercaNuovoProd}
                    testoRicerca={inputCerca}
                farmSelez={richiesta.idFarmacia}
                    insertProdotto={insertProdotto}
                />}

            <Loading isVisible={loading} />
            <div style={{ background: 'white', padding: 10, borderRadius: 10, }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div><h4 style={{ fontSize: 22, margin: 0 }}>Dettaglio richiesta livellamento magazzino: {tagRicercha()}</h4></div>
                    <div><button style={{ border: 'none', background: 'none', fontSize: 20 }} onClick={() => setOpen(!open)}>{open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</button></div>
                </div>
                <div className={open ? 'main' : 'mainOut'}>
                    <div className="col-md-12">
                        <div className='form-row'>
                            <div className="form-group col-md-2">
                                <TextBox disabled value={richiesta?.titolo} placeholder="..." />
                            </div>
                            <div className="form-group col-md-1">
                                <TextBox disabled value={richiesta?.data != undefined ? cambiaFormatoLocalDateTimeToEuropeo(richiesta?.data) : ''}  placeholder="..." />
                            </div>



                            {/*{richiesta?.totaleProdotti && <div className="form-group col-md-1">*/}
                            {/*    <TextBox disabled value={String(richiesta?.totaleProdotti)}  placeholder="..." />*/}
                            {/*</div>}*/}
                            {richiesta?.dittaTitolare && <div className="form-group col-md-2">
                                <TextBox disabled value={richiesta?.dittaTitolare}  placeholder="..." />
                            </div>}

                            {
                                richiesta?.stato && <div className="form-group col-md-1">
                                    <TextBox disabled value={richiesta?.stato}  placeholder="..." />
                                </div>
                            }


                        </div>


                    </div>
                </div>
            </div>

            <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} >
            <Row>
                <Col md='3' >
                    <span><h6 style={{paddingTop:10} }>RICERCA PRODOTTO (codice, EAN, descrizione)</h6></span>
                </Col>
                    <Col md='3' className="boxCerca">
                    <BoxTestoCerca
                        placeholder=""
                        id="ricercaProdottiBD"
                        setInputCerca={setInputCerca}
                        startRicerca={startRicercaProdInBD}
                        inputCerca={inputCerca}
                        setSearchBoxRefInstance={setSearchBoxRefInstance}
                    />
                </Col>
                    
                    <div className="col-md-6 text-right">

                        <Button className="float-right" id='btnInviaLivellamento' variant="success" onClick={btnInviaClick} >
                            INVIA IN FARMACIA
                        </Button>

                        <Button className="float-right" id='btnElaboraLivellamento' variant="primary" onClick={btnElaboraClick} style={{ visibility: richiesta?.flagRichiestaVuota == 1 ? 'hidden' : 'visible' }}  >
                            ELABORA RICHIESTA
                        </Button>

                        <Button className="float-right" id='btnElaboraLivellamento' variant="danger" onClick={btnEliminaClick} disabled={selectedProd.length <= 0} >
                            ELIMINA PRODOTTI
                        </Button>

                        {/*<Button disabled={!selectedProd || selectedProd.length <= 0} className="float-right" id='btnElaboraLivellamentoSelezionati' variant="primary" onClick={btnElaboraSelezionatiClick} >*/}
                        {/*    ELABORA SELEZIONATI*/}
                        {/*</Button>*/}
                      
                    </div>
                </Row>
                <Row>
                    <Col md="5">
                        
                        <div> <p style={{ fontSize: 16, margin: 0, color: 'red' }}><FontAwesomeIcon visibility={avviso && avviso?.length > 0 ? 'always' : 'hidden'} icon={faExclamationCircle} className="fa fa-info-circle fa-lg" /><strong>{avviso}</strong> </p>  </div>
                    </Col>
                </Row>
                
            </div>
            

            <NewTabella
                idTabella="prodotti"
                filterRow
                searchPanel={true}
                height='45vh'
                activeSaver
                columnC
                colonne={columnsProdotti}
                dataSource={tabellaProdotti}
                exportT={{
                    enabled: false,
                    fileName: "list_prod",
                }}
                paging={false}
                onclickRow={(datiRow: any) => { setIdProdotto(datiRow.data?.id); setCodCsf(datiRow.data?.codiceCsf); setCodProd(datiRow.data?.codiceProdotto) }}
                onCellPrepared={onCellPrepared}
                hidePager={true }
                //selectedValue={(datiRow: any) => {
                //    setSelectedProd(datiRow.selectedRowKeys);
                //    if (datiRow.currentSelectedRowKeys.length > 0) {
                //        setIdProdotto(datiRow.currentSelectedRowKeys[0].id);
                //        setCodCsf(datiRow.currentSelectedRowKeys[0].codiceCsf);
                //        setCodProd(datiRow.currentSelectedRowKeys[0].codiceProdotto)
                //    }

                //}}

                multyp={true}
                selectedValue={(datiRow: any) => setSelectedProd(datiRow.selectedRowKeys) }
                summary={
                    <Summary>
                        <TotalItem column="codiceProdotto" summaryType="count" />
                    </Summary>} 
            />

            <div id="">
                <Row >
                    <Col sm='8'>
                        {idProdotto ?
                            <NewTabella
                                idTabella='prodotti_livellamento'
                                exportT={{ enabled: false }}
                                colonne={columnsLivellamento}
                                dataSource={tabellaLivellamento}
                                height='30vh'
                                //onFocusedCellChanged={onFocusedCellChanged}
                                onclickRow={(datiRow: any) => { setFarmaciaSelezionata(datiRow.data?.idFarmacia); setOldQuantita(undefined); setOldQuantitaAvere(undefined) }}
                                paging={false}
                                hidePager={true }
                                showPageSizeSelector={false}
                                onCellPrepared={onCellPreparedDett}
                                //onRowUpdating={onRowUpdating}
                                tabReference={setTabellaInstance}
                                keyExpr="idFarmacia"
                                
                                
                                
                            /> : null}
                    </Col>
                    <Col sm='4' >
                        {idProdotto ? <NewTabella
                            idTabella='prodotti_livellamento_info'
                            exportT={{ enabled: false }}
                            colonne={columnsLivellamentoProdottoInfo}
                            dataSource={tabellaLivellamentoProdottoInfo}
                            height='30vh'
                            onCellPrepared={onCellPreparedInfo}
                            paging={false}
                            hidePager={true}
                            showPageSizeSelector={false}
                            onRowUpdating={onRowUpdating}
                            onEditingStart={(datiRow: any) => { setOldQuantita(datiRow.data.quantitaTrasferita); setOldQuantitaAvere(datiRow.data.quantitaTrasferitaAvere) }}
                        />
                            :
                            null} 
                    </Col>
                </Row>
                
            </div>
            <Row>&nbsp;</Row>
            <Row id="gridVendutoRda">
                {(listFarmacie && listFarmacie.length > 0) && 

                    <TabelleVendutoLight
                        listaFarmacie={listFarmacie}
                        codCsf={codCsf}
                        setIsLoadVisible={setIsLoadVisible}
                        quantitaSelezionata={farmaciaSelezionata}
                    />
                

                }
                {loadPanelVenduto}
            </Row>

        </>
    )

}

export default DettaglioRichiestaLivellamentoMagazzino