import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import {  trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import { AplicaValoreTO ,actionTables } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'


import './style.css'



const columns = [
    { caption: "Num. Bolla", dataField: "numeroBolla",  width: 100 },
    { caption: "Data Bolla", dataField: "dataBolla", format: "dd/MM/yyyy", dataType: "date", width: 100 },
    { caption: "Data Ric.", dataField: "dataRicezione", format: "dd/MM/yyyy", dataType: "date", width: 100 },
    { caption: "Causale", dataField: "descCausale", width: 120 },  
    { caption: "Farmacia", dataField: "descFarmacia" },
    { caption: "Fornitore", dataField: "ragioneSocialeFornitore", width: 200 },
    { caption: "Referenze", dataField: "totaleReferenze" },
    { caption: "Totale Quantità", dataField: "totaleQuantita" },
    { caption: "Imponibile", dataField: "totaleImponibile" ,format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Fatt. Abb.", dataField: "numeroUltimaFatturaAbbinata" },
    { caption: "Azioni",   width: 80  , type: "buttons", buttons: [
        {
            hint: "Informazioni Ordine Libero",
            icon: "print",
            onClick: (e: any) => {
                //console.log(e.row.data.idCarico);
                actionTables(e.row.data.idCarico)
            }
        }]  },
    
];


const columnsCarichi = [
    { caption: "Codice", dataField: "codProd", width: 100},
    { caption: "Codice EAN", dataField: "ean", width: 100},
    { caption: "Descrizione Prod.", dataField: "descrizioneProdotto", width: 380 }, 
    { caption: "Quantità", dataField: "quantita", width: 75},
    { caption: "Qta Omaggio", dataField: "quantitaOmaggio", width: 75},
    { caption: "Costo", dataField: "costoListino",format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Imponibile", dataField: "totaleImponibile" ,format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Prezzo Base", dataField: "prezzoBase" ,format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Tipo Sconto", dataField: "desTipoSconto",  width: 80 },
    { caption: "Sc.1 %", dataField: "sconto1" , width: 100},
    { caption: "Sc.2 %", dataField: "sconto2" , width: 100},
    { caption: "Sc.3 %", dataField: "sconto3" , width: 100},
    { caption: "Sc.4 %", dataField: "sconto4" , width: 100},
    { caption: "Sc.5 %", dataField: "sconto5" , width: 100},
    { caption: "Prezzo Rif.", dataField: "prezzoListino" ,format: { type: "currency", precision: 2, currency: "EUR" } },
    { caption: "Iva", dataField: "iva",customizeText:(cellInfo:any)=> AplicaValoreTO(cellInfo, '%','iva','d') },
    { caption: "Deivato", dataField: "prezzoDeivato",format: { type: "currency", precision: 2, currency: "EUR" } },

];




const Fatcarichi = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idCarico,setIdCarico] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [tabellaCarichi,setTabellaCarichi] = useState<any>([])
    const [causali,setCausali] = useState([])
    const [newFarmaList,setNewFarmaList] = useState([])
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth);
    
    const itemsFields = [
        {type:'select' , idValue:'idFar' , label:'Farmacie ' ,displayExpr:'descrizione', dataSource : newFarmaList , require:true ,width:350 },
        {type:'date' , idValue:'dal' , label:'Dal' , dataSource : null, require:true , width:200 },
        {type:'date' , idValue:'al' , label:'Al' , dataSource : null, require:true , width:200 },
        {type:'string' , idValue:'numeroBolla' , label:'Num. Bolla ' , dataSource : null , width:200 },
        {type:'autocomplete' , idValue:'idFornitore' , label:'Fornitore ' ,typeApi:'fornitori' ,valueExpr:'codice' , apisearch:true, dataSource : {} , width:200 },
        {type:'select' , idValue:'idCausale' , label:'Causale' ,displayExpr:'descrizione', dataSource : causali , width:250  },
        {type:'string' , idValue:'numeroCarico' , label:'Num. Carico' , dataSource : null, width:200  },
        {type:'autocomplete' , idValue:'codCsf' , label:'Prodotto' , typeApi:'products',valueExpr:'text', apisearch:true, dataSource : {},width:300 },
    ]



    const farmaList = () =>{
         if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {                     
                        //console.log(result.response);
                        setNewFarmaList(result.response)                    
                    }else{                              
                        //console.log(result);
                        
                    }
                })     
        }
    }


    const getCausaliCarichi = () =>{     
        if (auth) {
            RestServices().rest('POST', null , urlConsultazione.getCausaliCarico)
                .then(result => {
                    if (result?.esito) {               
                       // console.log(result.response);                                         
                        setCausali(result.response)
                    }else{
                   //     console.log(result);               
                    }
                })     
        }
    }

    useEffect(() => {  
        getCausaliCarichi()
        farmaList()
    }, [auth]);

    
    const getCarichiAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {idCarico  : idCarico} , urlConsultazione.getCarico)
                .then(result => {
                    if (result?.esito) {
                        setTabellaCarichi(result.response?.dettaglio)
                        setLoading(false)
                    }else{
                        //console.log(result);                
                        setLoading(false)
                    }
                })
        
    }
    }

    const filterAction = (objFiltro:Filtri) =>{
        let params = {...objFiltro}
        //console.log(params);
        setIdCarico(null)
        if(params.dal ){
            params.idFar = Number(params.idFar?.idFar)
            params.dal = trasformaDateTimeInLocale(params.dal , true);
            params.al = trasformaDateTimeInLocale(params.al, true);
            if(objFiltro.codCsf){
                params.codCsf = Number(params.codCsf?.id)
            }
            if(objFiltro.idFornitore){
                params.idFornitore = objFiltro.idFornitore.id
            }
            if(objFiltro.numeroCarico || objFiltro.numeroCarico != ''){
                params.numeroCarico = objFiltro.numeroCarico
            }else{
                delete params.numeroCarico
            }
            if(objFiltro.idCausale){
                params.idCausale = objFiltro.idCausale.id
            }
            if(objFiltro.numeroBolla || objFiltro.numeroBolla != ''){
                params.numeroBolla = objFiltro.numeroBolla
            }
            else{
                delete params.numeroBolla
            }
            //console.log(params);

            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListCarichi)
                        .then(result => {
                            if (result?.esito) {
                                setTabellaPrincipale(result.response)
                                setLoading(false)
                            }else{
                                //console.log(result);
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        }
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    useEffect(() => {
        if (idCarico) {
            getCarichiAction()
        }
    }, [idCarico]);

   
    return (
        <>
        <Loading isVisible={loading} />
            <div className='title'>
                <h3> CARICHI  <span className='subt' > Consultazione carichi</span> </h3>
             </div>
            <Ricerca ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  />
           
            <NewTabella 
                idTabella='elenco_fat_carichi'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                height='50vh'
                searchPanel={true}
                onclickRow={(datiRow:any)=>setIdCarico(datiRow.data?.idCarico)}
                exportT={{
                    enabled: true,
                    fileName: "fat_carichi"
                }}
                activeSaver
                columnC
                summary={
                    <Summary>
                        <TotalItem column="dataBolla" summaryType="count" />
                        <TotalItem column="totaleQuantita" summaryType="sum"  /> 
                        <TotalItem column="totaleImponibile" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                    </Summary>}
            />
            <div id="">
                    {idCarico ? (
                        <div style={{marginTop:10}}>
                            <h4> PRODOTTI</h4>
                             <NewTabella 
                              height='50vh'
                              idTabella='elenco_ord_prod'
                              exportT={{enabled: false}} 
                              colonne={columnsCarichi} 
                              dataSource={tabellaCarichi}
                              summary={
                                <Summary>
                                    <TotalItem column="totaleImponibile" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}}  /> 
                                </Summary>}
                              /> 
                        </div>    
                    )
                   
                    : null }
            </div>
        </>
    )
}

export default Fatcarichi



