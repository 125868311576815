import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { trasform_for_cards_charts_01, transformCategory } from "../util/trasformation";
import { boxCardVendCategory } from "./Boxcards/AnalOperativo";
import { columnsAnalisiVendOperativo } from "./Colums/ColAnalOperativo";
import { MenuCruscottoOperativo } from "../components/menu_operativo";
import { createItemFilterAnalVendCategory } from "./ItemsFiltri/FiltriAnalOperativo";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";

import "./style.css";

interface DialogProps {
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
}




const AnalVendCateOper = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardVendCategory);
  const [settori, setSettori] = useState<any[]>([]);
  const [category, setCategory] = useState<any[]>([]);
  const [ditte, setDitte] = useState<any[]>([]);
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [openPopTwo, setOpenPopTwo] = useState<boolean>(false);
  const [openPopThree, setOpenPopThree] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);

  const dashboard24 = { dashboardID: 24 };

  function aggiornaDataFine(dataFine: Date): Date {
    const nuovaDataFine = new Date(dataFine);

    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

      let venditaDPC = params?.venditaDPC ? [] : [0];
      let flagBusta = params?.flagBusta ? [] : [0];

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      venditaDPC: venditaDPC,
      flagBusta: flagBusta,
      operatori: params.operatori,
        dal: params.periodo.dal.toLocaleString().replace(",", ""),
        al: params.periodo.al.toLocaleString().replace(",", ""), 
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall24 = {
        ...dashboard24,
        idFarList: params.idFar,
        params: parameters,
      };

      

      RestServices()
        .rest("POST", dashboardCall24, urlReport.getQueryDashboardAllData)
        .then((result) => {
            if (result) {

                let datiPrimaParteCards = trasform_for_cards_charts_01(result["104"], boxCardVendCategory);
                setFila1cards(datiPrimaParteCards);

                const anno = params.periodo.dal.substring(0 , 4)

                let datiPrimaTabella = transformCategory(result["105"], anno);
                console.log(datiPrimaTabella);
                setValoriTabella1(datiPrimaTabella);

           
            setIsVisible(false);

          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });


      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });


    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);


  const itemsFields = createItemFilterAnalVendCategory(
    newFarmaList,
    category,
    ditte,
    settori
  );

  const filters = () => {
    if (auth) {

      RestServices()
        .rest("POST", "", urlBancaDati.getSettore)
        .then((result) => {
          if (result) setSettori(result);
        });

      RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
        // console.log('vediamoooo categorie');
        // console.log(result);
        if (result) setCategory(result);
      })

      RestServices()
        .rest("POST", "", urlBancaDati.getDitte)
        .then((result) => {
          if (result) {
            const ditteWithCodiceDescrizione = result.map(
              (ditta: { codice: any; descrizione: any }) => {
                return {
                  ...ditta,
                  codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                };
              }
            );
            // console.log('da togliere vediamo ditte');
            // console.log(ditteWithCodiceDescrizione);

            setDitte(ditteWithCodiceDescrizione);
          }
        });
    }

    const ssn = { id: 1, descrizione: "SSN" };
    const libera = { id: 0, descrizione: "Libera" };

    const vendite = [libera, ssn];
    setTipologiaVendita(vendite);
  };

  useEffect(() => {
    filters();
  }, [auth]);

    const AnaVenProdotto = [
        { caption: "Prodotto - MinSan", dataField: "", width: "auto" },
        { caption: "Incidenza % valore del venduto periodo selezionato", dataField: "", width: "auto" },
        { caption: "Incidenza % valore del venduto annao precedente", dataField: "", width: "auto" },
        { cpation: "Valore del venduto periodo selezionato", dataField: "", width: "auto" },
        { caption: "Valore del venduto anno precedente", dataField: "", width: "auto" },
        { caption: "Delta valore del venduto", dataField: "", width: "auto"}
  ];

  
    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };


  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  return (
    <>
      <section style={{ display: "flex", height: "98vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >
              <h2>BusinessLab - Cruscotto Operativo</h2>
            </div>
            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuCruscottoOperativo />
              </div>
            </div>

            {/* flexbox */}

            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"40%"}
              height={"40%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup>


          <div style={{ paddingBottom: 5 }} className="grid-container-six">
              {fila1cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
         </div>
            
            <div style={{marginTop:5, paddingBottom: 50}}>

            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 17, marginTop: 10 }}>Tabella Dettaglio</h6>

            <NewTabella
              height={"70vh"}
              idTabella="AnaVendCategory"
              sync
              colonne={columnsAnalisiVendOperativo}
              dataSource={valoriTabella1}
              rowAlternation={true}
              filterRow
              width={500}
              searchPanel={true}
              exportT={{
                enabled: true,
                fileName: "TabellaDettaglio",
                formats: { exportFormats },
              }}
              activeSaver={true}
              columnC={true}
              headerFilter
              wordWrapEnabled={true}
              onExporting={onExporting}
              summary={
                <Summary>
                  <TotalItem
                    column="VALORE_VENDUTOCR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                />
                  <TotalItem
                    column="VALORE_VENDUTOAP" 
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                />
                  <TotalItem
                    column="MARGINECR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                      />
                <TotalItem
                    column="VOLUME_VENDITACR"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                />
                </Summary>
              }
            />

     </div>
            {/* <div className="containButtonAna">
              <div className="buttonCerca">
                <Button
                  id="btnCercax"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  className="btn btn-primary"
                  variant="primary"
                  onClick={() => {
                    setOpenPop(true);
                    console.log("dialog");
                    //ricerca(objPostRicerca);
                  }}
                >
                  Analisi operatori per gruppo merceologico
                </Button>

                <Button
                  className="btn btn-primary"
                  variant="primary"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  onClick={() => {
                    setOpenPopTwo(true);
                    console.log("dialog2");
                    //setOpenPoptwo(true)
                    //ricerca(objPostRicerca);
                  }}
                >
                  Analisi operatori per ditta titolare
                </Button>
                <Button
                  className="btn btn-primary"
                  variant="primary"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  onClick={() => {
                    console.log("dialog3");
                    setOpenPopThree(true);
                    //ricerca(objPostRicerca);
                  }}
                >
                  Analisi operatori cross farmacie dello stesso gruppo
                </Button>
              </div>
            </div> */}
          </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
          <div style={{}}>
            <FiltriVerticale
              ricerca={filterAction}
              items={itemsFields}
              paramsFilter={paramsFilter}
            />
          </div>
        </section>
      </section>
    </>
  );
};

export default AnalVendCateOper;
