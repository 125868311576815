import { faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart, PieChart } from 'devextreme-react';
import { ArgumentAxis, CommonSeriesSettings, Export, Label, Legend, ScrollBar, Series, SeriesTemplate, Subtitle, Title, Tooltip, ValueAxis } from 'devextreme-react/chart';
import { Connector, Font, Format, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { format } from 'util';
import { useAuth } from '../../../hooks/useAuth';
import { ListaFarmacieInterface } from '../../../interfaces/farmacie';
import { GruppoMerceologicoInterface } from '../../../interfaces/generiche';
import { RestServices } from '../../../services/restServices';
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from '../../consultazione/interface';
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from '../../costanti';
import { firstWidget } from '../queries/queriesCruscotto';
import '.././style.css';
import { NewTabella } from '../../../widget/nuova_tabella/newVersion';
import { Loading } from '../../../widget/Notifications';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { TitoloContenuto } from '../../../struttura_pagina/TitoloContenuto';
import { Summary, TotalItem } from 'devextreme-react/data-grid';

const BusinessLite = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({})
    const [isVisible, setIsVisible] = useState(false);

    const [chartData, setChartData] = useState<any>([]);
    const chartRef = useRef<any>(null);
    const [chartKey, setChartKey] = useState(false);

    const [chartDataMargine, setChartDataMargine] = useState<any>([]);
    const chartRefMargine = useRef<any>(null);
    const [chartKeyMargine, setChartKeyMargine] = useState(false);

    const [chartDataNumeroVend, setChartDataNumeroVend] = useState<any>([]);
    const chartRefNumeroVend = useRef<any>(null);
    const [chartKeyNumeroVend, setChartKeyNumeroVend] = useState(false);

    const [chartDataPezzi, setChartDataPezzi] = useState<any>([]);
    const chartRefPezzi = useRef<any>(null);
    const [chartKeyPezzi, setChartKeyPezzi] = useState(false);

    const chartRefDitta = useRef<any>(null);
    const [chartKeyDitta, setChartKeyDitta] = useState(false);

    const [chartDataVNSPie, setChartDataVNSPie] = useState<any>([]);
    const [chartDataSSNPie, setChartDataSSNPie] = useState<any>([]);
    const [chartDataATCPie, setChartDataATCPPie] = useState<any>([]);
    const [chartDataDittaPie, setChartDataDittaPie] = useState<any>([]);
    const [chartDataOperatori, setChartDataOperatori] = useState<any>([]);

    const chartRefMVNSPie = useRef<any>(null);

    const [totalValue, setTotalValue] = useState(0);
    const [totalValueMargine, setTotalValueMargine] = useState(0);
    const [totalValueNumeroVend, setTotalValueNumeroVend] = useState(0);
    const [totalValuePezzi, setTotalValuePezzi] = useState(0);

    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);

    const visualRange = { startValue: 300, endValue: 500 };


    const auth = useAuth(urlConsultazione.auth)
    const authReport = useAuth(urlReport.auth)

    

   // const [gruppoMercFilters, setGruppoMercFilters] = useState<GruppoMerceologicoInterface[]>();
    const titolo = TitoloContenuto("BusinessLab Lite - Analisi venduto");
    const [settori, setSettori] = useState<any[]>([]);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const [codiciAtc, setCodiciAtc] = useState<any[]>([]);
    const [ditte, setDitte] = useState<any[]>([]);
    const [operatori, setOperatori] = useState<any[]>([]);
    const [annimesi, setAnniMesi] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />



    // const testApicrusc = {
    //     queryID: firstWidget.queryID,
    //     idFarList: [17,19],
    //     filterDetailsIDs: firstWidget.filterDetailsIDs.map(column => {
            
            
    //         const {
    //             select,
    //             where,
    //             groupBy,
    //             filterValueList,
    //             orderBy
    //         } = column;

    //         const dwhColumn = {
    //             id: column.id,
    //             select,
    //             where,
    //             groupBy,
    //             filterValueList,
    //             orderBy
    //         };

    //         return dwhColumn;
    //     })
    // };

   

    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
           // newArr.push({text: element.descrizione, isSelected: element.isSelected ? true : false, idFar: element.idFar})ù
            newArr.push({ DESCRIZIONE: element.descrizione, isSelected: element.isSelected ? true : false, ID_FAR: element.idFar, CODICE: element.codice })
        });
        return newArr
    }

    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale è mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = []
        let newIDSettore: any = []


        //mettere altri filtri che mi servono

        /*
        if (objFiltro.codiceSettore) {
            objFiltro.codiceSettore.forEach((element: any) => {
                console.log(element);
                newIDSettore.push(element)
            });

            params.codiceSettore = newIDSettore
        }
        */

        const monthNames = [
            "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
            "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
        ];


        if (objFiltro.idFar) {

            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element)
            });
            params.idFar = newIDFAR
        }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter((item: number) => item % 100 === 0);
            gruppoMerc2 = params.gruppoMerc.filter((item: number) => item % 10 === 0 && item % 100 !== 0);
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {
            

            /**qui api test  */
            const testapi = {
                //...testApicrusc,
                idFarList: params.idFar,
                params: parameters,
            };

            RestServices()
                .rest('POST', testapi, urlReport.getQueryDashboardData)
                .then(result => {

                    console.log('vediamooooo se funzionneeee');
                    console.log(result);
                    
                    

                    setIsVisible(false);

                    if (result) {
                        
                        setChartDataMargine(result.reverse());
                        const total = result.slice(12, 23).reduce((acc: number, item: any) => acc + item.MARGINE_TOTALE, 0);
                        const formattedTotalMargine = total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });

                        setTotalValueMargine(formattedTotalMargine);

                        setChartKeyMargine(prevKey => !prevKey);
                        if (chartRefMargine.current) {
                            const chartInstance = chartRefMargine.current.instance;
                            chartInstance.dataSource = chartDataMargine;
                        }
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000);
                    }
                })
                .catch(error => {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "Errore" + error }, "error", 4000);
                });

            
            
            setParamsFilter(params);
            setIsFiltersExpanded(false);


        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        setNewFarmaList(result.response)
                    } else {
                        // handle error
                    }
                })


            RestServices().rest('POST', {}, urlConsultazione.getListOperatoreDistinct).then(result => {
                if (result.esito) {
                    const operatori = result.response.map((ope: {
                        idFarmacia: any; codice: any; operatore: any;
                    }) => {
                        //console.log(ope.idFarmacia);
                        //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                        //console.log(farmaciaCorrispondente);
                        return {
                            ...ope,
                            id: `${ope.operatore}`,
                            descrizione: `${ope.operatore}`,
                            codice_descrizione: `${ope.codice} - ${ope.operatore}`
                        };
                    });
                    setOperatori(operatori);
                }
            });
        }
    }


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();

    }, [auth]);


    const filters = () => {
        if (auth) {
            RestServices().rest('POST', "", urlBancaDati.getGruppoMerceologico).then(result => {
                if (result) setGruppoMercFilters(result);
            })
                    /*
                    const groupedFilters = {
                        gruppoMercFilters1: result.filter((item: { livello: number; }) => item.livello === 1),
                        gruppoMercFilters2: result.filter((item: { livello: number; }) => item.livello === 2),
                        gruppoMercFilters3: result.filter((item: { livello: number; }) => item.livello === 3)
                    };

                    setGruppoMercFilters1(groupedFilters.gruppoMercFilters1);
                    setGruppoMercFilters2(groupedFilters.gruppoMercFilters2);
                    setGruppoMercFilters3(groupedFilters.gruppoMercFilters3);
                }
            })*/

            RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                if (result) setSettori(result);
            })
                
           
            RestServices().rest('POST', "", urlBancaDati.getAtc).then(result => {
                if (result) setCodiciAtc(result);
            })

            RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
                if (result) {
                    const ditteWithCodiceDescrizione = result.map((ditta: { codice: any; descrizione: any; }) => {
                        return {
                            ...ditta,
                            codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`
                        };
                    });
                    setDitte(ditteWithCodiceDescrizione);
                }
            });

            const ssn = { id: 1, descrizione: 'SSN' }
            const libera = { id: 0, descrizione: 'Libera' }

            const vendite = [ libera, ssn];
            setTipologiaVendita(vendite);
        }
    };


    useEffect(() => {
        filters();


        
    }, [auth]);

    const exportFormats = ['csv'];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('tabella_operatori');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "tabella_operatori.csv");
            });
        });
    }

    const itemsFields = [
        { type: 'dateRange', idValue: 'periodo', width: 400, require: true },
        { type: 'select3', idValue: 'idFar', label: 'Farmacie ', displayExpr: 'text', dataSource: ConvertForSelect(newFarmaList), idValueGrid: 'idFar', require: true },
        { type: 'selectWithLevels', id: 'gruppoMerc', label: 'Gruppo Merceologico', displayExpr: 'descrizione', dataSource: gruppoMercFilters, idValueGrid: 'gruppoMerc' as any },
        { type: 'selectWithLevels', id: 'codiceSettore', label: 'Settore di inventario ', displayExpr: 'descrizione', dataSource: settori, idValueGrid: 'codiceSettore' as any },
        { type: 'selectWithLevels', id: 'venditaSSN', label: 'Tipologia di vendita', displayExpr: 'descrizione', dataSource: tipologiaVendita, idValueGrid: 'venditaSSN' as any },
        { type: 'selectWithLevels', id: 'atc', label: 'Codice ATC', displayExpr: 'descrizione', dataSource: codiciAtc, idValueGrid: 'atc' as any },
        { type: 'selectMultipla', id: 'ditta', label: 'Ditta', displayExpr: 'descrizione', dataSource: ditte, idValueGrid: 'ditta' as any },
        { type: 'selectMultipla', id: 'operatori', label: 'Operatori', displayExpr: 'operatore', dataSource: operatori, idValueGrid: 'operatori' as any }
    ];

    const columnsOperatori = [
        { caption: "Operatore", dataField: "OPERATORE_USERNAME", width: 'auto' },
        { caption: "Farmacia", dataField: "FARM_DESCRIZIONE", width: 'auto' },
        { caption: "Pezzi venduti", dataField: "QUANTITA", width: 'auto' },
        { caption: "Incidenza % valore venduto", dataField: "INCIDENZA_VENDUTO_LORDO_SCONTI", width: 'auto' },
        { caption: "Valore del venduto lordo sconti", dataField: "VENDUTO_LORDO_SCONTI", width: 'auto', format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto prodotto", dataField: "SCONTO", width: 'auto', format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Valore del venduto netto sconti", dataField: "VENDUTO_NETTO_SCONTI", width: 'auto', format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Imponibile", dataField: "IMPONIBILE", width: 'auto', format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Margine", dataField: "MARGINE", width: 'auto', format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Margine percentuale", dataField: "MARGINE_PERC", width: 'auto' }
    ];

    function formatLabel(arg: { argumentText: any; valueText: any; }) {
        return `${arg.argumentText}: ${arg.valueText}%`;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter(atc => atc.length === (level * 1));
    }

    const onPointClick = (e: { target: any; }) => {
        const point = e.target;
        if (point.isSelected()) {
            point.clearSelection();
        } else {
            point.select();
        }
    }


    return (
        <>

            <div style={{ float: 'right', marginLeft: '2em', visibility: 'visible' }}>
                <FiltriVerticale ricerca={filterAction} items={itemsFields} paramsFilter={paramsFilter} />
            </div>
        </>
    );
}; 


export default BusinessLite;
