import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { FrameWrapper } from "../../widget/FrameWrapper";
import { urlCommon } from "../costanti";
import './style.css';


const ReleaseNote = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const [isVisible, setIsVisible] = useState(false);
    const [releaseNote, setreleaseNote] = useState<any[]>([]);
    const auth = useAuth(urlCommon.auth);
    const [renderp, setRenderp] = useState<any>();
    const location = useLocation<any>();
    const { revisioneRilascio } = useParams<{ revisioneRilascio: string }>();
    const history = useHistory();


    const tornaInCima = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const showIndex = () => {

        window.location.href = ('/app/DocumentazioniRevisioni/Documentazione')
    };

    const showGuida = () => {

        setRenderp(1);
    };


    const releaseNoteList = () => {

        setIsVisible(true);
        if (auth) {
            RestServices()
                .rest("POST", {}, urlCommon.getReleaseNote)
                .then((result) => {
                    if (result?.esito) {

                        setreleaseNote(result.response);

                        //console.log(result.response)

                    } else {
                        // handle error
                        setIsVisible(false);
                    }
                });
        }
    };

    useEffect(() => {
        releaseNoteList();
    }, [auth]);




    return (
        <>
            <br></br>

            <div style={{ textAlign: 'center', marginBottom: '3%', marginTop: '2%' }}>
                <h2><b>Release Note</b></h2>
            </div>


            <section style={{
                width: '80%', margin: '0 auto',
            }}>

                <div style={{ /*textAlign: 'center',*/  marginBottom: '3%' }}>
                    <div style={{}}>
                        <Button /*className="custom-button"*/ variant="primary" onClick={() => { showIndex(); }}><b>Vai all'elenco delle Revisioni</b></Button>
                    </div>

                </div>

                <div>

                    {(() => {

                        if (releaseNote && releaseNote.length > 0) {

                            const revisioniStampate = new Set();
                            const dataRilascioStampate = new Set();
                            const moduliStampati = new Set();


                            return releaseNote.map((releaseNote, index) => {


                                // Se la versione della revisione non � stata stampata la stampa, pulisce i moduli e la data 
                                // cos� sono organizzati per data e stampa il primo articolo completo
                                // aggiungendo il modulo cos� poi nel blocco successivo fa' i controlli giusti
                                if (!revisioniStampate.has(releaseNote.revisioneRilascio)) {

                                    revisioniStampate.add(releaseNote.revisioneRilascio);
                                    dataRilascioStampate.clear();
                                    moduliStampati.clear();

                                    dataRilascioStampate.add(releaseNote.dataRilascio);
                                    moduliStampati.add(releaseNote.idModulo);

                                    return (
                                        <div key={`data-${index}`} style={{ padding: 25, marginTop: "2%" }}>

                                            <div style={{ textAlign: 'center', marginBottom: "5%" }}>
                                                <h3><b>{`Revisione: ${releaseNote.revisioneRilascio} del ${releaseNote.dataRilascio}`}</b></h3>
                                            </div>

                                            <div style={{ textAlign: 'center' }}>
                                                <h4><b>{`Modulo: ${releaseNote.descrizioneModulo}`}</b></h4>
                                            </div>

                                            <div style={{ gap: 10, background: '#ffffff', padding: 25, borderBottom: '2px dashed #000', borderRadius: 10, marginTop: 10 }}>
                                                <FrameWrapper html={`${releaseNote.linkArticolo}`} />
                                                <h6 style={{ textAlign: 'right', fontStyle: 'italic', marginTop: 1 }}>{`Disponibile dalla Revisione ${releaseNote.revisioneRilascio} del: ${releaseNote.dataRilascio}`}</h6>
                                            </div>

                                        </div>
                                    );


                                }

                                // Se la data non � stata stampata la stampa, pulisce i moduli cos� sono organizzati per data e stampa il primo articolo completo
                                // aggiungendo il modulo cos� poi nel blocco successivo fa' i controlli giusti
                                if (!dataRilascioStampate.has(releaseNote.dataRilascio)) {

                                    dataRilascioStampate.add(releaseNote.dataRilascio);
                                    moduliStampati.clear();

                                    moduliStampati.add(releaseNote.idModulo);

                                    return (
                                        <div key={`data-${index}`} style={{ padding: 25, marginTop: "2%" }}>

                                            <div style={{ textAlign: 'center', marginBottom: "5%" }}>
                                                <h3><b>{`Revisione: ${releaseNote.revisioneRilascio} del ${releaseNote.dataRilascio}`}</b></h3>
                                            </div>

                                            <div style={{ textAlign: 'center' }}>
                                                <h4><b>{`Modulo: ${releaseNote.descrizioneModulo}`}</b></h4>
                                            </div>

                                            <div style={{ gap: 10, background: '#ffffff', padding: 25, borderBottom: '2px dashed #000', borderRadius: 10, marginTop: 10 }}>
                                                <FrameWrapper html={`${releaseNote.linkArticolo}`} />
                                                <h6 style={{ textAlign: 'right', fontStyle: 'italic', marginTop: 1 }}>{`Disponibile dalla Revisione ${releaseNote.revisioneRilascio} del: ${releaseNote.dataRilascio}`}</h6>
                                            </div>

                                        </div>
                                    );


                                }

                                //Se il modulo non � stampato lo stampa e lo aggiunge al set
                                if (!moduliStampati.has(releaseNote.idModulo)) {

                                    moduliStampati.add(releaseNote.idModulo);

                                    return (
                                        <div key={index} style={{ padding: 25, marginTop: "5%" }}>

                                            <div style={{ textAlign: 'center' }}>
                                                <h4><b>{`Modulo: ${releaseNote.descrizioneModulo}`}</b></h4>
                                            </div>

                                            <div style={{ gap: 10, background: '#ffffff', padding: 25, borderBottom: '2px dashed #000', borderRadius: 10, marginTop: 10 }}>
                                                <FrameWrapper html={`${releaseNote.linkArticolo}`} />
                                                <h6 style={{ textAlign: 'right', fontStyle: 'italic', marginTop: 1 }}>{`Disponibile dalla Revisione ${releaseNote.revisioneRilascio} del: ${releaseNote.dataRilascio}`}</h6>
                                            </div>
                                        </div>
                                    );
                                }

                                // Se il modulo � gi� stato stampato, genera solo l'articolo
                                return (
                                    <div key={index} style={{ padding: 25, marginTop: "5%" }}>

                                        <div style={{ gap: 10, background: '#ffffff', padding: 25, borderBottom: '2px dashed #000', borderRadius: 10, marginTop: 10 }}>
                                            <FrameWrapper html={`${releaseNote.linkArticolo}`} />
                                            <h6 style={{ textAlign: 'right', fontStyle: 'italic', marginTop: 1 }}>{`Disponibile dalla Revisione ${releaseNote.revisioneRilascio} del: ${releaseNote.dataRilascio}`}</h6>
                                        </div>

                                    </div>
                                );

                            });

                        } else {

                            return (

                                <div style={{ textAlign: 'center', background: 'white', padding: 30, borderRadius: 10, }}>
                                    <h4><b>{`Nessuno articolo disponibile per la  Revisione selezionata`}</b></h4>
                                </div>
                            );
                        }


                    })()}


                </div>


                <div style={{ display: "flex", marginBottom: 30 }}>
                    <div style={{}}>
                        <Button /*className="custom-button"*/ variant="primary" onClick={() => { showIndex(); }}><b>Vai all'elenco delle Revisioni</b></Button>
                    </div>

                    <div style={{ marginLeft: "auto" }}>
                        <Button className="btn btn-warning" variant="warning" onClick={() => { tornaInCima(); }}><b>Torna In Cima</b></Button>
                    </div>
                </div>

                <br />
                <br />

            </section>



        </>

    )

};

export default ReleaseNote;