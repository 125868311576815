import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react"
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { FornitoriCentrInterface, OggettoInterface } from "../../interfaces/generiche";
import { FatturaProdottoAbbinamento } from "../../interfaces/prodottoAbbinamento";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { RestServices } from "../../services/restServices";
import { TabellaCompleta } from "../../widget/Tabelle";
import { urlAnagrafiche, urlBancaDati, urlProdottoAbbinamento } from "../costanti";
import { DialogConferma, Loading } from "../../widget/Notifications";
import { Selezione } from "../../widget/Select";
import { BoxFiltro, BoxTestoCerca } from "../../widget/Editor";
import { RicercaProdInterface } from "../../interfaces/bancaDati";
import DialogAggiungiProdOrdine from "../../common/DialogAggiungiProdOrdine";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export const DialogElencoFatturaDisabbina = (props: {
    isDialogDisabbinaOpen: boolean,
    setIsDialogDisabbinaOpen: (isDialogDisabbinaOpen: boolean) => void
    handleCloseDialogDisabbina: (aggiorna: boolean) => void,
    setIsVisible: (isVisible: boolean) => void,
    codCsf: number|undefined,
    ean: string | undefined,
    idFatProdottoAbbinamento: number | undefined,
    descrizione?: string | undefined,
    partitaIvaFornitore?: string | undefined,
    partitaIvaDitta?: string | undefined,

}) => {


    useEffect(() => {
        if (props.isDialogDisabbinaOpen)
            cerca();
    }, [props.isDialogDisabbinaOpen])

    const newElencoFattura = {
        idFat: undefined,
        idFar:undefined,
        numeroDocumento: undefined,
        dataDocumento: undefined,
        fornitore: undefined,
        imponibile: undefined
    }

    const [filtri, setFiltri] = useState<FatturaProdottoAbbinamento>(newElencoFattura);
    const [elencoFattura, setElencoFattura] = useState<FatturaProdottoAbbinamento[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [messaggioConferma, setMessaggioConferma] = useState<any>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const cerca = () => {
        const param = {
            ean: props.ean,
            codCsf: props.codCsf,
            partitaIvaFornitore: props.partitaIvaFornitore
        };
        if (props.ean && props.codCsf) {
            RestServices().rest('POST', param , urlProdottoAbbinamento.getFattureProdottoAbbinamento).then(result => {
                if (result) {
                    setElencoFattura(result);
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                }
            })
        }
    }
    const handleClose = (aggiorna: boolean) => {
        setElencoFattura(undefined);
        setTabellaInstance(undefined)
        props.handleCloseDialogDisabbina(aggiorna);

    }

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.nascosto == true)
                e.component.deselectRows(row); return;
        })
    }

    const disabbinaProdottoAbbinamentoClick = (e: any) => {
        var keys = tabellaInstance.instance.getSelectedRowKeys();
        
        if (keys.length == 0) {
            setMessaggioConferma("Sara' elminata la regola di abbinamento e nessuna fattura sara' disabbinata automaticamente. Continuare?");
        }
        else {
            setMessaggioConferma("Sara' elminata la regola di abbinamento e disabbinate automaticamente le fatture selezionate. Continuare?");
        }
        setDialogIsOpen(true);
    }

    const functionNext = () => {
        setDialogIsOpen(false);
        disabbinaProdotto();
    }

    const disabbinaProdotto = () => {
        var keys = tabellaInstance.instance.getSelectedRowKeys();
        var lstFat = new Array();
        for (var i = 0; i < keys.length; i++) {
            lstFat.push(keys[i].idFat);
        }
        const param = {
            ean: props.ean,
            codCsf: props.codCsf,
            idFatProdottoAbbinamento: props.idFatProdottoAbbinamento,
            lstFat: lstFat,
        }; 
        RestServices().rest('POST', param, urlProdottoAbbinamento.disabbinaProdotto).then(result => {
            if (result.esito) {
                notify({ position: "center", width: "auto", message: "Operazione effetuata con successo" }, "success", 4000)
                handleClose(true);
            } else {
                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                return;
            }
        })
    }

    const propsTabElencoFattura: TabellaCompletaInterface = {
        id: "prodotto_abbinamentno_fatture",
        dataSource: elencoFattura,
        pagination: 10,
        selection: "multiple",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: true,
        enablePagination: true,
    }

    const columnsElencoFattura = CreaColonne([
        { caption: "Id fat", dataField: "idFat", visible: false },
        { caption: "Id far", dataField: "idFar", visible: false },
        { caption: "Numero doc.", dataField: "numeroDocumento" },
        { caption: "Data documento", dataField: "dataDocumento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Fornitore", dataField: "fornitore" },
        { caption: "Imponibile", dataField: "imponibile" },
    ])

    return (
        <>
            <Modal
                show={props.isDialogDisabbinaOpen}
                onHide={()=>handleClose(false)}
                dialogClassName="dialogElencoFattura"
                size="xl"
                centered={true}
                backdrop="static"
                
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloElencoFattura">
                        <h3>Disabbina prodotto</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="1" className="infoProdottoAbbinamento">
                            <FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg"/>
                        </Col>
                        <Col sm="11">
                            <p className="testo">
                                Elenco delle fatture in cui il codice articolo <b>{props.ean}</b> e' abbinato al prodotto <b>{props.descrizione}</b><br></br>
                                Premendo il pulsante "Disabbina" verra' eliminata la regola di associazione tra codice articolo e prodotto; inoltre, selezionando le fatture in elenco, sara' <br></br>
                                anche annullato l'abbinamento all'interno delle fatture scelte
                            </p>
                        </Col>
                    </Row>
                    <TabellaCompleta propsTabella={propsTabElencoFattura} columns={columnsElencoFattura} setTabellaInstance={setTabellaInstance} selectedRowKeys={selectedRowKeys} onSelectionChanged={onSelectionChanged} />

                </Modal.Body>
                <Modal.Footer>
                    
                    <Button id="btnDisabbinaProdottoFattura" variant="success" onClick={disabbinaProdottoAbbinamentoClick} >
                        Disabbina
                    </Button>

                    <Button id="btnChiudiDialogElencoFattura" variant="danger" onClick={() => handleClose(false)}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

            <DialogConferma
                dialogIsOpen={dialogIsOpen}
                message={messaggioConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />

        </>
        )
}


export const DialogElencoFatturaAbbina = (props: {
    isDialogAbbinaOpen: boolean,
    setIsDialogAbbinaOpen: (isDialogAbbinaOpen: boolean) => void
    handleCloseDialogAbbina: (aggiornaGriglia: boolean) => void,
    setIsVisible: (isVisible: boolean) => void,
    codCsf?: number | undefined,
    ean?: string | undefined,
    idFatProdottoAbbinamento?: number | undefined,
    descrizione?: string | undefined,
    codProd?: string | undefined,
    eanProdotto?: string | undefined,
    partitaIvaFornitore?: string | undefined,
    partitaIvaDitta?: string | undefined,

}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [elencoFattura, setElencoFattura] = useState<FatturaProdottoAbbinamento[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [messaggioConferma, setMessaggioConferma] = useState<any>();
    const [inputCerca, setInputCerca] = useState<string>("");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [isDialogAddProdOpen, setIsDialogAddProdOpen] = useState<boolean>(false);
    const handleDialogAddProdClose = () => setIsDialogAddProdOpen(false);
    const handleDialogAddProdOpen = () => setIsDialogAddProdOpen(true);
    const svuotaDialogAddProd = () => setCercaNuovoProd([]);
    const [lstFar, setLstFar] = useState<number[] | undefined>();
    const [codProd, setCodProd] = useState<string>();
    const [descProd, setDescProd] = useState<string>();
    const [codCsf, setCodCsf] = useState<number>();
    const [codEan, setCodEan] = useState<string>();
    const [partitaIvaFornitore, setPartitaIvaFornitore] = useState<string>();
    const [partitaIvaDitta, setPartitaIvaDitta] = useState<string>();
    const [fornitori, setFornitori] = useState<FornitoriCentrInterface[]>();


    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        if (props.isDialogAbbinaOpen) {
            setIsVisible(true);
            RestServices().rest('POST', "", urlAnagrafiche.getFornitoreCentral).then(result => {
                if (result) setFornitori(result);
            })

            setLstFar([]);
            setFiltri({ codArticolo: props.ean });
            setCodProd(props.codProd);
            setDescProd(props.descrizione);
            setCodCsf(props.codCsf);
            setCodEan(props.eanProdotto);
            setPartitaIvaFornitore(props.partitaIvaFornitore);
            setPartitaIvaDitta(props.partitaIvaDitta);
            setInputCerca("");
            setIsVisible(false);
        }
    }, [props.isDialogAbbinaOpen])

    useEffect(() => {
        
        let newFiltro: OggettoInterface = {};
        if (partitaIvaFornitore ) {
            newFiltro["idAnaFornitore"] = fornitori?.find(item => item.PARTITA_IVA == props.partitaIvaFornitore)?.ID;
            if (impostaFiltri)
                impostaFiltri(newFiltro);
        }
        else if (partitaIvaDitta) {
            newFiltro["idAnaFornitore"] = fornitori?.find(item => item.PARTITA_IVA == props.partitaIvaDitta)?.ID;
            if (impostaFiltri)
                impostaFiltri(newFiltro);
        }


    }, [fornitori])

    const cerca = () => {
        if (filtri != undefined) {
            if (!filtri.codArticolo) {
                notify({ position: "center", width: "auto", message: "inserire il codice articolo" }, "error", 4000);
                return;
            }
            if (!filtri.idAnaFornitore) {
                notify({ position: "center", width: "auto", message: "selezionare il fornitore" }, "error", 4000);
                return;
            }
            const param = {
                ean: filtri.codArticolo,
                idAnaFornitore: filtri.idAnaFornitore,
            };
            if (filtri.codArticolo && filtri.idAnaFornitore) {
                RestServices().rest('POST', param, urlProdottoAbbinamento.getFattureProdottoAbbinamentoNonAbbinate).then(result => {
                    if (result) {
                        setElencoFattura(result);
                    } else {
                        notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                    }
                })
            }
        }

    }
    const handleClose = (aggiorna: boolean) => {
        setElencoFattura(undefined);
        setTabellaInstance(undefined)
        props.setIsDialogAbbinaOpen(false);
        props.handleCloseDialogAbbina(aggiorna);

    }

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.nascosto == true)
                e.component.deselectRows(row); return;
        })
    }

    const abbinaProdottoAbbinamentoClick = (e: any) => {


        if (!codCsf) {
            notify({ position: "center", width: "auto", message: "Selezionare un prodotto" }, "error", 4000)
            return
        }
        if (filtri != undefined)
            if (!filtri.idAnaFornitore) {
                notify({ position: "center", width: "auto", message: "Selezionare un fornitore" }, "error", 4000)
                return
            }
        if (filtri != undefined)
            if (!filtri.codArticolo) {
                notify({ position: "center", width: "auto", message: "Inserire il codice articolo" }, "error", 4000)
                return
            }


        var keys = tabellaInstance.instance.getSelectedRowKeys();
        if (keys.length == 0) {
            setMessaggioConferma("Sara' inserita la regola di abbinamento e nessuna fattura sara' abbinata automaticamente. Continuare?");
        }
        else {
            setMessaggioConferma("Sara' inserita la regola di abbinamento ed ed in automatico le fatture selezionate saranno associate al prodotto. Continuare?");
        }
        setDialogIsOpen(true);
    }

    const functionNext = () => {
        setDialogIsOpen(false);
        abbinaProdotto();
    }

    const abbinaProdotto = () => {
        var keys = tabellaInstance.instance.getSelectedRowKeys();
        var lstFat = new Array();
        for (var i = 0; i < keys.length; i++) {
            lstFat.push(keys[i].idFat);
        }
        const param = {
            ean: filtri?.codArticolo,
            codCsf: codCsf,
            idAnaFornitore: filtri?.idAnaFornitore,
            lstFat: lstFat,
            codProd: codProd
        };
        RestServices().rest('POST', param, urlProdottoAbbinamento.abbinaProdotto).then(result => {
            if (result.esito) {
                notify({ position: "center", width: "auto", message: "Operazione effetuata con successo" }, "success", 4000)
                handleClose(true);
            } else {
                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                return;
            }
        })
    }

    const insertProdotto = (codCsf: number) => {
        setCodCsf(codCsf);
    }

    const insertCodProd = (codProd: string) => {
        setCodProd(codProd);
    }

    const insertDescProd = (descProd: string) => {
        setDescProd(descProd);
    }

    const insertCodEan = (codEan: string) => {
        setCodEan(codEan);
    }

    const startRicercaProdInBD = () => {

            setIsVisible(true);
            const parametriCerca = {
                testo: inputCerca,
                lstFar: undefined,
                soloInCommercio: false,
                soloInGiacenza: false
            }
            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    setIsVisible(false);
                    if (result.length === 1) {
                        if (result[0].codCsf) {
                            setCodCsf(result[0].codCsf);
                            setCodProd(result[0].minsan);
                            setDescProd(result[0].descrizione);
                        } else {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                        }
                    } else if (result.length > 1) {
                        setCercaNuovoProd(result);
                        handleDialogAddProdOpen();
                    } else {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                        setCercaNuovoProd([]);
                    }
                })
           
    }

    const propsTabElencoFattura: TabellaCompletaInterface = {
        id: "prodotto_abbinamentno_fatture",
        dataSource: elencoFattura,
        pagination: 10,
        selection: "multiple",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: true,
        enablePagination: true,
    }

    const columnsElencoFattura = CreaColonne([
        { caption: "Id fat", dataField: "idFat", visible: false },
        { caption: "Id far", dataField: "idFar", visible: false },
        { caption: "Numero doc.", dataField: "numeroDocumento" },
        { caption: "Data documento", dataField: "dataDocumento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Fornitore", dataField: "fornitore" },
        { caption: "Imponibile", dataField: "imponibile" },
    ])

    return (
        <>
            {loadPanel}
            <Modal
                show={props.isDialogAbbinaOpen}
                onHide={() => handleClose(false)}
                dialogClassName="dialogElencoFattura"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloElencoFattura">
                        <h3>Abbina prodotto</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="1" className="infoProdottoAbbinamento">
                            <FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" />
                        </Col>
                        <Col sm="11">
                            <p className="testo">
                                Abbinamento del codice articolo inserito al prodotto selezionato rispetto al fornitore scelto. E' obbligatorio indicare il prodotto, il codice articolo ed il fornitore.<br></br>
                                E' possibile ricercare il prodotto tramite la casella di ricerca.
                                Premendo il pulsante cerca sara' mostrato l'elenco delle fatture con il codice articolo imposto.
                                Premendo il pulsante abbina verra' salvata la regola di abbinamento codice articolo - prodotto per il fornitore scelto; inoltre, selezionando le fatture in elenco, <br></br>
                                sara' anche applicato l'abbinamento alle fatture scelte
                            </p>
                        </Col>
                    </Row>
                    <Row id="rigaRicercaProdottoAbbinamento">
                        <Col sm='4' className="prodottoAbbinamento">
                            <span>Ricerca prodotto (codice, EAN, descrizione)</span>
                        </Col>
                        <Col sm='5' className="boxCerca">
                            <BoxTestoCerca placeholder="" id="ricercaProdottiBD" setInputCerca={setInputCerca} startRicerca={startRicercaProdInBD} inputCerca={inputCerca} />
                        </Col>
                    </Row>
                    &nbsp;
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm='2' className="labelCreaNuovoOrdLib">
                            <span>Prodotto: </span>
                        </Col>
                        <Col sm='4'>
                            <BoxFiltro id="descProd" impostaFiltri={impostaFiltri} value={descProd} disabled={true} />
                        </Col>
                        <Col sm='1' className="labelCreaNuovoOrdLib">
                            <span>Ministeriale: </span>
                        </Col>
                        <Col sm='2'>
                            <BoxFiltro id="codProd" impostaFiltri={impostaFiltri} value={codProd} disabled={true} />
                        </Col>
                        <Col sm='1' className="labelCreaNuovoOrdLib">
                            <span>Ean: </span>
                        </Col>
                        <Col sm='2'>
                            <BoxFiltro id="codEan" impostaFiltri={impostaFiltri} value={codEan} disabled={true} />
                        </Col>
                    </Row>

                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Fornitore(Ditta): </span>
                        </Col>
                        <Col sm="4">
                            <Selezione
                                dataSource={fornitori}
                                id="idAnaFornitore"
                                impostaFiltri={impostaFiltri}
                                //value={idAnaFornitore}
                                value={props.partitaIvaFornitore != undefined ? fornitori?.find(item => item.PARTITA_IVA == props.partitaIvaFornitore)?.ID : props.partitaIvaDitta && fornitori?.find(item => item.PARTITA_IVA == props.partitaIvaDitta)?.ID}
                                displayExpr="RAGIONE_SOCIALE"
                                valueExpr="ID"
                                searchEnabled={true}
                                
                            />
                        </Col>
                        <Col sm="1" className="labelCreaNuovoOrdLib">
                            <span>P.Iva: </span>
                        </Col>
                        <Col sm="2">
                            <BoxFiltro id="partitaIva" impostaFiltri={impostaFiltri} value={(filtri && filtri["idAnaFornitore"]) ?  fornitori?.find(item => item.ID == filtri["idAnaFornitore"])?.PARTITA_IVA : partitaIvaFornitore} disabled={true} />
                            {/*<BoxFiltro id="partitaIva" impostaFiltri={impostaFiltri} value={partitaIvaFornitore ? fornitori?.find(item => item.PARTITA_IVA == partitaIvaFornitore)?.PARTITA_IVA : fornitori?.find(item => item.PARTITA_IVA == partitaIvaDitta)?.PARTITA_IVA} disabled={true} />*/}

                        </Col>
                    </Row>
                    

                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm='2' className="labelCreaNuovoOrdLib">
                            <span>Codice articolo</span>
                        </Col>
                        <Col sm='4'>
                            <BoxFiltro placeholder="" id="codArticolo" impostaFiltri={impostaFiltri} value={ props.ean} />
                        </Col>
                        <Col sm="2">
                            <Button id="btnAbbinaProdottoFattura" variant="success" onClick={cerca} className="btnAbbinaCerca" title="Cerca fatture non ancora approvate e con codice articolo inserito">
                                Cerca fatture
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        
                    </Row>
                    <TabellaCompleta propsTabella={propsTabElencoFattura} columns={columnsElencoFattura} setTabellaInstance={setTabellaInstance} selectedRowKeys={selectedRowKeys} onSelectionChanged={onSelectionChanged} />

                </Modal.Body>
                <Modal.Footer>

                    <Button id="btnAbbinaProdottoFattura" variant="success" onClick={abbinaProdottoAbbinamentoClick} >
                        Abbina
                    </Button>

                    <Button id="btnChiudiDialogElencoFattura" variant="danger" onClick={() => handleClose(false)}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

            <DialogConferma
                dialogIsOpen={dialogIsOpen}
                message={messaggioConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />

            {(cercaNuovoProd) &&
                <DialogAggiungiProdOrdine
                isOpen={isDialogAddProdOpen}
                handleClose={handleDialogAddProdClose}
                svuotaDialog={svuotaDialogAddProd}
                cercaNuovoProd={cercaNuovoProd}
                testoRicerca={inputCerca}
                farmSelez={lstFar}
                insertProdotto={insertProdotto}
                insertCodProd={insertCodProd}
                insertDescProd={insertDescProd}
                insertCodEan={insertCodEan}
                />}

        </>
    )
}