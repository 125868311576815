import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import { cambiaFormatoLocalDateTimeToEuropeo } from '../../funzioni/formatoVariabili';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { TestataOrdineInterface, TrasmettiDatiOrdineInterface } from '../../interfaces/ordiniRda';
import { RestServices } from '../../services/restServices';
import { DialogConferma } from '../../widget/Notifications';
import { urlOrdiniRda } from '../costanti';
import { DialogTrasmettiInFarmacia, DialogPagamento, DialogFornitore } from './Dialog';
import { FornitoriCentrInterface, ListaPagamentiInterface } from '../../interfaces/generiche';
import { getDescrFarmFromId } from '../../funzioni/anagrafica';

const TestataOrdineRda = (props: {
    testata: TestataOrdineInterface | undefined,
    farmacie: ListaFarmacieInterface[],
    statoAttuale: number | undefined,
    setStatoAttuale: (stato: number) => void,
    listaPagamenti: ListaPagamentiInterface[] | undefined,
    listaFornitori: FornitoriCentrInterface[] | undefined,
    setIdTipPagamento: (idPagamento: number | undefined) => void,
    autorizzazioni: string[] | undefined
}) => {
    const [descrFarm, setDescrFarm] = useState<string>();
    const [scrittaBtnTestata, setScrittaBtnTestata] = useState("");
    const [btnTestataDisabilita, setBtnTestataDisabilita] = useState(true);
    const [statoSuccessivo, setStatoSuccessivo] = useState<number>();
    const [isOpen, setIsOpen] = useState(false);
    const [isConfermaValutazioneOpen, setIsConfermaValutazioneOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [descrStato, setDescrStato] = useState("");
    const [dialogTrasmettiOpen, setDialogTrasmettiOpen] = useState(false);
    const [datiOrdine, setDatiOrdine] = useState<TrasmettiDatiOrdineInterface>();
    const [dialogPagamento, setDialogPagamento] = useState(false);
    const [tipoPagamento, setTipoPagamento] = useState("");
    const [idTipoPagamento, setIdTipoPagamento] = useState<number|undefined>();
    const [dialogFornitore, setDialogFornitore] = useState(false);
    const [descrFornitore, setDescrFornitore] = useState("");

    useEffect(() => {
        if (props.testata && props.testata.descTipPagamento) setTipoPagamento(props.testata.descTipPagamento);
    }, [props.testata])

    useEffect(() => {
        props.setIdTipPagamento(idTipoPagamento);
    }, [idTipoPagamento])
    
    useEffect(() => {
        if (props.testata && props.statoAttuale) {
            setDescrStato(props.testata.descStato);
            //if (props.testata.lstAutorizzazione.includes("A") && props.statoAttuale !== 6)
            if (props.autorizzazioni && (props.autorizzazioni.includes("A")))
                setBtnTestataDisabilita(false);
                //se autorizzazione "Modifica" e stato attuale in valutazione => pulsante "Approva" abilitato
            else if (props.autorizzazioni && props.autorizzazioni.includes("M") && props.autorizzazioni && props.autorizzazioni.includes("P") && props.statoAttuale == 2)
                setBtnTestataDisabilita(false);
            else if (props.autorizzazioni && props.autorizzazioni.includes("O") && props.autorizzazioni && props.autorizzazioni.includes("M") && props.statoAttuale == 1 )
                setBtnTestataDisabilita(false);
            else if (props.autorizzazioni && props.autorizzazioni.includes("O") && props.autorizzazioni && props.autorizzazioni.includes("M") && props.statoAttuale == 2)
                setBtnTestataDisabilita(true);
            setDatiOrdine({
                idFornitore: props.testata.idFornitore,
                idOrdine: props.testata.id,
                descrFornitore: props.testata.ragioneSociale,
                idFarmacia: props.testata.idFar
            })
            setDescrFornitore(props.testata.ragioneSociale);
        }
    }, [props.testata, props.statoAttuale, props.autorizzazioni])

    useEffect(() => {
        if (props.statoAttuale) {
            switch (props.statoAttuale) {
                case 1:
                    setScrittaBtnTestata("In Valutazione");
                    setStatoSuccessivo(2);
                    setMessage("In seguito a questa operazione l'ordine risulterà in valutazione. Si vuole procedere ?")
                    break;
                case 2:
                    setScrittaBtnTestata("Approva");
                    setStatoSuccessivo(3);
                    setDescrStato("RDA in valutazione");
                    setMessage("In seguito a questa operazione non sarà più possibile modificare l'ordine. Si vuole procedere con l'approvazione dell'ordine?")
                    break;
                case 3:
                    if (props.autorizzazioni && !(props.autorizzazioni.includes("A")))
                        setBtnTestataDisabilita(true);
                    setScrittaBtnTestata("Trasmetti");
                    setStatoSuccessivo(6);
                    setMessage("In seguito a questa operazione l'ordine risulterà trasmesso. Si vuole procedere con la trasmissione?")
                    break;
                case 6:
                    setScrittaBtnTestata("Trasmetti");
                    setStatoSuccessivo(6);
            }
        }
    }, [props.statoAttuale])

    useEffect(() => {
        if (props.farmacie.length > 0 && props.testata) {
            const descrizione = getDescrFarmFromId(props.farmacie, props.testata.idFar);
            setDescrFarm(descrizione);
        }
    }, [props.farmacie, props.testata])

    const confermaTrasmetti = () => {
        if (props.statoAttuale !== 3 && props.statoAttuale !== 6) {
            setIsOpen(true);
        } else if (props.statoAttuale === 3 || props.statoAttuale === 6) {
            setDialogTrasmettiOpen(true);
        }
    }

    const functionNext = () => {
        const param = {
            idRdaOrdine: props.testata?.id,
            idStato: statoSuccessivo
        }
        RestServices().rest('POST', param, urlOrdiniRda.setStatoOrdine).then(result => {
            if (result === true) {
                setIsOpen(false);
                if (props.statoAttuale === 2) {
                    props.setStatoAttuale(3);
                    setDescrStato("RDA valutata ed accettata");
                    notify({ position: "center", width: "auto", message: "L'ordine è stato confermato con successo" }, 'success', 3000);

                } else if (props.statoAttuale === 3) {
                    props.setStatoAttuale(6);
                    setDescrStato("RDA accettata e trasmessa al fornitore")
                    notify({ position: "center", width: "auto", message: "L'ordine è stato trasmesso con successo" }, 'success', 3000);

                }
                else if (props.statoAttuale === 1) {
                    props.setStatoAttuale(2);
                    setDescrStato("RDA in valutazione");
                    notify({ position: "center", width: "auto", message: "L'ordine è ora in valutazione" }, 'success', 3000);
                }

            } else if (result.codice != undefined && result.message) 
            {
                notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
            }
            else {
                if (props.statoAttuale === 2) {
                    notify({ position: "center", width: "auto", message: "Non è stato possibile confermare l'ordine. Ci scusiamo per il disagio" }, 'error', 3000);
                } else if (props.statoAttuale === 3) {
                    notify({ position: "center", width: "auto", message: "Non è stato possibile trasmettere l'ordine. Ci scusiamo per il disagio" }, 'error', 3000);
                }
            }
        })
    }

    const functionNextTrasmetti = (result: boolean) => {
        //nel caso di trasmissione verifico l'esito della trasmissione, se positivo cambio gli stato
        if (result) {
            props.setStatoAttuale(6);
            setDescrStato("RDA accettata e trasmessa al fornitore");
        }
    }
    const handleCloseConferma = () => setIsOpen(false);
    const handleCloseConfermaValutazione = () => setIsConfermaValutazioneOpen(false);

    const confermaValutazione = () => setIsConfermaValutazioneOpen(true);

    const tornaAValutazione = () => {
        const param = {
            idRdaOrdine: props.testata?.id,
            idStato: 2
        }
        RestServices().rest('POST', param, urlOrdiniRda.setStatoOrdine).then(result => {
            if (result === true) {
                setIsConfermaValutazioneOpen(false);
                props.setStatoAttuale(2);
                setDescrStato("RDA in valutazione");
                notify({ position: "center", width: "auto", message: "L'ordine è stato riportato con successo allo stato 'In Valutazione'" }, 'success', 3000);
            } else {
                notify({ position: "center", width: "auto", message: "Non è stato possibile riportare l'ordine allo stato 'In Valutazione'. Ci scusiamo per il disagio" }, 'error', 3000);
            }
        })
    }

    return (
        <>
            <Col sm='5'>
                <span>Farmacia:&nbsp;&nbsp;</span><span className="valoreTestata">{descrFarm}</span>
            </Col>
            <Col sm='2'>
                <span>Numero:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.codiceOrdine}</span>
            </Col>
            <Col sm='2'>
                <span>Data:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata && cambiaFormatoLocalDateTimeToEuropeo(props.testata.dataEmissione)}</span>
            </Col>
            <Col sm="3" id="rigaBtn">
                <Button variant="danger" id="btnChiudiSchedaOrdine" onClick={() => window.close()}>Chiudi</Button>
                {(scrittaBtnTestata ) && <Button variant="success" id="btnTrasmettiOrdine" disabled={btnTestataDisabilita} onClick={confermaTrasmetti}>{scrittaBtnTestata}</Button>}
            </Col>
            <Col sm='5'>
                <span>Fornitore:&nbsp;&nbsp;</span><span className="valoreTestata">{descrFornitore}</span>
                <span id="spanCambiaFornitore"><Button variant="primary" id="btnCambiaFornitore" onClick={() => setDialogFornitore(true)}>Cambia Fornitore</Button></span>
            </Col>
            <Col sm='2'>
                <span>Operatore:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.operatore}</span>
            </Col>
            <Col sm='3'>
                <span>Stato:&nbsp;&nbsp;</span><span className="valoreTestata">{descrStato}</span>
            </Col>
            <Col sm='2' id="salvaAuto">
                <span>Salvataggio Automatico</span>
            </Col>
            <Col sm="5" className="aggiungiRiga" id="ultimaRigaTestataRda">
                <span>Tipo Pagamento:&nbsp;&nbsp;</span><span className="valoreTestata">{tipoPagamento}</span>
                <span id="spanPagamentoRda"><Button variant="primary" id="btnCambiaTipoPagamentoRda" onClick={() => setDialogPagamento(true)}>Cambia Pagamento</Button></span>
            </Col>
            <Col sm="2"  >
                <span>Note:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.note}</span>
            </Col>
            <Col sm='2'>
                <span>Data prevista consegna:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata && cambiaFormatoLocalDateTimeToEuropeo(props.testata.dataConsegna)}</span>
            </Col>
            <Col sm="2" className="aggiungiRiga" id="tornaValutazioneRda">
                {(props.testata && props.autorizzazioni &&  props.autorizzazioni.includes("A") && props.statoAttuale === 3)
                    &&
                    <Button variant="danger" id="btnValutazioneOrdine" onClick={confermaValutazione}>Stato In Valutazione</Button>
                }
            </Col>
            <DialogConferma
                dialogIsOpen={isOpen}
                message={message}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />
            <DialogConferma
                dialogIsOpen={isConfermaValutazioneOpen}
                message="In seguito a questa operazione l'ordine tornerà allo stato 'In Valutazione'. Si vuole procedere?"
                functionNext={tornaAValutazione}
                handleCloseConferma={handleCloseConfermaValutazione}
            />
            <DialogTrasmettiInFarmacia
                setDialogTrasmettiOpen={setDialogTrasmettiOpen}
                dialogTrasmettiOpen={dialogTrasmettiOpen}
                datiOrdine={datiOrdine}
                functionNext={functionNextTrasmetti}
            />
            <DialogPagamento
                setDialogPagamento={setDialogPagamento}
                dialogPagamento={dialogPagamento}
                tipoPagamento={props.testata?.idTipPagamento}
                setTipoPagamento={setTipoPagamento}
                setIdTipoPagamento={ setIdTipoPagamento}
                listaPagamenti={props.listaPagamenti}
                idOrdine={props.testata?.id}
            />
            <DialogFornitore
                listaFornitori={props.listaFornitori}
                idFarm={props.testata?.idFar}
                dialogFornitore={dialogFornitore}
                idFornitore={props.testata?.idFornitore}
                idOrdine={props.testata?.id}
                setDescrFornitore={setDescrFornitore}
                setDialogFornitore={setDialogFornitore}
            />
        </>
    )
}

export default TestataOrdineRda;