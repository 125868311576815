import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { trasform_for_cards_charts_01, trasform_no_marge } from "../util/trasformation";
import { testApi } from "../util/testRisp";
import { AplicaValoreTO } from "../../../funzioni/operazioniSuTabelle";
import { MenuAnalisiDirezionale } from '../components/menu_Analisi_Direzionale';
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from "../../costanti";
import { CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Connector, Title, Export, Font, Format, Label, HoverStyle, Size, SmallValuesGrouping, Tooltip } from "devextreme-react/pie-chart";
import Chart, { Annotation, CommonSeriesSettings, Legend, Series } from 'devextreme-react/chart';
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import ".././style.css";
import { log } from "console";
import { Center } from "devextreme-react/map";

const dashboard22 = { dashboardID: 22 };

export interface boxCard {
    testoPop?: string;
}

export interface boxsecond {
    testoPop?: string;
}

interface cardV {
    testo: string;
    subTesto: string;
    keyValue: string | undefined;
    typeShowValue?: typeShow;
    testoPop?: string;
    second?: boolean;
}

const boxCard: cardC[] = [
    {
        testo: "Numero ricette",
        valore: "NUMERO_RICETTE",
        testoPop: `Numero ricette:
                   Rappresenta il numero di ricette lavorate dalla farmacia nel periodo selezionato.`
    },
    {
        testo: "Numero pezzi",
        valore: "NUMERO_PEZZI",
        testoPop: `Numero pezzi:
                   Rappresenta il numero di pezzi erogati dietro ricetta dalla farmacia nel periodo selezionato.`,
    },
];



const box2: cardC[] = [
    {
        testo: "Lordo",
        valore: "LORDO",
        typeShowValue: typeShow.euro,
        testoPop: "Lordo: Rappresenta il valore lordo delle ricette lavorate dalla farmacia nel periodo selezionato.",

    },
    {
        testo: "Sconto regione",
        typeShowValue: typeShow.euro,
        valore: "SCONTI_REGIONE",
        testoPop: `Sconto regione: Rappresenta lo sconto applicato dalla regione alle ricette lavorate dalla farmacia nel periodo selezionato, 
             Gli sconti considerati sono: Sconto DL. 182,
             Sconto Industria,
             Sconto PayBack, Sconto SSN.`
    },
    {
        testo: "Fatturato",
        typeShowValue: typeShow.euro,
        valore: "FATTURATO",
        testoPop: `Fatturato: Rappresenta il fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato. Il fatturato e' calcolato come differenza tra il valore lordo e lo sconto regione.
             Gli sconti considerati dallo sconto regione sono:
             Sconto DL. 182
             Sconto Industria
             Sconto PayBack
             Sconto SSN.`
    },
    {
        testo: "Ticket",
        typeShowValue: typeShow.euro,
        valore: "TICKET",
        testoPop: `Ticket: Rappresenta il ticket incassato anticipatamente dalle ricette lavorate dalla farmacia nel periodo selezionato.`,

    },
    {
        testo: "Netto a liquidare",
        typeShowValue: typeShow.euro,
        valore: "NETTO_A_LIQUIDARE",
        testoPop: `Netto a liquidare:
                   Rappresenta il netto a liquidare generato dalle ricette lavorate dalla farmacia nel periodo selezionato.
                   Il netto a liquidare e' calcolato come differenza tra il fatturato e il ticket, rappresenta quanto l'ASL liquidera alla farmacia.`,

    },

];

const box3: cardC[] = [
    {
        testo: "Medici prescrittori distinti",
        subTesto: "% Ricette con med registrato",
        valore: "MEDICI_PRESCRITTORI_DISTINTI",
        subValore: "PERCENTUALE_RICETTE_MEDICO",
        typeShowSubValue: typeShow.percento,
        testoPop: `Medici prescrittori distinti:
                   Rappresenta il numero di medici prescrittori distinti delle ricette lavorate dalla farmacia, nel periodo selezionato.
                   La % ricette con Med registrato indica la percentuale del numero di ricette su cui il dato relativo al codice fiscale del medico consente di determinare l'univocita' del medico stesso.
                   Nel caso in cui questa percentuale sia inferiore al 100% allora alcune ricette non hanno un codice fiscale utile per risalire al medico prescrittore.`
    },
    {
        testo: "Numero medio giorni tra prescrizione ed erogazione ricetta",
        valore: "GIORNI_RICETTA_MEDIO",
        subTesto: "Numero giorni lavorati anno precedente",
        testoPop: `Numero medio giorni tra prescrizione ed erogazione ricetta:`,
    },
];

const boxGlavorati: cardC[] = [
    {
        testo: "Numero medio pezzi per ricetta",
        valore: "NUMERO_PEZZI_MEDIO_RICETTA",
        testoPop: `Numero medio pezzi per ricetta:
                   Rappresenta il numero medio di pezzi erogati per ricetta dalla farmacia nel periodo selezionato.`
    },
]



//da spostare
const convertTime = (data: Date) => {
    const anno = data.getFullYear();
    const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perch� i mesi sono 0-based
    const giorno = data.getDate().toString().padStart(2, "0");
    const ore = data.getHours().toString().padStart(2, "0");
    const minuti = data.getMinutes().toString().padStart(2, "0");
    const secondi = data.getSeconds().toString().padStart(2, "0");

    const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

    return dataFormattata;
};

const AnalisiMedici = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [newMedicoList, setNewMedicoList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCard);
    const [fila2cards, setFila2cards] = useState<cardC[]>(box2);
    const [fila3cards, setFila3cards] = useState<cardC[]>(box3);
    const [filaGiorniLav, setFilaGiorniLav] = useState<cardC[]>(boxGlavorati);

    const [valori1W, setValori1W] = useState<any>([]);
    const [valori2W, setValori2W] = useState<any>([]);
    const [valori3W, setValori3W] = useState<any>([]);


    const [tipoRaggruppamento, setTipoRaggruppamento] = useState<any>([]);
    const [sesso, setSesso] = useState<any>([]);
    const [eta, setEta] = useState<any>([]);
    const [flagGenerico, setFlagGenerico] = useState<any>([]);


    const [pie, setPie] = useState<any[]>([]);
    const [secondpie, setSecondPie] = useState<any[]>([]);
    const [settori, setSettori] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePopOne, setVisiblePopOne] = useState(false);

    const [totalValue, setTotalValue] = useState(0);
    const [totalValueMargine, setTotalValueMargine] = useState(0);
    const [totalValueMargine2, setTotalValueMargine2] = useState(0);
    const [chartData, setChartData] = useState<any>([]);
    const [chartData3, setChartData3] = useState<any>([]);
    const chartRef = useRef<any>(null);
    // ??? const [chartKey, setChartKey] = useState(false);

    const [chartDataRSPPie, setChartDataRSPPie] = useState<any>([]);
    const [chartDataFSPPie, setChartDataFSPPie] = useState<any>([]);

    const [chartDataMargine, setChartDataMargine] = useState<any>([]);
    // ??const chartRefMargine = useRef<any>(null);
    // ??const [chartKeyMargine, setChartKeyMargine] = useState(false);

    const [chartDataNumeroVend, setChartDataNumeroVend] = useState<any>([]);
    // ??const chartRefNumeroVend = useRef<any>(null);
    // ??const [chartKeyNumeroVend, setChartKeyNumeroVend] = useState(false);

    const [chartDataPezzi, setChartDataPezzi] = useState<any>([]);
    //??const chartRefPezzi = useRef<any>(null);
    //?? const [chartKeyPezzi, setChartKeyPezzi] = useState(false);

    const chartRefDitta = useRef<any>(null);
    //??const [chartKeyDitta, setChartKeyDitta] = useState(false);

    const [visiblePopCHT, setVisiblePopCHT] = useState(false);
    const [visiblePopCHT2, setVisiblePopCHTtwo] = useState(false);
    const [visiblePopCHT3, setVisiblePopCHTthree] = useState(false);

    const [visiblePopPie, setVisiblePopPie] = useState(false);
    const [visiblePopPie2, setVisiblePopPietwo] = useState(false);
    const [visiblePopPie3, setVisiblePopPiethree] = useState(false);
    const [visiblePopPie4, setVisiblePopPiefour] = useState(false);
    const [visiblePopPie5, setVisiblePopPiefive] = useState(false);


    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale � mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const mmonthNames = [
        "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
        "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
    ];

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        console.log(params.sesso);

        const parameters = {
            dal: params.periodo.dal.toLocaleString().substring(0, 6),
            al: params.periodo.al.toLocaleString().substring(6, 0),
            tipoRaggruppamento: params.tipoRaggruppamento,
            sesso: params.sesso,
            eta: params.eta,
            flagGenerico: params.flagGenerico
        };

        console.log(parameters);
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall22 = {
                ...dashboard22,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall22, urlReport.getQueryDashboardAllData)
                .then((result) => {

                    setIsVisible(false);
                    if (result) {

                        const valori1W = (result["92"]);
                        setValori1W(valori1W);

                        const valori2W = (result["96"]);
                        setValori2W(valori2W);

                        setChartDataRSPPie(result["71"]);

                        const valori3W = (result["95"]);
                        setValori3W(valori3W);

                        setChartDataFSPPie(result["72"]);

                        setChartData(result["93"].reverse());
                        setChartDataMargine(result["94"]);

                        let datiPrimaParteCards = trasform_no_marge(
                            result["68"]["0"],
                            boxCard
                        );


                        let datiSecondaParteCards = trasform_no_marge(
                            result["68"]["0"],
                            box2
                        );

                        let dati3Cards = trasform_no_marge(
                            result["68"]["0"],
                            box3
                        );

                        let dati4Cards = trasform_no_marge(
                            result["68"]["0"],
                            boxGlavorati
                        );


                        setFila1cards(datiPrimaParteCards);
                        setFila2cards(datiSecondaParteCards);
                        setFila3cards(dati3Cards);
                        setFilaGiorniLav(dati4Cards);

                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);

                        //Total Value 1
                        const total = result["93"]
                            .reverse()
                            .reduce(
                                (acc: number, item: any) => acc + item.NUMERO_RICETTE,
                                0
                            );
                        const formattedTotal = total.toLocaleString("it-IT", {
                            minimumFractionDigit: 0,
                        });

                        setTotalValue(formattedTotal);

                        //Total Value 2
                        const total2 = result["94"]
                            .reverse()
                            .reduce((acc: number, item: any) => acc + item.NUMERO_RICETTE, 0);
                        const formattedTotaLMargine = total2.toLocaleString("it-IT", {
                            minimumFractionsDigits: 0,
                        });

                        setTotalValueMargine(formattedTotaLMargine);

                        //Total Value 3
                        const total3 = result["93"]
                            .reverse()
                            .reduce(
                                (acc: number, item: any) => acc + item.NUMERO_RICETTE,
                                0
                            );
                        const totalValueMargine2 = total3.toLocaleString("it-IT", {
                            minimumFractionDigit: 0,
                        });

                        setTotalValueMargine2(totalValueMargine2);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            const inizioAnno = new Date();

            inizioAnno.setMonth(0);
            inizioAnno.setDate(1);

        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };

    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };

    let messOne = [`Distribuzione ricette per Sesso paziente:
                    Rappresenta la distribuzione per Sesso del numero di ricette lavorate dalla farmacia nel periodo selezionato.`];

    let messTwo = [`Distribuzione ricette per Fasce d'et� paziente:
                    Rappresenta la distribuzione per Fasce d'et� del numero di ricette lavorate dalla farmacia nel periodo selezionato.`];



    const popVisibleCHT = (test: string) => {
        setVisiblePopCHT(true);
    }

    const popVisibleCHT2 = (test: string) => {
        setVisiblePopCHTtwo(true);
    }

    const popVisibleCHT3 = (test: string) => {
        setVisiblePopCHTthree(true);
    }

    let CHTOne = [`Distribuzione ricette per data erogazione:
                   Mostra la distribuzione giornaliera delle ricette lavorate/erogate dalla farmacia nel periodo selezionato.`]

    let CHTtwo = [`Distribuzione ricette per data prescrizione:
                   Mostra la distribuzione in base alla data di prescrizione medica delle ricette lavorate/erogate dalla farmacia nel periodo selezionato.`]

    let CHTthree = [`Distribuzione ricette per data erogazione suddivisa per data prescrizione ricetta:
                     Le colonne mostrano la distribuzione giornaliera delle ricette lavorate/erogate dalla farmacia nel periodo selezionato.
                     La linea, con riferimento sull'asse destro, indica la percentuale di ricette lavorate con una differenza media tra giorni di prescrizione ed erogazione inferiore a 2.`]

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();


    }, [auth]);

    const filters = () => {
        if (auth) {

            const nondefinito = { id: 1, descrizione: 'Non Definito' }
            const ssn = { id: 2, descrizione: 'SSN' }
            const int = { id: 3, descrizione: `INT` }
            const dpc = { id: 4, descrizione: `DPC` }
            const intd = { id: 5, descrizione: `INTD` }
            const irc = { id: 6, descrizione: `IRC` }
            const cel = { id: 7, descrizione: `CEL` }
            const die = { id: 8, descrizione: `DIE` }
            const pro = { id: 9, descrizione: `PRO` }
            const care = { id: 10, descrizione: `CARE` }

            const tipoRaggruppamento = [nondefinito, ssn, int, dpc, intd, irc, cel, die, pro, care];
            setTipoRaggruppamento(tipoRaggruppamento);

            const male = { id: `M`, descrizione: `M` }
            const female = { id: `F`, descrizione: `F` }

            const sesso = [male, female]
            setSesso(sesso);

            const a = { id: `A`, codice: `A`, descrizione: `Neonati, 0 - 3 anni` }
            const b = { id: `B`, codice: `B`, descrizione: `Bambini, 3 - 11 anni` }
            const c = { id: `C`, codice: `C`, descrizione: `Adolescenti, 11 - 17 anni` }
            const d = { id: `D`, codice: `D`, descrizione: `Giovani adulti, 18 - 34 anni` }
            const e = { id: `E`, codice: `E`, descrizione: `Adulti, 35 - 44 anni` }
            const f = { id: `F`, codice: `F`, descrizione: `Adulti maturi, 45 - 59 anni` }
            const g = { id: `G`, codice: `G`, descrizione: `Giovani anziani, 60 - 74 anni` }
            const h = { id: `H`, codice: `H`, descrizione: `Anziani, 74 - 84 anni` }
            const i = { id: `I`, codice: `I`, descrizione: `Grandi anziani, 85+ anni` }
            const l = { id: `L`, codice: `L`, descrizione: `Eta non disponibile` }

            const eta = [a, b, c, d, e, f, g, h, i, l]
            setEta(eta)

            const fb = { id: 0, descrizione: "Ricetta con soli farmaci branded" }
            const fe = { id: 1, descrizione: "Ricetta con soli farmaci equivalenti" }

            const flagGenerico = [fb, fe]
            setFlagGenerico(flagGenerico)

            console.log();
        }
    };

    useEffect(() => {
        filters();


    }, [auth]);

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabellaMediciSmart");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabellaMediciSmart.csv"
                );
            });
        });
    };

    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "eta",
            label: "Fascia d'eta del paziente",
            noId: true,
            displayExpr: "descrizione",
            dataSource: eta,
            idValueGrid: "eta" as any,
        },
        {
            type: "selectWithLevels",
            id: "sesso",
            label: "Sesso del paziente",
            displayExpr: "descrizione",
            noId: true,
            dataSource: sesso,
            idValueGrid: "" as any,
        },
        {
            type: "selectWithLevels",
            id: "tipoRaggruppamento",
            label: "Tipo raggruppamento",
            noId: true,
            displayExpr: "descrizione",
            dataSource: tipoRaggruppamento,
            idValueGrid: "" as any,
        },
    ];

    const Medico = [
        { caption: "Nominativo medico", dataField: "NOMINATIVO_MEDICO", width: "auto", fixed: true },
        { caption: "Numero ricette", dataField: "NUMERO_RICETTE", sortOrder: "desc", width: "auto" },
        { caption: "% Numero ricette", dataField: "NUMERO_RICETTE_PERCENTUALE", wdith: "auto", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")},
        { caption: "Numero pezzi", dataField: "NUMERO_PEZZI", width: 90},
        { caption: "Numero medio pezzi per ricetta", dataField: "NUMERO_PEZZI_MEDIO_RICETTA", width: "auto", customizeText: (cellInfo: any) => cellInfo?.value?.toFixed(2), },
        { caption: "Lordo", dataField: "LORDO", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto regione", dataField: "SCONTI_REGIONE", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Fatturato", dataField: "FATTURATO", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "% Fatturato", dataField: "FATTURATO_PERCENTUALE", width: "auto", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d") },
        { caption: "Ticket", dataField: "TICKET", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Netto a liquidare", dataField: "NETTO_A_LIQUIDARE", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Fatturato medio per ricette", dataField: "FATTURATO_MEDIO_RICETTA", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Numero medio giorni tra prescrizione ed erogazione ricetta", dataField: "GIORNI_RICETTA_MEDIO", width: "auto", customizeText: (cellInfo: any) => cellInfo?.value?.toFixed(2) }
    ];

    const Paziente = [
        { caption: "Medico", dataField: "NOMINATIVO_MEDICO", width: 100 },
        { caption: "Sesso", dataField: "SESSO", width: "auto" },
        { caption: "Numero ricette", dataField: "NUMERO_RICETTE", sortOrder: "desc", width: "auto" },
        { caption: "% Numero ricette", dataField: "NUMERO_RICETTE_PERCENTUALE", width: "auto", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d")},
    ];

    const Fasce_d_eta = [
        { caption: "Medico", dataField: "NOMINATIVO_MEDICO", width: 100 },
        { caption: "Fasce d' eta", dataField: "ETA_RANGE", width: "auto" },
        { caption: "Numero ricette", dataField: "NUMERO_RICETTE", sortOrder: "desc", width: "auto" },
        { caption: "% Numero ricette", dataField: "NUMERO_RICETTE_PERCENTUALE", width: "auto", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d") },
    ];

    function formatLabel(arg: { argumentText: any; valueText: any }) {
        return `${arg.argumentText}: ${arg.valueText}%`;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    return (
        <section style={{ display: "flex", justifyContent: "space-between", height: "115vh" }}>
            {loadPanel}
            {loadinCambio ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                </div>
            ) : (
                <section
                        id="scrollerelement"
                        style={{ width: "100%", overflow: "scroll", height: "117vh" }}
                >
                    <div
                        style={{
                            fontSize: "15px",
                            alignItems: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "rgb(80, 84, 92)",
                        }}
                    >
                        <h2>BusinessLab Smart - Analisi Medici</h2>
                    </div>

                    <div
                        style={{
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                    >



                    </div>

                    <Popup
                        visible={visiblePop}
                        showCloseButton={true}
                        //onHiding={() => doSomething()}
                        width={"40%"}
                        height={"60%"}
                        showTitle={true}
                        title="Dettagli widget"
                    >
                        <div>
                            <p>{testoPop}</p>
                        </div>
                    </Popup>

                   <div>
                      <div className="grid-ibrid">
                            <div style={{ width: 'calc(166.9%)', paddingBottom: 5 }} className="grid-container-one">

                            {fila1cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                          </div>
                        </div>

                        <div style={{ fontSize: 16, width: "100%", position: 'relative', paddingBottom: 5 }} className="grid-first">
                            {fila2cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>

                            <div style={{ width: 'calc(100%)', justifyContent: "space-between", paddingBottom: 15 }} className="grid-container-3-cate">
                                    {fila3cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}

                                        {filaGiorniLav.map((el, i) => {
                                            return (
                                                <CardCart
                                                    testo={el.testo}
                                                    subTesto={el.subTesto}
                                                    key={i}
                                                    valore={el.valore}
                                                    subValore={el.subValore}
                                                    starter={el.starter}
                                                    typeShowValue={el.typeShowValue}
                                                    typeShowSubValue={el.typeShowSubValue}
                                                    testoPop={el.testoPop}
                                                />
                                            );
                                        })}
                                </div>
                          
                     <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, marginBottom: '-3px', marginLeft: '5px' }}>Distribuzione ricette per medico</h6>
                            <div style={{ marginTop: "5px", marginBottom: 40 }} >
                            <NewTabella
                                height={"92vh"}
                                idTabella="AnalisiMedici"
                                sync
                                colonne={Medico}
                                dataSource={valori1W}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiMedico",
                                    formats: { exportFormats },
                                }}
                                activeSaver
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem
                                            column="LORDO"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                        <TotalItem
                                            column="FATTURATO"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                        <TotalItem
                                            column="TICKET"
                                            summaryType="sum" 
                                            valueFormat={{ style:"currency", precision: 2, currency: "EUR" }} />

                                        <TotalItem
                                            column="NETTO_A_LIQUIDARE"
                                            summaryType="sum" 
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                        <TotalItem
                                            column="FATTURATO_MEDIO_RICETTA"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                        <TotalItem
                                            column="SCONTI_REGIONE"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                        <TotalItem
                                            column="NUMERO_RICETTE"
                                            summaryType="sum" />
                                        
                                        <TotalItem
                                            column="NUMERO_PEZZI"
                                            summaryType="sum" />
                                    </Summary>
                                }
                            />
                        </div>
                    </div>


                    <div
                        style={{
                            display: "flex",
                            backgroundColor: "white",
                            marginTop: 5
                        }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginLeft: -20 }}>
                            <div style={{ width: '47%' }}>
                                <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: -30, marginLeft: -10 }}>Distribuzione ricette per medico per sesso paziente</h6>
                                <NewTabella
                                        height={"70vh"}
                                        idTabella="AnalisiMedici"
                                        sync
                                        colonne={Paziente}
                                        dataSource={valori2W}
                                        filterRow
                                        searchPanel={true}
                                        exportT={{
                                            enabled: true,
                                            fileName: "AnalisiSessoPaziente",
                                            formats: { exportFormats },
                                        }}
                                        activeSaver
                                        columnC
                                        onExporting={onExporting}
                                        summary={
                                        <Summary>
                                            <TotalItem
                                            column="NUMERO_RICETTE"
                                            summaryType="sum"
                                            valueFormat={{ precision: 2 }} />
                                    </Summary>
                                }
                            />
                        </div>


                            <div style={{ width: "45%", marginTop: 50, }}>
                                    <PieChart
                                        id="pie"
                                        dataSource={chartDataRSPPie}
                                        palette="Green Mist"
                                        paletteExtensionMode="alternate"
                                        type="doughnut"

                                    >
                                        <Series
                                            argumentField="SESSO"
                                            valueField="NUMERO_RICETTE"
                                        >
                                            <HoverStyle color="rgb(255, 0, 0)" />
                                        </Series>

                                        
                                        {<Tooltip
                                            enabled={true}
                                            customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                                const dataItem = chartDataRSPPie.find((item: any) => item.SESSO === argument);
                                                const dataSourceValue = dataItem ? dataItem.NUMERO_RICETTE : 0;
                                                const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                                return {
                                                    html: `<p>Sesso: ${argument}<p><p>Numero ricette: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                                };
                                            }}
                                        />}
                                        <Title
                                            text="Distribuzione ricette per sesso paziente"
                                        >
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Title>

                                        <Export fileName="Distribuzione ricette per sesso paziente" enabled={true} />
                                    </PieChart>
                                </div>

                                <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                                    <button
                                        className="ibutton-lite-two"
                                        style={{ border: "none" }}
                                        onClick={() => popVisible1("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                            </div>

                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 40,
                            marginBottom: 5
                        }}
                    >

                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px', marginLeft: -20 }}>
                            <div style={{ width: '47%' }}>
                                <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: -30, marginLeft: -10 }}>Distribuzione ricette per medico per fasce d' eta</h6>
                                <NewTabella
                                    height={"70vh"}
                                    idTabella="AnalisiMedici"
                                    sync
                                    colonne={Fasce_d_eta}
                                    dataSource={valori3W}
                                    rowAlternation={true}
                                    filterRow
                                    searchPanel={true}
                                    exportT={{
                                        enabled: true,
                                        fileName: "AnalisiFasced'eta",
                                        formats: { exportFormats },
                                    }}
                                    activeSaver={true}
                                    columnC={true}
                                    wordWrapEnabled={true}
                                        onExporting={onExporting}
                                    summary={
                                        <Summary>
                                            <TotalItem
                                            column="NUMERO_RICETTE"
                                            summaryType="sum"
                                            valueFormat={{ precision: 2 }} />
                                    </Summary>
                                }
                            />
                        </div>


                            <div style={{ width: "45%", marginTop: 50 }}>
                                <PieChart
                                    id="pie"
                                    dataSource={chartDataFSPPie}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                >
                                    <Series
                                        argumentField="ETA_RANGE"
                                        valueField="FATTURATO"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>

                                    {<Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                            const dataItem = chartDataFSPPie.find((item: any) => item.ETA_RANGE === argument);
                                            const dataSourceValue = dataItem ? dataItem.NUMERO_RICETTE : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT', {}).format(dataSourceValue);

                                            return {
                                                html: `<p>Eta: ${argument}<p><p>Numero ricette: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                            };
                                        }}
                                    />}
                                    <Title
                                        text="Distribuzione ricette per fasce d'eta"
                                    >
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                        <Export fileName="Distribuzione ricette per fasce d'eta" enabled={true} />
                                </PieChart>
                            </div>

                            <div style={{ display: "flex", marginTop: "30px", width: "3%" }}>
                                <button
                                    className="ibutton-lite-two"
                                    style={{ border: "none" }}
                                    onClick={() => popVisible2("")}
                                >
                                    <FontAwesomeIcon
                                        icon={faInfo}
                                        style={{ fontSize: "11px", color: "black" }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>

                        <div>
                            <div style={{ display: "flex", backgroundColor: 'white', paddingLeft: 10, marginLeft: -10, }}>
                                <div style={{ width: "80%", margin: "10px" }}>
                                    <Chart dataSource={chartData} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="DATA_EROGAZIONE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                        >
                                            <Label visible={true}>
                                                <Format precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="NUMERO_RICETTE"
                                            type="bar"
                                            name="Numero ricette"
                                            color="#1cd4ec"
                                        />

                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                );

                                                return {
                                                    html: `<p> Data erogazione: ${argument}</p>
                                                           <p>Numero ricette: ${formattedValue}</p>`,
                                                };
                                            }}
                                        />
                                        <Title text="Distribuzione ricette per data erogazione ricette in farmacia">
                                            <Font color="#5b6372" size="20" weight="400" />
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="Distribuzione ricette per data erogazione ricette in farmacia" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisibleCHT("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>

                                {chartDataMargine.length > 0 && (
                                    <div
                                        style={{
                                            width: "30%",
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <h2
                                            style={{
                                                color: "#5b6372",
                                                fontFamily: "Arial, sans-serif",
                                            }}
                                        >
                                            Totale:
                                        </h2>
                                        <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                                            {totalValue}
                                        </h2>
                                    </div>
                                )}
                            </div>
                        </div>

                    <div>
                        <div style={{ display: "flex", backgroundColor: 'white', marginBottom: 15 }}>
                                <div style={{ width: "80%", margin: "10px", paddingBottom: 30 }}>
                                    <Chart dataSource={chartDataMargine} ref={chartRef}>
                                    <CommonSeriesSettings
                                        argumentField="DATA_RICETTA"
                                        type="bar"
                                        hoverMode="allArgumentPoints"
                                        selectionMode="allArgumentPoints"
                                    >
                                        <Label visible={true}>
                                            <Format precision={0} />
                                        </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="NUMERO_RICETTE"
                                            type="bar"
                                            name= "Numero ricette"
                                            color="#1cd4ec"
                                        />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({
                                            value,
                                            argument,
                                        }: {
                                            value: any;
                                            argument: any;
                                        }) => {
                                            const formattedValue = parseFloat(value).toLocaleString(
                                                "it-IT",
                                                {
                                                    minimumFractionDigits: 0,
                                                }
                                            );

                                            return {
                                                html: `<p>Data prescrizione: ${argument}</p>
                                                       <p>Numero ricette ${formattedValue}</p>`,
                                            };
                                        }}
                                    />
                                    <Title text="Distribuzione ricette per data prescrizione ricetta dal medico">
                                        <Font color="#5b6372" size="20" weight="400" />
                                    </Title>
                                    <Legend
                                        verticalAlignment="bottom"
                                        horizontalAlignment="center"
                                    ></Legend>
                                    <Export fileName="Distribuzione ricette per data prescrizione ricetta dal medico" enabled={true} />
                                </Chart>
                            </div>

                            <div style={{}}>
                                <p
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "left",
                                        justifyContent: "left",
                                    }}
                                >
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisibleCHT2("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>

                            {chartDataMargine.length > 0 && (
                                <div
                                    style={{
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale:
                                    </h2>
                                    <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                                        {totalValueMargine}
                                    </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            )}

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                <div style={{}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
                </div>
            </section>
            <Popup
                visible={visiblePop1}
                showCloseButton={true}
                onHiding={() => setVisiblePop1(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopCHT}
                showCloseButton={true}
                onHiding={() => setVisiblePopCHT(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{CHTOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopCHT2}
                showCloseButton={true}
                onHiding={() => setVisiblePopCHTtwo(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{CHTtwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopCHT3}
                showCloseButton={true}
                onHiding={() => setVisiblePopCHTthree(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{CHTthree}</p>
                </div>
            </Popup>
        </section>

    );
};

export default AnalisiMedici;
