import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { trasform_for_cards_charts_01, trasform_no_marge } from "../util/trasformation";
import { testApi } from "../util/testRisp";
import { AplicaValoreTO, onExporting } from "../../../funzioni/operazioniSuTabelle";
import { MenuAnalisiDirezionale } from '../components/menu_Analisi_Direzionale';
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from "../../costanti";
import { CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Connector, Title, Export, Font, Format, Label, HoverStyle, Size, SmallValuesGrouping, Tooltip } from "devextreme-react/pie-chart";
import Chart, { Annotation, CommonSeriesSettings, Legend, Series } from 'devextreme-react/chart';
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";

import ".././style.css";
import { log } from "console";
import { Center } from "devextreme-react/map";

const dashboard23 = { dashboardID: 23 };

export interface boxCard {
    testoPop?: string;
}

export interface boxsecond {
    testoPop?: string;
}

const boxCard: cardC[] = [
    {
        testo: "Numero ricette",
        valore: "NUMERO_RICETTE",
        testoPop: `Numero ricette:
                   Rappresenta il numero di ricette lavorate dalla farmacia nel periodo selezionato.`
    },
    {
        testo: "Numero pezzi",
        valore: "NUMERO_PEZZI",
        testoPop: `Numero pezzi:
                   Rappresenta il numero di pezzi erogati dietro ricetta dalla farmacia nel periodo selezionato.`,
    },
];


const box2: cardC[] = [
    {
        testo: "Lordo",
        valore: "LORDO",
        typeShowValue: typeShow.euro,
        testoPop: `Lordo:
                   Rappresenta il valore lordo delle ricette lavorate dalla farmacia nel periodo selezionato.`
    },
    {
        testo: "Sconto regione",
        typeShowValue: typeShow.euro,
        valore: "SCONTI_REGIONE",
        testoPop: `Sconto regione:
                   Rappresenta lo sconto applicato dalla regione alle ricette lavorate dalla farmacia nel periodo selezionato.
                   Gli sconti considerati sono:
                   Sconto DL. 182
                   Sconto Industria
                   Sconto PayBack
                   Sconto SSN.`,
    },
    {
        testo: "Fatturato",
        typeShowValue: typeShow.euro,
        valore: "FATTURATO",
        testoPop: `Fatturato:
                   Rappresenta il fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato.
                   Il fatturato e' calcolato come differenza tra il valore lordo e lo sconto regione.
                   Gli sconti considerati dallo sconto regione sono:
                   Sconto DL. 182
                   Sconto Industria
                   Sconto PayBack
                   Sconto SSN.`,
    },
    {
        testo: "Ticket",
        typeShowValue: typeShow.euro,
        valore: "TICKET",
        testoPop: `Ticket:
                   Rappresenta il ticket incassato anticipatamente dalle ricette lavorate dalla farmacia nel periodo selezionato.`,
    },
    {
        testo: "Netto a liquidare",
        typeShowValue: typeShow.euro,
        valore: "NETTO_A_LIQUIDARE",
        testoPop: `Netto a liquidare:
                   Rappresenta il netto a liquidare generato dalle ricette lavorate dalla farmacia nel periodo selezionato.
                   Il netto a liquidare e' calcolato come differenza tra il fatturato e il ticket, rappresenta quanto l'ASL liquidera' alla farmacia.`,
    },
];





//da spostare
const convertTime = (data: Date) => {
    const anno = data.getFullYear();
    const mese = (data.getMonth() + 1).toString().padStart(2, "0"); // +1 perch� i mesi sono 0-based
    const giorno = data.getDate().toString().padStart(2, "0");
    const ore = data.getHours().toString().padStart(2, "0");
    const minuti = data.getMinutes().toString().padStart(2, "0");
    const secondi = data.getSeconds().toString().padStart(2, "0");

    const dataFormattata = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;

    return dataFormattata;
};

const AnalisiFarmaciErogati = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCard);
    const [fila2cards, setFila2cards] = useState<cardC[]>(box2);

    
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [ditte, setDitte] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
   
    const [visiblePopOne, setVisiblePopOne] = useState(false);

    const [visiblePopPie, setVisiblePopPie] = useState(false);
    const [visiblePopPie2, setVisiblePopPietwo] = useState(false);
    const [visiblePopPie3, setVisiblePopPiethree] = useState(false);
    const [visiblePopPie4, setVisiblePopPiefour] = useState(false);
    const [codiciAtc, setCodiciAtc] = useState<any[]>([]);



    const [tipoRaggruppamento, setTipoRaggruppamento] = useState<any>([]);
    const [sesso, setSesso] = useState<any>([]);
    const [eta, setEta] = useState<any>([]);
    const [flagGenerico, setFlagGenerico] = useState<any>([]);

    const [chartDataPFEPie, setChartDataPFEPie] = useState<any>([]);
    const [chartDataPSPie, setChartDataPSPie] = useState<any>([]);
    const [chartDataTFEPie, setChartDataTFEPie] = useState<any>([]);
    const [chartDataFTEPie, setChartDataFTEPie] = useState<any>([]);

    const [valori1W, setValori1W] = useState<any>([]);
    const [valori2W, setValori2W] = useState<any>([]);
    const [valori3W, setValori3W] = useState<any>([]);


    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale � mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];
        let newIDSettore: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {

            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            dal: params.periodo.dal.toLocaleString().substring(0, 6),
            al: params.periodo.al.toLocaleString().substring(6, 0),
            tipoRaggruppamento: params.tipoRaggruppamento,
            sesso: params.sesso,
            eta: params.eta,
        };

        console.log(parameters);
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall23 = {
                ...dashboard23,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall23, urlReport.getQueryDashboardAllData)
                .then((result) => {

                    if (result) {

                        setChartDataPFEPie(result["99"]);
                        setChartDataPSPie(result["100"]);
                        setChartDataTFEPie(result["101"]);
                        setChartDataFTEPie(result["101"]);

                        let datiPrimaParteCards = trasform_no_marge(
                            result["97"]["0"],
                            boxCard
                        );

                        let dati2Cards = trasform_no_marge(
                            result["97"]["0"],
                            box2
                        );

                        setFila1cards(datiPrimaParteCards);
                        setFila2cards(dati2Cards);

                        const valori1W = (result["98"]);
                        setValori1W(valori1W);

                        const valori2W = (result["102"]);
                        setValori2W(valori2W);

                        const valori3W = (result["103"]);
                        setValori3W(valori3W);


                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);


                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });
        }

        setIsVisible(true);
        if (authReport) {


            if (
                params.idFar.length === 0 ||
                params.idFar === undefined ||
                params.periodo.dal === null ||
                params.periodo.al === null
            ) {
                notify(
                    {
                        position: "center",
                        width: "auto",
                        message: "Filtro per farmacia e periodo obbligatori.",
                    },
                    "error",
                    4000
                );
                return;
            }



            //cambio data
            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1� gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 � gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };


    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };

    const popVisibleOne = (testo: string) => {
        setVisiblePopOne(true);
    };

    const popVisiblePie = (testo: string) => {
        setVisiblePopPie(true);
    };

    const popVisiblePietwo = (testo: string) => {
        setVisiblePopPietwo(true);
    };
    const popVisiblePiethree = (testo: string) => {
        setVisiblePopPiethree(true);
    };
    const popVisiblePiefour = (testo: string) => {
        setVisiblePopPiefour(true);
    };

    let messOne = [`Distribuzione pezzi per Fasce d'et� paziente:
                    Rappresenta la distribuzione per Fasce d'et� del numero di pezzi erogati dalla farmacia nel periodo selezionato.`]

    let messTwo = [`Distribuzione pezzi per Sesso paziente:
                    Rappresenta la distribuzione per Sesso del numero di pezzi erogati dalla farmacia nel periodo selezionato.`]

    let messThree = [`Distribuzione pezzi per tipologia farmaco erogato:
                      Rappresenta la distribuzione per tipologia farmaco del numero di pezzi erogati dalla farmacia nel periodo selezionato.`]

    let messFour = [`Distribuzione fatturato per per tipologia farmaco erogato:
                     Rappresenta la distribuzione per tipologia farmaco erogato del fatturato generato dalle ricette lavorate dalla farmacia nel periodo selezionato.`]


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();


    }, [auth]);


    const filters = () => {
        if (auth) {

            RestServices().rest('POST', "", urlBancaDati.getAtc).then(result => {
                if (result) setCodiciAtc(result);

            })

            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );

                        console.log(ditteWithCodiceDescrizione)
                        setDitte(ditteWithCodiceDescrizione);
                    }
                });


            console.log(ditte)
            const nondefinito = { id: 1, descrizione: 'Non Definito' }
            const ssn = { id: 2, descrizione: 'SSN' }
            const int = { id: 3, descrizione: `INT` }
            const dpc = { id: 4, descrizione: `DPC` }
            const intd = { id: 5, descrizione: `INTD` }
            const irc = { id: 6, descrizione: `IRC` }
            const cel = { id: 7, descrizione: `CEL` }
            const die = { id: 8, descrizione: `DIE` }
            const pro = { id: 9, descrizione: `PRO` }
            const care = { id: 10, descrizione: `CARE` }

            const tipoRaggruppamento = [nondefinito, ssn, int, dpc, intd, irc, cel, die, pro, care];
            setTipoRaggruppamento(tipoRaggruppamento);

            console.log();

            const male = { id: `M`, descrizione: `M` }
            const female = { id: `F`, descrizione: `F` }

            const sesso = [male, female]
            setSesso(sesso);

            const a = { id: `A`, codice: `A`, descrizione: `Neonati, 0 - 3 anni` }
            const b = { id: `B`, codice: `B`, descrizione: `Bambini, 3 - 11 anni` }
            const c = { id: `C`, codice: `C`, descrizione: `Adolescenti, 11 - 17 anni` }
            const d = { id: `D`, codice: `D`, descrizione: `Giovani adulti, 18 - 34 anni` }
            const e = { id: `E`, codice: `E`, descrizione: `Adulti, 35 - 44 anni` }
            const f = { id: `F`, codice: `F`, descrizione: `Adulti maturi, 45 - 59 anni` }
            const g = { id: `G`, codice: `G`, descrizione: `Giovani anziani, 60 - 74 anni` }
            const h = { id: `H`, codice: `H`, descrizione: `Anziani, 74 - 84 anni` }
            const i = { id: `I`, codice: `I`, descrizione: `Grandi anziani, 85+ anni` }
            const l = { id: `L`, codice: `L`, descrizione: `Eta non disponibile` }

            const eta = [a, b, c, d, e, f, g, h, i, l]
            setEta(eta)

            const fb = { id: 0, descrizione: "Ricetta con soli farmaci branded" }
            const fe = { id: 1, descrizione: "Ricetta con soli farmaci equivalenti" }

            const flagGenerico = [fb, fe]
            setFlagGenerico(flagGenerico)
        }
    };

    useEffect(() => {
        filters();


    }, [auth]);


    const exportFormats = ["csv"];

    const itemsFields = [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        //{
            //type: "selectWithLevels",
            //id: "flagGenerico",
            //label: "Tipo ricetta ",
            //displayExpr: "descrizione",
            //dataSource: flagGenerico,
            //idValueGrid: "flagGenerico" as any,
        //},
        {
            type: "selectMultiplaMoreColon",
            id: "eta",
            label: "Fascia d'eta del paziente",
            noId: true,
            displayExpr: "descrizione",
            dataSource: eta,
            idValueGrid: "eta" as any,
        },
        {
            type: "selectWithLevels",
            id: "sesso",
            label: "Sesso del paziente",
            displayExpr: "descrizione",
            noId: true,
            dataSource: sesso,
            idValueGrid: "sesso" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "ditta",
            label: "Ditta",
            displayExpr: "descrizione",
            dataSource: ditte,
            idValueGrid: "ditta" as any,
            allowSelectAll: false
        },
        //{
            //type: "selectWithLevels",
            //id: "",
            //label: "Tipologia prodotto",
            //displayExpr: "descrizione",
            //dataSource: "",
            //idValueGrid: "" as any,
        //},
        {
            type: 'selectWithLevels',
            id: 'atc',
            label: 'Codice ATC',
            displayExpr: 'descrizione',
            dataSource: codiciAtc,
            idValueGrid: 'atc' as any
        },
        {
            type: "selectWithLevels",
            id: "tipoRaggruppamento",
            label: "Tipo raggruppamento",
            noId: true,
            displayExpr: "descrizione",
            dataSource: tipoRaggruppamento,
            idValueGrid: "tipoRaggruppamento" as any,
        },
    ];

    const ProdottiVenduti = [
        { caption: "Prodotto", dataField: "SMA_ODT_DESCRIZIONE_PRODOTTO", width: "auto", fixed: true, },
        { caption: "Numero ricette", dataField: "NUMERO_RICETTE", sortOrder: "desc", width: 150 },
        { caption: "Numeri pezzi", dataField: "PEZZI_PRODOTTO", width: "auto" },
        { caption: "% Su pezzi totali", dataField: "PEZZI_PERCENTUALE", width: 150, customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d") },
        { caption: "Lordo", dataField: "LORDO", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto regione", dataField: "SCONTI_REGIONE", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Fatturato", dataField: "FATTURATO", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "% Sul fatturato totale", dataField: "FATTURATO_PERCENTUALE", width: 150, customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, "%", "percentuale", "d") },
        { caption: "Ticket", dataField: "TICKET", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Netto a liquidare", dataField: "NETTO_A_LIQUIDARE", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Fatturato medio pezzi", dataField: "FATTURATO_MEDIO_PEZZI", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
    ];

    const AnalisiDitta = [
        { caption: "Ditta", dataField: "PRO_DESC_DITTA_TITOLARE", width: "auto", fixed: true, },
        { caption: "Numero ricette", dataField: "NUMERO_RICETTE", sortOrder: "desc", width: 150 },
        { caption: "Numero pezzi", dataField: "PEZZI", width: 150 },
        { caption: "Tikcet", dataField: "TICKET", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Lordo", dataField: "LORDO", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto regione", dataField: "SCONTI_REGIONE", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Fatturato", dataField: "FATTURATO", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
    ];

    const AnalisiATC = [
        { caption: "ATC", dataField: "PRO_DESC_ATC1", width: "auto", fixed: true },
        { caption: "Numero ricette", dataField: "NUMERO_RICETTE", sortOrder: "desc", width: 150 },
        { caption: "Numero pezzi", dataField: "PEZZI_DITTA", width: 150 },
        { caption: "Ticket", dataField: "TICKET", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Lordo", dataField: "LORDO", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto regione", dataField: "SCONTI_REGIONE", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Fatturato", dataField: "FATTURATO", width: 150, format: { type: "currency", precision: 2, currency: "EUR" } },
    ];

    function formatLabel(arg: { argumentText: any; valueText: any }) {
        return `${arg.argumentText}: ${arg.valueText}%`;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    return (
        <section style={{ display: "flex", overflow: "hidden", height: "115vh" }}>
            {loadPanel}
            {loadinCambio ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                    }}
                >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                </div>
            ) : (
                <section
                    id="scrollerelement"
                    style={{ width: "100%", overflow: "scroll", height: "115vh" }}
                >
                    <div
                        style={{
                            fontSize: "15px",
                            alignItems: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "rgb(80, 84, 92)",
                        }}
                    >
                        <h2>BusinessLab Smart - Analisi Farmaci Erogati</h2>
                    </div>

                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                        >

                    </div>

                    <Popup
                        visible={visiblePop}
                        showCloseButton={true}
                        //onHiding={() => doSomething()}
                        width={"40%"}
                        height={"60%"}
                        showTitle={true}
                        title="Dettagli widget"
                    >
                        <div>
                            <p>{testoPop}</p>
                        </div>
                    </Popup>

                        <div>

                            <div className="grid-ibrid">
                            <div style={{width: 'calc(166.9%)', marginBottom: -1}} className="grid-container-one">
                            {fila1cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                                </div>
                            </div>
                            <div style={{ width: 'calc(100%)', paddingTop: 5, marginBottom: 20 }} className="grid-first">
                            {fila2cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>

                        <div style={{ marginTop: "5px", marginBottom: 15 }} >
                            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17 }}>Top 10 prodotti venduti</h6>
                            <NewTabella
                                height={"81vh"}
                                idTabella="AnalisiFarmaciErogati"
                                sync
                                colonne={ProdottiVenduti}
                                dataSource={valori1W}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiProdotti",
                                    formats: { exportFormats },
                                }}
                                activeSaver
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                       <Summary>
                                            <TotalItem
                                                column="LORDO"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                            <TotalItem
                                                column="FATTURATO"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                            <TotalItem
                                                column="TICKET"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                            <TotalItem
                                                column="SCONTI_REGIONE"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                            <TotalItem
                                                column="NUMERO_RICETTE"
                                                summaryType="sum" />
                                        </Summary>
                                    }
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5,
                            paddingBottom: 10
                        }}
                    >

                        <div style={{ width: "45%", marginTop: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataPFEPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series
                                    argumentField="ETA_RANGE"
                                    valueField="PEZZI"
                                >
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>

                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        console.log('vediamo value');
                                        console.log(percentText);


                                        const dataItem = chartDataPFEPie.find((item: any) => item.ETA_RANGE === argument);
                                        const dataSourceValue = dataItem ? dataItem.PEZZI : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', { }).format(dataSourceValue);

                                        return {
                                            html: `<p>ETA Range: ${argument}<p><p>Pezzi: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title
                                    text="Distribuzione pezzi per fasce d'eta"
                                >
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                    <Export fileName="Distribuzione pezzi per fasce d'eta" enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>


                        <div style={{ width: "45%", marginTop: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataPSPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series argumentField="SESSO" valueField="PEZZI">
                                <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>
                                    {<Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                            console.log('vediamo value');
                                            console.log(percentText);


                                            const dataItem = chartDataPSPie.find((item: any) => item.SESSO === argument);
                                            const dataSourceValue = dataItem ? dataItem.PEZZI : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT', { }).format(dataSourceValue);

                                            return {
                                                html: `<p>Sesso: ${argument}<p><p>Pezzi: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                            };
                                        }}
                                    />}
                                <Title text="Distribuzione pezzi per sesso">
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                    <Export fileName="Distribuzione pezzi per sesso" enabled={true} />
                            </PieChart>
                        </div>
                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePietwo("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5,
                            paddingBottom: 10
                        }}
                    >

                        <div style={{ width: "45%", marginTop: 20 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataTFEPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series
                                    argumentField="TIPO_RICETTA"
                                    valueField="NUMERO_RICETTE"
                                >
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>

                                {<Tooltip
                                    enabled={true}
                                    customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                        console.log('vediamo value');
                                        console.log(percentText);


                                        const dataItem = chartDataTFEPie.find((item: any) => item.TIPO_RICETTA === argument);
                                        const dataSourceValue = dataItem ? dataItem.NUMERO_RICETTE : 0;
                                        const formattedValue = new Intl.NumberFormat('it-IT', { }).format(dataSourceValue);

                                        return {
                                            html: `<p>Tipo ricetta: ${argument}<p><p>Numero pezzi: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                        };
                                    }}
                                />}
                                <Title
                                    text="Numero pezzi per tipologia farmaco erogato"
                                >
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                    <Export fileName="Mumero pezzi per tipologia farmaco erogato" enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePiethree("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>


                        <div style={{ width: "45%", marginTop: 30 }}>
                            <PieChart
                                id="pie"
                                dataSource={chartDataFTEPie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            >
                                <Series argumentField="TIPO_RICETTA" valueField="FATTURATO">
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>
                                    {<Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                            console.log('vediamo value');
                                            console.log(percentText);


                                            const dataItem = chartDataFTEPie.find((item: any) => item.TIPO_RICETTA === argument);
                                            const dataSourceValue = dataItem ? dataItem.FATTURATO : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT', { }).format(dataSourceValue);

                                            return {
                                                html: `<p>Tipo ricetta: ${argument}<p><p>Fatturato: ${formattedValue}</p><p>Percentuale: ${(percentText)}</p>`,
                                            };
                                        }}
                                    />}
                                <Title text="Fatturato per tipologia erogato">
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                    <Export fileName="Fatturato per tipologia erogato" enabled={true} />
                            </PieChart>
                        </div>
                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePiefour("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>
                    </div>

                    <div>
                        <div style={{ marginTop: 15, marginBottom: 15 }} >
                            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17 }}>Analisi ditta - prodotto</h6>
                            <NewTabella
                                height={"81vh"}
                                idTabella="AnalisiFarmaciErogati"
                                sync
                                colonne={AnalisiDitta}
                                dataSource={valori2W}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiDittaProdotto",
                                    formats: { exportFormats },
                                }}
                                activeSaver
                                wordWrapEnabled={true}
                                    onExporting={onExporting}
                                    summary={
                                        <Summary>
                                            <TotalItem
                                                column="LORDO"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                            <TotalItem
                                                column="FATTURATO"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                            <TotalItem
                                                column="TICKET"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                            <TotalItem
                                                column="SCONTI_REGIONE"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                            <TotalItem
                                                column="NUMERO_RICETTE"
                                                summaryType="sum" />

                                        </Summary>
                                    }
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5
                        }}
                    >
                    </div>
                    <div>
                        <div style={{ marginTop: "5px" }} >
                            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17 }}>Analisi ATC - prodotto</h6>
                            <NewTabella
                                height={"67vh"}
                                idTabella="AnalisiFarmaciErogati"
                                sync
                                colonne={AnalisiATC}
                                dataSource={valori3W}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiATCProdotti",
                                    formats: { exportFormats },
                                }}
                                activeSaver
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                        <Summary>
                                            <TotalItem
                                                column="LORDO"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                            <TotalItem
                                                column="FATTURATO"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                            <TotalItem
                                                column="TICKET"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />

                                            <TotalItem
                                                column="SCONTI_REGIONE"
                                                summaryType="sum"
                                                valueFormat={{ style: "currency", precision: 2, currency: "EUR" }} />
                                            <TotalItem
                                                column="NUMERO_RICETTE"
                                                summaryType="sum" />

                                        </Summary>
                                    }
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginTop: 5
                        }}
                    >
                    </div>
                </section>
            )}

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                <div style={{}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
                </div>
            </section>
            <Popup
                visible={visiblePopPie}
                showCloseButton={true}
                onHiding={() => setVisiblePopPie(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie2}
                showCloseButton={true}
                onHiding={() => setVisiblePopPietwo(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie3}
                showCloseButton={true}
                onHiding={() => setVisiblePopPiethree(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePopPie4}
                showCloseButton={true}
                onHiding={() => setVisiblePopPiefour(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messFour}</p>
                </div>
            </Popup>
        </section>

    );
};

export default AnalisiFarmaciErogati;
