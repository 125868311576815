import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ListaFarmacieInterface } from './interfaces/farmacie'
import SignupUdi from './contenuti/cgm/SignupUdi'
import LoginUdi from './contenuti/cgm/LoginUdi'
import GestioneUdi from './contenuti/cgm/GestioneUdi'

const ContenutoCgm = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[],
}) => {

    useEffect(() => {
        document.title = "Registro UDI - Securefarma DB"
    }, [])

    return (
        <>
    <Switch>
        <Route path='/sfbd/signupUdi' >
            <SignupUdi />
        </Route>
        <Route path='/sfbd/loginUdi' >
            <LoginUdi />
                </Route>
        <Route path='/sfbd/gestioneUdi' >
            <GestioneUdi datiFarmacie={props.datiFarmacie} />
        </Route>
    </Switch>
    </>
    )}

export default ContenutoCgm