import { cardC, typeShow, cardV , cardG } from "../../components/card_chart";


export const boxAnaliMagazz: cardC[] = [
    {
      testo: "Giacenza attuale",
      valore: "VALORE_VENDUTO_EQUIPARATOSL",
      typeShowValue: typeShow.percento,
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Referenze in giacenza",
      valore: "MARGINE_EQUIPARATOSL",
      typeShowValue: typeShow.percento,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Indice di Rotazione medio",
      valore: "NUMERO_VENDITE_EQUIPARATOSL",
      typeShowValue: typeShow.percento,
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Indice di copertura medio",
      valore: "VOLUME_VENDITA_EQUIPARATOSL",
      typeShowValue: typeShow.percento,
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
                
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
  ];
  
 