import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { columnsAnaliVendSinte } from "./colums/AnalVendi";
import { MenuFbiVendite } from "../components/menu_Fbi_Vendite";
import { createItemFilterVendSinte } from "./itemsFiltri/AnaliVendite";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Chart, PieChart } from 'devextreme-react';
import { Connector, Font, Format, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';
import {
    Animation,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    Label,
    Legend,
    Series,
    Subtitle,
    Title,
    Tooltip,
    ValueAxis
} from 'devextreme-react/chart';

import "./style.css";

const AnalVendiSinte = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [farVima, setNewFarvima] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop0, setVisiblePop0] = useState(false);
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);
    const [visiblePop4, setVisiblePop4] = useState(false);
    const [visiblePop5, setVisiblePop5] = useState(false);
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [chartData, setChartData] = useState<any>([]);
    const [chartDataMargine, setChartDataMargine] = useState<any>([]);
    const [totalValueMargine, setTotalValueMargine] = useState(0);
    const [chartDataNumeroVend, setChartDataNumeroVend] = useState<any>([]);
    const [chartDataDittaPie, setChartDataDittaPie] = useState<any>([]);
    const [chartDataPezzi, setChartDataPezziVendu] = useState<any>([]);
    const [totalValuePezzi, setTotalValuePezzi] = useState(0);
    const [totalValue, setTotalValue] = useState(0);
    const [chartTipoProdPie, setChartTipoProdPie] = useState<any>([]);
    const [totalValueNumeroVend, setTotalValueNumeroVend] = useState(0);
    const [progetto, setProgetto] = useState<any[]>([]);
    const [societa, setSocieta] = useState<any[]>([]);
    const [categoria, setCategoria] = useState<any[]>([]);
    const [segmento, setSegmento] = useState<any[]>([]);
    const [blocco1, setBlocco1] = useState<any[]>([]);
    const [blocco2, setBlocco2] = useState<any[]>([]);
    const [tipologiaProdotto, setTipologiaProdotto] = useState<any[]>([]);
    const [tipologiaConFascia, setTipologiaConFascia] = useState<any[]>([]);
    const chartRef = useRef<any>(null);
    const chartRefDitta = useRef<any>(null);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const dashboard11 = { dashboardID: 11 };

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        //if (params.atc) {
        //    const atcList = params.atc;
        //    codAtc1 = getATCByLevel(atcList, 1);
        //    codAtc2 = getATCByLevel(atcList, 3);
        //    codAtc3 = getATCByLevel(atcList, 4);
        //    codAtc4 = getATCByLevel(atcList, 5);
        //    codAtc5 = getATCByLevel(atcList, 7);
        //}

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        if (params.societa && params.societa.length > 0) {
            console.log(societa);
            let objSocieta: any = []

            params.societa.forEach((element: any) => {
                const oggettoTrovato = societa.find(obj => obj.id === element);
                objSocieta.push({
                    id: oggettoTrovato.id,
                    codice: oggettoTrovato.codice,
                })
            });
            params.societaNewV = objSocieta;
        }


        if (params.progetto && params.progetto.length > 0) {
            let objSocieta: any = []

            params.progetto.forEach((element: any) => {
                const oggettoTrovato = progetto.find(obj => obj.id === element);
                objSocieta.push({
                    id: oggettoTrovato.id,
                    codice: oggettoTrovato.codice,
                })
            });
            params.progettoNewV = objSocieta;
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            categoria: params.categoria,
            segmento: params.segmento,
            blocco1: params.blocco1,
            blocco2: params.blocco2,
            progetto: params.progettoNewV,
            societa: params.societaNewV,
            tipologiaProdotto: params.tipologiaProdotto,
            tipologiaConFascia: params.tipologiaConFascia,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            //new call

            //console.log('mostro call');
            //console.log(dashboardCall11);


            const dashboardCall11 = {
                ...dashboard11,
                idFarList: params.idFar,
                // idFarList: [
                //   8939,
                //   8923],
                params: parameters,
            };


            if ((params.progettoNewV !== undefined && params.progettoNewV !== 0) && (params.societaNewV !== undefined && params.societaNewV !== 0)) {
                RestServices()
                    .rest("POST", dashboardCall11, urlReport.extractFarvimaPharmacies)
                    .then((result) => {


                        if (result) {

                            params.idFar = result;

                        }
                    });
            } else {

                params.idFar = objFiltro.idFar;
            }

            RestServices()
                .rest("POST", dashboardCall11, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {

                        //  i 4 Chart 
                        setChartData(result["58"].reverse());
                        setChartDataMargine(result["58"].reverse());
                        // console.log(result["58"]);
                        setChartDataNumeroVend(result["58"].reverse());
                        setChartDataPezziVendu(result["58"].reverse());

                        let datiPrimaTabelle = result["61"];


                        // 1 chart id 58

                        const total = result["58"]
                            .slice(12, 23)
                            .reduce(
                                (acc: number, item: any) => acc + item.VALORE_VENDUTO,
                                0
                            );
                        const formattedTotal = total.toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                        });

                        setTotalValue(formattedTotal);

                        // 2 chart margine id 58

                        const total2 = result["58"]
                            .slice(0, 11)
                            .reduce((acc: number, item: any) => acc + item.MARGINE_TOTALE, 0);
                        const formattedTotalMargine = total2.toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                            minimumFractionDigits: 0,
                        });

                        setTotalValueMargine(formattedTotalMargine);

                        // 3 CHART id 58

                        const total3 = result["58"]
                            .slice(12, 23)
                            .reduce(
                                (acc: number, item: any) => acc + item.NUMERO_VENDITE_TOTALE,
                                0
                            );
                        const formattedTotalNumeroVend = total3.toLocaleString("it-IT", {
                            minimumFractionDigits: 0,
                        });

                        setTotalValueNumeroVend(formattedTotalNumeroVend);

                        // 4 CHART id 58

                        const total4 = result["58"]
                            .slice(0, 11)
                            .reduce(
                                (acc: number, item: any) => acc + item.TOTALE_PEZZI_VENDUTI,
                                0
                            );
                        const formattedTotalPezzi = total4.toLocaleString("it-IT", {
                            minimumFractionDigits: 0,
                        });

                        setTotalValuePezzi(formattedTotalPezzi);

                        // ID 59 pie chart
                        const chartTipoProdPie = result["59"]
                            .map((item: any) => {
                                // const atc = item.PRO_COD_ATC1;
                                const desc = item.FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1;
                                const value = item.IMPORTO_LORDO_IVA;

                                // let final = atc + " - " + desc;
                                // if (desc === undefined) {
                                //   final = "N.D";
                                // }

                                return {
                                    //ATC: atc,
                                    FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1: desc,
                                    IMPORTO_LORDO_IVA: value,
                                };
                            })
                            .reverse();

                        // 1 PieChart ID 59

                        const total7 = chartTipoProdPie.reduce(
                            (sum: any, item: { IMPORTO_LORDO_IVA: any }) =>
                                sum + item.IMPORTO_LORDO_IVA,
                            0
                        );

                        // Aggiunta della percentuale per ogni valore
                        const chartTipoProdPieConPercentuale = chartTipoProdPie.map(
                            (item: { IMPORTO_LORDO_IVA: number }) => {
                                // Calcolo della percentuale per ogni valore
                                const percentuale = (
                                    (item.IMPORTO_LORDO_IVA / total7) *
                                    100
                                ).toFixed(2);

                                return {
                                    ...item,
                                    PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
                                };
                            }
                        );

                        setChartTipoProdPie(chartTipoProdPieConPercentuale);

                        // ID 60 Chart
                        const chartDataDitta = result["60"].map((item: any) => {
                            const venduto = item.IMPORTO_LORDO_IVA;
                            const ditta = item.PRO_DESC_DITTA_TITOLARE;

                            return {
                                IMPORTO_LORDO_IVA: venduto,
                                PRO_DESC_DITTA_TITOLARE: ditta,
                            };
                        });

                        //console.log('vedite visualuzza');
                        //console.log(chartDataDitta);

                        let sortato = chartDataDitta.sort((a: any, b: any) => b.IMPORTO_LORDO_IVA - a.IMPORTO_LORDO_IVA);


                        //console.log(sortato);

                        setChartDataDittaPie(sortato);


                        setValoriTabella1(datiPrimaTabelle);

                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1° gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }

    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };



    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const itemsFields = createItemFilterVendSinte(
        newFarmaList,
        progetto,
        societa,
        tipologiaConFascia,
        tipologiaProdotto,
        categoria,
        segmento,
        blocco1,
        blocco2
    );

    const filters = () => {
        if (authReport) {
            RestServices()
                .rest("POST", "", urlReport.getProgetto)
                .then((result) => {
                    //console.log('vediamo progetto');
                    //console.log(result);

                    if (result) {
                        const progetti = result.map((oggetto: { codice: any, id: any }) => {
                            if (oggetto.id) {
                                return {
                                    ...oggetto, // Copia tutte le proprietà esistenti
                                    id: oggetto.id, // Aggiungi la nuova proprietà id
                                };
                            }
                        });
                        setProgetto(progetti);
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getSocieta)
                .then((result) => {
                    if (result) {
                        const societas = result.map((oggetto: { codice: any, id: any }) => {
                            if (oggetto.id) {
                                return {
                                    ...oggetto, // Copia tutte le proprietà esistenti
                                    id: oggetto.id // Aggiungi la nuova proprietà id      
                                };
                            }
                        });
                        setSocieta(societas)
                    }
                });


            RestServices()
                .rest("POST", "", urlReport.getTipologiaConFascia)
                .then((result) => {
                    if (result) {
                        const fascia = result.map((oggetto: { descrizione: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le proprietà esistenti
                                id: oggetto.descrizione // Aggiungi la nuova proprietà id
                            };
                        });


                        setTipologiaConFascia(fascia)
                    }
                });


            RestServices()
                .rest("POST", "", urlReport.getTipologiaProdotto)
                .then((result) => {
                    if (result) {
                        const prodotti = result.map((oggetto: { descrizione: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le proprietà esistenti
                                id: oggetto.descrizione // Aggiungi la nuova proprietà id
                            };
                        });

                        setTipologiaProdotto(prodotti)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getCategoria)
                .then((result) => {

                    if (result) {
                        const categorie = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le proprietà esistenti
                                id: oggetto.codice // Aggiungi la nuova proprietà id
                            };
                        });
                        setCategoria(categorie)

                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getSegmento)
                .then((result) => {
                    if (result) {
                        const segmentos = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le proprietà esistenti
                                id: oggetto.codice // Aggiungi la nuova proprietà id
                            };
                        });
                        setSegmento(segmentos)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getBlocco1)
                .then((result) => {
                    if (result) {
                        const blocco1 = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le proprietà esistenti
                                id: oggetto.codice // Aggiungi la nuova proprietà id
                            };
                        });
                        setBlocco1(blocco1)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getBlocco2)
                .then((result) => {
                    if (result) {
                        const blocco2 = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le proprietà esistenti
                                id: oggetto.codice // Aggiungi la nuova proprietà id
                            };
                        });
                        setBlocco2(blocco2)
                    }
                });


        }

    };

    useEffect(() => {
        filters();
    }, [authReport]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // Scrivi il buffer del workbook in un file Excel
            workbook.xlsx.writeBuffer().then(function (buffer) {
                // Cambia il tipo MIME in "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                // e l'estensione del nome del file in ".xlsx" per l'esportazione in Excel
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "tabella_operatori.xlsx"
                );
            });
        });
    };


    const popVisible0 = (testo: string) => {
        setVisiblePop0(true);
    };

    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };

    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    const popVisible4 = (testo: string) => {
        setVisiblePop4(true);
    };

    const popVisible5 = (testo: string) => {
        setVisiblePop5(true);
    };


    let mess0 = ["Valore 0 "];
    let mess1 = ["Valore  1 "];
    let mess2 = ["Valore  2"];
    let mess3 = ["Valore  3"];
    let mess4 = ["Valore 4"];
    let mess5 = ["Valore 5"];

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    return (
        <>

            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2> F.B.I. </h2>
                        </div>
                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 5,
                                marginLeft: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >
                            <div>
                                <MenuFbiVendite />
                            </div>
                        </div>

                        {/* 1 CHART */}

                        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5, marginTop: 15 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "95%", margin: "10px" }}>
                                    <Chart dataSource={chartData} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            selectionMode="allArgumentPoints"
                                            barPadding={0.2}

                                        >
                                            <Label visible={true}>
                                                <Format type="currency" currency="EUR" precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="VALORE_VENDUTO_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)">

                                            <Label visible={true} verticalOffset={-60}> </Label>
                                        </Series>



                                        <Series
                                            valueField="VALORE_VENDUTO"
                                            type="bar"
                                            argumentField="MESE"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                            <Label visible={true} verticalOffset={80}> </Label>
                                        </Series>



                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        style: "currency",
                                                        currency: "EUR",
                                                        minimumFractionDigits: 2,
                                                    }
                                                );

                                                return {
                                                    text: `Valore del venduto netto sconti: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Andamento valore del venduto Ivato netto sconti - ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="16" weight="400" />
                                            <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Subtitle>
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="venduto_venduto_netto_sconti" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p>
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisible5("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {chartDataMargine.length > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        backgroundColor: "white",
                                        marginTop: 15

                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValue}</span>
                                    </h2>
                                </div>
                            )}
                        </div>


                        {/* 2 CHART */}
                        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5, marginTop: 15 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "95%", margin: "10px" }}>
                                    <Chart dataSource={chartData} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            barPadding={0.2}
                                        >
                                            <Label visible={true}>
                                                <Format type="currency" currency="EUR" precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="MARGINE_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)">

                                            <Label visible={true} verticalOffset={-60}> </Label>

                                        </Series>


                                        <Series
                                            valueField="MARGINE_TOTALE"
                                            type="bar"
                                            argumentField="MESE"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                            <Label visible={true} verticalOffset={80}> </Label>
                                        </Series>



                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        style: "currency",
                                                        currency: "EUR",
                                                        minimumFractionDigits: 2,
                                                    }
                                                );

                                                return {
                                                    text: `Venduto: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Andamento margine - ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="16" weight="400" />
                                            <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Subtitle>
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="Andamento_margine" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisible1("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {chartDataMargine.length > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        backgroundColor: "white",
                                        marginTop: 15

                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueMargine}</span>
                                    </h2>
                                </div>
                            )}
                        </div>


                        {/* 3 CHART */}
                        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5, marginTop: 15 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "95%", margin: "10px" }}>
                                    <Chart dataSource={chartDataNumeroVend} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            barPadding={0.2}
                                        >
                                            <Label visible={true}>
                                                <Format precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="NUMERO_VENDITE_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)">

                                            <Label visible={true} verticalOffset={-60}> </Label>

                                        </Series>


                                        <Series
                                            valueField="NUMERO_VENDITE_TOTALE"
                                            type="bar"
                                            argumentField="MESE"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                            <Label visible={true} verticalOffset={80}> </Label>

                                        </Series>



                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                );

                                                return {
                                                    text: `Vendite: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Andamento numero vendite - ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="16" weight="400" />
                                            <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Subtitle>
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="vendite" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left",
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisible2("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                            </div>

                            {chartDataNumeroVend.length > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        backgroundColor: "white",
                                        marginTop: 15

                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueNumeroVend}</span>
                                    </h2>
                                </div>
                            )}
                        </div>

                        {/* 4 CHART */}
                        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5, marginTop: 15 }}>
                            <div style={{ display: "flex" }}>
                                <div style={{ width: "95%", margin: "10px" }}>
                                    <Chart dataSource={chartDataPezzi} ref={chartRef}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            selectionMode="allArgumentPoints"
                                            barPadding={0.2}
                                        >
                                            <Label visible={true}>
                                                <Format precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="TOTALE_PEZZI_VENDUTI_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)">

                                            <Label visible={true} verticalOffset={-60}> </Label>

                                        </Series>


                                        <Series
                                            valueField="TOTALE_PEZZI_VENDUTI"
                                            type="bar"
                                            argumentField="MESE"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                            <Label visible={true} verticalOffset={80}> </Label>

                                        </Series>


                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({
                                                value,
                                                argument,
                                            }: {
                                                value: any;
                                                argument: any;
                                            }) => {
                                                const formattedValue = parseFloat(value).toLocaleString(
                                                    "it-IT",
                                                    {
                                                        minimumFractionDigits: 0,
                                                    }
                                                );
                                                return {
                                                    text: `Pezzi venduti: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Andamento pezzi venduti - ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="16" weight="400" />
                                            <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Subtitle>
                                        </Title>
                                        <Legend
                                            verticalAlignment="bottom"
                                            horizontalAlignment="center"
                                        ></Legend>
                                        <Export fileName="pezzi_venduti" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "left",
                                            justifyContent: "left"
                                        }}
                                    >
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisible3("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                            </div>
                            {chartDataPezzi.length > 0 && (
                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        backgroundColor: "white",
                                        marginTop: 15

                                    }}
                                >
                                    <h2
                                        style={{
                                            color: "#5b6372",
                                            fontFamily: "Arial, sans-serif",
                                        }}
                                    >
                                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValuePezzi}</span>
                                    </h2>
                                </div>
                            )}
                        </div>

                        {/* Tabella*/}

                        <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: 10 }}>Analisi venduto periodo selezionato</h6>

                        <NewTabella
                            height={"70vh"}
                            idTabella="analisi_prodotti"
                            sync
                            colonne={columnsAnaliVendSinte}
                            dataSource={valoriTabella1}
                            rowAlternation={true}
                            filterRow
                            width={500}
                            searchPanel={true}
                            exportT={{
                                enabled: true,
                                fileName: "AnalisiProdotti",
                                formats: { exportFormats },
                            }}
                            activeSaver={true}
                            columnC={true}
                            headerFilter
                            wordWrapEnabled={true}
                            onExporting={onExporting}
                            summary={
                                <Summary>
                                    <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                    <TotalItem column="OMAGGI" summaryType="sum" />
                                    <TotalItem
                                        column="COSTO"
                                        summaryType="sum"
                                        valueFormat={{ style: "currency", currency: "EUR" }}
                                    />
                                </Summary>
                            }
                        />

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "white",
                                marginTop: "5px"
                            }}
                        >
                            <div style={{ width: "93%", margin: "10px" }}>
                                <PieChart
                                    id="pie"
                                    dataSource={chartTipoProdPie}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
                                >
                                    <Series argumentField="FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1" valueField="PERCENTUALE">
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                        <SmallValuesGrouping
                                            groupName="Altro"
                                            threshold={1.5}
                                            mode="smallValueThreshold"
                                        />
                                    </Series>
                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({
                                            argument,
                                            value,
                                        }: {
                                            argument: any;
                                            value: any;
                                        }) => {
                                            const dataItem = chartTipoProdPie.find(
                                                (item: any) => item.FARVIMA_TIPOLOGIA_PRODOTTO_CF_BLOCCO_1 === argument
                                            );
                                            const dataSourceValue = dataItem
                                                ? dataItem.IMPORTO_LORDO_IVA
                                                : 0;
                                            const formattedValue = new Intl.NumberFormat("it-IT", {
                                                style: "currency",
                                                currency: "EUR",
                                            }).format(dataSourceValue);
                                            const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                                            const percentualeFormattata =
                                                percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                                            return {
                                                text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                                            };
                                        }}
                                    />
                                    <Title text="Valore del venduto netto ivato sconti per tiplogia prodotto - Periodo selezionato">
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                    <Export enabled={true} />
                                </PieChart>
                            </div>

                            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
                                <p>
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite-two"
                                            style={{ border: "none" }}
                                            onClick={() => popVisible4("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "white",
                                marginTop: "5px"
                            }}
                        >
                            <div style={{ width: "93%", margin: "10px" }}>
                                <Chart
                                    dataSource={chartDataDittaPie.slice(0, 22)}
                                    ref={chartRefDitta}
                                >
                                    <CommonSeriesSettings
                                        argumentField="PRO_DESC_DITTA_TITOLARE"
                                        type="bar"
                                    />

                                    <Series
                                        valueField="IMPORTO_LORDO_IVA"
                                        type="bar"
                                        color="rgb(25, 231, 255)"
                                    />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({
                                            value,
                                            argument,
                                        }: {
                                            value: any;
                                            argument: any;
                                        }) => {
                                            const formattedValue = parseFloat(value).toLocaleString(
                                                "it-IT",
                                                {
                                                    style: "currency",
                                                    currency: "EUR",
                                                    minimumFractionDigits: 2,
                                                }
                                            );

                                            return {
                                                text: `Venduto: ${formattedValue} \n Ditta: ${argument}`,
                                            };
                                        }}
                                    />

                                    <Title text="Valore del venduto ivato netto sconti per Produttore - Periodo selezionato">
                                        <Font color="#5b6372" size="16" weight="400" />
                                        <Subtitle text="<i>Si visualizzano i primi 20.</i>">
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle>
                                    </Title>
                                    <Export fileName="venduto_ditta" enabled={true} />
                                    <Legend visible={false} />
                                </Chart>
                            </div>

                            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
                                <p>
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisible5("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>


                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
                <Popup
                    visible={visiblePop0}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop0(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{mess0}</p>
                    </div>
                </Popup>
                <Popup
                    visible={visiblePop1}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop1(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{mess1}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePop2}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop2(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{mess2}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePop3}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop3(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{mess3}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePop4}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop4(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{mess4}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePop5}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop5(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{mess5}</p>
                    </div>
                </Popup>

            </section>
        </>
    );
};

export default AnalVendiSinte;