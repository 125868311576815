import React, { useEffect, useState } from 'react'
import notify from 'devextreme/ui/notify'
import { DialogConferma, Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlBancaDati, urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
import { DialogGestProdGruppo } from "./dialog/popup_gest_prod_gruppo"
import {DialogVisuProd} from "./dialog/popup_visu_prod"
import './style.css';
import { DialogLogInvioProd } from './dialog/popup_log_invio_prod'
import GestProdGruppoDett from './gest_prod_gruppo_dettaglio'
import { SettoriInterface } from '../../interfaces/generiche'
import { DialogRiepilogoInvioProd } from './dialog/popup_riepilogo_invio'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"


const GestProdGruppo = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const columns = [
        { caption: "Codice", dataField: "codiceProdotto" }, 
        { caption: "Ean", dataField: "ean" },  
        { caption: "Descrizione", dataField: "descrizioneRidotta" }, 
        { caption: "Azioni",  type: "buttons", buttons: [
            {
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {
                    //if (e.row.data.inviato == true) {
                    //    notify({ position: "center", width: "auto", message: "Un prodotto inviato non puo' essere modificato" }, "warning", 4000)
                    //    return;
                    //}
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setViewMode(false)
                    setRenderp(2)
                    //setOpenPop(true)
                    
                }
            },
            {
                hint: "Visualizza",
                icon: "search",
                onClick: (e: any) => {
                    setObjtipo(e.row.data)
                    setRenderp(2)
                    setViewMode(true)
                    //setOpenPopVisualProd(true)
                    
                }
            },
            {
                hint: "Invia in farmacia",
                icon: "upload",
                onClick: (e: any) => {
                    //console.log(e.row.data);             
                    setIdinv(e.row.data.id)
                    let ids: number[] = []
                    ids.push(e.row.data.id)
                    setIdRieInv(ids)
                    //setMessInv("il prodotto " + e.row.data.descrizioneRidotta + " sta per essere inviato a tutte le farmacie del gruppo. Confermare?")
                    //setPopInv(true)
                    setPopRieInv(true)
                    setMessRieInv("Riepilogo invio prodotto in farmacia ")
                    setCodProdRieInv(e.row.data.codiceProdotto)
                    setCodEanRieInv(e.row.data.ean)
                    setDescrRieInv(e.row.data.descrizioneRidotta)
                }
            },
            {
                hint: "Visualizza log invio",
                icon: "bulletlist",
                onClick: (e: any) => {
                    //console.log(e.row.data);             
                    setIdLogInv(e.row.data.id)
                    setMessLogInv("Invii del prodotto " + e.row.data.descrizioneRidotta )
                    setPopLogInv(true)
                }
            },
            {
                hint: "Disabilita",
                icon: "trash",
                onClick: (e: any) => {
                    //console.log(e.row.data);             
                    setIddell(e.row.data.id)
                    setMessDel("il prodotto "+e.row.data.descrizioneRidotta+" sta per essere eliminato. Confermare?")
                    setPopDel(true)
                    
                }
            }]  },
     ];

    
    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [popDel, setPopDel] = useState<boolean>(false)
    const [popInv, setPopInv] = useState<boolean>(false)
    const [popLogInv, setPopLogInv] = useState<boolean>(false)
    const [popRieInv, setPopRieInv] = useState<boolean>(false)
    const [openPopVisuaProd,setOpenPopVisualProd] = useState<boolean>(false)
    const [idDel,setIddell] = useState<number>(0)
    const [messDel,setMessDel] = useState<string>('')
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)
    const [idInv, setIdinv] = useState<number>(0)
    const [messInv, setMessInv] = useState<string>('')
    const [idLogInv, setIdLogInv] = useState<number>(0)
    const [idRieInv, setIdRieInv] = useState<number[]>([])
    const [messLogInv, setMessLogInv] = useState<string>('')
    const [messRieInv, setMessRieInv] = useState<string>('')
    const [codProdRieInv, setCodProdRieInv] = useState<string>('')
    const [codEanRieInv, setCodEanRieInv] = useState<string>('')
    const [descrRieInv, setDescrRieInv] = useState<string>('')
    const [renderp, setRenderp] = useState<any>();
    const [settori, setSettori] = useState<SettoriInterface[]>();
    const [viewMode, setViewMode] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>([])
    const [inviaSelezionatiClick, setInviaSelezionatiClick] = useState<boolean>();


    const getProdottiAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListProdGruppo)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })


            if (!settori || settori == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                    if (result) setSettori(result);
                })

        }
    }

    const removeTipo = () =>{
        if (auth) {
            RestServices().rest('POST', {id:idDel} , urlConsultazione.deleteProdGruppo)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       ricarica()                  
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }
        
                })   
         } 
    }


    const inviaSingoloProdotto = () => {

        setLoading(true)
        let ids: number[] = [];

        ids.push(idInv);

        const param = {
            idProdottoGruppo: ids,
        }

        RestServices().rest('POST', param, urlConsultazione.inviaProdGruppo).then(result => {
            if (result) {
                if (result.esito) {
                    setLoading(false)
                    notify({ position: "center", width: "auto", message: "Il prodotto e' stato inviato" }, "success", 4000)
                    setPopInv(false);
                    setPopRieInv(false);
                } else {
                    if (result.message) {
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                    else {
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: "Si e' verificato un errore, riprovare" }, "error", 4000)
                    }
                }
            }
            else {
                setLoading(false)
                notify({ position: "center", width: "auto", message: "Si � verificato un errore, riprovare" }, "error", 4000)
            }
        })

        

    }

    const inviaProdottiSelezionati = () => {

        let s: any[] = []

        if (selected && selected.length > 1) {
            selected.forEach((dato: any) => {
                if (dato.id != 0) {
                    s.push(dato.id)
                }


            })
        }

        if (s.length > 0 && auth) {

            const param = {
                idProdottoGruppo: s,
            }

            RestServices().rest('POST', param, urlConsultazione.inviaProdGruppo).then(result => {
                if (result) {
                    if (result.esito) {
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: "I prodotti sono stati inviati" }, "success", 4000)
                        setPopInv(false);
                        setPopRieInv(false);
                    } else {
                        if (result.message) {
                            setLoading(false)
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                        else {

                            notify({ position: "center", width: "auto", message: "Si e' verificato un errore, riprovare" }, "error", 4000)
                        }
                    }
                }
                else {
                    setLoading(false)
                    notify({ position: "center", width: "auto", message: "Si � verificato un errore, riprovare" }, "error", 4000)
                }
            })

        }

    }

    const btnInviaSelezionatiClick = () => {

        //console.log(selected)

        let s: number[] = []
        if (selected && selected.length > 0) {
            selected.forEach((dato: any) => {
                if (dato.id != 0) {
                    s.push(dato.id)
                }


            })
        }
        setIdRieInv(s)
        setMessRieInv("Riepilogo invio prodotti in farmacia ")
        setInviaSelezionatiClick(true)
        setPopRieInv(true)

    }

    const inviaProdotto = () => {
        

        // se ho premuto invia selezionati allora invio i selzionati altrimenti invio il singolo prodotto
        if (inviaSelezionatiClick)
            inviaProdottiSelezionati()
        else
            inviaSingoloProdotto();

        setInviaSelezionatiClick(false)
        setIdinv(0)
        setCodProdRieInv('')
    }

   

    const ricarica = () =>{
        setOpenPop(false)  
        setOpenPopVisualProd(false)
        getProdottiAction()
       
    }


   useEffect(()=>{
       getProdottiAction()
    },[auth])
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const onRowPrepared = (e: any) => {

        if (!e.data)
            return;
        if (e.data.associato === false) {
            e.rowElement.style.backgroundColor = 'rgb(252,230,232,255)';
        }
        else if (e.data.associato === true)
            e.rowElement.style.backgroundColor = 'rgb(221,244,239,255)';


    }

    

    const render = () => {
        switch (renderp) {
            case 1:
                return (
                    <>
                        <DialogConferma
                            dialogIsOpen={popDel}
                            message={messDel}
                            functionNext={() => removeTipo()}
                            handleCloseConferma={() => { setPopDel(false); setIddell(0) }}
                        />

                        <DialogConferma
                            dialogIsOpen={popInv}
                            message={messInv}
                            functionNext={() => inviaProdotto()}
                            handleCloseConferma={() => { setPopInv(false); setIdinv(0) }}
                        />


                        <DialogLogInvioProd
                            dialogLogInvioProdOpen={popLogInv}
                            handleCloseDialogLogInvioProd={() => { setPopLogInv(false); setIdLogInv(0) }}
                            idProdottoGruppo={idLogInv}
                            message={messLogInv}
                            setDialogLogInvioProdOpen={setPopLogInv}
                        />

                        <DialogRiepilogoInvioProd
                            dialogRiepilogoInvioProdOpen={popRieInv}
                            handleCloseDialogRiepilogoInvioProd={() => { setPopRieInv(false); setIdRieInv([]); setInviaSelezionatiClick(false) }}
                            idProdottoGruppo={idRieInv}
                            message={messRieInv}
                            codProd={codProdRieInv}
                            codEan={codEanRieInv}
                            descrizione={descrRieInv}
                            setDialogRiepilogoInvioProdOpen={setPopRieInv}
                            functionNext={() => inviaProdotto()}
                        />

                        {/* <DialogVisuProd edit={objtipo} ricarica={() => ricarica()} close={() => { setOpenPopVisualProd(false); setObjtipo({}) }} open={openPopVisuaProd} farmacie={undefined} />

                        <DialogGestProdGruppo typeEdit={edit} edit={objtipo} ricarica={() => ricarica()} close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }} open={openPop}*/}


                        <Loading isVisible={loading} />
                        <div className='title'>
                            <h3> GESTIONE PRODOTTI GRUPPO <span className='subt' ></span> </h3>
                        </div>
                        

                        <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                            <Row>
                                <Col md="1">
                                    <div className='title2'>
                                        <h6> Legenda Colori: </h6>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className='legAf1'>
                                        <h6 >  Associato a tutte le farmacie del gruppo </h6>
                                    </div>
                                </Col >
                                <Col md="3">
                                    <div className='legAf2'>
                                        <h6> Non associato a tutte le farmacie del gruppo </h6>
                                    </div>
                                </Col>
                                <Col md="1"></Col>
                                <Col md="4">
                                    <Button className="float-right" variant="primary" onClick={() => { setRenderp(3); setViewMode(false) }} >
                                        NUOVO PRODOTTO
                                    </Button>
                                    <Button className="float-right" style={{ marginRight: 10 }} variant="success" onClick={() => { btnInviaSelezionatiClick() }} disabled={selected && selected.length > 0 ? false : true} >
                                        INVIA SELEZIONATI
                                    </Button>

                                </Col>
                            </Row>
                        </div>


                        <NewTabella
                            idTabella='gest_prodotti_gruppo'
                            sync
                            colonne={columns}
                            dataSource={tabellaPrincipale}
                            filterRow
                            searchPanel={true}
                            onclickRow={() => null}
                            exportT={{
                                enabled: true,
                                fileName: "gest_prodotti_gruppo"
                            }}
                            activeSaver={true}
                            columnC={true}
                            onRowPrepared={onRowPrepared}
                            multyp
                            selectedValue={(datiRow: any) => setSelected(datiRow.selectedRowKeys)}

                        />

                    </>
                )
            case 2:

                return (
                    <GestProdGruppoDett
                        typeEdit={edit}
                        edit={objtipo}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        setIndietro={() => { setRenderp(1); ricarica() }}
                        typeInsert={false}
                        viewMode={viewMode}
                    >
                    </GestProdGruppoDett>
                )

            case 3:
                return (
                    <GestProdGruppoDett
                        typeEdit={edit}
                        edit={undefined}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        setIndietro={() => { setRenderp(1); ricarica() }}
                        typeInsert={true}
                        viewMode={viewMode}
                    >
                    </GestProdGruppoDett>
                )
            default:
                return (
                    <>
                        <DialogConferma
                            dialogIsOpen={popDel}
                            message={messDel}
                            functionNext={() => removeTipo()}
                            handleCloseConferma={() => { setPopDel(false); setIddell(0) }}
                        />

                        <DialogConferma
                            dialogIsOpen={popInv}
                            message={messInv}
                            functionNext={() => inviaProdotto()}
                            handleCloseConferma={() => { setPopInv(false); setIdinv(0) }}
                        />

                        <DialogLogInvioProd
                            dialogLogInvioProdOpen={popLogInv}
                            handleCloseDialogLogInvioProd={() => { setPopLogInv(false); setIdLogInv(0) }}
                            idProdottoGruppo={idLogInv}
                            message={messLogInv}
                            setDialogLogInvioProdOpen={setPopLogInv}
                        />

                        <DialogRiepilogoInvioProd
                            dialogRiepilogoInvioProdOpen={popRieInv}
                            handleCloseDialogRiepilogoInvioProd={() => { setPopRieInv(false); setIdRieInv([]) }}
                            idProdottoGruppo={idRieInv}
                            message={messRieInv}
                            codProd={codProdRieInv}
                            codEan={codEanRieInv}
                            descrizione={descrRieInv}
                            setDialogRiepilogoInvioProdOpen={setPopRieInv}
                            functionNext={() => inviaProdotto()}
                        />

                        {/*  <DialogVisuProd edit={objtipo} ricarica={() => ricarica()} close={() => { setOpenPopVisualProd(false); setObjtipo({}) }} open={openPopVisuaProd} farmacie={undefined} />

                        <DialogGestProdGruppo typeEdit={edit} edit={objtipo} ricarica={() => ricarica()} close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }} open={openPop} /> */}


                        <Loading isVisible={loading} />
                        <div className='title'>
                            <h3> GESTIONE PRODOTTI GRUPPO <span className='subt' ></span> </h3>
                        </div>
                        <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">

                            <Row>
                                <Col md="1">
                                    <div className='title2'>
                                        <h6> Legenda Colori: </h6>
                                    </div>
                                </Col>
                                <Col md="3">
                                    <div className='legAf1'>
                                        <h6 >  Associato a tutte le farmacie del gruppo </h6>
                                    </div>
                                </Col >
                                <Col md="3">
                                    <div className='legAf2'>
                                        <h6> Non associato a tutte le farmacie del gruppo </h6>
                                    </div>
                                </Col>
                                <Col md="1"></Col>
                                <Col md="4">
                                    <Button className="float-right" variant="primary" onClick={() => { setRenderp(3); setViewMode(false) }} >
                                        NUOVO PRODOTTO
                                    </Button>
                                    <Button className="float-right" style={{ marginRight: 10 }} variant="success" onClick={() => { btnInviaSelezionatiClick() }} disabled={selected && selected.length > 0 ? false : true} >
                                        INVIA SELEZIONATI
                                    </Button>
                                   
                                </Col>
                            </Row>
                            {/*<div className="col-md-8">*/}
                            {/*    <Row>*/}
                            {/*        <Col md="2">*/}
                            {/*            <div className='title2'>*/}
                            {/*                <h6> Legenda Colori: </h6>*/}
                            {/*            </div>*/}
                            {/*        </Col>*/}
                            {/*        <Col md="4">*/}
                            {/*            <div className='legAf1'>*/}
                            {/*                <h6 >  Associato a tutte le farmacie del gruppo </h6>*/}
                            {/*            </div>*/}
                            {/*        </Col >*/}
                            {/*        <Col md="4">*/}
                            {/*            <div className='legAf2'>*/}
                            {/*                <h6> Non associato a tutte le farmacie del gruppo </h6>*/}
                            {/*            </div>*/}
                            {/*        </Col>*/}

                            {/*    </Row>*/}

                            {/*</div>*/}
                            {/*<Row>*/}
                            {/*    <Col md="2">*/}
                            {/*        <Button className="float-right" variant="secondary" onClick={() => { inviaSelezionati() }} disabled={selected && selected.length > 0 ? false : true} >*/}
                            {/*            INVIA SELEZIONATI*/}
                            {/*        </Button>*/}
                            {/*    </Col>*/}
                            {/*    <Col md="2">*/}
                            {/*        <Button className="float-right" variant="primary" onClick={() => { setRenderp(3); setViewMode(false) }} >*/}
                            {/*            NUOVO PRODOTTOO*/}
                            {/*        </Button>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}

                        </div>


                        <NewTabella
                            idTabella='gest_prodotti_gruppo'
                            sync
                            colonne={columns}
                            dataSource={tabellaPrincipale}
                            filterRow
                            searchPanel={true}
                            onclickRow={() => null}
                            exportT={{
                                enabled: true,
                                fileName: "gest_prodotti_gruppo"
                            }}
                            activeSaver={true}
                            columnC={true}
                            onRowPrepared={ onRowPrepared}
                            multyp
                            selectedValue={(datiRow: any) => setSelected(datiRow.selectedRowKeys)}
                        />

                    </>
                )
        }

    }

  
   
    return render();
}

export default  GestProdGruppo