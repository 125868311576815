import React, { useEffect, useRef, useState } from "react";
import SelectBox from "devextreme-react/select-box";

export enum typeShow {
  euro,
  percento,
}

export interface cardC {
  testo?: string;
  subTesto?: string;
  valore?: string;
  subValore?: string;
  testoPop?: string;
  second?: boolean;
  starter?: boolean;
  typeShowValue?: typeShow;
  typeShowSubValue?: typeShow;
}

export const MenuFbiVendite = ({}) => {

  const selectBoxOptions = [
    {
      id: 1,
      text: "Analisi vendite sintetica",
      url: "/app/fbi/AnalisiVenditeSintetica",
    },
    {
      id: 2,
      text: "Cruscotto Vendite",
      url: "/app/fbi/CruscottoVendite",
    },
    {
      id: 3,
      text: "Analisi Dettaglio Vendite ",
      url: "/app/fbi/AnalisiDettaglioVendite",
    },
    {
      id: 4,
      text: "Analisi Vendite Operatori",
      url: "/app/fbi/AnalisiVenditeOperatore",
    },
    {
      id: 5,
      text: "Analisi Vendite fascia oraria",
      url: "/app/fbi/AnalisiVenditeFasciaOraria",
    },
    {
      id: 6,
      text: "Analisi Scontistica Vendite",
      url: "/app/fbi/AnalisiScontisticaVendite",
    },
    {
      id: 7,
      text: "Analisi Vendita con ricetta",
      url: "/app/fbi/AnalisiVenditeRicette",
    },
    {
      id: 8,
      text: "Analisi Vendita sotto margine",
      url: "/app/fbi/AnalisiVenditeSottoMargine",
    },
    {
      id: 9,
      text: "Analisi Margini Medi in Vendita",
      url: "/app/fbi/AnalisiMarginiMediVendita",
    },
    {
      id: 10,
      text: "FBI Stato Farmacia",
      url: "/app/fbi/StatoFarmacia",
    },
  ];

  const [value,setValue] = useState<number | null>()

  useEffect(() => {
    // Recupera l'URL corrente
    const currentUrl = new URL(window.location.href);
    var pathname = currentUrl.pathname;
    
    const pathSegments = currentUrl.pathname.split('/');
    const cruscottoDirezionale = pathSegments[pathSegments.length - 1];

    //alert(pathname)

    const index = selectBoxOptions.findIndex(option => option.url === pathname);

    
    if(index+1){
      setValue(index+1)
    }

    // Puoi ora gestire l'URL come desideri
  });


 

  const defaultValue = selectBoxOptions[1];

  const handleDropdownChange = (e: any) => {
    const urlToUse = selectBoxOptions[e.value-1].url
    setValue(e.value)
   window.open(urlToUse, "_self");
  };
  return (
    <div style={{ marginLeft: "-10px" }}>
      <label> Selezionare : </label>
      <div style={{ fontSize: "14px" }}>
        <SelectBox
          dataSource={selectBoxOptions}
          displayExpr="text"
          valueExpr="id"
          value={value}
          onValueChanged={handleDropdownChange}
        />
      </div>
    </div>
  );
};
