import React, { useState, useEffect, useMemo } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import { RadioGroup } from 'devextreme-react/radio-group'
import TabPanel, { Item } from 'devextreme-react/tab-panel'
import TextBox from "devextreme-react/text-box"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import NewTabella from "../../../widget/nuova_tabella"
import { AplicaValoreTO, CreaColonne } from '../../../funzioni/operazioniSuTabelle'
import { Loading } from '../../../widget/Notifications'


import "../style.css"
import { getCodiceFarmFromId } from "../../../funzioni/anagrafica"
import { ListaFarmacieInterface } from "../../../interfaces/farmacie"

interface DialogProps {
	open: boolean,
	edit: any,
	ricarica: () => void,
    close: () => void,
    lstFar: any[],
    datiFarmacie: ListaFarmacieInterface[],
    params: any;
}

export const DialogRiepGiace: React.FC<DialogProps> = ({ open, close, edit, ricarica, lstFar, datiFarmacie, params }) => {

    const auth = useAuth(urlConsultazione.auth)
    const [openD, setOpenD] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [giacenze, setGiacenze] = useState<any>([])
    //const [columns, setColumns] = useState<any>([])

    useEffect(() => {
        setOpenD(open)
        //if (open === true)
        //    caricaDati()
    }, [open])

    //useEffect(() => {
    //    if (open && auth) {
    //        caricaDati()
    //    }

    //}, [open, auth]);

    useEffect(() => {
        if (openD == true) {
            caricaDati()
        }

    }, [openD]);


    //const columns = [
    //    { caption: "MINSAN", dataField: "codProd", width: 100 },
    //    { caption: "Cod.EAN", dataField: "ean", width: 115 },
    //    { caption: "Descrizione ", dataField: "descEstesaProdotto" },
    //    { caption: "Ditta", dataField: "descDittaTitolare", width: 200 },
    //    { caption: "Giacenza", dataField: "giacenza", width: 100 },
    //    { caption: "Di Cui Invendibili", dataField: 'invendibili', width: 100 },
    //    { caption: "Prezzo Banca Dati", dataField: 'prezzoListino', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    //    { caption: "Prezzo farmacia", dataField: 'prezzoListino', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    //    { caption: "Costo ultimo m.p.", dataField: 'costoMedioPonderato', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    //    { caption: "Data costo ultimo m.p.", dataField: 'dataCostoAcquisto', format: "dd/MM/yyyy", dataType: "date", width: 100 },

    //];

    //const columns = [
    //    {
    //        "caption": "MINSAN",
    //        "dataField": "codProd",
    //        "width": 100
    //    },
    //    {
    //        "caption": "Cod.EAN",
    //        "dataField": "ean",
    //        "width": 115
    //    },
    //    {
    //        "caption": "Descrizione ",
    //        "dataField": "descEstesaProdotto"
    //    },
    //    {
    //        "caption": "Ditta",
    //        "dataField": "descDittaTitolare",
    //        "width": 200
    //    },
    //    {
    //        "caption": "Giacenza",
    //        "dataField": "giacenza",
    //        "width": 100
    //    },
    //    {
    //        "caption": "Di Cui Invendibili",
    //        "dataField": "invendibili",
    //        "width": 100
    //    },
    //    {
    //        "caption": "G.CSF1",
    //        "dataField": "farGia.17",
    //        "allowEditing": true,
    //        "dataType": "number",
    //        "alignment": "center",
    //        "width": 60
    //    },
    //    {
    //        "caption": "G.CSF2",
    //        "dataField": "farGia.18",
    //        "allowEditing": true,
    //        "dataType": "number",
    //        "alignment": "center",
    //        "width": 60
    //    },
    //    {
    //        "caption": "G.CSF4",
    //        "dataField": "farGia.5788",
    //        "allowEditing": true,
    //        "dataType": "number",
    //        "alignment": "center",
    //        "width": 60
    //    },
    //    {
    //        "caption": "G.CSF3",
    //        "dataField": "farGia.19",
    //        "allowEditing": true,
    //        "dataType": "number",
    //        "alignment": "center",
    //        "width": 60
    //    },
    //    {
    //        "caption": "G.CSF5",
    //        "dataField": "farGia.4796",
    //        "allowEditing": true,
    //        "dataType": "number",
    //        "alignment": "center",
    //        "width": 60
    //    },
    //    {
    //        "caption": "Prezzo Banca Dati",
    //        "dataField": "prezzoListino",
    //        "format": {
    //            "type": "currency",
    //            "precision": 2,
    //            "currency": "EUR"
    //        },
    //        "width": 100
    //    },
    //    {
    //        "caption": "Prezzo farmacia",
    //        "dataField": "prezzoListino",
    //        "format": {
    //            "type": "currency",
    //            "precision": 2,
    //            "currency": "EUR"
    //        },
    //        "width": 100
    //    },
    //    {
    //        "caption": "Costo ultimo m.p.",
    //        "dataField": "costoMedioPonderato",
    //        "format": {
    //            "type": "currency",
    //            "precision": 2,
    //            "currency": "EUR"
    //        },
    //        "width": 100
    //    },
    //    {
    //        "caption": "Data costo ultimo m.p.",
    //        "dataField": "dataCostoAcquisto",
    //        "format": "dd/MM/yyyy",
    //        "dataType": "date",
    //        "width": 100
    //    },
    //    {
    //        "caption": "EAN",
    //        "dataField": "ean",
    //        "allowEditing": false,
    //        "alignment": "center"
    //    }
    //]

    useEffect(() => {

        if (datiFarmacie && datiFarmacie.length > 0) {
            creaColonneDinamiche()
        }

    }, [datiFarmacie])
    

    const creaColonneDinamiche = () => {
        if (datiFarmacie && datiFarmacie.length > 0) {

        
            const primeColonne: any[] = [
                { caption: "MINSAN", dataField: "codProd", width: 100 },
                { caption: "Cod.EAN", dataField: "ean", width: 115 },
                { caption: "Descrizione ", dataField: "descEstesaProdotto" },
                { caption: "Ditta", dataField: "descDittaTitolare", width: 200 },
                { caption: "Giacenza", dataField: "giacenza", width: 100 },
                { caption: "Di Cui Invendibili", dataField: 'invendibili', width: 100 },
            ];

            //const ultimeColonne: any[] = [

            //    { caption: "Prezzo Banca Dati", dataField: 'prezzoListino', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
            //    { caption: "Prezzo farmacia", dataField: 'prezzoListino', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
            //    { caption: "Costo ultimo m.p.", dataField: 'costoMedioPonderato', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
            //    { caption: "Data costo ultimo m.p.", dataField: 'dataCostoAcquisto', format: "dd/MM/yyyy", dataType: "date", width: 100 },

            //]

            const aggiuntaColonneFarm = primeColonne;

            //console.log(lstFar)
            //if (lstFar && lstFar) {
            //    for (let i = 0; i < lstFar.length; i++) {
            //        const idFar =lstFar[i];
            //        let codice = getCodiceFarmFromId(datiFarmacie, idFar);
            //        aggiuntaColonneFarm.push(
            //            {
            //            caption: `G.${codice}`,
            //            dataField: `farGia.${idFar}`,
            //            allowEditing: true,
            //            dataType: "number",
            //            alignment: "center",
            //            width: 60
            //        }
            //        )


            //    }
            //}


            datiFarmacie.sort((a, b) => (a.CODICE > b.CODICE) ? 1 : ((b.CODICE > a.CODICE) ? -1 : 0))

                for (let i = 0; i < datiFarmacie.length; i++) {
                    const idFar = datiFarmacie[i].ID_FAR;
                    let codice = getCodiceFarmFromId(datiFarmacie, idFar);
                    aggiuntaColonneFarm.push(
                        {
                        caption: `G.${codice}`,
                        dataField: `farGia${idFar}`,
                        allowEditing: true,
                        dataType: "number",
                        alignment: "center",
                        width: 60
                    }
                    )
                }

            aggiuntaColonneFarm.push({ caption: "Prezzo Banca Dati", dataField: 'prezzoListino', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 });
            aggiuntaColonneFarm.push({ caption: "Prezzo farmacia", dataField: 'prezzoListino', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 });
            aggiuntaColonneFarm.push({ caption: "Costo ultimo m.p.", dataField: 'costoMedioPonderato', format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 });
            aggiuntaColonneFarm.push({ caption: "Data costo ultimo m.p.", dataField: 'dataCostoAcquisto', format: "dd/MM/yyyy", dataType: "date", width: 100 });

            //console.log(aggiuntaColonneFarm)
            return aggiuntaColonneFarm;
        }
    }

    
    const columns = creaColonneDinamiche()

    //const columns = CreaColonne(creaColonneDinamiche())

    const caricaDati = () => {
        setLoading(true)
            RestServices().rest('POST', params, urlConsultazione.getRiepilogoGiacenzaProdotti)
                .then(result => {
                    if (result?.esito) {
                        setGiacenze(result.response)
                        setLoading(false)
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })


    }

	return (

        <Popup
            visible={openD}
            showCloseButton={false}
            width={'75%'}
            height={'85%'}
            showTitle={true}
            title="Riepilogo giacenze"

        >
            <Loading isVisible={loading} />

            <NewTabella
                idTabella='riepilogo_giacenze'
                colonne={columns}
                dataSource={giacenze}
                filterRow
                exportT={{
                    enabled: true,
                    fileName: "riepilogo_giacenze"
                }}
            />

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Chiudi',
                    onClick: () => close(),
                }}
            />
        </Popup>

	)
}