import notify from "devextreme/ui/notify";
import React, { useEffect, useMemo, useState } from "react";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { DittaInterface, OggettoInterface, SettoriInterface } from "../../interfaces/generiche";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { DialogConferma, DialogConfermaPrezzoPromozione, Loading } from "../../widget/Notifications";
import { basenameCfPortal, basenameHost, basenameHostNewTabs, urlBancaDati, urlPrezzoPromozione } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { Selezione, SelezioneMultipla, TendinaFarm } from "../../widget/Select";
import Button from 'react-bootstrap/esm/Button';
import { DrawerFiltri } from "../../widget/Drawer";
import { BoxFiltro, BoxTestoCerca } from "../../widget/Editor";
import { RicercaProdInterface } from "../../interfaces/bancaDati";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { getCodiceFarmFromId } from "../../funzioni/anagrafica";
import { TabellaCompleta, TabellaOrdiniLiberi } from "../../widget/Tabelle";
import DialogAggiungiProdOrdine from '../../common/DialogAggiungiProdOrdine';
import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DialogDitta, DialogImpostazioniPrezzoPromozione, DialogUploadPrezzoPromozione } from "./Dialog";
import { custom } from "devextreme/ui/dialog";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";

const ElencoPrezzoPromozione = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const titolo = TitoloContenuto("Prezzi Promozione");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoPrezzoPromozione, setElencoPrezzoPromozione] = useState<1>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [openDrawer, setOpenDrawer] = useState(true);
    const [inputCerca, setInputCerca] = useState<string>("");
    const [searchBoxRefInstance, setSearchBoxRefInstance] = useState<any>();
    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [isDialogAddProdOpen, setIsDialogAddProdOpen] = useState<boolean>(false);
    const handleDialogAddProdClose = () => setIsDialogAddProdOpen(false);
    const handleDialogAddProdOpen = () => setIsDialogAddProdOpen(true);
    const svuotaDialogAddProd = () => setCercaNuovoProd([]);
    const [codCsf, setCodCsf] = useState<number>();
    const [colonnePrezzo, setColonnePrezzo] = useState<string[]>();
    const [ditte, setDitte] = useState<DittaInterface[]>();
    const [settori, setSettori] = useState<SettoriInterface[]>();
    const [descrizione, setDescrizione] = useState<string>();
    const [minsan, setMinsan] = useState<string>();
    const [ean, setEan] = useState<string>();
    const [messageConferma, setMessageConferma] = useState<string>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [isDialogImpostazioniOpen, setIsDialogImpostazioniOpen] = useState(false);
    const [isDialogImportaOpen, setIsDialogImportaOpen] = useState(false);
    const [isDialogDittaOpen, setIsDialogDittaOpen] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPageLoaded, setIsPageLoaded] = useState(false); //this helps
    const handleCloseDialogImpostazioni = () => { setIsDialogImpostazioniOpen(false); }
    const handleCloseDialogImporta = () => { setIsDialogImportaOpen(false); }
    const handleCloseDialogDitta = () => { setIsDialogDittaOpen(false); }
    const [prezzoRiferimentoNullo, setPrezzoRiferimentoNullo] = useState<boolean>();
    const [messageConfermaCopiaDaPrezzoPromozione, setMessageConfermaCopiaDaPrezzopromozione] = useState<string>();
    const [subMessageConfermaCopiaDaPrezzoPromozione, setSubMessageConfermaCopiaDaPrezzopromozione] = useState<string>();
    const [subMessage2ConfermaCopiaDaPrezzoPromozione, setSubMessage2ConfermaCopiaDaPrezzopromozione] = useState<string>();
    const [dialogIsOpenCopiaDaPrezzoPromozione, setDialogIsOpenCopiaDaPrezzoPromozione] = useState(false);
    const handleCloseConfermaCopiaDaPrezzoPromozione = () => setDialogIsOpenCopiaDaPrezzoPromozione(false);


    useEffect(() => {
        setIsLoaded(true);
    }, []);

    useEffect(() => {
        if (isLoaded) {
            setIsPageLoaded(true);
            cerca();
        }
    }, [isLoaded]);

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    function isNumber(n:any) {
        return !isNaN(parseFloat(n)) && !isNaN(n - 0);
    }


    useEffect(() => {

        //if (!settori || settori == undefined)
        //    RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
        //        if (result) setSettori(result);
        //    })
        //if (!ditte || ditte == undefined) {
        //    setIsVisible(true);
        //    RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
        //        if (result) {
        //            setIsVisible(false);
        //            setDitte(result);
        //        }
        //    })
        //}
        setFiltri({
            lstFar: props.datiFarmacie.map(function (object) { return object.ID_FAR })
        })

      //cerca();


    }, [props.datiFarmacie])

    //useEffect(() => {
    //    if (filtri.lstFar != undefined && filtri.lstFar.length > 0)
    //        cerca();
    //}, [])


    const cercaProdotto = (codCsfPram: number | undefined) => {

        if (filtri.lstFar == undefined || filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia." }, "error", 4000)
            return;
        }

        const param = {
            lstFar: filtri.lstFar,
            codCsf: codCsfPram,
        }

        setIsVisible(true);

        RestServices().rest('POST', param, urlPrezzoPromozione.getListPrezzoPromozione).then(result => {
            setIsVisible(false);
            if (result) {
                setElencoPrezzoPromozione(result.result);
                //setCodCsf(undefined);
            } else if (result !== true) {
                notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
            }
        })
        svuotaDialogAddProd();

    }

    const cerca = () => {

        if (filtri.lstFar == undefined || filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia." }, "error", 4000)
            return;
        }

        //if (filtri.idSettore == undefined && filtri.idDitta == undefined) {
        //    notify({ position: "center", width: "auto", message: "Selezionare uno tra ditta e settore" }, "error", 4000)
        //    return;
        //}
           

        const param = {
            lstFar: filtri.lstFar,
            //settore: filtri.idSettore != undefined ? filtri.idSettore : undefined,
            //ditta: filtri.idDitta != undefined ? filtri.idDitta[0] : undefined
        }
        setIsVisible(true);
           
        RestServices().rest('POST', param, urlPrezzoPromozione.getListPrezzoPromozione).then(result => {
                setIsVisible(false);
                if (result) {
                    setElencoPrezzoPromozione(result.result.lst);
                    setPrezzoRiferimentoNullo(result.result.prezzoRiferimentoNullo);
                    //setCodCsf(undefined);
                } else if (result !== true) {
                    notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
                }
        })
        svuotaDialogAddProd();
    }

    const insertCodProd = (codProd: string) => {
        setMinsan(codProd);
    }

    const insertDescProd = (descProd: string) => {
        setDescrizione(descProd);
    }

    const insertCodCsf = (codCsf: number) => {
        setCodCsf(codCsf);
    }

    const startRicercaProdInBD = () => {

        if (filtri.lstFar == undefined || filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia." }, "error", 4000)
            return;
        }

        if (inputCerca.length > 0) {
            setIsVisible(true);
            const parametriCerca = {
                testo: inputCerca,
                lstFar: undefined,
                soloInCommercio: false,
                soloInGiacenza: false
            }
            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    setIsVisible(false);
                    if (result.length === 1) {
                        if (result[0].codCsf) {
                           // setCodCsf(result[0].codCsf);
                            //setDescrizione(result[0].descrizione);
                            //setMinsan(result[0].minsan);
                            //cercaProdotto(result[0].codCsf);
                            nuovo(result[0].codCsf);
                            setCercaNuovoProd([]);
                        } else {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                        }
                    } else if (result.length > 1) {
                        setCercaNuovoProd(result);
                        handleDialogAddProdOpen();
                    } else {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                        setCercaNuovoProd([]);
                    }
                })
        }


    }

    const creaColonneDinamiche = () => {
        const primeColonne: any[] = [
            { caption: "Descrizione", dataField: "descrizione", allowEditing: false, width: 275 },
            { caption: "Cod. Prod.", dataField: "codProd", allowEditing: false},
            { caption: "Ean", dataField: "ean", allowEditing: false},
            { caption: "Cod. Csf", dataField: "codCsf", allowEditing: false, visible: false },
            { caption: "Ditta titolare", dataField: "ditta", allowEditing: false},
            { caption: "Ditta concessionaria", dataField: "dittaConcessionaria", allowEditing: false },
            { caption: "Gruppo merc.", dataField: "gruppoMerc", allowEditing: false },
            { caption: "Atc", dataField: "atc", allowEditing: false },
            { caption: "Settore", dataField: "settore", allowEditing: false},
        ];
        const aggiuntaColonneFarm = primeColonne;
        const colonnePrz: string[] = [];
        if (filtri.lstFar ) {
            for (let i = 0; i < filtri.lstFar.length; i++) {
                const idFar = filtri.lstFar[i];
                let codice = getCodiceFarmFromId(props.datiFarmacie, idFar);
                colonnePrz.push(`${idFar}`);
                aggiuntaColonneFarm.push({
                    caption: `Prz.${codice}`,
                    dataField: `farPre.${idFar}`,
                    allowEditing: false,
                    dataType: "number",
                    alignment: "center"
                })
            }
            setColonnePrezzo(colonnePrz);
        }
       
        aggiuntaColonneFarm.push({
            caption: " ", type: "buttons", width: 50, fixed: false, buttons: [{
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {
                    window.open(basenameHostNewTabs + "prezzoPromozione/PrezziPromozione?codCsf=" + e.row.data.codCsf + "&lstFar=" + filtri.lstFar);
                }
            }]
        })
        return aggiuntaColonneFarm;
    }

    const columnsPrezzoPromozione = useMemo(() => CreaColonne(creaColonneDinamiche()), [elencoPrezzoPromozione, filtri.lstFar])

    const propsTabPrezzoPomozione: TabellaCompletaInterface = {
        id: "elenco_prezzo_promozione",
        dataSource: elencoPrezzoPromozione,
        selection: "single",
        autoWidth: true,
        pagination: 20,
        headerFilterVisible: true,
        defaultFilterValue: null,
        export: {
            enabled: true,
            fileName: "Prezzo_Promozione"
        }
    }

    const insertProdotto = (codCsf: number| undefined) => {
        setCodCsf(codCsf);
        //cercaProdotto(codCsf);
        nuovo(codCsf);
    }

    const nuovo = (codCsf: number | undefined) => {

        if (filtri.lstFar == undefined || filtri.lstFar.length == 0) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia." }, "error", 4000)
            return;
        }

        if (codCsf == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare un prodotto." }, "error", 4000)
            return;
        }

        window.open(basenameHostNewTabs + "prezzoPromozione/PrezziPromozione?codCsf=" + codCsf + "&lstFar=" + filtri.lstFar + "&nuovo=true", '_blank');

    }

    const copiaDaRif = () => {

        //var keys = tabellaInstance.instance.getSelectedRowKeys();
        //if (keys.length == 0) {
        //    setIsVisible(false);
        //    notify({ position: "center", width: "auto", message: "Selezionare una riga" }, "error", 4000);
        //    return;
        //}

        //setMessageConferma("Si sta applicando il prezzo promozione della farmacia di riferimento alle altre farmacie per tutti i prodotti. Confermare?");
        if (prezzoRiferimentoNullo) {
            //let myDialog = custom({
            //    title: "Messaggio",
            //    messageHtml: "<b>Ci sono prodotti senza prezzo della farmacia di riferimento.<br>Premi Procedi per allineare comunque il prezzo alla farmacia di riferimento. <br> Premi Ignora per procedere non considerarando i prodotti senza prezzo della farmacia di riferimento </b>",
            //    buttons: [{
            //        text: "Procedi",
            //        onClick: (e: any) => {
            //            return { buttonText: e.component.option("text") }
            //        }
            //    },
            //    {
            //        text: "Ignora",
            //        onClick: (e: any) => {
            //            return { buttonText: e.component.option("text") }
            //        }
            //    },
            //    {
            //        text: "Annulla",
            //        onClick: (e: any) => {
            //            return { buttonText: e.component.option("text") }
            //        }
            //    },
            //    ]
            //});
            //myDialog.show().then((dialogResult: any) => {
            //    console.log(dialogResult.buttonText);
            //});
            //setMessageConfermaCopiaDaPrezzopromozione("Ci sono prodotti senza prezzo della farmacia di riferimento. Premi ''Procedi'' per allineare comunque il prezzo alla farmacia di riferimento. Premi ''Ignora'' per procedere non considerarando i prodotti senza prezzo della farmacia di riferimento");
            setMessageConfermaCopiaDaPrezzopromozione("Ci sono prodotti senza prezzo della farmacia di riferimento.");
            setSubMessageConfermaCopiaDaPrezzopromozione("Premi ''Procedi'' per allineare comunque il prezzo alla farmacia di riferimento.");
            setSubMessage2ConfermaCopiaDaPrezzopromozione("Premi ''Ignora'' per procedere non considerarando i prodotti senza prezzo della farmacia di riferimento");
            setDialogIsOpenCopiaDaPrezzoPromozione(true);
        }
        else {
            setMessageConferma("Si sta applicando il prezzo promozione della farmacia di riferimento alle altre farmacie per tutti i prodotti. Confermare?");
            setDialogIsOpen(true);
        }
        
        

        //setDialogIsOpen(true);

    }

    const apriLog = () => {

        window.open(basenameHostNewTabs + 'prezzoPromozione/LogPrezziPromozione', '_blank');

    }

    const apriImpostazioni = () => {
        setIsDialogImpostazioniOpen(true)
    }

    const apriImporta = () => {
        setIsDialogImportaOpen(true)
    }

    const apriDitta = () => {
        setIsDialogDittaOpen(true)
    }

    const apriInvia = () => {

        window.open(basenameHostNewTabs + "prezzoPromozione/InvioPrezziPromozione" , '_blank');

    }

    const functionNext = () => {

        setDialogIsOpen(false);
        functionNextCopiaDaPrezzoPromozione(2);

        //let data;
        //tabellaInstance.instance.getDataSource().store().load().done((allData: any) => { data = allData; });

        ////var a = tabellaInstance.instance.getSelectedRowsData();

        //setIsVisible(true);
        //const param = {
        //    row: data,
        //    lstFar: filtri.lstFar,
        //    tipo:tipo
        //}

        //RestServices().rest('POST', param, urlPrezzoPromozione.copiaDaRiferimento).then(result => {

        //    if (result) {
        //        if (result.esito == true) {
        //            setIsVisible(false);
        //            notify({ position: "center", width: "auto", message: "Operazione eseguita con successo" }, "success", 5000);
        //        }
        //        else if (result.esito == false) {
        //            setIsVisible(false);
        //            notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
        //        }

        //    }
        //})
    }

    const functionNextCopiaDaPrezzoPromozione = (tipo:any) => {
        
        //tipo 1 procedi , 2 ignora
        setDialogIsOpenCopiaDaPrezzoPromozione(false);

        let data;
        tabellaInstance.instance.getDataSource().store().load().done((allData: any) => { data = allData; });

        setIsVisible(true);
        const param = {
            row: data,
            lstFar: filtri.lstFar,
            tipo: tipo
        }

        RestServices().rest('POST', param, urlPrezzoPromozione.copiaDaRiferimento).then(result => {

            if (result) {
                if (result.esito == true) {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "Operazione eseguita con successo" }, "success", 5000);
                }
                else if (result.esito == false) {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: result.message }, "error", 5000);
                }

            }
        })
    }

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "data" && e.column && e.column.dataField) {
            if (e.column.dataField.includes(e.data.idFarRif)) {
                    e.cellElement.style.backgroundColor = "#FCFF92";
            }
        }

    }, [])

    const functionNextImpostazioni = () => {
        setIsDialogImpostazioniOpen(false); cerca();
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                {/*<DrawerFiltri id="paginaPrezzoPromozione" templateFiltri={templateFiltri} open={openDrawer} setOpen={setOpenDrawer} />*/}

                <Row>
                    <Col sm="6">
                        <label>Farmacia</label>
                        <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} valueGrid={filtri.lstFar}/>
                        {/*<SelezioneMultipla*/}
                        {/*    dataSource={props.datiFarmacie}*/}
                        {/*    id="lstFar"*/}
                        {/*        impostaFiltri={impostaFiltri}*/}
                        {/*    column={colonneGridGeneriche}*/}
                        {/*    selection="multiple"*/}
                        {/*    primaSelezionata={false}*/}
                        {/*    key="ID_FAR"*/}
                           
                        {/*/>*/}
                    </Col>
                    {/*<Col sm="3">*/}
                    {/*    <label>Ditta: </label>*/}
                    {/*    <SelezioneMultipla*/}
                    {/*        id="idDitta"*/}
                    {/*        dataSource={ditte}*/}
                    {/*        impostaFiltri={impostaFiltri}*/}
                    {/*        column={colonneGridGeneriche}*/}
                    {/*        selection="single"*/}
                    {/*        primaSelezionata={false}*/}
                    {/*        valueGrid={(filtri.idDitta) ? filtri.idDitta : []}*/}
                    {/*    ></SelezioneMultipla>*/}
                    {/*</Col>*/}
                    {/*<Col sm="3">*/}
                    {/*    <label>Settore: </label>*/}
                    {/*    <SelezioneMultipla*/}
                    {/*        id="idSettore"*/}
                    {/*        dataSource={settori}*/}
                    {/*        impostaFiltri={impostaFiltri}*/}
                    {/*        column={colonneGridGeneriche}*/}
                    {/*        selection="multiple"*/}
                    {/*        valueGrid={(filtri.idSettore) ? filtri.idSettore : []}*/}
                    {/*    ></SelezioneMultipla>*/}
                    {/*</Col>*/}
                    <Col sm='5'>
                    </Col>
                    <Col sm="1">
                        {/*<Button type="button" variant="success" id="btnCerca" onClick={inputCerca != undefined && inputCerca.length > 0 ? startRicercaProdInBD : cerca}>Cerca</Button>*/}
                        <Button type="button" variant="success" id="btnCerca" onClick={cerca}>Cerca</Button>
                    </Col>
                </Row>

                <div style={{ borderTop: "1px solid #756D6D ", marginLeft: 20, marginRight: 20, marginTop:20 }}></div>

                    <Row id="rigaRicercaProdottoPrezzoPromozione">
                        <Col sm='3' className="prodottoPrezzoPromozione">
                            <span>Ricerca prodotto per nuovo prezzo promozione (codice, EAN, descrizione)</span>
                        </Col>
                        <Col sm='3' className="boxCerca">
                            <BoxTestoCerca
                                placeholder=""
                                id="ricercaProdottiBD"
                                setInputCerca={setInputCerca}
                                startRicerca={startRicercaProdInBD}
                                inputCerca={inputCerca}
                                setSearchBoxRefInstance={setSearchBoxRefInstance}
                            />
                    </Col>
                    
                        <Col sm='3'>
                            {/*<BoxFiltro id="descrizione" impostaFiltri={impostaFiltri} value={descrizione} disabled={true} />*/}
                        </Col>
                    <Col sm='1'>
                        {/*<BoxFiltro id="minsan" impostaFiltri={impostaFiltri} value={minsan} disabled={true} />*/}
                    </Col>
                    <Col sm='1'>
                         {/*<BoxFiltro id="codCsf" impostaFiltri={impostaFiltri} value={codCsf} visible={true} disabled={true} /> */}
                    </Col>
                    <Col sm='1'>
                        {/*<Button type="button" variant="success" id="btnNuovo" >Nuovo</Button>*/}
                    </Col>
                </Row>
                {/*<Row id="rigaBottoniProdottoPrezzoPromozione">
                    <Col sm='1'>
                        <Button type="button" variant="primary" id="btnCopiaRif" onClick={copiaDaRif}>Copia da Farm. rif.</Button>
                    </Col>
                    <Col sm='1'>
                        <Button type="button" variant="primary"  onClick={apriImporta}>Importa</Button>
                    </Col>
                    <Col sm='1'>
                        <Button type="button" variant="primary"  onClick={apriImpostazioni}>Impostazioni</Button>
                    </Col>
                    <Col sm='7'></Col>
                    <Col sm='1'>
                        <Button type="button" variant="primary" id="btnLog"   onClick={apriLog}>Storico invii</Button>
                    </Col>
                    <Col sm='1'>
                        <Button type="button" variant="success"  onClick={apriInvia}>Invia</Button>
                    </Col>
                </Row>*/}
                <Row id="rigaBottoniProdottoPrezzoPromozione">
                    <div className="col-md-8 text-left">
                        <div className="btn-group" role="group">
                            <Button type="button" variant="primary" id="btnCopiaRif" onClick={copiaDaRif}>Copia da Farm. rif.</Button>
                            <Button type="button" variant="primary" onClick={apriImporta}>Importa</Button>
                            <Button type="button" variant="primary" onClick={apriDitta}>Applica sconto su ditta</Button>
                            <Button type="button" variant="primary" onClick={apriImpostazioni}>Impostazioni</Button>
                            
                        </div>
                    </div>
                    <div className="col-md-4 text-right">
                        <div className="btn-group" role="group">
                            <Button type="button" variant="primary" id="btnLog" onClick={apriLog}>Storico invii</Button>
                            <Button type="button" variant="success" id="btnInviaPrezziPromozione"  onClick={apriInvia}>Invia</Button>
                        </div>
                    </div>
                </Row>

                <Row id="rigaLegendaPrezzoPromozione">
                    <Col sm="6">
                        <div className="leftcontent">
                            <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                            &nbsp;
                            <span id="lblFarmaciaRiferimento" title="Farmacia di riferimento" className="lblFarmaciaRiferimento">Farmacia di riferimento</span>
                            &nbsp;

                        </div>
                    </Col>
                </Row>
                <Row >
                    <Col sm='12' className="tabellaFornitori">

                        {/*    <TabellaOrdiniLiberi*/}
                        {/*        propsTabella={propsTabPrezzoPomozione}*/}
                        {/*        //onRowUpdating={onRowUpdating}*/}
                        {/*        setTabellaInstance={setTabellaInstance}*/}
                        {/*        columns={columnsPrezzoPromozione}*/}
                        {/*        enterKeyDirection="row"*/}
                        {/*        onCellPrepared={onCellPrepared}*/}
                        {/*        //onContentReady={onContentReady}*/}
                        {/*        //onCellClick={onCellClick}*/}
                        {/*        //onFocusedCellChanged={onFocusedCellChanged}*/}
                        {/*        //onKeyDown={onKeyDown}*/}
                        {/*        //selectedRowKeys={selectedRowKeys}*/}
                        {/*        //onEditingStart={onEditingStart}*/}
                        {/*    //onRowPrepared={onRowPrepared}*/}
                        {/*/>*/}

                        <TabellaCompleta
                            propsTabella={propsTabPrezzoPomozione}
                            //onRowUpdating={onRowUpdating}
                            setTabellaInstance={setTabellaInstance}
                            columns={columnsPrezzoPromozione}
                            enterKeyDirection="row"
                            onCellPrepared={onCellPrepared}
                        //onContentReady={onContentReady}
                        //onCellClick={onCellClick}
                        //onFocusedCellChanged={onFocusedCellChanged}
                        //onKeyDown={onKeyDown}
                        //selectedRowKeys={selectedRowKeys}
                        //onEditingStart={onEditingStart}
                        //onRowPrepared={onRowPrepared}
                        />
                        
                    </Col>
                </Row>

            </div>

            <DialogAggiungiProdOrdine
                isOpen={isDialogAddProdOpen}
                handleClose={handleDialogAddProdClose}
                svuotaDialog={svuotaDialogAddProd}
                cercaNuovoProd={cercaNuovoProd}
                testoRicerca={inputCerca}
                farmSelez={filtri.lstFar}
                insertProdotto={insertProdotto}
                insertCodProd={insertCodProd}
                insertDescProd={insertDescProd}
            />

            <DialogConferma
                dialogIsOpen={dialogIsOpen}
                message={messageConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />

            <DialogConfermaPrezzoPromozione
                dialogIsOpen={dialogIsOpenCopiaDaPrezzoPromozione}
                message={messageConfermaCopiaDaPrezzoPromozione}
                functionNext={functionNextCopiaDaPrezzoPromozione}
                handleCloseConferma={handleCloseConfermaCopiaDaPrezzoPromozione}
                subMessage={subMessageConfermaCopiaDaPrezzoPromozione}
                subMessage2={subMessage2ConfermaCopiaDaPrezzoPromozione}
            />

            <DialogImpostazioniPrezzoPromozione
                isDialogImpostazioniPrezzoPromozione={isDialogImpostazioniOpen}
                setIsDialogImpostazioniPrezzoPromozione={setIsDialogImpostazioniOpen}
                handleCloseDialogImpostazioniPrezzoPromozione={handleCloseDialogImpostazioni}
                datiFarmacie={props.datiFarmacie}
                functionNext={functionNextImpostazioni}
            />

            <DialogUploadPrezzoPromozione
                isDialogUploadPrezzoPromozione={isDialogImportaOpen}
                datiFarmacie={props.datiFarmacie}
                setIsDialogUploadPrezzoPromozione={setIsDialogImportaOpen}
                handleCloseDialogUploadPrezzoPromozione={handleCloseDialogImporta}
                setIsVisible={setIsVisible}
            />
            <DialogDitta
                isDialogDitta={isDialogDittaOpen}
                datiFarmacie={props.datiFarmacie}
                setIsDialogDitta={setIsDialogDittaOpen}
                handleCloseDialogDitta={handleCloseDialogDitta}
                setIsVisible={setIsVisible}
                impostaFiltri={impostaFiltri}
                filtri={filtri }
            />
        </>
    )
}

export default ElencoPrezzoPromozione;


