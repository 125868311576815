import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Chart, PieChart } from "devextreme-react";
import {
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Series,
  Subtitle,
  Title,
  Tooltip,
} from "devextreme-react/chart";
import {
  Font,
  Format,
  HoverStyle,
  SmallValuesGrouping,
} from "devextreme-react/pie-chart";
import notify from "devextreme/ui/notify";
import React, { useEffect, useRef, useState } from "react";
//import { format } from "util";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
//import { GruppoMerceologicoInterface } from "../../../interfaces/generiche";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, {
  ConvertForSelect,
} from "../../dashboards/filters/FiltriVerticaleNewVersione";
// import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { createItemFilterVendite } from "./filtri/ItemFilters";

import { Filtri } from "../../consultazione/interface";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
//import { TitoloContenuto } from "../../../struttura_pagina/TitoloContenuto";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Popup } from "devextreme-react/popup";
import { AplicaValoreTO } from "../../../funzioni/operazioniSuTabelle";
import { filterActionexten } from "../filters/esterActions";
import { filtersCreate } from "./filtri/filtriLiteAcquisti";
import { trasform_no_marge } from "../util/trasformation";
import ".././style.css";

const dashboard2 = { dashboardID: 2 };

const BusinessLite = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const chartRef = useRef<any>(null);
  // ??? const [chartKey, setChartKey] = useState(false);

  const [chartDataMargine, setChartDataMargine] = useState<any>([]);
  // ??const chartRefMargine = useRef<any>(null);
  // ??const [chartKeyMargine, setChartKeyMargine] = useState(false);

  const [chartDataNumeroVend, setChartDataNumeroVend] = useState<any>([]);
  // ??const chartRefNumeroVend = useRef<any>(null);
  // ??const [chartKeyNumeroVend, setChartKeyNumeroVend] = useState(false);

  const [chartDataPezzi, setChartDataPezzi] = useState<any>([]);
  //??const chartRefPezzi = useRef<any>(null);
  //?? const [chartKeyPezzi, setChartKeyPezzi] = useState(false);

  const chartRefDitta = useRef<any>(null);
  //??const [chartKeyDitta, setChartKeyDitta] = useState(false);

  const [chartDataVNSPie, setChartDataVNSPie] = useState<any>([]);
  const [chartDataSSNPie, setChartDataSSNPie] = useState<any>([]);
  const [chartDataATCPie, setChartDataATCPPie] = useState<any>([]);
  const [chartDataDittaPie, setChartDataDittaPie] = useState<any>([]);
  //?? const [chartDataOperatori, setChartDataOperatori] = useState<any>([]);

  //?? const chartRefMVNSPie = useRef<any>(null);

  const [totalValue, setTotalValue] = useState(0);
  const [totalValueMargine, setTotalValueMargine] = useState(0);
  const [totalValueNumeroVend, setTotalValueNumeroVend] = useState(0);
  const [totalValuePezzi, setTotalValuePezzi] = useState(0);

  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);

  //?? const visualRange = { startValue: 300, endValue: 500 };

  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);

  //?? const titolo = TitoloContenuto("BusinessLab Lite - Analisi venduto");
  const [settori, setSettori] = useState<any[]>([]);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const [codiciAtc, setCodiciAtc] = useState<any[]>([]);
  const [ditte, setDitte] = useState<any[]>([]);
  const [operatori, setOperatori] = useState<any[]>([]);
  //?? const [annimesi, setAnniMesi] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePopOne, setVisiblePopOne] = useState(false);
  // ?nconst [visiblePopTwo, setVisiblePopTwo] = useState(false);
  const [visiblePopPie, setVisiblePopPie] = useState(false);
  const [visiblePopPietwo, setVisiblePopPietwo] = useState(false);
  const [visiblePopPiethree, setVisiblePopPiethree] = useState(false);
  const [visiblePopPiefour, setVisiblePopPiefour] = useState(false);

  const getCall = (params: any, parameters: any) => {
    setIsVisible(true);
    if (authReport) {
      const dashboardCall2 = {
        ...dashboard2,
        idFarList: params.idFar,
        params: parameters,
      };

      console.log(dashboardCall2);
      RestServices()
        .rest("POST", dashboardCall2, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {
            setChartData(result["13"]);
            setChartDataMargine(result["14"]);
            setChartDataNumeroVend(result["15"]);
            setChartDataPezzi(result["16"]);

            // ID 17
            const chartVNSPie = result["17"]
              .map(
                (item: {
                  PRO_COD_SETTORE_INVENTARIO: { toString: () => any };
                  VENDUTO_NETTO_SCONTI: any;
                  PRO_DESC_SETTORE_INVENTARIO: string;
                }) => {
                  const codSettoreInventario = item.PRO_COD_SETTORE_INVENTARIO
                    ? item.PRO_COD_SETTORE_INVENTARIO.toString()
                    : "";
                  const value = item.VENDUTO_NETTO_SCONTI;
                  const desc = item.PRO_DESC_SETTORE_INVENTARIO
                    ? codSettoreInventario +
                    " - " +
                    item.PRO_DESC_SETTORE_INVENTARIO
                    : "N.D";
                  return {
                    PRO_COD_SETTORE_INVENTARIO: codSettoreInventario,
                    VENDUTO_NETTO_SCONTI: value,
                    PRO_DESC_SETTORE_INVENTARIO: desc,
                  };
                }
              )
              .reverse();

            // ID 18
            const chartSSNPie = result["18"]
              .map((item: any) => {
                const isSSN = item.VEN_FLAG_SSN;
                const value = item.VENDUTO_NETTO_SCONTI;

                return {
                  SSN: isSSN,
                  VENDUTO_NETTO_SCONTI: value,
                  SSNDESC: isSSN ? "SSN" : "Libera",
                };
              })
              .reverse();

            // ID 19
            const chartATCPie = result["19"]
              .map((item: any) => {
                const atc = item.PRO_COD_ATC1;
                const desc = item.PRO_DESC_ATC1;
                const value = item.VENDUTO_NETTO_SCONTI;

                let final = atc + " - " + desc;
                if (atc === undefined) {
                  final = "N.D";
                }

                return {
                  ATC: atc,
                  ATC_DESC: final,
                  VENDUTO_NETTO_SCONTI: value,
                };
              })
              .reverse();

            // ID 20
            let datiPrimaTabelle = result["20"];

            // ID 21
            const chartDataDitta = result["21"].map((item: any) => {
              const venduto = item.VENDUTO_NETTO_SCONTI;
              const ditta = item.PRO_DESC_DITTA_TITOLARE;

              return {
                VENDUTO_NETTO_SCONTI: venduto,
                PRO_DESC_DITTA_TITOLARE: ditta,
              };
            });

            // 1 chart id 13

            const total = result["13"]
              .slice(12, 23)
              .reduce(
                (acc: number, item: any) => acc + item.VENDUTO_NETTO_SCONTI,
                0
              );
            const formattedTotal = total.toLocaleString("it-IT", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            });

            setTotalValue(formattedTotal);

            // 2 chart id 14

            const total2 = result["14"]
              .slice(12, 23)
              .reduce((acc: number, item: any) => acc + item.MARGINE_TOTALE, 0);
            const formattedTotalMargine = total2.toLocaleString("it-IT", {
              style: "currency",
              currency: "EUR",
              minimumFractionDigits: 0,
            });

            setTotalValueMargine(formattedTotalMargine);

            // 3 chart id 15
            const total3 = result["15"]
              .slice(12, 23)
              .reduce(
                (acc: number, item: any) => acc + item.NUMERO_VENDITE_TOTALE,
                0
              );
            const formattedTotalNumeroVend = total3.toLocaleString("it-IT", {
              minimumFractionDigits: 0,
            });

            setTotalValueNumeroVend(formattedTotalNumeroVend);

            // 4 chart id 16

            const total4 = result["16"]
              .slice(12, 23)
              .reduce(
                (acc: number, item: any) => acc + item.TOTALE_PEZZI_VENDUTI,
                0
              );
            const formattedTotalPezzi = total4.toLocaleString("it-IT", {
              minimumFractionDigits: 0,
            });

            setTotalValuePezzi(formattedTotalPezzi);

            // 1 chartPie ID 17

            const total5 = chartVNSPie.reduce(
              (sum: any, item: { VENDUTO_NETTO_SCONTI: any }) =>
                sum + item.VENDUTO_NETTO_SCONTI,
              0
            );

            const groupedChartVNSPie = chartVNSPie.reduce(
              (
                result: any[],
                current: {
                  PRO_DESC_SETTORE_INVENTARIO: any;
                  VENDUTO_NETTO_SCONTI: any;
                }
              ) => {
                const existingItemIndex = result.findIndex(
                  (item) =>
                    item.PRO_DESC_SETTORE_INVENTARIO ===
                    current.PRO_DESC_SETTORE_INVENTARIO
                );
                if (existingItemIndex !== -1) {
                  result[existingItemIndex].VENDUTO_NETTO_SCONTI +=
                    current.VENDUTO_NETTO_SCONTI;
                } else {
                  result.push({ ...current });
                }
                return result;
              },
              []
            );

            const totalGrouped = groupedChartVNSPie.reduce(
              (sum: any, item: { VENDUTO_NETTO_SCONTI: any }) =>
                sum + item.VENDUTO_NETTO_SCONTI,
              0
            );

            const chartVNSPieConPercentuale = groupedChartVNSPie.map(
              (item: { VENDUTO_NETTO_SCONTI: number }) => {
                const percentuale = (
                  (item.VENDUTO_NETTO_SCONTI / totalGrouped) *
                  100
                ).toFixed(2);
                return {
                  ...item,
                  PERCENTUALE: parseFloat(percentuale),
                };
              }
            );

            setChartDataVNSPie(chartVNSPieConPercentuale);

            // 2 chartPie ID 18

            const total6 = chartSSNPie.reduce(
              (sum: any, item: { VENDUTO_NETTO_SCONTI: any }) =>
                sum + item.VENDUTO_NETTO_SCONTI,
              0
            );

            // Aggiunta della percentuale per ogni valore
            const chartSSNPieConPercentuale = chartSSNPie.map(
              (item: { VENDUTO_NETTO_SCONTI: number }) => {
                // Calcolo della percentuale per ogni valore
                const percentuale = (
                  (item.VENDUTO_NETTO_SCONTI / total6) *
                  100
                ).toFixed(2);

                return {
                  ...item,
                  PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
                };
              }
            );

            setChartDataSSNPie(chartSSNPieConPercentuale);

            // 3 chartPie ID 19

            const total7 = chartATCPie.reduce(
              (sum: any, item: { VENDUTO_NETTO_SCONTI: any }) =>
                sum + item.VENDUTO_NETTO_SCONTI,
              0
            );

            // Aggiunta della percentuale per ogni valore
            const chartATCPieConPercentuale = chartATCPie.map(
              (item: { VENDUTO_NETTO_SCONTI: number }) => {
                // Calcolo della percentuale per ogni valore
                const percentuale = (
                  (item.VENDUTO_NETTO_SCONTI / total7) *
                  100
                ).toFixed(2);

                return {
                  ...item,
                  PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
                };
              }
            );

            setChartDataATCPPie(chartATCPieConPercentuale);

            // 1 tabella ID 20

            setValoriTabella1(datiPrimaTabelle);

            // chart infondo ID 21

            setChartDataDittaPie(chartDataDitta);

            setIsVisible(false);
            setParamsFilter(params);
            setIsFiltersExpanded(false);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore Insalata" + error,
            },
            "error",
            4000
          );
        });
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });

      RestServices()
        .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
        .then((result) => {
          if (result.esito) {
            const operatori = result.response.map(
              (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                return {
                  ...ope,
                  id: `${ope.operatore}`,
                  descrizione: `${ope.operatore}`,
                  codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                };
              }
            );
            setOperatori(operatori);
          }
        });
    }
  };

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  /*end */

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  useEffect(() => {
    filtersCreate(
      auth,
      (x) => setGruppoMercFilters(x),
      (x) => setSettori(x),
      (x) => setCodiciAtc(x),
      (x) => setDitte(x),
      (x) => setTipologiaVendita(x)
    );
  }, [auth]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };

  // const itemsFields = createItemFilterVendite(
  //   newFarmaList,
  //   operatori,
  //   settori,
  //   codiciAtc,
  //   tipologiaVendita,
  //   gruppoMercFilters,
  //   ditte
  // );

  const itemsFields = [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
    {
      type: "selectWithLevels",
      id: "atc",
      label: "Codice ATC",
      displayExpr: "descrizione",
      dataSource: codiciAtc,
      idValueGrid: "atc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
      allowSelectAll: false,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
  ];

  const columnsOperatori = [
    { caption: "Operatore", dataField: "OPERATORE_USERNAME", width: "auto" },
    { caption: "Farmacia", dataField: "FARM_DESCRIZIONE", width: "auto" },
      { caption: "Pezzi venduti", dataField: "QUANTITA", sortOrder: "desc", width: "auto" },
    {
      caption: "Incidenza % valore venduto",
      dataField: "INCIDENZA_VENDUTO_LORDO_SCONTI",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto lordo sconti",
      dataField: "VENDUTO_LORDO_SCONTI",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto prodotto",
      dataField: "SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto netto sconti",
      dataField: "VENDUTO_NETTO_SCONTI",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Altro importo",
      dataField: "ALTRO_IMPORTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile",
      dataField: "IMPONIBILE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine percentuale",
        dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  const popVisible = (testo: string) => {
    setVisiblePop(true);
  };

  const popVisibleOne = (testo: string) => {
    setVisiblePopOne(true);
  };

  const popVisiblePie = (testo: string) => {
    setVisiblePopPie(true);
  };

  const popVisiblePietwo = (testo: string) => {
    setVisiblePopPietwo(true);
  };
  const popVisiblePiethree = (testo: string) => {
    setVisiblePopPiethree(true);
  };
  const popVisiblePiefour = (testo: string) => {
    setVisiblePopPiefour(true);
  };


  let messOne = ["Valore del venduto per tipologia"];
  let messTwo = ["Valore del venduto per tipologia"];
  let messPie1 = [
    "Valore del venduto per settore inventario:Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione del settore inventario. Il settore inventario è una classificazione di banca dati CSF.",
  ];
  let messPie2 = [
    "Valore del venduto per tipologia di vendita: Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione della tipologia di vendita del prodotto. Le due tipologie di erogazione del prodotto considerate sono: -Sistema Sanitario Nazionale;-Vendita libera.Non è presente una voce mista poiché il costrutto alla base della torta è il prodotto e non l'aggregato vendita. Valore del venduto:Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
  ];
  let messPie3 = [
    "Valore del venduto per settore inventario:Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione del settore inventario. Il settore inventario è una classificazione di banca dati CSF. ",
  ];
  let messLast = [
    "Valore del venduto per settore inventario: Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione del settore inventario. Il settore inventario è una classificazione di banca dati CSF. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. ",
  ];

  return (
    <section
      style={{
        display: "flex",
        justifyContent: "space-between",
        height: "115",
        paddingBottom: 0,
        overflow: "auto"
      }}
    >
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 36,
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{
          width: "100%",
          overflow: "auto",
          minHeight: "auto",   
          maxHeight: "130vh",   
          paddingBottom: "20px", 

            
          }}
        >
          <div
            style={{
              fontSize: "14px",
              alignItems: "left",
              margin: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h2>BusinessLab Lite - Analisi Vendite</h2>
          </div>

            {/* andamento Valore del venduto netto sconti*/}
            <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>

            <div style={{ display: "flex" }}>
              <div style={{ width: "95%", margin: "10px" }}>
                <Chart dataSource={chartData} ref={chartRef}>
                  <CommonSeriesSettings
                    argumentField="MESE"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                    barPadding={0.2}

                  >
                    <Label visible={true}>
                      <Format type="currency" currency="EUR" precision={0} />
                    </Label>
                  </CommonSeriesSettings>

                  <Series
                    valueField="VENDUTO_PRECEDENTE"
                    type="line"
                    name="2022/2023"
                    color="rgb(215, 140, 245)">

                 <Label verticalOffset={-20}></Label>

                 </Series>
                  

                  <Series
                    valueField="VENDUTO_NETTO_SCONTI"
                    type="bar"
                    argumentField="MESE"
                    color="rgb(25, 231, 255)"
                    name="2023/2024">

                  <Label verticalOffset={80}> </Label>

                </Series>

                <Tooltip
                    enabled={true}
                    customizeTooltip={({
                        value,
                        argument,
                    }: {
                        value: any;
                        argument: any;
                    }) => {
                        const formattedValue = parseFloat(value).toLocaleString(
                            "it-IT",
                            {
                                style: "currency",
                                currency: "EUR",
                                minimumFractionDigits: 2,
                            }
                        );

                        return {
                            text: `Venduto: ${formattedValue}`,
                        };
                    }}
                />

                  <Title text="Andamento valore del venduto netto sconti - ultimi 12 mesi rolling">
                    <Font color="#5b6372" size="16" weight="400" />
                    <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                      <Font color="#5b6372" size="16" weight="400" />
                    </Subtitle>
                  </Title>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  ></Legend>
                  <Export fileName="venduto_netto_sconti" enabled={true} />
                </Chart>
              </div>

              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisible("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>
            </div>
            {chartDataMargine.length > 0 && (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginTop: 5,
                        paddingBottom: 5
                    }}
                >
                    <h2
                        style={{
                            color: "#5b6372",
                            fontFamily: "Arial, sans-serif",
                        }}
                    >
                        Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValue}</span>
                    </h2>
                </div>
            )}
        </div>
          
          {/* andamento pezzi acquistati*/}
        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>

            <div style={{ display: "flex" }}>
              <div style={{ width: "95%", margin: "10px" }}>
                <Chart dataSource={chartDataMargine} ref={chartRef}>
                  <CommonSeriesSettings
                    argumentField="MESE"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                    barPadding={0.2}

                  >
                    <Label visible={true}>
                      <Format type="currency" currency="EUR" precision={0} />
                    </Label>
                  </CommonSeriesSettings>

                  <Series
                    valueField="MARGINE_PRECEDENTE"
                    type="Line"
                    name="2022/2023"
                    color="rgb(215, 140, 245)">

                  <Label visible={true} verticalOffset={-20}> </Label>

                  </Series>
                  

                  <Series
                    valueField="MARGINE_TOTALE"
                    type="bar"
                    argumentField="MESE"
                    color="rgb(25, 231, 255)"
                    name="2023/2024">

                  <Label visible={true} verticalOffset={80}> </Label>

                  </Series>

                  <Tooltip
                      enabled={true}
                      customizeTooltip={({
                          value,
                          argument,
                      }: {
                          value: any;
                          argument: any;
                      }) => {
                          const formattedValue = parseFloat(value).toLocaleString(
                              "it-IT",
                              {
                                  style: "currency",
                                  currency: "EUR",
                                  minimumFractionDigits: 2,
                              }
                          );

                          return {
                              text: `Venduto: ${formattedValue}`,
                          };
                      }}
                  />
                  
                  <Title text="Andamento margine - ultimi 12 mesi rolling">
                    <Font color="#5b6372" size="16" weight="400" />
                    <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                      <Font color="#5b6372" size="16" weight="400" />
                    </Subtitle>
                  </Title>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  ></Legend>
                  <Export fileName="margine" enabled={true} />
                </Chart>
              </div>

              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisibleOne("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>
           </div>
           {chartDataMargine.length > 0 && (
               <div
                   style={{
                       width: "100%",
                       display: "flex",
                       alignItems: "center",
                       flexDirection: "column",
                       justifyContent: "center",
                       marginTop: 5,
                       paddingBottom: 5
                   }}
               >
                   <h2
                       style={{
                           color: "#5b6372",
                           fontFamily: "Arial, sans-serif",
                       }}
                   >
                       Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueMargine}</span>
                   </h2>
               </div>
           )}
        </div>

        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>

            <div style={{ display: "flex" }}>
              <div style={{ width: "95%", margin: "10px" }}>
                <Chart dataSource={chartDataNumeroVend} ref={chartRef}>
                  <CommonSeriesSettings
                    argumentField="MESE"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                    barPadding={0.2}

                  >
                    <Label visible={true}>
                      <Format precision={0} />
                    </Label>
                  </CommonSeriesSettings>

                  <Series
                    valueField="NUMERO_VENDITE_PRECEDENTE"
                    type="line"
                    name="2022/2023"
                    color="rgb(215, 140, 245)"
                  />

                  <Series
                    valueField="NUMERO_VENDITE_TOTALE"
                    type="bar"
                    argumentField="MESE"
                    color="rgb(25, 231, 255)"
                    name="2023/2024">

                  <Label visible={true} verticalOffset={110}> </Label>

                  </Series>

                  <Tooltip
                      enabled={true}
                      customizeTooltip={({
                          value,
                          argument,
                      }: {
                          value: any;
                          argument: any;
                      }) => {
                          const formattedValue = parseFloat(value).toLocaleString(
                              "it-IT",
                              {
                                  minimumFractionDigits: 0,
                              }
                          );
                
                          return {
                              text: `Vendite: ${formattedValue}`,
                          };
                      }}
                  />

                 
                  <Title text="Andamento vendite - ultimi 12 mesi rolling">
                    <Font color="#5b6372" size="16" weight="400" />
                    <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                      <Font color="#5b6372" size="16" weight="400" />
                    </Subtitle>
                  </Title>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  ></Legend>
                  <Export fileName="vendite" enabled={true} />
                </Chart>
              </div>

              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisibleOne("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>
            </div>
            {chartDataNumeroVend.length > 0 && (
               <div
                   style={{
                       width: "100%",
                       display: "flex",
                       alignItems: "center",
                       flexDirection: "column",
                       justifyContent: "center",
                       marginTop: 5,
                       paddingBottom: 5
                   }}
               >
                   <h2
                       style={{
                           color: "#5b6372",
                           fontFamily: "Arial, sans-serif",
                       }}
                   >
                       Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueNumeroVend}</span>
                   </h2>
               </div>
           )}
        </div>

        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>

            <div style={{ display: "flex" }}>
              <div style={{ width: "95%", margin: "10px" }}>
                <Chart dataSource={chartDataPezzi} ref={chartRef}>
                  <CommonSeriesSettings
                    argumentField="MESE"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                    barPadding={0.2}

                  >
                    <Label visible={true}>
                      <Format precision={0} />
                    </Label>
                  </CommonSeriesSettings>

                  <Series
                    valueField="TOTALE_PEZZI_VENDUTI_PRECEDENTE"
                    type="line"
                    name="2022/2023"
                    color="rgb(215, 140, 245)"
                  />

                  <Series
                    valueField="TOTALE_PEZZI_VENDUTI"
                    type="bar"
                    argumentField="MESE"
                    color="rgb(25, 231, 255)"
                    name="2023/2024">

                    <Label visible={true} verticalOffset={80}> </Label>

                  </Series>

                  <Tooltip
                      enabled={true}
                      customizeTooltip={({
                          value,
                          argument,
                      }: {
                          value: any;
                          argument: any;
                      }) => {
                          const formattedValue = parseFloat(value).toLocaleString(
                              "it-IT",
                              {
                                  minimumFractionDigits: 0,
                              }
                          );
                          return {
                              text: `Pezzi venduti: ${formattedValue}`,
                          };
                      }}
                  />
                  
                 
                  <Title text="Andamento pezzi venduti - ultimi 12 mesi rolling">
                    <Font color="#5b6372" size="16" weight="400" />
                    <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                      <Font color="#5b6372" size="16" weight="400" />
                    </Subtitle>
                  </Title>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  ></Legend>
                  <Export fileName="pezzi_venduti" enabled={true} />
                </Chart>
              </div>

              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisibleOne("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>
             </div>
             {chartDataPezzi.length > 0 && (
                 <div
                     style={{
                         width: "100%",
                         display: "flex",
                         alignItems: "center",
                         flexDirection: "column",
                         justifyContent: "center",
                         marginTop: 5,
                         paddingBottom: 5
                     }}
                 >
                     <h2
                         style={{
                             color: "#5b6372",
                             fontFamily: "Arial, sans-serif",
                         }}
                     >
                         Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValuePezzi}</span>
                     </h2>
                 </div>
             )}
          </div>



          <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, marginBottom: '-3px', marginLeft: '5px' }}>Analisi venduto - Periodo selezionato</h6>

          <NewTabella
            idTabella="elenco_operatori"
            sync
            colonne={columnsOperatori}
            dataSource={valoriTabella1}
            filterRow
            width={10}
            searchPanel={true}
            exportT={{
              enabled: true,
              fileName: "ElencoOperatori",
              formats: { exportFormats },
            }}
            activeSaver={true}
            columnC={true}
            rowAlternation={true}
            headerFilter
            wordWrapEnabled={true}
            onExporting={onExporting}
            summary={
              <Summary>
                <TotalItem
                  column="VENDUTO_NETTO_SCONTI"
                  summaryType="sum"
                  valueFormat={{ style: "currency", currency: "EUR" }}
                />
                <TotalItem
                  column="VENDUTO_LORDO_SCONTI"
                  summaryType="sum"
                  valueFormat={{ style: "currency", currency: "EUR" }}
                />
                <TotalItem
                  column="IMPONIBILE"
                  summaryType="sum"
                  valueFormat={{ style: "currency", currency: "EUR" }}
                />
                <TotalItem
                  column="MARGINE"
                  summaryType="sum"
                  valueFormat={{ style: "currency", currency: "EUR" }}
                />
                <TotalItem
                  column="SCONTO"
                  summaryType="sum"
                  valueFormat={{ style: "currency", currency: "EUR" }}
                />
                <TotalItem column="QUANTITA" summaryType="sum" />
              </Summary>
            }
          />
   
          {/* new pie*/}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              backgroundColor: 'white',
              borderRadius: 5,
              paddingBottom: 10
            }}
          >
            <div style={{ width: "45%" }}>
              <PieChart
                id="pie"
                dataSource={chartDataVNSPie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
              >
                <Series
                  argumentField="PRO_DESC_SETTORE_INVENTARIO"
                  valueField="PERCENTUALE"
                >
                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>
                <Tooltip
                  enabled={true}
                  customizeTooltip={({
                    argument,
                    value,
                  }: {
                    argument: any;
                    value: any;
                  }) => {
                    const dataItem = chartDataVNSPie.find(
                      (item: any) =>
                        item.PRO_DESC_SETTORE_INVENTARIO === argument
                    );
                    const dataSourceValue = dataItem
                      ? dataItem.VENDUTO_NETTO_SCONTI
                      : 0;
                    const formattedValue = new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(dataSourceValue);
                    const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                    const percentualeFormattata =
                      percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                    return {
                      text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                    };
                  }}
                />
                <Title text="Valore del venduto netto sconti per settore inventario - Periodo selezionato">
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div>
            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisiblePie("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </div>

            <div style={{ width: "45%" }}>
              <PieChart
                id="pie"
                dataSource={chartDataSSNPie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
              >
                <Series argumentField="SSNDESC" valueField="PERCENTUALE">
                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>
                <Tooltip
                  enabled={true}
                  customizeTooltip={({
                    argument,
                    value,
                  }: {
                    argument: any;
                    value: any;
                  }) => {
                    const dataItem = chartDataSSNPie.find(
                      (item: any) => item.SSNDESC === argument
                    );
                    const dataSourceValue = dataItem
                      ? dataItem.VENDUTO_NETTO_SCONTI
                      : 0;
                    const formattedValue = new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(dataSourceValue);
                    const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                    const percentualeFormattata =
                      percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                    return {
                      text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                    };
                  }}
                />
                <Title text="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato">
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div>
            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisiblePietwo("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "10px",
              backgroundColor: 'white',
              borderRadius: 5
            }}
          >
            <div style={{ width: "93%", margin: "10px" }}>
              <PieChart
                id="pie"
                dataSource={chartDataATCPie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
              >
                <Series argumentField="ATC_DESC" valueField="PERCENTUALE">
                  <HoverStyle color="rgb(255, 0, 0)" />
                  <SmallValuesGrouping
                    groupName="Altro"
                    threshold={1.5}
                    mode="smallValueThreshold"
                  />
                </Series>
                <Tooltip
                  enabled={true}
                  customizeTooltip={({
                    argument,
                    value,
                  }: {
                    argument: any;
                    value: any;
                  }) => {
                    const dataItem = chartDataATCPie.find(
                      (item: any) => item.ATC_DESC === argument
                    );
                    const dataSourceValue = dataItem
                      ? dataItem.VENDUTO_NETTO_SCONTI
                      : 0;
                    const formattedValue = new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(dataSourceValue);
                    const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                    const percentualeFormattata =
                      percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                    return {
                      text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                    };
                  }}
                />
                <Title text="Valore del venduto netto sconti per codice ATC - Periodo selezionato">
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div>

            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
              <p>
                <span>
                  {" "}
                  <button
                    className="ibutton-lite-two"
                    style={{ border: "none" }}
                    onClick={() => popVisiblePiethree("")}
                  >
                    <FontAwesomeIcon
                      icon={faInfo}
                      style={{ fontSize: "11px", color: "black" }}
                    />
                  </button>
                </span>
              </p>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "white",
              marginTop: 10
            }}
          >
            <div style={{ width: "93%", margin: "10px" }}>
              <Chart
                dataSource={chartDataDittaPie.slice(0, 22)}
                ref={chartRefDitta}
              >
                <CommonSeriesSettings
                  argumentField="PRO_DESC_DITTA_TITOLARE"
                  type="bar"
                />

                <Series
                  valueField="VENDUTO_NETTO_SCONTI"
                  type="bar"
                  color="rgb(25, 231, 255)"
                />

                <Tooltip
                  enabled={true}
                  customizeTooltip={({
                    value,
                    argument,
                  }: {
                    value: any;
                    argument: any;
                  }) => {
                    const formattedValue = parseFloat(value).toLocaleString(
                      "it-IT",
                      {
                        style: "currency",
                        currency: "EUR",
                        minimumFractionDigits: 2,
                      }
                    );

                    return {
                      text: `Venduto: ${formattedValue} \n Ditta: ${argument}`,
                    };
                  }}
                />

                <Title text="Valore del venduto netto sconti per ditta - Periodo selezionato">
                  <Font color="#5b6372" size="16" weight="400" />
                  <Subtitle text="<i>Si visualizzano i primi 20.</i>">
                    <Font color="#5b6372" size="16" weight="400" />
                  </Subtitle>
                </Title>
                <Export fileName="venduto_ditta" enabled={true} />
                <Legend visible={false} />
              </Chart>
            </div>

            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
              <p>
                <span>
                  {" "}
                  <button
                    className="ibutton-lite"
                    style={{ border: "none" }}
                    onClick={() => popVisiblePiefour("")}
                  >
                    <FontAwesomeIcon
                      icon={faInfo}
                      style={{ fontSize: "11px", color: "black" }}
                    />
                  </button>
                </span>
              </p>
            </div>
          </div>
        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{
            fontSize: "30px",
            color: "black",
          }}
        />
      </div>
      <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
        <div style={{}}>
          {/* <FiltriVerticale
                ricerca={filterAction}
                items={itemsFields}
                paramsFilter={paramsFilter}
              /> */}
          <FiltriVerticale
            ricerca={(e) =>
              filterActionexten(
                e,
                (x) => setParamsFilter(x),
                (x: any, y: any) => getCall(x, y)
              )
            }
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>

      <Popup
        visible={visiblePop}
        showCloseButton={true}
        onHiding={() => setVisiblePop(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messOne}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePopOne}
        showCloseButton={true}
        onHiding={() => setVisiblePopOne(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messTwo}</p>
        </div>
      </Popup>

      <Popup
        visible={visiblePopPie}
        showCloseButton={true}
        onHiding={() => setVisiblePopPie(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messPie1}</p>
        </div>
      </Popup>

      <Popup
        visible={visiblePopPietwo}
        showCloseButton={true}
        onHiding={() => setVisiblePopPietwo(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messPie2}</p>
        </div>
      </Popup>
 
      <Popup
        visible={visiblePopPiethree}
        showCloseButton={true}
        onHiding={() => setVisiblePopPiethree(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messPie3}</p>
        </div>
      </Popup>

      <Popup
        visible={visiblePopPiefour}
        showCloseButton={true}
        onHiding={() => setVisiblePopPiefour(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messLast}</p>
        </div>
      </Popup>
    </section>
  );
};

export default BusinessLite;
