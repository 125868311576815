import notify from "devextreme/ui/notify";
import React, { useEffect, useMemo, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { RestServices } from "../../services/restServices";
import { urlB2B, urlOrdiniLiberi, urlTrasferimenti } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/esm/Button';
import { Loading } from "../../widget/Notifications";
import { DrawerFiltri } from "../../widget/Drawer";
import { TabellaCompletaAlternate } from "../../widget/Tabelle";
import { DettagliListinoInterface, ElencoListiniInterface } from "../../interfaces/ordineLibero";
import TestataListino from "../ordiniLiberi/TestataListinoOrdineLibero";
import TestataListinoB2B from "./TestataListinoOrdineB2B";
import { NewTabella } from '../../widget/nuova_tabella/newVersion';

interface PageProps {
    idListino: number,
    setIndietro: () => void,
}

export const SingoloListinoOrdineB2B: React.FC<PageProps> = ({
    idListino,
    setIndietro
}) => {

//const SingoloListinoOrdineB2B = (props: {
//    idListino:number,
//}) => {

    //const queryString = window.location.search;
   // const urlParams = new URLSearchParams(queryString);

    //const idListino = urlParams.get('idListino');


    useEffect(() => {

        //setIdContenitore(parseInt(urlParams.get('idContenitore')));
        //setContenitoreFarmacia(parseInt(urlParams.get('contenitoreFarmacia')));

        if (idListino) {
            const param = {
                id: idListino
            }
            setIsVisible(true);
            RestServices().rest('POST', param, urlB2B.getListino)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result.esito && result.response.testata) {
                            setTestata(result.response.testata);
                            setDettagliListino(result.response.dettaglio);
                        } else if (result.esito == false) {
                            notify({ position: "center", width: "auto", message: "Oops, si � verificato un problema. Ci scusiamo per il disagio" }, "error", 4000)
                        }
                    }

                })
        }

    }, []);


    const [isVisible, setIsVisible] = useState(false);
    const [testata, setTestata] = useState<ElencoListiniInterface>();
    const [dettagliListino, setDettagliListino] = useState<DettagliListinoInterface[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />
    const [openDrawer, setOpenDrawer] = useState(true);
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const propsTabListinoDettaglio: TabellaCompletaInterface = useMemo(() => ({
        id: "dettaglio_listino",
        dataSource: dettagliListino,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        pagination: 10,
        rowAlternationEnabled: false,
        summary: {
            totalItems: [{
                name: "TotaleProdotti",
                showInColumn: "Cod. Prod.",
                displayFormat: "Prodotti: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Listino"
        }
    }), [dettagliListino])

    const columnsDettaglio = [
        { caption: "Cod. Csf", dataField: "codCsf", visible: false },
        { caption: "Cod. Prod.", dataField: "codProd", visible: true },
        { caption: "Descrizione", dataField: "prodotto", },
        { caption: "Qta. minima", dataField: "quantitaMinima", },
        { caption: "Qta. massima", dataField: "quantitaMassima", },
        { caption: "Dt. inizio", dataField: "dataInizio", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Dt. fine", dataField: "dataFine", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Imponibile", dataField: "imponibile", allowEditing: true, format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
    ]

    const templateFiltri = () => {
        return (
            <Row id="testata">
                <TestataListinoB2B
                    testata={testata}
                />
            </Row>
        )
    }

    return (
        <>
            {loadPanel}
            {
                <>
                    <div className="contenutoPagina">
                        
                        {

                            <>
                                <DrawerFiltri
                                    id="testataListino"
                                    templateFiltri={templateFiltri}
                                    testoDrawer="DETTAGLIO LISTINO"
                                    open={openDrawer}
                                    setOpen={setOpenDrawer}
                                />

                                <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                                    <div>
                                        <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro() }}>INDIETRO </Button>
                                    </div>
                                </div>

                                <Row>
                                    <Col sm='12' className="ordineRda">
                                        {

                                            <NewTabella
                                                idTabella='singolo_listino'
                                                sync
                                                colonne={columnsDettaglio}
                                                dataSource={dettagliListino}
                                                filterRow
                                                searchPanel={true}
                                                onclickRow={() => null}
                                                exportT={{
                                                    enabled: false,
                                                    fileName: "liv_mag"
                                                }}
                                                activeSaver={false}
                                                columnC={false}
                                            />

                                        }
                                    </Col>

                                </Row>
                            </>
                        }
                    </div>
                </>
            }
        </>
    )
}

export default SingoloListinoOrdineB2B