import React, { useState, useEffect, useRef } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import NewTabella from "../../widget/nuova_tabella"
import { Loading } from '../../widget/Notifications'
import TextBox from 'devextreme-react/text-box'
import { NumberBox } from 'devextreme-react/number-box'
import { SelectBox } from 'devextreme-react/select-box'
import notify from 'devextreme/ui/notify'
import DateBox from 'devextreme-react/date-box'
import CheckBox from 'devextreme-react/check-box'
import { useAuth } from '../../hooks/useAuth'
import { RestServices } from '../../services/restServices'
import { urlCommon, urlConsultazione } from '../costanti'
import Button from 'react-bootstrap/Button'
import AsyncSelect from "react-select/async"

import '../consultazione/dialog/style.css';
import TextArea from 'devextreme-react/text-area'

interface DialogProps {
    open: boolean,
    close: () => void,
    ricarica: () => void,
    edit: any,
    typeEdit: boolean,
}

export const SendQueryDialog: React.FC<DialogProps> = ({ open, close, ricarica, edit, typeEdit}, ref) => {


    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD, setOpenD] = useState<boolean>(false)
    const [closeD, setcloseD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [popAsk, setPopAsk] = useState(false)
    const [objPostRicerca, setObjPostRicerca] = useState<any>({})
    const [newFarmaList, setNewFarmaList] = useState([])
    const [tokens, setTokens] = useState<any>([]);
    const [tokensList, setTokensList] = useState<any>([]);
    const [results, setResults] = useState([])
    const [selectedScad, setSelectedScad] = useState<any>([])
    const [queryResult, setQueryResult] = useState([]);


    const columns = [
        { caption: "ID", dataField: "idFar", width: "45" },
        { caption: "Descrizione", dataField: "descrizioneFarmacia", width: "fixed"},
        { caption: "Token", dataField: "token", width:"fixed"},
        { caption: "Risultato", dataField: "result", width:"fixed" },
    ];

    useEffect(() => {
        if (edit) {
            let objTo = { ...edit };
            setObjctTOsave(objTo);
        }
    }, [edit, openD]);


    const readValues = () => {
       
        const model = {
            tokens: edit
           
        }
    
        console.log(model.tokens);
        RestServices().rest('POST', model, urlConsultazione.readQueryResult)
            .then(result => {
                console.log(result);
                if (result.esito) {
                    setResults(result.response);
                }
            })
    }

    useEffect(() => {
        if (openD) {
            readValues();
        }
    }, [openD]);


    useEffect(() => {
        setOpenD(open);
    }, [open])

    return (
        <>
            <Popup
                visible={open}
                showCloseButton={false}
                width={'80%'}
                height={'85%'}
                showTitle={true}
                title="Risultato Query"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="top"
                    location="after"
                    options={{
                        text: 'Chiudi',
                        onClick: () => {
                            close()
                        }
                    }}
                />

                <NewTabella
                idTabella='sendqueryresult'
                sync 
                    colonne={columns}
                    dataSource={results}
                    searchPanel={true}
                    selectedValue={(datiRow: any) => {
                        setSelectedScad(datiRow.selectedRowKeys)
                        console.log();
                    }}

                exportT={{
                    enabled: true,
                    fileName: "sendquery"
                }}
                activeSaver
                    columnC

            />  
            </Popup>
        </>

    )
}
