import React, { useEffect, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { MonitoraggioFarmaciaParametro } from "../../interfaces/monitoraggio";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlMonitoraggio } from "../costanti";
import { Selezione, TendinaFarm } from '../../widget/Select';
import Button from 'react-bootstrap/esm/Button';
import { DrawerFiltri } from '../../widget/Drawer';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { OggettoInterface } from '../../interfaces/generiche';
import { TabellaCompleta, TabellaCompletaAlternate, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { SelectFarm } from '../../widget/Select';
import { urlConsultazione } from "../costanti"
import { BoxFiltro } from "../../widget/Editor";
import { Summary, TotalItem } from 'devextreme-react/data-grid'
import NewTabella from "../../widget/nuova_tabella"
import { Filtri } from "../consultazione/interface";
import Ricerca from "../../widget/nuova_ricerca";

const ElencoFarmacieParametri = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlConsultazione.auth);
    const auth2 = useAuth(urlMonitoraggio.auth)
    
    /*
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);
    
    useEffect(() => {
        if (auth) {
          //  startCerca();
        }
    }, [auth]);
    */
    

    const titolo = TitoloContenuto("Monitoraggio farmacie parametri Platinum");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoFarmacieMonitoraggioParametri, setElencoFarmacieMonitoraggioPramatri] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState([])
    const [paramsRicerca, setParamsRicerca] = useState<any>({})

    const columns = [
        //{ caption: "IdFar", dataField: "idFar", visible: false },
        //{ caption: "Avviso", dataField: "avviso", visible: true, dataType: "boolean" },
        { caption: "Farmacia", dataField: "ragioneSociale" },
        { caption: "Titolare", dataField: "ragioneSocialeAggiunta" },
        { caption: "Cod.Tph.", dataField: "codTph" },
        { caption: "Concessionario", dataField: "ragioneSocialeConcessionario" },
        { caption: "Cod.Tph.Conc.", dataField: "codTphConcessionario" },
        { caption: "Indrizzo", dataField: "indirizzo" },
        { caption: "Citta'", dataField: "citta" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Release installata", dataField: "release" },
        { caption: "Nome modulo", dataField: "nomeModulo" },
        { caption: "Nome parametro", dataField: "nomeParametro" },
        { caption: "Tipo parametro", dataField: "tipoParametro" },
        { caption: "Valore parametro", dataField: "valoreParametro" },
        { caption: "Data aggiornamento", dataField: "dataAggiornamento" }
        //{ caption: "Data aggiornamento", dataField: "dataAggiornamento", format: "dd/MM/yyyy", dataType: "date"},
    ];

    const itemsFields = [
        { type: 'select', idValue: 'idFar', label: 'Farmacia ', displayExpr: 'descrizione', dataSource: newFarmaList, width: 300 },
        { type: 'string', idValue: 'nomeModulo', label: 'Nome modulo', dataSource: null, width: 200 },
        { type: 'string', idValue: 'nomeParametro', label: 'Nome parametro', dataSource: null, width: 200 },

    ]

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFarDetail)
                .then(result => {
                    if (result?.esito) {
                   
                        setNewFarmaList(result.response)

                    } else {
                      
                    }
                })
        }
    }

    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }

        if (params.idFar) {
            params.idFar = Number(params.idFar?.idFar)
        }
        if (objFiltro?.nomeModulo) {
            params.nomeModulo = objFiltro?.nomeModulo
        }
        if (objFiltro?.nomeParametro) {
            params.nomeParametro = objFiltro?.nomeParametro
        }

        setParamsRicerca(params)


        if (params.idFar == null && params.nomeModulo == null && params.nomeModulo === "" && params.nomeParametro == null && params.nomeParametro === "") {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Errore, seleziona almeno uno dei tre filtri." }, 'error', 4000);

        } else {
            console.log("arrivato");
            setIsVisible(true);
            if (auth2) {
                RestServices().rest('POST', params, urlMonitoraggio.getListMonitoraggioFarmaciaParametri)
                    .then(result => {
                        if (result?.esito) {
                            setIsVisible(false);
                            setElencoFarmacieMonitoraggioPramatri(result.response);

                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setIsVisible(false);
                        }
                        /*
                        setIsVisible(false);
                        if (result?.esito) {
                            setIsVisible(false);
                            setElencoFarmacieMonitoraggioPramatri(result.response);
                            console.log("1");
                        } else {
                            if (result) {
                                if (result === true) {
                                    notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                                    console.log("2");
                                    //setElencoFarmacieMonitoraggioPramatri(result.response)
                                } else {
                                    console.log("3");
                                }
                            }

                            else if (result && result.message && result.severity) {
                                notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                            } else {
                                notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                            }
                        }
                        */
                    })
            }
        }
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

   

    useEffect(() => {
        farmaList();
    }, [auth])

    return (
        <>
            {titolo}
            {loadPanel}
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>

            <NewTabella
                idTabella='elenco_farmacie_parametri'
                sync
                colonne={columns}
                dataSource={elencoFarmacieMonitoraggioParametri}
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "MonitoraggioParametri"
                }}
                activeSaver={true}
                columnC={true}
                summary={
                    <Summary>
                        <TotalItem column="ragioneSociale" summaryType="count" />
                    </Summary>}         
            />
        </>

    )


    /*
    useEffect(() => {
        if (filtri.lstFar && filtri.lstFar.length === 0) {
            const listaFarmacie: number[] = [];
            props.datiFarmacie.forEach(farm => listaFarmacie.push(farm.ID_FAR));
            const nuovoFiltro = { ...filtri, lstFar: listaFarmacie }
            setFiltri(nuovoFiltro);
        }
    }, [filtri.lstFar]);

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const creaRiepilogoFiltri = () => {
        let descrFarm: string[] = [""];

        if (props.datiFarmacie && newFarmaList) {
            for (let i = 0; i < newFarmaList.length; i++) {
                descrFarm[i] = props.datiFarmacie.filter((item) => {

                    return item.ID_FAR === newFarmaList[i]
                })[0].CODICE

                console.log(descrFarm[i])
            }
        }

        const elencoFarmSelez = () => {
            if (newFarmaList.length === props.datiFarmacie.length) {
                return "Tutte"
            } else {
                const spanDescr = descrFarm.map((descr) => {          
                    return <span className="cerca" key={descr}>{descr}&nbsp;</span>
                })

                return spanDescr;
            }
        }

        return (
            <>
                {(filtri.lstFar) && <span className="cerca">&nbsp;Farmacia: {elencoFarmSelez()}</span>}     
                {(filtri.nomeModulo && filtri.nomeModulo) && <span className="cerca">&nbsp;Nome modulo: {filtri.nomeModulo}</span>}
                {(filtri.nomeParametro && filtri.nomeParametro) && <span className="cerca">&nbsp;Nome parametro: {filtri.nomeParametro}</span>}
            </>
        )
    }

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="3">
                    <label>Farmacia</label>
                    <SelectFarm datiFarmacie={props.datiFarmacie} id="idFar" impostaFiltri={impostaFiltri} />
                </Col>
                <Col sm="2">
                    <label>Nome modulo</label>
                    <BoxFiltro id="nomeModulo" impostaFiltri={impostaFiltri} value={filtri.nomeModulo} />
                </Col>
                <Col sm="2">
                    <label>Nome parametro</label>
                    <BoxFiltro id="nomeParametro" impostaFiltri={impostaFiltri} value={filtri.nomeParametro} />
                </Col>
                <Col sm="3">
                    <Button type="button" variant="success" id="btnCercaMonitoraggio" onClick={() => { startCerca()}}>Cerca</Button>
                </Col>
            </Row>
            
        )
    }

    const startCerca = () => {

        setIsVisible(true);
        const param = {
            idFar: filtri.idFar,
            nomeModulo: filtri.nomeModulo,
            nomeParametro: filtri.nomeParametro
            //inserire parametri
        };

        if (param.idFar == null && param.nomeModulo == null && param.nomeParametro == null) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Errore, seleziona almeno uno dei tre filtri." }, 'error', 4000);

        } else {

            RestServices().rest('POST', param, urlMonitoraggio.getListMonitoraggioFarmaciaParametri)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result === true) {
                            notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                            setElencoFarmacieMonitoraggioPramatri(result)
                        } else {
                            setElencoFarmacieMonitoraggioPramatri(result);
                        }
                    }

                    else if (result && result.message && result.severity) {
                        notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                    } else {
                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                })
        }
    }



    const propsTabElencoFarmacieParametri: TabellaCompletaInterface = {
        id: "elenco_farmacie_730",
        dataSource: elencoFarmacieMonitoraggioParametri,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {
            totalItems: [{
                name: "TotaleFarmacieParametri",
                column: "idFar",
                showInColumn: "Farmacia",
                displayFormat: "Totale: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "MonitoraggioParametri"
        }
    }

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {
                        // console.log(result);                 
                    }
                })
        }
    }

    const columnsFarmacieParametri = CreaColonne([
        { caption: "IdFar", dataField: "idFar", visible: false },
        //{ caption: "Avviso", dataField: "avviso", visible: true, dataType: "boolean" },
        { caption: "Farmacia", dataField: "ragioneSociale" },
        { caption: "Titolare", dataField: "ragioneSocialeAggiunta" },
        { caption: "Cod.Tph.", dataField: "codTph" },
        { caption: "Concessionario", dataField: "ragioneSocialeConcessionario" },
        { caption: "Cod.Tph.Conc.", dataField: "codTphConcessionario" },
        { caption: "Indrizzo", dataField: "indirizzo" },
        { caption: "Citta'", dataField: "citta" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Nome modulo", dataField: "nomeModulo"},
        { caption: "Nome parametro", dataField: "nomeParametro" },
        { caption: "Tipo parametro", dataField: "tipoParametro" },
        { caption: "Valore parametro", dataField: "valoreParametro" },
        { caption: "Data aggiornamento", dataField: "dataAggiornamento"}
        //{ caption: "Data aggiornamento", dataField: "dataAggiornamento", format: "dd/MM/yyyy", dataType: "date"},
    ])

    const onRowPrepared = (e: any) => {
        if (e.values) {
            if (e.data.avviso == 1) {
                e.rowElement.style.backgroundColor = '#C08CEF';
            }
        }
    }

    return (
        <>
            {titolo}
            {loadPanel}
           
            <div className="contenutoPagina">
            <DrawerFiltri id="paginaElencoFarmaciaParametri" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={openDrawer} setOpen={setOpenDrawer} />
                <Row className="tabellaFornitori">

                    <Col sm="12">
                        <TabellaCompleta
                            propsTabella={propsTabElencoFarmacieParametri}
                            columns={columnsFarmacieParametri}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={true}
                            allowColumnResizing={true}
                        />  
                    </Col>
                </Row>
            </div>
        </>

    )
    */
}

export default ElencoFarmacieParametri;