import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

export const DialogGestBanca:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)


    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({}) 
        }

    },[edit])


    const cambiaValore = (tipo:string,value:string) =>{
            let objTo = {...objctTOsave}
            objTo[tipo] = value
            setObjctTOsave(objTo)
            //console.log(objTo);   
            if(objTo.abi?.length > 3 && objTo.cab?.length > 3 && objTo.ragioneSociale?.length > 4  && objTo.filiale?.length  > 3  ){
                setDisableButton(false)
            }else{
                setDisableButton(true)
        }
    }


    const creaModificaBanche = (modifica?:boolean) =>{
         objctTOsave.filiale = objctTOsave.filiale.toUpperCase()
         objctTOsave.ragioneSociale = objctTOsave.ragioneSociale.toUpperCase()
        if (auth) {
            if(modifica){
                objctTOsave.idBanca = objctTOsave.id               
                delete objctTOsave.id
                delete objctTOsave.idGru
                //console.log(objctTOsave);             
                RestServices().rest('POST', objctTOsave , urlConsultazione.putBanca)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave(null)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }else{
                
                RestServices().rest('POST', objctTOsave , urlConsultazione.postBanca)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave(null)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }
           
        
    } 
    }

    useEffect(()=>{
        setOpenD(open)
    },[auth])


    useEffect(()=>{
        setOpenD(open)
    },[open])



    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'60%'}
          showTitle={true}
          title="Gestione Banche"
        >
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={typeEdit ? {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:false,
                onClick: ()=>creaModificaBanche(true),
              }: {
                text: 'CREA',
                class:'btncreatipocluster',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>creaModificaBanche(),
              }}
          />

        {typeEdit ? (
               <ScrollView width='100%' height='100%'>
                    <div className='form-row '> 
                <div className="form-group col-md-6">
                      <label> ABI </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.abi}  onValueChanged={(v)=>cambiaValore('abi',v.value)}  maxLength='5'  placeholder="Inserire massimo 5 caratteri"  /> 
                   </div>
                   <div className="form-group col-md-6">
                   <label> CAB </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.cab} onValueChanged={(v)=>cambiaValore('cab',v.value) } maxLength='5'  placeholder="Inserire massimo 5 caratteri"  /> 
                   </div>
                   </div>
                   
                   <div className='form-row '> 
                <div className="form-group col-md-6">
                   <label> RAGIONE SOCIALE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.ragioneSociale} onValueChanged={(v)=>cambiaValore('ragioneSociale',v.value) }   placeholder="Inserire almeno 5 caratteri" /> 
                   </div>

                   <div className="form-group col-md-6" >
                   <label> FILIALE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.filiale} onValueChanged={(v)=>cambiaValore('filiale',v.value) }   placeholder="Inserire almeno 4 caratteri" /> 
                   </div>
                   </div>

             <div>
           </div>
           </ScrollView>
            ) : (
                <ScrollView width='100%' height='100%'>
                   
                   <div className='form-row '> 
                <div className="form-group col-md-6">
                       <label> ABI </label> 
                       <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.abi : ''}  onValueChanged={(v)=>cambiaValore('abi',v.value)} maxLength='5'  placeholder="Inserire massimo 5 caratteri" /> 
                    </div>
                   <div className="form-group col-md-6">
                    <label> CAB </label> 
                       <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.cab : ''} onValueChanged={(v)=>cambiaValore('cab',v.value)} maxLength='5'  placeholder="Inserire massimo 5 caratteri" /> 
                    </div>
                    </div>
                    
                    <div className='form-row '> 
                      <div className="form-group col-md-6">
                    <label> RAGIONE SOCIALE  </label> 
                       <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave ?  objctTOsave.ragioneSociale : ''} onValueChanged={(v)=>cambiaValore('ragioneSociale',v.value) }   placeholder="Inserire almeno 5 caratteri" /> 
                    </div>
                     
                    <div className="form-group col-md-6">
                    <label> FILIALE </label> 
                       <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave ?  objctTOsave.filiale : ''} onValueChanged={(v)=>cambiaValore('filiale',v.value) }   placeholder="Inserire almeno 4 caratteri" /> 
                    </div>
                    </div>
           
            </ScrollView>
            )}
        
        </Popup>
        </>
      )

  
}
