
import { DataGrid } from 'devextreme-react'
import { Column } from 'devextreme-react/data-grid'
import React, { useEffect, useMemo, useState } from 'react'
import { OggettoInterface } from '../interfaces/generiche'
import {  TabellaCompletaInterface, TabellaSempliceInterface } from '../interfaces/tabelle'
import { RestServices } from '../services/restServices'

export const Detail = (props: {
    propsTabella: TabellaCompletaInterface,
    columns?: any,
    key?: string | undefined,
    data?: any,
    urlDetail?: string | undefined
    keyDetail?: string | undefined,
    paramsDetail: any | undefined,
}
) => {

    const [data, setData] = useState<OggettoInterface[]>();
    useEffect(() => {
        if (props.urlDetail && props.keyDetail) {
           // console.log(props)
            let param: any = {};
            if (props.paramsDetail != undefined)
                param = props.paramsDetail
            //RestServices().rest('POST', param, props.urlDetail + "?idFar=" + props.data.idFar)
            if (props.keyDetail)
                param[props.keyDetail] = props.data[props.keyDetail]
            RestServices().rest('POST', param, props.urlDetail)
                .then(result => {
                    if (result)
                        setData(result);
                })
        }else if (props.data) {
            setData(props.data.detail);
        }
    }, []);

    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.propsTabella.headerFilterVisible
        }
    }, []);

    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);

    return (
        <>
            <React.Fragment>
                <DataGrid
                    repaintChangesOnly={true}
                    showBorders={true}
                    showRowLines={true}
                    hoverStateEnabled={true}
                    columnAutoWidth={true}
                    dataSource={data}
                    headerFilter={headerFilterOptions}
                    filterRow={filterRowOptions}
                >
                    {props.columns}
                </DataGrid>
            </React.Fragment>
        </>
    )

}