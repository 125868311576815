import React, { useEffect, useState } from 'react'
import NewTabella from "../../../widget/nuova_tabella"
import Ricerca from "../../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../../widget/Notifications'
import { ListaFarmacieInterface } from '../../../interfaces/farmacie'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione, urlFidelityBe } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'


import '.././style.css';
import { Filtri } from '../../consultazione/interface'
import { ConvertForSelect } from '../filters/FiltriVerticaleNewVersione'
import MovimentiCoupon from './MovimentiCoupon'
import { TextArea } from 'devextreme-react'
import { trasformaDateTimeInLocale } from '../../../funzioni/formatoVariabili'


const GestioneCoupon = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [paramsFilter, setParamsFilter] = useState<Filtri>();
    const [objtipo, setObjtipo] = useState<any>(null);
    const [idDel, setIddel] = useState<number>(0)
    const [loading, setLoading] = useState<boolean>(false)
    const [renderp, setRenderp] = useState<any>();
    const [newFarmaList, setNewFarmaList] = useState([])
    const [couponList, setCouponList] = useState([])

    let farms: any[] = [];
    const auth = useAuth(urlFidelityBe.auth);
    const consultazioneAuth = useAuth(urlConsultazione.auth);

    const columns = [
        { caption: "Codice Coupon", dataField: "codice", sortOrder: "asc" },
        { caption: "Farmacia emissione", dataField: "codiceDescFarmacia", type: TextArea },
        //{ caption: "Operatore emissione", dataField: "operatore" },
        //{ caption: "Terminale emissione", dataField: "terminale" },
        { caption: "Data emissione", dataField: "dataEmissione", format: "dd/MM/yyyy", dataType: "datetime" },
        { caption: "Data Scadenza", dataField: "dataScadenza", format: "dd/MM/yyyy", dataType: "datetime" },
        { caption: "Scaduto", dataField: "scaduto", dataType: "boolean" },
        { caption: "Percentuale", dataField: "percentuale" },
        { caption: "Valore", dataField: "valore", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Residuo", dataField: "residuo", format: { type: "currency", precision: 2, currency: "EUR" } },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Revoca",
                    icon: "revert",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data.id)

                        let revokeModel = {
                            id: e.row.data.id
                        }


                        RestServices().rest('POST', revokeModel, urlFidelityBe.revocaCoupon)
                            .then(result => {

                            })
                        revocaCoupon();
                        ricarica()
                    },

                }]
        }

    ];

    


    const itemsFields = [
        { type: 'selectFarmacie', idValue: 'idFarmacia', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), require: true, width: 370 },
        //{ type: 'date', idValue: 'dal', label: 'Data Creazione', dataSource: null, require: false, width: 250 },
        { type: 'ckBox', idValue: 'flagScaduti', label: 'Visualizza solo scaduti', require: false, dataSource: null, width: 300, marginRight: 100 },
    ]

    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }

        if (params.flagScaduti) {
            params.flagScaduti = params.flagScaduti;
        }

        if (params.dal) {
            params.dal = trasformaDateTimeInLocale(params.dal, true);
        }

        let flagScaduti = params?.flagScaduti ? 1 : 0;

        let farList: any[] = [];
        params.idFarmacia.idFar.forEach((element: any) => {
            farList.push(element);
            farms.push(element);
        });

        if (params.idFarmacia) {
            params.idFarList = farList;
        }

        const model = {
            idFarList: farList,
            flagScaduti: flagScaduti,
            dal: params.dal
        }

        setParamsFilter(params);

        console.log(2222)

        if (auth) {
            RestServices().rest('POST', model, urlFidelityBe.getList)
                .then(result => {
                    if (result?.esito) {
                        setIsVisible(false)
                        setCouponList(result.response)
                       
                    } else {

                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false)
                    }
                })
        }

    }

    const getGestCoupon = () => {
        let model = {
            idFarList: farms
        }
        RestServices().rest('POST', model, urlFidelityBe.getList)
            .then(result => {
                if (result?.esito) {
                    setIsVisible(false)
                    setCouponList(result.response)
                    
                } else {

                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    setLoading(false)
                }
            })
    }

    const revocaCoupon = () => {
        if (auth) {
            RestServices().rest('POST', { idCoupon: idDel }, urlFidelityBe.revocaCoupon)
                .then(result => {
                    if (result.esito) {
                        ricarica()
                    }
                })
        }
    }


    const ricarica = () => {
        setObjtipo(null);
        getGestCoupon();
        setIsVisible(false);
    }

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlFidelityBe.getClusterPharmacies)
                .then(result => {
                    if (result) {
                        //console.log(result.response);
                        setNewFarmaList(result)
                    } else {

                    }
                })
        }
    }


    useEffect(() => {
        farmaList();
    }, [auth]);

    

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const render = () => {
        switch (renderp) {
            case 1:
                return (

                    <MovimentiCoupon
                        rowData={objtipo}
                        setIndietro={() => { setRenderp("default") }}
                    >
                    </MovimentiCoupon>

                )

            default:
                return (
                    <>
                        {loadPanel}
                        <Loading isVisible={loading} />
                        <div className='title'>
                            <h3> Gestione Coupon <span className='subt' > </span> </h3>
                        </div>

                        <div style={{ width: '250' }}>
                            <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields}  />
                        </div>

                        <NewTabella
                            idTabella='Gestione_Coupon'
                            height={"100vh"}
                            sync
                            colonne={columns}
                            dataSource={couponList}
                            filterRow
                            searchPanel={true}
                            exportT={{
                                enabled: true,
                                fileName: "Gestione_Coupon"
                            }}
                            activeSaver={true}
                            columnC
                        />

                    </>

                )

        }



    }

    return render()
}

export default GestioneCoupon


