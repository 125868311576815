import React, { useState, useEffect } from "react"
import { useAuth } from "../../../hooks/useAuth";
import { urlBancaDati, urlCommon, urlConsultazione } from "../../costanti";
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import TextBox from "devextreme-react/text-box"
import { AutocompleteBox } from "../../../widget/Autocomplete";
import { Selezione, SelezioneMultipla } from "../../../widget/Select";
import { RestServices } from "../../../services/restServices";
import { ClasseAInterface } from "../../../interfaces/generiche";
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import CheckBox from "devextreme-react/check-box"
import Select from 'react-select';
import { convertForSelectFarmacie } from "../../../funzioni/formatoVariabili";
import RadioGroup from "devextreme-react/radio-group";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { BoxFiltro } from "../../../widget/Editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
interface DialogProps {
    open: boolean,
    ricarica: () => void,
    close: () => void,
}

export const DialogImpostazioniLivellamentoMagazzino: React.FC<DialogProps> = ({ open, close, ricarica }) => {

    const [openD, setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlCommon.auth)
    const [loading, setLoading] = useState<boolean>(false)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [farmacie, setFarmacie] = useState<any>([])
    const [infoSoglia, setInfoSoglia] = useState<any>()
    const [sogliaValoreVisible, setSogliaValoreVisible] = useState<boolean>(false)


    useEffect(() => {
        setOpenD(open);
        if (open && auth) {


        }

    }, [open, auth]);

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value
        setObjctTOsave(objTo)
    }


    const radioGroupOnValueChanged = (e: any) => {
        console.log(e)
        let index = soglie.indexOf(e.value)
        setInfoSoglia(soglieDesc[index])
        setSogliaValoreVisible(true)
    }

    const soglie = ['su singolo prodotto', 'su tutto l\'ordine'];
    const soglieDesc = ['soglia minima sulle quantita\' per singolo prodotto','soglia minima sulle quantita\' per intero ordine']

    return (
        <>

            <Popup
                visible={openD}
                showCloseButton={false}
                width={'60%'}
                height={'55%'}
                showTitle={true}
                title="IMPOSTAZIONI LIVELLAMENTO MAGAZZINO"
            >

                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Annulla',
                        onClick: () => close(),
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'SALVA',
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => { },
                    }
                    }
                />

                <ScrollView height="100%" width="100%">

                    <div style={{ background: '#fff', padding: 10, borderRadius: 10, bottom: 20, width: '100%' }}>
                        <div>
                            <h5>SELEZIONA FARMACIA </h5>
                        </div>

                        <div className='form-row' style={{ marginBottom: 20 }}>
                            <div className="form-group col-md-6">
                                <div style={{ width: 400 }}>
                                    <Select styles={{
                                        multiValue: (styles, { data }) => {
                                            //console.log(data);         
                                            return {
                                                ...styles,
                                                maxWidth: 80,
                                            };
                                        },
                                    }} onChange={(v: any) => cambiaValore('idFarmacie', v)} isMulti options={convertForSelectFarmacie(farmacie)} />
                                </div>
                            </div>

                        </div>

                        <hr></hr>

                        &nbsp;
                        <div className='form-row' style={{ marginBottom: 20 }} >
                            <div className="form-group col-md-6">
                                <label> E' MAGAZZINO </label>
                                <CheckBox value={objctTOsave?.flagEquivalenti} onValueChanged={(v) => cambiaValore('flagEquivalenti', v.value)} />
                            </div>
                        </div>

                        {/* 
                            <div className='form-row' style={{ marginBottom: 20 }} >
                                <div className="form-group col-md-6">
                                    <label> TIPO DI COSTO </label>
                                    <RadioGroup items={costi} defaultValue={costi[0]} />
                                </div>
                            </div>

                            <div className='form-row' style={{ marginBottom: 20 }} >
                                <div className="form-group col-md-6">
                                    <label> TIPO DI DISPONIBILITA' </label>
                                    <RadioGroup items={disponibilita} defaultValue={disponibilita[0]} />
                                </div>
                            </div>
                        
                        <div className='form-row' style={{ marginBottom: 20 }} >
                            <div className="form-group col-md-6">
                                <label>SOGLIE </label>
                                <RadioGroup items={soglie} defaultValue={-1} onValueChanged={radioGroupOnValueChanged } />
                            </div>
                        </div>
                        */ }
                        <div className='form-row' style={{ marginBottom: 20 }} >
                            <div className="form-group col-md-6">
                                <label> SOGLIA PRODOTTO </label><span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="soglia minima sulle quantita' per singolo prodotto" /> soglia minima sulle quantita' per singolo prodotto</span>
                                <TextBox value={objctTOsave.valoreSogliaProdotto} onValueChanged={(v) => cambiaValore('valoreSogliaProdotto', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-6">
                                <label> SOGLIA ORDINE </label> <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="soglia minima sulle quantita' per intero ordine" /> soglia minima sulle quantita' per intero ordine</span>
                                <TextBox value={objctTOsave.valoreSogliaOrdine} onValueChanged={(v) => cambiaValore('valoreSogliaOrdine', v.value)} placeholder="..." />
                            </div>
                        </div>

                    </div>


                </ScrollView>

            </Popup>

        </>
    )
};
