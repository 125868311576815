import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth';
import { urlCommon, urlGestioneUdi, urlGestioneUdiV2 } from '../costanti';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import ElencoUdi from './ElencoUdi';
import InserimentoUdi from './InserimentoUdi';


import "./style.css";


const GestioneUdi = (props: { datiFarmacie: ListaFarmacieInterface[] }) => {

    const auth = useAuth(urlGestioneUdiV2.auth);
    const auth2 = useAuth(urlCommon.auth);

    const [selectedIndex, setSelectedIndex] = useState<number>();
    let defaultClassName = 'btnCgm';
    let selectedClassName = 'btnCgmSelected'
    let defaultFontWeight = '400';
    let selectedFontWeight = '600'

    const [bgColor1, setBgColor1] = useState(defaultClassName);
    const [bgColor2, setBgColor2] = useState(selectedClassName);

    const [fontWeight1, setFontWeight1] = useState(defaultFontWeight);
    const [fontWeight2, setFontWeight2] = useState(selectedFontWeight);


    useEffect(() => { 

        setFontWeight1(defaultFontWeight)
        setFontWeight2(selectedFontWeight)

    }, [])
    
    const [renderp, setRenderp] = useState<number>(2);

    const render = () => {

        

        switch (renderp) {
            case 1:
                return (
                    <>
                        {auth && auth2 && <InserimentoUdi
                            datiFarmacie={props.datiFarmacie}
                            authCommon={auth}
                            authUdi={auth2}
                        />}
                        
                        


                    </>)
            case 2:
                return (
                    <>
                        {auth && auth2 && <ElencoUdi
                            datiFarmacie={props.datiFarmacie}
                            authCommon={auth}
                            authUdi={auth2}
                        />}
                        
                    </>
                )
        }
    }

    useEffect(() => {
        render()
    }, [renderp]);


    return (

        <>
            <Row className="nav-top-row">
                <Col md="12" className="nav-top-col-cgm-2" style={{ height: 44 }}>
                    <nav className="navbar-static-top-cgm-down" role="navigation">
                        <Col>

                            <div className="d-flex align-items-center" style={{marginLeft:4} }>
                                <div className="d-inline-block">
                                    <Button className={bgColor1} style={{ borderRadius: '4px 4px 0px 0px', borderTopLeftRadius: '4px 4px 0px 0px', borderTopRightRadius: '4px 4px 0px 0px', backgroundColor: '#083C72 !important', fontSize: 14, fontFamily: 'Inter', fontWeight: fontWeight1, letterSpacing: 0.4, lineHeight: '20px', height: '44px' }} onClick={() => { setRenderp(1); setBgColor1(selectedClassName); setBgColor2(defaultClassName); setFontWeight1(selectedFontWeight); setFontWeight2(defaultFontWeight) }}>Inserimento</Button>
                                </div>
                                <div className="d-inline-block">
                                    <Button className={bgColor2} style={{ borderRadius: '4px 4px 0px 0px', borderTopLeftRadius: '4px 4px 0px 0px', borderTopRightRadius: '4px 4px 0px 0px', backgroundColor: '#083C72 !important', fontSize: 14, fontFamily: 'Inter', fontWeight: fontWeight2, letterSpacing: 0.4, lineHeight: '20px', height: '44px' }} onClick={() => { setRenderp(2); setBgColor2(selectedClassName); setBgColor1(defaultClassName); setFontWeight2(selectedFontWeight); setFontWeight1(defaultFontWeight) }}>Consultazione</Button>
                                </div>
                            </div>


                        </Col>

                    </nav>

                </Col>
            </Row>

            <div className="bodyCgm">
                {render()}
            </div>

            

        </>

    )

}

export default GestioneUdi
