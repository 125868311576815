import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../../interfaces/farmacie";
import { RestServices } from "../../../../services/restServices";
import FiltriVerticale from "../../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../../consultazione/interface";
import { cardC, CardCart } from "../../components/card_chart";
import { trasform_no_marge } from "../../util/trasformation";
import { columnsAnalisi } from "../../evolution/Colums/analisiPerOperatore";
import { MenuCruscotto } from "../../components/menu_cruscotto";
import Button from "react-bootstrap/Button";
import { DialogOperMerce } from "../../evolution/dialog/PopOpeMerce";
import { DialogOperDitta } from "../../evolution/dialog/PopOpeDitta";
import { DialogOperFarma } from "../../evolution/dialog/PopOpeFarma";
import { createItemFilter } from "../../evolution/ItemsFiltri/analisiPerOperatore";
import { urlBancaDati, urlConsultazione, urlReport } from "../../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../../widget/nuova_tabella/newBusineVersion";
import { boxCard, boxsecond } from "../../evolution/BoxCard/analisiPerOperatore";
import "../../evolution/styleAnalisi.css";

const GalileoMagazzinoGiacenza = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [ditte, setDitte] = useState<any[]>([]);
    const [operatori, setOperatori] = useState<any[]>([]);
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCard);
    const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecond);
    const [settori, setSettori] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [openPopTwo, setOpenPopTwo] = useState<boolean>(false);
    const [openPopThree, setOpenPopThree] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const dashboard1 = { dashboardID: 1 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        let venditaDPC = params?.venditaDPC ? [] : [0];
        let flagBusta = params?.flagBusta ? [] : [0];

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            venditaDPC: venditaDPC,
            flagBusta: flagBusta,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall1 = {
                ...dashboard1,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            //console.log('mostro call');
            //console.log(dashboardCall1);

            RestServices()
                .rest("POST", dashboardCall1, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {
                        let datiPrimaParteCards = trasform_no_marge(result["39"]["0"], boxCard);
                        let datiSecondaParteCards = trasform_no_marge(
                            result["39"]["0"],
                            boxsecond
                        );
                        let datiPrimaTabelle = result["41"];

                        setFila1cards(datiPrimaParteCards);
                        setFila2cards(datiSecondaParteCards);
                        setValoriTabella1(datiPrimaTabelle);


                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1� gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 � gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });

            RestServices()
                .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
                .then((result) => {
                    if (result.esito) {
                        const operatori = result.response.map(
                            (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                                //console.log(ope.idFarmacia);
                                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                                //console.log(farmaciaCorrispondente);
                                return {
                                    ...ope,
                                    id: `${ope.operatore}`,
                                    descrizione: `${ope.operatore}`,
                                    codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                                };
                            }
                        );
                        setOperatori(operatori);
                        // console.log('operatori');
                        // console.log(operatori);
                    }
                });
        }
    };




    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const filters = () => {
        if (auth) {
            RestServices()
                .rest("POST", "", urlBancaDati.getGruppoMerceologico)
                .then((result) => {
                    if (result) setGruppoMercFilters(result);
                });
        }
        RestServices()
            .rest("POST", "", urlBancaDati.getSettore)
            .then((result) => {
                if (result) setSettori(result);
            });


        RestServices()
            .rest("POST", "", urlBancaDati.getDitte)
            .then((result) => {
                if (result) {
                    const ditteWithCodiceDescrizione = result.map(
                        (ditta: { codice: any; descrizione: any }) => {
                            return {
                                ...ditta,
                                codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                            };
                        }
                    );
                    // console.log('da togliere vediamo ditte');
                    // console.log(ditteWithCodiceDescrizione);

                    setDitte(ditteWithCodiceDescrizione);
                }
            });

        const ssn = { id: 1, descrizione: "SSN" };
        const libera = { id: 0, descrizione: "Libera" };

        const vendite = [libera, ssn];
        setTipologiaVendita(vendite);
    };

    useEffect(() => {
        filters();
    }, [auth]);

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabella_operatori.csv"
                );
            });
        });
    };

    const itemsFields = createItemFilter(
        newFarmaList,
        operatori,
        tipologiaVendita,
        gruppoMercFilters,
        ditte
    );

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    return (
        <>
            <DialogOperMerce
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            />
            <DialogOperDitta
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPopTwo(false);
                    setObjtipo(null);
                }}
                open={openPopTwo}
                filterData={paramsFilter}

            />
            <DialogOperFarma
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                close={() => {
                    setOpenPopThree(false);
                    setObjtipo(null);
                }}
                auth={auth}
                filterData={paramsFilter}
                open={openPopThree}
            />
            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2 style={{ display: 'inline-block', marginRight: '20px' }}>Magazzino Giacenza</h2>
                            <h1 style={{ color: 'rgb(215, 140, 245)', display: 'inline-block' }}>*Pagina in manutenzione*</h1>
                        </div>
                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >

                        </div>

                        {/* flexbox */}

                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>

                        <div>
                            <div className="grid-container-sec">
                                {fila1cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                            </div>
                            <div className="grid-container-sec">
                                {fila2cards.map((el, i) => {
                                    return (
                                        <CardCart
                                            testo={el.testo}
                                            subTesto={el.subTesto}
                                            key={i}
                                            valore={el.valore}
                                            subValore={el.subValore}
                                            starter={el.starter}
                                            typeShowValue={el.typeShowValue}
                                            typeShowSubValue={el.typeShowSubValue}
                                            testoPop={el.testoPop}
                                        />
                                    );
                                })}
                            </div>
                        </div>

                        {/* new tabella*/}
                        <div style={{ marginTop: "5px" }} >

                            <h6 style={{
                                color: 'black',
                                fontFamily: 'Arial, sans-serif',
                                fontWeight: 'bold',
                                marginBottom: 10,
                                marginLeft: '5px',
                                marginTop: 20

                            }}>Dettaglio operatore</h6>


                            <NewTabella
                                height={"70vh"}
                                idTabella="analisi_prodotti"
                                sync
                                colonne={columnsAnalisi}
                                dataSource={valoriTabella1}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiProdotti",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                        <TotalItem column="OMAGGI" summaryType="sum" />
                                        <TotalItem
                                            column="COSTO"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                    </Summary>
                                }
                            />
                        </div>

                        <div style={{ paddingBottom: 20 }} className="containButtonAna">
                            <div className="buttonCerca">
                                <Button
                                    id="btnCercax"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        setOpenPop(true);
                                        console.log("dialog");
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi operatori per gruppo merceologico
                                </Button>

                                <Button
                                    className="btn btn-primary"
                                    variant="primary"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    onClick={() => {
                                        setOpenPopTwo(true);
                                        console.log("dialog2");
                                        //setOpenPoptwo(true)
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi operatori per ditta titolare
                                </Button>
                            </div>
                        </div>
                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
        </>
    );
};

export default GalileoMagazzinoGiacenza;
