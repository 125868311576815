import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { NumberBox } from 'devextreme-react/number-box'
import AsyncSelect from "react-select/async"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'


//import './style.css';


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
} 

export const DialogAnagAziende:React.FC<DialogProps> = ({open,close,ricarica,edit})  => {

    const auth = useAuth(urlConsultazione.auth)
    const [openD,setOpenD] = useState<boolean>(false)
    const [infoA,setInfoA] = useState<any>(null)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    
    const getListAzie = () =>{
        if (auth) {
            //alert('inizio')
           RestServices().rest('POST', { } , urlConsultazione.getListAzienda)
               .then(result => {
                   if (result?.esito) {               
                        //console.log(result.response);
                        setInfoA(result.response) 
                                                                      
                   }else{                                
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)                      
                   }
               })     
       }
    }

    const cambioValue = (value:any,key:string) =>{
        //console.log(value);    
        let newInfo = {...infoA}
        newInfo[key] = value
        //console.log(newInfo);  
        if(newInfo.piva?.length  == 11   ){
            setDisableButton(false)
        }else{
            setDisableButton(true)
         }  
        setInfoA(newInfo)

    }


    const UpdateAnaAzienda = () =>{
        let updater = {
            "id": infoA.id,
            "cap":  infoA.cap,
            'codice': infoA.codice,
            "descrizioneRidotta": infoA.descrizioneRidotta,
            "capitaleSociale": infoA.capitaleSociale,
            "iscrizioneCameraCommercio": infoA.iscrizioneCameraCommercio,
            "ragioneSociale": infoA.ragioneSociale,   
            "codiceFiscale": infoA.codiceFiscale,      
            "email": infoA.email,
            "fax": infoA.fax,
            "indirizzo": infoA.indirizzo,
            "referente": infoA.referente,
            "sito": infoA.sito,
            "cellulare": infoA.cellulare,
            "piva": infoA.piva  ,
            "pec": infoA.pec,
            "telefono": infoA.telefono,
            'telefonoReferente': infoA.referenteTelefono,   
            'idComune':infoA.idComune
        }
        
        if (auth) {
            
           RestServices().rest('POST', updater , urlConsultazione.updateAzienda)
               .then(result => {
                   if (result?.esito) {  
                    ricarica()                   
                   }else{                              
                       //console.log(result);
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   }
                  
                   
               })     
       }
    }


    const loadOptions = (
        inputValue: string,
        callback: (options: any) => void
        ) => {
            let idfartoUse = null
            console.log(inputValue);
            
            
            if(inputValue){
                RestServices().rest('POST', {
                    filter:inputValue
                } , urlConsultazione.getListComuni)
                .then((result: { esito: any; response: any }) => {
                    if (result?.esito) {

                        let arrToUse:any = []
                        result.response.forEach((element:any) => {
                            arrToUse.push({value:element.id,label:element.comune+' ('+element.provincia+')'})
                        });
                               
                        
                        callback(arrToUse);
                        
                    }else{
                                   
                        //console.log(result);
                        callback([{value:'pp',label:'no'}]);
                        
                    }
                    
                }) 
            }
           
            
      }; 

  



    useEffect(()=>{
         if(auth){
        getListAzie()   
        }
       
    },[auth])

    useEffect(()=>{
        if(edit){
            if(edit.piva?.length  == 11   ){
               
                setDisableButton(false)
            }else{
                setDisableButton(true)
             }  
            setInfoA(edit) ;
        }

    },[edit, openD])


    useEffect(()=>{
        setOpenD(open)
        
    },[open])


    return (
        <>
       
   
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'80%'}
          showTitle={true}
          title="Modifica Anagrafica azienda"
        >
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={ {
                text: 'Modifica',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>UpdateAnaAzienda(),
              }}
          />

        
               <ScrollView width='100%' height='100%'>
                <div className='form-row '> 
                <div className="form-group col-md-6">
                      <label> CODICE </label> 
                      <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'codice')}  value={infoA?.codice} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                   </div>
                   <div className="form-group col-md-6">
                   
                   <label> RAGIONE SOCIALE </label> 
                      <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'ragioneSociale')}  value={infoA?.ragioneSociale} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                   </div>               
                   </div>
                   
                   <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> P. IVA </label> 
                      <TextBox maxLength="11" onValueChanged={(e:any)=>cambioValue(e.value,'piva')}  value={infoA?.piva} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserire obbligatoriamente 11 caratteri" /> 
                   </div>
                   <div className="form-group col-md-6">
                   <label> CODICE FISCALE </label> 
                      <TextBox maxLength='16'  onValueChanged={(e:any)=>cambioValue(e.value,'codiceFiscale')}  value={infoA?.codiceFiscale} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                   </div>          
                    </div>


                    <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> DESCRIZIONE </label> 
                      <TextBox onValueChanged={(e:any)=>cambioValue(e.value,'descrizioneRidotta')}  value={infoA?.descrizioneRidotta} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...."  /> 
                   </div>
                   <div className="form-group col-md-6">
                   <label> INDIRIZZO </label> 
                      <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'indirizzo')}  value={infoA?.indirizzo} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                   </div>      
                   
                    </div>

                    <div className='form-row '>
                    <div className="form-group col-md-6">
                   <label> COMUNE </label>
                   {infoA?.idComune ? (
                    
                    <AsyncSelect
                        placeholder="cerca"
                        isClearable= {true}
                        defaultValue={{id:322,label:infoA?.denominazione }}
                        loadOptions={loadOptions}
                        onChange={(x:any)=>cambioValue(x?.value,'idComune') }
                        /> 
                       
                   ) : (
                    <div>
                    <AsyncSelect
                         placeholder="cerca"
                         isClearable= {true}
                         loadOptions={loadOptions}
                         onChange={(x:any)=>cambioValue(x?.value,'idComune') }
                         /> 
                     </div>    
                   )}
                   </div>
                   <div className="form-group col-md-6">
                   <label> CAP </label> 
                      <TextBox maxLength='5' onValueChanged={(e:any)=>cambioValue(e.value,'cap')}  value={infoA?.cap} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                   </div>                
                 
                    </div> 

                    <div className='form-row '>
                    <div className="form-group col-md-6">
                   <label> TELEFONO </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'telefono')}  value={infoA?.telefono} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>
                    <div className="form-group col-md-6">
                   <label> FAX </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'fax')}  value={infoA?.fax} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>
                          
                    </div> 

                    <div className='form-row '>
                    <div className="form-group col-md-6">
                   <label> CELLULARE </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'cellulare')}  value={infoA?.cellulare} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>

                    <div className="form-group col-md-6">
                   <label> EMAIL </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'email')}  value={infoA?.email} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>
                            
                    </div> 

                    <div className='form-row '>                  
                   <div className="form-group col-md-6">
                   <label> SITO </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'sito')}  value={infoA?.sito} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>
                    <div className="form-group col-md-6">
                   <label> PEC </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'pec')}  value={infoA?.pec} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>
                    </div>    

                     <div className='form-row '>
                     <div className="form-group col-md-6">
                    <label> CAPITALE SOCIALE </label> 
                      <NumberBox  onValueChanged={(e:any)=>cambioValue(e.value,'capitaleSociale')}  value={infoA?.capitaleSociale} valueChangeEvent="keyup" showClearButton={true}  placeholder="€" /> 
                   </div>

                   <div className="form-group col-md-6">
                   <label> ISCR. CAMERA DI COMMERCIO </label> 
                   <TextBox  onValueChanged={(e:any)=>cambioValue(e.value,'iscrizioneCameraCommercio')}  value={infoA?.iscrizioneCameraCommercio} valueChangeEvent="keyup" showClearButton={true}  placeholder="Inserisci...." /> 
                    </div>
                            
                  
                    </div>             
         
           </ScrollView>
           
            
        
           

        </Popup>
        </>
      )

  
}
