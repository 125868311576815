import React, { useEffect} from 'react'



const InfoMagazzino = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   

    useEffect(() => { 
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);



    
   
    return (
        <>
         
             <div className='form-row '> 
                <div className="form-group col-md-6">
                 
                <div style={{  marginLeft:10 , marginTop:20, color:'#337ab7'   }}>
                <h3 className='font-size:6vw'>Magazzino Virtuale</h3>
                </div>

                <div style={{ padding:10, marginRight:30 , marginTop:20 , borderRadius:10 , backgroundColor:'#fff', color:'#337ab7' }}  >
                  <h5 className='font-size:2vw '>
                  La piattaforma <strong>CloudFarma</strong> consente la gestione del magazzino virtuale:
                     </h5>
                     </div> 
                     <div style={{  marginLeft:5 , marginTop:10 , color:'#337ab7'}}>
                     <ul>
                            <h6> <li> <strong>Carichi:</strong> carichi e documenti di trasporto registrati; </li></h6>   
                            <h6><li> <strong>Magazzino:</strong> movimenti di magazzino e giacenze; </li></h6> 
                            <h6><li> <strong>Bilanciamento:</strong> trasferimento prodotti tra magazzini. </li></h6> 
                           
                    </ul>
                    </div>

                </div>   
             <div className="form-group col-md-6" >
                 <div style={{ marginRight:10 , marginTop:20 , borderRadius:20  }}  >
                 <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_magazzino_arg.png" alt="magazzino" />

                     </div>
                     <div style={{ marginRight:10 , marginTop:20, borderRadius:20   }}  >
                     <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_magazzino.png" alt="magazzino" />
                 </div>
            </div> 
            </div> 
           
        </>
    )
}

export default InfoMagazzino