import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import Ricerca from "../../widget/nuova_ricerca";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { Filtri } from "../consultazione/interface";
import { urlCommon, urlConsultazione } from "../costanti";
import { ConvertForSelect } from "../dashboards/filters/FiltriVerticaleNewVersione";
import ElencoEsportazioneFarmacia from "./ElencoEsportazioneDettaglioFarmacia";

const ElencoEsportazioniFarmacie = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const titolo = TitoloContenuto("Monitoraggio Esportazioni Standard");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoEsportazioni, setElencoEsportazioni] = useState<any[]>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>();
    const [objtipo, setObjtipo] = useState<any>(null);
    const [renderp, setRenderp] = useState<any>();

    const auth = useAuth(urlCommon.auth);

    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }

        if (params.idFarmacia) {

            params.idFarmacia = params.idFarmacia;

        }

        let newArr: number[] = []
        params.idFarmacia.idFar.forEach((element: any) => {
            newArr.push(element);
        });


        const model = {
            listFar: newArr
        }

        setParamsFilter(params)
        
        setIsVisible(true)

        if (auth) {
            RestServices().rest('POST', model, urlCommon.getListExport)
                .then(result => {
                    setIsVisible(false);

                    if (result) {
                        setIsVisible(false);
                        setElencoEsportazioni(result.response);

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }

                })
        }

    }

    const farmaList = () => {

        let model = {}

        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {

                        setNewFarmaList(result.response);

                        //console.log(result.response)

                        let newArr: number[] = []
                        result.response.forEach((element: any) => {
                            newArr.push(element.idFar);
                        });

                        model = {
                            listFar: newArr
                        }

                    } else {
                        // handle error
                    }

                    RestServices().rest('POST', model, urlCommon.getListExport)
                        .then(result => {
                            setIsVisible(false);

                            if (result) {
                                setIsVisible(false);
                                setElencoEsportazioni(result.response);

                            } else {

                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                setIsVisible(false);
                            }
                        })

                });
        }
    };

    const columnsEsportazioni = [
        { caption: "Codice Farmacia", dataField: "codiceFarmacia" },
        { caption: "Descrizione Farmacia", dataField: "descrizioneFarmacia" },
        { caption: "Codice Flusso", dataField: "codiceFlusso" },
        { caption: "Nome Esportazione", dataField: "nomeFlusso" },
        { caption: "Data ultima Esportazione", dataField: "dataInizio", format: "dd/MM/yyyy HH:mm:ss", dataType: "datetime" },
        { caption: "Esito", dataField: "esitoResponse" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Mostra Dettagli",
                    icon: "search",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        //console.log(e.row.data)
                        setRenderp(1)
                    }
                }]
        }
    ];

    const itemsFields = [
        { type: 'selectFarmacie', idValue: 'idFarmacia', label: 'Farmacie', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 500, require: true, tutteSelezionate: true },
    ];

    useEffect(() => {
        farmaList();
    }, [auth]);


    const render = () => {
        switch (renderp) {
            case 1:
                return (

                    <ElencoEsportazioneFarmacia
                        rowData={objtipo}
                        setIndietro={() => { setRenderp("default") }}
                    >
                    </ElencoEsportazioneFarmacia>

                )

            default :
                return (
                    <>
                        {titolo}
                        {loadPanel}
                        <div style={{ width: '250' }}>
                            <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
                        </div>

                        <NewTabella
                            idTabella='elenco_esportazioni_farmacie'
                            sync
                            colonne={columnsEsportazioni}
                            dataSource={elencoEsportazioni}
                            filterRow
                            searchPanel={true}
                            exportT={{enabled: true, fileName: "ElencoEsportazioniFarmacie"}}
                            activeSaver={true}
                            columnC={true}
                            headerFilter
                            wordWrapEnabled={true}
                        />
                    </>

                )

        }

        

    }

    return render()
}
    


export default ElencoEsportazioniFarmacie;