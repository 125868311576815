import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import './widget.css'
import DataGrid, { GroupItem, KeyboardNavigation, LoadPanel, MasterDetail, Pager, Paging, RemoteOperations, Scrolling, Summary } from 'devextreme-react/data-grid'
import { OnRowDblClickInterface, TabellaCompletaInterface, TabellaCompletaMasterDetailInterface, TabellaSempliceInterface } from '../interfaces/tabelle'
import { dxToolbarOptions } from 'devextreme/ui/toolbar'
import { setStatoTabella } from '../funzioni/operazioniSuTabelle'
import { RestServices } from '../services/restServices'
import { urlLayoutTabelle } from '../contenuti/costanti'
import { Detail } from './Detail'

export const TabellaCompleta = (props: {
    propsTabella: TabellaCompletaInterface,
    onRowClick?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onCellPrepared?: (e: any) => void,
    onEditingStart?: (e: any) => void,
    onSelectionChanged?: (e: any) => void,
    onFocusedCellChanged?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    selectedRowKeys?: any[],
    columns?: any,
    setTabellaInstance: (instance: any) => void, 
    enterKeyDirection?: string,
    wordWrapEnabled?: boolean,
    togliFilterPanel?: boolean,
    rowAlternationEnabled?: boolean,
    allowColumnResizing?: boolean,
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    const [enablePagination, setEnablePagination] = useState(true);
    const [enterKeyDirection, setEnterKeyDirection] = useState("column");
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.enablePagination === false) setEnablePagination(false);
    }, [props.propsTabella.enablePagination])

    useEffect(() => {
        if (tabRef.current) props.setTabellaInstance(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.enterKeyDirection) setEnterKeyDirection(props.enterKeyDirection);
    }, [props.enterKeyDirection])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props.togliFilterPanel === true) setFilterPanel(false);
    }, [props.togliFilterPanel])

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.propsTabella.selection,
            showCheckBoxesMode: props.propsTabella.showCheckBoxesMode
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);
    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);
    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.propsTabella.headerFilterVisible
        }
    }, []);
    const columnChooserOptions = useMemo(() => {
        return {
            enabled: true
        }
    }, [])

    const getStatoTabella = () => {
        if (!props.propsTabella.id) return;
        RestServices().rest('POST', props.propsTabella.id, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {
            if (result === true) {
                tabRef.current.instance.repaint();
            }
        });
    }

    useEffect(() => {
        getStatoTabella();
    }, []);

    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();
                        
                        setStatoTabella(props.propsTabella.id, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                           // console.log("ripristina stato");
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.propsTabella.id);
                        }
                    }
                }
            )
        }
    }

    return (
        <DataGrid
            ref={tabRef}
            id={props.propsTabella.id}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={(props.rowAlternationEnabled) ? (props.rowAlternationEnabled) : true}
            columnAutoWidth={props.propsTabella.autoWidth}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            filterRow={filterRowOptions}
            filterSyncEnabled={true}
            filterPanel={filterPanelOptions}
            headerFilter={headerFilterOptions}
            defaultFilterValue={props.propsTabella.defaultFilterValue}
            columnChooser={columnChooserOptions}
            allowColumnReordering={true}
            summary={props.propsTabella.summary}
            export={props.propsTabella.export}
            onToolbarPreparing={onToolbarPreparing}
            onRowClick={props.onRowClick}
            onRowDblClick={props.onRowDblClick}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            selectedRowKeys={props.selectedRowKeys}
            onEditorPreparing={props.onEditorPreparing}
            onCellPrepared={props.onCellPrepared}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
            wordWrapEnabled={wordWrapEnabled}
            repaintChangesOnly={true}
            allowColumnResizing={props.allowColumnResizing}           
        >
            <LoadPanel enabled={false} />
            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction='startEdit'
                enterKeyDirection={enterKeyDirection}
            />
            {props.columns}
        </DataGrid>
    )
}

export const TabellaCompletaAlternate = (props: {
    propsTabella: TabellaCompletaInterface,
    onRowClick?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onCellPrepared?: (e: any) => void,
    onCellHoverChanged?: (e: any) => void,
    onEditingStart?: (e: any) => void,
    onSelectionChanged?: (e: any) => void,
    onFocusedCellChanged?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    selectedRowKeys?: any[],
    columns?: any,
    setTabellaInstance: (instance: any) => void,
    enterKeyDirection?: string,
    wordWrapEnabled?: boolean,
    togliFilterPanel?: boolean,
    rowAlternationEnabled: boolean,
    allowColumnResizing?: boolean,
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    const [enablePagination, setEnablePagination] = useState(true);
    const [enterKeyDirection, setEnterKeyDirection] = useState("column");
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.enablePagination === false) setEnablePagination(false);
    }, [props.propsTabella.enablePagination])

    useEffect(() => {
        if (tabRef.current) props.setTabellaInstance(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.enterKeyDirection) setEnterKeyDirection(props.enterKeyDirection);
    }, [props.enterKeyDirection])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props.togliFilterPanel === true) setFilterPanel(false);
    }, [props.togliFilterPanel])

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.propsTabella.selection,
            showCheckBoxesMode: props.propsTabella.showCheckBoxesMode
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);
    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);
    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.propsTabella.headerFilterVisible
        }
    }, []);
    const columnChooserOptions = useMemo(() => {
        return {
            enabled: true
        }
    }, [])

    const getStatoTabella = () => {
        if (!props.propsTabella.id) return;
        RestServices().rest('POST', props.propsTabella.id, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {
            if (result === true) {
                tabRef.current.instance.repaint();
            }
        });
    }

    useEffect(() => {
        getStatoTabella();
    }, []);

    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();
                        setStatoTabella(props.propsTabella.id, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                           // console.log("ripristina stato");
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.propsTabella.id);
                        }
                    }
                }
            )
        }
    }

   
    
    
    return (
        <>
        <DataGrid
            ref={tabRef}
            id={props.propsTabella.id}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={props.rowAlternationEnabled}
            columnAutoWidth={props.propsTabella.autoWidth}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            filterRow={filterRowOptions}
            filterSyncEnabled={true}
            filterPanel={filterPanelOptions}
            headerFilter={headerFilterOptions}
            defaultFilterValue={props.propsTabella.defaultFilterValue}
            columnChooser={columnChooserOptions}
            allowColumnReordering={false}
            summary={props.propsTabella.summary}
            export={props.propsTabella.export}
            onToolbarPreparing={onToolbarPreparing}
            onRowClick={props.onRowClick}
            onRowDblClick={props.onRowDblClick}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            selectedRowKeys={props.selectedRowKeys}
            onEditorPreparing={props.onEditorPreparing}
            onCellPrepared={props.onCellPrepared}
            onCellHoverChanged={props.onCellHoverChanged}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
            wordWrapEnabled={wordWrapEnabled}
            repaintChangesOnly={true}
            allowColumnResizing={props.allowColumnResizing}
        >
            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction='startEdit'
                enterKeyDirection={enterKeyDirection}
            />
            {props.columns}
        </DataGrid>
        </>
    )
}

export const TabellaSemplice = (props: {
    propsTabella: TabellaSempliceInterface,
    dettagliRighe?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onCellPrepared?: (event: any) => void
    columns?: any,
    selectedRowKeys?: any[],
    onRowPrepared?: (event:any) => void
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])
    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination
        }
    }, [])
    const selectionOptions = useMemo(() => {
        const mode: 'single' | 'multiple' | 'none' = 'single'
        return {
            mode: mode
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: props.propsTabella.filterRow,
            showOperationChooser: true
        }
    }, []);

    return (
        <DataGrid
            id={props.propsTabella.id}
            repaintChangesOnly={true}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            columnAutoWidth={true}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            summary={props.propsTabella.summary}
            filterRow={filterRowOptions}
            onRowClick={props.dettagliRighe}
            onRowDblClick={props.onRowDblClick}
            onCellPrepared={props.onCellPrepared}
            selectedRowKeys={props.selectedRowKeys}
            onRowPrepared={ props.onRowPrepared}
        >
            {props.columns}
        </DataGrid>
    )
}

export const TabellaSempliceEdit = (props: {
    propsTabella: TabellaCompletaInterface,
    dettagliRighe?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onCellPrepared?: (event: any) => void
    columns?: any,
    selectedRowKeys?: any[],
    onFocusedCellChanged?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onSelectionChanged?: (e: any) => void,
    onEditingStart?: (e: any) => void,
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])
    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination
        }
    }, [])
    const selectionOptions = useMemo(() => {
        const mode: 'single' | 'multiple' | 'none' = 'single'
        return {
            mode: mode
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: props.propsTabella.headerFilterVisible,
            showOperationChooser: true
        }
    }, []);

    return (
        <DataGrid
            id={props.propsTabella.id}
            repaintChangesOnly={true}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            columnAutoWidth={true}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            summary={props.propsTabella.summary}
            filterRow={filterRowOptions}
            onRowClick={props.dettagliRighe}
            onRowDblClick={props.onRowDblClick}
            onCellPrepared={props.onCellPrepared}
            selectedRowKeys={props.selectedRowKeys}
            rowAlternationEnabled={false}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            onEditorPreparing={props.onEditorPreparing}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
        >
            {props.columns}
        </DataGrid>
    )
}

export const TabellaOrdiniLiberi = (props: {
    propsTabella: TabellaCompletaInterface,
    onRowClick?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onEditorPrepared?: (editorPrepared: any) => void,
    onCellPrepared?: (e: any) => void,
    onEditingStart?: (e: any) => void,
    onSelectionChanged?: (e: any) => void,
    onFocusedCellChanged?: (e: any) => void,
    onFocusedCellChanging?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    onKeyDown?: (e: any) => void,
    selectedRowKeys?: any[],
    columns?: any,
    setTabellaInstance: (instance: any) => void,
    enterKeyDirection?: string,
    focusedRowKey?: any;
    keyExpr?: any;
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    const [enablePagination, setEnablePagination] = useState(true);
    const [enterKeyDirection, setEnterKeyDirection] = useState("column");

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.enablePagination === false) setEnablePagination(false);
    }, [props.propsTabella.enablePagination])

    useEffect(() => {
        if (tabRef.current) props.setTabellaInstance(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.enterKeyDirection) setEnterKeyDirection(props.enterKeyDirection);
    }, [props.enterKeyDirection])

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.propsTabella.selection
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);
    // const filterPanelOptions = useMemo(() => {
    //     return {
    //         visible: true,
    //         texts: { createFilter: "Filtri Avanzati" }
    //     }
    // }, []);
    // const headerFilterOptions = useMemo(() => {
    //     return {
    //         allowSearch: true,
    //         visible: props.propsTabella.headerFilterVisible
    //     }
    // }, []);

    return (
        <DataGrid
            ref={tabRef}
            id={props.propsTabella.id}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={true}
            columnAutoWidth={props.propsTabella.autoWidth}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            filterRow={filterRowOptions}
            filterSyncEnabled={true}
            // filterPanel={filterPanelOptions}
            // headerFilter={headerFilterOptions}
            defaultFilterValue={props.propsTabella.defaultFilterValue}
            allowColumnReordering={true}
            summary={props.propsTabella.summary}
            export={props.propsTabella.export}
            onRowClick={props.onRowClick}
            onRowDblClick={props.onRowDblClick}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            selectedRowKeys={props.selectedRowKeys}
            onEditorPreparing={props.onEditorPreparing}
            onEditorPrepared={props.onEditorPrepared}
            onCellPrepared={props.onCellPrepared}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
            onFocusedCellChanging={props.onFocusedCellChanging}
            onKeyDown={props.onKeyDown}
            repaintChangesOnly={true}
            focusedRowKey={props.focusedRowKey}
            keyExpr={props.keyExpr}
        >
            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction='startEdit'
                enterKeyDirection={enterKeyDirection}
            />
            {props.columns}
        </DataGrid>
    )
}


export const TabellaCompletaMasterDetail = (props: {
    propsTabella: TabellaCompletaInterface,
    onRowClick?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onCellPrepared?: (e: any) => void,
    onEditingStart?: (e: any) => void,
    onSelectionChanged?: (e: any) => void,
    onFocusedCellChanged?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    selectedRowKeys?: any[],
    columns?: any,
    columnsDetail?: any,
    setTabellaInstance: (instance: any) => void,
    enterKeyDirection?: string,
    wordWrapEnabled?: boolean,
    togliFilterPanel?: boolean,
    rowAlternationEnabled?: boolean,
    allowColumnResizing?: boolean,
    key?: string | undefined,
    onExporting?: (e: any) => void,
    urlDetail?: string | undefined,
    keyDetail?: string | undefined,
    paramsDetail?: any | undefined,
    searchPanel?: boolean,

}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    const [enablePagination, setEnablePagination] = useState(true);
    const [enterKeyDirection, setEnterKeyDirection] = useState("column");
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);
    const [searchPanel, setSearchPanel] = useState(false);


    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.enablePagination === false) setEnablePagination(false);
    }, [props.propsTabella.enablePagination])

    useEffect(() => {
        if (tabRef.current) props.setTabellaInstance(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.enterKeyDirection) setEnterKeyDirection(props.enterKeyDirection);
    }, [props.enterKeyDirection])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props.togliFilterPanel === true) setFilterPanel(false);
    }, [props.togliFilterPanel])

    useEffect(() => {
        if (props.searchPanel === true) setSearchPanel(true);
    }, [props.searchPanel])


    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.propsTabella.selection
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);
    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);
    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.propsTabella.headerFilterVisible
        }
    }, []);
    const columnChooserOptions = useMemo(() => {
        return {
            enabled: true
        }
    }, [])
    const searchPanelOptions = useMemo(() => {
        return {
            visible: searchPanel
        }
    }, [searchPanel]);


    const getStatoTabella = () => {
        if (!props.propsTabella.id) return;
        RestServices().rest('POST', props.propsTabella.id, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {
            if (result === true) {
                tabRef.current.instance.repaint();
            }
        });
    }

    useEffect(() => {
        getStatoTabella();
    }, []);

    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();
                        setStatoTabella(props.propsTabella.id, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                           // console.log("ripristina stato");
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.propsTabella.id);
                        }
                    }
                }
            )
        }
    }

    //const renderDetailSection = ({ data }) => {
    //    console.log(data);

    //    return (<Detail propsTabella={props.propsTabella} columns={props.columnsDetail} />)
    //}, []);

    const renderDetailSection = (data: any) => {
        return (<Detail propsTabella={props.propsTabella} columns={props.columnsDetail} data={data.key} urlDetail={props.urlDetail} keyDetail={props.keyDetail} key={props.key} paramsDetail={props.paramsDetail} />)
    };

    return (
        <DataGrid
            ref={tabRef}
            id={props.propsTabella.id}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={(props.rowAlternationEnabled) ? (props.rowAlternationEnabled) : false}
            columnAutoWidth={props.propsTabella.autoWidth}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            filterRow={filterRowOptions}
            filterSyncEnabled={true}
            filterPanel={filterPanelOptions}
            headerFilter={headerFilterOptions}
            defaultFilterValue={props.propsTabella.defaultFilterValue}
            columnChooser={columnChooserOptions}
            allowColumnReordering={true}
            summary={props.propsTabella.summary}
            export={props.propsTabella.export}
            onToolbarPreparing={onToolbarPreparing}
            onRowClick={props.onRowClick}
            onRowDblClick={props.onRowDblClick}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            selectedRowKeys={props.selectedRowKeys}
            onEditorPreparing={props.onEditorPreparing}
            onCellPrepared={props.onCellPrepared}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
            wordWrapEnabled={wordWrapEnabled}
            repaintChangesOnly={true}
            allowColumnResizing={props.allowColumnResizing}
            onExporting={props.onExporting}
            searchPanel={searchPanelOptions}
            //key="ID"
        >
            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction='startEdit'
                enterKeyDirection={enterKeyDirection}
            />
            {props.columns}
             <MasterDetail
                enabled={true}
                //component={Detail}
                render={renderDetailSection}
                
            />

        </DataGrid>
    )
}


export const TabellaSempliceDetail = (props: {
    propsTabella: TabellaSempliceInterface,
    dettagliRighe?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onCellPrepared?: (event: any) => void
    columns?: any,
    selectedRowKeys?: any[],
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])
    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination
        }
    }, [])
    const selectionOptions = useMemo(() => {
        const mode: 'single' | 'multiple' | 'none' = 'single'
        return {
            mode: mode
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: props.propsTabella.filterRow,
            showOperationChooser: true
        }
    }, []);

    return (
        <React.Fragment>
            <DataGrid
                id={props.propsTabella.id}
                repaintChangesOnly={true}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                columnAutoWidth={true}
                height={props.propsTabella.height}
                paging={paginationOptions}
                dataSource={props.propsTabella.dataSource}
                selection={selectionOptions}
                summary={props.propsTabella.summary}
                filterRow={filterRowOptions}
                onRowClick={props.dettagliRighe}
                onRowDblClick={props.onRowDblClick}
                onCellPrepared={props.onCellPrepared}
                selectedRowKeys={props.selectedRowKeys}
            >
                {props.columns}
            </DataGrid>
        </React.Fragment>
        
    )
}

export const TabellaCompletaInfoFarmacia = (props: {
    propsTabella: TabellaCompletaInterface,
    onRowClick?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onCellPrepared?: (e: any) => void,
    onEditingStart?: (e: any) => void,
    onSelectionChanged?: (e: any) => void,
    onFocusedCellChanged?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    selectedRowKeys?: any[],
    columns?: any,
    setTabellaInstance: (instance: any) => void,
    enterKeyDirection?: string,
    wordWrapEnabled?: boolean,
    togliFilterPanel?: boolean,
    rowAlternationEnabled?: boolean,
    allowColumnResizing?: boolean,
    searchPanel?: boolean,
    groupPanel?: boolean
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    const [enablePagination, setEnablePagination] = useState(true);
    const [enterKeyDirection, setEnterKeyDirection] = useState("column");
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);
    const [searchPanel, setSearchPanel] = useState(false);
    const [groupPanel, setGroupPanel] = useState(false);

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.enablePagination === false) setEnablePagination(false);
    }, [props.propsTabella.enablePagination])

    useEffect(() => {
        if (tabRef.current) props.setTabellaInstance(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.enterKeyDirection) setEnterKeyDirection(props.enterKeyDirection);
    }, [props.enterKeyDirection])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props.togliFilterPanel === true) setFilterPanel(false);
    }, [props.togliFilterPanel])

    useEffect(() => {
        if (props.searchPanel === true) setSearchPanel(true);
    }, [props.searchPanel])

    useEffect(() => {
        if (props.groupPanel === true) setGroupPanel(true);
    }, [props.groupPanel])

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.propsTabella.selection,
            showCheckBoxesMode: props.propsTabella.showCheckBoxesMode
        }
    }, []);
    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);
    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);
    const searchPanelOptions = useMemo(() => {
        return {
            visible: searchPanel
        }
    }, [searchPanel]);
    const groupPanelOptions = useMemo(() => {
        return {
            visible: groupPanel
        }
    }, [groupPanel]);
    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.propsTabella.headerFilterVisible
        }
    }, []);
    const columnChooserOptions = useMemo(() => {
        return {
            enabled: true
        }
    }, [])

    const getStatoTabella = () => {
        if (!props.propsTabella.id) return;
        RestServices().rest('POST', props.propsTabella.id, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {
            if (result === true) {
                tabRef.current.instance.repaint();
            }
        });
    }

    useEffect(() => {
        getStatoTabella();
    }, []);

    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();
                        setStatoTabella(props.propsTabella.id, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                          //  console.log("ripristina stato");
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.propsTabella.id);
                        }
                    }
                }
            )
        }
    }

    const allowedPageSizes = [5, 10, 20, 0];
    const displayModes = [{ text: 'Display Mode \'full\'', value: 'full' }, { text: 'Display Mode \'compact\'', value: 'compact' }];
    const displayMode = { text: 'Display Mode \'full\'', value: 'full' };

    return (
        <DataGrid
            ref={tabRef}
            id={props.propsTabella.id}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={(props.rowAlternationEnabled) ? (props.rowAlternationEnabled) : true}
            columnAutoWidth={props.propsTabella.autoWidth}
            height={props.propsTabella.height}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            filterRow={filterRowOptions}
            filterSyncEnabled={true}
            filterPanel={filterPanelOptions}
            headerFilter={headerFilterOptions}
            defaultFilterValue={props.propsTabella.defaultFilterValue}
            columnChooser={columnChooserOptions}
            allowColumnReordering={true}
            summary={props.propsTabella.summary}
            export={props.propsTabella.export}
            onToolbarPreparing={onToolbarPreparing}
            onRowClick={props.onRowClick}
            onRowDblClick={props.onRowDblClick}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            selectedRowKeys={props.selectedRowKeys}
            onEditorPreparing={props.onEditorPreparing}
            onCellPrepared={props.onCellPrepared}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
            wordWrapEnabled={wordWrapEnabled}
            repaintChangesOnly={true}
            allowColumnResizing={props.allowColumnResizing}
            searchPanel={searchPanelOptions}
            groupPanel={groupPanelOptions}
        >
            <Scrolling rowRenderingMode='virtual'></Scrolling>
            <LoadPanel enabled={false} />
            <Paging defaultPageSize={20} />
            <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                showPageSizeSelector={true}
                showInfo={false}
                showNavigationButtons={true}
            />

            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction='startEdit'
                enterKeyDirection={enterKeyDirection}
            />
            {props.columns}
        </DataGrid>
    )
}

export const TabellaMasterDetailed = (props: {
    propsTabella: TabellaCompletaInterface,
    onRowClick?: (selectedRowsData: any) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void,
    onRowUpdating?: (rowUpdating: any) => void,
    onCellClick?: (cellClick: any) => void,
    onContentReady?: (content: any) => void,
    onOptionChanged?: (optionChanged: any) => void,
    onEditorPreparing?: (editorPreparing: any) => void,
    onCellPrepared?: (e: any) => void,
    onEditingStart?: (e: any) => void,
    onSelectionChanged?: (e: any) => void,
    onFocusedCellChanged?: (e: any) => void,
    onRowPrepared?: (e: any) => void,
    selectedRowKeys?: any[],
    columns?: any,
    columnsDetail?: any,
    setTabellaInstance: (instance: any) => void,
    enterKeyDirection?: string,
    wordWrapEnabled?: boolean,
    togliFilterPanel?: boolean,
    rowAlternationEnabled?: boolean,
    allowColumnResizing?: boolean,
    key?: string | undefined,
    onExporting?: (e: any) => void,
    urlDetail?: string | undefined,
    keyDetail?: string | undefined,
    paramsDetail?: any | undefined,
    searchPanel?: boolean,
    onToolbarPreparing?: boolean,
    enableColumnChooser?: boolean,
}) => {
    const tabRef = useRef<any>(null);
    const [pagination, setPagination] = useState(15);
    const [enablePagination, setEnablePagination] = useState(true);
    const [enterKeyDirection, setEnterKeyDirection] = useState("column");
    const [wordWrapEnabled, setWordWrapEnabled] = useState(false);
    const [filterPanel, setFilterPanel] = useState(true);
    const [searchPanel, setSearchPanel] = useState(false);

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.pagination) setPagination(props.propsTabella.pagination);
    }, [props.propsTabella.pagination])

    useEffect(() => {
        if (props.propsTabella && props.propsTabella.enablePagination === false) setEnablePagination(false);
    }, [props.propsTabella.enablePagination])

    useEffect(() => {
        if (tabRef.current) props.setTabellaInstance(tabRef.current);
    }, [tabRef])

    useEffect(() => {
        if (props.enterKeyDirection) setEnterKeyDirection(props.enterKeyDirection);
    }, [props.enterKeyDirection])

    useEffect(() => {
        if (props.wordWrapEnabled === true) setWordWrapEnabled(true);
    }, [props.wordWrapEnabled])

    useEffect(() => {
        if (props.togliFilterPanel === true) setFilterPanel(false);
    }, [props.togliFilterPanel])

    useEffect(() => {
        if (props.searchPanel === true) setSearchPanel(true);
    }, [props.searchPanel])

    const paginationOptions = useMemo(() => {
        return {
            pageSize: pagination,
            enabled: enablePagination
        }
    }, [enablePagination, pagination])

    const selectionOptions = useMemo(() => {
        return {
            mode: props.propsTabella.selection
        }
    }, []);

    const filterRowOptions = useMemo(() => {
        return {
            visible: true,
            showOperationChooser: true
        }
    }, []);

    const filterPanelOptions = useMemo(() => {
        return {
            visible: filterPanel,
            texts: { createFilter: "Filtri Avanzati" }
        }
    }, [filterPanel]);

    const headerFilterOptions = useMemo(() => {
        return {
            allowSearch: true,
            visible: props.propsTabella.headerFilterVisible
        }
    }, []);

    const columnChooserOptions = useMemo(() => {
        return {
            enabled: props.enableColumnChooser !== false
        };
    }, [props.enableColumnChooser]);

    const searchPanelOptions = useMemo(() => {
        return {
            visible: searchPanel
        }
    }, [searchPanel]);

    const getStatoTabella = () => {
        if (!props.propsTabella.id) return;
        RestServices().rest('POST', props.propsTabella.id, urlLayoutTabelle.urlGetLayout).then(result => {
            if (result) {
                tabRef.current.instance.state(result);
            }
        });
    }

    const deleteStatoTabella = (id: string) => {
        if (!id) return;
        RestServices().rest('POST', id, urlLayoutTabelle.urlDeleteLayout).then(result => {
            if (result === true) {
                tabRef.current.instance.repaint();
            }
        });
    }

    useEffect(() => {
        getStatoTabella();
    }, []);

    const onToolbarPreparing = (e: { toolbarOptions?: dxToolbarOptions }) => {
        if (props.onToolbarPreparing && e.toolbarOptions && e.toolbarOptions.items) {
            e.toolbarOptions.items.push({
                location: "after",
                widget: "dxButton",
                options: {
                    icon: "save",
                    hint: "Salvataggio della configurazione della tabella",
                    type: "default",
                    onClick: function () {
                        let stato = tabRef.current.instance.state();
                        setStatoTabella(props.propsTabella.id, stato);
                    }
                }
            },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "refresh",
                        hint: "Reimposta l'ultimo stato salvato per la tabella",
                        type: "default",
                        onClick: getStatoTabella
                    }
                },
                {
                    location: "after",
                    widget: "dxButton",
                    options: {
                        icon: "revert",
                        hint: "Reimposta lo stato originale della tabella",
                        type: "default",
                        onClick: function () {
                            const ripristina = tabRef.current.instance.resetOption("columns");
                            deleteStatoTabella(props.propsTabella.id);
                        }
                    }
                });
        }
    }

    const renderDetailSection = (data: any) => {
        return (
            <Detail
                propsTabella={props.propsTabella}
                columns={props.columnsDetail}
                data={data.key}
                urlDetail={props.urlDetail}
                keyDetail={props.keyDetail}
                key={props.key}
                paramsDetail={props.paramsDetail}
            />
        );
    };

    return (
        <DataGrid
            ref={tabRef}
            id={props.propsTabella.id}
            showBorders={true}
            showRowLines={true}
            hoverStateEnabled={true}
            rowAlternationEnabled={props.rowAlternationEnabled ?? false}
            columnAutoWidth={props.propsTabella.autoWidth}
            height={props.propsTabella.height}
            paging={paginationOptions}
            dataSource={props.propsTabella.dataSource}
            selection={selectionOptions}
            //filterRow={filterRowOptions}
            filterSyncEnabled={true}
            filterPanel={filterPanelOptions}
            headerFilter={headerFilterOptions}
            defaultFilterValue={props.propsTabella.defaultFilterValue}
            columnChooser={columnChooserOptions}
            allowColumnReordering={true}
            summary={props.propsTabella.summary}
            export={props.propsTabella.export}
            onToolbarPreparing={onToolbarPreparing}
            onRowClick={props.onRowClick}
            onRowDblClick={props.onRowDblClick}
            editing={props.propsTabella.editing}
            onRowUpdating={props.onRowUpdating}
            onRowPrepared={props.onRowPrepared}
            onCellClick={props.onCellClick}
            onContentReady={props.onContentReady}
            onOptionChanged={props.onOptionChanged}
            selectedRowKeys={props.selectedRowKeys}
            onEditorPreparing={props.onEditorPreparing}
            onCellPrepared={props.onCellPrepared}
            onEditingStart={props.onEditingStart}
            onSelectionChanged={props.onSelectionChanged}
            onFocusedCellChanged={props.onFocusedCellChanged}
            wordWrapEnabled={wordWrapEnabled}
            repaintChangesOnly={true}
            allowColumnResizing={props.allowColumnResizing}
            onExporting={props.onExporting}
            searchPanel={searchPanelOptions}
        >
            <KeyboardNavigation
                editOnKeyPress={true}
                enterKeyAction='startEdit'
                enterKeyDirection={enterKeyDirection}
            />

            {props.columns}

            <MasterDetail
                render={renderDetailSection}
            />

            <Pager
                visible={true}
                showInfo={true}
                infoText="Pagina {0} di {1} ({2} elementi)"
            />
        </DataGrid>
    );
};