import React from 'react';
import { dxDataGridColumn } from 'devextreme/ui/data_grid';
import { Column } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';
import { RestServices } from '../services/restServices';
import { urlLayoutTabelle ,urlConsultazione } from '../contenuti/costanti'
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';

export const setStatoTabella = (nomeFile: string, stato: any) => {
    //per evitare di salvare i filtri utilizzati al momento del salvataggio vengono cancellati i vari filterValue
    if (stato.filterValue) {
        delete stato.filterValue;
    }
    for (let i = 0; i < stato.columns.length; i++) {
        if (stato.columns[i].filterValue || stato.columns[i].filterValues || stato.columns[i].filterType) {
            delete stato.columns[i].filterValue
            delete stato.columns[i].filterValues
            delete stato.columns[i].filterType
        }
    }

    let params = [nomeFile, stato];
    RestServices().rest('POST', params, urlLayoutTabelle.urlSetLayout)
        .then(result => {
            if (result) {
                notify({ position: "center", width: "auto", message: "Configurazione della tabella salvata con successo" }, "success", 4000);
            } else {
                notify({ position: "center", width: "auto", message: "Errore nel salvataggio della configurazione" }, "error", 4000);
            }
        })
}


export const onExporting = (e: any) => {
    console.log(e.idTabella);
    console.log(e.component);
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("t");
    exportDataGrid({
        component: e.component,
        worksheet: worksheet,
    }).then(function () {
        // https://github.com/exceljs/exceljs#writing-csv
        // https://github.com/exceljs/exceljs#reading-csv
        workbook.csv.writeBuffer().then(function (buffer) {
            saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                "t"
            );
        });
    });
};

export const CreaColonne = (colonne: any[] | undefined) => {
    if (!colonne) return;
    const columns = colonne.map((col) => {
        return <Column
            caption={col.caption}
            dataField={col.dataField}
            allowEditing={col.allowEditing}
            format={col.format}
            fixed={col.fixed}
            width={col.width}
            type={col.type}
            buttons={col.buttons}
            visible={col.visible}
            dataType={col.dataType}
            groupIndex={col.groupIndex}
            sortOrder={col.sortOrder}
            cellRender={col.cellRender}
            sortIndex={col.sortIndex}
            alignment={col.alignment}
        />
    })
    return columns;
}
export const CreaColonneNew = (colonne: any[] | undefined) => {
    
    if (!colonne) return;
    const columns = colonne.map((col) => {
        return <Column
            caption={col.caption}
            dataField={col.dataField}
            allowEditing={col.allowEditing}
            format={col.format}
            fixed={col.fixed}
            width={col.width}
            type={col.type}
            buttons={col.buttons}
            visible={col.visible}
            dataType={col.dataType}
            groupIndex={col.groupIndex}
            sortOrder={col.sortOrder}
            cellRender={col.cellRender}
            sortIndex={col.sortIndex}
            alignment={col.alignment}
        />
    })
    return columns;
}


//funxione per pdf 
export const actionTables = (id:number) =>{
   RestServices().rest('POST', {idCarico:id} , urlConsultazione.hasOrdineLibero)
            .then(result => {
                if (result?.esito) {               
                    //console.log(result.response);
                    if(result.response.hasOrdineLibero){
                        window.open(
                            "https://www.cloud-farma.it/cfportal/report/get/ReportCaricoDb/1/"+id, "_blank");
                    }else{
                        window.open(
                            "https://www.cloud-farma.it/cfportal/report/get/ReportCaricoDb/0/"+id, "_blank");
                    }
                   
                    
                    
                }else{
                   // console.log(result);
                    //alert('X ORA NON FUNZIONA :)')
                }
            })  
}

export const AplicaValoreTO = (cellInfo:any,sigla:string,type = 'string',posizione = 'p' ) =>{
    
    let valoreTouse = cellInfo.value
    switch (type) {
        case 'valute':
            //valoreTouse = parseInt(valoreTouse, 10);
            if(valoreTouse){
                valoreTouse = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(valoreTouse);

            }
            if(valoreTouse === 0){
                valoreTouse = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0);
            }
            return valoreTouse;
        case 'percentuale':
            if(valoreTouse){
                valoreTouse  = new Intl.NumberFormat( 'default', {style: 'percent' , minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valoreTouse / 100);
            
            }
            return valoreTouse;

            // case 'giorniset':
            //     if(valoreTouse === 1 ){
            //         valoreTouse.push('Lunedi');            
            //     }
                //  else (valoreTouse == 2 ) {
                //     return valoreTouse.push('Lunedi');  
                // };
                
              
        default:
            break;
    }

    if(posizione == 'p'){
        
        return sigla+' '+valoreTouse
    }else{
        return valoreTouse+' '+sigla
    }
   
}

export const AplicaValoreTOGruppo = (cellInfo:any,sigla:string,type = 'string',posizione = 'p' ) =>{
    
    let valoreTouse = cellInfo
    switch (type) {
        case 'valute':
            //valoreTouse = parseInt(valoreTouse, 10);
            if(valoreTouse){
                valoreTouse = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(valoreTouse);

            }
            if(valoreTouse === 0){
                valoreTouse = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(0);
            }
            return valoreTouse;
        case 'percentuale':
            if(valoreTouse){
                valoreTouse  = new Intl.NumberFormat( 'default', {style: 'percent' , minimumFractionDigits: 2, maximumFractionDigits: 2}).format(valoreTouse / 100);
            
            }
            return valoreTouse;
        default:
            break;
    }

    if(posizione == 'p'){
        
        return sigla+' '+valoreTouse
    }else{
        return valoreTouse+' '+sigla
    }
   
}

  //  funzione in cella  da number  a giorni settimana


export  function getDayName(dayNumber:number) {
    const days = [
      "",
      "Lunedì",
      "Martedì",
      "Mercoledì",
      "Giovedì",
      "Venerdì",
      "Sabato",
      "Domenica",
    ];
    return days[dayNumber];
  }

  
  


  
  
  


