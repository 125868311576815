import FiltriVerticale, {
  ConvertForSelect,
} from "../../../dashboards/filters/FiltriVerticaleNewVersione";

export const createItemFilter = (
  newFarmaList: any,
  operatori: any,
  progetto: any,
  societa: any,
  tipologiaConFascia: any,
  tipologiaProdotto: any,
  categoria: any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },

    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

export const createItemFilterVendOpera = (
  newFarmaList: any,
  operatori: any,
  progetto: any,
  societa: any,
  tipologiaConFascia: any,
  tipologiaProdotto: any,
  categoria: any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },

    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },

    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

export const createItemFilterPopVendOpera = (
  newFarmaList: any,
  operatori: any,
  progetto: any,
  societa: any,
  tipologiaConFascia: any,
  tipologiaProdotto: any,
  categoria: any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },

    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },

    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

export const createItemFilterDettVend = (
  newFarmaList: any,
  progetto: any,
  societa: any,
  tipologiaConFascia: any,
  tipologiaProdotto: any,
  categoria: any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },

    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },

    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },

    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

export const createItemFilterVendSinte = (
  newFarmaList: any,
  progetto: any,
  societa: any,
  tipologiaConFascia: any,
  tipologiaProdotto: any,
  categoria: any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },

    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },

    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

export const createItemFilterVendRicette = (
  newFarmaList: any,
  progetto: any,
  societa: any,
  tipologiaVendita:any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
  ];
};

export const createItemFilterCrusc = (
  newFarmaList: any,
  progetto: any,
  societa: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },
  ];
};

export const createItemFilterAnalVendSottMarg = (
  newFarmaList: any,
  progetto: any,
  societa: any,
  operatori:any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
  ];
};


export const createItemFilterAnalmargMediVendi = (
  newFarmaList: any,
  progetto: any,
  societa: any,
 
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },
  ];
};

export const createItemFilterAnaVendiRicette = (
  newFarmaList: any,
  operatori: any,
  category: any,
  tipologiaSconto: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "category",
      label: "Category",
      displayExpr: "descrizione",
      dataSource: category,
      idValueGrid: "category" as any,
    },
    {
      type: "selectWithLevels",
      id: "tipologiaSconto",
      label: "Tipologia di sconto",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaSconto,
      idValueGrid: "tipologiaSconto" as any,
    },
  ];
};

export const createItemFilterAnalDett = (
  newFarmaList: any,
  settori: any,
  tipologiaVendita: any,
  gruppoMercFilters: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },

    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "codiceSettore",
      label: "Settore di inventario ",
      displayExpr: "descrizione",
      dataSource: settori,
      idValueGrid: "codiceSettore" as any,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnalVendOper = (
  newFarmaList: any,
  operatori: any,
  tipologiaVendita: any,
  gruppoMercFilters: any,
  ditte: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
    },
    {
      type: "selectWithLevels",
      id: "venditaSSN",
      label: "Tipologia di vendita",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaVendita,
      idValueGrid: "venditaSSN" as any,
    },
    {
      type: "selectWithLevels",
      id: "gruppoMerc",
      label: "Gruppo Merceologico",
      displayExpr: "descrizione",
      dataSource: gruppoMercFilters,
      idValueGrid: "gruppoMerc" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "ditta",
      label: "Ditta",
      displayExpr: "descrizione",
      dataSource: ditte,
      idValueGrid: "ditta" as any,
    },
    {
      type: "ckBox",
      idValue: "venditaDPC",
      label: "Includi DPC",
      dataSource: null,
      width: 200,
    },
    {
      type: "ckBox",
      idValue: "flagBusta",
      label: "Includi BUSTE",
      dataSource: null,
      width: 200,
    },
  ];
};

export const createItemFilterAnalScontiVendi = (
  newFarmaList: any,
  operatori: any,
  progetto: any,
  societa: any,
  categoria: any,
  tipologiaSconto: any,
  tipologiaConFascia: any,
  tipologiaProdotto:any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },
    {
      type: "selectMultiplaMoreColonNoCodice",
      noId: true,
      id: "operatori",
      label: "Operatori",
      displayExpr: "operatore",
      dataSource: operatori,
      idValueGrid: "operatori" as any,
      allowSelectAll: false
    },
    {
      type: "selectMultiplaMoreColon",
      id: "progetto",
      label: "Progetto",
      displayExpr: "descrizione",
      dataSource: progetto,
      idValueGrid: "progetto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "societa",
      label: "Societa",
      displayExpr: "descrizione",
      dataSource: societa,
      idValueGrid: "societa" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },
    {
      type: "selectWithLevels",
      id: "tipologiaSconto",
      label: "Tipologia di sconto",
      noId: true,
      displayExpr: "descrizione",
      dataSource: tipologiaSconto,
      idValueGrid: "tipologiaSconto" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

// fbi stato farmacia

export const createItemFilterStatoFarm = (
  newFarmaList: any,
  tipologiaConFascia: any,
  tipologiaProdotto: any,
  categoria: any,
  segmento: any,
  blocco1: any,
  blocco2: any
) => {
  return [
    { type: "dateRange", idValue: "periodo", width: "auto", require: true },
    {
      type: "newSelectorFarma",
      idValue: "idFar",
      label: "Farmacie ",
      displayExpr: "text",
      dataSource: ConvertForSelect(newFarmaList),
      idValueGrid: "idFar",
      require: true,
    },

    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaConFascia",
      label: "Tipologia con Fascia",
      displayExpr: "tipologiaConFascia",
      dataSource: tipologiaConFascia,
      idValueGrid: "tipologiaConFascia" as any,
    },
    {
      type: "selectWithLevels",
      noId: true,
      id: "tipologiaProdotto",
      label: "Tipologia prodotto",
      displayExpr: "tipologiaProdotto",
      dataSource: tipologiaProdotto,
      idValueGrid: "tipologiaProdotto" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "categoria",
      label: "Categoria",
      displayExpr: "descrizione",
      dataSource: categoria,
      idValueGrid: "categoria" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "segmento",
      label: "Segmento",
      displayExpr: "descrizione",
      dataSource: segmento,
      idValueGrid: "segmento" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco1",
      label: "Blocco1",
      displayExpr: "descrizione",
      dataSource: blocco1,
      idValueGrid: "blocco1" as any,
    },
    {
      type: "selectMultiplaMoreColon",
      id: "blocco2",
      label: "Blocco2",
      displayExpr: "descrizione",
      dataSource: blocco2,
      idValueGrid: "blocco2" as any,
    },
  ];
};

// export const createItemFilterAnaSconGrupMerc = (newFarmaList: any, operatori: any, gruppoMercFilters: any, tipologiaSconto:any) => {
//   return (
//     [
//       { type: "dateRange", idValue: "periodo", width: "auto", require: true },
//       {
//         type: "newSelectorFarma",
//         idValue: "idFar",
//         label: "Farmacie ",
//         displayExpr: "text",
//         dataSource: ConvertForSelect(newFarmaList),
//         idValueGrid: "idFar",
//         require: true,
//       },
//       {
//         type: "selectWithLevels",
//         noId: true,
//         id: "operatori",
//         label: "Operatori",
//         displayExpr: "operatore",
//         dataSource: operatori,
//         idValueGrid: "operatori" as any,
//       },
//       {
//         type: "selectWithLevels",
//         id: "gruppoMerc",
//         label: "Gruppo Merceologico",
//         displayExpr: "descrizione",
//         dataSource: gruppoMercFilters,
//         idValueGrid: "gruppoMerc" as any,
//       },
//       {
//         type: "selectWithLevels",
//         id: "tipologiaSconto",
//         label: "Tipologia di sconto",
//         noId: true,
//         displayExpr: "descrizione",
//         dataSource: tipologiaSconto,
//         idValueGrid: "tipologiaSconto" as any,
//       },

//     ]
//   )
// }

// export const createItemFilterAnalRicette = (newFarmaList: any, tipologiaVendita: any) => {
//   return (
//     [
//       { type: "dateRange", idValue: "periodo", width: "auto", require: true },
//       {
//         type: "newSelectorFarma",
//         idValue: "idFar",
//         label: "Farmacie ",
//         displayExpr: "text",
//         dataSource: ConvertForSelect(newFarmaList),
//         idValueGrid: "idFar",
//         require: true,
//       },
//       {
//         type: "selectWithLevels",
//         id: "venditaSSN",
//         label: "Tipologia di vendita",
//         noId: true,
//         displayExpr: "descrizione",
//         dataSource: tipologiaVendita,
//         idValueGrid: "venditaSSN" as any,
//       },

//     ]
//   )
// }
