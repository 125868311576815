import React, { useEffect } from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap';
import apple from '../../../immagini/apple.png'
import qrios from '../../../immagini/qrios.png'
import qrandroid from '../../../immagini/qrandroid.png'
import android from '../../../immagini/android.png'
import performance from '../../../immagini/performance.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css'
import { faChartLine, faCloudDownloadAlt, faFileInvoiceDollar, faHandHoldingUsd, faMobile, faMobileAlt, faStopwatch, faUserSecret, faWarehouse } from '@fortawesome/free-solid-svg-icons';




const PerformancePlus = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {


    useEffect(() => {
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    return (
        <>
            <div
                style={{ display: 'flex', fontSize: 15, justifyContent: 'center', marginLeft: -20, padding: '20px', borderRadius: 15 }} className='form-row' >

                <div style={{ flex: '2 2 calc(50% - 40px)', marginRight: 30, marginTop: 10, borderRadius: 10 }}>
                    <div style={{ display: 'flex', alignItems: 'left', justifyContent: 'center', marginBottom: '20px' }}>
                        <img
                            src={performance}
                            alt="Logo"
                            style={{ width: '150px', height: '150px', marginRight: '10px' }}
                        />
                        <div></div>
                        <h1 style={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#333', textTransform: 'uppercase', letterSpacing: '2px', marginTop: 70, marginBottom: 10 }}>
                            PERFORMANCE+ APP
                        </h1>
                    </div>
                        <Container>
                            <Row style={{ justifyContent: 'center', alignItems: 'center' }}>
                                <Col>
                                    <p style={{ textAlign: 'center' }}>
                                        <strong>Performance+</strong> offre un dashboard analitico che sintetizza i principali indicatori di performance, facilitando l'identificazione immediata di anomalie meritevoli di ulteriori analisi e azioni correttive rapide.
                                        Questa visualizzazione consente agli utenti di riconoscere prontamente gli "alert" per situazioni atipiche attraverso l'applicazione di metodi statistici di confronto con dati storici. Le funzionalita' includono:
                                    </p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
          


            <div style={{ display: 'flex', fontSize: 15, justifyContent: 'center', padding: '20px', borderRadius: 15, backgroundColor: "#255563" }} className='form-row'>
                {[
                    {
                        icon: faHandHoldingUsd,
                        title: 'Controllo Sconti Manuali',
                        description: 'Dettaglio degli sconti manuali applicati durante la vendita.'
                    },
                    {
                        icon: faChartLine,
                        title: 'Quadratura delle Giacenze',
                        description: 'Verifica delle giacenze contabili al principio e al termine della giornata.'
                    },
                    {
                        icon: faWarehouse,
                        title: 'Analisi Movimentazione di Magazzino',
                        description: 'Valutazione complessiva e categorizzazione della movimentazione di magazzino, inclusi carichi, vendite, resi, e altri.'
                    },
                    {
                        icon: faFileInvoiceDollar,
                        title: 'Monitoraggio Incassi e Vendite Sotto Margine',
                        description: 'Supervisione continua degli incassi e delle vendite che non raggiungono il margine desiderato.'
                    },
                ].map((feature, index) => (
                    <div key={index} className="form-group col-md-10" style={{ flex: '1 2 calc(50% - 90px)' }}>
                        <div className="card" style={{ borderRadius: 10, padding: '20px' }}>
                            <Container fluid="md" className="testoLogin">
                                <Row>
                                    <Col md="1" style={{ display: 'flex', alignItems: 'center' }}>
                                        <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2rem' }} className="icon" icon={feature.icon} />
                                    </Col>
                                    <Col md="11">
                                        <h5>{feature.title}</h5>
                                        <p>{feature.description}</p>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </div>
                ))}
            </div>

            <hr
                style={{ borderTop: "1px solid lightgrey", width: 900, paddingTop: 20, marginTop: 30 }}
            ></hr>


            <div style={{ fontSize: 15, padding: '20px', borderRadius: 15 }}>
                <h3 style={{ textAlign: 'left', fontSize: '1.5rem', fontWeight: 'bold', color: '#333', marginBottom: '20px' }}>
                    Scopri la Rivoluzione Mobile con Performance+
                </h3>

                <div style={{ border: '1px solid #ccc', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', width: '100%', borderRadius: 15 }}>
                    <Container fluid style={{ padding: '10px' }}>
                        <Row>
                            <Col style={{ padding: '0' }}>
                                <p style={{ textAlign: 'left', marginBottom: '20px', marginLeft: 20, marginRight: '0', padding: '20' }}>
                                    Trasforma la gestione della tua farmacia con l'app Performance+, che porta la potenza di un sistema di gestione avanzato direttamente nel palmo della tua mano. Liberati dalle catene del desktop e sperimenta la liberta' e l'efficacia della gestione mobile. Ecco i vantaggi esclusivi che solo l'app Performance+ puo' offrire:
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>

            

            <div style={{ display: 'flex', fontSize: 15, justifyContent: 'center', padding: '20px', borderRadius: 15, backgroundColor: "#255563" }} className='form-row'>
                <Col md="12">
                    <h4 style={{ color: 'white' }} className='SubTitolo'>
                        <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2rem', color: 'white' }} className="icon" icon={'function'} />
                        Sempre con Te, Ovunque Tu Sia
                    </h4>
                </Col>
                <div style={{ flex: '1 2 calc(50% - 40px)', marginRight: 30, marginTop: 10, borderRadius: 10 }}>
                   
                    <div className="card">
                        <Container fluid="md" className="testoLogin">
                            <Row>
                                <Col md="1" style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2.3rem' }} className="icon" icon={faMobile} />
                                </Col>
                                <Col md="11">
                                    <h5>Massima Mobilita'</h5>
                                    <p>L'app Performance+ elimina la necessita' di essere legati a un desktop. Che tu sia in farmacia, in viaggio o a un evento, tutte le informazioni cruciali sono a tua disposizione in tempo reale, direttamente sul tuo smartphone.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

                <div style={{ flex: '1 1 calc(50% - 30px)', marginTop: 10, borderRadius: 10 }}>
                    <div className="card">
                        <Container fluid="md" className="testoLogin">
                            <Row>
                                <Col md="1" style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2.3rem' }} className="icon" icon={faStopwatch} />
                                </Col>
                                <Col md="11">
                                    <h5>Prontezza senza Paragoni</h5>
                                    <p>Visualizza immediatamente e gestisci le emergenze con pochi tocchi, ovunque ti trovi. Con Performance+, sei sempre pronto a intervenire, garantendo un servizio eccellente e una gestione ottimale delle risorse.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>


                <div style={{ display: 'flex', fontSize: 15, justifyContent: 'center', padding: '20px', borderRadius: 15, backgroundColor: "#255563" }} className='form-row'>
                    <Col md="12">
                        <h4 style={{ color: 'white', marginLeft: -20 }} className='SubTitolo'>
                            <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2rem', color: 'white' }} className="icon" icon={'function'} />
                            Efficienza e Semplicita' a Portata di Mano
                        </h4>
                    </Col>
                    <div style={{ flex: '1 2 calc(50% - 40px)', marginRight: 30, marginTop: 10, borderRadius: 10 }}>
                    <div className="card">
                        <Container fluid="md" className="testoLogin">
                            <Row>
                                <Col md="1" style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2.3rem' }} className="icon" icon={faMobileAlt} />
                                </Col>
                                <Col md="11">
                                    <h5>Interfaccia Intuitiva</h5>
                                    <p>L'app e' progettata per garantire un'esperienza utente fluida e intuitiva, permettendoti di navigare tra le funzionalita' con semplicita' e velocita'. Meno tempo speso per capire come funzionano le cose significa piu' tempo per cio' che conta davvero.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
                <div style={{ flex: '1 1 calc(50% - 30px)', marginTop: 10, borderRadius: 10 }}>
                    <div className="card">
                        <Container fluid="md" className="testoLogin">
                            <Row>
                                <Col md="1" style={{ display: 'flex', alignItems: 'center' }}>
                                    <FontAwesomeIcon style={{ marginRight: 10, fontSize: '2.3rem' }} className="icon" icon={faUserSecret} />
                                </Col>
                                <Col md="11">
                                    <h5>Controllo Totale</h5>
                                    <p>Da monitoraggi dettagliati delle vendite e dell'inventario a analisi complesse, tutto e' gestibile con un tocco sul tuo dispositivo mobile. Performance+ ti da' il controllo completo, ottimizzando ogni aspetto della gestione della tua farmacia con tecnologia all'avanguardia.</p>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </div>

            <hr
                style={{ borderTop: "1px solid lightgrey", width: 900, paddingTop: 20, marginTop: 20 }}
            ></hr>


            <div style={{ display: "flex", fontSize: 14 }} className="App">
                <div>
                    <Container fluid="md" className="testoLogin">
                        <Row>
                            <Col md="12">
                                <h4>
                                    <FontAwesomeIcon style={{ justifyContent: "center", marginLeft: 130, marginTop: 20 }} icon={faCloudDownloadAlt} className="icon" />
                                    SCARICA
                                </h4>
                                <div style={{ marginTop: 10, marginBottom: -40, marginRight: 70 }}  >
                                    <p>l'app PERFORMANCE+ scansionando il codice QR qui sotto</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="qr-container">
                    {/* QR Code 1 */}
                    <img
                        src={apple}
                        style={{ width: '210px', height: '60px', marginTop: 52 }}
                    />
                    <div className="qr-code">
                        <img
                            src={qrios}
                            alt="QR Code 1"
                            style={{ width: '80px', height: '80px', marginTop: 42 }}
                        />

                    </div>
                    {/* QR Code 2 */}
                    <img
                        src={android}
                        style={{ width: '210px', height: '60px', marginTop: 52 }}
                    />
                    <div className="qr-code">
                        <img
                            src={qrandroid}
                            alt="QR Code 2"
                            style={{ width: '80px', height: '80px', marginTop: 42 }}

                        />

                    </div>
                </div>
            </div>

        </>
    )
}

export default PerformancePlus