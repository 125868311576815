import { DataGrid } from 'devextreme-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { cambiaFormatoValuta, parseAsMoney } from '../../funzioni/formatoVariabili'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle'
import { GiacenzaDettInterface, OrdineLiberoQuantitaInterface } from '../../interfaces/bancaDati'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { OggettoInterface } from '../../interfaces/generiche'
import { DatiTabelleGiacenzeInterface, DatiVendutoInterface } from '../../interfaces/ordineLibero'
import { RestServices } from '../../services/restServices'
import { urlOrdiniLiberi } from '../costanti'

const CreaTabelleGiacenze = (props: {
    listaFarmacie: number[],
    giacenzeGruppo: GiacenzaDettInterface[],
    datiProdotto?: OggettoInterface,
    setIsLoadVisible: (isLoadVisible: boolean) => void,
    quantitaSelezionata: string | undefined,
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    flagVisualizzaVendutoGiacenza: boolean | undefined,
    flagVisualizzaVendutoGiacenzaTotale: boolean | undefined,
    idOrdine: string | number | undefined,
}) => {
    const [giacenzaTotale, setGiacenzaTotale] = useState(0);
    //const [quantitaTotaleInOrdine, setQuantitaTotaleInOrdine] = useState(0);
    const [giacenzaFarmacie, setGiacenzaFarmacie] = useState(0);
    const [vendutoTotale, setVendutoTotale] = useState(0);
    const [vendutoMedio, setVendutoMedio] = useState(0);
    const [vendutoTotalePrec, setVendutoTotalePrec] = useState(0);
    const [datiTabelleGiacenze, setDatiTabelleGiacenze] = useState<DatiVendutoInterface[]>();
    const [mese, setMese] = useState("");
    const [tabellaEvidenziata, setTabellaEvidenziata] = useState<string>();
    const [passato, setPassato] = useState<boolean>(false);
    const [quantitaInOrdine, setQuantitaInOrdine] = useState<OrdineLiberoQuantitaInterface[]>([]);
    const [quantitaTotaleInOrdine, setQuantitaTotaleInOrdine] = useState<number>(1);

    const [chiamate, setChiamate] = useState<number>()

    const totChiamate = 2;

    useEffect(() => {
        const meseNumero = new Date().getMonth();
        const elencoMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
        setMese(elencoMesi[meseNumero]);
        //console.log(props.flagVisualizzaVendutoGiacenza)
        //console.log(props.flagVisualizzaVendutoGiacenzaTotale)
    }, [])

    useEffect(() => {
        let sommaGiacenza = 0;
        let sommaGiacenzeFarmacie = 0;
        if (props.giacenzeGruppo.length > 0) {
            //console.log(props.giacenzeGruppo)
            for (let i = 0; i < props.giacenzeGruppo.length; i++) {
                const giacVend = props.giacenzeGruppo[i].giacenzaVendibili;
                const qtaInOrdine = props.giacenzeGruppo[i].qtaInOrdine;
                //console.log(giacVend)
                if (props.giacenzeGruppo[i].idFar != 0)
                    sommaGiacenza += giacVend;
                if (props.listaFarmacie) {
                    props.listaFarmacie.forEach(farm => {
                        if (props.giacenzeGruppo[i].idFar === farm) {
                            sommaGiacenzeFarmacie += props.giacenzeGruppo[i].giacenzaVendibili;
                        }
                    })
                }
            }
            setGiacenzaTotale(sommaGiacenza);
            setGiacenzaFarmacie(sommaGiacenzeFarmacie);
        }
    }, [props.giacenzeGruppo, props.listaFarmacie])

    useEffect(() => {
        let sommaQuantitaInOrdine = 0;
        if (quantitaInOrdine.length > 0) {
            //console.log(props.quantitaInOrdine)
            for (let i = 0; i < quantitaInOrdine.length; i++) {
                const qtaInOrdine = quantitaInOrdine[i].quantita != undefined ? quantitaInOrdine[i].quantita : 0;
                sommaQuantitaInOrdine += qtaInOrdine;
            }

            setQuantitaTotaleInOrdine(sommaQuantitaInOrdine);
        }
    }, [quantitaInOrdine, props.listaFarmacie])

    useEffect(() => {
        let c = 0
        if (props.listaFarmacie && props.datiProdotto) {
            props.setIsLoadVisible(true);
            var far;
            if (Boolean(props.flagVisualizzaVendutoGiacenza))
                far = props.datiFarmacie?.map(({ ID_FAR }) => ID_FAR);
            else
                far = props.listaFarmacie;
            const param = {
                //lstFar: props.listaFarmacie,
                lstFar: far,
                codCsf: props.datiProdotto.codCsf,
                idOrdLib: props.idOrdine
            }

            

            RestServices().rest('POST', param, urlOrdiniLiberi.getVenduto)
                .then(result => {
                    if (result) {
                        
                        c = c + 1
                        //console.log(c)
                        setChiamate(c)

                        let sommaVenduto: number = 0;
                        let sommaVendutoGruppo: number = 0;
                        let sommaVendutoPrec: number = 0;
                        for (let i = 0; i < result.length; i++) {
                            //console.log(result[i]);
                            if (!result[i].magazzino && result[i].venduto[0]) {
                                sommaVendutoGruppo += result[i].venduto[0].TOT
                            }
                            if (!result[i].magazzino && result[i].venduto[0] && props.listaFarmacie.includes(result[i].idFar)) {
                                sommaVenduto += result[i].venduto[0].TOT;
                            }
                            if (!result[i].magazzino && result[i].venduto[1] && props.listaFarmacie.includes(result[i].idFar)) {
                                sommaVendutoPrec += result[i].venduto[1].TOT;
                            }
                        }
                        setDatiTabelleGiacenze(result);
                        setVendutoTotale(sommaVenduto);
                        setVendutoTotalePrec(sommaVendutoPrec);
                        var now: any = new Date();
                        var start: any = new Date(now.getFullYear(), 0, 0);
                        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diff / oneDay);
                        if (sommaVendutoGruppo > 0)
                            setVendutoMedio(Math.round((sommaVendutoGruppo / day) * 30));
                        else
                            setVendutoMedio(0);
                        //props.setIsLoadVisible(false);
                    } else {
                        //props.setIsLoadVisible(false);
                    }
                })


            RestServices().rest('POST', param, urlOrdiniLiberi.getQuantitaOrdini).then(response => {
                props.setIsLoadVisible(true);
                if (response) {
                    c = c +1
                    //console.log(c)
                     setChiamate(c)
                    if (response.quantitaTotale)
                        setQuantitaTotaleInOrdine(response.quantitaTotale);
                    else
                        setQuantitaTotaleInOrdine(0);

                    if (response.lstFarQuantita instanceof Array) {
                        setQuantitaInOrdine(response.lstFarQuantita);
                    }

                    //props.setIsLoadVisible(false);
                }

            })



        }
    }, [props.listaFarmacie, props.datiProdotto])

    useEffect(() => {
        if (props.quantitaSelezionata) setTabellaEvidenziata(props.quantitaSelezionata)
    }, [props.quantitaSelezionata])

    useEffect(() => {
        //console.log("chiamate")
        //console.log(chiamate)
        if (chiamate == totChiamate) {
            //console.log("chiamate stop")
            props.setIsLoadVisible(false);
        }
            
        
            
    }, [chiamate])

    return (
        <>
            {(datiTabelleGiacenze && props.datiProdotto) &&
                <>
                <Col sm="12" className="descrProdSelezionato">
                    <span><b>{props.datiProdotto.prodotto} - {props.datiProdotto.codProd} - Fascia {props.datiProdotto.codMut ? props.datiProdotto.codMut + " - " : "N.D. - "}   </b></span>
                        <span>Venduto totale anno prec.: </span>
                        <span><b>{parseAsMoney(vendutoTotalePrec)}&nbsp;&nbsp;&nbsp;-</b></span>
                        <span>&nbsp;&nbsp;&nbsp;Venduto totale anno in corso: </span>
                        <span><b>{parseAsMoney(vendutoTotale)}&nbsp;&nbsp;&nbsp;-</b></span>
                        <span>&nbsp;&nbsp;&nbsp;Venduto medio mensile gruppo: </span>
                        <span><b>{parseAsMoney(vendutoMedio)}&nbsp;&nbsp;&nbsp;-</b></span>
                        <span>&nbsp;&nbsp;&nbsp;Giacenza farmacie selezionate: </span>
                        <span><b>{parseAsMoney(giacenzaFarmacie)}&nbsp;&nbsp;&nbsp;-</b></span>
                        <span>&nbsp;&nbsp;&nbsp;Giacenza gruppo (vendibile): </span>
                        <span><b>{parseAsMoney(giacenzaTotale)}&nbsp;&nbsp;&nbsp;-</b></span>
                        <span>&nbsp;&nbsp;&nbsp;Qta totale in ordine: </span>
                        <span><b>{parseAsMoney(quantitaTotaleInOrdine == undefined ? 0 : quantitaTotaleInOrdine)}</b></span>
                    </Col>
                    {datiTabelleGiacenze.map((giacenza: DatiVendutoInterface, index: number) => {
                        if (giacenza.idFar != 0 )
                        return (
                                    <Col sm="3" key={giacenza.idFar} className="colonnaTabVenduto">
                                    <Tabelle
                                        giacenza={props.giacenzeGruppo}
                                        datiTabelleGiacenze={giacenza}
                                        index={index}
                                        mese={mese}
                                        tabellaEvidenziata={tabellaEvidenziata}
                                        quantita={quantitaInOrdine}
                                     />
                            </Col>
                            

                        )


                    })}

                 {datiTabelleGiacenze.map((giacenza: DatiVendutoInterface, index: number) => {
                     if (giacenza.idFar != 0 && giacenza.trasferito && giacenza.trasferito.length > 0)
                    return (
                        <Col sm="3" key={giacenza.idFar} className="colonnaTabVenduto">
                            <TabelleTrasferito
                                giacenza={props.giacenzeGruppo}
                                datiTabelleGiacenze={giacenza}
                                index={index}
                                mese={mese}
                                tabellaEvidenziata={tabellaEvidenziata}
                                quantita={ quantitaInOrdine}
                            />
                        </Col>
                    )


                 })}
                {/* 
                {Boolean(props.flagVisualizzaVendutoGiacenzaTotale) === true && datiTabelleGiacenze.map((giacenza: DatiVendutoInterface, index: number) => {
                    
                    if (giacenza.idFar === 0 && giacenza.venduto && giacenza.venduto.length > 0)

                                return (
                                    <Col sm="3" key={giacenza.idFar} className="colonnaTabVenduto">
                                        <Tabelle
                                            giacenza={props.giacenzeGruppo}
                                            datiTabelleGiacenze={giacenza}
                                            index={index}
                                            mese={mese}
                                            tabellaEvidenziata={tabellaEvidenziata}
                                        />
                                    </Col>
                                )

                })}
                */ }
                </>
            }
        </>
    )
}

export default CreaTabelleGiacenze;

const Tabelle = (props: {
    giacenza: GiacenzaDettInterface[],
    datiTabelleGiacenze: DatiVendutoInterface,
    index: number,
    mese: string,
    tabellaEvidenziata: string | undefined,
    quantita: OrdineLiberoQuantitaInterface[],
}) => {
    const [datiGiacenzaFarm, setDatiGiacenzaFarm] = useState<GiacenzaDettInterface>();
    const [datiGriglia, setDatiGriglia] = useState<DatiTabelleGiacenzeInterface[]>();
    const [color, setColor] = useState("");
    const [coloreScritta, setColoreScritta] = useState("black");

    useEffect(() => {
        if (props.giacenza.length > 0 && props.datiTabelleGiacenze)
            for (let i = 0; i < props.giacenza.length; i++) {
                if (props.giacenza[i].idFar === props.datiTabelleGiacenze.idFar) {
                    setDatiGiacenzaFarm(props.giacenza[i])
                }
            }
    }, [props.giacenza])

    useEffect(() => {
        setDatiGriglia(props.datiTabelleGiacenze.venduto)
    }, [props.datiTabelleGiacenze])

    const colonneTabGiacenza = useMemo(() => CreaColonne([
        { caption: "", dataField: "ANNO", width: 35 },
        { caption: "Tot", dataField: "TOT", width: 35 },
        { caption: "Gen", dataField: "Gennaio", },
        { caption: "Feb", dataField: "Febbraio", },
        { caption: "Mar", dataField: "Marzo", },
        { caption: "Apr", dataField: "Aprile", },
        { caption: "Mag", dataField: "Maggio", },
        { caption: "Giu", dataField: "Giugno", },
        { caption: "Lug", dataField: "Luglio", },
        { caption: "Ago", dataField: "Agosto", },
        { caption: "Set", dataField: "Settembre", },
        { caption: "Ott", dataField: "Ottobre", },
        { caption: "Nov", dataField: "Novembre", },
        { caption: "Dic", dataField: "Dicembre", }
    ]), [])

    useEffect(() => {

        if (!props.tabellaEvidenziata || (props.tabellaEvidenziata && (props.tabellaEvidenziata !== props.datiTabelleGiacenze.idFar.toString() || props.tabellaEvidenziata === " "))) {
            if (props.index % 2 === 0) {
                setColor("lightgoldenrodyellow")
                setColoreScritta("black");
            } else {
                setColor("paleturquoise");
                setColoreScritta("black");
            }
        } else {
            if (props.tabellaEvidenziata === props.datiTabelleGiacenze.idFar.toString()) {
                setColor("red");
                setColoreScritta("white");
            }
        }
    }, [props.index, props.tabellaEvidenziata])

    const onCellPrepared = useCallback((e: any) => {
        e.cellElement.style.fontWeight = "900";
        if (e.column.dataField === props.mese) {
            e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
        }
    }, [])

    //{datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)} - Qta In Ordine : {parseAsMoney(props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar).length > 0 ? props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar)[0].quantita : 0)}</b></div>}
    return (
        <>
            
            {datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": datiGiacenzaFarm.idFar === 0 ? "rgb(150, 255, 164)" : color, "color": coloreScritta }}><b>{datiGiacenzaFarm.idFar == 8847 ? 'NOX - Mag. [ECommerce]' : datiGiacenzaFarm.descrFarm} - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)} - Qta In Ordine : {parseAsMoney(props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar).length > 0 ? props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar)[0].quantita : 0)}</b></div>}
            {datiGriglia && <DataGrid
                id={props.datiTabelleGiacenze?.idFar.toString()}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                dataSource={datiGriglia}
                onCellPrepared={onCellPrepared}
            >
                {colonneTabGiacenza}
            </DataGrid>
            }

        </>
    )
}

const TabelleTrasferito = (props: {
    giacenza: GiacenzaDettInterface[],
    datiTabelleGiacenze: DatiVendutoInterface,
    index: number,
    mese: string,
    tabellaEvidenziata: string | undefined,
    quantita: OrdineLiberoQuantitaInterface[],
}) => {
    const [datiGiacenzaFarm, setDatiGiacenzaFarm] = useState<GiacenzaDettInterface>();
    const [datiGrigliaTrasferito, setDatiGrigliaTrasferito] = useState<DatiTabelleGiacenzeInterface[]>();
    const [color, setColor] = useState("");
    const [coloreScritta, setColoreScritta] = useState("black");

    useEffect(() => {
        if (props.giacenza.length > 0 && props.datiTabelleGiacenze)
            for (let i = 0; i < props.giacenza.length; i++) {
                if (props.giacenza[i].idFar === props.datiTabelleGiacenze.idFar) {
                    setDatiGiacenzaFarm(props.giacenza[i])
                }
            }
    }, [props.giacenza])

    useEffect(() => {
        setDatiGrigliaTrasferito(props.datiTabelleGiacenze.trasferito)
    }, [props.datiTabelleGiacenze])

    const colonneTabGiacenza = useMemo(() => CreaColonne([
        { caption: "", dataField: "ANNO", width: 35 },
        { caption: "Tot", dataField: "TOT", width: 35 },
        { caption: "Gen", dataField: "Gennaio", },
        { caption: "Feb", dataField: "Febbraio", },
        { caption: "Mar", dataField: "Marzo", },
        { caption: "Apr", dataField: "Aprile", },
        { caption: "Mag", dataField: "Maggio", },
        { caption: "Giu", dataField: "Giugno", },
        { caption: "Lug", dataField: "Luglio", },
        { caption: "Ago", dataField: "Agosto", },
        { caption: "Set", dataField: "Settembre", },
        { caption: "Ott", dataField: "Ottobre", },
        { caption: "Nov", dataField: "Novembre", },
        { caption: "Dic", dataField: "Dicembre", }
    ]), [])

    useEffect(() => {

        if (!props.tabellaEvidenziata || (props.tabellaEvidenziata && (props.tabellaEvidenziata !== props.datiTabelleGiacenze.idFar.toString() || props.tabellaEvidenziata === " "))) {
            if (props.index % 2 === 0) {
                setColor("lightgoldenrodyellow")
                setColoreScritta("black");
            } else {
                setColor("paleturquoise");
                setColoreScritta("black");
            }
        } else {
            if (props.tabellaEvidenziata === props.datiTabelleGiacenze.idFar.toString()) {
                setColor("red");
                setColoreScritta("white");
            }
        }
    }, [props.index, props.tabellaEvidenziata])

    const onCellPrepared = useCallback((e: any) => {
        e.cellElement.style.fontWeight = "900";
        if (e.column.dataField === props.mese) {
            e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
        }
    }, [])

    //{(datiGrigliaTrasferito && datiGrigliaTrasferito.length && datiGiacenzaFarm) && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} [Trasferito] - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)}</b></div>}
    return (
        <>

            
            {datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} [Trasferito] - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)} - Qta In Ordine : {parseAsMoney(props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar).length > 0 ? props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar)[0].quantita : 0)}</b></div>}
            {(datiGrigliaTrasferito && datiGrigliaTrasferito.length > 0) && <DataGrid
                id={props.datiTabelleGiacenze?.idFar.toString()}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                dataSource={datiGrigliaTrasferito}
                onCellPrepared={onCellPrepared}
            >
                {colonneTabGiacenza}
            </DataGrid>
            }

        </>
    )
}