import React, { useState,  useEffect } from "react"
import { Popup,  ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import {  Loading } from '../../../widget/Notifications'
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import NewTabella from "../../../widget/nuova_tabella"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import {  legendByarray } from '../../../funzioni/formatoVariabili'

import "./style.css"

const columns = [
    { caption: "Farmacia", dataField: "descrix",width:300 },
    { caption: "Abilitato", dataField: "abilitato",width:70 },
    { caption: "Operatore", dataField: "operatore",width:80 },
    { caption: "Nome", dataField: "nome" },  
    { caption: "Codice Fiscale", dataField: "codiceFiscale" },
   
];

interface DialogProps {
  open: boolean;
  farmacie: any;
  edit: any;
  ricarica: () => void;
  close: () => void;
}

export const DialogVisuOper: React.FC<DialogProps> = ({
  open,
  close,
  farmacie,
  edit,
}) => {
  const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([]);
  const [openD, setOpenD] = useState <boolean>(false);
  const [loading, setLoading] = useState <boolean>(false);
  const auth = useAuth(urlConsultazione.auth);
  const [objctTOsave, setObjctTOsave] = useState<any>({});


const getListFarm = () =>{
  if (auth) {
     return RestServices().rest('POST', {} , urlConsultazione.getListFar)
  }
}

const listaOperatoriFarmaciaOk = async(selexionate:any) =>{
  let farmacieLista = await getListFarm()
  setTabellaPrincipale([]) 
  if (farmacieLista?.esito) { 
       if(farmacieLista){
          let params = {} 
          let listaTabella = await RestServices().rest('POST', params , urlConsultazione.getListOperatore)
              if (listaTabella?.esito) {
                  if(listaTabella.response.length > 0){
                    let newLista:any = []
                  listaTabella.response.map((val:any)=>{
                    if(selexionate?.includes(val.id)){
                      newLista.push(val)
                    }
                    
                  })

                  if(newLista.length > 0){
                    let valoreFormatt = legendByarray(
                      newLista,
                      farmacieLista.response,
                      'idFar',
                      'idFarmacia',
                      'descrix',
                      ['descrizione'])
                 setTabellaPrincipale(valoreFormatt) 
                  }else{
                    
                  }
                  }              
              }else{
                  notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)

              }
          
       }
        }else{
            if(farmacieLista?.message){
                notify({ position: "center", width: "auto", message: farmacieLista.message}, "error", 4000)
                
            }   
       }
    }



      useEffect(() => {
        if (edit && openD) {
          listaOperatoriFarmaciaOk(edit.operatoriFarmacia ? edit.operatoriFarmacia : [])
          let objTo = { ...objctTOsave };
          objTo.nome = edit.nome;
          objTo.operatore = edit.operatore;
          objTo.codiceFiscale = edit.codiceFiscale;
          setObjctTOsave(objTo);
        }
      }, [edit, openD]);

      useEffect(() => {
        setOpenD(open);
      }, [open]);

      useEffect(() => {
      }, [auth, farmacie ]);

  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'60%'}
      height={'80%'}
      showTitle={true}
      title="Visualizzazione Operatore gruppo"
    >
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Chiudi",
          onClick: () => close(),
        }}
      />
     
      <Loading isVisible={loading} />  

      <ScrollView height="100%"  width="100%">
      <div className='form-row '> 
      <div className="form-group col-md-6">
          <label> OPERATORE </label>
          <TextBox disabled value={objctTOsave.operatore} />
        </div>

        <div className="form-group col-md-6">
          <label> NOME </label>
          <TextBox disabled value={objctTOsave.nome} />
        </div>
        </div>

        <div style={{marginTop:10 }}>
          <label> CODICE FISCALE </label>
          <TextBox disabled value={objctTOsave.codiceFiscale} />
        </div>
         <div style={{marginTop:20,marginBottom:10,textAlign:'center'}} >
                        <label> ASSOCIAZIONI </label> 
                </div>
        
          <NewTabella
            idTabella="visua_oper_gruppo_popup"
            sync
            colonne={columns}
            dataSource={tabellaPrincipale}
            filterRow
            exportT={{
              enabled: false,
              fileName: "list_oper_gruppo",
            }}
          />
        
      </ScrollView>
    </Popup>
  );
};
