import notify from "devextreme/ui/notify";
import React, { useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { OggettoInterface } from "../../../interfaces/generiche";
import { RestServices } from "../../../services/restServices";
import { SelezioneMultiplaFarmacie } from '../../../widget/Select';
import { urlPrezzoPromozione } from "../../costanti";


export const RifermentoDialog = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    isDialogImpostazioniRiferimento: boolean,
    setIsDialogImpostazioniRiferimento: (isDialogImpostazioniPrezzoPromozione: boolean) => void
    handleCloseDialogRiferimento: () => void,
    functionNext: () => void,
    filtri: OggettoInterface,
    impostaFiltri: (newFiltro: OggettoInterface) => void;

}) => {


    const [filtriDialog, setFiltriDialog] = useState<OggettoInterface>({});

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtriDialog, ...newFiltro }
        setFiltriDialog(newF);
    }

    const handleClose = () => {
        props.setIsDialogImpostazioniRiferimento(false);
    }


    const salvaImpostazioniPrezzoPromozione = () => {

        let idFar = Array.isArray(filtriDialog.idFarRiferimento) && filtriDialog.idFarRiferimento.length > 0
            ? filtriDialog.idFarRiferimento[0]
            : undefined;

        if (!idFar) {

            notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, "error", 4000);

        }
        else {
            RestServices().rest('POST', idFar, urlPrezzoPromozione.updateFarmaciaRiferimento).then(result => {

                if (result.response >= 0) {

                    notify({ position: "center", width: "auto", message: "Salvataggio eseguito con successo" }, "success", 2000)
                    props.impostaFiltri({ idFarRiferimento: idFar })
                    props.functionNext();

                } else {
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                }
            })
        }
    }

    const colonneGridGeneriche = CreaColonne([
        { dataField: "ID_FAR", caption: "id", visible: false },
        { dataField: "DESCRIZIONE", caption: "descrizione", sortOrder: "asc" },
        { dataField: "CODICE", caption: "codice", width: 120 }
    ])

    return (
        <>
            <Modal
                show={props.isDialogImpostazioniRiferimento}
                onHide={handleClose}
                dialogClassName="dialogRiferimento"
                size="lg"
                centered={false}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h2 className="main-title"> Selezione Farmacia di Riferimento </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Row className="rigaCreaNuovoOrdLib">

                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span style={{ fontSize: 14 }}>Farmacie</span>
                        </Col>
                        <Col sm="12">

                            <SelezioneMultiplaFarmacie
                                dataSource={props.datiFarmacie}
                                id="idFarRiferimento"
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGeneriche}
                                selection="single"
                                primaSelezionata={false}
                                valueGrid={props.filtri?.idFarRiferimento || []}
                            />
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="PopFirst-btn" variant="success" onClick={salvaImpostazioniPrezzoPromozione}>
                        Salva
                    </Button>
                    <button className="PopSecond-btn" onClick={handleClose}>
                        Chiudi
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}