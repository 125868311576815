import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlCommon, urlConsultazione, urlFidelityBe, urlMonitoraggio } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { trasformaDateTimeInLocale, trasformaDateTimeInLocaleNewF } from '../../funzioni/formatoVariabili';
import { Filtri } from '../consultazione/interface'
import { ConvertForSelect } from '../dashboards/filters/FiltriVerticaleNewVersione'
import '../contenuti.css'



const AnalisiRepDCR = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {



    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [paramsFilter, setParamsFilter] = useState<Filtri>();
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [idFarList, setidFarList] = useState([])
    const [objtipo, setObjtipo] = useState<any>(null);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [newFarmaList, setNewFarmaList] = useState([])
    const [flagScaduti, setFlagScaduti] = useState([])
    const [reportList, setReportList] = useState([])
    const [tabella, setTabella] = useState([])

    const firstAuth = useAuth(urlConsultazione.auth);
    const auth = useAuth(urlMonitoraggio.auth);

    const columns = [
        { caption: "Codice Farmacia", dataField: "codiceFarmacia", fixed: true, width: 160 },
        { caption: "Descrizione Farmacia", dataField: "descrizioneFarmacia", fixed: true, width: 150 },
        { caption: "Anno", dataField: "anno", width: 150 },
        { caption: "Mese", dataField: "mese", width: 150 },
        { caption: "Ricette Tot.", dataField: "ricetteTotali", width: 200 },
        { caption: "1_Spec. Ricette", dataField: "specialitaRicette", width: 200 },
        { caption: "1_Spec. Lordo", dataField: "specialitaLordo", width: 300 },
        { caption: "2_Oss. Ricette", dataField: "ossigenoRicette", width: 200 },
        { caption: "2_Oss Lordo", dataField: "ossigenoLordo", width: 200 },
        { caption: "3_Galenici Ricette", dataField: "galeniciRicette", width: 200 },
        { caption: "3_Galenici Lordo", dataField: "galeniciLordo", width: 200 },
        { caption: "3b_Farmaci Fascia C Ricette", dataField: "farmaciFasciaCRicette", width: 200 },
        { caption: "3b_Farmaci Fascia C Lordo", dataField: "farmaciFasciaCLordo", width: 200 },
        { caption: "4_Imp Ricette Netto IVA", dataField: "impRicetteNettoIVA", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "5_Quota Ind ED str IVA", dataField: "quotaIndEDstrIVA", width: 200 },
        { caption: "6_N Confezioni Med", dataField: "nconfezioniMed", width: 200 },
        { caption: "6b_N Confezioni Trasp", dataField: "nconfezionilstTrasp", width: 200 },
        { caption: "7_Quota Sei", dataField: "quotaSei", width: 200 },
        { caption: "8_N Qouta 55 Prz 4", dataField: "nquota55Prz4", width: 200 },
        { caption: "8_Imp Qouta 55 Prz 4", dataField: "impQuota55Prz4", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "9_N Quota 166 Prz 4 to 11", dataField: "nquota166Prz4to11", width: 200 },
        { caption: "9_Imp Quota 166 Prz 4 to 11", dataField: "impQuota166Prz4to11", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "10_N Quota 250 Prz 11", dataField: "nquota250Prz11", width: 200 },
        { caption: "10_Imp Quota 250 Prz 11", dataField: "impQuota250Prz11", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "11_N Quota 10 Lista Trasparenza", dataField: "nquota10ListaTrasparenza", width: 200 },
        { caption: "11_Imp Quota 10 Lista Trasparenza", dataField: "impQuota10ListaTrasparenza", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "12_N Quota 120 Fatturato SSN", dataField: "nquota120FatturatoSSN", width: 200 },
        { caption: "12_Imp Quota 120 Fatturato SSN", dataField: "impQuota120FatturatoSSN", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "13_N Quota 58 Non Sussidiate", dataField: "nquota58NonSussidiate", width: 200 },
        { caption: "13_Imp Quota 58 Non Sussidiate", dataField: "impQuota58NonSussidiate", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "14_N Quota 62 Sussidiate", dataField: "nquota62Sussidiate", width: 200 },
        { caption: "14_Imp Quota 62 Sussidiate", dataField: "impQuota62Sussidiate", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "15_Tot. Quote Spettanza LDB 2024", dataField: "totaleQuoteSpettanzaLDB2024", width: 200 },
        { caption: "16_Imp. Specialita Netto IVA", dataField: "importoSpecialitaNettoIVA", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "17_Imp. Specialita Lordo IVA", dataField: "importoSpecialitaLordoIVA", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "17b_Sconto Det AIFA", dataField: "scontoDetAIFA", width: 200 },
        { caption: "18_Imp. Ricette Lordo IVA", dataField: "importoRicetteLordoIVA", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "18a_Tratt. ENPAF", dataField: "trattenutaENPAF", width: 200 },
        { caption: "18b_Tratt. Convenzionali", dataField: "trattenutaConvenzionali", width: 200 },
        { caption: "18c_Tratt. Contributi Federfarma", dataField: "trattenutaContributiFederfarma", width: 200 },
        { caption: "18d_Tratt. Contributi Assofarm", dataField: "trattenutaContributiAssofarm", width: 200 },
        { caption: "18e_Tratt. Altro", dataField: "trattenutaAltro", width: 200 },
        { caption: "19_Tot. Trattenute", dataField: "totaleTrattenute", width: 200 },
        { caption: "20_Num Doc Integrativi", dataField: "numDocIntegrativi", width: 200 },
        { caption: "20_Lordo Integrativi", dataField: "lordoIntegrativi", width: 200 },
        { caption: "20a_Imp. Presidi IVA 4", dataField: "importoPresidiIVA4", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "20b_Imp. Presidi IVA 10", dataField: "importoPresidiIVA10", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "20c_Imp. Presidi IVA 22", dataField: "importoPresidiIVA22", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "20d_Imp. Presidi IVA 5", dataField: "importoPresidiIVA5", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "21_Imp. Netto Trattenute IVA", dataField: "importoNettoTrattenuteIVA", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "22_Imp. Ticket Riscosso", dataField: "importoTicketRiscosso", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "22a_Qot Fissa Ticket", dataField: "quotaFissaTicket", width: 200 },
        { caption: "22b_Qot Assistito Ticket", dataField: "quotaAssistitoTicket", width: 200 },
        { caption: "23_Acc. Mese Precedente", dataField: "accontoMesePrecedente", width: 200 },
        { caption: "24_Acc. Mese Corrente", dataField: "accontoMeseCorrente", width: 200 },
        { caption: "25_Rettifiche Accredito", dataField: "rettificheAccredito", width: 200 },
        { caption: "26_Rettifiche Addebito", dataField: "rettificheAddebito", width: 200 },
        { caption: "27_Diritto Addizionale Notturno", dataField: "dirittoAddizionaleNotturno", width: 200 },
        { caption: "28_Onorario DPC", dataField: "onorarioDPC", width: 200 },
        { caption: "28a_N Medicinali N Conf", dataField: "", width: 200 },
        { caption: "28a_Imp. Medicinali N Conf", dataField: "importoMedicinaliNConf", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "28b_N Presidi Diabetici", dataField: "", width: 200 },
        { caption: "28b_Imp. Presidi Diabetici", dataField: "importoPresidiDiabetici", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "28c_N Presidi Incontinenza Cons", dataField: "", width: 200 },
        { caption: "28_Imp. Presidi Incontinenza Cons", dataField: "importoPresidiIncontinenzaCons", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "28d_N Pesidi Incontinenza Resi", dataField: "", width: 200 },
        { caption: "28d_Imp. Presidi Incontinenza Resi", dataField: "importoPresidiIncontinenzaResi", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "29_Remuner Att SSR", dataField: "remunerAttSSR", width: 200 },
        { caption: "30_Rettifiche Accr DPC", dataField: "rettificheAccrDPC", width: 200 },
        { caption: "31_Rettifiche Addbt DPC", dataField: "rettificheAddbtDPC", width: 200 },
        { caption: "32_Imp. IVA Fatt Split", dataField: "importoIVAFattSplit", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "33_Imp. Netto Da Liquidare", dataField: "importoNettoDaLiquidare", width: 200, format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Prosp Iva 4 Lordo", dataField: "prospIva4Lordo", width: 200 },
        { caption: "Prosp Iva 4 Imposta", dataField: "prospIva4Imposta", width: 200 },
        { caption: "Prosp Iva 4 Netto", dataField: "prospIva4Netto", width: 200 },
        { caption: "Prosp Iva 5 Lordo", dataField: "prospIva5Lordo", width: 200 },
        { caption: "Prosp Iva 5 Imposta", dataField: "prospIva5Imposta", width: 200 },
        { caption: "Prosp Iva 5 Netto", dataField: "prospIva5Netto", width: 200 },
        { caption: "Prosp Iva 10 Lordo", dataField: "prospIva10Lordo", width: 200 },
        { caption: "Prosp Iva 10 Imposta", dataField: "prospIva10Imposta", width: 200 },
        { caption: "Prosp Iva 10 Netto", dataField: "prospIva10Netto", width: 200 },
        { caption: "Prosp Iva 22 Lordo", dataField: "prospIva22Lordo", width: 200 },
        { caption: "Prosp Iva 22 Imposta", dataField: "prospIva22Imposta", width: 200 },
        { caption: "Prosp Iva 22 Netto", dataField: "prospIva22Netto", width: 200 },
        { caption: "Prosp DL 156 Lordo", dataField: "prospDL156Lordo", width: 200 },
        { caption: "Prosp DL 156 Imposta", dataField: "prospDL156Imposta", width: 200 },
        { caption: "Prosp DL 156 Netto", dataField: "prospDL156Netto", width: 200 },
        { caption: "Prosp 4 Nomenclatura Lordo", dataField: "prosp4NomenclaturaLordo", width: 200 },
        { caption: "Prosp 4 Nomenclatura Imposta", dataField: "prosp4NomenclaturaImposta", width: 200 },
        { caption: "Prosp 4 Nomenclatura Netto", dataField: "prosp4NomenclaturaNetto", width: 200 },
        { caption: "Prosp 4 Diabetico Lordo", dataField: "prosp4DiabeticoLordo", width: 200 },
        { caption: "Prosp 4 Diabetico Imposta", dataField: "prosp4DiabeticoImposta", width: 200 },
        { caption: "Prosp 4 Diabetico Netto", dataField: "prosp4DiabeticoNetto", width: 200 },
        { caption: "Prosp 10 Ticket Lordo", dataField: "prosp10TicketLordo", width: 200 },
        { caption: "Prosp 10 Ticket Imposta", dataField: "prosp10TicketImposta", width: 200 },
        { caption: "Prosp 10 Ticket Netto", dataField: "prosp10TicketNetto", width: 200 },
        { caption: "Prosp 22 Dir Nttr Lordo", dataField: "prosp22DirNttrLordo", width: 200 },
        { caption: "Prosp 22 Dir Nttr Imposta", dataField: "prosp22DirNttrImposta", width: 200 },
        { caption: "Prosp 22 Dir Nttr Netto", dataField: "prosp22DirNttrNetto", width: 200 },
        
    ];


    const itemsFields = [
        { type: 'selectFarmacie', idValue: 'idFarmacia', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 370 },
        { type: 'date', idValue: 'data', label: 'Mese', format: "dd/MM/yyyy HH:mm:ss", dataSource: null, require: true, width: 250 },
    ]


    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }

        if (params.idFarmacia) {

            params.idFarmacia = params.idFarmacia

        }

        if (params.data) {
            params.data = trasformaDateTimeInLocaleNewF(params.data, true)
        }

        let farList: number[] = []
        params.idFarmacia.idFar.forEach((element: any) => {
            farList.push(element)
        });


        const model = {
            idFarList: farList,
            data: params.data
        }

        setParamsFilter(params)
        setIsVisible(true)
     

        if (auth) {
            RestServices().rest('POST', model, urlMonitoraggio.getDCRSmart)
                .then(result => {
                    setIsVisible(false);
                   

                    if (result) {
                        setIsVisible(false);
                        setReportList(result)
                        //console.log(result)

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })
        }
    }

    const farmaList = () => {

        let model = {}

        if (firstAuth) {
            RestServices()
                .rest('POST', model, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {

                        setNewFarmaList(result.response);

                        let farList: number[] = []
                        result.response.forEach((element: any) => {
                            farList.push(element)
                        });




                    } else {

                        // handle error
                    }
                })
        }
    }

    useEffect(() => {
        farmaList();
    }, [firstAuth]);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    return (
        <>
            {loadPanel}
            <div className='title'>
                <h3> Report DCR <span className='subt' > Estrazione distinte riepilogative </span> </h3>
            </div>
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} /> </div>

            <NewTabella
                idTabella='AnalisiReportDCRSmart'
                height={"98vh"}
                sync
                colonne={columns}
                dataSource={reportList}
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "AnalisiRepDCRSmart"
                }}
                activeSaver
                columnC

            />


        </>
    )
}

export default AnalisiRepDCR


