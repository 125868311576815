import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { SelectBox } from 'devextreme-react/select-box'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import Button from 'devextreme-react/button'

import Select from 'react-select'
import {
    Validator,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    EmailRule  
  } from 'devextreme-react/validator';


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

interface Profilo {
    id:string
    nomeProfilo:string
}

export const DialogNewProfilo:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [profiloList,setProfiliUtenti] = useState<any>([])
    const [id,setId] = useState<number|null>(null)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [loading,setLoading] = useState<boolean>(false)
    const [profili,setProfili] = useState<Profilo[]>([])
    const [farma,setFarma] = useState<[]>([])


    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({}) 
        }

    },[edit])

    useEffect(()=>{
        if(auth && open){
            //farmaList()
            getProfili()
            getProfUtenti()  
        }
       
    },[auth,open])

    const getProfUtenti = () =>{
        //setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getProfiliUtenti)
                .then(result => {
                    if (result?.esito) {
                        setProfiliUtenti(result.response)
                        //setTabellaPrincipale(result.response)
                        setLoading(false)
                        //console.log(result);
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })      
         }
    }



    const getProfili = () =>{
       RestServices().rest('POST', {} , urlConsultazione.getProfiliUtenti)
            .then(result => {
               if(result.esito){
                    setProfili(result.response)
                 
               }
               else{
                //console.log(result);
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }
    
            }) 
   }


   const ConvertForSelect = (arr:any) =>{
    let newArr:any = []
    arr.forEach((element:any) => {
        //console.log(element);
        newArr.push({value:element.idFar,label:element.descrizione})
        
    });
        return newArr
    }

    const cambiaValore = (tipo:string,value:string) =>{
            let objTo = {...objctTOsave}
            objTo[tipo] = value
            //console.log('objTo');
            //console.log(objTo);
            
            setObjctTOsave(objTo)
            //console.log(objTo);   
            if(objTo.abi?.length > 3 && objTo.cab?.length > 3 && objTo.ragioneSociale?.length > 4  && objTo.filiale?.length  > 3  ){
                setDisableButton(false)
            }else{
                setDisableButton(true)
        }
    }
           
       

   const CreateUpdateProfilo = () =>{
            //console.log('pollo');
            

        if(!objctTOsave.nome){
            notify({ position: "center", width: "auto", message: "devi inserire il nome"}, "error", 4000)

            
        }else{
            let objToUseEdit:any = {
                "nome": objctTOsave.nome,
                
            }
            if(objctTOsave.fromID){
                objToUseEdit.objToUseEdit =  objToUseEdit.objToUseEdit
            }
    
            
            RestServices().rest('POST', objToUseEdit , urlConsultazione.createProfili)
            .then(result => {
                if (result?.esito) { 
                 ricarica()  
                                        
                }else{                              
                   setLoading(false)
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }
            })
        }    
    
          

    
}



    useEffect(()=>{
        setOpenD(open)
    },[auth])


    useEffect(()=>{
        setOpenD(open)
    },[open])



    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'50%'}
          showTitle={true}
          title="Gestione Profilo"
        >
         <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
           <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'CHIUDI',
                onClick: ()=>{close()},
              }}
          />

        
               <ScrollView width='100%' height='100%'>

                    <form action="your-action" onSubmit={(e)=>{
                              //
                               CreateUpdateProfilo()
                               e.preventDefault();
                                
                            }}>
                                
                    <div className='form-row '> 
                <div className="form-group col-md-6">
                      <label> NOME PROFILO </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.nome}  onValueChanged={(v)=>cambiaValore('nome',v.value)}    placeholder="Campo obbligatorio" >
                    </TextBox> 
                   </div>
                   <div className="form-group col-md-6">
                   <label> COPIA DA </label> 
                   <SelectBox dataSource={profiloList}  showDataBeforeSearch={true}  valueExpr="id"  displayExpr="nomeProfilo"   valueChangeEvent="keyup" showClearButton={false}  placeholder="Seleziona il profilo"
                   onValueChanged={(e:any)=>cambiaValore('fromID',e.value)} 
                      /> 
                     
                   </div>
                   </div>
                   



             <div>
           </div>

           <div style={{position:'fixed',bottom:22,right:130,zIndex:2, width:100}}>
                  <Button
                    id="button"
                    text={"SALVA"}
                    type="success"
                    width={200}  
                    onClick={()=>CreateUpdateProfilo()}          
                    useSubmitBehavior={true} />      
            </div>
                    </form>
           </ScrollView>    
          
        
        </Popup>
        </>
      )

  
}






     