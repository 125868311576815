import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_for_cards_charts_01, CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { columnsAnaVendiGruppMerce } from "./Colums/ColAnalOperativo";
import { MenuCruscottoOperativo } from "../components/menu_operativo";
import { Summary, TotalItem } from "devextreme-react/data-grid";
//import { DialogAnaScoCatMerc } from "./dialog/PopAnaScoCatMerc";
import { createItemFilterAnalVendGruMerc } from "./ItemsFiltri/FiltriAnalOperativo";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import {
  boxCardVendGrupMerc,
  boxCardVendGrupMerc2,
} from "./Boxcards/AnalOperativo";

import "./style.css";

const AnaliVendGruMerce = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [operatori, setOperatori] = useState<any[]>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardVendGrupMerc);

  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePop3, setVisiblePop3] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [ditte, setDitte] = useState<any[]>([]);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const [settori, setSettori] = useState<any[]>([]);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);
  const loadPanel = <Loading isVisible={isVisible} />;
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);

  const dashboard25 = { dashboardID: 25 };

  function aggiornaDataFine(dataFine: Date): Date {
    const nuovaDataFine = new Date(dataFine);

    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };
    console.log("vediamo i parametri yo");
    console.log(params);

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

      let venditaDPC = params?.venditaDPC ? [] : [0];
      let flagBusta = params?.flagBusta ? [] : [0];

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }


    if (params.periodo.al) {
      params.periodo.al = params.periodo.al; //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      ditta: params.ditta,
      venditaDPC: venditaDPC,
      flagBusta: flagBusta,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall25 = {
        ...dashboard25,
        idFarList: params.idFar,
        params: parameters,
      };

      //new call

      console.log("mostro call");
      console.log(dashboardCall25);

      RestServices()
        .rest("POST", dashboardCall25, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {

              let datiPrimaParteCards = trasform_for_cards_charts_01(result["107"], boxCardVendGrupMerc);
              setFila1cards(datiPrimaParteCards);

              const anno = params.periodo.dal.substring(0, 4)
              console.log(anno);

              let datiPrimaTabella = CruscottoGruppo_traform_analisi(result["108"], anno);
              setValoriTabella1(datiPrimaTabella);


            setIsVisible(false);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });

      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const itemsFields = createItemFilterAnalVendGruMerc(
    newFarmaList,
    gruppoMercFilters,
    ditte,
    settori
  );

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlBancaDati.getGruppoMerceologico)
        .then((result) => {
          if (result) setGruppoMercFilters(result);
        });

      RestServices()
        .rest("POST", "", urlBancaDati.getSettore)
        .then((result) => {
          if (result) setSettori(result);
        });

      RestServices()
        .rest("POST", "", urlBancaDati.getDitte)
        .then((result) => {
          if (result) {
            const ditteWithCodiceDescrizione = result.map(
              (ditta: { codice: any; descrizione: any }) => {
                return {
                  ...ditta,
                  codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                };
              }
            );
            // console.log('da togliere vediamo ditte');
            // console.log(ditteWithCodiceDescrizione);

            setDitte(ditteWithCodiceDescrizione);
          }
        });
    }

    const SCONTO_MISTO = { id: "SCONTO_MISTO", descrizione: "Sconto misto" };
    const SCONTO_PRODOTTO = {
      id: "SCONTO_PRODOTTO",
      descrizione: "Sconto prodotto",
    };
    const SCONTO_TESTATA = {
      id: "SCONTO_TESTATA",
      descrizione: "Sconto testata",
    };

    const sconto = [SCONTO_PRODOTTO, SCONTO_TESTATA, SCONTO_MISTO];
    const ssn = { id: 1, descrizione: "SSN" };
    const libera = { id: 0, descrizione: "Libera" };

    const vendite = [libera, ssn];
    setTipologiaVendita(vendite);
  };

  useEffect(() => {
    filters();
  }, [auth]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  function getCategoryByLevel(categoryList: any[], level: number) {
    return categoryList.filter((category) => category.length === level * 1);
  }


  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  const popVisible = (testo: string) => {
    setVisiblePop(true);
  };

  const popVisible3 = (testo: string) => {
    setVisiblePop3(true);
  };

  return (
    <>
      {/* <DialogAnaScoCatMerc
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            /> */}

      <section style={{ display: "flex", height: "98vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >

        <h2 style={{ display: 'inline-block', marginRight: '20px' }}>BusinessLab Evolution - Cruscotto Operativo </h2>
            </div>

            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuCruscottoOperativo />
              </div>
            </div>

            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"40%"}
              height={"40%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup>

            <div className="grid-container-six">
              {fila1cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            <div style={{ marginTop: "5px" }}>
            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginTop: 10, fontSize: 17 }}>Tabella Dettaglio</h6>

              <NewTabella
                idTabella="elenco_operatori"
                sync
                colonne={columnsAnaVendiGruppMerce}
                dataSource={valoriTabella1}
                filterRow
                width={10}
                searchPanel={true}
                exportT={{
                  enabled: true,
                  fileName: "ElencoOperatori",
                  formats: { exportFormats },
                }}
                activeSaver={true}
                columnC={true}
                rowAlternation={true}
                headerFilter
                wordWrapEnabled={true}
                onExporting={onExporting}
                summary={
                <Summary>
                    <TotalItem
                        column="VALORE_VENDUTOCR"
                        summaryType="sum"
                        valueFormat={{ style: "currency", currency: "EUR" }}
                    />
                    <TotalItem
                        column="VALORE_VENDUTOAP"
                        summaryType="sum"
                        valueFormat={{ style: "currency", currency: "EUR" }}
                    />
                    <TotalItem
                        column="MARGINECR"
                        summaryType="sum"
                        valueFormat={{ style: "currency", currency: "EUR" }}
                    />
                    <TotalItem
                        column="VOLUME_VENDITACR"
                            summaryType="sum"
                            valueFormat={{ style: "currency", currency: "EUR" }}
                        />
                    </Summary>
                }
              />
            </div>
          </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section
          style={{
            background: "#fff",
            opacity: isFiltersExpanded ? 1 : 0,
            width: isFiltersExpanded ? "auto" : 10,
          }}
        >
          <div style={{}}>
             <FiltriVerticale
              ricerca={filterAction}
              items={itemsFields}
              paramsFilter={paramsFilter}
            />
          </div>
        </section>
      </section>
    </>
  );
};

export default AnaliVendGruMerce;
