import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row'; import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import Button from 'react-bootstrap/esm/Button';
import notify from 'devextreme/ui/notify';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { Loading } from '../../widget/Notifications';
import { useAuth } from '../../hooks/useAuth';
import { OggettoInterface } from '../../interfaces/generiche';
import { ElencoFarmacieTrasferimentiInterface } from '../../interfaces/trasferimenti';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { DrawerFiltri } from '../../widget/Drawer';
import { SelectFarm } from '../../widget/Select';
import { TabellaCompleta } from '../../widget/Tabelle';
import { ColumnButtonOnClickInterface, TabellaCompletaInterface } from '../../interfaces/tabelle';
import { urlTrasferimenti } from '../costanti';
import { DialogFarmaciaTrasferimento } from './Dialog';
import { useDatiFarm, useFarmMongo } from '../../hooks/useDatiFarm';

const FarmacieTrasferimenti = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const auth = useAuth(urlTrasferimenti.auth);

    const titolo = TitoloContenuto("Elenco farmacie trasferimento merce");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [openDrawer, setOpenDrawer] = useState(true);

    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const [aggiorna, setAggiorna] = useState<boolean>(false);

    const [filtri, setFiltri] = useState<OggettoInterface>({});

    const [elencoFarmacieTrasferimenti, setElencoFarmacieTrasferimenti] = useState<ElencoFarmacieTrasferimentiInterface[]>();

    const [isUpdate, setIsUpdate] = useState<boolean>();
    

    const [isDialogFarmaciaTrasferimentoOpen, setIsDialogFarmaciaTrasferimentoOpen] = useState<boolean>(false);
    const handleCloseDialogFarmaciaTrasferimento = () => setIsDialogFarmaciaTrasferimentoOpen(false);


    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        startCerca();
    }, [aggiorna]);

    const startCerca = () => {
        if (filtri) {
            //if (!filtri.idFar) {
            //    notify({ position: "center", width: "auto", message: "Selezionare la farmacia" }, 'error', 4000);
            //}
            //else {
            setIsVisible(true);
            const param = {

            }
            RestServices().rest('POST', filtri.idFar, urlTrasferimenti.getElencoFarmacie)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        if (result === true) {
                            notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                            setElencoFarmacieTrasferimenti(result)
                        } else {
                            setElencoFarmacieTrasferimenti(result);
                        }
                    }
                    else if (result && result.message && result.severity) {
                        notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                    } else {
                        notify({ position: "center", width: "auto", message: "Oops c'� stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    }
                })
            //}
        } else {
            notify({ position: "center", width: "auto", message: "Inserire i parametri di ricerca richiesti" }, 'error', 4000);
        }
    }

    const nuovo = () => {
        setNewFarmaciaTrasferimento({
            id: undefined,
            farmacia: undefined,
            codiceFarmaciaTrasferimento: undefined,
            username: undefined,
            password: undefined,
            isFarmacia: undefined,
            isGrossista: undefined,
        });
        setIsUpdate(false);
        setIsDialogFarmaciaTrasferimentoOpen(true);
    }

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="3">
                    <label>Farmacia</label>
                    <SelectFarm datiFarmacie={props.datiFarmacie} id="idFar" impostaFiltri={impostaFiltri} />
                </Col>
                <Col sm="2">
                    <Button type="button" variant="success" id="btnCercaTrasf" onClick={startCerca}>Cerca</Button>
                </Col>
                <Col sm="7">
                    <Button type="button" variant="info" id="btnNuovoTrasf" onClick={nuovo}>Nuovo</Button>
                </Col>
            </Row>
        )
    }


    const propsTabElencoFarmacieTrasferimenti: TabellaCompletaInterface = {
        id: "elenco_trasferimenti",
        dataSource: elencoFarmacieTrasferimenti,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        export: {
            enabled: true,
            fileName: "FarmacieTrasferimenti"
        }
    }

    const apriModalModifica = (e: ColumnButtonOnClickInterface) => {
        if (e && e.row && e.row.data) {
            setNewFarmaciaTrasferimento({
                id: e.row.data.id,
                farmacia: e.row.data.farmacia,
                codiceFarmaciaTrasferimento: e.row.data.codiceFarmaciaTrasferimento,
                username: e.row.data.username,
                password: e.row.data.password,
                isFarmacia: e.row.data.isFarmacia,
                isGrossista: e.row.data.isGrossista,
            });
            setIsUpdate(true);
            setIsDialogFarmaciaTrasferimentoOpen(true);
        }
        
    }

    const eliminaFarmacia = (e: ColumnButtonOnClickInterface) => {
        if (e && e.row && e.row.data) {
            const param = {
                id: e.row.data.id,
                farmacia: e.row.data.farmacia,
                codiceFarmaciaTrasferimento: e.row.data.codiceFarmaciaTrasferimento,
                username: e.row.data.username,
                password: e.row.data.password,
                isFarmacia: e.row.data.isFarmacia,
                isGrossista: e.row.data.isGrossista,
                isUpdate: false,
                isDelete:true,
            }
            
            RestServices().rest('POST', param, urlTrasferimenti.setFarmacia)
                .then(result => {
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "eliminazione eseguita correttamente", onHiding: startCerca }, "success", 4000);
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }

       
    }

    const columnsFarmacie = CreaColonne([
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Cod. Farmacia", dataField: "codiceFarmaciaTrasferimento" },
        { caption: "Username", dataField: "username" },
        { caption: "Password", dataField: "password" },
        { caption: "E' farmacia", dataField: "isFarmacia" },
        { caption: "E' grossista", dataField: "isGrossista" },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Modifica",
                icon: "edit",
                onClick: apriModalModifica
            }, {
                    hint: "Elimina",
                    icon: "clear",
                    onClick: eliminaFarmacia
                }]
        }
    ])

    const [newFarmaciaTrasferimento, setNewFarmaciaTrasferimento] = useState<ElencoFarmacieTrasferimentiInterface>({
        id: undefined,
        farmacia: undefined,
        codiceFarmaciaTrasferimento: undefined,
        username: undefined,
        password: undefined,
        isFarmacia: undefined,
        isGrossista: undefined,
    });

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaOrdiniRda" templateFiltri={templateFiltri} open={openDrawer} setOpen={setOpenDrawer} />
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaCompleta
                            propsTabella={propsTabElencoFarmacieTrasferimenti}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={true}
                        />
                    </Col>
                </Row>
                <DialogFarmaciaTrasferimento
                    datiFarmacie={props.datiFarmacie}
                    isDialogFarmaciaTrasferimentoOpen={isDialogFarmaciaTrasferimentoOpen}
                    setIsDialogFarmaciaTrasferimentoOpen={setIsDialogFarmaciaTrasferimentoOpen}
                    handleCloseDialogFarmaciaTrasferimento={handleCloseDialogFarmaciaTrasferimento}
                    setIsVisible={setIsVisible}
                    farmaciaTrasferimento={newFarmaciaTrasferimento}
                    idFar={(props.datiFarmacie) && (props.datiFarmacie.length > 0) ? props.datiFarmacie[0]?.ID_FAR : undefined}
                    setAggiorna={setAggiorna}
                    aggiorna={aggiorna}
                    isUpdate={ isUpdate}
                />
            </div>
        </>
    )
}

export default FarmacieTrasferimenti;

