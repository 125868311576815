import { AnagraficaRsaInterface, RsaInterface } from './../interfaces/elencoRsa';
import { urlTorinoRsa } from './../contenuti/costanti';
import { RestServices } from './../services/restServices';
import { useEffect, useCallback } from 'react';

export const useGetElencoRsa = (
    setElencoClienti: (elencoClienti: RsaInterface[]) => void,
    setIsVisible: (isVisible: boolean) => void,
    aggiorna: boolean
) => {

    useEffect(() => {
        getElencoRsa();
    }, [aggiorna]);

    const getElencoRsa = useCallback(() => {
        setIsVisible(true);

        RestServices().rest('POST', "", urlTorinoRsa.getElencoRsa).then(result => {
            if (result) {
                setElencoClienti(result);
                setIsVisible(false);
            }
        })
    }, [aggiorna])
}

export const useGetRsa = (setRsaSelez: (rsa: AnagraficaRsaInterface) => void, rigaSelezionata?: RsaInterface) => {

    useEffect(() => {
        if (rigaSelezionata) getRsa();
    }, [rigaSelezionata]);

    const getRsa = useCallback(() => {
        RestServices().rest('POST', rigaSelezionata, urlTorinoRsa.getRsa).then(result => {
            if (result) {
                setRsaSelez(result);
            }
        })
    }, [rigaSelezionata])
}