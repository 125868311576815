import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { createItemFilter } from "./itemsFiltri/AnaliVendite";
//import { DialogAnaScoCateg } from "./dialog/PopAnaScoCate";
import { columnsAnalScontiVendi } from "./colums/AnalVendi";
import { createItemFilterAnalScontiVendi } from "./itemsFiltri/AnaliVendite";
import { boxCardAnalScontiVend } from "./BoxCard/AnalVendite";
import { MenuFbiVendite } from "../components/menu_Fbi_Vendite";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { urlConsultazione, urlReport } from "../../costanti";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Chart, PieChart } from "devextreme-react";
import {
  Animation,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Series,
  Subtitle,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import { HoverStyle } from "devextreme-react/pie-chart";
import { Connector, Font, Format } from "devextreme-react/pie-chart";

import "./style.css";

const AnaliScontiVendita = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [operatori, setOperatori] = useState<any[]>([]);
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnalScontiVend);
  const [pie, setPie] = useState<any[]>([]);
  const [secondpie, setSecondPie] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>([]);
  const chartRef = useRef<any>(null);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePop2, setVisiblePop2] = useState(false);
  const [visiblePop1, setVisiblePop1] = useState(false);
  const [visiblePop3, setVisiblePop3] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);
  const [progetto, setProgetto] = useState<any[]>([]);
  const [societa, setSocieta] = useState<any[]>([]);
  const [categoria, setCategoria] = useState<any[]>([]);
  const [tipologiaSconto, setTipologiaSconto] = useState<any[]>([]);
  const [segmento, setSegmento] = useState<any[]>([]);
  const [blocco1, setBlocco1] = useState<any[]>([]);
  const [blocco2, setBlocco2] = useState<any[]>([]);
  const [tipologiaProdotto, setTipologiaProdotto] = useState<any[]>([]);
  const [tipologiaConFascia, setTipologiaConFascia] = useState<any[]>([]);

  const loadPanel = <Loading isVisible={isVisible} />;
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);

  const dashboard15 = { dashboardID: 15 };

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    // let venditaDPC = [0];
    // let flagBusta = [0];

    // if (params?.venditaDPC) {
    //     venditaDPC = [1];
    // }
    // if (params?.flagBusta) {
    //     flagBusta = [1];
    // }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    let category1;
    let category2;
    let category3;
    let category4;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.category) {
      const categoryList = params.category;

      category1 = getCategoryByLevel(categoryList, 2);
      category2 = getCategoryByLevel(categoryList, 4);
      category3 = getCategoryByLevel(categoryList, 6);
      category4 = getCategoryByLevel(categoryList, 8);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al; //aggiornaDataFine(params.periodo.al);
    }

    if (params.societa && params.societa.length > 0) {
      console.log(societa);
      let objSocieta: any = [];

      params.societa.forEach((element: any) => {
        const oggettoTrovato = societa.find((obj) => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        });
      });
      params.societaNewV = objSocieta;
    }

    if (params.progetto && params.progetto.length > 0) {
      let objSocieta: any = [];

      params.progetto.forEach((element: any) => {
        const oggettoTrovato = progetto.find((obj) => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        });
      });
      params.progettoNewV = objSocieta;
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      category1: category1,
      category2: category2,
      category3: category3,
      category4: category4,
      ditta: params.ditta,
      operatori: params.operatori,
      categoria: params.categoria,
      segmento: params.segmento,
      blocco1: params.blocco1,
      blocco2: params.blocco2,
      progetto: params.progettoNewV,
      societa: params.societaNewV,
      tipologiaProdotto: params.tipologiaProdotto,
      tipologiaConFascia: params.tipologiaConFascia,
      tipologiaSconto: params.tipologiaSconto,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall15 = {
        ...dashboard15,
        //idFarList: [8939, 8923],
        idFarList: params.idFar,
        params: parameters,
      };

      //new call

      console.log("mostro call");
      console.log(dashboardCall15);

      RestServices()
        .rest("POST", dashboardCall15, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {
            let datiPrimaParteCards = trasform_for_cards_charts_01(
              result["73"],
              boxCardAnalScontiVend
            );

            // 1 Chart
            setChartData(result["74"].reverse());

            let datiPrimaTabelle = result["76"];

            setFila1cards(datiPrimaParteCards);
            setValoriTabella1(datiPrimaTabelle);

            //  1 piechart
            let arrayTousePie = [
              {
                label: "SCONTO TESTATA",
                value: result["73"][0]?.NUMERO_VENDITE_SCONTO_TESTATA,
              },
              {
                label: "SCONTO PRODOTTO",
                value: result["73"][0]?.NUMERO_VENDITE_SCONTO_PRODOTTO,
              },
              {
                label: "SCONTO MISTO",
                value: result["73"][0]?.NUMERO_VENDITE_SCONTO_MISTO,
              },
            ];

            setPie(arrayTousePie);

            //  2 piechart
            setSecondPie(result["75"]);

            setIsVisible(false);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });

      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });

    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const itemsFields = createItemFilterAnalScontiVendi(
    newFarmaList,
    operatori,
    progetto,
    societa,
    categoria,
    tipologiaSconto,
    tipologiaConFascia,
    tipologiaProdotto,
    segmento,
    blocco1,
    blocco2
  );

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
        .then((result) => {
          if (result.esito) {
            const operatori = result.response.map(
              (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                //console.log(ope.idFarmacia);
                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                //console.log(farmaciaCorrispondente);
                return {
                  ...ope,
                  id: `${ope.operatore}`,
                  descrizione: `${ope.operatore}`,
                  codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                };
              }
            );
            setOperatori(operatori);
            // console.log('operatori');
            // console.log(operatori);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getProgetto)
        .then((result) => {
          console.log("vediamo sytruttura");
          console.log(result);

          if (result) {
            const progetti = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });

            setProgetto(result);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getSocieta)
        .then((result) => {
          if (result) {
            const societas = result.map((oggetto: { codice: any; id: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.id, // Aggiungi la nuova proprietà id
              };
            });
            console.log("mostro società");
            console.log(result);

            setSocieta(result);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getTipologiaConFascia)
        .then((result) => {
          if (result) {
            const fascia = result.map((oggetto: { descrizione: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.descrizione, // Aggiungi la nuova proprietà id
              };
            });

            setTipologiaConFascia(fascia);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getTipologiaProdotto)
        .then((result) => {
          if (result) {
            const prodotti = result.map((oggetto: { descrizione: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.descrizione, // Aggiungi la nuova proprietà id
              };
            });

            setTipologiaProdotto(prodotti);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getCategoria)
        .then((result) => {
          if (result) {
            const categorie = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });
            console.log("mostro categorie");
            console.log(categorie);

            setCategoria(categorie);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getSegmento)
        .then((result) => {
          if (result) {
            const segmentos = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });
            setSegmento(segmentos);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getBlocco1)
        .then((result) => {
          if (result) {
            const blocco1 = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });
            setBlocco1(blocco1);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getBlocco2)
        .then((result) => {
          if (result) {
            const blocco2 = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });
            setBlocco2(blocco2);
          }
        });

      const SCONTO_MISTO = { id: "SCONTO_MISTO", descrizione: "Sconto misto" };
      const SCONTO_PRODOTTO = {
        id: "SCONTO_PRODOTTO",
        descrizione: "Sconto prodotto",
      };
      const SCONTO_TESTATA = {
        id: "SCONTO_TESTATA",
        descrizione: "Sconto testata",
      };

      const sconto = [SCONTO_PRODOTTO, SCONTO_TESTATA, SCONTO_MISTO];
      setTipologiaSconto(sconto);
    }
  };

    useEffect(() => {
        filters();
    }, [authReport]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };


  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  function getCategoryByLevel(getListCate: any[], level: number) {
    console.log("categorie by level");
    console.log(getListCate);
    console.log(level);

    return getListCate.filter((category) => category.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  const popVisible1 = (testo: string) => {
    setVisiblePop1(true);
  };
  const popVisible2 = (testo: string) => {
    setVisiblePop2(true);
  };

  const popVisible = (testo: string) => {
    setVisiblePop(true);
  };
  const popVisible3 = (testo: string) => {
    setVisiblePop3(true);
  };

  let messOne = ["Val 1 Pie chart"];
  let messTwo = [
    "Valore sconto per operatore: Rappresenta la distribuzione del valore sconto, nel periodo selezionato, in funzione dell'operatore erogante lo sconto. Valore sconto: Rappresenta lo sconto totale applicato in vendita.",
  ];
  let mess4 = [
    "Valore sconto per tipologia di sconto: Rappresenta la distribuzione del valore sconto, nel periodo selezionato, in funzione della tipologia di sconto applicata in vendita. Valore sconto: Rappresenta lo sconto totale applicato in vendita.",
  ];
  let messThree = [
    "Andamento valore sconto: Descrive l'andamento mensile del valore sconto negli ultimi 12 mesi rolling. Valore sconto anno corrente: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Valore sconto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
  ];

  return (
    <>
      {/* <DialogAnaScoCateg
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            /> */}

      <section style={{ display: "flex", height: "98vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >
              <h2>F.B.I.</h2>
            </div>

            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuFbiVendite />
              </div>
            </div>

            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"40%"}
              height={"40%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup>

            <div className="grid-container-sec">
              {fila1cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                marginTop: 5,
              }}
            >
              {/* 1 PieChart */}
              <div style={{ width: "45%", marginTop: 10 }}>
                <PieChart
                  id="pie"
                  dataSource={pie}
                  palette="Green Mist"
                  paletteExtensionMode="alternate"
                  type="doughnut"

                  //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                >
                  <Series argumentField="label" valueField="value">
                    <HoverStyle color="rgb(255, 0, 0)" />
                  </Series>

                  {
                    <Tooltip
                      enabled={true}
                      customizeTooltip={({
                        argument,
                        value,
                        percentText,
                      }: {
                        argument: any;
                        value: any;
                        percentText: any;
                      }) => {
                        const formattedValue = new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(value);

                        return {
                          html: `<p>Importo ${argument}</p><p>${formattedValue} / ${percentText}</p>`,
                        };
                      }}
                    />
                  }

                  <Title text="Valore sconto per tipologia di sconto">
                    <Font color="#5b6372" size="16" weight="400" />
                  </Title>

                  <Export enabled={true} />
                </PieChart>
              </div>
              <div
                style={{ display: "flex", marginTop: "-380px", width: "3%" }}
              >
                <button
                  className="ibutton-lite-two"
                  style={{ border: "none" }}
                  onClick={() => popVisible1("")}
                >
                  <FontAwesomeIcon
                    icon={faInfo}
                    style={{ fontSize: "11px", color: "black" }}
                  />
                </button>
              </div>

              {/* 2 PieChart */}

              <div style={{ width: "45%", marginTop: 10 }}>
                <PieChart
                  id="pie"
                  dataSource={secondpie}
                  palette="Green Mist"
                  paletteExtensionMode="alternate"
                  type="doughnut"

                  //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
                >
                  <Series
                    argumentField="OPERATORE_USERNAME"
                    valueField="VEN_SCONTO_VENDITA"
                  >
                    <HoverStyle color="rgb(255, 0, 0)" />
                  </Series>
                  {
                    <Tooltip
                      enabled={true}
                      customizeTooltip={({
                        argument,
                        value,
                        percentText,
                      }: {
                        argument: any;
                        value: any;
                        percentText: any;
                      }) => {
                        console.log("vediamo value");
                        console.log(percentText);

                        const dataItem = secondpie.find(
                          (item: any) => item.OPERATORE_USERNAME === argument
                        );
                        const dataSourceValue = dataItem
                          ? dataItem.VEN_SCONTO_VENDITA
                          : 0;
                        const formattedValue = new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(dataSourceValue);

                        //console.log(formattedValue);
                        //  console.log(dataSourceValue);
                        return {
                          html: `<p>Importo sconto - ${argument}</p><p>${formattedValue} / ${percentText}</p>`,
                        };
                      }}
                    />
                  }
                  <Title text="Valore sconto per operatore">
                    <Font color="#5b6372" size="16" weight="400" />
                  </Title>

                  <Export enabled={true} />
                </PieChart>
              </div>
              <div
                style={{ display: "flex", marginTop: "-380px", width: "3%" }}
              >
                <button
                  className="ibutton-lite-two"
                  style={{ border: "none" }}
                  onClick={() => popVisible2("")}
                >
                  <FontAwesomeIcon
                    icon={faInfo}
                    style={{ fontSize: "11px", color: "black" }}
                  />
                </button>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                backgroundColor: "white",
                marginTop: "5px",
              }}
            >
              <div
                style={{
                  width: "99%",
                  margin: 20,
                  marginLeft: 20,
                  marginRight: 10,
                }}
              >
                <Chart dataSource={chartData} ref={chartRef}>
                  <CommonSeriesSettings
                     argumentField="MESE"
                     type="bar"
                     selectionMode="allArgumentPoints"
                     barPadding={0.2}
                  >
                    <Label visible={true}>
                      <Format type="currency" currency="EUR" precision={0} />
                    </Label>
                  </CommonSeriesSettings>

                  <Series
                    valueField="VEN_SCONTO_VENDITA_PRECEDENTE"
                    type="line"
                    name="2022/2023"
                    color="rgb(215, 140, 245)">

                  <Label visible={true} verticalOffset={-60}></Label>
                  </Series>
                                      

                  <Series
                    valueField="VEN_SCONTO_VENDITA"
                    type="bar"
                    argumentField="MESE"
                    color="rgb(25, 231, 255)"
                    name="2023/2024"
                  />

                  <Tooltip
                    enabled={true}
                    customizeTooltip={({
                      value,
                      argument,
                    }: {
                      value: any;
                      argument: any;
                    }) => {
                      const formattedValue = parseFloat(value).toLocaleString(
                        "it-IT",
                        {
                          style: "currency",
                          currency: "EUR",
                          minimumFractionDigits: 2,
                        }
                      );

                      return {
                        text: `Valore sconto periodo selezionato: ${formattedValue}`,
                      };
                    }}
                  />
                  <Title text="Andamento valore del venduto netto sconti - ultimi 12 mesi rolling">
                    <Font color="#5b6372" size="16" weight="400" />
                    {/* <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle> */}
                  </Title>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  ></Legend>
                  <Export fileName="venduto_sconto_vendita" enabled={true} />
                </Chart>
              </div>
              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                    margin: 10,
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisible3("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>
            </div>

            <div style={{ marginTop: "5px", backgroundColor: "white" }}>

            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: 10 }}>Valore sconto per classificazione</h6>

              <NewTabella
                idTabella="elenco_operatori"
                sync
                colonne={columnsAnalScontiVendi}
                dataSource={valoriTabella1}
                filterRow
                width={10}
                searchPanel={true}
                exportT={{
                  enabled: true,
                  fileName: "ElencoOperatori",
                  formats: { exportFormats },
                }}
                activeSaver={true}
                columnC={true}
                rowAlternation={true}
                headerFilter
                wordWrapEnabled={true}
                onExporting={onExporting}
                //   summary={
                //     <Summary>
                //       <TotalItem column="VALORE_VENDUTOCR" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                //       <TotalItem column="NUMERO_VENDITECR" summaryType="sum" />

                //       <TotalItem column="VALORE_VENDUTO_EQUIPARATO" summaryType="sum" />
                //     </Summary>
                //   }
              />

              {/* <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisiblePie("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>  */}

              {/* <div className="containButtonAna">
                                <Button
                                    id="btnCercax"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        setOpenPop(true);
                                        console.log("dialog");
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi sconti per operatori
                                </Button>
                            </div> */}
            </div>
          </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section
          style={{
            background: "#fff",
            opacity: isFiltersExpanded ? 1 : 0,
            width: isFiltersExpanded ? "auto" : 10,
          }}
        >
          <div style={{}}>
            <FiltriVerticale
              ricerca={filterAction}
              items={itemsFields}
              paramsFilter={paramsFilter}
            />
          </div>
        </section>
      </section>
      <Popup
        visible={visiblePop}
        showCloseButton={true}
        onHiding={() => setVisiblePop(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messOne}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop1}
        showCloseButton={true}
        onHiding={() => setVisiblePop1(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{mess4}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop2}
        showCloseButton={true}
        onHiding={() => setVisiblePop2(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messTwo}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop3}
        showCloseButton={true}
        onHiding={() => setVisiblePop3(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messThree}</p>
        </div>
      </Popup>
    </>
  );
};

export default AnaliScontiVendita;
