import React, { useCallback, useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import Ricerca from "../../widget/nuova_ricerca"
import { DialogConferma, Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione/interface'
import { trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import { AplicaValoreTO, actionTables } from '../../funzioni/operazioniSuTabelle'
import Button from 'react-bootstrap/Button'
import { DialogPrimaNota } from "./dialog/popup_prima_nota"
import { DialogImpostazioni } from "./dialog/pop_impostazioni"
import { Summary, TotalItem } from 'devextreme-react/data-grid'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons"

import './style.css';
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'



const PrimaNota = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {


    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [causali, setCausali] = useState<any>([])
    const [conti, setConti] = useState<any>([])
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [popDel, setPopDel] = useState<boolean>(false)
    const [idDel, setIddel] = useState<number>(0)
    const [messDel, setMessDel] = useState<string>('')
    const [edit, setImmediate] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [summaryObj, setSummaryObj] = useState<any>({})
    const [saldoObj, setSaldoObj] = useState<number>(0)
    const [saldoOpen, setSaldoOpen] = useState<boolean>(true)
    const [contoSelect, setContoSelect] = useState<number | null>(null)
    const [paramsFilter, setParamsFilter] = useState<Filtri>()
    const formatConto = (cellInfo: { value: any }) => {
        const value = cellInfo.value;
        console.log(value)
        if (value.startsWith('CONTANTI - ')) {
            return 'CONTANTI';
        }
        return value;
    };


    const columns = [
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Pdf Prima nota",
                    icon: "print",
                    visible: (x: any) => isPrintIconVisible(x),
                    onClick: (e: any) => {
                        if (e.row.data.linkFattura) {
                            window.open(e.row.data.linkFattura, "_blank");
                        } else {
                            notify({ position: "center", width: "auto", message: "Non ci sono fatture da visualizzare" }, "error", 4000)
                        }

                    }
                },
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        setImmediate(true)
                        setOpenPop(true)

                    }
                },
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        //console.log(e.row.data);            
                        setIddel(e.row.data.id)
                        setMessDel("La Prima Nota " + e.row.data.titolo + " sta per essere eliminata. Confermare?")
                        setPopDel(true)

                    }
                }]
        },
        { caption: "Entrate", dataField: "importoEntrata", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Uscite", dataField: "importoUscita", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Saldo", dataField: "saldoConto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Data", dataField: "data", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "id", dataField: "id", sortOrder: "desc", visible: false },
        { caption: "Conto/Pagamento", dataField: "conto", customizeText: formatConto },
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Causale", dataField: "descrizione" },
        { caption: "Ragione Sociale", dataField: "ragioneSociale" }, /*riferimentoFattura*/      /*clienteFornitore: "CSF NORD SRL"*/
        { caption: "Titolo", dataField: "titolo" },
        { caption: "Num. documento", dataField: "numDocumento" },
        { caption: "Data documento", dataField: "dataDocumento", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Nota", dataField: "nota", width: 200 },
        //{ caption: "Rif. fattura", dataField: "riferimentoFattura" },


    ];

    const data = new Date()

    const dueMesiIndietro = new Date(data.getFullYear(), data.getMonth() - 2);

    const anno = dueMesiIndietro.getFullYear();
    const mese = (dueMesiIndietro.getMonth() + 1).toString().padStart(2, "0");
    const giorno = '01';

    const dataFormattata = `${anno}-${mese}-${giorno}`;


    const itemsFields = [
        { type: 'select', idValue: 'idConto', label: 'Conto', displayExpr: 'conto', dataSource: conti, width: 280 },
        { type: 'select', idValue: 'idFar', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: newFarmaList, width: 350 },
        { type: 'date', idValue: 'dal', label: 'Dal', defValue: dataFormattata, require: false },
        { type: 'date', idValue: 'al', label: 'Al', defValue: new Date() },
        { type: 'select', idValue: 'idCausale', label: 'Causale', grouped: true, displayExpr: 'descrizioneBreve', dataSource: causali, width: 280 },
        //{ type: 'ckBox', idValue: 'flagEntrata', label: 'Entrate' },
        //{ type: 'ckBox', idValue: 'flagUscita', label: 'Uscite' },

    ]


    const isPrintIconVisible = (e: any) => {
        if (e.row.data.linkFattura) {
            return true;
        } else {
            return false;
        }
    }

    const getConto = (id?: number) => {
        if (auth) {
            let param = {};
            if (id != undefined && id > 0) {
                param = {idConto: id}
            }
            RestServices().rest('POST', param, urlConsultazione.getListConti)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setConti(result.response)
                    } else {
                        //console.log(result); 
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const getCausali = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListTipoCausaPrimaNota)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setCausali(result.response)
                    } else {
                        //console.log(result); 
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {
                        //console.log(result);                   
                    }
                })
        }
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro }
        if (params.dal) {
            params.dal = trasformaDateTimeInLocale(params.dal, true);

        } else {
            let nixioAnno: any = dataFormattata;
            params.dal = trasformaDateTimeInLocale(nixioAnno, true);
        }
        if (params.al) {
            params.al = trasformaDateTimeInLocale(params.al, true);

        } else {
            let oggi: any = new Date()
            params.al = trasformaDateTimeInLocale(oggi, true);
        }
        if (objFiltro.idFar) {
            params.idFar = Number(params.idFar?.idFar)
        } else {
            delete params.idFar
        }
        if (objFiltro.idConto) {
            params.idConto = Number(params.idConto?.id)
            setContoSelect(objFiltro.idConto?.id)
        } else {
            delete params.idConto
            setContoSelect(null)
        }
        if (objFiltro.idCausale) {
            params.idCausale = Number(params.idCausale?.id)
        } else {
            delete params.idCausale
        }
        setParamsFilter(params)
        setLoading(true)
        if (auth) {


            //if (objFiltro.idConto)
            //    getConto(objFiltro.idConto.id)

            RestServices().rest('POST', params, urlConsultazione.getListPrimaNota)
                .then(result => {
                    if (result?.esito) {



                        let entrateToto = result.response.reduce((accumulator: any, object: any) => {
                            if (object.importoEntrata) {
                                return accumulator + object.importoEntrata;
                            } else {
                                return accumulator;
                            }

                        }, 0);
                        let usciteToto = result.response.reduce((accumulator: any, object: any) => {
                            if (object.importoUscita) {
                                return accumulator + object.importoUscita;
                            } else {
                                return accumulator;
                            }
                        }, 0);

                        setSummaryObj({ entrateToto, usciteToto })

                        if (result?.response && result?.response.length > 0) {
                            setSaldoObj(result.response[0].saldoConto)
                        }




                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }




    const getPrimaNota = () => {
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', paramsFilter != undefined ? paramsFilter : { dal: dataFormattata }, urlConsultazione.getListPrimaNota)
                .then(result => {
                    if (result?.esito) {

                        let entrateToto = result.response.reduce((accumulator: any, object: any) => {
                            if (object.importoEntrata) {
                                return accumulator + object.importoEntrata;
                            } else {
                                return accumulator;
                            }

                        }, 0);
                        let usciteToto = result.response.reduce((accumulator: any, object: any) => {
                            if (object.importoUscita) {
                                return accumulator + object.importoUscita;
                            } else {
                                return accumulator;
                            }
                        }, 0);


                        setSummaryObj({ entrateToto, usciteToto })

                        //console.log({entrateToto,usciteToto});                     

                        setTabellaPrincipale(result.response)

                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })
        }
    }


    const removeTipo = () => {
        if (auth) {
            RestServices().rest('POST', { idPrimaNota: idDel }, urlConsultazione.deletePrimaNota)
                .then(result => {
                    if (result.esito) {
                        setPopDel(false)
                        ricarica()
                    }
                    else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }

                })
        }
    }

    const ricarica = () => {
        setOpenPop(false)
        setImmediate(false);

        getPrimaNota()
        //getConto()

    }


    useEffect(() => {
        farmaList()
        getCausali()
        getConto()
        getPrimaNota()
    }, [auth]);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


    const onCellPrepared = useCallback((e: any) => {

        if (e.rowType === "data" && (e.column.dataField === "saldoConto")) {
            if (e.data.saldoConto && e.data.saldoConto <= 0)
                e.cellElement.style.color = "red";
        }

        if (e.rowType === "data" && (e.column.dataField === "importoEntrata")) {
            e.cellElement.style.color = "green";
        }
        if (e.rowType === "data" && (e.column.dataField === "importoUscita")) {
            e.cellElement.style.color = "red";
        }

    }, [])


    return (
        <>
            <DialogConferma
                dialogIsOpen={popDel}
                message={messDel}
                functionNext={() => removeTipo()}
                handleCloseConferma={() => { setPopDel(false); setIddel(0) }}
            />

            <DialogPrimaNota
                listaFarmacie={newFarmaList}
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }}
                open={openPop} />

            <DialogImpostazioni
                listaFarmacie={newFarmaList}
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />

            <Loading isVisible={loading} />
            <div className='title'>
                <h3> PRIMA NOTA   </h3>
            </div>

            <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />

            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }}>
                <p style={{ fontSize: 20, margin: 0, color: 'black', marginTop: 12 }}>Movimenti: {tabellaPrincipale.length}  </p>
                <p style={{ fontSize: 20, margin: 0, color: 'green', marginTop: 12 }}>Entrate: {summaryObj.entrateToto ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(summaryObj.entrateToto) : '0 €'} </p>
                <p style={{ fontSize: 20, margin: 0, color: 'red', marginTop: 12 }}>Uscite: {summaryObj.usciteToto ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(summaryObj.usciteToto) : '0 €'} </p>
                <p style={{ fontSize: 20, margin: 0, textAlign: 'right', marginTop: 12 }}>Saldo: {saldoObj ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldoObj) : '0 €'} </p>

                <div style={{ marginLeft: 'auto' }}>
                    <Button variant="primary" onClick={() => setOpenPop2(true)}  >
                        IMPOSTA CAUSALI DI DEFAULT
                    </Button>
                </div>

            </div>

            <div className='' style={{ display: 'grid', gridTemplateColumns: saldoOpen ? '75% auto' : '96% auto', gap: 5, marginTop: 7 }}>

                <div style={{}}>
                    <div className='button_primabanche'>
                        <Button variant="primary" onClick={() => setOpenPop(true)} >
                            AGGIUNGI +
                        </Button>

                        <Link to="/banche" target="_blank">
                            <Button className='btnbanca' variant="primary"  >
                                BANCHE
                            </Button>
                        </Link>
                        <Link to="/primanota/tipologia_causale" target="_blank">
                            <Button className='btnbanca' variant="primary"  >
                                CAUSALI
                            </Button>
                        </Link>
                        <Link to="/consultazione/report" target="_blank">
                            <Button className='btnbanca' variant="primary"  >
                                GRAFICO
                            </Button>
                        </Link>
                    </div>


                    <NewTabella
                        idTabella='prima_nota'
                        sync
                        height={'60vh'}
                        // width={'70%'}
                        headerFilter
                        filterRow
                        colonne={columns}
                        dataSource={tabellaPrincipale}
                        exportT={{
                            enabled: true,
                            fileName: "prima_nota"
                        }}
                        searchPanel={true}
                        activeSaver={true}
                        columnC={true}
                        onCellPrepared={onCellPrepared }
                    /*summary={
                    <Summary>
                        <TotalItem column="data" summaryType="count" />
                        
                    </Summary>} */
                    />

                </div>

                <div>

                    {saldoOpen ? (
                        <div style={{ position: 'relative', gap: 10, display: 'flex-end', background: 'white', padding: 10, borderRadius: 10, marginTop: 5, marginRight: 5, height: '61vh' }}>
                            <div style={{ display: 'flex' }}>
                                <button onClick={() => setSaldoOpen(!saldoOpen)} style={{ border: 'none', background: 'none', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faAngleRight} /></button>
                                <h4>SALDI CONTI</h4></div>
                            <div style={{ padding: 10, overflow: 'scroll', height: '90%' }}>
                                <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }} className="">
                                    {conti.map((el: { conto: any; saldo: any; id: any; ragioneSocialeFarmacia: any; ragioneSocialeAzienda: any }) => {
                                        const { saldo, id, conto, ragioneSocialeFarmacia, ragioneSocialeAzienda } = el;
                                        if (contoSelect) {
                                            if (contoSelect == id) {
                                                return (
                                                    <li key={id} className="">
                                                        <div>
                                                            {/*<h6>{conto}</h6>*/}
                                                            <h6>{'(' + conto.slice(-5) + ')' + ' ' + conto.split('-')[0]}</h6>
                                                            <p style={{ margin: 0 }}>{ragioneSocialeAzienda} - {ragioneSocialeFarmacia}</p>
                                                            <Row>
                                                                <Col sm="7">
                                                                </Col>
                                                                <Col sm="2">
                                                                    <p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5, textAlign: 'right' }}>SALDO:</p>
                                                                </Col>

                                                                <Col sm="3">
                                                                    <p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5, textAlign: 'right', color: saldoObj && saldoObj <= 0 ? 'red' : 'black' }}>{saldoObj ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldoObj) : '0 €'}</p>
                                                                </Col>
                                                            </Row>
                                                            {/*<p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5, textAlign: 'right', color: saldo && saldo <= 0 ? 'red' : 'black' }}>SALDO:{saldo ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldo) : '0 €'}</p>*/}
                                                        </div>
                                                    </li>
                                                );
                                            }
                                        } else {
                                            return (
                                                <li key={id} className="">
                                                    <div>
                                                        {/*<h6>{conto}</h6>*/}
                                                        <h6>{'(' + conto.slice(-5) + ')' + ' ' + conto.split('-')[0]}</h6>
                                                        <p style={{ margin: 0 }}>{ragioneSocialeAzienda} - {ragioneSocialeFarmacia}</p>
                                                        <Row>
                                                            <Col sm="7">
                                                            </Col>
                                                            <Col sm="2">
                                                                <p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5, textAlign: 'right' }}>SALDO:</p>
                                                            </Col>

                                                            <Col sm="3">
                                                                <p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5, textAlign: 'right', color: saldo && saldo <= 0 ? 'red' : 'black' }}>{saldo ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldo) : '0 €'}</p>
                                                            </Col>
                                                        </Row>
                                                        {/*<p style={{ fontWeight: 'bold', fontSize: 14, marginTop: 5, textAlign: 'right', color: saldo && saldo <= 0 ? 'red' : 'black' }}>SALDO:{saldo ? new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldo) : '0 €'}  </p>*/}
                                                    </div>
                                                </li>
                                            );
                                        }

                                    })}
                                </ul>
                            </div>
                        </div>

                    ) : (
                        <div style={{ position: 'relative', gap: 10, display: 'flex-end', background: 'white', padding: 10, borderRadius: 10, marginTop: 5, height: '62vh' }}>
                            <div style={{ display: 'flex' }}>
                                <button onClick={() => setSaldoOpen(!saldoOpen)} style={{ border: 'none', background: 'none', fontWeight: 'bold', marginRight: 10, fontSize: 20 }}>
                                    <FontAwesomeIcon icon={faAngleLeft} /></button>
                            </div>
                        </div>
                    )}

                </div>

            </div>


        </>
    )
}

export default PrimaNota