import React from 'react'
import NavTop from './NavTop'
import Container from 'react-bootstrap/esm/Container';
import { ListaFarmacieInterface } from '../interfaces/farmacie';
import { MenuInterface } from '../interfaces/menu';
import Contenuto from '../Contenuto';
import NavTopV2 from './NavTopV2';
import ContenutoCgm from '../ContenutoCgm';


const CorpoCgm = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    handleClosePsw: () => void,
    showModalPsw: boolean,
    menu: MenuInterface | undefined
}) => {
    //mostra/nasconde la modal della versione -> TODO da cambiare contenuto e reinserire
    // const [showModal, setShow] = useState<boolean>(false);
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    //definisce l'id della page-wrapper a seconda se il menu � collassato o meno
    let pageId = 'page-wrapper';
    if (!props.statoMenu) {
        pageId = 'page-wrapper-closed';
    }

    pageId = 'page'

    return (
        <>
            <NavTopV2
                statoMenu={props.statoMenu}
                toggleMenu={() => props.toggleMenu(props.statoMenu)}
                menu={props.menu}
            />
            <ContenutoCgm
                toggleMenu={() => props.toggleMenu(props.statoMenu)}
                statoMenu={props.statoMenu}
                datiFarmacie={props.datiFarmacie}

            />
            {/* <DialogVersion showModal={showModal} handleClose={handleClose} /> */}
        </>
    )
}

export default CorpoCgm