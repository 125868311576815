import { FileUploader } from "devextreme-react";
import { ButtonItem, Form, GroupItem } from "devextreme-react/form";
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ElementOptionChanged } from "../../interfaces/componentOptions";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { DittaInterface, OggettoInterface } from "../../interfaces/generiche";
import { RestServices } from "../../services/restServices";
import { BoxDataOra, Check, NumberBoxFiltro } from '../../widget/Editor';
import { Loading } from "../../widget/Notifications";
import { SelezioneMultipla, SelezioneMultiplaFarmacie, TendinaFarm } from '../../widget/Select';
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { urlAnagrafiche, urlBancaDati, urlPrezzoPromozione } from "../costanti";

export const DialogImpostazioniPrezzoPromozione = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    isDialogImpostazioniPrezzoPromozione: boolean,
    setIsDialogImpostazioniPrezzoPromozione: (isDialogImpostazioniPrezzoPromozione: boolean) => void
    handleCloseDialogImpostazioniPrezzoPromozione: () => void,
    functionNext: () => void,
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogImpostazioniPrezzoPromozione(false);
    }

    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    useEffect(() => {
        //getImpostazioniPrezzoPromozione();
    }, []);

    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    const salvaImpostazioniPrezzoPromozione = () => {

        if (filtri?.idFar == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, "error", 4000);
        }
        else {
            RestServices().rest('POST', filtri?.idFar, urlPrezzoPromozione.salvaFarmaciaRiferimento).then(result => {
                if (result >= 0) {
                    notify({ position: "center", width: "auto", message: "Salvataggio eseguito con successo" }, "success", 2000)
                    props.functionNext();
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                }
            })
        }
    }

    //const getImpostazioniPrezzoPromozione = () => {
    //    RestServices().rest('POST', filtri?.idFar, urlAnagrafiche.getFarmaciaRiferimento).then(result => {
    //        if (result) {
    //            setFiltri(result.response)
    //        } else {

    //        }
    //    })
    //}

    const clear = () => {

    }

    const colonneGridGeneriche = CreaColonne([
        { dataField: "ID_FAR", caption: "id", visible: false },
        { dataField: "DESCRIZIONE", caption: "descrizione", sortOrder: "asc" },
        { dataField: "CODICE", caption: "codice", width: 120 }
    ])


    return (
        <>
            <Modal
                show={props.isDialogImpostazioniPrezzoPromozione}
                onHide={handleClose}
                dialogClassName="dialogImpostazioniOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Impostazioni prezzo promozione</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <TabPanel
                        selectedIndex={selectedIndex}
                        loop={false}
                        animationEnabled={true}
                        onOptionChanged={onOptionChanged}


                    >
                        <Item title="Farmacia di riferrimento" icon="money">
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">

                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Farmacia di riferimento: </span>
                                </Col>
                                <Col sm="5">
                                    {/*<Selezione*/}
                                    {/*    dataSource={props.datiFarmacie}*/}
                                    {/*    id="idFar"*/}
                                    {/*    impostaFiltri={impostaFiltri}*/}
                                    {/*    displayExpr="DESCRIZIONE"*/}
                                    {/*    valueExpr="ID_FAR"*/}
                                    {/*    searchEnabled={true}*/}
                                    {/*    value={filtri?.idFar}*/}
                                    {/*/>*/}
                                    <SelezioneMultiplaFarmacie
                                        dataSource={props.datiFarmacie}
                                        id="idFar"
                                        impostaFiltri={impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="single"

                                        primaSelezionata={false}
                                        valueGrid={(filtri?.idFar) ? filtri?.idFar : []}

                                    />
                                    {/*<SelezioneMultipla*/}
                                    {/*    dataSource={props.datiFarmacie}*/}
                                    {/*    id="lstFar"*/}
                                    {/*        impostaFiltri={impostaFiltri}*/}
                                    {/*    column={colonneGridGeneriche}*/}
                                    {/*    selection="multiple"*/}
                                    {/*    primaSelezionata={false}*/}
                                    {/*    key="ID_FAR"*/}

                                    {/*/>*/}
                                </Col>
                                <Col sm="1">
                                    {/*<Button id="btnSalvaImpostazioniOrdLibero" variant="success" onClick={clear} >*/}
                                    {/*    Pulisci*/}
                                    {/*</Button>*/}
                                </Col>
                                <Col sm="4">
                                </Col>
                            </Row>
                        </Item>

                    </TabPanel>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSalvaImpostazioniOrdLibero" variant="success" onClick={salvaImpostazioniPrezzoPromozione} >
                        Salva
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => props.handleCloseDialogImpostazioniPrezzoPromozione()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export const DialogDitta = (props: {
    isDialogDitta: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    setIsDialogDitta: (isDialogDitta: boolean) => void
    handleCloseDialogDitta: () => void,
    setIsVisible: (isVisible: boolean) => void,
    impostaFiltri: (newFiltro: object) => void,
    filtri: any
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [ditte, setDitte] = useState<DittaInterface[]>();
    const [prodottiDataSource, setProdottiDataSource] = useState<any>([])
    const [codCsf, setCodCsf] = useState<any>([]);
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const [disabled, setDisabled] = useState<boolean>(true);
    const [disabledSalva, setDisabledSalva] = useState<boolean>(true)

    const [codCsfKeys, setCodCsfKeys] = useState<any>([])

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    useEffect(() => {

        if (props.isDialogDitta) {

           
            if (!ditte || ditte == undefined) {
                setIsVisible(true);
                RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
                    if (result) {
                        setIsVisible(false);
                        setDitte(result);
                    }
                })
            }
        }

    }, [props.isDialogDitta])

    const salvaFiltri =  () => {
        

        RestServices().rest('POST', props.filtri, urlPrezzoPromozione.setPrezzoPromozioneDitta).then(result => {
            setIsVisible(false);
            if (result.esito) {
                /*notify({ position: "center", width: "auto", message: "Salvataggio avvenuto con successo" }, "success", 4000)*/
                notify({ position: "center", width: "auto", message: "Salvataggio avvenuto con successo" }, "success", 4000)
                props.handleCloseDialogDitta()
            } else if (result.esito !== true) {
                notify({ position: "center", width: "auto", message: "Errore nel salvataggio" }, "error", 4000)
            }
        })
    }

    useEffect(() => {

        if (props.filtri) {
            if (props.filtri.idDitta != null && 
                props.filtri.idDitta.length > 0 && 
                props.filtri.lstFar != null && 
                props.filtri.lstFar.length > 0 &&
                props.filtri.sconto > 0 &&
                props.filtri.dal != null &&
                props.filtri.al != null &&
                codCsf != null && 
                codCsf.length > 0 &&
                props.filtri.quantita > 0
            )
                setDisabledSalva(false)
            else
                setDisabledSalva(true)
        }
        else
            setDisabledSalva(true)


    }, [props.filtri])

    useEffect(() => {

    },[codCsf])

    const cercaProdotti = () => {

        if (props.filtri.idDitta && props.filtri.idDitta.length > 0) {

            const parametriCerca = {
                testo: '',
                idDitta: props.filtri.idDitta[0],
            }

            //console.log(parametriCerca)

            setIsVisible(true)

            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    if (result) {
                        setDisabled(false)
                        setIsVisible(false);
                        if (result.length >= 1) {
                            setProdottiDataSource(result);
                        } else {
                            notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                            setProdottiDataSource([]);
                            setDisabled(true)
                        }
                    }
                    
                })
        }

        
    }

    const columns = [
        /*{ caption: "Descrizione", dataField: "descrizione", sortOrder: "asc" },*/
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Cod.Prod", dataField: "minsan" },
        { caption: "EAN", dataField: "ean" },
        { caption: "Prezzo", dataField: "prezzo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, },
        { caption: "Codice CSF", dataField: "codCsf", visible: false }
    ];

    const cambiaCodCsfSel = (value: any) => {

        let ids: number[] = [];

        let selectedProd: any[] = [];

        value.map((forn: any) => {

            //console.log(forn)

            ids.push(forn.codCsf)

            let obj = { codCsf: forn.codCsf, prezzo: forn.prezzo, codProd: forn.minsan }
            selectedProd.push(obj)
        })

        //console.log(selectedProd)

        setCodCsf(ids)
        let newFiltro: OggettoInterface = {};
        newFiltro['prodotti'] = selectedProd
        props.impostaFiltri(newFiltro)

    }

    useEffect(() => {

        if (prodottiDataSource) {
            if (tabellaInstance)
                tabellaInstance.instance.selectAll();
        }

    }, [prodottiDataSource])



    return (

        <>
            <Modal
                show={props.isDialogDitta}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Filtri prodotti nuovo prezzo promozione</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm="12">
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Ditta: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="4">
                                    <SelezioneMultipla
                                        id="idDitta"
                                        dataSource={ditte}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="single"
                                        primaSelezionata={false}
                                    ></SelezioneMultipla>
                                </Col>
                                <Col sm="2">
                                    <Button type="button" variant="success" id="btnCerca" onClick={cercaProdotti}>Cerca</Button>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Farmacie: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    
                                    <TendinaFarm
                                        datiFarmacie={props.datiFarmacie}
                                        id="lstFar"
                                        impostaFiltri={props.impostaFiltri}
                                        valueGrid={props.filtri?.lstFar}
                                        disabled={disabled}
                                    />
                                </Col>

                            </Row>

                            <Row>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Sconto %: </span>
                                </Col>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Quantita'': </span>
                                </Col>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Dal: </span>
                                </Col>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Al: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="3" className="labelCreaNuovoOrdLib">

                                    <NumberBoxFiltro
                                        id={"sconto"}
                                        impostaFiltri={props.impostaFiltri}
                                        max={100}
                                         step={1}
                                        disabled={disabled}
                                    />
                                </Col>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <NumberBoxFiltro id="quantita"
                                        impostaFiltri={props.impostaFiltri} disabled={disabled}
                                        max={365}
                                         step={1}
                                    />
                                </Col>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <BoxDataOra id="dal" impostaFiltri={props.impostaFiltri} typeDate="date" disabled={disabled } />
                                </Col>
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <BoxDataOra id="al" impostaFiltri={props.impostaFiltri} typeDate="date" disabled={disabled} />
                                </Col>

                            </Row>

                            <Row>
                                <Col sm="12">
                                    <NewTabella
                                        idTabella='elenco_prodotti'
                                        sync
                                        colonne={columns}
                                        dataSource={prodottiDataSource}
                                        filterRow
                                        searchPanel={true}
                                        onclickRow={() => null}
                                        exportT={{
                                            enabled: false,
                                            fileName: "elenco_prodotti"
                                        }}
                                        activeSaver={true}
                                        columnC={false}
                                        multyp
                                        keyExpr={"codCsf"}
                                        selectedRowKeys={codCsf}
                                        selectedValue={(datiRow: any) => {  cambiaCodCsfSel(datiRow.selectedRowsData); } }
                                        tabReference={setTabellaInstance}
                                        pagination={10}
                                        height={480}
                                        
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                   
                        </Modal.Body>
                        <Modal.Footer>
                    <Button id="btnSalvaFiltriProdottoOrdLibero"
                        variant="success"
                        onClick={salvaFiltri}
                        disabled={disabledSalva }
                    >
                                Salva prodotti
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => props.handleCloseDialogDitta()}>
                                Annulla
                            </Button>
                        </Modal.Footer>
                    </Modal>


        </>
    )
}

export const DialogUploadPrezzoPromozione = (props: {
    isDialogUploadPrezzoPromozione: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    setIsDialogUploadPrezzoPromozione: (isDialogUploadPrezzoPromozione: boolean) => void
    handleCloseDialogUploadPrezzoPromozione: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [uploadUrl, setUploadUrl] = useState<any>();
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();
    const [formRef, setFormRef] = useState<any>();
    const [fileUploaderRef, setFileUploaderRef] = useState<any>();

    const fileExtensions = ['.csv'];

    useEffect(() => {

        if (props.isDialogUploadPrezzoPromozione) {
            setUploadUrl(urlPrezzoPromozione.importaDaFile);
        }
    }, [props.isDialogUploadPrezzoPromozione])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogUploadPrezzoPromozione(false);
    }

    const aggiungiParametroFile = (e: any) => {
        if (e.value.length > 0) {
            setUploadUrl(updateQueryStringParameter(uploadUrl, "nomeFile", e.value[0].name))
            e.component.option("uploadUrl", uploadUrl);
        }

    }

    const updateQueryStringParameter = (uri: any, key: any, value: any) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            //console.log("1:" + uri.replace(re, '$1' + key + "=" + value + '$2'));
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            //console.log("2:" + uri + separator + key + "=" + value);
            return uri + separator + key + "=" + value;
        }
    }

    const importa = () => {

    }

    const editorOptions = {
        readOnly: true,
    };

    const submitButtonOptions = {
        text: "Importa file",
        useSubmitBehavior: true,
        type: "success",
        onClick: function (e: any) {

            setUploadUrl(uploadUrl);
            var upl = '';
            if (filtri != undefined) {
                if (filtri.lstFar != undefined || filtri.length > 0) {
                    upl = upl + '&lstFar=' + filtri.lstFar;
                }
                if (filtri.dal != undefined) {
                    upl = upl + '&dal=' + cambiaFormatoLocalDateTimeToEuropeo(filtri.dal);
                }
                if (filtri.al != undefined) {
                    upl = upl + '&al=' + cambiaFormatoLocalDateTimeToEuropeo(filtri.al);
                }
                if (filtri.sostituisci != undefined) {
                    upl = upl + '&sostituisci=' + filtri.sostituisci;
                }
            }
            setUploadUrl(uploadUrl + upl);
        }
    };

    const handleSubmit = (e: any) => {

        props.setIsVisible(true);

        e.preventDefault();
        fetch(e.target.action, {
            method: 'POST',
            body: new FormData(e.target),
            credentials: 'include'
        }).then(resp => resp.json())
            .then(
                (result) => {
                    if (result.esito == true) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Importazione completata" }, "success", 4000)
                    }
                    else if (result.esito == false) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                }).catch((error) => {
                    props.setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                });



    }

    return (
        <>
            <Modal
                show={props.isDialogUploadPrezzoPromozione}
                onHide={handleClose}
                dialogClassName="dialogNuovaNewsletter"
                size="lg"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Importa da file</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-uploader-block">
                        <form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} onSubmit={handleSubmit}>
                            {/*<form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} target="_blank">*/}
                            <Form
                                colCount={2}>

                                <GroupItem
                                    caption="Tracciato del file"
                                    colSpan={2}>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">Codice ministeriale<b>;</b>prezzo</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( separatore decimale ammesso per il prezzo punto (.) o virgola (,) )</span>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <span>Esempi:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">004763037;5.2</span>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">025940053;4,43</span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli il file"
                                    colSpan={2}>
                                    <FileUploader
                                        selectButtonText="Seleziona file" labelText=""
                                        /*accept="image/*"*/
                                        uploadMode="useForm"
                                        uploadUrl={uploadUrl}
                                        onValueChanged={aggiungiParametroFile}
                                        allowedFileExtensions={fileExtensions}
                                        maxFileSize={2000000}
                                        id="fileUploader"
                                        ref={setFileUploaderRef}
                                    />
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'File permessi: '}
                                                <span>.csv</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'Dimensione massima file: '}
                                                <span>2 MB</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli le farmacie"
                                    colSpan={2}>

                                    <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} valueGrid={filtri?.lstFar} />

                                </GroupItem>
                                <GroupItem
                                    caption="Inserisci le date"
                                    colSpan={1}>
                                    <Row>
                                        <Col sm="1">
                                            <span>Dal:</span>
                                        </Col>
                                        <Col sm="5">
                                            <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                    <Row>
                                        <Col sm="1">
                                            <span>Al:</span>
                                        </Col>
                                        <Col sm="5">
                                            <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={al} />
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Seleziona opzione"
                                    colSpan={2}>
                                    <span style={{ cursor: "help" }} title="Sostituisce i prezzi promozione non ancora inviati, per le farmacie selezionate, con quelli presenti nel file ed azzera i prezzi promozione precedentemente inviati">Sostituisci prezzi promozione: </span>
                                    &nbsp;
                                    <Check id="sostituisci" impostaFiltri={impostaFiltri} />
                                    <Col>
                                        <span className="checkPrezzoInfo">Sostituisce i prezzi promozione non ancora inviati, per le farmacie selezionate, con quelli presenti nel file ed azzera i prezzi promozione precedentemente inviati</span>
                                    </Col>
                                </GroupItem>
                                <ButtonItem colSpan={2} buttonOptions={submitButtonOptions} horizontalAlignment="right" />
                            </Form>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button id="btnImportaPrezzoPromozione" variant="success" onClick={() => importa()}>*/}
                    {/*    Importa*/}
                    {/*</Button>*/}
                    <Button id="btnChiudiDialogImportPrezzoPromozione" variant="danger" onClick={() => props.handleCloseDialogUploadPrezzoPromozione()}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}