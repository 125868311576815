import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import { SelectBox } from 'devextreme-react/select-box'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import Button from 'devextreme-react/button'
import Select from 'react-select'
import {
    Validator,
    RequiredRule,
    PatternRule,
    StringLengthRule,
    EmailRule  
  } from 'devextreme-react/validator';


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

interface Profilo {
    id:string
    nomeProfilo:string
}

export const DialogEdit:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [profiloList,setProfiliUtenti] = useState<any>([])
    const [id,setId] = useState<number|null>(null)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [loading,setLoading] = useState<boolean>(false)
    const [profili,setProfili] = useState<Profilo[]>([])
    const [farma,setFarma] = useState<[]>([])


    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({}) 
        }

    },[edit])

    useEffect(()=>{
        if(auth && open){
            //farmaList()
            getProfili()
            getProfUtenti()  
        }
       
    },[auth,open])

    const getProfUtenti = () =>{
        //setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getProfiliUtenti)
                .then(result => {
                    if (result?.esito) {
                        setProfiliUtenti(result.response)
                        //setTabellaPrincipale(result.response)
                        setLoading(false)
                        //console.log(result);
                    }else{
                       // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })      
         }
    }


    // const farmaList = () =>{
       
    //        RestServices().rest('POST', {} , urlConsultazione.getListFar)
    //            .then(result => {
    //                if (result?.esito) {                     
    //                    //console.log(result.response);
    //                    setFarma(result.response)
                       
    //                }else{                               
    //                    //console.log(result);
                      
    //                }
    //            })     
    // }

    const getProfili = () =>{
       RestServices().rest('POST', {} , urlConsultazione.getProfiliUtenti)
            .then(result => {
               if(result.esito){
                    setProfili(result.response)
                 
               }
               else{
                //console.log(result);
                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
            }
    
            }) 
   }


   const ConvertForSelect = (arr:any) =>{
    let newArr:any = []
    arr.forEach((element:any) => {
        //console.log(element);
        newArr.push({value:element.idFar,label:element.descrizione})
        
    });
        return newArr
    }

    const cambiaValore = (tipo:string,value:string) =>{
            let objTo = {...objctTOsave}
            objTo[tipo] = value
           // console.log('objTo');
           // console.log(objTo);
            
            setObjctTOsave(objTo)
            //console.log(objTo);   
            if(objTo.abi?.length > 3 && objTo.cab?.length > 3 && objTo.ragioneSociale?.length > 4  && objTo.filiale?.length  > 3  ){
                setDisableButton(false)
            }else{
                setDisableButton(true)
        }
    }
           
       
    const SaveProf = ()=>{
        RestServices().rest('POST', { id:objctTOsave._id ,name:objctTOsave.pn } , urlConsultazione.renameProfilo)
                .then(result => {
                   if(result.esito){
                    ricarica() 
                        notify({ position: "center", width: "auto", message: 'Salvataggio avvenuto'}, "success", 4000)

                                
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }    
                })   
    }


   const CreateUpdateProfilo = (edit?:boolean) =>{

    if(typeEdit){
        let objToUseEdit:any = {
            "id": objctTOsave.id,
            "nome": objctTOsave.pn,
            
        }

        
        if (auth) {
           //console.log(objctTOsave);
           RestServices().rest('POST', objToUseEdit , urlConsultazione.renameProfilo)
               .then(result => {
                   if (result?.esito) { 
                    ricarica()  
                                           
                   }else{                              
                      setLoading(false)
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   }
               })     
       }

    }else {


        let objToUse:any = {
            //"id": objctTOsave.id,
            //"username":'pppp',
            "id": objctTOsave.id,
            "nome": objctTOsave.nomeProfilo,
        }
        // if(objctTOsave.elencoFarmacie?.length > 0){
        //     let ids:number[] = []
        //     objctTOsave.elencoFarmacie?.forEach((element:{ label: string, value: number }) => {
        //         ids.push(element.value)
                
        //     });
        //     objToUse.elencoFarmacie = ids
        // }
        
        if (auth) {
           //console.log(objctTOsave);
           RestServices().rest('POST', objToUse , urlConsultazione.createProfili)
               .then(result => {
                   if (result?.esito) { 
                    ricarica()  
                                           
                   }else{                              
                      setLoading(false)
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   }
               })     
       }



    }

    
}



    useEffect(()=>{
        setOpenD(open)
    },[auth])


    useEffect(()=>{
        setOpenD(open)
    },[open])



    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'45%'}
          height={'50%'}
          showTitle={true}
          title="Gestione Profilo"
        >
         <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
           <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'CHIUDI',
                onClick: ()=>{close()},
              }}
          />

              
               <ScrollView width='100%' height='100%'>

                    <form action="your-action" onSubmit={(e)=>{
                              SaveProf()

                                
                            }}>
                                
                    <div className='form-row '> 
                <div className="form-group col-md-6">
                      <label> NOME PROFILO </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.pn}  onValueChanged={(v)=>cambiaValore('pn',v.value)}    placeholder="Campo obbligatorio" >
                      <Validator>
                            <RequiredRule message="Nome obbligatorio" />
                            {/*<PatternRule message="Do not use digits in the Name" pattern={/^[^0-9]+$/} />*/}
                            {/*<StringLengthRule message="Il codice deve essere massimo di 10 caratteri" max={10} />*/}
                        </Validator>
                    </TextBox> 
                   </div>
                   
                   </div>
                   


      
                

             <div>
           </div>

           <div style={{position:'fixed',bottom:22,right:130,zIndex:2, width:100}}>
                  <Button
                    id="button"
                    text={typeEdit ? "Modifica" :"SALVA"}
                    type="success"
                    onClick={()=>SaveProf()}
                    width={200}    />      
            </div>
                    </form>
           </ScrollView>    
          
        
        </Popup>
        </>
      )

  
}






     