import React, { useState, useEffect } from "react";
import { NewTabella } from "../../../../widget/nuova_tabella/newBusineVersion";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../../widget/Notifications";
import Button from "react-bootstrap/Button";
import {
  urlConsultazione,
  urlReport,
  urlCommon,
  urlBancaDati
} from "../../../costanti";
import { Export, Series, Title, Tooltip } from "devextreme-react/chart";
import { Font, HoverStyle } from "devextreme-react/pie-chart";
import DataSource, { DataSourceOptions } from "devextreme/data/data_source";
import FiltriVerticale from "../../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../../consultazione/interface";
import notify from "devextreme/ui/notify";
import { Pop_Ana_Sco_Vend_Categ } from "../../queries/queriesCruscotto";
import { RestServices } from "../../../../services/restServices";
import { createItemFilterAnaSconCate } from "../ItemsFiltri/analiScontiCateg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { columnsOpeAnaScoCatePop } from "../Colums/analisiPerOperatore";

import "./style.css";


const dashboard6 = { dashboardID: 6 };

interface DialogProps {
  open: boolean;
  close: () => void;
  ricarica: () => void;
  filterData: any;
  auth: any;
  //edit: any,
  //listaFarmacie:any
}

export const DialogAnaScoCateg: React.FC<DialogProps> = ({
  open,
  close,
  filterData,
  auth,
  ricarica,
}) => {
  const [openD, setOpenD] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState(false);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [valoreTorte, setValoreTorte] = useState<any>([]);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [operatori, setOperatori] = useState<any[]>([]);
  const [codiciCategory, setCodiciCategory] = useState<any[]>([]);
  const [tipologiaSconto, setTipologiaSconto] = useState<any[]>([]);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const [ditte, setDitte] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [category, setCategory] = useState<any[]>([]);




  const call50 = {
    queryID: 50,
    filterDetailsIDs: Pop_Ana_Sco_Vend_Categ.filterDetailsIDs.map(
      (column) => {
        const { select, where, groupBy, filterValueList, orderBy } = column;

        const dwhColumn = {
          id: column.id,
          select,
          where,
          groupBy,
          filterValueList,
          orderBy,
        };

        return dwhColumn;
      }
    ),
  };


  function aggiornaDataFine(dataFine: Date): Date {
    const nuovaDataFine = new Date(dataFine);
    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);
    return nuovaDataFine;
  }


  const filters = () => {
    if (auth) {

      RestServices().rest('POST', "", urlCommon.getListCate)
        .then(result => {
          if (result) setCodiciCategory(result);
          console.log(result)
        });

        RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
          console.log('vediamoooo categorie');
          console.log(result);
          if (result) setCategory(result);
          
          
          //if (result) setCategory(result);
      })
    }



    const SCONTO_MISTO = { id: 'SCONTO_MISTO', descrizione: "Sconto misto" };
    const SCONTO_PRODOTTO = { id: 'SCONTO_PRODOTTO', descrizione: "Sconto prodotto" };
    const SCONTO_TESTATA = { id: 'SCONTO_TESTATA', descrizione: "Sconto testata" };

    const sconto = [SCONTO_PRODOTTO, SCONTO_TESTATA, SCONTO_MISTO];
    setTipologiaSconto(sconto);

  };


  useEffect(() => {
    if (openD) {
      filters();
    }
  }, [auth, openD]);


  const filterAction = (objFiltro: Filtri, newF?: boolean) => {
    let params = { ...objFiltro };
    setParamsFilter(params);
    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    } else {
      newFarmaList.forEach((element: any) => {
        newIDFAR.push(element.idFar);
      });

      params.idFar = newIDFAR;
    }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    let category1;
    let category2;
    let category3;
    let category4;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.category) {
      const categoryList = params.category;
      category1 = getCategoryByLevel(categoryList, 2);
      category2 = getCategoryByLevel(categoryList, 4);
      category3 = getCategoryByLevel(categoryList, 6);
      category4 = getCategoryByLevel(categoryList, 8);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      category1: category1,
      category2: category2,
      category3: category3,
      category4: category4,
      ditta: params.ditta,
      tipologiaSconto: params.tipologiaSconto,
      //  tipologiaSconto: ["SCONTO_TESTATA"] ,
      operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", "")
    };




    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    //if (authReport)
    {

      const infoClick1Call1 = {
        ...call50,
        idFarList: params.idFar,
        params: parameters

      };


      RestServices()
        .rest("POST", infoClick1Call1, urlReport.getQueryDashboardData)
        .then((result) => {
          if (result) {
            console.log("mostro results 50");
            console.log(result);
            console.log(params);
            setValoriTabella1(result);
            setIsVisible(false);
            //alert('ok')
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });

      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  function getCategoryByLevel(getListCate: any[], level: number) {
    return getListCate.filter((category) => category.length === level * 1);
}

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });

      RestServices()
        .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
        .then((result) => {
          if (result.esito) {
            const operatori = result.response.map(
              (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                return {
                  ...ope,
                  id: `${ope.operatore}`,
                  descrizione: `${ope.operatore}`,
                  codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                };
              }
            );
            setOperatori(operatori);
          }
        });
    }
  };

  const itemsFields = createItemFilterAnaSconCate(
    newFarmaList,
    operatori,
    category,   
    tipologiaSconto
);

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  useEffect(() => {
    setOpenD(open);
    if (open) {
      //qui prende i dati filtri della pagina padre
      console.log(filterData);
    }
  }, [open]);

  useEffect(() => {
    if (openD) {
      farmaList();
    }
  }, [auth, openD]);

  useEffect(() => {
    if (JSON.stringify(filterData) === '{}') {

    } else {
      if (openD) {
        //alert(JSON.stringify(filterData))
        filterAction(filterData, true)
      }


    }

  }, [filterData, openD])

  if (!openD) {
    return null
  } else {
    return (
      <>
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 801,
            background: '#00000082',
            padding: 30
          }}
        >
          <div style={{ background: 'white', padding: 15, borderRadius: 10 }}>
            <div className="butt">
              <button
                onClick={() => close()}
                className="buttonclose"
              >CHIUDI</button></div>
            <div>
              <div style={{ display: "flex" }}>
                {loadPanel}
                {loadinCambio ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                  </div>
                ) : (
                  <div style={{ height: '90vh', overflow: "scroll", width: "100%", }}>
                    <h6
                      style={{
                        color: "#5b6372",
                        fontFamily: "Arial, sans-serif",
                        marginBottom: "-20px",
                        marginLeft: "10px",
                        zIndex: 1,
                        position: "relative"
                      }}
                    >
                      Dettaglio Analisi sconti operatore
                    </h6>
                    <NewTabella
                      height={"70vh"}
                      idTabella="analisi_prodotti"
                      sync
                      colonne={columnsOpeAnaScoCatePop}
                      dataSource={valoriTabella1}
                      rowAlternation={true}
                      filterRow
                      width={500}
                      searchPanel={true}
                      exportT={{
                        enabled: true,
                        fileName: "AnalisiProdotti",
                        //formats: { exportFormats },
                      }}
                      activeSaver={true}
                      columnC={true}
                      headerFilter
                      wordWrapEnabled={true}
                      // onExporting={onExporting}
                      summary={
                        <Summary>
                          <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                          <TotalItem column="OMAGGI" summaryType="sum" />
                          <TotalItem
                            column="COSTO"
                            summaryType="sum"
                            valueFormat={{ style: "currency", currency: "EUR" }}
                          />
                        </Summary>
                      }
                    />
                  </div>
                )}
                <div
                  style={{ width: "auto", display: "flex", alignItems: "center" }}
                >
                  <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                  />
                </div>
                <section style={{ opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>

                  <div >
                    <FiltriVerticale
                      pop
                      ricerca={filterAction}
                      items={itemsFields}
                      paramsFilter={paramsFilter}
                    />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

};
