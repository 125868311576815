import notify from "devextreme/ui/notify";
import React, { useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { OggettoInterface } from "../../../interfaces/generiche";
import { TabellaCompletaInterface } from "../../../interfaces/tabelle";
import { RestServices } from "../../../services/restServices";
import { BoxFiltro, Check } from "../../../widget/Editor";
import { Loading } from "../../../widget/Notifications";
import { Selezione } from '../../../widget/Select';
import { TabellaMasterDetailed } from "../../../widget/Tabelle";
import { urlBancaDati } from "../../costanti";
import '.././style.css';


export const ProdottoDialog = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    isDialogImpostazioniProdotto: boolean,
    setIsDialogImpostazioniProdotto: (isDialogImpostazioniPrezzoPromozione: boolean) => void
    handleCloseDialogProdotto: () => void,
    functionNext: () => void,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [ditta, setDitta] = useState<any[]>([]);
    const [prodotti, setProdotti] = useState<any[]>([]);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();


    const [filtri, setFiltri] = useState<OggettoInterface>({});

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {

        setFiltri(prevFiltri => {
            const { codiceDitta: _, ...restoFiltri } = prevFiltri;
            return restoFiltri;
        });

        setDitta([]);
        setProdotti([]);

        props.setIsDialogImpostazioniProdotto(false);
    }


    const propsTabElenco: TabellaCompletaInterface = {
        id: "elenco_prodotti",
        dataSource: (prodotti && prodotti.length > 0) ? prodotti : [],
        selection: "none",
        autoWidth: true,
        pagination: 5,
        enablePagination: true,
        headerFilterVisible: true,
        defaultFilterValue: null,

        export: {
            enabled: false,
            fileName: "DialogProdotto"
        },
    }

    const ElencoProdotti = CreaColonne([
        { caption: "Codice Prodotto", dataField: "c" },
        { caption: "EAN", dataField: "e" },
        { caption: "Descrizione", dataField: "d" }
    ])


    const advancedSearch = () => {
        let model = {
            dec: filtri.contieneDescrizione ? filtri.contieneDescrizione : "",
            def: filtri.finisceDescrizione ? filtri.finisceDescrizione : "",
            dei: filtri.inizioDescrizione ? filtri.inizioDescrizione : "",
            e: filtri.ean ? filtri.ean : "",
            c: filtri.codiceProdotto ? filtri.codiceProdotto : "",
            t: filtri.codiceDitta && filtri.codiceDitta.length > 0 ? filtri.codiceDitta : JSON.stringify([-1]),
            ico: filtri.inCommercio
        }

        setIsVisible(true);

        RestServices().rest('POST', model, urlBancaDati.advancedSearch)
            .then(result => {

                if (result) {

                    setProdotti(result.response.data);
                    setIsVisible(false);

                } else {

                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                }
            })
    };


    const findProdottiDitta = (e: string) => {

        let model = {
            'q': e,
        };

        setIsVisible(true);

        RestServices().rest('POST', model, urlBancaDati.findProdottiDitta)
            .then(result => {

                if (result) {
                    //console.log("Ditta: " + result.response);
                    setDitta(result.response)
                    setIsVisible(false);

                } else {
                    // console.log(result);
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                }
            })

    }

    return (
        <>
            <Modal
                show={props.isDialogImpostazioniProdotto}
                onHide={handleClose}
                dialogClassName="dialogRiferimento"
                size="lg"
                centered={false}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h2 className="main-title"> Ricerca Prodotto </h2>
                        <h6 className="sub-title"> Ricerca avanzata dei prodotti</h6>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {loadPanel}

                    <Row className="rigaCreaNuovoOrdLib d-flex align-items-center">
                        <Col sm="2">
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>Descrizione</span>
                            <BoxFiltro id="inizioDescrizione" impostaFiltri={impostaFiltri} placeholder="inizia per" />
                        </Col>

                        <Col sm="2">
                            <div style={{ marginTop: '19px' }}>
                                <BoxFiltro id="contieneDescrizione" impostaFiltri={impostaFiltri} placeholder="contiene" />
                            </div>
                        </Col>

                        <Col sm="2">
                            <div style={{ marginTop: '19px' }}>
                                <BoxFiltro id="finisceDescrizione" impostaFiltri={impostaFiltri} placeholder="finisce per" />
                            </div>
                        </Col>
                    </Row>

                    <Row className="rigaCreaNuovoOrdLib d-flex align-items-center">
                        <Col sm="3">
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>Codice Prodotto</span>
                            <BoxFiltro id="codiceProdotto" impostaFiltri={impostaFiltri} />
                        </Col>

                        <Col sm="3">
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>EAN</span>
                            <BoxFiltro id="ean" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>Presenza del prodotto in</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>commercio</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ marginTop: 5 }}>
                            <Check id="inCommercio" impostaFiltri={impostaFiltri} /> <span>In commercio</span>
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ marginTop: 5 }} sm="6">
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>Ditta</span>
                            <Selezione id="codiceDitta" impostaFiltri={impostaFiltri} placeholder="Cerca Ditta..." dataSource={ditta} displayExpr="text" valueExpr="id" isService={true} isSelezioneMultipla={true} onChange={(valoreDigitato) => { if (valoreDigitato.length >= 2) { findProdottiDitta(valoreDigitato); } }} />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Button type="button" variant="success" id="btnCerca" style={{ width: 100 }} onClick={() => { advancedSearch(); }}>
                                Cerca
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div style={{ marginTop: 15, marginBottom: -10 }}>
                                <p className="main-topic"> Tabella <span className="sub-topic"> Elenco Prodotti </span></p>
                            </div>

                            <TabellaMasterDetailed
                                propsTabella={propsTabElenco}
                                columns={ElencoProdotti}
                                setTabellaInstance={setTabellaInstance}
                                selectedRowKeys={selectedRowKeys}
                                searchPanel={false}
                                onToolbarPreparing={false}
                                enableColumnChooser={false}
                            />
                        </Col>
                    </Row>

                    <Modal.Footer>
                        <button className="PopSecond-btn" onClick={handleClose}>
                            Chiudi
                        </button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )

}