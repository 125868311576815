import Form, { RangeRule, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { SelectFarm, Selezione, SelezioneGerarchica, SelezioneMultipla, SelezioneMultiplaGerarchica, TendinaFarm } from '../../widget/Select';
import { CategoryInterface, ClasseAInterface, DittaInterface, FornitoriCentrInterface, GruppiInterface, GruppoMerceologicoInterface, OggettoInterface, SettoriInterface } from '../../interfaces/generiche';
import { ImpostazioniOrdineLiberoInterface, ImpostazioniOrdineLiberoOrdineFarmacia, ImpostazioniOrdineLiberoPuntiVendita, ImpostazioniOrdineLiberoPuntiVenditaFarmacie, ImpostazioniOrdineLiberoPuntiVenditaTrasferitoVenduto, InfoInterface, InfoTrasmissioneInterface, NewTestataInterface, PrioritaInterface } from '../../interfaces/ordineLibero';
import notify from 'devextreme/ui/notify';
import { RestServices } from '../../services/restServices';
import { basenameHostNewTabs, urlAnagrafiche, urlB2B, urlBancaDati, urlConsultazione, urlOrdiniLiberi } from '../costanti';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { BoxDataOra, BoxFiltro, Check } from '../../widget/Editor';
import { Loading } from '../../widget/Notifications';
import { AplicaValoreTO, CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { ElementOptionChanged } from '../../interfaces/componentOptions';
import { TabellaCompleta, TabellaCompletaAlternate, TabellaSemplice } from '../../widget/Tabelle';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { List, NumberBox, RadioGroup, Validator } from 'devextreme-react';
import { ItemDragging } from 'devextreme-react/list';
import { ListElementi } from '../../widget/List';
import ArrayStore from 'devextreme/data/array_store';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import { confirm } from 'devextreme/ui/dialog';
import { ButtonItem,  GroupItem } from "devextreme-react/form";
import { FileUploader } from "devextreme-react";
import DateBox from 'devextreme-react/date-box'

export const DialogNuovoOrdineLibero2 = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogNewOpen: boolean,
    handleCloseDialogNew: () => void,
    setNewTestata: (datiTestata: NewTestataInterface) => void,
    setIsVisible: (isVisible: boolean) => void,
    setIdOrdineLibero: (idOrdine: number) => void,
    fornitori: FornitoriCentrInterface[] | undefined,
    settori: SettoriInterface[] | undefined,
    gruppi: GruppiInterface[] | undefined,
    flagOrdineB2B?: boolean | undefined
}) => {
    const newOrdineLibero = {
        titolo: undefined,
        lstFar: [],
        idFornitore: undefined,
        sconto: undefined,
        idSettore: undefined,
        idGruppo: [],
        trattati: undefined,
        flagUltimoCosto: undefined,
        flagPrezzoBancaDati: undefined,
        flagPrezzoFarmacia: undefined,
        flagQuantitaOmaggio: undefined,
        idFarmaciaPrezzo: undefined,
        idDitta: undefined,
        idLinea: [],
        idClasseA: undefined,
        idClasseC: undefined,
        idSopOtc: undefined,
        idGenerici: undefined,
        idCategory: [],
        idStato: undefined,
        stato: undefined,
        flagIncludiProdotti: undefined,
        flagUltimoSconto: undefined,
        flagVisualizzaVendutoGiacenza: undefined,
        flagVisualizzaVendutoGiacenzaTotale: undefined,
        flagVisualizzaUltimiAcquisti: undefined,
        flagVisualizzaListiniOfferte: undefined,
        flagVisualizzaAltreInfo: undefined,
        flagVisualizzaTrasferimenti: undefined,
        flagVisualizzaIdentici: undefined,
        flagVisualizzaSimili: undefined,
        avvisoFattureScadenza: undefined,
        avvisoProdottiSegnalazione: undefined,
        flagVisualizzaListiniOfferteIdentici: undefined,
        flagVisualizzaPrezzoFarmacia: undefined,
        flagModificaPrezzoFarmacia: undefined,
        avvisoFattureNote: undefined,
        flagCostoListino: undefined,
        flagVisualizzaListiniOrdineLibero: undefined,
        flagOrdineB2B: undefined,
        flagGeneraProposta: undefined,
        idTipologiaProposta: undefined,
        dataProposta: undefined,
        giorniLavoratiProposta: undefined,
        giorniCoperturaProposta: undefined,
        idStatoProposta: undefined,
        statoProposta: undefined,
        flagConsideraOrdiniApertiProposta: undefined,
        flagEscludiTrasferimenti: undefined,
        statoApprovazione: undefined,
        utenteStatoB2B: undefined,
        dataStatoB2B: undefined
    }
    const [valoreSconto, setValoreSconto] = useState<number | undefined>(undefined);
    const [filtri, setFiltri] = useState<NewTestataInterface>(newOrdineLibero);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [isDialogFiltraProdottiOpen, setIsDialogFiltraProdottiOpen] = useState<boolean>(false);
    const [avvisoFiltri, setAvvisoFiltri] = useState<string>();
    const [fornitoriB2B, setFornitoriB2B] = useState<FornitoriCentrInterface[]>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }
    useEffect(() => {
        if (props.fornitori === undefined) {
            props.setIsVisible(true);
        } else {
            props.setIsVisible(false);
        }
    }, [props.fornitori])

    useEffect(() => {
        if (filtri && filtri.idFornitore) {
            RestServices().rest('POST', filtri.idFornitore, urlOrdiniLiberi.getSconto).then(result => {
                if (typeof result === "number") {
                    impostaFiltri({ sconto: result });
                    setValoreSconto(result);
                }
                else {
                    impostaFiltri({ sconto: undefined });
                    setValoreSconto(undefined);
                }
            })
        }
    }, [filtri.idFornitore])

    useEffect(() => {

        if (props.flagOrdineB2B != undefined && props.flagOrdineB2B == true) {

            //query fornitori in base alle farmacie scelte
            let param = {lstFar: filtri.lstFar}
            RestServices().rest('POST', param, urlB2B.getListFornitore).then(result => {
                if (result) {
                    let list: any = [];
                    result.response.forEach((item: any) => {
                        list.push({ ID: item.id, RAGIONE_SOCIALE: item.ragione_SOCIALE, PARTITA_IVA: item.partita_IVA })
                    })
                    setFornitoriB2B(list);
                }

            })

        }

    },[filtri.lstFar])

    const cambiaValore = (tipo: string, value: any) => {
        let newFiltro: OggettoInterface = {};
        newFiltro[tipo] = value
        impostaFiltri(newFiltro);
        setValoreSconto(value);
    }

    const creaNewOrdineLibero = () => {
        if (!filtri.titolo) {
            notify({ position: "center", width: "auto", message: "Inserire il titolo del nuovo ordine" }, "error", 4000)
        } else if (!filtri.lstFar) {
            notify({ position: "center", width: "auto", message: "Selezionare una o più farmacie" }, "error", 4000)
        } else if (filtri.lstFar && filtri.lstFar.length > 12) {
            notify({ position: "center", width: "auto", message: "Selezionare al massimo 12 farmacie" }, "error", 4000)
        } else if (!filtri.idFornitore) {
            notify({ position: "center", width: "auto", message: "Selezionare un fornitore dall'elenco" }, "error", 4000)
        } else if (!props.flagOrdineB2B && ( !filtri.flagUltimoCosto  && !filtri.sconto)) {
            notify({ position: "center", width: "auto", message: "Impostare uno sconto % di default" }, "error", 4000)
        } else {
            props.setNewTestata(filtri);
            const param = {
                idFornitore: filtri.idFornitore,
                ultimoSconto: filtri.sconto
            }
            filtri.flagOrdineB2B = props.flagOrdineB2B
            setIsVisible(true);
            RestServices().rest('POST', filtri, urlOrdiniLiberi.creaOrdine).then(result => {
                if (result) {
                    setIsVisible(false);
                    if (result > 0) {
                        RestServices().rest('POST', param, urlOrdiniLiberi.setSconto)
                        props.setIdOrdineLibero(result);
                        let link = "apriOrdineLibero?"
                        if (props.flagOrdineB2B != undefined && props.flagOrdineB2B == true)
                            link = "apriOrdineB2B?"

                        let url = basenameHostNewTabs + link + result;

                        if (filtri.trattati)
                            url = url + "&true";
                        window.location.assign(url);
                    }
                    else {
                        if (result.codice != undefined)
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        else
                            notify({ position: "center", width: "auto", message: "Non ci sono prodotti per i filtri impostati" }, "error", 4000)
                    }
                } else {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore, riprovare" }, "error", 4000)
                }
            })
        }
    }

    const handleCloseDialogFiltraProdotti = () => {
        setIsDialogFiltraProdottiOpen(false);
        //verifica se sono stati selezionati dei filtri
        if ((filtri.idClasseA != undefined && filtri.idClasseA.length > 0) || (filtri.idClasseC != undefined && filtri.idClasseC.length > 0) || (filtri.idGenerici != undefined && filtri.idGenerici.length > 0)
            || (filtri.idDitta != undefined && filtri.idDitta.length > 0) || (filtri.idSopOtc != undefined && filtri.idSopOtc.length > 0) || (filtri.idGruppo != undefined && filtri.idGruppo.length > 0) || (filtri.idLinea != undefined && filtri.idLinea.length > 0)
            || (filtri.idCategory != undefined && filtri.idCategory.length > 0) || (filtri.idSettore != undefined && filtri.idSettore.length > 0)) {
            setAvvisoFiltri("Ci sono dei filtri applicati sui prodotti, premere il pulsante per modificarli");
        }
        else
            setAvvisoFiltri("");
    }
    const handleOpenDialogFiltraProdotti = () => setIsDialogFiltraProdottiOpen(true);

    const openDialogFitroProdotti = () => {
        handleOpenDialogFiltraProdotti();
    }

    return (
        <>
            <Modal
                show={props.isDialogNewOpen}
                onHide={() => window.close()}
                dialogClassName="dialogNewOrdineLibero"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Crea Nuovo Ordine</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Titolo: </span>
                        </Col>
                        <Col sm="10">
                            <BoxFiltro id="titolo" impostaFiltri={impostaFiltri} maxLength={20} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Farmacie: </span>
                        </Col>
                        <Col sm="10">
                            <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Fornitore(Ditta): </span>
                        </Col>
                        <Col sm="6">
                            <Selezione
                                dataSource={props.flagOrdineB2B != undefined && props.flagOrdineB2B == true ? fornitoriB2B : props.fornitori}
                                id="idFornitore"
                                impostaFiltri={impostaFiltri}
                                value={newOrdineLibero.idFornitore}
                                displayExpr="RAGIONE_SOCIALE"
                                valueExpr="ID"
                                searchEnabled={true}
                            />
                        </Col>
                        {props.flagOrdineB2B == undefined || !props.flagOrdineB2B && <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span id="spanScontoNuovoOrdLib">Sconto% default: <NumberBox
                                id="sconto"
                                value={valoreSconto}
                                onValueChanged={(v) => { cambiaValore('sconto',v.value) } }
                                disabled={filtri.flagUltimoCosto}
                            >
                                <Validator>
                                    <RangeRule min={0} max={100} message="Inserire un valore compreso tra 0 e 100" />
                                </Validator>
                            </NumberBox></span>
                        </Col>}
                    </Row>

                    {!props.flagOrdineB2B && <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Considera ultimo costo: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagUltimoCosto" impostaFiltri={impostaFiltri} disabled={filtri.flagUltimoSconto || filtri.flagCostoListino} />
                        </Col>
                    </Row>}
                    {!props.flagOrdineB2B && <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Considera ultimo sconto: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagUltimoSconto" impostaFiltri={impostaFiltri} disabled={filtri.flagUltimoCosto || filtri.flagCostoListino} />
                        </Col>
                    </Row>}
                    {!props.flagOrdineB2B && <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Considera costo listino: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagCostoListino" impostaFiltri={impostaFiltri} disabled={filtri.flagUltimoCosto || filtri.flagUltimoSconto} />
                        </Col>
                    </Row> }
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Gestisci quantità omaggio: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagQuantitaOmaggio" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Includi prodotti: </span>
                        </Col>
                        <Col sm="1">
                            <Check id="trattati" impostaFiltri={impostaFiltri} />
                        </Col>
                        <Col sm="2">
                            <Button id="btnIncludiProdotti" variant="success" onClick={openDialogFitroProdotti} disabled={!filtri.trattati} >
                                Filtra prodotti
                            </Button>
                        </Col>
                        <Col sm="4">
                            <span id="avvisoFiltri">{avvisoFiltri}</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiNewOrdLibero" variant="success" onClick={creaNewOrdineLibero} >
                        Crea
                    </Button>
                    <Button id="btnChiudiDialogNewOrdLibero" variant="danger" onClick={() => window.close()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

            <DialogFiltraProdottiOrdineLiberoPrev
                impostaFiltri={impostaFiltri}
                filtri={filtri}
                handleCloseDialogFiltraProdotti={handleCloseDialogFiltraProdotti}
                isDialogFiltraProdottiOpen={isDialogFiltraProdottiOpen}
                setIsVisible={setIsVisible}
            />
        </>
    )
}

export const DialogNuovoOrdineLibero = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogNewOpen: boolean,
    handleCloseDialogNew: () => void,
    setNewTestata: (datiTestata: NewTestataInterface) => void,
    setIsVisible: (isVisible: boolean) => void,
    setIdOrdineLibero: (idOrdine: number) => void,
    fornitori: FornitoriCentrInterface[] | undefined,
    settori: SettoriInterface[] | undefined,
    gruppi: GruppiInterface[] | undefined,
    flagOrdineB2B?: boolean | undefined
}) => {
    const newOrdineLibero = {
        titolo: undefined,
        lstFar: [],
        idFornitore: undefined,
        sconto: undefined,
        idSettore: undefined,
        idGruppo: [],
        trattati: undefined,
        flagUltimoCosto: undefined,
        flagPrezzoBancaDati: undefined,
        flagPrezzoFarmacia: undefined,
        flagQuantitaOmaggio: undefined,
        idFarmaciaPrezzo: undefined,
        idDitta: [],
        idLinea: [],
        idClasseA: undefined,
        idClasseC: undefined,
        idSopOtc: undefined,
        idGenerici: undefined,
        idCategory: [],
        idStato: undefined,
        stato: undefined,
        flagIncludiProdotti: undefined,
        flagUltimoSconto: undefined,
        flagVisualizzaVendutoGiacenza: undefined,
        flagVisualizzaVendutoGiacenzaTotale: undefined,
        flagVisualizzaUltimiAcquisti: undefined,
        flagVisualizzaListiniOfferte: undefined,
        flagVisualizzaAltreInfo: undefined,
        flagVisualizzaTrasferimenti: undefined,
        flagVisualizzaIdentici: undefined,
        flagVisualizzaSimili: undefined,
        avvisoFattureScadenza: undefined,
        avvisoProdottiSegnalazione: undefined,
        flagVisualizzaListiniOfferteIdentici: undefined,
        flagVisualizzaPrezzoFarmacia: undefined,
        flagModificaPrezzoFarmacia: undefined,
        avvisoFattureNote: undefined,
        flagCostoListino: undefined,
        flagVisualizzaListiniOrdineLibero: undefined,
        flagOrdineB2B: undefined,
        flagGeneraProposta: undefined,
        idTipologiaProposta: undefined,
        dataProposta: undefined,
        giorniLavoratiProposta: undefined,
        giorniCoperturaProposta: undefined,
        idStatoProposta: undefined,
        statoProposta: undefined,
        flagConsideraOrdiniApertiProposta: undefined,
        flagEscludiTrasferimenti: undefined,
        statoApprovazione: undefined,
        utenteStatoB2B: undefined,
        dataStatoB2B: undefined
    }
    const [valoreSconto, setValoreSconto] = useState<number | undefined>(undefined);
    const [filtri, setFiltri] = useState<NewTestataInterface>(newOrdineLibero);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [isDialogFiltraProdottiOpen, setIsDialogFiltraProdottiOpen] = useState<boolean>(false);
    const [avvisoFiltri, setAvvisoFiltri] = useState<string>();
    const [fornitoriB2B, setFornitoriB2B] = useState<FornitoriCentrInterface[]>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }
    useEffect(() => {
        if (props.fornitori === undefined) {
            props.setIsVisible(true);
        } else {
            props.setIsVisible(false);
        }
    }, [props.fornitori])

    useEffect(() => {
        if (filtri && filtri.idFornitore) {

            


            RestServices().rest('POST', filtri.idFornitore, urlOrdiniLiberi.getSconto).then(result => {
                if (typeof result === "number") {
                    impostaFiltri({ sconto: result });
                    setValoreSconto(result);
                }
                else {
                    impostaFiltri({ sconto: undefined });
                    setValoreSconto(undefined);
                }
            })

            
            

        }
    }, [filtri.idFornitore])

    useEffect(() => {

        if (props.flagOrdineB2B != undefined && props.flagOrdineB2B == true) {

            //query fornitori in base alle farmacie scelte
            let param = { lstFar: filtri.lstFar }
            RestServices().rest('POST', param, urlB2B.getListFornitore).then(result => {
                if (result) {
                    let list: any = [];
                    result.response.forEach((item: any) => {
                        list.push({ ID: item.id, RAGIONE_SOCIALE: item.ragione_SOCIALE, PARTITA_IVA: item.partita_IVA })
                    })
                    setFornitoriB2B(list);
                }

            })

        }

    }, [filtri.lstFar])

    useEffect(() => {
        //recupero cod ditta csf
        if (props.fornitori) {
            let f = props.fornitori.filter((forn) => forn.ID == filtri.idFornitore)
            if (f.length > 0) {
                let n: number[] = []
                n.push(f[0].COD_DITTA_CSF)
                /*impostaFiltri({ idDitta: n });*/

                let newFiltro: OggettoInterface = {};
                newFiltro["idDitta"] = n
                //console.log("ciao")
                //console.log(f)
                //console.log(newFiltro)
                impostaFiltri(newFiltro);

            }


        }
    }, [valoreSconto])

    const cambiaValore = (e: any) => {
        let newFiltro: OggettoInterface = {};
        newFiltro["sconto"] = e.value
        impostaFiltri(newFiltro);
        setValoreSconto(e.value);
    }

    const creaNewOrdineLibero = () => {
        //console.log(props)
        //console.log(filtri)
        if (!filtri.titolo) {
            notify({ position: "center", width: "auto", message: "Inserire il titolo del nuovo ordine" }, "error", 4000)
        } else if (!filtri.lstFar) {
            notify({ position: "center", width: "auto", message: "Selezionare una o più farmacie" }, "error", 4000)
        } else if (filtri.lstFar && filtri.lstFar.length > 12) {
            notify({ position: "center", width: "auto", message: "Selezionare al massimo 12 farmacie" }, "error", 4000)
        } else if (!filtri.idFornitore) {
            notify({ position: "center", width: "auto", message: "Selezionare un fornitore dall'elenco" }, "error", 4000)
        } else if (!props.flagOrdineB2B && (!filtri.flagUltimoCosto && !filtri.sconto)) {
            notify({ position: "center", width: "auto", message: "Impostare uno sconto % di default" }, "error", 4000)
        } else if (props.flagOrdineB2B == true && (filtri.idDitta == undefined || filtri.idDitta.length == 0)) {
            notify({ position: "center", width: "auto", message: "Selezionare almeno una ditta" }, "error", 4000)
        } else {
            props.setNewTestata(filtri);
            const param = {
                idFornitore: filtri.idFornitore,
                ultimoSconto: filtri.sconto
            }
            filtri.flagOrdineB2B = props.flagOrdineB2B
            setIsVisible(true);
            //console.log(filtri)
            RestServices().rest('POST', filtri, urlOrdiniLiberi.creaOrdine).then(result => {
                if (result) {
                    setIsVisible(false);
                    if (result > 0) {
                        RestServices().rest('POST', param, urlOrdiniLiberi.setSconto)
                        props.setIdOrdineLibero(result);
                        let link = "apriOrdineLibero?"
                        if (props.flagOrdineB2B != undefined && props.flagOrdineB2B == true)
                            link = "apriOrdineB2B?"

                        let url = basenameHostNewTabs + link + result;

                        if (filtri.trattati)
                            url = url + "&true";
                        window.location.assign(url);
                    }
                    else {
                        if (result.codice != undefined)
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        else
                            notify({ position: "center", width: "auto", message: "Non ci sono prodotti per i filtri impostati" }, "error", 4000)
                    }
                } else {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore, riprovare" }, "error", 4000)
                }
            })
        }
    }

    const handleCloseDialogFiltraProdotti = () => {
        setIsDialogFiltraProdottiOpen(false);
        //verifica se sono stati selezionati dei filtri
        if ((filtri.idClasseA != undefined && filtri.idClasseA.length > 0) || (filtri.idClasseC != undefined && filtri.idClasseC.length > 0) || (filtri.idGenerici != undefined && filtri.idGenerici.length > 0)
            || (filtri.idDitta != undefined && filtri.idDitta.length > 0) || (filtri.idSopOtc != undefined && filtri.idSopOtc.length > 0) || (filtri.idGruppo != undefined && filtri.idGruppo.length > 0) || (filtri.idLinea != undefined && filtri.idLinea.length > 0)
            || (filtri.idCategory != undefined && filtri.idCategory.length > 0) || (filtri.idSettore != undefined && filtri.idSettore.length > 0)) {
            setAvvisoFiltri("Ci sono dei filtri applicati sui prodotti, premere il pulsante per modificarli");
        }
        else
            setAvvisoFiltri("");
    }
    const handleOpenDialogFiltraProdotti = () => setIsDialogFiltraProdottiOpen(true);

    const openDialogFitroProdotti = () => {
        handleOpenDialogFiltraProdotti();
    }

    return (
        <>
            <Modal
                show={props.isDialogNewOpen}
                onHide={() => window.close()}
                dialogClassName="dialogNewOrdineLibero"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Crea Nuovo Ordine</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Titolo: </span>
                        </Col>
                        <Col sm="10">
                            <BoxFiltro id="titolo" impostaFiltri={impostaFiltri} maxLength={20} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Farmacie: </span>
                        </Col>
                        <Col sm="10">
                            <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Fornitore(Ditta): </span>
                        </Col>
                        <Col sm="6">
                            <Selezione
                                dataSource={props.flagOrdineB2B != undefined && props.flagOrdineB2B == true ? fornitoriB2B : props.fornitori}
                                id="idFornitore"
                                impostaFiltri={impostaFiltri}
                                value={newOrdineLibero.idFornitore}
                                displayExpr="RAGIONE_SOCIALE"
                                valueExpr="ID"
                                searchEnabled={true}
                            />
                        </Col>
                        {props.flagOrdineB2B == undefined || !props.flagOrdineB2B && <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span id="spanScontoNuovoOrdLib">Sconto% default: <NumberBox
                                id="sconto"
                                value={valoreSconto}
                                onValueChanged={cambiaValore}
                                disabled={filtri.flagUltimoCosto}
                            >
                                <Validator>
                                    <RangeRule min={0} max={100} message="Inserire un valore compreso tra 0 e 100" />
                                </Validator>
                            </NumberBox></span>
                        </Col>}
                    </Row>

                    {!props.flagOrdineB2B && <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Considera ultimo costo: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagUltimoCosto" impostaFiltri={impostaFiltri} disabled={filtri.flagUltimoSconto || filtri.flagCostoListino} />
                        </Col>
                    </Row>}
                    {!props.flagOrdineB2B && <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Considera ultimo sconto: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagUltimoSconto" impostaFiltri={impostaFiltri} disabled={filtri.flagUltimoCosto || filtri.flagCostoListino} />
                        </Col>
                    </Row>}
                    {!props.flagOrdineB2B && <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Considera costo listino: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagCostoListino" impostaFiltri={impostaFiltri} disabled={filtri.flagUltimoCosto || filtri.flagUltimoSconto} />
                        </Col>
                    </Row>}
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Gestisci quantità omaggio: </span>
                        </Col>
                        <Col sm="4">
                            <Check id="flagQuantitaOmaggio" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="4" className="labelCreaNuovoOrdLib">
                            <span>Includi prodotti/genera proposta: </span>
                        </Col>
                        <Col sm="1">
                            <Check disabled={filtri.idFornitore ? false : true} id="trattati" impostaFiltri={impostaFiltri} />
                        </Col>
                        <Col sm="2">
                            <Button id="btnIncludiProdotti" variant="success" onClick={openDialogFitroProdotti} disabled={!filtri.trattati} >
                                Filtra prodotti
                            </Button>
                        </Col>
                        <Col sm="4">
                            <span id="avvisoFiltri">{avvisoFiltri}</span>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiNewOrdLibero" variant="success" onClick={creaNewOrdineLibero} >
                        Crea
                    </Button>
                    <Button id="btnChiudiDialogNewOrdLibero" variant="danger" onClick={() => window.close()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

            <DialogFiltraProdottiOrdineLibero
                impostaFiltri={impostaFiltri}
                filtri={filtri}
                handleCloseDialogFiltraProdotti={handleCloseDialogFiltraProdotti}
                isDialogFiltraProdottiOpen={isDialogFiltraProdottiOpen}
                setIsVisible={setIsVisible}
            />
        </>
    )
}

export const DialogStampaPdf = (props: {
    idOrdine: number | string | undefined,
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    farmacieSelezionate: number[] | undefined,
    isDialogPdfOpen: boolean,
    isStampaTotale: boolean,
    isStampaFarmacia: boolean,
    handleCloseDialogPdf: () => void
}) => {
    const [idFarmacie, setIdFarmacie] = useState<number[]>();
    const [newDatiFarm, setNewDatiFarm] = useState<ListaFarmacieInterface[]>();
    const [checkValueCostoTotale, setCheckValueCostoTotale] = useState(false);
    const [checkValueCostoFarmacia, setCheckValueCostoFarmacia] = useState(false);

    useEffect(() => {
        if (props.datiFarmacie && props.farmacieSelezionate) {
            const filtroFarm: ListaFarmacieInterface[] = [];
            props.datiFarmacie.forEach(datiFarm => {
                props.farmacieSelezionate?.forEach(farmSelez => {
                    if (farmSelez === datiFarm.ID_FAR) {
                        filtroFarm.push(datiFarm);
                    }
                })
            })
            setNewDatiFarm(filtroFarm);
            setIdFarmacie(props.farmacieSelezionate);
        }
    }, [props.datiFarmacie, props.farmacieSelezionate])

    useEffect(() => {
        if (props.isDialogPdfOpen === true) {
            if (props.isStampaTotale) {
                RestServices().rest('POST', "", urlOrdiniLiberi.getCheckCostoTotale).then(result => {
                    if (result === 0) {
                        setCheckValueCostoTotale(false)
                    } else if (result === 1) {
                        setCheckValueCostoTotale(true);
                    }
                    impostaFiltri({ checkReportCostoTotale: checkValueCostoTotale });
                    impostaFiltri({ selezioneFarm: idFarmacie });
                })
            }
            if (props.isStampaFarmacia) {
                RestServices().rest('POST', "", urlOrdiniLiberi.getCheckCostoFarmacia).then(result => {
                    if (result === 0) {
                        setCheckValueCostoFarmacia(false)
                    } else if (result === 1) {
                        setCheckValueCostoFarmacia(true);
                    }
                    impostaFiltri({ checkReportCostoFarmacia: checkValueCostoFarmacia });
                    impostaFiltri({ selezioneFarm: idFarmacie });
                })
            }

        }
    }, [props.isDialogPdfOpen])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let arrayFarm = newFiltro.selezioneFarm;
        setIdFarmacie(arrayFarm);
    }

    const impostaFiltriCheckValueTot = (newFiltro: OggettoInterface) => {
        const check = newFiltro.checkReportCostoTotale;
        setCheckValueCostoTotale(check);
    }

    const impostaFiltriCheckValueFar = (newFiltro: OggettoInterface) => {
        const check = newFiltro.checkReportCostoFarmacia;
        setCheckValueCostoFarmacia(check);
    }

    const stampaPdfOrdineLibero = () => {
        let param: number = 0;
        if (checkValueCostoFarmacia === true) {
            param = 1;
        }
        else if (checkValueCostoFarmacia === false) {
            param = 0;
        }
        RestServices().rest('POST', param, urlOrdiniLiberi.setCheckCostoFarmacia).then(result => {
            if (result) {
                if (idFarmacie && props.idOrdine) {
                    for (let i = 0; i < idFarmacie.length; i++) {
                        const url = urlOrdiniLiberi.stampaPdfSingoli + 'id=' + props.idOrdine + '&idFar=' + idFarmacie[i];
                        window.open(url);
                        if (i === (idFarmacie.length - 1)) {
                            props.handleCloseDialogPdf();
                        }
                    }
                }
            }
        })

    }

    const stampaPdfOrdineLiberoTotale = () => {

        let param: number = 0;
        if (checkValueCostoTotale === true) {
            param = 1;
        }
        else if (checkValueCostoTotale === false) {
            param = 0;
        }

        RestServices().rest('POST', param, urlOrdiniLiberi.setCheckCostoTotale).then(result => {
            if (result) {
                if (props.idOrdine) {
                    const url = urlOrdiniLiberi.stampaTuttiPdf + props.idOrdine
                    window.open(url);
                    props.handleCloseDialogPdf();
                }
            }
        })
    }

    return (
        <>
            <Modal
                show={props.isDialogPdfOpen}
                onHide={() => window.close()}
                dialogClassName="dialogPdfOrdineLibero"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloPdfOrdineLibero">
                        <h3>Stampa PDF Ordine Libero</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(props.isStampaFarmacia) ?
                        (
                            <>
                                <Row>
                                    <Col sm="8" className="labelCheckReportFarmaciaCosto">
                                        <h6>Stampa del costo nel report per farmacia: </h6>
                                    </Col>
                                    <Col sm="4">
                                        {newDatiFarm && <Check id="checkReportCostoFarmacia" impostaFiltri={impostaFiltriCheckValueFar} checkValue={checkValueCostoFarmacia} />}
                                    </Col>
                                </Row>
                                <Row>&nbsp;</Row>
                                <Row>
                                    <Col sm="12">
                                        <h6>Selezionare le farmacie per le quali si desidera stampare il PDF</h6>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="12">
                                        {newDatiFarm && <TendinaFarm id='selezioneFarm' datiFarmacie={newDatiFarm} impostaFiltri={impostaFiltri} valueGrid={props.farmacieSelezionate} />}
                                    </Col>
                                </Row>
                            </>
                        )
                        :
                        (
                            <>
                                <Row>
                                    <Col sm="8" className="labelCheckReportTotaleCosto">
                                        <h6>Stampa del costo nel report totale: </h6>
                                    </Col>
                                    <Col sm="4">
                                        {newDatiFarm && <Check id="checkReportCostoTotale" impostaFiltri={impostaFiltriCheckValueTot} checkValue={checkValueCostoTotale} />}
                                    </Col>
                                </Row>
                                <Row>

                                </Row>
                                <Row>

                                </Row>
                            </>
                        )

                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        (props.isStampaFarmacia) ?
                            (
                                <>
                                    <Button id="btnStampaPdfOrdLibero" variant="success" onClick={stampaPdfOrdineLibero} >
                                        Stampa PDF
                                    </Button>
                                </>
                            )
                            :
                            (
                                <Button id="btnStampaPdfOrdLibero" variant="success" onClick={stampaPdfOrdineLiberoTotale} >
                                    Stampa PDF
                                </Button>
                            )
                    }

                    <Button id="btnChiudiDialogPdfOrdLibero" variant="danger" onClick={() => props.handleCloseDialogPdf()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const DialogTrasmettiOrdLib = (props: {
    idOrdine: number | string | undefined,
    isDialogTrasmettiOpen: boolean,
    flagModificaPrezzoVendita: boolean|undefined,
    handleCloseDialogTrasmetti: () => void,
    setRicarica: (e: boolean) => void,
    flagOrdineB2B: boolean | undefined
}) => {
    const [checkValue, setCheckValue] = useState(false);
    const [checkValuePrezzoVendita, setCheckValuePrezzoVendita] = useState(false);

    useEffect(() => {
        if (props.isDialogTrasmettiOpen === true) {
            RestServices().rest('POST', "", urlOrdiniLiberi.getCheckTrasmetti).then(result => {
                if (result === 0) {
                    setCheckValue(false)
                } else if (result === 1) {
                    setCheckValue(true);
                }
            })
        }

        if (props.isDialogTrasmettiOpen === true && props.flagModificaPrezzoVendita == true && props.idOrdine) {
            cerca()
        }
        setCheckValuePrezzoVendita(false)
    }, [props.isDialogTrasmettiOpen, props.idOrdine])

    const cerca = () => {
        RestServices().rest('POST', props.idOrdine, urlOrdiniLiberi.getListPrezzoVendita).then(result => {
            if (result) {
                setPrezzoVendita(result)
            }
        })
    }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        const check = newFiltro.checkTrasmetti;
        const checkPrezzoVendita = newFiltro.checkTrasmettiPrezzoVendita;
        setCheckValue(check);
        setCheckValuePrezzoVendita(checkPrezzoVendita)
    }
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [prezzoVendita, setPrezzoVendita] = useState<any[]>([])

    const columnsFattureScadenza = [
        { caption: "Id", dataField: "id", visible:false },
        { caption: "Cod. Prod.", dataField: "codProd"},
        { caption: "Prezzo Vendita", dataField: "prezzoVendita", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Inviato", dataField: "inviato", dataType: "boolean" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        //console.log (e)
                        if (e.row.data) {
                            let resultD = confirm("<i>Eliminare il prezzo impostato?</i>", "Attenzione");
                            resultD.then((dialogResult) => {
                                if (dialogResult) {
                                    //elimina
                                    RestServices().rest('POST', e.row.data.id, urlOrdiniLiberi.deletePrezzoVendita)
                                        .then(result => {
                                            if (result) {
                                                notify({ position: "center", width: "auto", message: "Prezzo eliminato con successo" }, "success", 4000)
                                                    cerca()
                                                    //setLoading(false)
                                                    //getListPagamenti()
                                                    //setIsDeleted(true)
                                                    props.setRicarica(true)

                                            } else {
                                                notify({ position: "center", width: "auto", message: "Errore imprevisto durante la cancellazione" }, "error", 4000)
                                               
                                            }
                                        })


                                } else {
                                    return;
                                }
                            });
                        }


                    },
                }
            ]
        }
    ]

    const trasmettiOrdLib = () => {
        //let param: number = 0;
        //if (checkValue === true) {
        //    param = 1;
        //}
        setIsVisible(true);
        const param = {
            trasmetti: checkValue,
            idOrdine: props.idOrdine,
            trasmettiPrezzoFarmacia: checkValuePrezzoVendita,
            flagOrdineB2B: props.flagOrdineB2B
        }
        RestServices().rest('POST', param, urlOrdiniLiberi.setCheckTrasmetti).then(result => {
            setIsVisible(false);
            if (result) {
                if (result.esito) {
                    //servizio trasmetti ordine
                    setCheckValue(false);
                    props.handleCloseDialogTrasmetti();
                    notify({ position: "center", width: "auto", message: "L'ordine è stato trasmesso" }, "success", 4000)
                } else {
                    if (result.message)
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    else
                        notify({ position: "center", width: "auto", message: "Si è verificato un errore, riprovare" }, "error", 4000)
                }
            }
            else
                notify({ position: "center", width: "auto", message: "Si è verificato un errore, riprovare" }, "error", 4000)

        })
    }
    return (
        <>
            <Modal
                show={props.isDialogTrasmettiOpen}
                onHide={props.handleCloseDialogTrasmetti}
                dialogClassName="dialogTrasmettiOrdLib"
                centered={true}
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdLib">
                        <h3>Trasmissione Ordine {props.flagOrdineB2B ? "B2B" : "Libero"}  </h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>
                        <Col sm="8" className="labelCheckTrasmettiOrdLib">
                            <h6>Trasmissione del costo in farmacia: </h6>
                        </Col>
                        <Col sm="4">
                            <Check id="checkTrasmetti" impostaFiltri={impostaFiltri} checkValue={checkValue} />
                        </Col>
                    </Row>
                    &nbsp;
                    {(props.flagModificaPrezzoVendita != undefined && props.flagModificaPrezzoVendita == true) &&
                    <>
                        < Row >
                        <Col sm="8" className="labelCheckTrasmettiOrdLib">
                            <h6>Trasmissione del prezzo di vendita in farmacia: </h6>
                        </Col>
                        <Col sm="4">
                            <Check id="checkTrasmettiPrezzoVendita" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                        {checkValuePrezzoVendita == true && <Row>
                            <Col sm="12">
                                <NewTabella
                                    height={300}
                                    idTabella='prezzo_vendita'
                                    sync
                                    colonne={columnsFattureScadenza}
                                    dataSource={prezzoVendita}
                                    allowColumnReordering={false}
                                    headerFilter
                                    filterRow
                                    hidePager={false}
                                    activeSaver
                                    exportT={{
                                        enabled: false,
                                        fileName: ""
                                    }}

                                />
                            </Col>
                        </Row>
                        }
                    </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnStampaPdfOrdLibero" variant="success" onClick={trasmettiOrdLib} >
                        Trasmetti
                    </Button>
                    <Button id="btnChiudiDialogPdfOrdLibero" variant="danger" onClick={() => props.handleCloseDialogTrasmetti()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogImpostazioniOrdLib = (props:
    {
        datiFarmacie: ListaFarmacieInterface[],
        isDialogImpostazioniOrdLib: boolean,
        setIsDialogImpostazioniOrdLib: (isDialogImpostazioniOrdLib: boolean) => void
        handleCloseDialogImpostazioniOrdLib: () => void,
        priorita?: PrioritaInterface[]

    }) => {

    const newImpostazioni = {
        idFar: undefined,
        flagPrezzoBancaDati: undefined,
        flagPrezzoFarmacia: undefined,
        prioritaPrezzoBancaDati: undefined,
        prioritaPrezzoFarmacia: undefined,
        flagCostoInControlloFattura: undefined,
        lstOrdineFarmacia: undefined,
        lstPuntiVendita: undefined,
        flagVisualizzaVendutoGiacenza: undefined,
        flagVisualizzaVendutoGiacenzaTotale: undefined,
        lstPuntiVenditaTrasferitoVenduto: undefined,
        lstPuntiVenditaFarmacie: undefined,
        flagVisualizzaUltimiAcquisti: undefined,
        flagVisualizzaListiniOfferte: undefined,
        flagVisualizzaAltreInfo: undefined,
        flagVisualizzaTrasferimenti: undefined,
        flagVisualizzaIdentici: undefined,
        flagVisualizzaSimili: undefined,
        flagVisualizzaListiniOfferteIdentici: undefined,
        flagVisualizzaPrezzoFarmacia: undefined,
        flagModificaPrezzoFarmacia: undefined,
        flagVisualizzaListiniOrdineLibero: undefined,
    }

    const [idFarmacia, setIdFarmacia] = useState<number>();
    const [checkValuePrezzoBancaDati, setCheckValuePrezzoBancaDati] = useState(false);
    const [checkValuePrezzoFarmacia, setCheckValuePrezzoFarmacia] = useState(false);
    const [checkFlagCostoInControlloFattura, setCheckFlagCostoInControlloFattura] = useState(false);
    const [checkFlagVisualizzaVendutoGiacenza, setCheckFlagVisualizzaVendutoGiacenza] = useState(false);
    const [filtri, setFiltri] = useState<ImpostazioniOrdineLiberoInterface>(newImpostazioni);
    const listItemRef = useRef(null);
    const [listaFarmacieOrdineNew, setListaFarmacieOrdineNew] = useState<ImpostazioniOrdineLiberoOrdineFarmacia[]>();
    const [listaPuntiVenditaNew, setListaPuntiVenditaNew] = useState<ImpostazioniOrdineLiberoPuntiVendita[]>();
    const [listaPuntiVenditaNewTrasferitoVenduto, setListaPuntiVenditaNewTrasferitoVenduto] = useState<ImpostazioniOrdineLiberoPuntiVenditaTrasferitoVenduto[]>();
    const [listaPuntiVenditaNewFarmacie, setListaPuntiVenditaNewFarmacie] = useState<ImpostazioniOrdineLiberoPuntiVenditaFarmacie[]>();
    const [listaFarmacieOrdineCurrent, setListaFarmacieOrdineCurrent] = useState<ImpostazioniOrdineLiberoOrdineFarmacia[]>();
    const [selectedItemKeys, setSelectedItemKeys] = useState<number[]>();
    const [selectedItemKeysTrasferitoVenduto, setSelectedItemKeysTrasferitoVenduto] = useState<number[]>();
    const [dataSource, setDatasource] = useState<any>();
    const [dataSourceTrasferitoVenduto, setDatasourceTrasferitoVenduto] = useState<any>();
    const [dataSourceFarmacie, setDatasourceFarmacie] = useState<any>();

    useEffect(() => {
        if (props.isDialogImpostazioniOrdLib === true) {
            RestServices().rest('POST', "", urlOrdiniLiberi.getImpostazioni).then(result => {
                if (result === undefined) {

                } else if (result) {
                    setCheckValuePrezzoBancaDati(result.flagPrezzoBancaDati);
                    setCheckValuePrezzoFarmacia(result.flagPrezzoFarmacia);
                    setCheckFlagCostoInControlloFattura(result.flagCostoInControlloFattura);
                    setCheckFlagVisualizzaVendutoGiacenza(result.flagVisualizzaVendutoGiacenza)
                    setIdFarmacia(result.idFarmaciaPrezzo)

                    setFiltri({
                        flagPrezzoBancaDati: result.flagPrezzoBancaDati,
                        flagPrezzoFarmacia: result.flagPrezzoFarmacia, idFar: result.idFarmaciaPrezzo,
                        prioritaPrezzoBancaDati: result.prioritaPrezzoBancaDati,
                        prioritaPrezzoFarmacia: result.prioritaPrezzoFarmacia,
                        flagCostoInControlloFattura: result.flagCostoInControlloFattura,
                        lstOrdineFarmacia: result.lstOrdineFarmacia,
                        flagVisualizzaVendutoGiacenza: result.flagVisualizzaVendutoGiacenza,
                        lstPuntiVendita: result.lstPuntiVendita,
                        lstPuntiVenditaTrasferitoVenduto: result.lstPuntiVenditaTrasferitoVenduto,
                        lstPuntiVenditaFarmacie: result.lstPuntiVenditaFarmacie,
                        flagVisualizzaVendutoGiacenzaTotale: result.flagVisualizzaVendutoGiacenzaTotale,
                        flagVisualizzaUltimiAcquisti: result.flagVisualizzaUltimiAcquisti,
                        flagVisualizzaListiniOfferte: result.flagVisualizzaListiniOfferte,
                        flagVisualizzaAltreInfo: result.flagVisualizzaAltreInfo,
                        flagVisualizzaTrasferimenti: result.flagVisualizzaTrasferimenti,
                        flagVisualizzaIdentici: result.flagVisualizzaIdentici,
                        flagVisualizzaSimili: result.flagVisualizzaSimili,
                        flagVisualizzaListiniOfferteIdentici: result.flagVisualizzaListiniOfferteIdentici,
                        flagVisualizzaPrezzoFarmacia: result.flagVisualizzaPrezzoFarmacia,
                        flagModificaPrezzoFarmacia: result.flagModificaPrezzoFarmacia,
                        flagVisualizzaListiniOrdineLibero: result.flagVisualizzaListiniOrdineLibero,
                    });
                    setListaFarmacieOrdineNew(result.lstOrdineFarmacia);
                    setListaPuntiVenditaNew(result.lstPuntiVendita);
                    setListaPuntiVenditaNewTrasferitoVenduto(result.lstPuntiVenditaTrasferitoVenduto);
                    setListaPuntiVenditaNewFarmacie(result.lstPuntiVenditaFarmacie);

                    let selected: number[] = Array();
                    result.lstPuntiVendita?.forEach((punto: any) => {
                        if (punto.flagMagazzino == true) {
                            selected.push(punto.idFar);
                        }
                    })

                    setSelectedItemKeys(selected);

                    let selectedTrasferitoVenduto: number[] = Array();
                    result.lstPuntiVenditaTrasferitoVenduto?.forEach((punto: any) => {
                        if (punto.flagTrasferitoVenduto == true) {
                            selectedTrasferitoVenduto.push(punto.idFar);
                        }
                    })

                    setSelectedItemKeysTrasferitoVenduto(selectedTrasferitoVenduto);

                }

            })
        }
    }, [props.isDialogImpostazioniOrdLib])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const salvaImpostazioniOrdineLibero = () => {

        const param = {
            idFarmaciaPrezzo: filtri.idFar,
            flagPrezzoFarmacia: filtri.flagPrezzoFarmacia,
            flagPrezzoBancaDati: filtri.flagPrezzoBancaDati,
            prioritaPrezzoBancaDati: filtri.prioritaPrezzoBancaDati,
            prioritaPrezzoFarmacia: filtri.prioritaPrezzoFarmacia,
            flagCostoInControlloFattura: filtri.flagCostoInControlloFattura,
            flagVisualizzaVendutoGiacenza: filtri.flagVisualizzaVendutoGiacenza,
            lstOrdineFarmacia: listaFarmacieOrdineNew,
            lstPuntiVendita: listaPuntiVenditaNew,
            lstPuntiVenditaTrasferitoVenduto: listaPuntiVenditaNewTrasferitoVenduto
        };
        if (filtri.flagPrezzoFarmacia && filtri.idFar == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, "error", 4000);
        } else if (filtri.flagPrezzoFarmacia && filtri.prioritaPrezzoFarmacia == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare la priorita del prezzo farmacia" }, "error", 4000);
        } else if (filtri.flagPrezzoBancaDati && filtri.prioritaPrezzoBancaDati == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare la priorita' del prezzo banca dati" }, "error", 4000);
        } else if (filtri.prioritaPrezzoBancaDati != undefined && filtri.prioritaPrezzoBancaDati == filtri.prioritaPrezzoFarmacia) {
            notify({ position: "center", width: "auto", message: "Selezionare priorita' differenti" }, "error", 4000);
        }
        else {
            RestServices().rest('POST', param, urlOrdiniLiberi.setImpostazioni).then(result => {
                if (result) {
                    notify({ position: "center", width: "auto", message: "Salvataggio eseguito con successo" }, "success", 2000)
                    props.handleCloseDialogImpostazioniOrdLib();
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'è stato un errore!" }, "error", 4000)
                }
            })
        }
    }

    const handleClose = () => {
        props.setIsDialogImpostazioniOrdLib(false);
    }

    const tabs = [
        {
            id: 0,
            text: 'Priorità prezzo',
            icon: 'money',
            content: ''
        },
        {
            id: 1,
            text: 'Controllo fattura',
            icon: 'check',
            content: ''
        },
        {
            id: 2,
            text: '',
            icon: '',
            content: ''
        }
    ];

    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    const ListItem = (data: any) => {
        return (
            <div>
                <b>{data.descFar}</b>
                <br />
            </div>
        );
    };

    const onItemReordered = (e: any) => {

        //console.log(e);

        let fromIndex = e.fromIndex;
        let toIndex = e.toIndex;
        let idFar = e.itemData.idFar;
        let fromPosition = e.itemData.posizione;
        var item: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;
        var item2: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;

        var itemToUpdate: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;
        var itemFromUpdate: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;

        var toPosition;
        var toIndice;


        var listAppo: ImpostazioniOrdineLiberoOrdineFarmacia[];
        var farAppo: ImpostazioniOrdineLiberoOrdineFarmacia;

        if (listaFarmacieOrdineNew != undefined) {

            if (fromIndex > toIndex) {
                listAppo = [];
                listaFarmacieOrdineNew.forEach((farmacia) => {
                    if (farmacia) {
                        //console.log(farmacia)
                        if (farmacia.indice < toIndex) {
                            listAppo.push(farmacia);
                        }
                        else if (farmacia.indice >= toIndex && farmacia.indice < fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = farmacia.posizione + 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = farmacia.indice + 1;

                            listAppo.push(farAppo);
                        }

                        else if (farmacia.indice == fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = (toIndex + 1) * 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = toIndex;

                            listAppo.push(farAppo);
                        }
                        else
                            listAppo.push(farmacia);
                    }
                })

                //console.log(listaFarmacieOrdineNew)
                //console.log(listAppo)


                setListaFarmacieOrdineNew(listAppo);
            }


            if (fromIndex < toIndex) {
                listAppo = [];
                listaFarmacieOrdineNew.forEach((farmacia) => {
                    if (farmacia) {
                        //console.log(farmacia)
                        if (farmacia.indice < fromIndex) {
                            listAppo.push(farmacia);
                        }
                        else if (farmacia.indice == fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = (toIndex + 1) * 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = toIndex;

                            listAppo.push(farAppo);
                        }
                        else if (farmacia.indice > fromIndex && farmacia.indice <= toIndex && farmacia.indice != fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = farmacia.posizione - 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = farmacia.indice - 1;

                            listAppo.push(farAppo);
                        }

                        else
                            listAppo.push(farmacia);
                    }
                })

                //console.log(listaFarmacieOrdineNew)
                //console.log(listAppo)


                setListaFarmacieOrdineNew(listAppo);
            }


        }


    }

    const onSelectedItemKeysChange = (args: any) => {

        if (args.name === 'selectedItemKeys') {
            setSelectedItemKeys(args.value);
            listaPuntiVenditaNew?.forEach((punto) => {
                if (args.value.includes(punto.idFar)) {
                    punto.flagMagazzino = true;
                }
                else
                    punto.flagMagazzino = false;
            })
            //this.setState({
            //    selectedItemKeys: args.value,
            //});

        }
    }

    const onSelectedItemKeysChangeTraasferitoVenduto = (args: any) => {
        if (args.name === 'selectedItemKeys') {
            setSelectedItemKeysTrasferitoVenduto(args.value);
            listaPuntiVenditaNewTrasferitoVenduto?.forEach((punto) => {
                if (args.value.includes(punto.idFar)) {
                    punto.flagTrasferitoVenduto = true;
                }
                else
                    punto.flagTrasferitoVenduto = false;
            })
            //this.setState({
            //    selectedItemKeys: args.value,
            //});

        }
    }



    useEffect(() => {
        //const tasks = [{ idFar: 1, text: 'Prepare 2016 Financial'},
        //    { idFar: 2, text: 'Prepare 2016 Marketing Plan'},
        //    { idFar: 3, text: 'Update Personnel Files'},
        //    { idFar: 4, text: 'Review Health Insurance Options Under the Affordable Care Act'}];

        setDatasource(new ArrayStore({
            key: 'idFar',
            data: filtri.lstPuntiVendita,
        }))
    }, [filtri.lstPuntiVendita])

    useEffect(() => {
        //const tasks = [{ idFar: 1, text: 'Prepare 2016 Financial'},
        //    { idFar: 2, text: 'Prepare 2016 Marketing Plan'},
        //    { idFar: 3, text: 'Update Personnel Files'},
        //    { idFar: 4, text: 'Review Health Insurance Options Under the Affordable Care Act'}];

        setDatasourceTrasferitoVenduto(new ArrayStore({
            key: 'idFar',
            data: filtri.lstPuntiVenditaTrasferitoVenduto,
        }))
    }, [filtri.lstPuntiVenditaTrasferitoVenduto])

    useEffect(() => {
        //const tasks = [{ idFar: 1, text: 'Prepare 2016 Financial'},
        //    { idFar: 2, text: 'Prepare 2016 Marketing Plan'},
        //    { idFar: 3, text: 'Update Personnel Files'},
        //    { idFar: 4, text: 'Review Health Insurance Options Under the Affordable Care Act'}];

        setDatasourceFarmacie(new ArrayStore({
            key: 'idFar',
            data: filtri.lstPuntiVenditaFarmacie,
        }))
    }, [filtri.lstPuntiVenditaFarmacie])


    return (
        <>
            <Modal
                show={props.isDialogImpostazioniOrdLib}
                onHide={handleClose}
                dialogClassName="dialogImpostazioniOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Impostazioni ordine libero</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <TabPanel
                        selectedIndex={selectedIndex}
                        loop={false}
                        animationEnabled={true}
                        onOptionChanged={onOptionChanged}


                    >
                        <Item title="Priorità prezzo" icon="money">
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Utilizza il prezzo di Banca Dati: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagPrezzoBancaDati" impostaFiltri={impostaFiltri} checkValue={checkValuePrezzoBancaDati} />
                                </Col>
                                <Col sm="1" className="labelCreaNuovoOrdLib">
                                    <span>Priorità: </span>
                                </Col>
                                <Col sm="1">
                                    <Selezione
                                        dataSource={props.priorita}
                                        id="prioritaPrezzoBancaDati"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="PRIORITA"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                        value={filtri.prioritaPrezzoBancaDati}
                                        disabled={!filtri.flagPrezzoBancaDati}
                                    />
                                </Col>
                                <Col sm="6">
                                </Col>
                            </Row>

                            <Row>&nbsp;</Row>

                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Utilizza il prezzo di Farmacia: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagPrezzoFarmacia" impostaFiltri={impostaFiltri} checkValue={checkValuePrezzoFarmacia} />
                                </Col>
                                <Col sm="1" className="labelCreaNuovoOrdLib">
                                    <span>Priorità: </span>
                                </Col>
                                <Col sm="1">
                                    <Selezione
                                        dataSource={props.priorita}
                                        id="prioritaPrezzoFarmacia"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="PRIORITA"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                        value={filtri.prioritaPrezzoFarmacia}
                                        disabled={!filtri.flagPrezzoFarmacia}
                                    />
                                </Col>
                                <Col sm="2" className="labelCreaNuovoOrdLib">
                                    <span>Farmacia: </span>
                                </Col>
                                <Col sm="4">
                                    <SelectFarm datiFarmacie={props.datiFarmacie} id="idFar" impostaFiltri={impostaFiltri} displayExpr={true} value={filtri.idFar} disabled={!filtri.flagPrezzoFarmacia} />
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Controllo fattura" icon="bulletlist">
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza ed utilizza il costo in controllo fattura: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagCostoInControlloFattura" impostaFiltri={impostaFiltri} checkValue={checkFlagCostoInControlloFattura} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="4"><span className="trasfInfo">In controllo bolla fattura, nella tabella dei prodotti presenti nelle DDT caricate in farmacia,è visualizzato ed utilizzato il costo del relativo ordine libero se esistente </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Ordine farmacie" icon="orderedlist">
                            <Row>&nbsp;</Row>
                            <Row><Col sm="12"><span className="trasfInfo">Definire in che ordine le farmacie sono visualizzate nella pagina di gestione del singolo ordine libero. &nbsp;Spostare le farmacie secondo l'ordine desiderato. </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <List dataSource={filtri.lstOrdineFarmacia} itemRender={ListItem} onItemReordered={onItemReordered} ref={listItemRef}>
                                <ItemDragging
                                    allowReordering={true}
                                />
                            </List>

                            {/*
                            <ListElementi
                                id="listFarmacie"
                                dataSource={filtri.lstOrdineFarmacia}
                                impostaFiltri={impostaFiltri} >

                            </ListElementi>*/}
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Venduto e giacenze" icon="orderedlist">
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza venduto e giacenza di tutte le farmacie del gruppo: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagVisualizzaVendutoGiacenza" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaVendutoGiacenza} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="4"><span className="trasfInfo">Nella pagina del singolo ordine libero viene mostrato il venduto e la giacenza del prodotto selezionato per tutte le farmacie del gruppo, altrimenti solo per le farmacie dell'ordine </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Punti vendita" icon="orderedlist">
                            <Row>&nbsp;</Row>
                            <Row><Col sm="12"><span className="trasfInfo">Indicare le farmacie "Magazzino" per escluderle dal calcolo del venduto totale nel pagina di modifica dell'ordine libero; indicare le farmacie "Trasferito e venduto" per visualizzare sia il venduto e il trasferito nella pagina di modifica dell'ordine libero</span></Col></Row>                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="3"><span className="trasfInfo">Farmacie</span>
                                    <List
                                        dataSource={dataSourceFarmacie}
                                    >
                                    </List>

                                </Col>
                                <Col sm="1"><span title="Selezionare i punti vendita che sono anche magazzino" className="trasfInfo">Magazzino</span>
                                    <List
                                        id="listItemMagazzino"
                                        dataSource={dataSource}
                                        selectionMode={'multiple'}
                                        showSelectionControls={true}
                                        selectedItemKeys={selectedItemKeys}
                                        onOptionChanged={onSelectedItemKeysChange}>
                                    </List>

                                </Col>
                                <Col sm="2"><span title="Selezionare i punti vendita di cui visualizzare trasferito e venduto" className="trasfInfo">Trasferito e venduto</span>
                                    <List
                                        id="listItemTrasferitoVenduto"
                                        dataSource={dataSourceTrasferitoVenduto}
                                        selectionMode={'multiple'}
                                        showSelectionControls={true}
                                        selectedItemKeys={selectedItemKeysTrasferitoVenduto}
                                        onOptionChanged={onSelectedItemKeysChangeTraasferitoVenduto}
                                       
                                    >
                                    </List>

                                </Col>
                            </Row>


                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>

                    </TabPanel>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSalvaImpostazioniOrdLibero" variant="success" onClick={salvaImpostazioniOrdineLibero} >
                        Salva
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => props.handleCloseDialogImpostazioniOrdLib()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )

}


export const DialogFiltraProdottiOrdineLiberoPrev = (props: {
    isDialogFiltraProdottiOpen: boolean,
    handleCloseDialogFiltraProdotti: () => void,
    setIsVisible: (isVisible: boolean) => void,
    impostaFiltri: (newFiltro: object) => void,
    filtri: NewTestataInterface,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [ditte, setDitte] = useState<DittaInterface[]>();
    const [settori, setSettori] = useState<SettoriInterface[]>();
    const [gruppi, setGruppi] = useState<GruppoMerceologicoInterface[]>();
    const [linee, setLinee] = useState<DittaInterface[]>();
    const [category, setCategory] = useState<CategoryInterface[]>()
    const [classeA, setClasseA] = useState<ClasseAInterface[]>()
    const [classeC, setClasseC] = useState<ClasseAInterface[]>()
    const [sopOtc, setSopOtc] = useState<ClasseAInterface[]>()
    const [treeBoxValue, setTreeBoxValue] = useState<string[]>(['1_1']);
    const [generici, setGenerici] = useState<ClasseAInterface[]>()


    useEffect(() => {

        if (props.isDialogFiltraProdottiOpen) {

            if (!settori || settori == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                    if (result) setSettori(result);
                })
            if (!ditte || ditte == undefined) {
                setIsVisible(true);
                RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
                    if (result) {
                        setIsVisible(false);
                        setDitte(result);
                    }
                })
            }

            if (!classeA || classeA == undefined)
                RestServices().rest('POST', "", urlBancaDati.getClasseA).then(result => {
                    if (result) setClasseA(result);
                })
            if (!classeC || classeC == undefined)
                RestServices().rest('POST', "", urlBancaDati.getClasseC).then(result => {
                    if (result) setClasseC(result);
                })
            if (!sopOtc || sopOtc == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSopOtc).then(result => {
                    if (result) setSopOtc(result);
                })
            if (!generici || generici == undefined)
                RestServices().rest('POST', "", urlBancaDati.getGenerici).then(result => {
                    if (result) setGenerici(result);
                })
            if (!gruppi || gruppi == undefined)
                RestServices().rest('POST', "", urlBancaDati.getGruppoMerceologico).then(result => {
                    if (result) setGruppi(result);
                })
            if (!category || category == undefined)
                RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
                    if (result) setCategory(result);
                })
        }

    }, [props.isDialogFiltraProdottiOpen])

    useEffect(() => {
        if (props.filtri.idDitta != undefined)
            RestServices().rest('POST', props.filtri.idDitta, urlBancaDati.getLinee).then(result => {
                if (result) setLinee(result);
            })


    }, [props.filtri.idDitta])

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const colonneGridIncludiEscludi = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120, visible: false }
    ])

    const salvaFiltriProdottoOrdineLibero = () => {
        //console.log(props.filtri);
        props.handleCloseDialogFiltraProdotti();

    }



    const treeViewRender = () => {
        return (

            <SelezioneGerarchica
                id="idGruppo"
                width={540}
                height={300}
                dataSource={gruppi != undefined ? gruppi : []}
                selectNodesRecursive={true}
                selectByClick={false}
                showCheckBoxesMode='normal'
                selectionMode='multiple'
                impostaFiltri={props.impostaFiltri}
            />
        )
    }

    const syncTreeViewSelection = (e: any) => {
        let treeView = (e.component.selectItem && e.component);
        //console.log(treeView);
        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                let values = e.value;
                values && values.forEach(function (value: any) {
                    treeView.selectItem(value);
                });
            }
        }

        if (e.value !== undefined) {
            setTreeBoxValue(e.value);
        }
    }

    return (
        <>
            <Modal
                show={props.isDialogFiltraProdottiOpen}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Filtri prodotti nuovo Ordine Libero</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm="6">
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Ditta: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idDitta"
                                        dataSource={ditte}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="single"
                                        primaSelezionata={false}
                                        valueGrid={(props.filtri.idDitta) ? props.filtri.idDitta : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Linea: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idLinea"
                                        dataSource={linee}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="multiple"
                                        primaSelezionata={false}
                                        valueGrid={(props.filtri.idLinea) ? props.filtri.idLinea : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                        </Col>
                        <Col sm="6">
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Settore di inventario: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idSettore"
                                        dataSource={settori}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="multiple"
                                        valueGrid={(props.filtri.idSettore) ? props.filtri.idSettore : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Gruppo merceologico: </span>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="12">
                                    {/*  <TreeView
                                        id="tvGruppoMerceologico"
                                        width={540}
                                        height={300}
                                        items={gruppi != undefined ? gruppi : []}
                                        selectNodesRecursive={true}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        itemRender={renderTreeViewItem}
                                    />*/}

                                    {/*    <SelezioneGerarchica
                                        id="idGruppo"
                                        width={540}
                                        height={300}
                                        dataSource={gruppi != undefined ? gruppi : []}
                                        selectNodesRecursive={true}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        impostaFiltri={props.impostaFiltri}
                                    /> */}
                                    <SelezioneMultiplaGerarchica
                                        id="idGruppo"
                                        dataSource={gruppi != undefined ? gruppi : []}
                                        selectNodesRecursive={false}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        impostaFiltri={props.impostaFiltri}
                                        valueGrid={(props.filtri.idGruppo) ? props.filtri.idGruppo : []}
                                    />


                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Category: </span>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="12">
                                    {/*<TreeView
                                        id="tvCategory"
                                        width={540}
                                        height={300}
                                        items={category != undefined ? category : []}
                                        selectNodesRecursive={true}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        itemRender={renderTreeViewItem}
                                    />*/}
                                    {/*<SelezioneGerarchica*/}
                                    {/*    id="idCategory"*/}
                                    {/*    width={540}*/}
                                    {/*    height={300}*/}
                                    {/*    dataSource={category != undefined ? category : []}*/}
                                    {/*    selectNodesRecursive={true}*/}
                                    {/*    selectByClick={false}*/}
                                    {/*    showCheckBoxesMode='normal'*/}
                                    {/*    selectionMode='multiple'*/}
                                    {/*    impostaFiltri={props.impostaFiltri}*/}
                                    {/*/>*/}
                                    <SelezioneMultiplaGerarchica
                                        id="idCategory"
                                        dataSource={category != undefined ? category : []}
                                        selectNodesRecursive={false}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        impostaFiltri={props.impostaFiltri}
                                        valueGrid={(props.filtri.idCategory) ? props.filtri.idCategory : []}
                                    />
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Classe A: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idClasseA"
                                        dataSource={classeA}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridIncludiEscludi}
                                        selection="single"
                                        valueGrid={(props.filtri.idClasseA) ? props.filtri.idClasseA : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Classe C: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idClasseC"
                                        dataSource={classeC}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridIncludiEscludi}
                                        selection="single"
                                        valueGrid={(props.filtri.idClasseC) ? props.filtri.idClasseC : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>SOP-OTC: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idSopOtc"
                                        dataSource={sopOtc}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridIncludiEscludi}
                                        selection="single"
                                        valueGrid={(props.filtri.idSopOtc) ? props.filtri.idSopOtc : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Generici: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idGenerici"
                                        dataSource={generici}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridIncludiEscludi}
                                        selection="single"
                                        valueGrid={(props.filtri.idGenerici) ? props.filtri.idGenerici : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSalvaFiltriProdottoOrdLibero" variant="success" onClick={salvaFiltriProdottoOrdineLibero} >
                        Salva filtri
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => props.handleCloseDialogFiltraProdotti()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export const DialogFiltraProdottiOrdineLibero = (props: {
    isDialogFiltraProdottiOpen: boolean,
    handleCloseDialogFiltraProdotti: () => void,
    setIsVisible: (isVisible: boolean) => void,
    impostaFiltri: (newFiltro: object) => void,
    filtri: NewTestataInterface,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [ditte, setDitte] = useState<DittaInterface[]>();
    const [settori, setSettori] = useState<SettoriInterface[]>();
    const [gruppi, setGruppi] = useState<GruppoMerceologicoInterface[]>();
    const [linee, setLinee] = useState<DittaInterface[]>();
    const [category, setCategory] = useState<CategoryInterface[]>()
    const [classeA, setClasseA] = useState<ClasseAInterface[]>()
    const [classeC, setClasseC] = useState<ClasseAInterface[]>()
    const [sopOtc, setSopOtc] = useState<ClasseAInterface[]>()
    const [treeBoxValue, setTreeBoxValue] = useState<string[]>(['1_1']);
    const [generici, setGenerici] = useState<ClasseAInterface[]>()
    const [infoRipartizione, setInfoRipartizione] = useState<any>();
    const [selectedIndex, setSelectedIndex] = useState<any>()
    const [dataInizioCalcolo, setDataInizioCalcolo] = useState<any>()

    useEffect(() => {
        if (props.isDialogFiltraProdottiOpen) {
            //console.log(props.filtri)
            if (!settori || settori == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                    if (result) setSettori(result);
                })
            if (!ditte || ditte == undefined) {
                setIsVisible(true);
                RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
                    if (result) {
                        setIsVisible(false);
                        setDitte(result);
                    }
                })
                
            }

            if (!classeA || classeA == undefined)
                RestServices().rest('POST', "", urlBancaDati.getClasseA).then(result => {
                    if (result) setClasseA(result);
                })
            if (!classeC || classeC == undefined)
                RestServices().rest('POST', "", urlBancaDati.getClasseC).then(result => {
                    if (result) setClasseC(result);
                })
            if (!sopOtc || sopOtc == undefined)
                RestServices().rest('POST', "", urlBancaDati.getSopOtc).then(result => {
                    if (result) setSopOtc(result);
                })
            if (!generici || generici == undefined)
                RestServices().rest('POST', "", urlBancaDati.getGenerici).then(result => {
                    if (result) setGenerici(result);
                })
            if (!gruppi || gruppi == undefined)
                RestServices().rest('POST', "", urlBancaDati.getGruppoMerceologico).then(result => {
                    if (result) setGruppi(result);
                })
            if (!category || category == undefined)
                RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
                    if (result) setCategory(result);
                })
        }

    }, [props.isDialogFiltraProdottiOpen])

    useEffect(() => {
        if (props.filtri.idDitta != undefined)
            RestServices().rest('POST', props.filtri.idDitta, urlBancaDati.getLinee).then(result => {
                if (result) setLinee(result);
            })


    }, [props.filtri.idDitta])

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const colonneGridIncludiEscludi = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120, visible: false }
    ])

    const salvaFiltriProdottoOrdineLibero = () => {
       //console.log(props.filtri);
        if (props.filtri.flagGeneraProposta) {
            if (props.filtri.idTipologiaProposta == undefined || props.filtri.dataProposta == undefined
                || props.filtri.giorniCoperturaProposta == undefined || props.filtri.giorniLavoratiProposta == undefined) {
                notify({ position: "center", width: "auto", message: "Compilare tutti i campi per la proposta di ordine libero" }, 'error', 3000);
                return;
                }
                
        }
        props.handleCloseDialogFiltraProdotti();

    }



    const treeViewRender = () => {
        return (

            <SelezioneGerarchica
                id="idGruppo"
                width={540}
                height={300}
                dataSource={gruppi != undefined ? gruppi : []}
                selectNodesRecursive={true}
                selectByClick={false}
                showCheckBoxesMode='normal'
                selectionMode='multiple'
                impostaFiltri={props.impostaFiltri}
            />
        )
    }

    const syncTreeViewSelection = (e: any) => {
        let treeView = (e.component.selectItem && e.component);
        //console.log(treeView);
        if (treeView) {
            if (e.value === null) {
                treeView.unselectAll();
            } else {
                let values = e.value;
                values && values.forEach(function (value: any) {
                    treeView.selectItem(value);
                });
            }
        }

        if (e.value !== undefined) {
            setTreeBoxValue(e.value);
        }
    }

    const groupBoxValueChanged = (index: number) => {

        setSelectedIndex(index)
        setInfoRipartizione(parametriInfo[index])

    }

    useEffect(() => {
        if (selectedIndex != undefined) {
            
            //let newFiltro: OggettoInterface = {};
            //newFiltro["idTipologiaProposta"] = selectedIndex + 1
            //props.impostaFiltri(newFiltro)
            //console.log(newFiltro) 
            cambiaValore("idTipologiaProposta", selectedIndex)
        }
        


    }, [selectedIndex])


    const parametri = [{ id: 0, text: 'Storico' }, { id: 1, text: 'Proiezione' }];
    const parametriInfo = ['Calcola gli indici di vendita a partire dalla data di inizio calcolo ed andando a ritroso dei giorni indicati in giorni lavorati', 'Calcola gli indici di vendita a partire dalla data di inizio calcolo ed andando in avanti dei giorni indicati in giorni lavorati']

    const onValueChanged = (e: any) => {
        //0 storico 1 proiezione
        //console.log(e)
        //groupBoxValueChanged(e.value)

        let index = e.value +1

        let d = new Date()

        if (index == 1)
            d = new Date()
        else if (index == 2) {
            d.setFullYear(d.getFullYear() - 1);
            
        }
        
        setDataInizioCalcolo(d)
        cambiaValore("dataProposta", d)
        //cambiaValore("idTipologiaProposta", e.value + 1)
        setSelectedIndex(index)
        setInfoRipartizione(parametriInfo[index - 1])

    }


    const cambiaValore = (tipo: string , e: any) => {
        let newFiltro: OggettoInterface = {};
        newFiltro[tipo] = e
        props.impostaFiltri(newFiltro);
        if (tipo == "dataProposta")
            setDataInizioCalcolo(e);
    }

    return (
        <>
            <Modal
                show={props.isDialogFiltraProdottiOpen}
                onHide={() => window.close()}
                dialogClassName="dialogFiltraProdottiOrdineLibero"
                size="xl"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Filtri prodotti nuovo Ordine Libero</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>&nbsp;</Row>
                    <Row>
                        <Col sm="6">
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Ditta: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idDitta"
                                        dataSource={ditte}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="multiple"
                                        primaSelezionata={false}
                                        valueGrid={(props.filtri.idDitta) ? props.filtri.idDitta : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Linea: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idLinea"
                                        dataSource={linee}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="multiple"
                                        primaSelezionata={false}
                                        valueGrid={(props.filtri.idLinea) ? props.filtri.idLinea : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                        </Col>
                        <Col sm="6">
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Settore di inventario: </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm="10">
                                    <SelezioneMultipla
                                        id="idSettore"
                                        dataSource={settori}
                                        impostaFiltri={props.impostaFiltri}
                                        column={colonneGridGeneriche}
                                        selection="multiple"
                                        valueGrid={(props.filtri.idSettore) ? props.filtri.idSettore : []}
                                    ></SelezioneMultipla>
                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="6" className="labelCreaNuovoOrdLib">
                                    <span>Gruppo merceologico: </span>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="12">
                                    <SelezioneMultiplaGerarchica
                                        id="idGruppo"
                                        dataSource={gruppi != undefined ? gruppi : []}
                                        selectNodesRecursive={false}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        impostaFiltri={props.impostaFiltri}
                                        valueGrid={(props.filtri.idGruppo) ? props.filtri.idGruppo : []}
                                    />


                                </Col>
                            </Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Category: </span>
                                </Col>
                            </Row>
                            <Row >
                                <Col sm="12">
                                    <SelezioneMultiplaGerarchica
                                        id="idCategory"
                                        dataSource={category != undefined ? category : []}
                                        selectNodesRecursive={false}
                                        selectByClick={false}
                                        showCheckBoxesMode='normal'
                                        selectionMode='multiple'
                                        impostaFiltri={props.impostaFiltri}
                                        valueGrid={(props.filtri.idCategory) ? props.filtri.idCategory : []}
                                    />
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>
                        
                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span>Classe A: </span>
                            <SelezioneMultipla
                                id="idClasseA"
                                dataSource={classeA}
                                impostaFiltri={props.impostaFiltri}
                                column={colonneGridIncludiEscludi}
                                selection="single"
                                valueGrid={(props.filtri.idClasseA) ? props.filtri.idClasseA : []}
                            ></SelezioneMultipla>
                        </Col>

                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span>Classe C: </span>
                            <SelezioneMultipla
                                id="idClasseC"
                                dataSource={classeC}
                                impostaFiltri={props.impostaFiltri}
                                column={colonneGridIncludiEscludi}
                                selection="single"
                                valueGrid={(props.filtri.idClasseC) ? props.filtri.idClasseC : []}
                            ></SelezioneMultipla>
                        </Col>

                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span>SOP-OTC: </span>
                            <SelezioneMultipla
                                id="idSopOtc"
                                dataSource={sopOtc}
                                impostaFiltri={props.impostaFiltri}
                                column={colonneGridIncludiEscludi}
                                selection="single"
                                valueGrid={(props.filtri.idSopOtc) ? props.filtri.idSopOtc : []}
                            ></SelezioneMultipla>
                        </Col>

                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span>Generici: </span>
                            <SelezioneMultipla
                                id="idGenerici"
                                dataSource={generici}
                                impostaFiltri={props.impostaFiltri}
                                column={colonneGridIncludiEscludi}
                                selection="single"
                                valueGrid={(props.filtri.idGenerici) ? props.filtri.idGenerici : []}
                            ></SelezioneMultipla>
                        </Col>

                        
                    </Row>
                    <Row>&nbsp;</Row>
                    <Row>

                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span>Genera proposta di ordine: </span>
                        </Col>
                        <Col sm="4" style={{ marginTop: 10 }}>
                            <Check id="flagGeneraProposta" impostaFiltri={props.impostaFiltri} checkValue={props.filtri.flagGeneraProposta} />
                        </Col>
                    </Row>

                    <div style={{ display: props.filtri.flagGeneraProposta ? "block" : "none" }}>
                        <Row>
                            <Col sm="3" className="labelCreaNuovoOrdLib">
                                <span>Tipologia: </span>
                                <RadioGroup
                                    items={parametri}
                                    //value={viewMode ? parametri[Number(objctTOsave.idTipoRipartizione - 1)] : parametri[-1]}
                                    //defaultValue={viewMode && objctTOsave.idTipoRipartizione ? Number(objctTOsave.idTipoRipartizione - 1) : -1}
                                    layout="horizontal"
                                    onValueChanged={onValueChanged}
                                    valueExpr="id" displayExpr="text"
                                    defaultValue={props.filtri.idTipologiaProposta ? props.filtri.idTipologiaProposta - 1 : undefined}
                                />
                            </Col>


                        </Row>

                        <div className='form-row' >
                            <div className="form-group col-md-6">
                                <p style={{ fontStyle: 'italic' }}>{infoRipartizione}</p>
                            </div>
                        </div>

                        <Row>
                            <Col sm="3" className="labelCreaNuovoOrdLib">
                                <span>Data inizio calcolo:</span>
                                {/*<BoxDataOra id="dataProposta" impostaFiltri={props.impostaFiltri} typeDate="date" value={dataInizioCalcolo} />*/}
                                <DateBox value={dataInizioCalcolo}
                                    valueChangeEvent="keyup" showClearButton={true}
                                    onValueChanged={(v) => { cambiaValore('dataProposta', v.value) }}
                                    type="date"
                                    displayFormat="dd/MM/yyyy" />
                            </Col>

                            <Col sm="3" className="labelCreaNuovoOrdLib">
                                <span>Giorni lavorati:</span>
                                <BoxFiltro id="giorniLavoratiProposta"
                                    impostaFiltri={props.impostaFiltri}
                                    value={props.filtri.giorniLavoratiProposta?.toString()}
                                />

                            </Col>

                        </Row>

                        <Row>
                            <Col sm="3" className="labelCreaNuovoOrdLib">
                                <span>Giorni di copertura:</span>
                                <BoxFiltro id="giorniCoperturaProposta"
                                    impostaFiltri={props.impostaFiltri}
                                    value={props.filtri.giorniCoperturaProposta?.toString()}
                                />
                            </Col>

                            <Col sm="3" className="labelCreaNuovoOrdLib" >
                                <span>Considera ordini aperti ultimi 12 mesi(merce in viaggio): </span>
                                <Check id="flagConsideraOrdiniApertiProposta"
                                    impostaFiltri={props.impostaFiltri}
                                    checkValue={props.filtri.flagConsideraOrdiniApertiProposta }
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col sm="3" className="labelCreaNuovoOrdLib">
                                <span>Escludere i trasferimenti tra le sedi (valido solo per i gruppi):</span>
                                <Check id="flagEscludiTrasferimenti"
                                    impostaFiltri={props.impostaFiltri}
                                    checkValue={props.filtri.flagEscludiTrasferimenti}
                                />
                            </Col>


                        </Row>
                    </div>

                    

                    
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnSalvaFiltriProdottoOrdLibero" variant="success" onClick={salvaFiltriProdottoOrdineLibero} >
                        Salva filtri
                    </Button>
                    <Button id="btnChiudiDialogImpostazioniOrdLibero" variant="danger" onClick={() => props.handleCloseDialogFiltraProdotti()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogInfo = (props: {
    dialogInfoIsOpen: boolean,
    idOrdine: number | string | undefined,
    titoloOrdine: string | undefined,
    handleCloseInfo: () => void,
}) => {

    const [info, setInfo] = useState<InfoInterface>();
    const [infoTrasmissione, setInfoTrasmissione] = useState<InfoTrasmissioneInterface[]>();
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    useEffect(() => {
        if (props.dialogInfoIsOpen)
            cerca();
    }, [props.dialogInfoIsOpen])

    const handleClose = () => {
        setInfo(undefined);
        props.handleCloseInfo();
    }

    const cerca = () => {
        if (props.idOrdine != undefined) {
            setIsVisible(true);
            let param: number | string = 0;
            param = props.idOrdine;
            RestServices().rest('POST', param, urlOrdiniLiberi.getInfo).then(result => {
                if (result) {
                    setInfo(result);
                    setInfoTrasmissione(result.trasmissione);
                    setIsVisible(false);
                }

            })
        }
    }

    const propsTabInfoTrasmissione = {
        id: "trasmissione",
        dataSource: infoTrasmissione,
        selection: "single",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: false,
        filterRow: true,
    }

    const columnsInfoTrasmissione = CreaColonne([
        { caption: "Farmacia", dataField: "descrizioneFarmacia" },
        { caption: "Data Trasmissione", dataField: "dataTrasmissione", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" }
    ])

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    return (
        <Modal
            show={props.dialogInfoIsOpen}
            onHide={handleClose}
            dialogClassName="dialogImpostazioniOrdineLibero"
            size="lg"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title className="titoloNewOrdineLibero">
                    <h3>Informazioni ordine libero: {props.titoloOrdine}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadPanel}
                <TabPanel
                    selectedIndex={selectedIndex}
                    loop={false}
                    animationEnabled={true}
                    onOptionChanged={onOptionChanged}

                >
                    <Item title="Trasmissione" icon="upload">
                        <Row>&nbsp;</Row>
                        <Row className="rigaCreaNuovoOrdLib">
                            <Col sm="12">
                                <TabellaSemplice
                                    propsTabella={propsTabInfoTrasmissione}
                                    columns={columnsInfoTrasmissione}
                                />
                            </Col>
                        </Row>
                    </Item>
                </TabPanel>
            </Modal.Body>
            <Modal.Footer>
                <Button id="modalConfermaOk" variant="success" onClick={handleClose}>
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogFornitore = (props: {
    dialogFornitore: boolean,
    setDialogFornitore: (dialogPagamento: boolean) => void,
    idFornitore: number | undefined,
    idStato: number | undefined,
    setDescrFornitore: (pagamento: string) => void,
    listaFornitori: FornitoriCentrInterface[] | undefined,
    idOrdine: number | string | undefined,
    setIdFornitore: (forn: number | undefined) => void,
}) => {
    const [fornitoreScelto, setFornitoreScelto] = useState<number>();
    const [listaFornitori, setListaFornitori] = useState<FornitoriCentrInterface[]>();

    useEffect(() => {
        if (props.idFornitore) setFornitoreScelto(props.idFornitore);
    }, [props.idFornitore]);

    useEffect(() => {
        if (props.listaFornitori) setListaFornitori(props.listaFornitori);
    }, [props.listaFornitori]);

    const cambiaFornitore = () => {
        if (listaFornitori) {
            const descrFornitore = listaFornitori.filter(fornitore => {
                return fornitore.ID === fornitoreScelto;
            })[0].RAGIONE_SOCIALE
            const param = {
                idOrdine: Number(props.idOrdine),
                idFornitore: fornitoreScelto,
                idStato: props.idStato
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updateFornitore).then(result => {
                if (result != undefined) {
                    if (result.esito === false) {
                        notify({ position: "center", width: "auto", message: result.message }, 'error', 3000);
                    }
                    else if (result.esito === true) {
                        props.setDialogFornitore(false);
                        props.setDescrFornitore(descrFornitore);
                        props.setIdFornitore(fornitoreScelto);
                        notify({ position: "center", width: "auto", message: "Il fornitore è stato aggiornato" }, 'success', 3000);
                    } else {
                        notify({ position: "center", width: "auto", message: "Non è stato possibile modificare il pagamento. Ci scusiamo per il disagio" }, 'error', 3000);
                    }
                }

            })
        }
    }

    const handleClose = () => props.setDialogFornitore(false);
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        setFornitoreScelto(newFiltro.idFornitore);
    }
    return (
        <>
            <Modal
                show={props.dialogFornitore}
                onHide={handleClose}
                dialogClassName="dialogFornitoreRda"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloDialogFornitoreRda">
                        <h3>Fornitore</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div id="dialogFornitoreRdaBody">
                        <Row id="rigaCambiaFornitoreRda">
                            <Col sm="12" id="scrittaCambiaFornitoreRda">
                                Selezionare un fornitore:
                            </Col>
                            <Col sm="12">
                                {(fornitoreScelto) &&
                                    <Selezione
                                        dataSource={props.listaFornitori}
                                        id="idFornitore"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="RAGIONE_SOCIALE"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                        value={props.idFornitore}
                                    />
                                }
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnOkCambiaFornitoreRda" variant="success" onClick={cambiaFornitore}>
                        OK
                    </Button>
                    <Button id="btnChiudiDialogFornitoreRda" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogFattureScadenza = (props: {
    dialogFattureScadenzaIsOpen: boolean,
    idFornitore: number | string | undefined,
    handleCloseFattureScadenza: () => void,
    fornitore?: string ,
}) => {

    const [fattureScadenza, setFattureScadenza] = useState<any>([]);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    useEffect(() => {
        if (props.dialogFattureScadenzaIsOpen)
            cerca();
    }, [props.dialogFattureScadenzaIsOpen])

    const handleClose = () => {
        props.handleCloseFattureScadenza();
    }

    const cerca = () => {
        let params: any = {
            dal: trasformaDateTimeInLocale(new Date(new Date().getFullYear() - 1, 0, 1), true),
            al: trasformaDateTimeInLocale(new Date(new Date().getFullYear(),11,31), true),
            flagCicloAttivo: false,
            idFornitore: props.idFornitore
        }
        setIsVisible(true)
        RestServices().rest('POST', params, urlConsultazione.getListScad)
            .then(result => {
                if (result?.esito) {
                    //console.log(result.response);
                    setFattureScadenza(result.response.list)
                    setIsVisible(false)

                } else {
                    setIsVisible(false)
                }
            })     

    }

    const propsTabFattureScadenza = {
        id: "fattureScadenza",
        dataSource: fattureScadenza,
        selection: "none",
        export: { enabled: true },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: false,
        filterRow: true,
    }

    const columnsFattureScadenza = [
        { caption: "Scadenza", dataField: "scadeIl", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Importo", dataField: "importo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Tipo Doc.", dataField: "tipoDocumento"},
        { caption: "Num. Doc.", dataField: "numeroDocumento"},
        { caption: "Data Doc.", dataField: "data", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Data Pag.", dataField: "dataSaldo", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date" }
    ]

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    const onRowPrepared = (e: any) => {
        if (e.values) {
            if (e.data.idTipoDocumento == '4' || e.data.idTipoDocumento == '8') {
                e.rowElement.style.backgroundColor = 'rgb(176, 249, 255)';
            }
            else if (e.data.statoPagamento == "I") {
                e.rowElement.style.backgroundColor = 'rgb(244, 246, 137)';
            }
            else if (e.data.statoPagamento == 'M') {
                e.rowElement.style.backgroundColor = 'rgb(255, 204, 102)';
            }
            else if (e.data.statoPagamento == 'P') {
                e.rowElement.style.backgroundColor = 'rgb(150, 255, 164)';
            }
            else if (e.data.statoPagamento == 'S') {
                e.rowElement.style.backgroundColor = 'rgb(252, 230, 232)';
            }

        }
    }

    const apriScadenzario = () => {
        window.open("/app/scadenzario/fatture_passive", '_blank')
    }

    return (
        <Modal
            show={props.dialogFattureScadenzaIsOpen}
            onHide={handleClose}
            dialogClassName="dialogFattureScadenza"
            size="xl"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title className="titoloNewOrdineLibero">
                    <h3>Scadenze per il fornitore: {props.fornitore} </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadPanel}
                <Row>&nbsp;</Row>
                <Row className="rigaCreaNuovoOrdLib">
                    <Col sm="12">

                        <NewTabella
                            idTabella='scade_fatt_passive'
                            sync
                            colonne={columnsFattureScadenza}
                            dataSource={fattureScadenza}
                            allowColumnReordering={false}
                            headerFilter
                            filterRow
                            hidePager={false}
                            activeSaver
                            onRowPrepared={onRowPrepared}
                            exportT={{
                                enabled: false,
                                fileName: "esportazione_scadenzario_passivo"
                            }}

                        />
                    </Col>
                </Row>
                <Row>
                    <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                        <div className='title2'>
                            <h6> Legenda Colori: </h6>
                        </div>
                        <div className='leg5'>
                            <h6 >  Saldati </h6>
                        </div>
                        <div className='leg4'>
                            <h6> Scaduti </h6>
                        </div>
                        <div className='leg1'>
                            <h6>  Scadono oggi </h6>
                        </div>
                        <div className='leg2'>
                            <h6> Scadono nel mese </h6>
                        </div>
                        <div className='leg6'>
                            <h6> Non ancora scaduti  </h6>
                        </div>
                        <div className='leg3'>
                            <h6> Note di credito  </h6>
                        </div>
                    </div>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button id="modalScadenzario" variant="primary" onClick={apriScadenzario}>
                    APRI SCADENZARIO
                </Button>
                <Button id="modalConfermaOk" variant="success" onClick={handleClose}>
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogProdottiSegnalazione = (props: {
    dialogProdottiSegnalazioneIsOpen: boolean,
    idFornitore: number | string | undefined,
    handleCloseProdottiSegnalazione: () => void,
    fornitore?: string,
}) => {

    const [prodottiSegnalazione, setProdottiSegnalazione] = useState<any>([]);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    useEffect(() => {
        if (props.dialogProdottiSegnalazioneIsOpen)
            cerca();
    }, [props.dialogProdottiSegnalazioneIsOpen])

    const handleClose = () => {
        props.handleCloseProdottiSegnalazione();
    }

    const cerca = () => {
        let params: any = {
            mesi: 6,
            idAnaFornitore: props.idFornitore
        }

        setIsVisible(true)
        RestServices().rest('POST', params, urlConsultazione.getListSegnalazione)
            .then(result => {
                if (result?.esito) {
                    //console.log(result.response);
                    setProdottiSegnalazione(result.response)
                    setIsVisible(false)
                } else {
                    setIsVisible(false)
                }
            })

    }

    const propsTabProdottiSegnalazione = {
        id: "prodottiSegnalazione",
        dataSource: prodottiSegnalazione,
        selection: "none",
        export: { enabled: true },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: false,
        filterRow: true,
    }

    const columnsProdottiSegnalazione = [
        { caption: "Data Doc.", dataField: "dataDocumento", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Num.Doc.", dataField: "numeroDocumento", sortOrder: "asc" },
        { caption: "Segnalazione", dataField: "segnalazione" },
        { caption: "Cod.Prod.", dataField: "codProd" },
        { caption: "Cod.Ean", dataField: "codEan" },
        { caption: "Descrizione", dataField: "descrizione",  sortOrder: "asc" },
        { caption: "Qta.", dataField: "quantita" },
        { caption: "Qta.Fat.", dataField: "quantitaFattura" },
        { caption: "Qta.Seg.", dataField: "quantitaSegnalazione" },
        { caption: "Costo", dataField: "costo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Costo Fat.", dataField: "costoFattura", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Costo Seg.", dataField: "costoSegnalazione", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Imponibile", dataField: "imponibile", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Iva", dataField: "iva",customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'iva', 'd') },
        { caption: "Prezzo Pub.", dataField: "prezzoPubblico", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Deivato", dataField: "deivato", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sc.1 %", dataField: "sconto1", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'sconto1', 'd') },
        { caption: "Sc.2 %", dataField: "sconto2", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'sconto2', 'd') },
        { caption: "Sc.3 %", dataField: "sconto3", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'sconto3', 'd') },
        { caption: "Sc.4 %", dataField: "sconto4", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'sconto4', 'd') },
        { caption: "Sc.5 %", dataField: "sconto5", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'sconto5', 'd') },
        
        
    ]

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }


    return (
        <Modal
            show={props.dialogProdottiSegnalazioneIsOpen}
            onHide={handleClose}
            dialogClassName="dialogProdottiSegnalazione"
            size="xl"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title className="titoloNewOrdineLibero">
                    <h3>Segnalazioni per il fornitore: {props.fornitore} </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadPanel}
                <Row>&nbsp;</Row>
                <Row className="rigaCreaNuovoOrdLib">
                    <Col sm="12">

                        <NewTabella
                            idTabella='scade_fatt_passive'
                            sync
                            colonne={columnsProdottiSegnalazione}
                            dataSource={prodottiSegnalazione}
                            allowColumnReordering={false}
                            headerFilter
                            filterRow
                            hidePager={false}
                            activeSaver
                            exportT={{
                                enabled: false,
                                fileName: "esportazione_prodotti_segnalazione"
                            }}

                        />
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button id="modalConfermaOk" variant="success" onClick={handleClose}>
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogFattureNote = (props: {
    dialogFattureNoteIsOpen: boolean,
    idFornitore: number | string | undefined,
    handleCloseFattureNote: () => void,
    fornitore?: string,
}) => {

    const [fattureNote, setFattureNote] = useState<any>([]);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    useEffect(() => {
        if (props.dialogFattureNoteIsOpen)
            cerca();
    }, [props.dialogFattureNoteIsOpen])

    const handleClose = () => {
        props.handleCloseFattureNote();
    }

    const cerca = () => {
        let params: any = {
            idAnaFornitore: props.idFornitore
        }
        setIsVisible(true)
        RestServices().rest('POST', params, urlConsultazione.getListFattureNote)
            .then(result => {
                if (result?.esito) {
                    //console.log(result.response);
                    setFattureNote(result.response)
                    setIsVisible(false)

                } else {
                    setIsVisible(false)
                }
            })

    }

    const propsTabFattureNote = {
        id: "fattureNote",
        dataSource: fattureNote,
        selection: "none",
        export: { enabled: true },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: false,
        filterRow: true,
    }

    const columnsFattureNote = [

        { caption: "Num. Doc.", dataField: "numero" },
        { caption: "Data Doc.", dataField: "data", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Note", dataField: "note" },
        { caption: "Importo", dataField: "importo", format: { type: "currency", precision: 2, currency: "EUR" } },
        
        
        
    ]

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }


    return (
        <Modal
            show={props.dialogFattureNoteIsOpen}
            onHide={handleClose}
            dialogClassName="dialogFattureNote"
            size="xl"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title className="titoloNewOrdineLibero">
                    <h3>Note fatture per il fornitore: {props.fornitore}  (ultimi 6 mesi)</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadPanel}
                <Row>&nbsp;</Row>
                <Row className="rigaCreaNuovoOrdLib">
                    <Col sm="12">

                        <NewTabella
                            idTabella='note_fatture_fornitore'
                            sync
                            colonne={columnsFattureNote}
                            dataSource={fattureNote}
                            allowColumnReordering={false}
                            headerFilter
                            filterRow
                            hidePager={false}
                            activeSaver
                            exportT={{
                                enabled: false,
                                fileName: "note_fatture_fornitore"
                            }}

                        />
                    </Col>
                </Row>

            </Modal.Body>
            <Modal.Footer>
                <Button id="modalConfermaOk" variant="success" onClick={handleClose}>
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    )
}

export const DialogImportListino = (props: {
    isDialogImportListino: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    setIsDialogImportListino: (isDialogImportListino: boolean) => void
    handleCloseDialogImportListino: () => void,
    setIsVisible: (isVisible: boolean) => void,
    fornitori: FornitoriCentrInterface[],
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [uploadUrl, setUploadUrl] = useState<any>();
    const [data, setData] = useState<string>();
    const [formRef, setFormRef] = useState<any>();
    const [fileUploaderRef, setFileUploaderRef] = useState<any>();

    const fileExtensions = ['.csv'];

    useEffect(() => {

        if (props.isDialogImportListino) {
            setUploadUrl(urlOrdiniLiberi.addListino);
        }

    }, [props.isDialogImportListino])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogImportListino(false);
    }

    const aggiungiParametroFile = (e: any) => {
        if (e.value.length > 0) {
            setUploadUrl(updateQueryStringParameter(uploadUrl, "nomeFile", e.value[0].name))
            e.component.option("uploadUrl", uploadUrl);
        }

    }

    const updateQueryStringParameter = (uri: any, key: any, value: any) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            //console.log("1:" + uri.replace(re, '$1' + key + "=" + value + '$2'));
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            //console.log("2:" + uri + separator + key + "=" + value);
            return uri + separator + key + "=" + value;
        }
    }

    const importa = () => {

    }

    const editorOptions = {
        readOnly: true,
    };

    const submitButtonOptions = {
        text: "Importa file",
        useSubmitBehavior: true,
        type: "success",
        onClick: function (e: any) {

            setUploadUrl(uploadUrl);
            var upl = '';
            if (filtri != undefined) {
                if (filtri.nome != undefined || filtri.length > 0) {
                    upl = upl + '&nome=' + filtri.nome;
                }
                if (filtri.descrizione != undefined || filtri.length > 0) {
                    upl = upl + '&descrizione=' + filtri.descrizione;
                }
                if (filtri.idAnagrafica != undefined || filtri.length > 0) {
                    upl = upl + '&idAnagrafica=' + filtri.idAnagrafica;
                }
                if (filtri.data != undefined) {
                    upl = upl + '&data=' + cambiaFormatoLocalDateTimeToEuropeo(filtri.data);
                }

                if (filtri.inVigore != undefined) {
                    upl = upl + '&inVigore=' + filtri.inVigore;
                }
            }
            setUploadUrl(uploadUrl + upl);
        }
    };

    const handleSubmit = (e: any) => {


        e.preventDefault();

        if (filtri != undefined) {
            if (filtri.nome != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Nome obbligatorio" }, "error", 4000);
                return false;
            }
            if (filtri.descrizione != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Descrizione obbligatoria" }, "error", 4000);
                return false;
            }
            if (filtri.idAnagrafica != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Fornitore obbligatorio" }, "error", 4000);
                return false;
            }
            if (filtri.data != undefined) {
            } else {
                notify({ position: "center", width: "auto", message: "Data obbligatoria" }, "error", 4000);
                return false;
            }
        }
        else {
            notify({ position: "center", width: "auto", message: "Campi obbligatori mancanti" }, "error", 4000);
            return false;
        }

        props.setIsVisible(true);
        fetch(e.target.action, {
            method: 'POST',
            body: new FormData(e.target),
            credentials: 'include'
        }).then(resp => resp.json())
            .then(
                (result) => {
                    if (result.esito == true) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Importazione completata" }, "success", 4000)
                    }
                    else if (result.esito == false) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                }).catch((error) => {
                    props.setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                });



    }

    return (
        <>
            <Modal
                show={props.isDialogImportListino}
                onHide={handleClose}
                dialogClassName="dialogNuovaNewsletter"
                size="lg"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Importa da file</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-uploader-block">
                        <form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} onSubmit={handleSubmit}>
                            {/*<form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} target="_blank">*/}
                            <Form
                                colCount={2}>

                                <GroupItem
                                    caption="Tracciato del file"
                                    colSpan={2}>
                                    <Row>
                                        <Col>
                                            {/*<span className="trasfInfo"><b>Tipo riga<b>;</b>Codice ministeriale<b>;</b>Quantita minima<b>;</b>Quantita massima<b>;</b>Data inizio<b>;</b>Data fine<b>;</b>prezzo(imponibile)</b></span>*/}
                                            <span className="trasfInfo">Codice ministeriale<b>;</b>prezzo(imponibile)</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( separatore decimale ammesso per il prezzo punto (.) o virgola (,) )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Il tipo riga puo' essere "L" (listino) o "C" (Condizione) )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Nel caso di "C" e' obbligatorio inserire oltre al codice ministeriale e il prezzo anche la quantita minima e quantita massima, le date non sono obbligatorie. La loro assenza implica durata infinita alla condizione )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Nel caso di "L" il codice ministeriale e il prezzo )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Le date devono essere nel formato AAAAMMGG )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Per uno stesso prodotto possono esistere sia riga di tipo "L"" che "C"; se la condizione e' valida sara' applicata )</span>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <span>Esempi di valori accettati:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">L;004763037;;;;;5.2</span>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">L;025940053;;;;;4,43</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">C;025940053;2;4;20230324;20240323;4,43</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">C;025940053;2;4;;;4,43</span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli il file"
                                    colSpan={2}>
                                    <FileUploader
                                        selectButtonText="Seleziona file" labelText=""
                                        /*accept="image/*"*/
                                        uploadMode="useForm"
                                        uploadUrl={uploadUrl}
                                        onValueChanged={aggiungiParametroFile}
                                        allowedFileExtensions={fileExtensions}
                                        maxFileSize={2000000}
                                        id="fileUploader"
                                        ref={setFileUploaderRef}
                                        height={150}
                                    />
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'File permessi: '}
                                                <span>.csv</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'Dimensione massima file: '}
                                                <span>2 MB</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli il Fornitore/Ditta"
                                    colSpan={2}>

                                    <Selezione
                                        dataSource={props.fornitori}
                                        id="idAnagrafica"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="RAGIONE_SOCIALE"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                    />

                                </GroupItem>

                                <GroupItem
                                    caption="Inserisci le info"
                                    colSpan={2}>

                                    <Row>
                                        <Col sm="4">
                                            <span>Nome listino:</span>
                                        </Col>
                                        <Col sm="8">
                                            <BoxFiltro id="nome" impostaFiltri={impostaFiltri} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <span>Descrizione listino:</span>
                                        </Col>
                                        <Col sm="8">
                                            <BoxFiltro id="descrizione" impostaFiltri={impostaFiltri} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <span>Data listino:</span>
                                        </Col>
                                        <Col sm="3">
                                            <BoxDataOra id="data" impostaFiltri={impostaFiltri} typeDate="date" value={data} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Seleziona opzione"
                                    colSpan={2}>
                                    <span style={{ cursor: "help" }} title="Rende il istino che si sta caricando subito in vigore sostituendo il listino attualmente attivo">Rendi listino in vigore: </span>
                                    &nbsp;
                                    <Check id="inVigore" impostaFiltri={impostaFiltri} />
                                    <Col>
                                        <span className="checkPrezzoInfo">Rende il istino che si sta caricando subito in vigore sostituendo il listino attualmente attivo</span>
                                    </Col>
                                </GroupItem>
                                <ButtonItem colSpan={2} buttonOptions={submitButtonOptions} horizontalAlignment="right" />
                            </Form>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button id="btnImportaPrezzoPromozione" variant="success" onClick={() => importa()}>*/}
                    {/*    Importa*/}
                    {/*</Button>*/}
                    <Button id="btnChiudiDialogImportPrezzoPromozione" variant="danger" onClick={() => props.handleCloseDialogImportListino()}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
