
import React, { useEffect } from 'react'



const InfoBs = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   

    useEffect(() => { 
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

   
    return (
        <>
        
             
             <div className='form-row '> 
                <div className="form-group col-md-6">
                 
                <div style={{  marginLeft:10 , marginTop:20, color:'#337ab7'   }}>
                <h3 className='font-size:6vw'>Business Intelligence</h3>
                </div>

                <div style={{ padding:10, marginRight:30 , marginTop:20 , borderRadius:10 , backgroundColor:'#fff', color:'#337ab7' }}  >
                  <h5 className='font-size:2vw '>
                  La piattaforma <strong>CloudFarma</strong> offre un evoluto sistema di analisi chiamato BusinessLab.
                     </h5>
                     <h5 className='font-size:2vw '>
                     Potente, flessibile e veloce: strumento necessario per un'analisi approfondita del proprio esercizio.
                     </h5>
                     </div> 
                     
                </div>   
             <div className="form-group col-md-6" >
                 <div style={{ marginRight:10 , marginTop:20 , borderRadius:20  }}  >
                 <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_bi_arg.png" alt="business" />

                     </div>
                     <div style={{ marginRight:10 , marginTop:20, borderRadius:20   }}  >
                     <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_bi.png" alt="business" />
                 </div>
            </div> 
            </div> 
           
        </>
    )
}

export default InfoBs