import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import {  trasform_for_cards_charts_01 } from "../util/trasformation";
//import { DialogOperMerce } from "./dialog/PopOpeMerce";
import { createItemFilterAnalVendSintetica } from "./filter/FiltriGalileo";
import {  urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { boxCardSinte, boxCardAllYSinte , gaugeAllYSinte } from "./boxcards/CardsGalileo";
import {
    CircularGauge,
    Scale,
    Label,
    RangeContainer,
    Range,
    Export,
    Title,
    Font,
    Size,
  } from "devextreme-react/circular-gauge";

import "./style.css";

interface cardG {
  testo: string;
  keyValue: string;
  second?: boolean;
  testoPop?: string;
  puntale?: string
}



const GalileoAnalVenditeSintetica = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardSinte);
  const [fila2cards, setFila2cards] = useState<cardC[]>(boxCardAllYSinte);
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const [category, setCategory] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);
  const [valoriQ, setValoriQ] = useState<any>(null);
  const [valoriQO, setValoriQO] = useState<any>(null);
  const [valoriQAY, setValoriQAY] = useState<any>(null);
  const [valoriQOAY, setValoriQOAY] = useState<any>(null);
  const [progetto, setProgetto] = useState<any[]>([]);
  const [societa, setSocieta] = useState<any[]>([]);
  

  const dashboard8 = { dashboardID: 8 };

  const Gauce: React.FC<cardG> = ({ testo, keyValue, second, testoPop, puntale }) => {
    let valoreToUse = 0;
    let valorePuntale = 0;
    let color;
    let colorText;
    if (second) {
      if (valoriQOAY && keyValue) {
        valoreToUse = valoriQOAY[keyValue]?.toFixed(2);
        if (puntale) {
          console.log('cosa sono');

          valorePuntale = valoriQOAY[puntale]?.toFixed(2);
        }

      }
    } else {
      if (valoriQO && keyValue) {
        valoreToUse = valoriQO[keyValue]?.toFixed(2);
        if (puntale) {
          valorePuntale = valoriQO[puntale]?.toFixed(2);

        }
      }
    }

    if (valoreToUse >= -200 && valoreToUse < -30) {
      color = "#CE2029";
      colorText = "#fff";
    } else if (valoreToUse >= -30 && valoreToUse < 0) {
      color = "#FFD700";
      colorText = "#000";
    } else if (valoreToUse >= 0 && valoreToUse < 100) {
      color = "#228B22";
      colorText = "#fff";
    } else {
      // Valore non rientra in nessun intervallo specifico
      // Gestisci il caso generico qui
    }

    return (
      <div style={{ background: "#fff" }} className="grid-item border-solid">
        <div style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
          <button
            className="ibutton"
            style={{ border: "none" }}
            onClick={() => popVisible(testoPop ? testoPop : "")}
          >
            <FontAwesomeIcon
              icon={faInfo}
              style={{ fontSize: "10px", color: "black" }}
            />
          </button>
        </div>
        <CircularGauge id="gauge" value={valoreToUse}>
          <Size height={200} />
          <Scale startValue={-100} endValue={100} tickInterval={10}>
            <Label customizeText={""} />
          </Scale>
          <RangeContainer>
            <Range startValue={-100} endValue={-30} color="#CE2029" />
            <Range startValue={-30} endValue={0} color="#FFD700" />
            <Range startValue={1} endValue={100} color="#228B22" />
          </RangeContainer>
          <Export enabled={true} />
          <Title text={testo}>
          <Font color="#5b6372" size="16" weight="400" />
          </Title>
        </CircularGauge>
        <div style={{ background: color, color: colorText }}>
          <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>
            {valoreToUse}%
          </p>
        </div>
        Delta puntuale <strong>{valorePuntale}%</strong>
      </div>
    );
  };

  
  function aggiornaDataFine(dataFine: Date): Date {
    const nuovaDataFine = new Date(dataFine);

    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    // let venditaDPC = [0];
    // let flagBusta = [0];

    // if (params?.venditaDPC) {
    //   venditaDPC = [1];
    // }
    // if (params?.flagBusta) {
    //   flagBusta = [1];
    // }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      segmento: params.segmento,
      blocco1: params.blocco1,
      blocco2: params.blocco2,
      progetto: params.progetto,
      societa: params.societa,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      // venditaDPC: venditaDPC,
      // flagBusta: flagBusta,
      operatori: params.operatori,
      prodotto: params.prodotto,
      fascia: params.fascia,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall8 = {
        ...dashboard8,
        idFarList: [9199,
          8248,
          8221],
        //idFarList: params.idFar,
        params: parameters,
      };

      //new call

      //console.log('mostro call');
      //console.log(dashboardCall1);

      RestServices()
        .rest("POST", dashboardCall8, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {
            //prima fila 
            let datiPrimaParteCards = trasform_for_cards_charts_01(result["31"], boxCardSinte);

            //seconda fila 
            let datiSecondaParteCards = trasform_for_cards_charts_01(
              result["62"],
              boxCardAllYSinte
            );

              
            setFila1cards(datiPrimaParteCards);
            setFila2cards(datiSecondaParteCards);

            
            setValoriQ(result[31][0]);
            setValoriQO(result[31][1]);
            
            setValoriQAY(result[62][0]);
            setValoriQOAY(result[62][1]);
           

            setIsVisible(false);

          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });


      
      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };




  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlReport.getProgetto)
        .then((result) => {
         // console.log('vediamo sytruttura');
         // console.log(result);

          if(result){
            const progetti = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          
          setProgetto(progetti);
          }
          
          
        });

        RestServices()
        .rest("POST", "", urlReport.getSocieta)
        .then((result) => {
          if(result){
            const societas = result.map((oggetto: { codice: any; id:any }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.id // Aggiungi la nuova proprietà id
              };
          });
          setSocieta(societas)
        }
        });  
    }   
  };

  useEffect(() => {
    filters();
  }, [auth]);

  const exportFormats = ["csv"];

  const onExporting = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("tabella_operatori");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-csv
      // https://github.com/exceljs/exceljs#reading-csv
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "tabella_operatori.csv"
        );
      });
    });
  };

  const itemsFields = createItemFilterAnalVendSintetica(
    newFarmaList
  );

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };

  const valoreGmargineFront = (second?: boolean) => {
    let valoreToUse =
      valoriQ["MARGINE_PERCENTUALE"]?.toFixed(2) -
      valoriQO["MARGINE_PERCENTUALE"]?.toFixed(2);

    if (second) {
      
      valoreToUse =
        valoriQAY["MARGINE_PERCENTUALE"]?.toFixed(2) -
        valoriQOAY["MARGINE_PERCENTUALE"]?.toFixed(2);
    }

    let color: string = "#fff";
    let colorText: string = "#fff";
    if (valoreToUse >= -100 && valoreToUse < -30) {
      color = "#CE2029";
      colorText = "#fff";
    } else if (valoreToUse >= -30 && valoreToUse < 0) {
      color = "#FFD700";
      colorText = "#000";
    } else if (valoreToUse >= 0 && valoreToUse < 100) {
      color = "#228B22";
      colorText = "#fff";
    } else {
      color = "#228B22";
      colorText = "#fff";
      // Valore non rientra in nessun intervallo specifico
      // Gestisci il caso generico qui
    }

    
    return {
      value: valoreToUse,
      color,
      colorText,
    };
  };

 

  return (
    <>
      {/* <DialogOperMerce
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        auth={auth}
        close={() => {
          setOpenPop(false);
          setObjtipo(null);
        }}
        filterData={paramsFilter}
        open={openPop}
      /> */}
     
     <section style={{ display: "flex", height: "98vh" }}>
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div
            style={{
              fontSize: "15px",
              alignItems: "left",
              marginTop: "10px",
              marginBottom: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h2>Analisi Sintetica Farmacia</h2>
          </div>

          {/* Card box */}
          <div style={{}}>
            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"40%"}
              height={"40%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup> 
            <div className="grid-container">
              {fila1cards.map((el, i) => {
               
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                    second2={el.second2}
                  />
                );
              })}
            </div>

            <div style={{ marginTop: '5px' }} className="grid-container">
              {fila2cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                    second2={el.second2}
                  />
                );
              })}
            </div>
          </div>

          {/* gauce 1 */}

          <div style={{ textAlign: "center", padding: 30 }}>
            <h6>
              Andamento puntuale periodo selezionato rispetto l'anno precedente
            </h6>
          </div>

          <div style={{ marginBottom: 5 }} className="grid-container-2">
            <div
              style={{ background: "#fff" }}
              className="grid-item-2 "
            >
              <div style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
                <button
                  className="ibutton-gen"
                  style={{ border: "none" }}
                  onClick={() => popVisible(testoPop ? testoPop : "")}
                >
                  <FontAwesomeIcon
                    icon={faInfo}
                    style={{ fontSize: "10px", color: "black" }}
                  />
                </button>
              </div>
              <CircularGauge
                id="gauge"
                value={valoriQ && valoriQO ? valoreGmargineFront().value : 0}
              >
                <Size height={200} />
                <Scale startValue={-100} endValue={100} tickInterval={10}>
                  <Label customizeText={""} />
                </Scale>
                <RangeContainer>
                  <Range startValue={-100} endValue={-30} color="#CE2029" />
                  <Range startValue={-30} endValue={0} color="#FFD700" />
                  <Range startValue={1} endValue={100} color="#228B22" />
                </RangeContainer>
                <Export enabled={true} />
                <Title text={"Margine percentuale"}>
                <Font color="#5b6372" size="16" weight="400" />
                </Title>
              </CircularGauge>

              <div
                style={{
                  background:
                    valoriQ && valoriQO ? valoreGmargineFront().color : "none",
                  color:
                    valoriQ && valoriQO
                      ? valoreGmargineFront().colorText
                      : "none",
                }}
              >
                <p style={{ fontSize: 16, fontWeight: "bold", margin: 0 }}>
                  {valoriQ && valoriQO
                    ? valoreGmargineFront().value?.toFixed(2)
                    : 0}
                  %
                </p>
              </div>
            </div>

            {gaugeAllYSinte.map((g, i) => {
              return (
                <Gauce
                  key={"g_" + i}
                  testo={g.testo}
                  puntale={g.puntale}
                  testoPop={g.testoPop}
                  keyValue={g.keyValue}
                />
              );
            })}
          </div>

          <div style={{ textAlign: "center", padding: 30 }}>
            <h6>Andamento progressivo annuo</h6>
          </div>

          <div style={{ marginBottom: 5 }} className="grid-container-2">
            <div
              style={{ background: "#fff" }}
              className="grid-item-2"
            >
              <div style={{ display: "flex", alignItems: "right", justifyContent: "right" }}>
                <button
                  className="ibutton-gen"
                  style={{ border: "none" }}
                  onClick={() => popVisible(testoPop ? testoPop : "")}
                >
                  <FontAwesomeIcon
                    icon={faInfo}
                    style={{ fontSize: "10px", color: "black" }}
                  />
                </button>
              </div>

              <CircularGauge
                id="gauge"
                value={valoriQAY && valoriQOAY ? valoreGmargineFront(true).value : 0}
              >
                <Size height={200} />
                <Scale startValue={-100} endValue={100} tickInterval={10}>
                  <Label customizeText={""} />
                </Scale>
                <RangeContainer>
                  <Range startValue={-100} endValue={-30} color="#CE2029" />
                  <Range startValue={-30} endValue={0} color="#FFD700" />
                  <Range startValue={1} endValue={100} color="#228B22" />
                </RangeContainer>
                <Export enabled={true} />
                <Title text={"Margine percentuale"}>
                <Font color="#5b6372" size="16" weight="400" />
                </Title>
              </CircularGauge>
              <div
                style={{
                  background:
                    valoriQAY && valoriQOAY ? valoreGmargineFront(true).color : "none",
                  color:
                    valoriQAY && valoriQOAY
                      ? valoreGmargineFront(true).colorText
                      : "none",
                }}
              >
                <p style={{ fontSize: 18, fontWeight: "bold", margin: 0 }}>
                  {valoriQAY && valoriQOAY
                    ? valoreGmargineFront(true).value?.toFixed(2)
                    : 0}
                  %
                </p>
              </div>
            </div>

            {gaugeAllYSinte.map((g, i) => {
              return (
                <Gauce
                  second={true}
                  key={"g_" + i}
                  puntale={g.puntale}
                  testoPop={g.testoPop}
                  testo={g.testo}
                  keyValue={g.keyValue}
                />
              );
            })}
          </div>


        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{ fontSize: "30px", color: "black" }}
        />
      </div>
      <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
        <div style={{}}>
          <FiltriVerticale
            ricerca={filterAction}
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>
    </section>
    </>
  );
};

export default GalileoAnalVenditeSintetica;
