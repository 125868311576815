import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_no_marge } from "../util/trasformation";
import { columnsAnalisiVendOpera } from "./colums/AnalVendi";
import Button from "react-bootstrap/Button";
import { DialogAnalVendOper } from "./dialog/PopVendOper";
//import { DialogOperDitta } from "./dialog/PopOpeDitta";
//import { DialogOperFarma } from "./dialog/PopOpeFarma";
import { createItemFilterVendOpera} from "./itemsFiltri/AnaliVendite";
import {  urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { boxCardAnaVendOper, boxsecondAnaVendOper } from "./BoxCard/AnalVendite";
import "./style.css";
import { MenuFbiVendite } from "../components/menu_Fbi_Vendite";

const AnalVendiOperatore = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnaVendOper);
  const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecondAnaVendOper);
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);
  const [operatori, setOperatori] = useState<any[]>([]);
  const [progetto, setProgetto] = useState<any[]>([]);
  const [societa, setSocieta] = useState<any[]>([]);
  const [categoria, setCategoria] = useState<any[]>([]);
  const [segmento, setSegmento] = useState<any[]>([]);
  const [blocco1, setBlocco1] = useState<any[]>([]);
  const [blocco2, setBlocco2] = useState<any[]>([]);
  const [tipologiaProdotto, setTipologiaProdotto] = useState<any[]>([]);
  const [tipologiaConFascia, setTipologiaConFascia] = useState<any[]>([]);

  const dashboard12 = { dashboardID: 12 };

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }


    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    if (params.societa && params.societa.length > 0) {
      console.log(societa);
      let objSocieta: any = []

      params.societa.forEach((element: any) => {
        const oggettoTrovato = societa.find(obj => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        })
      });
      params.societaNewV = objSocieta;
    }


    if (params.progetto && params.progetto.length > 0) {
      let objSocieta: any = []

      params.progetto.forEach((element: any) => {
        const oggettoTrovato = progetto.find(obj => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        })
      });
      params.progettoNewV = objSocieta;
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      operatori: params.operatori,
      tipologiaProdotto: params.tipologiaProdotto,
      tipologiaConFascia: params.tipologiaConFascia,
      categoria: params.categoria,
      segmento: params.segmento,
      blocco1: params.blocco1,
      blocco2: params.blocco2,
      progetto: params.progettoNewV,
      societa: params.societaNewV,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall12 = {
        ...dashboard12,
        idFarList: params.idFar,
        params: parameters,
      };


      RestServices()
        .rest("POST", dashboardCall12, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {
            if(result["64"].length > 0){
              let datiPrimaParteCards = trasform_no_marge(result["64"]["0"], boxCardAnaVendOper);
            let datiSecondaParteCards = trasform_no_marge(
              result["64"]["0"],
              boxsecondAnaVendOper
            );
            let datiPrimaTabelle = result["65"];

            setFila1cards(datiPrimaParteCards);
            setFila2cards(datiSecondaParteCards);
            setValoriTabella1(datiPrimaTabelle);

            
            }
            setIsVisible(false);

          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });


      
      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const itemsFields = createItemFilterVendOpera(
    newFarmaList,
    operatori,
    progetto,
    societa,
    tipologiaConFascia,
    tipologiaProdotto,
    categoria,
    segmento,
    blocco1,
    blocco2
  );

 
  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlReport.getProgetto)
        .then((result) => {
          console.log('vediamo sytruttura');
          console.log(result);

          if(result){
            const progetti = result.map((oggetto: { codice: any; id:any }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: String(oggetto.id)  // Aggiungi la nuova proprietà id
              };
          });
          console.log('proge');
          console.log(result);
                  
          setProgetto(progetti);
          }
          
        });

      RestServices()
        .rest("POST", "", urlReport.getSocieta)
        .then((result) => {
          if(result){
            const societas = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setSocieta(result)
        }
        });


      RestServices()
        .rest("POST", "", urlReport.getTipologiaConFascia)
        .then((result) => {
          if(result){
            const fascia = result.map((oggetto: { descrizione: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.descrizione // Aggiungi la nuova proprietà id
              };
          });       
          setTipologiaConFascia(fascia)
        }
        }); 


      RestServices()
        .rest("POST", "", urlReport.getTipologiaProdotto)
        .then((result) => {
          if(result){
            const prodotti = result.map((oggetto: { descrizione: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.descrizione // Aggiungi la nuova proprietà id
              };
          });
         
          setTipologiaProdotto(prodotti)
        }
        });

        RestServices()
        .rest("POST", "", urlReport.getCategoria)
        .then((result) => {

          if(result){
            const categorie = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setCategoria(categorie)

        }
        });

        RestServices()
        .rest("POST", "", urlReport.getSegmento)
        .then((result) => {
          if(result){
            const segmentos = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setSegmento(segmentos)
        }
        });

        RestServices()
        .rest("POST", "", urlReport.getBlocco1)
        .then((result) => {
          if(result){
            const blocco1 = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setBlocco1(blocco1)
        }
        });

        RestServices()
        .rest("POST", "", urlReport.getBlocco2)
        .then((result) => {
          if(result){
            const blocco2 = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setBlocco2(blocco2)
        }
        });

        RestServices()
                .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
                .then((result) => {
                    if (result.esito) {
                        const operatori = result.response.map(
                            (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                                //console.log(ope.idFarmacia);
                                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                                //console.log(farmaciaCorrispondente);
                                return {
                                    ...ope,
                                    id: `${ope.operatore}`,
                                    descrizione: `${ope.operatore}`,
                                    codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                                };
                            }
                        );
                        setOperatori(operatori);
                        // console.log('operatori');
                        // console.log(operatori);
                    }
                });

    }

  };

   
    useEffect(() => {
        filters();
    }, [authReport]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };


  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  return (
    <>
      <DialogAnalVendOper
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        auth={auth}
        close={() => {
          setOpenPop(false);
          setObjtipo(null);
        }}
        filterData={paramsFilter}
        open={openPop}
      />
      {/* <DialogOperDitta
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        auth={auth}
        close={() => {
          setOpenPopTwo(false);
          setObjtipo(null);
        }}
        open={openPopTwo}
        filterData={paramsFilter}
        
      /> */}
      {/* <DialogOperFarma
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        close={() => {
          setOpenPopThree(false);
          setObjtipo(null);
        }}
        auth={auth}
        filterData={paramsFilter}
        open={openPopThree}
      /> */}
      <section style={{ display: "flex", height: "98vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >
              <h2>F.B.I.</h2>
            </div>
            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuFbiVendite />
              </div>
            </div>


            <div>
              <div className="grid-container-sec">
                {fila1cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>
              <div className="grid-container-sec">
                {fila2cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>
            </div>

            {/*  tabella*/}
            <div style={{ marginTop:5 }}></div>

            <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, fontSize: 17, marginTop: 10 }}>Dettaglio operatore</h6>

            
            <NewTabella
              height={"90vh"}
              idTabella="analisi_prodotti"
              sync
              colonne={columnsAnalisiVendOpera}
              dataSource={valoriTabella1}
              rowAlternation={true}
              filterRow
              width={500}
              searchPanel={true}
              exportT={{
                enabled: true,
                fileName: "AnalisiProdotti",
                formats: { exportFormats },
              }}
              activeSaver={true}
              columnC={true}
              headerFilter
              wordWrapEnabled={true}
              onExporting={onExporting}
              summary={
                <Summary>
                  <TotalItem column="GIORNI_LAVORATI" summaryType="sum" />
                  <TotalItem column="ORE_LAVORATE" summaryType="sum" />
                  <TotalItem column="NUMERO_VENDITE" summaryType="sum" />
                  <TotalItem column="NUMERO_RIGHE" summaryType="sum" />
                  <TotalItem
                    column="VALORE_VENDUTO"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem
                    column="MARGINE"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                  <TotalItem column="PEZZI" summaryType="sum" />
                  <TotalItem
                    column="VALORE_VENDITA_MEDIO"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                </Summary>
              }
            />
            <div/>
            <div className="containButtonAnaVendOper">
              <div className="buttonCerca">
                <Button
                  id="btnCercax"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  className="btn btn-primary"
                  variant="primary"
                  onClick={() => {
                    setOpenPop(true);
                    console.log("dialog");
                    //ricerca(objPostRicerca);
                  }}
                >
                  Analisi operatori per classificazione
                </Button>

                {/* <Button
                  className="btn btn-primary"
                  variant="primary"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  onClick={() => {
                    setOpenPopTwo(true);
                    console.log("dialog2");
                    //setOpenPoptwo(true)
                    //ricerca(objPostRicerca);
                  }}
                >
                  Analisi operatori per ditta titolare
                </Button> */}
                {/* <Button
                  className="btn btn-primary"
                  variant="primary"
                  style={{ fontWeight: "bold", fontSize: "14px" }}
                  onClick={() => {
                    console.log("dialog3");
                    setOpenPopThree(true);
                    //ricerca(objPostRicerca);
                  }}
                >
                  Analisi operatori cross farmacie dello stesso gruppo
                </Button> */}
              </div>
            </div>
          </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section style={{background:'#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
          <div style={{}}>
              <FiltriVerticale
                ricerca={filterAction}
                items={itemsFields}
                paramsFilter={paramsFilter}
              />
            </div>
        </section>
      </section>
    </>
  );
};

export default AnalVendiOperatore;
