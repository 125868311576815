import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../../interfaces/farmacie";
import { RestServices } from "../../../../services/restServices";
import FiltriVerticale from "../../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../../components/card_chart";
import { trasform_no_marge, trasform_for_cards_charts_01 } from "../../util/trasformation";
import { columnsOpeAnaScoCate, columnsOpeAnaScoCate2 } from "../Colums/analisiPerOperatore";
import Button from "react-bootstrap/Button";
import { DialogAnaScoCateg } from "../dialog/PopAnaScoCate";
import { createItemFilterAnaSconCate } from "../../evolution/ItemsFiltri/analiScontiCateg";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { urlBancaDati, urlConsultazione, urlReport, urlCommon } from "../../../costanti";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../../widget/nuova_tabella/newBusineVersion";
import { Chart, PieChart } from 'devextreme-react';
import { Animation, ArgumentAxis, CommonSeriesSettings, Export, Label, Legend, Series, Subtitle, Title, Tooltip, ValueAxis } from 'devextreme-react/chart';
import { Connector, Font, Format, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';
import "./styleIntelli.css";

const boxCardAnaSconti: cardC[] = [
    {
        testo: "Valore sconto ",
        subTesto: "Delta anno precedente",
        valore: "VALORE_SCONTOSL",
        subValore: "VALORE_SCONTO_PUNTUALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
    },
    {
        testo: "Sconto percentuale",
        subTesto: "Delta anno precedente",
        valore: "PERC_SCONTOSL",
        subValore: "PERC_SCONTO_PUNTUALEPR",
        typeShowValue: typeShow.percento,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato � gi� al netto della DCR per le vendite successive all'installazione della revisione 10.",
    },
    {
        testo: "Numero vendite con sconto",
        subTesto: "Delta anno precedente",
        valore: "NUMERO_VENDITE_SCONTOSL",
        subValore: "NUMERO_VENDITE_SCONTO_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop:
            "Numero vendite con sconto: Rappresenta il numero di vendite con sconto nel periodo selezionato. Delta numero vendite con sconto: Rappresenta il delta percentuale tra il numero di vendite con sconto anno corrente e anno precedente, nel periodo selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
    },
    {
        testo: "% di vendite con sconto ",
        subTesto: "Delta anno precedente",
        valore: "PERC_VENDITE_SCONTOSL",
        subValore: "PERC_VENDITE_SCONTO_PUNTUALEPR",
        typeShowValue: typeShow.percento,
        typeShowSubValue: typeShow.percento,
        testoPop:
            "% di vendite con sconto: Rappresenta il rapporto tra il numero di vendite con sconto ed il numero di vendite totale. Il periodo considerato � quello selezionato. Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate."
    },

];

const GalileoVenSconti = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [operatori, setOperatori] = useState<any[]>([]);
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnaSconti);
    const [firstpie, setFirstpie] = useState<any[]>([]);
    const [secondpie, setSecondpie] = useState<any[]>([]);
    const [newDataSecondPie, setNewDataSecondPie] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any>([]);
    const chartRef = useRef<any>(null);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);
    const [visiblePop5, setVisiblePop5] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [codiciCategory, setCodiciCategory] = useState<any[]>([]);
    const [tipologiaSconto, setTipologiaSconto] = useState<any[]>([]);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);
    const [category, setCategory] = useState<any[]>([]);

    const loadPanel = <Loading isVisible={isVisible} />;
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);

    const dashboard6 = { dashboardID: 6 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        };

        // let venditaDPC = [0];
        // let flagBusta = [0];

        // if (params?.venditaDPC) {
        //     venditaDPC = [1];
        // }
        // if (params?.flagBusta) {
        //     flagBusta = [1];
        // }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        let category1;
        let category2;
        let category3;
        let category4;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }



        if (params.category) {
            const categoryList = params.category;


            category1 = getCategoryByLevel(categoryList, 2);
            category2 = getCategoryByLevel(categoryList, 4);
            category3 = getCategoryByLevel(categoryList, 6);
            category4 = getCategoryByLevel(categoryList, 8);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            category1: category1,
            category2: category2,
            category3: category3,
            category4: category4,
            ditta: params.ditta,
            operatori: params.operatori,
            tipologiaSconto: params.tipologiaSconto,
            //  tipologiaSconto: ["SCONTO_TESTATA", "SCONTO_MISTO"] ,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall6 = {
                ...dashboard6,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            console.log('mostro call');
            console.log(dashboardCall6);

            RestServices()
                .rest("POST", dashboardCall6, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {
                        let datiPrimaParteCards = trasform_for_cards_charts_01(result["46"], boxCardAnaSconti);

                        //  1 chartpie
                        let arrayTousePie = [
                            { label: 'SCONTO TESTATA', value: result["46"][0]?.NUMERO_VENDITE_SCONTO_TESTATA },
                            { label: 'SCONTO PRODOTTO', value: result["46"][0]?.NUMERO_VENDITE_SCONTO_PRODOTTO },
                            { label: 'SCONTO MISTO', value: result["46"][0]?.NUMERO_VENDITE_SCONTO_MISTO }
                        ]

                        setFirstpie(arrayTousePie);

                        //  2 chartpie
                        setSecondpie(result["48"]);

                        // 1 Chart
                        setChartData(result["47"].reverse());

                        let datiPrimaTabelle = result["49"];

                        setFila1cards(datiPrimaParteCards);
                        setValoriTabella1(datiPrimaTabelle);

                        let datiSecondaTabelle = result["142"];
                        setValoriTabella2(datiSecondaTabelle);

                        const sortedData = [...result["48"]].sort((a, b) => b.VEN_SCONTO_VENDITA - a.VEN_SCONTO_VENDITA);

                        // Se ci sono pi� di 10 oggetti, prendo solo i primi 10 e calcolo la somma degli sconti degli oggetti rimanenti
                        let remainingTotal = 0;
                        if (sortedData.length > 10) {
                            remainingTotal = sortedData.slice(10).reduce((acc, curr) => acc + curr.VEN_SCONTO_VENDITA, 0);
                        }

                        // Prendo i primi 10 oggetti
                        const top10 = sortedData.slice(0, 10);

                        // Aggiungo l'oggetto "Altro" con il valore della somma degli sconti degli oggetti rimanenti
                        const other = {
                            OPERATORE_USERNAME: 'Altri operatori',
                            VEN_SCONTO_VENDITA: remainingTotal,
                        };

                        // Creo un nuovo array con i primi 10 oggetti e l'oggetto "Altro"
                        const newData = [...top10, other];
                        setNewDataSecondPie(newData);
                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });

            // setNewDataSecondPie(newData);
            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });

            RestServices()
                .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
                .then((result) => {
                    if (result.esito) {
                        const operatori = result.response.map(
                            (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                                //console.log(ope.idFarmacia);
                                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                                //console.log(farmaciaCorrispondente);
                                return {
                                    ...ope,
                                    id: `${ope.operatore}`,
                                    descrizione: `${ope.operatore}`,
                                    codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                                };
                            }
                        );
                        setOperatori(operatori);
                        // console.log('operatori');
                        // console.log(operatori);
                    }
                });
        }
    };




    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const filters = () => {
        if (auth) {


            RestServices().rest('POST', "", urlCommon.getListCate)
                .then(result => {
                    if (result) setCodiciCategory(result);
                    console.log(result)
                });

            RestServices().rest('POST', "", urlBancaDati.getCategory).then(result => {
                console.log('vediamoooo categorie');
                console.log(result);
                if (result) setCategory(result);
            })
        }


        const SCONTO_MISTO = { id: 'SCONTO_MISTO', descrizione: "Sconto misto" };
        const SCONTO_PRODOTTO = { id: 'SCONTO_PRODOTTO', descrizione: "Sconto prodotto" };
        const SCONTO_TESTATA = { id: 'SCONTO_TESTATA', descrizione: "Sconto testata" };

        const sconto = [SCONTO_PRODOTTO, SCONTO_TESTATA, SCONTO_MISTO];
        setTipologiaSconto(sconto);
    };

    const itemsFields = createItemFilterAnaSconCate(
        newFarmaList,
        operatori,
        category,
        tipologiaSconto,
    );

    useEffect(() => {
        filters();
    }, [auth]);

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabellaGalileoVenSconti");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabellaGalileoVenSconti.csv"
                );
            });
        });
    };


    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    function getCategoryByLevel(getListCate: any[], level: number) {
        // console.log('categorie by level');
        // console.log(getListCate);
        // console.log(level);    
        return getListCate.filter((category) => category.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };
    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    const popVisible5 = (testo: string) => {
        setVisiblePop5(true);
    };


    let messOne = ["Valore sconto per tipologia di sconto: Rappresenta la distribuzione del valore sconto, nel periodo selezionato, in funzione della tipologia di sconto applicata in vendita. Valore sconto: Rappresenta lo sconto totale applicato in vendita."];
    let messTwo = ["Andamento valore sconto: Descrive l'andamento mensile del valore sconto negli ultimi 12 mesi rolling. Valore sconto anno corrente: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Valore sconto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente � coerente a quello selezionato per l'anno corrente. "];
    let messThree = ["Valore sconto per operatore: Rappresenta la distribuzione del valore sconto, nel periodo selezionato, in funzione dell'operatore erogante lo sconto. Valore sconto: Rappresenta lo sconto totale applicato in vendita."];
    let mess5 = ["Valore Chart"];

    return (
        <>
            <DialogAnaScoCateg
                ricarica={() => {
                    ricarica();
                    setObjtipo(null);
                }}
                auth={auth}
                close={() => {
                    setOpenPop(false);
                    setObjtipo(null);
                }}
                filterData={paramsFilter}
                open={openPop}
            />

            <section style={{ display: "flex", height: "115vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", overflow: "scroll", height: "115vh" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>Vendite Sconti</h2>
                        </div>
                        {/* select*/}

                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >
                        </div>


                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>




                        <div className="grid-container-sec">
                            {fila1cards.map((el, i) => {

                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>


                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background: "white",
                                marginTop: "5px"
                            }}
                        >

                            <div style={{ width: "99%", margin: "10px", marginLeft: "10px" }}>
                                <Chart dataSource={chartData} ref={chartRef}>
                                    <CommonSeriesSettings
                                        argumentField="MESE"
                                        type="bar"
                                        hoverMode="allArgumentPoints"
                                        selectionMode="allArgumentPoints"
                                    >
                                        <Label visible={true}>
                                            <Format type="currency" currency="EUR" precision={0} />
                                        </Label>
                                    </CommonSeriesSettings>

                                    <Series
                                        valueField="VEN_SCONTO_VENDITA_PRECEDENTE"
                                        type="bar"
                                        name="2022/2023"
                                        color="rgb(215, 140, 245)"
                                    />
                                    <Series
                                        valueField="VEN_SCONTO_VENDITA"
                                        type="bar"
                                        argumentField="MESE"
                                        color="rgb(25, 231, 255)"
                                        name="2023/2024"
                                    />


                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({
                                            value,
                                            argument,
                                        }: {
                                            value: any;
                                            argument: any;
                                        }) => {
                                            const formattedValue = parseFloat(value).toLocaleString(
                                                "it-IT",
                                                {
                                                    style: "currency",
                                                    currency: "EUR",
                                                    minimumFractionDigits: 2,
                                                }
                                            );

                                            return {
                                                text: `Valore sconto periodo selezionato: ${formattedValue}`,
                                            };
                                        }}
                                    />
                                    <Title text="Andamento valore del venduto netto sconti - ultimi 12 mesi rolling">
                                        <Font color="#5b6372" size="16" weight="400" />
                                        {/* <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle> */}
                                    </Title>
                                    <Legend
                                        verticalAlignment="bottom"
                                        horizontalAlignment="center"
                                    ></Legend>
                                    <Export fileName="Andamento valore del venduto netto sconti - Ultimi 12 mesi rolling" enabled={true} />
                                </Chart>
                            </div>
                            <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
                                <p>
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisible5("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>

                        </div>



                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 5,
                                backgroundColor: "white",

                            }}
                        >
                            <div style={{ width: "45%" }}>

                                <PieChart
                                    id="pie"
                                    dataSource={firstpie}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"
                                >
                                    <Series
                                        argumentField="label"
                                        valueField="value"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>

                                    {<Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {


                                            const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);

                                            return {
                                                html: `<p>Importo ${argument}</p><p>${formattedValue} / ${(percentText)}</p>`,
                                            };
                                        }}
                                    />}
                                    <Title
                                        text="Valore sconto per tipologia sconto"
                                    >
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                        <Export fileName="Valore sconto per tipologia sconto" enabled={true} />
                                </PieChart>
                            </div>
                            <div style={{ display: "flex", width: "5%", marginTop: "-360px" }}>
                                <p>
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite-two"
                                            style={{ border: "none" }}
                                            onClick={() => popVisible("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>



                            <div style={{ width: "45%" }}>

                                <PieChart
                                    id="pie"
                                    dataSource={newDataSecondPie}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                                >
                                    <Series
                                        argumentField="OPERATORE_USERNAME"
                                        valueField="VEN_SCONTO_VENDITA"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>

                                    {<Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                            console.log('vediamo value');
                                            console.log(percentText);


                                            const dataItem = newDataSecondPie.find((item: any) => item.OPERATORE_USERNAME === argument);
                                            const dataSourceValue = dataItem ? dataItem.VEN_SCONTO_VENDITA : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dataSourceValue);

                                            //console.log(formattedValue);
                                            //console.log(dataSourceValue);
                                            return {
                                                html: `<p>Importo sconto - ${argument}</p><p>${formattedValue} / ${(percentText)}</p>`,
                                            };
                                        }}
                                    />}
                                    <Title
                                        text="Valore sconto per operatore"
                                    >
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                        <Export fileName="Valore sconto per operatore" enabled={true} />
                                </PieChart>
                            </div>

                            <div style={{ display: "flex", width: "5%", marginTop: "-360px" }}>
                                <p>
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite-two"
                                            style={{ border: "none" }}
                                            onClick={() => popVisible3("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>

                        <div style={{ marginTop: 5, backgroundColor: "white" }}>

                        <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, marginBottom: '-3px', marginLeft: '5px' }}>Valore sconto per category</h6>

                            <NewTabella
                                idTabella='GalileoVenSconti'
                                sync
                                colonne={columnsOpeAnaScoCate}
                                dataSource={valoriTabella1}
                                filterRow
                                width={10}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "Valore sconto per category",
                                    formats: { exportFormats }
                                }}
                                activeSaver={true}
                                columnC={true}
                                rowAlternation={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                            
                                />
                            </div>

                                <div style={{ marginTop: 5, backgroundColor: "white", width: '100%' }}>

                                    <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, marginBottom: '-3px', marginLeft: '5px' }}>Valore sconto per Anno e Mese</h6>

                                    <NewTabella
                                        idTabella='GalileoVenSconti'
                                        sync
                                        colonne={columnsOpeAnaScoCate2}
                                        dataSource={valoriTabella2}
                                        filterRow
                                        width={10}
                                        searchPanel={true}
                                        exportT={{
                                            enabled: true,
                                            fileName: "Valore sconto per category",
                                            formats: { exportFormats }
                                        }}
                                        activeSaver={true}
                                        columnC={true}
                                        rowAlternation={true}
                                        headerFilter
                                        wordWrapEnabled={true}
                                        onExporting={onExporting}
                                    
                                    />

                           

                                <div style={{paddingBottom: 20}} className="containButtonAna">
                                <Button
                                    id="btnCercax"
                                    style={{ fontWeight: "bold", fontSize: "14px" }}
                                    className="btn btn-primary"
                                    variant="primary"
                                    onClick={() => {
                                        setOpenPop(true);
                                        console.log("dialog");
                                        //ricerca(objPostRicerca);
                                    }}
                                >
                                    Analisi sconti per operatori
                                </Button>
                            </div>


                        </div>


                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
            <Popup
                visible={visiblePop}
                showCloseButton={true}
                onHiding={() => setVisiblePop(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop3}
                showCloseButton={true}
                onHiding={() => setVisiblePop3(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop5}
                showCloseButton={true}
                onHiding={() => setVisiblePop5(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{mess5}</p>
                </div>
            </Popup>
        </>
    );
};


export default GalileoVenSconti;
