import React, { useState, useRef, useEffect ,useMemo } from 'react'
import { Popup, Position, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { CheckBox } from 'devextreme-react/check-box'
import {  Loading } from '../../../widget/Notifications'
import NewTabella from "../../../widget/nuova_tabella"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import { legendByarray } from '../../../funzioni/formatoVariabili';
import DataGrid , {FilterRow , Selection ,Paging ,Pager } from 'devextreme-react/data-grid'
import './style.css';




interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

export const DialogGest:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)



    const columns = [
        { caption: "Farmacia", dataField: "descrix", width:300 },
        { caption: "Abilitato", dataField: "abilitato", width:40, dataType: "boolean" },
        { caption: "Operatore", dataField: "operatore" },
        { caption: "Nome", dataField: "nome" },  
        { caption: "Codice Fiscale", dataField: "codiceFiscale" }
    ];

    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({}) 
        }

    },[edit])





    const cambiaValore = (tipo:string,value:string) =>{
            let objTo = {...objctTOsave}
            objTo[tipo] = value
            setObjctTOsave(objTo)
            if(objTo.nome?.length > 2 && objTo.codiceFiscale?.length > 4  && objTo.operatore?.length  > 3  ){
                setDisableButton(false)
            }else{
                setDisableButton(true)
        }
    }


    const cambiaIdfarma = (value:any,special?:boolean) =>{
        if(special){
                   
            let ids:any = []
            value.map((farma:any)=>{
                ids.push(farma.id)
            })
            let objTos = {...objctTOsave}
            objTos.operatoriFarmacia = ids
            setObjctTOsave(objTos)
            if(objTos.nome?.length > 2 && objTos.codiceFiscale?.length > 4  && objTos.operatore?.length  > 3  ){
            setDisableButton(false)
        }else{
            setDisableButton(true)
        }
            
        }else{
            let objTo = {...objctTOsave}
            objTo.operatoriFarmacia = value
            setObjctTOsave(objTo)
            if(objTo.nome?.length > 2 && objTo.codiceFiscale?.length > 4  && objTo.operatore?.length  > 3  ){
                setDisableButton(false)
            }else{
                setDisableButton(true)
            }
        }
        
       

        
    }

   
   const getListFarm = () =>{
    if (auth) {
       return RestServices().rest('POST', {} , urlConsultazione.getListFar)
    }
}

   const caricamentiNuovaRicerca = async() =>{
    setLoading(true)
    let farmacieLista = await getListFarm()
    if (farmacieLista?.esito) { 
         if(farmacieLista){
            let params = {} 
            let listaTabella = await RestServices().rest('POST', params , urlConsultazione.getListOperatore)
                if (listaTabella?.esito) {
                    let valoreFormatt = legendByarray(
                        listaTabella.response,
                        farmacieLista.response,
                        'idFar',
                        'idFarmacia',
                        'descrix',
                        ['descrizione'],
                        true,
                        objctTOsave.operatoriFarmacia)
                        

                        let final = valoreFormatt
                        //final.sort((a:any, b:any) => (a.priority > b.priority) ? 1 : -1)

                        final.sort((a: any, b: any) => {
                            if (a.priority > b.priority) return 1;
                            if (a.priority < b.priority) return -1;
                            if (a.operatore.toUpperCase() > b.operatore.toUpperCase()) return 1;
                            if (a.operatore.toUpperCase() < b.operatore.toUpperCase()) return -1;
                        });

                        setTabellaPrincipale(final)
                   
                   
                   
                   
                   
                   setLoading(false)
                }else{
                    setLoading(false)
                    notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)

                }
            
    }
    }else{
        if(farmacieLista?.message){
            notify({ position: "center", width: "auto", message: farmacieLista.message}, "error", 4000)
            
        }
        
    }
   }




    const creaModificaOperCentr = (modifica?:boolean) =>{
         objctTOsave.operatore = objctTOsave.operatore.toUpperCase()
         objctTOsave.nome = objctTOsave.nome.toUpperCase()
         objctTOsave.codiceFiscale = objctTOsave.codiceFiscale.toUpperCase()
        if (auth) {
            if(modifica){
                
                if(objctTOsave.abilitato){
                    objctTOsave.abilitato = 1
                }else{
                    objctTOsave.abilitato = 0
                }
                RestServices().rest('POST', objctTOsave , urlConsultazione.putOperatoreGruppo)
                .then(result => {
                    if(result.esito){
                        ricarica()
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }else{
                
                if(objctTOsave.abilitato){
                    objctTOsave.abilitato = 1
                }else{
                    objctTOsave.abilitato = 0
                }
                if(!objctTOsave.operatoriFarmacia){
                    objctTOsave.operatoriFarmacia = []
                }
                RestServices().rest('POST', objctTOsave , urlConsultazione.postOperatoreGruppo)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave(null)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }
           
        
    } 
    }

    useEffect(()=>{
        setOpenD(open)
        caricamentiNuovaRicerca()
    },[auth])


    useEffect(()=>{
        setOpenD(open)
    },[open])

    const allowedPageSizes = [5, 10, 20, 50];


    return (
        <>
   
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'80%'}
          showTitle={true}
          title="Gestione Operatore"
        >
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={typeEdit ? {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:false,
                onClick: ()=>creaModificaOperCentr(true),
              }: {
                text: 'CREA',
                class:'btncreatipocluster',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>creaModificaOperCentr(),
              }}
          />

        {typeEdit ? (
               <ScrollView width='100%' height='100%'>
              <div className='form-row '> 
              <div className="form-group col-md-6">
                      <label> OPERATORE </label> 
                      <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave.operatore}  onValueChanged={(v)=>cambiaValore('operatore',v.value) }  placeholder="..." /> 
                   </div>

                   <div className="form-group col-md-6">
                   <label> NOME </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave.nome} onValueChanged={(v)=>cambiaValore('nome',v.value) }  placeholder="..." /> 
                   </div>
                   </div>
                   
                   <div className='form-row '> 
                   <div className="form-group col-md-6">
                   <label> CODICE FISCALE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave.codiceFiscale} onValueChanged={(v)=>cambiaValore('codiceFiscale',v.value) } maxLength="16"   placeholder="..." /> 
                   </div>
                    
                   <div className="form-group col-md-6">
                   <label> ABILITATO </label> 
                   <br></br>
                    <CheckBox value={objctTOsave?.abilitato == 1 ? true : false}  onValueChanged={(v)=>cambiaValore('abilitato',v.value) } />
                    </div>
                    </div>

                 <div style={{marginTop:10,marginBottom:10,textAlign:'center'}} >
                        <label> ASSOCIAZIONI </label> 
                </div>

        <div style={{marginTop:20,marginBottom:20, marginRight:20 }} id='tabellaCss'>
        <DataGrid   
              selectedRowKeys={objctTOsave.operatoriFarmacia}
              keyExpr="id"
              dataSource={tabellaPrincipale}
              defaultColumns={columns}
              showBorders={true}
              onSelectionChanged={(datiRow:any)=>cambiaIdfarma(datiRow.selectedRowKeys)}
              showRowLines={true} 
              columnAutoWidth={true}            
          >
          <FilterRow visible={true}/> 
          <Paging defaultPageSize={20} />  
          <Pager
          visible={true}
          showPageSizeSelector={true}
          allowedPageSizes= {allowedPageSizes}      
          showInfo={true} />    
          <Selection 
          mode={'multiple'} 
          />
         
               
          </DataGrid>
           </div>
           <div>
           </div>
           </ScrollView>
            ) : (
                <ScrollView width='100%' height='100%'>
                   <div className='form-row '> 
                   <div className="form-group col-md-6">
                       <label> OPERATORE </label> 
                       <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.codice : ''}  onValueChanged={(v)=>cambiaValore('operatore',v.value) }  placeholder="..." /> 
                    </div>
                    <div className="form-group col-md-6">
                    <label> NOME </label> 
                       <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.nome : ''} onValueChanged={(v)=>cambiaValore('nome',v.value) }  placeholder="..." /> 
                    </div>
                    </div>
                      
                    <div className='form-row '> 
                    <div className="form-group col-md-6">
                    <label> CODICE FISCALE  </label> 
                       <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave ?  objctTOsave.codiceFiscale : ''} onValueChanged={(v)=>cambiaValore('codiceFiscale',v.value) } maxLength="16"  placeholder="..." /> 
                    </div>
                     
                    <div className="form-group col-md-6">
                   <label> ABILITATO </label> 
                   <br></br>
                   <CheckBox  value={objctTOsave?.abilitato == 1 ? true : false} onValueChanged={(v)=>cambiaValore('abilitato',v.value) } />
                    </div>
                    </div>

                <div style={{marginRight:20}}>
                  <div style={{marginTop:10,marginBottom:10,textAlign:'center'}} >
                      <label> ASSOCIAZIONI </label> 
                  </div>
                    <Loading isVisible={loading} />   
                     {openD ? (
                        <NewTabella 
                        idTabella='gest_oper_centr_popup'                  
                        sync
                        multyp
                        colonne={columns} 
                        dataSource={tabellaPrincipale} 
                        filterRow
                        selectedValue={(datiRow:any)=>cambiaIdfarma(datiRow.selectedRowKeys,true)}
                        exportT={{
                            enabled: false,
                            fileName: ""
                        }}       
                        />
                ) : (
                    null
                )}   
                
                </div>
           
            </ScrollView>
            )}
        
           

        </Popup>
        </>
      )

  
}
