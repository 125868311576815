import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';

export const Player = (props: {
    url: string | undefined,
    path: string | undefined,
    isDialogPlayerOpen: boolean,
    setIsDialogPlayerOpen: (isDialogPlayerOpen: boolean) => void
}) => {

    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const onLoadedData = () => {
        setIsVideoLoaded(true);
    };


    return (
        <>
            <Modal
                show={props.isDialogPlayerOpen}
                onHide={() => props.setIsDialogPlayerOpen(false)}
                dialogClassName="dialogDifferenzeRda"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title >
                        
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="youtube-container">
                        <iframe width="560" height="315"
                            src="https://www.youtube.com/embed/4x2bMD2uRaI?si=wzGXM6N3f6dvFQC5?mute=0&loop=1&color=white&controls=1&modestbranding=0&playsinline=1&rel=0&enablejsapi=1&playlist=4x2bMD2uRaI"
                            title="Registro UDI"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin"
                            allowFullScreen></iframe>
                    </div>
                </Modal.Body>
                <Modal.Footer>


                    <Button id="btnChiudiDialogDiffRda" variant="danger" onClick={() => props.setIsDialogPlayerOpen(false)}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}