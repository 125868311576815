import { TabellaCompletaInterface, TabellaSempliceInterface } from "../../interfaces/tabelle";
import Modal from 'react-bootstrap/esm/Modal';
import React, { useEffect, useState } from "react";
import { Loading } from "../../widget/Notifications";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/esm/Button';
import { TabellaCompleta, TabellaCompletaMasterDetail, TabellaSemplice } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import { urlAnagrafiche, urlMonitoraggio } from "../costanti";
import { Selezione } from "../../widget/Select";
import { OggettoInterface } from "../../interfaces/generiche";
import { BoxDataOra, BoxTextArea, BoxTextAreaTicket } from "../../widget/Editor";
import { OperatoreHelpDeskInterface } from "../../interfaces/monitoraggio";
import { useAuth } from "../../hooks/useAuth";
import ScrollView from "devextreme-react/scroll-view"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import TextBox from "devextreme-react/text-box"
import { SelectBox, TextArea } from "devextreme-react";

export const DialogInfoFarmacia = (props: {
    dialogInfoFarmaciaOpen: boolean,
    setDialogInfoFarmacia: (dialogInfoFarmacia: boolean) => void,
    propsTab: TabellaSempliceInterface,
    column: any,
    dati?: any | undefined,
    titolo:string | undefined
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const handleClose = () => {
        props.setDialogInfoFarmacia(false);
    }



    return (
        <>
            <Modal
                show={props.dialogInfoFarmaciaOpen}
                onHide={handleClose}
                dialogClassName="dialogTrasmettiInFarmacia"
                centered={true}
                backdrop="static"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdineRda">
                        <h3>{ props.titolo}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <div id="dialogTrasmettiBody">
                      
                        <Row id="tabellaLogEmail">
                            <TabellaSemplice propsTabella={props.propsTab} columns={props.column} />
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogInfoFarmaciaMasterDetail = (props: {
    dialogInfoFarmaciaOpen: boolean,
    setDialogInfoFarmacia: (dialogInfoFarmacia: boolean) => void,
    propsTab: TabellaCompletaInterface,
    column: any,
    columnDetail: any,
    dati?: any | undefined,
    titolo: string | undefined,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const handleClose = () => {
        props.setDialogInfoFarmacia(false);
    }

    useEffect(() => {

    }, []);

    const [tabellaInstance, setTabellaInstance] = useState<any>();

    return (
        <>
            <Modal
                show={props.dialogInfoFarmaciaOpen}
                onHide={handleClose}
                dialogClassName="dialogTrasmettiInFarmacia"
                centered={true}
                backdrop="static"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdineRda">
                        <h3>{props.titolo}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <div id="dialogTrasmettiBody">

                        <Row id="tabellaLogEmail">
                            {/*<TabellaCompletaMasterDetail propsTabella={props.propsTab} columns={props.column} />*/}

                            <TabellaCompletaMasterDetail
                                propsTabella={props.propsTab}
                                columns={props.column}
                                setTabellaInstance={setTabellaInstance}
                                rowAlternationEnabled={false}
                                columnsDetail={props.columnDetail}
                                key="IndexesFragmentationData"
                                />
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogClienteDoppioClient = (props: {
    dialogClienteDoppioClientOpen: boolean,
    setDialogClienteDoppioClient: (dialogClienteDoppioClient: boolean) => void,
    propsTab: TabellaSempliceInterface,
    column: any,
    dati?: any | undefined,
    titolo: string | undefined
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const handleClose = () => {
        props.setDialogClienteDoppioClient(false);
    }



    return (
        <>
            <Modal
                show={props.dialogClienteDoppioClientOpen}
                onHide={handleClose}
                dialogClassName="dialogTrasmettiInFarmacia"
                centered={true}
                backdrop="static"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdineRda">
                        <h3>{props.titolo}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <div id="dialogTrasmettiBody">

                        <Row id="tabellaLogEmail">
                            <TabellaSemplice propsTabella={props.propsTab} columns={props.column} />
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogMonitoraggioPlatinumRevisione = (props: {
    dialogMonitoraggioPlatinumRevisioneOpen: boolean,
    setDialogMonitoraggioPlatinumRevisione: (dialogMonitoraggioPlatinumRevisione: boolean) => void,
    propsTab: TabellaCompletaInterface,
    column: any,
    dati?: any | undefined,
    titolo: string | undefined
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    const handleClose = () => {
        props.setDialogMonitoraggioPlatinumRevisione(false);
    }



    return (
        <>
            <Modal
                show={props.dialogMonitoraggioPlatinumRevisioneOpen}
                onHide={handleClose}
                dialogClassName="dialogTrasmettiInFarmacia"
                centered={true}
                backdrop="static"
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdineRda">
                        <h3>{props.titolo}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <div id="dialogTrasmettiBody">

                        <Row id="tabellaLogEmail">
                            <TabellaCompleta propsTabella={props.propsTab} columns={props.column} setTabellaInstance={setTabellaInstance}/>
                        </Row>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogMonitoraggioPlatinumAssegnaTicket = (props: {
    dialogMonitoraggioPlatinumAssegnaTicketOpen: boolean,
    setDialogMonitoraggioPlatinumAssegnaTicket: (dialogMonitoraggioPlatinumAssegnaTicket: boolean) => void,
    selectedKeys: any[] | undefined,
    dati?: any | undefined,
    titolo: string | undefined,
    setEsitoAssegnaTicket: (esitoAssegnaTicketAssegnaTicket: boolean) => void,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [operatoriHelpDesk, setOperatoriHelpDesk] = useState<OperatoreHelpDeskInterface[]>();
    const [dataInizio, setDataInizio] = useState<string>();
    const [dataFine, setDataFine] = useState<string>();
    const [defaultValue, setDefaultValue] = useState<string | undefined>();

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setDialogMonitoraggioPlatinumAssegnaTicket(false);
    }

    useEffect(() => {

        if (props.dialogMonitoraggioPlatinumAssegnaTicketOpen == true) {

            const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
            setDataInizio(dataInizio.toString());

            //setFiltri({
            //    dataInizio: dataInizio.toString(),
            //})

            let idClienti : number[] = [];
            props.selectedKeys?.forEach((row: any) => {
                if (row.idCliente)
                    idClienti.push(row.idCliente);
            })
            setIsVisible(true);
            const param = { clienti: idClienti };
           // console.log(param);
            RestServices().rest('POST', param, urlMonitoraggio.getOperatoriHelpDesk).then(result => {
                if (result) {
                    if (result.response) {
                        setFiltri({ operatore: result.response, dataInizio: dataInizio, });
                        setOperatoriHelpDesk(result.response);
                    } else {
                        notify({ position: "center", width: "auto", message: "Non e' possibile recuperare gli operatori" }, "error", 4000)
                    }
                }
                
            })
            setIsVisible(false);
        }

        
    }, [props.dialogMonitoraggioPlatinumAssegnaTicketOpen]);

    const inviaTicket = () => {

        let idClienti: number[] = [];
        props.selectedKeys?.forEach((row: any) => {
            if (row.idCliente)
                idClienti.push(row.idCliente);
        })
        //console.log(filtri)
        if (filtri.dataInizio == undefined || filtri.dataFine == undefined) {
            notify({ position: "center", width: "auto", message: "Compilare i campi data" }, "error", 4000);
            return;
        }

        if (filtri.operatore == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare un operatore" }, "error", 4000)
            return;
        }

        let email: string | undefined;
        email = operatoriHelpDesk?.filter(operatore => {
            return operatore.id === filtri.operatore;
        })[0].email

        //console.log(email)
         
        if (email == "") {
            notify({ position: "center", width: "auto", message: "L'operatore selezionato non ha l'email" }, "error", 4000)
            return;
            //email = "daniele.desantis@csfsistemi.it";
        }

        const param = {
            idOpe: filtri.operatore,
            clienti: idClienti,
            testoTicket: filtri.testoTicket,
            dataInizio: filtri.dataInizio,
            dataFine: filtri.dataFine,
            email: email
        }
        setDefaultValue(filtri.testoTicket);

        setIsVisible(true);
        RestServices().rest('POST', param, urlMonitoraggio.addTicketEsternoMonitoraggioHelpDesk).then(result => {
            if (result) {
                setIsVisible(false);
                if (result.esito == true) {
                    notify({ position: "center", width: "auto", message: "Ticket inseriti ed inviati tramite email all'operatore" }, "success", 4000)
                    props.setEsitoAssegnaTicket(true);
                    handleClose();
                } else {
                    props.setEsitoAssegnaTicket(false);
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, "error", 4000)
                }
            }
            else {
                setIsVisible(false);
                props.setEsitoAssegnaTicket(false);
                notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, "error", 4000)
            }
            
        })
        

    }

    return (
        <>
            <Modal
                show={props.dialogMonitoraggioPlatinumAssegnaTicketOpen}
                onHide={handleClose}
                dialogClassName="dialogTrasmettiInFarmacia"
                centered={true}
                backdrop="static"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloTrasmettiOrdineRda">
                        <h3>{props.titolo}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <div id="dialogTrasmettiBody">
                        <Row>
                            <Col sm="7">
                                <label>Operatore</label>
                                <Selezione id="operatore" impostaFiltri={impostaFiltri} dataSource={operatoriHelpDesk} displayExpr="nomeCognome" valueExpr="id" searchEnabled={true} />
                            </Col>
                            
                        </Row>
                        &nbsp;
                        &nbsp;
                        <Row>
                            <Col sm="4">
                                <label>Data inizio prevista attivita'</label>
                                <BoxDataOra id="dataInizio" impostaFiltri={impostaFiltri} typeDate="date" value={dataInizio} />
                            </Col>
                            
                        </Row>
                        &nbsp;
                        &nbsp;
                        <Row>
                            <Col sm="4">
                                <label>Data fine prevista attivita'</label>
                                <BoxDataOra id="dataFine" impostaFiltri={impostaFiltri} typeDate="date" value={dataFine} />
                            </Col>
                        </Row>
                        &nbsp;
                        &nbsp;
                        <Row>
                            <Col sm="8">
                                <label>Testo</label>
                                <BoxTextAreaTicket id="testoTicket" impostaFiltri={impostaFiltri} value={defaultValue} />
                            </Col>
                        </Row>
                        
                        
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogTrasmetti" variant="success" onClick={inviaTicket}>
                        Invia
                    </Button>
                    <Button id="btnChiudiDialogTrasmetti" variant="danger" onClick={handleClose}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

interface DialogProps {
    open: boolean,
    edit: any,
    typeEdit: boolean,
    ricarica: () => void,
    close: () => void,
}

export const DialogNoteMon: React.FC<DialogProps> = ({ open, close, edit, ricarica, typeEdit }) => {

    const [openD, setOpenD] = useState<boolean>(false)
    /*const auth = useAuth(urlMonitoraggio.auth)*/
    const [objctTOsave, setObjctTOsave] = useState<any>({})


    const UpdateNote = () => {
        //console.log(objctTOsave);
        if (objctTOsave) {
            //console.log(objctTOsave);
            RestServices().rest('POST', {
                note: objctTOsave.note,
                idCliente: objctTOsave.idCliente,
                codiceTelepharma: objctTOsave.codiceTelepharma
            }, urlMonitoraggio.updateNote)
                .then(result => {
                    if (result?.esito) {
                        ricarica()
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }




    const cambiaValore = (tipo: string, value: string) => {
        
        let objTo = { ...objctTOsave }
        objTo[tipo] = value
        setObjctTOsave(objTo)
    }


    useEffect(() => {
        if (edit) {
            //console.log(edit);   
            let objTo = { ...objctTOsave };
            objTo.note = edit.note;
            objTo.idCliente = edit.idCliente
            objTo.codiceTelepharma = edit.codiceTelepharma
            setObjctTOsave(objTo);
        }
    }, [edit, openD]);

    useEffect(() => {
        setOpenD(open);
        if (open) {

        }

    }, [open]);






    return (
        <Popup
            visible={openD}
            showCloseButton={false}
            width={'40%'}
            height={'50%'}
            showTitle={true}
            title=""
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Annulla',
                    onClick: () => close(),
                }}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Salva',
                    elementAttr: {
                        class: 'btncreatecluster',
                    },
                    onClick: () => UpdateNote(),
                }
                }
            />

            <ScrollView height="100%" width="100%">


                <div style={{ width: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h3> MODIFICA NOTE <span className='subt' ></span> </h3>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        <TextArea height={120} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.note} onValueChanged={(v) => cambiaValore('note', v.value)} />
                    </div>

                </div>


            </ScrollView>
        </Popup>
    );
};


interface DialogPropsAnomalie {
    open: boolean,
    edit: any,
    typeEdit: boolean,
    tipoAnomaliaDataSource: any[],
    ricarica: () => void,
    close: () => void,
}

export const DialogAnomalieMon: React.FC<DialogPropsAnomalie> = ({ open, close, edit,tipoAnomaliaDataSource, ricarica, typeEdit }) => {

    const [openD, setOpenD] = useState<boolean>(false)
    /*const auth = useAuth(urlMonitoraggio.auth)*/
    const [objctTOsave, setObjctTOsave] = useState<any>({})


    const UpdateAnomalia = () => {
        //console.log(objctTOsave);
        if (objctTOsave) {
            //console.log(objctTOsave);
            RestServices().rest('POST', {
                idCliente: objctTOsave.idCliente,
                idTipoAnomalia: objctTOsave.idTipoAnomalia,
                flagAttivo: objctTOsave.idTipoAnomalia > 0 ? 1 : 0
            }, urlMonitoraggio.setAnomalia)
                .then(result => {
                    if (result?.esito) {
                        notify({ position: "center", width: "auto", message: "Salvataggio avvenuto con successo. Attendere aggiornamento tabella..." }, "success", 4000)
                        setTimeout(() => {
                            ricarica()
                            close()
                        }, 2000);
                        
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
    }




    const cambiaValore = (tipo: string, value: string) => {
        //console.log("ciao")
        //console.log(tipo)
        //console.log(value)
        let objTo = { ...objctTOsave }
        objTo[tipo] = value
        setObjctTOsave(objTo)
    }


    useEffect(() => {
        if (edit) {
            //console.log(edit);   
            let objTo = { ...objctTOsave };
            objTo.idTipoAnomalia = edit.idTipoAnomalia;
            objTo.idCliente = edit.idCliente;

            setObjctTOsave(objTo);
        }
    }, [edit, openD]);

    useEffect(() => {
        setOpenD(open);
        if (open) {

        }

    }, [open]);



    return (
        <Popup
            visible={openD}
            showCloseButton={false}
            width={'40%'}
            height={'40%'}
            showTitle={true}
            title=""
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Annulla',
                    onClick: () => close(),
                }}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Salva',
                    elementAttr: {
                        class: 'btncreatecluster',
                    },
                    onClick: () => UpdateAnomalia(),
                }
                }
            />

            <ScrollView height="100%" width="100%">
                <div style={{ width: '100%' }}>
                    <div style={{ textAlign: 'center' }}>
                        <h3> MODIFICA ANOMALIA <span className='subt' ></span> </h3>
                    </div>

                    <div style={{ marginTop: '50px' }}>
                        <SelectBox
                            dataSource={tipoAnomaliaDataSource}
                            displayExpr="descrizione"
                            valueExpr="id"
                            valueChangeEvent="keyup"
                            value={objctTOsave?.idTipoAnomalia}
                            onValueChanged={(v: any) => cambiaValore('idTipoAnomalia', v.value)} />
                    </div>

                </div>
            </ScrollView>
        </Popup>
    );
};