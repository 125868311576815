import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import { DialogConferma, Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import 'devextreme/data/odata/store';
import DataGrid, { Column, Paging, Pager ,FilterRow} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
// import {DialogGest} from "./dialog/pop_ges_op_cent"
// import {DialogVisuOper} from "./dialog/popup_visu_oper"
import './style.css';


function isNotEmpty(value:any) {
    return value !== undefined && value !== null && value !== '';
  }

const store:any = new CustomStore({
    key: 'OrderNumber',
    load(loadOptions:any) {
     // console.log('parametrii che uso');
      //console.log(loadOptions)
      alert('chiamo server')
      
      let params = '?';
      [
        'skip',
        'take',
        'requireTotalCount',
        'requireGroupCount',
        'sort',
        'filter',
        'totalSummary',
        'group',
        'groupSummary',
      ].forEach((i) => {
        if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
      });
      params = params.slice(0, -1);
      return fetch(`https://js.devexpress.com/Demos/WidgetsGalleryDataService/api/orders${params}`)
        .then((response) => response.json())
        .then((data) =>{
          console.log(data);
          return({
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount,
          })
          })
        .catch(() => { throw new Error('Data Loading Error'); });
    },
  });

const allowedPageSizes = [8, 12, 20];

const GestTry = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   
    const auth = useAuth(urlConsultazione.auth); 
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [openPop , setOpenPop ] = useState<boolean>(false)
   

   

    const ricarica = () =>{
        setOpenPop(false)  
        
    }


   useEffect(()=>{
        
    },[auth])
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


  
   
    return (
        <>
        <Loading isVisible={loading} />
            <div  className='title'>
                <h3>TEST  <span className='subt' ></span> </h3>
             </div>
        
            

             <DataGrid
        dataSource={store}
        showBorders={true}
        remoteOperations={true}
      >
        <Column
          dataField="OrderNumber"
          dataType="number"
        />
        <Column
          dataField="OrderDate"
          dataType="date"
        />
        <Column
          dataField="StoreCity"
          dataType="string"
        />
        <Column
          dataField="StoreState"
          dataType="string"
        />
        <Column
          dataField="Employee"
          dataType="string"
        />
        <Column
          dataField="SaleAmount"
          dataType="number"
          format="currency"
        />
        <Paging defaultPageSize={12} />
        <FilterRow visible={true}  />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={allowedPageSizes}
        />
      </DataGrid>
    
        {/* <NewTabella 
                idTabella='gest_clienti_farm'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                onclickRow={()=>null}          
                exportT={{
                    enabled: true,
                    fileName: "gest_clienti_farm"
                }}
                activeSaver={true}
                columnC={true}              
            /> */}
      </>

    )
}

export default  GestTry