import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { TabellaCompletaInterface, TabellaSempliceInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaInfoFarmacia, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from 'devextreme/ui/notify';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AnaFarmacia, FarmaciaSelezionataNewsletter } from "../../interfaces/newsletter";
import Button from 'react-bootstrap/esm/Button';
import ReactDOM from "react-dom";
import { DialogClienteDoppioClient, DialogNoteMon } from "./Dialog";
import { useAuth } from "../../hooks/useAuth";

const ElencoClientiContrattiSmart = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlMonitoraggio.auth);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            startCerca();
        }

    }, [auth]);

    const titolo = TitoloContenuto("Elenco clienti monitoraggio contratti Smart");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoFarmacie, setElencoFarmacie] = useState<AnaFarmacia[]>();
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)


    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        RestServices().rest('POST', param, urlMonitoraggio.getAnagraficaClientiContrattiSmart)
            .then(result => {
                setIsVisible(false);
                if (result && result.length > 0) {
                    setElencoFarmacie(result);
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const propsTabElencoFarmacie: TabellaCompletaInterface = {
        id: "elenco_info_farmacie_smart",
        dataSource: elencoFarmacie,
        selection: "single",
        autoWidth: true,
        defaultFilterValue: ["platinum", "=", true],
        //height: function () {
        //    return window.innerHeight / 1.2;
        //},
        headerFilterVisible: true,
        summary: {

            totalItems: [{
                name: "TotaleFarmacie",
                showInColumn: "Cod.CSF",
                displayFormat: "Tot.: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoInfoFarmacieSmart"
        }
    }


    const columnsFarmacie = CreaColonne([
        { caption: "IdCliente", dataField: "idCliente", visible: false },
        { caption: "Cod.CSF", dataField: "codiceCliente", visible: true },
        { caption: "Cod.Tph", dataField: "codiceTelepharma", visible: true, sortOrder: "asc" },
        { caption: "Tipo Cliente", dataField: "tipoCliente" },
        { caption: "Rag.Soc.", dataField: "ragioneSociale" },
        { caption: "Titolare", dataField: "titolare" },
        { caption: "P.Iva", dataField: "partitaIva" },
        { caption: "Email", dataField: "email" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Comune", dataField: "comune" },
        { caption: "Cap", dataField: "cap" },
        { caption: "Telefono", dataField: "telefono" },
        { caption: "Concessionario", dataField: "concessionario" },
        { caption: "Tipo Anagrafica", dataField: "tipoAnagrafica" },
        { caption: "Ha platinum", dataField: "platinum", visible: true, dataType: "boolean" },
        { caption: "Ha disdetto platinum", dataField: "disdettaPlatinum", visible: true, dataType: "boolean" },
        { caption: "Data termine Platinum", dataField: "dataDisdettaPlatinum", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Data ultima vendita", dataField: "ultimaVendita", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data Aggior.", dataField: "dataAggiornamento", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Revisione", dataField: "releaseInstallata" },
        { caption: "Ha cloudfarma", dataField: "cloudfarma", visible: true, dataType: "boolean" },
        { caption: "Livello platinum", dataField: "livelloPlatinum", visible: true },
        { caption: "Livello cloudfarma", dataField: "livelloCloudfarma", visible: true },
        //{ caption: "Categoria", dataField: "categoriaDiBaseContrattoPrincipale", visible: true },
        { caption: "Data installazione CloudFarma", dataField: "dataInstallazioneCloudfarma", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", visible: true },
        { caption: "Data ultimo invio flusso", dataField: "dataUltimoInvio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", visible: false },
        { caption: "Mese ultimo invio flusso", dataField: "meseUltimoInvio", visible: false },
        { caption: "Data attivazione Smart", dataField: "dataAttivazioneSmart", format: "dd/MM/yyyy", dataType: "date" },
        { caption: "Ragione sociale Smart", dataField: "ragioneSocialeSmart" },
        { caption: "Tipo origine", dataField: "tipoOrigine"},
        { caption: "Gruppo Smart", dataField: "gruppoSmart" },
        { caption: "Partita IVA Smart", dataField: "partitaIVASmart" },


        {
            caption: "Note", dataField: "note", width: 100, cellRender: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        }
    ])


    const onContentReady = (function (e: any) {


        //const node = ReactDOM.findDOMNode(null);
        //console.log(node)
        //// Get child nodes
        //if (node instanceof HTMLElement) {
        //    const child = node.querySelector('.dx-page-size');
        //    console.log(child);
        //}

        //var el = e.component.element().find('.dx-page-size').last();
        //el.text('All');
        //el.click(function () {
        //    e.component.pageSize(0);
        //    el.text('All');
        //});
    })

    const onCellPrepared = (e: any) => {

        e.cellElement.style.padding = "3px";
    }

    const ricarica = () => {
        setOpenPop2(false)
        setImmediate(false)
        startCerca()
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row className="tabellaFornitori" id="rigaTabInfoFarmacia">
                    <Col sm="12" className="infoFarmacia">
                        {/*<TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            columnsDetail={columnsFarmacieDettaglio}
                            urlDetail={urlAnagrafiche.getAnagraficaClienteNewsletterDettaglio + '?idCli='}
                            keyDetail={"idCliente"}
                            key={"idCli"}
                        />*/}

                        <TabellaCompletaInfoFarmacia
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            togliFilterPanel={false}
                            searchPanel={true}
                            groupPanel={true}
                            onContentReady={onContentReady}
                            onCellPrepared={onCellPrepared}

                        />
                    </Col>
                </Row>
            </div>

            <DialogNoteMon
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />

        </>
    )

}

export default ElencoClientiContrattiSmart;