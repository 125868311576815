import { default as React, useEffect, useMemo, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useAuth } from "../../hooks/useAuth";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { urlCommon } from "../costanti";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import DettaglioDocumenti from "./DettaglioDocumenti";

interface PageProps {
    rowData: any;
    setIndietro: () => void;
}

export const Scontrini: React.FC<PageProps> = ({ rowData, setIndietro }) => {

    const titolo = TitoloContenuto("Pagina Scontrini");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [scontrini, setScontrini] = useState<any[]>([]);
    const [scontriniConDelta, setScontriniConDelta] = useState<any[]>([]);
    const [scontriniConDeltaNegativo, setScontriniConDeltaNegativo] = useState<any[]>([]);
    const [params, setParams] = useState<any>({});
    const [showNegativeDelta, setShowNegativeDelta] = useState(false);
    const [deltaTotale, setDeltaTotale] = useState<any[]>([]);
    const [renderp, setRenderp] = useState<any>();
    const [objtipo, setObjtipo] = useState<any>(null);

    const auth = useAuth(urlCommon.auth);

    let idFarValue = rowData.idFar;
    let idRegistratoreValue = rowData.idRegistratore;
    let numeroDiChiusuraValue = rowData.numeroDiChiusura;

    const exportScontrino = () => {

        //console.log(idFar)
        //console.log(data)

        let model = {
            idFar: idFarValue,
            //data: dataValue,
            idRegistratore: idRegistratoreValue,
            numeroDiChiusura: numeroDiChiusuraValue
        }

        //console.log(model);

        setParams(model);

        //console.log(params)
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getScontrini)
                .then(result => {


                    if (result) {

                        setScontrini(result.response);
                        setIsVisible(false);

                        if (result.response.length === 0) {

                            notify({ position: "center", width: "auto", message: "Nessun dettaglio da mostrare" }, "error", 1000)
                            setIsVisible(false);
                        }

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })

        }

    }


    useEffect(() => {
        exportScontrino();
    }, [auth]);


    useEffect(() => {

        const newResponse = scontrini.map(item => {

            //const delta = calcolaDelta(item.importoPagato, item.importoPlatinum);

            let delta = undefined;

            //DOCUMENTO 3 UGUALE RESO
            if (item.tipoDocumento === 3) {

                if (item.cpeVendita !== undefined)
                    delta = item.reso + item.importoPlatinum;
                else
                    delta = item.reso - item.totComplessivo;
            }
            //DOCUMENTO 2 UGUALE VENDITA ANNULLATA
            else if (item.tipoDocumento === 2) {
                delta = item.importoPagato - item.importoPlatinum;
            }
            //DOCUMENTO 1 E CPEVENDITA A NULL UGUALE VENDITA ANTICIPATA
            else if (item.tipoDocumento === 1) {
                if (item.cpeVendita !== undefined)
                    delta = item.importoPagato - item.importoPlatinum;
                else
                    delta = item.importoPagato - item.venditaAnticipata;
            }



            const data = item.dataVendita;

            //console.log("Data:", data, "importo pagato:", item.importoPagato, "importo platinum", item.importoPlatinum)

            let formattedData = "Data non disponibile";

            if (data && data.includes('T')) {

                const [parteData, parteOrario] = data.split('T');

                const [anno, mese, giorno] = parteData.split('-');

                const [ore, minuti, secondi] = parteOrario.split(':');

                formattedData = `${giorno}/${mese}/${anno} ${ore}:${minuti}:${secondi}`;
            }

            const Vendita = item.cpeVendita;

            let codiceVendita = "Nessuna vendita presente";

            if (Vendita)
                codiceVendita = Vendita;

            return {
                ...item,
                delta: delta,
                dataVendita: formattedData,
                cpeVendita: codiceVendita,
            };
        });

        setScontriniConDelta(newResponse);

        const deltaTotale = newResponse.reduce((acc, item) => acc + item.delta, 0);

        setDeltaTotale(deltaTotale);

        const filtered = newResponse.filter(item => item.delta !== 0);
        setScontriniConDeltaNegativo(filtered);

    }, [scontrini]);


    const toggleView = () => {
        setShowNegativeDelta(!showNegativeDelta);
    };



    const getDeltaCellColor = (e: any) => {
        if (e.rowType === "data" && e.column.caption === "Delta") {
            e.cellElement.style.color = e.data.delta === 0 ? 'green' : 'red';
            e.cellElement.style.fontWeight = 'bold';

        }
    };

    const columnsScontrini = [

        { caption: "Codice Vendita", dataField: "cpeVendita", },
        { caption: "Data Vendita", dataField: "dataVendita", },
        {
            caption: "Numero Documento", dataField: "numDocumento", width: "auto",

            render: (e: any) => {

                const multiDocumento = e.value.includes(',');

                if (multiDocumento) {

                    return (<a style={{ cursor: 'pointer', fontWeight: 'bold', }}
                        onClick={() => {

                            let model =
                            {
                                idFar: idFarValue,
                                idRegistratore: idRegistratoreValue,
                                numeroDiChiusura: numeroDiChiusuraValue,
                                numeroDocumento: e.row.data.numDocumento,
                            }

                            setObjtipo(model);
                            setRenderp(1);
                            //console.log(e.row.data)
                        }}>{e.value}</a>)
                }
                else {
                    return <span>{e.value}</span>
                }
            }

        },
        { caption: "Importo Pagato", dataField: "importoPagato", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Importo Platinum", dataField: "importoPlatinum", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Totale", dataField: "totComplessivo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Reso", dataField: "reso", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Vendita Anticipata", dataField: "venditaAnticipata", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Vendita Annullata", dataField: "venditaAnnullata", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Delta", dataField: "delta", format: { type: "currency", precision: 2, currency: "EUR" }, getDeltaCellColor },
    ];



    // Estrae la data
    const data = rowData.data;

    // Trasforma la stringa della data in una parte data e una parte orario
    const [parteData, parteOrario] = data.split('T');

    // Estrae i componenti della data
    const [anno, mese, giorno] = parteData.split('-');

    // Estrae i componenti dell'orario
    const [ore, minuti, secondi] = parteOrario.split(':');

    // Costruisce la nuova data nel formato "dd/MM/yyyy HH:mm:ss"
    const formattedData = `${giorno}/${mese}/${anno} - ${ore}:${minuti}:${secondi}`;


    const currentDataSource = showNegativeDelta ? scontriniConDeltaNegativo : scontriniConDelta;


    const render = () => {

        switch (renderp) {
            case 1:
                return (

                    <DettaglioDocumenti
                        rowData={objtipo}
                        setIndietro={() => { setRenderp("default") }}
                    >
                    </DettaglioDocumenti>

                )


            default:
                return (
                    <>
                        <br></br>
                        {titolo}
                        {loadPanel}

                        <div style={{ marginTop: 15, display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>

                            {/*<Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />*/}

                            <div>
                                <h4> <span style={{ fontWeight: 'bold' }}>{rowData.codiceFarmacia} - {rowData.descrizioneFarmacia}</span></h4>
                                <h4> <span style={{ fontWeight: 'bold' }}>Matricola Registratore: <span style={{ color: 'red' }}>{rowData.matricola}</span></span></h4>
                                <h4> <span style={{ fontWeight: 'bold' }}>Numero di Chiusura: <span style={{ color: 'red' }}>{rowData.numeroDiChiusura}</span> - in data e ora: {formattedData}</span></h4>
                            </div>

                            <div>
                                <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro(); }}> TORNA AL DETTAGLIO GIORNATA </Button>
                            </div>
                        </div>

                        <div style={{ marginTop: 30, display: "flex", justifyContent: 'center' }}>
                            <Button className="btn btn-warning" variant="primary" onClick={toggleView} disabled={scontriniConDelta.length === 0 ? true : false} > {showNegativeDelta ? "MOSTRA TUTTI" : "MOSTRA SOLO I DELTA NEGATIVI"}</Button>
                        </div>

                        <div>
                            <h4>
                                <span>
                                    Somma dei delta: <span style={{ color: 'red', fontWeight: 'bold' }}>{typeof deltaTotale === 'number' ? new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(deltaTotale) : 'Calcolo in corso...'}</span>
                                </span>
                            </h4>
                        </div>



                        <NewTabella
                            idTabella='scontrini'
                            sync
                            colonne={columnsScontrini}
                            dataSource={currentDataSource}
                            filterRow
                            scrolling={{
                                useNative: false,
                                showScrollbar: 'onHover'
                            }}
                            searchPanel={true}
                            exportT={{ enabled: true, fileName: "ElencoEsportazioniDettagli" }}
                            activeSaver={true}
                            columnC={true}
                            headerFilter
                            wordWrapEnabled={true}
                            onCellPrepared={getDeltaCellColor}
                        />


                    </>

                )
        }

    }
    return render()
}

export default Scontrini;


