import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { SelectBox } from 'devextreme-react/select-box'
import NewTabella from "../../../widget/nuova_tabella"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import {DialogCluster as DialogClusterTipo } from "./popup_tipo_cluster"
import DataGrid , {FilterRow , Selection ,Paging ,Pager ,Export} from 'devextreme-react/data-grid'

import './style.css';


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

export const DialogCluster:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const [tipologie,setTipologie] = useState<any>([])
    const auth = useAuth(urlConsultazione.auth)
    const [ openPop , setOpenPop ] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(true)



    const columns = [
        { caption: "Codice Farmacia", dataField: "codice" , width:"80" },
        { caption: "Descrizione Farmacia", dataField: "ragioneSociale", width:"240" },
        { caption: "P.IVA ", dataField: "partitaIva", width:"150" },
    ];

    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave(null) 
        }

    },[edit])


    const ricaricaTipi = () =>{
        setOpenPop(false)
        getTipologiaCluster()
    }

    const cambiaValore = (tipo:string,value:string) =>{
        let objTo = {...objctTOsave}
        objTo[tipo] = value
        setObjctTOsave(objTo)
        if(objTo.codice?.length > 4 && objTo.descrizione?.length > 4 && objTo.idClusterTipo && objTo.idFarmacie?.length > 0){
            setDisableButton(false)
        }else{
            setDisableButton(true)
        }
    }


    const cambiaIdfarma = (value:any,special?:boolean) =>{
        if(special){
            let ids:any = []
            value.map((farma:any)=>{
                ids.push(farma.idFar)
            })
            let objTos = {...objctTOsave}
            objTos.idFarmacie = ids
            setObjctTOsave(objTos)
            if(objTos.codice?.length > 2 && objTos.descrizione?.length > 4 && objTos.idClusterTipo && objTos.idFarmacie?.length > 0){
            setDisableButton(false)
        }else{
            setDisableButton(true)
        }
            
        }else{
            let objTo = {...objctTOsave}
            objTo.idFarmacie = value
            
            setObjctTOsave(objTo)
            if(objTo.codice?.length > 2 && objTo.descrizione?.length > 4 && objTo.idClusterTipo && objTo.idFarmacie?.length > 0){
                setDisableButton(false)
            }else{
                setDisableButton(true)
            }
        }
        
       

        
    }

    const farmaList = (order?:boolean) =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {
        
                       let addKeyFarma:any = []
                       
                       result.response.forEach((element:any) => {
                          
                           let obj = {...element}
                           obj.key = element.idFar
                           obj.priority = objctTOsave?.farmacie.includes(element.idFar) ?  1 : 2;
                           addKeyFarma.push(obj)
                       });

                       let final = addKeyFarma
                       final.sort((a:any, b:any) => (a.priority > b.priority) ? 1 : -1)
                       setTabellaPrincipale(final)                  
                       
                   }else{
                       
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
             
                   }
               })     
       }
   }

    const getTipologiaCluster = () =>{
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListTipologiaCluster)
                .then(result => {
                     if (result?.esito) {
                         setTipologie(result.response)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
        }
    }


    const creaModificaCluster = (modifica?:boolean) =>{
        objctTOsave.descrizione = objctTOsave.descrizione.toUpperCase()
        objctTOsave.descrizioneEstesa = objctTOsave.descrizioneEstesa.toUpperCase()
        if (auth) {
            if(modifica){
                delete objctTOsave.codice
                delete objctTOsave.numeroFarmacie
                if(!objctTOsave.idFarmacie){
                    objctTOsave.idFarmacie = objctTOsave.farmacie
                }
                delete objctTOsave.farmacie
                delete objctTOsave.idDesc
                RestServices().rest('POST', objctTOsave , urlConsultazione.putClusterObj)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave(null)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }else{
                RestServices().rest('POST', objctTOsave , urlConsultazione.postClusterObj)
                .then(result => {
                    if(result.esito){
                        ricarica()
                        setObjctTOsave(null)
                    }else{
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

                    }
                })
            }
           
        
    } 
    }

    useEffect(()=>{
        if(typeEdit){
            farmaList(true)
        }else{
            //farmaList()
        }
       
    },[typeEdit])

    useEffect(()=>{
         if(auth){
            getTipologiaCluster()
            farmaList()
        }
        
        setOpenD(open)
            
       
    },[auth])


    useEffect(()=>{
        setOpenD(open)
    },[open])


    return (
        <>
         <DialogClusterTipo typeEdit={false} edit={null} ricarica={()=>ricaricaTipi()} close={()=>setOpenPop(false)} open={openPop} />
   
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'80%'}
          showTitle={true}
          title="Gestione Cluster"
        >
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={typeEdit ? {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:false,
                onClick: ()=>creaModificaCluster(true),
              }: {
                text: 'CREA',
                class:'btncreatipocluster',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>creaModificaCluster(),
              }}
          />

        {typeEdit ? (
               <ScrollView width='100%' height='100%'>
                <div className='form-row '> 
                <div className="form-group col-md-6">
                      <label> CODICE </label> 
                      <TextBox disabled value={objctTOsave.codice}  onValueChanged={(v)=>cambiaValore('codice',v.value)}  /> 
                   </div>
                   <div className="form-group col-md-6">
                   <label> NOME </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave.descrizione} onValueChanged={(v)=>cambiaValore('descrizione',v.value) }  placeholder="Inserisci..." /> 
                   </div>
                   </div>
                   
                   <div className='form-row '>
                   <div className="form-group col-md-6">
                   <label> DESCRIZIONE </label> 
                      <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave.descrizioneEstesa} onValueChanged={(v)=>cambiaValore('descrizioneEstesa',v.value) }   placeholder="Inserisci..." /> 
                   </div>
                    
                   <div className="form-group col-md-6">
                   <label> TIPO </label> 
                   <SelectBox dataSource={tipologie}
                       displayExpr={(data)=>{
                        if(data){
                            return data?.codice + "-" + data?.descrizione; 

                        }else{
                            return ''; 

                        }
                        
                    }}
                       searchEnabled={true}
                       valueExpr="id"
                       value={objctTOsave.idClusterTipo}
                       onValueChanged={(v)=>cambiaValore('idClusterTipo',v.value)}
                   />
                    </div>
                    </div>
                    <div className='select'>
                    <div style={{textAlign:'center'}}>
                         <a  style={{color:'#3279b7',cursor:'pointer',fontWeight:'bold'}} onClick={()=>setOpenPop(true)}>Aggiungi nuova tipologia cluster</a>  
                    </div>    
        </div>
        <div style={{marginTop:10,marginBottom:20}} id='tabellaCss'>
        <DataGrid   
              defaultSelectedRowKeys={objctTOsave.farmacie}
              keyExpr="idFar"
              dataSource={tabellaPrincipale}
              defaultColumns={columns}
              showBorders={true}
              onSelectionChanged={(datiRow:any)=>cambiaIdfarma(datiRow.selectedRowKeys)}
              showRowLines={true}             
          >
          <FilterRow visible={true}/> 
          <Paging defaultPageSize={100} />
         
         
          <Selection 
          mode={'multiple'} 
          />
               
          </DataGrid>
           </div>
         
           </ScrollView>
            ) : (
                <ScrollView width='100%' height='100%'>
                <div className='form-row '> 
                <div className="form-group col-md-6">
                       <label> CODICE </label> 
                       <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.codice : ''}  onValueChanged={(v)=>cambiaValore('codice',v.value) } maxLength='10'  placeholder="Inserisci Max 10 numeri" /> 
                    </div>
                    <div className="form-group col-md-6">
                    <label> NOME </label> 
                       <TextBox  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.descrizione : ''} onValueChanged={(v)=>cambiaValore('descrizione',v.value) }  placeholder="Inserisci..." /> 
                    </div>
                    </div>
                    
                    <div className='form-row '> 
                    <div className="form-group col-md-6">
                    <label> DESCRIZIONE </label> 
                       <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave ?  objctTOsave.descrizioneEstesa : ''} onValueChanged={(v)=>cambiaValore('descrizioneEstesa',v.value) }   placeholder="Inserisci..." /> 
                    </div>
                     
                    <div className="form-group col-md-6">
                    <label> TIPO </label> 
                    <SelectBox dataSource={tipologie}
                        displayExpr={(data)=>{
                            if(data){
                                return data?.codice + "-" + data?.descrizione; 

                            }else{
                                return ''; 

                            }
                            
                        }}
                        valueExpr="id"
                        value={objctTOsave ?  objctTOsave.idClusterTipo : ''}
                        onValueChanged={(v)=>cambiaValore('idClusterTipo',v.value)}
                    />
                     </div>
                     </div>

                     <div className='select'>
                     <div style={{textAlign:'center'}}>
                         <a  style={{color:'#3279b7',cursor:'pointer',fontWeight:'bold'}} onClick={()=>setOpenPop(true)}>Aggiungi nuova tipologia cluster</a>  
                    </div>    
                   
         </div>
         <div className=''>
          {openD ? (
              <NewTabella 
              idTabella='elenco_list_farma_popup'
              sync
              multyp
              colonne={columns} 
              dataSource={tabellaPrincipale} 
              filterRow
              selectedValue={(datiRow:any)=>cambiaIdfarma(datiRow.selectedRowKeys,true)}
              exportT={{
                  enabled: false,
                  fileName: "fat_carichi"
              }}
              
          />
          ) : (
            null
          )}   
         
            </div>
           
            </ScrollView>
            )}
        
           

        </Popup>
        </>
      )

  
}
