import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from './interface'
import {  trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'
import { AplicaValoreTO ,actionTables } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'


import './style.css'


const GestListiniForni = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idCarico,setIdCarico] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [ListGrupMercProd,setListGrupMercProd] = useState([])
    const [ListCategory,setListCategory] = useState([])
    const [newFarmaList,setNewFarmaList] = useState([])
    const [listFornitori,setListFornitori] = useState([])
    const [loadinc,setLoadinc] = useState<boolean>(false)
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth);



const columns = [
    { caption: "Fornitore", dataField: "fornitore",  width: 100 },
    { caption: "Cod. Prod.", dataField: "codProdotto", width: 100 },
    { caption: "Codice Ean", dataField: "ean", width: 180 },  
    { caption: "Descrizione", dataField: "descrizioneEstesa", },
    { caption: "Prezzo", dataField: "prezzoBancaDati",format: { type: "currency", precision: 2, currency: "EUR" } , width: 100 },
    { caption: "Quantità", dataField: "quantita", width: 150 },
    { caption: "Costo", dataField: "costo",format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
    { caption: "Sconto %", dataField: "sconto",width: 80 },
    // { caption: "Azioni",   width: 80  , type: "buttons", buttons: [
    //     {
    //         hint: "Informazioni Ordine Libero",
    //         icon: "print",
    //         onClick: (e: any) => {
    //             //console.log(e.row.data.idCarico);
    //             actionTables(e.row.data.idCarico)
    //         }
    //     }]  },
    
   ];

const itemsFields = [
    {type:'select' , idValue:'idFar' , label:'Farmacie ' ,displayExpr:'descrizione', dataSource : newFarmaList , require:true ,width:350 },
    {type:'select' , idValue:'idFornitore' , displayExpr:'ragioneSociale', label:'Fornitore' , dataSource : listFornitori ,width:300 },
    {type:'autocomplete' , idValue:'codCsf' , label:'Prodotto' , typeApi:'products',valueExpr:'text', apisearch:true, dataSource : {},width:300 },
    {type:'autocomplete' , idValue:'codDitta' , label:'Ditta' ,typeApi:'ditta' ,valueExpr:'ragioneSociale' , apisearch:true, dataSource : {} ,width:350 },
    {type:'select' , idValue:'codGruppoMerceologico' , label:'Gruppo Merceologico' , displayExpr:'descGruppoMerceologico' , dataSource :ListGrupMercProd ,width:270 },
    {type:'select' , idValue:'codiceCategory' , label:'Categoria' , displayExpr:'descrizioneCategory' , dataSource : ListCategory ,width:250  },
]


const farmaList = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListFar)
           .then(result => {
               if (result?.esito) {                     
                   //console.log(result.response);
                   setNewFarmaList(result.response)                    
               }else{                              
                   //console.log(result);
                   
               }
           })     
   }
}


const operListForni = (obj?:any) =>{
    setLoadinc(true)
    if (auth) {
        if(obj?.idFar){
            let idF = obj?.idFar.idFar;
            RestServices().rest('POST', {idFar:idF } , urlConsultazione.getForniFarm)
           .then(result => {
               if (result?.esito) {                     
                  // console.log(result.response);
                   setListFornitori(result.response)  
                   setLoadinc(false)                  
               }else{     
                setLoadinc(false)                          
                  // console.log(result);                    
               }
           }) 
        }else{
            setLoadinc(false)
            setListFornitori([]) 
        }
        
   }
}

const getListCategory = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListCate)
           .then(result => {
               if (result?.esito) {                 
                   //console.log(result.response);
                   setListCategory(result.response)
                   
               }else{                               
                  // console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}

const getGrupMerc = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getGrupMercProd)
           .then(result => {
               if (result?.esito) {                 
                   //console.log(result.response);
                   setListGrupMercProd(result.response)
                   
               }else{                               
                  // console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}


const filterAction = (objFiltro:Filtri) =>{
    let params = {...objFiltro}
     //console.log(params);
           {

        if(objFiltro.idFar){
            params.idFarmacia = Number(params.idFar?.idFar)
            delete params.idFar
        }    
        
        if(objFiltro.codCsf){
            params.codCsf = Number(params.codCsf?.id)
        }
        if(objFiltro.idFornitore){
            //console.log(objFiltro.idFornitore);        
            params.idAnag = objFiltro.idFornitore.idAnagrafica
            delete params.idFornitore
        }
        else{
            delete params.idFornitore
        } 
        
        if(objFiltro.codDitta){
            params.codDitta = objFiltro.codDitta.codice
        }  
        else{
            delete params.codDitta
        } 

        if(objFiltro.codGruppoMerceologico){
            params.codGrupMerc = Number (params.codGruppoMerceologico?.codGruppoMerceologico)
            delete params.codGruppoMerceologico
        }else{
            delete params.codGruppoMerceologico
        }

        if(objFiltro.codiceCategory){
            params.codCategory = Number (params.codiceCategory?.codiceCategory)
            delete params.codiceCategory
        }else{
            delete params.codiceCategory
        }
        
       
        //console.log(params);
        if(params.idAnag || params.codCsf ){
            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListiniAcqui)
                        .then(result => {
                            if (result?.esito) {
                                setTabellaPrincipale(result.response)
                                setLoading(false)
                            }else{
                                //console.log(result);
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        }else{
            notify({ position: "center", width: "auto", message: 'Selezionare uno tra fornitore e prodotto'}, "error", 4000)
        }

        
    }
}


        useEffect(() => {
            if (props.statoMenu) {
                props.toggleMenu(!props.statoMenu);
            }
        }, []);

        useEffect(()=>{
            farmaList()
            getGrupMerc()
            getListCategory()
                      
        },[auth])


        useEffect(() => {
            if (idCarico) {
                //getCarichiAction()
            }
        }, [idCarico]);



        return (
            <>
            <Loading isVisible={loading} />
                <div className='title'>
                    <h3> Listini Acquisto <span className='subt' > Consultazione listini dei fornitori</span> </h3>
                </div>
                <Ricerca loadingc={loadinc}  async={(filtro)=>operListForni(filtro)} ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  />
            
                <NewTabella 
                    idTabella='gestione_listini'
                    sync
                    colonne={columns} 
                    dataSource={tabellaPrincipale} 
                    filterRow
                    height='50vh'
                    searchPanel={true}
                   // onclickRow={(datiRow:any)=>setIdCarico(datiRow.data?.idCarico)}
                    exportT={{
                        enabled: true,
                        fileName: "Gest_listini"
                    }}
                    activeSaver
                    columnC
                    summary={
                        <Summary>
                            <TotalItem column="dataBolla" summaryType="count" />
                            <TotalItem column="totaleQuantita" summaryType="sum"  /> 
                            <TotalItem column="totaleImponibile" summaryType="sum" valueFormat={{style: 'currency', currency: 'EUR'}} />
                        </Summary>}
                />

        </>
            )
        }

export default GestListiniForni