import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { FrameWrapper } from "../../widget/FrameWrapper";
import { urlCommon } from "../costanti";
import GuidaInstallazioneRevisione from "./GuidaInstallazioneRevisione";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import './style.css';

interface PageProps {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu: (stato: boolean) => void;
    statoMenu: boolean;
}

const ElencoArticoli: React.FC<PageProps> = ({ datiFarmacie, toggleMenu, statoMenu }) => {

    const [isVisible, setIsVisible] = useState(false);
    const [articoli, setArticoli] = useState<any[]>([]);
    const auth = useAuth(urlCommon.auth);
    const [renderp, setRenderp] = useState<any>();
    const location = useLocation<any>();
    const { revisioneRilascio } = useParams<{ revisioneRilascio: string }>();
    const history = useHistory();


    const tornaInCima = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const showIndex = () => {

        window.location.href= ('/app/DocumentazioniRevisioni/Documentazione')
    };

    const showGuida = () => {

        setRenderp(1);
    };


    const articoliList = () => {

        let versioneRevisione = revisioneRilascio;
        //let idRevisione = 1086;


        let model = {
            revisioneRilascio: versioneRevisione,
        }

        setIsVisible(true);
        if (auth) {
            RestServices()
                .rest("POST", model, urlCommon.getArticoliDocumentazione)
                .then((result) => {
                    if (result?.esito) {

                        setArticoli(result.response);

                        //console.log(result.response)

                    } else {
                        // handle error
                        setIsVisible(false);
                    }
                });
        }
    };

    useEffect(() => {
        articoliList();
    }, [auth]);



    const render = () => {

        switch (renderp) {

            case 1:
                return (

                    <GuidaInstallazioneRevisione
                        setIndietro={() => { setRenderp("default") }}
                    >
                    </GuidaInstallazioneRevisione>

                )

            default:
                return (
                    <>
                        <br></br>

                        <div style={{ textAlign: 'center', marginBottom: '5%', marginTop: '2%' }}>
                            <h2><b>Documentazione '{revisioneRilascio}'</b></h2>
                        </div>


                        <section style={{
                            width: '80%', margin: '0 auto',
                        }}>

                            <div style={{ display: "flex", marginBottom: 30 }}>
                                <div style={{}}>
                                    <Button /*className="custom-button"*/ variant="primary" onClick={() => { showIndex(); }}><b>Vai all'elenco delle Revisioni</b></Button>
                                </div>

                                <div style={{ marginLeft: "auto" }}>
                                    <Button /*className="custom-button"*/ variant="success" onClick={() => { showGuida(); }}><FontAwesomeIcon icon={faInfoCircle}/><b> - Guida all'installazione</b></Button>
                                </div>
                            </div>

                            <div>

                                {(() => {

                                    if (articoli && articoli.length > 0) {

                                        const moduliStampati = new Set();


                                        return articoli.map((revisione, index) => {


                                            // Se il modulo non � stato ancora stampato, viene stampato e aggiunto al set
                                            if (!moduliStampati.has(revisione.idModulo)) {

                                                moduliStampati.add(revisione.idModulo);

                                                return (
                                                    <div key={index} style={{ padding: 25, }}>

                                                        <div style={{ textAlign: 'center' }}>
                                                            <h4><b>{`Modulo: ${revisione.descrizioneModulo}`}</b></h4>
                                                        </div>

                                                        <div style={{ gap: 10, background: '#ffffff', padding: 25, borderBottom: '2px dashed #000', borderRadius: 10, marginTop: 10 }}>
                                                            <FrameWrapper html={`${revisione.linkArticolo}`} />
                                                            <h6 style={{ textAlign: 'right', fontStyle: 'italic', marginTop: 1 }}>{`Disponibile dalla Revisione ${revisione.revisioneRilascio} del: ${revisione.dataRilascio}`}</h6>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            // Se il modulo � gi� stato stampato, genera solo l'articolo
                                            return (
                                                <div key={index} style={{ padding: 25, }}>
                                                    <div style={{ gap: 10, background: '#ffffff', padding: 25, borderBottom: '2px dashed #000', borderRadius: 10, marginTop: 10 }}>
                                                        <FrameWrapper html={`${revisione.linkArticolo}`} />
                                                        <h6 style={{ textAlign: 'right', fontStyle: 'italic', marginTop: 1 }}>{`Disponibile dalla Revisione ${revisione.revisioneRilascio} del: ${revisione.dataRilascio}`}</h6>
                                                    </div>
                                                </div>
                                            );

                                        });

                                    } else {

                                        return (

                                            <div style={{ textAlign: 'center', background: 'white', padding: 30, borderRadius: 10, }}>
                                                <h4><b>{`Nessuno articolo disponibile per la  Revisione selezionata`}</b></h4>
                                            </div>
                                        );
                                    }


                                })()}


                            </div>


                            <div style={{ display: "flex", marginBottom: 30 }}>
                                <div style={{}}>
                                    <Button /*className="custom-button" */ variant="primary" onClick={() => { showIndex(); }}><b>Vai all'elenco delle Revisioni</b></Button>
                                </div>

                                <div style={{ marginLeft: "auto" }}>
                                    <Button /*className="btn btn-warning"*/ variant="warning" onClick={() => { tornaInCima(); }}><b>Torna In Cima</b></Button>
                                </div>
                            </div>

                            <br />
                            <br />

                        </section>



                    </>


                )

        }
    }

    return render()
};

export default ElencoArticoli;