import React, { useEffect, useState } from 'react'
import Select from 'react-select'

// import './style.css';


const StatBusinessLab = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    
    let options:any = [
        {label:'Cruscotto andamento farmacia', value:'5d404ce16cb220190cc6e9c1'},
        {label:'Cruscotto andamento gruppo', value:'5ba4ab277f9fa337d8d5c19e'},
        {label:'Analisi Vendite', value:'5b238f1b6d70650058054cee'},
        {label:'Analisi performance per operatore', value:'5ba2052c948f851b80e82e3b'},
        {label:'Analisi Sconti Vendite per Category (Banca Dati CSF)', value:'5bed752ac7e591102cbe848c'},
        {label:'Analisi Sconti Vendite per Gruppo Merceologico (Banca Dati CSF)', value:'5b238f076d70650058054c79'},
        {label:'Analisi Ricette', value:'5b238f016d70650058054c5b'},
        {label:'Analisi Trasferimenti', value:'5c88d9477499fc13187ddd31'},
        {label:'Analisi Contenitori - Disponibile solo per i Clienti SistemaF Omnia', value:'5cef82dd5dff552340e85f77'},
   ]

   const [selection,setSelection] = useState<string>('5d404ce16cb220190cc6e9c1')

    useEffect(() => {
       
        if (false) {
            props.toggleMenu(props.statoMenu);
        }
    }, [false]);


    return (
        <>     
        <div >
        <div className='title'>
                <h4>  BusinessLab  <span className='subt' > Cruscotto Direzionale</span> </h4>
             </div>
             <label >Selezionare il cruscotto direzionale:</label> 
                <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={options[0]}
                    isClearable={false}
                    isSearchable={true}
                    onChange={(s)=>{
                       // console.log(s.value);
                        setSelection(s.value)
                        
                    }}
                    name="color"
                    options={options}
                />   
            </div>   
            
            <div style={{ height: '80vh', marginTop: 10 }}>
                <iframe
                    id="ifm"
                    width="100%"
                    height="100%"
                    src={`https://sss.cloud-farma.it/app/main#/dashboards/${selection}?embed=true`}
                ></iframe>
            </div>
           
        </>
    )
}

export default StatBusinessLab