import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth';
import { urlCommon, urlGestioneUdi, urlGestioneUdiV2 } from '../costanti';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import { CheckBox, TextBox, Validator } from "devextreme-react";
import notify from "devextreme/ui/notify";

import "./style.css";
import { Loading } from '../../widget/Notifications';
import { RestServices } from '../../services/restServices';
import { AsyncRule, EmailRule, RequiredRule } from 'devextreme-react/validator';
import { Redirect } from 'react-router-dom';

const SignupUdi = (props: {  }) => {

    //const auth = useAuth(urlGestioneUdi.auth);

    const [loading, setLoading] = useState<boolean>(false)
    const [isRegistraDisabled, setIsRegistraDisabled] = useState<boolean>(true)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [confermaEmailValidation, setConfermaEmailValidation] = useState<boolean>(false)
    const [passwordValidation, setPasswordValidation] = useState<boolean>(false)


    const cambiaValore = (tipo: string, value: string, tipoControllo?: string) => {
        let objTo = { ...objctTOsave }

        if (tipoControllo === 'Select') {
            //console.log(value)
            //value � un array
            let myArray = value as unknown as Array<any>;
            let ids: any[] = [];
            myArray.forEach((element: any) => {
                ids.push(element.value)

            });
            if (tipo === 'idFarmacie') {
                
            }

            objTo[tipo] = ids;

        } else if (tipoControllo === 'SelectSingle') {
            let v: any = value as unknown as any
            objTo[tipo] = v.value;
        } else {
            objTo[tipo] = value
        }

        setObjctTOsave(objTo)
        // console.log(objTo)


        if ((objTo.cbInformativa == undefined || objTo.cbInformativa == false) || (objTo.ragioneSociale == undefined || objTo.ragioneSociale == "") || (objTo.partitaIva == undefined || objTo.partitaIva == "") || (objTo.email == undefined || objTo.email == "") || (objTo.confermaEmail == undefined || objTo.confermaEmail == "") || confermaEmailValidation == false)
            setIsRegistraDisabled(true)
        else
            setIsRegistraDisabled(false)
    }
    const signUp = () => {

        //if (auth) {

            if (objctTOsave) {
                let param = {
                    ragioneSociale: objctTOsave.ragioneSociale,
                    partitaIva: objctTOsave.partitaIva,
                    email: objctTOsave.email,
                    indirizzo: objctTOsave.indirizzo,
                    gestionale: "STE",
                    idGestionale: "STE_" + objctTOsave.email,
                }

                setLoading(true)

                RestServices().rest('POST', param, urlGestioneUdiV2.signUp, undefined, undefined, 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImlhdCI6MTUxNjIzOTAyMn0.VFb0qJ1LRg_4ujbZoRMXnVkUgiuKq5KxWqNdbKq_G9Vvz-S1zZa9LPxtHWKa64zDl2ofkT8F6jBt_K4riU-fPg' )
                    .then(result => {
                        if (result) {
                            //console.log(result.response);
                            if (result.esito) {
                                notify({ position: "center", width: "auto", message: "Registrazione completata con successo, controllare la posta in arrivo" }, "success", 4000)
                                //redirect to login page
                                
                                setLoading(false)
                                setTimeout(() => {
                                    window.open("/app/sfbd/loginUdi", '_self')
                                }, 2000);

                            }
                            else {
                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                setLoading(false)
                            }

                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
            

        //}

    }

    const maskRules = {

        // a regular expression
        H: /[0-9]/,
    }

    function validaEmailConferma(value: string) {
        return new Promise((resolve) => {
            setTimeout(() => {
                setConfermaEmailValidation(value == objctTOsave.email)
                resolve(value == objctTOsave.email);
            }, 1000);
        });
    }

    function validaPassword(value: string) {
        return new Promise((resolve) => {
            setTimeout(() => {

                var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
                setPasswordValidation(re.test(value))
                resolve(re.test(value));
            }, 1000);
        });
    }

    const confermaEmailValidationFunc = (params: { value: any }) => {

        return validaEmailConferma(params.value);
    }

    const passwordValidationFunc = (params: { value: any }) => {
        return validaPassword(params.value);
    }


    return (

        <>
            <Loading isVisible={loading} />
            <Row >
                <Col md="12" style={{ height: 18, background:'#00234f' }}>
                    <nav role="navigation">
                        <Col>



                        </Col>



                    </nav>

                </Col>
            </Row>
            <div className="bodyCgm">
                <div className="cgmInput">

                    <div style={{ marginLeft: 20, marginRight: 20 }}>
                        <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 4,  height: '950px' }} className="form-group">

                            <div className="row" style={{ marginTop: 16 }}>
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <label style={{ fontSize: 20 }}> Dispositivi Medici e Diagnostici in vitro </label>
                                </div>
                                <div className="col">

                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 8 }}>
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <label style={{ fontSize: 18, fontWeight: 500, height: 24, color: '#B7B9BD'}}> Adempimenti per gli operatori sanitari </label>
                                </div>
                                <div className="col">

                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 24 }}>
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <label style={{ fontSize: 18, fontWeight: 500, height: 24, color: '#B7B9BD' }}> Completa la registrazione per ricevere le credenziali </label>
                                </div>
                                <div className="col">

                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 8 }}>
                                <div className="col">

                                </div>

                                <div style={{ gap: 10, background: '#EAECEF', padding: 10, borderRadius: 4, height: '400px' }} className="col">

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox id="ragioneSociale"
                                                    width="580" height="56"
                                                    valueChangeEvent="keyup"
                                                    showClearButton={true}
                                                    onValueChanged={(v) => { cambiaValore("ragioneSociale", v.value) }}
                                                    maxLength='100' placeholder="Ragione sociale *">

                                                    <Validator>
                                                        <RequiredRule message="Ragione sociale obbligatoria" />
                                                        {/*<AsyncRule*/}
                                                        {/*    message="Email is already registered"*/}
                                                        {/*    validationCallback={asyncValidation}*/}
                                                        {/*/>*/}
                                                    </Validator>

                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox id="partitaIva" width="580"
                                                    height="56" valueChangeEvent="keyup"
                                                    showClearButton={true} onValueChanged={(v) => { cambiaValore("partitaIva", v.value) }}
                                                    maxLength='11' placeholder="Partita IVA *">

                                                    <Validator>
                                                        <RequiredRule message="Partita IVA obbligatoria" />
                                                        {/*<AsyncRule*/}
                                                        {/*    message="Email is already registered"*/}
                                                        {/*    validationCallback={asyncValidation}*/}
                                                        {/*/>*/}
                                                    </Validator>

                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox id="indirizzo" width="580" height="56" valueChangeEvent="keyup" showClearButton={true} onValueChanged={(v) => { cambiaValore("indirizzo", v.value) }} maxLength='100' placeholder="Indirizzo" />
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox id="email" width="580" height="56"
                                                    valueChangeEvent="keyup" showClearButton={true}
                                                    onValueChanged={(v) => { cambiaValore("email", v.value) }}
                                                    maxLength='100' placeholder="Email *"
                                                >

                                                    <Validator>
                                                        <RequiredRule message="Email obbligatoria" />
                                                        <EmailRule message="Email non valida" />
                                                        {/*<AsyncRule*/}
                                                        {/*    message="Email is already registered"*/}
                                                        {/*    validationCallback={asyncValidation}*/}
                                                        {/*/>*/}
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox
                                                    id="confermaEmail" width="580" height="56"
                                                    valueChangeEvent="keyup" showClearButton={true}
                                                    onValueChanged={(v) => { cambiaValore("confermaEmail", v.value) }}
                                                    maxLength='100' placeholder="Conferma email *">

                                                    <Validator>
                                                        <RequiredRule message="Email obbligatoria" />
                                                        <EmailRule message="Email non valida" />
                                                        <AsyncRule
                                                            message="Email di conferma differente"
                                                            validationCallback={confermaEmailValidationFunc}
                                                        />
                                                    </Validator>

                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>

                                    {/* 
                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox
                                                    id="password" width="580" height="56"
                                                    valueChangeEvent="keyup" showClearButton={true}
                                                    onValueChanged={(v) => { cambiaValore("password", v.value) }}
                                                    maxLength='100' placeholder="Password *" mode={"password" }>

                                                    <Validator>
                                                        <RequiredRule message="Password obbligatoria" />
                                                        <AsyncRule
                                                            message="La password deve essere lunga 8 con almeno un numero, una maiuscola, una minuscola e un carattere speciale (!,$,#,%)"
                                                            validationCallback={passwordValidationFunc}
                                                        />
                                                    </Validator>

                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>
                                    */ }
                                </div>

                                <div className="col">

                                </div>
                            </div>

                            <div className="row" style={{ marginTop: 8 }}>
                                <div className="col">

                                </div>

                                <div style={{ gap: 10, background: '#EAECEF', padding: 10, borderRadius: 4, height: '230px' }} className="col">
                                    <div className="row">
                                        <div className="col" style={{ display: 'flex', justifyContent: 'left' }}>
                                            <label style={{ fontSize: 18, height: 24, width:580 }}> Trattamento dati personali </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col" style={{ display: 'flex', justifyContent: 'left' }}>
                                            <label style={{ fontSize: 14, fontWeight: 500, height: 24, color: '#B7B9BD' }}> Le informazioni fornite durante la fase di iscrizione saranno elaborate al fine di gestire reclami e per inviare comunicati legati al servizio.
                                                <br />L'elaborazione dei dati sara' eseguita mediante sistemi elettronici.
                                                <br />La compilazione dei campi con dati personali nel modulo e' indispensabile per poter utilizzare il portale per la registrazione dei codici UDI.
                                                <br />L'omissione di tali informazioni preclude l'accesso alla piattaforma.
                                                <br />Per una descrizione completa del trattamento dei dati personali, si rimanda alla seguente documentazione disponibile qui.
                                            </label>
                                        </div>
                                    </div>

                                    <div className="row" style={{marginTop:120} }>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'left' }}>
                                            <CheckBox
                                                id="cbInformativa"
                                                defaultValue={false}
                                                text="Ho preso visione dell'informativa dettagliata sulla privacy."
                                                style={{ fontSize: 14, fontFamily: 'Inter' }}
                                                onValueChanged={(v) => { cambiaValore('cbInformativa', v.value) } }
                                            />
                                        </div>
                                    </div>


                                </div>

                                <div className="col">

                                </div>
                            </div>

                            <div className="row">
                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: 580, display: 'flex', justifyContent: 'right', marginTop:16 }}>
                                        <Button id={isRegistraDisabled ? "btnVerificaCodiceProdottoCgm" : "btnVerificaCodiceProdottoCgmCheck"} onClick={() => {
                                            signUp()
                                        }}
                                            disabled={isRegistraDisabled}>REGISTRA </Button>
                                    </div>

                                </div>
                                <div className="col">

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
            

        </>
    )
}

export default SignupUdi