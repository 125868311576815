import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/esm/Button';
import { useHistory } from 'react-router-dom';
import money from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon.png';
import increase from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon2.png';
import lamp from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon3.png';
import statistic from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon4.png';
import registratore from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon5.png';
import documento from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon6.png';
import chiusura from '../../../src/immagini/QuadraturaDiCassa/QuadraturaIcon7.png';
import performance from '../../../src/immagini/performance.png';
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlCommon } from "../costanti";
import { PopUp_Dettagli } from "./Dialog/PopUp_Dettagli";
import './style.css';



const DashboardRegistratoreDiCassa = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const titolo = TitoloContenuto("Quadratura di cassa Dashboard");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [dashboardInfo, setDashboardInfo] = useState<any[]>([]);
    const [registratoriDiCassa, setRegistratoriDiCassa] = useState<any[]>([]);
    const [documentiEmessi, setDocumentiEmessi] = useState<any[]>([]);
    const [chiusureEffettuate, setChiusureEffettuate] = useState<any[]>([]);
    const [typeData, setTypeData] = useState<number>(0);
    const [dataSourceDialog, setDataSourceDialog] = useState<any[]>([]);
    const [numeroRegistratoriTotaliUsatiCurrent, setNumeroRegistratoriTotaliUsatiCurrent] = useState<number>(0);
    const [numeroChiusureTotaliCurrent, setNumeroChiusureTotaliCurrent] = useState<number>(0);
    const [numeroDocumentiTotaliEmessiCurrent, setNumeroDocumentiTotaliEmessiCurrent] = useState<number>(0);
    const [idFarList, setIdFarList] = useState<any[]>([]);
    const [isDialog, setIsDialog] = useState(false);

    const today = new Date();
    const lastMonth = new Date();
    lastMonth.setMonth(today.getMonth() - 1);

    const history = useHistory();


    const auth = useAuth(urlCommon.auth);

    const farmaList = () => {

        const idFarList: any[] = [];

        if (props.datiFarmacie) {
            for (let i = 0; i < props.datiFarmacie.length; i++) {

                const idFar = props.datiFarmacie[i].ID_FAR;

                idFarList.push(idFar);

            }

            setIdFarList(idFarList);
        }
    }

    const getDashboardInfo = () => {

        let model = {
            listFar: idFarList,
        }

        setIsVisible(true);

        if (auth) {
            RestServices().rest('POST', model, urlCommon.getDashboardInfo)
                .then(result => {

                    if (result) {
                        setDashboardInfo(result.response.info);
                        setRegistratoriDiCassa(result.response.registratoreDiCassaList);
                        setDocumentiEmessi(result.response.documentiEmessiList);
                        setChiusureEffettuate(result.response.chiusureEffettuateList);
                        setIsVisible(false);

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })
        }
    }

    const vaiRegistratoreDiCassa = () => {
        history.push('/RegistratoreDiCassa/ElencoRegistratoreDiCassa');
    };

    const openDialog = (dataSource: any[], typeData: number) => {

        if (dataSource && dataSource.length > 0) {

            setTypeData(typeData);
            setDataSourceDialog(dataSource);
            setIsDialog(true);

        } else {

            notify({ position: "center", width: "auto", message: "NESSUN DATO DA MOSTRARE." }, "error", 4000)

        }
    };

    useEffect(() => {

        farmaList();
        getDashboardInfo();

    }, [auth]);


    useEffect(() => {

        const numeroRegistratoriTotaliUsatiCurrent = dashboardInfo.reduce((acc, item) => acc + (item.numeroRegUsatiCurrent || 0), 0);
        const numeroChiusureTotaliCurrent = dashboardInfo.reduce((acc, item) => acc + (item.numeroChiusureCurrent || 0), 0);
        const numeroDocumentiTotaliEmessiCurrent = dashboardInfo.reduce((acc, item) => acc + (item.numeroDocumentiEmessiCurrent || 0), 0);

        setNumeroRegistratoriTotaliUsatiCurrent(numeroRegistratoriTotaliUsatiCurrent);
        setNumeroChiusureTotaliCurrent(numeroChiusureTotaliCurrent);
        setNumeroDocumentiTotaliEmessiCurrent(numeroDocumentiTotaliEmessiCurrent);

    }, [dashboardInfo]);



    return (
        <>
            <br></br>
            {titolo}
            <br></br>
            {loadPanel}

            <PopUp_Dettagli
                isDialog={isDialog}
                setIsDialog={setIsDialog}
                datiFarmacie={props.datiFarmacie}
                typeData={typeData}
                dataSource={dataSourceDialog}
            />

            <section style={{ background: '#ffffff', padding: 25, borderRadius: 10, paddingBottom: '10%' }}>

                <Container fluid className="d-flex align-items-center justify-content-center" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>

                    <Row className="justify-content-center">

                        <Col xs={12} sm={10} md={8} lg={6}>

                            <Card style={{ width: '700px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', borderRadius: '10px', transition: 'transform 0.2s', overflow: 'hidden' }}

                                onMouseEnter={(e: any) => (e.currentTarget.style.transform = 'scale(1.17)')}
                                onMouseLeave={(e: any) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <Card.Header as="h5" style={{ backgroundColor: '#007bff', color: '#fff' }}>
                                    <strong>Quadratura Di Cassa</strong>
                                </Card.Header>

                                <Card.Body>

                                    <Card.Title>
                                        <span style={{ color: 'red' }}><strong>Riepilogo {new Date().getFullYear()}</strong></span>
                                    </Card.Title>

                                    <Card.Text>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div>
                                                <h6>
                                                    <strong>FARMACIE:</strong> {idFarList.length}
                                                    <br />
                                                    <strong>REGISTRATORI USATI NEL {new Date().getFullYear()}:</strong> {numeroRegistratoriTotaliUsatiCurrent}
                                                    <br />
                                                    <strong>CHIUSURE EFFETTUATE NEL {new Date().getFullYear()}:</strong> {numeroChiusureTotaliCurrent}
                                                    <br />
                                                    <strong>DOCUMENTI EMESSI NEL {new Date().getFullYear()}:</strong> {numeroDocumentiTotaliEmessiCurrent}
                                                    <br />
                                                </h6>
                                            </div>
                                            <img style={{ marginLeft: 'auto' }} src={increase} alt="prova" width="20%" />
                                            <img style={{ marginLeft: 'auto' }} src={money} alt="prova" width="20%" />
                                        </div>
                                    </Card.Text>

                                    <Button type="button" variant="dark" onClick={() => { vaiRegistratoreDiCassa(); }}>
                                        Accedi
                                    </Button>

                                    <hr />

                                    <div>
                                        {idFarList.length === 1 ? (
                                            <p>Confronta giorno per giorno l'andamento della tua farmacia...</p>
                                        ) : idFarList.length > 1 ? (
                                            <p>Confronta giorno per giorno le chiusure delle tue farmacie...</p>
                                        ) : (
                                            <p>Nessuna farmacia disponibile per il confronto.</p>
                                        )}
                                    </div>

                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    Data odierna: {new Date().toLocaleDateString()}
                                </Card.Footer>

                            </Card>

                        </Col>

                        <Col xs={12} sm={10} md={8} lg={6}>

                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginRight: '15%' }}>
                                <img src={performance} alt="prova2" style={{ width: '20%' }} />
                                <img src={statistic} alt="prova" style={{ width: '100%', }} />
                            </div>

                        </Col>

                    </Row>

                </Container>

                <hr style={{ borderTop: '1px solid #e0e0e0', width: '90%', margin: '30px auto' }} />

                <Container fluid className="d-flex flex-column align-items-center justify-content-center" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>

                    <Row className="justify-content-center mb-4">

                        <Col xs={12} className="text-center">
                            <h1 className="fancy-title"><strong>Visualizza piu' dettagli</strong></h1>
                        </Col>
                    </Row>

                    <Row className="justify-content-center mb-4">

                        <Col xs={12} sm={10} md={8} lg={6}>

                            <Card style={{ width: '700px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', borderRadius: '10px', transition: 'transform 0.2s', overflow: 'hidden' }}

                                onMouseEnter={(e: any) => (e.currentTarget.style.transform = 'scale(1.10)')}
                                onMouseLeave={(e: any) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <Card.Header as="h5" style={{ backgroundColor: '#5ab267', color: '#fff' }}>
                                    <strong> Registratori di Cassa</strong>
                                </Card.Header>

                                <Card.Body>

                                    <Card.Title style={{ marginBottom: '5%' }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={registratore} alt="prova" width="20%" />
                                        </div>
                                    </Card.Title>

                                    <Card.Text>
                                        <h6><strong>Carica le info relative ai Registratori di Cassa</strong></h6>
                                    </Card.Text>

                                    <Button type="button" variant="dark" onClick={() => { openDialog(registratoriDiCassa, 1); }}>
                                        Visualizza
                                    </Button>

                                    <hr />

                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <strong>REGISTRATORI PRESENTI:</strong> {numeroRegistratoriTotaliUsatiCurrent}
                                </Card.Footer>

                            </Card>

                        </Col>

                        <Col xs={12} sm={10} md={8} lg={6}>

                            <Card style={{ width: '700px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', borderRadius: '10px', transition: 'transform 0.2s', overflow: 'hidden' }}

                                onMouseEnter={(e: any) => (e.currentTarget.style.transform = 'scale(1.10)')}
                                onMouseLeave={(e: any) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <Card.Header as="h5" style={{ backgroundColor: '#ffbe40', color: '#fff' }}>
                                    <strong> Documenti Emessi</strong>
                                </Card.Header>

                                <Card.Body>

                                    <Card.Title style={{ marginBottom: '5%' }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={documento} alt="prova" width="20%" />
                                        </div>
                                    </Card.Title>

                                    <Card.Text>
                                        <h6><strong>Carica le info relative ai Documenti Emessi</strong></h6>
                                    </Card.Text>

                                    <Button type="button" variant="dark" onClick={() => { openDialog(documentiEmessi, 2); }}>
                                        Visualizza
                                    </Button>

                                    <hr />

                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <strong>DOCUMENTI EMESSI NEL PERIODO {lastMonth.toLocaleDateString()} | {today.toLocaleDateString()}</strong>
                                </Card.Footer>

                            </Card>

                        </Col>


                    </Row>

                    <Row className="justify-content-center">

                        <Col xs={12} sm={10} md={8} lg={6} className="d-flex justify-content-center">

                            <Card className="mx-auto" style={{ width: '1400px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', borderRadius: '10px', transition: 'transform 0.2s', overflow: 'hidden' }}

                                onMouseEnter={(e: any) => (e.currentTarget.style.transform = 'scale(1.10)')}
                                onMouseLeave={(e: any) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <Card.Header as="h5" style={{ backgroundColor: '#9129e3', color: '#fff' }}>
                                    <strong> Chiusure di Cassa</strong>
                                </Card.Header>

                                <Card.Body>

                                    <Card.Title style={{ marginBottom: '5%' }}>
                                        <div className="d-flex justify-content-center align-items-center">
                                            <img src={chiusura} alt="prova" width="20%" />
                                        </div>
                                    </Card.Title>

                                    <Card.Text>
                                        <h6><strong>Carica le info relative alle Chiusure di Cassa</strong></h6>
                                    </Card.Text>

                                    <Button type="button" variant="dark" onClick={() => { openDialog(chiusureEffettuate, 3); }}>
                                        Visualizza
                                    </Button>

                                    <hr />

                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    <strong>CHIUSURE EFFETTUATE NEL PERIODO {lastMonth.toLocaleDateString()} | {today.toLocaleDateString()}</strong>
                                </Card.Footer>

                            </Card>

                        </Col>

                    </Row>

                </Container>

                <hr style={{ borderTop: '1px solid #e0e0e0', width: '90%', margin: '30px auto' }} />

                <Container fluid className="d-flex flex-column align-items-center justify-content-center" style={{ padding: '20px', backgroundColor: '#f8f9fa' }}>

                    <Row className="justify-content-center mb-4">

                        <Col xs={12} className="text-center">
                            <h1 className="fancy-title"><strong>Curiosita'</strong></h1>
                        </Col>

                    </Row>

                    <Row className="justify-content-center">

                        <Col xs={12} sm={10} md={8} lg={6}>

                            <Card style={{ width: '100%', maxWidth: '1100px', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)', borderRadius: '10px', transition: 'transform 0.2s', overflow: 'hidden' }}

                                onMouseEnter={(e: any) => (e.currentTarget.style.transform = 'scale(1.10)')}
                                onMouseLeave={(e: any) => (e.currentTarget.style.transform = 'scale(1)')}
                            >
                                <Card.Header as="h5" style={{ backgroundColor: '#ff5050', color: '#fff' }}>
                                    <strong>Saperne di piu'...</strong>
                                </Card.Header>

                                <Card.Body>

                                    <Card.Title>
                                        <img src={lamp} alt="prova" width="20%" />
                                    </Card.Title>

                                    <Card.Text>
                                        <h6>
                                            La quadratura di cassa ha come scopo quello di confrontare le vendite registrate dal registratore di cassa con quelle annotate nel gestionale Sistema F Platinum,
                                            al fine di identificare e correggere eventuali discrepanze.
                                            I dati vengono importati automaticamente da entrambe le fonti, consentendo un confronto fra le transazioni per individuare anomalie,
                                            segnalare tempestivamente discrepanze, generare report dettagliati e mantenere uno storico delle quadrature.
                                            Queste funzionalita' assicurano una gestione accurata delle vendite e l'affidabilita' delle informazioni.
                                        </h6>
                                    </Card.Text>

                                    <hr />

                                </Card.Body>

                                <Card.Footer className="text-muted">
                                    Data odierna: {new Date().toLocaleDateString()}
                                </Card.Footer>

                            </Card>

                        </Col>

                    </Row>

                </Container>

            </section >

        </>

    );

};

export default DashboardRegistratoreDiCassa;