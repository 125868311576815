import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { urlB2B, urlCommon } from '../costanti'
import { Loading } from '../../widget/Notifications'
import { RestServices } from '../../services/restServices'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle'
import Button from 'react-bootstrap/Button'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TextBox from "devextreme-react/text-box"
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { cambiaFormatoLocalDateTimeToEuropeo } from '../../funzioni/formatoVariabili'

const SingoloOrdineB2B = (props: {
    idOrdine: number | undefined,
    statoOrdineAggiornato: any,
    auth: boolean | undefined
}) => {


    const [loading, setLoading] = useState<boolean>(false)
    const [dettagliOrdineB2B, setDettagliOrdineB2B] = useState<any[]>([]);
    const [listFarmacie, setListFarmacie] = useState<any[]>([])
    const [open, setOpen] = useState<boolean>(false)
    const [disableButton, setDisableButton] = useState<boolean>(false)
    const [testataOrdineB2B, setTestataOrdineB2B] = useState<any>();
    const [elencoFarmacie, setElencoFarmacie] = useState<string>("");
    const [columns, setColumns]= useState<any[]>([])
    //const [columnsDettaglioOrdLib, setColumnsDettaglioOrdLib] = useState<any>();

    useEffect(() => {
        if (props.auth) {
            if (props.idOrdine)
                getOrdineB2B();
        }
        

    }, [props.auth, props.idOrdine])

    useEffect(() => {

        if (testataOrdineB2B) {
            let testataCopy = { ...testataOrdineB2B }
            testataCopy.stato = props.statoOrdineAggiornato
            setTestataOrdineB2B(testataCopy)
        }

    }, [props.statoOrdineAggiornato])

    const getOrdineB2B = () => {
        setLoading(true);
        let params = { id: props.idOrdine }
        console.log(params)
        RestServices().rest('POST', params, urlB2B.getOrdineB2B)
            .then(result => {
                if (result?.esito) {
                    setLoading(false);
                    setDettagliOrdineB2B(result.response.listProdotti);
                    setListFarmacie(result.response.listFarmacie);
                    setTestataOrdineB2B(result.response.testata)
                    
                }
            })


    }

    useEffect(() => {

        if (listFarmacie) {
            let totalColumns: any [] = []

            let primeColonne = [

                { caption: "Descrizione", dataField: "prodotto", allowEditing: false, width: 400 },
                { caption: "Cod.Prod.", dataField: "codProd", allowEditing: false, alignment: "center", width: 120 },
                { caption: "EAN", dataField: "ean", allowEditing: false, alignment: "center", width: 120 },
                { caption: "Prz BD", dataField: "prezzo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: true, alignment: "center", width: 65 },
                { caption: "IVA(%)", dataField: "iva", allowEditing: false, alignment: "center", format: { currency: "EUR" }, width: 60 },
                { caption: "Sc(%)", dataField: "sconto", format: { currency: "EUR" }, allowEditing: true, alignment: "center", width: 55 },
                { caption: "Costo", dataField: "costo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: true, alignment: "center", width: 65 },
/*                { caption: "Giac.Tot.", dataField: "giacenzaTotale", allowEditing: false, alignment: "center", width: 60 },*/
                

            ]
            totalColumns = primeColonne
            const colonneQta: string[] = [];
            for (let i = 0; i < listFarmacie.length; i++) {
                const idFar = listFarmacie[i].idFar;
                let codice = listFarmacie[i].codiceFar;
                let descrizione = listFarmacie[i].descrizioneFar;

                colonneQta.push(`${idFar}`);
                totalColumns.push({
                    caption: `Q.${codice}`,
                    dataField: `farQta.${idFar}`,
                    allowEditing: true,
                    dataType: "number",
                    alignment: "center",
                    width: 80
                })
                //colonneQta.push(`${idFar}`);
                //totalColumns.push({
                //    caption: `Q_O.${codice}`,
                //    dataField: `farQtaOmaggio.${idFar}`,
                //    allowEditing: true,
                //    dataType: "number",
                //    alignment: "center",
                //    width: 70
                //})

            }

            totalColumns.push({ caption: "Qta.Tot.", dataField: "qtaTotale", allowEditing: false, alignment: "center" });
            totalColumns.push({ caption: "Qta.Tot.Om.", dataField: "qtaTotaleOmaggio", allowEditing: false, alignment: "center", visible: true });
            
            //totalColumns.push({ caption: "U.Sconto", dataField: "ultimoSconto", allowEditing: false, alignment: "center", format: { currency: "EUR" }, width: 60 });
            //totalColumns.push({ caption: "U.Costo", dataField: "ultimoCosto", allowEditing: false, alignment: "center", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, width: 60 });
            


            setColumns(totalColumns)
        }

    },[listFarmacie])

    const tagRicercha = () => {
        let tagArr = []
        if (testataOrdineB2B) {
            for (const [key, value] of Object.entries(testataOrdineB2B)) {

                if (key == 'stato') {
                    tagArr.push({ id: 2, value: testataOrdineB2B.stato })
                }

                if (key == 'titolo') {
                    tagArr.push({ id: 1, value: testataOrdineB2B.titolo })
                }

                if (key == 'data') {
                    tagArr.push({ id: 3, value: cambiaFormatoLocalDateTimeToEuropeo(testataOrdineB2B.data) })
                }

                //if (key == 'sconto') {
                //    tagArr.push(testataOrdineB2B.sconto)
                //}

                //if (key == 'elencoFarmacie') {
                //    tagArr.push(testataOrdineB2B.elencoFarmacie)
                //}

                

            }

            tagArr.sort((a: any, b: any) => {
                if (a.id < b.id) {
                    return -1;
                }
                if (a.id > b.id) {
                    return 1;
                }
                return 0;
            });

        }


        if (open) {
            return null
        } else {
            return (
                tagArr.map((tag, index) => {
                    return (
                        <div style={{ marginRight: 10, display: 'inline-block', background: ' #dfdfdf', padding: 5, paddingLeft: 10, paddingRight: 10, borderRadius: 5 }} key={'tag' + index}>
                            <p style={{ fontSize: 13, margin: 0 }}>{tag.value}</p>
                        </div>
                    )
                })

            )
        }
    }

    return (
        <>
            <Loading isVisible={loading} />
            <div style={{ width: '250' }}>
                <div style={{ background: 'white', padding: 10, borderRadius: 10, }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div><h4 style={{ fontSize: 22, margin: 0 }}>Dettaglio ordine: {tagRicercha()}</h4></div>
                        <div><button style={{ border: 'none', background: 'none', fontSize: 20 }} onClick={() => setOpen(!open)}>{open ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}</button></div>

                    </div>

                    <div className={open ? 'main' : 'mainOut'}>
                        <Row>
                            <div className="form-group col-md-3">
                                <label> TITOLO</label>
                                <TextBox maxLength={100} disabled={true} value={testataOrdineB2B?.titolo} placeholder="..." />
                            </div>
                            <div className="form-group col-md-3">
                                <label> DATA</label>
                                <TextBox maxLength={100} disabled={true} value={cambiaFormatoLocalDateTimeToEuropeo(testataOrdineB2B?.data)} placeholder="..." />
                            </div>
                            <div className="form-group col-md-3">
                                <label> STATO</label>
                                <TextBox maxLength={100} disabled={true} value={testataOrdineB2B?.stato} placeholder="..." />
                            </div>
                            <div className="form-group col-md-3">
                                <label> SCONTO</label>
                                <TextBox maxLength={100} disabled={true} value={testataOrdineB2B?.sconto} placeholder="..." />
                            </div>    
                        </Row>
                        <Row>
                            <div className="form-group col-md-12">
                                <label> ELENCO FARMACIE</label>
                                <TextBox maxLength={100} disabled={true} value={testataOrdineB2B?.elencoFarmacie} placeholder="..." />
                            </div>
                        </Row>
                    </div>
                </div>
            </div>

            <NewTabella
                idTabella='dett_ord_b2b'
                sync
                colonne={columns}
                dataSource={dettagliOrdineB2B}
                filterRow
                searchPanel={false}
                onclickRow={() => null}
                exportT={{
                    enabled: true,
                    fileName: "dett_ord_b2b"
                }}
                activeSaver={true}
                columnC={true}
            />
        </>
    )
}

export default SingoloOrdineB2B