import React, { useEffect, useRef, useState, useCallback } from "react";
import SelectBox from "devextreme-react/select-box";

export enum typeShow {
  euro,
  percento,
}

export interface cardC {
  testo?: string;
  subTesto?: string;
  valore?: string;
  subValore?: string;
  testoPop?: string;
  second?: boolean;
  starter?: boolean;
  typeShowValue?: typeShow;
  typeShowSubValue?: typeShow;
}

export const MenuCruscotto = ({}) => {

  const selectBoxOptions = [
    {
      id: 1,
      text: "Cruscotto andamento farmacia",
      url: "/app/businesslab/evolution/CruscottoDirezionale",
    },
    {
      id: 2,
      text: "Cruscotto andamento gruppo",
      url: "/app/businesslab/evolution/CruscottoGruppo",
    },
    {
      id: 3,
      text: "Analisi Vendite",
      url: "/app/businesslab/evolution/AnalisiVendite",
    },
    {
      id: 4,
      text: "Analisi performance per operatore",
      url: "/app/businesslab/evolution/AnalisiPerformanceOperatore",
    },
    {
      id: 5,
      text: "Analisi Sconti Vendite per Category (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiScontiCategory",
    },
    {
      id: 6,
      text: "Analisi Sconti Vendite per Gruppo Merceologico (Banca Dati CSF)",
      url: "/app/businesslab/evolution/AnalisiScontiGruppoMerceologico",
    },
    {
      id: 7,
      text: "Analisi Ricette",
      url: "/app/businesslab/evolution/AnalisiRicette",
    },
    {
      id: 8,
      text: "Analisi Trasferimenti",
      url: "/app/businesslab/evolution/AnalisiTrasferimenti",
    },
    // {
    //   id: 9,
    //   text: "Analisi Contenitori - Disponibile solo per i Clienti Sistema Omnia",
    //   url: "/app/businesslab/evolution/AnalisiContenitori",
    // },
  ];

  const [value,setValue] = useState<number | null>()

  useEffect(() => {
    // Recupera l'URL corrente
    const currentUrl = new URL(window.location.href);
    var pathname = currentUrl.pathname;
    
    const pathSegments = currentUrl.pathname.split('/');
    const cruscottoDirezionale = pathSegments[pathSegments.length - 1];

    //alert(pathname)

    const index = selectBoxOptions.findIndex(option => option.url === pathname);

    
    if(index+1){
      setValue(index+1)
    }

    // Puoi ora gestire l'URL come desideri
  });


 

  const defaultValue = selectBoxOptions[1];

  const handleDropdownChange = (e: any) => {
    const urlToUse = selectBoxOptions[e.value-1].url
    setValue(e.value)
   window.open(urlToUse, "_self");
  };
  return (
    <div style={{ marginLeft: "-5px" }}>
      <label> Selezionare il cruscotto direzionale: </label>
      <div style={{ fontSize: "14px" }}>
        <SelectBox
          dataSource={selectBoxOptions}
          displayExpr="text"
          valueExpr="id"
          value={value}
          onValueChanged={handleDropdownChange}
        />
      </div>
    </div>
  );
};
