import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import {DialogCluster} from "./dialog/popup_gest_cluster"
import {DialogListFarm} from "./dialog/popup_list_farm"
import {DialogConferma,  Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'

import './style.css';


const Gestcluster = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    
    const [ openPop , setOpenPop ] = useState<boolean>(false)
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [popDel,setPopDel] = useState<boolean>(false)
    const [idDel,setIddell] = useState<number>(0)
    const [messDel,setMessDel] = useState<string>('')
    
    const [openListF, setOpenListF] = useState<boolean>(false)


    const columns = [
        { caption: "Codice", dataField: "codice" },
        { caption: "Nome", dataField: "descrizione"  },
        { caption: "Descrizione", dataField: "descrizioneEstesa" },
        { caption: "Tipologia", dataField: "idDesc" },
        { caption: "Numero Farmacie", dataField: "numeroFarmacie",width:"80" },
        { caption: "Azioni",    type: "buttons", buttons: [
            {
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {
                   
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setOpenPop(true)
                    
                }
            },
            {
                hint: "Visualizza",
                icon: "search",
                onClick: (e: any) => {
                  
                    setObjtipo(e.row.data)
                    setOpenListF(true)
                    
                }
            },
            {
                hint: "Cancella",
                icon: "trash",
                onClick: (e: any) => {
                    
                    setIddell(e.row.data.id)
                    setMessDel("il cluster "+e.row.data.codice+" Sta per essere eliminato. Confermare?")
                    setPopDel(true)
                    
                }
            }]  },
    ];
    

    


    const remove = () =>{
        if (auth) {
            RestServices().rest('POST', { idCluster : idDel} , urlConsultazione.deleteCluster)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       carivaIniziale()
                   }
                   else{
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    setLoading(false)
                }
        
                })
        
    } 
    }

    


      
        const getClusterListAs = () =>{
            if (auth) {
                return  RestServices().rest('POST', {} , urlConsultazione.getListCluster)
                    
                      }
                }

        const getClusterTipiList = () =>{
            setLoading(true)
            if (auth) {
                return RestServices().rest('POST', {} , urlConsultazione.getListTipologiaCluster)
                    
                      }
                }
        
  


const ricarica =  () =>{
    setOpenPop(false)
    setImmediate(false);
    setObjtipo(null)
    carivaIniziale()
}

    

    const carivaIniziale = async() =>{
        let tipiClister = await getClusterTipiList()
        if (tipiClister?.esito) { 
             if(tipiClister){
            let listaTabella = await getClusterListAs()
            let tipologieArr = tipiClister.response
            if (listaTabella?.esito) { 
                let tabellaNew:any = []
                listaTabella.response.map((value:any)=>{
                    let objValue = {...value}
                    let index = tipologieArr.findIndex((objValue:any)=> {
                        return objValue.id === value.idClusterTipo ;
                      })
                    if(index > -1){
                      objValue.idDesc =  tipologieArr[index].codice+' - '+tipologieArr[index].descrizione 
                    }  
                    tabellaNew.push(objValue)

                
                })
                setTabellaPrincipale(tabellaNew)
                setLoading(false)
            }else{
                notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)
                setLoading(false)
            }
        }
        }else{
            if(tipiClister?.message){
                notify({ position: "center", width: "auto", message: tipiClister.message}, "error", 4000)
                setLoading(false)
            }
            
        }

    }

    useEffect(()=>{
        carivaIniziale()
        
    },[auth])
    
    useEffect(() => {
       if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

 
   
    return (
        <>

        <DialogConferma
            dialogIsOpen={popDel}
            message={messDel}
            functionNext={()=>remove()}
            handleCloseConferma={()=>{setPopDel(false);setIddell(0)}}
        />
        <DialogCluster typeEdit={edit} edit={objtipo} ricarica={()=>{ricarica();setImmediate(false);setObjtipo(null)}} close={()=>{setOpenPop(false);setImmediate(false);setObjtipo(null)}} open={openPop} />
        <DialogListFarm farmacie={objtipo} edit={objtipo} close={()=>setOpenListF(false)} ricarica={()=>{ricarica();setImmediate(false);setObjtipo(null)}}  open={openListF}/>
      
        
        <Loading isVisible={loading} />
        <div  className='title_cluster'>
                <h3> GESTIONE CLUSTER  </h3>
             </div>
        
             <div className='button_cluster'>
               <Button   variant="primary" onClick={()=>setOpenPop(true)} >
               NUOVO CLUSTER
           </Button>
        
            <Link to="/cluster/tipologia" target="_blank">
                <Button className='tipologia'   variant="primary" >
                    ELENCO TIPOLOGIE
                </Button>
            </Link>
            </div>
              
             
            <NewTabella 
                idTabella='elenco_gest_cluster'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "ele_gest_cluster"
                }}
                activeSaver={true}
                columnC={true}
            />
        </>
    )
}

export default Gestcluster