import React, { useEffect, useRef, useState } from "react"
import { Loading } from "../../widget/Notifications"
import { Accordion, TextBox } from "devextreme-react";
import CustomTitle from "./CustomTitle";
import CustomItem from "./CustomItem";
import Button from 'react-bootstrap/Button'
import { urlCommon, urlConsultazione, urlGestioneUdi } from "../costanti";
import { useAuth } from "../../hooks/useAuth";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import DateBox from 'devextreme-react/date-box'
import Select from 'react-select';
import { convertForSelectTipoMovimentoRegistrazioneUdi } from "../../funzioni/formatoVariabili";
import { confirm } from 'devextreme/ui/dialog';
import { SelectBox } from 'devextreme-react/select-box'
interface PageProps {

    toggleMenu(stato: boolean): any
    statoMenu: boolean
}

const RegistrazioneUdi = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlGestioneUdi.auth);
    const auth2 = useAuth(urlCommon.auth);

    const [loading, setLoading] = useState<boolean>(true)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [daRegistrare, setDaRegistrare] = useState<boolean>(false)
    const [farmacie, setFarmacie] = useState<any>([])
    const [farmacieSelezionate, setFarmacieSelezionate] = useState<any[]>()
    const [codCsf, setCodCsf] = useState<number>()
    const [tipoMovimento, setTipoMovimento] = useState<any[]>()
    const [disableConferma, setDisableConferma] = useState<boolean>(true)
    const [infoProdotto, setInfoProdotto] = useState<string>()
    const [infoRegistra, setInfoRegistra] = useState<string>()
    const [selectedFarm, setSelectFarm] = useState(null)

    const [codiceProdotto, setCodiceProdotto] = useState("")
    const [codiceUdi, setCodiceUdi] = useState("")
    const [data, setData] = useState<any>()
    const [tipoMovimentoS, setTipoMovimentoS] = useState("")
    const [farmaciaS, setFarmaciaS] = useState("")

    const now = new Date();

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
        setData(now)
        tipoMovimentoList()
    }, []);


    const infos = [{
        'ID': 1,
        'InfoName': 'Informazioni',
        'Testo': '',
    }]

    useEffect(() => {
        if (auth && auth2) {
            setLoading(false)
            farmaList()
            
            objctTOsave.data = now
            
            
            
        }
    }, [auth, auth2])



    const cambiaValore = (tipo: string, value: string, tipoControllo?: string) => {
        let objTo = { ...objctTOsave }

        if (tipoControllo === 'Select') {
            //console.log(value)
            //value � un array
            let myArray = value as unknown as Array<any>;
            let ids: any[] = [];
            myArray.forEach((element: any) => {
                ids.push(element.value)

            });
            if (tipo === 'idFarmacie') {
                setFarmacieSelezionate(myArray)
            }

            objTo[tipo] = ids;

        } else if (tipoControllo === 'SelectSingle') {
            let v: any = value as unknown as any
            objTo[tipo] = v.value;
        } else {
            objTo[tipo] = value
        }

        setObjctTOsave(objTo)

        if (objTo.codiceUdi == undefined || codCsf == undefined || objTo.idFarmacia == undefined || objTo.data == undefined || objTo.tipoMovimento == undefined)
            setDisableConferma(true)
        else
            setDisableConferma(false)
    }

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFarSystem)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setFarmacie(result.response)
                        if (result.response.length === 1) {
                            setSelectFarm(result.response[0].idFar)
                            cambiaValore("idFarmacia", result.response[0].idFar)
                        }
                            
                    } else {

                    }
                })
        }
    }

    const tipoMovimentoList = () => {

        let array = []

        array.push({ id: 1, descrizione: 'ACQUISIZIONE' })
        array.push({ id: 2, descrizione: 'CESSIONE' })

        setTipoMovimento(array)

    }

    const salvaRegistrazione = () => {


        let rc = confirm("<i>Registrare il movimento per il prodotto " + objctTOsave.codiceProdotto + "  ?</i > ", "Attenzione");
        rc.then((dialogResult) => {
            if (dialogResult) {

                if (auth) {

                    //console.log(objctTOsave)
                    //console.log(tipoMovimento)
                    //console.log(objctTOsave.tipoMovimento.value)

                    let params = {
                        codCsf: codCsf,
                        idFarmacia: objctTOsave.idFarmacia,
                        codiceUdi: objctTOsave.codiceUdi,
                        data: objctTOsave.data,
                        tipoMovimento: tipoMovimento?.filter((item) => {
                            return item.id === objctTOsave.tipoMovimento
                        })[0].descrizione
                    }

                    setLoading(true)
                    RestServices().rest('POST', params, urlGestioneUdi.salvaRegistrazione)
                        .then(result => {
                            if (result?.esito) {
                                if (result.response) {
                                    setLoading(false)
                                    setDaRegistrare(result.response)
                                    if (result.response.esito === true) {
                                        notify({ position: "center", width: "auto", message: "Movimento registrato con successo" }, "success", 4000)

                                        let obj = { ...objctTOsave }
                                        if (farmacie.length > 1)
                                            obj.idFarmacia = undefined
                                        obj.codiceProdotto = undefined
                                        obj.codiceUdi = undefined
                                        obj.data = now
                                        obj.tipoMovimento = undefined

                                        setObjctTOsave(obj)

                                        setDaRegistrare(false)
                                        setInfoProdotto("")
                                        setInfoRegistra("")
                                        setDisableConferma(true)

                                        setCodiceProdotto("")
                                        setCodiceUdi("")
                                        setData(new Date())
                                        if (farmacie.length > 1)
                                            if (farmacie.length > 1) {
                                                setFarmaciaS("")
                                                setSelectFarm(null)
                                            }
                                        setTipoMovimentoS("")


                                    }
                                        
                                    else
                                        notify({ position: "center", width: "auto", message: "Movimento non registrato" }, "error", 4000)
                                }

                            } else {
                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                setLoading(false)
                            }
                        })

                }

            } else {
                return
            }
        });

        

    }

    const verificaCodiceProdotto = () => {

        
        if (auth) {

            if (objctTOsave.codiceProdotto == undefined || (objctTOsave.codiceProdotto.length == 0 || objctTOsave.codiceProdotto.length < 9 || objctTOsave.codiceProdotto.length > 13 || (objctTOsave.codiceProdotto.length > 9 && objctTOsave.codiceProdotto.length < 13))) {
                notify({ position: "center", width: "auto", message: "Inserire un codice ministeriale o ean valido" }, "error", 4000)
                setDaRegistrare(false)
                setDisableConferma(true)
                setInfoProdotto("")
                setInfoRegistra("")
                setDaRegistrare(false)
                return;
            }

            setLoading(true)
            let params = { codProd: objctTOsave.codiceProdotto }
            RestServices().rest('POST', params, urlGestioneUdi.verificaProdotto)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setDaRegistrare(result.response.registrare)
                            setCodCsf(result.response.codCsf)
                            setInfoProdotto(result.response.infoProdotto)
                            setInfoRegistra(result.response.infoRegistra)
                            //if (result.response.registrare === true)
                            //    //notify({ position: "center", width: "auto", message: "Per il prodotto e' obbligatoria la registrazione del movimento" }, "success", 4000)
                            //else
                            //    //notify({ position: "center", width: "auto", message: "Per il prodotto non e' richiesta la registrazione del movimento" }, "error", 4000)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> REGISTRAZIONE CODICE UDI </h3>
            </div>
            <div id="accordion">
                <Accordion
                    dataSource={infos}
                    collapsible={true}
                    multiple={false}
                    animationDuration={100}
                    itemTitleRender={CustomTitle}
                    itemRender={CustomItem}
                />
            </div>

            &nbsp;

            <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div className='form-row' >
                    <div className="form-group col-md-3">
                    </div>
                    <div className="form-group col-md-6">
                        <div className="form-row">
                            <div className="form-group col-md-9">
                                <label> VERIFICA SE IL PRODOTTO NECESSITA DI REGISTRAZIONE </label>
                                <TextBox value={codiceProdotto} valueChangeEvent="keyup blur change focusout" showClearButton={true} onValueChanged={(v) => { cambiaValore('codiceProdotto', v.value); setCodiceProdotto(v.value) }} maxLength='13' placeholder="Inserisci il codice EAN o minsan del prodotto..." />
                            </div>
                            <div className="form-group col-md-3">
                                <Button title="Verifica che il prodotto preveda la registrazione" id="btnVerificaCodiceProdotto" variant="info" onClick={() => { verificaCodiceProdotto() }}><i className="fa fa-check"></i> VERIFICA</Button>            
                            </div>
                        </div>
                        
                    </div>
                    {/*                     <div className="form-group col-md-1" >
                        
                    </div>
                    <div className="form-group col-md-4">
                    </div>
                    */}
                </div>
                
                <div className='form-row' >
                    <div className="form-group col-md-3">
                    </div>
                    <div className="form-group col-md-6">
                        {infoProdotto && <span style={{ fontSize: 15 }} className="badge badge-primary">{infoProdotto}</span>}
                    </div>
                    <div className="form-group col-md-3">
                    </div>
                </div>
                <div className='form-row' >
                    <div className="form-group col-md-3">
                    </div>
                    <div className="form-group col-md-6">
                        {infoRegistra && <span style={{ fontSize: 15 }} className={daRegistrare ? "badge badge-success" : "badge badge-danger"}>{infoRegistra}</span>}
                    </div>
                    <div className="form-group col-md-3">
                    </div>
                </div>
                
            </div>

            <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div className="form-group">
                    <div className='form-row' >
                        <div className="form-group col-md-3">
                        </div>
                        <div className="form-group col-md-6">
                            <label> FARMACIA </label>
                            <div>
                                {/*<Select styles={{*/}
                                {/*    multiValue: (styles, { data }) => {     */}
                                {/*        return {*/}
                                {/*            ...styles,*/}
                                {/*            maxWidth: 300,*/}
                                {/*        };*/}
                                {/*    },*/}
                                {/*}} placeholder="Seleziona..." isDisabled={!daRegistrare} onChange={(v: any) => cambiaValore('idFarmacia', v)} options={convertForSelectFarmacie(farmacie)} />*/}
                                <SelectBox dataSource={farmacie}
                                    value={selectedFarm}
                                    showDataBeforeSearch={true}
                                    valueExpr="idFar"
                                    displayExpr="descrizione"
                                    valueChangeEvent="keyup"
                                    showClearButton={false}
                                    placeholder="Seleziona il punto vendita..."
                                    disabled={!daRegistrare}
                                    onValueChanged={(e: any) => { cambiaValore("idFarmacia", e.value); setSelectFarm(e.value) }}
                                />
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                        </div>
                    </div>
                    <div className='form-row' >
                        <div className="form-group col-md-3">
                        </div>
                        <div className="form-group col-md-6">
                            <label> CODICE UDI </label>
                            <TextBox value={codiceUdi} disabled={!daRegistrare} valueChangeEvent="keyup" showClearButton={true} onValueChanged={(v) => { cambiaValore('codiceUdi', v.value); setCodiceUdi(v.value) }} maxLength='100' placeholder="Inserisci codice UDI" />
                        </div>
                        <div className="form-group col-md-3">
                        </div>
                    </div>
                    <div className='form-row' >
                        <div className="form-group col-md-3">
                        </div>
                        <div className="form-group col-md-6">
                            <label> DATA MOVIMENTO </label>
                            <DateBox value={data} disabled={!daRegistrare} valueChangeEvent="keyup" showClearButton={true} onValueChanged={(v) => { cambiaValore('data', v.value); setData(v.value) }} type="date" displayFormat="dd/MM/yyyy" />
                        </div>
                        <div className="form-group col-md-3">
                        </div>
                    </div>
                    <div className='form-row' >
                        <div className="form-group col-md-3">
                        </div>
                        <div className="form-group col-md-6">
                            <label> TIPO MOVIMENTO </label>
                            <div>
                                {/*<Select styles={{*/}
                                {/*    multiValue: (styles, { data }) => {*/}
                                {/*        //console.log(data);         */}
                                {/*        return {*/}
                                {/*            ...styles,*/}
                                {/*            maxWidth: 300,*/}
                                {/*        };*/}
                                {/*    },*/}
                                {/*}} value={tipoMovimentoS} placeholder="Seleziona..." isDisabled={!daRegistrare} onChange={(v: any) => { cambiaValore('tipoMovimento', v); setTipoMovimentoS(v) }} options={tipoMovimento && convertForSelectTipoMovimentoRegistrazioneUdi(tipoMovimento)} />*/}

                                <SelectBox dataSource={tipoMovimento}
                                    value={tipoMovimentoS}
                                    showDataBeforeSearch={true}
                                    valueExpr="id"
                                    displayExpr="descrizione"
                                    valueChangeEvent="keyup"
                                    showClearButton={false}
                                    placeholder="Seleziona il tipo di movimento..."
                                    disabled={!daRegistrare}
                                    onValueChanged={(e: any) => { cambiaValore("tipoMovimento", e.value); setTipoMovimentoS(e.value) }}
                                />


                            </div>
                        </div>
                        <div className="form-group col-md-3">
                        </div>
                    </div>
                    <div className='form-row' >
                        <div className="form-group col-md-3">
                        </div>
                        <div className="form-group col-md-6">
                            <div className="float-right">
                                <Button className="btn btn-success" variant="success" onClick={() => {
                                    salvaRegistrazione()
                                }}
                                    disabled={disableConferma}>CONFERMA </Button>
                            </div>
                        </div>
                        <div className="form-group col-md-3">
                        </div>
                    </div>
                </div>

            </div>
            

        </>
    )
}

export default RegistrazioneUdi 