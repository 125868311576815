import notify from "devextreme/ui/notify";
import React, { useState } from "react";
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { OggettoInterface } from "../../../interfaces/generiche";
import { RestServices } from "../../../services/restServices";
import { SelezioneMultiplaFarmacie } from '../../../widget/Select';
import { urlAnagrafiche } from "../../costanti";

export const InviaPrezziDialog = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    isDialogInviaPrezzi: boolean,
    setIsDialogInviaPrezzi: (isDialogImpostazioniPrezzoPromozione: boolean) => void
    handleCloseDialogInviaPrezzi: () => void,
    functionNext: () => void,
    oggetti: any[],
    selectedRowCount: number

}) => {

    const [bloccoAggiornamento, setBloccoAggiornamento] = useState<boolean>(false);
    const [filtriDialog, setFiltriDialog] = useState<OggettoInterface>({});

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtriDialog, ...newFiltro }
        setFiltriDialog(newF);
    }

    const handleClose = () => {
        props.setIsDialogInviaPrezzi(false);
    }

    const pushMassivePrezzo = () => {

        if (filtriDialog.idFarList && filtriDialog.idFarList.length > 0) {

            let model = [
                ...props.oggetti,
                filtriDialog.idFarList,
                bloccoAggiornamento,
            ];

            RestServices().rest('POST', model, urlAnagrafiche.pushMassivePrezzo)
                .then(result => {

                    if (result.response) {
                        //console.log("Ditta: " + result.response);
                        notify({ position: "center", width: "auto", message: "Riuscito" }, "success", 4000);

                    } else {
                        // console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000);
                    }
                })

        } else {

            return notify({ position: "center", width: "auto", message: "Nessuna farmacia selezionata" }, "error", 4000);
        }

    };


    const colonneGridGeneriche = CreaColonne([
        { dataField: "ID_FAR", caption: "id", visible: false },
        { dataField: "DESCRIZIONE", caption: "descrizione", sortOrder: "asc" },
        { dataField: "CODICE", caption: "codice", width: 120 }
    ])


    //console.log("oggetti selezionati: ", props.oggetti, " variabile checkbox: ", bloccoAggiornamento, " farmacie selezionate: ", filtriDialog.idFarList);


    return (
        <>
            <Modal
                show={props.isDialogInviaPrezzi}
                onHide={handleClose}
                dialogClassName="dialogInviaPrezzi"
                size="lg"
                centered={false}
                backdrop="static"
            >
                <Modal.Header>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h2 className="main-title"> Invio massivo a Farmacie </h2>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>


                    <Row className="rigaCreaNuovoOrdLib">

                        <Col sm="3" className="labelCreaNuovoOrdLib">
                            <span style={{ fontSize: 14 }}>Farmacie</span>
                        </Col>
                        <Col sm="12">

                            <SelezioneMultiplaFarmacie
                                dataSource={props.datiFarmacie}
                                id="idFarList"
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGeneriche}
                                selection="multiple"
                                tutteSelezionate={true}
                                placeholder="Seleziona una o piu' farmacie..."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span style={{ fontSize: 13, fontWeight: 'bold' }}>Blocco aggiornamento banca dati</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: 5 }}>
                            <input type="checkbox" checked={bloccoAggiornamento} onChange={(e: any) => setBloccoAggiornamento(e.target.checked)} /> <span>Blocco aggiornamento</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: 5, fontWeight: 'bold' }}>
                            <span>Somma: {props.selectedRowCount}</span>
                        </Col>
                    </Row>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="PopFirst-btn" variant="success" onClick={pushMassivePrezzo}>
                        Allinea
                    </Button>
                    <button className="PopSecond-btn" onClick={handleClose}>
                        Chiudi
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )

}