import { TextBox, Validator } from 'devextreme-react';
import { RequiredRule, StringLengthRule } from 'devextreme-react/validator';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useGetElencoRsa, useGetRsa } from '../../hooks/useFetchElencoRsa';
import { SelectOptionChangeInterface } from '../../interfaces/componentOptions';
import { RsaInterface, AnagraficaRsaInterface } from '../../interfaces/elencoRsa';
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { OggettoInterface } from '../../interfaces/generiche';
import { ColumnButtonOnClickInterface, TabellaCompletaInterface, TabellaSempliceInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';
import { BoxFiltro, BoxTestoCerca, Check } from '../../widget/Editor';
import { Loading } from '../../widget/Notifications';
import { TabellaCompleta, TabellaSemplice } from '../../widget/Tabelle';
import { urlTorinoRsa } from '../costanti';

const ElencoClientiRSA = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean
}) => {
    //al caricamento della pagina si fa collassare il menu laterale in modo da avere più spazio a disposizione per la pagina
    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    //crea il titolo della pagina sotto la navbar
    const titolo = TitoloContenuto("Gestione clienti delle RSA", "Cliccare sulla matita per aggiungere i clienti della RSA");

    //rende visibile/invisibile il pannello di loading
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [modalAperta, setModalAperta] = useState(false);
    const [elencoClienti, setElencoClienti] = useState<RsaInterface[]>([]);
    const [rigaSelez, setRigaSelez] = useState<RsaInterface>();
    const [aggiorna, setAggiorna] = useState<boolean>(false);

    useGetElencoRsa(setElencoClienti, setIsVisible, aggiorna);

    const aggiungiRsa = () => {
        setRigaSelez(undefined);
        setModalAperta(true);
    }

    const apriModalModifica = (e: ColumnButtonOnClickInterface) => {
        if (e && e.row && e.row.data) {
            const riga = {
                idRsa: e.row.data.idRsa,
                codice: e.row.data.codice,
                descrizione: e.row.data.descrizione,
                flagAttivo: e.row.data.flagAttivo
            }
            setRigaSelez(riga);
            setModalAperta(true);
        }
    }
    const [tabellaInstance, setTabellaInstance] = useState();

    const propsTabElencoClienti: TabellaCompletaInterface = {
        id: "elenco_clienti_rsa",
        dataSource: elencoClienti,
        selection: "none",
        export: { enabled: true },
        defaultFilterValue: ["flagAttivo", "=", true],
        headerFilterVisible: true
    }
    const columnsElencoClienti = CreaColonne([
        { caption: "Codice", dataField: "codice", dataType: "string", sortOrder: "asc" },
        { caption: "Descrizione", dataField: "descrizione", dataType: "string", },
        { caption: "Attiva", dataField: "flagAttivo", dataType: "boolean", },
        {
            caption: "Modifica", type: "buttons", buttons: [{
                hint: "Modifica Anagrafica RSA",
                icon: "edit",
                onClick: apriModalModifica
            }]
        }
    ])

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row className="nuovaRsa">
                    <Col sm="12">
                        <Button id="btnNuovaRsa" variant="success" onClick={aggiungiRsa}>Nuova RSA</Button>
                    </Col>
                </Row>
                <Row className="tabellaClientiRsa">
                    <Col sm="12">
                        <TabellaCompleta propsTabella={propsTabElencoClienti} columns={columnsElencoClienti} setTabellaInstance={setTabellaInstance} />
                    </Col>
                </Row>
                {(rigaSelez) ?
                    <ModalModificaRsa modalAperta={modalAperta} setModalAperta={setModalAperta} rigaSelez={rigaSelez} aggiorna={aggiorna} setAggiorna={setAggiorna} />
                    :
                    <ModalModificaRsa modalAperta={modalAperta} setModalAperta={setModalAperta} aggiorna={aggiorna} setAggiorna={setAggiorna} />
                }
            </div>
        </>
    )
}

export default ElencoClientiRSA

const ModalModificaRsa = (props: {
    modalAperta: boolean,
    setModalAperta: (modalAperta: boolean) => void,
    rigaSelez?: RsaInterface,
    aggiorna: boolean,
    setAggiorna: (aggiorna: boolean) => void
}) => {
    const [filtri, setFiltri] = useState<RsaInterface>({ flagAttivo: true });
    const [rsaSelez, setRsaSelez] = useState<AnagraficaRsaInterface>();

    useEffect(() => {
        if (props.rigaSelez) setFiltri(props.rigaSelez);
    }, [props.rigaSelez])

    useGetRsa(setRsaSelez, props.rigaSelez);

    const impostaFiltri = (newFiltro: RsaInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setModalAperta(false);
        setFiltri({ flagAttivo: true });
        setRsaSelez(undefined);
    }

    const aggiornaPagina = () => {
        props.setModalAperta(false);
        setFiltri({ flagAttivo: true });
        setRsaSelez(undefined);
        props.setAggiorna(!props.aggiorna);
    }

    const modificaRsa = () => {
        if (!filtri.codice) {
            notify({ position: "center", width: "auto", message: "Inserire il codice" }, "error", 4000)
        } else if (filtri.codice.includes(" ")) {
            notify({ position: "center", width: "auto", message: "Non sono ammessi spazi vuoti nel codice" }, "error", 4000)
        } else if (!filtri.descrizione) {
            notify({ position: "center", width: "auto", message: "Inserire la descrizione" }, "error", 4000)
        } else {
            if (filtri.idRsa) {
                RestServices().rest('POST', filtri, urlTorinoRsa.editRsa).then(result => {
                    if (result) {
                        if (result.message && result.severity) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                        } else {
                            notify({ position: "center", width: "auto", message: "Salvataggio effettuato con successo", onHiding: aggiornaPagina() }, "success", 4000)
                        }
                    }
                })
            } else {
                RestServices().rest('POST', filtri, urlTorinoRsa.insertRsa).then(result => {
                    if (result) {
                        if (result.message && result.severity) {
                            notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                        } else {
                            notify({ position: "center", width: "auto", message: "Salvataggio effettuato con successo", onHiding: aggiornaPagina() }, "success", 4000)
                        }
                    }
                })
            }
        }
    }

    return (
        <>
            <Modal
                show={props.modalAperta}
                onHide={handleClose}
                dialogClassName="modalModificaRsa"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloModificaRsa">
                        <h5>Anagrafica RSA</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalBody rsaSelez={rsaSelez} impostaFiltri={impostaFiltri} />
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnOkModifica" variant="success" onClick={modificaRsa}>
                        OK
                    </Button>
                    <Button id="btnAnnullaModifica" variant="danger" onClick={handleClose} >
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

const ModalBody = (props: { rsaSelez: AnagraficaRsaInterface | undefined, impostaFiltri: (filtro: RsaInterface) => void }) => {
    const [valoreCheck, setValoreCheck] = useState(true);
    useEffect(() => {
        if (props.rsaSelez && props.rsaSelez.flagAttivo === false) setValoreCheck(props.rsaSelez.flagAttivo);
    }, [props.rsaSelez?.flagAttivo])

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="3">
                        <label className="labelRsa">Codice</label>
                    </Col>
                    <Col sm="8" className="rsaCodice">
                        {(props.rsaSelez?.codice) ? <BoxFiltro id="codice" impostaFiltri={props.impostaFiltri} value={props.rsaSelez.codice} /> : <BoxFiltro id="codice" impostaFiltri={props.impostaFiltri} />}
                    </Col>
                    <Col sm="1" />

                    <Col sm="3">
                        <label className="labelRsa">Descrizione</label>
                    </Col>
                    <Col sm="8" className="rsaDescr">
                        {(props.rsaSelez?.descrizione) ? <BoxFiltro
                            id="descrizione"
                            impostaFiltri={props.impostaFiltri}
                            value={props.rsaSelez.descrizione}
                        /> : <BoxFiltro id="descrizione" impostaFiltri={props.impostaFiltri} />}
                    </Col>
                    <Col sm="1" />

                    <Col sm="3" className="rsaCheck">
                        <label>Attiva</label>
                    </Col>
                    <Col sm="8" className="rsaCheck">
                        <Check id="flagAttivo" impostaFiltri={props.impostaFiltri} checkValue={valoreCheck} />
                    </Col>
                </Row>
                {props.rsaSelez && <ElencoCF rsaSelez={props.rsaSelez} />}
            </Container>
        </>
    )
}

const ElencoCF = (props: { rsaSelez: AnagraficaRsaInterface }) => {
    const [nuovoCF, setNuovoCF] = useState<OggettoInterface>({});
    const [aggiornaTab, setAggiornaTab] = useState(1);

    let elencoCF = props.rsaSelez?.lstCodFiscale;

    const eliminaCf = (e: ColumnButtonOnClickInterface) => {
        if (e && e.row && e.row.data) {
            console.log(e.row.data)
            const param = {
                idRsa: props.rsaSelez?.idRsa,
                lstCodFiscale: [{ codiceFiscale: e.row.data.codiceFiscale }]
            }
            RestServices().rest('POST', param, urlTorinoRsa.cancellaCodFisc).then(result => {
                if (result) {
                    if (result.message && result.severity) {
                        notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                    } else {
                        dataSource.push([{
                            type: "remove", key: e.row?.data.codiceFiscale
                        }]);
                    }
                }
            })
        }
    }

    const aggiungiCF = () => {
        if (nuovoCF.codiceFiscale.length === 16) {
            const param = {
                idRsa: props.rsaSelez?.idRsa,
                lstCodFiscale: [nuovoCF]
            }
            RestServices().rest('POST', param, urlTorinoRsa.aggiungiCodFisc).then(result => {
                if (result) {
                    if (result && result.message && result.severity) {
                        notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000)
                    } else {
                        const nuovoElencoCF = elencoCF;
                        if (nuovoElencoCF) {
                            nuovoElencoCF.unshift({ dataInsert: new Date(), codiceFiscale: nuovoCF.codiceFiscale });
                            setAggiornaTab(aggiornaTab + 1);
                        }
                        // dataSource.push([{
                        //     type: "insert", data: {
                        //         dataInsert: new Date(),
                        //         codiceFiscale: nuovoCF.codiceFiscale
                        //     }
                        // }]);
                        // setAggiornaTab(aggiornaTab + 1);
                    }
                }
            })
        } else {
            notify({ position: "center", width: "auto", message: "Inserire il codice fiscale per aggiungerlo all'elenco" }, "error", 4000)
        }
    }
    const dataSource = new ArrayStore({
        data: elencoCF,
        key: "codiceFiscale"
    });

    const propsTabCodiciFiscali: TabellaSempliceInterface = {
        id: "RSA_codici_fiscali",
        dataSource: dataSource,
        filterRow: true,
        pagination: 10
    }
    const columnsCodiciFiscali = CreaColonne([
        { caption: "Data", dataField: "dataInsert", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Codice Fiscale", dataField: "codiceFiscale" },
        {
            caption: "Elimina", type: "buttons", buttons: [{
                hint: "Elimina Codice Fiscale",
                icon: "trash",
                onClick: eliminaCf
            }]
        }
    ])

    const impostaCF = (newFiltro: OggettoInterface) => {
        newFiltro.codiceFiscale = newFiltro.codiceFiscale.toUpperCase();
        let newF = { ...nuovoCF, ...newFiltro }
        setNuovoCF(newF);
    }
    const cambiaValore = (e: SelectOptionChangeInterface) => {
        const param = "codiceFiscale";
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = e.value
        impostaCF(newFiltro);
    }

    return (
        <>
            <Row className="titoloCodiciFiscali">
                <Col sm='12'>
                    <div>Codici Fiscali Collegati</div>
                </Col>
            </Row>
            <Row className="aggiungiNuovoCF">
                <Col sm="4">
                    <label className="labelInsertCF">Inserisci nuovo codice fiscale</label>
                </Col>
                <Col sm="6" className="rsaNuovoCF">
                    <TextBox
                        onValueChanged={cambiaValore}
                        placeholder=" "
                        onEnterKey={aggiungiCF}
                    >
                        <Validator>
                            <StringLengthRule message="Inserire i 16 caratteri del codice fiscale" min={16} max={16} />
                        </Validator>
                    </TextBox>
                </Col>
                <Col sm="2">
                    <Button id="btnAggiungiCF" variant="success" onClick={aggiungiCF}>Aggiungi</Button>
                </Col>
            </Row>
            {(aggiornaTab) && <TabellaSemplice propsTabella={propsTabCodiciFiscali} columns={columnsCodiciFiscali} />}
        </>
    )
} 