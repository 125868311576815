import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { DialogInfo, Loading  } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlCommon,urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione/interface'
import Button from 'react-bootstrap/Button'
import { RadioGroup } from 'devextreme-react/radio-group'
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale, getCurrentDateTime } from '../../funzioni/formatoVariabili';
import { AplicaValoreTO, actionTables } from '../../funzioni/operazioniSuTabelle'
import {Summary, TotalItem} from 'devextreme-react/data-grid'
import { DialogData } from "./dialog/pop_data"
import { DialogNotePass } from "./dialog/pop_note_pass"
import { DiaPagamPass } from "./dialog/pop_pag_pass_beta"
import { DialogDataSaldo } from "./dialog/pop_dele_dat_saldo"

import './style.css';
import { NewTabella } from '../../widget/nuova_tabella/newVersion'




const FattPassiveBeta = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    const [idScadenzario,setIdScadenzario] = useState<number | null>(null)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [newFarmaList,setNewFarmaList] = useState([])
    const [propTab,setPropTab] = useState<number>(0)
    const [importo,setImporto] = useState<number>(0)
    const [saldate,setSaldate] = useState<number>(0)
    const [scadute,setScadute] = useState<number>(0)
    const [scadenzaO,setScadenzaO] = useState<number>(0)
    const [ScadenzaM,setScadenzaM] = useState<number>(0)
    const [nonScaduti,setNonScaduti] = useState<number>(0)
    const [nCredito,setNCredito] = useState<number>(0)
    const [summaryObj,setSummaryObj] = useState<any>({})
    const [selezione,setSelezione] = useState<any>([])
    const [scadenzario,setScadenzario] = useState<any>({})
    const [dataDalObj,setdataDalObj] = useState<any>({})
    const [dataAlObj,setdataAlObj] = useState<any>({})
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [openPop2 , setOpenPop2 ] = useState<boolean>(false)
    const [openPop3 , setOpenPop3 ] = useState<boolean>(false)
    const [openPopSaldo , setOpenPopSaldo ] = useState<boolean>(false)
    const [edit,setImmediate] = useState<boolean>(false)
    const [objtipo,setObjtipo] = useState<any>(null)
    const [paramsRicerca,setParamsRicerca] = useState<any>({})
    const [messDel,setMessDel] = useState<string>('')
    const [openPopInfo , setOpenPopInfo ] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)


    const columns = [
       
         { caption: "Scadenza", dataField: "scadeIl", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date", width: 150, render:(e:any)=>{
            // console.log('valore data');
            // console.log(e);
            return(
                <a style={{ cursor:'pointer', fontWeight:'bold',color:'blue'}}
                 onClick={()=>{setOpenPop(true);setObjtipo(e.data); setImmediate(true)}}>{e.text}</a>
            )
        }},
        { caption: "",width: 40,  type: "buttons", buttons: [
            {
                hint: "",
                icon: "info",
                visible:(x:any)=>isIconVisible(x),
                onClick: (e: any)  => {
                    if(e.row.data.avviso){
                        setMessDel(e.row.data.avviso)
                        setOpenPopInfo(true)
                        // notify({ position: "center", width: "auto", message:e.row.data.avviso }, "info", 7000) 
                     }
                }
            },] 
          },
        { caption: "Data Rif.", dataField: "dataRiferimento" , format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Giorni", dataField: "giorni" }, 
        { caption: "Importo", dataField: "importo",format: { type: "currency", precision: 2, currency: "EUR" }, width: 90},
        { caption: "Farmacia", dataField: "farmacia", width: 200  },
        { caption: "Fornitore", dataField: "intestatario", width: 300  },
        { caption: "Cat. Fornitore", dataField: "categoriaFornitore", width: 120  },
        { caption: "Tipo Doc.", dataField: "tipoDocumento", width: 130  },
        { caption: "Num. Doc.", dataField: "numeroDocumento", width: 100  },
        { caption: "Data Doc.", dataField: "data" , format: "dd/MM/yyyy", dataType: "date", width: 90 },
        { caption: "Totale", dataField: "totale" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Imponibile", dataField: "imponibile" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Imposta", dataField: "imposta" ,format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Data Pag.", dataField: "dataSaldo", sortOrder: "desc", format: "dd/MM/yyyy", dataType: "date", width: 95, render:(e:any)=>{
            return(
                <a style={{ cursor:'pointer', fontWeight:'bold',color:'blue'}}
                 onClick={()=>{setOpenPopSaldo(true);setObjtipo(e.data); setImmediate(true)}}>{e.text}</a>
            )
        }},
        { caption: "Pagamento", dataField: "pagamento" , width: 120 },
        { caption: "Riferimento", dataField: "riferimento" , width: 120 },
        { caption: "Banca", dataField: "banca" , width: 130 },
        { caption: "C/C", dataField: "concoCorrente" , width: 130 },
        { caption: "Annotazione", dataField: "annotazione" , width: 130 },
        { caption: "Scostamento", dataField: "scostamento" , format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },      
        { caption: "Stato", dataField: "stato" , width: 120 },
        { caption: "Nota pagamento", dataField: "note" , width: 100, render:(e:any)=>(<a style={{cursor:'pointer', fontWeight:'bold',color:'blue'}}
         onClick={()=>{setOpenPop2(true);setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)},
        
    ];

    const isIconVisible = (e:any) =>{
        if(e.row.data.avviso){
          return true;
        }else{
            return false;
        }     
      }
    
    

    const itemsFields = [
        {type:'select' , idValue:'idFar' , label:'Farmacie ' ,displayExpr:'descrizione', dataSource : newFarmaList  ,width:350 },
        {type:'date' , idValue:'dal' , label:'Dal' , dataSource : null, require:true , defValue : new Date(new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate()), width:250 },
        {type:'date' , idValue:'al' , label:'Al' , dataSource : null, require:true ,defValue : new Date(new Date().setMonth(new Date().getMonth() + 1)), width:250 },

        
    ]

    const isPrintIconVisible = (e:any) =>{
        if(e.row.data.linkFattura){
          return true;
        }else{
            return false;
        }     
      }


    const onRowPrepared = (e: any) => {
        if (e.values) {
            if ( e.data.idTipoDocumento == '4' || e.data.idTipoDocumento == '8') {
                e.rowElement.style.backgroundColor = 'rgb(176, 249, 255)';
            }
            else if (e.data.statoPagamento == "I" ) {
                e.rowElement.style.backgroundColor = 'rgb(244, 246, 137)';
            }
            else if (e.data.statoPagamento == 'M') {
                e.rowElement.style.backgroundColor = 'rgb(255, 204, 102)';
            }
            else if (e.data.statoPagamento == 'P') {
                    e.rowElement.style.backgroundColor = 'rgb(150, 255, 164)';
            }
            else if (e.data.statoPagamento == 'S') {
                e.rowElement.style.backgroundColor = 'rgb(252, 230, 232)';
            }
            
        }
    }


    
    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {
                       //console.log(result.response);
                       setNewFarmaList(result.response)
                                         
                   }else{
                      
                   }
               })     
       }
   }


   

    const filterAction = (objFiltro:Filtri,tab?:number) =>{
        setLoading(true)
        let params = {...objFiltro}
        setParamsRicerca(params)
        setIdScadenzario(null)
        if(!tab){
            setPropTab(0)
        }
        //console.log(params);
            if(params.idFar){
                params.idFar = Number(params.idFar?.idFar)
            }
       
            params.dal = trasformaDateTimeInLocale(params.dal , true);
            params.al = trasformaDateTimeInLocale(params.al, true);
            params.flagCicloAttivo = false
            
            //console.log(params);  
            setLoading(true)
            if (auth) {
                    RestServices().rest('POST', params , urlConsultazione.getListScad)
                        .then(result => {
                            if (result?.esito) {
                              
                                setLoading(false)   

                                let entrate = result.response.list.reduce((accumulator:any, object:any) => {
                                    if( object.importo){
                                        return accumulator + object.importo;
                                    }else{
                                        return accumulator ;
                                    }                                
                                  }, 0);

                                let dataDal = cambiaFormatoLocalDateTimeToEuropeo (params.dal);
                                let dataAl = cambiaFormatoLocalDateTimeToEuropeo (params.al);
                                
                                setSummaryObj({entrate})
                                setdataDalObj({dataDal})
                                setdataAlObj({dataAl})                           

                                let listaFiltrata:any = []
                                result.response.list.forEach((dato:any) => {
                                    //console.log(dato);
                                    if(tab && tab != 0){
                                        switch (tab) {
                                            case 0:
                                                listaFiltrata.push(dato)
                                                break;
                                            case 1:
                                                if(dato.statoPagamento == 'P'){
                                                    listaFiltrata.push(dato)
                                                }                            
                                                break;
                                            case 2:if(dato.statoPagamento == 'S'){
                                                listaFiltrata.push(dato)
                                              }
                                                 break;                      
                                            case 3:
                                                if(dato.statoPagamento == 'I'){
                                                    listaFiltrata.push(dato)
                                                }
                                                break;
                                            case 4:
                                                if(dato.statoPagamento == 'M'){
                                                    listaFiltrata.push(dato)
                                                }
                                                break;
                                            case 5:
                                                if(dato.statoPagamento == 'N'){
                                                    listaFiltrata.push(dato)
                                                }
                                                break; 
                                            case 6:
                                                if( dato.idTipoDocumento == '4' || dato.idTipoDocumento == '8' ){
                                                    listaFiltrata.push(dato)
                                                }
                                                break;                                        
                                            default:
                                                break;
                                        }
                                    }else{
                                        listaFiltrata.push(dato)
                                    }                              
                                });

                                setTabellaPrincipale(listaFiltrata)
                                setScadenzario(result.response) 
                                
                            }else{
                                //console.log(result);
                                notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                                setLoading(false)
                            }
                        })
                
            }
        
    }
    

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {     
        
        farmaList()
        setOpenPopInfo(false)
        filterAction({
        dal:new Date(new Date().getFullYear()+'-'+new Date().getMonth()+'-'+new Date().getDate()),
        al:new Date().setMonth(new Date().getMonth() + 1)
        })
    }, [auth]);
    
   

     let propr = [{ id: 0, text: 'Tutte' },
     { id: 1, text: 'Saldati' },
     { id: 2, text: 'Scaduti'},
     { id: 3, text: 'Scadono oggi'},
     { id: 4, text: 'Scadono nel mese'},
     { id: 5, text: 'Non ancora scaduti'},
     { id: 6, text: 'Nota di credito'}
      
    ]

    const caricaTabella = (value:number) =>{
        setPropTab(value)
        if(paramsRicerca.dal){
            filterAction(paramsRicerca,value)
        }
        
       // caricaDati(value == 0 ? true : false)
      }

      const ricarica = () =>{
        setOpenPop(false)    
        setOpenPop2(false)
        setOpenPop3(false)
        setImmediate(false)
        setOpenPopSaldo(false)
        filterAction(paramsRicerca)
                 
    }


    const gestioneMulty = () =>{
        console.log('vediamo ');
        console.log(selezione);
        
        
        if(selezione?.length <= 1){
            return true
        }else{
            return false
        }
    }
    
      

    return (
        <>
        <DialogInfo
                    dialogIsOpen={openPopInfo}
                    message={messDel}  
                    functionNext={()=>('')}        
                    handleCloseConferma={()=>{setOpenPopInfo(false)}}
                />

        <DialogDataSaldo
             typeEdit={edit} 
             edit={objtipo} 
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPopSaldo(false);setObjtipo({});setImmediate(false)}}
             open={openPopSaldo} /> 

         <DialogData
             typeEdit={edit} 
             edit={objtipo} 
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPop(false);setObjtipo({});setImmediate(false)}}
             open={openPop} /> 

      <DialogNotePass
             typeEdit={edit} 
             edit={objtipo} 
             ricarica={()=>ricarica()} 
             close={()=>{setOpenPop2(false);setObjtipo({});setImmediate(false)}}
             open={openPop2} /> 
        

        {openPop3  ? (
                <DiaPagamPass
                typeEdit={edit} 
                edit={selezione} 
                ricarica={()=>ricarica()} 
                close={()=>{setOpenPop3(false);setImmediate(false)}}
                open={openPop3} /> 
            ) : null}

        <Loading isVisible={loading} />
            <div className='title'>
                <h3> FATTURE PASSIVE  <span className='subt' > Consultazione Scadenzario</span> </h3>
             </div>
             <div style={{width:'250'}}>
            <Ricerca ricerca={(filtro)=>filterAction(filtro)}  items={itemsFields}  />
             </div>
            <div style={{gap:10,display:'flex',background: 'white',padding:10,borderRadius: 10,marginTop:4}} >   
            <div className='title'>
                <h6> Filtro rapido stato pagamento: </h6>
             </div>  
             <div style={{marginTop:8}}>
           <RadioGroup onValueChanged={(x)=>caricaTabella(x.value.id)} dataSource={propr} layout="horizontal" value={propr[propTab]}   />  
           </div>
                  
        </div>
        <div style={{gap:16,display:'flex',flexWrap: 'wrap',background: 'white',padding: 10,borderRadius: 10,marginTop:4}}>
               <p style={{fontSize:16,margin:0,color:'black', marginTop:10 }}  >Dal:<strong> {dataDalObj.dataDal} </strong>  </p>
               <p style={{fontSize:16,margin:0,color:'black', marginTop:10 }}  >Al: <strong>{dataAlObj.dataAl } </strong> </p>
                <p style={{fontSize:16,margin:0,color:'black', marginTop:10 }}>Importo: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(importo)} </strong> </p>         
                <div className='leg5'>  <p style={{fontSize:16,margin:0,color:'black'}}>Saldati: <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(saldate)} </strong> </p>   </div>  
                <div className='leg4'><p style={{fontSize:16,margin:0,color:'black'}}>Scaduti: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadute) } </strong> </p> </div>
                <div className='leg1'> <p style={{fontSize:16,margin:0,color:'black'}}> Scadono oggi: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(scadenzaO)} </strong> </p></div>
                <div className='leg2'><p style={{fontSize:16,margin:0,color:'black'}}>Scadono nel mese: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(ScadenzaM)} </strong> </p> </div>
                <div className='leg6'> <p style={{fontSize:16,margin:0,color:'black'}}>Non ancora scaduti: <strong> {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nonScaduti)} </strong> </p> </div>           
                <div className='leg3'><p style={{fontSize:16,margin:0,color:'black'}}>Note Di credito: <strong>  {new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(nCredito)} </strong> </p>  </div>       
             </div>

             

             <div className='button_primabanche'>            
             <Button  disabled={selezione.length == 1 && selezione[0].linkAbbinamento ? false : true} 
               onClick={()=>{  window.open(selezione[0].linkAbbinamento, "_blank") }}
                variant="primary" >
                 ABBINAMENTO
              </Button>
              <Button style={{marginLeft:5}} disabled={selezione.length == 1 && selezione[0].linkQuadratura ? false : true} 
               onClick={()=>{  window.open(selezione[0].linkQuadratura, "_blank") }}
                variant="primary" >
                 QUADRATURA
              </Button>
              <Button style={{marginLeft:5}} disabled={selezione.length == 1 && selezione[0].linkFattura ? false : true}
               onClick={()=>{  window.open(selezione[0].linkFattura, "_blank") }}
                variant="primary" >
                 VISUALIZZA
              </Button>           
              <Button style={{marginLeft:5}}  disabled={selezione.length > 0 ? false : true}  onClick={()=>{setOpenPop3(true); setImmediate(true) }} variant="primary" >
                 PAGAMENTO
              </Button>  
           </div>

            <NewTabella
                idTabella='scade_fatt_passive'
                sync
                multyp
                colonne={columns}
                dataSource={tabellaPrincipale}
                filterRow
                headerFilter
                hidePager={true}
                searchPanel={true}
                selectedValue={(datiRow:any)=>setSelezione(datiRow.selectedRowKeys)}
                exportT={{
                    enabled: true,
                    fileName: "esportazione_scadenzario_passivo_" + getCurrentDateTime()
                }}
                activeSaver
                columnC
                filteredDataShow={(istanza) => {
                    const dataV: any = istanza.component.getDataSource();
                    let entrate = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.importo) {
                            return accumulator + object.importo;
                        } else {
                            return accumulator;
                        }
                    }, 0);

                    let saldate = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'P') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }
                    }, 0);

                    let totScadute = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'S') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totOggi = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'I') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);


                    let totProssimeMese = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'M') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totSuccessive = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'N') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);

                    let totNote = dataV._items.reduce((accumulator: any, object: any) => {
                        if (object.statoPagamento === 'C') {
                            if (object.importo && object.idTipoDocumento != 4 && object.idTipoDocumento != 8) {
                                return accumulator + object.importo;
                            } else {
                                return accumulator - object.importo;
                            }
                        } else {
                            return accumulator
                        }

                    }, 0);





                    setSaldate(saldate)
                    setImporto(entrate)
                    setScadute(totScadute)
                    setScadenzaO(totOggi)
                    setScadenzaM(totProssimeMese)
                    setNonScaduti(totSuccessive)
                    setNCredito(totNote)

                    //setSummaryObj({}) 



                    //console.log('dati filtrati');
                    //console.log(filtrati);


                }}

                summary={
                    <Summary>
                        <TotalItem column="dateEmissione" summaryType="count" />
                    </Summary>}
                onRowPrepared={onRowPrepared}   
                              
            />
          <div style={{gap:10,display:'flex',background: 'white',padding:10,borderRadius: 10,marginTop:10}} className="form-group">   
            <div className='title2'>
                <h6> Legenda Colori: </h6>
             </div>  
             <div className='leg5'>
           <h6 >  Saldati </h6> 
           </div> 
           <div className='leg4'>
           <h6> Scaduti </h6>   
           </div>   
             <div className='leg1'>
             <h6>  Scadono oggi </h6> 
           </div>   
           <div className='leg2'>
           <h6> Scadono nel mese </h6> 
           </div>
           <div className='leg6'>
            <h6> Non ancora scaduti  </h6>   
           </div>
           <div className='leg3'>
           <h6> Note di credito  </h6>   
           </div> 
        </div>

           
        </>
    )
}

export default FattPassiveBeta

