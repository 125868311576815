import notify from "devextreme/ui/notify";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { urlCommon } from "../costanti";
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import Scontrini from "./Scontrini";

interface PageProps {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu: (stato: boolean) => void;
    statoMenu: boolean;
}

const RegistratoreDiCassaDettaglio: React.FC<PageProps> = ({ datiFarmacie, toggleMenu, statoMenu }) => {

    const titolo = TitoloContenuto("Registratore Di Cassa Dettaglio Giornata");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [objtipo, setObjtipo] = useState<any>(null);
    const [renderp, setRenderp] = useState<any>();
    const [params, setParams] = useState<any>({});

    const [documentiRegistratoreDiCassa, setDocumentiRegistratoreDiCassa] = useState<any[]>([]);
    const [venditePlatinum, setVenditePlatinum] = useState<any[]>([]);
    const [venditeAbbinate, setVenditeAbbinate] = useState<any[]>([]);
    const [nonAbbinate, setNonAbbinate] = useState<any[]>([]);

    const [totaleDocumentiRegistratoreDiCassa, setTotaleDocumentiRegistratoreDiCassa] = useState<any>();
    const [totaleVenditePlatinum, setTotaleVenditePlatinum] = useState<any>();

    const [totaleVenditePlatinumAbbinate, setTotaleVenditePlatinumAbbinate] = useState<any>();
    const [totaleRegistratoreAbbinate, setTotaleRegistratoreAbbinate] = useState<any>();

    const [totaleRegistratoreNonAbbinate, setTotaleRegistratoreNonAbbinate] = useState<any>();
    const [totalePlatinumNonAbbinate, setTotalePlatinumNonAbbinate] = useState<any>();

    const location = useLocation<any>();
    const { rowData } = location.state || { rowData: {} };
    const history = useHistory();

    const auth = useAuth(urlCommon.auth);

    const exportDetails = () => {

        let idFarValue = rowData.idFar;
        //let idFar = 17;

        let dataValue = rowData.data;
        //let data = '2024-01-17';

        //console.log(idFar)
        //console.log(data)

        let model = {
            idFar: idFarValue,
            data: dataValue
        }

        //console.log(model);

        setParams(model);

        //console.log(params)
        setIsVisible(true);
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getRegistratoreDiCassaListDettaglio)
                .then(result => {

                    if (result) {

                        //Registratore di cassa
                        if (result.response.documentiRegistratoreDiCassa) {
                            setDocumentiRegistratoreDiCassa(result.response.documentiRegistratoreDiCassa);
                        }

                        //Platinum
                        if (result.response.venditePlatinum) {
                            setVenditePlatinum(result.response.venditePlatinum);

                        }

                        //Abbinate
                        if (result.response.venditeAbbinate) {
                            setVenditeAbbinate(result.response.venditeAbbinate);
                        }

                        //Non Abbinate
                        if (result.response.venditeNonAbbinate) {
                            setNonAbbinate(result.response.venditeNonAbbinate);
                        }

                        //Registratore di cassa
                        setTotaleDocumentiRegistratoreDiCassa(result.response.totaleDocumentiRegistratoreDiCassa);

                        //Platinum
                        setTotaleVenditePlatinum(result.response.totaleVenditePlatinum);

                        //Abbinate
                        setTotaleVenditePlatinumAbbinate(result.response.totalePlatinumVenditeAbbinate);
                        setTotaleRegistratoreAbbinate(result.response.totaleRegistratoreVenditeAbbinate);

                        //Non Abbinate
                        setTotaleRegistratoreNonAbbinate(result.response.totaleRegistratoreNonAbbinate);
                        setTotalePlatinumNonAbbinate(result.response.totalePlatinumNonAbbinate);

                        setIsVisible(false);

                        if (result.response.length === 0) {

                            notify({ position: "center", width: "auto", message: "Nessun dettaglio da mostrare" }, "error", 4000)
                            setIsVisible(false);
                        }

                    } else {

                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }
                })

        }

    }

    const formatDate = (e: any) => {

        if (!e) {
            return "Nessuna Data";
        }
        else {
            const [anno, mese, giorno] = e.split('-');

            e = `${giorno}/${mese}/${anno}`;

            return e;
        }
    };

    const formattedData = formatDate(rowData.data);


    const formatTime = (e: any) => {

        if (!e) {
            return "Nessuna Data";
        }
        else {
            const [data, orario] = e.split('T');
            const [ore, minuti, secondi] = orario.split(':');

            e = `${ore}:${minuti}:${secondi}`;

            return e;
        }
    };


    const columnsDocumentiRegistratoreDiCassa = [
        { caption: "Matricola Registratore", dataField: "matricola", },
        { caption: "Numero di Chiusura", dataField: "numeroDiChiusura", width: "auto" },
        { caption: "Numero Documento", dataField: "numeroDocumento", width: "auto" },
        { caption: "Importo", dataField: "totaleComplessivo", format: { type: "currency", precision: 2, currency: "EUR" } },
    ];

    const columnsVenditePlatinum = [
        { caption: "Codice Vendita", dataField: "cpeVendita", },
        { caption: "Importo Foglio Vendita", dataField: "importoFogliovendita", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto Foglio Vendita", dataField: "scontoFogliovendita", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Sconto Vendita", dataField: "scontoVendita", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Importo Altro", dataField: "importoAltro", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Anticipato", dataField: "importoAnticipato", format: { type: "currency", precision: 2, currency: "EUR" } },
    ];


    const columnsVenditeAbbinate = [
        { caption: "Numero Documento", dataField: "numeroDocumento", width: "auto" },
        {
            caption: "Orario", dataField: "dataOra", format: { type: "date" },
            render: (e: any) => {

                return formatTime(e.value);
            }
        },
        { caption: "Matricola Registratore", dataField: "matricola", },
        { caption: "Numero di Chiusura", dataField: "numeroDiChiusura", },
        { caption: "Totale", dataField: "totaleComplessivo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Tot. Pagato", dataField: "importoPagato", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Codice Vendita", dataField: "cpeVendita", },
        {
            caption: "Orario Vendita", dataField: "dataVendita",
            render: (e: any) => {

                return formatTime(e.value);
            }
        },
        { caption: "Totale Platinum", dataField: "totaleVendita", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Tot. Anticipato", dataField: "totaleAnticipato", format: { type: "currency", precision: 2, currency: "EUR" } },

    ];

    const columnsNonAbbinate = [
        {caption: "Numero Documento", dataField: "numeroDocumento", width: "auto",},
        { caption: "Codice Vendita", dataField: "cpeVendita", },
        { caption: "Matricola Registratore", dataField: "matricola", },
        { caption: "Numero di Chiusura", dataField: "numeroDiChiusura", width: "auto" },
        { caption: "Complessivo", dataField: "totaleComplessivo", format: { type: "currency", precision: 2, currency: "EUR" } },
    ];

    useEffect(() => {
        exportDetails();
    }, [auth]);


    const goBack = () => {
        history.push("/RegistratoreDiCassa/ElencoRegistratoreDiCassa");
    };



    const render = () => {
        switch (renderp) {
            case 1:
                return (

                    <Scontrini
                        rowData={objtipo}
                        setIndietro={() => { setRenderp("default") }}
                    >
                    </Scontrini>

                )

            default:
                return (
                    <>
                        <br></br>
                        {titolo}
                        {loadPanel}


                        <div style={{ marginTop: 15, marginBottom: 20, display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>

                            <div>
                                <h4> <span style={{ fontWeight: 'bold', }}>{rowData.codiceFarmacia} - {rowData.descrizioneFarmacia} - {formattedData}</span></h4>
                            </div>

                            <div >
                                <Button className="btn btn-warning" variant="warning" onClick={() => { goBack(); }}> TORNA INDIETRO </Button>
                            </div>


                        </div>

                        <div style={{ padding: 20 }}>

                            <div style={{ display: "flex", overflowX: "auto" }}>

                                <div style={{ marginRight: 10 }}>
                                    <NewTabella
                                        idTabella='documentiRegistratoreDiCassa'
                                        title={
                                            <span>
                                                Documenti Registratore di Cassa:{' '}
                                                <span style={{ color: 'red' }}>
                                                    {new Intl.NumberFormat('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }).format(totaleDocumentiRegistratoreDiCassa)}
                                                </span>
                                            </span>
                                        }
                                        sync
                                        colonne={columnsDocumentiRegistratoreDiCassa}
                                        dataSource={documentiRegistratoreDiCassa}
                                        filterRow
                                        searchPanel={true}
                                        exportT={{ enabled: true, fileName: "Registratore_Di_Cassa" }}
                                        activeSaver={true}
                                        columnC={true}
                                        headerFilter
                                        wordWrapEnabled={true}
                                    />

                                </div>

                                <div>
                                    <NewTabella
                                        idTabella='venditePlatinum'
                                        title={
                                            <span>
                                                Vendite Platinum:{' '}
                                                <span style={{ color: 'red' }}>
                                                    {new Intl.NumberFormat('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }).format(totaleVenditePlatinum)}
                                                </span>
                                            </span>
                                        }
                                        sync
                                        colonne={columnsVenditePlatinum}
                                        dataSource={venditePlatinum}
                                        filterRow
                                        searchPanel={true}
                                        exportT={{ enabled: true, fileName: "Vendite_Platinum" }}
                                        activeSaver={true}
                                        columnC={true}
                                        headerFilter
                                        wordWrapEnabled={true}
                                    />

                                </div>


                            </div>

                            <div style={{ display: "flex", overflowX: "auto", marginTop: 20 }}>

                                <div style={{ marginRight: 10 }}>
                                    <NewTabella
                                        idTabella='venditeAbbinate'
                                        title={
                                            <span>
                                                Vendite Abbinate - {' '}
                                                Tot. Registratore: {' '}
                                                <span style={{ color: 'red' }}>
                                                    {new Intl.NumberFormat('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }).format(totaleRegistratoreAbbinate)}
                                                </span>

                                                {' | '}

                                                Tot. Platinum: {' '}
                                                <span style={{ color: 'red' }}>
                                                    {new Intl.NumberFormat('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }).format(totaleVenditePlatinumAbbinate)}
                                                </span>

                                                <br />
                                                <br />
                                                <br />

                                            </span>
                                        }
                                        sync
                                        colonne={columnsVenditeAbbinate}
                                        dataSource={venditeAbbinate}
                                        filterRow
                                        searchPanel={true}
                                        exportT={{ enabled: true, fileName: "Vendite_Abbinate" }}
                                        activeSaver={true}
                                        columnC={true}
                                        headerFilter
                                        wordWrapEnabled={true}
                                    />

                                </div>

                                <div>
                                    <NewTabella
                                        idTabella='venditeNONAbbinate'
                                        title={
                                            <span>
                                                Tot. Registratore Non Abbinate: {' '}
                                                <span style={{ color: 'red' }}>
                                                    {new Intl.NumberFormat('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }).format(totaleRegistratoreNonAbbinate)}
                                                </span>

                                                <br />

                                                Tot. Vendite Non Abbinate: {' '}
                                                <span style={{ color: 'red' }}>
                                                    {new Intl.NumberFormat('it-IT', {
                                                        style: 'currency',
                                                        currency: 'EUR',
                                                    }).format(totalePlatinumNonAbbinate)}
                                                </span>

                                            </span>

                                        }
                                        sync
                                        colonne={columnsNonAbbinate}
                                        dataSource={nonAbbinate}
                                        filterRow
                                        searchPanel={true}
                                        exportT={{ enabled: true, fileName: "Vendite_NON_Abbinate" }}
                                        activeSaver={true}
                                        columnC={true}
                                        headerFilter
                                        wordWrapEnabled={true}
                                    />

                                </div>

                            </div>

                        </div>

                    </>
                )
        }
    }

    return render()

}


export default RegistratoreDiCassaDettaglio;