import React, { useCallback, useEffect, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { MonitoraggioFarmaciaParametro } from "../../interfaces/monitoraggio";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlCommon, urlMonitoraggio } from "../costanti";
import { Selezione, TendinaFarm } from '../../widget/Select';
import Button from 'react-bootstrap/esm/Button';
import { DrawerFiltri } from '../../widget/Drawer';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { OggettoInterface } from '../../interfaces/generiche';
import { TabellaCompleta, TabellaCompletaAlternate, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { SelectFarm } from '../../widget/Select';
import { urlConsultazione } from "../costanti"
import { BoxFiltro } from "../../widget/Editor";
import { Summary, TotalItem } from 'devextreme-react/data-grid'
import { Filtri } from "../consultazione/interface";
import Ricerca from "../../widget/nuova_ricerca";
import { trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";
import { DialogModificaAnomalia } from "./dialog/DialogModificaAnomalia";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import Tooltip from "devextreme-react/tooltip"
import { on } from "devextreme/events";



const ElencoAnomalieFarmacie = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const titolo = TitoloContenuto("Monitoraggio Anomalie Farmacie");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoAnomalie, setElencoAnomalie] = useState<any>([])
    const [anomalieFiltri, setAnomalieFiltri] = useState<any>([])
    const [paramsFilter, setParamsFilter] = useState<Filtri>()
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [tooltip, setTooltip] = useState<any>(null)
    const [state, setState] = useState<any>(null)

    const auth = useAuth(urlCommon.auth);

    useEffect(() => {

        let anomalie = [];
        let anomaliaTutte = { id: 1, descrizione: 'Tutte' };
        let anomaliaRisolte = { id: 2, descrizione: 'Risolte' };
        let anomaliaNonRisolte = { id: 3, descrizione: 'Non risolte' };

        anomalie.push(anomaliaTutte);
        anomalie.push(anomaliaRisolte);
        anomalie.push(anomaliaNonRisolte);

        setAnomalieFiltri(anomalie);
    },[])



    const filterAction = (objFiltro: Filtri) => {
        setIsVisible(true)
        let params = { ...objFiltro }
        if (params.dal) {
            params.dal = trasformaDateTimeInLocale(params.dal, true);
        }

        if (params.al) {
            params.al = trasformaDateTimeInLocale(params.al, true);
        }

        if (params.descrizione) {
            params.descrizione = params.descrizione;
        }

        setParamsFilter(params)
        setIsVisible(true)
        if (auth) {
            RestServices().rest('POST', params, urlCommon.getListAnomalie)
                .then(result => {
                    setIsVisible(false);
                    if (result) {
                        setIsVisible(false);
                        setElencoAnomalie(result.response.listaAnomalie);
                            
                        //console.log(result.response.anomalieFilters);

                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false);
                    }

                })
        }
    }

    const getAnomalie = () => {
        setIsVisible(true)
        if (auth) {
            RestServices().rest('POST', paramsFilter != undefined ? paramsFilter : { dal: null, al: null, descrizione: 'Non risolte'}, urlCommon.getListAnomalie)
                .then(result => {
                   // setIsVisible(false);
                    if (result) {
                        setIsVisible(false);
                        setElencoAnomalie(result.response.listaAnomalie);

                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setIsVisible(false   );
                    }

                })
        }
    }

    const ricarica = () => {
        if (openPop)
            getAnomalie()
        setOpenPop(false)
        setImmediate(false);
    }

    const columnsAnomalie = [
        //{ caption: "Id", dataField: "id", visible: false },
        //{ caption: "IdFar", dataField: "idFar", visible: false },
        { caption: "Cod. TPH", dataField: "codTPH" },
        { caption: "Farmacia", dataField: "descrizioneFarmacia" },
        { caption: "Nota", dataField: "nota", visible: false },
        { caption: "IdFar", dataField: "idFar" },
        { caption: "Codice", dataField: "codice" },
        { caption: "Anomalia", dataField: "nomeTipoAnomalia" },
        { caption: "Descrizione anomalia", dataField: "descrizioneAnomalia" },
        { caption: "Data prima rilevazione", dataField: "dataPrimaRilevazione", format: "dd/MM/yyyy hh:mm:ss", dataType: "datetime" },
        { caption: "Data ultima rilevazione", dataField: "dataUltimaRilevazione", format: "dd/MM/yyyy hh:mm:ss", dataType: "datetime" },
        { caption: "Utente risoluzione", dataField: "usernameRisoluzione" },
        { caption: "Data risoluzione", dataField: "dataRisoluzione", format: "dd/MM/yyyy hh:mm:ss", dataType: "datetime" },
        { caption: "Descrizione Risoluzione", dataField: "descrizioneRisoluzione" },
        { caption: "Chiuso automaticamente", dataField: "chiusoAutomaticamente" },
        { caption: "Ignora stesso tipo anomalia", dataField: "ignoraStessoTipoAnomalia" },
        { caption: "Data fine ignora anomalia", dataField: "dataFineIgnoraAnomalia", format: "dd/MM/yyyy", dataType: "date"},
        { caption: "Falsa anomalia", dataField: "falsaAnomalia" },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Modifica",
                    icon: "edit",
                    onClick: (e: any) => {
                        
                        setObjtipo(e.row.data)
                        setImmediate(true)
                        setOpenPop(true)
                    }
                }]
        }
    ];

    const itemsFields = [
        { type: 'date', idValue: 'dal', label: 'Dal', require: false },
        { type: 'date', idValue: 'al', label: 'Al', require: false },
        { type: 'select', idValue: 'descrizione', label: 'Stato anomalie', require: false, displayExpr: 'descrizione', dataSource: anomalieFiltri, width: 250, defaultValue: 'Non risolte', valueExpr: 'descrizione'},
    ];

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

        getAnomalie();
    }, [auth]);

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "data") {

            let tooltip = "";
            switch (e.column.dataField) {
                case "descrizioneFarmacia":
                    tooltip = e.data.nota
            }

            /*
            if (!tooltip) {
                tooltip = "nessuna risoluzione"
            }*/

            if (tooltip) {
                e.cellElement.title = tooltip;
            }
        }
    }, [])

    return (
        <>
            <DialogModificaAnomalia
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={()=>{setOpenPop(false);setImmediate(false);setObjtipo(null)}}
                open={openPop} />

            {titolo}
            {loadPanel}
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>

            <NewTabella
                idTabella='elenco_anomalie_faramcie'
                sync
                colonne={columnsAnomalie}
                dataSource={elencoAnomalie}
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "ElencoAnomalieFarmacie"
                }}
                activeSaver={true}
                columnC={true}
                headerFilter
                onCellPrepared={onCellPrepared}
                wordWrapEnabled={true}
            />
        </>

    )
}        

export default ElencoAnomalieFarmacie;