import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ElementOptionChanged, SelectOptionChangeInterface } from '../interfaces/componentOptions';
import { OggettoInterface } from '../interfaces/generiche';
import HtmlEditor, { Item, MediaResizing, Toolbar } from 'devextreme-react/html-editor';
import CheckBox from 'devextreme-react/check-box';
import TextBox from 'devextreme-react/text-box';
import DateBox from 'devextreme-react/date-box';
import { NumberBox, TextArea, Validator } from 'devextreme-react';
import { ValidationRule } from 'devextreme-react/data-grid';

export const BoxTestoCerca = (props: {
    placeholder: string,
    id: string,
    startRicerca: (value: string) => void,
    inputCerca?: string,
    setInputCerca: (value: string) => void,
    setSearchBoxRefInstance?: (searchBoxRef: any) => void,
    disabled?: boolean
    text?: string
}) => {
    const searchBoxRef = useRef<any>(null);

    useEffect(() => {
        if (props.setSearchBoxRefInstance) props.setSearchBoxRefInstance(searchBoxRef.current);
    }, [props.setSearchBoxRefInstance])
    //setta lo stato del componente
    const valueChanged = (e: any) => {
        props.setInputCerca(e.value)
    }
    //fa partire la ricerca quando si preme invio con il focus nel componente
    const ricerca = () => {
        if (props.inputCerca) props.startRicerca(props.inputCerca)
    }

    return (
        <TextBox
            ref={searchBoxRef}
            value={props.inputCerca}
            onValueChanged={valueChanged}
            placeholder={props.placeholder}
            onEnterKey={ricerca}
            id={props.id}
            text={props.text}
            disabled={ props.disabled}
        />
    )
}

export const BoxDataOra = (props: {
    typeDate: "date" | "time" | "datetime",
    impostaFiltri: (newFiltro: object) => void,
    id: string,
    value?: string,
    disabled?: boolean,
}) => {
    const [data, setData] = useState<string | number | Date | undefined>();
    useEffect(() => {
        if (props.value) {
            setData(new Date(props.value))
        } else {
            setData(props.value)
        }
    }, [props.value])

    const cambiaValore = (e: SelectOptionChangeInterface) => {
        if (e.value) {
            let newFiltro: OggettoInterface = {};
            newFiltro[props.id] = e.value;
            props.impostaFiltri(newFiltro);
            setData(e.value);
        }
    };

    const formato = () => {
        let format: string;
        if (props.typeDate === "date") {
            return format = "dd/MM/yyyy"
        } else if (props.typeDate === "datetime") {
            return format = "dd/MM/yyyy HH:mm:ss"
        }
    }
    return (
        <DateBox
            type={props.typeDate}
            onValueChanged={cambiaValore}
            value={data}
            displayFormat={formato()}
            disabled={props.disabled}
        />
    )
}

export const BoxFiltro = (props: {
    placeholder?: string,
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    value?: string,
    disabled?: boolean,
    maxLength?: number,
    visible?: boolean,
}) => {
    const [text, setText] = useState<string>();
    useEffect(() => {
        setText(props.value)
    }, [props.value]);
    let placeholder = " ";
    if (props.placeholder) placeholder = props.placeholder;
    let disabled = false;
    let visible = true;
    let maxLength;
    if (props.disabled) disabled = props.disabled;
    if (props.maxLength) maxLength = props.maxLength;
    if (props.visible != undefined) visible = props.visible;
    //memorizza il valore del componente all'interno dell'oggetto filtro tramite impostaFiltri -> restituisce una stringa
    const cambiaValore = (e: SelectOptionChangeInterface) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = e.value
        props.impostaFiltri(newFiltro);
        setText(e.value);
    }
    return (
        <TextBox
            onValueChanged={cambiaValore}
            placeholder={placeholder}
            value={text}
            disabled={disabled}
            maxLength={maxLength}
            visible={visible}
        >

        </TextBox>
    )
}

export const Check = (props: {
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    text?: string,
    checkValue?: boolean,
    disabled?: boolean
}) => {
    const [checkValue, setCheckValue] = useState<boolean>();
    useEffect(() => {
        //il componente devExtreme CheckBox ha anche il valore undefined che non usiamo quindi se non viene passato diventa false
        if (props.checkValue === undefined) {
            setCheckValue(false);
        } else {
            setCheckValue(props.checkValue)
        }
    }, [props.checkValue])
    //memorizza il valore del componente all'interno dell'oggetto filtro tramite impostaFiltri -> restituisce true/false
    const cambiaValore = (e: SelectOptionChangeInterface) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = e.value
        props.impostaFiltri(newFiltro);
        setCheckValue(e.value);
    }

    return (
        <CheckBox
            text={props.text}
            onValueChanged={cambiaValore}
            value={checkValue}
            disabled={ props.disabled}
        />
    )
}


export const EditorHtml = (props: {
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    defaultValue: string,

}) => {

    const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
    const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
    const headerValues = [false, 1, 2, 3, 4, 5];
    const [editorValue, setEditorValue] = useState<string>();

    const cambiaValore = (e: any) => {
        //const param = props.id;
        //let newFiltro: OggettoInterface = {};
        //newFiltro[param] = e.value
        //if (props.impostaFiltri) props.impostaFiltri(newFiltro);
        
    }

    useEffect(() => {
        setEditorValue(props.defaultValue)
    }, [props.defaultValue]);

    return (
        <HtmlEditor
            defaultValue={props.defaultValue}
            id={props.id}
            onValueChanged={cambiaValore}
        >
            <MediaResizing enabled={true} />
            <Toolbar multiline={true}>
                <Item formatName="undo" />
                <Item formatName="redo" />
                <Item formatName="separator" />
                <Item
                    formatName="size"
                    formatValues={sizeValues}
                />
                <Item
                    formatName="font"
                    formatValues={fontValues}
                />
                <Item formatName="separator" />
                <Item formatName="bold" />
                <Item formatName="italic" />
                <Item formatName="strike" />
                <Item formatName="underline" />
                <Item formatName="separator" />
                <Item formatName="alignLeft" />
                <Item formatName="alignCenter" />
                <Item formatName="alignRight" />
                <Item formatName="alignJustify" />
                <Item formatName="separator" />
                <Item formatName="orderedList" />
                <Item formatName="bulletList" />
                <Item formatName="separator" />
                <Item
                    formatName="header"
                    formatValues={headerValues}
                />
                <Item formatName="separator" />
                <Item formatName="color" />
                <Item formatName="background" />
                <Item formatName="separator" />
                <Item formatName="link" />
                <Item formatName="image" />
                <Item formatName="separator" />
                <Item formatName="clear" />
                <Item formatName="codeBlock" />
                <Item formatName="blockquote" />
            </Toolbar>
        </HtmlEditor>
        )
}


export const BoxTextArea = (props: {
    placeholder?: string,
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    value?: string,
    disabled?: boolean,
}) => {
    const [text, setText] = useState<string>();
    useEffect(() => {
        setText(props.value)
    }, [props.value]);
    let placeholder = " ";
    if (props.placeholder) placeholder = props.placeholder;
    let disabled = false;
    if (props.disabled) disabled = props.disabled;
    //memorizza il valore del componente all'interno dell'oggetto filtro tramite impostaFiltri -> restituisce una stringa
    const cambiaValore = (e: SelectOptionChangeInterface) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = e.value
        props.impostaFiltri(newFiltro);
        setText(e.value);
    }
    return (
        <TextArea
            onValueChanged={cambiaValore}
            placeholder={placeholder}
            value={text}
            disabled={disabled}
            height={500}
        />
    )
}


export const BoxTextAreaTicket = (props: {
    placeholder?: string,
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    value?: string,
    disabled?: boolean,
}) => {
    const [text, setText] = useState<string>();
    useEffect(() => {
        setText(props.value)
    }, [props.value]);
    let placeholder = " ";
    if (props.placeholder) placeholder = props.placeholder;
    let disabled = false;
    if (props.disabled) disabled = props.disabled;
    //memorizza il valore del componente all'interno dell'oggetto filtro tramite impostaFiltri -> restituisce una stringa
    const cambiaValore = (e: SelectOptionChangeInterface) => {
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = e.value
        props.impostaFiltri(newFiltro);
        setText(e.value);
    }
    return (
        <TextArea
            onValueChanged={cambiaValore}
            placeholder={placeholder}
            value={text}
            disabled={disabled}
            height={250}
        />
    )
}

export const NumberBoxFiltro = (props: {
    placeholder?: string,
    id: string,
    impostaFiltri: (newFiltro: object) => void,
    value?: string,
    disabled?: boolean,
    visible?: boolean,
    max?: number,
    format?: string,
    step?:number
}) => {
    const [text, setText] = useState<number>();
    useEffect(() => {
        let n = Number(props.value)
        setText(n)
    }, [props.value]);
    let placeholder = " ";
    if (props.placeholder) placeholder = props.placeholder;
    let disabled = false;
    let visible = true;
    let max;
    let format = "";
    let step = 1;
    if (props.disabled) disabled = props.disabled;
    if (props.max) max = props.max;
    if (props.format) format = props.format;
    if (props.visible != undefined) visible = props.visible;
    if (props.step) step = props.step
    //memorizza il valore del componente all'interno dell'oggetto filtro tramite impostaFiltri -> restituisce una stringa
    const cambiaValore = (e: any) => {

        console.log(props)
        console.log(e)

        if (props.max != undefined && props.max > 0 && e.value > props.max)
            return;
        const param = props.id;
        let newFiltro: OggettoInterface = {};
        newFiltro[param] = e.value
        props.impostaFiltri(newFiltro);
        setText(e.value);
    }
    return (
        <NumberBox
            onValueChanged={cambiaValore}
            placeholder={placeholder}
            value={text}
            disabled={disabled}
            visible={visible}
            step={step}
            min={0}
            max={max}
            format={ format}
            showSpinButtons={true}
            showClearButton={true}
            
        >

        </NumberBox>
    )
}