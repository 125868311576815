import React, { useState, useEffect } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import { urlCommon, urlConsultazione } from "../../costanti";
import { useAuth } from "../../../hooks/useAuth";
import { RestServices } from "../../../services/restServices";
import notify from "devextreme/ui/notify";
import { Loading } from "../../../widget/Notifications";
import TextBox from "devextreme-react/text-box";
import SelectBox from "devextreme-react/select-box";
import { AutocompleteBox } from "../../../widget/Autocomplete"
import Form, { GroupItem } from "devextreme-react/form";
import { SimpleItem } from "devextreme-react/tree-list";


interface DialogProps {
    open: boolean;
    idAnaIndirizzi: number;
    close: () => void;
    idAnaGenerale: number;
    closeAndReload: () => void

}

export const DialogAnaFornCenIndirizzo: React.FC<DialogProps> = ({
    open,
    idAnaIndirizzi,
    close,
    idAnaGenerale,
    closeAndReload
}) => {

    const auth = useAuth(urlCommon.auth);
    const [openD, setOpenD] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [objctTOsave, setObjctTOsave] = useState<any>({});
    const [objctTOedit, setObjctTOedit] = useState<any>({});
    const [tipoInrizzo, setTipoIndirizzo] = useState<any>([]);
    const [tipoEmail, setTipoEmail] = useState<any>([]);
    const [tipoLabelEmail, setTipoLabelEmail] = useState<any>([]);
    const [tipoTelefono, setTipoTelefono] = useState<any>([]);
    const [disableButton, setDisableButton] = useState<boolean>(true)


    useEffect(() => {
        if (auth) {
            setOpenD(open)
        }
        
    }, [auth])

    useEffect(() => {
        
        if (openD) {
            getIndirizzo()
            getListTipoIndirizzo()
            getListTipoEmail()
            getListTipoLabelEmail()
            getListTipoTelefono()
        }
        
    }, [openD])


    useEffect(() => {
        if (objctTOedit) {

            if (objctTOedit.indirizzo?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit.cap?.length > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

            if (objctTOedit.idTipIndirizzo > 0) {
                setDisableButton(false)
            } else {
                setDisableButton(true)
                return;
            }

        }

    }, [objctTOedit])


    const getIndirizzo = () => {

        if (idAnaIndirizzi) {
            let params = { idAnaIndirizzi: idAnaIndirizzi };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getIndirizzo)
                    .then(result => {
                        if (result?.esito) {
                            setObjctTOsave(result.response)
                            setObjctTOedit(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        }
        else {
            setObjctTOsave({idAnaGenerale: idAnaGenerale});
        }

    }

    const getListTipoIndirizzo = () => {

        //if (idAnaIndirizzi) {
            setLoading(true)
            let params = { };
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoIndirizzo)
                    .then(result => {
                        if (result?.esito) {
                            setTipoIndirizzo(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        //}

    }

    const getListTipoEmail = () => {

        //if (idAnaIndirizzi) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoEmail)
                    .then(result => {
                        if (result?.esito) {
                            setTipoEmail(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        //}

    }

    const getListTipoLabelEmail = () => {

        //if (idAnaIndirizzi) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoLabelEmail)
                    .then(result => {
                        if (result?.esito) {
                            setTipoLabelEmail(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }

        //}

    }

    const getListTipoTelefono = () => {

        //if (idAnaIndirizzi) {
            setLoading(true)
            let params = {};
            if (auth) {
                RestServices().rest('POST', params, urlCommon.getListTipoTelefono)
                    .then(result => {
                        if (result?.esito) {
                            setTipoTelefono(result.response)
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }

        //}

    }

    const cambiaValore = (tipo: string, value: string) => {
        let objTo = { ...objctTOsave }
        objTo[tipo] = value

        //console.log(objTo)

        setObjctTOsave(objTo)

        if (objTo.indirizzo?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.cap?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

        if (objTo.idTipIndirizzo > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
            return;
        }

    }

    const salvaIndirizzo = () => {
        setLoading(true)
        if (idAnaIndirizzi) {
            if (auth) {
                RestServices().rest('POST', objctTOsave, urlCommon.updateAltroIndirizzo)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                notify({ position: "center", width: "auto", message: 'Indirizzo aggiornato' }, "success", 4000)
                                closeAndReload();
                            }
                            else {
                                notify({ position: "center", width: "auto", message: 'Indirizzo non aggiornato' }, "error", 4000)
                            }
                            setLoading(false)
                            
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }
        else {
            if (auth) {
                RestServices().rest('POST', objctTOsave, urlCommon.addAltroIndirizzo)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                notify({ position: "center", width: "auto", message: 'Indirizzo inserito' }, "success", 4000)
                                closeAndReload();
                            }
                            else {
                                notify({ position: "center", width: "auto", message: 'Indirizzo non inserito' }, "error", 4000)
                            }
                            setLoading(false)
                        } else {
                            //console.log(result);
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }
        }
        
    }

    return (
        <>
            

            <Popup
                visible={openD}
                showCloseButton={false}
                width={'70%'}
                height={'90%'}
                showTitle={true}
                title="Visualizzazione indirizzo"
            >
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: "Chiudi",
                        onClick: () => close(),
                    }

                    }
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        text: 'Salva',
                        disabled: disableButton,
                        elementAttr: {
                            class: 'btncreatecluster',
                        },
                        onClick: () => salvaIndirizzo(),
                    }}
                />

                <Loading isVisible={loading} />


                <Form>
                    <GroupItem
                        caption="Dati Indirizzo"
                        colSpan={1}
                    >

                            <div className='form-row '>
                                <div className="form-group col-md-4">
                                    <label> INDIRIZZO </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.indirizzo} onValueChanged={(v) => cambiaValore('indirizzo', v.value)} placeholder="..." />
                                </div>

                                <div className="form-group col-md-4">
                                    <label> NUMERO CIVICO </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.numero} onValueChanged={(v) => cambiaValore('numero', v.value)} placeholder="..." />
                                </div>

                                <div className="form-group col-md-4">
                                    <label> TIPO </label>
                                <SelectBox  dataSource={tipoInrizzo} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipIndirizzo} onValueChanged={(v) => cambiaValore('idTipIndirizzo', v.value)} />
                                </div>
                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-4">
                                <label> CAP </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.cap} onValueChanged={(v) => cambiaValore('cap', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-4">
                                <label> CITTA'' </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.citta} onValueChanged={(v) => cambiaValore('citta', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-4">
                                <label> PROVINCIA </label>
                                <AutocompleteBox typeApi={"provincie"} idApi={"id"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idProvincia"} descExpr={"descrizioneProvincia"} idPost={"id"} descPost={"descrizione"} idControl={"province"} ></AutocompleteBox>
                            </div>
                        </div>
                    </GroupItem>

                    <GroupItem caption="Dati Email">
                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> EMAIL </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.email} onValueChanged={(v) => cambiaValore('email', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-6">
                                <label> NOTE </label>
                                <TextBox valueChangeEvent="keyup"value={objctTOsave.noteAnaRecapitiEmail} onValueChanged={(v) => cambiaValore('noteAnaRecapitiEmail', v.value)} placeholder="..." />
                            </div>

                        </div>
                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> TIPO </label>
                                <SelectBox dataSource={tipoEmail} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipEmail} onValueChanged={(v) => cambiaValore('idTipEmail', v.value)} />
                            </div>

                            <div className="form-group col-md-6">
                                <label> IDENTIFICATIVO ETICHETTA </label>
                                <SelectBox dataSource={tipoLabelEmail} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipLabelEmail} onValueChanged={(v) => cambiaValore('idTipLabelEmail', v.value)} />
                            </div>

                        </div>
                    </GroupItem>
                    <GroupItem caption="Dati Telefono">
                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> NUMERO TELEFONICO </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.numeroTelefono} onValueChanged={(v) => cambiaValore('numeroTelefono', v.value)} placeholder="..." />
                            </div>

                            <div className="form-group col-md-6">
                                <label> NOTE </label>
                                <TextBox valueChangeEvent="keyup"  value={objctTOsave.noteAnaRecapitiTelefono} onValueChanged={(v) => cambiaValore('noteAnaRecapitiTelefono', v.value)} placeholder="..." />
                            </div>

                        </div>

                        <div className='form-row '>
                            <div className="form-group col-md-6">
                                <label> TIPO </label>
                                <SelectBox dataSource={tipoTelefono} displayExpr="descrizione" valueExpr="id" valueChangeEvent="keyup" value={objctTOsave?.idTipNumeroTelefono} onValueChanged={(v) => cambiaValore('idTipNumeroTelefono', v.value)} />
                            </div>


                        </div>
                    </GroupItem>
                </Form>
                
                
                

            </Popup>



        </>
    )
}