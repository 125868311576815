import React, { useEffect, useState } from "react";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/esm/Button';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OggettoInterface } from "../../interfaces/generiche";
import { ElencoOrdiniInterface, ListaStatoNSOInterface, ListaTipoDocumentoNSOInterface } from "../../interfaces/ordiniNso";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { BoxDataOra } from "../../widget/Editor";
import { Loading } from "../../widget/Notifications";
import { Selezione, TendinaFarm } from "../../widget/Select";
import { basenameHost, urlOrdiniNso } from "../costanti";
import notify from "devextreme/ui/notify";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { DrawerFiltri } from "../../widget/Drawer";
import { TabellaCompleta } from "../../widget/Tabelle";
import { DialogSto } from "./Dialog";

const OrdiniNso = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const titolo = TitoloContenuto("Ordini NSO");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const auth = useAuth(urlOrdiniNso.auth);

    const [openDrawer, setOpenDrawer] = useState(true);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [aggiornaTabDett, setAggiornaTabDett] = useState(1);

    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [elencoOrdini, setElencoOrdini] = useState<ElencoOrdiniInterface[]>([]);
    const [dal, setDal] = useState<string>();
    const [idOrdineSelez, setIdOrdineSelez] = useState<number>();
    const [numeroOrdineSelez, setNumeroOrdineSelez] = useState<string>();
    const [isConfermaOpen, setIsConfermaOpen] = useState(false);
    const [dialogStoIsOpen, setDialogStoIsOpen] = useState(false);

    const [listaStatoNSO, setListaStatoNSO] = useState<ListaStatoNSOInterface[]>();
    const [listaTipoDocumentoNSO, setListaTipoDocumentoNSO] = useState<ListaTipoDocumentoNSOInterface[]>();

    const handleCloseStorico = () => setDialogStoIsOpen(false);

    const [numeroOrdiniInValutazione, setNumeroOrdiniInValutazione] = useState<number>(0);

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();


    useEffect(() => {
        const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        setDal(dataInizio.toString());
        setFiltri({
            dal: dataInizio.toString(),
            al: (new Date()).toString()
        })
    }, [])

    //useEffect(() => {
    //    const dataInizio = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
    //    setDal(dataInizio.toString());
    //    if (props.datiFarmacie && props.datiFarmacie.length > 0) {
    //        const listaFarmacie: number[] = [];
    //        props.datiFarmacie.forEach(farm => listaFarmacie.push(farm.ID_FAR));
    //        setFiltri({
    //            dal: dataInizio.toString(),
    //            al: (new Date()).toString(),
    //            lstFar: listaFarmacie,
    //        })
    //    }
    //}, [props.datiFarmacie])

    //useEffect(() => {
    //    if (filtri.lstFar && filtri.lstFar.length === 0) {
    //        const listaFarmacie: number[] = [];
    //        props.datiFarmacie.forEach(farm => listaFarmacie.push(farm.ID_FAR));
    //        const nuovoFiltro = { ...filtri, lstFar: listaFarmacie }
    //        setFiltri(nuovoFiltro);
    //    }
    //}, [filtri.lstFar])

    useEffect(() => {
        if (auth) {
            if (listaStatoNSO == undefined) {
                const param = {
                };
                RestServices().rest('POST', param, urlOrdiniNso.getListStatoNSO)
                    .then(result => {
                        if (result) {
                            setListaStatoNSO(result);
                        }
                    })
            }
            if (listaTipoDocumentoNSO == undefined) {
                const param = {
                };
                RestServices().rest('POST', param, urlOrdiniNso.getListTipoDocumentoNso)
                    .then(result => {
                        if (result) {
                            setListaTipoDocumentoNSO(result);
                        }
                    })
            }
            if (filtri && filtri.dal && filtri.al) startCerca();
        }
    }, [auth])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const creaRiepilogoFiltri = () => {
        //let descrFarm: string[] = [""];
        //if (props.datiFarmacie && filtri['lstFar']) {
        //    for (let i = 0; i < filtri['lstFar'].length; i++) {
        //        descrFarm[i] = props.datiFarmacie.filter((item) => {
        //            return item.ID_FAR === filtri['lstFar'][i]
        //        })[0].DESCRIZIONE
        //    }
        //}
        //const elencoFarmSelez = () => {
        //    if (filtri.lstFar.length === props.datiFarmacie.length) {
        //        return "Tutte"
        //    } else {
        //        const spanDescr = descrFarm.map((descr) => {
        //            return <span className="cerca" key={descr}>{descr}&nbsp;</span>
        //        })
        //        return spanDescr;
        //    }
        //}

        return (
            <>
                {/*{(filtri.lstFar) && <span className="cerca">&nbsp;Farmacia: {elencoFarmSelez()}</span>}*/}
                {(filtri.dal) && <span className="cerca">&nbsp;Dal: {cambiaFormatoLocalDateTimeToEuropeo(filtri.dal)}</span>}
                {(filtri.al) && <span className="cerca">&nbsp;Al: {cambiaFormatoLocalDateTimeToEuropeo(filtri.al)}</span>}
                {(filtri.stato) && <span className="cerca">&nbsp;Stato: {listaStatoNSO?.filter(st => st.id == filtri.stato)[0].descrizione}</span>}
                {(filtri.tipoDocumento) && <span className="cerca">&nbsp;Tipo: {listaTipoDocumentoNSO?.filter(st => st.id == filtri.tipoDocumento)[0].descrizione}</span>}
            </>
        )
    }


    

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                {/*<Col sm="3">*/}
                {/*    <label>Farmacia</label>*/}
                {/*    <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} />*/}
                {/*</Col>*/}
                <Col sm="1">
                    <label>Dal *</label>
                    <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                </Col>
                <Col sm="1">
                    <label>Al *</label>
                    <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={(new Date()).toString()} />
                </Col>
                <Col sm="2">
                    <label>Stato</label>
                    <Selezione id="stato" impostaFiltri={impostaFiltri} dataSource={listaStatoNSO} displayExpr="descrizione" valueExpr="id" searchEnabled={true} />
                </Col>
                <Col sm="2">
                    <label>Tipo</label>
                    <Selezione id="tipoDocumento" impostaFiltri={impostaFiltri} dataSource={listaTipoDocumentoNSO} displayExpr="descrizione" valueExpr="id" searchEnabled={true} />
                </Col>
                <Col sm="1">
                    <Button type="button" variant="success" id="btnCerca" onClick={startCerca}>Cerca</Button>
                </Col>
            </Row>

        )
    }

    const startCerca = () => {
        if (auth) {
        if (filtri) {
            if (!filtri.dal) {
                notify({ position: "center", width: "auto", message: "Selezionare la data di inizio" }, 'error', 4000);
            } else if (!filtri.al) {
                notify({ position: "center", width: "auto", message: "Selezionare la data di fine" }, 'error', 4000);
            } else {
                setIsVisible(true);

                const param = {
                    lstFar: filtri.lstFar,
                    dal: filtri.dal,
                    al: filtri.al,
                    stato: filtri.stato,
                    tipoDocumento: filtri.tipoDocumento,
                    //numeroDocumento: filtri.numeroDocumento
                }
                param.dal = trasformaDateTimeInLocale(param.dal, true);
                param.al = trasformaDateTimeInLocale(param.al, true);
                RestServices().rest('POST', param, urlOrdiniNso.ordiniList)
                    .then(result => {
                        setIsVisible(false);
                        if (result) {
                            if (result.esito === true) {
                                //result.response.forEach((item: ElencoOrdiniInterface) => {
                                //    if (props.datiFarmacie.length > 0) {
                                //        const descrFarm = props.datiFarmacie.filter((farm) => {
                                //            return farm.ID_FAR === item.idFarCF
                                //        })[0].DESCRIZIONE
                                //        item.descrFarm = descrFarm;
                                //    }
                                //})
                                setOpenDrawer(false);
                                setElencoOrdini(result.response);
                            }
                            else if (result.esito === false) {
                                notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                            }
                        }
                        else {
                            notify({ position: "center", width: "auto", message: "Oops c'� stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                        }
                    })
            }
        } else {
            notify({ position: "center", width: "auto", message: "Inserire i parametri di ricerca richiesti" }, 'error', 4000);
            }
        }
    };

    const propsTabElencoOrdini: TabellaCompletaInterface = {
        id: "elenco_ordine_nso",
        dataSource: elencoOrdini,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {

            totalItems: [{
                name: "TotaleOrdini",
                showInColumn: "Numero documento",
                displayFormat: "Documenti: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Ordini_NSO"
        }
    }

    const columnsOrdiniNso = CreaColonne([
        { caption: "Id", dataField: "idOrdCF", visible: false },
        { caption: "Numero documento", dataField: "numeroDocumento" },
        { caption: "Numero doc. padre", dataField: "numeroDocumentoPadre" },
        { caption: "P.Iva", dataField: "partitaIva", visible: false},
        { caption: "Cliente", dataField: "denominazioneCliente" },
        { caption: "Data documento", dataField: "dataDocumento", format: "dd/MM/yyyy", dataType: "date", sortOrder: "desc" },
        { caption: "Tipo", dataField: "tipoDocumento" },
        { caption: "Stato", dataField: "statoOrdine" },
        { caption: "Importo", dataField: "importo", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Token", dataField: "fileToken", visible: false },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Visualizza sorgente",
                icon: "file",
                onClick: (e: any) => {
                    const fileToken = e.row.data.fileToken;
                    window.open(basenameHost + 'cfportal/view/' + fileToken, "_blank");
                }
            },
                {
                    hint: "Visualizza documento",
                    icon: "contentlayout",
                    onClick: (e: any) => {
                        const fileToken = e.row.data.fileToken;
                        window.open(basenameHost + 'cfportal/view/' + fileToken + '_htm', "_blank");
                    }
                },
                {
                    hint: "Visualizza storico",
                    icon: "detailslayout",
                    onClick: (e: any) => {
                        setIdOrdineSelez(e.row.data.idOrdCF);
                        setNumeroOrdineSelez(e.row.data.numeroDocumento)
                        setDialogStoIsOpen(true);
                    }
                }
            ]
        }
    ])

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaOrdiniRda" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={openDrawer} setOpen={setOpenDrawer} />
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        {aggiornaTabDett &&
                            <TabellaCompleta
                                propsTabella={propsTabElencoOrdini}
                                columns={columnsOrdiniNso}
                                setTabellaInstance={setTabellaInstance}
                                //onRowPrepared={onRowPrepared}
                                rowAlternationEnabled={false}
                                allowColumnResizing={true}
                                selectedRowKeys={selectedRowKeys}
                            />}
                    </Col>
                </Row>
                <DialogSto
                    dialogStoIsOpen={dialogStoIsOpen}
                    idOrdine={idOrdineSelez}
                    handleCloseSto={handleCloseStorico}
                    numeroDocumento={numeroOrdineSelez}
                />
            </div>
        </>
    )

}

export default OrdiniNso;