import React from 'react'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { ContrattiFarmaciaInterface, ServiziFarmaciaInterface } from '../../interfaces/myCsf';
import { TabellaSemplice } from '../../widget/Tabelle';

const ContrattiEServizi = (props: {
    contratti: ContrattiFarmaciaInterface[],
    servizi: ServiziFarmaciaInterface[],
    concessionari: boolean
}) => {
    //proprietà della tabella dei Contratti
    const propsTabContratti = {
        id: "anaContrattiFarmacia",
        dataSource: props.contratti,
        height: "auto",
        filterRow: true
    }
    const columnsContratti = CreaColonne([
        { caption: "Codice Contratto", dataField: "codiceContratto" },
        { caption: "Descrizione Contratto", dataField: "descrizioneContratto", sortOrder: "asc" },
        { caption: "Stato Contratto", dataField: "statoContratto" },
        { caption: "Data Prima Fatturazione", dataField: "dataPrimaFatturazione", format: 'dd/MM/yyyy', dataType: "date" },
        { caption: "Data Inizio Competenza", dataField: "ultimaFatturaDa", format: 'dd/MM/yyyy', dataType: "date" },
        { caption: "Data Fine Competenza", dataField: "ultimaFatturaA", format: 'dd/MM/yyyy', dataType: "date" }
    ]);
    //proprietà della tabella dei Servizi
    const propsTabServizi = {
        id: "anaServiziFarmacia",
        dataSource: props.servizi,
        height: "auto",
        filterRow: true
    }
    const columnsServizi = CreaColonne([
        { caption: "Codice Servizio", dataField: "codice" },
        { caption: "Descrizione Servizio", dataField: "descrizione", sortOrder: "asc" },
        { caption: "Tipo Servizio", dataField: "tipo" }
    ]);
    //tramite lo spread operator si possono riutilizzare le stesse proprietà delle tabelle, cambiandone solo l'id
    const propsTabContrattiConcess = { ...propsTabContratti, id: "anaContrattiConcessionario" };
    const propsTabServiziConcess = { ...propsTabServizi, id: "anaServiziConcessionario" };

    return (
        <>
            <Row className="contratti">
                <Col sm="12">
                    {(props.concessionari) ? <TabellaSemplice propsTabella={propsTabContrattiConcess} columns={columnsContratti} /> : <TabellaSemplice propsTabella={propsTabContratti} columns={columnsContratti} />}
                </Col>
            </Row>
            <Row className="servizi">
                <Col sm='12'>
                    {(props.concessionari) ? <TabellaSemplice propsTabella={propsTabServiziConcess} columns={columnsServizi} /> : <TabellaSemplice propsTabella={propsTabServizi} columns={columnsServizi} />}
                </Col>
            </Row>
        </>
    )
}

export default ContrattiEServizi