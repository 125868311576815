import { DataGrid } from 'devextreme-react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { cambiaFormatoValuta, parseAsMoney } from '../../funzioni/formatoVariabili'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle'
import { GiacenzaDettInterface, OrdineLiberoQuantitaInterface } from '../../interfaces/bancaDati'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { OggettoInterface } from '../../interfaces/generiche'
import { DatiTabelleGiacenzeInterface, DatiVendutoInterface } from '../../interfaces/ordineLibero'
import { RestServices } from '../../services/restServices'
import { urlCommon, urlOrdiniLiberi } from '../costanti'

const TabelleVendutoLight = (props: {
    listaFarmacie: any[],
    codCsf: number | undefined,
    setIsLoadVisible: (isLoadVisible: boolean) => void,
    quantitaSelezionata: number | undefined,
}) => {
    const [vendutoTotale, setVendutoTotale] = useState(0);
    const [vendutoMedio, setVendutoMedio] = useState(0);
    const [vendutoTotalePrec, setVendutoTotalePrec] = useState(0);
    const [mese, setMese] = useState("");
    const [tabellaEvidenziata, setTabellaEvidenziata] = useState<number>();
    const [datiTabelleVenduto, setDatiTabelleVenduto] = useState<DatiVendutoInterface[]>();

    useEffect(() => {
        const meseNumero = new Date().getMonth();
        const elencoMesi = ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"]
        setMese(elencoMesi[meseNumero]);
    }, [])


    useEffect(() => {
        if (props.listaFarmacie && props.codCsf) {
            props.setIsLoadVisible(true);

            var far;
            far = props.listaFarmacie?.map(({ idFarmacia }) => idFarmacia);

            const param = {
                lstFar: far,
                codCsf: props.codCsf
            }

            if (far.length > 0) {
           
                
            RestServices().rest('POST', param, urlCommon.getVenduto)
                .then(result => {
                    if (result) {
                        let sommaVenduto: number = 0;
                        let sommaVendutoGruppo: number = 0;
                        let sommaVendutoPrec: number = 0;
                        for (let i = 0; i < result.length; i++) {
                            //console.log(result[i]);
                            if (!result[i].magazzino && result[i].venduto[0]) {
                                sommaVendutoGruppo += result[i].venduto[0].TOT
                            }
                            if (!result[i].magazzino && result[i].venduto[0] && props.listaFarmacie.includes(result[i].idFar)) {
                                sommaVenduto += result[i].venduto[0].TOT;
                            }
                            if (!result[i].magazzino && result[i].venduto[1] && props.listaFarmacie.includes(result[i].idFar)) {
                                sommaVendutoPrec += result[i].venduto[1].TOT;
                            }
                        }
                        result.sort((a:any, b:any) => a.idFar - b.idFar); 
                        setDatiTabelleVenduto(result);
                        setVendutoTotale(sommaVenduto);
                        setVendutoTotalePrec(sommaVendutoPrec);
                        var now: any = new Date();
                        var start: any = new Date(now.getFullYear(), 0, 0);
                        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diff / oneDay);
                        if (sommaVendutoGruppo > 0)
                            setVendutoMedio(Math.round((sommaVendutoGruppo / day) * 30));
                        else
                            setVendutoMedio(0);
                        props.setIsLoadVisible(false);
                    } else {
                        props.setIsLoadVisible(false);
                    }
                })


            }


        }
    }, [props.listaFarmacie, props.codCsf])


    useEffect(() => {
        if (props.quantitaSelezionata) setTabellaEvidenziata(props.quantitaSelezionata)
    }, [props.quantitaSelezionata])

    useEffect(() => {

        if (datiTabelleVenduto && props.listaFarmacie) {

            datiTabelleVenduto.sort((a: DatiVendutoInterface, b: DatiVendutoInterface) =>
                (props.listaFarmacie?.find(item => item.idFarmacia === a.idFar)?.descrizioneFarmacia > props.listaFarmacie?.find(item => item.idFarmacia === b.idFar)?.descrizioneFarmacia) ? 1 : ((props.listaFarmacie?.find(item => item.idFarmacia === b.idFar)?.descrizioneFarmacia > props.listaFarmacie?.find(item => item.idFarmacia === a.idFar)?.descrizioneFarmacia) ? -1 : 0))
        }

    }, [datiTabelleVenduto, props.listaFarmacie])

    

    return (
        <>
            {(datiTabelleVenduto && props.codCsf) &&
                <>
                {datiTabelleVenduto.map((venduto: DatiVendutoInterface, index: number) => {
                    if (venduto.idFar != 0)
                        return (
                            <Col sm={props.listaFarmacie.length <= 2 ? "6" : props.listaFarmacie.length == 3 ? "4" : props.listaFarmacie.length >= 4 ? "3" : "3"} key={venduto.idFar} className="colonnaTabVenduto">
                                    <Tabelle
                                        listaFarmacie={props.listaFarmacie}
                                        datiTabelleVenduto={venduto}
                                        index={index}
                                        mese={mese}
                                        tabellaEvidenziata={tabellaEvidenziata}
                                        idFarmacia={venduto.idFar }
                                    />
                                </Col>


                            )


                    })}
                </>
            }
        </>
    )
}

export default TabelleVendutoLight;

const Tabelle = (props: {
    listaFarmacie: any[],
    datiTabelleVenduto: DatiVendutoInterface,
    index: number,
    mese: string,
    tabellaEvidenziata: number | undefined,
    idFarmacia: number,
    //quantita: OrdineLiberoQuantitaInterface[],
}) => {
    const [datiGriglia, setDatiGriglia] = useState<DatiTabelleGiacenzeInterface[]>();
    const [color, setColor] = useState("");
    const [coloreScritta, setColoreScritta] = useState("black");



    useEffect(() => {
        setDatiGriglia(props.datiTabelleVenduto.venduto)
    }, [props.datiTabelleVenduto])

    const colonneTabVenduto = useMemo(() => CreaColonne([
        { caption: "", dataField: "ANNO", width: 35 },
        { caption: "Tot", dataField: "TOT", width: 35 },
        { caption: "Gen", dataField: "Gennaio", },
        { caption: "Feb", dataField: "Febbraio", },
        { caption: "Mar", dataField: "Marzo", },
        { caption: "Apr", dataField: "Aprile", },
        { caption: "Mag", dataField: "Maggio", },
        { caption: "Giu", dataField: "Giugno", },
        { caption: "Lug", dataField: "Luglio", },
        { caption: "Ago", dataField: "Agosto", },
        { caption: "Set", dataField: "Settembre", },
        { caption: "Ott", dataField: "Ottobre", },
        { caption: "Nov", dataField: "Novembre", },
        { caption: "Dic", dataField: "Dicembre", }
    ]), [])

    useEffect(() => {

        if (!props.tabellaEvidenziata || (props.tabellaEvidenziata && (props.tabellaEvidenziata !== props.datiTabelleVenduto.idFar || props.tabellaEvidenziata === undefined))) {
            if (props.index % 2 === 0) {
                setColor("lightgoldenrodyellow")
                setColoreScritta("black");
            } else {
                setColor("#ecb363");
                setColoreScritta("black");
            }
        } else {
            if (props.tabellaEvidenziata === props.datiTabelleVenduto.idFar) {
                setColor("red");
                setColoreScritta("white");
            }
        }

    }, [props.index, props.tabellaEvidenziata])

    const onCellPrepared = useCallback((e: any) => {
        e.cellElement.style.fontWeight = "900";
        if (e.column.dataField === props.mese) {
            e.cellElement.style.backgroundColor = "rgb(150, 255, 164)"
        }
    }, [])




    //{datiGiacenzaFarm && <div className="labelTabella" style={{ "backgroundColor": color, "color": coloreScritta }}><b>{datiGiacenzaFarm.descrFarm} - Giacenza: {parseAsMoney(datiGiacenzaFarm.giacenzaVendibili)} - Qta In Ordine : {parseAsMoney(props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar).length > 0 ? props.quantita.filter(f => f.idFar == datiGiacenzaFarm.idFar)[0].quantita : 0)}</b></div>}
    return (
        <>
            {/* {props.datiTabelleVenduto && <div className="labelTabella" style={{ "backgroundColor": props.datiTabelleVenduto.idFar === 0 ? "rgb(150, 255, 164)" : color, "color": coloreScritta }}><b>{props.listaFarmacie.filter((obj:any) => { return obj.idFarmacia === props.datiTabelleVenduto.idFar })[0].descrizioneFarmacia}</b></div>}*/}
            {props.datiTabelleVenduto && <div className="labelTabella" style={{ "backgroundColor": props.datiTabelleVenduto.idFar === 0 ? "rgb(150, 255, 164)" : color, "color": coloreScritta }}><b>{props.listaFarmacie?.find(item => item.idFarmacia === props.idFarmacia)?.descrizioneFarmacia}</b></div>}
            {datiGriglia && <DataGrid
                id={props.datiTabelleVenduto?.idFar.toString()}
                showBorders={true}
                showRowLines={true}
                hoverStateEnabled={true}
                dataSource={datiGriglia}
                onCellPrepared={onCellPrepared}
            >
                {colonneTabVenduto}
            </DataGrid>
            }

        </>
    )
}
