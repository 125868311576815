import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_no_marge } from "../util/trasformation";
import { colAnalisiOpertaori } from "./col/ColGalileo";
import {  PieChart } from 'devextreme-react';
import Button from "react-bootstrap/Button";
// import { DialogOperMerce } from "./dialog/PopOpeMerce";
// import { DialogOperDitta } from "./dialog/PopOpeDitta";
// import { DialogOperFarma } from "./dialog/PopOpeFarma";
import { createItemFilterAcquiFornitori } from "./filter/FiltriGalileo";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { boxAnaAcquiFornitori } from "./boxcards/CardsGalileo";
import { Chart } from "devextreme-react";
import {
    Animation,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    HoverStyle,
    Label,
    Legend,
    Series,
    Subtitle,
    Title,
    Tooltip,
    ValueAxis,
    Format,
    Font
} from "devextreme-react/chart";
import "./style.css";

const GalileoAnalAcquiFornitori = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [ditte, setDitte] = useState<any[]>([]);
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [visiblePop5, setVisiblePop5] = useState(false);
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxAnaAcquiFornitori);
    const [firstpie, setFirstpie] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any>([]);
    const chartRef = useRef<any>(null);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [category, setCategory] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [openPopTwo, setOpenPopTwo] = useState<boolean>(false);
    const [openPopThree, setOpenPopThree] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const dashboard1 = { dashboardID: 1 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    function getCategoryByLevel(categoryList: any[], level: number) {
        return categoryList.filter((category) => category.length === level * 1);
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        // let venditaDPC = [0];
        // let flagBusta = [0];

        // if (params?.venditaDPC) {
        //     venditaDPC = [1];
        // }
        // if (params?.flagBusta) {
        //     flagBusta = [1];
        // }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        let category1;
        let category2;
        let category3;
        let category4;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.category) {
            const categoryList = params.category;
            category1 = getCategoryByLevel(categoryList, 1);
            category2 = getCategoryByLevel(categoryList, 2);
            category3 = getCategoryByLevel(categoryList, 3);
            category4 = getCategoryByLevel(categoryList, 4);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            category1: category1,
            category2: category2,
            category3: category3,
            category4: category4,
            ditta: params.ditta,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall1 = {
                ...dashboard1,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            //console.log('mostro call');
            //console.log(dashboardCall1);

            RestServices()
                .rest("POST", dashboardCall1, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    if (result) {
                        let datiPrimaParteCards = trasform_no_marge(result["39"]["0"], boxAnaAcquiFornitori);
                        
                        let datiPrimaTabelle = result["41"];
                        let datiPrimaTabelle2 = result["41"];

                        setFila1cards(datiPrimaParteCards);
                      
                        setValoriTabella1(datiPrimaTabelle);
                        setValoriTabella2(datiPrimaTabelle2);


                        // 1 Chart
                        setChartData(result[""].reverse());

                        //  1 chartpie
                        let arrayTousePie = [
                            { label: 'SCONTO VENDITA', value: result[""][0]?.NUMERO_VENDITE_SCONTO_TESTATA },
                            { label: 'SCONTO PRODOTTO', value: result[""][0]?.NUMERO_VENDITE_SCONTO_PRODOTTO },
                            { label: 'SCONTO MISTO', value: result[""][0]?.NUMERO_VENDITE_SCONTO_MISTO }
                        ]

                        setFirstpie(arrayTousePie);

                        setIsVisible(false);

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });



            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1° gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

            // const oggi = new Date();

            // testapi.params.dal = convertTime(inizioAnno)
            // testapi.params.al = convertTime(oggi)

            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });


        }
    };


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const itemsFields = createItemFilterAcquiFornitori(
        newFarmaList,
        ditte,
        category
   
    );

    const filters = () => {
        if (auth) {
            RestServices().rest('POST', "", urlBancaDati.getCategory)
                .then(result => {
                    // console.log('vediamoooo categorie');
                    // console.log(result);
                    if (result) setCategory(result);
                })


            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );
                        // console.log('da togliere vediamo ditte');
                        // console.log(ditteWithCodiceDescrizione);

                        setDitte(ditteWithCodiceDescrizione);
                    }
                });

            
        }


    };

    useEffect(() => {
        filters();
    }, [auth]);

    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabella_operatori.csv"
                );
            });
        });
    };

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };

    const popVisible5 = (testo: string) => {
        setVisiblePop5(true);
    };

    let messOne = [", in funzione della tipologia di sconto applicata in vendita. Valore sconto: Rappresenta lo sconto totale applicato in vendita."];
    let mess5 = ["Valore Chart"];

    return (
        <>
            {/* <DialogOperMerce
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        auth={auth}
        close={() => {
          setOpenPop(false);
          setObjtipo(null);
        }}
        filterData={paramsFilter}
        open={openPop}
      />
      <DialogOperDitta
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        auth={auth}
        close={() => {
          setOpenPopTwo(false);
          setObjtipo(null);
        }}
        open={openPopTwo}
        filterData={paramsFilter}
        
      />
      <DialogOperFarma
        ricarica={() => {
          ricarica();
          setObjtipo(null);
        }}
        close={() => {
          setOpenPopThree(false);
          setObjtipo(null);
        }}
        auth={auth}
        filterData={paramsFilter}
        open={openPopThree}
      /> */}
            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", height: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                            <h2>GalileoPro - Acquisti Fornitori </h2>
                        </div>


                        {/* flexbox */}

                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>

                        <div className="grid-ibrid-2">
                            {fila1cards.map((el, i) => {
                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}


                            <div style={{ height: 280, padding: 4, background: "#fff", width:"100%" }} className="AnaForniPie">
                                <PieChart
                                    id="pie"
                                    dataSource={firstpie}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                                >

                                    {/* <Series
                                    argumentField="Label"
                                    valueField="VALORE_VENDUTO"
                                    > */}

                                    <Series
                                        argumentField="label"
                                        valueField="value"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>

                                    {<Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                                            console.log('vediamo value');
                                            console.log(percentText);
                                            const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);

                                            return {
                                                html: `<p>Valore del venduto SSN - ${argument} </p><p>${formattedValue} / ${(percentText)}</p>`,
                                            };
                                        }}
                                    />}
                                    <Title
                                        text="Analisi per tipologia fornitore "
                                    >
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                    <Export enabled={true} />
                                </PieChart>
                                <div style={{ display: "flex" }}>
                                    <button
                                        className="ibutton-lite-two"
                                        style={{ border: "none" }}
                                        onClick={() => popVisible("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "white",
                            marginTop: "5px"
                        }}
                          >
                        <div style={{ width: "93%", margin: "10px" }}>
                            <Chart dataSource={chartData} ref={chartRef}>
                                <CommonSeriesSettings
                                    argumentField="MESE"
                                    type="bar"
                                    hoverMode="allArgumentPoints"
                                    selectionMode="allArgumentPoints"
                                >
                                    <Label visible={true}>
                                        <Format type="currency" currency="EUR" precision={0} />
                                    </Label>
                                </CommonSeriesSettings>

                                <Series
                                    valueField="VEN_SCONTO_VENDITA_PRECEDENTE"
                                    type="bar"
                                    name="2022/2023"
                                    color="rgb(215, 140, 245)"
                                />
                                <Series
                                    valueField="VEN_SCONTO_VENDITA"
                                    type="bar"
                                    argumentField="MESE"
                                    color="rgb(25, 231, 255)"
                                    name="2023/2024"
                                />


                                <Tooltip
                                    enabled={true}
                                    customizeTooltip={({
                                        value,
                                        argument,
                                    }: {
                                        value: any;
                                        argument: any;
                                    }) => {
                                        const formattedValue = parseFloat(value).toLocaleString(
                                            "it-IT",
                                            {
                                                style: "currency",
                                                currency: "EUR",
                                                minimumFractionDigits: 2,
                                            }
                                        );

                                        return {
                                            text: `Valore sconto periodo selezionato: ${formattedValue}`,
                                        };
                                    }}
                                />
                                <Title text="Andamento costo d'acquisto">
                                    <Font color="#5b6372" size="16" weight="400" />
                                    {/* <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Subtitle> */}
                                </Title>
                                <Legend
                                    verticalAlignment="bottom"
                                    horizontalAlignment="center"
                                ></Legend>
                                <Export fileName="venduto_sconto_vendita" enabled={true} />
                            </Chart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
                            <p>
                                <span>
                                    {" "}
                                    <button
                                        className="ibutton-lite"
                                        style={{ border: "none" }}
                                        onClick={() => popVisible5("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                                </span>
                            </p>
                        </div>
                    </div>
                  

                        {/* tabella*/}
                        <div style={{ marginTop: "5px" }}>

                        <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 17, marginTop: 10 }}>Dettaglio Operatore</h6>


                            <NewTabella
                                height={"70vh"}
                                idTabella="analisi_prodotti"
                                sync
                                colonne={colAnalisiOpertaori}
                                dataSource={valoriTabella1}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiProdotti",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                        <TotalItem column="OMAGGI" summaryType="sum" />
                                        <TotalItem
                                            column="COSTO"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                    </Summary>
                                }
                            />
                        </div>


                        <div style={{ marginTop: "5px" }}>

                        <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', fontSize: 17, marginTop: 10 }}>Dettaglio Operatore</h6>


                            <NewTabella
                                height={"70vh"}
                                idTabella="analisi_prodotti"
                                sync
                                colonne={colAnalisiOpertaori}
                                dataSource={valoriTabella2}
                                rowAlternation={true}
                                filterRow
                                width={500}
                                searchPanel={true}
                                exportT={{
                                    enabled: true,
                                    fileName: "AnalisiProdotti",
                                    formats: { exportFormats },
                                }}
                                activeSaver={true}
                                columnC={true}
                                headerFilter
                                wordWrapEnabled={true}
                                onExporting={onExporting}
                                summary={
                                    <Summary>
                                        <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                        <TotalItem column="OMAGGI" summaryType="sum" />
                                        <TotalItem
                                            column="COSTO"
                                            summaryType="sum"
                                            valueFormat={{ style: "currency", currency: "EUR" }}
                                        />
                                    </Summary>
                                }
                            />
                        </div>

                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
            <Popup
                visible={visiblePop}
                showCloseButton={true}
                onHiding={() => setVisiblePop(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop5}
                showCloseButton={true}
                onHiding={() => setVisiblePop5(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{mess5}</p>
                </div>
            </Popup>
        </>
    );
};

export default GalileoAnalAcquiFornitori;
