import React, { useEffect, useState } from 'react'
import NewTabella from "../../../widget/nuova_tabella"
import { Loading } from '../../../widget/Notifications'
import { ListaFarmacieInterface } from '../../../interfaces/farmacie'
import { RestServices } from "../../../services/restServices"
import { urlCommon, urlConsultazione, urlFidelityBe } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import { trasformaDateTimeInLocale } from '../../../funzioni/formatoVariabili';
import { Button } from 'react-bootstrap'
import '.././style.css';

interface PageProps {
    rowData: any;
    setIndietro: () => void;
}

export const MovimentiCoupon: React.FC<PageProps> = ({ rowData, setIndietro }) => {

    const [isVisible, setIsVisible] = useState(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [moveList, setMoveList] = useState([])
    const auth = useAuth(urlFidelityBe.auth);

    const exportMoveList = () => {

        let id = rowData.id;

        let model = {
            id: id
        }

        if (auth) {
            RestServices().rest('POST', model, urlFidelityBe.getListMovimenti)
                .then(result => {
                    setIsVisible(false);

                    if (result) {
                        setIsVisible(false)
                        setMoveList(result.response)
                    }
                })
        }
    }

        const columns = [
            { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "datetime", width: 100 },
            { caption: "Farmacia", dataField: "", dataType: "date", width: 160 },
            { caption: "Operatore", dataField: "", width: 150 },
            { caption: "Terminale", dataField: "" },
            { caption: "Valore", dataField: "valore" },
            { caption: "Residuo", dataField: "", width: 200 },

        ];
   

    useEffect(() => {
        exportMoveList();
        //console.log(rowData)
    }, [auth]);
   
    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> Movimenti Coupon  <span className='subt' > </span> </h3>
            </div>

            <Button style={{marginTop: 20, marginBottom: 10}} className="btn btn-warning" variant="warning" onClick={() => { setIndietro(); }}>INDIETRO</Button>

            <NewTabella
                idTabella='Movimenti_Coupon'
                sync
                colonne={columns}
                dataSource={moveList}
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "Movimenti_Coupon"
                }}
                activeSaver
                columnC
            />


        </>
    )
}

export default MovimentiCoupon


