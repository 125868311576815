import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import { RestServices } from "../../../services/restServices"
import { urlCommon, urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import DateBox from 'devextreme-react/date-box'
import { NewTabella } from "../../../widget/nuova_tabella/newVersion"
import { Loading } from "../../../widget/Notifications"
import { confirm } from 'devextreme/ui/dialog';

interface DialogProps {
	open: boolean,
	idScadenzario: number|undefined,
    close: () => void,
    titolo: string | undefined,
    ricarica: () => void,
}

export const DialogElencoPagamenti: React.FC<DialogProps> = ({ open, idScadenzario,close, titolo, ricarica }) => {

	const [openD, setOpenD] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any[]>([])
    const [isDeleted, setIsDeleted] = useState<boolean>(false)
	//const auth = useAuth(urlConsultazione.auth)


    useEffect(() => {

        setIsDeleted(false)
		setOpenD(open);
        getListPagamenti()

	}, [open]);

    const getListPagamenti = ()  => {
        if (open) {
            let param = { idScadenzario: idScadenzario }
            if (idScadenzario) {
                setLoading(true)
                RestServices().rest('POST', param, urlConsultazione.getListPagamenti)
                    .then(result => {
                        if (result?.esito) {
                            if (result.response) {
                                setLoading(false)
                                setTabellaPrincipale(result.response)
                            }

                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })
            }


        }


    }

    const columns = [
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Data", dataField: "dataPagamento", format: "dd/MM/yyyy", dataType: "date", width: 90, sortOrder: "desc" },
        { caption: "Banca", dataField: "banca" },
        { caption: "CC", dataField: "contoCorrente" },
        { caption: "Annotazione", dataField: "annotazione" },
        { caption: "Importo", dataField: "importo", format: { type: "currency", precision: 2, currency: "EUR" } },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {
                        //console.log (e)
                        if (e.row.data) {
                            let resultD = confirm("<i>Eliminare il pagamento?</i>", "Attenzione");
                            resultD.then((dialogResult) => {
                                if (dialogResult) {
                                    //elimina
                                    setLoading(true)
                                    let param = { flagCicloAttivo: false, idScadenzario: idScadenzario, idScadenzarioPagamento: e.row.data.id }
                                    RestServices().rest('POST', param, urlConsultazione.deletePagamento)
                                        .then(result => {
                                            if (result?.esito) {
                                                if (result.response.esito == true) {
                                                    notify({ position: "center", width: "auto", message: "Pagamento eliminato con successo" }, "success", 4000)
                                                    setLoading(false)
                                                    getListPagamenti()
                                                    setIsDeleted(true)
                                                }

                                            } else {
                                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                                                setLoading(false)
                                            }
                                        })


                                } else {
                                    return;
                                }
                            });
                        }


                    },
                }
            ]
        }
       ]


	return (<>
        <Loading isVisible={loading} />
        <Popup
            visible={openD}
            showCloseButton={false}
            width={'60%'}
            height={'70%'}
            showTitle={true}
            title={titolo}
        >
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Chiudi',
                    onClick: () => { close(); if (isDeleted) ricarica() },
                }}
            />

            <NewTabella
                idTabella='pagamenti'
                height="500"
                sync
                colonne={columns}
                dataSource={tabellaPrincipale}
                filterRow
                searchPanel={false}
                onclickRow={() => null}
                exportT={{
                    enabled: false,
                    fileName: "pagamento_scadenzario"
                }}
                activeSaver={true}
                columnC={false}
            />

        </Popup>


	</>)
}