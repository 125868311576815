import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { Chart, PieChart } from 'devextreme-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { colOsserMargini, colOsserMargini2 } from "./col/ColStatisticheOperative";
import { MenuStatisticheOpera } from "../components/menu_Statistiche_Operative";
import { createItemFilterOssMargini } from "./filters/FilterStatiOper";
import {
    urlBancaDati,
    urlConsultazione,
    urlReport,
} from "../../costanti";
import { CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { LoadIndicator } from "devextreme-react/load-indicator";
import {
    Title,
    Export,
    Font,
    Series,
    Format,
    Label,
    Legend,
    Tooltip,
} from "devextreme-react/pie-chart";
import {
    CommonSeriesSettings,
} from 'devextreme-react/chart';
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";

import "./style.css";

const dashboard5 = { dashboardID: 5 };

export interface boxCard {
    testoPop?: string;
}

export interface boxsecond {
    testoPop?: string;
}


const OsservatorioMargini = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [valoriTabella, setValoriTabella] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);
    const [visiblePop5, setVisiblePop5] = useState(false);
    const [settori, setSettori] = useState<any[]>([]);
    const [category, setCategory] = useState<any[]>([]);
    const [ditte, setDitte] = useState<any[]>([]);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [chartData, setChartData] = useState<any>([]);
    const chartRef = useRef<any>(null);

    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale è mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }


    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];
        let newIDSettore: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }
        let venditaDPC = params?.venditaDPC ? undefined : [0];
        let flagBusta = params?.flagBusta ? undefined : [0];

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            operatori: params.operatori,
            venditaDPC: venditaDPC,
            flagBusta: flagBusta,
            tipologiaProdotto: params.tipologiaProdotto,
            tipologiaConFascia: params.tipologiaConFascia,
            categoria: params.categoria,
            segmento: params.segmento,
            blocco1: params.blocco1,
            blocco2: params.blocco2,
            progetto: params.progetto,
            societa: params.societa,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall5 = {
                ...dashboard5,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall5, urlReport.getQueryDashboardAllData)
                .then((result) => {

                    if (result) {

                        // tabella 1
                        const anno = params.periodo.dal.substring(0, 4);
                        const traform = CruscottoGruppo_traform_analisi(result["38"], anno);
                        setValoriTabella(traform);

                        // tabella 2
                        const traform2 = CruscottoGruppo_traform_analisi(result["38"], anno);
                        setValoriTabella2(traform);


                        // 1 Chart in fondo
                        setChartData(result["47"].reverse());

                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);


                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            //cambio data
            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1° gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }


    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };
    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    let messOne = ["Analisi CìVed"];
    let messTwo = ["Analisi CìVed 222"];
    let messThree = ["Analisi CìVed 3333"];
    let mess5 = ["Valore 5"];

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const itemsFields = createItemFilterOssMargini(
        newFarmaList,
        ditte,
        gruppoMercFilters
    );


    const filters = () => {
        if (auth) {

            RestServices()
                .rest("POST", "", urlBancaDati.getGruppoMerceologico)
                .then((result) => {
                    if (result) setGruppoMercFilters(result);
                });
      

            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );
                        // console.log('da togliere vediamo ditte');
                        // console.log(ditteWithCodiceDescrizione);

                        setDitte(ditteWithCodiceDescrizione);
                    }
                });
        }
        const ssn = { id: 1, descrizione: "SSN" };
        const libera = { id: 0, descrizione: "Libera" };

        const vendite = [libera, ssn];
        setTipologiaVendita(vendite);

    };

    useEffect(() => {
        filters();
    }, [auth]);



    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabella_operatori.csv"
                );
            });
        });
    };



    function formatLabel(arg: { argumentText: any; valueText: any }) {
        return `${arg.argumentText}: ${arg.valueText}%`;
    }



    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);



    const popVisible = (testo: string) => {
        setVisiblePop(true);
        setTestoPop(testo);
    };

    const popVisible5 = (testo: string) => {
        setVisiblePop5(true);
    };



    return (
        <section style={{ display: "flex", height: "98vh" }}>
            {loadPanel}
            {loadinCambio ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                </div>
            ) : (
                <section
                    id="scrollerelement"
                    style={{ width: "100%", overflow: "scroll" }}
                >
                    <div
                        style={{
                            fontSize: "15px",
                            alignItems: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "rgb(80, 84, 92)",
                        }}
                    >
                        <h2>Business Lab </h2>
                    </div>

                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                    >

                        <div>
                            <MenuStatisticheOpera />
                        </div>

                    </div>

                    <Popup
                        visible={visiblePop}
                        showCloseButton={true}
                        //onHiding={() => doSomething()}
                        width={"40%"}
                        height={"60%"}
                        showTitle={true}
                        title="Dettagli widget"
                    >
                        <div>
                            <p>{testoPop}</p>
                        </div>
                    </Popup>


                    <h6
                        style={{
                            color: "#5b6372",
                            fontFamily: "Arial, sans-serif",
                            marginBottom: "-20px",
                            marginLeft: "10px",
                            zIndex: 1,
                            position: "relative"
                        }}
                    >
                        Contribuzione del margine per Farmacia
                    </h6>
                    <NewTabella
                        height={"70vh"}
                        idTabella="analisi_prodotti"
                        sync
                        colonne={colOsserMargini}
                        dataSource={valoriTabella}
                        rowAlternation={true}
                        filterRow
                        width={500}
                        searchPanel={true}
                        exportT={{
                            enabled: true,
                            fileName: "AnalisiProdotti",
                            formats: { exportFormats },
                        }}
                        activeSaver={true}
                        columnC={true}
                        headerFilter
                        wordWrapEnabled={true}
                        onExporting={onExporting}
                        summary={
                            <Summary>
                                <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                <TotalItem column="OMAGGI" summaryType="sum" />
                                <TotalItem
                                    column="COSTO"
                                    summaryType="sum"
                                    valueFormat={{ style: "currency", currency: "EUR" }}
                                />
                            </Summary>
                        }
                    />


                    <div style={{ marginTop: "5px" }}>
                        <h6
                            style={{
                                color: "#5b6372",
                                fontFamily: "Arial, sans-serif",
                                marginBottom: "-20px",
                                marginLeft: "10px",
                                zIndex: 1,
                                position: "relative"
                            }}
                        >
                            Analisi andamento margine per mese
                        </h6>
                        <NewTabella
                            height={"70vh"}
                            idTabella="analisi_prodotti"
                            sync
                            colonne={colOsserMargini2}
                            dataSource={valoriTabella2}
                            rowAlternation={true}
                            filterRow
                            width={500}
                            searchPanel={true}
                            exportT={{
                                enabled: true,
                                fileName: "AnalisiProdotti",
                                formats: { exportFormats },
                            }}
                            activeSaver={true}
                            columnC={true}
                            headerFilter
                            wordWrapEnabled={true}
                            onExporting={onExporting}
                            summary={
                                <Summary>
                                    <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                    <TotalItem column="OMAGGI" summaryType="sum" />
                                    <TotalItem
                                        column="COSTO"
                                        summaryType="sum"
                                        valueFormat={{ style: "currency", currency: "EUR" }}
                                    />
                                </Summary>
                            }
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            background: "white",
                            marginTop: "5px"
                        }}
                    >
                        <div style={{ width: "93%", margin: "10px" }}>
                            <Chart dataSource={chartData} ref={chartRef}>
                                <CommonSeriesSettings
                                    argumentField="MESE"
                                    type="bar"
                                    hoverMode="allArgumentPoints"
                                    selectionMode="allArgumentPoints"
                                >
                                    <Label visible={true}>
                                        <Format type="currency" currency="EUR" precision={0} />
                                    </Label>
                                </CommonSeriesSettings>

                                <Series
                                    valueField="VEN_SCONTO_VENDITA_PRECEDENTE"
                                    //type="bar"
                                    name="2022/2023"
                                    color="rgb(215, 140, 245)"
                                />
                                <Series
                                    valueField="VEN_SCONTO_VENDITA"
                                    //type="bar"
                                    argumentField="MESE"
                                    color="rgb(25, 231, 255)"
                                    name="2023/2024"
                                />
                                <Tooltip
                                    enabled={true}
                                    customizeTooltip={({
                                        value,
                                        argument,
                                    }: {
                                        value: any;
                                        argument: any;
                                    }) => {
                                        const formattedValue = parseFloat(value).toLocaleString(
                                            "it-IT",
                                            {
                                                style: "currency",
                                                currency: "EUR",
                                                minimumFractionDigits: 2,
                                            }
                                        );

                                        return {
                                            text: `Valore sconto periodo selezionato: ${formattedValue}`,
                                        };
                                    }}
                                />
                                <Title text="Analisi andamento margine per mese">
                                    <Font color="#5b6372" size="16" weight="400" />
                                    {/* <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                      <Font color="#5b6372" size="16" weight="400" />
                  </Subtitle> */}
                                </Title>
                                <Legend
                                    verticalAlignment="bottom"
                                    horizontalAlignment="center"
                                ></Legend>
                                <Export fileName="venduto_sconto_vendita" enabled={true} />
                            </Chart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "5%" }}>
                            <p>
                                <span>
                                    {" "}
                                    <button
                                        className="ibutton-lite"
                                        style={{ border: "none" }}
                                        onClick={() => popVisible5("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                                </span>
                            </p>
                        </div>
                    </div>
                </section>
            )}

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                <div style={{}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
                </div>
            </section>
            <Popup
                visible={visiblePop1}
                showCloseButton={true}
                onHiding={() => setVisiblePop1(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop3}
                showCloseButton={true}
                onHiding={() => setVisiblePop3(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop5}
                showCloseButton={true}
                onHiding={() => setVisiblePop5(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{mess5}</p>
                </div>
            </Popup>
        </section>

    );
};

export default OsservatorioMargini;
