import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import { Form, ButtonItem, GroupItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { FileUploader, SelectBox } from "devextreme-react";
import { BoxDataOra, BoxFiltro, Check } from '../../widget/Editor';
import { TendinaFarm } from '../../widget/Select';
import { OggettoInterface } from '../../interfaces/generiche';
import { cambiaFormatoLocalDateTimeToEuropeo } from '../../funzioni/formatoVariabili';
import { urlB2B, urlCommon } from '../costanti';
import { useAuth } from '../../hooks/useAuth';
import { Upload } from 'devextreme-react/file-manager';
import { RestServices } from '../../services/restServices';
import Select from 'react-select';

interface PageProps {
    typeEdit: boolean,
    edit: any;
    ricarica: () => void;
    close: () => void;
    setIndietro: () => void,
    typeInsert: boolean,
    //datiFarmacie: any[],
    farmacie: any[],
}


export const ListinoB2B: React.FC<PageProps> = ({
    close,
    edit,
    setIndietro,
    typeEdit,
    typeInsert,
    //datiFarmacie,
    farmacie
}) => {

    const [uploadUrl, setUploadUrl] = useState<any>();
    const [uploadUrlBase, setUploadUrlBase] = useState<any>();
    const [formRef, setFormRef] = useState<any>();
    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [isVisible, setIsVisible] = useState(false);
    const [fileUploaderRef, setFileUploaderRef] = useState<any>();
    const [data, setData] = useState<string>();
    const [disableButton, setDisableButton] = useState<boolean>(false)
    const auth = useAuth(urlCommon.auth);
    const formElement = useRef<any>(null);
    const [nomeFile, setNomeFile] = useState<any>("");
    const [farmacieAbilitate, setFarmacieAbilitate] = useState<any>();

    const fileExtensions = ['.csv'];


    useEffect(() => {
        setUploadUrlBase(urlB2B.importListino);
        
    }, [])

    useEffect(() => {

        let filtro: OggettoInterface = {};
        filtro.idFar = farmacie[0].idFar
        impostaFiltri(filtro)

        let param = {};

        param = { idFar: farmacie[0].idFar }


        if (auth) {
            console.log(param)
            RestServices().rest('POST', param, urlB2B.getFarmacie)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {

                            setFarmacieAbilitate(result.response)
                        }

                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }
                })
        }
        

    }, [farmacie, auth])

    

    const handleSubmit = (e: any) => {


        e.preventDefault();

        if (filtri != undefined) {
            if (filtri.nome != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Nome obbligatorio" }, "error", 4000);
                return false;
            }
            if (filtri.descrizione != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Descrizione obbligatoria" }, "error", 4000);
                return false;
            }
            if (filtri.lstFar != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Farmacia obbligatoria" }, "error", 4000);
                return false;
            }
            if (filtri.data != undefined) {
            } else {
                notify({ position: "center", width: "auto", message: "Data obbligatoria" }, "error", 4000);
                return false;
            }
        }
        else {
            notify({ position: "center", width: "auto", message: "Campi obbligatori mancanti" }, "error", 4000);
            return false;
        }


        setIsVisible(true);
        fetch(e.target.action, {
            method: 'POST',
            body: new FormData(e.target),
            credentials: 'include'
        }).then(resp => resp.json())
            .then(
                (result) => {
                    if (result.esito == true) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Importazione completata" }, "success", 4000)
                    }
                    else if (result.esito == false) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                }).catch((error) => {
                    setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                });



    }

    const aggiungiParametroFile = (e: any) => {
        if (e.value.length > 0) {
            setUploadUrl(updateQueryStringParameter(uploadUrlBase, "nomeFile", e.value[0].name))
            e.component.option("uploadUrl", uploadUrlBase);
            setNomeFile(e.value[0].name)
        }

    }

    const updateQueryStringParameter = (uri: any, key: any, value: any) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            //console.log("1:" + uri.replace(re, '$1' + key + "=" + value + '$2'));
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            //console.log("2:" + uri + separator + key + "=" + value);
            return uri + separator + key + "=" + value;
        }
    }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        //console.log(newFiltro)
        var upl = '?';
        if (newF != undefined) {
            if (newF.nome != undefined || newF.length > 0) {
                if (upl.substr(upl.length - 1) == '?')
                    upl = upl + 'nome=' + newF.nome;
                else
                    upl = upl + '&nome=' + newF.nome;
            }
            if (newF.descrizione != undefined || newF.length > 0) {
                if (upl.substr(upl.length - 1) == '?')
                    upl = upl + 'descrizione=' + newF.descrizione;
                else
                    upl = upl + '&descrizione=' + newF.descrizione;
            }
            if (newF.lstFar != undefined || newF.length > 0) {
                if (upl.substr(upl.length - 1) == '?')
                    upl = upl + 'lstFar=' + newF.lstFar;
                else
                    upl = upl + '&lstFar=' + newF.lstFar;
            }
            if (newF.data != undefined) {
                if (upl.substr(upl.length - 1) == '?')
                    upl = upl + 'data=' + cambiaFormatoLocalDateTimeToEuropeo(newF.data);
                else
                    upl = upl + '&data=' + cambiaFormatoLocalDateTimeToEuropeo(newF.data);
            }

            if (newF.idFar != undefined || newF.length > 0) {
                if (upl.substr(upl.length - 1) == '?')
                    upl = upl + 'idFar=' + newF.idFar;
                else
                    upl = upl + '&idFar=' + newF.idFar;
            }

            //console.log(nomeFile)
            if (nomeFile != undefined || nomeFile.length > 0) {
                if (upl.substr(nomeFile.length - 1) == '?')
                    upl = upl + 'nomeFile=' + nomeFile;
                else
                    upl = upl + '&nomeFile=' + nomeFile;
            }

            if (newF.inVigore != undefined) {
                upl = upl + '&inVigore=' + newF.inVigore;
            }
        }

        setUploadUrl(uploadUrlBase + upl);

        //console.log(uploadUrlBase + upl)

        setFiltri(newF);
    }

    const insertListino = () => {

        formElement.current.submit();

    }

    const btnSalvaOnClick = useCallback(() => {
        if (typeInsert == true) {
            if (formElement.current)
                //formElement.current.submit();
                insertListino()
        }
         
    }, []);

    const cambiaValore = (tipo: string, value: string) => {

        let filtro: OggettoInterface = {};
        let strArray : string[] = []

        let arr: string [] = Array.from(value)
        arr.forEach((item: string) => {

            let obj: any = {}

            obj= Object.assign({}, item)
            let a = obj.value
            strArray.push(a)
            
        })

        filtro[tipo] = strArray
        impostaFiltri(filtro)

    }

    return (<>
        {/*<div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
            <div>
                <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro() }}>INDIETRO </Button>
            </div>
            <div className="posbtn">
                <Button className="btn btn-success" variant="success" onClick={btnSalvaOnClick}
                    disabled={disableButton}>SALVA </Button>
            </div>
        </div>
        */}
        <form onSubmit={handleSubmit} action={uploadUrl}>
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div>
                    <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro() }}>INDIETRO </Button>
                </div>
                <div className="posbtn">
                    <Button type="submit" className="btn btn-success" variant="success"
                        disabled={disableButton}>SALVA </Button>
                </div>
            </div>
            <Form
                colCount={2}>
            <GroupItem
                caption="Tracciato del file"
                colSpan={2}>
                <Row>
                    <Col>
                        <span className="trasfInfo">Codice ministeriale<b>;</b>prezzo(imponibile)</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">( separatore decimale ammesso per il prezzo punto (.) o virgola (,) )</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">( Il tipo riga puo' essere "L" (listino) o "C" (Condizione) )</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">( Nel caso di "C" e' obbligatorio inserire oltre al codice ministeriale e il prezzo anche la quantita minima e quantita massima, le date non sono obbligatorie. La loro assenza implica durata infinita alla condizione )</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">( Nel caso di "L" il codice ministeriale e il prezzo )</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">( Le date devono essere nel formato AAAAMMGG )</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">( Per uno stesso prodotto possono esistere sia riga di tipo "L"" che "C"; se la condizione e' valida sara' applicata )</span>
                    </Col>
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                    <Col>
                        <span>Esempi di valori accettati:</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">L;004763037;;;;;5.2</span>
                    </Col>

                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">L;025940053;;;;;4,43</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">C;025940053;2;4;20230324;20240323;4,43</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="trasfInfo">C;025940053;2;4;;;4,43</span>
                    </Col>
                </Row>
            </GroupItem>

            <GroupItem
                caption="Scegli il file"
                colSpan={2}>
                    <FileUploader
                        selectButtonText="Seleziona file" labelText=""
                        uploadMode="useForm"
                        uploadUrl={uploadUrl}
                        onValueChanged={aggiungiParametroFile}
                        allowedFileExtensions={fileExtensions}
                        maxFileSize={2000000}
                        id="fileUploader"
                        ref={setFileUploaderRef}
                        height={150}
                    />
            </GroupItem >
                {/*  <GroupItem
                caption="Scegli la ditta"
                colSpan={2}>
                    <SelectBox
                        dataSource={farmacie}
                    showDataBeforeSearch={true}
                    valueExpr="idFar"
                    displayExpr="descrizione"
                    valueChangeEvent="keyup"
                    showClearButton={false}
                    placeholder="Farmacia"
                    onValueChanged={(e: any) => { let filtro = { idFar: e.value }; impostaFiltri(filtro) }}
                    width="453.317"
                    height="40"
                />
            </GroupItem>
            */}

            <GroupItem
                caption="Scegli le farmacie"
                colSpan={2}>
                <Row>
                    <Col>
                        <span className="trasfInfo">Selezionare le farmacie a cui sara' attivato il listino</span>
                    </Col>
                </Row>
                <Row>
                        
                    <Col sm="4">
                            {/*<TendinaFarm datiFarmacie={farmacieAbilitate} id="lstFar" impostaFiltri={impostaFiltri} valueGrid={filtri?.lstFar} /> */}

                            <Select styles={{
                                multiValue: (styles, { data }) => {
                                    //console.log(data);         
                                    return {
                                        ...styles,
                                        maxWidth: 80,
                                    };
                                },
                            }} onChange={(v: any) => cambiaValore('lstFar', v)} isMulti options={convertForSelectFarmacie(farmacieAbilitate)} />



                    </Col>
                     
                </Row>


            </GroupItem>

            <GroupItem
                caption="Inserisci le info"
                colSpan={2}>

                <Row>
                    <Col sm="4">
                        <span>Nome listino:</span>
                    </Col>
                    <Col sm="2">
                        <BoxFiltro id="nome" impostaFiltri={impostaFiltri} />
                    </Col>
                </Row>
                <Row>
                    &nbsp;
                </Row>
                <Row>
                    <Col sm="4">
                        <span>Descrizione listino:</span>
                    </Col>
                    <Col sm="5">
                        <BoxFiltro id="descrizione" impostaFiltri={impostaFiltri} />
                    </Col>
                </Row>
                <Row>
                    &nbsp;
                </Row>
                <Row>
                    <Col sm="4">
                        <span>Data entrata in vigore listino:</span>
                    </Col>
                    <Col sm="2">
                        <BoxDataOra id="data" impostaFiltri={impostaFiltri} typeDate="date" value={data} />
                    </Col>
                </Row>
                <Row>
                    &nbsp;
                </Row>
            </GroupItem>
            
            </Form>
            <br />

        </form>


        
         
    </>)
}

export default ListinoB2B

export const convertForSelectFarmacie = (arr: any) => {
    let newArr: any = []
    if (arr)
    arr.forEach((element: any) => {
        newArr.push({ value: element.idFar, label: element.codice + ' - ' +element.descrizione })

    });

    return newArr
}