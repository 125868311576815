//data input: "2020-12-31T12:00:00" -> trasformata in "31/12/2020" 
export function cambiaFormatoLocalDateTimeToEuropeo(data: string, orario?: boolean) {
    if (!data) return;
    const options:any = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric"
    }

    
    let data2 = new Date(data);
    let dataFormat = new Intl.DateTimeFormat("en-GB").format(data2);
    if (orario) {
        dataFormat = new Intl.DateTimeFormat("en-GB", options).format(data2);
    }
    return dataFormat;
}

export function cambiaFormatoValuta(valuta: number) {
    if (!valuta) return;
    const cifra = new Intl.NumberFormat("it", {
        style: "currency",
        currency: "EUR"
    }).format(valuta)
    return cifra;
}

export function trasformaDateTimeInLocale(data: Date, soloData?: boolean) {
    if (!data) return;
    //la stringa del formato Date() contiene l'informazione sull'ora legale alla posizione 30 della stringa;
    //il formato LocaleDateTime del backend è riferito all'ora italiana quindi nella trasformazione da Date() a 
    //ISOString bisona aggiungere un'ora o due per tener conto del fuso orario (+1) e dell'ora legale (+2) a seconda
    //del periodo dell'anno selezionato
    const dataInput = new Date(data);
    let increment = (dataInput.toString()[30] === "1") ? 1 : 2;
    dataInput.setHours(dataInput.getHours() + increment);
    let dataOra = dataInput.toISOString();
    if (soloData) {
        dataOra = dataOra.split("T")[0];
    } else {
        dataOra = dataOra.substring(0, dataOra.length - 1);
    }
    return dataOra;
}


export function trasformaDateInLocale(data: Date): string | undefined {
    if (!data) return;

    // La stringa del formato Date() contiene l'informazione sull'ora legale alla posizione 30 della stringa;
    // Il formato LocaleDateTime del backend è riferito all'ora italiana quindi nella trasformazione da Date() a 
    // ISOString bisogna aggiungere un'ora o due per tener conto del fuso orario (+1) e dell'ora legale (+2) a seconda
    // del periodo dell'anno selezionato
    const dataInput = new Date(data);
    let increment = (dataInput.toString()[30] === "1") ? 1 : 2;
    dataInput.setHours(dataInput.getHours() + increment);

    // Prendi solo la parte della data (YYYY-MM-DD)
    const dataOra = dataInput.toISOString().split("T")[0];

    return dataOra;
}


export function trasformaDateTimeInLocaleNewF(data: Date, soloData?: boolean) {
    if (!data) return;
    //la stringa del formato Date() contiene l'informazione sull'ora legale alla posizione 30 della stringa;
    //il formato LocaleDateTime del backend è riferito all'ora italiana quindi nella trasformazione da Date() a 
    //ISOString bisona aggiungere un'ora o due per tener conto del fuso orario (+1) e dell'ora legale (+2) a seconda
    //del periodo dell'anno selezionato
    const dataInput = new Date(data);
    let increment = (dataInput.toString()[30] === "1") ? 1 : 2;
    dataInput.setHours(dataInput.getHours() + increment);
    let dataOra = dataInput.toISOString();
    if (soloData) {
        dataOra = dataOra.split("T")[0];
    } else {
        dataOra = dataOra.substring(0, dataOra.length - 1);
    }

    let formmatted = dataOra.replaceAll('-', '/')

    return formmatted;
}

export function getCurrentDateTime() {
    let date = new Date();
    let formatted;

    const dataInput = new Date(date);
    let increment = (dataInput.toString()[30] === "1") ? 1 : 2;
    dataInput.setHours(dataInput.getHours() + increment);
    let dataOra = dataInput.toISOString();
    dataOra = dataOra.substring(0, dataOra.length - 1);

    formatted = dataOra.replaceAll('-', '-').replaceAll('T', '_').replaceAll(':', '_').split('.')[0];
    //console.log(formatted);

    return formatted;

}

export function getCurrentDate(plusMonth?: number, plusDay?: number, plusYear?: number) {
    let date = new Date();
    if (plusDay != undefined && plusDay > 0)
        date.setDate(date.getDate() + plusDay);

    if (plusMonth != undefined && plusMonth > 0)
        date.setMonth(date.getMonth() + plusMonth);

    if (plusYear != undefined && plusYear > 0)
        date.setFullYear(date.getFullYear() + plusYear);

    let formatted;

    const dataInput = new Date(date);
    let increment = (dataInput.toString()[30] === "1") ? 1 : 2;
    dataInput.setHours(dataInput.getHours() + increment);
    let dataOra = dataInput.toISOString();
    dataOra = dataOra.substring(0, dataOra.length - 1);
    console.log(dataOra)

    formatted = dataOra.split('T')[0];
    //console.log(formatted);

    return formatted;

}



export const parseAsMoney = (value: number, needEuro?: boolean): string => {
    if (!value) return "0";
    let valore = value.toString();
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(valore)) {
        valore = valore.replace(rgx, "$1.$2");
    }
    if (needEuro) {
        return `€ ${valore}`;
    }
    return `${valore}`;
};



export const legendByarray = (lista: any, leggenda: any , listaFild:string , idLegend:string , nomeNewF:string , extractValue:any,priority?:boolean,objtPririty?:any): any => {
    let tabellaNew:any = []    
    lista.map((value:any)=>{
        let objValue = {...value}
        let index = leggenda.findIndex((objValue:any)=> {
            return objValue[listaFild] === value[idLegend] ;
          })
        if(priority){
           objValue.priority = objtPririty?.includes(objValue.id) ?  1 : 2;
        }else{
            objValue.priority = 2 
        }  
        if(index > -1){
           let valueToExtr = ''
           extractValue.map((st:string)=>{
               if(valueToExtr == ''){
                 valueToExtr = valueToExtr+leggenda[index][st]
               }else{
                valueToExtr = valueToExtr+' - '+leggenda[index][st]
               }
           })
          objValue[nomeNewF] =  valueToExtr  
        }  
        
        tabellaNew.push(objValue)
    })
    return tabellaNew

    
};



export const convertForSelectFarmacie = (arr:any) =>{
    let newArr:any = []
    arr.forEach((element:any) => {
        newArr.push({value:element.idFar,label:element.descrizione})
        
    });
        return newArr
}

export const convertForSelectTipoMovimentoRegistrazioneUdi = (arr: any) => {

    let newArr: any = []
    arr.forEach((element: any) => {
        newArr.push({ value: element.id, label: element.descrizione })

    });
    return newArr

}

export const convertForSelectSettore = (arr: any) => {
    let newArr: any = []
    if (arr == undefined) return newArr
    arr.forEach((element: any) => {
        newArr.push({ value: element.id, label: element.codice + ' -  ' + element.descrizione})

    });
    return newArr
}

export const getLabelSettore = (arr: any, id: string) => {
    //console.log(arr)
    //console.log(id)
    let label: any;
    if (arr == undefined) return label
    arr.forEach((element: any) => {
        //console.log(element)
        //console.log(id)
        if (id != undefined) {
            //console.log(`${id}`)
            if (element.id === id)
                label = element.codice + ' -  ' + element.descrizione
        }
        

    });
    return label
}

export const convertForSelectClasse = (arr: any) => {
    let newArr: any = []
    if (arr == undefined) return newArr
    arr.forEach((element: any) => {
        newArr.push({ value: element.id, label: element.descrizione })

    });
    return newArr
}

export const convertForDefaultSingle = (id: number, arr: any) => {
    let single: any = {value:0, label:"Seleziona..."}
    //single = { value: 1, label: "Soltanto Classe A" }
    //return single;
    if (arr == undefined) return single
    arr.forEach((element: any) => {
        if (element.id == id)
            single = { value: element.id, label: element.descrizione}

    });
    return single
}

export const convertForDefaultMultiple = (id: string[], arr: any) => {
    let newArr: any = []
    if (arr == undefined || id == undefined) return newArr
    arr.forEach((element: any) => {
        if (id.includes(element.id))
            newArr.push({ value: element.id, label: element.codice + ' - ' +  element.descrizione })

    });
    return newArr
}

export const convertForDefaultMultipleDitta = (id: string[], arr: any) => {
    console.log("convertForDefaultMultipleDitta")
    console.log(id)
    console.log(arr)
    let newArr: any = []
    if (arr == undefined || id == undefined) return newArr
    arr.forEach((element: any) => {
        if (id.includes(element.codice))
            newArr.push({ value: element.codice, label: element.codice + ' - ' + element.ragioneSociale })

    });
    //console.log(newArr)
    return newArr
}

export const convertForSelectGruppo = (arr: any) => {
    let newArr: any = []
    if (arr == undefined) return newArr
    arr.forEach((element: any) => {
        newArr.push({ value: element.id, label: element.codice + ' -  ' + element.descrizione })
    });
    return newArr
}

export const convertForSelectLinea = (arr: any) => {
    
    let newArr: any = []
    if (arr == undefined) return newArr
    arr.forEach((element: any) => {
        newArr.push({ value: element.codice, label: element.ragioneSociale })
    });
    return newArr
}

export const convertForSelectAtc = (arr: any) => {
    let newArr: any = []
    if (arr == undefined) return newArr
    arr.forEach((element: any) => {
        newArr.push({ value: element.id, label: element.codice + ' -  ' + element.descrizione })
    });
    return newArr
}

export const convertForSelectIva = (arr: any) => {
    let newArr: any = []
    if (arr == undefined) return newArr
    arr.forEach((element: any) => {
        newArr.push({ value: element.id, label: element.codice + ' -  ' + element.descrizione })
    });
    return newArr
}