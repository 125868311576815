import React, { useEffect, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { MonitoraggioFarmacia730 } from "../../interfaces/monitoraggio";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { urlMonitoraggio } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from "devextreme/ui/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { DialogNoteMon } from "../infoFarmacie/Dialog";
import { DrawerFiltri } from "../../widget/Drawer";
import { BoxDataOra } from "../../widget/Editor";
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";
import { OggettoInterface } from "../../interfaces/generiche";
import Button from 'react-bootstrap/esm/Button';

const ElencoFarmacie730 = (props: {toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlMonitoraggio.auth);

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        if (auth) {
            //startCerca();
        }
    }, [auth]);

    const titolo = TitoloContenuto("Monitoraggio farmacie 730");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoFarmacieMonitoraggio730, setElencoFarmacieMonitoraggio730] = useState<MonitoraggioFarmacia730[]>();
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [edit, setImmediate] = useState<boolean>(false)
    const [dal, setDal] = useState<string>();
    const [al, setAl] = useState<string>();
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [openDrawer, setOpenDrawer] = useState(true);

    const startCerca = () => {

        if (filtri.dal == undefined || filtri.al == undefined) {
            notify({ position: "center", width: "auto", message: "Compilare i campi data" }, 'error', 4000);
            return;
        }

        setIsVisible(true);
        const param = { dal: trasformaDateTimeInLocale(filtri.dal), al: trasformaDateTimeInLocale(filtri.al) };
        RestServices().rest('POST', param, urlMonitoraggio.getListMonitoraggioFarmacia730)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setElencoFarmacieMonitoraggio730(result)
                    } else {
                        setElencoFarmacieMonitoraggio730(result);
                    }
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const propsTabElencoFarmacie730: TabellaCompletaInterface = {
        id: "elenco_farmacie_730",
        dataSource: elencoFarmacieMonitoraggio730,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {

            totalItems: [{
                name: "TotaleFarmacie",
                showInColumn: "Cod.CSF",
                displayFormat: "Farmacie: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "Monitoraggio730"
        }
    }

    const columnsFarmacie730 = CreaColonne([
        { caption: "IdFar", dataField: "idFar", visible: false },
        //{ caption: "An. 1", dataField: "avvisoInvii", visible: true, dataType: "boolean" },
        //{ caption: "An. 2", dataField: "avvisoEsito", visible: true, dataType: "boolean" },
        { caption: "Cod.CSF", dataField: "codiceCliente", visible: true },
        { caption: "Cod.Tph.", dataField: "codiceTelepharma" },
        { caption: "Tipo Cliente", dataField: "tipoCliente" },
        { caption: "Farmacia", dataField: "ragioneSociale" },
        { caption: "Titolare", dataField: "titolare" },
        { caption: "P.Iva", dataField: "partitaIva" },
        { caption: "Email", dataField: "email" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Citta'", dataField: "citta" },
        { caption: "Cap", dataField: "cap" },
        { caption: "Telefono", dataField: "telefono" },
        { caption: "Concessionario", dataField: "ragioneSocialeConcessionario" },
        { caption: "Cod.Tph.Conc.", dataField: "codTphConcessionario" },
        { caption: "Tipo Anagrafica", dataField: "tipoAnagrafica" },
        { caption: "Indrizzo", dataField: "indirizzo" },
        { caption: "Ha platinum", dataField: "platinum", visible: true, dataType: "boolean" },
        { caption: "Ha disdetto platinum", dataField: "disdettaPlatinum", visible: true, dataType: "boolean" },
        { caption: "Data termine Platinum", dataField: "dataDisdettaPlatinum", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data primo invio", dataField: "dataPrimoInvio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data ultimo invio positivo", dataField: "dataUltimoInvio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "desc" },
        { caption: "Tot. vendite", dataField: "totaleVendite" },
        { caption: "Tot. scontrini", dataField: "totaleScontrini" },
        { caption: "Tot. invii", dataField: "totaleInvii" },
        { caption: "Tot. invii positivi", dataField: "totaleInviiEsitoPositivo" },
        { caption: "Tot. invii con errori", dataField: "totaleInviiEsitoPosConErrori" },
        //{ caption: "Tot. invii scartati", dataField: "totaleInviiEsitoScartati" },
        //{ caption: "Tot. invii scartati 2", dataField: "totaleInviiEsitoScartati2" },
        //{ caption: "Tot. invii con errori altro", dataField: "totaleInviiEsitoAltro" },
        { caption: "% esito positivo", dataField: "percentualePositivi", format: { precision: 2, type: "fixedPoint" } },
        { caption: "% esito negativo", dataField: "percentualeNegativi", format: { precision: 2, type: "fixedPoint" }},
        { caption: "AvvisoEsito", dataField: "avvisoEsito", visible: false },
        { caption: "AvvisoInvii", dataField: "avvisoInvii", visible: false },
        {
            caption: "Note", dataField: "note", width: 100, cellRender: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [
                {
                    hint: "Segnala anomalia su HelpDesk",
                    icon: "movetofolder",
                    onClick: (e: any) => {
                        const idReparto = 16;
                        const testo = "Monitoraggio 730";
                        const codTph = e.row.data.codTph;
                        const param = {
                            codiceTelepharma: codTph,
                            testoTicket: testo,
                            idReparto : idReparto
                        }
                        RestServices().rest('POST', param, urlMonitoraggio.addTicketHelpDesk)
                            .then(result => {
                                setIsVisible(false);
                                if (result) {
                                    if (result.esito == true) {
                                        notify({ position: "center", width: "auto", message: "Ticket inserito con successo sul sistema HelpDesk" }, 'success', 4000);
                                    } else if (result.esito == false && result.message) {
                                        notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                                    }
                                    else {
                                        notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                                    }
                                }
                                else {
                                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                                }
                                
                            })
                }
              
                }]
        }
    ])

    const columnsDetailFarmacie730 = CreaColonne([
        { caption: "IdFar", dataField: "idFar", visible: false },
        { caption: "Id Invio", dataField: "idInvio", sortOrder: "desc" },
        { caption: "Protocollo", dataField: "protocollo" },
        { caption: "Data inizio", dataField: "dataInizio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data fine", dataField: "dataFine", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data invio", dataField: "dataInvio", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Data accoglienza", dataField: "dataAccoglienza", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Nome file", dataField: "nomeFileAllegato" },
        { caption: "Dimensione file", dataField: "dimensioneFileAllegato" },
        { caption: "Codice esito", dataField: "codiceEsito" },
        { caption: "Descrizione esito", dataField: "descrizioneEsito" },
        { caption: "Id errore", dataField: "idErrore" },
        { caption: "Num. vendite", dataField: "numeroVendite" },
        { caption: "Num. scontrini", dataField: "numeroScontrini" },
        { caption: "Num. invii", dataField: "numeroInvii" },
        { caption: "Num. invii positivi", dataField: "numeroInviiEsitoPositivo" },
        { caption: "Num. invii con errori", dataField: "numeroInviiEsitoPosConErrori" },
        { caption: "Num. invii scartati", dataField: "numeroInviiEsitoScartati" },
        { caption: "Num. invii scartati 2", dataField: "numeroInviiEsitoScartati2" },
        { caption: "Num. invii con errori altro", dataField: "numeroInviiEsitoAltro" },
    ])

        const onRowPrepared = (e: any) => {
        //    if (e.values) {
        //        if (e.data.avvisoEsito == 1 &&  e.data.avvisoInvii == 1) {
        //            e.rowElement.style.backgroundColor = '#EEFF39';
        //        }
        //        else if (e.data.avvisoEsito == 1) {
        //            e.rowElement.style.backgroundColor = '#F49141';
        //        }
        //        else if (e.data.avvisoInvii == 1) {
        //            e.rowElement.style.backgroundColor = '#C08CEF';
        //        }
        //}
    }

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const ricarica = () => {
        setOpenPop2(false)
        setImmediate(false)
        startCerca()
    }

    const creaRiepilogoFiltri = () => {
        return (
            <>
                {/*{(filtri.lstFar) && <span className="cerca">&nbsp;Farmacia: {elencoFarmSelez()}</span>}*/}
                {(filtri.dal) && <span className="cerca">&nbsp;Dal: {cambiaFormatoLocalDateTimeToEuropeo(filtri.dal)}</span>}
                {(filtri.al) && <span className="cerca">&nbsp;Al: {cambiaFormatoLocalDateTimeToEuropeo(filtri.al)}</span>}
            </>
        )
    }

    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="2">
                    <label> Dal </label>
                    <BoxDataOra id="dal" impostaFiltri={impostaFiltri} typeDate="date" value={dal} />
                </Col>
                <Col sm="2">
                    <label> Al </label>
                    <BoxDataOra id="al" impostaFiltri={impostaFiltri} typeDate="date" value={al} />
                </Col>
                <Col sm="3">
                    <Button type="button" variant="success" id="btnCerca730" onClick={startCerca}>Cerca</Button>
                </Col>
            </Row>
        )
    }

    return (
        <>
            {titolo}
            {loadPanel}
            &nbsp;
            <DrawerFiltri id="elencoFarmacie730" templateFiltri={templateFiltri} creaRiepilogoFiltri={creaRiepilogoFiltri} open={openDrawer} setOpen={setOpenDrawer} testoFiltro={'Periodo relativo alla data di invio degli scontrini da utilizzare per il calcolo dei totali'} />
            {/* 
            <div className="rightcontent">
                <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                &nbsp;
                <span id="lblAvvisoInvii" title="Sono presenti invii all'agenzia delle entrate non sequenziali" className="lblAvvisoInvii">An.1: anomalia invii</span>
                &nbsp;
                <span id="lblAvvisoEsiti" title="Gli invii con esito negativo superano il 5% degli invii con esito positivo" className="lblAvvisoEsiti">An.2: anomalia esito invii</span>
                &nbsp;
                <span id="lblAvvisoInviiEsiti" title="Sono presenti invii non equenziali e con esito negativo superiori al 5% di quelli con esito positivo" className="lblAvvisoInviiEsiti">An.1 + An.2</span>
            </div>
            */ }
            <div className="contenutoPagina">
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoFarmacie730}
                            columns={columnsFarmacie730}
                            columnsDetail={columnsDetailFarmacie730}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={false}
                            onRowPrepared={onRowPrepared}
                            urlDetail={urlMonitoraggio.getListMonitoraggioFarmaciaDettaglio730}
                            keyDetail={"idFar"}
                            key={"idFar"}
                            paramsDetail={{ dal: trasformaDateTimeInLocale(filtri.dal), al: trasformaDateTimeInLocale(filtri.al) }}
                        />
                    </Col>
                </Row>
            </div>

            <DialogNoteMon
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />
        </>
    )

}

export default ElencoFarmacie730;