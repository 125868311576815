import React, { useEffect, useState } from 'react'
import '../index.css'
import profile_image from '../immagini/profile_small.jpg'
import Navbar from 'react-bootstrap/Navbar'
import Image from 'react-bootstrap/Image'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import MenuItem from './MenuItems'
import { MenuInterface, MenuItemInterface } from '../interfaces/menu'
import { useLocation } from 'react-router-dom'
import { urlLogOut, urlRicercaSito } from '../contenuti/costanti'

const SideDrawer = (props: { statoMenu: boolean, menu: MenuInterface, handleShowPsw: () => void }) => {
    //classi necessarie per il css
    let profileClasses = 'dropdown profile-element';
    let menuUtente = 'menu-utente chiuso'
    let menuClasses = 'navbar-default navbar-static-side'
    let sidebarClasses = 'sidebar-collapse'

    //idMenuAperto definisce l'id del primo livello di menu che deve essere espanso
    const [idMenuAperto, setIdMenuAperto] = useState<string>("")
    //stato dell'apertura del profilo (nella parte superiore del menu, sotto l'immagine di profilo)
    const [statoProfilo, setStatoProfilo] = useState<boolean>(false)
    const apriProfilo = () => {
        setStatoProfilo(!statoProfilo)
    }
    //cambio classi css per visualizzare il menu utente (home, cambio psw e logout)
    if (statoProfilo) {
        profileClasses = 'dropdown profile-element aperto'
        menuUtente = 'menu-utente'
    }
    //cambio classi css per aprire e chiudere la sidebar laterale del menu
    if (!props.statoMenu) {
        menuClasses = 'navbar-default navbar-static-side closed'
        sidebarClasses = 'sidebar-collapse closed'
    }
    //apertura modal cambio password
    function apriModalPsw() {
        props.handleShowPsw();
        apriProfilo();
    }
    //useLocation() è una funzione del react-router-dom che restituisce la location/url attuale
    const location = useLocation();
    //di seguito si aggiorna la lunghezza della pagina a seconda delle altezze relative tra la sidebar e il contenuto della pagina
    useEffect(() => {
        let livello = document.getElementById(idMenuAperto);
        let altezzaSecondoLivello = livello?.children[1].clientHeight;
        let navbar = document.getElementsByClassName("navbar-default")[0];
        let wrapper = document.getElementsByClassName("wrapper") as HTMLCollectionOf<HTMLElement>;
        let altezzaMenu;
        if (altezzaSecondoLivello) {
            altezzaMenu = navbar.clientHeight + altezzaSecondoLivello;
        } else {
            altezzaMenu = navbar.clientHeight + 50;
            altezzaSecondoLivello = 0;
        }
        let newAlt;
        if (altezzaMenu < 930) {
            newAlt = "930px";
        } else {
            newAlt = "" + altezzaMenu + "px";
        }
        wrapper[0].style.minHeight = newAlt;
    }, [idMenuAperto, props.statoMenu])

    return (
        <Navbar className={menuClasses} role="navigation">
            <div className={sidebarClasses}>
                <ul className="nav metismenu" id="side-menu">
                    {(props.statoMenu) ?
                        <li className="nav-header">
                            <div className={profileClasses}>
                                <span>
                                    <Image roundedCircle alt="image" src={profile_image}></Image>
                                </span>
                                <div>
                                    <span className="clear">
                                        <span className="user-logged">
                                            <b>{props.menu.userName}</b>
                                        </span>
                                        <span className="group-logged">
                                            {props.menu.userRole}
                                            {/*<FontAwesomeIcon icon={faCaretDown} className="freccia-giu" onClick={apriProfilo} />*/}
                                        </span>
                                    </span>
                                </div>
                                {/*<ul className={menuUtente}>*/}
                                {/*    <li>*/}
                                {/*        <a href={urlRicercaSito}>Home</a>*/}
                                {/*    </li>*/}
                                {/*    <li className="divider"></li>*/}
                                {/*    <li>*/}
                                {/*        <div onClick={apriModalPsw}> Cambio Password</div>*/}
                                {/*    </li>*/}
                                {/*    <li>*/}
                                {/*        <a href={urlLogOut}>Logout</a>*/}
                                {/*    </li>*/}
                                {/*</ul>*/}
                            </div>
                        </li> : <li className="nav-header-closed">
                            <div className="siglaUtente">CF</div>
                        </li>}
                    {props.menu.menuItems.ancestor.map((singMenu: MenuItemInterface) =>
                        <MenuItem
                            menu={props.menu}
                            singMenu={singMenu}
                            statoMenu={props.statoMenu}
                            key={singMenu.id}
                            handleShowPsw={props.handleShowPsw}
                            location={(location.pathname).substring(1)}
                            idMenuAperto={idMenuAperto}
                            setIdMenuAperto={setIdMenuAperto}
                        />)}
                </ul>
            </div>
        </Navbar>
    )
}

export default SideDrawer