import React, { useState, useEffect, useMemo } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import NewTabella from "../../../widget/nuova_tabella"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import DateBox from 'devextreme-react/date-box'
import { SelectBox } from 'devextreme-react/select-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import { trasformaDateTimeInLocale } from '../../../funzioni/formatoVariabili'
import { AplicaValoreTO } from '../../../funzioni/operazioniSuTabelle'

//import "./style.css";

interface DialogProps {
  open: boolean,
  edit: any,
  typeEdit: boolean,
  ricarica: () => void,
  close: ()=>void,
}



export const DiaPagamPass: React.FC<DialogProps> = ({ open, close,edit,ricarica}) => {


const selezionati = useMemo(() => edit, [edit]);

const columns =  useMemo(() => [
       

  { caption: "Data Rif.", dataField: "dataRiferimento" , format: "dd/MM/yyyy", dataType: "date",  },
  { caption: "Importo", dataField: "importo",format: { type: "currency", precision: 2, currency: "EUR" }, width: 90},
  { caption: "Farmacia", dataField: "farmacia"  },
  { caption: "Fornitore", dataField: "intestatario"  },
  { caption: "Cat. Fornitore", dataField: "categoriaFornitore"  },
  { caption: "Tipo Doc.", dataField: "tipoDocumento" },
  { caption: "Num. Doc.", dataField: "numeroDocumento" },
  { caption: "Data Doc.", dataField: "data" , format: "dd/MM/yyyy", dataType: "date" },
  { caption: "Totale", dataField: "totale" ,format: { type: "currency", precision: 2, currency: "EUR" } },
  { caption: "Imponibile", dataField: "imponibile" ,format: { type: "currency", precision: 2, currency: "EUR" } },
  { caption: "Imposta", dataField: "imposta" ,format: { type: "currency", precision: 2, currency: "EUR" } },
 
  { caption: "Pagamento", dataField: "pagamento"  },
  { caption: "Riferimento", dataField: "riferimento"  },
  { caption: "Banca", dataField: "banca" },
  { caption: "C/C", dataField: "concoCorrente"  },
  { caption: "Annotazione", dataField: "annotazione" },
  { caption: "Scostamento", dataField: "scostamento" , format: { type: "currency", precision: 2, currency: "EUR" } },      
  { caption: "Stato", dataField: "stato"  },
  
 ], []); 


  const [openD, setOpenD] = useState <boolean>(false)
  const [loading, setLoading] = useState <boolean>(false)
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})
  const [tipoPaga, setTipoPaga] = useState<any>([])
  const [conti, setConti] = useState<any>([])
  

 

  const UpdatePaga = async() =>{
    if (auth) {

      //console.log(objctTOsave);
      let dataToUse = new Date()
      if(objctTOsave.dataPagamento){
        dataToUse = objctTOsave.dataPagamento
      }

      let objToSave = {
        "idScadenzario": objctTOsave.idScadenzario,
        "idBanca": objctTOsave?.conti?.idBanca,
        "idConto": objctTOsave?.conti?.id,
        "ragioneSocialeBanca": 'objctTOsave?.conti?.conto',
        "dataPagamento": trasformaDateTimeInLocale(dataToUse , true),
        "riferimento": objctTOsave?.riferimento,
        "annotazione": objctTOsave.annotazione,
        "idTipoPagamento": objctTOsave.idTipoPagamento,
        "flagCicloAttivo": false,
        "iban": objctTOsave?.conti?.iban
      }

        let arrId:any = []
        edit.forEach((element:any) => {
          arrId.push(element.idScadenzario)
        });


       //console.log(objctTOsave);

       try {
        objToSave.idScadenzario = arrId
        const contents = await RestServices().rest('POST', objToSave , urlConsultazione.updateScadeMultiPagamento)
        console.log(contents);
        if(!contents.esito){
          notify({ position: "center", width: "auto", message: contents.message}, "error", 4000)
          return
        }else{
          notify({ position: "center", width: "auto", message: 'Pagamenti effettuati con successo'}, "success", 4000)
          ricarica() 

        }
       } catch (error) {
        notify({ position: "center", width: "auto", message: 'ci sono stati errori'}, "error", 4000)

       }

       /*
       try {
        for (const objTO of edit) {
          objToSave.idScadenzario = objTO.idScadenzario
          const contents = await RestServices().rest('POST', objToSave , urlConsultazione.updateScadenzarioPagamento)
          console.log(contents);
          if(!contents.esito){
            notify({ position: "center", width: "auto", message: contents.message}, "error", 4000)
            return
          }
        }
        notify({ position: "center", width: "auto", message: 'Pagamenti effettuati con successo'}, "success", 4000)
        ricarica() 
       } catch (error) {
         notify({ position: "center", width: "auto", message: 'ci sono stati errori'}, "error", 4000)
       }

       */

      
       
       
       
       /*RestServices().rest('POST', objToSave , urlConsultazione.updateScadenzarioPagamento)
           .then(result => {
               if (result?.esito) { 
                ricarica()  
                                       
               }else{                              
                   //console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })*/     
   }
}


const getListTipoPaga = () =>{
  if (auth) {
     //console.log(objctTOsave);
     RestServices().rest('POST', { } , urlConsultazione.getListTipoPagame)
         .then(result => {
             if (result?.esito) { 
              setTipoPaga(result.response)
                                     
             }else{                              
                 //console.log(result);
                 notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
             }
         })     
 }
}

const getConti = () =>{
  if (auth) {
     RestServices().rest('POST', {} , urlConsultazione.getListConti)
         .then(result => {
             if (result?.esito) {
             // console.log('lista conti');
              //console.log(result?.response);
              
              
              let objTo = { ...edit };
              if(edit.dataSaldo){
                objTo.dataPagamento = edit.dataSaldo
              }
             // console.log('objTo');
              
             // console.log(objTo);
              
                if(edit.idConto){
                  result?.response.forEach((conto:any) => {
                    //console.log(conto);
                    
                    if(conto.id == edit.idConto ){
                     
                      objTo.conti = conto
                    }
                  });
                  setObjctTOsave(objTo);

                }else{
                  setObjctTOsave(objTo);
                }     
                 //console.log(result.response);
                 setConti(result.response)
                 
             }else{                         
                 //console.log(result);
                 notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                 
             }
         })     
 }
}


 const cambiaValore = (tipo:string,value:string) =>{    
          let objTo = {...objctTOsave}
          objTo[tipo] = value
          setObjctTOsave(objTo)
    }


 

  useEffect(() => { 
        getConti();
        getListTipoPaga();
        setOpenD(open);
    
  }, [open,auth]);



const calcoloTotale = () =>{
  let somma = 0
  selezionati.forEach( (s:any)=> {
      let importo = s.importo ? s.importo : 0
      if(s.idTipoDocumento == 4){
        importo = importo * -1
      }
      somma = somma + importo
      //console.log(s.importo);

      
  });
  AplicaValoreTO(somma,'€','valute')
  return somma.toFixed(2);
}

  
  const showBancheInfo = () =>{
    let result = tipoPaga.filter((tP:any) => tP.idTipoPagamento == objctTOsave?.idTipoPagamento);
    return result[0]?.flagBanca
    
  }

  const findeIndexTipiP = (ts:any) =>{
    let ids = null
    tipoPaga.forEach((pag:any) => {
        if(ts == pag.descrizione){
          ids = pag.idTipoPagamento 
        }
      });
      return ids
  }



  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'65%'}
      height={'80%'}
      showTitle={true}
      title="Registrazione Pagamento"
    >
     <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Annulla',
                onClick: ()=>close(),
              }}       
          />
           <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Salva',
                elementAttr:{
                  class:'btncreatecluster',
                },
                onClick: ()=>UpdatePaga(),
              }
            }
            />


      
      <ScrollView >
      

      <div className='form-row' >    
           <div className="form-group col-md-6">
               <label> DATA PAGAMENTO  </label>              
             <DateBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave && objctTOsave.dataPagamento ?  objctTOsave.dataPagamento : new Date()}  onValueChanged={(v)=>cambiaValore('dataPagamento',v.value) }  type="date" displayFormat="dd/MM/yyyy" />
                </div>  
              
                <div className="form-group col-md-6" >
             <label> MODALITA' PAGAMENTO</label>                
             <SelectBox dataSource={tipoPaga} displayExpr="descrizione" valueExpr="idTipoPagamento" valueChangeEvent="keyup" value={objctTOsave?.idTipoPagamento}    onValueChanged={(v)=>cambiaValore('idTipoPagamento',v.value) }  />               
                </div> 

                </div>

                <div className='form-row' >    
            <div className="form-group col-md-6">
               <label> RIFERIMENTO  </label>              
               <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.riferimento}  onValueChanged={(v)=>cambiaValore('riferimento',v.value) }  /> 
                </div>  


                {showBancheInfo() ? (
                  <div className="form-group col-md-6" > 
                  <label> BANCA </label>               
                  <SelectBox dataSource={conti}  displayExpr="conto"   valueChangeEvent="keyup"  value={objctTOsave?.conti ? objctTOsave?.conti : null}   onValueChanged={(v)=>cambiaValore('conti',v.value) }  />               
                     </div> 
                ) : null}
                  
              
              
                 
                </div>    


                {showBancheInfo() ? (
                   <div className='form-row' >    
                   <div className="form-group col-md-6">
                   <label> RAGIONE SOCIALE  </label>              
                   <TextBox disabled valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.conti?.ragioneSocialeAzienda}  onValueChanged={(v)=>cambiaValore('note',v.value) }  /> 
                   </div>  
             
                   <div className="form-group col-md-6" > 
                   <label> IBAN </label>               
                   <TextBox disabled valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.conti?.iban}  onValueChanged={(v)=>cambiaValore('note',v.value) }  />               
                   </div>  
               </div>
                ) : <div></div>}
          
                
               
          
            {showBancheInfo() ? (
                   <div>
                   <label> CONTO CORRENTE </label>               
                 <TextBox disabled valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.conti?.contoCorrente}  onValueChanged={(v)=>cambiaValore('note',v.value) }  />               
                   <br></br> 
               </div>
                ) : <div></div>}
               
             
              <label> NOTA PAGAMENTO </label>               
             <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.annotazione}  onValueChanged={(v)=>cambiaValore('annotazione',v.value) }  />
            <div>
             <br></br> 
            <label>LISTA PAGAMENTI DA EFFETTUARE </label>   
            <NewTabella

                idTabella='scade_fatt_passive'
                colonne={columns}
                dataSource={selezionati}
                height={250}
                exportT={{
                    enabled: false,
                    fileName: "esportazione_scadenzario_passivo_"
                }}
                  
                              
            />
            <br></br>
            <h2>Totale {calcoloTotale()} €</h2>
            </div>
      </ScrollView>
    </Popup>
  );
};