import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { getDescrFarmFromId, getRagSocCentralFromId } from '../../funzioni/anagrafica';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { FornitoriCentrInterface } from '../../interfaces/generiche';
import { NewTestataInterface } from '../../interfaces/ordineLibero';
import { DialogConferma } from '../../widget/Notifications';
import { urlOrdiniLiberi } from '../costanti';
import { DialogStampaPdf, DialogTrasmettiOrdLib } from './Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { DialogFornitore } from '../ordiniLiberi/Dialog';
import { cambiaFormatoLocalDateTimeToEuropeo, trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili';

const TestataOrdineLibero = (props: {
    newTestata: NewTestataInterface,
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    fornitori: FornitoriCentrInterface[] | undefined,
    idOrdine: number | string | undefined
    setRicarica: (e: boolean) => void,
    flagOrdineB2B: boolean | undefined
}) => {
    const [farmacieSelez, setFarmacieSelez] = useState<string>();
    const [fornitoreSelez, setFornitoreSelez] = useState<string>();
    const [idFornitoreSelez, setIdFornitoreSelez] = useState<number>();
    const [isDialogPdfOpen, setIsDialogPdfOpen] = useState(false);
    const [isDialogTrasmettiOpen, setIsDialogTrasmettiOpen] = useState(false);
    const [isStampaFarmacia, setIsStampaFarmacia] = useState(false);
    const [isStampaTotale, setIsStampaTotale] = useState(false);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [messageConferma, setMessageConferma] = useState<string>();

    const [descStato, setDescStato] = useState<String>();
    const [idStato, setIdStato] = useState<number>();

    const [descStatoProposta, setDescStatoProposta] = useState<String>();
    const [idStatoProposta, setIdStatoProposta] = useState<number>();

    const handleCloseConferma = () => setDialogIsOpen(false);

    const [dialogFornitore, setDialogFornitore] = useState(false);

    // const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (props.newTestata && props.newTestata.lstFar && props.datiFarmacie) {
            let listaFarmacie = "";
            for (let i = 0; i < props.newTestata.lstFar.length; i++) {
                const idFar = props.newTestata.lstFar[i];
                let descrizione = getDescrFarmFromId(props.datiFarmacie, idFar)
                if (i >= 0 && i < props.newTestata.lstFar.length - 1) {
                    listaFarmacie += descrizione + ",  ";
                } else {
                    listaFarmacie += descrizione;
                }
            }
            setFarmacieSelez(listaFarmacie);
        }
        if (props.newTestata && props.newTestata.idFornitore && props.fornitori !== undefined) {
            const fornitore = getRagSocCentralFromId(props.fornitori, props.newTestata.idFornitore)
            setFornitoreSelez(fornitore);
            setIdFornitoreSelez(props.newTestata.idFornitore);
        }
        setDescStato(props.newTestata.stato);
        setIdStato(props.newTestata.idStato);

        setDescStatoProposta(props.newTestata.statoProposta);
        setIdStatoProposta(props.newTestata.idStatoProposta);

    }, [props.newTestata, props.fornitori])

    const handleCloseDialogPdf = () => setIsDialogPdfOpen(false);
    const handleCloseDialogTrasmetti = () => setIsDialogTrasmettiOpen(false);

    // const confermaTrasmetti = () => setIsOpen(true);

    // const handleCloseConferma = () => setIsOpen(false);
    const stampaFarmacia = () => { setIsDialogPdfOpen(true); setIsStampaFarmacia(true); setIsStampaTotale(false); };
    const stampaTotale = () => { setIsDialogPdfOpen(true); setIsStampaFarmacia(false); setIsStampaTotale(true);}
    //const stampaTotale = () => {
    //    if (props.idOrdine) {
    //        const url = urlOrdiniLiberi.stampaTuttiPdf + props.idOrdine
    //        window.open(url);
    //    }
    //}

    const setOrdineRicevuto = () => {
        setMessageConferma("L'ordine " + props.newTestata.titolo + " sta per essere impostato come ricevuto. Confermare?");
        setDialogIsOpen(true);
    }

    const functionNext = () => {
        setDialogIsOpen(false);
        setStatoOrdine();
    }

    const setStatoOrdine = () => {
        const param = {
            idOrdine: props.idOrdine,
            stato: 4
        }
        RestServices().rest('POST', param, urlOrdiniLiberi.setStato).then(result => {
            if (result) {

                if (result.esito === true) {
                    notify({ position: "center", width: "auto", message: "L'ordine e' stato aggiornato correttamente" }, "success", 4000)
                    //props.newTestata.idStato = 4;
                    //props.newTestata.stato = "Ordine ricevuto";
                    setDescStato("Ordine ricevuto");
                    setIdStato(4);

                } else {
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    return;
                }
            }
        })
    }

    return (
        <>

            <Col sm='2'>
                <span>Titolo:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.titolo}</span>
            </Col>
            <Col sm='6'>
                <span>Fornitore:&nbsp;&nbsp;</span><span className="valoreTestata">{fornitoreSelez}</span>
                <span id="spanCambiaFornitore"><Button variant="primary" id="btnCambiaFornitore" onClick={() => setDialogFornitore(true)}>Cambia Fornitore</Button></span>
            </Col>
            {/* <Col sm="8"></Col> */}
            <Col sm="4" id="salvaAuto">
                <span>Salvataggio Automatico</span>
                {(props.newTestata.flagUltimoCosto != undefined && props.newTestata.flagUltimoCosto == true) && <span>&nbsp;&nbsp;&nbsp;&nbsp;Ultimo costo attivo</span>}
            </Col>
            {/*<Col sm='2'>
                <span>Titolo:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.titolo}</span>
            </Col>*/}
            {/*<Col sm='3'>
                <span>Fornitore:&nbsp;&nbsp;</span><span className="valoreTestata">{fornitoreSelez}</span>
            </Col>*/}
            {/*<Col sm='3'>
                <span>Fornitore:&nbsp;&nbsp;</span><span className="valoreTestata">{fornitoreSelez}</span>
                <span id="spanCambiaFornitore"><Button variant="primary" id="btnCambiaFornitore" onClick={() => setDialogFornitore(true)}>Cambia Fornitore</Button></span>
            </Col>
            {(props.flagOrdineB2B == undefined || props.flagOrdineB2B == false) && < Col sm='2'>
                <span>Sconto %:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.sconto}</span>
            </Col>}

            {(props.flagOrdineB2B !== undefined || props.flagOrdineB2B == true) && < Col sm='2'>
                <span></span>
            </Col>}*/}

            <Col sm='2'>
                <span>Sconto %:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.sconto}</span>
            </Col>


            <Col sm='2'>
                <span>Stato:&nbsp;&nbsp;</span><span className="valoreTestata">{descStato + (descStatoProposta ? ", proposta " + descStatoProposta.toLowerCase() : "")}</span>
                {idStato == 3 && <FontAwesomeIcon style={{ cursor: "pointer" }} onClick={setOrdineRicevuto} id="icoOrdineRicevuto" icon={faDownload} className="fa fa-info-circle fa-lg" title="Premi per impostare l'ordine come ricevuto" />}
            </Col>
            <Col sm='4'>
                <table>
                    <tr>
                        <td>
                            {props.newTestata.flagUltimoCosto != undefined && props.newTestata.flagUltimoCosto == true &&
                                <div className='leg5' title='Considera ultimo costo cronologico del fornitore/ditta'>  <p style={{ fontSize: 16, margin: 0, color: 'black' }}> Considera ultimo costo</p></div>
                            }
                        </td>
                        <td>
                            {props.newTestata.flagUltimoSconto != undefined && props.newTestata.flagUltimoSconto == true &&
                                <div className='leg5' title='Considera ultimo sconto applicato al prodotto negli ordini liberi'>  <p style={{ fontSize: 16, margin: 0, color: 'black' }}> Considera ultimo sconto</p></div>
                            }
                        </td>
                        <td>
                            {props.newTestata.flagCostoListino != undefined && props.newTestata.flagCostoListino == true &&
                                <div className='leg5' title="Considera il costo di listino del fornitore/ditta, altrimenti e' utilizzato l'ultimo costo cronologico del fornitore/ditta ">  <p style={{ fontSize: 16, margin: 0, color: 'black' }}> Considera costo listino</p></div>
                            }
                        </td>
                    </tr>
                </table>
            </Col>
            
            <Col sm="4" id="primaRigaBtn">
                <Button variant="danger" id="btnChiudiOrdineLibero" onClick={() => window.close()}>Chiudi</Button>
                <Button variant="primary" id="btnStampaFarmacia" onClick={stampaFarmacia}>Stampa Per Farmacia</Button>
            </Col>
            {props.flagOrdineB2B && 
                <Col sm='3' className="aggiungiRiga">
                    <span>Stato approvazione:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.statoApprovazione}</span>
                </Col>

                }
            {props.flagOrdineB2B &&
                <Col sm='3' className="aggiungiRiga">
                    <span>Data approvazione:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.dataStatoB2B != undefined ? cambiaFormatoLocalDateTimeToEuropeo(props.newTestata.dataStatoB2B.toString()) : ""}</span>
                </Col>

            }
            {props.flagOrdineB2B &&
                <Col sm='3' className="aggiungiRiga">
                    <span>Utente approvazione:&nbsp;&nbsp;</span><span className="valoreTestata">{props.newTestata.utenteStatoB2B}</span>
                </Col>

            }
            <Col sm='8' className="aggiungiRiga">
                <span>Elenco Farmacie:&nbsp;&nbsp;</span><span className="valoreTestata">{farmacieSelez}</span>
            </Col>
            <Col sm="4" className="aggiungiRiga" id="secondaRigaBtn">
                <Button variant="success" id="btnTrasmettiOrdineLibero" onClick={() => setIsDialogTrasmettiOpen(true)}>Trasmetti</Button>
                <Button variant="primary" id="btnStampaTotale" onClick={stampaTotale}>Stampa Totale</Button>
            </Col>
            
            <DialogStampaPdf
                isDialogPdfOpen={isDialogPdfOpen}
                handleCloseDialogPdf={handleCloseDialogPdf}
                farmacieSelezionate={props.newTestata.lstFar}
                idOrdine={props.idOrdine}
                datiFarmacie={props.datiFarmacie}
                isStampaFarmacia={isStampaFarmacia}
                isStampaTotale={isStampaTotale}
            />
            <DialogTrasmettiOrdLib
                idOrdine={props.idOrdine}
                handleCloseDialogTrasmetti={handleCloseDialogTrasmetti}
                isDialogTrasmettiOpen={isDialogTrasmettiOpen}
                flagModificaPrezzoVendita={props.newTestata.flagModificaPrezzoFarmacia}
                setRicarica={props.setRicarica}
                flagOrdineB2B={props.flagOrdineB2B}
            />

            <DialogConferma
                dialogIsOpen={dialogIsOpen}
                message={messageConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />

            <DialogFornitore
                listaFornitori={props.fornitori}
                dialogFornitore={dialogFornitore}
                idFornitore={idFornitoreSelez}
                idOrdine={props.idOrdine}
                setDescrFornitore={setFornitoreSelez}
                setIdFornitore={setIdFornitoreSelez}
                setDialogFornitore={setDialogFornitore}
                idStato={props.newTestata?.idStato}
            />
            {/* <DialogConferma dialogIsOpen={isOpen} message="" functionNext={functionNext} handleCloseConferma={handleCloseConferma} /> */}
        </>
    )
}

export default TestataOrdineLibero;