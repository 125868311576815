import { faCaretUp, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chart, PieChart } from 'devextreme-react';
import { ArgumentAxis, CommonSeriesSettings, Export, Label, Legend, ScrollBar, Series, SeriesTemplate, Subtitle, Title, Tooltip, ValueAxis, ZoomAndPan } from 'devextreme-react/chart';
import { Connector, Font, Format, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';
import notify from 'devextreme/ui/notify';
import React, { useEffect, useRef, useState } from 'react';
import { format } from 'util';
import { useAuth } from '../../../hooks/useAuth';
import { ListaFarmacieInterface } from '../../../interfaces/farmacie';
import { GruppoMerceologicoInterface } from '../../../interfaces/generiche';
import { RestServices } from '../../../services/restServices';
import FiltriVerticale, {ConvertForSelect}  from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from '../../consultazione/interface';
import { urlBancaDati, urlCommon, urlConsultazione, urlReport } from '../../costanti';
import '.././style.css';
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Loading } from '../../../widget/Notifications';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import dxPieChart, { piePointObject } from 'devextreme/viz/pie_chart';
import { dxElement } from 'devextreme/core/element';
import { Summary, TotalItem } from 'devextreme-react/data-grid';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { Popup } from "devextreme-react/popup";


import "./style.css";

const dashboard3 = { dashboardID: 3 };

const BusinessLiteAcquisti = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const [loadinCambio, setLoadinCambio] = useState(false)
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({})
    const [isVisible, setIsVisible] = useState(false);

    const [chartDataFirst, setChartDataFirst] = useState<any>([]);
    const chartRefFirst = useRef<any>(null);
    const [chartKeyFirst, setChartKeyFirst] = useState(false);
    const [totalValueFirst, setTotalValueFirst] = useState(0);

    const [chartDataSecond, setChartDataSecond] = useState<any>([]);
    const chartRefSecond = useRef<any>(null);
    const [chartKeySecond, setChartKeySecond] = useState(false);
    const [totalValueSecond, setTotalValueSecond] = useState(0);

    const [chartDataThird, setChartDataThird] = useState<any>([]);
    const [chartDataFourth, setChartDataFourth] = useState<any>([]);
    const [chartDataFifth, setChartDataFifth] = useState<any>([]);
    const [chartDataSixth, setChartDataSixth] = useState<any>([]);

    const [chartDataSeventh, setChartDataSeventh] = useState<any>([]);
    const chartRefSeventh = useRef<any>(null);
    const [chartKeySeventh, setChartKeySeventh] = useState(false);

    const [chartDataEighth, setChartDataEighth] = useState<any>([]);
    const chartRefEighth = useRef<any>(null);
    const [chartKeyEighth, setChartKeyEighth] = useState(false);

    const [chartDataNinth, setChartDataNinth] = useState<any>([]);
    const chartRefNinth = useRef<any>(null);
    const [chartKeyNinth, setChartKeyNinth] = useState(false);

    const auth = useAuth(urlConsultazione.auth)
    const authReport = useAuth(urlReport.auth)

    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);

    const [settori, setSettori] = useState<any[]>([]);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
    const [codiciAtc, setCodiciAtc] = useState<any[]>([]);
    const [ditte, setDitte] = useState<any[]>([]);
    const [operatori, setOperatori] = useState<any[]>([]);
    const [annimesi, setAnniMesi] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />
    const [visiblePop, setVisiblePop] = useState(false);
    const [visiblePopOne, setVisiblePopOne] = useState(false);
    const [visiblePopTwo, setVisiblePopTwo] = useState(false);
    const [visiblePopPie, setVisiblePopPie] = useState(false);
    const [visiblePopPietwo, setVisiblePopPietwo] = useState(false);
    const [visiblePopPiethree, setVisiblePopPiethree] = useState(false);
    const [visiblePopPiefour, setVisiblePopPiefour] = useState(false);
    const [visiblePopPiefifty, setVisiblePopPiefifty] = useState(false);
    const [visiblePopPiesixty, setVisiblePopPiesixty] = useState(false);



    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };


    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    function formattaData(data: Date): string {
        const dataFormattata = format(data, 'yyyyMMdd HH:mm:ss');
        return dataFormattata;
    }


    const scrollToTop = () => {
        const element = document.getElementById('scrollerelement');

        if (element) {
            element.scrollTo({
                top: 0,
                behavior: 'smooth', // Abilita uno scorrimento fluido
            });
        }
    };

    const filterAction = (objFiltro: Filtri) => {
        scrollToTop()
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = []
        let newIDSettore: any = []

        const monthNames = [
            "Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno",
            "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"
        ];


        if (objFiltro.idFar) {

            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element)
            });
            params.idFar = newIDFAR
        }

        if (objFiltro.idFornitore) {
            params.idFornitore = objFiltro.idFornitore.id
        }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter((item: number) => item % 100 === 0);
            gruppoMerc2 = params.gruppoMerc.filter((item: number) => item % 10 === 0 && item % 100 !== 0);
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
            //params.periodo.al = formattaData(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            ditta: params.ditta,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
            fornitore: params.idFornitore
        };

        console.log(parameters);
        if ((params.idFar.length === 0 || params.idFar === undefined) || (params.periodo.dal === null || params.periodo.al === null)) {
            notify({ position: "center", width: "auto", message: "Filtro per farmacia e periodo obbligatori." }, "error", 4000);
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall3 = {
                ...dashboard3,
                idFarList: params.idFar,
                params: parameters,
            };

            RestServices()
            .rest("POST", dashboardCall3, urlReport.getQueryDashboardAllData)
            .then((result) => {

                if (result) { 

                    setChartDataFirst(result["22"]);
                    setChartDataSecond(result["23"]);

                    const chartThird = result["24"].map((item: { PRO_COD_SETTORE_INVENTARIO: { toString: () => any; }; COSTO_ACQUISTO: any; PRO_DESC_SETTORE_INVENTARIO: string; }) => {
                        const codSettoreInventario = item.PRO_COD_SETTORE_INVENTARIO ? item.PRO_COD_SETTORE_INVENTARIO.toString() : '';
                        const descSettore = item.PRO_COD_SETTORE_INVENTARIO ? codSettoreInventario + ' - ' + item.PRO_DESC_SETTORE_INVENTARIO : 'N.D'
                        const value = item.COSTO_ACQUISTO;

                        return {
                            PRO_COD_SETTORE_INVENTARIO: codSettoreInventario,
                            COSTO_ACQUISTO: value,
                            PRO_DESC_SETTORE_INVENTARIO: descSettore
                        };
                    }).reverse();

                    const chartFourth = result["25"].map((item: { PRO_COD_SETTORE_INVENTARIO: { toString: () => any; }; PEZZI_ACQUISTATI: any; PRO_DESC_SETTORE_INVENTARIO: string; }) => {
                        const codSettoreInventario = item.PRO_COD_SETTORE_INVENTARIO ? item.PRO_COD_SETTORE_INVENTARIO.toString() : '';
                        const descSettore = item.PRO_COD_SETTORE_INVENTARIO ? codSettoreInventario + ' - ' + item.PRO_DESC_SETTORE_INVENTARIO : 'N.D'
                        const value = item.PEZZI_ACQUISTATI;

                        return {
                            PRO_COD_SETTORE_INVENTARIO: codSettoreInventario,
                            PEZZI_ACQUISTATI: value,
                            PRO_DESC_SETTORE_INVENTARIO: descSettore
                        };
                    }).reverse();

                    const formattedResult = result["26"].map((item: any) => {
                        return {
                            ...item,
                            DESCRIZIONE: item.PRO_DESCRIZIONE_RIDOTTA,
                            //COSTO: new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(item.COSTO),
                            PEZZI_ACQUISTATI: new Intl.NumberFormat('it-IT').format(item.PEZZI_ACQUISTATI),
                            INCIDENZA: item.INCIDENZA_PERCENTUALE_COSTO.toFixed(2) + '%', // Formatta l'incidenza con due decimali e aggiunge il simbolo "%"
                        };
                    });

                    const chartSixth = result["27"].map((item: { PRO_COD_ATC1: { toString: () => any; }; COSTO_ACQUISTO: any; PRO_DESC_ATC1: string; }) => {
                        const codATC = item.PRO_COD_ATC1 ? item.PRO_COD_ATC1.toString() : '';
                        const descATC = item.PRO_COD_ATC1 ? codATC + ' - ' + item.PRO_DESC_ATC1 : 'N.D'
                        const value = item.COSTO_ACQUISTO;

                        return {
                            PRO_COD_ATC1: codATC,
                            COSTO_ACQUISTO: value,
                            PRO_DESC_ATC1: descATC
                        };
                    }).reverse();

                    const chartDataSeventh = result["28"].map((item: any) => {
                        const costoAcquisto = item.COSTO_ACQUISTO;
                        const desc = item.PRO_DESC_GRUPPO_MERCEOLOGICO1;
                        const codice = item.PRO_COD_GRUPPO_MERCEOLOGICO1

                        return {
                            COSTO_ACQUISTO: costoAcquisto,
                            DESCRIZIONE: codice + ' - ' + desc
                        };
                    })

                    const chartDataEighth = result["29"].map((item: any) => {
                        const costoAcquisto = item.COSTO_ACQUISTO;
                        const desc = item.PRO_DESC_DITTA_TITOLARE;

                        return {
                            COSTO_ACQUISTO: costoAcquisto,
                            DESCRIZIONE: desc
                        };
                    })

                    const chartDataNinth = result["30"].map((item: any) => {
                        const costoAcquisto = item.COSTO_ACQUISTO;
                        const desc = item.ANA_RAGIONE_SOCIALE;

                        return {
                            COSTO_ACQUISTO: costoAcquisto,
                            DESCRIZIONE: desc
                        };
                    })





                    //ID 22
                    const total = result["22"].slice(12, 23).reduce((acc: number, item: any) => acc + item.COSTO_ACQUISTO, 0);
                    const formattedTotal = total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0 });

                    setTotalValueFirst(formattedTotal);

                    //ID 23

                    const total2 = result["23"].slice(12, 23).reduce((acc: number, item: any) => acc + item.PEZZI_ACQUISTATI, 0);
                    const formattedTotal2 = total2.toLocaleString('it-IT', { minimumFractionDigits: 0 });

                    setTotalValueSecond(formattedTotal2);

                    //ID24 

                    const total3 = chartThird.reduce((sum: any, item: { VENDUTO_NETTO_SCONTI: any; }) => sum + item.VENDUTO_NETTO_SCONTI, 0);

                        const groupedChartThird = chartThird.reduce((result: any[], current: { PRO_DESC_SETTORE_INVENTARIO: any; COSTO_ACQUISTO: any; }) => {
                            const existingItemIndex = result.findIndex(item => item.PRO_DESC_SETTORE_INVENTARIO === current.PRO_DESC_SETTORE_INVENTARIO);
                            if (existingItemIndex !== -1) {
                                result[existingItemIndex].COSTO_ACQUISTO += current.COSTO_ACQUISTO;
                            } else {
                                result.push({ ...current });
                            }
                            return result;
                        }, []);

                        const totalGrouped = groupedChartThird.reduce((sum: any, item: { COSTO_ACQUISTO: any; }) => sum + item.COSTO_ACQUISTO, 0);

                        const chartThirdConPercentuale = groupedChartThird.map((item: { COSTO_ACQUISTO: number; }) => {
                            const percentuale = ((item.COSTO_ACQUISTO / totalGrouped) * 100).toFixed(2);
                            return {
                                ...item,
                                PERCENTUALE: parseFloat(percentuale)
                            };
                        });

                        setChartDataThird(chartThirdConPercentuale);

                        // ID 25

                        const groupedChartFourth = chartFourth.reduce((result: any[], current: { PRO_DESC_SETTORE_INVENTARIO: any; PEZZI_ACQUISTATI: any; }) => {
                            const existingItemIndex = result.findIndex(item => item.PRO_DESC_SETTORE_INVENTARIO === current.PRO_DESC_SETTORE_INVENTARIO);
                            if (existingItemIndex !== -1) {
                                result[existingItemIndex].COSTO_ACQUISTO += current.PEZZI_ACQUISTATI;
                            } else {
                                result.push({ ...current });
                            }
                            return result;
                        }, []);

                        const totalGrouped2 = groupedChartFourth.reduce((sum: any, item: { PEZZI_ACQUISTATI: any; }) => sum + item.PEZZI_ACQUISTATI, 0);

                        const chartFourthConPercentuale = groupedChartFourth.map((item: { PEZZI_ACQUISTATI: number; }) => {
                            const percentuale = ((item.PEZZI_ACQUISTATI / totalGrouped2) * 100).toFixed(2);
                            return {
                                ...item,
                                PERCENTUALE: parseFloat(percentuale)
                            };
                        });

                        setChartDataFourth(chartFourthConPercentuale);

                        // ID 26
                        setChartDataFifth(formattedResult);


                        // ID 27

                        const total4 = chartSixth.reduce((sum: any, item: { COSTO_ACQUISTO: any; }) => sum + item.COSTO_ACQUISTO, 0);

                        const groupedChartSixth = chartSixth.reduce((result: any[], current: { PRO_DESC_ATC1: any; COSTO_ACQUISTO: any; }) => {
                            const existingItemIndex = result.findIndex(item => item.PRO_DESC_ATC1 === current.PRO_DESC_ATC1);
                            if (existingItemIndex !== -1) {
                                result[existingItemIndex].COSTO_ACQUISTO += current.COSTO_ACQUISTO;
                            } else {
                                result.push({ ...current });
                            }
                            return result;
                        }, []);

                        const totalGrouped4 = groupedChartSixth.reduce((sum: any, item: { COSTO_ACQUISTO: any; }) => sum + item.COSTO_ACQUISTO, 0);

                        const chartSixthConPercentuale = groupedChartSixth.map((item: { COSTO_ACQUISTO: number; }) => {
                            const percentuale = ((item.COSTO_ACQUISTO / totalGrouped4) * 100).toFixed(2);
                            return {
                                ...item,
                                PERCENTUALE: parseFloat(percentuale)
                            };
                        });

                        setChartDataSixth(chartSixthConPercentuale);

                        // ID 28
                        setChartDataSeventh(chartDataSeventh);

                        // ID 29
                        setChartDataEighth(chartDataEighth);

                        // ID 30
                        setChartDataNinth(chartDataNinth);


                    setIsVisible(false);
                    setParamsFilter(params);
                    setIsFiltersExpanded(false);


                }
                else {
                    notify(
                        { position: "center", width: "auto", message: result.message },
                        "error",
                        4000
                    );
                }
            })
                
            .catch((error) => {
                notify(
                    {
                        position: "center",
                        width: "auto",
                        message: "Errore Insalata" + error,
                    },
                    "error",
                    4000
                );
            });

            }

        };
    



    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        setNewFarmaList(result.response)
                    } else {
                        // handle error
                    }
                })
        }
    }


    /*sitema grafici */

    const startLoading = () => {
        setLoadinCambio(true);
        setTimeout(() => {
            setLoadinCambio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading()
    }, [isFiltersExpanded]);

    /*end */


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();

    }, [auth]);

    const columnsAnalisi = [
        { caption: "MinSan", dataField: "PRO_COD_PROD", width: '200px' },
        { caption: "Prodotto", dataField: "DESCRIZIONE", width: '200px' },
        { caption: "Desc. Farmacia - Cod. Farmacia", dataField: "FARM_DESCRIZIONE", width: '300px' },
        { caption: "Pezzi acquistati", dataField: "PEZZI_ACQUISTATI", sortOrder: "desc", width: '200px' },
        { caption: "Omaggi", dataField: "OMAGGI", width: '200px' },
        { caption: "Costo", dataField: "COSTO", width: '200px', format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Incidenza percentuale Costo", dataField: "INCIDENZA", width: '200px' },
    ];


    const filters = () => {
        if (auth) {
            RestServices().rest('POST', "", urlBancaDati.getGruppoMerceologico).then(result => {
                if (result) setGruppoMercFilters(result);
            })

            RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                if (result) setSettori(result);
            })


            RestServices().rest('POST', "", urlBancaDati.getAtc).then(result => {
                if (result) setCodiciAtc(result);
            })

            RestServices().rest('POST', "", urlBancaDati.getDitte).then(result => {
                if (result) {
                    const ditteWithCodiceDescrizione = result.map((ditta: { codice: any; descrizione: any; }) => {
                        return {
                            ...ditta,
                            codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`
                        };
                    });
                    console.log('da togliere vediamo ditte');
                    console.log(ditteWithCodiceDescrizione);


                    setDitte(ditteWithCodiceDescrizione);
                }
            });

            const ssn = { id: 1, descrizione: 'SSN' }
            const libera = { id: 0, descrizione: 'Libera' }

            const vendite = [libera, ssn];
            setTipologiaVendita(vendite);
        }
    };


    useEffect(() => {
        filters();



    }, [auth]);


    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const itemsFields = [
        { type: 'dateRange', idValue: 'periodo', width: 'auto', require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
          },
        //{ type: 'select3', idValue: 'idFar', label: 'Farmacie ', displayExpr: 'text', dataSource: ConvertForSelect(newFarmaList), idValueGrid: 'idFar', require: true },
        { type: 'selectWithLevels', id: 'gruppoMerc', label: 'Gruppo Merceologico', displayExpr: 'descrizione', dataSource: gruppoMercFilters, idValueGrid: 'gruppoMerc' as any },
        { type: 'selectMultiplaMoreColon', id: 'codiceSettore', label: 'Settore di inventario ', displayExpr: 'descrizione', dataSource: settori, idValueGrid: 'codiceSettore' as any },
        { type: 'selectWithLevels', id: 'atc', label: 'Codice ATC', displayExpr: 'descrizione', dataSource: codiciAtc, idValueGrid: 'atc' as any },
        { type: 'selectMultiplaMoreColon', id: 'ditta', label: 'Ditta', displayExpr: 'descrizione', dataSource: ditte, idValueGrid: 'ditta' as any },
    ];

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter(atc => atc.length === (level * 1));
    }


    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };

    const popVisibleOne = (testo: string) => {
        setVisiblePopOne(true);
    };

    const popVisibleTwo = (testo: string) => {
        setVisiblePopTwo(true);
    };

    const popVisiblePie = (testo: string) => {
        setVisiblePopPie(true);
    };

    const popVisiblePietwo = (testo: string) => {
        setVisiblePopPietwo(true);
    };
    const popVisiblePiethree = (testo: string) => {
        setVisiblePopPiethree(true);
    };
    const popVisiblePiefour = (testo: string) => {
        setVisiblePopPiefour(true);
    };

    const popVisiblePiefifty = (testo: string) => {
        setVisiblePopPiefifty(true);
    };

    const popVisiblePiesixty = (testo: string) => {
        setVisiblePopPiesixty(true);
    };

    let messOne = ['null'];
    let messTwo = ['Mario  rossi'];
    let messPie1 = ["Valore del venduto per settore inventario:Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione del settore inventario. Il settore inventario è una classificazione di banca dati CSF."];
    let messPie2 = ["Valore del venduto per tipologia di vendita: Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione della tipologia di vendita del prodotto. Le due tipologie di erogazione del prodotto considerate sono: -Sistema Sanitario Nazionale;-Vendita libera.Non è presente una voce mista poiché il costrutto alla base della torta è il prodotto e non l'aggregato vendita. Valore del venduto:Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10."];
    let messPie3 = ["Valore del venduto per settore inventario:Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione del settore inventario. Il settore inventario è una classificazione di banca dati CSF. "];
    let messLast = ["Valore del venduto per settore inventario: Rappresenta la distribuzione del valore del venduto, nel periodo selezionato, in funzione del settore inventario. Il settore inventario è una classificazione di banca dati CSF. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. "];
    let messfifty = ["Valore  23"];
    let messixty = ["Valore 56"];



    return (
        <>
            <section style={{ display: 'flex', justifyContent: 'space-between', overflow: "hidden", height: '130vh' }}>
                {loadPanel}
                {loadinCambio ? (
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 36 }}>
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section id='scrollerelement' style={{ width: '100%', overflow: 'scroll', height: "130vh" }}>
                        <div style={{ fontSize: '14px', alignItems: 'left', margin: '10px', color: 'rgb(80, 84, 92)' }}>
                            <h2>BusinessLab Lite - Analisi Acquisti</h2>
                        </div>
                        {/* andamento costo d'acquisto*/}
                       <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '95%', margin: '10px' }}>
                                    <Chart dataSource={chartDataFirst} ref={chartRefFirst}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                            barPadding={0.2}

                                        >
                                            {/* label bar */}
                                                <Label visible={true} overlappingBehavior={false}>

                                                <Format type='currency' currency='EUR' precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="COSTO_ACQUISTO_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)"
                                        />

                                        <Series
                                            valueField="COSTO_ACQUISTO"
                                            type="bar"
                                            argumentField="MESE"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                        <Label visible={true} verticalOffset={80}> </Label>

                                        </Series>
                                        
                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({ value, argument }: { value: any, argument: any }) => {
                                                const formattedValue = parseFloat(value).toLocaleString('it-IT', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                    minimumFractionDigits: 2,
                                                });

                                                return {
                                                    text: `Costo acquisto: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Andamento costo d'acquisto - ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="16" weight="400" />
                                            <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Subtitle>
                                        </Title>
                                        <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                                        <Export fileName="costo_acquisto" enabled={true} />
                                    </Chart>

                                </div>

                                <div style={{}}>
                                    <p style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisible("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                                </div>
                            {chartDataFirst.length > 0 && (
                             <div
                                 style={{
                                     width: "100%",
                                     display: "flex",
                                     alignItems: "center",
                                     flexDirection: "column",
                                     justifyContent: "center",
                                     marginTop: 5,
                                     paddingBottom: 5
                                 }}
                             >
                                 <h2
                                     style={{
                                         color: "#5b6372",
                                         fontFamily: "Arial, sans-serif",
                                     }}
                             >
                                    Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueFirst}</span>
                               </h2>
                           </div>
                       )}
                        </div>

                        {/* andamento pezzi acquistati*/}
                        <div style={{ backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>

                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '95%', margin: '10px' }}>
                                    <Chart dataSource={chartDataSecond} ref={chartRefSecond}>
                                        <CommonSeriesSettings
                                            argumentField="MESE"
                                            type="bar"
                                            hoverMode="allArgumentPoints"
                                            selectionMode="allArgumentPoints"
                                            barPadding={0.2}

                                        >
                                            <Label visible={true}>
                                                <Format precision={0} />
                                            </Label>
                                        </CommonSeriesSettings>

                                        <Series
                                            valueField="PEZZI_ACQUISTATI_PRECEDENTE"
                                            type="line"
                                            name="2022/2023"
                                            color="rgb(215, 140, 245)"
                                        />

                                        <Series
                                            valueField="PEZZI_ACQUISTATI"
                                            type="bar"
                                            argumentField="MESE"
                                            color="rgb(25, 231, 255)"
                                            name="2023/2024">

                                        <Label visible={true} verticalOffset={80}> </Label>

                                        </Series>
                                       
                                        <Tooltip
                                            enabled={true}
                                            customizeTooltip={({ value, argument }: { value: any, argument: any }) => {
                                                const formattedValue = parseFloat(value).toLocaleString('it-IT', {

                                                    minimumFractionDigits: 0,
                                                });

                                                return {
                                                    text: `Pezzi acquistati: ${formattedValue}`,
                                                };
                                            }}
                                        />
                                        <Title text="Andamento pezzi acquistati - ultimi 12 mesi rolling">
                                            <Font color="#5b6372" size="16" weight="400" />
                                            <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                                <Font color="#5b6372" size="16" weight="400" />
                                            </Subtitle>
                                        </Title>
                                        <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                                        <Export fileName="pezzi_acquistati" enabled={true} />
                                    </Chart>
                                </div>

                                <div style={{}}>
                                    <p style={{ display: 'flex', flexDirection: 'column', alignItems: 'left', justifyContent: 'left' }}>
                                        <span>
                                            {" "}
                                            <button
                                                className="ibutton-lite"
                                                style={{ border: "none" }}
                                                onClick={() => popVisibleOne("")}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    style={{ fontSize: "11px", color: "black" }}
                                                />
                                            </button>
                                        </span>
                                    </p>
                                </div>
                                </div>
                                {chartDataSecond.length > 0 && (
                                    <div
                                        style={{
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            flexDirection: "column",
                                            justifyContent: "center",
                                            marginTop: 5,
                                            paddingBottom: 5
                                        }}
                                    >
                                        <h2
                                            style={{
                                                color: "#5b6372",
                                                fontFamily: "Arial, sans-serif",
                                            }}
                                        >
                                            Totale: <span style={{ color: "#16d3e0", fontWeight: "bold" }}>{totalValueSecond}</span>
                                        </h2>
                                    </div>
                                )}
                        </div>
                        {/* new pie*/}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '15px', backgroundColor: 'white', marginBottom: 10, borderRadius: 5, paddingBottom: 10 }}>
                            <div style={{ width: '45%' }}>
                                <PieChart
                                    id="pie"
                                    dataSource={chartDataThird}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                                >
                                    <Series
                                        argumentField="PRO_DESC_SETTORE_INVENTARIO"
                                        valueField="PERCENTUALE"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>
                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value }: { argument: any, value: any }) => {
                                            const dataItem = chartDataThird.find((item: any) => item.PRO_DESC_SETTORE_INVENTARIO === argument);
                                            const dataSourceValue = dataItem ? dataItem.COSTO_ACQUISTO : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dataSourceValue);
                                            const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                                            const percentualeFormattata = percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                                            return {
                                                text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                                            };
                                        }}
                                    />
                                    <Title text="Costo d'acquisto per settore inventario - Periodo selezionato">
                                    <Font color="#5b6372" size="16" weight="400" />

                                    </Title>

                                    <Export enabled={true} />

                                </PieChart>

                            </div>
                            <div style={{ display: 'flex', marginTop: '-380px', width: '5%' }}>
                                <button
                                    className="ibutton-lite-two"
                                    style={{ border: "none" }}
                                    onClick={() => popVisiblePie("")}
                                >
                                    <FontAwesomeIcon
                                        icon={faInfo}
                                        style={{ fontSize: "11px", color: "black" }}
                                    />
                                </button>

                            </div>


                            <div style={{ width: '45%' }}>
                                <PieChart
                                    id="pie"
                                    dataSource={chartDataFourth}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"

                                //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
                                >
                                    <Series
                                        argumentField="PRO_DESC_SETTORE_INVENTARIO"
                                        valueField="PERCENTUALE"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                    </Series>
                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value }: { argument: any, value: any }) => {
                                            const dataItem = chartDataFourth.find((item: any) => item.PRO_DESC_SETTORE_INVENTARIO === argument);
                                            const dataSourceValue = dataItem ? dataItem.PEZZI_ACQUISTATI : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT').format(dataSourceValue);
                                            const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                                            const percentualeFormattata = percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                                            return {
                                                text: `Percentuale: ${percentualeFormattata}%\nPezzi acquistati: ${formattedValue}`,
                                            };
                                        }}
                                    />
                                    <Title text="Pezzi acquistati per settore inventario - Periodo selezionato">
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>

                                    <Export enabled={true} />
                                </PieChart>
                            </div>
                            <div style={{ display: 'flex', marginTop: '-380px', width: '5%' }}>
                                <button
                                    className="ibutton-lite-two"
                                    style={{ border: "none" }}
                                    onClick={() => popVisiblePietwo("")}
                                >
                                    <FontAwesomeIcon
                                        icon={faInfo}
                                        style={{ fontSize: "11px", color: "black" }}
                                    />
                                </button>

                            </div>

                        </div>

                            {/* new tabella*/}
                 <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 400, marginBottom: '-3px', marginLeft: '5px' }}>Analisi prodotti acquisti - Ultimi 12 mesi rolling</h6>
                    <div style={{ backgroundColor: 'white', borderRadius: 10, marginBottom: 10}}>
                        <NewTabella
                            height={'70vh'}
                            idTabella='analisi_prodotti'
                            sync
                            colonne={columnsAnalisi}
                            dataSource={chartDataFifth}
                            rowAlternation={true}
                            filterRow
                            width={500}
                            searchPanel={true}
                            exportT={{
                                enabled: true,
                                fileName: "AnalisiProdotti",
                                formats: { exportFormats }
                            }}
                            activeSaver={true}
                            columnC={true}
                            headerFilter
                            wordWrapEnabled={true}
                            onExporting={onExporting}
                            summary={
                                <Summary>
                                    <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                                    <TotalItem column="OMAGGI" summaryType="sum" />
                                    <TotalItem column="COSTO" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />
                                </Summary>}
                                
                            />
                       </div>


                        {/* new torta*/}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', backgroundColor: 'white', marginBottom: 10, borderRadius: 5, paddingBottom: 10 }}>
                            <div style={{ width: '50%' }}>
                                <PieChart
                                    id="pie"
                                    dataSource={chartDataSixth}
                                    palette="Green Mist"
                                    paletteExtensionMode="alternate"
                                    type="doughnut"
                                >
                                    <Series
                                        argumentField="PRO_DESC_ATC1"
                                        valueField="PERCENTUALE"
                                    >
                                        <HoverStyle color="rgb(255, 0, 0)" />
                                        <SmallValuesGrouping groupName="Altro" threshold={1.5} mode="smallValueThreshold" />
                                    </Series>

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({ argument, value }: { argument: any, value: any }) => {
                                            const dataItem = chartDataSixth.find((item: any) => item.PRO_DESC_ATC1 === argument);
                                            const dataSourceValue = dataItem ? dataItem.COSTO_ACQUISTO : 0;
                                            const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dataSourceValue);
                                            const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                                            const percentualeFormattata = percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                                            return {
                                                text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}\n ${argument}`,
                                            };
                                        }}
                                    />
                                    <Title  text="Costo d'acquisto per ATC - Periodo selezionato">
                                        <Font color="#5b6372" size="16" weight="400" />
                                    </Title>
                                    <Export enabled={true} />
                                    <Legend visible={true} verticalAlignment="top" horizontalAlignment="right" />
                                </PieChart>
                            </div>
                            <div style={{}}>
                                <p style={{ display: 'flex', marginBottom: '360px' }}>
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisiblePietwo("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>

                        </div>
                        {/* new acquisto gruppo*/}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>
                            <div style={{ width: '95%', margin: '10px' }}>

                                <Chart dataSource={chartDataSeventh.slice().sort((a: any, b: any) => b.COSTO_ACQUISTO - a.COSTO_ACQUISTO)} ref={chartRefSeventh}>
                                    <CommonSeriesSettings
                                        argumentField="DESCRIZIONE"
                                        type="bar"
                                        color="rgb(25, 231, 255)"
                                    />

                                    <Series
                                        valueField="COSTO_ACQUISTO"
                                        type="bar"
                                    />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({ value, argument }: { value: any, argument: any }) => {
                                            const formattedValue = parseFloat(value).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR',
                                                minimumFractionDigits: 2,
                                            });

                                            return {
                                                text: `Costo acquisto: ${formattedValue} \n Gruppo merceologico: ${argument}`,
                                            };
                                        }}
                                    />

                                    <Title text="Costo d'acquisto per gruppo merceologico - Periodo selezionato">
                                        <Font color="#5b6372" size="16" weight="400" />
                                        <Subtitle text="<i>Si visualizzano i primi 20.</i>">
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle>
                                    </Title>
                                    <Export fileName="costoacquisto_gruppo_merceologico" enabled={true} />
                                    <Legend visible={false} />
                                </Chart>

                            </div>
                            <div style={{ display: 'flex', marginTop: '-380px', width: '5%' }}>
                                <p >
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisiblePiefour("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>


                        {/* new ditta*/}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>
                            <div style={{ width: '95%', overflowX: 'auto', margin: '10px' }}>

                                <Chart dataSource={chartDataEighth.slice(0, 22).sort((a: any, b: any) => b.COSTO_ACQUISTO - a.COSTO_ACQUISTO)} ref={chartRefEighth}>
                                    <CommonSeriesSettings
                                        argumentField="DESCRIZIONE"
                                        type="bar"
                                    />

                                    <Series
                                        valueField="COSTO_ACQUISTO"
                                        type="bar"
                                        color="rgb(25, 231, 255)"

                                    />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({ value, argument }: { value: any, argument: any }) => {
                                            const formattedValue = parseFloat(value).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR',
                                                minimumFractionDigits: 2,
                                            });

                                            return {
                                                text: `Costo acquisto: ${formattedValue} \n Ditta: ${argument}`,
                                            };
                                        }}
                                    />

                                    <Title text="Costo d'acquisto per ditta - Periodo selezionato">
                                     <Font color="#5b6372" size="16" weight="400" />
                                        <Subtitle text="<i>Si visualizzano i primi 20.</i>">
                                        <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle>
                                    </Title>
                                    <Export fileName="costoacquisto_ditta" enabled={true} />
                                    <Legend visible={false} />
                                </Chart>

                            </div>
                            <div style={{ display: 'flex', marginTop: '-380px', width: '5%' }}>
                                <p >
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisiblePiefifty("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>


                        </div>

                        {/* new fornitori */}
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px', backgroundColor: 'white', marginBottom: 5, borderRadius: 5 }}>
                            <div style={{ width: '95%', overflowX: 'auto', margin: '10px' }}>

                                <Chart dataSource={chartDataNinth.slice(0, 20).sort((a: any, b: any) => b.COSTO_ACQUISTO - a.COSTO_ACQUISTO)} ref={chartRefNinth}>
                                    <CommonSeriesSettings
                                        argumentField="DESCRIZIONE"
                                        type="bar"
                                    />

                                    <Series
                                        valueField="COSTO_ACQUISTO"
                                        type="bar"
                                        color="rgb(25, 231, 255)"

                                    />

                                    <Tooltip
                                        enabled={true}
                                        customizeTooltip={({ value, argument }: { value: any, argument: any }) => {
                                            const formattedValue = parseFloat(value).toLocaleString('it-IT', {
                                                style: 'currency',
                                                currency: 'EUR',
                                                minimumFractionDigits: 2,
                                            });

                                            return {
                                                text: `Costo acquisto: ${formattedValue} \n Ditta: ${argument}`,
                                            };
                                        }}
                                    />

                                    <Title text="Costo d'acquisto per fornitore - Periodo selezionato">
                                    <Font color="#5b6372" size="16" weight="400" />
                                        <Subtitle text="<i>Si visualizzano i primi 20.</i>">
                                        <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle>
                                    </Title>
                                    <Export fileName="costoacquisto_fornitore" enabled={true} />
                                    <Legend visible={false} />
                                </Chart>
                            </div>

                            <div style={{ display: 'flex', marginTop: '-380px', width: '5%' }}>
                                <p >
                                    <span>
                                        {" "}
                                        <button
                                            className="ibutton-lite"
                                            style={{ border: "none" }}
                                            onClick={() => popVisiblePiesixty("")}
                                        >
                                            <FontAwesomeIcon
                                                icon={faInfo}
                                                style={{ fontSize: "11px", color: "black" }}
                                            />
                                        </button>
                                    </span>
                                </p>
                            </div>
                        </div>
                    </section>
                )}
                <div style={{ width: 'auto', display: 'flex', alignItems: 'center' }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: '30px', color: 'black' }} />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                            <FiltriVerticale
                                ricerca={filterAction}
                                items={itemsFields}
                                paramsFilter={paramsFilter} />
                        </div>

                </section>
                <Popup
                    visible={visiblePop}
                    showCloseButton={true}
                    onHiding={() => setVisiblePop(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messOne}</p>
                    </div>
                </Popup>
                <Popup
                    visible={visiblePopOne}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopOne(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messTwo}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePopPie}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopPie(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messPie1}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePopPietwo}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopPietwo(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messPie2}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePopPiethree}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopPiethree(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messPie3}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePopPiefour}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopPiefour(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messLast}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePopPiefifty}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopPiefifty(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messfifty}</p>
                    </div>
                </Popup>

                <Popup
                    visible={visiblePopPiesixty}
                    showCloseButton={true}
                    onHiding={() => setVisiblePopPiesixty(false)}
                    width={"40%"}
                    height={"40%"}
                    showTitle={true}
                    title="Dettagli widget"
                >
                    <div>
                        <p>{messixty}</p>
                    </div>
                </Popup>
            </section>
        </>
    );
};


export default BusinessLiteAcquisti;
