import React, { useState } from "react";
import Modal from 'react-bootstrap/esm/Modal';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OggettoInterface } from "../../interfaces/generiche";
import { ElencoFarmacieTrasferimentiInterface, LogListinoInterface } from "../../interfaces/trasferimenti";
import { useEffect } from "react";
import { RestServices } from "../../services/restServices";
import { urlTrasferimenti } from "../costanti";
import notify from "devextreme/ui/notify";
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";
import { Form, ButtonItem, SimpleItem, GroupItem } from "devextreme-react/form";
import { FileUploader, Popup } from "devextreme-react";
import { SelectFarm, Selezione, SelezioneGerarchica, SelezioneMultipla, SelezioneMultiplaFarmacie, SelezioneMultiplaGerarchica, TendinaFarm } from '../../widget/Select';
import { BoxDataOra, BoxFiltro, Check } from '../../widget/Editor';
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { TabellaCompleta } from "../../widget/Tabelle";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { Loading } from "../../widget/Notifications";
import { ToolbarItem } from "devextreme-react/popup";

export const DialogFarmaciaTrasferimento = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogFarmaciaTrasferimentoOpen: boolean,
    setIsDialogFarmaciaTrasferimentoOpen: (isDialogFarmaciaTrasferimentoOpen: boolean) => void
    handleCloseDialogFarmaciaTrasferimento: () => void,
    farmaciaTrasferimento: ElencoFarmacieTrasferimentiInterface,
    setIsVisible: (isVisible: boolean) => void,
    idFar?: number,
    aggiorna: boolean,
    setAggiorna: (aggiorna: boolean) => void,
    isUpdate:boolean | undefined,
}) => {


    useEffect(() => {
        setFiltri(props.farmaciaTrasferimento);
    }, [props.farmaciaTrasferimento]);

    const [filtri, setFiltri] = useState<ElencoFarmacieTrasferimentiInterface>(props.farmaciaTrasferimento);
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogFarmaciaTrasferimentoOpen(false);
        //setFiltri(newConsegnaProvetta);
    }

    const salvaFarmacia = () => {

        const param = {
            id: filtri.id,
            farmacia: filtri.farmacia,
            codiceFarmaciaTrasferimento: filtri.codiceFarmaciaTrasferimento,
            username: filtri.username,
            password: filtri.password,
            isFarmacia: filtri.isFarmacia,
            isGrossista: filtri.isGrossista,
            isUpdate: props.isUpdate,
        }
        RestServices().rest('POST', param, urlTrasferimenti.setFarmacia)
            .then(result => {
                if (result.esito) {
                    notify({ position: "center", width: "auto", message: "salvataggio eseguito correttamente", onHiding: aggiornaPagina() }, "success", 4000);
                    props.setIsDialogFarmaciaTrasferimentoOpen(false);
                } else {
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                }
            })
    }

    const aggiornaPagina = () => {
        props.setIsDialogFarmaciaTrasferimentoOpen(false);
        props.setAggiorna(!props.aggiorna);
    }

    return (
        <>
            <Modal
                show={props.isDialogFarmaciaTrasferimentoOpen}
                onHide={handleClose}
                dialogClassName="dialogFarmaciaTrasferimento"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloFarmaciaTrasferimento">
                        <h3>Farmacia</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm="2" className="labelFarmaciaTrasferimento">
                            <span>Farmacia</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <SelectFarm datiFarmacie={props.datiFarmacie} id="id" impostaFiltri={impostaFiltri} displayExpr={true} value={props.farmaciaTrasferimento.id} disabled={ (props.farmaciaTrasferimento.id != undefined) ? true : false} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="6" className="labelFarmaciaTrasferimento">
                            <span>Codice farmacia trasferimento</span>
                        </Col>
                    </Row>
                    <Row>

                        <Col sm="4">
                            <BoxFiltro id="codiceFarmaciaTrasferimento" impostaFiltri={impostaFiltri} value={props.farmaciaTrasferimento.codiceFarmaciaTrasferimento} />
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="4" className="labelFarmaciaTrasferimento">
                            <span>Username</span>
                        </Col>
                        <Col sm="4" className="labelFarmaciaTrasferimento">
                            <span>Password</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <BoxFiltro id="username" impostaFiltri={impostaFiltri} value={props.farmaciaTrasferimento.username} />
                        </Col>
                        <Col sm="4">
                            <BoxFiltro id="password" impostaFiltri={impostaFiltri} value={props.farmaciaTrasferimento.password} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" className="labelFarmaciaTrasferimento">
                            <span>E' farmacia</span>
                        </Col>
                        <Col sm="4" className="labelFarmaciaTrasferimento">
                            <span>E' grossista</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4">
                            <Check id="isFarmacia" impostaFiltri={impostaFiltri} checkValue={props.farmaciaTrasferimento.isFarmacia}/>
                        </Col>
                        <Col sm="4">
                            <Check id="isGrossista" impostaFiltri={impostaFiltri} checkValue={props.farmaciaTrasferimento.isGrossista} />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiConsegnaProvetta" variant="success" onClick={ salvaFarmacia}>
                        Salva
                    </Button>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}


export const DialogImportListino = (props: {
    isDialogImportListino: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    setIsDialogImportListino: (isDialogImportListino: boolean) => void
    handleCloseDialogImportListino: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [uploadUrl, setUploadUrl] = useState<any>();
    const [data, setData] = useState<string>();
    const [formRef, setFormRef] = useState<any>();
    const [fileUploaderRef, setFileUploaderRef] = useState<any>();

    const fileExtensions = ['.csv'];

    useEffect(() => {

        if (props.isDialogImportListino) {
            setUploadUrl(urlTrasferimenti.importaDaFile);
        }

    }, [props.isDialogImportListino])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogImportListino(false);
    }

    const aggiungiParametroFile = (e: any) => {
        if (e.value.length > 0) {
            setUploadUrl(updateQueryStringParameter(uploadUrl, "nomeFile", e.value[0].name))
            e.component.option("uploadUrl", uploadUrl);
        }

    }

    const updateQueryStringParameter = (uri: any, key: any, value: any) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            //console.log("1:" + uri.replace(re, '$1' + key + "=" + value + '$2'));
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            //console.log("2:" + uri + separator + key + "=" + value);
            return uri + separator + key + "=" + value;
        }
    }

    const importa = () => {

    }

    const editorOptions = {
        readOnly: true,
    };

    const submitButtonOptions = {
        text: "Importa file",
        useSubmitBehavior: true,
        type: "success",
        onClick: function (e: any) {

            setUploadUrl(uploadUrl);
            var upl = '';
            if (filtri != undefined) {
                if (filtri.nome != undefined || filtri.length > 0) {
                    upl = upl + '&nome=' + filtri.nome;
                }
                if (filtri.descrizione != undefined || filtri.length > 0) {
                    upl = upl + '&descrizione=' + filtri.descrizione;
                }
                if (filtri.lstFar != undefined || filtri.length > 0) {
                    upl = upl + '&lstFar=' + filtri.lstFar;
                }
                if (filtri.data != undefined) {
                    upl = upl + '&data=' + cambiaFormatoLocalDateTimeToEuropeo(filtri.data);
                }

                if (filtri.inVigore != undefined) {
                    upl = upl + '&inVigore=' + filtri.inVigore;
                }
            }
            setUploadUrl(uploadUrl + upl);
        }
    };

    const handleSubmit = (e: any) => {

        
        e.preventDefault();

        if (filtri != undefined) {
            if (filtri.nome != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Nome obbligatorio" }, "error", 4000);
                return false;
            }
            if (filtri.descrizione != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Descrizione obbligatoria" }, "error", 4000);
                return false;
            }
            if (filtri.lstFar != undefined || filtri.length > 0) {
            } else {
                notify({ position: "center", width: "auto", message: "Farmacia obbligatoria" }, "error", 4000);
                return false;
            }
            if (filtri.data != undefined) {
            } else {
                notify({ position: "center", width: "auto", message: "Data obbligatoria" }, "error", 4000);
                return false;
            }
        }
        else {
            notify({ position: "center", width: "auto", message: "Campi obbligatori mancanti" }, "error", 4000);
            return false;
        }

        props.setIsVisible(true);
        fetch(e.target.action, {
            method: 'POST',
            body: new FormData(e.target),
            credentials: 'include'
        }).then(resp => resp.json())
            .then(
                (result) => {
                    if (result.esito == true) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Importazione completata" }, "success", 4000)
                    }
                    else if (result.esito == false) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                }).catch((error) => {
                    props.setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                });



    }

    return (
        <>
            <Modal
                show={props.isDialogImportListino}
                onHide={handleClose}
                dialogClassName="dialogNuovaNewsletter"
                size="lg"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Importa da file</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-uploader-block">
                        <form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} onSubmit={handleSubmit}>
                            {/*<form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} target="_blank">*/}
                            <Form
                                colCount={2}>

                                <GroupItem
                                    caption="Tracciato del file"
                                    colSpan={2}>
                                    <Row>
                                        <Col>
                                            {/*<span className="trasfInfo"><b>Tipo riga<b>;</b>Codice ministeriale<b>;</b>Quantita minima<b>;</b>Quantita massima<b>;</b>Data inizio<b>;</b>Data fine<b>;</b>prezzo(imponibile)</b></span>*/}
                                            <span className="trasfInfo">Codice ministeriale<b>;</b>prezzo(imponibile)</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( separatore decimale ammesso per il prezzo punto (.) o virgola (,) )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Il tipo riga puo' essere "L" (listino) o "C" (Condizione) )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Nel caso di "C" e' obbligatorio inserire oltre al codice ministeriale e il prezzo anche la quantita minima e quantita massima, le date non sono obbligatorie. La loro assenza implica durata infinita alla condizione )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Nel caso di "L" il codice ministeriale e il prezzo )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Le date devono essere nel formato AAAAMMGG )</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( Per uno stesso prodotto possono esistere sia riga di tipo "L"" che "C"; se la condizione e' valida sara' applicata )</span>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <span>Esempi di valori accettati:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">L;004763037;;;;;5.2</span>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">L;025940053;;;;;4,43</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">C;025940053;2;4;20230324;20240323;4,43</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">C;025940053;2;4;;;4,43</span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli il file"
                                    colSpan={2}>
                                    <FileUploader
                                        selectButtonText="Seleziona file" labelText=""
                                        /*accept="image/*"*/
                                        uploadMode="useForm"
                                        uploadUrl={uploadUrl}
                                        onValueChanged={aggiungiParametroFile}
                                        allowedFileExtensions={fileExtensions}
                                        maxFileSize={2000000}
                                        id="fileUploader"
                                        ref={setFileUploaderRef}
                                    />
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'File permessi: '}
                                                <span>.csv</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'Dimensione massima file: '}
                                                <span>2 MB</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli le farmacie"
                                    colSpan={2}>

                                    <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} valueGrid={filtri?.lstFar} />

                                </GroupItem>

                                <GroupItem
                                    caption="Inserisci le info"
                                    colSpan={2}>

                                    <Row>
                                        <Col sm="4">
                                            <span>Nome listino:</span>
                                        </Col>
                                        <Col sm="8">
                                            <BoxFiltro id="nome" impostaFiltri={impostaFiltri} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <span>Descrizione listino:</span>
                                        </Col>
                                        <Col sm="8">
                                            <BoxFiltro id="descrizione" impostaFiltri={impostaFiltri} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                    <Row>
                                        <Col sm="4">
                                            <span>Data listino:</span>
                                        </Col>
                                        <Col sm="3">
                                            <BoxDataOra id="data" impostaFiltri={impostaFiltri} typeDate="date" value={data} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Seleziona opzione"
                                    colSpan={2}>
                                    <span style={{ cursor: "help" }} title="Rende il istino che si sta caricando subito in vigore sostituendo il listino attualmente attivo">Rendi listino in vigore: </span>
                                    &nbsp;
                                    <Check id="inVigore" impostaFiltri={impostaFiltri} />
                                    <Col>
                                        <span className="checkPrezzoInfo">Rende il istino che si sta caricando subito in vigore sostituendo il listino attualmente attivo</span>
                                    </Col>
                                </GroupItem>
                                <ButtonItem colSpan={2} buttonOptions={submitButtonOptions} horizontalAlignment="right" />
                            </Form>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button id="btnImportaPrezzoPromozione" variant="success" onClick={() => importa()}>*/}
                    {/*    Importa*/}
                    {/*</Button>*/}
                    <Button id="btnChiudiDialogImportPrezzoPromozione" variant="danger" onClick={() => props.handleCloseDialogImportListino()}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export const DialogLogListino = (props: {
    isDialogLogListino: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    setIsDialogLogListino: (isDialogLogListino: boolean) => void
    handleCloseDialogLogListino: () => void,
    setIsVisible: (isVisible: boolean) => void,
    idListino: number | undefined,
    nomeListino: string | undefined,
    //dataListino: Date | undefined,
}) => {

    const [elencoLog, setElencoLog] = useState<LogListinoInterface[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();

    useEffect(() => {
        if (props.isDialogLogListino == true)
            if (props.idListino)
                cerca();
    }, [props.isDialogLogListino]);

    const handleClose = () => {
        setElencoLog(undefined);
        setTabellaInstance(undefined)
        props.setIsDialogLogListino(false);

    }

    const cerca = () => {
        props.setIsVisible(true);
        //const param = { idListino: props.idListino };
        const param = { idListino: 1 };
        RestServices().rest('POST', param, urlTrasferimenti.getListinoLog).then(result => {

            if (result) {
                props.setIsVisible(false);
                    setElencoLog(result);
            } else {
                props.setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                }
            })
    }

    const propsTabLogListino: TabellaCompletaInterface = {
        id: "listino_log",
        dataSource: elencoLog,
        pagination: 15,
        selection: "none",
        export: { enabled: true },
        defaultFilterValue: null,
        headerFilterVisible: true,
        enablePagination: true,
    }

    const columnsLogListino = CreaColonne([
        { caption: "idFar", dataField: "idFar", visible: false },
        { caption: "idListino", dataField: "idListino", visible: false },
        { caption: "Farmacia", dataField: "farmacia", },
        { caption: "Nome listino", dataField: "titolo", visible: false },
        { caption: "Data download", dataField: "dataDownload", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" }
    ])


    return (
        <>
            <Modal
                show={props.isDialogLogListino}
                onHide={handleClose}
                dialogClassName="dialogLogListino"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Elenco download listino: {props.nomeListino}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TabellaCompleta propsTabella={propsTabLogListino} columns={columnsLogListino} setTabellaInstance={setTabellaInstance} />
                </Modal.Body>
                <Modal.Footer>
                <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                    Annulla
                </Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}


export const DialogGrossista = (props: {
    open: boolean,
    close: () => void,
    idFarmacia: number | undefined,
    farmacia: string | undefined,
    selezionato: (e: any) => void
    //dataListino: Date | undefined,
}) => {

    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [tabellaGrossista, setTabellaGrossista] = useState<any[]>([])
    const [openD, setOpenD] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [selected, setSelected] = useState<any>();

    useEffect(() => {

        setOpenD(props.open);
        getListGrossista()

    }, [props.open]);


    const getListGrossista = () => {
        if (props.open) {
            setLoading(true);

            let param = { idFar: props.idFarmacia }
            RestServices().rest('POST', param, urlTrasferimenti.getListGrossista)
                .then(result => {
                    if (result.esito) {
                        //notify({ position: "center", width: "auto", message: "Configurazione salvata" }, "success", 4000)
                        setTabellaGrossista(result.response)
                        setLoading(false)
                    }
                    else {
                        notify({ position: "center", width: "auto", message: "Impossibile recuperare i grossisti della farmacia" }, "error", 4000)
                        setLoading(false)
                    }
                })
        }
       
    }

    const columns = [
        { caption: "CPAnabase", dataField: "cpAnabase", visible: false },
        { caption: "Codice", dataField: "codice", visible: true },
        { caption: "Grossista", dataField: "ragioneSociale", },
        { caption: "Partita IVA", dataField: "partitaIva", visible: true },
    ]

    const selezionaGrossista = () => {

        props.selezionato(selected);
        props.close();
    }


    return (
        <>
            <Loading isVisible={loading} />
            <Modal
                show={openD}
                onHide={props.close}
                dialogClassName="dialogLogListino"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Elenco grossisti {props.farmacia}</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <NewTabella
                        idTabella='grossisti'
                        height="500"
                        sync
                        colonne={columns}
                        dataSource={tabellaGrossista}
                        filterRow
                        searchPanel={false}
                        onclickRow={() => null}
                        exportT={{
                            enabled: false,
                            fileName: "grossisti"
                        }}
                        activeSaver={true}
                        columnC={false}
                        multyp={false}
                        selectedValue={(datiRow: any) => setSelected(datiRow) }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="success" onClick={selezionaGrossista}>
                        Seleziona
                    </Button>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={props.close}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )


}