import React from 'react'
import BancaDati from './contenuti/bancaDati/BancaDati'
import { Switch, Route } from 'react-router-dom'
//import HomePage from './contenuti/HomePage'
import HomePageNew from './contenuti/HomePageNew'
import HomeMyCsf from './contenuti/Home_myCsf'
import { ListaFarmacieInterface } from './interfaces/farmacie'
import { XadmMondoDb } from './contenuti/xadm_mongodb/Xadm_mongodb'
import './contenuti/contenuti.css'
import AnagraficaFornitoriLocali from './contenuti/anagrafiche/AnaFornLocali'
import AnagraficaMyCsf from './contenuti/myCsf/Anagrafica'
import Report from './contenuti/report/Report'

import * as Constanti from './contenuti/costanti'
import OrdiniRda from './contenuti/ordiniRda/ElencoOrdiniRda'
import OrdiniNso from './contenuti/ordiniNso/ElencoOrdiniNso'
import ElencoClientiRSA from './contenuti/torinoRsa/ElencoClientiRsa'
import PaginaReport from './contenuti/report/PaginaReport'
import SingoloOrdineRda from './contenuti/ordiniRda/SingoloOrdineRda'
import ElencoOrdiniLiberi from './contenuti/ordiniLiberi/ElencoOrdiniLiberi'
import SingoloOrdineLibero from './contenuti/ordiniLiberi/SingoloOrdineLibero'
import Screening from './contenuti/screening/Screening'
import RichiesteTrasferimenti from './contenuti/trasferimenti/ElencoRichieste'
import ConfigurazioneParametri from './contenuti/trasferimenti/ConfigurazioneParametri'
import FarmacieTrasferimenti from './contenuti/trasferimenti/ElencoFarmacie'
import ProdottoAbbinamento from './contenuti/fatture/ProdottoAbbinamento'
import ElencoFarmacie730 from './contenuti/monitoraggio/ElencoFarmacie730'
import ElencoFarmacieParametri from './contenuti/monitoraggio/ElencoFarmacieParametri'
import NoteRilascio from './contenuti/noteRilascio/NoteRilascio'
import Test from './contenuti/esempi/Test'
import ElencoFarmacie from './contenuti/trasferimenti/ElencoFarmacie'
import ElencoClienti from './contenuti/newsletter/ElencoClienti'
import AnagraficaFarmacia from './contenuti/myCsf/AnagraficaFarmacia'
import ElencoNewsletter from './contenuti/newsletter/ElencoNewsletter'
import ElencoContenitori from './contenuti/contenitori/ElencoContenitori'
import SingoloContenitore from './contenuti/contenitori/SingoloContenitore'
import ElencoFileNewsletter from './contenuti/newsletter/ElencoImmaginiNewsletter'
import ElencoPrezzoPromozione from './contenuti/prezzoPromozione/ElencoPrezzoPromozione'
import PrezziPromozione from './contenuti/prezzoPromozione/PrezziPromozione'
import LogPrezziPromozione from './contenuti/prezzoPromozione/LogPrezziPromozione'
import InvioPrezziPromozione from './contenuti/prezzoPromozione/InvioPrezziPromozione'
import ElencoClientiInfoFarmacie from './contenuti/infoFarmacie/ElencoClientiInfoFarmacie'
import ElencoParametriFarmacia from './contenuti/infoFarmacie/ElencoParametriFarmacia'
import InfoFarmacia from './contenuti/infoFarmacie/InfoFarmacia'

import VenConsultazione from './contenuti/consultazione/Ven_consultazione'
import FatOrdini from './contenuti/consultazione/Fat_ordini'
import FatCarichi from './contenuti/consultazione/Fat_carichi'
import MagGiacenze from './contenuti/consultazione/Mag_giacenze'
import Gestcluster from './contenuti/cluster/Gest_cluster'
import Gestclustertipo from './contenuti/cluster/Gest_tipo_cluster'
import ConsultazioneOperatori from './contenuti/consultazione/Cons_operatori'
import GestCentraOperatori from './contenuti/consultazione/Gest_oper_centra'
import ElencoListini from './contenuti/trasferimenti/ElencoListini'
import SingoloListino from './contenuti/trasferimenti/SingoloListino'
import PrimaNota from './contenuti/primanota/prima_nota'
import GestProdGruppo from './contenuti/prodotti/gest_prod_gruppo'
import GestCentProdPers from './contenuti/prodotti/gest_prod_pers'
import GestBanche from './contenuti/banche/banche'
import GestTipoCausale from './contenuti/primanota/tipo_causale'
import VenditeBusiness from './contenuti/BusinessLab/vendite_business'
import AcquistiBusiness from './contenuti/BusinessLab/acquisti_business'
import MercatoBusiness from './contenuti/BusinessLab/mercato_business'
import Privacy from './contenuti/info/privacy'
import InfoDati from './contenuti/info/info_dati'
import InfoMagazzino from './contenuti/info/info_magazzino'
import InfoListini from './contenuti/info/info_listini'
import InfoOrdini from './contenuti/info/info_ordini'
import InfoFatture from './contenuti/info/info_fatture'
import InfoMonitoraggio from './contenuti/info/info_monitoraggio'
import InfoBs from './contenuti/info/info_bs'
import InfoTariff from './contenuti/info/info_tariffazione'
import InfoApp from './contenuti/info/info_appfarm'
import InfoAppClienti from './contenuti/info/info_appclienti'
import ReportGrafic from './contenuti/consultazione/report'
import ReportGraficBeta from './contenuti/consultazione/reportBeta'

import FattAttive from './contenuti/scadenzario/fatture_attive'
import FattPassive from './contenuti/scadenzario/fatture_passive'
import FattPassiveBeta from './contenuti/scadenzario/fatture_passiveOk'
import ConfigurazioneDatiAltreFarmacie from './contenuti/datiAltreFarmacie/ConfigurazioneDatiAltreFarmacie'
import ElencoClientiDoppioClient from './contenuti/infoFarmacie/ElencoClientiDoppioClient'
import ElencoClientiTariffazione from './contenuti/infoFarmacie/ElencoClientiTariffazione'
import AnaGruppo from './contenuti/anagrafiche/Anagrafica_gruppo'
import AnagAziende  from './contenuti/anagrafiche/Anagrafica_aziende'
import Anagfarmacie  from './contenuti/anagrafiche/Anagrafica_farmacie'
import MagMovimentazione from './contenuti/consultazione/Mag_movimentazione'
import MonitFarmacie from './contenuti/monitoraggio/farmacie'
import GestClientiFarm from './contenuti/consultazione/Gestio_clienti_farm'
import GestListiniForni from './contenuti/consultazione/Gest_listini'
import GestTry from './contenuti/consultazione/try'
import Utenti from './contenuti/utenti/Utenti'
import DownLoad from './contenuti/download/Download'
import ProfiliUtenti from './contenuti/utenti/Profili'
import ValorInventa from './contenuti/consultazione/Valorizz_inventario'
import StatBusinessLab from './contenuti/businesslab_evo/Stat_businessLab'
import StatBusinessLabOper from './contenuti/businesslab_evo/Stat_businessLab_ope'
import StatBusinessLabReport from './contenuti/businesslab_evo/Stat_business_report'
import StatBusinessLabDashb from './contenuti/businesslab_ext/Stat_businesslab_Dash'
import FattElettronicaPassive from './contenuti/fatt_elettronica/Fatture_passive'
import FattElettronicaAttive from './contenuti/fatt_elettronica/Fatture_attive'

import ElencoClientiArt34 from './contenuti/infoFarmacie/ElencoClientiArt34'
import ElencoClientiMonitoraggioPlatinum from './contenuti/infoFarmacie/ElencoClientiMonitoraggioPlatinum'
import ElencoClientiMonitoraggioDatabasePlatinum from './contenuti/infoFarmacie/ElencoClientiMonitoraggioDatabasePlatinum'
import { MenuInterface } from './interfaces/menu'
import ElencoClientiMonitoraggioBancaDatiPlatinum from './contenuti/infoFarmacie/ElencoClientiMonitoraggioBancaDatiPlatinum'
import ElencoClientiMonitoraggioBackupDBPlatinum from './contenuti/infoFarmacie/ElencoClientiMonitoraggioBackupDBPlatinum'
import ElencoClientiMonitoraggioSpazioDiscoPlatinum from './contenuti/infoFarmacie/ElencoClientiMonitoraggioSpazioDiscoPlatinum'
import ElencoAnomalieFarmacie from './contenuti/anomalia/ElencoAnomalieFarmacie'
import FattAbbinamenti from './contenuti/consultazione/fatt_abbinamenti'
import ElencoClientiContrattiSmart from './contenuti/infoFarmacie/ElencoClientiContrattiSmart'
import AnaFornCentr from './contenuti/anagrafiche/Anagrafica_fornitori_centralizzata'
import ImpostazioniOrdineLibero from './contenuti/ordiniLiberi/ImpostazioniOrdineLibero'
import ReportRda from './contenuti/ordiniRda/ReportRda'
import AnaFornitoriCentr from './contenuti/anagrafiche/Anagrafica_fornitori_centralizzata'
//import ElencoRichiesteLivellamentoMagazzino from './contenuti/livellamentoMagazzino/ElencoRichieste'
//import DettaglioRichiestaLivellamentoMagazzino from './contenuti/livellamentoMagazzino/DettaglioRichiestaLivellamentoMagazzino'
import SelloutAssistant from './contenuti/business_intelligence/SelloutAssistant'
import SelloutAssistantDett from './contenuti/business_intelligence/SelloutAssistantDett'
import SelloutAssistantOper from './contenuti/business_intelligence/SellOutAssistantOper'
//import BusinessLiteVendite from './contenuti/dashboards/lite/BusinessLiteVendite'
//import BusinessLiteAcquisti from './contenuti/dashboards/lite/BusinessLiteAcquisti'
import BusinessLiteAcquistiNewV from './contenuti/dashboards/lite/BusinessLiteAcquistiNewV'
import BusinessLiteVenditeNewV from './contenuti/dashboards/lite/BusinessLiteVenditeNew'
import LiteMercato from './contenuti/dashboards/lite/Mercato'
import ElencoRichiesteLivellamentoMagazzino from './contenuti/livellamentoMagazzino/ElencoRichieste'
import DettaglioRichiestaLivellamentoMagazzino from './contenuti/livellamentoMagazzino/DettaglioRichiestaLivellamentoMagazzino'
import CruscottoDireEvol from './contenuti/dashboards/evolution/CruscottoDirezionale'
import CruscottoGruppoEvol from './contenuti/dashboards/evolution/CruscottoGruppo'
import AnalisiVenditeEvol from  './contenuti/dashboards/evolution/AnalisiVendite'
import AnalPerfOperatore from './contenuti/dashboards/evolution/AnalPerfOperatore'
import AnaliScontiCategory from './contenuti/dashboards/evolution/AnaliScontiCateg'
import AnaliScontiGruppoMerce from './contenuti/dashboards/evolution/AnalisiScontiCateMerc'
import CruscottoAnaliRicetteEvo from './contenuti/dashboards/evolution/AnalisiRicette'
import AnalisiTrasferimenti from './contenuti/dashboards/evolution/AnalisiTrasferimenti'
import AnalisiConteni from './contenuti/dashboards/evolution/AnalisiContenitori'
import AnaliVendiDitta from './contenuti/dashboards/operativo/AnaVendiDitta'
import AnalVendCateOper from './contenuti/dashboards/operativo/AnaVendCategory'
import AnalVendSetInvenOper from './contenuti/dashboards/operativo/AnaVenSetInventario'
import AnaliVendGruMerce from './contenuti/dashboards/operativo/AnalVendGrupMerc'
import AnalAcquiCategory from './contenuti/dashboards/operativo/AnalAcquiCategory'
import AnalAcquiGrupMerc from './contenuti/dashboards/operativo/AnalAcquiGruMerc'
import AnalAcquiDittaOper from './contenuti/dashboards/operativo/AnaAcquiDitta'
import AnalAcquiSettInvenOper from './contenuti/dashboards/operativo/AnaAcquiSettInven'
import AnaliFornDittaCategory from './contenuti/dashboards/operativo/AnalFornDittaCategory'
import AnaliFornDittaGruppo from './contenuti/dashboards/operativo/AnalFornDittaGruppo'
import AnalisiMargiMedi from './contenuti/dashboards/operativo/AnalMargiMedi'
import AnalisiVendiSottMargineOper from './contenuti/dashboards/operativo/AnalVendSotMargOper'
import AnalVendFascOrariaOper from './contenuti/dashboards/operativo/AnalVendFascOrariaOper'
import AnalProdFermi from './contenuti/dashboards/operativo/AnalProdFermi'
import VenditeEffettuate from './contenuti/dashboards/reportistica/VenditeEffettuate'
import AnalTrasferimenti from './contenuti/dashboards/reportistica/AnalTrasferimenti'
import AnalisiMagazzino from './contenuti/dashboards/statistiche_operative/AnalisiMagazzino'
import OsservatorioMargini from './contenuti/dashboards/statistiche_operative/OsservatorioMargini'


import AnalVendiSinte from './contenuti/dashboards/fbi_vendite/AnalVendiSinte'
import CruscoVendite from './contenuti/dashboards/fbi_vendite/CruscottoVendite'
import AnalisiDettaglioVendite from './contenuti/dashboards/fbi_vendite/AnalDettVendite'
import AnalVendiOperatore from './contenuti/dashboards/fbi_vendite/AnaliVendiOper'
import AnalVendFascOraria from './contenuti/dashboards/fbi_vendite/AnalVendFascOraria'
import AnaliScontiVendita from './contenuti/dashboards/fbi_vendite/AnalScontiVendite'
import AnaliVendiRicetta from "./contenuti/dashboards/fbi_vendite/AnalVendRicetta"
import AnalisiVendiSottMargine from "./contenuti/dashboards/fbi_vendite/AnalVendSotMargine"
import AnalisiMargiMediVendita from "./contenuti/dashboards/fbi_vendite/AnaliMargiMediVendita"
import FbiStatofarmacia from "./contenuti/dashboards/fbi_vendite/FbiStatoFarmacia"
import GalileoCruscottoGruppo from './contenuti/dashboards/galileopro/CruscottoGruppo'
import GalileoAnaliVendCateg from './contenuti/dashboards/galileopro/AnalVendCategory'
import GalileoAnalVendOperatore from  './contenuti/dashboards/galileopro/AnalVendOperatori'
import GalileoAnalAcquiFornitori from  './contenuti/dashboards/galileopro/AnalAcquiFornitori'
import GalileoAnaliFasciaOraria from './contenuti/dashboards/galileopro/AnalVendiFasciaOraria'
import GalileoProdoFermi from './contenuti/dashboards/galileopro/ProdottiFermi'
import GalileoAnaliMagazzino from './contenuti/dashboards/galileopro/AnalisiMagazzino'
import GalileoAnaliSconti from './contenuti/dashboards/galileopro/AnalSconti'
import GalileoAnaliRicetteVendite from  './contenuti/dashboards/galileopro/AnalRicetteVendite'
import GalileoAnalVenditeSintetica from './contenuti/dashboards/galileopro/AnalVendiSintetica'
import GalileoMercato from './contenuti/dashboards/galileopro/Mercato'
import GalileoAnalVenditeFarmacie from './contenuti/dashboards/galileopro/AnalVenditefarmacie'
import GalileoAnalVenditeGiornaDitta from './contenuti/dashboards/galileopro/AnalVendGiornDitta'


import TesctP from './contenuti/dashboards/lite/testApi'
import SendQuery from './contenuti/sendquery/SendQuery'
import ElencoRegistrazioniUdi from './contenuti/gestioneUdi/ElencoRegistrazioni'
import RegistrazioneUdi from './contenuti/gestioneUdi/Registrazione'
import GestioneUdi from './contenuti/cgm/GestioneUdi'
import AnalisiMedici from './contenuti/dashboards/smart/AnalisiMedici'
import AnalisiFarmaciErogati from './contenuti/dashboards/smart/AnalisiFarmaciErogati'
import AnalisiRicette from './contenuti/dashboards/smart/AnalisiRicette'
import RepVenditeEffettuate from './contenuti/dashboards/galileopro/RepVenditeEffettuate'
import ElencoClienti730 from './contenuti/infoFarmacie/ElencoClienti730'
import ElencoClientiMonitoraggioTelepharma2 from './contenuti/infoFarmacie/ElencoClientiMonitoraggioTelepharma2'
import ElencoListiniOrdineLibero from './contenuti/ordiniLiberi/ElencoListiniOrdineLibero'
import SingoloListinoOrdineLibero from './contenuti/ordiniLiberi/SingoloListinoOrdineLibero'
import GalileoVenditeInterni from './contenuti/dashboards/galileopro/interni/VenditeGalileoInterni'
import GalileoAcquistiInterni from './contenuti/dashboards/galileopro/interni/AcquistiGalileoInterni'
import MercatoGalileoInterni from './contenuti/dashboards/galileopro/interni/MercatoGalileoInterni'
import VenditeGiornoDittaGalileoInterni from './contenuti/dashboards/galileopro/interni/VenditeGiornoDittaInterni'
import GalileoVenFarmaInterni from './contenuti/dashboards/galileopro/interni/VenFarmaGalileoInterni'
import GalileoAcquiAggrInterni from './contenuti/dashboards/galileopro/interni/VenditeAcquiAggrGalileoInterni'
import GalileoVenOperatori from './contenuti/dashboards/galileopro/business_intelligence/GalileoVenOperatori'
import GalileoAcquiFornitori from './contenuti/dashboards/galileopro/business_intelligence/GalileoAcquistiFornitori'
import GalileoVenFascOrarie from './contenuti/dashboards/galileopro/business_intelligence/GalileoVenFasceOrarie'
import GalileoVenSconti from './contenuti/dashboards/galileopro/business_intelligence/GalileoVenSconti'
import GalileoVenAnalisiRicette from './contenuti/dashboards/galileopro/business_intelligence/GalileoVenAnalisiRicette'
import GalileoMagazzinoFermi from './contenuti/dashboards/galileopro/business_intelligence/GalileoMagazzinoFermi'
import GalileoMagazzinoGiacenza from './contenuti/dashboards/galileopro/business_intelligence/GalileoMagazzinoGiacenza'
import GalileoVenditeCategory from './contenuti/dashboards/galileopro/business_intelligence/GalileoVenditeCategory'
import GalileoVenSintesi from './contenuti/dashboards/galileopro/business_intelligence/GalileoVenSintesi'
import FbiAnaAcquiSintetica from './contenuti/dashboards/fbi_acquisti/FbiAnalisiAcquiSintetica'
import FbiAnaAcquiFornitore from './contenuti/dashboards/fbi_acquisti/FbiAnalisiAcquiFornitore'
import FbiAnaInciFarvimaAcqui from './contenuti/dashboards/fbi_acquisti/FbiAnalisiInciFarvimaAcqui'
import FbiAnaAcquiTipologia from './contenuti/dashboards/fbi_acquisti/FbiAnalisiAcquiTipologia'
import ListiniB2B from './contenuti/ordiniB2B/ListiniB2B'
import HomePage from './contenuti/HomePage'
import ElencoEsportazioniFarmacie from './contenuti/EsportazioneFarmacie/ElencoEsportazioniFarmacie'
import ReportTariffate from './contenuti/dashboards/smart/AnalisiRicetteNonTariffate'
import GestioneCoupon from './contenuti/dashboards/coupon/GestioneCoupon'
import ConfigCoupon from './contenuti/dashboards/coupon/ConfigurazioneCoupon'
import AnalisiRepDCR from './contenuti/monitoraggio/AnalisiReportDCRSmart'
import RegistratoreDiCassa from './contenuti/RegistratoreDiCassa/ElencoRegistratoreDiCassa'
import DashboardRegistratoreDiCassa from './contenuti/RegistratoreDiCassa/DashboardRegistratoreDiCassa'
import RegistratoreDiCassaDettaglio from './contenuti/RegistratoreDiCassa/ElencoRegistratoreDiCassaDettaglio'
import FarmaPlus from './contenuti/dashboards/Performance/PerformancePlus'
import ElencoClientiMonitoraggioFramework from './contenuti/infoFarmacie/ElencoClientiMonitoraggioFramework'
import ElencoFarmacieB2B from './contenuti/ordiniB2B/ElencoFarmacie'
import GestioneOrdiniB2B from './contenuti/ordiniB2B/GestioneOrdiniB2B'
import Documentazione from './contenuti/DocumentazioneRevisioni/Documentazioni'
import ElencoArticoli from './contenuti/DocumentazioneRevisioni/ElencoArticoli'
import ReleaseNote from './contenuti/DocumentazioneRevisioni/ReleaseNote'
import AllineamentoPrez from './contenuti/PrezziVendita/Allineamento_Prezzi'
import Autenticazione2FA from './contenuti/utenti/Autenticazione2FA'
//import ImpostazioniOrdineB2B from './contenuti/ordiniB2B/ImpostazioniOrdineB2B'
//import ElencoLisitniB2B from './contenuti/ordiniB2B/ListiniB2B'
//import AnalisiRicetteNew from './contenuti/dashboards/smart/AnalisiRicetteNew'

 //import ImpostazioniOrdineB2B from './contenuti/ordiniB2B/ImpostazioniOrdineB2B'
//import ElencoLisitniB2B from './contenuti/ordiniB2B/ListiniB2B'

//import ElencoRichiesteLivellamentoMagazzino from './contenuti/livellamentoMagazzino/ElencoRichieste'
//import DettaglioRichiestaLivellamentoMagazzino from './contenuti/livellamentoMagazzino/DettaglioRichiestaLivellamentoMagazzino'


//import anomalia

const Contenuto = (props: {
    toggleMenu: (stato: boolean) => void,
    statoMenu: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    menu: MenuInterface
}) => {

    return (
        <>
            <Switch>
                <Route exact path="/home">
                    <HomePageNew toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>

                <Route exact path="/home/new">
                    <HomePage toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} flagMyCsf={false} />
                </Route>
                <Route exact path="/home_myCsf">
                    <HomePage toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} flagMyCsf={true} />
                </Route>

                <Route path="/anagrafiche/fornitori_loc">
                    <AnagraficaFornitoriLocali datiFarmacie={props.datiFarmacie} toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path="/bancadati/prodotti">
                    <BancaDati toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path="/xadm_mongodb">
                    <XadmMondoDb />
                </Route>
                <Route path="/myCsf/anagrafica_farm">
                    <AnagraficaFarmacia toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} contenuto="La Mia Farmacia" />
                </Route>
                <Route  path="/consultazione/vendite">
                    <VenConsultazione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}    />
                </Route>
                <Route  path="/consultazione/ordini">
                    <FatOrdini toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/consultazione/carichi">
                    <FatCarichi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/consultazione/giacenze">
                    <MagGiacenze toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie }   />
                </Route>
                <Route  path="/consultazione/movimentazione">
                    <MagMovimentazione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}   />
                </Route>
                <Route  path="/cluster/gestione">
                    <Gestcluster toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/cluster/tipologia">
                    <Gestclustertipo toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>   
                <Route  path="/consultazione/operatori">
                    <ConsultazioneOperatori toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/operatori/gestione">
                    <GestCentraOperatori toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/prodotti/gruppo">
                    <GestProdGruppo toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/prodotti/personalizzati">
                    <GestCentProdPers toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route exact  path="/primanota">
                    <PrimaNota toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route exact  path="/banche">
                    <GestBanche toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/primanota/tipologia_causale">
                    <GestTipoCausale toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/businesslablite/vendite">
                    <VenditeBusiness toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}   />
                </Route>
                <Route  path="/businesslablite/acquisti">
                    <AcquistiBusiness toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}   />
                </Route>
                <Route  path="/businesslablite/mercato">
                    <MercatoBusiness toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/privacy">
                    <Privacy toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/dati">
                    <InfoDati toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/magazzino">
                    <InfoMagazzino toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/listini">
                    <InfoListini toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/ordini">
                    <InfoOrdini toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/fatture">
                    <InfoFatture toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/monitoraggio">
                    <InfoMonitoraggio toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/business_intelligence">
                    <InfoBs toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/tariffazione">
                    <InfoTariff toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/app_farmacia">
                    <InfoApp toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/info/app_clienti">
                    <InfoAppClienti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route  path="/consultazione/reportbeta">
                    <ReportGraficBeta toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/consultazione/report">
                    <ReportGrafic toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/scadenzario/fatture_attive">
                    <FattAttive toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/scadenzario/fatture_passive">
                    <FattPassive toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route  path="/scadenzario/fatture_passive_beta">
                    <FattPassiveBeta toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route  path="/anagrafica_gruppo">
                    <AnaGruppo toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/anagrafica_aziende">
                    <AnagAziende toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/anagrafica_farmacie">
                    <Anagfarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/monitoraggio/farmacie">
                    <MonitFarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/clienti/gestione">
                    <GestClientiFarm toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/consultazione/gestione_utenti">
                    <Utenti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/consultazione/gestione_profili">
                    <ProfiliUtenti menu={props.menu} toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/download">
                    <DownLoad toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/valorizzazione_inventario">
                    <ValorInventa toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/stat_businesslab">
                    <StatBusinessLab toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route  path="/stat_businesslab_oper">
                    <StatBusinessLabOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route  path="/stat_businesslab_report">
                    <StatBusinessLabReport toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route  path="/stat_businesslab_dashboard">
                    <StatBusinessLabDashb toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route  path="/fattura_elettronica/passive">
                    <FattElettronicaPassive toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/fattura_elettronica/attive">
                    <FattElettronicaAttive toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route  path="/consultazione/gestione/listini_fornitori">
                    <GestListiniForni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}  />
                </Route>
                <Route  path="/test">
                    <GestTry toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                


                <Route path="/myCsf/contratti_servizi_farm">
                    <AnagraficaMyCsf toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} contenuto="Contratti e Servizi Farmacia" url={Constanti.urlmyCsf.contrattiFarm} />
                </Route>
                <Route path="/myCsf/anagrafica_conc">
                    <AnagraficaMyCsf toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} contenuto="Anagrafica Concessionari" url={Constanti.urlmyCsf.anaConcess} />
                </Route>
                <Route path="/myCsf/contratti_servizi_conc">
                    <AnagraficaMyCsf toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} contenuto="Contratti e Servizi Concessionari" url={Constanti.urlmyCsf.contrattiConcess} />
                </Route>
                <Route path='/report/elenco_report' >
                    <Report toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/apriReport' >
                    <PaginaReport toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/ordiniRda/ordini_rda' >
                    <OrdiniRda toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/apriOrdineRda' >
                    <SingoloOrdineRda toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/ordiniRda/report' >
                    <ReportRda toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/gestioneRsa/elenco_clienti_rsa' >
                    <ElencoClientiRSA toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/ordiniLiberi/ordini_liberi' >
                    <ElencoOrdiniLiberi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/apriOrdineLibero' >
                    <SingoloOrdineLibero toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} farmacieGruppo={props.datiFarmacie} />
                </Route>
                <Route path='/ordiniLiberi/impostazioni' >
                    <ImpostazioniOrdineLibero toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/ordiniLiberi/listini' >
                    <ElencoListiniOrdineLibero toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/ordiniLiberi/listino_dettaglio' >
                    <SingoloListinoOrdineLibero toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/trasferimenti/trasferimenti_configurazione' >
                    <ConfigurazioneParametri toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} contenuto="Configurazione trasferimento merce" />
                </Route>
                <Route path='/trasferimenti/trasferimenti_elenco' >
                    <RichiesteTrasferimenti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/trasferimenti/trasferimenti_farmacie' >
                    <FarmacieTrasferimenti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie }/>
                </Route>
                <Route path='/trasferimenti/listini_elenco' >
                    <ElencoListini toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/trasferimenti/listino_dettaglio' >
                    <SingoloListino toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/screening/pannelloScreening' >
                    <Screening toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} contenuto="Programma &quot;Screening del colon-retto&quot;" />
                </Route>
                <Route path='/fatture/ProdottoAbbinamento' >
                    <ProdottoAbbinamento toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} contenuto="Abbinamento Codice Articolo - Prodotto" />
                </Route>
                <Route path='/monitoraggio/Farmacie730' >
                    <ElencoFarmacie730 toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/monitoraggio/FarmacieParametri' >
                    <ElencoFarmacieParametri toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/noteRilascio/NoteRilascio' >
                    <NoteRilascio toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} prodotto={1} />
                </Route>
                <Route path='/newsletter/ElencoClienti' >
                    <ElencoClienti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/newsletter/ElencoNewsletter' >
                    <ElencoNewsletter toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/ordiniNso/ordini_nso' >
                    <OrdiniNso toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu}  />
                </Route>
                <Route path='/newsletter/ElencoFileNewsletter' >
                    <ElencoFileNewsletter toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/contenitori/ElencoContenitori' >
                    <ElencoContenitori toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/contenitori/GetDettaglio' >
                    <SingoloContenitore toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/prezzoPromozione/ElencoPrezzoPromozione' >
                    <ElencoPrezzoPromozione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/prezzoPromozione/PrezziPromozione' >
                    <PrezziPromozione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/prezzoPromozione/LogPrezziPromozione' >
                    <LogPrezziPromozione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/prezzoPromozione/InvioPrezziPromozione' >
                    <InvioPrezziPromozione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiInfoFarmacie' >
                    <ElencoClientiInfoFarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoParametriFarmacia' >
                    <ElencoParametriFarmacia toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/InfoFarmacia' >
                    <InfoFarmacia toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiDoppioClient' >
                    <ElencoClientiDoppioClient toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/configurazioni/DatiAltreFarmacie' >
                    <ConfigurazioneDatiAltreFarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiTariffazione' >
                    <ElencoClientiTariffazione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiArt34' >
                    <ElencoClientiArt34 toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClienti730' >
                    <ElencoClienti730 toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioTelepharma2' >
                    <ElencoClientiMonitoraggioTelepharma2 toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiContrattiSmart' >
                    <ElencoClientiContrattiSmart toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioPlatinum' >
                    <ElencoClientiMonitoraggioPlatinum toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioDatabasePlatinum' >
                    <ElencoClientiMonitoraggioDatabasePlatinum toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioBancaDatiPlatinum' >
                    <ElencoClientiMonitoraggioBancaDatiPlatinum toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioBackupDBPlatinum' >
                    <ElencoClientiMonitoraggioBackupDBPlatinum toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioSpazioDiscoPlatinum' >
                    <ElencoClientiMonitoraggioSpazioDiscoPlatinum toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/infoFarmacie/ElencoClientiMonitoraggioFramework' >
                    <ElencoClientiMonitoraggioFramework toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                <Route path='/anomalia/ElencoAnomalieFarmacie' >
                    <ElencoAnomalieFarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/consultazione/FattureAbbinamenti' >
                    <FattAbbinamenti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                {/*<Route path="/anagrafiche/FornitoriCentralizzati">*/}
                {/*    <AnaFornCentr toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />*/}
                {/*</Route>*/}

                <Route path="/anagrafiche/fornitoriCentralizzati">
                    <AnaFornitoriCentr toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path="/magazzino/livellamento">
                    <ElencoRichiesteLivellamentoMagazzino toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/magazzino/livellamentoDettaglio' >
                    <DettaglioRichiestaLivellamentoMagazzino toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path='/businessIntelligence/SelloutAssistant' >
                    <SelloutAssistant username={props.menu.userName} toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path='/businessIntelligence/SelloutAssistantDettaglio' >
                    <SelloutAssistantDett username={props.menu.userName} toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path='/businessIntelligence/SelloutAssistantOperatore' >
                    <SelloutAssistantOper username={props.menu.userName} toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path='/test/Test' >
                    <Test />
                </Route>

                {/* Cruscotto direzionale */}

                <Route path='/businesslab/lite/Vendite' >
                    <BusinessLiteVenditeNewV toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/lite/Acquisti' >
                    <BusinessLiteAcquistiNewV toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/lite/Mercato' >
                    <LiteMercato toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/statisticheOperative/AnalisiMagazzino' >
                    <AnalisiMagazzino toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/statisticheOperative/OsservatorioMargini' >
                    <OsservatorioMargini toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                

                


                <Route path='/businesslab/lite/test' >
                    <TesctP toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/CruscottoDirezionale' >
                    <CruscottoDireEvol toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/CruscottoGruppo' >
                    <CruscottoGruppoEvol toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiVendite' >
                    <AnalisiVenditeEvol toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiPerformanceOperatore' >
                    <AnalPerfOperatore toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiScontiCategory' >
                    <AnaliScontiCategory toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiScontiGruppoMerceologico' >
                    <AnaliScontiGruppoMerce toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
               
                <Route path='/businesslab/evolution/AnalisiRicette' >
                    <CruscottoAnaliRicetteEvo toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiTrasferimenti' >
                    <AnalisiTrasferimenti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/evolution/AnalisiContenitori' >
                    <AnalisiConteni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

              {/* Cruscotto operativo */}

                <Route path='/businesslab/evolution/AnalisiVenditeDitta' >
                    <AnaliVendiDitta toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiVenditeCategory' >
                    <AnalVendCateOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiVenditeSettoreInventario' >
                    < AnalVendSetInvenOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiVenditeGruppoMerceologico' >
                    <AnaliVendGruMerce toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/evolution/AnalisiAcquistiCategory' >
                    <AnalAcquiCategory toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/evolution/AnalisiAcquistiGruppoMerceologico' >
                    <AnalAcquiGrupMerc toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiAcquistiPerSettoreInventario' >
                    <AnalAcquiSettInvenOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiAcquistiPerDitta' >
                    <AnalAcquiDittaOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiFornitoriDittaCategory' >
                    <AnaliFornDittaCategory toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/evolution/AnalisiFornitoriDittaGruppoMerceologico' >
                    <AnaliFornDittaGruppo toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/businesslab/evolution/AnalisiMarginiMedi' >
                    <AnalisiMargiMedi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiVenditaSottoMargineOperativo' >
                    <AnalisiVendiSottMargineOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiVenditaFasciaOrariaOperativo' >
                    <AnalVendFascOrariaOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/evolution/AnalisiProdottiFermi' >
                    <AnalProdFermi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/reportistica/VenditeEffettuate' >
                    <VenditeEffettuate toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/reportistica/AnalisiTrasferimenti' >
                    <AnalTrasferimenti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>


                
                <Route path='/businesslab/operativo/AnalisiVenditeDitta' >
                    <AnaliVendiDitta toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/businesslab/operativo/AnalisiVenditeCategory' >
                    <AnalVendCateOper toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                

                <Route path='/fbi/AnalisiVenditeSintetica' >
                    <AnalVendiSinte toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/CruscottoVendite' >
                    <CruscoVendite toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiDettaglioVendite' >
                    <AnalisiDettaglioVendite toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiVenditeOperatore' >
                    <AnalVendiOperatore toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiVenditeFasciaOraria' >
                    <AnalVendFascOraria toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiScontisticaVendite' >
                    <AnaliScontiVendita toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiVenditeRicette' >
                    <AnaliVendiRicetta toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiVenditeSottoMargine' >
                    <AnalisiVendiSottMargine toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiMarginiMediVendita' >
                    <AnalisiMargiMediVendita toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/StatoFarmacia' >
                    <FbiStatofarmacia toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>          

                
                <Route path='/galileopro/AnalisiVenditeCategory' >
                    <GalileoAnaliVendCateg toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiVenditeOperatore' >
                    <GalileoAnalVendOperatore toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiAcquistiFornitori' >
                    <GalileoAnalAcquiFornitori toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiVenditeFasciaOraria' >
                    <GalileoAnaliFasciaOraria toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/ProdottiFermi' >
                    <GalileoProdoFermi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiMagazzino' >
                    <GalileoAnaliMagazzino toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiSconti' >
                    <GalileoAnaliSconti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiRicetteVendite' >
                    <GalileoAnaliRicetteVendite toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiVenditeSintetica' >
                    <GalileoAnalVenditeSintetica toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiMercato' >
                    <GalileoMercato toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiVenditeFarmacie' >
                    <GalileoAnalVenditeFarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/galileopro/AnalisiVenditeGiornaliereDitta' >
                    <GalileoAnalVenditeGiornaDitta toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/galileopro/reportistica/VenditeEffettuate' >
                    <RepVenditeEffettuate toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
       
                {<Route path='/galileopro/CruscottoGruppo' >
                    <GalileoCruscottoGruppo toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/Vendite' >
                    <GalileoVenditeInterni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/Acquisti' >
                    <GalileoAcquistiInterni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/Mercato' >
                    <MercatoGalileoInterni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}


                {<Route path='/galileopro/VenditeGiornoDitta' >
                    <VenditeGiornoDittaGalileoInterni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/VenditeFarmacie' >
                    <GalileoVenFarmaInterni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/VenditeAcquistiAggregati' >
                    <GalileoAcquiAggrInterni toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/VenditeOperatori' >
                    <GalileoVenOperatori toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/AcquistiFornitori' >
                    <GalileoAcquiFornitori toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/VenditeFasceOrarie' >
                    <GalileoVenFascOrarie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/VenditeSconti' >
                    <GalileoVenSconti toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                <Route path='/galileopro/VenditeSintesi' >
                    <GalileoVenSintesi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                {<Route path='/galileopro/VenditeAnalisiRicette' >
                    <GalileoVenAnalisiRicette toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/VenditeCategory' >
                    <GalileoVenditeCategory toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/MagazzinoGiacenza' >
                    <GalileoMagazzinoGiacenza toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                {<Route path='/galileopro/MagazzinoProdottiFermi' >
                    <GalileoMagazzinoFermi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>}

                <Route path='/sendquery/SendQuery' >
                    <SendQuery toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/gestioneUdi/elenco' >
                    <ElencoRegistrazioniUdi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>
                <Route path='/gestioneUdi/registra' >
                    <RegistrazioneUdi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path='/sfbd/gestioneUdi' >
                    <GestioneUdi datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/smart/AnalisiRicette' >
                    <AnalisiRicette toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/smart/AnalisiMedici' >
                    <AnalisiMedici toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/smart/AnalisiFarmaciErogati' >
                    <AnalisiFarmaciErogati toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiAcquistiSintetica' >
                    <FbiAnaAcquiSintetica toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiAcquistiPerTipologia' >
                    <FbiAnaAcquiTipologia toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiAcquistiFornitore' >
                    <FbiAnaAcquiFornitore toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/fbi/AnalisiIncidenzaFarvimaAcquisti' >
                    <FbiAnaInciFarvimaAcqui toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/ordiniB2B/listini' >
                    <ListiniB2B toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/ordiniB2B/elenco' >
                    <ElencoOrdiniLiberi toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} flagB2B={true} />
                </Route>

                <Route path='/ordiniB2B/impostazioni' >
                    <ImpostazioniOrdineLibero toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} flagOrdineB2B={true} />
                </Route>

                <Route path='/apriOrdineB2B' >
                    <SingoloOrdineLibero toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} farmacieGruppo={props.datiFarmacie} flagOrdineB2B={true} />
                </Route>

                <Route path='/ordiniB2B/elencoFarmacie' >
                    <ElencoFarmacieB2B toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path='/ordiniB2B/gestione' >
                    <GestioneOrdiniB2B toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>
                
                <Route path='/EsportazioneFarmacie/ElencoEsportazioniFarmacie' >
                    <ElencoEsportazioniFarmacie toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/smart/AnalisiRicetteNonTariffate' >
                    <ReportTariffate toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/coupon/GestioneCoupon' >
                    <GestioneCoupon toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/coupon/ConfigurazioneCoupon' >
                    <ConfigCoupon toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/smart/AnalisiReportDCRSmart' >
                    <AnalisiRepDCR toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/RegistratoreDiCassa/DashBoard' >
                    <DashboardRegistratoreDiCassa toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/RegistratoreDiCassa/ElencoRegistratoreDiCassa' >
                    <RegistratoreDiCassa toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path='/RegistratoreDiCassa/ElencoRegistratoreDiCassaDettaglio' >
                    <RegistratoreDiCassaDettaglio toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}/>
                </Route>

                <Route path="/info/PerformancePlus">
                    <FarmaPlus toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} />
                </Route>

                <Route path="/DocumentazioniRevisioni/Documentazione">
                    <Documentazione toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                <Route path="/DocumentazioniRevisioni/ElencoArticoli/:revisioneRilascio">
                    <ElencoArticoli toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}/>
                </Route>

                <Route path="/DocumentazioniRevisioni/ReleaseNote">
                    <ReleaseNote toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie}/>
                </Route>

                <Route path="/PrezziVendita/AllineamentoPrezzi">
                    <AllineamentoPrez toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />
                </Route>

                {/*<Route path="/consultazione/Autenticazione2FA">*/}
                {/*    <Autenticazione2FA toggleMenu={() => props.toggleMenu(props.statoMenu)} statoMenu={props.statoMenu} datiFarmacie={props.datiFarmacie} />*/}
                {/*</Route>*/}


            </Switch>
        </>
    )
}

export default Contenuto