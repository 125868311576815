import React, { useState, useEffect, useRef   } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import TextBox from 'devextreme-react/text-box'
import { NumberBox } from 'devextreme-react/number-box'
import { SelectBox } from 'devextreme-react/select-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'
import DateBox from 'devextreme-react/date-box'
import { DialogConferma } from '../../../widget/Notifications'
import Button from 'react-bootstrap/Button'
import AsyncSelect from "react-select/async"


import './style.css';




interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
    listaFarmacie:any
} 

export const DialogPrimaNota:React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit,listaFarmacie},ref)  => {

    const formRef:any = useRef();
  
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [causali, setCausali] = useState<any>([])
    const [conti, setConti] = useState<any>([])
    const [fatture, setFatture] = useState<any>([])
    const [openD,setOpenD] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [popAsk, setPopAsk] =  useState(false)
    const [objPostRicerca , setObjPostRicerca ] = useState<any>({})



    const getListFatt = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFatture)
               .then(result => {
                   if (result?.esito) {                    
                       //console.log(result.response);
                       setFatture(result.response)                     
                   }else{                           
                       //console.log(result);  
                       
                   }
               })     
       }
   }



    const getCausali = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListTipoCausaPrimaNota)
               .then(result => {
                   if (result?.esito) {
                       
                       //console.log(result.response);
                       setCausali(result.response)
                       
                   }else{
                                  
                       //console.log(result);
                       notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                       
                   }
               })     
       }
   }
   const getConti = () =>{
    if (auth) {
       RestServices().rest('POST', {} , urlConsultazione.getListConti)
           .then(result => {
               if (result?.esito) {          
                   //console.log(result.response);
                   setConti(result.response)
                   
               }else{                         
                   //console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                   
               }
           })     
   }
}


    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit    
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave(null) 
        }

    },[edit])

    const ckIfEntrataO = () => {

        if (causali) {

            let ret = 'null';
            causali.forEach((causaleGroup: any) => {
                causaleGroup.items.forEach((causale: any) => {
                    if (causale.id === objctTOsave?.idCausale) {
                        if (causale.flagEntrata)
                            ret = 'ent'
                        else if (causale.flagUscita)
                            ret = 'usc'
                        else
                            ret = objctTOsave?.idCausale
                    }
                    
                })
            });

            return ret

            //index = causali.findIndex((object:any) => {
            //    return object.id === objctTOsave?.idCausale
            //});

            //if (index == undefined || index < 0)
            //    index = causali.findIndex((object: any) => {
            //        return object.id === objctTOsave?.idCausale
            //    });

            //console.log(index);

            //if(index > -1){
            //    if(causali[index].flagEntrata){
            //        console.log('ent')
            //        return 'ent'
            //    }
            //    if (causali[index].flagUscita) {
            //        console.log('usc')
            //        return 'usc'
            //    }
            //}else{
            //    return objctTOsave?.idCausale
            //} 
        }else{
            return 'null'
        }
         
          

        
    }

    

    useEffect(()=>{
        if(open){
            getCausali()
            getConti()
            getListFatt()
        }
    },[open])




    const cambiaValore = (tipo:string,value:string) =>{
        let objTo = {...objctTOsave}
        objTo[tipo] = value
        //console.log(objTo);
        if(tipo == 'idCausale'){
            objTo.importoEntrata = 0
            objTo.importoUscita = 0
        }
        setObjctTOsave(objTo)
        if(objTo.idCausale && objTo.titolo  && objTo.idConto && (objTo.importoEntrata || objTo.importoUscita) ){
            setDisableButton(false)
        }else{
            setDisableButton(true)
        }
    }



const creaModificaPrimaNota = (modifica?:boolean) =>{
    objctTOsave.titolo = objctTOsave.titolo.toUpperCase()
    if(objctTOsave.flagEntrata){
        delete objctTOsave.flagEntrata
    }
    if(objctTOsave.flagUscita){
        delete objctTOsave.flagUscita
    }
    
    if(objctTOsave.saldoConto){
        delete objctTOsave.saldoConto
    }
   if (auth) {
       if(modifica){
           if(objctTOsave.clienteFornitore){
                delete objctTOsave.clienteFornitore
           }
           if(objctTOsave.farmacia){
               delete objctTOsave.farmacia
           }
           if(objctTOsave.conto){
            delete objctTOsave.conto
            }
            if(objctTOsave.causale){
                delete objctTOsave.causale
            }
            if(objctTOsave.causale){
                delete objctTOsave.causale
            }
            if(objctTOsave.id){
                 objctTOsave.idPrimaNota = objctTOsave.id
                 delete objctTOsave.id

            }
            if(objctTOsave.idFatScadenzario){
                delete objctTOsave.idFatScadenzario

            }
            if(objctTOsave.riferimentoFattura){
                delete objctTOsave.riferimentoFattura

            }
            if(objctTOsave.linkFattura){
                delete objctTOsave.linkFattura

            }
            if(!objctTOsave.importoUscita){
                objctTOsave.importoUscita = null
            }
            if(!objctTOsave.importoEntrata){
                objctTOsave.importoEntrata = null
            }

           // console.log(objctTOsave)

            
           RestServices().rest('POST', objctTOsave , urlConsultazione.putPrimaNota)
           .then(result => {
               if(result.esito){
                   ricarica()
                   setObjctTOsave(null)
                   setPopAsk(false)
               }else{
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })
       }else{
           if(objctTOsave.idFar){
            objctTOsave.idFar = objctTOsave.idFar.idFar
           }
           if(objctTOsave.idConto){
            objctTOsave.idConto = objctTOsave.idConto.id
           }if(objctTOsave.idCausale){
            objctTOsave.idCausale = objctTOsave.idCausale.id
           }
           if(!objctTOsave.data){
            objctTOsave.data = new Date()
           }
          //console.log(objctTOsave);
          if(!objctTOsave.importoUscita){
            objctTOsave.importoUscita = 0
        }
        if(!objctTOsave.importoEntrata){
            objctTOsave.importoEntrata = 0
        }
           
        
        
           RestServices().rest('POST', objctTOsave , urlConsultazione.postPrimaNota)
           .then(result => {
               if(result.esito){
                   ricarica()
                   setObjctTOsave(null)
               }else{
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)

               }
           })
       }      
     } 
   }


    useEffect(()=>{
        setOpenD(open)
    },[auth])

  

    useEffect(()=>{
        setOpenD(open)
    },[open])

    const populateObjPost = (id:string , value:any) =>{
        let nuovoObj = {...objPostRicerca}
        nuovoObj[id] = value
        if(objPostRicerca.type == 'autocomplete'){
       
        }
        setObjPostRicerca(nuovoObj)
    }


  
    const loadOptions = (
        inputValue: string,
        callback: (options: any) => void
        ) => {
            let idfartoUse = null
            if(objctTOsave?.idFar){
                if(typeof objctTOsave.idFar === 'number'){
                    idfartoUse =  objctTOsave.idFar
                }else{
                    idfartoUse = objctTOsave.idFar.idFar
                }
                
            }
            
            if(inputValue){
                RestServices().rest('POST', {cerca:inputValue ,idFar:idfartoUse,flagFattureAttive:true,flagFatturePassive:false } , urlConsultazione.getListFatture)
                .then((result: { esito: any; response: any }) => {
                    if (result?.esito) {
                        let arrToUse:any = []
                        result.response.forEach((element:any) => {
                            arrToUse.push({value:element.idFattura,label:element.testoCompleto})
                        });
                        //console.log('vediamo reiuok');
                        
                        //console.log(result.response);
                        //console.log(arrToUse);
                        
                        callback(arrToUse);
                        
                    }else{
                                   
                        //console.log(result);
                        callback([{value:'pp',label:'no'}]);
                        
                    }
                    
                }) 
            }
           
            
      };
    
    const loadOptionsPassiva = (
        inputValue: string,
        callback: (options: any) => void
        ) => {
            let idfartoUse = null
            if(objctTOsave?.idFar){
                if(typeof objctTOsave.idFar === 'number'){
                    idfartoUse =  objctTOsave.idFar
                }else{
                    idfartoUse = objctTOsave.idFar.idFar
                }
                
            }
            if(inputValue){
                RestServices().rest('POST', {cerca:inputValue ,idFar:idfartoUse,flagFattureAttive:false,flagFatturePassive:true } , urlConsultazione.getListFatture)
                .then((result: { esito: any; response: any }) => {
                    if (result?.esito) {
                        let arrToUse:any = []
                        result.response.forEach((element:any) => {
                            arrToUse.push({value:element.idFattura,label:element.testoCompleto})
                        });
                        //console.log('vediamo reiuok');
                        
                        //console.log(result.response);
                        //console.log(arrToUse);
                        
                        callback(arrToUse);
                        
                    }else{
                                   
                        //console.log(result);
                        callback([{value:'pp',label:'no'}]);
                        
                    }
                    
                }) 
            }
           
            
      };  


      const handleInputChange = (newValue: string) => {
        //console.log('cambio');
        //console.log(newValue);
        
        
        const inputValue = newValue.replace(/\W/g, "");
        //this.setState({ inputValue });
        return inputValue;
      };

    return (
        <>
        <DialogConferma
                    dialogIsOpen={popAsk}
                    message={'sei sicuro di voler procedere con la modifica?'}
                    functionNext={()=>alert('ok')}
                    handleCloseConferma={()=>{setPopAsk(false)}}
                />    
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'80%'}
          showTitle={true}
          title="Gestione Prima Nota"
        >

           <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}
          />
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={typeEdit ? {
                text: 'Modifica',
                elementAttr:{
                    class:'btncreatecluster',
                },
                disabled:false,
                onClick: ()=>setPopAsk(true),
              }: {
                text: 'CREA',
                class:'btncreatipocluster',
                disabled:disableButton,
                elementAttr:{
                    class:'btncreatecluster',
                },
                onClick: ()=>creaModificaPrimaNota(),
              }}
          />
          

        {typeEdit ? (
               <ScrollView width='100%' height='100%'>
                      
              <div className='form-row '>
                      <div className="form-group col-md-6">
                     <label> DATA </label>          
                     <DateBox valueChangeEvent="keyup" showClearButton={true} max={new Date()} value={objctTOsave && objctTOsave.data ?  objctTOsave.data : new Date()}  onValueChanged={(v)=>cambiaValore('data',v.value) }  type="date" displayFormat="dd/MM/yyyy" />               
                       </div>

                        <div className="form-group col-md-6">
                        <label> CAUSALE  </label> 
                                <SelectBox dataSource={causali} grouped={ true} valueExpr="id"   displayExpr="descrizioneBreve"  valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave && objctTOsave.idCausale ?  objctTOsave.idCausale : ''} onValueChanged={(v)=>cambiaValore('idCausale',v.value) }    /> 
                        </div>  
                        </div>  
                        
                        <div className='form-row '>
                        <div className="form-group col-md-6">
                        <label> TITOLO </label> 
                        <TextBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.titolo}   onValueChanged={(v)=>cambiaValore('titolo',v.value) }  placeholder="..." />               
                          </div>
                        
                          <div className="form-group col-md-6">
                        <label> DESCRIZIONE </label> 
                        <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.descrizione}  onValueChanged={(v)=>cambiaValore('descrizione',v.value) }  placeholder="..." />               
                          </div> 
                       </div>

                       <div className='form-row '>                        
                        <div className="form-group col-md-6">
                        <label> FARMACIA  </label> 
                       <SelectBox dataSource={listaFarmacie} showDataBeforeSearch={true} ref={formRef} valueExpr="idFar"  displayExpr="descrizione"   valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave?.idFar} onValueChanged={(v)=>cambiaValore('idFar',v.value) } placeholder="...."    /> 
                        </div> 
   
                          <div className="form-group col-md-6">
                        <label> CONTO </label> 
                        <SelectBox valueExpr="id"  dataSource={conti} searchEnabled={true}  displayExpr={(item)=>{
                           return item?.conto
                       }}     onInput={(e:any)=>console.log(e.component.option("text"))} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave && objctTOsave.idConto ?  objctTOsave.idConto : ''}  onValueChanged={(v)=>cambiaValore('idConto',v.value) }  placeholder="..." />               
                          </div>
           
                       </div>
                       
                        <div className='form-row '>
                        {ckIfEntrataO() == 'ent' && objctTOsave.idFatScadenzario == undefined ? (
                        <div className="form-group col-md-6">
                        <label> IMPORTO IN ENTRATA </label> 
                            <NumberBox disabled={objctTOsave?.importoUscita} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.importoEntrata} onValueChanged={(v)=>cambiaValore('importoEntrata',v.value) } placeholder="€"  /> 
                        </div>
                        ) : null}  
                        {ckIfEntrataO() == 'usc' && objctTOsave.idFatScadenzario == undefined  ? (
                        <div className="form-group col-md-6">
                        <label> IMPORTO IN USCITA  </label> 
                            <NumberBox disabled={objctTOsave?.importoEntrata}  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.importoUscita} onValueChanged={(v)=>cambiaValore('importoUscita',v.value) } placeholder="€"  /> 
                        </div> 
                        ) : null}        
                       

                                                               
                       </div> 
                       <div className='form-row '> 
                { objctTOsave?.idFar ?  (
                        <>   

                        {ckIfEntrataO() == 'ent' && objctTOsave.idFatScadenzario == undefined ? (
                        <div className="form-group col-md-6">
                        <label> FATTURA ATTIVA </label> 
                        <AsyncSelect
                         placeholder="cerca"
                         isClearable= {true}
                         loadOptions={loadOptions}
                         onChange={(x:any)=>cambiaValore('idFattura',x?.value)}
                         //onInputChange={handleInputChange}
                         />
                        </div>
                        ) : null}  
                        {ckIfEntrataO() == 'usc' && objctTOsave.idFatScadenzario == undefined ? (
                        <div className="form-group col-md-6">
                        <label> FATTURA PASSIVA </label> 
                        <AsyncSelect
                         placeholder="cerca"
                         isClearable= {true}
                         loadOptions={loadOptionsPassiva}
                         onChange={(x:any)=>cambiaValore('idFattura',x?.value) }
                         //onInputChange={handleInputChange}
                         />
 
                        </div>
                        ) : null}
                        
                                
                       

                   

                      
                       </>
                    ) :<p></p> }
                    </div>
          
           </ScrollView>
            ) : (
                <ScrollView width='100%' height='100%'>

                      <div className='form-row '>  
                      <div className="form-group col-md-6">
                     <label> DATA </label>          
                     <DateBox valueChangeEvent="keyup" showClearButton={true} max={new Date()} value={objctTOsave && objctTOsave.data ?  objctTOsave.data : new Date()}  onValueChanged={(v)=>cambiaValore('data',v.value) }  type="date" displayFormat="dd/MM/yyyy" />               
                       </div>

                        <div className="form-group col-md-6">
                        <label> CAUSALE  </label> 
                                    <SelectBox dataSource={causali} grouped={true} searchEnabled={true}   displayExpr="descrizioneBreve"  valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave && objctTOsave.idCausale ?  objctTOsave.idCausale : ''} onValueChanged={(v)=>cambiaValore('idCausale',v.value) }    /> 
                        </div> 
                        
                       </div>

                     <div className='form-row '>        
                     <div className="form-group col-md-6">
                        <label> TITOLO </label> 
                        <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.titolo : ''}  onValueChanged={(v)=>cambiaValore('titolo',v.value) }  placeholder="..." />               
                          </div>

                       <div className="form-group col-md-6">
                     <label> DESCRIZIONE </label> 
                     <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.descrizione : ''}  onValueChanged={(v)=>cambiaValore('descrizione',v.value) }  placeholder="..." />               
                       </div>
        
                    </div>

                    <div className='form-row '>                   
                        <div className="form-group col-md-6">
                        <label> FARMACIA  </label> 
                       <SelectBox  ref={formRef} displayExpr="descrizione"  searchEnabled={true} dataSource={listaFarmacie} valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave ?  objctTOsave.idFar : ''}  onValueChanged={(v)=>cambiaValore('idFar',v.value)}   placeholder="...."    /> 
                        </div> 
   
                          <div className="form-group col-md-6">
                        <label> CONTO </label> 
                        <SelectBox  dataSource={conti} searchEnabled={true}   displayExpr="conto"   onInput={(e:any)=>console.log(e.component.option("text"))} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave && objctTOsave.idConto ?  objctTOsave.idConto : ''}  onValueChanged={(v)=>cambiaValore('idConto',v.value) }  placeholder="..." />               
                          </div>
           
                       </div>
                       

                    
                    <div className='form-row '>
                     {!objctTOsave?.idCausale || objctTOsave?.idCausale?.flagUscita ? (
                        null
                     ) : (
                        <div className="form-group col-md-6">
                        <label> IMPORTO IN ENTRATA </label> 
                        <NumberBox disabled={!objctTOsave?.idCausale || objctTOsave?.idCausale?.flagUscita}  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.importoEntrata : 0} onValueChanged={(v)=>cambiaValore('importoEntrata',v.value) } placeholder="€"  /> 
                        </div>
                     )} 
                     {!objctTOsave?.idCausale || objctTOsave?.idCausale?.flagEntrata ? (
                        null
                     ) : (
                        <div className="form-group col-md-6">
                        <label> IMPORTO IN USCITA </label> 
                        <NumberBox disabled={!objctTOsave?.idCausale || objctTOsave?.idCausale?.flagEntrata}  valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ?  objctTOsave.importoUscita : 0} onValueChanged={(v)=>cambiaValore('importoUscita',v.value) } placeholder="€"  /> 
                        </div> 
                     )}               
                    
                    
                    
                </div>
                       
                <div className='form-row '> 
                { objctTOsave?.idFar ?  (
                        <>  

                         {!objctTOsave?.idCausale || objctTOsave?.idCausale?.flagEntrata ? 
                         (
                            <div className="form-group col-md-6">
                            <label> FATTURA ATTIVA</label> 
                            <AsyncSelect
                             placeholder="cerca"
                             isClearable= {true}
                             loadOptions={loadOptions}
                             onChange={(x:any)=>cambiaValore('idFattura',x?.value)}
                             //onInputChange={handleInputChange}
                             />
                        </div>) : null }        
                       

                   {!objctTOsave?.idCausale || objctTOsave?.idCausale?.flagUscita ? (
                    <div className="form-group col-md-6">
                    <label> FATTURA PASSIVA </label> 
                    <AsyncSelect
                     placeholder="cerca"
                     isClearable= {true}
                     loadOptions={loadOptionsPassiva}
                     onChange={(x:any)=>cambiaValore('idFattura',x?.value) }
                     //onInputChange={handleInputChange}
                     />

                    </div>
                   ) : null}
                   
                       </>
                    ) :<p></p> }
                    </div>
                    
                    
     
            </ScrollView>
            )}
        
           

        </Popup>
        <Popup
          visible={popAsk}
          showCloseButton={false}
          width={'50%'}
          height={'25%'}
          showTitle={false}
          title="Modifica Prima Nota"
        >
        <hr></hr>    
        Sei sicuro di voler procedere con la modifica?
        <hr></hr> 
        <div style={{display:'flex', justifyContent: 'flex-end', gap: 10}}>

        <Button id="modalConfermaOk" variant="success" onClick={()=>creaModificaPrimaNota(true)}>
            OK
        </Button>
        <Button id="modalConfermaAnnulla" variant="danger" onClick={()=>setPopAsk(false)} >
            Annulla
        </Button>
        </div>  
        </Popup>   
        </>
      )

  
}
