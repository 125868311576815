import { AplicaValoreTO } from "../../../../funzioni/operazioniSuTabelle";

export const columnsAnalisi = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Giorni lavorati",
      dataField: "GIORNI_LAVORATI",
      width: "auto",
    },
    {
      caption: "Ore lavorate",
      dataField: "ORE_LAVORATE",
      width: "auto",
    },
    {
      caption: "Media oraria giornaliera",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },

    },
    {
      caption: "Numero vendite",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Numero di righe di vendita",
      dataField: "NUMERO_RIGHE",
      width: "auto",
    },
    {
      caption: "% Vendite SSN",
      dataField: "PERC_VENDUTO_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Vendite Miste",
      dataField: "PERC_VENDITE_MISTE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "% Vendite Libere",
      dataField: "PERC_VENDITE_LIBERE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media righe vendita",
      dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Media oraria righe di vendita",
      dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Pezzi",
      dataField: "PEZZI",
      width: "auto",
    },
    {
      caption: "Valore vendita medio",
      dataField: "VALORE_VENDITA_MEDIO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
  ];

  export const columnsAnalisiPopMerce = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Codice merceologico",
      dataField: "PRO_COD_GRUPPO_MERCEOLOGICO1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "MARGINE_PERCENTUALE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export const columnsAnalisiPopCrossFarm = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Farmacia",
      dataField: "FARM_DESCRIZIONE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Ore lavorate",
      dataField: "ORE_LAVORATE",
      width: "auto",
    },
    {
      caption: "Media oraria lavorata",
      dataField: "MEDIA_ORARIA_GIORNALIERA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Numero vendite",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Numero righe di vendita",
      dataField: "NUMERO_RIGHE",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Media righe vendita",
      dataField: "MEDIA_RIGHE_VENDITA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Media oraria righe di vendita",
      dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
      width: "auto",
      customizeText: (cellInfo: any) =>
      cellInfo?.value?.toFixed(2),
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore vendita medio",
      dataField: "VALORE_VENDITA_MEDIO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
  ];

  export const columnsAnalisiPopOpeDitta = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Ditta",
      dataField: "PRO_DESC_DITTA_TITOLARE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
      
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },

  ];

  export const columnsAnalisiPopOpeDittaTwo = [
    {
      caption: "Ditta",
      dataField: "PRO_DESC_DITTA_TITOLARE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore del venduto",
      dataField: "VALORE_VENDUTO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % valore del venduto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },


    {
      caption: "Media oraria valore del venduto",
      dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
      width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine",
      dataField: "MARGINE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "% Incidenza margine",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "INCIDENZA_MARGINE",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export const columnsOpeAnaScoCatePop = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto ",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % sconto",
      dataField: "INCIDENZA_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del vendtuo ",
      dataField: "IMPORTO_LORDO_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

export const columnsOpeAnaScoCate = [
    {
        caption: "Codice",
        dataField: "PRO_COD_CATEGORY1",
        width: "auto",
        fixed: true,
        sortOrder: 'asc'
    },
    {
      caption: "Categoria",
      dataField: "PRO_DESC_CATEGORY1",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto periodo selezione",
      dataField: "VEN_SCONTO_VENDITA",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale valore sconto",
        dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    }
];

export const columnsOpeAnaScoCate2 = [
    {
        caption: "Codice",
        dataField: "ID_FARMACIA",
        width: "auto",
        fixed: true,
        sortOrder: 'asc'
    },
    {
        caption: "Farmacia",
        dataField: "FARM_DESCRIZIONE",
        width: "auto",
        fixed: true
    },
    {
        caption: "Anno / Mese",
        dataField: "ANNOMESE",
        width: "auto",
    },
    {
        caption: "Valore sconto periodo selezione",
        dataField: "VEN_SCONTO_VENDITA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    
];

  export const columnPopAnaScoCateg = [
    {
      caption: "Operatore",
      dataField: "OPERATORE",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto ",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % sconto",
      dataField: "INCIDENZA_VENDUTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto percentuale",
      dataField: "PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export const columnPopAnaScoVendGruMerc = [
    {
      caption: "Operatore",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto ",
      dataField: "VALORE_SCONTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % sconto",
      dataField: "INCIDENZA_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Import lordo sconto ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export const columnsAnaScoCateMerce  = [
    {
      caption: "Gruppo merceologico",
          dataField: "CUNCAT_TEXT_GRUPPOMERC",
      width: "auto",
      fixed: true
    },
    {
      caption: "Valore sconto periodo selezione",
      dataField: "VEN_SCONTO_VENDITA",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale valore sconto",
      dataField: "INC_PERC_SCONTO",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Sconto percentuale",
      dataField: "SCONTO_PERC",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  // pop anal ricette

  export const columnPopAnaRicette = [
    {
      caption: "Farmacia",
      dataField: "FARMACIA",
      width: "auto",
      fixed: true
    },
    {
      caption: "Periodo",
      dataField: "DATA",
      width: "auto",
      fixed: true
    },
    {
      caption: "Venduto lordo",
      dataField: "VENDUTO_LORDO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile lordo",
      dataField: "IMPONIBILE_LORDO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Decurtazione in DCR",
      dataField: "DCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Venduto netto decurtazione SSN",
      dataField: "VENDUTO_SSN",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile netto decurtazione SSN",
      dataField: "IMPONIBILE_SSN",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore Ticket + Quota ricetta",
      dataField: "DCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Previsione incasso DCR",
      dataField: "DCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Numero ricette lavorate ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: " Valore medio per ricetta",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Pezzi venduti SSN",
      dataField: "FARMACIA",
      width: "auto",
    },
    {
      caption: "Numero medio pezzi per ricetta",
      dataField: "FARMACIA",
      width: "auto",
    },
   
  ];

  export const columnsAnaTrasferime  = [
    {
      caption: "Farmacia ricevente",
      dataField: "FARM_DESCRIZIONE",
      width: "auto",
      fixed: true,
      groupIndex:0,
      
    },
    {
      caption: "Mese",
      dataField: "ANNOMESE",
      width: "auto",
      groupIndex:1,
    },
    
    {
      caption: "Fornitore",
      dataField: "ANA_RAGIONE_SOCIALE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Costo del prodotto",
      dataField: "COSTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    
    {
      caption: "Numero pezzi",
      dataField: "QUANTITA",
      width: "auto",
    },
    
  ];