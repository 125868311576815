import { TestataContenitoreInterface } from "../../interfaces/contenitore";
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";
import { ElencoListiniInterface } from "../../interfaces/ordineLibero";

const TestataListinoB2B = (props: {
    testata: ElencoListiniInterface | undefined,
}) => {

    return (
        <>
            <Col sm='4'>
                <span>Ditta/Farmacia:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.farmacia}</span>
            </Col>
            <Col sm='3'>
                <span>Nome:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.titolo}</span>
            </Col>
            <Col sm='3'>
                <span>Descrizione:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.descrizione}</span>
            </Col>
            <Col sm='2'>
                <span>Data:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.data && cambiaFormatoLocalDateTimeToEuropeo(props.testata.data.toString())}</span>
            </Col>
        </>
    )
}

export default TestataListinoB2B;