import { useAuth } from "../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../interfaces/farmacie"
import { urlOrdiniLiberi } from "../costanti";
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { BoxFiltro, Check } from '../../widget/Editor';
import { Loading } from '../../widget/Notifications';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import TabPanel, { Item } from 'devextreme-react/tab-panel';
import { SelectFarm, Selezione, SelezioneGerarchica, SelezioneMultipla, SelezioneMultiplaGerarchica, TendinaFarm } from '../../widget/Select';
import { CategoryInterface, ClasseAInterface, DittaInterface, FornitoriCentrInterface, GruppiInterface, GruppoMerceologicoInterface, OggettoInterface, SettoriInterface } from '../../interfaces/generiche';
import { ImpostazioniOrdineLiberoInterface, ImpostazioniOrdineLiberoOrdineFarmacia, ImpostazioniOrdineLiberoPuntiVendita, ImpostazioniOrdineLiberoPuntiVenditaFarmacie, ImpostazioniOrdineLiberoPuntiVenditaTrasferitoVenduto, InfoInterface, InfoTrasmissioneInterface, NewTestataInterface, PrioritaInterface } from '../../interfaces/ordineLibero';
import { ItemDragging } from 'devextreme-react/list';
import { List, NumberBox, Validator } from 'devextreme-react';
import { ElementOptionChanged } from "../../interfaces/componentOptions";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import ArrayStore from "devextreme/data/array_store";
import notify from "devextreme/ui/notify";

const ImpostazioniOrdineLibero = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean, flagOrdineB2B?:boolean | undefined}) => {

    const [tipoOrdine, setTipoOrdine] = useState<string>("libero");

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }

        if (props.flagOrdineB2B != undefined && props.flagOrdineB2B == true)
        setTipoOrdine("B2B")

    }, []);

    const newImpostazioni = {
        idFar: undefined,
        flagPrezzoBancaDati: undefined,
        flagPrezzoFarmacia: undefined,
        prioritaPrezzoBancaDati: undefined,
        prioritaPrezzoFarmacia: undefined,
        flagCostoInControlloFattura: undefined,
        lstOrdineFarmacia: undefined,
        lstPuntiVendita: undefined,
        flagVisualizzaVendutoGiacenza: undefined,
        flagVisualizzaVendutoGiacenzaTotale: undefined,
        lstPuntiVenditaTrasferitoVenduto: undefined,
        lstPuntiVenditaFarmacie: undefined,
        flagVisualizzaUltimiAcquisti: undefined,
        flagVisualizzaListiniOfferte: undefined,
        flagVisualizzaListiniOrdineLibero: undefined,
        flagVisualizzaAltreInfo: undefined,
        flagVisualizzaTrasferimenti: undefined,
        flagVisualizzaIdentici: undefined,
        flagVisualizzaSimili: undefined,
        flagVisualizzaListiniOfferteIdentici: undefined,
        flagVisualizzaPrezzoFarmacia: undefined,
        flagModificaPrezzoFarmacia: undefined,
        idFarRiferimento: undefined,
    }

    
    const auth = useAuth(urlOrdiniLiberi.auth);
    const [titolo, setTitolo] = useState<string>("");
    const [chiamante, setChiamante] = useState<string>();
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [filtri, setFiltri] = useState<ImpostazioniOrdineLiberoInterface>(newImpostazioni);
    const [idFarmacia, setIdFarmacia] = useState<number>();
    const [checkValuePrezzoBancaDati, setCheckValuePrezzoBancaDati] = useState(false);
    const [checkValuePrezzoFarmacia, setCheckValuePrezzoFarmacia] = useState(false);
    const [checkValueVisualizzaPrezzoFarmacia, setCheckValueVisualizzaPrezzoFarmacia] = useState(false);
    const [checkValueModificaPrezzoFarmacia, setCheckValueModificaPrezzoFarmacia] = useState(false);
    const [checkFlagCostoInControlloFattura, setCheckFlagCostoInControlloFattura] = useState(false);
    const [checkFlagVisualizzaVendutoGiacenza, setCheckFlagVisualizzaVendutoGiacenza] = useState(false);
    const [checkFlagVisualizzaVendutoGiacenzaTotale, setCheckFlagVisualizzaVendutoGiacenzaTotale] = useState(false);
    const [checkFlagVisualizzaUltimiAcquisti, setCheckFlagVisualizzaUltimiAcquisti] = useState(false);
    const [checkFlagVisualizzaListiniOfferte, setCheckFlagVisualizzaListiniOfferte] = useState(false);
    const [checkFlagVisualizzaListiniOrdineLibero, setCheckFlagVisualizzaListiniOrdineLibero] = useState(false);
    const [checkFlagVisualizzaTrasferimenti, setCheckFlagVisualizzaTrasferimenti] = useState(false);
    const [checkFlagVisualizzaAltreInfo, setCheckFlagVisualizzaAltreInfo] = useState(false);
    const [checkFlagVisualizzaIdentici, setCheckFlagVisualizzaIdentici] = useState(false);
    const [checkFlagVisualizzaSimili, setCheckFlagVisualizzaSimili] = useState(false);
    const [checkFlagVisualizzaListiniOfferteIdentici, setCheckFlagVisualizzaListiniOfferteIdentici] = useState(false);
    const [listaFarmacieOrdineNew, setListaFarmacieOrdineNew] = useState<ImpostazioniOrdineLiberoOrdineFarmacia[]>();
    const [listaPuntiVenditaNew, setListaPuntiVenditaNew] = useState<ImpostazioniOrdineLiberoPuntiVendita[]>();
    const [listaPuntiVenditaNewTrasferitoVenduto, setListaPuntiVenditaNewTrasferitoVenduto] = useState<ImpostazioniOrdineLiberoPuntiVenditaTrasferitoVenduto[]>();
    const [listaPuntiVenditaNewFarmacie, setListaPuntiVenditaNewFarmacie] = useState<ImpostazioniOrdineLiberoPuntiVenditaFarmacie[]>();
    const [listaFarmacieOrdineCurrent, setListaFarmacieOrdineCurrent] = useState<ImpostazioniOrdineLiberoOrdineFarmacia[]>();
    const [selectedItemKeys, setSelectedItemKeys] = useState<number[]>();
    const [selectedItemKeysTrasferitoVenduto, setSelectedItemKeysTrasferitoVenduto] = useState<number[]>();
    const [dataSource, setDatasource] = useState<any>();
    const [dataSourceTrasferitoVenduto, setDatasourceTrasferitoVenduto] = useState<any>();
    const [dataSourceFarmacie, setDatasourceFarmacie] = useState<any>();
    const listItemRef = useRef(null);
    const [listaPriorita, setListaPriorita] = useState<PrioritaInterface[]>();
    const [disableAltreInfo, setDisableAltreInfo] = useState<boolean>();
    const [disablePrezzoFarmacia, setDisablePrezzoFarmacia] = useState<boolean>();
    const [disableTrasferimenti, setDisableTrasferimenti] = useState<boolean>();


    useEffect(() => {

        if (auth) {
            if (window.location.href.includes("?")) {
                setIsVisible(true);
                let config = window.location.href.split("?")[1]
                setChiamante(config)
                if (config) {
                    if (config === "0") {
                        if (props.flagOrdineB2B != undefined && props.flagOrdineB2B == true) {
                            setTitolo("Impostazioni ordine B2B")
                        }
                        else {
                            setTitolo("Impostazioni ordine libero")
                        }
                        
                        
                    }
                    else if (config ==="1") {
                        setTitolo("Impostazioni ordine")
                    }
                }
            }

            

            const lista = [
            {
                ID: 1,
                PRIORITA: '1',
            },
            {
                ID: 2,
                PRIORITA: '2',
            },
            ];
            setListaPriorita(lista);

            RestServices().rest('POST', "", urlOrdiniLiberi.getImpostazioni).then(result => {
                if (result === undefined) {

                } else if (result) {
                    setCheckValuePrezzoBancaDati(result.flagPrezzoBancaDati);
                    setCheckValuePrezzoFarmacia(result.flagPrezzoFarmacia);
                    setCheckValueVisualizzaPrezzoFarmacia(result.flagVisualizzaPrezzoFarmacia);
                    setCheckValueModificaPrezzoFarmacia(result.flagModificaPrezzoFarmacia);
                    setCheckFlagCostoInControlloFattura(result.flagCostoInControlloFattura);
                    setCheckFlagVisualizzaVendutoGiacenza(result.flagVisualizzaVendutoGiacenza)
                    setCheckFlagVisualizzaVendutoGiacenzaTotale(result.flagVisualizzaVendutoGiacenzaTotale)
                    setCheckFlagVisualizzaUltimiAcquisti(result.flagVisualizzaUltimiAcquisti)
                    setCheckFlagVisualizzaListiniOfferte(result.flagVisualizzaListiniOfferte)
                    setCheckFlagVisualizzaListiniOrdineLibero(result.flagVisualizzaListiniOrdineLibero)
                    setCheckFlagVisualizzaListiniOfferteIdentici(result.flagVisualizzaListiniOfferteIdentici)
                    setCheckFlagVisualizzaTrasferimenti(result.flagVisualizzaTrasferimenti)
                    setCheckFlagVisualizzaAltreInfo(result.flagVisualizzaAltreInfo)
                    setCheckFlagVisualizzaIdentici(result.flagVisualizzaIdentici)
                    setCheckFlagVisualizzaSimili(result.flagVisualizzaSimili)
                    setIdFarmacia(result.idFarmaciaPrezzo)

                    setFiltri({
                        flagPrezzoBancaDati: result.flagPrezzoBancaDati, flagPrezzoFarmacia: result.flagPrezzoFarmacia,
                        idFar: result.idFarmaciaPrezzo, prioritaPrezzoBancaDati: result.prioritaPrezzoBancaDati,
                        prioritaPrezzoFarmacia: result.prioritaPrezzoFarmacia, flagCostoInControlloFattura: result.flagCostoInControlloFattura,
                        lstOrdineFarmacia: result.lstOrdineFarmacia, flagVisualizzaVendutoGiacenza: result.flagVisualizzaVendutoGiacenza,
                        lstPuntiVendita: result.lstPuntiVendita, lstPuntiVenditaTrasferitoVenduto: result.lstPuntiVenditaTrasferitoVenduto,
                        lstPuntiVenditaFarmacie: result.lstPuntiVenditaFarmacie, flagVisualizzaVendutoGiacenzaTotale: result.flagVisualizzaVendutoGiacenzaTotale,
                        flagVisualizzaUltimiAcquisti: result.flagVisualizzaUltimiAcquisti, flagVisualizzaListiniOfferte: result.flagVisualizzaListiniOfferte,
                        flagVisualizzaAltreInfo: result.flagVisualizzaAltreInfo, flagVisualizzaTrasferimenti: result.flagVisualizzaTrasferimenti,
                        flagVisualizzaIdentici: result.flagVisualizzaIdentici, flagVisualizzaSimili: result.flagVisualizzaSimili,
                        flagVisualizzaListiniOfferteIdentici: result.flagVisualizzaListiniOfferteIdentici,
                        flagVisualizzaPrezzoFarmacia: result.flagVisualizzaPrezzoFarmacia,
                        flagModificaPrezzoFarmacia: result.flagModificaPrezzoFarmacia,
                        flagVisualizzaListiniOrdineLibero: result.flagVisualizzaListiniOrdineLibero,
                    });
                    setListaFarmacieOrdineNew(result.lstOrdineFarmacia);
                    setListaPuntiVenditaNew(result.lstPuntiVendita);
                    setListaPuntiVenditaNewTrasferitoVenduto(result.lstPuntiVenditaTrasferitoVenduto);
                    setListaPuntiVenditaNewFarmacie(result.lstPuntiVenditaFarmacie);

                    let selected: number[] = Array();
                    result.lstPuntiVendita?.forEach((punto: any) => {
                        if (punto.flagMagazzino == true) {
                            selected.push(punto.idFar);
                        }
                    })

                    setSelectedItemKeys(selected);

                    let selectedTrasferitoVenduto: number[] = Array();
                    result.lstPuntiVenditaTrasferitoVenduto?.forEach((punto: any) => {
                        if (punto.flagTrasferitoVenduto == true) {
                            selectedTrasferitoVenduto.push(punto.idFar);
                        }
                    })

                    setSelectedItemKeysTrasferitoVenduto(selectedTrasferitoVenduto);
                    setIsVisible(false);
                }

            })
        }

    }, [auth]);


    

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    const ListItem = (data: any) => {
        return (
            <div>
                <b>{data.descFar}</b>
                <br />
            </div>
        );
    };

    const onItemReordered = (e: any) => {

        //console.log(e);

        let fromIndex = e.fromIndex;
        let toIndex = e.toIndex;
        let idFar = e.itemData.idFar;
        let fromPosition = e.itemData.posizione;
        var item: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;
        var item2: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;

        var itemToUpdate: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;
        var itemFromUpdate: ImpostazioniOrdineLiberoOrdineFarmacia | undefined;

        var toPosition;
        var toIndice;


        var listAppo: ImpostazioniOrdineLiberoOrdineFarmacia[];
        var farAppo: ImpostazioniOrdineLiberoOrdineFarmacia;

        if (listaFarmacieOrdineNew != undefined) {

            if (fromIndex > toIndex) {
                listAppo = [];
                listaFarmacieOrdineNew.forEach((farmacia) => {
                    if (farmacia) {
                        //console.log(farmacia)
                        if (farmacia.indice < toIndex) {
                            listAppo.push(farmacia);
                        }
                        else if (farmacia.indice >= toIndex && farmacia.indice < fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = farmacia.posizione + 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = farmacia.indice + 1;

                            listAppo.push(farAppo);
                        }

                        else if (farmacia.indice == fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = (toIndex + 1) * 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = toIndex;

                            listAppo.push(farAppo);
                        }
                        else
                            listAppo.push(farmacia);
                    }
                })

                //console.log(listaFarmacieOrdineNew)
                //console.log(listAppo)


                setListaFarmacieOrdineNew(listAppo);
            }


            if (fromIndex < toIndex) {
                listAppo = [];
                listaFarmacieOrdineNew.forEach((farmacia) => {
                    if (farmacia) {
                        //console.log(farmacia)
                        if (farmacia.indice < fromIndex) {
                            listAppo.push(farmacia);
                        }
                        else if (farmacia.indice == fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = (toIndex + 1) * 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = toIndex;

                            listAppo.push(farAppo);
                        }
                        else if (farmacia.indice > fromIndex && farmacia.indice <= toIndex && farmacia.indice != fromIndex) {
                            farAppo = {} as ImpostazioniOrdineLiberoOrdineFarmacia;
                            farAppo.posizione = farmacia.posizione - 10;
                            farAppo.idFar = farmacia.idFar;
                            farAppo.descFar = farmacia.descFar;
                            farAppo.indice = farmacia.indice - 1;

                            listAppo.push(farAppo);
                        }

                        else
                            listAppo.push(farmacia);
                    }
                })

                //console.log(listaFarmacieOrdineNew)
                //console.log(listAppo)


                setListaFarmacieOrdineNew(listAppo);
            }


        }


    }

    const onSelectedItemKeysChange = (args: any) => {

        if (args.name === 'selectedItemKeys') {
            setSelectedItemKeys(args.value);
            listaPuntiVenditaNew?.forEach((punto) => {
                if (args.value.includes(punto.idFar)) {
                    punto.flagMagazzino = true;
                }
                else
                    punto.flagMagazzino = false;
            })
            //this.setState({
            //    selectedItemKeys: args.value,
            //});

        }
    }

    const onSelectedItemKeysChangeTraasferitoVenduto = (args: any) => {
        if (args.name === 'selectedItemKeys') {
            setSelectedItemKeysTrasferitoVenduto(args.value);
            listaPuntiVenditaNewTrasferitoVenduto?.forEach((punto) => {
                if (args.value.includes(punto.idFar)) {
                    punto.flagTrasferitoVenduto = true;
                }
                else
                    punto.flagTrasferitoVenduto = false;
            })
            //this.setState({
            //    selectedItemKeys: args.value,
            //});

        }
    }

    useEffect(() => {
        //const tasks = [{ idFar: 1, text: 'Prepare 2016 Financial'},
        //    { idFar: 2, text: 'Prepare 2016 Marketing Plan'},
        //    { idFar: 3, text: 'Update Personnel Files'},
        //    { idFar: 4, text: 'Review Health Insurance Options Under the Affordable Care Act'}];

        setDatasource(new ArrayStore({
            key: 'idFar',
            data: filtri.lstPuntiVendita,
        }))
    }, [filtri.lstPuntiVendita])

    useEffect(() => {
        //const tasks = [{ idFar: 1, text: 'Prepare 2016 Financial'},
        //    { idFar: 2, text: 'Prepare 2016 Marketing Plan'},
        //    { idFar: 3, text: 'Update Personnel Files'},
        //    { idFar: 4, text: 'Review Health Insurance Options Under the Affordable Care Act'}];

        setDatasourceTrasferitoVenduto(new ArrayStore({
            key: 'idFar',
            data: filtri.lstPuntiVenditaTrasferitoVenduto,
        }))
    }, [filtri.lstPuntiVenditaTrasferitoVenduto])

    useEffect(() => {
        //const tasks = [{ idFar: 1, text: 'Prepare 2016 Financial'},
        //    { idFar: 2, text: 'Prepare 2016 Marketing Plan'},
        //    { idFar: 3, text: 'Update Personnel Files'},
        //    { idFar: 4, text: 'Review Health Insurance Options Under the Affordable Care Act'}];

        setDatasourceFarmacie(new ArrayStore({
            key: 'idFar',
            data: filtri.lstPuntiVenditaFarmacie,
        }))
    }, [filtri.lstPuntiVenditaFarmacie])

    const annullaImpostazioniOrdineLibero = () => {
        window.open('about:blank', '_self');
        window.close();
    }

    const salvaImpostazioniOrdineLibero = () => {

        const param = {
            idFarmaciaPrezzo: filtri.idFar,
            flagPrezzoFarmacia: filtri.flagPrezzoFarmacia,
            flagPrezzoBancaDati: filtri.flagPrezzoBancaDati,
            prioritaPrezzoBancaDati: filtri.prioritaPrezzoBancaDati,
            prioritaPrezzoFarmacia: filtri.prioritaPrezzoFarmacia,
            flagCostoInControlloFattura: filtri.flagCostoInControlloFattura,
            flagVisualizzaVendutoGiacenza: filtri.flagVisualizzaVendutoGiacenza,
            flagVisualizzaVendutoGiacenzaTotale: filtri.flagVisualizzaVendutoGiacenzaTotale,
            flagVisualizzaUltimiAcquisti: filtri.flagVisualizzaUltimiAcquisti,
            flagVisualizzaTrasferimenti: filtri.flagVisualizzaTrasferimenti,
            flagVisualizzaListiniOfferte: filtri.flagVisualizzaListiniOfferte,
            flagVisualizzaListiniOrdineLibero: filtri.flagVisualizzaListiniOrdineLibero,
            flagVisualizzaAltreInfo: filtri.flagVisualizzaAltreInfo,
            flagVisualizzaIdentici: filtri.flagVisualizzaIdentici,
            flagVisualizzaSimili: filtri.flagVisualizzaSimili,
            flagVisualizzaListiniOfferteIdentici: filtri.flagVisualizzaListiniOfferteIdentici,
            lstOrdineFarmacia: listaFarmacieOrdineNew,
            lstPuntiVendita: listaPuntiVenditaNew,
            lstPuntiVenditaTrasferitoVenduto: listaPuntiVenditaNewTrasferitoVenduto,
            flagVisualizzaPrezzoFarmacia: filtri.flagVisualizzaPrezzoFarmacia,
            flagModificaPrezzoFarmacia: filtri.flagModificaPrezzoFarmacia
        };
        if (filtri.flagPrezzoFarmacia && filtri.idFar == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare una farmacia" }, "error", 4000);
        } else if (filtri.flagPrezzoFarmacia && filtri.prioritaPrezzoFarmacia == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare la priorita del prezzo farmacia" }, "error", 4000);
        } else if (filtri.flagPrezzoBancaDati && filtri.prioritaPrezzoBancaDati == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare la priorita' del prezzo banca dati" }, "error", 4000);
        } else if (filtri.prioritaPrezzoBancaDati != undefined && filtri.prioritaPrezzoBancaDati == filtri.prioritaPrezzoFarmacia) {
            notify({ position: "center", width: "auto", message: "Selezionare priorita' differenti" }, "error", 4000);
        }
        else {
            RestServices().rest('POST', param, urlOrdiniLiberi.setImpostazioni).then(result => {
                if (result) {
                    notify({ position: "center", width: "auto", message: "Salvataggio eseguito con successo" }, "success", 2000)
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'� stato un errore!" }, "error", 4000)
                }
            })
        }
    }

    useEffect(() => {
        if (Boolean(filtri.flagVisualizzaAltreInfo) === true) {
            setDisableAltreInfo(false)
            setDisableTrasferimenti(false)
        }

        else {
            setDisableAltreInfo(true)
            setDisableTrasferimenti(true)
        }
            

    }, [filtri.flagVisualizzaAltreInfo])

    useEffect(() => {
        if (Boolean(filtri.flagVisualizzaPrezzoFarmacia) === true) {
            setDisablePrezzoFarmacia(true)
        }

        else {
            setDisablePrezzoFarmacia(false)
        }


    }, [filtri.flagVisualizzaPrezzoFarmacia])

    useEffect(() => {
        if (Boolean(filtri.flagVisualizzaUltimiAcquisti) === true && Boolean(filtri.flagVisualizzaAltreInfo) === true)
            setDisableTrasferimenti(false)
        else
            setDisableTrasferimenti(true)

    }, [filtri.flagVisualizzaUltimiAcquisti])

    return (
        <>
            &nbsp;
            {TitoloContenuto(titolo)}
            {loadPanel}
            &nbsp;
            <Row>
                <Col sm="8">
                    <TabPanel
                        selectedIndex={selectedIndex}
                        loop={false}
                        animationEnabled={true}
                        onOptionChanged={onOptionChanged}


                    >
                        <Item title="Priorita' prezzo" icon="money">
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Utilizza il prezzo di Banca Dati: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagPrezzoBancaDati" impostaFiltri={impostaFiltri} checkValue={checkValuePrezzoBancaDati} />
                                </Col>
                                <Col sm="1" className="labelCreaNuovoOrdLib">
                                    <span>Priorita': </span>
                                </Col>
                                <Col sm="1">
                                    <Selezione
                                        dataSource={listaPriorita}
                                        id="prioritaPrezzoBancaDati"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="PRIORITA"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                        value={filtri.prioritaPrezzoBancaDati}
                                        disabled={!filtri.flagPrezzoBancaDati}
                                    />
                                </Col>
                                <Col sm="6">
                                </Col>
                            </Row>

                            <Row>&nbsp;</Row>

                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span>Utilizza il prezzo di Farmacia: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagPrezzoFarmacia" impostaFiltri={impostaFiltri} checkValue={checkValuePrezzoFarmacia} />
                                </Col>
                                <Col sm="1" className="labelCreaNuovoOrdLib">
                                    <span>Priorita': </span>
                                </Col>
                                <Col sm="1">
                                    <Selezione
                                        dataSource={listaPriorita}
                                        id="prioritaPrezzoFarmacia"
                                        impostaFiltri={impostaFiltri}
                                        displayExpr="PRIORITA"
                                        valueExpr="ID"
                                        searchEnabled={true}
                                        value={filtri.prioritaPrezzoFarmacia}
                                        disabled={!filtri.flagPrezzoFarmacia}
                                    />
                                </Col>
                                <Col sm="2" className="labelCreaNuovoOrdLib">
                                    <span>Farmacia: </span>
                                </Col>
                                <Col sm="4">
                                    <SelectFarm datiFarmacie={props.datiFarmacie} id="idFar" impostaFiltri={impostaFiltri} displayExpr={true} value={filtri.idFar} disabled={!filtri.flagPrezzoFarmacia} />
                                </Col>
                            </Row>

                             
                            <Row>&nbsp;</Row>

                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span title="Visualizza il prezzo di vendita della farmacia di riferimento">Visualizza il prezzo di Farmacia: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagVisualizzaPrezzoFarmacia" impostaFiltri={impostaFiltri} checkValue={checkValueVisualizzaPrezzoFarmacia} />
                                </Col>
                            </Row>
                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="3" className="labelCreaNuovoOrdLib">
                                    <span title="Modifica il prezzo di vendita suggerito e permette l'invio in farmacia quando si trasmette l'ordine">Modifica il prezzo di Farmacia : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={!disablePrezzoFarmacia} id="flagModificaPrezzoFarmacia" impostaFiltri={impostaFiltri} checkValue={checkValueModificaPrezzoFarmacia} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            

                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Controllo fattura" icon="bulletlist" visible={chiamante == "0" ? true : false}>
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza ed utilizza il costo in controllo fattura: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagCostoInControlloFattura" impostaFiltri={impostaFiltri} checkValue={checkFlagCostoInControlloFattura} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="4"><span className="trasfInfo">In controllo bolla fattura, nella tabella dei prodotti presenti nelle DDT caricate in farmacia,e' visualizzato ed utilizzato il costo del relativo ordine {tipoOrdine} se esistente </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Ordine farmacie" icon="orderedlist" visible={chiamante == "0" ? true : false}>
                            <Row>&nbsp;</Row>
                            <Row><Col sm="12"><span className="trasfInfo">Definire in che ordine le farmacie sono visualizzate nella pagina di gestione del singolo ordine {tipoOrdine}. &nbsp;Spostare le farmacie secondo l'ordine desiderato. </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <List dataSource={filtri.lstOrdineFarmacia} itemRender={ListItem} onItemReordered={onItemReordered} ref={listItemRef}>
                                <ItemDragging
                                    allowReordering={true}
                                />
                            </List>

                            {/*
                            <ListElementi
                                id="listFarmacie"
                                dataSource={filtri.lstOrdineFarmacia}
                                impostaFiltri={impostaFiltri} >

                            </ListElementi>*/}
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Venduto e giacenze" icon="orderedlist" visible={chiamante == "0" ? true : false}>
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza venduto e giacenza di tutte le farmacie del gruppo: </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagVisualizzaVendutoGiacenza" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaVendutoGiacenza} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfo">Nella pagina del singolo ordine {tipoOrdine} viene mostrato il venduto e la giacenza del prodotto selezionato per tutte le farmacie del gruppo, altrimenti solo per le farmacie dell'ordine </span></Col></Row>
                            
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>
                        <Item title="Punti vendita" icon="orderedlist" visible={chiamante == "0" ? true : false}>
                            <Row>&nbsp;</Row>
                            <Row><Col sm="12"><span className="trasfInfo">Indicare le farmacie "Magazzino" per escluderle dal calcolo del venduto totale nella pagina di modifica dell'ordine libero; indicare le farmacie "Trasferito e venduto" per visualizzare sia il venduto che il trasferito nella pagina di modifica dell'ordine libero</span></Col></Row>                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>
                                <Col sm="3"><span className="trasfInfo">Farmacie</span>
                                    <List
                                        dataSource={dataSourceFarmacie}
                                    >
                                    </List>

                                </Col>
                                <Col sm="1"><span title="Selezionare i punti vendita che sono anche magazzino" className="trasfInfo">Magazzino</span>
                                    <List
                                        id="listItemMagazzino"
                                        dataSource={dataSource}
                                        selectionMode={'multiple'}
                                        showSelectionControls={true}
                                        selectedItemKeys={selectedItemKeys}
                                        onOptionChanged={onSelectedItemKeysChange}>
                                    </List>

                                </Col>
                                <Col sm="2"><span title="Selezionare i punti vendita di cui visualizzare trasferito e venduto" className="trasfInfo">Trasferito e venduto</span>
                                    <List
                                        id="listItemTrasferitoVenduto"
                                        dataSource={dataSourceTrasferitoVenduto}
                                        selectionMode={'multiple'}
                                        showSelectionControls={true}
                                        selectedItemKeys={selectedItemKeysTrasferitoVenduto}
                                        onOptionChanged={onSelectedItemKeysChangeTraasferitoVenduto}

                                    >
                                    </List>

                                </Col>
                            </Row>


                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>

                        <Item title="Altre info" icon="orderedlist" visible={chiamante == "0" ? true : false}>
                            <Row>&nbsp;</Row>
                            <Row className="rigaCreaNuovoOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza pannello altre info : </span>
                                </Col>
                                <Col sm="1">
                                    <Check id="flagVisualizzaAltreInfo" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaAltreInfo} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfo">Nella pagina del singolo ordine {tipoOrdine} viene mostrato un pannello laterale con altre informazioni riguardanti il prodotto </span></Col></Row>
                            <Row>&nbsp;</Row>

                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza venduto e giacenza totale : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo} id="flagVisualizzaVendutoGiacenzaTotale" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaVendutoGiacenzaTotale} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSub">Nella pagina del singolo ordine {tipoOrdine} viene mostrato il venduto e la giacenza totale del prodotto selezionato </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza ultimi acquisti : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo} id="flagVisualizzaUltimiAcquisti" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaUltimiAcquisti} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati gli ultimi acquisti del prodotto selezionato </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Includi trasferimenti : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableTrasferimenti} id="flagVisualizzaTrasferimenti" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaTrasferimenti} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSubSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati tra gli ultimi acquisti anche i trasferimenti tra le altre sedi del prodotto selezionato </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza listini e offerte : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo}  id="flagVisualizzaListiniOfferte" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaListiniOfferte} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati i listini e le offerte dei fornitori del prodotto selezionato </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza listini ordine {tipoOrdine}: </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo} id="flagVisualizzaListiniOrdineLibero" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaListiniOrdineLibero} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati i listini del fornitore caricati dall'ordine {tipoOrdine} (sezione listini) </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza prodotti identici : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo} id="flagVisualizzaIdentici" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaIdentici} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati i prodotti identici (importazione parallela) </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza listini e offerte dei prodotti identici : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo} id="flagVisualizzaListiniOfferteIdentici" impostaFiltri={impostaFiltri}
                                        checkValue={checkFlagVisualizzaListiniOfferteIdentici} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSubSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati i listini e le offerte dei fornitori dei prodotti identici </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row className="rigaImpostazioneSubOrdLib">
                                <Col sm="4" className="labelCreaNuovoOrdLib">
                                    <span>Visualizza prodotti simili : </span>
                                </Col>
                                <Col sm="1">
                                    <Check disabled={disableAltreInfo} id="flagVisualizzaSimili" impostaFiltri={impostaFiltri} checkValue={checkFlagVisualizzaSimili} />
                                </Col>
                                <Col sm="7">
                                </Col>
                            </Row>
                            <Row><Col sm="12"><span className="trasfInfoSub">Nella pagina del singolo ordine {tipoOrdine} vengono mostrati i prodotti similii (stessa sostanza) </span></Col></Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Item>

                    </TabPanel>
                </Col>
                
            </Row>
            <Row>
                <Col sm="7">
                    <Button type="button" variant="danger" id="btnAnnullaImpostazioniOrdineLibero" onClick={annullaImpostazioniOrdineLibero}>Chiudi   </Button>
                </Col>
                <Col sm="1">
                    <Button type="button" variant="info" id="btnSalvaImpostazioniOrdineLibero" onClick={salvaImpostazioniOrdineLibero}>Salva   </Button>
                </Col>
            </Row>
            
        </>
    )
}

export default ImpostazioniOrdineLibero;