import { TestataContenitoreInterface } from "../../interfaces/contenitore";
import notify from 'devextreme/ui/notify';
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";

const TestataContenitore = (props: {
    testata: TestataContenitoreInterface | undefined,
}) => {

    return (
        <>
            <Col sm='5'>
                <span>Nome:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.nome}</span>
            </Col>
            <Col sm='5'>
                <span>Descrizione:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.descrizione}</span>
            </Col>
            <Col sm='2'>
                <span>Data:&nbsp;&nbsp;</span><span className="valoreTestata">{props.testata?.dataContenitore && cambiaFormatoLocalDateTimeToEuropeo(props.testata.dataContenitore.toString())}</span>
            </Col>
        </>
    )
}

export default TestataContenitore;
