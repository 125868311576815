import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import { legendByarray } from '../../../funzioni/formatoVariabili'

//import "./style.css";

interface DialogProps {
  open: boolean,
  edit: any,
  typeEdit: boolean,
  ricarica: () => void,
  close: ()=>void,
}


export const DialogNote: React.FC<DialogProps> = ({ open, close,edit,ricarica,typeEdit}) => {

  const [openD, setOpenD] = useState <boolean>(false)
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})

 

 const UpdateNote = () =>{
    if (auth) {
       //console.log(objctTOsave);
       RestServices().rest('POST', {
        nota:objctTOsave.note, 
        idScadenzario:objctTOsave.idScadenzario,
        flagCicloAttivo: true } , urlConsultazione.updateScadenzarioNota)
           .then(result => {
               if (result?.esito) { 
                ricarica()  
                                       
               }else{                              
                   //console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}




 const cambiaValore = (tipo:string,value:string) =>{
          let objTo = {...objctTOsave}
          objTo[tipo] = value
          setObjctTOsave(objTo)
    }


  useEffect(() => {
    if (edit) {
      //console.log(edit);   
      let objTo = { ...objctTOsave };
      objTo.note = edit.note;
      objTo.idScadenzario = edit.idScadenzario
      objTo.flagCicloAttivo =  edit.flagCicloAttivo
      setObjctTOsave(objTo);
    }
  }, [edit, openD]);

  useEffect(() => { 
          setOpenD(open);
        if(open && auth){
          
        }
    
  }, [open,auth]);






  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'40%'}
      height={'50%'}
      showTitle={true}
      title=""
    >
     <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Annulla',
                onClick: ()=>close(),
              }}       
          />
           <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Salva',
                elementAttr:{
                  class:'btncreatecluster',
                },
                onClick: ()=>UpdateNote(),
              }
            }
            />

      <ScrollView height="100%"  width="100%">
      

        <div style={{ width: '100%'}}>
        <div  style={{textAlign:'center'}}>
                <h3> MODIFICA NOTE <span className='subt' ></span> </h3>
             </div>
              
             <div style={{marginTop:'50px'}}>              
             <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.note}  onValueChanged={(v)=>cambiaValore('note',v.value) }  />               
                </div>  
                  
        </div>
        
            
      </ScrollView>
    </Popup>
  );
};