import React, { useEffect } from 'react'



import './style.css';


const VenditeBusiness = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
 
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);


   
    return (
        <>
    
             <div style={{height:'80vh'}}>
                 <embed id="ifm"  width="100%" height="100%" src="https://sss.cloud-farma.it/app/main#/dashboards/5d91b7f73f8efb1f281ff9b3?embed=true" ></embed>
            </div>      
           
        </>
    )
}

export default VenditeBusiness