import React, { useEffect, useRef, useState} from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from '../../dashboards/filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { testApi } from "../util/testRisp";
import { MenuFbiVendite } from "../components/menu_Fbi_Vendite";
import { createItemFilterStatoFarm } from "./itemsFiltri/AnaliVendite";
import { boxCardStaFarmOne, boxsecondStatFarm, box3StatoFarm, boxCassa, boxGlavorati } from "./BoxCard/AnalVendite"
import { urlConsultazione, urlReport } from "../../costanti";
import { CruscottoGruppo_traform_analisi } from "../util/trasformation";
import { LoadIndicator } from "devextreme-react/load-indicator";
import {
  Connector,
  Title,
  Export,
  Font,
  Series,
  Format,
  Label,
  HoverStyle,
  Size,
  SmallValuesGrouping,
  Tooltip,
} from "devextreme-react/pie-chart";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";

import "./style.css";

const dashboard18 = { dashboardID: 18 };

export interface boxCard {
  testoPop?: string;
}

export interface boxsecond {
  testoPop?: string;
}


const FbiStatofarmacia = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [isPanelOpen, setPanelOpen] = useState(false);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardStaFarmOne);
  const [fila2cards, setFila2cards] = useState<cardC[]>(boxsecondStatFarm);
  const [fila3cards, setFila3cards] = useState<cardC[]>(box3StatoFarm);
  const [pie, setPie] = useState<any[]>([]);
  const [secondPie, setSecondpie] = useState<any[]>([]);
  const [categoria, setCategoria] = useState<any[]>([]);
  const [segmento, setSegmento] = useState<any[]>([]);
  const [blocco1, setBlocco1] = useState<any[]>([]);
  const [blocco2, setBlocco2] = useState<any[]>([]);
  const [tipologiaProdotto, setTipologiaProdotto] = useState<any[]>([]);
  const [tipologiaConFascia, setTipologiaConFascia] = useState<any[]>([]);
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [visiblePop1, setVisiblePop1] = useState(false);
  const [visiblePop2, setVisiblePop2] = useState(false);
  const [visiblePop3, setVisiblePop3] = useState(false);


  const togglePanel = () => {
    setPanelOpen(!isPanelOpen);
  };

  function aggiornaOrarioFine(dataFine: any) {
    // Verifica se l'orario della data finale è mezzanotte (00:00)
    const dataFineAggiornata = new Date(dataFine);
    dataFineAggiornata.setHours(23, 59, 59);
    return dataFineAggiornata;
  }

  function aggiornaDataFine(dataFine: Date): Date {
    // Clona la data di partenza per non modificarla direttamente
    const nuovaDataFine = new Date(dataFine);

    // Aggiungi un giorno alla data
    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];
    let newIDSettore: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }


    
    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      ditta: params.ditta,
      categoria: params.categoria,
      segmento: params.segmento,
      blocco1: params.blocco1,
      blocco2: params.blocco2,
      progetto: params.progetto,
      societa: params.societa,
      tipologiaProdotto: params.tipologiaProdotto,
      tipologiaConFascia: params.tipologiaConFascia,
      operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {

      const dashboardCall18 = {
        ...dashboard18,

        idFarList: params.idFar,
        params: parameters,
      };

      // call Global

      RestServices()
        .rest("POST", dashboardCall18, urlReport.getQueryDashboardAllData)
        .then((result) => {

          if (result) {

      
            // let fixedCassaCard = [
            //   {
            //     testo: "SCONTO CASSA",
            //     valore: '€' + (result["35"]["0"]).SCONTO_CASSA.toLocaleString("it-IT", {
            //       minimumFractionDigits: 2,
            //       maximumFractionDigits: 2,
            //     }),
            //     starter: true,
            //     testoPop:
            //       "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
            //   },
            // ]

            let datiPrimaParteCards = trasform_for_cards_charts_01(
              result["80"],
              boxCardStaFarmOne
            );

            let datiSecondaParteCards = trasform_for_cards_charts_01(
              result["80"],
              boxsecondStatFarm
            );

            let dati3Cards = trasform_for_cards_charts_01(
              result["80"],
              box3StatoFarm
            );


            setFila1cards(datiPrimaParteCards);
            setFila2cards(datiSecondaParteCards);
            setFila3cards(dati3Cards);

             // 1 chartpie
             setPie(result["80"]);

             // 2 chartpie

             const chartSSNPie = result["81"]
             .map((item: any) => {
               const isSSN = item.VEN_FLAG_SSN;
               const value = item.VALORE_VENDUTO ;
               return {
                 SSN: isSSN,
                 VALORE_VENDUTO : value,
                 SSNDESC: isSSN ? "SSN" : "Libera",
               };
             })
             .reverse();

            // 1 chartPie ID 37

            const total = chartSSNPie.reduce(
             (sum: any, item: { VALORE_VENDUTO: any }) =>
               sum + item.VALORE_VENDUTO,
             0
           );

           // Aggiunta della percentuale per ogni valore
           const chartSSNPieConPercentuale = chartSSNPie.map(
             (item: { VALORE_VENDUTO: number }) => {
               // Calcolo della percentuale per ogni valore
               const percentuale = (
                 (item.VALORE_VENDUTO / total) *
                 100
               ).toFixed(2);

               return {
                 ...item,
                 PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
               };
             }
           );

           setSecondpie(chartSSNPieConPercentuale);



            setIsVisible(false);
            setParamsFilter(params);
            setIsFiltersExpanded(false);


          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }

        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            { position: "center", width: "auto", message: "Errore" + error },
            "error",
            4000
          );
        });

      //cambio data
      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };



  const popVisible1 = (testo: string) => {
    setVisiblePop1(true);
  };
  const popVisible2 = (testo: string) => {
    setVisiblePop2(true);
  };
  const popVisible3 = (testo: string) => {
    setVisiblePop3(true);
  };

  let messOne = ["Analisi CìVed"];
  let messTwo = ["Analisi CìVed 222"];
  let messThree = ["Analisi CìVed 3333"];

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);


  const itemsFields = createItemFilterStatoFarm(
    newFarmaList,
    tipologiaConFascia,
    tipologiaProdotto,
    categoria,
    segmento,
    blocco1,
    blocco2
  );

  const filters = () => {
    if (auth) {

      RestServices()
        .rest("POST", "", urlReport.getTipologiaConFascia)
        .then((result) => {
          if(result){
            const fascia = result.map((oggetto: { descrizione: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.descrizione // Aggiungi la nuova proprietà id
              };
          });
        
          
          setTipologiaConFascia(fascia)
        }
        }); 


      RestServices()
        .rest("POST", "", urlReport.getTipologiaProdotto)
        .then((result) => {
          if(result){
            const prodotti = result.map((oggetto: { descrizione: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.descrizione // Aggiungi la nuova proprietà id
              };
          });
         
          setTipologiaProdotto(prodotti)
        }
        });

        RestServices()
        .rest("POST", "", urlReport.getCategoria)
        .then((result) => {

          if(result){
            const categorie = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setCategoria(categorie)

        }
        });

        RestServices()
        .rest("POST", "", urlReport.getSegmento)
        .then((result) => {
          if(result){
            const segmentos = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setSegmento(segmentos)
        }
        });

        RestServices()
        .rest("POST", "", urlReport.getBlocco1)
        .then((result) => {
          if(result){
            const blocco1 = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setBlocco1(blocco1)
        }
        });

        RestServices()
        .rest("POST", "", urlReport.getBlocco2)
        .then((result) => {
          if(result){
            const blocco2 = result.map((oggetto: { codice: any; }) => {
              return {
                  ...oggetto, // Copia tutte le proprietà esistenti
                  id: oggetto.codice // Aggiungi la nuova proprietà id
              };
          });
          setBlocco2(blocco2)
        }
        });


    }
    

   
  };

    useEffect(() => {
        filters();
    }, [authReport]);



  const exportFormats = ["csv"];

  const onExporting = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("tabella_operatori");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-csv
      // https://github.com/exceljs/exceljs#reading-csv
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "tabella_operatori.csv"
        );
      });
    });
  };


  function formatLabel(arg: { argumentText: any; valueText: any }) {
    return `${arg.argumentText}: ${arg.valueText}%`;
  }

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);



  const popVisible = (testo: string) => {
    setVisiblePop(true);
    setTestoPop(testo);
  };



  return (
    <section style={{ display: "flex", height: "98vh" }}>
      {loadPanel}
      {loadinCambio ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadIndicator id="small-indicator" height={50} width={50} />
        </div>
      ) : (
        <section
          id="scrollerelement"
          style={{ width: "100%", overflow: "scroll" }}
        >
          <div
            style={{
              fontSize: "15px",
              alignItems: "left",
              marginTop: "10px",
              marginBottom: "10px",
              color: "rgb(80, 84, 92)",
            }}
          >
            <h2>F.B.I.</h2>
          </div>

          <div
            style={{
              display: "block",
              gap: 5,
              padding: 10,
              borderRadius: 10,
              marginTop: 10,
              width: "auto",
            }}
          >

            <div>
              <MenuFbiVendite />
            </div>

          </div>

          <Popup
            visible={visiblePop}
            showCloseButton={true}
            //onHiding={() => doSomething()}
            width={"40%"}
            height={"60%"}
            showTitle={true}
            title="Dettagli widget"
          >
            <div>
              <p>{testoPop}</p>
            </div>
          </Popup>

          <div>
            <div className="grid-container-3-stato">
              {fila1cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            <div style={{ marginTop: "5px" }} >
              <div className="grid-2-stato">
                {fila2cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>
            </div>
            {/* <div className="grid-ibrid">
              <div className="grid-container-sec">
                {fila3cards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>


              <div className="grid-container-one">
                {filaCassacards.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}


                {filaGiorniLav.map((el, i) => {
                  return (
                    <CardCart
                      testo={el.testo}
                      subTesto={el.subTesto}
                      key={i}
                      valore={el.valore}
                      subValore={el.subValore}
                      starter={el.starter}
                      typeShowValue={el.typeShowValue}
                      typeShowSubValue={el.typeShowSubValue}
                      testoPop={el.testoPop}
                    />
                  );
                })}
              </div>
            </div> */}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
              marginTop: "5px",
              paddingBottom: 10
              
            }}
                      >
        {/*
           <div style={{ width: "45%", marginTop: "5px" }}>
              /<PieChart
                id="pie"
                dataSource={pie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
              >
                <Series
                  argumentField="VEN_IVA"
                  valueField="VALORE_VENDUTO"
                >
                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>

                {<Tooltip
                  enabled={true}
                  customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {
                    console.log('vediamo value');
                    console.log(percentText);


                    const dataItem = pie.find((item: any) => item.VEN_IVA === argument);
                    const dataSourceValue = dataItem ? dataItem.VALORE_VENDUTO : 0;
                    const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dataSourceValue);

                    return {
                      html: `<p>Valore del venduto - ${argument}</p><p>${formattedValue} / ${(percentText)}</p>`,
                    };
                  }}
                />}
                <Title
                  text="Valore del venduto per percentuali d'IVA"
                >
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>

                <Export enabled={true} />
              </PieChart>
            </div>

            <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisible2("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
                          </div>
            */}

            <div style={{ width: "45%", marginTop:5 }}>
              <PieChart
                id="pie"
                dataSource={secondPie}
                palette="Green Mist"
                paletteExtensionMode="alternate"
                type="doughnut"

              //title="Valore del venduto netto sconti per settore inventario - Periodo selezionato"
              >
                <Series
                  argumentField="SSNDESC"
                  valueField="PERCENTUALE"
                >

                  <HoverStyle color="rgb(255, 0, 0)" />
                </Series>
                <Tooltip
                  enabled={true}
                  customizeTooltip={({
                    argument,
                    value,
                  }: {
                    argument: any;
                    value: any;
                  }) => {
                    const dataItem = secondPie.find(
                      (item: any) => item.SSNDESC === argument
                    );
                    const dataSourceValue = dataItem
                      ? dataItem.VALORE_VENDUTO
                      : 0;
                    const formattedValue = new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(dataSourceValue);
                    const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                    const percentualeFormattata =
                      percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                    return {
                      text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                    };
                  }}
                />
                <Title
                  text="Valore del venduto per tipologia di vendita"
                >
                  <Font color="#5b6372" size="16" weight="400" />
                </Title>
                <Export enabled={true} />
              </PieChart>
            </div>
            <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
              <button
                className="ibutton-lite-two"
                style={{ border: "none" }}
                onClick={() => popVisible3("")}
              >
                <FontAwesomeIcon
                  icon={faInfo}
                  style={{ fontSize: "11px", color: "black" }}
                />
              </button>
            </div>
          </div>

          <div style={{ marginTop: "5px", paddingBottom: 10 }} >
            <div className="grid-2-stato-due">
              {fila3cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>
          </div>


        </section>
      )}

      <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon
          icon={faCaretUp}
          rotation={270}
          onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
          style={{ fontSize: "30px", color: "black" }}
        />
      </div>
      <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
        <div style={{}}>
          <FiltriVerticale
            ricerca={filterAction}
            items={itemsFields}
            paramsFilter={paramsFilter}
          />
        </div>
      </section>
      <Popup
        visible={visiblePop1}
        showCloseButton={true}
        onHiding={() => setVisiblePop1(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messOne}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop2}
        showCloseButton={true}
        onHiding={() => setVisiblePop2(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messTwo}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop3}
        showCloseButton={true}
        onHiding={() => setVisiblePop3(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messThree}</p>
        </div>
      </Popup>
    </section>

  );
};

export default FbiStatofarmacia;
