import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from 'devextreme/ui/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AnaFarmacia, FarmaciaSelezionataNewsletter } from "../../interfaces/newsletter";
import Button from 'react-bootstrap/esm/Button';
import { DialogNuovaNewsletter } from "./Dialog";

const ElencoClienti = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);
    
    useEffect(() => {
        startCerca();
    }, []);

    const titolo = TitoloContenuto("Elenco clienti");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoFarmacie, setElencoFarmacie] = useState<AnaFarmacia[]>();
    const [isDialogNuovaNewsletterOpen, setIsDialogNuovaNewsletterOpen] = useState(false);
    const [farmacieSelezionate, setFarmacieSelezionate] = useState<FarmaciaSelezionataNewsletter[]>();

    const handleCloseDialogNuovaNewsletter = () => setIsDialogNuovaNewsletterOpen(false);

    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        RestServices().rest('POST', param, urlAnagrafiche.getAnagraficaClientiNewsletter)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setElencoFarmacie(result)
                    } else {
                        setElencoFarmacie(result);
                    }
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const propsTabElencoFarmacie: TabellaCompletaInterface = {
        id: "elenco_farmacie",
        dataSource: elencoFarmacie,
        selection: "multiple",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {

            totalItems: [{
                name: "TotaleFarmacie",
                showInColumn: "Cod.CSF",
                displayFormat: "Farmacie: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoFarmacie"
        }
    }

    const apriNuovaNewsletter = () => {

        var keys = tabellaInstance.instance.getSelectedRowKeys();
        if (keys.length == 0) {
            setIsVisible(false);
            notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia" }, "error", 4000);
        }
        else {

            var item;
            var farmacie = new Array();
            for (var i = 0; i < keys.length; i++) {
                item = { idCli: keys[i].idCliente, codTph: keys[i].codiceTelepharma, email: keys[i].email };
                farmacie.push(item);    
            }
            setFarmacieSelezionate(farmacie);
            setIsDialogNuovaNewsletterOpen(true);
        }

        
    }

    const apriStoricoNewsletter = () => {

        window.open(basenameHostNewTabs + "newsletter/ElencoNewsletter")
    }

    const apriImmaginiNewsletter = () => {
        window.open(basenameHostNewTabs + "newsletter/ElencoFileNewsletter")
    }

    const columnsFarmacie = CreaColonne([
        { caption: "IdCliente", dataField: "idCliente", visible: false },
        { caption: "Cod.CSF", dataField: "codiceCliente", visible: true},
        { caption: "Cod.Tph", dataField: "codiceTelepharma", visible: true},
        { caption: "Rag.Soc.", dataField: "ragioneSociale" },
        { caption: "Titolare", dataField: "titolare" },
        { caption: "P.Iva", dataField: "partitaIva" },
        { caption: "Email", dataField: "email" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Comune", dataField: "comune" },
        { caption: "Cap'", dataField: "cap" },
        { caption: "Concessionario", dataField: "concessionario" },
        { caption: "Tipo Anagrafica", dataField: "tipoAnagrafica" },
        { caption: "Livello platinum", dataField: "livelloPlatinum", visible: true },
        { caption: "Ha platinum", dataField: "platinum", visible: true, dataType: "boolean" },
        { caption: "Ha disdetto platinum", dataField: "disdettaPlatinum", visible: true, dataType: "boolean" },
        { caption: "Data termine Platinum", dataField: "dataDisdettaPlatinum", format: "MM/dd/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Ha cloudfarma", dataField: "cloudfarma", visible: true, dataType: "boolean" },
        { caption: "Ha disdetto newsletter", dataField: "disdettaNewsletter", visible: true, dataType: "boolean" },
        { caption: "Data disdetta newsletter", dataField: "dataDisdettaNewsletter", format: "MM/dd/yyyy HH:mm:ss", dataType: "date" },
    ])

    const columnsFarmacieDettaglio = CreaColonne([
        { caption: "Data", dataField: "dataNewsletter", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "desc" },
        { caption: "Oggetto", dataField: "oggetto" },
        { caption: "Messaggio", dataField: "messaggio" },
        { caption: "Esito invio", dataField: "esitoInvio" },
        { caption: "Esito messaggio", dataField: "esitoMessaggio" },
    ])

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.disdettaNewsletter == true)
                e.component.deselectRows(row); return;
        })
    }



    return (
        <>
            {titolo}
            {loadPanel}
            <div className="rightcontent">
                <Button id="btnImmaginiNewsLetter" variant="primary" onClick={apriImmaginiNewsletter}>File Newsletter</Button>
                <Button id="btnStoricoNewsLetter" variant="info" onClick={apriStoricoNewsletter}>Storico Newsletter</Button>
                <Button id="btnNuovaNewsLetter" variant="success" onClick={apriNuovaNewsletter}>Nuova Newsletter</Button>
            </div>
            <div className="contenutoPagina">
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        {/*<TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            columnsDetail={columnsFarmacieDettaglio}
                            urlDetail={urlAnagrafiche.getAnagraficaClienteNewsletterDettaglio + '?idCli='}
                            keyDetail={"idCliente"}
                            key={"idCli"}
                        />*/}

                        <TabellaCompleta
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            onSelectionChanged={onSelectionChanged}
                        />
                    </Col>
                </Row>

                <DialogNuovaNewsletter
                    isDialogNuovaNewsletter={isDialogNuovaNewsletterOpen}
                    setIsDialogNuovaNewsletter={setIsDialogNuovaNewsletterOpen}
                    handleCloseDialogNuovaNewsletter={handleCloseDialogNuovaNewsletter}
                    farmacieSelezionate={farmacieSelezionate}
                    setIsVisible={setIsVisible}
                />
            </div>
        </>
    )

}

export default ElencoClienti;