
import { urlBancaDati, urlConsultazione } from "../../../costanti";
import { useAuth } from "../../../../hooks/useAuth";
import { RestServices } from "../../../../services/restServices";


export const filtersCreate = (auth:any,setGruppoMercFilters: (arg0: any) => void,setSettori: (arg0: any) => void,setCodiciAtc: (arg0: any) => void,setDitte: (arg0: any) => void,setTipologiaVendita: (arg0: any) => void) => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlBancaDati.getGruppoMerceologico)
        .then((result) => {
          if (result) setGruppoMercFilters(result);
        });

      RestServices()
        .rest("POST", "", urlBancaDati.getSettore)
        .then((result) => {
          if (result) setSettori(result);
        });

      RestServices()
        .rest("POST", "", urlBancaDati.getAtc)
        .then((result) => {
          if (result) setCodiciAtc(result);
        });

      RestServices()
        .rest("POST", "", urlBancaDati.getDitte)
        .then((result) => {
          if (result) {
            const ditteWithCodiceDescrizione = result.map(
              (ditta: { codice: any; descrizione: any }) => {
                return {
                  ...ditta,
                  codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                };
              }
            );
            setDitte(ditteWithCodiceDescrizione);
          }
        });

      const ssn = { id: 1, descrizione: "SSN" };
      const libera = { id: 0, descrizione: "Libera" };

      const vendite = [libera, ssn];
      setTipologiaVendita(vendite);
    }
  };