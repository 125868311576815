import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import DateBox from 'devextreme-react/date-box'
import { SelectBox } from 'devextreme-react/select-box'
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import { trasformaDateTimeInLocale } from '../../../funzioni/formatoVariabili'
import { NumberBox } from "devextreme-react"


//import "./style.css";

interface DialogProps {
  open: boolean,
  edit: any,
  typeEdit: boolean,
  ricarica: () => void,
    close: () => void,
    selected: any[];
}


export const DiaPagamAtt: React.FC<DialogProps> = ({ open, close, edit, ricarica, typeEdit, selected }) => {

  const [openD, setOpenD] = useState <boolean>(false)
  const [loading, setLoading] = useState <boolean>(false)
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})
  const [tipoPaga, setTipoPaga] = useState<any>([])
    const [conti, setConti] = useState<any>([])
    const [importo, setImporto] = useState<number>();

 

 const UpdatePaga = () =>{
    if (auth) {
      //console.log(objctTOsave);
      let dataToUse = new Date()
      if(objctTOsave.dataPagamento){
        dataToUse = objctTOsave.dataPagamento
      }

        let listScadenzario: number[] = [];

        selected.forEach((scad: any) => {

            if (scad.idScadenzario) {

                listScadenzario.push(scad.idScadenzario)
            }
        });

      let objToSave = {
          "idScadenzario": listScadenzario,
        "idBanca": objctTOsave?.conti?.idBanca,
        "idConto": objctTOsave?.conti?.id,
        "ragioneSocialeBanca": objctTOsave?.conti?.conto,
        "dataPagamento": trasformaDateTimeInLocale(dataToUse , true),
        "riferimento": objctTOsave?.riferimento,
        "annotazione": objctTOsave.annotazione,
          "idTipoPagamento": objctTOsave.idTipoPagamento,
        "flagCicloAttivo": true,
          "iban": objctTOsave?.conti?.iban,
        "importo": objctTOsave?.importo,
      }

       //console.log(objctTOsave);
       RestServices().rest('POST', objToSave , urlConsultazione.updateScadenzarioPagamento)
           .then(result => {
               if (result?.esito) { 
                ricarica()  
                                       
               }else{                              
                   //console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}


const getListTipoPaga = () =>{
  if (auth) {
     //console.log(objctTOsave);
     RestServices().rest('POST', { } , urlConsultazione.getListTipoPagame)
         .then(result => {
             if (result?.esito) { 
              setTipoPaga(result.response)
                                     
             }else{                              
                 //console.log(result);
                 notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
             }
         })     
 }
}

    const getConti = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListConti)
                .then(result => {
                    if (result?.esito) {
                        // console.log('lista conti');
                        //console.log(result?.response);


                        if (selected && selected.length == 1) {
                            let objTo = { ...edit };


                            if (edit.dataSaldo) {
                                objTo.dataPagamento = edit.dataSaldo
                            }
                            // console.log('objTo');

                            // console.log(objTo);

                            if (edit.idConto) {
                                result?.response.forEach((conto: any) => {
                                    //console.log(conto);

                                    if (conto.id == edit.idConto) {

                                        objTo.conti = conto
                                    }
                                });
                                setObjctTOsave(objTo);

                            } else {
                                setObjctTOsave(objTo);
                            }
                        }
                        else {
                            let obj = { ...objctTOsave }



                            obj.dataPagamento = new Date()
                            obj.idTipoPagamento = undefined
                            obj.descrizione = undefined
                            obj.annotazione = undefined
                            obj.riferimento = undefined
                            obj.conti = undefined

                            setObjctTOsave(obj)
                        }

                        setConti(result.response)




                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                    }
                })
        }
    }





 const cambiaValore = (tipo:string,value:string) =>{    
          let objTo = {...objctTOsave}
          objTo[tipo] = value
          setObjctTOsave(objTo)
    }


    const showBancheInfo = () => {
        let result = tipoPaga.filter((tP: any) => tP.idTipoPagamento == objctTOsave?.idTipoPagamento);
        return result[0]?.flagBanca

    }


  useEffect(() => {
    if (edit) {   
    }
  }, [edit, openD]);

  useEffect(() => { 
        getConti();
        if (selected && selected.length > 1) {
            let obj = { ...objctTOsave }



            obj.dataPagamento = new Date()
            obj.idTipoPagamento = undefined
            obj.descrizione = undefined
            obj.annotazione = undefined
            obj.riferimento = undefined
            obj.conti = undefined

            setObjctTOsave(obj)
      }

      let importo: number = 0;
      selected.forEach((scad: any) => {

          if (scad.idScadenzario) {

              console.log(scad)
              if (scad.idTipoDocumento === 4)
                  importo = importo - scad.importo
              else
                  importo = importo + scad.importo
          }
      });

      setImporto(importo)

        getListTipoPaga();
        setOpenD(open);
    
  }, [open, auth, selected]);



  const findeIndexTipiP = (ts:any) =>{
    let ids = null
    tipoPaga.forEach((pag:any) => {
        if(ts == pag.descrizione){
          ids = pag.idTipoPagamento 
        }
      });
      return ids
  }




  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'65%'}
      height={'80%'}
      showTitle={true}
      title="Registrazione Pagamento"
    >
     <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Annulla',
                onClick: ()=>close(),
              }}       
          />
           <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Salva',
                elementAttr:{
                  class:'btncreatecluster',
                },
                onClick: ()=>UpdatePaga(),
              }
            }
            />

      <ScrollView height="100%"  width="100%">
      

      <div className='form-row' >    
           <div className="form-group col-md-6">
               <label> DATA  </label>              
             <DateBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave && objctTOsave.dataPagamento ?  objctTOsave.dataPagamento : new Date()}  onValueChanged={(v)=>cambiaValore('dataPagamento',v.value) }  type="date" displayFormat="dd/MM/yyyy" />
                </div>  
              
                  <div className="form-group col-md-6" >
                      <label> MODALITA' PAGAMENTO</label>
                      <SelectBox dataSource={tipoPaga} displayExpr="descrizione" valueExpr="idTipoPagamento" valueChangeEvent="keyup" value={objctTOsave?.idTipoPagamento} onValueChanged={(v) => cambiaValore('idTipoPagamento', v.value)} />
                  </div> 

                </div>

                <div className='form-row' >    
           <div className="form-group col-md-6">
               <label> RIFERIMENTO  </label>              
               <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.riferimento}  onValueChanged={(v)=>cambiaValore('riferimento',v.value) }  /> 
                  </div>  

                  {showBancheInfo() ? (
                      <div className="form-group col-md-6" >
                          <label> BANCA </label>
                          <SelectBox dataSource={conti} displayExpr="conto" valueChangeEvent="keyup" value={objctTOsave?.conti ? objctTOsave?.conti : null} onValueChanged={(v) => cambiaValore('conti', v.value)} />
                      </div>
                  ) : null}




              </div>


              {showBancheInfo() ? (
                  <div className='form-row' >
                      <div className="form-group col-md-6">
                          <label> RAGIONE SOCIALE  </label>
                          <TextBox disabled valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.conti?.ragioneSocialeAzienda} onValueChanged={(v) => cambiaValore('note', v.value)} />
                      </div>

                      <div className="form-group col-md-6" >
                          <label> IBAN </label>
                          <TextBox disabled valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.conti?.iban} onValueChanged={(v) => cambiaValore('note', v.value)} />
                      </div>
                  </div>
              ) : <div></div>}




              {showBancheInfo() ? (
                  <div>
                      <label> CONTO CORRENTE </label>
                      <TextBox disabled valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.conti?.contoCorrente} onValueChanged={(v) => cambiaValore('note', v.value)} />
                      <br></br>
                  </div>
              ) : <div></div>}      
              
              <br></br>
              <label> ANNOTAZIONE </label>               
             <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.annotazione}  onValueChanged={(v)=>cambiaValore('annotazione',v.value) }  />               

              <label style={{ paddingTop: 15 }}> IMPORTO TOTALE </label>
              <NumberBox format="#,##0.## &euro;" disabled={true} value={importo} placeholder="&euro;" />

        
        
            
      </ScrollView>
    </Popup>
  );
};