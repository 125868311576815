import React, { useEffect } from "react";
import { useState } from "react";
import { ElencoContenitoriInterface, TestataContenitoreInterface } from "../../interfaces/contenitore";
import { OggettoInterface } from "../../interfaces/generiche";
import { DialogConferma, Loading } from '../../widget/Notifications';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { BoxFiltro, Check } from '../../widget/Editor';
import notify from "devextreme/ui/notify";
import { RestServices } from "../../services/restServices";
import { basenameHostNewTabs, urlContenitori } from "../costanti";
import { ListaVettoriInterface } from "../../interfaces/screening";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OnRowDblClickInterface, TabellaCompletaInterface } from "../../interfaces/tabelle";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { TabellaCompletaAlternate } from "../../widget/Tabelle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { TendinaFarm } from "../../widget/Select";
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";
import { Form, ButtonItem, SimpleItem, GroupItem } from "devextreme-react/form";
import { FileUploader } from "devextreme-react";

export const DialogNuovoContenitore = (props: {
    isDialogNewOpen: boolean,
    handleCloseDialogNew: () => void,
    setNewTestata: (datiTestata: TestataContenitoreInterface) => void,
    setIsVisible: (isVisible: boolean) => void,
    setIdContenitore: (idOrdine: number) => void,
}) => {

    const newContenitore = {
        idContenitore: undefined,
        nome: undefined,
        descrizione: undefined,
        operatore: undefined,
        dataContenitore: undefined,
    }

    const [filtri, setFiltri] = useState<TestataContenitoreInterface>(newContenitore);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }


    const creaNewContenitore = () => {
        if (!filtri.nome) {
            notify({
                position: "center", width
                    : "auto", message: "Inserire il nome del nuovo contenitore"
            }, "error", 4000)
        } 
        else {
            props.setNewTestata(filtri);
            const param = {
                nome: filtri.nome,
                descrizione: filtri.descrizione,
                idContenitore: undefined,
                dataContenitore: undefined,
                operatore: undefined
            }
            setIsVisible(true);
            RestServices().rest('POST', param, urlContenitori.creaContenitore).then(result => {
                if (result) {
                    if (result.esito) {
                        setIsVisible(false);
                        if (result.response.idContenitore > 0) {
                            props.setIdContenitore(result);
                            let url = basenameHostNewTabs + "contenitori/GetDettaglio?idContenitore=" + result.response.idContenitore + "&contenitoreFarmacia=0";
                            window.location.assign(url);
                        }
                    } else {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Si � verificato un errore, riprovare" }, "error", 4000)
                    }
                }
                
            })
        }
    }



    return (
        <>
            <Modal
                show={props.isDialogNewOpen}
                onHide={() => window.close()}
                dialogClassName="dialogNewContenitore"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewContenitore">
                        <h3>Crea Nuovo Contenitore</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row className="rigaCreaNuovoContenitore">
                        <Col sm="2" className="labelCreaNuovoContenitore">
                            <span>Nome: </span>
                        </Col>
                        <Col sm="10">
                            <BoxFiltro id="nome" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>
                    <Row className="rigaCreaNuovoContenitore">
                        <Col sm="2" className="labelCreaNuovoContenitore">
                            <span>Descrizione: </span>
                        </Col>
                        <Col sm="10">
                            <BoxFiltro id="descrizione" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiNewContenitore" variant="success" onClick={creaNewContenitore} >
                        Crea
                    </Button>
                    <Button id="btnChiudiDialogNewContenitore" variant="danger" onClick={() => window.close()}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}


export const DialogElencoContenitori = (props: {
    datiFarmacie: ListaFarmacieInterface[] | undefined,
    isDialogElencoContenitoriOpen: boolean,
    setIsDialogElencoContenitoriOpen: (isDialogElencoContenitoriOpen: boolean) => void
    handleCloseDialogElencoContenitori: () => void,
    functionNext: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const newElencoContenitori = {
        idContenitore: undefined,
        dataContenitore: undefined,
        nome: undefined,
        operatore: undefined,
        descrizione: undefined,
        flagImport: undefined,
        farmacia: undefined,
        numeroOccorrenze: undefined,
        dataContenitoreModifica: undefined
    }
    const [filtri, setFiltri] = useState<ElencoContenitoriInterface>(newElencoContenitori);
    const [elencoContenitori, setElencoContenitori] = useState<ElencoContenitoriInterface[]>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [idCont, setIdCont] = useState<number>();
    const [idFar, setIdFar] = useState<number>();
    const [titoloCont, setTitoloCont] = useState<string>();
    const [messageConferma, setMessageConferma] = useState<string>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />


    useEffect(() => {
        if (props.isDialogElencoContenitoriOpen)
            cerca();
    }, [props.isDialogElencoContenitoriOpen])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        setElencoContenitori(undefined);
        setTabellaInstance(undefined)
        props.setIsDialogElencoContenitoriOpen(false);

    }

    const cerca = () => {
        const param = {};
        setIsVisible(true);
        RestServices().rest('POST', param, urlContenitori.getListFarmacie).then(result => {
            if (result) {
                setIsVisible(false);
                if (result.esito) {
                    setElencoContenitori(result.response);
                } else {
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                }
            }
                
            })
    }

    const propsTabContenitoriFarmacia: TabellaCompletaInterface = {
        id: "Screening_codici_kit",
        dataSource: elencoContenitori,
        pagination: 10,
        selection: "single",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: true,
    }
    const columnsContenitoriFarmacia = CreaColonne([
        { caption: "Id", dataField: "idContenitore", visible: false },
        { caption: "IdFar", dataField: "idFar", visible: false },
        { caption: "Import", dataField: "flagImport", visible: false },
        { caption: "Importato", dataField: "flagImportato", visible: false },
        { caption: "Nome", dataField: "nome" },
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Data Ora", dataField: "dataContenitore", sortOrder: "desc", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", visible: false },
        { caption: "Farmacia", dataField: "farmacia" },
        { caption: "Num. occorrenze", dataField: "numeroOccorrenze" },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [
                {

                    hint: "Importa Contenitore",
                    icon: "arrowdown",
                    onClick: (e: any) => {

                        if (!e.row.data.flagImport) {
                            notify({ position: "center", width: "auto", message: "Il contenitore non puo' essere importato." }, "error", 4000)
                            return;
                        }

                        setIdCont(e.row.data.idContenitore);
                        setIdFar(e.row.data.idFar);
                        setTitoloCont(e.row.data.nome);
                        setMessageConferma("Il contenitore " + e.row.data.nome + " sta per essere importato. Confermare?");
                        setDialogIsOpen(true);

                    },
                }
            ]
        }
    ])

    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            if (rowDblClicked.data.flagImport == 1) {
                //notify({ position: "center", width: "auto", message: "Importare il contenitore per poterlo modificare." }, "error", 4000)
                //return;
                const path = basenameHostNewTabs + "contenitori/GetDettaglio?idContenitore=" + rowDblClicked.data.idContenitore + "&contenitoreFarmacia=1&idFar=" + rowDblClicked.data.idFar;
                window.open(path);
            }
            
        }
    }

    const onRowPrepared = (e: any) => {
        if (e.values) {
            if (e.data.flagImportato == 1) {
                e.rowElement.style.backgroundColor = '#95FFF8';
            }
        }
    }

    const functionNext = () => {
        setDialogIsOpen(false);
        importaContenitore();

    }

    const importaContenitore = () => {
        const param = {

        };
        setIsVisible(true);
        RestServices().rest('POST', param, urlContenitori.import + '?idContenitore=' + idCont + '&idFar=' + idFar).then(result => {
            if (result) {
                setIsVisible(false);
                if (result.esito) {
                    notify({ position: "center", width: "auto", message: "Contenitore importato correttamente" }, "success", 4000)
                    cerca();
                }
                else {
                    notify({ position: "center", width: "auto", message: "Non e' stato possibile importare il contenitore. Si prega di riprovare" }, "error", 4000)
                    return;
                }
            }

        })
        
    }

    const handleCloseConferma = () => setDialogIsOpen(false);


    return (
        <>
            
            <Modal
                show={props.isDialogElencoContenitoriOpen}
                onHide={handleClose}
                dialogClassName="dialogElencoContenitori"
                size="xl"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloConsegnaProvetta">
                        <h3>Elenco contenitori farmacia</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row>
                        <Col sm="6">
                            <div className="leftcontent">
                                <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Info" /> Doppio click sulla riga per visualizzare il dettaglio</span>
                                &nbsp;
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="6">
                            <div className="leftcontent">
                                <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                                &nbsp;
                                <span id="lblStatoCreato" title="Ordine creato" className="lblStatoCreato">Contenitore gia' importato</span>
                                &nbsp;
                            </div>
                        </Col>
                    </Row>
                    <TabellaCompletaAlternate
                        propsTabella={propsTabContenitoriFarmacia}
                        columns={columnsContenitoriFarmacia}
                        onRowDblClick={onRowDblClick}
                        setTabellaInstance={setTabellaInstance}
                        wordWrapEnabled={true}
                        togliFilterPanel={true}
                        rowAlternationEnabled={false}
                        onRowPrepared={onRowPrepared}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnChiudiDialogConsegnaProvetta" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>

                <DialogConferma
                    dialogIsOpen={dialogIsOpen}
                    message={messageConferma}
                    functionNext={functionNext}
                    handleCloseConferma={handleCloseConferma}
                />

            </Modal>
        </>
    )

}

export const DialogInviaContenitore = (props: {
    datiFarmacie: ListaFarmacieInterface[],
    isDialogInviaContenitoreOpen: boolean,
    setIsDialogInviaContenitoreOpen: (isDialogInviaContenitoreOpen: boolean) => void
    handleCloseDialogInviaContenitore: () => void,
    setIsVisible: (isVisible: boolean) => void,
    idContenitore : number[] | undefined,
}) => {


    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {

        props.setIsDialogInviaContenitoreOpen(false);

    }

    const inviaContenitore = () => {
        if (!filtri.lstFar) {
            notify({
                position: "center", width
                    : "auto", message: "Selezionare almeno una farmacia"
            }, "error", 4000)
        }
        else {



            const param = {
                idFar: filtri.lstFar,
                idCon: props.idContenitore,
            }
            setIsVisible(true);
            RestServices().rest('POST', param, urlContenitori.invia).then(result => {
                if (result) {
                    if (result.esito) {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Invio effettuato con successo" }, "success", 4000)
                    } else {
                        setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Si � verificato un errore, riprovare" }, "error", 4000)
                    }
                }

            })
        }
    }



    return (
        <>
            <Modal
                show={props.isDialogInviaContenitoreOpen}
                onHide={() => window.close()}
                dialogClassName="dialogNewContenitore"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewContenitore">
                        <h3>Crea Invia Contenitori</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loadPanel}
                    <Row className="rigaCreaNuovoOrdLib">
                        <Col sm="2" className="labelCreaNuovoOrdLib">
                            <span>Farmacie: </span>
                        </Col>
                        <Col sm="10">
                            <TendinaFarm datiFarmacie={props.datiFarmacie} id="lstFar" impostaFiltri={impostaFiltri} />
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiNewContenitore" variant="success" onClick={inviaContenitore} >
                        Invia
                    </Button>
                    <Button id="btnChiudiDialogNewContenitore" variant="danger" onClick={handleClose}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export const DialogUploadContenitore = (props: {
    isDialogUploadContenitore: boolean,
    setIsDialogUploadContenitore: (isDialogUploadContenitore: boolean) => void
    handleCloseDialogUploadContenitore: () => void,
    functionNext: () => void,
    setIsVisible: (isVisible: boolean) => void,
}) => {

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [uploadUrl, setUploadUrl] = useState<any>();
    const [formRef, setFormRef] = useState<any>();
    const [fileUploaderRef, setFileUploaderRef] = useState<any>();

    const fileExtensions = ['.csv'];

    useEffect(() => {

        if (props.isDialogUploadContenitore) {
            setUploadUrl(urlContenitori.importaDaFile);
        }
    }, [props.isDialogUploadContenitore])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    const handleClose = () => {
        props.setIsDialogUploadContenitore(false);
    }

    const aggiungiParametroFile = (e: any) => {
        if (e.value.length > 0) {
            setUploadUrl(updateQueryStringParameter(uploadUrl, "nomeFile", e.value[0].name))
            e.component.option("uploadUrl", uploadUrl);
        }

    }

    const updateQueryStringParameter = (uri: any, key: any, value: any) => {
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
        if (uri.match(re)) {
            //console.log("1:" + uri.replace(re, '$1' + key + "=" + value + '$2'));
            return uri.replace(re, '$1' + key + "=" + value + '$2');
        }
        else {
            //console.log("2:" + uri + separator + key + "=" + value);
            return uri + separator + key + "=" + value;
        }
    }

    const importa = () => {



    }

    const editorOptions = {
        readOnly: true,
    };



    const submitButtonOptions = {

        text: "Importa file",
        useSubmitBehavior: true,
        type: "success",
        onClick: function (e: any) {

            setUploadUrl(uploadUrl);
            var upl = '';
            if (filtri != undefined) {
                if (filtri.nome != undefined || filtri.nome > 0) {
                    upl = upl + '&nome=' + filtri.nome;
                }

                if (filtri.descrizione != undefined || filtri.descrizione > 0) {
                    upl = upl + '&descrizione=' + filtri.descrizione;
                }
            }
            setUploadUrl(uploadUrl + upl);
        }
    };

    const handleSubmit = (e: any) => {

        props.setIsVisible(true);
        e.preventDefault();
        fetch(e.target.action, {
            method: 'POST',
            body: new FormData(e.target),
            credentials: 'include'
        }).then(resp => resp.json())
            .then(
                (result) => {
                    if (result.esito == true) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: "Importazione completata" }, "success", 4000)
                    }
                    else if (result.esito == false) {
                        props.setIsVisible(false);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                }).catch((error) => {
                    props.setIsVisible(false);
                    notify({ position: "center", width: "auto", message: "ops c'e' stato un errore!" }, "error", 4000)
                });



    }

    return (
        <>
            <Modal
                show={props.isDialogUploadContenitore}
                onHide={handleClose}
                dialogClassName="dialogNuovaNewsletter"
                size="lg"
                centered={true}
                backdrop="static"
                enforceFocus={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloNewOrdineLibero">
                        <h3>Importa da file</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="file-uploader-block">
                        <form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} onSubmit={handleSubmit}>
                            {/*<form id="form" method="post" encType="multipart/form-data" ref={setFormRef} action={uploadUrl} target="_blank">*/}
                            <Form
                                colCount={2}>

                                <GroupItem
                                    caption="Tracciato del file"
                                    colSpan={2}>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">Codice ministeriale<b>;</b>quantita</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">( la quantita puo' essere omessa, di default sara' impostata a 1 )</span>
                                        </Col>
                                    </Row>
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            <span>Esempi:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">004763037;5</span>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">025940053;</span>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="trasfInfo">004763037;</span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Scegli il file"
                                    colSpan={2}>
                                    <FileUploader
                                        selectButtonText="Seleziona file" labelText=""
                                        /*accept="image/*"*/
                                        uploadMode="useForm"
                                        uploadUrl={uploadUrl}
                                        onValueChanged={aggiungiParametroFile}
                                        allowedFileExtensions={fileExtensions}
                                        maxFileSize={2000000}
                                        id="fileUploader"
                                        ref={setFileUploaderRef}
                                    />
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'File permessi: '}
                                                <span>.csv</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span className="noteFileUploadNewsletter">{'Dimensione massima file: '}
                                                <span>2 MB</span>
                                                .
                                            </span>
                                        </Col>
                                    </Row>
                                </GroupItem>

                                <GroupItem
                                    caption="Inserisci le informazioni"
                                    colSpan={2}>
                                    <Row>
                                        <Col sm="6">
                                            <span>Nome contenitore:</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <BoxFiltro id="nome" impostaFiltri={impostaFiltri} />
                                        </Col>
                                    </Row>
                                    <Row>
                                        &nbsp;
                                    </Row>
                                    <Row>
                                        <Col sm="6">
                                            <span>Descrizione contenitore:</span>
                                        </Col>
                                        
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                            <BoxFiltro id="descrizione" impostaFiltri={impostaFiltri} />
                                        </Col>
                                        </Row>
                                </GroupItem>

                                <ButtonItem colSpan={2} buttonOptions={submitButtonOptions} horizontalAlignment="right" />
                            </Form>
                        </form>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/*<Button id="btnImportaPrezzoPromozione" variant="success" onClick={() => importa()}>*/}
                    {/*    Importa*/}
                    {/*</Button>*/}
                    <Button id="btnChiudiDialogImportPrezzoPromozione" variant="danger" onClick={() => props.handleCloseDialogUploadContenitore()}>
                        Chiudi
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
