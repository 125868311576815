import notify from "devextreme/ui/notify";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { OggettoInterface } from "../../interfaces/generiche";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { DialogConferma, Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlBancaDati, urlPrezzoPromozione, urlScreening } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TendinaFarm } from "../../widget/Select";
import Button from 'react-bootstrap/esm/Button';
import { DrawerFiltri } from "../../widget/Drawer";
import { BoxTestoCerca } from "../../widget/Editor";
import { RicercaProdInterface } from "../../interfaces/bancaDati";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { getCodiceFarmFromId } from "../../funzioni/anagrafica";
import { TabellaCompleta, TabellaCompletaAlternate, TabellaOrdiniLiberi } from "../../widget/Tabelle";
import { useAuth } from "../../hooks/useAuth";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { ElencoPrezziPromozioneInterface } from "../../interfaces/prezzoPromozione";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const PrezziPromozione = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    //const auth = useAuth(urlPrezzoPromozione.auth);

    const titolo = TitoloContenuto("Prezzi Promozione");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [filtri, setFiltri] = useState<OggettoInterface>({});
    const [elencoPrezziPromozione, setElencoPrezziPromozione] = useState<ElencoPrezziPromozioneInterface[]>();
    const [elencoPrezziPromozioneOriginale, setElencoPrezziPromozioneOriginale] = useState<1>();
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [prodotto, setProdotto] = useState<any>();
    const [openDrawer, setOpenDrawer] = useState(true);
    const [allowEditing, setAllowEditing] = useState<boolean>(true);
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [messageConferma, setMessageConferma] = useState<string>();
    const handleCloseConferma = () => setDialogIsOpen(false);
    const [prezzo, setPrezzo] = useState<number | undefined>();
    const [quantita, setQuantita] = useState<number | undefined>();
    const [idPreLis, setIdPreLis] = useState<number | undefined>();
    const [idFar, setIdFar] = useState<number | undefined>();
    const [dataInizio, setDataInizio] = useState<string | undefined>()
    const [dataFine, setDataFine] = useState<string | undefined>()
    const [nuovo, setNuovo] = useState<boolean | undefined>()

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {

        if (window.location.href.includes("?")) {
            setIsVisible(true);
            const query = new URLSearchParams(window.location.search);
            const codCsf = query.get('codCsf');
            const far = query.get('lstFar');
            const nuovo = query.get('nuovo');
            if (nuovo == 'true')
                setNuovo(true);
            else
                setNuovo(false);
            var array;
            if (far != null && far.length === 0) {
                array = new Array();
            } else {
                array = far!.replace(/, +/g, ",").split(",").map(Number);
            }

            const param = {
                codCsf: codCsf,
                lstFar: array,
                nuovo: nuovo,
            }

            RestServices().rest('POST', param, urlPrezzoPromozione.getListModificaPrezzoPromozione).then(result => {
                setIsVisible(false);
                if (result) {
                    setElencoPrezziPromozione(result.lst);
                    const objCloneByJsonStringfy = JSON.parse(JSON.stringify(result.lst));
                    setElencoPrezziPromozioneOriginale(objCloneByJsonStringfy);
                    setProdotto(result.prodotto);
                } else if (result !== true) {
                    notify({ position: "center", width: "auto", message: "Oops, si � verificato un errore. Ci scusiamo per il disagio" }, "error", 4000)
                }
            })
        }

    }, [props.datiFarmacie]);

    


    const templateFiltri = () => {
        return (
            <Row className="filtriInDrawer">
                <Col sm="10">
                    <label>{prodotto?.descrizione}  -  Cod. Prod.: {prodotto?.minsan}  -  Cod. Ean: {prodotto?.ean ? prodotto?.ean : '**********'}  -  Prezzo Banca Dati: {prodotto?.prezzo} &euro;</label>
                </Col>

                <Col sm="2">
                    <Button type="button" variant="success" id="btnSalvaPrezzoPromozione" onClick={() => salva(false)}>Salva</Button>
                    <Button type="button" variant="info" id="btnSalvaInviaPrezzoPromozione" onClick={() => salva(true)}>Salva e invia</Button>
                </Col>
            </Row>

        )
    }

    const creaRiepilogoFiltri = () => {
        return (
            <>
               
            </>
        )
    }

    const propsTabPrezziPromozione: TabellaCompletaInterface = useMemo(() => ({
        id: "elenco_prezzi_promozione",
        dataSource: elencoPrezziPromozione,
        selection: "multiple",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        pagination: 20,
        rowAlternationEnabled: false,
        showCheckBoxesMode: 'always',
        export: {
            enabled: true,
            fileName: "Prezzi_Promozione"
        },
        editing: {
            mode: "cell",
            allowUpdating: allowEditing,
            selectTextOnEditStart: true,
        }
    }), [elencoPrezziPromozione, allowEditing])

    const columnsPrezziPromozione = CreaColonne([
        { caption: "Id", dataField: "idPreLis", visible: false, allowEditing: false },
        { caption: "IdFar", dataField: "idFar", visible: false, allowEditing: false },
        { caption: "CodCsf", dataField: "codCsf", visible: false, allowEditing: false },
        { caption: "Farmacia", dataField: "farmacia", width: 750, allowEditing: false },
        { caption: "Prezzo farmacia", dataField: "prezzoFarmacia", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: false },
        { caption: "Prezzo promozione", dataField: "prezzo", format: { currency: "EUR", precision: 2, type: "fixedPoint", allowEditing: true } },
        { caption: "Qta.", dataField: "quantita", allowEditing: true },
        { caption: "Data inizio", dataField: "dataInizio", format: "dd/MM/yyyy", dataType: "date", allowEditing: true },
        { caption: "Data fine", dataField: "dataFine", format: "dd/MM/yyyy", dataType: "date", allowEditing: true },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [
                {
                    hint: "Copia prezzo, quantita' e data sulle farmacie selezionate",
                    icon: "copy",
                    onClick: (e: any) => {

                        //verifica se ci sono record checkati e se il record selezionato non sia il corrente
                        //setIsVisible(true);
                        var keys = tabellaInstance.instance.getSelectedRowKeys();
                        if (keys.length == 0) {
                            //setIsVisible(false);
                            //notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia" }, "error", 4000);
                            //return;
                            setMessageConferma("Applicare il prezzo a tutte le altre farmacie?");
                        } else {
                            setMessageConferma("Applicare il prezzo alle farmacie selezionate?");
                        }

                        const prezzo = e.row.data.prezzo;
                        const quantita = e.row.data.quantita;
                        const dataInizio = e.row.data.dataInizio;
                        const dataFine = e.row.data.dataFine;
                        const idPreLis = e.row.data.idPreLis;
                        const idFar = e.row.data.idFar;

                        setPrezzo(prezzo);
                        setQuantita(quantita);
                        setIdPreLis(idPreLis);
                        setIdFar(idFar)
                        setDataInizio(dataInizio);
                        setDataFine(dataFine);

                        setDialogIsOpen(true);

                        //recupero quantita, prezzo, data inizio e data fine della riga
                        

                        //const copiaDati = elencoPrezziPromozione;
                        //if (copiaDati != undefined) {
                        //    if (keys.length >= 0) {
                        //        copiaDati.forEach(riga => {
                        //            if (riga.idPreLis !== idPreLis && keys.filter(function (o: any) {
                        //                return o.idPreLis
                        //                    === riga.idPreLis;
                        //            }).length > 0) {
                        //                riga.prezzo = prezzo;
                        //                riga.quantita = quantita;
                        //                riga.dataInizio = dataInizio;
                        //                riga.dataFine = dataFine;
                        //            }
                        //        })
                        //    }
                        //    else if (keys.length == 0) {
                        //        copiaDati.forEach(riga => {
                        //            if (riga.idPreLis !== idPreLis )
                        //            {
                        //                riga.prezzo = prezzo;
                        //                riga.quantita = quantita;
                        //                riga.dataInizio = dataInizio;
                        //                riga.dataFine = dataFine;
                        //            }
                        //        })
                        //    }
                        //    setElencoPrezziPromozione(copiaDati);
                        //}
                        //setIsVisible(false);
                    }
                }]
        }
    ])

    const salva = (invia: boolean | undefined) =>
    {

        const param = {
            new: tabellaInstance.instance.getDataSource()._items,
            old: elencoPrezziPromozioneOriginale,
            invia:false
        }

        if (invia)
            param.invia = true;

        RestServices().rest('POST', param, urlPrezzoPromozione.setPrezzoPromozione).then(result => {
            setIsVisible(false);
            if (result.esito) {
                /*notify({ position: "center", width: "auto", message: "Salvataggio avvenuto con successo" }, "success", 4000)*/
                notify({ position: "center", width: "auto", message: result.message }, "success", 4000)
            } else if (result.esito !== true) {
                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
            }
        })
    }

    const onCellClick = (e: any) => {

        if (e.rowType === "data" && ((e.column.dataField === "farmacia") || (e.column.dataField === "prezzoFarmacia"))) {

        }
    }


    const onCellPrepared = useCallback((e: any) => {
        
        if (e.rowType === "data" && ((e.column.dataField === "prezzo") || (e.column.dataField === "quantita") || (e.column.dataField === "dataInizio") || (e.column.dataField === "dataFine")) ) {
            e.cellElement.style.backgroundColor = "paleturquoise";
        }
    }, [])

    const onFocusedCellChanged = useCallback((e: any) => {
        if (tabellaInstance && ((e.column.dataField === "prezzo") || (e.column.dataField === "quantita") || (e.column.dataField === "dataInizio") || (e.column.dataField === "dataFine"))) {
            if (allowEditing ) {
                tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
                tabellaInstance.instance.editCell(e.rowIndex, e.column.dataField);
            } else {
                tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
            }
        }
    }, [tabellaInstance, allowEditing])

    const onRowPrepared = (e: any) => {
        if (e.values) {
            if (e.data.riferimento == 1) {
                e.rowElement.style.backgroundColor = '#FCFF92';
            }
        }
    }

    const functionNext = () => {

        setIsVisible(true);

        var keys = tabellaInstance.instance.getSelectedRowKeys();

        const copiaDati = elencoPrezziPromozione;
        if (copiaDati != undefined) {
            if (keys.length == 1 && keys[0].idFar == idFar) {
                if (keys[0].idFar == idFar) {
                    copiaDati.forEach(riga => {
                        if (!nuovo) {
                            if (riga.idFar !== idFar) {
                                riga.prezzo = prezzo;
                                riga.quantita = quantita;
                                riga.dataInizio = dataInizio;
                                riga.dataFine = dataFine;
                            }
                        }
                        else {

                            riga.prezzo = prezzo;
                            riga.quantita = quantita;
                            riga.dataInizio = dataInizio;
                            riga.dataFine = dataFine;

                        }

                    })
                }
            }else if (keys.length > 0) {
                copiaDati.forEach(riga => {
                    if (!nuovo) {
                        if (riga.idFar !== idFar && keys.filter(
                            function (o: any) {
                                return o.idFar === riga.idFar;
                            }).length > 0) {
                            riga.prezzo = prezzo;
                            riga.quantita = quantita;
                            riga.dataInizio = dataInizio;
                            riga.dataFine = dataFine;
                        }
                    }
                    else {
                        if (keys.filter(
                            function (o: any) {
                                return o.idFar === riga.idFar;
                            }).length > 0) {
                            riga.prezzo = prezzo;
                            riga.quantita = quantita;
                            riga.dataInizio = dataInizio;
                            riga.dataFine = dataFine;
                        }
                    }


                })
            }
            else if (keys.length == 0) {
                copiaDati.forEach(riga => {
                    if (!nuovo) {
                        if (riga.idFar !== idFar) {
                            riga.prezzo = prezzo;
                            riga.quantita = quantita;
                            riga.dataInizio = dataInizio;
                            riga.dataFine = dataFine;
                        }
                    }
                    else {

                        riga.prezzo = prezzo;
                        riga.quantita = quantita;
                        riga.dataInizio = dataInizio;
                        riga.dataFine = dataFine;

                    }

                })
            }
            
            setElencoPrezziPromozione(copiaDati);
        }

        setDialogIsOpen(false);
        setIsVisible(false);
        
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <DrawerFiltri id="paginaOrdiniRda" templateFiltri={templateFiltri} open={openDrawer} setOpen={setOpenDrawer} testoDrawer="Prodotto" />
                <Col sm="6">
                    <div className="leftcontent">
                        <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                        &nbsp;
                        <span id="lblFarmaciaRiferimento" title="Farmacia di riferimento" className="lblFarmaciaRiferimento">Farmacia di riferimento</span>
                        &nbsp;

                    </div>
                </Col>
            <Row className="tabellaFornitori">
                <Col sm='12' >
                    <TabellaCompletaAlternate
                        propsTabella={propsTabPrezziPromozione}
                        //onRowUpdating={onRowUpdating}
                        setTabellaInstance={setTabellaInstance}
                        columns={columnsPrezziPromozione}
                        enterKeyDirection="row"
                        rowAlternationEnabled={false}
                    onCellPrepared={onCellPrepared}
                    //onContentReady={onContentReady}
                    onCellClick={onCellClick}
                    onFocusedCellChanged={onFocusedCellChanged}
                    //onKeyDown={onKeyDown}
                    //selectedRowKeys={selectedRowKeys}
                    //onEditingStart={onEditingStart}
                    onRowPrepared={onRowPrepared}
                    />

                </Col>
                </Row>
            </div>

            <DialogConferma
                dialogIsOpen={dialogIsOpen}
                message={messageConferma}
                functionNext={functionNext}
                handleCloseConferma={handleCloseConferma}
            />
        </>
    )

}

export default PrezziPromozione;