import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import {DialogAnagAziende} from "./dialog/popup_ana_aziende"
import {  Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'


const AnagAziende = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
    
    const [ openPop , setOpenPop ] = useState<boolean>(false)
    const [objtipo,setObjtipo] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth);
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    
    const columns = [
        { caption: "Codice", dataField: "codice" },
        { caption: "Ragione Sociale", dataField: "ragioneSociale"  },
        { caption: "P. Iva", dataField: "piva"  },
        { caption: "Indirizzo", dataField: "indirizzo" },
        { caption: "Cap", dataField: "cap" },
        { caption: "Comune", dataField: "denominazione" },
        { caption: "Telefono", dataField: "telefono"},
        { caption: "Azioni",    type: "buttons", buttons: [
            {
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {   
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setOpenPop(true)
                    
                }
            },
            
        ] 
         },
    ];
    


      
        const getListAzie = () =>{
            if (auth) {
                return  RestServices().rest('POST', {} , urlConsultazione.getListAzienda)
                    
                      }
                }

        
const ricarica =  () =>{
    setOpenPop(false)
    setImmediate(false);
    setObjtipo(null)
    caricaDati()
}

    

    const caricaDati = async() =>{
        if (auth){
            let listaTabella = await getListAzie()
            
            if (listaTabella?.esito) { 
                let tabellaNew:any = []
                listaTabella.response.map((value:any)=>{
                    let objValue = {...value}         
                    tabellaNew.push(objValue)
              
                })
                
                setTabellaPrincipale(tabellaNew)
                setLoading(false)
            }else{
                notify({ position: "center", width: "auto", message: listaTabella.message}, "error", 4000)
                setLoading(false)
            }
        }
    }    



    useEffect(()=>{
       caricaDati()     
    },[auth])
    
    useEffect(() => {
       if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

 
   
    return (
        <>

        <DialogAnagAziende  edit={objtipo} ricarica={()=>{ricarica();setImmediate(false);setObjtipo(null)}} close={()=>{setOpenPop(false);setImmediate(false);setObjtipo(null)}} open={openPop} />
      
       
        <Loading isVisible={loading} />
        <div  className='title_cluster'>
                <h3> ANAGRAFICA AZIENDE  </h3>
             </div>
              
             
            <NewTabella 
                idTabella='Anagraf_aziende'
                sync
                height='90vh'
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                exportT={{
                    enabled: true,
                    fileName: "Anagraf_aziende"
                }}
                
            />
        </>
    )
}

export default AnagAziende