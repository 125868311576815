import { useEffect, useRef, useState } from "react";
import React from 'react';
import IframeResizer from 'iframe-resizer-react'

export const FrameWrapper = (props: {
    html: string
}) => {
    const iframeRef = useRef(null)
    const [messageData, setMessageData] = useState()

    const onResized = (data:any) => setMessageData(data)

    const onMessage = (data: any) => {
        setMessageData(data)
        //console.log(data)
        //iframeRef.current?.sendMessage('Hello back from the parent page')
    }

    return (
        <>
            <IframeResizer
                forwardRef={iframeRef}
                heightCalculationMethod="lowestElement"
                inPageLinks
                log
                onMessage={onMessage}
                onResized={onResized}
                src={props.html}
                style={{ width: '1px', minWidth: '100%', border:'none' }}
            />
        </>
    );
};