import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { AnagraficaFarmaciaInterface } from '../../interfaces/myCsf';
import { OggettoInterface } from '../../interfaces/generiche';
import Button from 'react-bootstrap/esm/Button';
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { urlmyCsf } from '../costanti';
import { Loading } from '../../widget/Notifications';
import { Check } from '../../widget/Editor';
import { CheckBox } from 'devextreme-react';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { TitoloContenuto } from '../../struttura_pagina/TitoloContenuto';

const AnagraficaFarmacia = (props: {
    statoMenu: boolean,
    toggleMenu: (stato: boolean) => void,
    datiFarmacie: ListaFarmacieInterface[],
    contenuto: string
}) => {
    //questo componente viene utilizzato sia per anagrafica farmacia che per il concessionario -> nel secondo caso ci sono 
    //dei parametri in più da visualizzare e sono stati inseriti come opzionali dopo il controllo della presenza di 
    //props.concessionari (se è true si tratta della pagina per il concessionario altrimenti solo della farmacia)

    const [anagraficaFarmacia, setAnagraficaFarmacia] = useState<AnagraficaFarmaciaInterface>();
    const [idFarm, setIdFarm] = useState<number>();
    let titolo = TitoloContenuto(props.contenuto);

    const newAnagraficaFarmacia = {
        ragioneSociale: undefined,
        titolare: undefined,
        codiceClienteCsf: undefined,
        codiceTelepharma: undefined,
        partitaIva: undefined,
        codiceFiscale: undefined,
        indirizzo: undefined,
        citta: undefined,
        cap: undefined,
        provincia: undefined,
        regione: undefined,
        telefono: undefined,
        telefono2: undefined,
        telefonoCellulare: undefined,
        email: undefined,
        emailAmministrativa: undefined,
        emailPEC: undefined,
        categoriaContabile: undefined,
        codiceFarmaPlan: undefined,
        codiceIms: undefined,
        codiceNewLine: undefined,
        situazioneGiuridica: undefined,
        emailCommMark: undefined,
        disdettaNewsletter: undefined,
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, [])

    useEffect(() => {
        if (props.datiFarmacie.length === 1) {
            const farm = props.datiFarmacie[0].ID_FAR;
            setIdFarm(farm);
            chiamate(farm);
        }
    }, [props.datiFarmacie])

    const chiamate = (farmacia: number) => {
        setIsVisible(true);
        RestServices().rest('POST', farmacia, urlmyCsf.anaFarmacie)
            .then(result => {
                setAnagraficaFarmacia(result);
                setDisdettaNewsletter(result.disdettaNewsletter);
                setEmailCom(result.emailCommMark);
            })
            .then(() => {
                setIsVisible(false)
            });
    }

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    let tmp: boolean|undefined;

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [emailCom, setEmailCom] = useState<string>();
    const [disdettaNewsletter, setDisdettaNewsletter] = useState<boolean>();

    const editorOptions = {
        readOnly: true,
    };

    const formFieldDataChanged = (e: any) => {
        if (e.dataField == 'disdettaNewsletter')
            setDisdettaNewsletter(e.value);
        if (e.dataField == 'emailCommMark')
            setEmailCom(e.value);
    }

    const salvaAnagraficaFarmacia = () => {

        const param = {
            idFar: idFarm,
            disdettaNewsletter: disdettaNewsletter,
            emailCom: emailCom,
        }
       // console.log(disdettaNewsletter)
       // console.log(emailCom)
        if (disdettaNewsletter && (emailCom == undefined || emailCom == ''))
            {
            notify({ position: "center", width: "auto", message: "Inserire indirizzo email per comunicazioni e marketing" }, 'error', 4000);
            return;
            }
            
        setIsVisible(true);
        RestServices().rest('POST', param, urlmyCsf.setAnaFarmacie).then(result => {
            setIsVisible(false);
            if (result.esito) {
                notify({ position: "center", width: "auto", message: "Salvataggio eseguito con successo" }, 'success', 4000);
            } else {
                notify({ position: "center", width: "auto", message: "Ops. Si e' verificato un problema" }, 'error', 4000);
            }
        })


    }

    return (
        <>
            {titolo}
            {loadPanel}
            <Row>&nbsp;</Row>
            <Form
                colCount={2}
                id="form"
                formData={anagraficaFarmacia}
                onFieldDataChanged={formFieldDataChanged}>
                <GroupItem colCount={2} colSpan={2} caption="Farmacia e Titolare">
                    <SimpleItem dataField="ragioneSociale" editorOptions={editorOptions} />
                    <SimpleItem dataField="titolare" editorOptions={editorOptions} />
                    <SimpleItem dataField="partitaIva" editorOptions={editorOptions} />
                    <SimpleItem dataField="codiceFiscale" editorOptions={editorOptions} />
                </GroupItem>

                <GroupItem colCount={3} colSpan={2} caption="Indirizzo">
                    <SimpleItem dataField="indirizzo" editorOptions={editorOptions} />
                    <SimpleItem dataField="citta" editorOptions={editorOptions} />
                    <SimpleItem dataField="cap" editorOptions={editorOptions} />
                    <SimpleItem dataField="provincia" editorOptions={editorOptions} />
                    <SimpleItem dataField="regione" editorOptions={editorOptions} />
                </GroupItem>

                <GroupItem colCount={2} colSpan={2} caption="Recapiti">
                    <SimpleItem dataField="telefono" editorOptions={editorOptions} />
                    <SimpleItem dataField="telefono2" editorOptions={editorOptions} />
                    <SimpleItem dataField="telefonoCellulare" editorOptions={editorOptions} />
                    <SimpleItem dataField="email" editorOptions={editorOptions} />
                    <SimpleItem dataField="emailAmministrativa" editorOptions={editorOptions} />
                    <SimpleItem dataField="emailPec" editorOptions={editorOptions} />
                </GroupItem>

                <GroupItem colCount={3} colSpan={2} caption="Comunicazioni e Marketing">
                    <SimpleItem
                        dataField="emailCommMark">
                        <Label text="Email per comunicazioni e marketing" />
                    </SimpleItem>
                    <SimpleItem dataField="disdettaNewsletter" editorType="dxCheckBox">
                        <Label text="Accetta ricezione email comunicazioni e marketing" />
                        </SimpleItem>
                    <SimpleItem><Button id="btnSalvaAnagraficaFarmacia" variant="success" onClick={salvaAnagraficaFarmacia} >
                        Salva modifiche
                    </Button></SimpleItem>
                </GroupItem>

                <GroupItem colCount={2} colSpan={2} caption="Informazioni di sistema">
                    <SimpleItem dataField="codiceClienteCsf" editorOptions={editorOptions}/>
                    <SimpleItem dataField="codiceTelepharma" editorOptions={editorOptions}/>
                </GroupItem>
            </Form>
                
        </>
    )
}

export default AnagraficaFarmacia