import React, { useState, useEffect } from 'react'
import { Popup, ToolbarItem } from 'devextreme-react/popup'
import ScrollView from 'devextreme-react/scroll-view'
import NewTabella from "../../../widget/nuova_tabella"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from '../../../hooks/useAuth'
import notify from 'devextreme/ui/notify'


interface DialogProps  {
    open: boolean,
    close: ()=>void,
    ricarica: ()=>void,
    edit:any,
    typeEdit: boolean,
} 

export const DialogNascondiTipo :React.FC<DialogProps> = ({open,close,ricarica,edit,typeEdit})  => {

    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [openD,setOpenD] = useState<boolean>(false)
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const auth = useAuth(urlConsultazione.auth)
   

    const columns = [
        { caption: "Nome", dataField: "descrizioneBreve"  },
        { caption: "Descrizione", dataField: "descrizioneEstesa"  },
        { caption: "Azioni",  type: "buttons", buttons: [

            {
                hint: "Mostra",
                icon: "plus",
                onClick: (e: any) => {
                   // console.log(e.row.data);
                    actionNascRemove(e.row.data.id) 
                    //ricarica()               
                    //actionNasc(e.row.data.id)               
                                              
                }
            },]
        }]



    const actionNascRemove = (id:number) =>{
        RestServices().rest('POST', {id:id} , urlConsultazione.deleteCausaNascostaPN)
                .then(result => {
                   if(result.esito){
                       getListCausaNascosteRicarica()
                       notify({ position: "center", width: "auto", message: 'Causale ora visibile'}, "success", 4000)
                       
                   }
                   else{
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                    setLoading(false)
                }
        
                })
        
    }


    const getListCausaNascoste = () =>{
        setLoading(true)
        if (open) {
            RestServices().rest('POST', {} , urlConsultazione.getListCausaNascostaPN)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)

                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }
    }

    const getListCausaNascosteRicarica = () =>{
        setLoading(true)
        RestServices().rest('POST', {} , urlConsultazione.getListCausaNascostaPN)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }


    useEffect(()=>{
        if(edit){
            let objTo = {...objctTOsave}
            objTo = edit
            setObjctTOsave(objTo) 
        }else{
            setObjctTOsave({}) 
        }

    },[edit])


    useEffect(()=>{
        setOpenD(open)   
    },[auth])


    useEffect(()=>{
        setOpenD(open)
        getListCausaNascoste()
    },[open])



    return (
        <>
        <Popup
          visible={openD}
          showCloseButton={false}
          width={'60%'}
          height={'60%'}
          showTitle={true}
          title="Tipologie Nascoste"
          
        >
            
            <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
                text: 'Chiudi',
                onClick: ()=>close(),
                 
              }}
          />
          

      
               <ScrollView width='100%' height='100%'>
               <div style={{marginRight:20}}>
                    
               <NewTabella 
                idTabella='Gest_tipolo_nascoste'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                //filterRow
                //searchPanel={true}
                //onclickRow={(datiRow:any)=>(datiRow.data?.id)}          
                exportT={{
                    enabled: false,
                    fileName: "gest_tipo_nascon"
                }}
                
                //columnC={true}
                height='60vh'
                
            />   
            </div>          
           
            </ScrollView>
                
        </Popup>
        </>
      )

}
