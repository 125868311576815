import FiltriVerticale, {
    ConvertForSelect,
  } from "../../../dashboards/filters/FiltriVerticaleNewVersione";
  
  export const createItemFilterVendCategory = (newFarmaList: any, category: any, ditte: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },   
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        },       
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },
       
      ]
    )
  }

  export const createItemFilterOperatori = (newFarmaList: any, operatori: any, ditte: any,  category: any  ) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
           type: "selectMultiplaMoreColonNoCodice",
           noId: true,
           id: "operatori",
           label: "Operatori",
           displayExpr: "operatore",
           dataSource: operatori,
           idValueGrid: "operatori" as any,
           allowSelectAll: false
        },
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        }, 
       
       
      ]
    )
  }

  export const createItemFilterAcquiFornitori = (newFarmaList: any, ditte: any,  category: any  ) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        },    
      ]
    )
  }

  export const createItemFilterProdFermi = (newFarmaList: any, ditte: any  ) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },  
      ]
    )
  }

  export const createItemFilterAnalSconti = (newFarmaList: any,  operatori: any, category: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },  
        {
          type: "selectMultiplaMoreColonNoCodice",
          noId: true,
          id: "operatori",
          label: "Operatori",
          displayExpr: "operatore",
          dataSource: operatori,
          idValueGrid: "operatori" as any,
          allowSelectAll: false
        },
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        }    
        
      ]
    )
  }

  export const createItemFilterAnalRicetteVendite = (newFarmaList: any, tipologiaVendita: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "selectWithLevels",
          id: "venditaSSN",
          label: "Tipologia di vendita",
          noId: true,
          displayExpr: "descrizione",
          dataSource: tipologiaVendita,
          idValueGrid: "venditaSSN" as any,
        },
  
      ]
    )
  }

  export const createItemFilterAnalVendSintetica = (newFarmaList: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        }
  
      ]
    )
  }

  export const createItemFilterCruscGruppo = (newFarmaList: any) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "ckBox",
          idValue: "venditaDPC",
          label: "Includi DPC",
          dataSource: null,
          width: 200,
        },
        {
          type: "ckBox",
          idValue: "flagBusta",
          label: "Includi BUSTE",
          dataSource: null,
          width: 200,
        },
       
      ]
    )
  }

  export const createItemFilterMercato = (newFarmaList: any  ) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        }, 
      ]
    )
  }

  export const createItemFilterAnalVenditeFarm = (newFarmaList: any, ditte: any,  category: any  ) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },
        {
          type: "selectWithLevels",
          noId: true,
          id: "category",
          label: "Category",
          displayExpr: "descrizione",
          dataSource: category,
          idValueGrid: "category" as any,
        },    
      ]
    )
  }

  export const createItemFilterAnalVendGiorDitta = (newFarmaList: any, ditte: any ) => {
    return (
      [
        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
          type: "newSelectorFarma",
          idValue: "idFar",
          label: "Farmacie ",
          displayExpr: "text",
          dataSource: ConvertForSelect(newFarmaList),
          idValueGrid: "idFar",
          require: true,
        },
        {
          type: "selectMultiplaMoreColon",
          id: "ditta",
          label: "Ditta",
          displayExpr: "descrizione",
          dataSource: ditte,
          idValueGrid: "ditta" as any,
        },
            
      ]
    )
  }