import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import DateBox from "devextreme-react/date-box";
import Button from "react-bootstrap/Button";
import notify from "devextreme/ui/notify";

import "./styleNew.css";
import { ChiamataApi } from "../../../widget/nuova_ricerca/serviceAuto";
import {
  SelezioneMultipla,
  SelezioneMultiplaPlusColon,
  SelezioneMultiplaGerarchicaNew,
  SelezioneMultiplaPlusColonNew,
  TendinaFarmNew,
  SelezioneCheckBox,
  SelezioneMultiplaPlusColonNoCodice,
  SelezioneFarvima,
} from "../../../widget/Select";

import { OggettoInterface } from "../../../interfaces/generiche";
import { Filtri } from "../../consultazione/interface";
import { Col } from "react-bootstrap";
import { CreaColonne } from "../../../funzioni/operazioniSuTabelle";

import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
} from "devextreme-react/data-grid";

const fruits = ["Apples", "Oranges", "Lemons", "Pears", "Pineapples"];
const gridColumns = ["id", "descrizione"];
const ownerLabel = { "aria-label": "Owner" };

interface RicercaProps {
  items: any;
  pop?: boolean;
  advace?: boolean;
  async?: (newFiltro: object) => void;
  ricerca: (newFiltro: object) => void;
  loadingc?: boolean;
  paramsFilter: Filtri | undefined;
}

const dtaformattazione = (data:Date) =>{
  // Ottiene l'anno, il mese e il giorno
 const anno = data.getFullYear();
 const mese = String(data.getMonth() + 1).padStart(2, '0'); // getMonth() parte da 0!
 const giorno = String(data.getDate()).padStart(2, '0');
// Concatena in formato yyyy/MM/dd
 const dataFormattata = `${anno}${mese}${giorno}`;
 return(dataFormattata)
}

export const ConvertForSelect = (arr: any) => {
  let newArr: any = [];
  arr.forEach((element: any) => {
    newArr.push({
      DESCRIZIONE: element.ragioneSociale,
      isSelected: element.isSelected ? true : false,
      ID_FAR: element.idFar,
      CODICE: element.codice,
    });
  });
  return newArr;
};

export const FarmDropdown: React.FC<RicercaProps> = React.memo(
  ({ items, ricerca, async, advace, loadingc, paramsFilter, pop }) => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const [objPostRicerca, setObjPostRicerca] = useState<any>({});
    const [open, setOpen] = useState<boolean>(true);
    const [autoComp, setAutoComp] = useState<any>([]);
    const [loading, setLoading] = useState<any>({});
    const [openMore, setOpenMore] = useState<boolean>(false);
    const [loadingcx, setLoadingcx] = useState<any>(false);
    const [isFilterVisible, setFilterVisible] = useState<boolean>(true);
    const [reset, setReset] = useState<boolean>(false);
    const [dall, setDall] = useState<Date>(firstDayOfMonth);
    const [all, setAll] = useState<Date>(today);
    const [filterValues, setFilterValues] = useState<Filtri>(
      paramsFilter || {}
    );

    const ckB = useRef<any>(null);

    useEffect(() => {
      if (reset) {
        //ckB.current.instance.option('value', null);
        //ckB.current.instance.option('value', false);
      }
    }, [reset]);

    /*
    test
    */

    const dataGridOnSelectionChanged = (e: any) => {
      //alert('pp')
      let descrixioneTouse: string[] = [];

      e.selectedRowKeys.forEach((elem: any) => {
        //console.log('elemento');

        //console.log(elem);
        descrixioneTouse.push(elem.id);
      });

      //console.log('multy array desc');
      //console.log(descrixioneTouse);

      setGridBoxValue(descrixioneTouse);

      //setIsGridBoxOpened({});

      if (e.selectedRowKeys.length > 0) {
        populateObjPost("ditta", descrixioneTouse);
      } else {
        populateObjPost("ditta", []);
      }
    };

    const [gridBoxValue, setGridBoxValue] = useState<any>();

    const [dataSource, setDataSource] = useState(fruits);

    const dataGridRender = (grt: any, sected: any[]) => {
      console.log("nooooo sto facendo");

      return (
        <div>
          {gridBoxValue?.map(
            (
              x:
                | boolean
                | React.ReactChild
                | React.ReactFragment
                | React.ReactPortal
                | null
                | undefined
            ) => {
              return <p>{x}</p>;
            }
          )}
         
          <DataGrid
            dataSource={grt}
            columns={gridColumns}
            //selectedRowKeys={gridBoxValue ? [...gridBoxValue] : }
            hoverStateEnabled={true}
            onSelectionChanged={dataGridOnSelectionChanged}
            height={345}
          >
            <Selection mode="multiple" />
            <Scrolling mode="virtual" />
            <Paging enabled={true} pageSize={10} />
            <FilterRow visible={true} />
          </DataGrid>
        </div>
      );
    };

    const [periodo, setPeriodo] = useState({
      dal: firstDayOfMonth,
      al: today,
    });

    useEffect(() => {
      setLoadingcx(loadingc);
    }, [loadingc]);

    useEffect(() => {
      console.log("sono curioso");
      console.log(paramsFilter);
      //('cambiato')

      setFilterValues(paramsFilter || {});
    }, [paramsFilter]);

    useEffect(() => {
      populateObjPost("idFar", filterValues.idFar);
      populateObjPost("codiceSettore", filterValues.codiceSettore);
      populateObjPost("giorniSettimana", filterValues.giorniSettimana);
      populateObjPost("progetto", filterValues.progetto);
      populateObjPost("societa", filterValues.societa);
      populateObjPost("categoria", filterValues.categoria);
      populateObjPost("segmento", filterValues.segmento);
      populateObjPost("blocco1", filterValues.blocco1);
      populateObjPost("blocco2", filterValues.blocco2);
      populateObjPost("gruppoMerc", filterValues.gruppoMerc);
      populateObjPost("atc", filterValues.atc);
      populateObjPost("category", filterValues.category);
      populateObjPost("venditaSSN", filterValues.venditaSSN);
      populateObjPost("ditta", filterValues.ditta);
      populateObjPost("operatori", filterValues.operatori);
      populateObjPost("tipologiaConFascia", filterValues.tipologiaConFascia);
      populateObjPost("tipologiaProdotto ", filterValues.tipologiaProdotto);
      populateObjPost("prodotto", filterValues.prodotto);
      populateObjPost("fascia", filterValues.fascia);
      populateObjPost("tipologiaSconto", filterValues.tipologiaSconto);
      populateObjPost("dal", filterValues.dal);
      populateObjPost("al", filterValues.dal);
      populateObjPost("tipoRaggruppamento", filterValues.tipoRaggruppamento);
      populateObjPost("sesso", filterValues.sesso);
      populateObjPost("eta", filterValues.eta);
      populateObjPost("flagGenerico", filterValues.flagGenerico);
      populateObjPost("statoRicette", filterValues.statoRicette);

    }, []);

    useEffect(() => {
      populateObjPost("periodo", periodo);
    }, [periodo]);

    useEffect(() => {
      items.forEach((element: any) => {
        if (element.idValue == "idFar" && element.dataSource.length == 1) {
          let objPostRicercaN = { ...objPostRicerca };
          objPostRicercaN.idFar = element.dataSource[0];
          setObjPostRicerca(objPostRicercaN);
        }
      });
      /*objPostRicerca.dal = new Date()
        setObjPostRicerca(objPostRicerca)*/
      items.forEach((element: any) => {
        if (element.defValue) {
          objPostRicerca[element.idValue] = element.defValue;
          setObjPostRicerca(objPostRicerca);
        }
      });
    }, []);

    //azione boh
    const actions = async (string: string, typeApi: string, idVal?: string) => {
      let loadingg = { ...loading };
      if (idVal) {
        loadingg[idVal] = true;
        setLoading(loadingg);
      }

      let risulatoCall = await ChiamataApi(string, typeApi);
      if (risulatoCall) {
        if (risulatoCall?.esito) {
          //console.log(risulatoCall.response);
          setAutoComp(risulatoCall.response);
          if (idVal) {
            loadingg[idVal] = false;
            setLoading(loadingg);
          }
        } else {
          if (idVal) {
            loadingg[idVal] = false;
            setLoading(loadingg);
          }
          notify(
            {
              position: "center",
              width: "auto",
              message: risulatoCall.message,
            },
            "error",
            4000
          );
        }
      }
    };

    const remove = (id: string) => {
      let nuovoObj = { ...objPostRicerca };
      delete nuovoObj[id];
      setObjPostRicerca(nuovoObj);
    };

    //cos'è non ricordo
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        setFilterValues(paramsFilter || {});

        if (newFiltro.farmacie !== undefined) {
            populateObjPost("idFar", newFiltro.farmacie);
        }

      if (newFiltro.codiceSettore !== undefined) {
        populateObjPost("codiceSettore", newFiltro.codiceSettore);
      }

      if (newFiltro.progetto !== undefined) {
        populateObjPost("progetto", newFiltro.progetto);
      }

      if (newFiltro.societa !== undefined) {
        populateObjPost("societa", newFiltro.societa);
      }

      if (newFiltro.fascia !== undefined) {
        populateObjPost("fascia", newFiltro.fascia);
      }

      if (newFiltro.segmento !== undefined) {
        populateObjPost("segmento", newFiltro.segmento);
      }
      if (newFiltro.categoria !== undefined) {
        populateObjPost("categoria", newFiltro.categoria);
      }

      if (newFiltro.blocco1 !== undefined) {
        populateObjPost("blocco1", newFiltro.blocco1);
      }

      if (newFiltro.blocco2 !== undefined) {
        populateObjPost("blocco2", newFiltro.blocco2);
      }

      if (newFiltro.gruppoMerc !== undefined) {
        populateObjPost("gruppoMerc", newFiltro.gruppoMerc);
      }

      if (newFiltro.venditaSSN !== undefined) {
        populateObjPost("venditaSSN", newFiltro.venditaSSN);
      }

      if (newFiltro.atc !== undefined) {
        populateObjPost("atc", newFiltro.atc);
        console.log(newFiltro.atc);
      }

      if (newFiltro.category !== undefined) {
        populateObjPost("category", newFiltro.category);
        console.log(newFiltro.category);
      }

      if (newFiltro.ditta !== undefined) {
        populateObjPost("ditta", newFiltro.ditta);
      }

      if (newFiltro.operatori !== undefined) {
        populateObjPost("operatori", newFiltro.operatori);
      }

      if (newFiltro.tipologiaConFascia !== undefined) {
        populateObjPost("tipologiaConFascia", newFiltro.tipologiaConFascia);
      }

      if (newFiltro.tipologiaProdotto !== undefined) {
        populateObjPost("tipologiaProdotto", newFiltro.tipologiaProdotto);
      }

      if (newFiltro.prodotto !== undefined) {
        populateObjPost("prodotto", newFiltro.prodotto);
      }

      if (newFiltro.fascia !== undefined) {
        populateObjPost("fascia", newFiltro.fascia);
      }

      if (newFiltro.tipologiaSconto !== undefined) {
        populateObjPost("tipologiaSconto", newFiltro.tipologiaSconto);
      }


        if (newFiltro.giorniSettimana !== undefined) {
            populateObjPost("giorniSettimana", newFiltro.giorniSettimana);
        }

      if (newFiltro.periodo !== undefined) {
        populateObjPost("dal", periodo.dal);
        populateObjPost("al", periodo.al);
      }

      if (newFiltro.tipoRaggruppamento !== undefined) {
         populateObjPost("tipoRaggruppamento", newFiltro.tipoRaggruppamento)
      }
      if(newFiltro.sesso !== undefined) {
            populateObjPost("sesso", newFiltro.sesso)
      }
      if (newFiltro.eta !== undefined) {
            populateObjPost("eta", newFiltro.eta)
        }
        if (newFiltro.flagGenerico !== undefined) {
            populateObjPost("flagGenerico", newFiltro.flagGenerico)
        }

        if (newFiltro.statoRicette !== undefined) {
            populateObjPost("statoRicette", newFiltro.statoRicette)
        }

     // console.log(newFiltro.periodo);
    };

    //funxione setta oggetto post
    const populateObjPost = (id: string, value: any) => {
      let nuovoObj = { ...objPostRicerca };
      //console.log('***');

      //console.log(nuovoObj);
      nuovoObj[id] = value;
      if (objPostRicerca.type == "autocomplete") {
      }
      if (async && id == "idFar") {
        async(nuovoObj);
      }
      if (reset && id == "idFar") {
        let nuovoObj = { ...objPostRicerca };
        nuovoObj[id] = [];
      }

      setObjPostRicerca(nuovoObj);
    };

    const resetFArma = () => {
      let nuovoObj = { ...objPostRicerca };
      delete nuovoObj["idFar"];
      items.forEach((element: any) => {
        if (element.type != "dateRange") {
          console.log(element.id);
          let toRemove = element.id;
          delete nuovoObj[toRemove];
        }
      });
      setObjPostRicerca(nuovoObj);

      setDall(firstDayOfMonth);
      setAll(today);

      setPeriodo((prevPeriodo) => ({
        ...prevPeriodo,
        al: today,
      }));

      setPeriodo((prevPeriodo) => ({
        ...prevPeriodo,
        dal: firstDayOfMonth,
      }));
    };

    //crea input
    const createInput = (obj: any, index: number) => {
      switch (obj.type) {
        case "newSelectorFarma":
          return (
            <>
              <Col sm="6">
                <label style={{ marginLeft: "-14px" }}>
                  {obj.label} {obj.require ? <span>*</span> : null}
                </label>
              </Col>
              {/*JSON.stringify(filterValues.idFar)*/}
              <TendinaFarmNew
                valueGrid={filterValues.idFar}
                id="farmacie"
                datiFarmacie={obj.dataSource}
                impostaFiltri={impostaFiltri}
                tutteSelezionate={
                  !objPostRicerca?.idFar ||
                  !filterValues.idFar ||
                  JSON.stringify(filterValues.idFar) === "{}"
                    ? true
                    : false
                }
                showOnlyCod={true}
              />
            </>
          );
        case "dateRange":
          return (
            <div>
              <div
                style={{ width: obj.width ? obj.width : null, display: "flex" }}
              >
                <div style={{ marginRight: "10px" }}>
                  <label htmlFor="dal">
                    Dal {obj.require ? <span>*</span> : null}
                  </label>

                  <DateBox
                    id="dal"
                    showClearButton={true}
                    onValueChanged={(v) => {
                      console.log(filterValues);
                      setDall(new Date(v.value));
                      setPeriodo((prevPeriodo) => {
                        console.log("mostro dal");
                        console.log(v.value);

                       
                      
                      

                        return {
                          ...prevPeriodo,
                          dal: v.value,
                        };
                      });
                    }}
                    //defaultValue={firstDayOfMonth}
                    value={dall}
                    type="date"
                    displayFormat="dd/MM/yyyy"
                  />
                </div>
                <div>
                  <label htmlFor="al">
                    Al {obj.require ? <span>*</span> : null}
                  </label>

                  <DateBox
                    id="al"
                    showClearButton={true}
                    onValueChanged={(v) => {
                      console.log(new Date(v.value));

                      setAll(new Date(v.value));
                      setPeriodo((prevPeriodo) => ({
                        ...prevPeriodo,
                        al: v.value,
                      }));
                    }}
                    //defaultValue={today}
                    value={all}
                    type="date"
                    displayFormat="dd/MM/yyyy"
                  />
                </div>
              </div>
            </div>
          );
        case "selectMultipla":
          return (
            <>
              <Col sm="6">
                <label style={{ marginLeft: "-14px" }}>{obj.label}</label>
              </Col>
              <SelezioneMultipla
                id={obj.id}
                dataSource={obj.dataSource}
                impostaFiltri={impostaFiltri}
                column={colonneGridGeneriche}
                selection="multiple"
                primaSelezionata={false}
                reset={reset}
                valueGrid={
                  obj.id === "atc"
                    ? filterValues.atc
                    : obj.id === "category"
                    ? filterValues.category
                    : obj.id === "venditaSSN"
                    ? filterValues.venditaSSN
                    : obj.id === "codiceSettore"
                    ? filterValues.codiceSettore
                    : obj.id === "progetto"
                    ? filterValues.progetto
                    : obj.id === "societa"
                    ? filterValues.societa
                    : obj.id === "categoria"
                    ? filterValues.categoria
                    : obj.id === "segmento"
                    ? filterValues.segmento
                    : obj.id === "blocco1"
                    ? filterValues.blocco1
                    : obj.id === "blocco2"
                    ? filterValues.blocco2
                    : obj.id === "gruppoMerc"
                    ? filterValues.gruppoMerc
                    : obj.id === "operatori"
                    ? filterValues.operatori
                    : obj.id === "tipologiaConFascia"
                    ? filterValues.tipologiaConFascia
                    : obj.id === "tipologiaProdotto"
                    ? filterValues.tipologiaProdotto
                    : obj.id === "prodotto"
                    ? filterValues.prodotto
                    : obj.id === "fascia"
                    ? filterValues.fascia
                    : obj.id === "ditta"
                    ? filterValues.ditta
                    : obj.id === "tipoRaggruppamento"
                    ? filterValues.tipoRaggruppamento
                    : obj.id === "giorniSettimana"
                    ? filterValues.giorniSettimana
                    : obj.id === "sesso"
                    ? filterValues.sesso
                    : obj.id === "flagGenerico"
                    ? filterValues.flagGenerico
                    : obj.id === "statoRicette"
                    ? filterValues.statoRicette
                    : obj.idValueGrid
                
                    
                   
                }
              />
            </>
          );
        case "selectMultiplaMoreColonNewV":
          return (
            <>
              <Col sm="6">
                <label style={{ marginLeft: "-14px" }}>{obj.label}</label>
              </Col>
              <SelezioneMultiplaPlusColonNew
                id={obj.id}
                dataSource={obj.dataSource}
                impostaFiltri={impostaFiltri}
                column={colonneGridGeneriche}
                selection="multiple"
                primaSelezionata={false}
                reset={reset}
                valueGrid={
                  obj.id === "atc"
                    ? filterValues.atc
                    : obj.id === "category"
                    ? filterValues.category
                    : obj.id === "venditaSSN"
                    ? filterValues.venditaSSN
                    : obj.id === "codiceSettore"
                    ? filterValues.codiceSettore
                    : obj.id === "progetto"
                    ? filterValues.progetto
                    : obj.id === "societa"
                    ? filterValues.societa
                    : obj.id === "categoria"
                    ? filterValues.categoria
                    : obj.id === "segmento"
                    ? filterValues.segmento
                    : obj.id === "blocco1"
                    ? filterValues.blocco1
                    : obj.id === "blocco2"
                    ? filterValues.blocco2
                    : obj.id === "gruppoMerc"
                    ? filterValues.gruppoMerc
                    : obj.id === "operatori"
                    ? filterValues.operatori
                    : obj.id === "tipologiaConFascia"
                    ? filterValues.tipologiaConFascia
                    : obj.id === "tipologiaProdotto"
                    ? filterValues.tipologiaProdotto
                    : obj.id === "prodotto"
                    ? filterValues.prodotto
                    : obj.id === "fascia"
                    ? filterValues.fascia
                    : obj.id === "ditta"
                    ? filterValues.ditta
                    : obj.idValueGrid
                }
              />
            </>
              );
          case "selectFarvima":
              return (
                  <>
                      <Col sm="6">
                          <label style={{ marginLeft: "-14px" }}>{obj.label}</label>
                      </Col>
                      <SelezioneFarvima
                          id={obj.id}
                          newFarms={obj.farvimaPharmaciesToSet}
                          request={obj.request}
                          dataSource={obj.dataSource}
                          impostaFiltri={impostaFiltri}
                          column={colonneGridGeneriche}
                          selection="multiple"
                          primaSelezionata={false}
                          reset={reset}
                          valueGrid={
                          obj.id === "progetto"
                          ? filterValues.progetto
                          : obj.id === "societa"
                          ? filterValues.societa
                          : obj.idValueGrid
                          }
                      />
                  </>
              );
       case "selectMultiplaMoreColon": 
          return (
            <>
              <Col sm="6">
                <label style={{ marginLeft: "-14px" }}>{obj.label}</label>
              </Col>
              <SelezioneMultiplaPlusColon
                id={obj.id}
                dataSource={obj.dataSource}
                impostaFiltri={impostaFiltri}
                column={colonneGridGeneriche}
                selection="multiple"
                primaSelezionata={false}
                allowSelectAll={obj.allowSelectAll}
                reset={reset}
                valueGrid={
                  obj.id === "atc"
                    ? filterValues.atc
                    : obj.id === "category"
                    ? filterValues.category
                    : obj.id === "venditaSSN"
                    ? filterValues.venditaSSN
                    : obj.id === "codiceSettore"
                    ? filterValues.codiceSettore
                    : obj.id === "progetto"
                    ? filterValues.progetto
                    : obj.id === "societa"
                    ? filterValues.societa
                    : obj.id === "categoria"
                    ? filterValues.categoria
                    : obj.id === "segmento"
                    ? filterValues.segmento
                    : obj.id === "blocco1"
                    ? filterValues.blocco1
                    : obj.id === "blocco2"
                    ? filterValues.blocco2
                    : obj.id === "gruppoMerc"
                    ? filterValues.gruppoMerc
                    : obj.id === "operatori"
                    ? filterValues.operatori
                    : obj.id === "tipologiaConFascia"
                    ? filterValues.tipologiaConFascia
                    : obj.id === "tipologiaProdotto"
                    ? filterValues.tipologiaProdotto
                    : obj.id === "prodotto"
                    ? filterValues.prodotto
                    : obj.id === "fascia"
                    ? filterValues.fascia
                    : obj.id === "ditta"
                    ? filterValues.ditta
                    : obj.id === "eta"
                    ? filterValues.eta
                    : obj.idValueGrid
                }
              />
            </>
              );
          case "selectMultiplaMoreColonNoCodice":
              return (
                  <>
                      <Col sm="6">
                          <label style={{ marginLeft: "-14px" }}>{obj.label}</label>
                      </Col>
                      <SelezioneMultiplaPlusColonNoCodice
                          id={obj.id}
                          dataSource={obj.dataSource}
                          impostaFiltri={impostaFiltri}
                          column={colonneGridGeneriche}
                          selection="multiple"
                          primaSelezionata={false}
                          allowSelectAll={obj.allowSelectAll}
                          reset={reset}
                          valueGrid={
                          obj.id === "atc"
                          ? filterValues.atc
                          : obj.id === "category"
                          ? filterValues.category
                          : obj.id === "venditaSSN"
                          ? filterValues.venditaSSN
                          : obj.id === "codiceSettore"
                          ? filterValues.codiceSettore
                          : obj.id === "progetto"
                          ? filterValues.progetto
                          : obj.id === "societa"
                          ? filterValues.societa
                          : obj.id === "categoria"
                          ? filterValues.categoria
                          : obj.id === "segmento"
                          ? filterValues.segmento
                          : obj.id === "blocco1"
                          ? filterValues.blocco1
                          : obj.id === "blocco2"
                          ? filterValues.blocco2
                          : obj.id === "gruppoMerc"
                          ? filterValues.gruppoMerc
                          : obj.id === "operatori"
                          ? filterValues.operatori
                          : obj.id === "tipologiaConFascia"
                          ? filterValues.tipologiaConFascia
                          : obj.id === "tipologiaProdotto"
                          ? filterValues.tipologiaProdotto
                          : obj.id === "prodotto"
                          ? filterValues.prodotto
                          : obj.id === "fascia"
                          ? filterValues.fascia
                          : obj.id === "ditta"
                          ? filterValues.ditta
                          : obj.id === "eta"
                          ? filterValues.eta
                          : obj.idValueGrid
                          }
                      />
                  </>
              );
        case "selectWithLevels":
          return (
            <>
              <Col sm="6">
                <label style={{ marginLeft: "-14px" }}>{obj.label}</label>
              </Col>
              <SelezioneMultiplaGerarchicaNew
                id={obj.id}
                noId={obj.noId}
                dataSource={obj.dataSource}
                selectNodesRecursive={true}
                selectByClick={false}
                showCheckBoxesMode="normal"
                selectionMode="multiple"
                impostaFiltri={impostaFiltri}
                reset={reset}
                valueGrid={
                  obj.id === "atc"
                    ? filterValues.atc
                    : obj.id === "category"
                    ? filterValues.category
                    : obj.id === "venditaSSN"
                    ? filterValues.venditaSSN
                    : obj.id === "codiceSettore"
                    ? filterValues.codiceSettore
                    : obj.id === "progetto"
                    ? filterValues.progetto
                    : obj.id === "societa"
                    ? filterValues.societa
                    : obj.id === "categoria"
                    ? filterValues.categoria
                    : obj.id === "segmento"
                    ? filterValues.segmento
                    : obj.id === "blocco1"
                    ? filterValues.blocco1
                    : obj.id === "blocco2"
                    ? filterValues.blocco2
                    : obj.id === "gruppoMerc"
                    ? filterValues.gruppoMerc
                    : obj.id === "operatori"
                    ? filterValues.operatori
                    : obj.id === "tipologiaConFascia"
                    ? filterValues.tipologiaConFascia
                    : obj.id === "tipologiaProdotto"
                    ? filterValues.tipologiaProdotto
                    : obj.id === "prodotto"
                    ? filterValues.prodotto
                    : obj.id === "fascia"
                    ? filterValues.fascia
                    : obj.id === "giorniSettimana"
                    ? filterValues.giorniSettimana
                    : obj.idValueGrid
                }
              />
            </>
          );
        case "ckBox":
          return (
            <div style={{ width: obj.width ? obj.width : null }}>
              <label>
                {obj.label} {obj.require ? <span>*</span> : null}
              </label>
              <br></br>
              <SelezioneCheckBox
                reset={reset}
                //defaultValue={obj.defValue ? true : false}
                onValueChanged={(v) => populateObjPost(obj.idValue, v.value)}
              />
            </div>
          );
        default:
          break;
      }
    };

    const colonneGridGeneriche = CreaColonne([
      { dataField: "id", caption: "id", visible: false },
      {
        dataField: "descrizione",
        caption: "Descrizione",
        sortOrder: "asc",
      },
    ]);

    const azzera = () => {
      setReset(true);

      //populateObjPost("dal", filterValues.dal);
      //populateObjPost("al", filterValues.dal);

      //setFilterVisible(false)
      setTimeout(function () {
        setReset(false);
        console.log("mostro v");

        console.log(filterValues);

        console.log("itemsss");

        console.log(items);
        let darmacieAll: any[] = [];
        items.forEach((ite: any) => {
          if (ite.type == "newSelectorFarma") {
            ite.dataSource.forEach((e: any) => {
              darmacieAll.push(e.ID_FAR);
            });
          }
        });
        console.log("farma");

        console.log(darmacieAll);

        populateObjPost("idFar", []);
        populateObjPost("codiceSettore", []);
        populateObjPost("gruppoMerc", []);
        populateObjPost("atc", []);
        populateObjPost("category", []);
        populateObjPost("venditaSSN", []);
        populateObjPost("ditta", []);
        populateObjPost("operatori", []);
        populateObjPost("tipologiaConFascia", []);
        populateObjPost("tipologiaProdotto", []);
        populateObjPost("prodotto", []);
        populateObjPost("fascia", []);
        populateObjPost("tipologiaSconto", []);
        populateObjPost("progetto", []);
        populateObjPost("societa", []);
        populateObjPost("categoria", []);
        populateObjPost("segmento", []);
        populateObjPost("blocco1", []);
        populateObjPost("blocco2", []);
        populateObjPost("tipoRaggruppamento", []);
        populateObjPost("giorniSettimana", []);
        populateObjPost("sesso", []);
        populateObjPost("eta", []);
        populateObjPost("flagGenerico", []);
        populateObjPost("statoRicette", []);
        resetFArma();
        //setFilterVisible(true)
      }, 500);
    };

    return (
      <div
        className="containerFilter"
        style={{ height: pop ? "80vh" : "auto", background: "white" }}
      >
        <div className="containButton">
          <div className="buttonCerca">
            <Button
              id="btnCercax"
              style={{ fontWeight: "bold", fontSize: "14px" }}
              className="btn btn-success"
              variant="success"
              onClick={() => {
               // console.log("mando questo oggetto");

                // console.log(objPostRicerca);
                if(objPostRicerca.periodo.dal && objPostRicerca.periodo.dal instanceof Date){
                  objPostRicerca.periodo.dal =   dtaformattazione(objPostRicerca.periodo.dal);
                }
                if(objPostRicerca.periodo.al && objPostRicerca.periodo.al instanceof Date){
                  objPostRicerca.periodo.al =  dtaformattazione(objPostRicerca.periodo.al);
                }

                //console.log('farmacie:');
               // console.log(objPostRicerca.idFar);
                
                
                ricerca(objPostRicerca);
              }}
            >
              CERCA
            </Button>

            <Button
              className="btn btn-success"
              variant="success"
              style={{ fontWeight: "bold", fontSize: "14px" }}
              onClick={() => {
                azzera();
              }}
            >
              AZZERA
            </Button>
          </div>
        </div>

        {isFilterVisible && (
          <div
            style={{ overflowY: "scroll" }}
            className={open ? "main" : "mainOut"}
          >
            <div
              style={{ position: "relative", width: "100%" }}
              className="gridFilterRicerca"
            >
              <div className="filters-container">
                {items.map((inputObj: any, index: number) => {
                  if (advace) {
                    if (openMore) {
                      return (
                        <div key={index}>{createInput(inputObj, index)}</div>
                      );
                    } else {
                      if (inputObj.advace) {
                        return null;
                      } else {
                        return (
                          <div key={index}>{createInput(inputObj, index)}</div>
                        );
                      }
                    }
                  } else {
                    return (
                      <div key={index}>{createInput(inputObj, index)}</div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default FarmDropdown;

function chroma(color: any) {
  throw new Error("Function not implemented.");
}
