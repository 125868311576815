import { useState, useEffect } from "react";
import { ListaFarmacieInterface } from "../interfaces/farmacie";
import { RestServices } from "../services/restServices";
import { urlFarmGruppo, urlFarmMongo  } from '../contenuti/costanti'

export function useDatiFarm() {
    const [datiFarmacie, setDatiFarmacie] = useState<ListaFarmacieInterface[]>();

    //lo useEffect con [] fa sì che la chiamata venga fatta una sola volta al caricamento della pagina
    useEffect(() => {
        RestServices().rest('POST', "", urlFarmGruppo)
            .then(result => {
                if (result) {
                    setDatiFarmacie(result);
                }
            })
    }, [])

    return datiFarmacie;
}

export function useFarmMongo() {
    const [datiFarmMongo, setDatiFarmMongo] = useState<ListaFarmacieInterface[]>();

    //lo useEffect con [] fa sì che la chiamata venga fatta una sola volta al caricamento della pagina
    useEffect(() => {
        RestServices().rest('POST', "", urlFarmMongo.getFarm)
            .then(result => {
                if (result) {
                    setDatiFarmMongo(result);
                }
            })
    }, []);
    return datiFarmMongo;
}