import notify from "devextreme/ui/notify";
import React, { useEffect } from "react";
import { useState } from "react";
import { RestServices } from "../../services/restServices";
import { Loading } from "../../widget/Notifications";
import { basenameHost, urlAnagrafiche } from "../costanti";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { ImmagineNewsletter} from "../../interfaces/newsletter";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FileUploader } from "devextreme-react";
import Button from 'react-bootstrap/esm/Button';
import { DialogUploadFileNewsletter } from "./Dialog";
import { alert } from "devextreme/ui/dialog"


const ElencoFileNewsletter = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        startCerca();
    }, []);

    const titolo = TitoloContenuto("Elenco File Newsletter");
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoImmaginiNewsletter, setElencoImmaginiNewsletter] = useState<ImmagineNewsletter[]>();
    const [isDialogUploadFileNewsletterOpen, setIsDialogUploadFileNewsletterOpen] = useState(false);
    const handleCloseDialogUploadFileNewsletter = () => { setIsDialogUploadFileNewsletterOpen(false); startCerca(); }

    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        RestServices().rest('POST', param, urlAnagrafiche.getElencoImmaginiNewsletter)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setElencoImmaginiNewsletter(result)
                    } else {
                        setElencoImmaginiNewsletter(result);
                    }
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const propsTabElencoNewsletter: TabellaCompletaInterface = {
        id: "elenco_newsletter",
        dataSource: elencoImmaginiNewsletter,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {

            totalItems: [{
                name: "TotaleImmaginiNewsletter",
                showInColumn: "Id Newsletter",
                displayFormat: "Newsletter: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoImmaginiNewsletter"
        }
    }


    const columnsNewsletter = CreaColonne([
        { caption: "Id", dataField: "_id", visible: false, width: 120 },
        { caption: "Nome file", dataField: "nomeFile", visible: true, width: 400 },
        { caption: "Token file", dataField: "fileToken", visible: false, width: 400 },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "asc" },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Visualizza file",
                icon: "file",
                onClick: (e: any) => {
                    const fileToken = e.row.data.fileToken;
                    window.open(basenameHost + 'cfportal/view/' + fileToken, "_blank");
                }
            },
                {
                    hint: "Visualizza url",
                    icon: "copy",
                    onClick: (e: any) => {
                        const fileToken = e.row.data.fileToken;
                        alert(basenameHost + 'cfportal/view/' + fileToken, 'Url file');
                    }
                }
            ]
        }
        
    ])


    const uploadFileNewsletter = () => {
        setIsDialogUploadFileNewsletterOpen(true);
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="rightcontent">
                <Button id="btnUploadImmagineNewsLetter" variant="primary" onClick={uploadFileNewsletter}>Upload file</Button>
            </div>
            <div className="contenutoPagina">
                <Row className="tabellaImmagini">
                    <Col sm="12">
                        <TabellaCompleta
                            propsTabella={propsTabElencoNewsletter}
                            columns={columnsNewsletter}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={false}
                            key={"idNewsletter"}
                        />
                    </Col>
                </Row>

                <DialogUploadFileNewsletter
                    isDialogUploadFileNewsletter={isDialogUploadFileNewsletterOpen}
                    setIsDialogUploadFileNewsletter={setIsDialogUploadFileNewsletterOpen}
                    handleCloseDialogUploadFileNewsletter={handleCloseDialogUploadFileNewsletter}
                    setIsVisible={setIsVisible}
                />

            </div>
        </>
    )

}

export default ElencoFileNewsletter;