import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { PieChart } from "devextreme-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from '../filters/FiltriVerticaleNewVersione';
import { Filtri } from "../../consultazione/interface";
import { testApi } from "../util/testRisp";
import { MenuStatistiche } from "../components/menu_reportistica";
import { createItemFilterAnalTrasfStat } from "./Filtri/FiltriReportistica";
import {
    urlBancaDati,
    urlConsultazione,
    urlReport,
} from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Chart } from "devextreme-react";
import {
    Animation,
    ArgumentAxis,
    CommonSeriesSettings,
    Export,
    HoverStyle,
    Label,
    Legend,
    Series,
    Subtitle,
    Title,
    Tooltip,
    ValueAxis,
    Font
} from "devextreme-react/chart";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";

import "./style.css";

const dashboard5 = { dashboardID: 5 };

export interface boxCard {
    testoPop?: string;
}

export interface boxsecond {
    testoPop?: string;
}


const AnalTrasferimenti = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [isPanelOpen, setPanelOpen] = useState(false);
    const [valoriBoobble, setValoriBoobble] = useState<any>([]);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [visiblePopOne, setVisiblePopOne] = useState(false);
    const [pie, setPie] = useState<any[]>([]);
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);
    const [operatori, setOperatori] = useState<any[]>([]);
    const [category, setCategory] = useState<any[]>([]);
    const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
    const [ditte, setDitte] = useState<any[]>([]);
    const loadPanel = <Loading isVisible={isVisible} />;
    const [visiblePop1, setVisiblePop1] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);


    const togglePanel = () => {
        setPanelOpen(!isPanelOpen);
    };

    function aggiornaOrarioFine(dataFine: any) {
        // Verifica se l'orario della data finale è mezzanotte (00:00)
        const dataFineAggiornata = new Date(dataFine);
        dataFineAggiornata.setHours(23, 59, 59);
        return dataFineAggiornata;
    }

    function aggiornaDataFine(dataFine: Date): Date {
        // Clona la data di partenza per non modificarla direttamente
        const nuovaDataFine = new Date(dataFine);

        // Aggiungi un giorno alla data
        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }


    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];
        let newIDSettore: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        }

        // let venditaDPC = [0];
        // let flagBusta = [0];

        // if (params?.venditaDPC) {
        //     venditaDPC = [1];
        // }
        // if (params?.flagBusta) {
        //     flagBusta = [1];
        // }

        let gruppoMerc1;
        let gruppoMerc2;
        let gruppoMerc3;

        let codAtc1;
        let codAtc2;
        let codAtc3;
        let codAtc4;
        let codAtc5;

        let category1;
        let category2;
        let category3;
        let category4;

        if (params.category) {
            const categoryList = params.category;
            category1 = getCategoryByLevel(categoryList, 2);
            category2 = getCategoryByLevel(categoryList, 4);
            category3 = getCategoryByLevel(categoryList, 6);
            category4 = getCategoryByLevel(categoryList, 8);
        }

        if (params.gruppoMerc) {
            gruppoMerc1 = params.gruppoMerc.filter(
                (item: number) => item % 100 === 0
            );
            gruppoMerc2 = params.gruppoMerc.filter(
                (item: number) => item % 10 === 0 && item % 100 !== 0
            );
            gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
        }

        if (params.atc) {
            const atcList = params.atc;
            codAtc1 = getATCByLevel(atcList, 1);
            codAtc2 = getATCByLevel(atcList, 3);
            codAtc3 = getATCByLevel(atcList, 4);
            codAtc4 = getATCByLevel(atcList, 5);
            codAtc5 = getATCByLevel(atcList, 7);
        }

        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        const parameters = {
            codiceSettore: params.codiceSettore,
            gruppoMerc1: gruppoMerc1,
            gruppoMerc2: gruppoMerc2,
            gruppoMerc3: gruppoMerc3,
            venditaSSN: params.venditaSSN,
            codAtc1: codAtc1,
            codAtc2: codAtc2,
            codAtc3: codAtc3,
            codAtc4: codAtc4,
            codAtc5: codAtc5,
            category1: category1,
            category2: category2,
            category3: category3,
            category4: category4,
            ditta: params.ditta,
            progetto: params.progetto,
            societa: params.societa,
            operatori: params.operatori,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {

            const dashboardCall5 = {
                ...dashboard5,
                idFarList: params.idFar,
                params: parameters,
            };

            // call Global

            RestServices()
                .rest("POST", dashboardCall5, urlReport.getQueryDashboardAllData)
                .then((result) => {

                    if (result) {

                        // ID 37 Boobble
                        setValoriBoobble(result["37"]);


                        // ID 37 PieChart

                        const chartSSNPie = result["37"]
                            .map((item: any) => {
                                const isSSN = item.VEN_FLAG_SSN;
                                const value = item.VALORE_VENDUTO;

                                return {
                                    SSN: isSSN,
                                    VALORE_VENDUTO: value,
                                    SSNDESC: isSSN ? "SSN" : "Libera",
                                };
                            })
                            .reverse();

                        // 1 chartPie ID 37

                        const total = chartSSNPie.reduce(
                            (sum: any, item: { VALORE_VENDUTO: any }) =>
                                sum + item.VALORE_VENDUTO,
                            0
                        );

                        // Aggiunta della percentuale per ogni valore
                        const chartSSNPieConPercentuale = chartSSNPie.map(
                            (item: { VALORE_VENDUTO: number }) => {
                                // Calcolo della percentuale per ogni valore
                                const percentuale = (
                                    (item.VALORE_VENDUTO / total) *
                                    100
                                ).toFixed(2);

                                return {
                                    ...item,
                                    PERCENTUALE: parseFloat(percentuale), // Converti la percentuale in un numero float
                                };
                            }
                        );

                        setPie(chartSSNPieConPercentuale);

                        setIsVisible(false);
                        setParamsFilter(params);
                        setIsFiltersExpanded(false);


                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }

                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        { position: "center", width: "auto", message: "Errore" + error },
                        "error",
                        4000
                    );
                });

            //cambio data
            const inizioAnno = new Date(); // Ottieni la data corrente

            // Imposta la data al 1° gennaio dell'anno corrente
            inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
            inizioAnno.setDate(1); // Imposta il giorno a 1

        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });

        }
    };



    const popVisible1 = (testo: string) => {
        setVisiblePop1(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };
    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    let messOne = ["Analisi CìVed"];
    let messTwo = ["Analisi CìVed 222"];
    let messThree = ["Analisi CìVed 3333"];

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);



    const filters = () => {
        if (auth) {

            RestServices()
                .rest("POST", "", urlBancaDati.getGruppoMerceologico)
                .then((result) => {
                    if (result) setGruppoMercFilters(result);
                });

            RestServices()
                .rest('POST', "", urlBancaDati.getCategory)
                .then(result => {
                    // console.log('vediamoooo categorie');
                    // console.log(result);
                    if (result) setCategory(result);
                })

            RestServices()
                .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
                .then((result) => {
                    if (result.esito) {
                        const operatori = result.response.map(
                            (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                                //console.log(ope.idFarmacia);
                                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                                //console.log(farmaciaCorrispondente);
                                return {
                                    ...ope,
                                    id: `${ope.operatore}`,
                                    descrizione: `${ope.operatore}`,
                                    codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                                };
                            }
                        );
                        setOperatori(operatori);
                        // console.log('operatori');
                        // console.log(operatori);
                    }
                });



            RestServices()
                .rest("POST", "", urlBancaDati.getDitte)
                .then((result) => {
                    if (result) {
                        const ditteWithCodiceDescrizione = result.map(
                            (ditta: { codice: any; descrizione: any }) => {
                                return {
                                    ...ditta,
                                    codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                                };
                            }
                        );
                        setDitte(ditteWithCodiceDescrizione);
                    }
                });

        }
    };

    const itemsFields = createItemFilterAnalTrasfStat(
        newFarmaList,
        operatori,
        category,
        gruppoMercFilters,
        ditte
    );

    useEffect(() => {
        filters();
    }, [auth]);


    const exportFormats = ["csv"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_operatori");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/octet-stream" }),
                    "tabella_operatori.csv"
                );
            });
        });
    };

    function getCategoryByLevel(categoryList: any[], level: number) {
        return categoryList.filter((category) => category.length === level * 1);
    }


    function formatLabel(arg: { argumentText: any; valueText: any }) {
        return `${arg.argumentText}: ${arg.valueText}%`;
    }

    function getATCByLevel(atcList: any[], level: number) {
        return atcList.filter((atc) => atc.length === level * 1);
    }

    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);


    const popVisible = (testo: string) => {
        setVisiblePop(true);
        setTestoPop(testo);
    };
    const popVisibleOne = (testo: string) => {
        setVisiblePopOne(true);
    };



    return (
        <section style={{ display: "flex", height: "98vh" }}>
            {loadPanel}
            {loadinCambio ? (
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <LoadIndicator id="small-indicator" height={50} width={50} />
                </div>
            ) : (
                <section
                    id="scrollerelement"
                    style={{ width: "100%", overflow: "scroll" }}
                >
                    <div
                        style={{
                            fontSize: "15px",
                            alignItems: "left",
                            marginTop: "10px",
                            marginBottom: "10px",
                            color: "rgb(80, 84, 92)",
                        }}
                    >

                            <div>
                                <h2 style={{ display: 'inline-block', marginRight: '20px'}}>BusinessLab - Statistiche </h2>                              
                                <h1 style={{ color: 'rgb(215, 140, 245)', display: 'inline-block' }}>*Pagina in manutenzione*</h1>
                            </div>
                    </div>

                    <div
                        style={{
                            display: "block",
                            gap: 5,
                            padding: 10,
                            borderRadius: 10,
                            marginTop: 10,
                            width: "auto",
                        }}
                    >

                        <div>
                            <MenuStatistiche />
                        </div>

                    </div>

                    <Popup
                        visible={visiblePop}
                        showCloseButton={true}
                        //onHiding={() => doSomething()}
                        width={"40%"}
                        height={"60%"}
                        showTitle={true}
                        title="Dettagli widget"
                    >
                        <div>
                            <p>{testoPop}</p>
                        </div>
                    </Popup>



                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "white",
                            marginBottom: "5px"
                        }}
                    >
                        <div style={{ width: "97%" }}>
                            <PieChart
                                id="pie"
                                dataSource={pie}
                                palette="Green Mist"
                                paletteExtensionMode="alternate"
                                type="doughnut"

                            //title="Valore del venduto netto sconti per tipologia di vendita - Periodo selezionato"
                            >
                                <Series argumentField="SSNDESC" valueField="PERCENTUALE">
                                    <HoverStyle color="rgb(255, 0, 0)" />
                                </Series>
                                <Tooltip
                                    enabled={true}
                                    customizeTooltip={({
                                        argument,
                                        value,
                                    }: {
                                        argument: any;
                                        value: any;
                                    }) => {
                                        const dataItem = pie.find(
                                            (item: any) => item.SSNDESC === argument
                                        );
                                        const dataSourceValue = dataItem
                                            ? dataItem.VALORE_VENDUTO
                                            : 0;
                                        const formattedValue = new Intl.NumberFormat("it-IT", {
                                            style: "currency",
                                            currency: "EUR",
                                        }).format(dataSourceValue);
                                        const percentuale = dataItem ? dataItem.PERCENTUALE : 0;
                                        const percentualeFormattata =
                                            percentuale < 1 ? "1" : `${parseInt(percentuale)}`;
                                        return {
                                            text: `Percentuale: ${percentualeFormattata}%\nValore: ${formattedValue}`,
                                        };
                                    }}
                                />
                                <Title text="Valore vendita medio per Operatore/medio - Periodo selezionato">
                                    <Font color="#5b6372" size="16" weight="400" />
                                </Title>

                                <Export enabled={true} />
                            </PieChart>
                        </div>

                        <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                            <button
                                className="ibutton-lite-two"
                                style={{ border: "none" }}
                                onClick={() => popVisible3("")}
                            >
                                <FontAwesomeIcon
                                    icon={faInfo}
                                    style={{ fontSize: "11px", color: "black" }}
                                />
                            </button>
                        </div>
                    </div>

                    <div
                        style={{
                            background: "#fff",
                            padding: 10,
                            display: "flex",
                            paddingTop: 10,
                            marginTop: "0px",
                        }}
                    >


                        <div style={{ width: "100%" }}>
                            <h6
                                style={{
                                    display: "flex",
                                    color: "#5b6372",
                                    fontFamily: "Arial, sans-serif",
                                    marginBottom: "-15px",
                                    marginLeft: "15px",
                                }}
                            >
                                % Vendite per tipologia
                            </h6>
                            <div style={{ marginTop: "15px" }}>
                                {valoriBoobble.length > 0 ? (
                                    <div>
                                        <Chart id="bubbleChart" dataSource={valoriBoobble}>
                                            <CommonSeriesSettings
                                                argumentField="MARGINE"
                                                valueField="VALORE_VENDUTO"
                                            />
                                            <Series
                                                type="bubble"
                                                name="Bubble Series"
                                                sizeField="MARGINE_PERCENTUALE"
                                            ></Series>
                                            <Tooltip
                                                enabled={true}
                                                customizeTooltip={({
                                                    argument,
                                                    value,
                                                    size,
                                                    point,
                                                }: {
                                                    argument: any;
                                                    value: any;
                                                    size: any;
                                                    point: any;
                                                }) => {
                                                    // console.log("cosa sono i dati");
                                                    // console.log(argument);
                                                    // console.log(value);
                                                    // console.log(size);
                                                    // console.log(point.data);

                                                    return {
                                                        html: `
                                      <div><p style='margin:0px'>Valore del venduto</p><p style='font-weight: bold; color:#72ddee'>€${point.data.VALORE_VENDUTO.toLocaleString(
                                                            "it-IT",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}<p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Margine</p><p style='font-weight: bold;color:#72ddee'>€${point.data.MARGINE.toLocaleString(
                                                            "it-IT",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}</p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Farmacia</p><p style='font-weight: bold;color:#72ddee'>${point.data.FARM_DESCRIZIONE
                                                            }</p></div>
                                      <hr>
                                      <div><p style='margin:0px'>Margine percentuale</p><p style='font-weight: bold;color:#72ddee'>${point.data.MARGINE_PERCENTUALE.toFixed(2)
                                                            }%</p></div>`,
                                                    };
                                                }}
                                            />

                                            <ValueAxis>
                                                <Title text="Valore del venduto" />
                                            </ValueAxis>
                                            <ArgumentAxis>
                                                <Title text="Margine" />
                                            </ArgumentAxis>

                                            <Legend visible={false} />
                                        </Chart>
                                    </div>
                                ) : null}
                            </div>

                        </div>

                        <div style={{}}>
                            <p
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "left",
                                    justifyContent: "left",
                                }}
                            >
                                <span>
                                    {" "}
                                    <button
                                        className="ibutton-lite"
                                        style={{ border: "none" }}
                                        onClick={() => popVisibleOne("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                                </span>
                            </p>
                        </div>

                        {/* {chartDataMargine.length > 0 && (
              <div
                style={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h2
                  style={{ color: "#5b6372", fontFamily: "Arial, sans-serif" }}
                >
                  Totale:
                </h2>
                <h2 style={{ color: "#16d3e0", fontWeight: "bold" }}>
                  {totalValueMargine}
                </h2>
              </div>
            )} */}
                    </div>

                </section>
            )
            }

            <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon
                    icon={faCaretUp}
                    rotation={270}
                    onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                    style={{ fontSize: "30px", color: "black" }}
                />
            </div>
            <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                <div style={{}}>
                    <FiltriVerticale
                        ricerca={filterAction}
                        items={itemsFields}
                        paramsFilter={paramsFilter}
                    />
                </div>
            </section>
            <Popup
                visible={visiblePopOne}
                showCloseButton={true}
                onHiding={() => setVisiblePopOne(false)}
                width={"40%"}
                height={"60%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop1}
                showCloseButton={true}
                onHiding={() => setVisiblePop1(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop3}
                showCloseButton={true}
                onHiding={() => setVisiblePop3(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
        </section >

    );
};

export default AnalTrasferimenti;
