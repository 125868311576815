import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import NewTabella from "../../../widget/nuova_tabella"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"

import "./style.css";

const columns = [
  { caption: "Nome", dataField: "descrizione" },
  { caption: "Codice Farmacia", dataField: "codice" },
];

interface DialogProps {
  open: boolean;
  farmacie: any;
  edit: any;
  ricarica: () => void;
  close: () => void;
}

export const DialogListFarm: React.FC<DialogProps> = ({
  open,
  close,
  farmacie,
  edit,
}) => {
  const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([]);
  const [openD, setOpenD] = useState <boolean>(false);
  const [loading, setLoading] = useState <boolean>(false);
  const auth = useAuth(urlConsultazione.auth);
  const [objctTOsave, setObjctTOsave] = useState<any>({});

  const getListFarmCluster = () => {
    setLoading(true);
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            let arrayTouse: any = [];
            let arrFarma = farmacie ? farmacie.farmacie : [];

            result.response.forEach((farma: any) => {
              if (arrFarma.includes(farma.idFar)) {
                arrayTouse.push(farma);
              }
            });
            setTabellaPrincipale(arrayTouse);
            setLoading(false);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    if (edit) {
      let objTo = { ...objctTOsave };
      objTo.descrizione = edit.descrizione;
      objTo.codice = edit.codice;
      setObjctTOsave(objTo);
    }
  }, [edit, openD]);

  useEffect(() => {
    setOpenD(open);
  }, [open]);

  useEffect(() => {
    getListFarmCluster();
  }, [auth, farmacie]);

  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'50%'}
      height={'70%'}
      showTitle={true}
      title="Cluster Visualizzazione Farmacie"
    >
      <ToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="after"
        options={{
          text: "Chiudi",
          onClick: () => close(),
        }}
      />

      <ScrollView height="100%"  width="100%">
      <div className='form-row '> 
      <div className="form-group col-md-6">
          <label> CODICE </label>
          <TextBox disabled value={objctTOsave.codice}  />
        </div>

        <div className="form-group col-md-6">
          <label> NOME </label>
          <TextBox disabled value={objctTOsave.descrizione} />
        </div>
        </div>

        <div style={{ width: '100%'}}>
          <NewTabella
            idTabella="elenco_list_farma_popup"
            sync
            colonne={columns}
            dataSource={tabellaPrincipale}
            filterRow
            exportT={{
              enabled: false,
              fileName: "list_farm",
            }}
          />
        </div>
      </ScrollView>
    </Popup>
  );
};
