import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import { RestServices } from "../../../services/restServices"
import { urlConsultazione } from "../../costanti"
import { useAuth } from "../../../hooks/useAuth"
import DateBox from 'devextreme-react/date-box'


//import "./style.css";

interface DialogProps {
  open: boolean,
  edit: any,
  typeEdit: boolean,
  flagAttive?:boolean,
  ricarica: () => void,
  close: ()=>void,
}


export const DialogData: React.FC<DialogProps> = ({ open, close,edit,ricarica,typeEdit, flagAttive}) => {

  const [openD, setOpenD] = useState <boolean>(false)
  const [loading, setLoading] = useState <boolean>(false)
  const auth = useAuth(urlConsultazione.auth)
  const [objctTOsave, setObjctTOsave] = useState<any>({})

 

 const UpdateData = () =>{
    if (auth) {
      //console.log(objctTOsave);
       RestServices().rest('POST', { 
        dataScadenza:objctTOsave.scadeIl, 
        idScadenzario:objctTOsave.idScadenzario,
        flagCicloAttivo: false } , urlConsultazione.updateScadenza)
           .then(result => {
               if (result?.esito) {      
                   ricarica() 
                                           
               }else{                              
                   //console.log(result);
                   notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
               }
           })     
   }
}




 const cambiaValore = (tipo:string,value:string) =>{
          let objTo = {...objctTOsave}
          objTo[tipo] = value
          setObjctTOsave(objTo)
    }

  



    useEffect(() => {
      if (edit) {
        //console.log(edit);
        let objTo = { ...objctTOsave };
        objTo.scadeIl = edit.scadeIl;
        objTo.idScadenzario = edit.idScadenzario
        objTo.flagCicloAttivo =  edit.flagCicloAttivo
        setObjctTOsave(objTo);
      }
    }, [edit, openD]);

  useEffect(() => { 
          setOpenD(open);
        if(open && auth){
          
        }
    
  }, [open,auth]);



  return (
    <Popup
      visible={openD}
      showCloseButton={false}
      width={'40%'}
      height={'50%'}
      showTitle={true}
      title=""
    >
     <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Chiudi',
                onClick: ()=>close(),
              }}       
          />
           <ToolbarItem
         widget="dxButton"
         toolbar="bottom"
         location="after"
         options={{
                text: 'Modifica e Invia',
                elementAttr:{
                  class:'btncreatecluster',
              },
                onClick: ()=>UpdateData(),
              }
            }
            />

      <ScrollView height="100%"  width="100%">
      

        
        <div style={{ width: '100%'}}>
        <div  style={{textAlign:'center'}}>
                <h3> MODIFICA SCADENZA </h3>
             </div>
              
             <div style={{marginTop:'50px'}}>              
             <DateBox valueChangeEvent="keyup" showClearButton={true}  value={objctTOsave && objctTOsave.scadeIl ?  objctTOsave.scadeIl : new Date()}  onValueChanged={(v)=>cambiaValore('scadeIl',v.value) }  type="date" displayFormat="dd/MM/yyyy" />
                </div>  
                    
        </div>
        
      </ScrollView>
    </Popup>
  );
};