import React, { useEffect, useState } from 'react'
import { Loading } from '../../widget/Notifications'
import { useAuth } from '../../hooks/useAuth'
import { urlB2B } from '../costanti'
import Button from 'react-bootstrap/Button'
import ElencoOrdiniB2B from './ElencoOrdiniB2B'
import SingoloOrdineB2B from './SingoloOrdineB2B'
import { RestServices } from '../../services/restServices'
import notify from 'devextreme/ui/notify'
import { convertForSelectFarmacie } from '../../funzioni/formatoVariabili'

const GestioneOrdiniB2B = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlB2B.auth)

    const [renderp, setRenderp] = useState<any>(1);
    const [idOrdineSelezionatoDettaglio, setIdOrdineSelezionatoDettaglio] = useState<number|undefined>(0);
    const [statoOrdineAggiornato, setStatoOrdineAggiornato] = useState<any>()
    const [reload, setReload] = useState<boolean>(false);
    const [filtriElenco, setFiltriElenco] = useState<any>();
    const [newFarmaList, setNewFarmaList] = useState([])
    const [newFarmaListSelect, setNewFarmaListSelect] = useState([])
    const [listStatoOrdineB2B, setListStatoOrdineB2B] = useState<any[]>([])
    const [getList, setGetList] = useState<boolean>(false)

    useEffect(() => {

        if (auth) {

            farmaList()
            statoList()

        }

    }, [auth])

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlB2B.getListFarFornitoreB2B)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                        setNewFarmaListSelect(convertForSelectFarmacie(result.response))
                    } else {
                    }
                })
        }
    }

    const statoList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlB2B.getListStatoB2B)
                .then(result => {
                    if (result?.esito) {
                        setListStatoOrdineB2B(result.response)
                    } else {

                    }
                })
        }
    }

    const setStatoOrdineB2B = (idOrdine:number, idStato: number) => {
        //console.log(idStato)
        //console.log(idOrdineSelezionatoDettaglio)
        let idOrd = idOrdine > 0 ? idOrdine : idOrdineSelezionatoDettaglio
        if (idOrd) {
            
            let params = { idStato: idStato, idOrdine: idOrd }
                RestServices().rest('POST', params, urlB2B.setStatoOrdineB2B)
                    .then(result => {
                        if (result?.esito) {
                            notify({ position: "center", width: "auto", message: "Ordine aggiornato correttamente" }, "success", 4000)
                            setStatoOrdineAggiornato(idStato == 2 ? "Approvato" : "Rifiutato")
                            //if (renderp == 1) {
                                //aggiornamento stato dalla griglia, quindi reload griglia
                                //setReload(true)
                            setGetList(true)
                            setGetList(false)
                            //}
                        } else {
                            notify({ position: "center", width: "auto", message: "Ordine non aggiornato" }, "errore", 4000)
                        }
                    })
            //setReload(false)
        }
    }
   
    useEffect(() => {

        if (idOrdineSelezionatoDettaglio)
            setRenderp(2)

    }, [idOrdineSelezionatoDettaglio])


    const render = () => {
        switch (renderp) {
            case 1:
                return (
                    <>
                        <div className='title'>
                            <h3> ELENCO ORDINI B2B </h3>
                        </div>
                        <ElencoOrdiniB2B
                            setIdOrdineDettaglio={setIdOrdineSelezionatoDettaglio}
                            approva={setStatoOrdineB2B}
                            reload={reload}
                            setFiltriElenco={setFiltriElenco}
                            filtriElenco={filtriElenco}
                            auth={auth}
                            farmaList={newFarmaList}
                            farmaListSelect={newFarmaListSelect}
                            listStato={listStatoOrdineB2B}
                            getList={ getList}
                        />
                    </>
                )
            case 2:
                return (

                    <>
                        <div className='title'>
                            <h3> DETTAGLIO ORDINE B2B </h3>
                        </div>
                        <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                            <div>
                                <Button className="btn btn-warning" variant="warning" onClick={() => { setRenderp(1); setReload(true); setIdOrdineSelezionatoDettaglio(undefined) }}>INDIETRO </Button>
                            </div>

                            <div className="posbtn">
                                <Button style={{ marginRight: 30 }} className="btn btn-warning" variant="success" onClick={() => { setStatoOrdineB2B(0,3) }}

                                    disabled={renderp != 2}>RIFIUTA </Button>
                                <Button className="btn btn-success" variant="success" onClick={() => { setStatoOrdineB2B(0, 2) }}

                                    disabled={renderp != 2}>APPROVA </Button>
                            </div>
                        </div>
                        <SingoloOrdineB2B
                            idOrdine={idOrdineSelezionatoDettaglio}
                            statoOrdineAggiornato={statoOrdineAggiornato}
                            auth={auth }
                        />

                    </>
                )
            default:
                return (
                    <>
                    </>
                )
        }
    }

    return render();

}

export default GestioneOrdiniB2B 