import React, { useEffect, useRef, useState } from "react";
import SelectBox from "devextreme-react/select-box";

export enum typeShow {
    euro,
    percento,
}

export interface cardC {
    testo?: string;
    subTesto?: string;
    valore?: string;
    subValore?: string;
    testoPop?: string;
    second?: boolean;
    starter?: boolean;
    typeShowValue?: typeShow;
    typeShowSubValue?: typeShow;
}

export const MenuStatisticheOpera = ({ }) => {

    const selectBoxOptions = [
        {
            id: 1,
            text: "Analisi Magazzino ",
            url: "/app/businesslab/statisticheOperative/AnalisiMagazzino",
        },
        {
            id: 2,
            text: "Osservatorio Margini",
            url: "/app/businesslab/statisticheOperative/OsservatorioMargini",
        },
        {
            id: 3,
            text: "Analisi Gruppo Merceologico per Fascia Oraria",
            url: "/app/businesslab/reportistica/AnalisiTrasferimenti",
        },
    ];

    const [value, setValue] = useState<number | null>()

    useEffect(() => {
        // Recupera l'URL corrente
        const currentUrl = new URL(window.location.href);
        var pathname = currentUrl.pathname;

        const pathSegments = currentUrl.pathname.split('/');
        const cruscottoDirezionale = pathSegments[pathSegments.length - 1];

        //alert(pathname)

        const index = selectBoxOptions.findIndex(option => option.url === pathname);


        if (index + 1) {
            setValue(index + 1)
        }

        // Puoi ora gestire l'URL come desideri
    });




    const defaultValue = selectBoxOptions[1];

    const handleDropdownChange = (e: any) => {
        const urlToUse = selectBoxOptions[e.value - 1].url
        setValue(e.value)
        window.open(urlToUse, "_self");
    };
    return (
        <div style={{ marginLeft: "-10px" }}>
            <label> Selezionare statistiche Operative : </label>
            <div style={{ fontSize: "14px" }}>
                <SelectBox
                    dataSource={selectBoxOptions}
                    displayExpr="text"
                    valueExpr="id"
                    value={value}
                    onValueChanged={handleDropdownChange}
                />
            </div>
        </div>
    );
};
