import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart } from "../components/card_chart";
import { trasform_for_cards_charts_01 } from "../util/trasformation";
import { createItemFilterVendRicette } from "./itemsFiltri/AnaliVendite";
import { LoadIndicator } from "devextreme-react/load-indicator";
import {
  boxCardAnaVendRicette,
  boxsecondAnaVendRicette,
} from "./BoxCard/AnalVendite";
import { MenuFbiVendite } from "../components/menu_Fbi_Vendite";
import { urlConsultazione, urlReport, urlCommon } from "../../costanti";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { Chart, PieChart } from "devextreme-react";
import {
  Animation,
  ArgumentAxis,
  CommonSeriesSettings,
  Export,
  Label,
  Legend,
  Series,
  Subtitle,
  Title,
  Tooltip,
  ValueAxis,
} from "devextreme-react/chart";
import {
  Connector,
  Font,
  Format,
  HoverStyle,
} from "devextreme-react/pie-chart";

import "./style.css";

const AnaliVendiRicetta = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardAnaVendRicette);
  const [fila2cards, setFila2cards] = useState<cardC[]>(
    boxsecondAnaVendRicette
  );
  const [pie, setPie] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>([]);
  const chartRef = useRef<any>(null);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [visiblePop2, setVisiblePop2] = useState(false);
  const [visiblePop3, setVisiblePop3] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);
  const [progetto, setProgetto] = useState<any[]>([]);
  const [societa, setSocieta] = useState<any[]>([]);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const auth = useAuth(urlConsultazione.auth);
  const authReport = useAuth(urlReport.auth);

  const dashboard16 = { dashboardID: 16 };

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

    // let venditaDPC = [0];
    // let flagBusta = [0];

    // if (params?.venditaDPC) {
    //     venditaDPC = [1];
    // }
    // if (params?.flagBusta) {
    //     flagBusta = [1];
    // }

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    let category1;
    let category2;
    let category3;
    let category4;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.category) {
      const categoryList = params.category;
      category1 = getCategoryByLevel(categoryList, 2);
      category2 = getCategoryByLevel(categoryList, 4);
      category3 = getCategoryByLevel(categoryList, 6);
      category4 = getCategoryByLevel(categoryList, 8);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al; //aggiornaDataFine(params.periodo.al);
    }

    if (params.societa && params.societa.length > 0) {
      console.log(societa);
      let objSocieta: any = [];

      params.societa.forEach((element: any) => {
        const oggettoTrovato = societa.find((obj) => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        });
      });
      params.societaNewV = objSocieta;
    }

    if (params.progetto && params.progetto.length > 0) {
      let objSocieta: any = [];

      params.progetto.forEach((element: any) => {
        const oggettoTrovato = progetto.find((obj) => obj.id === element);
        objSocieta.push({
          id: oggettoTrovato.id,
          codice: oggettoTrovato.codice,
        });
      });
      params.progettoNewV = objSocieta;
    }

    const parameters = {
      codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      codAtc1: codAtc1,
      codAtc2: codAtc2,
      codAtc3: codAtc3,
      codAtc4: codAtc4,
      codAtc5: codAtc5,
      category1: category1,
      category2: category2,
      category3: category3,
      category4: category4,
      ditta: params.ditta,
      operatori: params.operatori,
      tipologiaSconto: params.tipologiaSconto,
      progetto: params.progettoNewV,
      societa: params.societaNewV,
      //  tipologiaSconto: ["SCONTO_TESTATA", "SCONTO_MISTO"] ,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall16 = {
        ...dashboard16,
        idFarList: [3246],
        // idFarList: params.idFar,
        params: parameters,
      };

      //new call

      console.log("mostro call");
      console.log(dashboardCall16);

      RestServices()
        .rest("POST", dashboardCall16, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {
            let datiPrimaParteCards = trasform_for_cards_charts_01(
              result["77"],
              boxCardAnaVendRicette
            );
            let datiSecondaParteCards = trasform_for_cards_charts_01(
              result["77"],
              boxsecondAnaVendRicette
            );

            let arrayTousePie = [
              {
                label: "SSN",
                value: result["77"][0]?.VENDUTO_DECURTATO_SSN_SSN,
              },
              {
                label: "MISTA",
                value: result["77"][0]?.VENDUTO_DECURTATO_SSN_MISTA,
              },
            ];

            setPie(arrayTousePie);

            // 1 Chart

               //trasforma dato x grafico

              let newArrays: any[] = [];

              result["79"].forEach((element: any) => {
              let year = String(element.ANNOMESE)?.substring(0, 4);
              let month = String(element.ANNOMESE)?.substring(4, 6);

              // Ricostruire nel formato 'MM/YYYY'
              let formatMeseAnno = month + "/" + year;

              let newObj = { ...element };
              newObj.formatANNOMESE = formatMeseAnno;

              newArrays.push(newObj);
            });

            setChartData(newArrays);

            setFila1cards(datiPrimaParteCards);
            setFila2cards(datiSecondaParteCards);

            setIsVisible(false);
          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });

      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });
    }
  };

  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const itemsFields = createItemFilterVendRicette(
    newFarmaList,
    progetto,
    societa,
    tipologiaVendita
  );

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlReport.getProgetto)
        .then((result) => {
          //console.log('vediamo sytruttura');
          //console.log(result);

          if (result) {
            const progetti = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });

            setProgetto(result);
          }
        });

      RestServices()
        .rest("POST", "", urlReport.getSocieta)
        .then((result) => {
          if (result) {
            const societas = result.map((oggetto: { codice: any }) => {
              return {
                ...oggetto, // Copia tutte le proprietà esistenti
                id: oggetto.codice, // Aggiungi la nuova proprietà id
              };
            });
            setSocieta(result);
          }
        });

      const SOLO_SSN = { id: 1, descrizione: "SSN" };
      const MISTA = { id: 0, descrizione: "MISTA" };

      const vendite = [SOLO_SSN, MISTA];
      setTipologiaVendita(vendite);
    }
  };

  useEffect(() => {
    filters();
  }, [authReport]);

  const exportFormats = ["csv"];

  const onExporting = (e: any) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet("tabella_operatori");
    exportDataGrid({
      component: e.component,
      worksheet: worksheet,
    }).then(function () {
      // https://github.com/exceljs/exceljs#writing-csv
      // https://github.com/exceljs/exceljs#reading-csv
      workbook.csv.writeBuffer().then(function (buffer) {
        saveAs(
          new Blob([buffer], { type: "application/octet-stream" }),
          "tabella_operatori.csv"
        );
      });
    });
  };

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  function getCategoryByLevel(getListCate: any[], level: number) {
    console.log("categorie by level");
    console.log(getListCate);
    console.log(level);
    return getListCate.filter((category) => category.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  const popVisible = (testo: string) => {
    setVisiblePop(true);
  };
  const popVisible2 = (testo: string) => {
    setVisiblePop2(true);
  };
  const popVisible3 = (testo: string) => {
    setVisiblePop3(true);
  };

  let messOne = [
    "Valore decurtato SSN per tipologia di vendita: Rappresenta la distribuzione del valore del venduto in SSN, nel periodo selezionato, in funzione della tipologia di vendita. Le due tipologie di vendita SSN considerate sono: Vendite SSN, in cui sono venduti solo prodotti dietro ricetta (SSN); Vendite miste, in cui sono presenti sia prodotti erogati dietro ricetta, sia prodotti di vendita libera. Il costrutto alla base della torta è l'aggregato vendita, non il singolo prodotto.",
  ];
  let messTwo = [
    "Andamento valore sconto: Descrive l'andamento mensile del valore sconto negli ultimi 12 mesi rolling. Valore sconto anno corrente: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Valore sconto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente. ",
  ];
  let messThree = ["Andamento ricette servite "];

  return (
    <>
      <section style={{ display: "flex", height: "98vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >
              <h2>F.B.I</h2>
            </div>

            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuFbiVendite />
              </div>
            </div>

            <div className="grid-container-2-ele">
              <div
                style={{ height: 200, padding: 5, background: "#fff" }}
                className="newPieVendRice"
              >
                <PieChart
                  id="pie"
                  dataSource={pie}
                  palette="Green Mist"
                  paletteExtensionMode="alternate"
                  type="doughnut"
                >
                  <Series argumentField="label" valueField="value">
                    <HoverStyle color="rgb(255, 0, 0)" />
                  </Series>

                  {
                    <Tooltip
                      enabled={true}
                      customizeTooltip={({
                        argument,
                        value,
                        percentText,
                      }: {
                        argument: any;
                        value: any;
                        percentText: any;
                      }) => {
                        console.log("vediamo value");
                        console.log(percentText);

                        const formattedValue = new Intl.NumberFormat("it-IT", {
                          style: "currency",
                          currency: "EUR",
                        }).format(value);

                        return {
                          html: `<p>Valore del venduto SSN - ${argument} </p><p>${formattedValue} / ${percentText}</p>`,
                        };
                      }}
                    />
                  }
                  <Title text="Fatturato ivato SSN per tipologia di vendita">
                    <Font color="#5b6372" size="16" weight="400" />
                  </Title>

                  <Export enabled={true} />
                </PieChart>
                <div style={{ display: "flex" }}>
                  <button
                    className="ibutton-lite-two"
                    style={{ border: "none" }}
                    onClick={() => popVisible("")}
                  >
                    <FontAwesomeIcon
                      icon={faInfo}
                      style={{ fontSize: "11px", color: "black" }}
                    />
                  </button>
                </div>
              </div>
              {fila1cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            <div className="grid-container-rice">
              {fila2cards.map((el, i) => {
                return (
                  <CardCart
                    testo={el.testo}
                    subTesto={el.subTesto}
                    key={i}
                    valore={el.valore}
                    subValore={el.subValore}
                    starter={el.starter}
                    typeShowValue={el.typeShowValue}
                    typeShowSubValue={el.typeShowSubValue}
                    testoPop={el.testoPop}
                  />
                );
              })}
            </div>

            <div
              style={{
                display: "flex",
                backgroundColor: "white",
                marginTop: "5px",
              }}
            >
              <div style={{ width: "99%", margin: "10px", marginLeft: "20px" }}>
                <Chart dataSource={chartData} ref={chartRef}>
                  <CommonSeriesSettings
                    argumentField="formatANNOMESE"
                    type="bar"
                    hoverMode="allArgumentPoints"
                    selectionMode="allArgumentPoints"
                  >
                    {/* <Label visible={true}>
                     <Format type="string" precision={0} /> 
                    </Label> */}
                  </CommonSeriesSettings>

                  <Series
                    valueField="NUMERO_RICETTE"
                    type="bar"
                    name="NUMERO RICETTE"
                    color="rgb(215, 140, 245)"
                  />

                  <Tooltip
                    enabled={true}
                    customizeTooltip={({
                      argument,
                      value,
                      size,
                      point,
                    }: {
                      argument: any;
                      value: any;
                      size: any;
                      point: any;
                    }) => {
                      // const formattedValue = parseFloat(value).toLocaleString(
                      //   "it-IT",
                      //   {
                      //     style: "currency",
                      //     currency: "EUR",
                      //     minimumFractionDigits: 2,
                      //   }
                      // );

                      // console.log("cosa sono i dati del chart");
                      //     console.log(argument);
                      //     console.log(value);
                      //     console.log(size);
                      //     console.log(point.data);

                      return {
                        html: `
                      <meta charset="UTF-8" />
                      <div><p style='margin:0px'>Numero ricette</p><p style='font-weight: bold;color:#72ddee'>${point.data.NUMERO_RICETTE}</p> 
                                  <hr>
                                  <div><p style='margin:0px'>Data</p><p style='font-weight: bold;color:#72ddee'>${point.data.formatANNOMESE} </p> 
                                  </div>                   
                          </div>`,
                      };
                    }}
                  />
                  <Title text="Andamento ricette servite - ultimi 12 mesi rolling">
                    <Font color="#5b6372" size="16" weight="400" />
                    {/* <Subtitle text="<i>Questo grafico non tiene conto del periodo selezionato nei filtri.</i>">
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Subtitle> */}
                  </Title>
                  <Legend
                    verticalAlignment="bottom"
                    horizontalAlignment="center"
                  ></Legend>
                  <Export fileName="venduto_sconto_vendita" enabled={true} />
                </Chart>
              </div>
              <div style={{}}>
                <p
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                    margin: 10,
                  }}
                >
                  <span>
                    {" "}
                    <button
                      className="ibutton-lite"
                      style={{ border: "none" }}
                      onClick={() => popVisible3("")}
                    >
                      <FontAwesomeIcon
                        icon={faInfo}
                        style={{ fontSize: "11px", color: "black" }}
                      />
                    </button>
                  </span>
                </p>
              </div>
            </div>
          </section>
        )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section
          style={{
            background: "#fff",
            opacity: isFiltersExpanded ? 1 : 0,
            width: isFiltersExpanded ? "auto" : 10,
          }}
        >
          <div style={{}}>
            <FiltriVerticale
              ricerca={filterAction}
              items={itemsFields}
              paramsFilter={paramsFilter}
            />
          </div>
        </section>
      </section>
      <Popup
        visible={visiblePop}
        showCloseButton={true}
        onHiding={() => setVisiblePop(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messOne}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop2}
        showCloseButton={true}
        onHiding={() => setVisiblePop2(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messTwo}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop3}
        showCloseButton={true}
        onHiding={() => setVisiblePop3(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messThree}</p>
        </div>
      </Popup>
    </>
  );
};

export default AnaliVendiRicetta;
