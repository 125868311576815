import { cardC, typeShow, cardV , cardG } from "../../components/card_chart";


export const boxVendiCategory: cardC[] = [
    {
      testo: "Numero giorni lavorati",
      subTesto: "Numero giorni lavorati anno prec.",
      valore: "VALORE_VENDUTO_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Valore del venduto",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Imponibile",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Margine",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.               
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Margine percentuale",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.percento,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Volume di vendita",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
                
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
  ];

  export const boxCard: cardC[] = [
    {
      testo: "Valore del venduto",
      subTesto: "Media per operatore",
      valore: "VALORE_VENDUTO",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.euro,
      subValore: "VALORE_VENDUTO_MEDIA_OPERATORE",
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Margine",
      subTesto: "Media per operatore",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.euro,
      valore: "MARGINE",
      subValore: "MARGINE_MEDIA_OPERATORE",
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
            
            Delta margine:
            Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Ore lavorate",
      subTesto: "Media per operatore",
      valore: "ORE_LAVORATE",
      subValore: "ORE_LAVORATE_MEDIA_OPERATORE",
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Numero righe vendita",
      subTesto: "Media per operatore",
      valore: "NUMERO_RIGHE",
      subValore: "NUMERO_RIGHE_MEDIA_OPERATORE",
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
                
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
  ];
  
  export const boxsecond: cardC[] = [
    {
      testo: "Valore del venduto medio orario",
      valore: "VALORE_VENDUTO_MEDIA_ORARIA",
      typeShowValue: typeShow.euro,
  
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Margine medio orario",
      valore: "MARGINE_MEDIA_ORARIA",
      typeShowValue: typeShow.euro,
  
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Numero vendite medio orario",
      valore: "NUMERO_VENDITE_MEDIA_ORARIA",
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
          
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Numero righe di vendita media orario",
      valore: "NUMERO_RIGHE_MEDIA_ORARIA",
      testoPop: `Margine:
                  Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
                  
                  Delta margine:
                  Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
  ];

  export const boxAnaAcquiFornitori: cardC[] = [
    {
      testo: "Costo d'acquisto periodo selezionato ",
      subTesto: "Delta anno precedente",
      valore: "VALORE_SCONTOSL",  
      subValore: "PERC_VENDITE_SCONTO_PUNTUALEPR", 
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Valore sconto: Rappresenta lo sconto "
    },
  
  ];

  export const boxAnaProdFermi: cardC[] = [
    {
      testo: "Prodotti Fermi ",
      valore: "VALORE_SCONTOSL",  
      testoPop: "Prodotti fermi: Questo kpi indica il numero di referenze ferme. Una referenza è considerata ferma quando si verificano tutte le condizioni seguenti: -  Non è acquistata da almeno 180 gg - Non è venduta da almeno 180 gg -  La giacenza attuale è > 0 "
    },
  
  ];
  
  export const boxAnalVendFasciaOraria: cardC[] = [
    {
      testo: "Valore del venduto",
      valore: "MARGINE_EQUIPARATOSL",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Valore del venduto medio per operatore",
      valore: "MARGINE_EQUIPARATOSL",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Numero vendite",
      valore: "MARGINE_EQUIPARATOSL",
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Numero vendite medio per operatore ",
      valore: "MARGINE_EQUIPARATOSL",
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.               
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Numero ore lavorate",
      valore: "MARGINE_EQUIPARATOSL",
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Righe di vendita lavorate per ora",
      valore: "MARGINE_EQUIPARATOSL",
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
                
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
  ];

  export const boxAnalMagazzino: cardC[] = [
    {
      testo: "Valore del venduto",
      valore: "MARGINE_EQUIPARATOSL",
      typeShowValue: typeShow.euro,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Valorizzazione attuale Magazzino ",
      valore: "MARGINE_EQUIPARATOSL",
      typeShowValue: typeShow.euro,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Referenze in giacenza",
      valore: "MARGINE_EQUIPARATOSL",
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Indice di rotazione medio  ",
      valore: "MARGINE_EQUIPARATOSL",
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.               
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Indice di copertura medio",
      valore: "MARGINE_EQUIPARATOSL",
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
   
  ];

  export const boxAnaliSconti: cardC[] = [
    {
      testo: "Valore sconto",
      subTesto: "Delta anno prec.",
      valore: "VALORE_VENDUTO_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Sconto percentuale",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
            Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Numero vendite con sconto",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.
              
              Delta margine:
              Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "% di vendite con sconto",
      subTesto: "Delta anno prec.",
      valore: "MARGINE_EQUIPARATOSL",
      subValore:"GIORNI_LAVORATIPR", 
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.               
                Delta margine:
                Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
   
   
  ];
 
  export const boxCardAnaRicette: cardC[] = [
    {
      testo: "Venduto lordo",
      subTesto: "% sul venduto totale",
      valore: "VENDUTO_LORDO_TOTALESL",
      subValore: "PERC_VENDUTO_TOTALEPR",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Valore sconto: Rappresenta lo sconto totale applicato in vendita nel periodo selezionato. Delta valore sconto: Rappresenta il delta percentuale tra il valore sconto anno corrente e anno precedente nel periodo selezionato."
    },
    {
      testo: "Imponibile Lordo",
      subTesto: "% sull'imponibile totale",
      valore: "IMPONIBILE_LORDO_SSNSL",
      subValore: "PERC_IMPONIBILE_TOTALEPR",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Sconto percentuale: Rappresenta il rapporto tra il valore sconto e la somma di valore del venduto e valore sconto, nel periodo selezionato. Delta sconto percentuale: Rappresenta il delta percentuale tra lo sconto percentuale anno corrente e anno precedente, nel periodo selezionato. Valore sconto: Rappresenta lo sconto totale applicato in vendita. Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.",
    },
  
  
  ];
  
  export const boxsecondRicette: cardC[] = [
    {
      testo: "Valore Ticket + Quota ricetta ",
      subTesto: "Delta anno precedente",
      valore: "TICKET_QUOTASL",
      subValore: "TICKET_QUOTA_PUNTUALEPR",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    
  ];
  
  export const boxterzRicette: cardC[] = [
    {
      testo: "Numero di ricette lavorate",
      subTesto: "Delta anno precedente",
      valore: "NUMERO_RICETTESL",
      subValore: "NUMERO_RICETTE_PUNTUALEPR",
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Valore del venduto: Rappresenta il valore dei prodotti venduti al netto di sconti, lordo IVA, nel periodo selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Valore del venduto anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Valore medio per ricetta",
      subTesto: "Delta anno precedente",
      valore: "VALORE_MEDIO_RICETTASL",
      subValore: "VALORE_MEDIO_RICETTA_PUNTUALEPR",
      typeShowSubValue: typeShow.percento,
      typeShowValue: typeShow.euro,
      testoPop: `Margine:
              Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato.`,
    },
    {
      testo: "Pezzi venduti SSN",
      subTesto: "Delta anno precedente",
      valore: "PEZZI_SSNSL",
      subValore: "PEZZI_SSN_PUNTUALEPR",
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
                Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
    {
      testo: "Numero medio pezzi per ricetta",
      subTesto: "Delta anno precedente",
      valore: "MEDIA_PEZZI_RICETTASL",
      subValore: "MEDIA_PEZZI_RICETTA_PUNTUALEPR",
      typeShowSubValue: typeShow.percento,
      testoPop: `Margine:
                  Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10.`,
    },
  ];

  export const boxCardSinte: cardC[] = [
    {
      testo: "Numero giorni di lavoro",
      subTesto: "Numero giorni lavorati anno prec.",
      valore: "GIORNI_LAVORATISL",
      subValore:"GIORNI_LAVORATIPR", 
      second2:"Periodo Selezionato",
      testoPop:
        "Il numero di giorni lavorati viene calcolato basandosi sulle vendite effettuate dalla farmacia. Se la farmacia in una data ha chiuso almeno una vendita, e questa vendita ha importo superiore a 50 €, allora la data è conteggiata come lavorativa.",
    },
    {
      testo: "Valore del venduto Ivato",
      subTesto: "Anno precedente",
      valore: "VALORE_VENDUTO_NETTO_IVASL",
      subValore:"VALORE_VENDUTO_NETTO_IVAPR",
      second2:"Periodo Selezionato",
      typeShowValue: typeShow.percento,   
      testoPop:
        "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Margine",
      subTesto: "Anno precedente",
      valore: "MARGINESL",
      subValore:"MARGINEPR",
      second2:"Periodo Selezionato",
      typeShowValue: typeShow.euro,
      testoPop:
        "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Margine Percentuale",
      subTesto: "Anno precedente",
      second2:"Periodo Selezionato",
      valore: "MARGINE_PERCENTUALESL",
      subValore:"MARGINE_PERCENTUALEPR",
      typeShowValue: typeShow.percento,
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Volume vendita",
      subTesto: "Anno precedente",
      second2:"Periodo Selezionato",
      valore: "VOLUME_VENDITASL",
      subValore:"VOLUME_VENDITAPR",
      testoPop:
        "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato. Volume di vendita anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Numero vendite",
      subTesto: "Anno precedente",
      second2:"Periodo Selezionato",
      valore: "NUMERO_VENDITESL",
      subValore:"NUMERO_VENDITEPR",
      testoPop:
        "Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero di vendite anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
  ];
  
  export const boxCardAllYSinte: cardC[] = [
    {
      testo: "Numero giorni di lavoro",
      subTesto: "Anno precedente",
      second2:"Progressivo anno corrente",
      valore: "GIORNI_LAVORATISL",
      subValore:"GIORNI_LAVORATIPR",
      testoPop:
        "Il numero di giorni lavorati viene calcolato basandosi sulle vendite effettuate dalla farmacia. Se la farmacia in una data ha chiuso almeno una vendita, e questa vendita ha importo superiore a 50 €, allora la data è conteggiata come lavorativa.",
    },
    {
      testo: "Valore del venduto Ivato",
      subTesto: "Anno precedente",
      second2:"Progressivo anno corrente",
      valore: "VALORE_VENDUTO_NETTO_IVASL",
      subValore:"VALORE_VENDUTO_NETTO_IVAPR",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.euro,
      testoPop:
        "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Margine",
      subTesto: "Anno precedente",
      second2:"Progressivo anno corrente",
      valore: "MARGINESL",
      subValore:"MARGINEPR",
      typeShowValue: typeShow.euro,
      typeShowSubValue: typeShow.euro,
      testoPop:
        "Margine: Rappresenta il valore dei prodotti venduti al netto di sconti e IVA, a cui sottraiamo il costo dei prodotti (costo medio ponderato). Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, il valore qui riportato è già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Margine Percentuale",
      subTesto: "Anno precedente",
      second2:"Progressivo anno corrente",
      valore: "MARGINE_PERCENTUALESL",
      subValore:"MARGINE_PERCENTUALEPR",
      typeShowValue: typeShow.percento,
      typeShowSubValue: typeShow.percento,
      testoPop:
        "Margine percentuale: Rappresenta il rapporto tra il margine e l'imponibile. Dove l'imponibile rappresenta il valore dei prodotti venduti al netto di sconti e IVA. Il periodo considerato è quello selezionato. Nel caso di prodotti erogati dietro ricetta, dividendo e divisore sono già al netto della DCR per le vendite successive all'installazione della revisione 10. Margine percentuale anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Volume vendita",
      subTesto: "Anno precedente",
      second2:"Progressivo anno corrente",
      valore: "VOLUME_VENDITASL",
      subValore:"VOLUME_VENDITAPR",
      testoPop:
        "Volume di vendita: Rappresenta il numero di pezzi venduti nel periodo selezionato. Volume di vendita anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
    {
      testo: "Numero vendite",
      subTesto: "Anno precedente",
      second2:"Progressivo anno corrente",
      valore: "NUMERO_VENDITESL",
      subValore:"NUMERO_VENDITEPR",
      testoPop:
        "Numero di vendite: Rappresenta il numero di vendite effettuate nel periodo selezionato. Tale numero non coincide con il numero scontrini in quanto consideriamo anche le vendite esentate. Numero di vendite anno precedente: Calcolato come il kpi anno corrente. Il periodo considerato come anno precedente è coerente a quello selezionato per l'anno corrente.",
    },
  ];
  
  export const gaugeAllYSinte: cardG[] = [
    {
      testo: "Venduto Ivato",
      keyValue: "VALORE_VENDUTO_EQUIPARATO",
      puntale: "VALORE_VENDUTO_PUNTUALE",
      testoPop:
        "Delta valore del venduto: Rappresenta il delta percentuale tra il valore del venduto anno corrente e anno precedente nel periodo selezionato.Il delta viene calcolato considerando entrambi i valori al lordo della DCR in quanto il valore netto è disponibile solo dall'installazione della revisione 10. Delta valore del venduto equiparato: Rappresenta il delta percentuale tra valore del venduto anno corrente ed il valore del venduto equiparato nel periodo selezionato. Il valore del venduto equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un valore del venduto di 1000 €, contro i 950 € dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
    {
      testo: "Margine",
      keyValue: "MARGINE_EQUIPARATO",
      puntale: "MARGINE_PUNTUALE",
      testoPop:
        "Delta margine: Rappresenta il delta percentuale tra il margine anno corrente e anno precedente nel periodo selezionato. Il delta viene calcolato considerando entrambi i valori al lordo della DCR in quanto il valore netto è disponibile solo dall'installazione della revisione 10.Delta margine equiparato: Rappresenta il delta percentuale tra margine anno corrente e margine equiparato nel periodo selezionato. Il margine equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un margine di 1000 €, contro i 950 € dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
    {
      testo: "Volume di vendita",
      keyValue: "VOLUME_VENDITA_EQUIPARATO",
      puntale: "VOLUME_VENDITA_PUNTUALE",
      testoPop:
        "Delta volume di vendita: Rappresenta il delta percentuale tra il volume di vendita anno corrente e anno precedente nel periodo selezionato. Delta volume di vendita equiparato: Rappresenta il delta percentuale tra volume di vendita anno corrente e volume di vendita equiparato nel periodo selezionato. Il volume di vendita equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho un venduto 1000 pezzi, contro i 950 dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
    {
      testo: "Numero di vendite",
      keyValue: "NUMERO_VENDITE_EQUIPARATO",
      puntale: "NUMERO_VENDITE_PUNTUALE",
      testoPop:
        "Delta numero vendite: Rappresenta il delta percentuale tra il numero di vendite anno corrente e anno precedente nel periodo selezionato. Delta numero vendite equiparato: Rappresenta il delta percentuale tra il numero di vendite anno corrente e numero di vendite equiparato nel periodo selezionato. Il numero di vendite equiparato permette di confrontare misure che non si basano sullo stesso numero di giorni lavorati. Esempio: Se in un mese ho effettuato 1000 vendite, contro le 950 dell'anno precedente, ma questo mese ho lavorato 4 giorni in più, in realtà questo delta positivo considerando gli assoluti (+5%) dev'essere mediato considerando i miei giorni lavorati (-8%).",
    },
  ];