import React, { useState, useEffect } from "react"
import { Popup, ToolbarItem } from "devextreme-react/popup"
import ScrollView from "devextreme-react/scroll-view"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import { RadioGroup } from 'devextreme-react/radio-group'

//import "../style.css"
import { useAuth } from "../../hooks/useAuth"
import { urlConsultazione } from "../costanti"
import { RestServices } from "../../services/restServices"
import { Loading } from "../../widget/Notifications"



function getLastDayOfMonth(year: any, month: any) {
    return new Date(year, month + 1, 0);
}

interface DialogProps {
    open: boolean,
    edit: any,
    close: () => void,
}


const objMesi: any = {
    1: 'Gen',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'Mag',
    6: 'Giu',
    7: 'Lug',
    8: 'Ago',
    9: 'Set',
    10: 'Ott',
    11: 'Nov',
    12: 'Dic',
}

const objMesiInv: any = {
    12: 'Dic',
    11: 'Nov',
    10: 'Ott',
    9: 'Set',
    8: 'Ago',
    7: 'Lug',
    6: 'Giu',
    5: 'Mag',
    4: 'Apr',
    3: 'Mar',
    2: 'Feb',
    1: 'Gen',
}


function magic(value: string) {
    //alert(value)
    let booo = Object.keys(objMesi).find(key => objMesi[key] === value);
    return booo
}
function oggetInv() {
    let index = new Date().getMonth() + 1
    var prima = new Array;
    var dopo = new Array;
    var total = new Array;




    for (const [key, value] of Object.entries(objMesiInv)) {
        if (Number(key) <= index) {
            prima.push(value);
        } else {
            dopo.push(value);
        }

    }

    prima.reverse()
    dopo.reverse()
    total = [...prima, ...dopo]


    total.splice(0, 0, "Anno");
    total[total.length] = 'Totale'

    //console.log(Object.assign({}, total));


    return Object.assign({}, total);
}

function reverseArr(input: any) {
    let index = new Date().getMonth() + 1
    var prima = new Array;
    var dopo = new Array;
    var total = new Array;




    for (const [key, value] of Object.entries(objMesiInv)) {
        //console.log(`${key}: ${value}`);
        //console.log(value);
        if (Number(key) <= index) {
            prima.push(value);
        } else {
            dopo.push(value);
        }

    }

    prima.reverse()
    dopo.reverse()
    total = [...prima, ...dopo]


    total.splice(0, 0, "Anno");
    total[total.length] = 'Totale'

    console.log(Object.assign({}, total));


    return total;
}


export const DialogMovimenti: React.FC<DialogProps> = ({ open, edit, close  }) => {




    const [tabelleInfo, setTabelleInfo] = useState<any>(null)

    const [openD, setOpenD] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    //const auth = useAuth(urlConsultazione.auth)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [propTab, setPropTab] = useState<number>(1)
    const [legendInv, setLegendInv] = useState<any>({})

    let propr = [{ id: 0, text: 'FINESTRA MOBILE' },
    { id: 1, text: 'ANNO SOLARE' }]


    const caricaDati = async (mobile?: boolean) => {

        setLoading(true)

        let query: any = {
            codCsf: edit.codCsf,
            annoSolare: true,
        }
        if (mobile) {
            query = {
                codCsf: edit.codCsf,
                annoSolare: false
            }
        }
        RestServices().rest('POST', query, urlConsultazione.getListDettVendiProdMese)
            .then(result => {
                if (result?.esito) {
                    /*if(mobile){
                      let leggg = oggetInv()
                      result.response.acquistato = []
                      result.response.venduto = []
                    }*/
                    setTabelleInfo(result.response)
                    setLoading(false)
                    // console.log(result.response);
                } else {
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    setLoading(false)
                }
            })
    }


    const tabellaInternaInver = (value: any) => {
        const newV = reverseArr(value)



        return (
            newV.map((h: any) => {
                if (h == 'Totale') {
                    return (
                        <th style={{ fontWeight: 'bold', textAlign: 'right' }}>{h}</th>
                    )
                } else if (h == 'Anno') {
                    return (
                        <th style={{ textAlign: 'center' }}>{h}</th>
                    )
                } else {
                    return (
                        <th style={{ textAlign: 'right' }}>{h}</th>
                    )
                }

            })
        )
    }
    const tabellaInterna = (value: any, reverse?: boolean) => {
        let newV = value
        if (reverse) {
            newV = value.reverse()
        }
        return (
            newV.map((h: any) => {
                if (h == 'Totale') {
                    return (
                        <th style={{ fontWeight: 'bold', textAlign: 'right' }}>{h}</th>
                    )
                } else if (h == 'Anno') {
                    return (
                        <th style={{ textAlign: 'center' }}>{h}</th>
                    )
                } else {
                    return (
                        <th style={{ textAlign: 'right' }}>{h}</th>
                    )
                }

            })
        )
    }

    useEffect(() => {
        if (edit) {
            
            let objTo = { ...edit };
            //objTo.descrizione = edit.descrizione;
            //objTo.codProdotto = edit.codProdotto;
            setObjctTOsave(objTo);
        }
    }, [edit, openD]);

    useEffect(() => {
        if (open) {
            setPropTab(1)
            caricaDati()
        }

    }, [open]);



    useEffect(() => {
        setTabelleInfo(undefined)
        setOpenD(open)
    }, [open])

    const cambiaProp = (valore: number) => {
        setPropTab(valore)
        if (valore == 0) {
            setLegendInv(oggetInv())
            caricaDati(true)
        } else {
            caricaDati()
        }

    }

    return (
        <Popup
            visible={openD}
            showCloseButton={false}
            width={'75%'}
            height={'100%'}
            showTitle={true}
            title="Dettaglio vendite mensili ultimi due anni"
        >
            <Loading isVisible={loading} />

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Chiudi',
                    onClick: () => close(),
                }}
            />

            <ScrollView height="100%" width="100%">
                <div className='form-row '>

                    <div className="form-group col-md-4">
                        <label> CODICE Minsan </label>
                        <TextBox disabled value={objctTOsave.codProd} />
                    </div>

                    <div className="form-group col-md-4">
                        <label> Descrizione Prodotto </label>
                        <TextBox disabled value={objctTOsave.descrizioneEstesa} />
                    </div>
                </div>

                <div className=' '>
                    <div style={{}} className="">
                        <br></br>
                        <RadioGroup onValueChanged={(x) => cambiaProp(x.value.id)} dataSource={propr} layout="horizontal" value={propr[propTab]} />
                    </div>

                </div>

                <div style={{ width: '100%' }}>
                    {tabelleInfo?.venduto.map((tes: any) => {
                    
                        return (
                            <div>
                               
                                <div style={{ fontWeight: 'bold', paddingTop:30 }}>
                                 DETTAGLIO VENDITE MENSILI - ULTIMI DUE ANNI - FARMACIA: {tes.farmacia }
                                </div>
                                {propTab == 1 ? (
                            <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10 }}>

                                <table id="dlgvenacq-tablevenm" className="table table-bordered table-hover b2x-table fixed-layout" >
                                    <thead>
                                        <tr>
                                            {tabelleInfo ? tabellaInterna(tabelleInfo?.header) : null}
                                        </tr>
                                    </thead>
                                    {tes.testata.map((anno: any) => {
                                        return (
                                            <tbody>
                                                <tr>

                                                    <td style={{ textAlign: 'center' }}><strong>{anno.anno}</strong></td>
                                                    {anno?.dettaglio.map((d: any) => {
                                                        return (
                                                            <th style={{ textAlign: 'right' }}>{d.qtaMese}</th>
                                                        )
                                                    })
                                                    }
                                                    <td style={{ fontWeight: 'bold', textAlign: 'right' }}><strong>{anno.qtaTot}</strong></td>
                                                </tr>
                                            </tbody>
                                        )
                                    })}

                                </table>

                                    </div>
                                ) :
                                    (

                                        <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 10 }}>

                                            <table id="dlgvenacq-tablevenm" className="table table-bordered table-hover b2x-table fixed-layout" >
                                                <thead>
                                                    <tr>
                                                        {tabelleInfo ? tabellaInternaInver(tabelleInfo?.header) : null}
                                                    </tr>
                                                </thead>
                                                {tes.testata.map((anno: any) => {
                                                    return (
                                                        <tbody>
                                                            <tr>

                                                                <td style={{ textAlign: 'center' }}><strong>{anno.anno}</strong></td>
                                                                {Object.keys(legendInv).map((key, index) => {
                                                                    let inddM = magic(legendInv[key])
                                                                    if (inddM) {
                                                                        return (
                                                                            <th style={{ textAlign: 'right' }}>{anno?.dettaglio[Number(inddM) - 1].qtaMese}</th>
                                                                        )
                                                                    }

                                                                })}

                                                                <td style={{ fontWeight: 'bold', textAlign: 'right' }}><strong>{anno.qtaTot}</strong></td>
                                                            </tr>
                                                        </tbody>
                                                    )
                                                })}

                                            </table>

                                        </div>

                                )}

                        </div>
                    )
                })

                }

                </div>

            </ScrollView>
        </Popup>
    );
};  