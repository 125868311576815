import React, { useEffect, useState } from 'react'
import { urlB2B, urlOrdiniLiberi } from '../costanti'
import { useAuth } from '../../hooks/useAuth'
import { RestServices } from '../../services/restServices'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { Loading } from '../../widget/Notifications'

const ElencoFarmacieB2B = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlB2B.auth)

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (auth) {

            farmaList()
        }

    }, [auth]);

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlB2B.getListFarB2B)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setTabellaPrincipale(result.response)
                    } else {

                    }
                })
        }
    }

    const columns = [
        { caption: "Id Farmacia", dataField: "idFar", visible: false },
        { caption: "Farmacia", dataField: "farmacia"},
        { caption: "P.Iva", dataField: "piva" },
        { caption: "Tot. da approvare", dataField: "totaleDaApprovare" },
        { caption: "Tot. approvati", dataField: "totaleApprovati" },
        { caption: "Tot. rifiutati", dataField: "totaleRifiutati" },
    ]

    return (
        <>
            <Loading isVisible={loading} />

            <div className='title'>
                {/*<h3> LIVELLAMENTO MAGAZZINO  <span className='subt' > Registro fatturazione elettronica</span> </h3>*/}
                <h3> ELENCO FARMACIE ABILITATE </h3>
            </div>

            <NewTabella
                idTabella='b2b_far'
                sync
                colonne={columns}
                dataSource={tabellaPrincipale}
                filterRow
                searchPanel={true}
                onclickRow={() => null}
                exportT={{
                    enabled: true,
                    fileName: "b2b_far"
                }}
                activeSaver={true}
                columnC={true}
            />
        </>
    )
}

export default ElencoFarmacieB2B