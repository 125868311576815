import React, { useEffect } from 'react'
import { CaricoDettInterface, CostoDettInterface, CostoInterface, GiacenzaDettInterface, GiacenzaDettMagazzInterface, GiacenzeMagazzInterface, OfferteInterface, PrezziStoriciInterface, PrezzoFarmaciaInterface, PrezzoPromozioneInterface, ProdBDInterface, VenditaDettInterface } from '../../interfaces/bancaDati'
import Row from 'react-bootstrap/esm/Row'
import Col from 'react-bootstrap/esm/Col'
import Image from 'react-bootstrap/esm/Image';
import immagineNonDisponibile from '../../immagini/immagineNonDisponibile.png';
import { TabellaSempliceInterface } from '../../interfaces/tabelle';
import { TabellaSemplice } from '../../widget/Tabelle';
import { cambiaFormatoLocalDateTimeToEuropeo, cambiaFormatoValuta } from '../../funzioni/formatoVariabili';
import { OggettoInterface } from '../../interfaces/generiche';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';

export const TabBase = (props: {
    dettagliProd?: ProdBDInterface,
    imaProd: string,
    giacenzaDett: GiacenzaDettInterface[],
    costoMedioDett: CostoDettInterface[],
    prezzoFarmacia: PrezzoFarmaciaInterface[],
    prezzoPromozione: PrezzoPromozioneInterface[],
    giacenzaDettMagazz: GiacenzaDettMagazzInterface[],
    lottoRiordino: number | undefined,
}) => {
    let giacenzaMagazzino: GiacenzeMagazzInterface[] = [];
    useEffect(() => {
        if (props.giacenzaDettMagazz[0]) {
            giacenzaMagazzino = props.giacenzaDettMagazz[0].giacenze;
        }
    }, [props.giacenzaDettMagazz])

    const propsTabGiacMagazz: TabellaSempliceInterface = {
        id: "banca_dati_giacenza_magazzino",
        dataSource: giacenzaMagazzino,
        filterRow: false,
    }
    const columnsGiacMagazz = CreaColonne([
        { caption: "Magazzino", dataField: "descrizione", sortOrder: "asc" },
        { caption: "Giacenza Totale", dataField: "giacenzaTotale" },
        { caption: "Scorta Minima", dataField: "scortaMinima" },
        { caption: "Scorta Massima", dataField: "scortaMassima" },
    ]);

    return (
        <>
            <Row className="elencoDett">
                {props.dettagliProd ? <Col sm="12" className="descrProd">{props.dettagliProd.descrizione}</Col> : <Col sm="12" />}
                <Col sm="8">
                    <Row className="lista">
                        <Col sm="3" className="titoloDett">Ministeriale</Col>
                        {props.dettagliProd ? <Col sm="3" className="valoreDett">{props.dettagliProd.minsan}</Col> : <Col sm="3" className="valoreDett" />}
                        <Col sm="3" className="titoloDett">EAN</Col>
                        {props.dettagliProd ? <Col sm="3" className="valoreDett">{props.dettagliProd.ean}</Col> : <Col sm="3" className="valoreDett" />}

                        <Col sm="3" className="titoloDett">Prezzo Banca Dati</Col>
                        {props.dettagliProd ? <Col sm="3" className="valoreDett">{cambiaFormatoValuta(props.dettagliProd.prezzo)}</Col> : <Col sm="3" className="valoreDett" />}
                        <Col sm="3" className="titoloDett">Iva</Col>
                        {props.dettagliProd ? <Col sm="3" className="valoreDett">{props.dettagliProd.iva} %</Col> : <Col sm="3" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Forma Farmaceutica</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.codFormaFarmaceutica} - {props.dettagliProd.formaFarmaceutica}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Classe Terapeutica</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.codClasseTerap} - {props.dettagliProd.classeTerap}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Atc</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.codAtc} - {props.dettagliProd.atc}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Suddivisione Merceologica</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.codSudMerc} - {props.dettagliProd.sudMerc}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Categoria Ricetta</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.catRicetta}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Category</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.category}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Sostanza</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.sostanza}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Ditta Titolare</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.codDittaTitolare} - {props.dettagliProd.dittaTitolare}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett">Ditta Concessionaria</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett">{props.dettagliProd.codConcessionario} - {props.dettagliProd.dittaConcessionaria}</Col> : <Col sm="8" className="valoreDett" />}

                        <Col sm="4" className="titoloDett noRiga">Classe</Col>
                        {props.dettagliProd ? <Col sm="8" className="valoreDett noRiga">{props.dettagliProd.mutuabilita}</Col> : <Col sm="8" className="noRiga" />}
                    </Row>
                </Col>

                <Col sm="4">
                    <Image src={(props.imaProd === "nessuna immagine" || props.imaProd === "") ? immagineNonDisponibile : props.imaProd} className="immagineProdotto" />
                </Col>
            </Row>
            <Row className="dettPerFarmacie">
                <Col sm="3" className="titoloDett">Prezzo:</Col>
                {props.prezzoFarmacia[0] ? <Col sm="3" className="valoreDett"> {cambiaFormatoValuta(props.prezzoFarmacia[0].prezzoFarmacia)} </Col> : <Col sm="3" />}
                <Col sm="3" className="titoloDett">Data Inizio:</Col>
                {props.prezzoFarmacia[0] ? <Col sm="3" className="valoreDett"> {cambiaFormatoLocalDateTimeToEuropeo(props.prezzoFarmacia[0].dataInizio)} </Col> : <Col sm="3" />}

                <Col sm="3" className="titoloDett">Prezzo Promozione:</Col>
                {props.prezzoPromozione[0] ? <Col sm="3" className="valoreDett"> {cambiaFormatoValuta(props.prezzoPromozione[0].prezzoPromozione)} </Col> : <Col sm="3" />}
                <Col sm="3" className="titoloDett">Data Inizio:</Col>
                {props.prezzoPromozione[0] ? <Col sm="3" className="valoreDett"> {cambiaFormatoLocalDateTimeToEuropeo(props.prezzoPromozione[0].dataInizio)} </Col> : <Col sm="3" />}

                <Col sm="3" className="titoloDett">Costo Medio:</Col>
                {props.costoMedioDett[0] ? <Col sm="3" className="valoreDett"> {cambiaFormatoValuta(props.costoMedioDett[0].costoMedio)} </Col> : <Col sm="3" />}
                <Col sm="3" className="titoloDett">Costo Medio Ponderato:</Col>
                {props.costoMedioDett[0] ? <Col sm="3" className="valoreDett"> {cambiaFormatoValuta(props.costoMedioDett[0].costoMedioPonderato)} </Col> : <Col sm="3" />}

                <Col sm="3" className="titoloDett">Lotto Riordino:</Col>
                {props.lottoRiordino ? <Col sm="3" className="valoreDett"> {props.lottoRiordino} </Col> : <Col sm="3" />}
                <Col sm='6' />

                <Col sm="3" className="titoloDett noRiga">Giacenza Vendibili:</Col>
                {props.giacenzaDett[0] ? <Col sm="3" className="valoreDett noRiga"> {props.giacenzaDett[0].giacenzaVendibili} </Col> : <Col sm="3" className="noRiga" />}
                <Col sm="3" className="titoloDett noRiga">Giacenza Invendibili:</Col>
                {props.giacenzaDett[0] ? <Col sm="3" className="valoreDett noRiga"> {props.giacenzaDett[0].giacenzaInvendibili} </Col> : <Col sm="3" className="noRiga" />}


                <Col sm='12' className="tabMagazzinoGiacDett noRiga">
                    <TabellaSemplice propsTabella={propsTabGiacMagazz} columns={columnsGiacMagazz} />
                </Col>
            </Row>
        </>
    )
}

export const TabFarmaco = (props: { atcHierarchy: OggettoInterface }) => {
    let chiavi = Object.keys(props.atcHierarchy)
    let valori = Object.values(props.atcHierarchy)

    function ListaChiavi() {
        const listaChiavi = chiavi.map((chiave, index) =>
            <li key={chiave[index]}>{chiave}</li>
        )
        return (
            <ul>{listaChiavi}</ul>
        )
    }
    function ListaValori() {
        const listaValori = valori.map((valore, index) =>
            <li key={valore[index]}>{valore}</li>
        )

        return (
            <ul>{listaValori}</ul>
        )
    }

    return (
        <Row className="atcHierarchy">
            <Col sm="12" className="titoloGerarchia">ATC / G.M.P.</Col>
            <Col sm="3">
                <ListaChiavi />
            </Col>
            <Col sm="9">
                <ListaValori />
            </Col>
        </Row>
    )
}

export const TabStorico = (props: { ultimoCosto: CostoInterface[] }) => {
    const propsTabUltimoCosto: TabellaSempliceInterface = {
        filterRow: false,
        id: "banca_dati_ultimo_costo",
        dataSource: props.ultimoCosto
    }
    const columnsUltimoCosto = CreaColonne([
        { caption: "Data Carico", dataField: "dataCarico", sortOrder: "asc", format: 'dd/MM/yyyy HH:mm:ss', dataType: "date" },
        { caption: "Ragione Sociale", dataField: "ragioneSociale" },
        { caption: "Data Bolla Fornitore", dataField: "dataBollaFornitore", format: 'dd/MM/yyyy', dataType: "date" },
        { caption: "Quantità", dataField: "quantita" },
        { caption: "Quantità Omaggio", dataField: "quantitaOmaggio" },
        { caption: "Costo Ultimo Fornitore (€)", dataField: "costoUltimoFornitore", format: { currency: "EUR" } },
    ]);

    return (
        <>
            <Row className="tabUltimoCosto">
                <Col sm='12'>
                    <TabellaSemplice propsTabella={propsTabUltimoCosto} columns={columnsUltimoCosto} />
                </Col>
            </Row>

        </>
    )
}

export const TabPrezzoStorico = (props: { prezzoStorico: PrezziStoriciInterface[] }) => {
    const propsTabPrezzoStorico: TabellaSempliceInterface = {
        filterRow: false,
        id: "banca_dati_prezzo_storico",
        dataSource: props.prezzoStorico
    }
    const columnsPrezzoStorico = CreaColonne([
        { caption: "Prezzo Farmacia (€)", dataField: "prezzoFarmacia", sortOrder: "asc", format: { currency: "EUR" } },
        { caption: "Data Inizio", dataField: "dataInizio", format: 'dd/MM/yyyy', dataType: "date" },
        { caption: "Data Fine", dataField: "dataFine", format: 'dd/MM/yyyy', dataType: "date" }
    ]);

    return (
        <>
            <Row className="tabPrezzoStorico">
                <Col sm='6'>
                    <TabellaSemplice propsTabella={propsTabPrezzoStorico} columns={columnsPrezzoStorico} />
                </Col>
            </Row>

        </>
    )
}

export const TabMovimentazione = (props: { caricoDett: CaricoDettInterface[], venditaDett: VenditaDettInterface[] }) => {
    const propsTabMovimVendite: TabellaSempliceInterface = {
        filterRow: false,
        id: 'banca_dati_movimentazione_vendite',
        dataSource: props.venditaDett
    }
    const columnsMovimVendite = CreaColonne([
        { caption: "Data Ultima Vendita", dataField: "dataUltimaVendita", format: 'dd/MM/yyyy HH:mm:ss', dataType: "date" },
        { caption: "Data Prima Vendita", dataField: "dataPrimaVendita", format: 'dd/MM/yyyy HH:mm:ss', dataType: "date" },
        { caption: "Venduto Mese In Corso", dataField: "vendutoMeseInCorso" },
        { caption: "Venduto Mese Precedente", dataField: "vendutoMesePrecedente" },
        { caption: "Venduto Anno In Corso", dataField: "vendutoAnnoInCorso" },
        { caption: "Venduto Anno Precedente", dataField: "vendutoAnnoPrecedente" }
    ]);

    const propsTabMovimCarichi: TabellaSempliceInterface = {
        filterRow: false,
        id: 'banca_dati_movimentazione_carichi',
        dataSource: props.caricoDett
    }
    const columnsMovimCarichi = CreaColonne([
        { caption: "Data Ultimo Carico", dataField: "dataUltimoCarico", format: 'dd/MM/yyyy HH:mm:ss', dataType: "date" },
        { caption: "Data Primo Carico", dataField: "dataPrimoCarico", format: 'dd/MM/yyyy HH:mm:ss', dataType: "date" },
        { caption: "Caricato Mese In Corso", dataField: "caricatoMeseInCorso" },
        { caption: "Caricato Mese Precedente", dataField: "caricatoMesePrecedente" },
        { caption: "Caricato Anno In Corso", dataField: "caricatoAnnoInCorso" },
        { caption: "Caricato Anno Precedente", dataField: "caricatoAnnoPrecedente" }
    ]);

    return (
        <>
            <Row className="tabMovimentoVendite">
                <Col sm='12'>
                    <TabellaSemplice propsTabella={propsTabMovimVendite} columns={columnsMovimVendite} />
                </Col>
            </Row>
            <Row className="tabMovimentoCarichi">
                <Col sm='12'>
                    <TabellaSemplice propsTabella={propsTabMovimCarichi} columns={columnsMovimCarichi} />
                </Col>
            </Row>
        </>
    )
}

export const TabOfferte = (props: { offerte: OfferteInterface[] }) => {
    const propsTabOfferte: TabellaSempliceInterface = {
        filterRow: false,
        id: "banca_dati_offerte_prodotto",
        dataSource: props.offerte
    }
    const columnsOfferte = CreaColonne([
        { caption: "Ragione Sociale", dataField: "ragioneSociale" },
        { caption: "Data Inizio", dataField: "dataInizio", sortOrder: "asc" },
        { caption: "Data Fine", dataField: "dataFine" },
        { caption: "Costo Unitario (€)", dataField: "costoUnitario", format: { currency: "EUR" } },
        { caption: "Costo Unitario Netto Sconti (€)", dataField: "costoUnitarioNettoSconti", format: { currency: "EUR" } },
        { caption: "Quantità Omaggi", dataField: "quantitaOmaggi" },
        { caption: "Scaglione Omaggi", dataField: "scaglioneOmaggi" },
        { caption: "Sconto 1", dataField: "sconto1", format: { currency: "EUR" } },
        { caption: "Sconto 2", dataField: "sconto2", format: { currency: "EUR" } },
        { caption: "Sconto 3", dataField: "sconto3", format: { currency: "EUR" } },
        { caption: "Sconto 4", dataField: "sconto4", format: { currency: "EUR" } },
        { caption: "Sconto 5", dataField: "sconto5", format: { currency: "EUR" } },
    ]);

    return (
        <>
            <Row className="tabOfferte">
                <Col sm='12'>
                    <TabellaSemplice propsTabella={propsTabOfferte} columns={columnsOfferte} />
                </Col>
            </Row>

        </>
    )
}