import React, { useCallback, useEffect, useRef, useState } from "react";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { TabellaCompletaInterface, TabellaSempliceInterface } from "../../interfaces/tabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { Loading } from "../../widget/Notifications";
import { basenameHostNewTabs, urlAnagrafiche, urlMonitoraggio } from "../costanti";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaInfoFarmacia, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { RestServices } from "../../services/restServices";
import notify from 'devextreme/ui/notify';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { AnaFarmacia, FarmaciaSelezionataNewsletter } from "../../interfaces/newsletter";
import Button from 'react-bootstrap/esm/Button';
import ReactDOM from "react-dom";
import { DialogClienteDoppioClient } from "./Dialog";

const ElencoClientiDoppioClient = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        startCerca();
    }, []);

    const titolo = TitoloContenuto("Elenco clienti con doppio client");
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [elencoFarmacie, setElencoFarmacie] = useState<AnaFarmacia[]>();
    const [venduto, setVenduto] = useState<any[]>();
    const [dialogClienteDoppioClientOpen, setDialogClienteDoppioClientOpen] = useState(false);


    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        RestServices().rest('POST', param, urlAnagrafiche.getAnagraficaClientiDoppioClient)
            .then(result => {
                setIsVisible(false);
                if (result && result.length > 0) {
                    setElencoFarmacie(result);
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const propsTabElencoFarmacie: TabellaCompletaInterface = {
        id: "elenco_info_farmacie",
        dataSource: elencoFarmacie,
        selection: "single",
        autoWidth: true,
        defaultFilterValue: ["platinum", "=", true],
        //height: function () {
        //    return window.innerHeight / 1.2;
        //},
        headerFilterVisible: true,
        summary: {

            totalItems: [{
                name: "TotaleFarmacie",
                showInColumn: "Cod.CSF",
                displayFormat: "Tot.: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoInfoFarmacie"
        }
    }

    const apriNuovaNewsletter = () => {

        //var keys = tabellaInstance.instance.getSelectedRowKeys();
        //if (keys.length == 0) {
        //    setIsVisible(false);
        //    notify({ position: "center", width: "auto", message: "Selezionare almeno una farmacia" }, "error", 4000);
        //}
        //else {

        //    var item;
        //    var farmacie = new Array();
        //    for (var i = 0; i < keys.length; i++) {
        //        item = { idCli: keys[i].idCliente, codTph: keys[i].codiceTelepharma, email: keys[i].email };
        //        farmacie.push(item);
        //    }
        //    setFarmacieSelezionate(farmacie);
        //    setIsDialogNuovaNewsletterOpen(true);
        //}


    }

    const apriStoricoNewsletter = () => {

        window.open(basenameHostNewTabs + "newsletter/ElencoNewsletter")
    }

    const apriImmaginiNewsletter = () => {
        window.open(basenameHostNewTabs + "newsletter/ElencoFileNewsletter")
    }

    const columnsFarmacie = CreaColonne([
        { caption: "IdCliente", dataField: "idCliente", visible: false },
        { caption: "IdMongo", dataField: "_id", visible: false },
        { caption: "Cod.CSF", dataField: "codiceCliente", visible: true },
        { caption: "Cod.Tph", dataField: "codiceTelepharma", visible: true, sortOrder: "asc" },
        { caption: "Nome server", dataField: "serverMachineName", visible: true, sortOrder: "asc" },
        { caption: "Data ultima vendita", dataField: "lastSaleDate", format: "dd/MM/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Rag.Soc.", dataField: "ragioneSociale" },
        { caption: "Titolare", dataField: "titolare" },
        { caption: "P.Iva", dataField: "partitaIva" },
        { caption: "Email", dataField: "email" },
        { caption: "Regione", dataField: "regione" },
        { caption: "Provincia", dataField: "provincia" },
        { caption: "Comune", dataField: "comune" },
        { caption: "Cap'", dataField: "cap" },
        { caption: "Concessionario", dataField: "concessionario" },
        { caption: "Tipo Anagrafica", dataField: "tipoAnagrafica" },
        { caption: "Ha platinum", dataField: "platinum", visible: true, dataType: "boolean" },
        { caption: "Ha disdetto platinum", dataField: "disdettaPlatinum", visible: true, dataType: "boolean" },
        { caption: "Data termine Platinum", dataField: "dataDisdettaPlatinum", format: "MM/dd/yyyy HH:mm:ss", dataType: "date" },
        { caption: "Ha cloudfarma", dataField: "cloudfarma", visible: true, dataType: "boolean" },
        {
            caption: "Azioni", type: "buttons", width: 90, fixed: false, buttons: [

                {
                    hint: "Informazioni vendite",
                    icon: "info",
                    onClick: (e: any) => {
                        const _id = e.row.data._id;
                        const param = {};
                        RestServices().rest('POST', param, urlAnagrafiche.getAnagraficaClientiDoppioClientDettaglio + "?id=" + _id)
                            .then(result => {
                                setIsVisible(false);
                                if (result) {
                                    if (result.Ok === true) {
                                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                                        setVenduto(result)
                                    } else {
                                        setVenduto(result);
                                    }
                                }
                                else if (result && result.message && result.severity) {
                                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                                } else {
                                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                                }
                            })
                        setDialogClienteDoppioClientOpen(true);
                    }
                }]
        }

    ])

    const columnsClienteDoppioClient = CreaColonne([
        { caption: "Anno", dataField: "ANNO", alignment: "right" , sortOrder:"asc"},
        { caption: "Gennaio", dataField: "Gennaio", alignment: "right" },
        { caption: "Febbraio", dataField: "Febbraio", alignment: "right" },
        { caption: "Marzo", dataField: "Marzo", alignment: "right" },
        { caption: "Aprile", dataField: "Aprile", alignment: "right" },
        { caption: "Maggio", dataField: "Maggio", alignment: "right" },
        { caption: "Giugno", dataField: "Giugno", alignment: "right" },
        { caption: "Luglio", dataField: "Luglio", alignment: "right" },
        { caption: "Agosto", dataField: "Agosto", alignment: "right" },
        { caption: "Settembre", dataField: "Settembre", alignment: "right" },
        { caption: "Ottobre", dataField: "Ottobre", alignment: "right" },
        { caption: "Novembre", dataField: "Novembre", alignment: "right" },
        { caption: "Dicembre", dataField: "Dicembre", alignment: "right" },
    ])

    const propsClienteDoppioClient: TabellaSempliceInterface = {
        id: "clienteDoppioClient",
        dataSource: venduto,
        pagination: 10,
        filterRow: false,
    }

    const onSelectionChanged = (e: any) => {
        var selectedRows = e.selectedRowKeys;

        selectedRows.forEach((row: any) => {
            if (row.disdettaNewsletter == true)
                e.component.deselectRows(row); return;
        })
    }

    const onContentReady = (function (e: any) {


        //const node = ReactDOM.findDOMNode(null);
        //console.log(node)
        //// Get child nodes
        //if (node instanceof HTMLElement) {
        //    const child = node.querySelector('.dx-page-size');
        //    console.log(child);
        //}

        //var el = e.component.element().find('.dx-page-size').last();
        //el.text('All');
        //el.click(function () {
        //    e.component.pageSize(0);
        //    el.text('All');
        //});
    })

    const onCellPrepared = (e: any) => {

        e.cellElement.style.padding = "3px";
    }



    return (
        <>
            {titolo}
            {loadPanel}
            <div className="contenutoPagina">
                <Row className="tabellaFornitori" id="rigaTabInfoFarmacia">
                    <Col sm="12" className="infoFarmacia">
                        {/*<TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            columnsDetail={columnsFarmacieDettaglio}
                            urlDetail={urlAnagrafiche.getAnagraficaClienteNewsletterDettaglio + '?idCli='}
                            keyDetail={"idCliente"}
                            key={"idCli"}
                        />*/}

                        <TabellaCompletaInfoFarmacia
                            propsTabella={propsTabElencoFarmacie}
                            columns={columnsFarmacie}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            onSelectionChanged={onSelectionChanged}
                            togliFilterPanel={false}
                            searchPanel={true}
                            groupPanel={true}
                            onContentReady={onContentReady}
                            onCellPrepared={onCellPrepared}

                        />
                    </Col>
                </Row>
            </div>

            <DialogClienteDoppioClient
                setDialogClienteDoppioClient={setDialogClienteDoppioClientOpen}
                dialogClienteDoppioClientOpen={dialogClienteDoppioClientOpen}
                column={columnsClienteDoppioClient}
                propsTab={propsClienteDoppioClient}
                titolo="Dettaglio vendite cliente"
            />
        </>
    )

}

export default ElencoClientiDoppioClient;