import { AplicaValoreTO } from "../../../../funzioni/operazioniSuTabelle";

export  const colAnalisiVendiCateg = [
  {
    caption: "Category ",
    dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
    width: "auto",
    fixed: true
  },
  {
    caption: "Incidenza % valore venduto ",
    dataField: "INCIDENZA_VALORE_VENDUTOCR",
    width: "180px",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Incidenza % valore del venduto indipendente da livello di navigazione",
    dataField: "INCIDENZA_VALORE_VENDUTOCR",
    width: "180px",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  
  {
    caption: "Valore del venduto periodo selezione",
    dataField: "VALORE_VENDUTOCR",
    width: "auto",
    format: { type: "currency", precision: 2, currency: "EUR" },
  },
  {
    caption: "Valore del venduto anno precedente",
    dataField: "VALORE_VENDUTOAP",
    width: "auto",
    format: { type: "currency", precision: 2, currency: "EUR" },
  },
  {
    caption: "Delta valore del venduto anno precedente",
    dataField: "VALORE_VENDUTO_PUNTUALEAP",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Delta valore del venduto equiparato",
    dataField: "",
    width: "180px",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Margine periodo selezionato",
    dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
    width: "180px",
    format: { type: "currency", precision: 2, currency: "EUR" },
  },
  {
    caption: "Delta margine anno precedente",
    dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Delta margine equiparato",
    dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Margine % periodo selezione",
    dataField: "MARGINECR",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Delta margine percentuale",
    dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Volume di vendita periodo seleziona",
    dataField: "MARGINE_EQUIPARATOCR",
    width: "auto",
  },
  {
    caption: "Delta volume di vendita",
    dataField: "MARGINE_PERCENTUALECR",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Delta volume di vendita equiparato",
    dataField: "MARGINE_PERCENTUALEAP",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  
];


export const colAnalisiOpertaori = [
  {
    caption: "Operatore",
    dataField: "OPERATORE_USERNAME",
    width: "auto",
    fixed: true,
  },
  {
    caption: "Giorni lavorati",
    dataField: "GIORNI_LAVORATI",
    width: "auto",
  },
  {
    caption: "Ore lavorate",
    dataField: "ORE_LAVORATE",
    width: "auto",
  },
  {
    caption: "Media oraria giornaliera",
    dataField: "VALORE_VENDUTO",
    width: "auto",
    customizeText: (cellInfo: any) =>
    cellInfo?.value?.toFixed(2),
  },
  {
    caption: "Numero vendite",
    dataField: "NUMERO_VENDITE",
    width: "auto",
  },
  {
    caption: "Numero di righe di vendita",
    dataField: "NUMERO_RIGHE",
    width: "auto",
  },
  {
    caption: "% Vendite SSN",
    dataField: "PERC_VENDUTO_SSN",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "% Vendite Miste",
    dataField: "PERC_VENDITE_MISTE",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "% Vendite Libere",
    dataField: "PERC_VENDITE_LIBERE",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Media righe vendita",
    dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
    width: "auto",
    customizeText: (cellInfo: any) =>
    cellInfo?.value?.toFixed(2),
  },
  {
    caption: "Media oraria righe di vendita",
    dataField: "MEDIA_ORARIA_RIGHE_VENDITA",
    width: "auto",
    customizeText: (cellInfo: any) =>
    cellInfo?.value?.toFixed(2),
  },
  {
    caption: "Valore del venduto",
    dataField: "VALORE_VENDUTO",
    width: "auto",
    format: { type: "currency", precision: 2, currency: "EUR" },
  },
  {
    caption: "Incidenza % valore del venduto",
    dataField: "INCIDENZA_VENDUTO",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Media oraria valore del venduto",
    dataField: "MEDIA_ORARIA_VALORE_VENDUTO",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "% Venduto SSN",
    dataField: "PERC_VENDUTO_SSN",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "% Venduto Vendita libera",
    dataField: "PERC_VENDUTO_VENDITA_LIBERA",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Margine",
    dataField: "MARGINE",
    width: "auto",
    format: { type: "currency", precision: 2, currency: "EUR" },
  },
  {
    caption: "Incidenza % margine",
    dataField: "INCIDENZA_MARGINE",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Margine percentuale",
    dataField: "??",
    width: "auto",
    customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
  },
  {
    caption: "Pezzi",
    dataField: "PEZZI",
    width: "auto",
  },
  {
    caption: "Valore vendita medio",
    dataField: "VALORE_VENDITA_MEDIO",
    width: "auto",
    format: { type: "currency", precision: 2, currency: "EUR" },
  },
];

export  const colAnalisimagazzino = [
    {
      caption: "Tipologia prodotto ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % valore venduto Ivato",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto Ivato periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto ivato equiparato",
      dataField: "",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezione",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine % periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume vendita periodo selezione",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
     
    },
    {
      caption: "Volume vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "80px",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "80px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const colAnalisimagazzino2 = [
    {
      caption: "Tipologia prodotto ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % valore venduto Ivato",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore del venduto Ivato periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto Ivato anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto ivato equiparato",
      dataField: "",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine periodo selezione",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Margine anno precedente",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine % periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine anno precedente",
      dataField: "MARGINEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
      AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale periodo selezione",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale anno precedente",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume vendita periodo selezione",
      dataField: "VOLUME_VENDITACR",
      width: "auto",
     
    },
    {
      caption: "Volume vendita anno precedente",
      dataField: "VOLUME_VENDITAAP",
      width: "80px",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "VOLUME_VENDITA_PUNTUALECR",
      width: "80px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const colOsserMargini = [
    {
      caption: "Farmacia ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Margini",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % margine",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine %",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    }, 
    {
      caption: "Sconto percentuale sul fatturato",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
   
  ];

  export  const colOsserMargini2 = [
    {
      caption: "Mese ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Numero giorni lavorati",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "auto",
    },
    {
      caption: "Valore del venduto ",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Imponibile",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta equiparato imponibile sul mese prec.",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine",
      dataField: "",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta equiparato margine sul mese prec.",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine percentuale",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale sul mese prec.",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Pezzi venduti",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta equiparato pezzi venduti sul mese prec.",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Costo medio pezzo",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta costo medio pezzo sul mese prec.",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valore medio pezzo",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTICR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore medio pezzo sul mese prec.",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];

  export  const colAnalVendfasciaOraria = [
    {
      caption: "Giorno della settimana ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Fascia oraria",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
    },
    {
      caption: "Numero operatore consigliati   ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
    },
   
    
  ];

  export  const colAnalVendfasciaOraria2 = [
    {
      caption: "Giorno della settimana ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % valore venduto ",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Incidenza % valore del venduto indipendente da livello di navigazione",
      dataField: "INCIDENZA_VALORE_VENDUTOCR",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    
    {
      caption: "Valore del venduto periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Valore del venduto anno precedente",
      dataField: "VALORE_VENDUTOAP",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta valore del venduto anno precedente",
      dataField: "VALORE_VENDUTO_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta valore del venduto equiparato",
      dataField: "",
      width: "180px",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine periodo selezionato",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "180px",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta margine anno precedente",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine equiparato",
      dataField: "IMPONIBILE_SSN_NETTO_SCONTIAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine % periodo selezione",
      dataField: "MARGINECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta margine percentuale",
      dataField: "MARGINE_PERCENTUALE_PUNTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Volume di vendita periodo seleziona",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
    },
    {
      caption: "Delta volume di vendita",
      dataField: "MARGINE_PERCENTUALECR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta volume di vendita equiparato",
      dataField: "MARGINE_PERCENTUALEAP",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    
  ];

  export  const colAnalMagazzino = [
    {
      caption: "Ditta ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Descrizione ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Giacenza attuale",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
    },
    {
      caption: "Incidenza % su Giacenza attuale  ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valorizzazione attuale magazzino ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % su Valorizzazione attuale magazzino ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
    
  ];

  export  const colAnalMagazzino2 = [
    {
      caption: "Category",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Descrizione",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Giacenza attuale",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },
    {
      caption: "Incidenza % su Giacenza fine mese ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Valorizzazione attuale magazzino ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % su Valorizzazione attuale magazzino ",
      dataField: "MARGINE_EQUIPARATOCR",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
  ];

  export  const colAnalisiSconti = [
    {
      caption: "Category ",
      dataField: "PRO_DESC_GRUPPO_MERCEOLOGICO1AP",
      width: "auto",
      fixed: true
    },  
    {
      caption: "Valore dello sconto periodo selezione",
      dataField: "VALORE_VENDUTOCR",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza percentuale valore sconto",
      dataField: "IMPONIBILE_SSN_LORDO_SCONTICR",
      width: "auto",
    },
  ];

  export const colAnalisiMercato = [
    {
      caption: "Provincia",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Prodotto",
      dataField: "GIORNI_LAVORATI",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Prezzo medio",
      dataField: "ORE_LAVORATE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Prezzo max. ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Prezzo min.",
      dataField: "NUMERO_VENDITE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Incidenza % a valore al totale provincia",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
  ];
  
  export const colAnalisiVenditeFarm = [
    {
      caption: "Codice Farmacia",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Category Lv. 3",
      dataField: "GIORNI_LAVORATI",
      width: "auto",
    },
    {
      caption: "Category Lv. 4",
      dataField: "ORE_LAVORATE",
      width: "auto",
    },
    {
      caption: "Ditta ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
    },
    {
      caption: "Prodotto",
      dataField: "NUMERO_VENDITE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Iva",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Volume di vendita",
      dataField: "NUMERO_VENDITE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Volume di vendita anno prec.",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Delta % volume di vendita",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Prezzo medio",
      dataField: "ORE_LAVORATE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Prezzo medio anno prec.",
      dataField: "ORE_LAVORATE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Delta % prezzo medio",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine perc.",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Margine perc. anno prec.",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta % margine percentuale",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Sconto percentuale",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta % Sconto percentuale",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },



  ];
  export const colAnalisiVendiGirnDitta = [
    {
      caption: "Codice",
      dataField: "OPERATORE_USERNAME",
      width: "auto",
      fixed: true,
    },
    {
      caption: "Ditta",
      dataField: "GIORNI_LAVORATI",
      width: "auto",
    },
    {
      caption: "Prodotto Min-San",
      dataField: "ORE_LAVORATE",
      width: "auto",
    },
    {
      caption: "Pezzi venduti ",
      dataField: "VALORE_VENDUTO",
      width: "auto",
    },
    {
      caption: "Prodotto",
      dataField: "NUMERO_VENDITE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Iva",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Volume di vendita",
      dataField: "NUMERO_VENDITE",
      width: "auto",
      format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
      caption: "Volume di vendita anno prec.",
      dataField: "NUMERO_VENDITE",
      width: "auto",
    },
    {
      caption: "Delta % volume di vendita",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
   
    {
      caption: "Delta % prezzo medio",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
      caption: "Delta % Sconto percentuale",
      dataField: "PERC_VENDITE_SSN",
      width: "auto",
      customizeText: (cellInfo: any) =>
        AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },



];


export const columnsRepVenditeEffettuate = [
    {
        caption: "Mese ed anno",
        dataField: "ANNOMESE",
        customizeText: (cellInfo: any) => {
            const stringValue = cellInfo.value.toString();
            const lastTwoDigits = stringValue.slice(-2);
            const remainingString = stringValue.slice(0, -2);
            const formattedString = `${lastTwoDigits}/${remainingString}`;

            return formattedString;
        },
        sortOrder: 'desc'
    },
    {
        caption: "Numero vendite",
        dataField: "NUMERO_VENDITE",
        width: "auto",
    },
    {
        caption: "Vendite scontrino",
        dataField: "VENDITE_SCONTRINO",
        width: "auto",
    },
    {
        caption: "Percentuale vendite scontrino",
        dataField: "VENDITE_SCONTRINO_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Vendite SSN",
        dataField: "VENDITE_SSN",
        width: "auto",
    },
    {
        caption: "Vendite miste",
        dataField: "VENDITE_MISTE",
        width: "auto",
    },
    {
        caption: "Vendite libere",
        dataField: "VENDITE_LIBERE",
        width: "auto",
    },
    {
        caption: "Pezzi",
        dataField: "PEZZI",
        width: "auto",
    },
    {
        caption: "Media pezzi di vendita",
        dataField: "NUMERO_PEZZI_VENDITA_MEDIO",
        width: "auto",
        customizeText: (cellInfo: any) =>
            cellInfo?.value?.toFixed(2),
    },
    {
        caption: "Media valore di vendita",
        dataField: "VALORE_VENDITA_MEDIO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Media valore al pezzo",
        dataField: "VALORE_PEZZI_MEDIO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore venduto",
        dataField: "VALORE_VENDUTO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore venduto SSN",
        dataField: "VALORE_VENDUTO_SSN",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Venduto SSN percentuale",
        dataField: "VALORE_VENDUTO_SSN_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Valore venduto libera",
        dataField: "VALORE_VENDUTO_LIBERA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Venduto libera percentuale",
        dataField: "VALORE_VENDUTO_LIBERA_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Margine",
        dataField: "MARGINE",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine SSN",
        dataField: "MARGINE_SSN",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine libera",
        dataField: "MARGINE_LIBERA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine percentuale",
        dataField: "MARGINE_PERCENTUALE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
];


export const columnsRepVenditeEffettuate2 = [
    {
        caption: "Codice",
        dataField: "ID_FARMACIA",
        width: "auto",
    },
    {
        caption: "Farmacia",
        dataField: "FARM_DESCRIZIONE",
        width: "auto",
        sortOrder: "asc"
    },
    {
        caption: "Giorno",
        dataField: "GIORNO",
        width: "auto",
        sortOrder: "asc"
    },
    {
        caption: "Giorno settimana",
        dataField: "GIORNO_SETTIMANA_TEXT",
        width: "auto",
    },
    {
        caption: "Numero vendite",
        dataField: "NUMERO_VENDITE",
        width: "auto",
    },
    {
        caption: "Vendite scontrino",
        dataField: "VENDITE_SCONTRINO",
        width: "auto",
    },
    {
        caption: "Percentuale vendite scontrino",
        dataField: "PERC_VENDITE_SCONTRINO",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Vendite SSN",
        dataField: "VENDITE_SSN",
        width: "auto",
    },
    {
        caption: "Vendite miste",
        dataField: "VENDITE_MISTE",
        width: "auto",
    },
    {
        caption: "Vendite libere",
        dataField: "VENDITE_LIBERE",
        width: "auto",
    },
    {
        caption: "Pezzi",
        dataField: "PEZZI",
        width: "auto",
    },
    {
        caption: "Media pezzi di vendita",
        dataField: "NUMERO_MEDIO_PEZZI_VENDITA",
        width: "auto",
        customizeText: (cellInfo: any) =>
            cellInfo?.value?.toFixed(2),
    },
    {
        caption: "Media valore di vendita",
        dataField: "VALORE_MEDIO_VENDITA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Media valore al pezzo",
        dataField: "VALORE_MEDIO_PEZZO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore venduto",
        dataField: "VALORE_VENDUTO",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Valore venduto SSN",
        dataField: "VALORE_VENDUTO_SSN",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Venduto SSN percentuale",
        dataField: "PERC_VENDUTO_SSN",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Valore venduto libera",
        dataField: "VALORE_VENDUTO_LIBERA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Venduto libera percentuale",
        dataField: "PERC_VENDUTO_LIBERA",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
    {
        caption: "Margine",
        dataField: "MARGINE",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine SSN",
        dataField: "MARGINE_SSN",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine libera",
        dataField: "MARGINE_LIBERA",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
    },
    {
        caption: "Margine percentuale",
        dataField: "PERC_MARGINE",
        width: "auto",
        customizeText: (cellInfo: any) =>
            AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
    },
];