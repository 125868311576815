import React, { useState } from 'react'
import { TabPanel, Item } from 'devextreme-react/tab-panel';
import { ElementOptionChanged } from '../../interfaces/componentOptions';
import { ConfigurazioneTrasferimentiFarmaciaInterface } from '../../interfaces/trasferimenti';
import { TabCosto, TabDisponibilita, TabFarmacieAbilitate, TabFarmacieAbilitateV2, TabListino } from './Tabs';
import { OggettoInterface } from '../../interfaces/generiche';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';

export const PannelloTab = (props: {
    parametriDisponibilita?: ConfigurazioneTrasferimentiFarmaciaInterface[],
    parametriCosto?: ConfigurazioneTrasferimentiFarmaciaInterface[],
    farmacieAbilitate?: number[],
    listinoInVigore?: string,
    abilitaListino?: boolean,
    datiFarmacie: ListaFarmacieInterface[],
    impostaFiltri: (newFiltro: object) => void,
    anagrafiche?: any[],
    idFarm?: number;
}) => {
    //permette di cambiare tab visualizzato a seconda di quale titolo si clicca
    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    return (
        <TabPanel
            id="tabPanelConfigurazioneParametri"
            selectedIndex={selectedIndex}
            loop={false}
            animationEnabled={true}
            onOptionChanged={onOptionChanged}
        >
            <Item title="Impostazione disponibilita'">
                <TabDisponibilita
                    parametriDisponibilita={props.parametriDisponibilita}
                    impostaFiltri={props.impostaFiltri}
                />
            </Item>
            <Item title="Impostazione costo">
                <TabCosto
                    parametriCosto={props.parametriCosto}
                    impostaFiltri={props.impostaFiltri}
                    datiFarmacie={props.datiFarmacie }
                />
            </Item>
            {/* <Item title="Farmacie abilitate">
                <TabFarmacieAbilitate
                    farmacie={props.farmacieAbilitate}
                    impostaFiltri={props.impostaFiltri}
                    datiFarmacie={props.datiFarmacie}
                />
            </Item>*/}
            <Item title="Farmacie abilitate">
                <TabFarmacieAbilitateV2
                    farmacie={props.farmacieAbilitate}
                    impostaFiltri={props.impostaFiltri}
                    datiFarmacie={props.datiFarmacie}
                    anagrafiche={props.anagrafiche}
                    idFarm={props.idFarm}
                />
            </Item>
            <Item title="Impostazione listino">
                <TabListino
                    impostaFiltri={props.impostaFiltri}
                    abilitaListino={props.abilitaListino}
                    listinoInVigore={props.listinoInVigore}
                />
            </Item>
            
             
            
        </TabPanel>
    )
}