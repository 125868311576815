import React from "react";
import { useEffect, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { cambiaFormatoLocalDateTimeToEuropeo } from "../../funzioni/formatoVariabili";
import { NotaRilascioInterface } from "../../interfaces/noteRilascio";
import { RestServices } from "../../services/restServices";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import { urlNoteRilascio } from "../costanti";

const NoteRilascio = (props: {
    toggleMenu(stato: boolean): any,
    statoMenu: boolean
    prodotto: number,
}) => {

    const [note, setNote] = useState<NotaRilascioInterface[]>();
    const titolo = TitoloContenuto("Note di rilascio");

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    },[])

    useEffect(() => {
        const param = {

        };
        RestServices().rest('POST', param, urlNoteRilascio.getNoteRilascio + props.prodotto)
            .then(result => {
                if (result) {
                    setNote(result);
                }
            })
    }, [props.prodotto])

    return (
        <>
            {titolo}
            <div className="contenutoPagina">
                {
                    note?.map((item, index) => {
                        return (
                                <>
                                <Row>
                                    <Col className="titoloNota">Revisione {item.revisioneRilascio} del {cambiaFormatoLocalDateTimeToEuropeo(item.dataRilascio)}</Col>
                                </Row>
                                <ul>
                                {
                                    item.dettagli.map((dett, indexDett) => {
                                        return (<>
                                            <li>
                                            <Row>
                                                <Col className="dettaglioNota">{dett.modulo} : {dett.descrizione}</Col>
                                                    </Row>
                                                </li>
                                                </>)
                                            })
                                    }
                                </ul>
                                <Row>&nbsp;</Row>
                                <Row>&nbsp;</Row>
                                </>
                            )
                    })
                }
            </div>
        </>
    )
}

export default NoteRilascio