import React, { useEffect, useState } from 'react'
import notify from 'devextreme/ui/notify'
import Ricerca from "../../widget/nuova_ricerca"
import { Loading } from '../../widget/Notifications'
import { RestServices } from "../../services/restServices"
import { urlConsultazione, urlOrdiniRda } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { trasformaDateTimeInLocale } from '../../funzioni/formatoVariabili'


import { Filtri } from '../consultazione/interface'
import PivotGrid, { Export, FieldChooser } from 'devextreme-react/pivot-grid'
import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source'



const ReportRda = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const [loading, setLoading] = useState<boolean>(false)
    const auth = useAuth(urlOrdiniRda.auth)
    const authC = useAuth(urlConsultazione.auth)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [dataSourceReport, setDataSourceReport] = useState<any>([])

    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
            //console.log(element);
            newArr.push({ value: element.idFar, label: element.descrizione })

        });
        return newArr
    }

    const itemsFields = [
        { type: 'select2', idValue: 'lstFar', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 350 },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, require: true },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null },
    ]

    const farmaList = () => {
        if (authC) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {
                        // console.log(result);                    
                    }
                })
        }
    }


    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro }
        if (params.dal) {
            params.dal = trasformaDateTimeInLocale(params.dal, true);
            params.al = trasformaDateTimeInLocale(params.al, true);

            if (objFiltro.lstFar) {

                let newIDFAR: any = []
                objFiltro.lstFar.forEach((element: any) => {
                    newIDFAR.push(element.value)
                });
                params.lstFar = newIDFAR

                //console.log( params.idFar);

                if (params.lstFar && params.lstFar.length < 1) {
                    delete params.lstFar
                }

            }

            if (objFiltro.codCsf) {
                params.codCsf = Number(params.codCsf?.id)
            } else {
                delete params.codCsf
            }
            setLoading(true)
            if (auth) {
                RestServices().rest('POST', params, urlOrdiniRda.getReportRda)
                    .then(result => {
                        if (result) {
                            setDataSourceReport(result)
                            setLoading(false)
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                            setLoading(false)
                        }
                    })

            }
        } else {
            let errore = ''
            if (!params.dal) {
                if (errore != '') {
                    errore += ' , '
                }
                errore = errore + 'specificare Dal'
            }
            notify({ position: 'center', width: "auto", message: errore }, "warning", 4000)

        }
    }



    useEffect(() => {
        farmaList()
    }, [authC])

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const summaryDisplayModes = [
        { text: 'None', value: 'none' },
        { text: 'Absolute Variation', value: 'absoluteVariation' },
        { text: 'Percent Variation', value: 'percentVariation' },
        { text: 'Percent of Column Total', value: 'percentOfColumnTotal' },
        { text: 'Percent of Row Total', value: 'percentOfRowTotal' },
        { text: 'Percent of Column Grand Total', value: 'percentOfColumnGrandTotal' },
        { text: 'Percent of Row Grand Total', value: 'percentOfRowGrandTotal' },
        { text: 'Percent of Grand Total', value: 'percentOfGrandTotal' },
    ];

    const dataSource = new PivotGridDataSource({
        fields: [{
            caption: 'Fornitore',
            width: 300,
            dataField: 'ragioneSociale',
            area: 'row',
        },
            {
                caption: 'Prodotto',
                dataField: 'prodotto',
                width: 300,
                area: 'row',
            },
            {
            dataField: 'dataEmissione',
            dataType: 'date',
            area: 'column',
        }, {
                groupName: 'dataEmissione',
            groupInterval: 'month',
                visible: true
            },
            { groupName: "dataEmissione", groupInterval: "quarter", visible:false},

            {
            caption: 'Totale quantita',
            dataField: 'quantita',
            summaryType: 'sum',
            dataType: 'number',
            area: 'data',
            },
            {
                caption: 'Totale costo',
                dataField: 'costo',
                summaryType: 'sum',
                dataType: 'number',
                format: {
                    precision: 2, currency: 'EUR'
                },
                area: 'data',
            }
        ],
        store: dataSourceReport,
    });

    const currencyFormatter = new Intl.NumberFormat(
        'it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2,
    },
    );

    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> REPORT  <span className='subt' > Consultazione Ordini RDA</span> </h3>
            </div>

            <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />

            <PivotGrid
                id="pivotgrid"
                dataSource={dataSource}
                allowSortingBySummary={true}
                allowFiltering={true}
                showBorders={true}
                showColumnTotals={false}
                showColumnGrandTotals={true}
                showRowTotals={false}
                showRowGrandTotals={true}
                
            >
                <FieldChooser enabled={true} height={400} />
                <Export enabled={true} />
            </PivotGrid>
            
        </>
    )
}

export default ReportRda

