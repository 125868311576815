import React, { useState, useRef, useEffect, useMemo } from 'react'
import Autocomplete from "devextreme-react/autocomplete"
import notify from "devextreme/ui/notify"
import { ChiamataApi } from './nuova_ricerca/serviceAuto'
import { OggettoInterface } from '../interfaces/generiche'


interface AutocompleteProps {
    objctTOsave: any
    setObjctTOsave: (value: any) => void,
    idExpr: string,
    descExpr: string,
    idPost: string,
    descPost: string,
    idControl: string,
    typeApi: string,
    idApi: string,
    idOpt?: string,
    descOpt?: string,
    width?: number,
    optionalBoolean?: boolean,
    disabled?:boolean
} 

export const AutocompleteBox: React.FC<AutocompleteProps> = React.memo(({ objctTOsave, setObjctTOsave,  idExpr, descExpr, idPost, descPost, idControl, typeApi, idApi, idOpt, descOpt, width, optionalBoolean, disabled }) => {


    const [objPostRicerca, setObjPostRicerca] = useState<any>({})
    const [autoComp, setAutoComp] = useState<any>([])
    const [loadingGif, setLoadingGif] = useState<any>({})
    const [w, setW] = useState<number>()

    useEffect(() => {
        if (width === undefined)
            setW(300)
        else
            setW(width)
    }, [objctTOsave])
    const populateObjPost = (id: string, value: any) => {
        let nuovoObj = { ...objPostRicerca }
        nuovoObj[id] = value
        if (objPostRicerca.type == 'autocomplete') {

        }
        setObjPostRicerca(nuovoObj)

        let nuovoObjToSave: OggettoInterface = {};

        nuovoObjToSave = { ...objctTOsave }
        if (idOpt)
            nuovoObjToSave[idExpr] = value[idOpt]
        else
            nuovoObjToSave[idExpr] = value.id

        if (descOpt)
            nuovoObjToSave[descExpr] = value[descOpt]
        else
            nuovoObjToSave[descExpr] = value.descrizione

        //objctTOsave(nuovoObjToSave)

        setObjctTOsave(nuovoObjToSave)

        //objctTOsave.idProvincia = value.id
        //objctTOsave.descrizioneProvincia = value.descrizione
        //setObjctTOsave(nuovoObjToSave)

    }

    const remove = (id: string) => {

        let nuovoObj: OggettoInterface = {};
        nuovoObj = { ...objctTOsave }

        delete nuovoObj[id]

        //objctTOsave(nuovoObj)
        setObjctTOsave(nuovoObj)

        
    }

    const actions = async (string: string, typeApi: string, idVal?: string) => {
        let loadingg = { ...loadingGif }
        if (idVal) {
            loadingg[idVal] = true
            setLoadingGif(loadingg)
        }


        let risulatoCall = await ChiamataApi(string, typeApi, optionalBoolean)
        if (risulatoCall) {
            if (risulatoCall?.esito) {
                //console.log(risulatoCall.response);
                setAutoComp(risulatoCall.response)
                if (idVal) {
                    loadingg[idVal] = false
                    setLoadingGif(loadingg)
                }
            } else {
                if (idVal) {
                    loadingg[idVal] = false
                    setLoadingGif(loadingg)
                }
                notify({ position: 'center', width: "auto", message: risulatoCall.message }, "error", 4000)

            }
        }

    }

    const createAutocomplete = () => {
        //console.log(objctTOsave)
        return (
            (objctTOsave && objctTOsave[idExpr])  ?
                            (
                                <div style={{ width: w }}>
                                    <div className='autoBtn'>
                                        <div className='troncamento'>
                                        {objctTOsave[descExpr] ? objctTOsave[descExpr] : objPostRicerca[idPost][descPost]}   
                                        </div>
                                        {!disabled && <button onClick={() => remove(idExpr)} style={{ border: 'none', fontSize: 14, background: '#fff', color: 'black', borderRadius: 10, marginLeft: 3, height: 18, width: 18 }}>x</button>}
                                    </div>
                                </div>
                            )
                            :
                            (
                                <div style={{ width: w}} >
                                    <div style={{ position: 'relative' }}>
                            <Autocomplete
                                disabled={disabled}
                                            valueChangeEvent="keyup"
                                            placeholder="Per favore inserisci 2 o piu' caratteri"
                                            displayExpr={idPost}
                                            valueExpr={descPost}
                                            dataSource={autoComp}
                                            onItemClick={(v) => populateObjPost(idPost, v.itemData)}
                                            onValueChanged={(p) => actions(p.value, typeApi, idApi)} />
                                            {loadingGif[idPost] ? (
                                                <img style={{ width: 30, height: 30, position: 'absolute', top: 3, right: 2 }} src='https://media3.giphy.com/media/3oEjI6SIIHBdRxXI40/200w.gif?cid=82a1493bvt3ie5rmhndsmobp0937yl13qq2ns64iczpazc4e&rid=200w.gif&ct=g' />
                                            ) : null}
                                    </div>
                                </div>
                            )
            
            )
    }

    return (
        <>
            {createAutocomplete()}
        </>
    )
})