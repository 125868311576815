import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { ElementOptionChanged } from '../../interfaces/componentOptions';
import { RestServices } from '../../services/restServices';
import { Loading } from "../../widget/Notifications";
import { basenameHost, urlOrdiniNso } from '../costanti';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/esm/Modal';
import { TabellaSemplice } from '../../widget/Tabelle';
import { ElencoOrdiniInterface } from '../../interfaces/ordiniNso';
import notify from 'devextreme/ui/notify';

export const DialogSto = (props: {
    dialogStoIsOpen: boolean,
    idOrdine: number | string | undefined,
    numeroDocumento: string | undefined,
    handleCloseSto: () => void,
}) => {

    const [storicoDocumento, setStoricoDocumento] = useState<ElencoOrdiniInterface[]>();
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    useEffect(() => {
        if (props.dialogStoIsOpen)
            cerca();
    }, [props.dialogStoIsOpen])

    const handleClose = () => {
        props.handleCloseSto();
    }

    const cerca = () => {
        if (props.idOrdine != undefined) {
            setIsVisible(true);
            const param = {
                idOrdCF: props.idOrdine
                //numeroDocumento: filtri.numeroDocumento
            }
            RestServices().rest('POST', param, urlOrdiniNso.getListStoricoDocumentoNso).then(result => {
                if (result) {
                    if (result.esito) {
                        setStoricoDocumento(result.response);
                        setIsVisible(false);
                    }
                    else {
                        notify({ position: "center", width: "auto", message: result.message }, 'error', 4000);
                        setIsVisible(false);
                    }
                }
                else {
                    notify({ position: "center", width: "auto", message: "Oops c'� stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                    setIsVisible(false);
                }
                    

            })
        }
    }

    const propsTabStoricoDocumento = {
        id: "storico",
        dataSource: storicoDocumento,
        selection: "single",
        export: { enabled: false },
        defaultFilterValue: null,
        headerFilterVisible: false,
        enablePagination: false,
        filterRow: true,
    }

    const columnsStoricoDocumento = CreaColonne([
        { caption: "Stato", dataField: "statoOrdine" },
        { caption: "Tipo", dataField: "tipoDocumento" },
        { caption: "Numero documento", dataField: "numeroDocumento" },
        { caption: "Data documento", dataField: "dataDocumento", format: "dd/MM/yyyy", dataType: "date", sortOrder: "desc" },
        { caption: "Importo", dataField: "importo", format: { currency: "EUR", precision: 2, type: "fixedPoint" } },
        { caption: "Cliente", dataField: "denominazioneCliente" },
        { caption: "Token", dataField: "fileToken", visible: false },
        {
            caption: "Azioni", type: "buttons", buttons: [{
                hint: "Visualizza sorgente",
                icon: "file",
                onClick: (e: any) => {
                    const fileToken = e.row.data.fileToken;
                    window.open(basenameHost + 'cfportal/view/' + fileToken, "_blank");
                }
            },
            {
                hint: "Visualizza documento",
                icon: "contentlayout",
                onClick: (e: any) => {
                    const fileToken = e.row.data.fileToken;
                    window.open(basenameHost + 'cfportal/view/' + fileToken + '_htm', "_blank");
                }
            }
            ]
        }

    ])

    const [selectedIndex, setSelectedIndex] = useState<number>(0)
    const onOptionChanged = (item: ElementOptionChanged) => {
        if (item.name === "selectedIndex") {
            setSelectedIndex(item.value)
        }
    }

    return (
        <Modal
            show={props.dialogStoIsOpen}
            onHide={handleClose}
            dialogClassName="dialogImpostazioniOrdineLibero"
            size="xl"
            centered={true}
            backdrop="static"
        >
            <Modal.Header closeButton>
                <Modal.Title className="titoloNewOrdineLibero">
                    <h3>Storico del numero documento: {props.numeroDocumento}</h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loadPanel}

                <TabellaSemplice
                    propsTabella={propsTabStoricoDocumento}
                    columns={columnsStoricoDocumento}
                />
                          
            </Modal.Body>
            <Modal.Footer>
                <Button id="modalConfermaOk" variant="success" onClick={handleClose}>
                    OK
                </Button>

            </Modal.Footer>
        </Modal>
    )
}