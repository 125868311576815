import Form, { GroupItem, Label, SimpleItem } from 'devextreme-react/form';
import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { AnagraficaFarmaciaInterface } from '../../interfaces/myCsf';
import { OggettoInterface } from '../../interfaces/generiche';
import Button from 'react-bootstrap/esm/Button';
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { urlmyCsf } from '../costanti';
import { Loading } from '../../widget/Notifications';
import { Check } from '../../widget/Editor';
import { CheckBox } from 'devextreme-react';

const AnagraficaFarmacia2 = (props: { anagrafica?: AnagraficaFarmaciaInterface, concessionari: boolean, idFar: number | undefined }) => {
    //questo componente viene utilizzato sia per anagrafica farmacia che per il concessionario -> nel secondo caso ci sono 
    //dei parametri in più da visualizzare e sono stati inseriti come opzionali dopo il controllo della presenza di 
    //props.concessionari (se è true si tratta della pagina per il concessionario altrimenti solo della farmacia)

    const newAnagraficaFarmacia = {
        ragioneSociale: undefined,
        titolare: undefined,
        codiceClienteCsf: undefined,
        codiceTelepharma: undefined,
        partitaIva: undefined,
        codiceFiscale: undefined,
        indirizzo: undefined,
        citta: undefined,
        cap: undefined,
        provincia: undefined,
        regione: undefined,
        telefono: undefined,
        telefono2: undefined,
        telefonoCellulare: undefined,
        email: undefined,
        emailAmministrativa: undefined,
        emailPEC: undefined,
        categoriaContabile: undefined,
        codiceFarmaPlan: undefined,
        codiceIms: undefined,
        codiceNewLine: undefined,
        situazioneGiuridica: undefined,
        emailCommMark: undefined,
        disdettaNewsletter: undefined,
    }

    const [filtri, setFiltri] = useState<OggettoInterface>();
    const impostaFiltri = (newFiltro: OggettoInterface) => {
        let newF = { ...filtri, ...newFiltro }
        setFiltri(newF);
    }

    let tmp: boolean|undefined;

    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    const [emailCom, setEmailCom] = useState<string>();
    const [disdettaNewsletter, setDisdettaNewsletter] = useState<boolean>();

    const editorOptions = {
        readOnly: true,
    };

    const disdettaValueChanged = (e: any) => {
        setDisdettaNewsletter(e.value);
        //tmp = e.value;
        //console.log(tmp);
    }

    const formFieldDataChanged = (e: any) => {
        setEmailCom(e.value);
    }

    const salvaAnagraficaFarmacia = () => {

        const param = {
            idFar: props.idFar,
            disdettaNewsletter: disdettaNewsletter,
            emailCom: emailCom,
        }

        setIsVisible(true);
        RestServices().rest('POST', param, urlmyCsf.setAnaFarmacie).then(result => {
            setIsVisible(false);
            if (result.esito) {
                notify({ position: "center", width: "auto", message: "Salvataggio eseguito con successo" }, 'success', 4000);
            } else {
                notify({ position: "center", width: "auto", message: "Ops. Si e' verificato un problema" }, 'error', 4000);
            }
        })


    }

    return (
        <>
            {loadPanel}
            <Row>&nbsp;</Row>
            <Form
                colCount={2}
                id="form"
                formData={props.anagrafica}
                onFieldDataChanged={formFieldDataChanged}>
                <GroupItem colCount={2} colSpan={2} caption="Farmacia e Titolare">
                    <SimpleItem dataField="ragioneSociale" editorOptions={editorOptions} />
                    <SimpleItem dataField="titolare" editorOptions={editorOptions} />
                    <SimpleItem dataField="partitaIva" editorOptions={editorOptions} />
                    <SimpleItem dataField="codiceFiscale" editorOptions={editorOptions} />
                </GroupItem>

                <GroupItem colCount={3} colSpan={2} caption="Indirizzo">
                    <SimpleItem dataField="indirizzo" editorOptions={editorOptions} />
                    <SimpleItem dataField="citta" editorOptions={editorOptions} />
                    <SimpleItem dataField="cap" editorOptions={editorOptions} />
                    <SimpleItem dataField="provincia" editorOptions={editorOptions} />
                    <SimpleItem dataField="regione" editorOptions={editorOptions} />
                </GroupItem>

                <GroupItem colCount={2} colSpan={2} caption="Recapiti">
                    <SimpleItem dataField="telefono" editorOptions={editorOptions} />
                    <SimpleItem dataField="telefono2" editorOptions={editorOptions} />
                    <SimpleItem dataField="telefonoCellulare" editorOptions={editorOptions} />
                    <SimpleItem dataField="email" editorOptions={editorOptions} />
                    <SimpleItem dataField="emailAmministrativa" editorOptions={editorOptions} />
                    <SimpleItem dataField="emailPec" editorOptions={editorOptions} />
                </GroupItem>

                <GroupItem colCount={3} colSpan={2} caption="Comunicazioni e Marketing">
                    <SimpleItem
                        dataField="emailCommMark">
                        <Label text="Email per comunicazioni e marketing" />
                    </SimpleItem>
                    <SimpleItem dataField="disdettaNewsletter">
                        <CheckBox
                            id="disdettaNewsletter"
                            text="Disdetta ricezione email per comnuicazioni e marketing"
                            defaultValue={props.anagrafica?.disdettaNewsletter}
                            onValueChanged={disdettaValueChanged}
                        />  </SimpleItem>
                    <SimpleItem><Button id="btnSalvaAnagraficaFarmacia" variant="success" onClick={salvaAnagraficaFarmacia} >
                        Salva modifiche
                    </Button></SimpleItem>
                </GroupItem>

                <GroupItem colCount={2} colSpan={2} caption="Informazioni di sistema">
                    <SimpleItem dataField="codiceClienteCsf" editorOptions={editorOptions}/>
                    <SimpleItem dataField="codiceTelepharma" editorOptions={editorOptions}/>
                </GroupItem>
            </Form>
            
            {/* <Button id="btnSalvaAnagraficaFarmacia" variant="success" onClick={salvaAnagraficaFarmacia} >
                Salva modifiche
            </Button>
            
            
             * Email per comunicazioni e marketing:
             * Disdetta ricezione email comnuicazioni e marketing
             * 
             * <Row>
                    {props.concessionari && <><Col sm="2"> <div className="titoloAnagrafica">Categoria Contabile: </div></Col>
                    <Col sm="4" className="valoreAnagrafica"><div>{props.anagrafica?.categoriaContabile}</div></Col></>}

                    {props.concessionari && <><Col sm="2"> <div className="titoloAnagrafica">Codice FarmaPlan: </div></Col>
                    <Col sm="4" className="valoreAnagrafica"><div>{props.anagrafica?.codiceFarmaPlan}</div></Col></>}
                </Row>

                <Row>
                    {props.concessionari && <><Col sm="2"> <div className="titoloAnagrafica">Codice IMS: </div></Col>
                    <Col sm="4" className="valoreAnagrafica"><div>{props.anagrafica?.codiceIms}</div></Col></>}

                    {props.concessionari && <><Col sm="2"> <div className="titoloAnagrafica">Codice NewLine: </div></Col>
                    <Col sm="4" className="valoreAnagrafica"><div>{props.anagrafica?.codiceNewLine}</div></Col></>}
                </Row>

{props.concessionari && <><Col sm="2"> <div className="titoloAnagrafica">Situazione Giuridica: </div></Col>
                        <Col sm="4" className="valoreAnagrafica"><div>{props.anagrafica?.situazioneGiuridica}</div></Col></>}
                */}
                
        </>
    )
}

export default AnagraficaFarmacia2