import { useEffect, useState } from "react";
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { useAuth } from "../../hooks/useAuth";
import { urlBusinessIntelligence, urlCommon, urlConsultazione, urlContenitori } from "../costanti";
import { RestServices } from "../../services/restServices";
import { trasformaDateTimeInLocale } from "../../funzioni/formatoVariabili";
import notify from "devextreme/ui/notify";
import { Filtri } from "../consultazione/interface";
import React from "react";
import { NewTabella } from "../../widget/nuova_tabella/newVersion";
import { Loading } from "../../widget/Notifications";
import Ricerca from "../../widget/nuova_ricerca"
import { AplicaValoreTO, AplicaValoreTOGruppo } from "../../funzioni/operazioniSuTabelle";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { exportDataGrid } from 'devextreme/excel_exporter'
import { GroupItem, Summary, TotalItem } from "devextreme-react/data-grid";



const SelloutAssistantOper = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean, username: string }) => {

    const usernames = ["dati.galileo", "elisa.sede", "stefania.sede"]
    const auth = useAuth(urlBusinessIntelligence.auth);
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [newContenitoreList, setNewContenitoreList] = useState([])


    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    const datatabella = tabellaPrincipale.map((row: { codDittaTitolare: any; descDittaTitolare: any; }) => ({
        ...row,
        ditta: `${row.codDittaTitolare} ${row.descDittaTitolare}`
    }));


    const columns = [
        { caption: "Codice Farmacia", dataField: "codiceFarmacia" },
        { caption: "Desc. Farmacia", dataField: "farmacia" },
        { caption: "Prodotto", dataField: "prodotto" },
        { caption: "MinSan", dataField: "minsan" },
        { caption: "Pezzi venduti", dataField: "pezziVenduti" },
        //{ caption: "Pezzi venduti scontr.", dataField: "pezziVendutiScontrino", visible: usernames.includes(props.username) ? true : false},
        { caption: "Valore del venduto totale (lordo iva, netto sconti)", dataField: "valoreVendutoTotale", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Valore del venduto totale scontr. (lordo iva, netto sconti)", dataField: "valoreVendutoTotaleScontrino", format: { type: "currency", precision: 2, currency: "EUR" }, visible: usernames.includes(props.username) ? true : false },
        { caption: "Valore venduto medio pezzo (lordo iva, netto sconti)", dataField: "valoreVendutoMedio", format: { type: "currency", precision: 2, currency: "EUR" } },
        //{ caption: "Margine totale (netto iva, netto sconti)", dataField: "margineTotale", format: { type: "currency", precision: 2, currency: "EUR" } },
        //{ caption: "Margine percentuale", dataField: "marginePercentuale", customizeText: (cellInfo: any) => AplicaValoreTO(cellInfo, '%', 'marginePercentuale', 'd') },
        { caption: "Sconto", dataField: "sconto", width: "auto", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Ditta", dataField: "ditta" } 

    ];

    const itemsFields = [
        //{ type: 'select2', idValue: 'idFarmacia', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 500, require: true },
        { type: 'selectFarmacie', idValue: 'idFarmacia', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 500, require: true },
        { type: 'select2', idValue: 'idContenitore', label: 'Contenitori ', displayExpr: 'nome', dataSource: ConvertForSelectContenitore(newContenitoreList), width: 500 },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, require: true, width: 200 },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null, require: true, width: 200 },
        { type: 'ckBox', idValue: 'includiDpc', label: 'Includi DPC', dataSource: null, width: 200 }
    ]


    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                    } else {
                        //console.log(result);

                    }
                })
        }
    }

    const contenitoreList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlContenitori.getList)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewContenitoreList(result.response)
                    } else {
                        //console.log(result);

                    }
                })
        }
    }

    useEffect(() => {
        farmaList()
        contenitoreList()
    }, [auth]);


    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro }

        if (params.idFarmacia.idFar) {

            let newArr: number[] = []
            params.idFarmacia.idFar.forEach((element: any) => {
                //console.log(element);
                newArr.push(element)

            });

            params.idFarmacia = newArr
        }

        params.dal = trasformaDateTimeInLocale(params.dal, true);
        params.al = trasformaDateTimeInLocale(params.al, true);


        if (params.idContenitore) {

            let newArr: number[] = []
            params.idContenitore.forEach((element: any) => {
                //console.log(element);
                newArr.push(element.value)

            });

            params.idContenitore = newArr
        }

        if (params.includiDpc) {
            params.includiDpc = params.includiDpc;
        }

        let includiDpc = params?.includiDpc ? true : false;

        console.log(params)

        const model = {
            ...params,
            includiDpc: includiDpc
        };

        console.log(params)


        setLoading(true)
        if (auth) {
            RestServices().rest('POST', model, urlBusinessIntelligence.getSelloutAssistant)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }

    const exportFormats = ['csv'];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('sellout_assistant');
        exportDataGrid({
            component: e.component,
            worksheet: worksheet
        }).then(function () {
            // https://github.com/exceljs/exceljs#writing-csv
            // https://github.com/exceljs/exceljs#reading-csv
            workbook.csv.writeBuffer().then(function (buffer) {
                saveAs(new Blob([buffer], { type: "application/octet-stream" }), "sellout_assistant.csv");
            });
        });
    }



    return (
        <>
            <Loading isVisible={loading} />
            <div className='title'>
                <h3> SELLOUT ASSISTANT OPERATORE  <span className='subt' > L'attendibilita' dei seguenti dati e' sottesa al corretto inserimento dei costi e dei carichi merce nel gestionale.</span> </h3>
            </div>
            <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />

            <NewTabella
                idTabella='elenco_fat_carichi'
                sync
                colonne={columns}
                dataSource={datatabella}
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "sellout_assistant",
                    formats: { exportFormats }
                }}
                activeSaver
                wordWrapEnabled={true}
                headerFilter={true}
                onExporting={onExporting}
                summary={
                    <Summary>

                        <TotalItem
                            column="minsan"
                            summaryType="count"
                            valueFormat={{ precision: 2 }}
                        />

                        <TotalItem
                            column="pezziVenduti"
                            summaryType="sum"
                            valueFormat={{ precision: 2 }}
                        />

                        <TotalItem
                            column="valoreVendutoTotale"
                            summaryType="sum"
                            valueFormat={{ type: "currency", precision: 2, currency: "EUR" }}
                        />

                        <TotalItem
                            column="valoreVendutoTotaleScontrino"
                            summaryType="sum"
                            valueFormat={{ type: "currency", precision: 2, currency: "EUR" }}
                        />

                        <TotalItem
                            column="valoreVendutoMedio"
                            summaryType="sum"
                            valueFormat={{ type: "currency", precision: 2, currency: "EUR" }}
                        />

                        <TotalItem
                            column="margineTotale"
                            summaryType="sum"
                            valueFormat={{ type: "currency", precision: 2, currency: "EUR" }}
                        />


                        <TotalItem
                            column="marginePercentuale"
                            summaryType="avg"
                            valueFormat={(value: number) => `${value.toFixed(2)}%`}
                        />

                        <TotalItem
                            column="sconto"
                            summaryType="sum"
                            valueFormat={{ type: "currency", precision: 2, currency: "EUR" }}
                        />

                    </Summary>
                }
            />
        </>
    )
}

export default SelloutAssistantOper

const ConvertForSelect = (arr: any) => {
    let newArr: any = []
    arr.forEach((element: any) => {
        //console.log(element);
        newArr.push({ ID_FAR: element.idFar, CODICE: element.codice, DESCRIZIONE: element.ragioneSociale })

    });
    return newArr
}

const ConvertForSelectContenitore = (arr: any) => {
    let newArr: any = []
    arr.forEach((element: any) => {
        //console.log(element);
        newArr.push({ value: element.idContenitore, label: element.nome })

    });
    return newArr
}
