import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import { useAuth } from '../../hooks/useAuth';
import { ListaFarmacieInterface } from "../../interfaces/farmacie";
import { RestServices } from '../../services/restServices';
import { basenameHostNewTabs, urlCommon, urlConsultazione } from '../costanti';
import { Filtri } from '../consultazione/interface';
import notify from 'devextreme/ui/notify';
import { DialogConferma, Loading } from '../../widget/Notifications';
import { convertForSelectFarmacie, trasformaDateTimeInLocaleNewF } from '../../funzioni/formatoVariabili';
import Button from 'react-bootstrap/Button'
import { DialogNuovoLivellamentoMagazzino } from './Dialog/Pop_livellamento_magazzino';
import { NewTabella } from '../../widget/nuova_tabella/newVersion';
import Row from "react-bootstrap/esm/Row"
import Col from "react-bootstrap/esm/Col"
import RadioGroup from "devextreme-react/radio-group";
import TextBox from "devextreme-react/text-box"
import Select from 'react-select';
import CheckBox from "devextreme-react/check-box"
import { confirm } from 'devextreme/ui/dialog';
import './style.css'
import { DialogImpostazioniLivellamentoMagazzino } from './Dialog/Pop_imp_livellamento_magazzino';
import { AutocompleteBox } from '../../widget/Autocomplete';
import { Selezione, SelezioneMultipla } from '../../widget/Select';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { ClasseAInterface } from '../../interfaces/generiche';
import RichiestaLivellamentoMagazzino from './RichiestaLivellamentoMagazzino';
const ElencoRichiesteLivellamentoMagazzino = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const auth = useAuth(urlCommon.auth)

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [newFarmaListSelect,setNewFarmaListSelect] = useState([])
    const [paramsRicerca, setParamsRicerca] = useState<any>({})
    const [openPopNuovoLivellamentoMagazzino, setOpenPopNuovoLivellamentoMagazzino] = useState<boolean>(false)
    const [openPopImpostazioniLivellamentoMagazzino, setOpenPopImpostazioniLivellamentoMagazzino] = useState<boolean>(false)
    const [renderp, setRenderp] = useState<any>(1);
    const [edit, setImmediate] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [idDel, setIddell] = useState<number>(0)
    const [messDel, setMessDel] = useState<string>('')
    const [popDel, setPopDel] = useState<boolean>(false)
    const [idEla, setIdEla] = useState<number>(0)
    const [messEla, setMessEla] = useState<string>('')
    const [popEla, setPopEla] = useState<boolean>(false)
    const [viewMode, setViewMode] = useState<boolean>(false);


    useEffect(() => {
        if (auth) {

            farmaList()

            filterAction({
                dal: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'),
                al: new Date()
            })
        }
            

        

        //setTabellaPrincipale(dataSourceTest)

    }, [auth]);

    useEffect(() => {
        
    }, [renderp])

    const columns = [
        //{ caption: "Id Farmacia", dataField: "idAnagrafica", visible: false },
        //{ caption: "Id Fornitore", dataField: "idAnagraficaFornitore", visible: false },
        { caption: "Id", dataField: "id", visible: false },
        { caption: "Titolo", dataField: "titolo"},
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy", dataType: "date", width: 90, sortOrder: "desc" },
        { caption: "Note", dataField: "note" },
        { caption: "Tot. prodotti", dataField: "totaleProdotti" },
        { caption: "Tot. farmacie", dataField: "totaleFarmacie" },
        { caption: "Ditta titolare", dataField: "dittaTitolare", },
        //{ caption: "Ditta linea", dataField: "dittaLinea", },
        //{ caption: "Settore inv.", dataField: "settoreInventario", },
        //{ caption: "Gruppo merc.", dataField: "gruppoMerceologico", },
        { caption: "Stato", dataField: "stato", },
        {
            caption: "Azioni", type: "buttons", buttons: [
                {
                    hint: "Vedi",
                    icon: "bulletlist",
                    onClick: (e: any) => {
                        //console.log (e)
                        if (e.row.data) {
                            const path = basenameHostNewTabs + "magazzino/livellamentoDettaglio?" + e.row.data.id;
                            window.open(path);
                        }
                        

                    },
                },
                {
                    hint: "Visualizza",
                    icon: "search",
                    onClick: (e: any) => {
                        setObjtipo(e.row.data)
                        setRenderp(3)
                        setViewMode(true)
                        //setOpenPopVisualProd(true)

                    }
                },
                {
                    hint: "Elabora",
                    icon: "pulldown",
                    onClick: (e: any) => {
                        setIdEla(e.row.data.id)
                        if (e.row.data.idStato === 2 || e.row.data.idStato === 3)
                            setMessEla("La richiesta " + e.row.data.titolo + " sta per essere elaborata. L'attuale elaborazione sara' sovrascritta. Confermare?")
                        else
                            setMessEla("La richiesta " + e.row.data.titolo + " sta per essere elaborata. Confermare?")

                        setPopEla(true)
                    },
                },
                {
                    hint: "Invia",
                    icon: "exportselected",
                    onClick: (e: any) => {
                        //notify({ position: "center", width: "auto", message: 'Funzione al momento non abilitata' }, "warning", 4000)

                        if (e.row.data.idStato == 3 || e.row.data.idStato == 5) {
                            let result = confirm("<i>Richiesta gia' inviata, inviare di nuovo?</i>", "Attenzione");
                            result.then((dialogResult) => {
                                if (dialogResult) {
                                    inviaInFarmacia(e.row.data.id)

                                    return;
                                } else {
                                    return;
                                }
                            });
                        } else {
                            let result = confirm("<i>Inviare alle farmacie la richiesta?</i>", "Attenzione");
                            result.then((dialogResult) => {
                                if (dialogResult) {
                                    inviaInFarmacia(e.row.data.id)

                                    return;
                                } else {
                                    return;
                                }
                            });
                        }


                    },
                },
                {
                    
                    hint: "Elimina",
                    icon: "trash",
                    onClick: (e: any) => {

                        setIddell(e.row.data.id)
                        setMessDel("La richiesta  " + e.row.data.titolo + " sta per essere eliminata. Confermare?")
                        setPopDel(true)

                    },
                }
            ]
        }
    ];

    const inviaInFarmacia = (id: any) => {

        // chiamata rest per schedulazione invio in farmacia
        if (auth) {
            RestServices().rest('POST', { idLivellamentoMagazzino: id }, urlCommon.inviaLivellamentoMagazzino)
                .then(result => {
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "Elemento schedulato per l'invio" }, "success", 4000);
                        getList(paramsRicerca)

                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }
    }

    const ConvertForSelect = (arr: any) => {
        let newArr: any = []
        arr.forEach((element: any) => {
            //console.log(element);
            newArr.push({ value: element.idFar, label: element.descrizione })

        });
        return newArr
    }

    const itemsFields = [
        { type: 'select2', idValue: 'idFarmacie', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: ConvertForSelect(newFarmaList), width: 350 },
        { type: 'date', idValue: 'dal', label: 'Dal', dataSource: null, defValue: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'), width: 250 },
        { type: 'date', idValue: 'al', label: 'Al', dataSource: null, defValue: new Date(), width: 250 },
        //{ type: 'autocomplete', idValue: 'id', label: 'Ditta ', typeApi: 'dittaTitlare', valueExpr: 'codice', apisearch: true, dataSource: {}, width: 300 },
        //{ type: 'autocomplete', idValue: 'id', label: 'Linea ', typeApi: 'dittaLinea', valueExpr: 'codice', apisearch: true, dataSource: {}, width: 300 },
    ]

    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)
                        setNewFarmaListSelect(convertForSelectFarmacie(result.response))
                    } else {

                    }
                })
        }
    }

    const filterAction = (objFiltro: Filtri) => {
        
        if (new Date(objFiltro.dal) > new Date(objFiltro.al)) {
            notify({ position: "center", width: "auto", message: "La data inizio non puo' essere maggiore della data fine" }, 'error', 4000);
        }
        else {
            setLoading(true)
            let params = { ...objFiltro }

            if (params.idFarmacie) {

                let newArr: number[] = []
                params.idFarmacie.forEach((element: any) => {
                    //console.log(element);
                    newArr.push(element.value)

                });

                params.idFarmacie = newArr
            }

            setParamsRicerca(params)

            getList(params)
        }
        

    }

    const ricarica = () => {


        setTimeout(
            function () {
                    if (paramsRicerca)
                        getList(paramsRicerca)
            }, 1000)
    }

    const getList = (par: any) => {

        setLoading(true)
        if (auth) {
            RestServices().rest('POST', par, urlCommon.getListLivellamentoMagazzino)
                .then(result => {
                    if (result?.esito) {
                        if (result.response) {
                            setLoading(false)
                            setTabellaPrincipale(result.response)
                        }
                        
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const removeLivellamento = () => {
        if (auth) {
            RestServices().rest('POST', { idLivellamentoMagazzino: idDel }, urlCommon.deleteLivellamentoMagazzino)
                .then(result => {
                    if (result.esito) {
                        setPopDel(false)
                        notify({ position: "center", width: "auto", message: "Elemento eliminato" }, "success", 4000)
                        ricarica()
                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }
    }

    const elaboraLivellamento = () => {
        if (auth) {
            RestServices().rest('POST', { idLivellamentoMagazzino: idEla }, urlCommon.elaboraLivellamentoMagazzino)
                .then(result => {
                    if (result.esito) {
                        setPopEla(false)
                        notify({ position: "center", width: "auto", message: "Elemento schedulato per l'elaborazione" }, "success", 4000)
                        ricarica()
                    }
                    else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                })
        }
    }

    const render = () => {

        switch (renderp) {
            case 1:
                return (
                    <>
                        <Loading isVisible={loading} />
                        <DialogConferma
                            dialogIsOpen={popDel}
                            message={messDel}
                            functionNext={() => removeLivellamento()}
                            handleCloseConferma={() => { setPopDel(false); setIddell(0) }}
                        />
                        <DialogConferma
                            dialogIsOpen={popEla}
                            message={messEla}
                            functionNext={() => elaboraLivellamento()}
                            handleCloseConferma={() => { setPopEla(false); setIdEla(0) }}
                        />
                        <div className='title'>
                            {/*<h3> LIVELLAMENTO MAGAZZINO  <span className='subt' > Registro fatturazione elettronica</span> </h3>*/}
                            <h3> ELENCO LIVELLAMENTO MAGAZZINO </h3>
                        </div>
                        <div style={{ width: '250' }}>
                            <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
                        </div>
                        <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                            <div className="col-md-10">


                            </div>
                            <div className="col-md-1 text-right">
                                <Button style={{visibility:'hidden'}} className="float-right" id='btnImpostazioniLivellamento' variant="primary" onClick={() => { setOpenPopImpostazioniLivellamentoMagazzino(true) }} >
                                    IMPOSTAZIONI
                                </Button>
                            </div>
                            <div className="col-md-1 text-right">
                                <Button className="float-right" id='btnNuovoLivellamento' variant="success" onClick={() => { setRenderp(2) }} >
                                    NUOVO
                                </Button>
                            </div>

                        </div>

                        <NewTabella
                            idTabella='liv_mag'
                            sync
                            colonne={columns}
                            dataSource={tabellaPrincipale}
                            filterRow
                            searchPanel={true}
                            onclickRow={() => null}
                            exportT={{
                                enabled: true,
                                fileName: "liv_mag"
                            }}
                            activeSaver={true}
                            columnC={true}
                        />
                        <DialogImpostazioniLivellamentoMagazzino
                            ricarica={() => ricarica()}
                            close={() => { setOpenPopImpostazioniLivellamentoMagazzino(false); }}
                            open={openPopImpostazioniLivellamentoMagazzino}
                        />
                    </>
                )
            case 2:
                return (
                <RichiestaLivellamentoMagazzino
                        typeEdit={edit}
                        edit={undefined}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        setIndietro={() => { setRenderp(1); ricarica() }}
                        typeInsert={true}
                        farmList={newFarmaListSelect}
                        viewMode={false }
                >
                </RichiestaLivellamentoMagazzino>
                )
            case 3:
                return (
                    <RichiestaLivellamentoMagazzino
                        typeEdit={edit}
                        edit={objtipo}
                        ricarica={() => ricarica()}
                        close={() => { setObjtipo({}); }}
                        setIndietro={() => { setRenderp(1); }}
                        typeInsert={true}
                        viewMode={viewMode}
                        farmList={newFarmaListSelect}
                    >
                    </RichiestaLivellamentoMagazzino>
                )
            default:
                return (<></>)
        }
    }
    return render()
}


export default ElencoRichiesteLivellamentoMagazzino
