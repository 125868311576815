import React, { useEffect, useState } from 'react'
import Ricerca from "../../widget/nuova_ricerca"
import notify from 'devextreme/ui/notify'
import { Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import { Filtri } from '../consultazione/interface'
import Button from 'react-bootstrap/Button'
import { cambiaFormatoLocalDateTimeToEuropeo, getCurrentDateTime, trasformaDateTimeInLocaleNewF } from '../../funzioni/formatoVariabili';
import { AplicaValoreTO, actionTables } from '../../funzioni/operazioniSuTabelle'
import { Summary, TotalItem } from 'devextreme-react/data-grid'
import { DialogConferma } from '../../widget/Notifications'
import { DialogNote } from "./dialog/Pop_note"
import { DialogFarmRif } from "./dialog/Pop_farm_rif_pass"
import { DialogCaricaXml } from "./dialog/Pop_carica_fatt"

import './style.css'
import { NewTabella } from '../../widget/nuova_tabella/newVersion'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import saveAs from 'file-saver'




const FattElettronicaPassive = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [newFarmaList, setNewFarmaList] = useState([])
    const [fattTipiList, setFattTipiList] = useState([])
    const [openPop, setOpenPop] = useState<boolean>(false)
    const [openPop2, setOpenPop2] = useState<boolean>(false)
    const [openPop3, setOpenPop3] = useState<boolean>(false)
    const [openPop4, setOpenPop4] = useState<boolean>(false)
    const [edit, setImmediate] = useState<boolean>(false)
    const [objtipo, setObjtipo] = useState<any>(null)
    const [paramsRicerca, setParamsRicerca] = useState<any>({})
    const [hasControlloFatture, setHasControlloFatture] = useState<boolean>(false)
    const [messImportFattura, setMessImportFattura] = useState<string>('')
    const [popImportFattura, setPopImportFattura] = useState<boolean>(false)
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [totalItems, setTotalItems] = useState<any>();
    const auth = useAuth(urlConsultazione.auth)


    const columns = [
        //{ caption: "Id Farmacia", dataField: "idAnagrafica", visible: false },
        //{ caption: "Id Fornitore", dataField: "idAnagraficaFornitore", visible: false },
        { caption: "Cedente/Prestatore (Fornitore)", dataField: "denominazione", width: 230 },
        { caption: "P.Iva Fornitore", dataField: "idFiscaleIVA" },
        { caption: "Num. Doc.", dataField: "numeroDoc" },
        { caption: "Data Doc.", dataField: "dataDoc", format: "dd/MM/yyyy", dataType: "date", width: 90, sortOrder:"desc" },
        { caption: "Tipo Doc.", dataField: "descrizioneBreve", width: 150 },
        { caption: "Importo", dataType: "number", dataField: "importo", format: { type: "currency", precision: 2, currency: "EUR" }, width: 90 },
        { caption: "Imponibile", dataType: "number", dataField: "imponibile", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Imposta", dataType: "number", dataField: "imposta", format: { type: "currency", precision: 2, currency: "EUR" }, width: 100 },
        { caption: "Importata in c.f.", dataField: "flagImportata" },
        {
            caption: "Farmacia di riferimento", dataField: "farRiferimento", width: 200,
            render: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => {
                    setOpenPop(true); setObjtipo(e.data); setImmediate(true);
                    // console.log('dati');
                    // console.log(e.data);
                }}>{e.value}</a>
            )
        },
        {
            caption: "Note", dataField: "note", width: 100, render: (e: any) => (<a style={{ cursor: 'pointer', fontWeight: 'bold', color: 'blue' }}
                onClick={() => { setOpenPop2(true); setObjtipo(e.data); setImmediate(true) }}>{e.value}</a>)
        },
        { caption: "Fornitore Cloudfarma", dataField: "descrizioneAnagraficaFornitore", width: 200 },
        { caption: "Data Ricezione", dataField: "dataInsert", format: "dd/MM/yyyy", dataType: "date", width: 100 },
        { caption: "Prog.Invio", dataField: "progInvio" },
        /*{ caption: "Cod.Ricezione", dataField: "idEsterno"},*/
    ];


    const itemsFields = [
        { type: 'select', idValue: 'idFar', label: 'Farmacie ', displayExpr: 'descrizione', dataSource: newFarmaList, width: 350 },
        { type: 'string', idValue: 'numeroDoc', label: 'Numero Documento', dataSource: null, width: 200 },
        { type: 'date', idValue: 'dataInizioDoc', label: 'Data documento iniziale', dataSource: null, require: true, defValue: new Date().getMonth() > 0 ? new Date(new Date().getFullYear() + '-' + (new Date().getMonth()) + '-01') : new Date((new Date().getFullYear() - 1) + '-' + (12) + '-01'), width: 250, require11: true },
        { type: 'date', idValue: 'dataFineDoc', label: 'Data documento finale', dataSource: null, require: true, defValue: new Date(), width: 250, require12: true },
        { type: 'select', idValue: 'id_TIF', label: 'Tipo documento', displayExpr: 'descrizione_BREVE', dataSource: fattTipiList, width: 250 },
        { type: 'string', idValue: 'cedente', label: 'Cedente Prestatore', dataSource: null, width: 200 },
        { type: 'date', idValue: 'dataInizioInvio', label: 'Data ricezione iniziale', dataSource: null, width: 200, require21: true },
        { type: 'date', idValue: 'dataFineInvio', label: 'Data ricezione finale', dataSource: null, width: 200, require22: true },

    ]



    const farmaList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getListFar)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setNewFarmaList(result.response)

                    } else {

                    }
                })
        }
    }

    const fattureList = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.getTipiFatture)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setFattTipiList(result.response)

                    } else {

                    }
                })
        }
    }
    const checkAbilitazioneControlloFatture = () => {
        if (auth) {
            RestServices().rest('POST', {}, urlConsultazione.checkAbilitazioneControlloFatture)
                .then(result => {
                    if (result?.esito) {
                        //console.log(result.response);
                        setHasControlloFatture(result.response)

                    } else {
                    }
                })
        }
    }



    const filterAction = (objFiltro: Filtri) => {
        setLoading(true)
        let params = { ...objFiltro }

        if (params.idFar) {
            params.idFar = Number(params.idFar?.idFar)
        }
        if (objFiltro?.numeroDoc) {
            params.numeroDoc = objFiltro?.numeroDoc
        }

        if (params.id_TIF) {
            params.tipoDoc = params.id_TIF.codice
            delete params.id_TIF
        } else {
            delete params.id_TIF
        }
        if (objFiltro?.cedente) {
            params.cedente = objFiltro?.cedente
        }

        params.dataInizioInvio = trasformaDateTimeInLocaleNewF(params.dataInizioInvio, true);
        params.dataFineInvio = trasformaDateTimeInLocaleNewF(params.dataFineInvio, true);

        params.dataInizioDoc = trasformaDateTimeInLocaleNewF(params.dataInizioDoc, true);
        params.dataFineDoc = trasformaDateTimeInLocaleNewF(params.dataFineDoc, true);
        params.tipoFattura = "passive"

        setParamsRicerca(params)

        //console.log(params);  
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', params, urlConsultazione.findFatture)
                .then(result => {
                    if (result?.esito) {

                        setLoading(false)
                        setTabellaPrincipale(result.response)


                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }

    }

    const ricaricaAfter = () => {
        if (auth) {
            RestServices().rest('POST', paramsRicerca, urlConsultazione.findFatture)
                .then(result => {
                    if (result?.esito) {

                        setLoading(false)
                        setTabellaPrincipale(result.response)

                    } else {
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        setLoading(false)
                    }
                })

        }
    }



    const importaFatturaInControlloFattura = () => {

        if (objtipo == undefined) {
            notify({ position: "center", width: "auto", message: "Selezionare un documento." }, "error", 4000)
            return;
        }
        if (objtipo.idAnagrafica == undefined) {
            notify({ position: "center", width: "auto", message: "Farmacia non identificata." }, "error", 4000)
            return;
        }
        if (objtipo.denominazione == undefined) {
            notify({ position: "center", width: "auto", message: "Cedente prestatore assente." }, "error", 4000)
            return;
        }
        if (objtipo.descrizioneAnagraficaFornitore == undefined) {
            notify({ position: "center", width: "auto", message: "Posizione anagrafica fornitore non identificata." }, "error", 4000)
            return;
        }
        if (objtipo.fileToken == undefined) {
            notify({ position: "center", width: "auto", message: "Contenuto assente." }, "error", 4000)
            return;
        }
        

        if (objtipo.flagImportata == true) {
            setMessImportFattura("La fattura risulta importata. Si vuole procedere ugualmente?")
            setPopImportFattura(true);
            return;
        }
        importFattura();

    }

    const importFattura = () => {

        let paramImport = {
            fileToken: objtipo.fileToken,
            idFar: objtipo.idAnagrafica
        }

        if (auth) {
            setLoading(true)
            RestServices().rest('POST', paramImport, urlConsultazione.importaInControlloFattura)
                .then(result => {
                    if (result?.esito) {

                        setLoading(false)
                        setPopImportFattura(false);
                        notify({ position: "center", width: "auto", message: "Importazione avviata. Essa potrebbe durare qualche minuto." }, "success", 4000)
                        ricarica()

                    } else {
                        //console.log(result);
                        setPopImportFattura(false);
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                    }
                })

        }
    }

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        fattureList()
        farmaList()
        checkAbilitazioneControlloFatture();

    }, [auth]);

    useEffect(() => {
        if (tabellaInstance) {

            tabellaInstance.instance.columnOption("idAnagrafica", "visible", false);
            tabellaInstance.instance.columnOption("idAnagraficaFornitore", "visible", false);

            if (hasControlloFatture == false) {
                tabellaInstance.instance.columnOption("flagImportata", "visible", false);
                tabellaInstance.instance.columnOption("descrizioneAnagraficaFornitore", "visible", false);
            }
        }
    }, [hasControlloFatture])



    const ricarica = () => {
        setOpenPop(false)
        setOpenPop2(false)
        setOpenPop3(false)
        setOpenPop4(false)
        setImmediate(false)
        ricaricaAfter()

    }

    const getTotalItems = (options: any) => {
        switch (options.summaryProcess) {
            case "start":
                options.totalValue = 0;
                break;
            case "calculate":
                options.totalValue = tabellaPrincipale.length
                break;
            case "finalize":
                break;
        }
    }

    const onExporting = (e: any) => {

        var workbook = new Workbook();
        var worksheet = workbook.addWorksheet('FatturePassive');

        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
            customizeCell: function ({ gridCell, excelCell }) {
                if (gridCell) {
                    
                    if (gridCell.column) {
                        if (gridCell.column.dataField === 'importo') {
                            
                            if (gridCell.rowType == 'data' && excelCell.value != null && excelCell.value != undefined && gridCell.data && gridCell.data.descrizioneBreve.toUpperCase().includes('CREDITO')) {
                                if (excelCell.value > 0)
                                    excelCell.value = -excelCell.value;
                            }
                        }
                        if (gridCell.column.dataField === 'imposta') {
                            if (gridCell.rowType == 'data' && excelCell.value != null && excelCell.value != undefined && gridCell.data && gridCell.data.descrizioneBreve.toUpperCase().includes('CREDITO')) {
                                if (excelCell.value > 0)
                                    excelCell.value = -excelCell.value;
                            }
                        }
                        if (gridCell.column.dataField === 'imponibile') {
                            if (gridCell.rowType == 'data' && excelCell.value != null && excelCell.value != undefined && gridCell.data && gridCell.data.descrizioneBreve.toUpperCase().includes('CREDITO')) {
                                if (excelCell.value > 0)
                                    excelCell.value = -excelCell.value;
                            }
                        }
                    }
                }
            }
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer: BlobPart) {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'fatt_passive_elett' + getCurrentDateTime() + '.xlsx');
            });
        });
    }

    return (
        <>


            <DialogNote
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop2(false); setObjtipo({}); setImmediate(false) }}
                open={openPop2} />

            <DialogFarmRif
                typeEdit={edit}
                edit={objtipo}
                ricarica={() => ricarica()}
                close={() => { setOpenPop(false); setObjtipo({}); setImmediate(false) }}
                open={openPop} />

            {openPop3 ? (
                <DialogCaricaXml
                    typeEdit={edit}
                    edit={objtipo}
                    ricarica={() => ricarica()}
                    close={() => { setOpenPop3(false); setObjtipo({}); setImmediate(false) }}
                    open={openPop3}
                    flagCicloAttivo={false }
                />
            ) : null}


            <DialogConferma
                dialogIsOpen={popImportFattura}
                message={messImportFattura}
                functionNext={() => importFattura()}
                handleCloseConferma={() => { setPopImportFattura(false); }}
            />


            <Loading isVisible={loading} />
            <div className='title'>
                {/*<h3> FATTURE PASSIVE  <span className='subt' > Registro fatturazione elettronica</span> </h3>*/}
                <h3> ELENCO DOCUMENTI RICEVUTI </h3>
            </div>
            <div style={{ width: '250' }}>
                <Ricerca ricerca={(filtro) => filterAction(filtro)} items={itemsFields} />
            </div>


            <div className='button_fatt_pass' >
                <Button disabled={objtipo && objtipo.fileToken ? false : true}
                    onClick={() => { window.open('https://www.cloud-farma.it/cfportal/view/' + objtipo.fileToken + '_htm', "_blank") }}
                    variant="primary" >
                    VISUALIZZA FATTURE
                </Button>
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.fileToken ? false : true}
                    onClick={() => { window.open('https://www.cloud-farma.it/cfportal/view/' + objtipo.fileToken, "_blank") }}
                    variant="primary" >
                    SORGENTE "XML"
                </Button>
                <Button style={{ marginLeft: 5 }} disabled={objtipo && objtipo.fileToken ? false : true}
                    onClick={() => { window.open('https://www.cloud-farma.it/cfportal/view/' + objtipo.fileToken + '_pdf', "_blank") }}
                    variant="primary" >
                    ALLEGATO PDF
                </Button>
                <Button style={{ marginLeft: 5 }}
                    onClick={() => { window.open('https://www.cloud-farma.it/app/anagrafiche/fornitoriCentralizzati', "_blank") }}
                    variant="primary" >
                    ANAGRAFICA FORNITORI
                </Button>
                <Button style={{ marginLeft: 5, visibility: hasControlloFatture && hasControlloFatture == true ? 'visible' : 'hidden' }} disabled={objtipo && objtipo.fileToken ? false : true}
                    onClick={() => { importaFatturaInControlloFattura() }}
                    variant="primary" >
                    IMPORTA FATTURA IN "CONTROLLO FATTURA"
                </Button>
                <Button style={{ marginLeft: 5 }} onClick={() => { setOpenPop3(true); setImmediate(true) }} variant="primary" >
                    CARICA FILE "XML"
                </Button>
            </div>




            <NewTabella
                idTabella='fatt_passive_elett'
                sync
                colonne={columns}
                dataSource={tabellaPrincipale}
                allowColumnReordering={true}
                filterRow
                headerFilter
                searchPanel={true}
                tabReference={setTabellaInstance}
                exportT={{
                    enabled: true,
                    fileName: "fatt_passive_elett"
                }}
                activeSaver
                columnC
                toolbar={true}
                onExporting={onExporting }
                onclickRow={(e) => {
                    var component = e.component,
                        prevClickTime = component.lastClickTime;
                    component.lastClickTime = new Date();
                    if (prevClickTime && (component.lastClickTime - prevClickTime < 300)) {
                        //Double click code  
                        //console.log(e.data);
                        //setOpenPop3(true);
                        // setOpenLink(true);
                        setObjtipo(e.data)
                    }
                    else {
                        setObjtipo(e.data)
                        //Single click code                       
                    }
                }}
                summary={
                    <Summary calculateCustomSummary={getTotalItems}>
                        <TotalItem
                            name="rowsSummary"
                            displayFormat="Elementi totali: {0}"
                            summaryType="custom"
                            showInColumn="denominazione" />
                    </Summary>
                }


            />


        </>
    )
}

export default FattElettronicaPassive

