import notify from 'devextreme/ui/notify'
import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'
import { urlBancaDati } from '../contenuti/costanti'
import { CreaColonne } from '../funzioni/operazioniSuTabelle'
import { RicercaProdInterface } from '../interfaces/bancaDati'
import { OnRowDblClickInterface, TabellaSempliceInterface } from '../interfaces/tabelle'
import { RestServices } from '../services/restServices'
import { BoxTestoCerca } from '../widget/Editor'
import { Loading } from '../widget/Notifications'
import { TabellaSemplice } from '../widget/Tabelle'
import { useAuth } from '../hooks/useAuth'
import { urlCommon } from '../contenuti/costanti'

const RicercaProdottiBD = (props: {
    testoRicerca: string,
    cercaNuovoProd: RicercaProdInterface[],
    lstFar: number[] | undefined,
    getProdottoSelez: (codiceCsf: number) => void,
    getCodProdSelez?: (codProd: string) => void,
    getDescProdSelez?: (descProd: string) => void,
    getCodEanSelez?: (codEan: string) => void,
    onRowDblClick?: (rowDblClicked: OnRowDblClickInterface) => void
}) => {
    const authCommon = useAuth(urlCommon.auth);
    const btnGiacenzaDefault = {
        colore: "success",
        id: "tutti",
        valore: "Tutti",
        soloInGiacenza: false
    }
    const btnInCommercioDefault = {
        colore: "success",
        id: "nonInCommercio",
        valore: "Non In Commercio",
        soloInCommercio: false
    }
    const [btnInGiacenza, setBtnInGiacenza] = useState(btnGiacenzaDefault)
    const [btnInCommercio, setBtnInCommercio] = useState(btnInCommercioDefault)
    useEffect(() => {
        if (authCommon) {
            const paramComm = {
                categoria: "RICERCA_PRODOTTO",
                nome: "IN_COMMERCIO"
            }
            const paramGiac = {
                categoria: "RICERCA_PRODOTTO",
                nome: "SOLO_GIACENZA"
            }
            RestServices().rest('POST', paramComm, urlCommon.getRicercaProd).then(result => {
                if (result === true) {
                    cambiaValoreBtnCommercio();
                }
            })
            RestServices().rest('POST', paramGiac, urlCommon.getRicercaProd).then(result => {
                if (result === true) {
                    cambiaValoreBtnInGiacenza();
                }
            })
        }
    }, [authCommon])

    const [inputCerca, setInputCerca] = useState<string>("");
    useEffect(() => {
        setInputCerca(props.testoRicerca);
        setCercaNuovoProd(props.cercaNuovoProd);
    }, [props.testoRicerca, props.cercaNuovoProd])

    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />

    useEffect(() => {
        startRicercaProdInBD()
    }, [btnInCommercio, btnInGiacenza])

    const startRicercaProdInBD = () => {
        if (inputCerca.length > 0) {
            setIsVisible(true);
            const parametriCerca = {
                testo: inputCerca,
                lstFar: props.lstFar,
                soloInCommercio: btnInCommercio.soloInCommercio,
                soloInGiacenza: btnInGiacenza.soloInGiacenza
            }
            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    setIsVisible(false);
                    if (result.length >= 1) {
                        setCercaNuovoProd(result);
                    } else {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                        setCercaNuovoProd([]);
                    }
                })
        } else {
            return;
        }
    }

    function cambiaValoreBtnInGiacenza() {
        const paramGiac = {
            categoria: "RICERCA_PRODOTTO",
            nome: "SOLO_GIACENZA",
            valore: true
        }
        switch (btnInGiacenza.id) {
            case "soloGiacenza":
                paramGiac.valore = false;
                RestServices().rest('POST', paramGiac, urlCommon.setRicercaProd);
                setBtnInGiacenza(btnGiacenzaDefault);
                break;
            case "tutti":
                RestServices().rest('POST', paramGiac, urlCommon.setRicercaProd);
                setBtnInGiacenza({
                    colore: "danger",
                    id: "soloGiacenza",
                    valore: "Solo Giacenza",
                    soloInGiacenza: true
                });
                break;
        }
    }

    function cambiaValoreBtnCommercio() {
        const paramComm = {
            categoria: "RICERCA_PRODOTTO",
            nome: "IN_COMMERCIO",
            valore: true
        }
        switch (btnInCommercio.id) {
            case "inCommercio":
                setBtnInCommercio(btnInCommercioDefault);
                paramComm.valore = false;
                RestServices().rest('POST', paramComm, urlCommon.setRicercaProd);
                break;
            case "nonInCommercio":
                RestServices().rest('POST', paramComm, urlCommon.setRicercaProd);
                setBtnInCommercio({
                    colore: "danger",
                    id: "inCommercio",
                    valore: "In Commercio",
                    soloInCommercio: true
                });
                break;
        }
    }

    const propsTabCerca: TabellaSempliceInterface = {
        id: "tabellaRicercaProdBD",
        dataSource: cercaNuovoProd,
        filterRow: true,
        summary: {
            totalItems: [{
                name: "TotaleProdotti",
                showInColumn: "settInventario",
                displayFormat: "Tot: {0}",
                summaryType: "count"
            }]
        }
    }
    const columnsRicerca = CreaColonne([
        { caption: "Sett", dataField: "settInventario", width: 50 },
        /*{ caption: "Descrizione", dataField: "descrizione", sortOrder: "asc" },*/
        { caption: "Descrizione", dataField: "descrizione" },
        { caption: "Cod.Prod", dataField: "minsan" },
        { caption: "EAN", dataField: "ean" },
        { caption: "Codice CSF", dataField: "codCsf", visible: false }
    ]);

    const dettagliRighe = (datiRighe: any) => {
        if (datiRighe.data.codCsf) {
            props.getProdottoSelez(datiRighe.data.codCsf);
            if (props.getCodProdSelez) props.getCodProdSelez(datiRighe.data.minsan);
            if (props.getDescProdSelez) props.getDescProdSelez(datiRighe.data.descrizione);
            if (props.getCodEanSelez) props.getCodEanSelez(datiRighe.data.ean);
        }
    }

    const placeholder = "Cerca un prodotto per codice, EAN, descrizione ..."

    return (
        <>
            {loadPanel}
            <Row>
                <Col sm="6">
                    <BoxTestoCerca placeholder={placeholder} id="ricercaProdottiBD" setInputCerca={setInputCerca} startRicerca={startRicercaProdInBD} inputCerca={inputCerca} />
                </Col>
                <Col sm='6'>
                    <div className="bottoniFiltro">
                        <Button type="button" variant={btnInGiacenza.colore} id={btnInGiacenza.id} onClick={cambiaValoreBtnInGiacenza}>{btnInGiacenza.valore}</Button>
                        <Button type="button" variant={btnInCommercio.colore} id={btnInCommercio.id} onClick={cambiaValoreBtnCommercio}>{btnInCommercio.valore}</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col sm='12'>
                    <TabellaSemplice propsTabella={propsTabCerca} columns={columnsRicerca} dettagliRighe={dettagliRighe} onRowDblClick={props.onRowDblClick} />
                </Col>
            </Row>
        </>
    )
}

export default RicercaProdottiBD