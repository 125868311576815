import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Row from 'react-bootstrap/esm/Row'

export function TitoloContenuto(titolo: string, descrizione?: string) {
    return (
        <>
            <Row className="rowHeader">
                <Col sm="12">
                    <h2 id="pagHeadTitle" className="paginaHeaderTitolo">{titolo}</h2>
                </Col>
                {descrizione && <Col sm="12">
                    <h5 id="pagHeadDescr" className="paginaHeaderDescrizione">{descrizione}</h5>
                </Col>}
                
            </Row>
        </>
    )
}
