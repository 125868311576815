import notify from "devextreme/ui/notify";
import React, { useEffect } from "react";
import { useState } from "react";
import { RestServices } from "../../services/restServices";
import { Loading } from "../../widget/Notifications";
import { urlAnagrafiche } from "../costanti";
import { ElencoInvioNewsletter, DettaglioNewsletter } from "../../interfaces/newsletter";
import { TabellaCompletaInterface } from "../../interfaces/tabelle";
import { CreaColonne } from "../../funzioni/operazioniSuTabelle";
import { TitoloContenuto } from "../../struttura_pagina/TitoloContenuto";
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { TabellaCompleta, TabellaCompletaMasterDetail } from "../../widget/Tabelle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const ElencoNewsletter = (props: { toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        startCerca();
    }, []);

    const titolo = TitoloContenuto("Elenco Newsletter");
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [elencoNewsletter, setElencoNewsletter] = useState<ElencoInvioNewsletter[]>();

    const startCerca = () => {
        localStorage.clear();
        setIsVisible(true);
        const param = {};
        RestServices().rest('POST', param, urlAnagrafiche.getElencoNewsletter)
            .then(result => {
                setIsVisible(false);
                if (result) {
                    if (result === true) {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
                        setElencoNewsletter(result)
                    } else {
                        setElencoNewsletter(result);
                    }
                }
                else if (result && result.message && result.severity) {
                    notify({ position: "center", width: "auto", message: result.message }, result.severity, 4000);
                } else {
                    notify({ position: "center", width: "auto", message: "Oops c'e' stato un errore. Ci scusiamo per il disagio" }, 'error', 4000);
                }
            })
    }

    const propsTabElencoNewsletter: TabellaCompletaInterface = {
        id: "elenco_newsletter",
        dataSource: elencoNewsletter,
        selection: "none",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        summary: {

            totalItems: [{
                name: "TotaleNewsletter",
                showInColumn: "Id Newsletter",
                displayFormat: "Newsletter: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: true,
            fileName: "ElencoNewsletter"
        }
    }

    const columnsNewsletter = CreaColonne([
        { caption: "Id Newsletter", dataField: "idNewsletter", visible: true, width: 120 },
        { caption: "Oggetto", dataField: "oggetto", visible: true, width: 400 },
        { caption: "Messaggio", dataField: "messaggio", visible: true, width: 900 },
        { caption: "Segnalazioni", dataField: "segnalazioni", visible: false},
        { caption: "Data", dataField: "data", format: "dd/MM/yyyy HH:mm:ss", dataType: "date", sortOrder: "asc" },
    ])

    const columnsNewsletterDetail = CreaColonne([
        { caption: "Id Newsletter", dataField: "idNewsletter", visible: false },
        { caption: "Id Detttaglio", dataField: "idDettaglio", visible: true },
        { caption: "Email", dataField: "email" },
        { caption: "Cod.Tph", dataField: "codTph" },
        { caption: "Id Cli. Cont.tti", dataField: "idCliContratti" },
        { caption: "Esito invio", dataField: "esitoEmail", visible: true },
        { caption: "Esito messaggio", dataField: "esitoEmailMessaggio" },
    ])

    const onRowPrepared = (e: any) => {
        if (e.values) {
            console.log(e.data);
            if (e.data.segnalazioni == 1) {
                e.rowElement.style.backgroundColor = '#EEFF39';
            }
        }
    }

    return (
        <>
            {titolo}
            {loadPanel}
            <div className="rightcontent">
                <span id="lbl0"><FontAwesomeIcon icon={faInfoCircle} className="fa fa-info-circle fa-lg" title="Legenda colori" /> Legenda colori:</span>
                &nbsp;
                <span id="lblAvvisoSegnalazioni" title="Sono presenti anomalie nell'invio delle mail" className="lblAvvisoSegnalazioni">Anomalie invio email</span>
                &nbsp;
           
            </div>
            <div className="contenutoPagina">
                <Row className="tabellaFornitori">
                    <Col sm="12">
                        <TabellaCompletaMasterDetail
                            propsTabella={propsTabElencoNewsletter}
                            columns={columnsNewsletter}
                            columnsDetail={columnsNewsletterDetail}
                            setTabellaInstance={setTabellaInstance}
                            rowAlternationEnabled={false}
                            allowColumnResizing={false}
                            onRowPrepared={onRowPrepared}
                            urlDetail={urlAnagrafiche.getNewsletterDettaglio + '?idNewsletter='}
                            keyDetail={"idNewsletter"}
                            key={"idNewsletter"}
                        />

                        {/*<TabellaCompleta*/}
                        {/*    propsTabella={propsTabElencoNewsletter}*/}
                        {/*    columns={columnsNewsletter}*/}
                        {/*    setTabellaInstance={setTabellaInstance}*/}
                        {/*    rowAlternationEnabled={false}*/}
                        {/*/>*/}
                    </Col>
                </Row>
            </div>
        </>
    )

}

export default ElencoNewsletter;
