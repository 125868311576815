import React, { useEffect, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button'
import { Loading } from '../../widget/Notifications';
import { TextBox, Validator } from "devextreme-react";

import "./style.css";
import { AsyncRule, EmailRule, RequiredRule } from 'devextreme-react/validator';
import { RestServices } from '../../services/restServices';
import notify from 'devextreme/ui/notify';
import { urlCfPortlaLogin, urlGestioneUdiV2 } from '../costanti';
import { Link } from 'react-router-dom';
import { DialogRecuperoPassword } from './Dialog';

const LoginUdi = (props: {}) => {

    const [loading, setLoading] = useState<boolean>(false)
    const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(true)
    const [isRecuperaPasswordDisabled, setIsRecuperaPasswordDisabled] = useState<boolean>(true)
    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [popupRecuperoPassword, setPopupRecuperoPassword] = useState<boolean>(false)

    const cambiaValore = (tipo: string, value: string, tipoControllo?: string) => {
        let objTo = { ...objctTOsave }

        if (tipoControllo === 'Select') {
            //console.log(value)
            //value � un array
            let myArray = value as unknown as Array<any>;
            let ids: any[] = [];
            myArray.forEach((element: any) => {
                ids.push(element.value)

            });
            if (tipo === 'idFarmacie') {

            }

            objTo[tipo] = ids;

        } else if (tipoControllo === 'SelectSingle') {
            let v: any = value as unknown as any
            objTo[tipo] = v.value;
        } else {
            objTo[tipo] = value
        }

        setObjctTOsave(objTo)
        // console.log(objTo)

        var regex = /^[\d\w._ -]+@[\d\w._ -]+\.[\w]+$/i

        if ((objTo.nomeUtente == undefined || objTo.nomeUtente == "") || (objTo.password == undefined || objTo.password == "") || regex.test(objTo.nomeUtente) == false  )
            setIsLoginDisabled(true)
        else
            setIsLoginDisabled(false)

        

        if ((objTo.emailRecuperoPassword == undefined || objTo.emailRecuperoPassword == "") || regex.test(objTo.emailRecuperoPassword) == false)
            setIsRecuperaPasswordDisabled(true)
        else
            setIsRecuperaPasswordDisabled(false)


    //    console.log(objTo)
    }


    const recuperaPassword = () => {

        let param = {
            email: objctTOsave.emailRecuperoPassword,
        }
        setLoading(true)
        RestServices().rest('POST', param, urlGestioneUdiV2.recuperoPassword, undefined, undefined, 'eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYWRtaW4iOnRydWUsImlhdCI6MTUxNjIzOTAyMn0.VFb0qJ1LRg_4ujbZoRMXnVkUgiuKq5KxWqNdbKq_G9Vvz-S1zZa9LPxtHWKa64zDl2ofkT8F6jBt_K4riU-fPg')
            .then(result => {
                if (result) {
                    //console.log(result.response);
                    if (result.esito) {
                        notify({ position: "center", width: "auto", message: "Controllare la posta in arrivo" }, "success", 4000)
                        setIsRecuperaPasswordDisabled(true)
                        setPopupRecuperoPassword(false)
                        setLoading(false)
                    }
                    else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    }

                } else {
                    notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                }
            })


    }

    return (<>

        <Loading isVisible={loading} />
        <Row >
            <Col md="12" style={{ height: 18, background: '#00234f' }}>
                <nav role="navigation">
                    <Col>



                    </Col>



                </nav>

            </Col>
        </Row>

        <div className="bodyCgm">
            <div className="cgmInput">

                <div style={{ marginLeft: 20, marginRight: 20 }}>
                    <div style={{ gap: 10, background: 'white', padding: 10, borderRadius: 4, height: '900px' }} className="form-group">

                        <div className="row" style={{ marginTop: 16 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label style={{ fontSize: 20 }}> Dispositivi Medici e Diagnostici in vitro </label>
                            </div>
                            <div className="col">

                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 8 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label style={{ fontSize: 18, fontWeight: 500, height: 24, color: '#B7B9BD' }}> Adempimenti per gli operatori sanitari </label>
                            </div>
                            <div className="col">

                            </div>
                        </div>

                        <div className="row" style={{ marginTop: 24 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <label style={{ fontSize: 18, fontWeight: 500, height: 24, color: '#B7B9BD' }}> Compila i campi per effettuare l'accesso </label>
                            </div>
                            <div className="col">

                            </div>
                        </div>

                        <form action={urlCfPortlaLogin} method="POST" onSubmit={(e) => {
                            //e.preventDefault();
                            //console.log(objctTOsave)
                            //console.log(e)
                        }}>
                            <div className="row" style={{ marginTop: 8 }}>
                                <div className="col">

                                </div>

                                <div className="col">

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>
                                                <TextBox name="j_username" id="j_username" width="250" height="56"
                                                    valueChangeEvent="keyup" showClearButton={true}
                                                    onValueChanged={(v) => { cambiaValore("nomeUtente", v.value) }}
                                                    maxLength='100' placeholder="Email *" >
                                                    <Validator>
                                                        <RequiredRule message="Email obbligatoria" />
                                                        <EmailRule message="Email non valida" />
                                                    </Validator>
                                                </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>

                                    <div className="row" style={{ marginTop: 16 }}>
                                        <div className="col">

                                        </div>
                                        <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div>

                                                <TextBox
                                                    mode={"password"} name="j_password" id="j_password"
                                                    width="250" height="56" valueChangeEvent="keyup"
                                                    showClearButton={true}
                                                    onValueChanged={(v) => { cambiaValore("password", v.value) }}
                                                    maxLength='100' placeholder="Password *">

                                                 </TextBox>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <TextBox
                                                name="j_nologin" id="j_nologin"
                                                visible={false }
                                                width="250" height="56" valueChangeEvent="keyup"
                                                showClearButton={true}
                                                value={"/app/sfbd/loginUdi" }
                                                maxLength='100' placeholder="Password *">

                                            </TextBox>
                                        </div>
                                    </div>


                                </div>

                                <div className="col">

                                </div>


                            </div>


                            <div className="row">

                                <div className="col">

                                </div>
                                <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ width: 580, display: 'flex', justifyContent: 'right', marginTop: 24, marginRight: 330 }}>
                                        <button id={isLoginDisabled ? "btnVerificaCodiceProdottoCgm" : "btnVerificaCodiceProdottoCgmCheck"} 
                                            disabled={isLoginDisabled}>LOGIN </button>
                                    </div>

                                </div>
                                <div className="col">

                                </div>

                            </div>

                        </form>


                        <div className="row" style={{ marginTop: 24 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <a
                                        href=""
                                        onClick={(event) => {
                                            event.preventDefault();
                                            setPopupRecuperoPassword(!popupRecuperoPassword)
                                            
                                        }}
                                    >
                                        Hai dimenticato la password?
                                    </a>
                                        
                                    </div>
                            </div>
                            <div className="col">

                            </div>
                        </div>


                        {popupRecuperoPassword && < div className="row" style={{ marginTop: 24 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <TextBox width="250" height="56"
                                        valueChangeEvent="keyup" showClearButton={true}
                                        onValueChanged={(v) => { cambiaValore("emailRecuperoPassword", v.value) }}
                                        maxLength='100' placeholder="Email recupero password *" >
                                        <Validator>
                                            <RequiredRule message="Email obbligatoria" />
                                            <EmailRule message="Email non valida" />
                                        </Validator>
                                    </TextBox>
                                </div>
                            </div>
                            <div className="col">

                            </div>
                        </div>}

                        {popupRecuperoPassword && < div className="row" style={{ marginTop: 24 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: 580, display: 'flex', justifyContent: 'right', marginRight: 330 }}>
                                    <button id={isRecuperaPasswordDisabled ? "btnRecuperaPasswordCgm" : "btnRecuperaPasswordCgmCheck"}
                                        disabled={isRecuperaPasswordDisabled} onClick={ () => {recuperaPassword()}  }>RECUPERA </button>
                                </div>

                            </div>
                            
                            
                            <div className="col">

                            </div>
                        </div>}


                        <div className="row" style={{ marginTop: 24 }}>
                            <div className="col">

                            </div>
                            <div className="col" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div>
                                    <Link to={'/sfbd/signupUdi'}>Sei un nuovo utente? Registrati</Link>

                                </div>
                            </div>
                            <div className="col">

                            </div>
                        </div>
                        

                        
                    </div>
                </div>

            </div>
        </div>
        {/* 
        <DialogRecuperoPassword
            open={popupRecuperoPassword}
            close={() => { setPopupRecuperoPassword(false) }}
        />
        */ }
    </>)

}

export default LoginUdi