import React, { useEffect, useState } from 'react'
import NewTabella from "../../widget/nuova_tabella"
import notify from 'devextreme/ui/notify'
import { DialogConferma, Loading } from '../../widget/Notifications'
import { ListaFarmacieInterface } from '../../interfaces/farmacie'
import { RestServices } from "../../services/restServices"
import { urlConsultazione } from "../costanti"
import { useAuth } from '../../hooks/useAuth'
import Button from 'react-bootstrap/Button'
import { DialogGestBanca } from "./dialog/popup_gest_banca"
import { DialogGestConto } from "./dialog/popup_gest_conto"
import { AplicaValoreTO } from '../../funzioni/operazioniSuTabelle'
import './style.css';



const GestBanche = (props: { datiFarmacie: ListaFarmacieInterface[], toggleMenu(stato: boolean): any, statoMenu: boolean }) => {

    const columns = [
        { caption: "Abi", dataField: "abi" }, 
        { caption: "Cab", dataField: "cab" },  
        { caption: "Ragione Sociale", dataField: "ragioneSociale" },  
        { caption: "Filiale", dataField: "filiale" }, 
        { caption: "Azioni",  type: "buttons", buttons: [
            {
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {
                    setObjtipo(e.row.data)
                    setImmediate(true)
                    setOpenPop(true)
                    
                }
            },
            {
                hint: "Disabilita",
                icon: "trash",
                onClick: (e: any) => {
                    //console.log(e.row.data);             
                    setIddel(e.row.data.id)
                    setMessDel("La banca "+e.row.data.ragioneSociale+" | "+e.row.data.filiale+" sta per essere eliminata. Confermare?")
                    setPopDel(true)
                    
                }
            }]  },
     ];



     const columnsConti = [ 
        { caption: "Conto Corrente", dataField: "contoCorrente", width: 120 },  
        { caption: "Iban", dataField: "iban", width: 250 }, 
        { caption: "Codice", dataField: "codice", width: 60 }, 
        { caption:'Intestatario', dataField: "intestatario", width: 150},
        { caption:'Descrizione', dataField: "descrizione"},
        { caption:'Farmacia', dataField: "ragioneSocialeFarmacia"},
        { caption:'Azienda', dataField: "ragioneSocialeAzienda"},
        { caption:'Saldo iniziale', dataField: "saldoIniziale" , format: { type: "currency", precision: 2, currency: "EUR" }},     
        { caption:'Saldo corrente', dataField: "saldo", format: { type: "currency", precision: 2, currency: "EUR" } },
        { caption: "Azioni",  type: "buttons", buttons: [
            {
                hint: "Modifica",
                icon: "edit",
                onClick: (e: any) => {
                    setObjtipo2(e.row.data)
                    setImmediate2(true)
                    setOpenPop2(true)
                    
                }
            },
            {
                hint: "Disabilita",
                icon: "trash",
                onClick: (e: any) => {
                    setIddel(e.row.data.id)
                    setMessDel("Il conto "+e.row.data.contoCorrente+" sta per essere eliminata. Confermare?")
                    setPopDel2(true)
                    
                }
            }]  },
     ];

    
    const auth = useAuth(urlConsultazione.auth);

    
    const [tabellaPrincipale,setTabellaPrincipale] = useState<any>([])
    const [tabellaSecondaria,settabellaSecondaria] = useState<any>([])
    const [loading,setLoading] = useState<boolean>(false)
    const [openPop , setOpenPop ] = useState<boolean>(false)
    const [openPop2 , setOpenPop2 ] = useState<boolean>(false)
    const [popDel,setPopDel] = useState<boolean>(false)
    const [popDel2,setPopDel2] = useState<boolean>(false)
    const [idDel,setIddel] = useState<number>(0)
    const [messDel,setMessDel] = useState<string>('')
    const [objtipo,setObjtipo] = useState<any>(null)
    const [objtipo2,setObjtipo2] = useState<any>(null)
    const [edit,setImmediate] = useState<boolean>(false)
    const [edit2,setImmediate2] = useState<boolean>(false)
    const [idBanca,setIdBanca] = useState<number>(0)
    const [newFarmaList,setNewFarmaList] = useState([])


    const farmaList = () =>{
        if (auth) {
           RestServices().rest('POST', {} , urlConsultazione.getListFar)
               .then(result => {
                   if (result?.esito) {                    
                       //console.log(result.response);
                       setNewFarmaList(result.response)                    
                   }else{                                
                       //console.log(result);                   
                   }
               })     
       }
   }


    const getBancheAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {} , urlConsultazione.getListBanche)
                .then(result => {
                    if (result?.esito) {
                        setTabellaPrincipale(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }
    }

    const getContiAction = () =>{
        setLoading(true)
        if (auth) {
            RestServices().rest('POST', {idBanca:idBanca} , urlConsultazione.getListConti)
                .then(result => {
                    if (result?.esito) {
                        settabellaSecondaria(result.response)
                        setLoading(false)
                    }else{
                        //console.log(result);
                        notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                        setLoading(false)
                    }
                })
        
    }
    }


    const removeTipo = (conto?:boolean) =>{
        if (auth) {
            if(conto){
                RestServices().rest('POST', {idConto:idDel} , urlConsultazione.deleteConto)
                .then(result => {
                   if(result.esito){
                       setPopDel2(false)
                       ricarica2()
                      
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }
        
                }) 
            }else{
                RestServices().rest('POST', {idBanca:idDel} , urlConsultazione.deleteBanca)
                .then(result => {
                   if(result.esito){
                       setPopDel(false)
                       setIdBanca(0)
                       ricarica()
                      
                   }
                   else{
                    //console.log(result);
                    notify({ position: "center", width: "auto", message: result.message}, "error", 4000)
                }
        
                }) 
            }
             
         } 
    }

   

    const ricarica = () =>{
        setOpenPop(false)  
        getBancheAction()    
    }
    const ricarica2 = () =>{
        setOpenPop2(false)  
        getContiAction()
    }


   useEffect(()=>{
       getBancheAction()
       farmaList()
    },[auth])
    
    useEffect(() => {
       
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);



    useEffect(()=>{
        if(idBanca){
            getContiAction()
        }
        
     },[idBanca])

     const formatNomeBanca = () =>{
        let banche = tabellaPrincipale.filter((banca: { id: number }) => banca.id == idBanca);
        return banche[0].ragioneSociale+' | '+banche[0].filiale
     }
  
   
    return (
        <>
             <DialogConferma
                    dialogIsOpen={popDel}
                    message={messDel}
                    functionNext={()=>removeTipo()}
                    handleCloseConferma={()=>{setPopDel(false);setIddel(0)}}
                />

             <DialogConferma
                    dialogIsOpen={popDel2}
                    message={messDel}
                    functionNext={()=>removeTipo(true)}
                    handleCloseConferma={()=>{setPopDel2(false);setIddel(0)}}
                />        

            <DialogGestBanca typeEdit={edit} edit={objtipo} ricarica={()=>ricarica()} close={()=>{setOpenPop(false);setObjtipo({});setImmediate(false)}} open={openPop} />
            <DialogGestConto listaFarmacie={newFarmaList} idBanca={idBanca} typeEdit={edit2} edit={objtipo2} ricarica={()=>ricarica2()} close={()=>{setOpenPop2(false);setObjtipo2({});setImmediate2(false)}} open={openPop2} />

        <Loading isVisible={loading} />
            <div  className='title'>
                <h3> GESTIONE BANCHE<span className='subt' ></span> </h3>
             </div>
             <Button  className='butt_banche'   variant="primary" onClick={()=>setOpenPop(true)} >
               NUOVA BANCA
            </Button>
               
            
            <NewTabella 
                idTabella='Gest_banche'
                sync
                colonne={columns} 
                dataSource={tabellaPrincipale} 
                filterRow
                searchPanel={true}
                onclickRow={(datiRow:any)=>setIdBanca(datiRow.data?.id)}          
                exportT={{
                    enabled: true,
                    fileName: "gest_banche"
                }}
                activeSaver={true}
                columnC={true}
                height='40vh'
                
            />

           
            {idBanca ? (
                <div>
                     <div  className='title'>
                <h3> CONTI CORRENTI {formatNomeBanca()}<span className='subt' ></span> </h3>
             </div>
                    <Button  className='butt_conto'   variant="primary" onClick={()=>setOpenPop2(true)} >
               NUOVO CONTO
            </Button>
               
            
            <NewTabella 
                idTabella='Gest_conti'
                sync
                colonne={columnsConti} 
                dataSource={tabellaSecondaria} 
                filterRow
                searchPanel={true}
                exportT={{
                    enabled: true,
                    fileName: "gest_conti"
                }}
                activeSaver={true}
                columnC={true}
                height='45vh'
                
            />
                </div>
            ) : null}
           
        </>
    )
}

export default  GestBanche


