import React, { useEffect, useRef, useState } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { columnsAnalisiVendDitta, columnsAnalisiVendOperativo2, columnsAnaVenduto, columnsAnaPezzi, columnsAnaPezzi2 } from "./Colums/ColAnalOperativo";
import { AplicaValoreTO } from "../../../funzioni/operazioniSuTabelle";
import { MenuCruscottoOperativo } from "../components/menu_operativo";
import Button from "react-bootstrap/Button";
import { createItemFilterAnalVendDitta } from "./ItemsFiltri/FiltriAnalOperativo";
import { urlBancaDati, urlConsultazione, urlReport } from "../../costanti";
import { LoadIndicator } from "devextreme-react/load-indicator";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Chart, PieChart } from 'devextreme-react';
//import { boxCard, boxsecond } from "./BoxCard/analisiPerOperatore";
import { Animation, ArgumentAxis, CommonSeriesSettings, Export, Format, Font, Label, Legend, Series, Subtitle, Title, Tooltip, ValueAxis } from 'devextreme-react/chart';
import { Connector, HoverStyle, Size, SmallValuesGrouping } from 'devextreme-react/pie-chart';

import "./style.css";
import { cardC, CardCart } from "../components/card_chart";
import { boxCardVendCategory } from "./Boxcards/AnalOperativo";
import { trasform_for_cards_charts_01 } from "../util/trasformation";

const AnaliVendiDitta = (props: {
  datiFarmacie: ListaFarmacieInterface[];
  toggleMenu(stato: boolean): any;
  statoMenu: boolean;
}) => {
  const [ditte, setDitte] = useState<any[]>([]);
  const [operatori, setOperatori] = useState<any[]>([]);
  const [valoriTabella1, setValoriTabella1] = useState<any>([]);
  const [valoriTabella2, setValoriTabella2] = useState<any>([]);
  const [valoriTabella3, setValoriTabella3] = useState<any>([]);
  const [valoriTabella4, setValoriTabella4] = useState<any>([]);
  const [valoriTabella5, setValoriTabelle5] = useState<any>([]);
  const [fila1cards, setFila1cards] = useState<cardC[]>(boxCardVendCategory);
  const [pie, setPie] = useState<any[]>([]);
  const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
  const [paramsFilter, setParamsFilter] = useState<Filtri>({});
  const [isVisible, setIsVisible] = useState(false);
  const [loadinCambio, setLoadinCamnbio] = useState(false);
  const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
  const [visiblePop, setVisiblePop] = useState(false);
  const [testoPop, setTestoPop] = useState<string>("");
  const [settori, setSettori] = useState<any[]>([]);
  const [chartData, setChartData] = useState<any>([]);
  const auth = useAuth(urlConsultazione.auth);
  const chartRef = useRef<any>(null);
  const authReport = useAuth(urlReport.auth);
  const [gruppoMercFilters, setGruppoMercFilters] = useState<any[]>([]);
  const [tipologiaVendita, setTipologiaVendita] = useState<any[]>([]);
  const loadPanel = <Loading isVisible={isVisible} />;
  const [visiblePop1, setVisiblePop1] = useState(false);
  const [visiblePop5, setVisiblePop5] = useState(false);
  const [openPop, setOpenPop] = useState<boolean>(false);
  const [openPopTwo, setOpenPopTwo] = useState<boolean>(false);
  const [openPopThree, setOpenPopThree] = useState<boolean>(false);
  const [objtipo, setObjtipo] = useState<any>(null);

  const dashboard19 = { dashboardID: 19 };


  function aggiornaDataFine(dataFine: Date): Date {
    const nuovaDataFine = new Date(dataFine);

    nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

    return nuovaDataFine;
  }

  const filterAction = (objFiltro: Filtri) => {
    let params = { ...objFiltro };

    setParamsFilter(params);

    let newIDFAR: any = [];

    if (objFiltro.idFar) {
      objFiltro.idFar.forEach((element: any) => {
        newIDFAR.push(element);
      });
      params.idFar = newIDFAR;
    }

      let venditaDPC = params?.venditaDPC ? [] : [0];
      let flagBusta = params?.flagBusta ? [] : [0];

    let gruppoMerc1;
    let gruppoMerc2;
    let gruppoMerc3;

    let codAtc1;
    let codAtc2;
    let codAtc3;
    let codAtc4;
    let codAtc5;

    if (params.gruppoMerc) {
      gruppoMerc1 = params.gruppoMerc.filter(
        (item: number) => item % 100 === 0
      );
      gruppoMerc2 = params.gruppoMerc.filter(
        (item: number) => item % 10 === 0 && item % 100 !== 0
      );
      gruppoMerc3 = params.gruppoMerc.filter((item: number) => item % 10 !== 0);
    }

    if (params.atc) {
      const atcList = params.atc;
      codAtc1 = getATCByLevel(atcList, 1);
      codAtc2 = getATCByLevel(atcList, 3);
      codAtc3 = getATCByLevel(atcList, 4);
      codAtc4 = getATCByLevel(atcList, 5);
      codAtc5 = getATCByLevel(atcList, 7);
    }

    if (params.periodo.al) {
      params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
    }

    const parameters = {
      //codiceSettore: params.codiceSettore,
      gruppoMerc1: gruppoMerc1,
      gruppoMerc2: gruppoMerc2,
      gruppoMerc3: gruppoMerc3,
      venditaSSN: params.venditaSSN,
      //codAtc1: codAtc1,
      //codAtc2: codAtc2,
      //codAtc3: codAtc3,
      //codAtc4: codAtc4,
      //codAtc5: codAtc5,
      ditta: params.ditta,
      venditaDPC: venditaDPC,
      flagBusta: flagBusta,
      //operatori: params.operatori,
      dal: params.periodo.dal.toLocaleString().replace(",", ""),
      al: params.periodo.al.toLocaleString().replace(",", ""),
    };

    if (
      params.idFar.length === 0 ||
      params.idFar === undefined ||
      params.periodo.dal === null ||
      params.periodo.al === null
    ) {
      notify(
        {
          position: "center",
          width: "auto",
          message: "Filtro per farmacia e periodo obbligatori.",
        },
        "error",
        4000
      );
      return;
    }

    setIsVisible(true);
    if (authReport) {
      const dashboardCall19 = {
        ...dashboard19,
        idFarList: params.idFar,
        params: parameters,
      };

      //new call

      //console.log('mostro call');
      //console.log(dashboardCall1);

      RestServices()
        .rest("POST", dashboardCall19, urlReport.getQueryDashboardAllData)
        .then((result) => {
          if (result) {

            let datiPrimaParteCards = trasform_for_cards_charts_01(result["104"], boxCardVendCategory);
            setFila1cards(datiPrimaParteCards);

            let datiPrimaTabelle = result["82"];
            let datiSecTabelle = result["83"];
            let datiThirdTabelle = result["84"]
            let datiFourthTabelle = result["84"]
            let datiFifthTabelle = result["85"]

            setValoriTabella1(datiPrimaTabelle);
            setValoriTabella2(datiSecTabelle);
            setValoriTabella3(datiThirdTabelle);
            setValoriTabella4(datiFourthTabelle);
            setValoriTabelle5(datiFifthTabelle)

            // 1 Chart
            setChartData(result["83"].reverse());

            //  1 chartpie    

            setPie(result["84"]);

            setIsVisible(false);

          } else {
            notify(
              { position: "center", width: "auto", message: result.message },
              "error",
              4000
            );
          }
        })
        .catch((error) => {
          setIsVisible(false);
          notify(
            {
              position: "center",
              width: "auto",
              message: "Errore" + error,
            },
            "error",
            4000
          );
        });



      const inizioAnno = new Date(); // Ottieni la data corrente

      // Imposta la data al 1° gennaio dell'anno corrente
      inizioAnno.setMonth(0); // Imposta il mese a gennaio (0-based, quindi 0 è gennaio)
      inizioAnno.setDate(1); // Imposta il giorno a 1

      // const oggi = new Date();

      // testapi.params.dal = convertTime(inizioAnno)
      // testapi.params.al = convertTime(oggi)

      setParamsFilter(params);
      setIsFiltersExpanded(false);
    }
  };

  const farmaList = () => {
    if (auth) {
      RestServices()
        .rest("POST", {}, urlConsultazione.getListFar)
        .then((result) => {
          if (result?.esito) {
            setNewFarmaList(result.response);
          } else {
            // handle error
          }
        });

      RestServices()
        .rest("POST", {}, urlConsultazione.getListOperatoreDistinct)
        .then((result) => {
          if (result.esito) {
            const operatori = result.response.map(
              (ope: { idFarmacia: any; codice: any; operatore: any }) => {
                //console.log(ope.idFarmacia);
                //const farmaciaCorrispondente = newFarmaList.find(farma => farma.idFar === ope.idFarmacia);
                //console.log(farmaciaCorrispondente);
                return {
                  ...ope,
                  id: `${ope.operatore}`,
                  descrizione: `${ope.operatore}`,
                  codice_descrizione: `${ope.codice} - ${ope.operatore}`,
                };
              }
            );
            setOperatori(operatori);
            // console.log('operatori');
            // console.log(operatori);
          }
        });
    }
  };




  useEffect(() => {
    if (props.statoMenu) {
      props.toggleMenu(!props.statoMenu);
    }
  }, []);

  useEffect(() => {
    farmaList();
  }, [auth]);

  const filters = () => {
    if (auth) {
      RestServices()
        .rest("POST", "", urlBancaDati.getGruppoMerceologico)
        .then((result) => {
          if (result) setGruppoMercFilters(result);
        });

      RestServices()
        .rest("POST", "", urlBancaDati.getSettore)
        .then((result) => {
          if (result) setSettori(result);
        });


      RestServices()
        .rest("POST", "", urlBancaDati.getDitte)
        .then((result) => {
          if (result) {
            const ditteWithCodiceDescrizione = result.map(
              (ditta: { codice: any; descrizione: any }) => {
                return {
                  ...ditta,
                  codice_descrizione: `${ditta.codice} - ${ditta.descrizione}`,
                };
              }
            );
            // console.log('da togliere vediamo ditte');
            // console.log(ditteWithCodiceDescrizione);

            setDitte(ditteWithCodiceDescrizione);
          }
        });
    }

    const ssn = { id: 1, descrizione: "SSN" };
    const libera = { id: 0, descrizione: "Libera" };

    const vendite = [libera, ssn];
    setTipologiaVendita(vendite);
  };

  useEffect(() => {
    filters();
  }, [auth]);

   
    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("EsportazioneDati");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_EsportazioneDati.xlsx"
                );
            });
        });
    };

  const itemsFields = createItemFilterAnalVendDitta(
    newFarmaList,
    tipologiaVendita,
    gruppoMercFilters,
    ditte
  );

  function getATCByLevel(atcList: any[], level: number) {
    return atcList.filter((atc) => atc.length === level * 1);
  }

  /*sitema grafici */

  const startLoading = () => {
    setLoadinCamnbio(true);
    setTimeout(() => {
      setLoadinCamnbio(false);
    }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
  };

  useEffect(() => {
    startLoading();
  }, [isFiltersExpanded]);

  const ricarica = () => {
    setOpenPop(false);
    setObjtipo(null);
    //caricaDati()
  };

  const popVisible1 = (testo: string) => {
    setVisiblePop1(true);
  };
  const popVisible5 = (testo: string) => {
    setVisiblePop5(true);
  };

  let messOne = ["Analisi CìVed"];
  let mess5 = ["Valore 5"];

  return (
    <>
      <section style={{ display: "flex", height: "98vh" }}>
        {loadPanel}
        {loadinCambio ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LoadIndicator id="small-indicator" height={50} width={50} />
          </div>
        ) : (
          <section
            id="scrollerelement"
            style={{ width: "100%", height: "100%", overflow: "scroll" }}
          >
            <div
              style={{
                fontSize: "15px",
                alignItems: "left",
                marginTop: "10px",
                marginBottom: "10px",
                color: "rgb(80, 84, 92)",
              }}
            >
              <h2>BusinessLab  - Cruscotto Operativo</h2>
            </div>
            <div
              style={{
                display: "block",
                gap: 5,
                padding: 10,
                borderRadius: 10,
                marginTop: 10,
                width: "auto",
              }}
            >
              <div>
                <MenuCruscottoOperativo />
              </div>
            </div>

            {/* flexbox */}

            <Popup
              visible={visiblePop}
              showCloseButton={true}
              onHiding={() => setVisiblePop(false)}
              width={"40%"}
              height={"40%"}
              showTitle={true}
              title="Dettagli widget"
            >
              <div>
                <p>{testoPop}</p>
              </div>
            </Popup>


                          <div style={{ paddingBottom: 5 }} className="grid-container-six">
                              {fila1cards.map((el, i) => {
                                  return (
                                      <CardCart
                                          testo={el.testo}
                                          subTesto={el.subTesto}
                                          key={i}
                                          valore={el.valore}
                                          subValore={el.subValore}
                                          starter={el.starter}
                                          typeShowValue={el.typeShowValue}
                                          typeShowSubValue={el.typeShowSubValue}
                                          testoPop={el.testoPop}
                                      />
                                  );
                              })}
                          </div>

     
          <h6 style={{ fontSize:- 20, color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginLeft: '5px' }}>Dettaglio per prodotto</h6>
          <div style={{ marginTop: "5px", marginBottom: 20 }} >

            <NewTabella
              height={"70vh"}
              idTabella="AnaVenDitta"
              sync
              colonne={columnsAnalisiVendDitta}
              dataSource={valoriTabella1}
              rowAlternation={true}
              filterRow
              width={500}
              searchPanel={true}
              exportT={{
                enabled: true,
                fileName: "DettaglioProdotti",
                formats: { exportFormats },
              }}
              activeSaver={true}
              columnC={true}
              wordWrapEnabled={true}
              headerFilter
              onExporting={onExporting}
              summary={
                <Summary>
                  <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                  <TotalItem column="OMAGGI" summaryType="sum" />
                  <TotalItem
                    column="COSTO"
                    summaryType="sum"
                    valueFormat={{ style: "currency", currency: "EUR" }}
                  />
                </Summary>
              }
            />
      </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "white",
                marginTop: "5px",
                marginBottom: 30
              }}
            >

          <div style={{ width: "96%", marginTop: 10, paddingBottom: 50 }}>
            <Chart dataSource={chartData.slice(0,22)} ref={chartRef}>
            <CommonSeriesSettings
               argumentField="PRO_DESC_DITTA_TITOLARE"
               type="bar"
           />

             <Series
               valueField="PEZZI"
               type="bar"
               color="#55bce3"
          />

             <Tooltip
               enabled={true}
               customizeTooltip={({
               value,
               argument,
            }: {
                value: any;
                argument: any;
         }) => {
                   return {
                       text: `Pezzi: ${value} \n Ditta: ${argument}`,
                      };
                    }}
          />
            <Title text="Numero pezzi per ditta">
            <Font color="#5b6372" size="20" weight="400" />
            </Title>
            <Legend visible={false} />
            <Export fileName="Numero pezzi per ditta" enabled={true} />
            </Chart>
                </div>
           </div>


            {/* 2 tabella*/}
         <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginBottom: 10, marginLeft: '5px' }}>Dettaglio per ditta e farmacia</h6>

        <div style={{ marginTop: "5px", marginBottom: 20 }}>
              <NewTabella
                height={"70vh"}
                idTabella="AnaVenDitta"
                sync
                colonne={columnsAnalisiVendOperativo2}
                dataSource={valoriTabella2}
                rowAlternation={true}
                filterRow
                width={500}
                searchPanel={true}
                exportT={{
                  enabled: true,
                  fileName: "DettaglioFarmacia",
                  formats: { exportFormats },
                }}
                activeSaver={true}
                columnC={true}
                wordWrapEnabled={true}
                headerFilter
                onExporting={onExporting}
                summary={
                  <Summary>
                    <TotalItem column="PEZZI_ACQUISTATI" summaryType="sum" />
                    <TotalItem column="OMAGGI" summaryType="sum" />
                    <TotalItem
                      column="COSTO"
                      summaryType="sum"
                      valueFormat={{ style: "currency", currency: "EUR" }}
                    />
                  </Summary>
                }
              />
         </div>
  <div
   style={{
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
       paddingBottom: 20,
       marginBottom: 30,
       backgroundColor: 'white',
   }}
   >
       <div style={{ width: "45%" }}>
           <PieChart
               id="pie"
               dataSource={pie}
               palette="Violet"
               paletteExtensionMode="blend"
               type="doughnut"

           >
           <Series argumentField="FARM_DESCRIZIONE" valueField="VALORE_VENDUTO">
               <HoverStyle color="rgb(255, 0, 0)" />
           </Series>

          <Series argumentField="ANNO" valueField="VALORE_VEDNUTO">
              <HoverStyle color="RGB(255, 0, 0)" />
           </Series>
           
           <Tooltip
               enabled={true}
               customizeTooltip={({
                   argument,
                   value,
               }: {
                   argument: any;
                   value: any;
               }) => {
                   const dataItem = pie.find(
                       (item: any) => item.FARM_DESCRIZIONE === argument
                   );
                   const dataSourceValue = dataItem
                       ? dataItem.VALORE_VENDUTO
                       : 0;
                   const formattedValue = new Intl.NumberFormat("it-IT", {
                       style: "currency",
                       currency: "EUR",
                   }).format(dataSourceValue);                                        
                                          return {
                                              text: `${dataItem.FARM_DESCRIZIONE}\nValore del venduto: ${formattedValue}\nAnno ${dataItem.ANNO}`,
                                          };
                                      }}
                                  />

                                  <Export enabled={true} />
                              </PieChart>
                              </div>
                          </div>

               <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginBottom: 10, marginLeft: '5px' }}>Valore del venduto</h6>
                          <div style={{ marginTop: "5px", marginBottom: 20 }}>
                              <NewTabella
                                  height={"70vh"}
                                  idTabella="AnaVenDitta"
                                  sync
                                  colonne={columnsAnaVenduto}
                                  dataSource={valoriTabella3}
                                  rowAlternation={true}
                                  filterRow
                                  width={500}
                                  searchPanel={true}
                                  exportT={{
                                      enabled: true,
                                      fileName: "ValoreDelVenduto",
                                      formats: { exportFormats },
                                  }}
                                  activeSaver={true}
                                  columnC={true}
                                  wordWrapEnabled={true}
                                  headerFilter
                                  onExporting={onExporting}
                              />
                          </div>
                          <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginBottom: 5, marginLeft: '5px' }}>Pezzi</h6>
                          <div style={{ marginTop: "5px", marginBottom: 20 }}>
                              <NewTabella
                                  height={"70vh"}
                                  idTabella="AnaVenDitta"
                                  sync
                                  colonne={columnsAnaPezzi}
                                  dataSource={valoriTabella4}
                                  rowAlternation={true}
                                  filterRow
                                  width={500}
                                  searchPanel={true}
                                  exportT={{
                                      enabled: true,
                                      fileName: "Pezzi",
                                      formats: { exportFormats },
                                  }}
                                  activeSaver={true}
                                  columnC={true}
                                  wordWrapEnabled={true}
                                  onExporting={onExporting}
                              />
                          </div>
                          <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginBottom: 5, paddingBottom: 10, marginLeft: '5px' }}>Pezzi </h6>
                          <div style={{ marginTop: "5px", marginBottom: 20 }}>
                              <NewTabella
                                  height={"auto"}
                                  idTabella="AnaVenDitta"
                                  sync
                                  colonne={columnsAnaPezzi2}
                                  dataSource={valoriTabella5}
                                  rowAlternation={true}
                                  filterRow
                                  width={500}
                                  searchPanel={true}
                                  exportT={{
                                      enabled: true,
                                      fileName: "Pezzi2",
                                      formats: { exportFormats },
                                  }}
                                  activeSaver={true}
                                  columnC={true}
                                  wordWrapEnabled={true}
                                  headerFilter
                                  onExporting={onExporting}
                              />
                          </div>
                   </section>
                 )}

        <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
          <FontAwesomeIcon
            icon={faCaretUp}
            rotation={270}
            onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
            style={{ fontSize: "30px", color: "black" }}
          />
        </div>
        <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
          <div style={{}}>
            <FiltriVerticale
              ricerca={filterAction}
              items={itemsFields}
              paramsFilter={paramsFilter}
            />
          </div>
        </section>
      </section>
      <Popup
        visible={visiblePop1}
        showCloseButton={true}
        onHiding={() => setVisiblePop1(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{messOne}</p>
        </div>
      </Popup>
      <Popup
        visible={visiblePop5}
        showCloseButton={true}
        onHiding={() => setVisiblePop5(false)}
        width={"40%"}
        height={"40%"}
        showTitle={true}
        title="Dettagli widget"
      >
        <div>
          <p>{mess5}</p>
        </div>
      </Popup>
    </>
  );
};

export default AnaliVendiDitta;
