import notify from 'devextreme/ui/notify';
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Button from 'react-bootstrap/esm/Button';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import { CreaColonne } from '../../funzioni/operazioniSuTabelle';
import { useAuth } from '../../hooks/useAuth';
import { GiacenzaDettInterface, OrdineLiberoQuantitaInterface, RicercaProdInterface } from '../../interfaces/bancaDati';
import { ListaFarmacieInterface } from '../../interfaces/farmacie';
import { TabellaCompletaInterface } from '../../interfaces/tabelle';
import { RestServices } from '../../services/restServices';
import { BoxTestoCerca } from '../../widget/Editor';
import { Loading } from '../../widget/Notifications';
import { TabellaOrdiniLiberi } from '../../widget/Tabelle';
import { urlAnagrafiche, urlB2B, urlBancaDati, urlCommon, urlOrdiniLiberi } from '../costanti';
import DialogAggiungiProdOrdine from '../../common/DialogAggiungiProdOrdine';
import { DialogFattureNote, DialogFattureScadenza, DialogInfo, DialogNuovoOrdineLibero, DialogProdottiSegnalazione } from './Dialog';
import TestataOrdineLibero from './TestataOrdineLIbero';
import { NewTestataInterface } from '../../interfaces/ordineLibero';
import { DrawerFiltri } from '../../widget/Drawer';
import { FornitoriCentrInterface, GruppiInterface, OggettoInterface, SettoriInterface } from '../../interfaces/generiche';
import CreaTabelleGiacenze from './TabelleGiacenze';
import { getCodiceFarmFromId, getDescrFarmFromId, getRagSocCentralFromId } from '../../funzioni/anagrafica';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SplitPane, { Pane, SashContent } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css'
import { faCaretUp, faInfoCircle, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { render } from '@testing-library/react';

import './style.css';
import CreaPannelloInfo from './PannelloInfo';
import SpeedDialAction from 'devextreme-react/speed-dial-action';
import ScrollView from 'devextreme-react/scroll-view';

const SingoloOrdineLibero = (props: {
    toggleMenu(stato: boolean): any,
    statoMenu: boolean,
    farmacieGruppo: ListaFarmacieInterface[],
    flagOrdineB2B?: boolean | undefined
}) => {
    const auth = useAuth(urlOrdiniLiberi.auth);
    const authCommon = useAuth(urlCommon.auth);
    const [isOrdineB2B, setIsOrdineB2B] = useState<boolean>(false)

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }



        if (window.location.href.includes("B2B")) {
            setIsOrdineB2B(true)
        }

        //console.log(props.flagOrdineB2B)

    }, []);
    const [isVisible, setIsVisible] = useState(false);
    const loadPanel = <Loading isVisible={isVisible} />
    const [isLoadVisible, setIsLoadVisible] = useState(false);
    const [isLoadVisibleAltreInfo, setIsLoadVisibleAltreInfo] = useState(false);
    const loadPanelGiacenze = <Loading isVisible={isLoadVisible} position="#rigaTabGiacenze" />
    const loadPanelAltreInfo = <Loading isVisible={isLoadVisibleAltreInfo} position="#rigaTabAltreInfo" />

    const [fornitori, setFornitori] = useState<FornitoriCentrInterface[]>();

    const [settori, setSettori] = useState<SettoriInterface[]>();

    const [gruppi, setGruppi] = useState<GruppiInterface[]>();

    const [idOrdineLibero, setIdOrdineLibero] = useState<string | number>();
    const [isDialogNewOpen, setIsDialogNewOpen] = useState<boolean>(false);
    const handleCloseDialogNew = () => setIsDialogNewOpen(false);
    const [rowIndex, setRowIndex] = useState<number>();

    const [datiFarmacie, setDatiFarmacie] = useState<ListaFarmacieInterface[]>();

    //flag comportamento termine riga
    const [ricercaAutomaticaTermineRiga, setRicercaAutomaticaTermineRiga] = useState<boolean>(true);

    const [descrizioneFornitore, setDescrizioneFornitore] = useState<string>();

    const [newTestata, setNewTestata] = useState<NewTestataInterface>({
        titolo: undefined,
        lstFar: undefined,
        idFornitore: undefined,
        idSettore: undefined,
        idGruppo: undefined,
        trattati: undefined,
        sconto: undefined,
        flagUltimoCosto: undefined,
        flagQuantitaOmaggio: undefined,
        idDitta: undefined,
        idLinea: undefined,
        idClasseA: undefined,
        idClasseC: undefined,
        idSopOtc: undefined,
        idGenerici: undefined,
        idCategory: undefined,
        idStato: undefined,
        stato: undefined,
        flagIncludiProdotti: undefined,
        flagUltimoSconto: undefined,
        flagVisualizzaVendutoGiacenza: undefined,
        flagVisualizzaVendutoGiacenzaTotale: undefined,
        flagVisualizzaUltimiAcquisti: undefined,
        flagVisualizzaListiniOfferte: undefined,
        flagVisualizzaAltreInfo: undefined,
        flagVisualizzaIdentici: undefined,
        flagVisualizzaTrasferimenti: undefined,
        flagVisualizzaSimili: undefined,
        avvisoFattureScadenza: undefined,
        avvisoProdottiSegnalazione: undefined,
        flagVisualizzaListiniOfferteIdentici: undefined,
        flagVisualizzaPrezzoFarmacia: undefined,
        flagModificaPrezzoFarmacia: undefined,
        avvisoFattureNote: undefined,
        flagCostoListino: undefined,
        flagVisualizzaListiniOrdineLibero: undefined,
        flagOrdineB2B: undefined,
        flagGeneraProposta: undefined,
        idTipologiaProposta: undefined,
        dataProposta: undefined,
        giorniLavoratiProposta: undefined,
        giorniCoperturaProposta: undefined,
        idStatoProposta: undefined,
        statoProposta: undefined,
        flagConsideraOrdiniApertiProposta: undefined,
        flagEscludiTrasferimenti: undefined,
        statoApprovazione: undefined,
        utenteStatoB2B: undefined,
        dataStatoB2B: undefined
    });

    const [openDrawer, setOpenDrawer] = useState(true);
    const [titoloDrawer, setTitoloDrawer] = useState<string>("")

    useEffect(() => {


    }, [])


    useEffect(() => {
        if (auth && authCommon) {

            RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                if (result) setSettori(result);
            })

            RestServices().rest('POST', "", urlBancaDati.getGruppo).then(result => {
                if (result) setGruppi(result);
            })


            carica()
        }
    }, [auth, authCommon])

    useEffect(() => {

        //console.log("ciao")
        //console.log(isOrdineB2B)

        if (auth && authCommon) {
            if (isOrdineB2B == undefined || isOrdineB2B == false) {
                RestServices().rest('POST', "", urlAnagrafiche.getFornitoreCentral).then(result => {
                    if (result) setFornitori(result);
                })
            } else {
                let param = { lstFar: newTestata.lstFar }
                RestServices().rest('POST', param, urlB2B.getListFornitore).then(result => {
                    if (result) {
                        if (result.response) {
                            let list: any = [];
                            result.response.forEach((item: any) => {
                                list.push({ ID: item.id, RAGIONE_SOCIALE: item.ragione_SOCIALE, PARTITA_IVA: item.partita_IVA })
                            })
                            setFornitori(list);
                        }

                    }

                })
            }
        }
        

    }, [newTestata, auth, authCommon])


    const carica = () => {

        

        if (window.location.href.includes("?")) {
            setIsVisible(true);
            let idOrdine = window.location.href.split("?")[1]
            const preimpostato = idOrdine.split("&")[1]
            if (preimpostato) {
                if (preimpostato === "true") {
                    setRicercaAutomaticaTermineRiga(false)
                    idOrdine = idOrdine.split("&")[0]
                }
                else {
                    setRicercaAutomaticaTermineRiga(true);
                    idOrdine = idOrdine.split("&")[0];
                }
            }


            RestServices().rest('POST', idOrdine, urlOrdiniLiberi.gatFar)
                .then(result => {
                    if (result) {
                        setDatiFarmacie(result);
                    }
                })

            setIdOrdineLibero(idOrdine);
            RestServices().rest('POST', idOrdine, urlOrdiniLiberi.getDettagliOrdine)
                .then(result => {
                    if (result) {
                        setIsVisible(false);
                        //console.log(result.testata);
                        setNewTestata(result.testata);
                        setDettagliOrdineLibero(result.lstDettaglioProd);
                        setOpenDrawer(false);
                        if (!ricercaAutomaticaTermineRiga)
                        //focus prima riga e posizionamento colonna
                        {
                            tabellaInstance.instance.selectRowsByIndexes(0);
                            posizionaPrimaColonnaRigaCorrente(false);
                        }
                    }
                })
        } else {
            const idOrdine = '0';
            RestServices().rest('POST', idOrdine, urlOrdiniLiberi.gatFar)
                .then(result => {
                    if (result) {
                        setDatiFarmacie(result);
                        setIsVisible(false);
                    }
                })
            setIsDialogNewOpen(true);
        }
    }

    useEffect(() => {
        if (newTestata && newTestata.lstFar) {
            const numeroFarmacie = newTestata.lstFar.length;
            const spazioTabella = document.getElementById("rigaTabDettOrdLib");
            let aggiunta = 0;
            const numeroRighe = Math.ceil(numeroFarmacie / 4);
            switch (numeroRighe) {
                case 1:
                    aggiunta = 66.5;
                    break;
                case 2:
                    aggiunta = 68.5;
                    break;
                case 3:
                    aggiunta = 74.5;
                    break;
            }
            const altezzaVenduto = (19.3 + (61.2 * numeroRighe) + aggiunta);
            if (spazioTabella) {
                spazioTabella.style.height = `calc(90vh - ${altezzaVenduto}px)`
            }
        }
        if (newTestata && newTestata.idFornitore && newTestata.titolo && fornitori) {
            let riepilogoDrawer = "Titolo: ";
            riepilogoDrawer += newTestata.titolo + ' - Fornitore: ';
            const descrFornitore = getRagSocCentralFromId(fornitori, newTestata.idFornitore);
            //const descStatoFornitore = ' - Stato: ' + newTestata.stato;
            setDescrizioneFornitore(descrFornitore)
            riepilogoDrawer += descrFornitore;
            setTitoloDrawer(riepilogoDrawer);
        }

        if (newTestata && newTestata.statoProposta && (newTestata.idStatoProposta == 1 || newTestata.idStatoProposta == 2)) {
            notify({ position: "center", width: "auto", message: "Attenzione!! la proposta di ordine e' " + newTestata.statoProposta.toLowerCase() }, "info", 4000)
        }

    }, [newTestata, fornitori])

    const templateFiltri = () => {
        return (
            <Row id="testata">
                <TestataOrdineLibero
                    newTestata={newTestata}
                    datiFarmacie={datiFarmacie}
                    fornitori={fornitori}
                    idOrdine={idOrdineLibero}
                    setRicarica={(e: boolean) => { if (e == true) carica() }}
                    flagOrdineB2B={isOrdineB2B}
                />
            </Row>)
    }

    const [inputCerca, setInputCerca] = useState<string>("");
    const [cercaNuovoProd, setCercaNuovoProd] = useState<RicercaProdInterface[]>([]);
    const [isDialogAddProdOpen, setIsDialogAddProdOpen] = useState<boolean>(false);
    const [dialogInfoIsOpen, setDialogInfoIsOpen] = useState<boolean>(false);
    const [dialogFattureScadenzaIsOpen, setDialogFattureScadenzaIsOpen] = useState<boolean>(false);
    const [dialogProdottiSegnalazioneIsOpen, setDialogProdottiSegnalazioneIsOpen] = useState<boolean>(false);
    const [dialogFattureNoteIsOpen, setDialogFattureNoteIsOpen] = useState<boolean>(false);
    const [searchBoxRefInstance, setSearchBoxRefInstance] = useState<any>();
    const [dettagliOrdineLibero, setDettagliOrdineLibero] = useState<any[]>([]);
    const [tabellaInstance, setTabellaInstance] = useState<any>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>();
    const [datiProdotto, setDatiProdotto] = useState<OggettoInterface>();
    const [giacenzeGruppo, setGiacenzeGruppo] = useState<GiacenzaDettInterface[]>([]);
    const [quantitaInOrdine, setQuantitaInOrdine] = useState<OrdineLiberoQuantitaInterface[]>([]);
    const [aggiornaTabDett, setAggiornaTabDett] = useState(1);
    const [colonneQuantita, setColonneQuantita] = useState<string[]>();
    const [colonnaEdit, setColonnaEdit] = useState(1);
    const [colonnaFocus, setColonnaFocus] = useState(1);
    const [fineColonna, setFineColonna] = useState<boolean>(false);
    const [isUltimaRiga, setIsUltimaRiga] = useState<boolean>(false);
    const [quantitaSelezionata, setQuantitaSelezionata] = useState<string>();
    const [codiceCsf, setCodiceCsf] = useState<number | string>();
    const [datiCella, setDatiCella] = useState<any>();
    const [totaleProdotti, setTotaleProdotti] = useState<number>();
    const [isInserimento, setIsInserimento] = useState<boolean>();
    const [quantitaTotaleInOrdine, setQuantitaTotaleInOrdine] = useState<number>();
    const [focusedRow, setFocusedRow] = useState<any>();

    const handleDialogAddProdClose = () => setIsDialogAddProdOpen(false);
    const handleDialogAddProdOpen = () => setIsDialogAddProdOpen(true);
    const handleCloseInfo = () => setDialogInfoIsOpen(false);
    const handleCloseFattureScadenza = () => setDialogFattureScadenzaIsOpen(false);
    const handleCloseFattureNote = () => setDialogFattureNoteIsOpen(false);
    const handleCloseProdottiSegnalazione = () => setDialogProdottiSegnalazioneIsOpen(false);
    const svuotaDialogAddProd = () => setCercaNuovoProd([]);

    useEffect(() => {
        if (searchBoxRefInstance) {
            searchBoxRefInstance.instance.focus();
        }
    }, [searchBoxRefInstance])

    const startRicercaProdInBD = () => {
        if (inputCerca.length > 0 && newTestata && newTestata.lstFar && newTestata.lstFar?.length > 0) {
            setIsVisible(true);
            const parametriCerca = {
                testo: inputCerca,
                lstFar: newTestata.lstFar,
                soloInCommercio: false,
                soloInGiacenza: false
            }
            RestServices().rest('POST', parametriCerca, urlBancaDati.cercaProd)
                .then(result => {
                    setIsVisible(false);
                    if (result.length === 1) {
                        if (result[0].codCsf) {
                            insertProdotto(result[0].codCsf);
                        } else {
                            notify({ position: "center", width: "auto", message: "Oops, si è verificato un problema. Ci scusiamo per il disagio, si prega di riprovare" }, "error", 4000)
                        }
                    } else if (result.length > 1) {
                        setCercaNuovoProd(result);
                        handleDialogAddProdOpen();
                    } else {
                        notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, "info", 4000)
                        setCercaNuovoProd([]);
                    }
                })
        }
    }

    const insertProdotto = (codCsf: number) => {

        if (!codCsf) {
            notify({ position: "center", width: "auto", message: "Oops, si è verificato un errore. Ci scusiamo per il disagio" }, 'error', 2500);
            return;
        }
        setIsVisible(true);
        RestServices().rest('POST', codCsf, urlBancaDati.getProd).then(result => {
            if (result === true) {
                setIsVisible(false);
                notify({ position: "center", width: "auto", message: "La ricerca non ha prodotto alcun risultato" }, 'info', 2500);
            } else {
                if (newTestata && (newTestata.sconto == 0 || (newTestata.sconto || newTestata.flagUltimoCosto == true || newTestata.flagQuantitaOmaggio == true)) && newTestata.lstFar && idOrdineLibero) {
                    let id: number;
                    if (typeof idOrdineLibero === "string") {
                        id = parseInt(idOrdineLibero)
                    } else {
                        id = idOrdineLibero
                    }
                    //let prezzo: number;
                    //prezzo = result.prezzo;
                    //if (newTestata.flagPrezzoFarmacia && newTestata.idFarmaciaPrezzo != undefined) {
                    //    RestServices().rest('POST', codCsf, urlOrdiniLiberi.getPrezzoListinoFarmacia).then(result => {
                    //        if (result)
                    //            prezzo = result;
                    //        else
                    //            prezzo = 0;
                    //    })
                    //}
                    const dati: OggettoInterface = {
                        codCsf: codCsf,
                        prezzo: result.prezzo,
                        iva: result.iva,
                        sconto: newTestata.sconto,
                        idOrdine: id,
                        codProd: result.minsan,
                        ean: result.ean,
                        prodotto: result.descrizione,
                        flagUltimoCosto: newTestata.flagUltimoCosto,
                        flagIncludiProdotti: newTestata.flagIncludiProdotti,
                        flagUltimoSconto: newTestata.flagUltimoSconto,
                        flagVisualizzaPrezzoVendita: newTestata.flagVisualizzaPrezzoFarmacia,
                        codMut: result.codMut,
                        idFornitore: newTestata.idFornitore,
                        flagCostoListino: newTestata.flagCostoListino

                    };
                    RestServices().rest('POST', dati, urlOrdiniLiberi.inserisciProdotto).then(res => {
                        setIsVisible(false);
                        if (res) {
                            if (!res.message) {
                                if (!dati.prezzo && !res.prezzo)
                                    dati.prezzo = 0;
                                else
                                    dati.prezzo = res.prezzo;

                                dati.prezzoVendita = res.prezzoVendita;
                                dati.costo = res.costo;
                                dati.idDettaglio = res.idDettaglio;
                                dati.sconto = res.sconto;
                                dati.giacenzaTotale = res.giacenzaTotale;
                                dati.farQta = {};
                                dati.farQtaOmaggio = {};
                                if (colonneQuantita && colonneQuantita.length > 0) {
                                    colonneQuantita.forEach((col) => {
                                        dati.farQta[col] = 0;
                                        dati.farQtaOmaggio[col] = 0;

                                        //dati.farQtaOmaggio[col] =0;
                                    })
                                    const par = {
                                        lstFar: newTestata.lstFar,
                                        codCsf: codCsf,
                                        idOrdine: id,
                                        idFornitore: newTestata.idFornitore

                                    }
                                    RestServices().rest('POST', par, urlOrdiniLiberi.getUltimiCostoSconto).then(risultato => {
                                        if (risultato && risultato.ULTIMOCOSTO && risultato.ULTIMOSCONTO) {
                                            dati.ultimoCosto = risultato.ULTIMOCOSTO;
                                            dati.ultimoSconto = risultato.ULTIMOSCONTO;
                                        } else {
                                            dati.ultimoCosto = 0;
                                            dati.ultimoSconto = 0;
                                        }
                                        setDatiProdotto(dati);
                                        let nuovoDettaglio: any;
                                        if (newTestata.flagIncludiProdotti == 0 && dettagliOrdineLibero) {
                                            //console.log('dettagli')
                                            //console.log(dettagliOrdineLibero)
                                            nuovoDettaglio = dettagliOrdineLibero.filter(riga => {
                                                return Object.values(riga.farQta).filter(qta => { return qta !== 0 }).length !== 0 ||
                                                    (riga.farQtaOmaggio !== undefined && Object.values(riga.farQtaOmaggio).filter(qta => { return qta !== 0 }).length !== 0)
                                            })
                                            //console.log('nuovo dettaglio')
                                            //console.log(nuovoDettaglio)

                                        } else {
                                            nuovoDettaglio = dettagliOrdineLibero;
                                        }
                                        nuovoDettaglio.unshift(dati);
                                        setDettagliOrdineLibero(nuovoDettaglio);
                                        if (newTestata.flagIncludiProdotti == 0) {
                                            const differenza = dettagliOrdineLibero.filter(dett => !nuovoDettaglio.includes(dett));
                                            const idRigheTolte: number[] = [];
                                            differenza.forEach(diff => idRigheTolte.push(diff.idDettaglio));
                                            const parametriDelete = {
                                                lstFar: newTestata.lstFar,
                                                lstIdDettaglio: idRigheTolte
                                            }
                                            RestServices().rest('POST', parametriDelete, urlOrdiniLiberi.cancellaRighe).then(resDelete => {
                                                if (resDelete && resDelete.message) {
                                                    notify({ position: "center", width: "auto", message: resDelete.message }, "error", 4000)
                                                }
                                            })
                                        }
                                        tabellaInstance.instance.refresh();
                                        tabellaInstance.instance.selectRowsByIndexes([0]);
                                        //setTimeout(() => {
                                        //    tabellaInstance.instance.selectRowsByIndexes([0]);
                                        //}, 1000);

                                    })
                                }

                                //posizionamento su prima colonna quantita dopo inserimento prodotto
                                if (colonneQuantita) {
                                    setIsInserimento(true);
                                    setIsUltimaRiga(false);
                                    setFineColonna(false);
                                }


                                setInputCerca("");
                                setAggiornaTabDett(aggiornaTabDett + 1);
                                setColonnaEdit(1);
                                setCodiceCsf(codCsf);
                                setIsVisible(false);
                                if (openDrawer === true) setOpenDrawer(false);
                            } else {
                                notify({ position: "center", width: "auto", message: "Il prodotto cercato si trova già all'interno dell'ordine" }, "info", 4000)
                                //trovare index del prodotto presente e poi selezionarlo con focus su prima cella editabile.
                                setInputCerca("");
                                let rowIndex = tabellaInstance.instance.getRowIndexByKey(dati.codProd)
                                tabellaInstance.instance.selectRowsByIndexes(rowIndex);
                                posizionaPrimaColonnaRigaCorrente(true);

                            }
                        } else {
                            notify({ position: "center", width: "auto", message: "Si è verificato un problema, si prega di riprovare" }, "error", 4000)
                        }
                    })
                }
            }
        });
    }

    const posizionaPrimaColonnaRigaCorrente = (procedi: boolean) => {
        if ((colonneQuantita != undefined && !ricercaAutomaticaTermineRiga && !isInserimento) || (colonneQuantita && procedi)) {
            //posizionamento su prima colonna riga corrente
            var keys = tabellaInstance.instance.getSelectedRowKeys();
            var currentRowIndex;
            for (var i = 0; i < keys.length; i++) {
                currentRowIndex = tabellaInstance.instance.getRowIndexByKey(keys[i]);
            }
            const colonna = colonneQuantita[0];
            const dataField = "farQta." + colonna;
            const cella = tabellaInstance.instance.getCellElement(currentRowIndex, dataField);
            tabellaInstance.instance.focus(cella);
            tabellaInstance.instance.editCell(currentRowIndex, dataField);
        }

    }

    useEffect(() => {
        var farmacieGruppo: number[] = [];
        if (newTestata.flagVisualizzaVendutoGiacenza) {
            props.farmacieGruppo?.forEach((farm) => {
                //console.log(farm)
                farmacieGruppo.push(farm.ID_FAR);
            })
        }
        else {
            if (newTestata.lstFar)
                farmacieGruppo = newTestata.lstFar;
        }


        const params = {
            lstFar: farmacieGruppo,
            codCsf: codiceCsf
        }

        RestServices().rest('POST', params, urlBancaDati.getGiacenzaDett).then(risultato => {
            if (risultato instanceof Array) {
                let giacenzaTotale: number = 0;
                risultato.forEach((item: GiacenzaDettInterface) => {
                    
                    //if (datiFarmacie && datiFarmacie.length > 0) {
                    //    if (item.idFar != 0) {
                    //        const descrFarm = getDescrFarmFromId(datiFarmacie, item.idFar)
                    //        item.descrFarm = descrFarm;
                    //    }
                    //    //calcolo giacenza totale
                    //    giacenzaTotale = giacenzaTotale + item.giacenzaVendibili

                    //}
                    if (props.farmacieGruppo && props.farmacieGruppo.length > 0) {
                        if (item.idFar != 0) {
                            const descrFarm = getDescrFarmFromId(props.farmacieGruppo, item.idFar)
                            item.descrFarm = descrFarm;
                        }
                        //calcolo giacenza totale
                        giacenzaTotale = giacenzaTotale + item.giacenzaVendibili

                    }
                })

                risultato.push({ idFar: 0, giacenzaVendibili: giacenzaTotale, descrFarm: 'Totale' })

                //console.log(risultato)
                setGiacenzeGruppo(risultato);

                setTimeout(() => {
                    if (isInserimento) {
                        setIsInserimento(false);
                        //posizionamento
                        if (colonneQuantita != undefined) {//&& ricercaAutomaticaTermineRiga) {
                            const colonna = colonneQuantita[0];
                            const dataField = "farQta." + colonna;
                            const cella = tabellaInstance.instance.getCellElement(0, dataField);
                            tabellaInstance.instance.focus(cella);
                            tabellaInstance.instance.editCell(0, dataField);
                        }
                        //else if (colonneQuantita != undefined && !ricercaAutomaticaTermineRiga) {
                        //    posizionaPrimaColonnaRigaCorrente();
                        //}
                    }
                    else if (!ricercaAutomaticaTermineRiga) {
                        posizionaPrimaColonnaRigaCorrente(false);
                    }
                }, 700);

            }
        })

    }, [codiceCsf])

    // const isColVisible = (e:any) =>{
    //     if(e.row.data.avviso){
    //       return true;
    //     }else{
    //         return false;
    //     }     
    //   }


    const creaColonneDinamiche = () => {
        const primeColonne: any[] = [
            { caption: "Descrizione", dataField: "prodotto", allowEditing: false, width: 275 },
            {
                caption: "Prz Ven.(€)", dataField: "prezzoVendita", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: newTestata.flagModificaPrezzoFarmacia == true ? true : false, alignment: "center", width: 65,
                visible: newTestata.flagVisualizzaPrezzoFarmacia == true ? true : false
            },
            { caption: "Prz BD(€)", dataField: "prezzo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: true, alignment: "center", width: 65 },
            { caption: "Sc(%)", dataField: "sconto", format: { currency: "EUR" }, allowEditing: true, alignment: "center", width: 55 },
            { caption: "Costo(€)", dataField: "costo", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, allowEditing: true, alignment: "center", width: 65 },
            //{ caption: "Qta.Tot.", dataField: "qtaTotale", allowEditing: false, alignment: "center", width: 60 },
            { caption: "Giac.Tot.", dataField: "giacenzaTotale", allowEditing: false, alignment: "center", width: 60 },
            {
                caption: "Qta.Tot.Om.", dataField: "qtaTotaleOmaggio", allowEditing: false, alignment: "center", width: 70,
                visible: newTestata.flagQuantitaOmaggio == true ? true : false
            }
            //{ caption: "IVA(%)", dataField: "iva", allowEditing: false, alignment: "center" }
        ];

        const aggiuntaColonneFarm = primeColonne;
        const colonneQta: string[] = [];
        if (newTestata && newTestata.lstFar) {
            for (let i = 0; i < newTestata.lstFar.length; i++) {
                const idFar = newTestata.lstFar[i];
                let codice = getCodiceFarmFromId(datiFarmacie, idFar);

                colonneQta.push(`${idFar}`);
                aggiuntaColonneFarm.push({
                    caption: `Q.${codice}`,
                    dataField: `farQta.${idFar}`,
                    allowEditing: true,
                    dataType: "number",
                    alignment: "center",
                    width: 60
                })
                if (newTestata.flagQuantitaOmaggio) {
                    colonneQta.push(`${idFar}`);
                    aggiuntaColonneFarm.push({
                        caption: `Q_O.${codice}`,
                        dataField: `farQtaOmaggio.${idFar}`,
                        allowEditing: true,
                        dataType: "number",
                        alignment: "center",
                        width: 70
                    })
                }

            }
            // per far funxionare enter by enter ho messo in array colonneQta anche gli sconti! da testare se dà problemi
            setColonneQuantita(colonneQta);
        }
        aggiuntaColonneFarm.push({ caption: "Qta.Tot.", dataField: "qtaTotale", allowEditing: false, alignment: "center", width: 60 });
        aggiuntaColonneFarm.push({ caption: "IVA(%)", dataField: "iva", allowEditing: false, alignment: "center", format: { currency: "EUR" }, width: 60 });
        aggiuntaColonneFarm.push({ caption: "U.Sconto", dataField: "ultimoSconto", allowEditing: false, alignment: "center", format: { currency: "EUR" }, width: 60 });
        aggiuntaColonneFarm.push({ caption: "U.Costo", dataField: "ultimoCosto", allowEditing: false, alignment: "center", format: { currency: "EUR", precision: 2, type: "fixedPoint" }, width: 60 });
        aggiuntaColonneFarm.push({ caption: "Cod.Prod.", dataField: "codProd", allowEditing: false, alignment: "center" });
        aggiuntaColonneFarm.push({ caption: "EAN", dataField: "ean", allowEditing: false, alignment: "center" });
        aggiuntaColonneFarm.push({
            caption: " ", type: "buttons", width: 50, fixed: false, buttons: [{
                hint: "Azzera le quantità del prodotto per tutte le farmacie",
                icon: "minus",
                onClick: (e: any) => {

                    const idRiga = e.row.data.idDettaglio;
                    const param = {
                        idDettaglio: idRiga,
                        farQta: e.row.data.farQta
                    }
                    //console.log(param)
                    RestServices().rest('POST', param, urlOrdiniLiberi.azzeraQuantita).then(result => {
                        if (result === true) {
                            const dati = [...dettagliOrdineLibero];
                            dati.forEach((riga) => {
                                if (riga.idDettaglio === idRiga) {
                                    for (const far in riga.farQta) {
                                        riga.farQta[far] = 0
                                    }
                                }
                            })
                            setDettagliOrdineLibero(dati);
                            setAggiornaTabDett(aggiornaTabDett + 1);
                            tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
                        } else {
                            notify({ position: "center", width: "auto", message: "Non è stato possibile azzerare le quantità. Si prega di riprovare" }, "error", 4000)
                            return;
                        }
                    })
                }
            }]
        })
        return aggiuntaColonneFarm;
    }
    const columnsDettaglioOrdLib = useMemo(() => CreaColonne(creaColonneDinamiche()), [dettagliOrdineLibero, newTestata.lstFar])

    const propsTabDettagliOrdineLibero: TabellaCompletaInterface = useMemo(() => ({
        id: "dettaglio_ordine_libero_" + idOrdineLibero,
        dataSource: dettagliOrdineLibero,
        selection: "single",
        autoWidth: true,
        headerFilterVisible: true,
        defaultFilterValue: null,
        enablePagination: false,
        height: "100%",
        summary: {
            totalItems: [{
                name: "TotaleProdotti",
                displayFormat: "Prodotti: {0}",
                summaryType: "count"
            }]
        },
        export: {
            enabled: false,
            fileName: "Ordine_Libero"
        },
        editing: {
            mode: "cell",
            allowUpdating: true,
            selectTextOnEditStart: true,
        }
    }), [dettagliOrdineLibero, idOrdineLibero, aggiornaTabDett])

    const onCellPrepared = useCallback((e: any) => {
        if (e.rowType === "header") {
            let tooltip = "";
            switch (e.column.dataField) {
                case "ultimoCosto":
                    tooltip = "Ultimo Costo cronologico ditta"
                    break;
                case "ultimoSconto":
                    tooltip = "Ultimo Sconto"
                    break;
            }
            e.cellElement.title = tooltip;
        }
        if (e.rowType === "data" && e.column && e.column.caption) {
            if (e.column.caption.startsWith("Q.")) {
                if (e.columnIndex % 2 === 0) {
                    e.cellElement.style.backgroundColor = "paleturquoise";
                    e.cellElement.style.fontSize = "15px";
                } else {
                    e.cellElement.style.backgroundColor = "LightGoldenrodYellow";
                    e.cellElement.style.fontSize = "15px";
                }
            } else if (e.column.caption.startsWith("Q_O.")) {
                if (e.columnIndex % 2 === 0) {
                    e.cellElement.style.backgroundColor = "paleturquoise";
                    e.cellElement.style.fontSize = "15px";
                } else {
                    e.cellElement.style.backgroundColor = "LightGoldenrodYellow";
                    e.cellElement.style.fontSize = "15px";
                }
            } else if (e.column.caption === "Qta.Tot.") {
                e.cellElement.style.fontSize = "15px";
            }
            else if (e.column.caption === "Giac.Tot.") {
                e.cellElement.style.fontSize = "15px";
            }
            else if (e.column.caption === "Qta.Tot.Om.") {
                e.cellElement.style.fontSize = "15px";
            } else if (e.column.caption === "U.Costo") {
                if (e.row.data.ultimoCosto < e.row.data.costo) { e.cellElement.style.backgroundColor = "rgb(250, 128, 114)"; }
            } else if (e.column.caption === "U.Sconto") {
                if (e.row.data.ultimoSconto < e.row.data.sconto) e.cellElement.style.backgroundColor = "rgb(250, 128, 114)";
            } else if (e.column.caption.startsWith("Prz") || e.column.caption.startsWith("Sc(%)") || e.column.caption.startsWith("Costo(€)") || e.column.caption.startsWith("IVA(%)")) {
                e.cellElement.style.fontSize = "15px";
            }
        }
    }, [])

    const onContentReady = useCallback((e: any) => {
        const numeroProdotti = e.component.getTotalSummaryValue("TotaleProdotti");
        if (numeroProdotti !== 0) setTotaleProdotti(numeroProdotti);
        if (aggiornaTabDett > 1 && colonneQuantita && colonnaEdit <= colonneQuantita.length) {
            searchBoxRefInstance.instance.blur();
            if (datiCella) {
                tabellaInstance.instance.selectRowsByIndexes([datiCella.rowIndex]);
                tabellaInstance.instance.editCell(datiCella.rowIndex, datiCella.dataField);
            } else {
                const colonna = colonneQuantita[colonnaEdit - 1];
                const dataField = "farQta." + colonna;
                const cella = tabellaInstance.instance.getCellElement(0, dataField);
                tabellaInstance.instance.focus(cella);
                tabellaInstance.instance.editCell(0, dataField);
            }
        }
    }, [aggiornaTabDett, colonneQuantita, colonnaEdit, datiCella])

    const onFocusedCellChanged = useCallback((e: any) => {
        setColonnaFocus(e.columnIndex);
        if (e.column && e.column.dataField && e.row && e.row.data &&
            (e.column.dataField === "sconto" || e.column.dataField === "costo" || e.column.dataField.startsWith("farQta") ||
                e.column.dataField === "prezzo" || (newTestata.flagModificaPrezzoFarmacia == true && e.column.dataField === "prezzoVendita"))) {
            if ((datiProdotto && e.row.data.codCsf !== datiProdotto.codCsf) || !datiProdotto) {
                if (codiceCsf !== e.row.data.codCsf) setCodiceCsf(e.row.data.codCsf);
                setDatiProdotto(e.row.data)
                if (e.column.dataField.startsWith("farQta")) {
                    const farmSelez = e.column.dataField.split("farQta.")[1];
                    setQuantitaSelezionata(farmSelez);
                } else {
                    setQuantitaSelezionata(" ");
                }
            }
            tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
            tabellaInstance.instance.editCell(e.rowIndex, e.column.dataField);
            //console.log("qui4:" + e.column.dataField + " rowIndex:" + e.rowIndex);
            setDatiCella({
                rowIndex: e.rowIndex,
                dataField: e.column.dataField
            })
        }
    }, [tabellaInstance])

    const onCellClick = (e: any) => {
        if (e.rowType === "data") {
            if (codiceCsf !== e.row.data.codCsf) setCodiceCsf(e.row.data.codCsf);
            setDatiProdotto(e.row.data)
            if (tabellaInstance) {
                const datiCella = tabellaInstance.instance.getCellElement(e.rowIndex, e.column.dataField);
                tabellaInstance.instance.selectRowsByIndexes([e.rowIndex]);
                tabellaInstance.instance.focus(datiCella);
                tabellaInstance.instance.editCell(e.rowIndex, e.dataField);
            }
        }
    }

    const onRowUpdating = (e: any) => {
            //console.log(e.newData)
        if (colonneQuantita && colonnaEdit <= colonneQuantita.length) setColonnaEdit(colonnaEdit + 1);
        if (e.newData.sconto < 0) {
            e.cancel = true;
            notify({ position: "center", width: "auto", message: "Non è consentito inserire valori negativi" }, "error", 4000)
            return;
        }
        if (e.newData.sconto === 0 || e.newData.sconto > 0) {
            if (e.newData.sconto > 100) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "Il valore dello sconto non può essere superiore a 100" }, "error", 4000)
                return;
            }
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                sconto: e.newData.sconto,
                prezzo: e.oldData.prezzo,
                iva: e.oldData.iva,
                costo: e.oldData.costo
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updateSconto).then(result => {
                if (typeof result === "number") {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        if (riga.idDettaglio === e.oldData.idDettaglio) {
                            riga.costo = result;
                        }
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }
        if (e.newData.sconto === null) {
            e.newData.sconto = 0;
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                sconto: 0,
                prezzo: e.oldData.prezzo,
                iva: e.oldData.iva,
                costo: e.oldData.costo
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updateSconto).then(result => {
                if (typeof result === "number") {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        if (riga.idDettaglio === e.oldData.idDettaglio) {
                            riga.costo = result;
                        }
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }

        if (e.newData.costo < 0) {
            e.cancel = true;
            notify({ position: "center", width: "auto", message: "Non è consentito inserire valori negativi" }, "error", 4000)
            return;
        }
        if (e.newData.costo === 0 || e.newData.costo > 0) {
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                costo: e.newData.costo,
                prezzo: e.oldData.prezzo,
                iva: e.oldData.iva,
                sconto: e.oldData.sconto
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updateCosto).then(result => {
                if (typeof result === "number") {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        if (riga.idDettaglio === e.oldData.idDettaglio) {
                            riga.sconto = result;
                        }
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }
        if (e.newData.costo === null) {
            e.newData.costo = 0;
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                costo: 0,
                prezzo: e.oldData.prezzo,
                iva: e.oldData.iva,
                sconto: e.oldData.sconto
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updateCosto).then(result => {
                if (typeof result === "number") {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        if (riga.idDettaglio === e.oldData.idDettaglio) {
                            riga.sconto = result;
                        }
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }

        //update prezzo
        if (e.newData.prezzo === 0 || e.newData.prezzo > 0) {
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                sconto: e.oldData.sconto,
                prezzo: e.newData.prezzo,
                iva: e.oldData.iva,
                costo: e.oldData.costo
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updatePrezzo).then(result => {
                if (typeof result === "object" && result.COSTO != undefined && result.SCONTO != undefined) {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        if (riga.idDettaglio === e.oldData.idDettaglio) {
                            riga.costo = result.COSTO;
                            riga.sconto = result.SCONTO;
                        }
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }
        if (e.newData.prezzo === null) {
            e.newData.prezzo = 0;
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                prezzo: 0,
                sconto: e.oldData.sconto,
                iva: e.oldData.iva,
                costo: e.oldData.costo
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updatePrezzo).then(result => {
                //console.log(result);
                if (typeof result === "object" && result.COSTO != undefined && result.SCONTO != undefined) {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        if (riga.idDettaglio === e.oldData.idDettaglio) {
                            riga.costo = result.COSTO;
                            riga.sconto = result.SCONTO;
                        }
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }

        //update prezzo vendita
        if (e.newData.prezzoVendita === 0 || e.newData.prezzoVendita > 0) {
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                prezzoVendita: e.newData.prezzoVendita,
                
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updatePrezzoVendita).then(result => {
                if (typeof result === "object" && result.PREZZO_VENDITA_NEW != undefined ) {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        //if (riga.idDettaglio === e.oldData.idDettaglio) {
                        //    riga.costo = result.COSTO;
                        //    riga.sconto = result.SCONTO;
                        //}
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }
        if (e.newData.prezzoVendita === null) {
            e.newData.prezzoVendita = 0;
            const param = {
                idDettaglio: e.oldData.idDettaglio,
                codCsf: e.oldData.codCsf,
                prezzoVendita: e.newData.prezzoVendita
            }
            RestServices().rest('POST', param, urlOrdiniLiberi.updatePrezzoVendita).then(result => {
                //console.log(result);
                if (typeof result === "object" && result.PREZZO_VENDITA_NEW != undefined ) {
                    const copiaDati = dettagliOrdineLibero;
                    copiaDati.forEach(riga => {
                        //if (riga.idDettaglio === e.oldData.idDettaglio) {
                        //    riga.costo = result.COSTO;
                        //    riga.sconto = result.SCONTO;
                        //}
                    })
                    setDettagliOrdineLibero(copiaDati);
                    setAggiornaTabDett(aggiornaTabDett + 1);
                } else {
                    e.cancel = true;
                    notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento del costo, si prega di riprovare" }, "error", 4000)
                }
            })
        }

        if (e.newData.farQta) {
            const qta = e.newData.farQta;
            const farmacia = Object.keys(qta)[0];
            const farm = parseInt(farmacia);
            if (qta[farm] < 0) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "Non è consentito inserire valori negativi" }, "error", 4000)
                return;
            }
            if (qta[farm] === 0 || qta[farm] > 0) {
                //const param = {
                //    idDettaglio: e.key.idDettaglio,
                //    farQta: e.newData.farQta
                //}

                var totale = 0;
                var value;
                Object.keys(e.oldData.farQta).forEach(function (key) {
                    if (key.toString() != farm.toString()) {
                        value = e.oldData.farQta[key];
                        totale = totale + value;
                    }
                });
                totale = totale + qta[farm];


                /* vecchia versione
                const param = {
                    idDettaglio: e.oldData.idDettaglio,
                    farQta: e.newData.farQta
                }
                */

               /* nupva versione */
                const param = {
                    idDettaglio: e.oldData.idDettaglio,
                    farQta: e.newData.farQta,
                    idFornitore: newTestata.idFornitore,
                    flagCostoListino: newTestata.flagCostoListino,
                    codCsf: e.oldData.codCsf,
                    idOrdine: idOrdineLibero,
                    prezzo: e.oldData.prezzo,
                    iva: e.oldData.iva,
                    costo: e.oldData.costo,
                    sconto: e.oldData.sconto,
                    lstFar: newTestata.lstFar,
                    qtaTotale: totale,
                    flagOrdineB2B: props.flagOrdineB2B
                }

                
                //1
                //const copiaDati = dettagliOrdineLibero;
                //const riga = copiaDati.find(x => x.idDettaglio === e.key.idDettaglio)
                //riga.qtaTotale = totale;
                //setDettagliOrdineLibero(copiaDati);
                //setAggiornaTabDett(aggiornaTabDett + 1);

                //2
                //var keys = tabellaInstance.instance.getSelectedRowKeys();
                //var currentRowIndex;
                //for (var i = 0; i < keys.length; i++) {
                //    currentRowIndex = tabellaInstance.instance.getRowIndexByKey(keys[i]);
                //}
                //console.log(currentRowIndex);
                //tabellaInstance.instance.cellValue(currentRowIndex, "qtaTotale", totale);

                RestServices().rest('POST', param, urlOrdiniLiberi.cambiaQuantitaV2).then(result => {
                    if (result == undefined) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento della quantità, si prega di riprovare" }, "error", 4000)
                    }
                    else {
                        tabellaInstance.instance.cellValue(rowIndex, "qtaTotale", totale);
                        /* nuovo versione */
                        if (newTestata.flagCostoListino || newTestata.flagOrdineB2B) {
                            let costo = result.COSTO
                            let sconto = result.SCONTO
                            tabellaInstance.instance.cellValue(rowIndex, "costo", costo);
                            tabellaInstance.instance.cellValue(rowIndex, "sconto", sconto);

                        }
                        e.component.saveEditData();
                        e.component.editCell(rowIndex, colonnaFocus);
                        //console.log("qui5:" + colonnaFocus + " rowIndex:" + rowIndex);

                        //setAggiornaTabDett(aggiornaTabDett + 1);
                        //scrittura quantita totale tornata dal servizio
                        //const copiaDati = dettagliOrdineLibero;
                        //const riga = copiaDati.find(x => x.idDettaglio === e.key.idDettaglio)
                        //riga.qtaTotale = result;
                        //setDettagliOrdineLibero(copiaDati);
                        //setAggiornaTabDett(aggiornaTabDett + 1);
                        //se non ci sono righe sotto ed ho aggiornato quantita mi sposto su ricerca prodotto
                        if (isUltimaRiga) {
                            setIsUltimaRiga(false);
                            focusRicercaProdotto(100, true);
                        }
                    }
                })
            }
            if (qta[farm] === null) {
                qta[farm] = 0;
                //const param: oggettointerface = {
                //    iddettaglio: e.key.iddettaglio,
                //    farqta: {}
                //}

                const param: OggettoInterface = {
                    idDettaglio: e.oldData.idDettaglio,
                    farQta: e.newData.farQta,
                    flagOrdineB2B: props.flagOrdineB2B
                }

                param.farQta[farm] = 0
                RestServices().rest('POST', param, urlOrdiniLiberi.cambiaQuantitaV2).then(result => {
                    if (result !== true) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento della quantità, si prega di riprovare" }, "error", 4000)
                    }
                    else {
                        //se non ci sono righe sotto ed ho aggiornato quantita mi sposto su ricerca prodotto
                        if (isUltimaRiga) {
                            setIsUltimaRiga(false);
                            focusRicercaProdotto(100, true);
                        }
                    }
                })
            }
        }

        if (e.newData.farQtaOmaggio) {
            const qta = e.newData.farQtaOmaggio;
            //console.log('totaleee qqqq');
            //console.log(qta);

            const farmacia = Object.keys(qta)[0];
           // console.log('farmacia');
            //console.log(farmacia);


            const farm = parseInt(farmacia);
            if (qta[farm] < 0) {
                e.cancel = true;
                notify({ position: "center", width: "auto", message: "Non è consentito inserire valori negativi" }, "error", 4000)
                return;
            }
            if (qta[farm] === 0 || qta[farm] > 0) {
                const param = {
                    idDettaglio: e.oldData.idDettaglio,
                    farQta: e.newData.farQtaOmaggio
                }
                var totale = 0;
                var value;
                Object.keys(e.oldData.farQtaOmaggio).forEach(function (key) {
                    if (key.toString() != farm.toString()) {
                        value = e.oldData.farQtaOmaggio[key];
                       // console.log('vediamo  il valore');
                       // console.log(value);


                        totale = totale + value;
                    }
                });

                totale = totale + qta[farm];

                //console.log(param);

                RestServices().rest('POST', param, urlOrdiniLiberi.updateQtaOmag).then(result => {
                    if (result !== true) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento della quantità, si prega di riprovare" }, "error", 4000)
                    }
                    else {


                        //console.log('sono quiiii');


                        tabellaInstance.instance.cellValue(rowIndex, "qtaTotaleOmaggio", totale);

                        e.component.saveEditData();
                        e.component.editCell(rowIndex, colonnaFocus);

                        //console.log('isUltimaRiga');
                        //console.log(isUltimaRiga);




                        if (isUltimaRiga) {
                            setIsUltimaRiga(false);
                            focusRicercaProdotto(100, true);
                        }
                    }
                })
            }
            if (qta[farm] === null) {
                qta[farm] = 0;
                const param: OggettoInterface = {
                    idDettaglio: e.oldData.idDettaglio,
                    farQta: {},
                    flagOrdineB2B: props.flagOrdineB2B
                }
                param.farQta[farm] = 0
                RestServices().rest('POST', param, urlOrdiniLiberi.cambiaQuantitaV2).then(result => {
                    if (result !== true) {
                        e.cancel = true;
                        notify({ position: "center", width: "auto", message: "Si è verificato un errore nell'aggiornamento della quantità, si prega di riprovare" }, "error", 4000)
                    }
                    else {
                        //se non ci sono righe sotto ed ho aggiornato quantita mi sposto su ricerca prodotto
                        if (isUltimaRiga) {
                            setIsUltimaRiga(false);
                            focusRicercaProdotto(100, true);
                        }
                    }
                })
            }
        }
    }

    const esportaGrid = () => {
        tabellaInstance.instance.exportToExcel(false);
    }



    const focusRicercaProdotto = (timeout: number, consideraRicercaAutomaticaTermineRiga: boolean) => {
        //console.log("focusRicercaProdotto");
        if (consideraRicercaAutomaticaTermineRiga) {
            if (searchBoxRefInstance && ricercaAutomaticaTermineRiga) {
                setTimeout(() => {
                    searchBoxRefInstance.instance.focus();
                }, timeout);
            }
        } else {
            if (searchBoxRefInstance) {
                setTimeout(() => {
                    searchBoxRefInstance.instance.focus();
                }, timeout);
            }
        }

    }

    const onEditingStart = (e: any) => {
        //se ho dato l'ultimo invio nella cella della quantita dell'ultima farmacia allora mi posiziono su ricerca
        //questa verifica vale quando do invio sull'ultima quantita ed automaticamente il focus va sulla prima cella della riga sotto; questo è dovuto alla griglia e non può essere cambiato
        //questo evento quindi verifica quando mi trovo nella prima cella della riga sotto quello che ho appena terminato di modificare
        setRowIndex(e.component.getRowIndexByKey(e.key));
        //console.log("qui6 rowindex:" + e.component.getRowIndexByKey(e.key) + "fine colonna:" + fineColonna);
        if (fineColonna) {
            setFineColonna(false);
            focusRicercaProdotto(500, true);

        }
    }

    const onKeyDown = (e: any) => {
        if (e.event.key === "F2") {
            //if (ricercaAutomaticaTermineRiga === true) {
            e.event.preventDefault();
            focusRicercaProdotto(100, false);
            //}else {
            //    //get curren row index
            //    var keys = tabellaInstance.instance.getSelectedRowKeys();
            //    var currentRowIndex;
            //    for (var i = 0; i < keys.length; i++) {
            //        currentRowIndex = tabellaInstance.instance.getRowIndexByKey(keys[i]);
            //    }
            //    tabellaInstance.instance.selectRowsByIndexes(currentRowIndex + 1);
            //    posizionaPrimaColonnaRigaCorrente();
            //}

        }


        if (e.event.key === "F4") {
            //get curren row index
            var keys = tabellaInstance.instance.getSelectedRowKeys();
            var currentRowIndex;
            for (var i = 0; i < keys.length; i++) {
                currentRowIndex = tabellaInstance.instance.getRowIndexByKey(keys[i]);
            }
            tabellaInstance.instance.selectRowsByIndexes(currentRowIndex + 1);
            posizionaPrimaColonnaRigaCorrente(true);
        }

        if (e.event.key === "F9") {
            //e.event.preventDefault();
            //setRicercaAutomaticaTermineRiga(!ricercaAutomaticaTermineRiga);
        }
        if (e.event.key === "Enter") {

            //verifico se mi trovo sull'ultima colonna quantita dell'ultima farmacia
            let nColO = 5
            let nColNO = 4

            if (newTestata.flagVisualizzaPrezzoFarmacia) {
                nColO = nColO + 1
                nColNO = nColNO + 1 
            }

            if (colonneQuantita && (colonnaFocus == ((newTestata.flagQuantitaOmaggio ? nColO : nColNO) + colonneQuantita?.length))) {

                var keys = tabellaInstance.instance.getSelectedRowKeys();
                var currentRowIndex;
                for (var i = 0; i < keys.length; i++) {
                    currentRowIndex = tabellaInstance.instance.getRowIndexByKey(keys[i]);
                }

                
                //verifico se ci sono altre righe al di sotto della riga che sto lavorando
                if (currentRowIndex == (tabellaInstance.instance.totalCount() - 1)) {

                    setIsUltimaRiga(true);
                    focusRicercaProdotto(100, true);

                }
                else
                    setFineColonna(true);
            }
        }

    }

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            if (event.key === "F2") {
                // if (ricercaAutomaticaTermineRiga === true) {
                event.preventDefault();
                focusRicercaProdotto(100, false);
                //}
                //else {
                //    //get curren row index
                //    var keys = tabellaInstance.instance.getSelectedRowKeys();
                //    var currentRowIndex;
                //    for (var i = 0; i < keys.length; i++) {
                //        currentRowIndex = tabellaInstance.instance.getRowIndexByKey(keys[i]);
                //    }
                //    tabellaInstance.instance.selectRowsByIndexes(currentRowIndex + 1 );
                //    posizionaPrimaColonnaRigaCorrente();
                //}
            }

            if (event.key === "F9") {
                //setRicercaAutomaticaTermineRiga(!ricercaAutomaticaTermineRiga);
                if (ricercaAutomaticaTermineRiga == true) {
                    setRicercaAutomaticaTermineRiga(false);
                    notify({ position: "center", width: "auto", message: "Disattivato spostamento automatico su ricerca prodotto al termine di riga" }, "info", 3000)
                }

                else {
                    setRicercaAutomaticaTermineRiga(true);
                    notify({ position: "center", width: "auto", message: "Attivato spostamento automatico su ricerca prodotto al termine di riga" }, "info", 3000)
                }
            }
        };
        document.addEventListener("keydown", onKeyDown);
        return () => {
            document.removeEventListener("keydown", onKeyDown);
        };
    })

    const [sizes, setSizes] = useState(['100%', 500]);

    const layoutCSS = {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    return (
        <>
            {loadPanel}
            {auth &&
                <>
                    <div className="contenutoPagina" id="ordiniLiberi">
                        {isDialogNewOpen ?

                            <DialogNuovoOrdineLibero
                            datiFarmacie={datiFarmacie}
                            isDialogNewOpen={isDialogNewOpen}
                            handleCloseDialogNew={handleCloseDialogNew}
                            setNewTestata={setNewTestata}
                            setIsVisible={setIsVisible}
                            setIdOrdineLibero={setIdOrdineLibero}
                            fornitori={fornitori}
                            settori={settori}
                            gruppi={gruppi}
                            flagOrdineB2B={isOrdineB2B}
                            />
                            :
                            <>
                                <DrawerFiltri
                                id="testataOrdineLibero"
                                templateFiltri={templateFiltri}
                                testoDrawer={titoloDrawer}
                                open={openDrawer}
                                setOpen={setOpenDrawer}
                                avvisoFattureScadenza={newTestata.avvisoFattureScadenza}
                                openPopUpFattureScadenza={dialogFattureScadenzaIsOpen}
                                setOpenPopupFattureScadenza={setDialogFattureScadenzaIsOpen}
                                avvisoProdottiSegnalazione={newTestata.avvisoProdottiSegnalazione}
                                openPopUpProdottiSegnalazione={dialogProdottiSegnalazioneIsOpen}
                                setOpenPopupProdottiSegnalazione={setDialogProdottiSegnalazioneIsOpen}
                                avvisoFattureNote={newTestata.avvisoFattureNote}
                                openPopUpFattureNote={dialogFattureNoteIsOpen}
                                setOpenPopupFattureNote={setDialogFattureNoteIsOpen}
                                />

                                <Row id="rigaRicercaOrdLib">
                                    <Col sm='3' className="ordineLibero">
                                        <span>Ricerca prodotto (codice, EAN, descrizione)</span>
                                    </Col>
                                    <Col sm='4' className="boxCerca">
                                        <BoxTestoCerca
                                            placeholder=""
                                            id="ricercaProdottiBD"
                                            setInputCerca={setInputCerca}
                                            startRicerca={startRicercaProdInBD}
                                            inputCerca={inputCerca}
                                            setSearchBoxRefInstance={setSearchBoxRefInstance}
                                        />
                                    </Col>
                                    <Col sm="1">
                                        <FontAwesomeIcon icon={faQuestionCircle} className="fa fa-info-circle fa-lg" title="Premi F2 per tornare alla barra di ricerca &#013;Premi F9 per cambiare modalità operativa &#013;Premi F4 per andare alla riga successiva" />
                                    </Col>
                                    <Col sm='4' id="rigaBtnOrdLib">
                                        <Button variant="info" onClick={() => { setDialogInfoIsOpen(true) }} id="informazioni">Informazioni</Button>
                                        {totaleProdotti && <Button variant="success" id="totaleProdotti">Prodotti: {totaleProdotti}</Button>}
                                        <Button variant="primary" onClick={esportaGrid} id="esportaGrid">Esporta Excel</Button>
                                    </Col>
                                </Row>
                            {Boolean(newTestata.flagVisualizzaAltreInfo) === false &&
                              <Row id="rigaTabDettOrdLib">
                                <Col sm='12' className="ordineLibero">
                                    {aggiornaTabDett &&
                                        <TabellaOrdiniLiberi
                                            propsTabella={propsTabDettagliOrdineLibero}
                                            onRowUpdating={onRowUpdating}
                                            setTabellaInstance={setTabellaInstance}
                                            columns={columnsDettaglioOrdLib}
                                            enterKeyDirection="row"
                                            onCellPrepared={onCellPrepared}
                                            onContentReady={onContentReady}
                                            onCellClick={onCellClick}
                                            onFocusedCellChanged={onFocusedCellChanged}
                                            onKeyDown={onKeyDown}
                                            selectedRowKeys={selectedRowKeys}
                                            onEditingStart={onEditingStart}
                                            keyExpr={'codProd'}
                                        //onRowPrepared={onRowPrepared}
                                        />
                                    }
                                </Col>
                            </Row>
                             }

                            {Boolean(newTestata.flagVisualizzaAltreInfo) === true && 
                                
                            <div style={{ height: 'calc(-147px + 90vh)' }}>
                                <SplitPane
                                    sizes={sizes}
                                    onChange={setSizes}
                                    resizerSize={10}
                                    sashRender={(index, active) => (
                                        <SashContent className="action-sash-wrap">
                                            <div>
                                            </div>
                                            <span
                                                className="action"
                                                onClick={() => {
                                                    setSizes(sizes[1] > 0 ? ['100%', 0] : ['100%', 500]);
                                                }}
                                            >
                                                {sizes[1] === 0 ? <button className="btn"><i className="fa fa-arrow-left"></i></button> : <button className="btn"><i className="fa fa-arrow-right"></i></button>}
                                                
                                            </span>
                                        </SashContent>
                                    )}
                                >
                                    <Pane>
                                        <div style={{ backgroundColor: "#fff" }}>
                                            <Row id="rigaTabDettOrdLib">
                                                <Col sm='12' className="ordineLibero">
                                                    {aggiornaTabDett &&
                                                        <TabellaOrdiniLiberi
                                                            propsTabella={propsTabDettagliOrdineLibero}
                                                            onRowUpdating={onRowUpdating}
                                                            setTabellaInstance={setTabellaInstance}
                                                            columns={columnsDettaglioOrdLib}
                                                            enterKeyDirection="row"
                                                            onCellPrepared={onCellPrepared}
                                                            onContentReady={onContentReady}
                                                            onCellClick={onCellClick}
                                                            onFocusedCellChanged={onFocusedCellChanged}
                                                            onKeyDown={onKeyDown}
                                                            selectedRowKeys={selectedRowKeys}
                                                            onEditingStart={onEditingStart}
                                                            keyExpr={'codProd'}
                                                        //onRowPrepared={onRowPrepared}
                                                        />
                                                    }
                                                </Col>
                                            </Row>

                                        </div>
                                        </Pane>
                                        <ScrollView height="100%" width="100%" >
                                        <Pane>
                                            
                                        {(newTestata.lstFar && datiProdotto) ?
                                            (
                                                <Row id="rigaTabAltreInfo">
                                                    <CreaPannelloInfo
                                                                listaFarmacie={newTestata.lstFar}
                                                                datiFarmacie={props.farmacieGruppo}
                                                                giacenzeGruppo={giacenzeGruppo}
                                                                datiProdotto={datiProdotto}
                                                                setIsLoadVisible={setIsLoadVisibleAltreInfo}
                                                                quantitaSelezionata={quantitaSelezionata}
                                                                flagVisualizzaVendutoGiacenza={newTestata.flagVisualizzaVendutoGiacenza != null && newTestata.flagVisualizzaVendutoGiacenza ? newTestata.flagVisualizzaVendutoGiacenza : false}
                                                                flagVisualizzaVendutoGiacenzaTotale={newTestata.flagVisualizzaVendutoGiacenzaTotale != null ? newTestata.flagVisualizzaVendutoGiacenzaTotale : false}
                                                                flagVisualizzaUltimiAcquisti={newTestata.flagVisualizzaUltimiAcquisti != null ? newTestata.flagVisualizzaUltimiAcquisti : false}
                                                                flagVisualizzaListiniOfferte={newTestata.flagVisualizzaListiniOfferte != null ? newTestata.flagVisualizzaListiniOfferte : false}
                                                                flagVisualizzaListiniOfferteIdentici={newTestata.flagVisualizzaListiniOfferteIdentici != null ? newTestata.flagVisualizzaListiniOfferteIdentici : false}
                                                                flagVisualizzaTrasferimenti={newTestata.flagVisualizzaTrasferimenti != null ? newTestata.flagVisualizzaTrasferimenti : false}
                                                                flagVisualizzaIdentici={newTestata.flagVisualizzaIdentici != null ? newTestata.flagVisualizzaIdentici : false}
                                                                flagVisualizzaSimili={newTestata.flagVisualizzaSimili != null ? newTestata.flagVisualizzaSimili : false}
                                                                idOrdine={idOrdineLibero}
                                                                flagVisualizzaListiniOrdineLibero={newTestata.flagVisualizzaListiniOrdineLibero != null ? newTestata.flagVisualizzaListiniOrdineLibero : false}
                                                                idFornitore={newTestata.idFornitore}
                                                                flagOrdineB2B={props.flagOrdineB2B}
                                                    />
                                                    {loadPanelAltreInfo}
                                                </Row>
                                            )
                                            :
                                            (<></>
                                            )
                                        }
                                        </Pane>
                                    </ScrollView>
                                </SplitPane>
                                    </div>
                                
                            }


                                {(newTestata.lstFar && datiProdotto) ?
                                    (
                                    <Row id="rigaTabGiacenze">
                                            <CreaTabelleGiacenze
                                                listaFarmacie={newTestata.lstFar}
                                                datiFarmacie={props.farmacieGruppo}
                                                giacenzeGruppo={giacenzeGruppo}
                                                datiProdotto={datiProdotto}
                                                setIsLoadVisible={setIsLoadVisible}
                                                quantitaSelezionata={quantitaSelezionata}
                                                flagVisualizzaVendutoGiacenza={newTestata.flagVisualizzaVendutoGiacenza != null && newTestata.flagVisualizzaVendutoGiacenza ? newTestata.flagVisualizzaVendutoGiacenza : false}
                                                flagVisualizzaVendutoGiacenzaTotale={newTestata.flagVisualizzaVendutoGiacenzaTotale != null ? newTestata.flagVisualizzaVendutoGiacenzaTotale : false}
                                                idOrdine={idOrdineLibero}
                                            />
                                            {loadPanelGiacenze}
                                        </Row>
                                    )
                                    :
                                    (<></>
                                    )
                                }
                            </>
                        }
                    </div>
                    {(cercaNuovoProd && inputCerca.length > 0 && newTestata.lstFar && newTestata.lstFar.length > 0) &&
                        <DialogAggiungiProdOrdine
                            isOpen={isDialogAddProdOpen}
                            handleClose={handleDialogAddProdClose}
                            svuotaDialog={svuotaDialogAddProd}
                            cercaNuovoProd={cercaNuovoProd}
                            testoRicerca={inputCerca}
                            farmSelez={newTestata.lstFar}
                            insertProdotto={insertProdotto}
                        />}

                    <DialogInfo
                        dialogInfoIsOpen={dialogInfoIsOpen}
                        idOrdine={idOrdineLibero}
                        titoloOrdine={newTestata.titolo}
                        handleCloseInfo={handleCloseInfo}
                />
                <DialogFattureScadenza
                    dialogFattureScadenzaIsOpen={dialogFattureScadenzaIsOpen}
                    idFornitore={newTestata.idFornitore}
                    handleCloseFattureScadenza={handleCloseFattureScadenza}
                    fornitore={ descrizioneFornitore}
                />
                <DialogFattureNote
                    dialogFattureNoteIsOpen={dialogFattureNoteIsOpen}
                    idFornitore={newTestata.idFornitore}
                    handleCloseFattureNote={handleCloseFattureNote}
                    fornitore={descrizioneFornitore}
                />

                <DialogProdottiSegnalazione
                    dialogProdottiSegnalazioneIsOpen={dialogProdottiSegnalazioneIsOpen}
                    idFornitore={newTestata.idFornitore}
                    handleCloseProdottiSegnalazione={handleCloseProdottiSegnalazione}
                    fornitore={descrizioneFornitore}
                />
            
                </>
            }
            </>
    )
}

export default SingoloOrdineLibero