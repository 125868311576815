
import React, { useEffect } from 'react'



const InfoAppClienti = (props: {  toggleMenu(stato: boolean): any, statoMenu: boolean }) => {
   

    useEffect(() => { 
        if (false) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

   
    return (
        <>
         
             <div className='form-row '> 
                <div className="form-group col-md-6">
                 
                <div style={{  marginLeft:10 , marginTop:20, color:'#337ab7' }}>
                <h3 className='font-size:6vw'>App Clienti</h3>
                </div>

                <div style={{ padding:10, marginRight:30 , marginTop:20 , borderRadius:10 , backgroundColor:'#fff', color:'#337ab7'  }}  >
                  <h5 className='font-size:2vw '>
                  La piattaforma <strong>CloudFarma</strong> offre un'app per smartphone rivolta ai clienti delle farmacie chiamata <strong>Pocketfarma</strong>.
                     </h5>
                     <h5 className='font-size:2vw '>
                     Essa facilita la comunicazione con i propri clienti e consente di offrire servizi come la prenotazione dei prodotti, l'aderenza alla terapia, ecc.
                     </h5>
                    
                     </div> 
                     
                </div>   
             <div className="form-group col-md-6" >
                 <div style={{ marginRight:10 , marginTop:20 , borderRadius:20  }}  >
                 <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_appclienti_arg.png" alt="app" />

                     </div>
                     <div style={{ marginRight:10 , marginTop:20, borderRadius:20   }}  >
                     <img className='img-fluid shadow-6 img-thumbnail' src="/app/cf_appclienti.png" alt="app" />
                 </div>
            </div> 
            </div> 
           
        </>
    )
}

export default InfoAppClienti