import { AplicaValoreTO } from "../../../../funzioni/operazioniSuTabelle";

export const columnsMercato = [
    {
      caption: "Farmacia",
      dataField: "FARMACIA",
      width: "180px",
      fixed: true
    },    
      {
        caption: "% SSN",
        dataField: "MARGINE",
        width: "auto",
        format: { type: "currency", precision: 2, currency: "EUR" },
      },
      
  ];