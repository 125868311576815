import React, { useEffect, useRef, useState, useCallback } from "react";
import notify from "devextreme/ui/notify";
import { faCaretUp, faInfo } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "devextreme-react/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../hooks/useAuth";
import { ListaFarmacieInterface } from "../../../interfaces/farmacie";
import { RestServices } from "../../../services/restServices";
import FiltriVerticale, { ConvertForSelect } from "../filters/FiltriVerticaleNewVersione";
import { Filtri } from "../../consultazione/interface";
import { cardC, CardCart, typeShow } from "../components/card_chart";
import { transformFornitore, transformDitta, trasform_for_cards_charts_01} from "../util/trasformation";
import { MenuFbiAcquisti } from "../components/menu_Fbi_Acquisti"
import { LoadIndicator } from "devextreme-react/load-indicator";
import { urlConsultazione, urlReport } from "../../costanti";
import { Summary, TotalItem } from "devextreme-react/data-grid";
import { Loading } from "../../../widget/Notifications";
import { exportDataGrid } from "devextreme/excel_exporter";
import { AplicaValoreTO } from "../../../funzioni/operazioniSuTabelle";
import { Workbook } from "exceljs";
import saveAs from "file-saver";
import { NewTabella } from "../../../widget/nuova_tabella/newBusineVersion";
import { Chart } from 'devextreme-react';
import { CommonSeriesSettings, Export, HoverStyle, Label, Legend, Series, Title, Tooltip } from 'devextreme-react/chart';
import PieChart, { Font, Format } from 'devextreme-react/pie-chart';
import ".././style.css";

const boxCard: cardC[] = [
    {
        testo: "Costo d'acquisto ",
        subTesto: "Delta anno precedente",
        valore: "COSTO_ACQUISTOSL",
        subValore: "COSTO_ACQUISTO_PUNTUALEPR",
        typeShowValue: typeShow.euro,
        typeShowSubValue: typeShow.percento,
        testoPop:
            ""
    },
    {
        testo: "Volume d'acquisto",
        subTesto: "Delta anno precedente",
        valore: "PEZZI_ACQUISTATISL",
        subValore: "PEZZI_ACQUISTATI_PUNTUALEPR",
        typeShowSubValue: typeShow.percento,
        testoPop:
            "",
    },
];


const FbiAnaAcquiFornitore = (props: {
    datiFarmacie: ListaFarmacieInterface[];
    toggleMenu(stato: boolean): any;
    statoMenu: boolean;
}) => {
    const [valoriTabella1, setValoriTabella1] = useState<any>([]);
    const [valoriTabella2, setValoriTabella2] = useState<any>([]);
    const [chartData, setChartData] = useState<any>([]);
    const [totalValue, setTotalValue] = useState(0);
    const [newFarmaList, setNewFarmaList] = useState<any[]>([]);
    const [tipologiaConFascia, setTipologiaConFascia] = useState<any[]>([]);
    const [tipologiaProdotto, setTipologiaProdotto] = useState<any[]>([]);
    const [progetto, setProgetto] = useState<any[]>([]);
    const [societa, setSocieta] = useState<any[]>([]);
    const [categoria, setCategoria] = useState<any[]>([]);
    const [segmento, setSegmento] = useState<any[]>([]);
    const [blocco1, setBlocco1] = useState<any[]>([]);
    const [blocco2, setBlocco2] = useState<any[]>([]);
    const [paramsFilter, setParamsFilter] = useState<Filtri>({});
    const [isVisible, setIsVisible] = useState(false);
    const [loadinCambio, setLoadinCamnbio] = useState(false);
    const [fila1cards, setFila1cards] = useState<cardC[]>(boxCard);
    const [chartDataATFPie, setChartDataATFPie] = useState<any>([]);
    const chartRef = useRef<any>(null);
    const [isFiltersExpanded, setIsFiltersExpanded] = useState(true);
    const [visiblePop, setVisiblePop] = useState(false);
    const [visiblePop2, setVisiblePop2] = useState(false);
    const [visiblePop3, setVisiblePop3] = useState(false);
    const [visiblePop5, setVisiblePop5] = useState(false);
    const [testoPop, setTestoPop] = useState<string>("");
    const [openPop, setOpenPop] = useState<boolean>(false);
    const [objtipo, setObjtipo] = useState<any>(null);

    const loadPanel = <Loading isVisible={isVisible} />;
    const auth = useAuth(urlConsultazione.auth);
    const authReport = useAuth(urlReport.auth);

    const dashboard41 = { dashboardID: 41 };


    function aggiornaDataFine(dataFine: Date): Date {
        const nuovaDataFine = new Date(dataFine);

        nuovaDataFine.setDate(nuovaDataFine.getDate() + 1);

        return nuovaDataFine;
    }

    const filterAction = (objFiltro: Filtri) => {
        let params = { ...objFiltro };

        setParamsFilter(params);

        let newIDFAR: any = [];

        if (objFiltro.idFar) {
            objFiltro.idFar.forEach((element: any) => {
                newIDFAR.push(element);
            });
            params.idFar = newIDFAR;
        };



        if (params.periodo.al) {
            params.periodo.al = params.periodo.al  //aggiornaDataFine(params.periodo.al);
        }

        if (params.societa && params.societa.length > 0) {
            console.log(societa);
            let objSocieta: any = []

            params.societa.forEach((element: any) => {
                const oggettoTrovato = societa.find(obj => obj.id === element);
                objSocieta.push({
                    id: oggettoTrovato.id,
                    codice: oggettoTrovato.codice,
                })
            });
            params.societaNewV = objSocieta;
        }


        if (params.progetto && params.progetto.length > 0) {
            let objSocieta: any = []

            params.progetto.forEach((element: any) => {
                const oggettoTrovato = progetto.find(obj => obj.id === element);
                objSocieta.push({
                    id: oggettoTrovato.id,
                    codice: oggettoTrovato.codice,
                })
            });
            params.progettoNewV = objSocieta;
        }

        const parameters = {
            tipologiaProdotto: params.tipologiaProdotto,
            tipologiaConFascia: params.tipologiaConFascia,
            progetto: params.progettoNewV,
            societa: params.societaNewV,
            categoria: params.categoria,
            segmento: params.segmento,
            blocco1: params.blocco1,
            blocco2: params.blocco2,
            ditta: params.ditta,
            operatori: params.operatori,
            tipologiaSconto: params.tipologiaSconto,
            //  tipologiaSconto: ["SCONTO_TESTATA", "SCONTO_MISTO"] ,
            dal: params.periodo.dal.toLocaleString().replace(",", ""),
            al: params.periodo.al.toLocaleString().replace(",", ""),
        };

        if (
            params.idFar.length === 0 ||
            params.idFar === undefined ||
            params.periodo.dal === null ||
            params.periodo.al === null
        ) {
            notify(
                {
                    position: "center",
                    width: "auto",
                    message: "Filtro per farmacia e periodo obbligatori.",
                },
                "error",
                4000
            );
            return;
        }

        setIsVisible(true);
        if (authReport) {
            const dashboardCall41 = {
                ...dashboard41,
                idFarList: params.idFar,
                params: parameters,
            };

            //new call

            RestServices()
                .rest("POST", dashboardCall41, urlReport.getQueryDashboardAllData)
                .then((result) => {
                    setIsVisible(false);
                    if (result) {

                        let datiPrimaParteCards = trasform_for_cards_charts_01(
                            result["134"],
                            boxCard
                        );

                        let arrayTousePie = [
                            { label: 'Costo Acquisto Tras', value: result["116"][0]?.COSTO_ACQUISTO_TRAS },
                            { label: 'Costo Acquisto Depo', value: result["116"][0]?.COSTO_ACQUISTO_DEPO },
                            { label: 'Costo Acquisto Ford', value: result["116"][0]?.COSTO_ACQUISTO_FORD },
                        ];

                        setChartDataATFPie(arrayTousePie);


                        setFila1cards(datiPrimaParteCards);

                        const anno = params.periodo.dal.substring(0, 4)

                        let datiPrimaTabella = transformFornitore (result["118"], anno);
                        setValoriTabella1(datiPrimaTabella);

                        let datiSecondaTabella = transformDitta(result["119"], anno);
                        console.log(datiPrimaTabella);
                        setValoriTabella2(datiSecondaTabella);

                        setChartData(result["135"]);
                      

                    } else {
                        notify(
                            { position: "center", width: "auto", message: result.message },
                            "error",
                            4000
                        );
                    }
                })
                .catch((error) => {
                    setIsVisible(false);
                    notify(
                        {
                            position: "center",
                            width: "auto",
                            message: "Errore" + error,
                        },
                        "error",
                        4000
                    );
                });

            // setNewDataSecondPie(newData);
            setParamsFilter(params);
            setIsFiltersExpanded(false);
        }
    };

    const farmaList = () => {
        if (auth) {
            RestServices()
                .rest("POST", {}, urlConsultazione.getListFar)
                .then((result) => {
                    if (result?.esito) {
                        setNewFarmaList(result.response);
                    } else {
                        // handle error
                    }
                });
        }
    };

    const columnsAnaFornDittaCate = [
        {
            caption: "Fornitore",
            dataField: "ANA_RAGIONE_SOCIALECR",
            fixed: true,
            sortOrder: "asc"
        },
        {
            caption: "Costo d'acquisto periodo selezionato",
            dataField: "COSTO_ACQUISTOCR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Incidenza percentuale costo d'acquisto",
            dataField: "INCIDENZA_COSTO_ACQUISTOCR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Costo d'acquisto anno precedente",
            dataField: "COSTO_ACQUISTOAP",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Delta costo d'acquisto",
            dataField: "COSTO_ACQUISTO_PUNTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Volume d'acquisto periodo selezionato",
            dataField: "VOLUME_ACQUISTOCR",
            width: "auto",
        },
        {
            caption: "Incidenza percentuale volume d'acquisto",
            dataField: "INCIDENZA_VOLUME_ACQUISTOCR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),

        },
        {
            caption: "Volume d'acquisto anno precedente",
            dataField: "VOLUME_ACQUISTOAP",
            width: "auto",
        },
        {
            caption: "Delta volume d'acquisto",
            dataField: "VOLUME_ACQUISTO_PUNTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
    ];

    const columnsAnaFornDittaCate2 = [
        {
            caption: "Ditta",
            dataField: "PRO_DESC_DITTA_TITOLARECR",
            width: "auto",
            sortOrder: "asc",
            fixed: true
        },
        {
            caption: "Fornitore",
            dataField: "ANA_RAGIONE_SOCIALECR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Costo d'acquisto periodo selezionato",
            dataField: "COSTO_ACQUISTOCR",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Incidenza percentuale costo d'acquisto relativa Ditta",
            dataField: "INCIDENZA_COSTO_ACQUISTO_DITTACR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Incidenza percentuale costo d'acquisto totale",
            dataField: "INCIDENZA_COSTO_ACQUISTOCR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Costo d'acquisto anno precedente",
            dataField: "COSTO_ACQUISTOAP",
            width: "auto",
            format: { type: "currency", precision: 2, currency: "EUR" },
        },
        {
            caption: "Delta costo d'acquisto",
            dataField: "COSTO_ACQUISTO_PUNTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },
        {
            caption: "Volume d'acquisto periodo selezionato",
            dataField: "VOLUME_ACQUISTOCR",
            width: "auto",

        },
        {
            caption: "Volume d'acquisto anno precedente",
            dataField: "VOLUME_ACQUISTOAP",
            width: "auto",

        },
        {
            caption: "Delta volume d'acquisto",
            dataField: "VOLUME_ACQUISTO_PUNTUALECR",
            width: "auto",
            customizeText: (cellInfo: any) =>
                AplicaValoreTO(cellInfo, "%", "percentuale", "d"),
        },


    ];

    useEffect(() => {
        if (props.statoMenu) {
            props.toggleMenu(!props.statoMenu);
        }
    }, []);

    useEffect(() => {
        farmaList();
    }, [auth]);

    const filters = () => {
        if (auth) {

            RestServices()
                .rest("POST", "", urlReport.getTipologiaConFascia)
                .then((result) => {
                    if (result) {
                        const fascia = result.map((oggetto: { descrizione: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.descrizione // Aggiungi la nuova propriet� id
                            };
                        });


                        setTipologiaConFascia(fascia)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getTipologiaProdotto)
                .then((result) => {
                    if (result) {
                        const prodotti = result.map((oggetto: { descrizione: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.descrizione // Aggiungi la nuova propriet� id
                            };
                        });

                        setTipologiaProdotto(prodotti)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getProgetto)
                .then((result) => {
                    console.log('vediamo sytruttura');
                    console.log(result);

                    if (result) {
                        const progetti = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.codice // Aggiungi la nuova propriet� id
                            };
                        });

                        setProgetto(result);
                    }


                });

            RestServices()
                .rest("POST", "", urlReport.getSocieta)
                .then((result) => {
                    if (result) {
                        const societas = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.codice // Aggiungi la nuova propriet� id
                            };
                        });
                        setSocieta(result)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getCategoria)
                .then((result) => {

                    if (result) {
                        const categorie = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.codice // Aggiungi la nuova propriet� id
                            };
                        });
                        setCategoria(categorie)

                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getSegmento)
                .then((result) => {
                    if (result) {
                        const segmentos = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.codice // Aggiungi la nuova propriet� id
                            };
                        });
                        setSegmento(segmentos)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getBlocco1)
                .then((result) => {
                    if (result) {
                        const blocco1 = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.codice // Aggiungi la nuova propriet� id
                            };
                        });
                        setBlocco1(blocco1)
                    }
                });

            RestServices()
                .rest("POST", "", urlReport.getBlocco2)
                .then((result) => {
                    if (result) {
                        const blocco2 = result.map((oggetto: { codice: any; }) => {
                            return {
                                ...oggetto, // Copia tutte le propriet� esistenti
                                id: oggetto.codice // Aggiungi la nuova propriet� id
                            };
                        });
                        setBlocco2(blocco2)
                    }
                });
        }

    };

    const itemsFields = [

        { type: "dateRange", idValue: "periodo", width: "auto", require: true },
        {
            type: "newSelectorFarma",
            idValue: "idFar",
            label: "Farmacie ",
            displayExpr: "text",
            dataSource: ConvertForSelect(newFarmaList),
            idValueGrid: "idFar",
            require: true,
        },
        {
            type: "selectWithLevels",
            noId: true,
            id: "tipologiaConFascia",
            label: "Tipologia con Fascia",
            displayExpr: "tipologiaConFascia",
            dataSource: tipologiaConFascia,
            idValueGrid: "tipologiaConFascia" as any,
        },
        {
            type: "selectWithLevels",
            noId: true,
            id: "tipologiaProdotto",
            label: "Tipologia prodotto",
            displayExpr: "tipologiaProdotto",
            dataSource: tipologiaProdotto,
            idValueGrid: "tipologiaProdotto" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "progetto",
            label: "Progetto",
            displayExpr: "descrizione",
            dataSource: progetto,
            idValueGrid: "progetto" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "societa",
            label: "Societa",
            displayExpr: "descrizione",
            dataSource: societa,
            idValueGrid: "societa" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "categoria",
            label: "Categoria",
            displayExpr: "descrizione",
            dataSource: categoria,
            idValueGrid: "categoria" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "segmento",
            label: "Segmento",
            displayExpr: "descrizione",
            dataSource: segmento,
            idValueGrid: "segmento" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "blocco1",
            label: "Blocco1",
            displayExpr: "descrizione",
            dataSource: blocco1,
            idValueGrid: "blocco1" as any,
        },
        {
            type: "selectMultiplaMoreColon",
            id: "blocco2",
            label: "Blocco2",
            displayExpr: "descrizione",
            dataSource: blocco2,
            idValueGrid: "blocco2" as any,
        },
    ];


    useEffect(() => {
        filters();
    }, [authReport]);

    const exportFormats = ["excel"];

    const onExporting = (e: any) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("tabella_FbiFornitore");
        exportDataGrid({
            component: e.component,
            worksheet: worksheet,
        }).then(function () {
            workbook.xlsx.writeBuffer().then(function (buffer) {
                saveAs(
                    new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }),
                    "Reporttabella_FbiFornitore.xlsx"
                );
            });
        });
    };




    /*sitema grafici */

    const startLoading = () => {
        setLoadinCamnbio(true);
        setTimeout(() => {
            setLoadinCamnbio(false);
        }, 500); // Imposta il ritardo a 1 secondo (1000 millisecondi)
    };

    useEffect(() => {
        startLoading();
    }, [isFiltersExpanded]);

    const ricarica = () => {
        setOpenPop(false);
        setObjtipo(null);
        //caricaDati()
    };

    const popVisible = (testo: string) => {
        setVisiblePop(true);
    };
    const popVisible2 = (testo: string) => {
        setVisiblePop2(true);
    };
    const popVisible3 = (testo: string) => {
        setVisiblePop3(true);
    };

    const popVisible5 = (testo: string) => {
        setVisiblePop5(true);
    };


    let messOne = [""];
    let messTwo = [""];
    let messThree = [""];
    let mess5 = [""];

    return (
        <>
            <section style={{ display: "flex", height: "98vh" }}>
                {loadPanel}
                {loadinCambio ? (
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <LoadIndicator id="small-indicator" height={50} width={50} />
                    </div>
                ) : (
                    <section
                        id="scrollerelement"
                        style={{ width: "100%", overflow: "scroll" }}
                    >
                        <div
                            style={{
                                fontSize: "15px",
                                alignItems: "left",
                                marginTop: "10px",
                                marginBottom: "10px",
                                color: "rgb(80, 84, 92)",
                            }}
                        >
                                <h2>Analisi Acquisti per Fornitore</h2>
                        </div>
                        {/* select*/}

                        <div
                            style={{
                                display: "block",
                                gap: 5,
                                padding: 10,
                                borderRadius: 10,
                                marginTop: 10,
                                width: "auto",
                            }}
                        >
                            <div>
                                <MenuFbiAcquisti />
                            </div>
                        </div>


                        <Popup
                            visible={visiblePop}
                            showCloseButton={true}
                            onHiding={() => setVisiblePop(false)}
                            width={"40%"}
                            height={"40%"}
                            showTitle={true}
                            title="Dettagli widget"
                        >
                            <div>
                                <p>{testoPop}</p>
                            </div>
                        </Popup>


                        <div style={{ marginBottom: 20 }} className="grid-2-acqui-cate">
                            {fila1cards.map((el, i) => {

                                return (
                                    <CardCart
                                        testo={el.testo}
                                        subTesto={el.subTesto}
                                        key={i}
                                        valore={el.valore}
                                        subValore={el.subValore}
                                        starter={el.starter}
                                        typeShowValue={el.typeShowValue}
                                        typeShowSubValue={el.typeShowSubValue}
                                        testoPop={el.testoPop}
                                    />
                                );
                            })}
                        </div>

                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "white",
                                    marginTop: 5,
                                    paddingBottom: 10
                                }}
                            >
                                <div style={{ width: "55%", marginLeft: 20 }}>
                                   <PieChart
                                        id="pie"
                                        dataSource={chartDataATFPie}
                                        palette="Green Mist"
                                        paletteExtensionMode="blend"
                                        type="doughnut"

                                    >
                                        <Series
                                            argumentField="label"
                                            valueField="value"
                                        >
                                            <HoverStyle color="rgb(255, 0, 0)" />
                                        </Series>

                                        {<Tooltip
                                            enabled={true}
                                            customizeTooltip={({ argument, value, percentText }: { argument: any, value: any, percentText: any }) => {


                                                const formattedValue = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);

                                                return {
                                                    html: `<p>${argument}</p><p>${formattedValue} / ${(percentText)}</p>`,
                                                };
                                            }}
                                        />}
                                        <Title
                                            text="Analisi per tipologia di fornitore"
                                        >
                                            <Font color="#5b6372" size="16" weight="400" />
                                        </Title>

                                        <Export fileName="Analisi per tipologia di fornitore" enabled={true} />
                                    </PieChart>
                                </div>

                                <div style={{ display: "flex", marginTop: "-380px", width: "3%" }}>
                                    <button
                                        className="ibutton-lite-two"
                                        style={{ border: "none" }}
                                        onClick={() => ("")}
                                    >
                                        <FontAwesomeIcon
                                            icon={faInfo}
                                            style={{ fontSize: "11px", color: "black" }}
                                        />
                                    </button>
                                </div>
                            </div>
                        
                            <div style={{ marginTop: '5px', marginBottom: 20 }}>

                                <h6 style={{
                                    color: 'black',
                                    fontFamily: 'Arial, sans-serif',
                                    fontWeight: 'bold',
                                    marginBottom: '-3px',
                                    marginLeft: '5px',
                                    marginTop: 20

                                }}>Analisi acquisti per fornitore</h6>

                                <NewTabella
                                    idTabella='FbiAnalisiAcquiFornitore'
                                    sync
                                    colonne={columnsAnaFornDittaCate}
                                    dataSource={valoriTabella1}
                                    filterRow
                                    width={10}
                                    searchPanel={true}
                                    exportT={{
                                        enabled: true,
                                        fileName: "AnalAcquiFornitore",
                                        formats: { exportFormats }
                                    }}
                                    activeSaver={true}
                                    columnC={true}
                                    rowAlternation={true}
                                    headerFilter
                                    wordWrapEnabled={true}
                                    onExporting={onExporting}
                                    summary={
                                        <Summary>
                                            <TotalItem column="COSTO_ACQUISTOCR" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                                            <TotalItem column="COSTO_ACQUISTOAP" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                                            <TotalItem column="VOLUME_ACQUISTOCR" summaryType="sum" />

                                            <TotalItem column="VOLUME_ACQUISTOAP" summaryType="sum" />

                                        </Summary>
                                    }
                                />



                            </div>

                            <div style={{ marginTop: '5px' }}>

                                <h6 style={{ color: 'black', fontFamily: 'Arial, sans-serif', fontWeight: 'bold', marginBottom: '-3px', marginLeft: '5px' }}>Analisi acquisti per ditta titolare</h6>

                                <NewTabella
                                    idTabella='FbiAnalisiAcquiFornitore'
                                    sync
                                    colonne={columnsAnaFornDittaCate2}
                                    dataSource={valoriTabella2}
                                    filterRow
                                    width={10}
                                    searchPanel={true}
                                    exportT={{
                                        enabled: true,
                                        fileName: "AnalisiAcquiFornitore",
                                        formats: { exportFormats }
                                    }}
                                    activeSaver={true}
                                    columnC={true}
                                    rowAlternation={true}
                                    headerFilter
                                    wordWrapEnabled={true}
                                    onExporting={onExporting}
                                    summary={
                                        <Summary>
                                            <TotalItem column="COSTO_ACQUISTOCR" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                                            <TotalItem column="COSTO_ACQUISTOAP" summaryType="sum" valueFormat={{ style: 'currency', currency: 'EUR' }} />

                                            <TotalItem column="VOLUME_ACQUISTOCR" summaryType="sum" />

                                            <TotalItem column="VOLUME_ACQUISTOAP" summaryType="sum" />

                                        </Summary>
                                    }
                                />
                            </div>

                            <div
                                style={{
                                    display: "block",
                                    gap: 5,
                                    padding: 10,
                                    borderRadius: 10,
                                    marginTop: 20,
                                    width: "auto",
                                }}
                            >


                                <div>
                                    <div style={{ display: "flex", backgroundColor: 'white', marginLeft: -10, marginRight: -10 }}>
                                        <div style={{ width: "80%", margin: "10px" }}>
                                            <Chart dataSource={chartData.slice(0, 20)} ref={chartRef}>
                                                <CommonSeriesSettings
                                                    argumentField="ANA_RAGIONE_SOCIALE"
                                                    type="bar"
                                                    hoverMode="allArgumentPoints"
                                                    selectionMode="allArgumentPoints"
                                                >
                                                    <Label visible={true}>
                                                        <Format precision={0} />
                                                    </Label>
                                                </CommonSeriesSettings>

                                                <Series
                                                    valueField="COSTO_ACQUISTO"
                                                    type="bar"
                                                    name="2023/2024"
                                                    color="rgb(25, 231, 255)"

                                                />

                                                <Tooltip
                                                    enabled={true}
                                                    customizeTooltip={({
                                                        value,
                                                        argument,
                                                    }: {
                                                        value: any;
                                                        argument: any;
                                                    }) => {
                                                        const formattedValue = parseFloat(value).toLocaleString(
                                                            "it-IT",
                                                            {
                                                                minimumFractionDigits: 0,
                                                            }
                                                        );

                                                        return {
                                                            html: `<p>Fornitore: ${argument}<p><p>Costo d'acquisto: ${formattedValue}</p>`,
                                                        };
                                                    }}
                                                />
                                                <Title text="Analisi Category - Fornitore">
                                                    <Font color="#5b6372" size="20" weight="400" />
                                                </Title>
                                                <Legend
                                                    verticalAlignment="bottom"
                                                    horizontalAlignment="center"
                                                ></Legend>
                                                <Export fileName="Analisi Category - Fornitore" enabled={true} />
                                            </Chart>
                                        </div>

                                        <div style={{}}>
                                            <p
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    alignItems: "left",
                                                    justifyContent: "left",
                                                }}
                                            >
                                                <span>
                                                    {" "}
                                                    <button
                                                        className="ibutton-lite"
                                                        style={{ border: "none" }}
                                                        onClick={() => ("")}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={faInfo}
                                                            style={{ fontSize: "11px", color: "black" }}
                                                        />
                                                    </button>
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                               </div>
                            </div>
                    </section>
                )}

                <div style={{ width: "auto", display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faCaretUp}
                        rotation={270}
                        onClick={() => setIsFiltersExpanded(!isFiltersExpanded)}
                        style={{ fontSize: "30px", color: "black" }}
                    />
                </div>
                <section style={{ background: '#fff', opacity: isFiltersExpanded ? 1 : 0, width: isFiltersExpanded ? 'auto' : 10 }}>
                    <div style={{}}>
                        <FiltriVerticale
                            ricerca={filterAction}
                            items={itemsFields}
                            paramsFilter={paramsFilter}
                        />
                    </div>
                </section>
            </section>
            <Popup
                visible={visiblePop}
                showCloseButton={true}
                onHiding={() => setVisiblePop(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messOne}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop2}
                showCloseButton={true}
                onHiding={() => setVisiblePop2(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messTwo}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop3}
                showCloseButton={true}
                onHiding={() => setVisiblePop3(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{messThree}</p>
                </div>
            </Popup>
            <Popup
                visible={visiblePop5}
                showCloseButton={true}
                onHiding={() => setVisiblePop5(false)}
                width={"40%"}
                height={"40%"}
                showTitle={true}
                title="Dettagli widget"
            >
                <div>
                    <p>{mess5}</p>
                </div>
            </Popup>
        </>
    );
};


export default FbiAnaAcquiFornitore;
