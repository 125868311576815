import React, { useState, useEffect } from "react"
import notify from "devextreme/ui/notify"
import TextBox from "devextreme-react/text-box"
import Autocomplete from "devextreme-react/autocomplete"
import SelectBox from "devextreme-react/select-box"
import CheckBox from "devextreme-react/check-box"
import { Loading } from '../../widget/Notifications'
import Button from 'react-bootstrap/Button'
import Select from 'react-select';
import DateBox from 'devextreme-react/date-box'
import NumberBox from 'devextreme-react/number-box'
import { AutocompleteBox } from "../../widget/Autocomplete"
import { convertForSelectAtc, convertForSelectGruppo, convertForSelectIva, convertForSelectLinea, convertForSelectSettore, getLabelSettore, legendByarray } from "../../funzioni/formatoVariabili"
import NewTabella from "../../widget/nuova_tabella"
import TabPanel, { Item } from "devextreme-react/tab-panel"
import './style.css';
import { RestServices } from "../../services/restServices"
import { urlBancaDati, urlConsultazione } from "../costanti"
import { useAuth } from "../../hooks/useAuth"
import { DittaInterface, GruppiInterface, OggettoInterface, SettoriInterface } from "../../interfaces/generiche"
import { SelezioneMultipla } from "../../widget/Select"
import { CreaColonne } from "../../funzioni/operazioniSuTabelle"
import { confirm } from 'devextreme/ui/dialog';
interface PageProps {
    typeEdit: boolean,
    edit: any;
    ricarica: () => void;
    close: () => void;
    setIndietro: () => void,
    typeInsert: boolean,
    viewMode: boolean
}

export const GestProdGruppoDett: React.FC<PageProps> = ({
    close,
    edit,
    setIndietro,
    typeEdit,
    typeInsert,
    viewMode

}) => {


    const [objctTOsave, setObjctTOsave] = useState<any>({})
    const [objctTOedit, setObjctTOedit] = useState<any>({})
    const auth = useAuth(urlConsultazione.auth)
    const [disableButton, setDisableButton] = useState<boolean>(true)
    const [tabellaPrincipale, setTabellaPrincipale] = useState<any>([])
    const [settori, setSettori] = useState<SettoriInterface[]>();
    const [gruppi, setGruppi] = useState<GruppiInterface[]>();
    const [ditte, setDitte] = useState<DittaInterface[]>();
    const [linee, setLinee] = useState<DittaInterface[]>();
    const [atc, setAtc] = useState<GruppiInterface[]>();
    const [segnalazione, setSegnalazione] = useState<GruppiInterface[]>();
    const [reparto, setReparto] = useState<GruppiInterface[]>();
    //const [linea, setLinea] = useState<any[]>()
    const [idDitta, setIdDitta] = useState<any>();
    const [iva, setIva] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false)
    const [defaultSettore, setDefaultSettore] =  useState<any>()
    const [filtri, setFiltri] = useState<any>();
    const [loadTabella, setLoadTabella] = useState<boolean>();
    const [flagProdottoBancaDati, setFlagProdottoBancaDati] = useState<boolean>(false);

    const columns = [
        { caption: "Farmacia", dataField: "descrix", width: 200 },
        { caption: "Cod. Prodotto", dataField: "codiceProdotto" },
        { caption: "Ean", dataField: "ean" },
        { caption: "Descrizione", dataField: "descrizioneRidotta" },
    ];


    useEffect(() => {

        getDettaglioProdotto(edit)

        //caricaTabella()

        setLoading(true)

        if (!settori || settori == undefined)
            RestServices().rest('POST', "", urlBancaDati.getSettore).then(result => {
                if (result) setSettori(result);
            })

        if (!gruppi || gruppi == undefined)
            RestServices().rest('POST', "", urlBancaDati.getGruppoMerceologico).then(result => {
                if (result) setGruppi(result);
            })

        if (!iva || iva == undefined)
            RestServices().rest('POST', "", urlBancaDati.getIva).then(result => {
                if (result) setIva(result);
            })

        if (!atc || atc == undefined) {
            setLoading(true)
            RestServices().rest('POST', "", urlBancaDati.getAtc).then(result => {
                if (result) {
                    setAtc(result);
                    setLoading(false)
                }
            })
        }
            

        if (!segnalazione || segnalazione == undefined)
            RestServices().rest('POST', "", urlBancaDati.getSegnalazione).then(result => {
            if (result) {
                setSegnalazione(result);
            }
        })

        if (!reparto || reparto == undefined) {
            let rep: any[];
            rep = [];
            rep.push({ id: "1", codice: "1", descrizione: "1" })
            rep.push({ id: "2", codice: "2", descrizione: "2" })
            rep.push({ id: "3", codice: "3", descrizione: "3" })
            rep.push({ id: "4", codice: "4", descrizione: "4" })
            rep.push({ id: "5", codice: "5", descrizione: "5" })
            rep.push({ id: "6", codice: "6", descrizione: "6" })
            rep.push({ id: "7", codice: "7", descrizione: "7" })
            rep.push({ id: "8", codice: "8", descrizione: "8" })
            rep.push({ id: "9", codice: "9", descrizione: "9" })
            rep.push({ id: "10", codice: "10", descrizione: "10" })

            setReparto(rep)
        }

        if (typeInsert && typeInsert === true) {
            caricaTabella()
        }

    }, [auth])


    const getDettaglioProdotto = async (prodotto: any) => {

        if (prodotto) {
            let params = { idProdottoGruppo: prodotto.id };
            if (auth) {
                RestServices().rest('POST', params, urlConsultazione.getProdottoGruppo)
                    .then(result => {
                        if (result?.esito) {
                            setObjctTOsave(result.response)
                            setObjctTOedit(result.response)
                            setFlagProdottoBancaDati(result.response.flagProdottoBancaDati)
                            setLoadTabella(true)
                            //console.log(result.response)
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                    })

            }

        }
    }

    useEffect(() => {

        if (loadTabella === true) {
            caricaTabella()
        }

    }, [loadTabella])

    const cambiaValore = (tipo: string, value: string, tipoControllo?: string, tipoDato?: string) => {
        let objTo = { ...objctTOsave }

        //tipoDato S: string ; N: number

        if (tipoControllo === 'Select') {
            //value � un array
            let myArray = value as unknown as Array<any>;
            let ids: any[] = [];
            myArray.forEach((element: any) => {
                let val;
                if (tipoDato && tipoDato === 'N')
                    val = Number(element.value)
                else
                    val = element.value
                ids.push(val)

            });
            objTo[tipo] = ids;

        } else if (tipoControllo === 'SelectSingle') {
            let v: any = value as unknown as any
            let val;
            if (tipoDato && tipoDato === 'N')
                val = Number(v.value)
            else
                val = v.value
            objTo[tipo] = val;
        } else if (tipo === 'dataPrezzo') {
            let vDate: Date = value as unknown as Date
            //vDate.setDate(vDate.getDate() + 1)
            objTo[tipo] = vDate
        } else {
            let val;
            if (tipoDato && tipoDato === 'N')
                val = Number(value)
            else
                val = value

            objTo[tipo] = val
        }

        setObjctTOsave(objTo)
        if (objTo.codiceProdotto?.length > 0 && objTo.descrizioneRidotta?.length > 0) {
            setDisableButton(false)
        } else {
            setDisableButton(true)
        }



    }


    useEffect(() => {
        if (objctTOedit) {

            if (objctTOedit.codiceProdotto?.length > 0 && objctTOedit.descrizioneRidotta?.length > 0) {
                    setDisableButton(false)
                } else {
                    setDisableButton(true)
                }

        }

    }, [objctTOedit])


    const creaModificaProdotto = (modifica?: boolean) => {

        

        objctTOsave.descrizioneRidotta = objctTOsave.descrizioneRidotta.toUpperCase()
        objctTOsave.flagProdottoBancaDati = flagProdottoBancaDati

     

        if (auth) {

            if (isNaN(parseInt(objctTOsave.codiceProdotto))) {
                notify({ position: "center", width: "auto", message: 'Codice prodotto non valido' }, "error", 4000)
                return;
            }
            if (modifica == undefined) {
                if (objctTOsave.dataPrezzo != undefined) {
                    let vDate: Date = objctTOsave.dataPrezzo as unknown as Date
                    vDate.setDate(vDate.getDate() + 1)
                    objctTOsave.dataPrezzo = vDate
                }
                
            }
            if (modifica) {
                if (!objctTOsave.prodottiFarmacia || flagProdottoBancaDati == true) {
                    objctTOsave.prodottiFarmacia = []
                }
                //console.log(objctTOsave)
                RestServices().rest('POST', objctTOsave, urlConsultazione.putProdGruppo)
                    .then(result => {
                        if (result.esito) {
                            if (result.response.esito) {
                                notify({ position: "center", width: "auto", message: 'Prodotto aggiornato correttamente' }, "success", 4000)
                                setIndietro()
                            }
                            else
                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                        }
                    })
            } else {

                if (!objctTOsave.prodottiFarmacia || flagProdottoBancaDati == true) {
                    objctTOsave.prodottiFarmacia = []
                }

                RestServices().rest('POST', objctTOsave, urlConsultazione.postProdGruppo)
                    .then(result => {
                        if (result.esito) {
                            if (result.response.esito) {
                                notify({ position: "center", width: "auto", message: 'Prodotto inserito correttamente' }, "success", 4000)
                                setIndietro()
                            }
                            else
                                notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        } else {
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                        }
                    })
            }


        }
        //console.log(objctTOsave)
    }


    const caricaTabella = async () => {
        if (auth) {
            //console.log("ciao")
            //console.log(objctTOsave.prodottiFarmacia)
            setLoading(true)
            let farmacieLista = await RestServices().rest('POST', {}, urlConsultazione.getListFar)
            if (farmacieLista?.esito) {
                if (farmacieLista) {
                    let params = {}
                    if (viewMode === true)
                        params = { prodotti : objctTOsave.prodottiFarmacia }

                    let listaTabella = await RestServices().rest('POST', params, urlConsultazione.getListProd)
                    if (listaTabella?.esito) {
                        let valoreFormatt = legendByarray(
                            listaTabella.response,
                            farmacieLista.response,
                            'idFar',
                            'farmacia',
                            'descrix',
                            ['descrizione'],
                            true,
                            objctTOsave.prodottiFarmacia)

                        let final = valoreFormatt
                        final.sort((a: any, b: any) => (a.priority > b.priority) ? 1 : -1)


                        setTabellaPrincipale(final)

                        //setLoading(false)
                    } else {
                        notify({ position: "center", width: "auto", message: listaTabella.message }, "error", 4000)
                        setLoading(false)
                    }
                }
            } else {
                if (farmacieLista?.message) {
                    notify({ position: "center", width: "auto", message: farmacieLista.message }, "error", 4000)

                }
            }
        }
        else
            console.log("no auth")
    }

    const colonneGridGeneriche = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione", sortOrder: "asc" },
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const colonneGridGenericheReparto = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione",},
        { dataField: "codice", caption: "Codice", width: 120 }
    ])

    const colonneGridGenericheIva = CreaColonne([
        { dataField: "id", caption: "id", visible: false },
        { dataField: "descrizione", caption: "Descrizione" },
        { dataField: "codice", caption: "Codice", width: 120, sortOrder: "asc" }
    ])

    const impostaFiltri = (newFiltro: OggettoInterface) => {
        //let newF = { ...filtri, ...newFiltro }
        //console.log(newFiltro)
        //setFiltri(newF);

        if (newFiltro.idSettoreInventario) {
            cambiaValore('idSettoreInventario', newFiltro.idSettoreInventario[0])
        }

        if (newFiltro.idGruppoMerceologico) {
            cambiaValore('idGruppoMerceologico', newFiltro.idGruppoMerceologico[0])
        }
        if (newFiltro.idIva) {
            cambiaValore('idIva', newFiltro.idIva[0])
        }
        if (newFiltro.idAtc) {
            cambiaValore('idAtc', newFiltro.idAtc[0])
        }

        if (newFiltro.idDittaTitolare) {
            cambiaValore('idDittaTitolare', newFiltro.idDittaTitolare[0])
        }

        if (newFiltro.idDittaLinea) {
            cambiaValore('idDittaLinea', newFiltro.idDittaLinea[0])
        }

        if (newFiltro.idSegnalazione) {
            cambiaValore('idSegnalazione', newFiltro.idSegnalazione[0])
        }

        if (newFiltro.idReparto) {
            cambiaValore('idReparto', newFiltro.idReparto[0])
        }
    }

    useEffect(() => {
        setLinee([])
        let objTmp = { ...objctTOsave }
        objTmp.idDittaLinea = undefined
        if (objTmp.idDittaLinea != objctTOedit.idDittaLinea)
            setObjctTOsave(objTmp)
        if (objctTOsave.idDittaTitolare != undefined)
            RestServices().rest('POST', objctTOsave.idDittaTitolare, urlBancaDati.getLinee).then(result => {
                if (result)
                    setLinee(result);
            })


    }, [objctTOsave.idDittaTitolare])

    const generaCodiceProdotto = () => {
        if (auth) {
            let param = {}
            setLoading(true)
            RestServices().rest('POST', param, urlConsultazione.generaProdGruppo)
                .then(result => {
                    if (result.esito) {

                        if (result.response.codiceProdotto) {
                            setLoading(false)
                            let objTo = { ...objctTOsave }

                            objTo.codiceProdotto = result.response.codiceProdotto

                            setObjctTOsave(objTo)
                            //objctTOsave.codiceProdotto = result.response.codiceProdotto
                        }
                        else {
                            setLoading(false)
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                        }
                            
                    } else {
                        setLoading(false)
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                    }
                })
        }
    }

    const verificaCodiceProdotto = () => {

        if (auth) {

            let param = {codiceProdotto: objctTOsave.codiceProdotto}

            RestServices().rest('POST', param, urlConsultazione.checkProdGruppo)
                .then(result => {
                    if (result.esito) {
                        if (result.response.esito && !result.response.prodotto) {
                            notify({ position: "center", width: "auto", message: 'Codice prodotto valido' }, "success", 4000)
                        }
                        else if (result.response.esito && result.response.prodotto) {

                            let resultConfirm = confirm("<i>Prodotto gia' presente in banca dati, continuare?</i>", "Attenzione");
                            resultConfirm.then((dialogResult) => {
                                if (dialogResult) {
                                    //
                                    let pro :any = {}

                                    pro.codiceProdotto = result.response.prodotto.minsan
                                    pro.ean = result.response.prodotto.ean
                                    pro.descrizioneRidotta = result.response.prodotto.descrizione
                                    pro.idSettoreInventario = result.response.prodotto.codSettoreInventario
                                    pro.idGruppoMerceologico = result.response.prodotto.codCatRicetta
                                    pro.idAtc = result.response.prodotto.codAtc
                                    pro.idDittaLinea = result.response.prodotto.codConcessionario
                                    pro.prezzoPubblico = result.response.prodotto.prezzo
                                    pro.idIva = String(result.response.prodotto.iva)
                                    pro.prezzoListino = result.response.prodotto.prezzoListino
                                    pro.dataPrezzo = result.response.prodotto.dataPrezzo
                                    pro.idDittaTitolare = result.response.prodotto.codDittaTitolare
                                    pro.descrizioneDittaTitolare = result.response.prodotto.codDittaTitolare + " - " + result.response.prodotto.dittaTitolare
                                    pro.idSegnalazione = result.response.prodotto.codSegnalazione

                                    setObjctTOsave(pro)
                                    setFlagProdottoBancaDati(true)
                                } else {
                                    setFlagProdottoBancaDati(false)
                                    return;
                                }
                            });

                        }else
                            notify({ position: "center", width: "auto", message: result.message }, "error", 4000)
                    } else {
                        notify({ position: "center", width: "auto", message: result.message }, "error", 4000)

                    }
                })

        }

    }

    return (
        <>

            <Loading isVisible={loading} />
            <div style={{ gap: 10, display: 'flex', background: 'white', padding: 10, borderRadius: 10, marginTop: 10 }} className="form-group">
                <div>
                    <Button className="btn btn-warning" variant="warning" onClick={() => { setIndietro() }}>INDIETRO </Button>
                </div>
                {!viewMode && <div className="posbtn">
                    <Button className="btn btn-success" variant="success" onClick={() => {
                        if (typeInsert == true) {
                            creaModificaProdotto(false)
                        }

                        else {

                            creaModificaProdotto(true)
                        }
                    }}
                        disabled={disableButton}>SALVA </Button>
                </div>}
            </div>
            &nbsp;

            <TabPanel
                //selectedIndex={selectedIndex}
                loop={false}
                animationEnabled={true}
                //onOptionChanged={onOptionChanged}


            >

                <Item title="Prodotto">
                &nbsp;
                    <div className='form-row'>
                        <div className="form-group col-md-3">
                            <label> CODICE PRODOTO </label>
                            <TextBox disabled={viewMode || edit !== undefined} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.codiceProdotto : ''} onValueChanged={(v) => cambiaValore('codiceProdotto', v.value)} maxLength='9' placeholder="Inserisci max 9 numeri" />
                        </div>
                        <div className="form-group col-md-3">
                            {!viewMode && <Button disabled={edit === undefined ? false : true} title="Genera un nuovo codice prodotto" id="btnGeneraCodiceProdotto" variant="info" onClick={() => { generaCodiceProdotto() }}><i className="fa fa-add"></i></Button>}
                            {!viewMode && <Button  title="Verifica il codice prodotto inserito" id= "btnVerificaCodiceProdotto" variant="info" onClick={() => { verificaCodiceProdotto() }}><i className="fa fa-check"></i></Button>}
                            
                        </div>
                        <div className="form-group col-md-3">
                            <label> EAN </label>
                            <TextBox disabled={viewMode} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.ean : ''} onValueChanged={(v) => cambiaValore('ean', v.value)} maxLength='13' placeholder="Inserisci max 13 numeri" />
                        </div>
                    </div>
                    <div className='form-row'>
                        <div className="form-group col-md-6">
                            <label> DESCRIZIONE </label>
                            <TextBox  disabled={viewMode} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.descrizioneRidotta : ''} onValueChanged={(v) => cambiaValore('descrizioneRidotta', v.value)} maxLength="26" placeholder="..." />
                        </div>
                    </div>

                    <div className='form-row '>
                        <div className="form-group col-md-4">
                            <label> SETTORE INVENTARIO </label>
                            <SelezioneMultipla
                                id="idSettoreInventario"
                                dataSource={settori}
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGeneriche}
                                selection="single"
                                valueGrid={(objctTOsave.idSettoreInventario) ? objctTOsave.idSettoreInventario : []}
                                disabled={viewMode}
                            ></SelezioneMultipla>
                        </div>
                        <div className="form-group col-md-4">
                            <label> GRUPPO MERCEOLOGICO </label>
                            <SelezioneMultipla
                                id="idGruppoMerceologico"
                                dataSource={gruppi}
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGeneriche}
                                selection="single"
                                valueGrid={(objctTOsave.idGruppoMerceologico) ? objctTOsave.idGruppoMerceologico : []}
                                disabled={viewMode}
                            ></SelezioneMultipla>
                        </div>

                        <div className="form-group col-md-4">
                                <label> ATC </label>
                                
                                <SelezioneMultipla
                                    id="idAtc"
                                    dataSource={atc}
                                    impostaFiltri={impostaFiltri}
                                    column={colonneGridGeneriche}
                                    selection="single"
                                    primaSelezionata={false}
                                    valueGrid={(objctTOsave.idAtc) ? objctTOsave.idAtc : []}
                                    disabled={viewMode}
                                ></SelezioneMultipla>
                            </div>
                    </div>
                    <div className='form-row'>
                        <div className="form-group col-md-4">
                            <label> SEGNALAZIONE </label>

                            <SelezioneMultipla
                                id="idSegnalazione"
                                dataSource={segnalazione}
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGeneriche}
                                selection="single"
                                primaSelezionata={false}
                                valueGrid={(objctTOsave.idSegnalazione) ? objctTOsave.idSegnalazione : []}
                                disabled={viewMode}
                            ></SelezioneMultipla>
                        </div>
                        <div className="form-group col-md-4">
                            <label> REPARTO </label>

                            <NumberBox disabled={viewMode}
                                defaultValue={0}
                                valueChangeEvent="keyup"
                                showClearButton={true}
                                value={objctTOsave?.idReparto}
                                onValueChanged={(v) => cambiaValore('idReparto', v.value)}
                                />

                            {/*<SelezioneMultipla*/}
                            {/*    id="idReparto"*/}
                            {/*    dataSource={reparto}*/}
                            {/*    impostaFiltri={impostaFiltri}*/}
                            {/*    column={colonneGridGenericheReparto}*/}
                            {/*    selection="single"*/}
                            {/*    primaSelezionata={false}*/}
                            {/*    valueGrid={(objctTOsave.idReparto) ? objctTOsave.idReparto : []}*/}
                            {/*    disabled={viewMode}*/}
                            {/*></SelezioneMultipla>*/}
                        </div>
                    </div>

                    <div className='form-row'>

                        <div className="form-group col-md-4">
                            <label> DITTA </label>
                            <AutocompleteBox typeApi={"ditta"} idApi={"codice"} objctTOsave={objctTOsave} setObjctTOsave={setObjctTOsave} idExpr={"idDittaTitolare"} descExpr={"descrizioneDittaTitolare"} idPost={"codice"} descPost={"ragioneSociale"} idControl={"dittaBancaDati"} idOpt={"codice"} descOpt={"ragioneSociale"} width={-1} optionalBoolean={true}></AutocompleteBox>
                        </div>

                        <div className="form-group col-md-4">
                            <label> LINEA </label>
                            <SelezioneMultipla
                                id="idDittaLinea"
                                dataSource={linee}
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGeneriche}
                                selection="single"
                                primaSelezionata={false}
                                valueGrid={(objctTOsave.idDittaLinea) ? objctTOsave.idDittaLinea : []}
                                disabled={viewMode}
                            ></SelezioneMultipla>

                        </div>

                        <div className="form-group col-md-4">
                            <label> IVA </label>
                            <SelezioneMultipla
                                id="idIva"
                                dataSource={iva}
                                impostaFiltri={impostaFiltri}
                                column={colonneGridGenericheIva}
                                selection="single"
                                primaSelezionata={false}
                                valueGrid={(objctTOsave.idIva) ? objctTOsave.idIva : []}
                                disabled={viewMode}
                            ></SelezioneMultipla>
                        </div>

                    </div>


                    <div className='form-row'>

                        <div className="form-group col-md-4">
                            <label> PREZZO LISTINO </label>
                            {/*<TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.prezzoListino : ''} onValueChanged={(v) => cambiaValore('prezzoListino', v.value)} maxLength="70" placeholder="..." />
                                    <NumberBox format="� #,##0.##" onValueChanged={(e: any) => cambiaValore(e.value, 'prezzoListino')} value={objctTOsave ? objctTOsave.prezzoListino : ''} valueChangeEvent="keyup" showClearButton={true} />*/}
                            <NumberBox disabled={viewMode} defaultValue={0} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.prezzoListino} onValueChanged={(v) => cambiaValore('prezzoListino', v.value)} format="#,##0.## &euro;" />
                        </div>

                        <div className="form-group col-md-4">
                            <label> PREZZO PUBBLICO </label>
                            {/* <TextBox valueChangeEvent="keyup" showClearButton={true} value={objctTOsave ? objctTOsave.prezzoPubblico : ''} onValueChanged={(v) => cambiaValore('prezzoPubblico', v.value)} maxLength="70" placeholder="..." /> */}
                            <NumberBox disabled={viewMode} defaultValue={0} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave?.prezzoPubblico} onValueChanged={(v) => cambiaValore('prezzoPubblico', v.value)} format="#,##0.## &euro;"/>
                        </div>

                        <div className="form-group col-md-4">
                            <label> DATA PREZZO </label>
                            <DateBox disabled={viewMode} valueChangeEvent="keyup" showClearButton={true} value={objctTOsave && objctTOsave.dataPrezzo ? objctTOsave.dataPrezzo : undefined} onValueChanged={(v) => cambiaValore('dataPrezzo', v.value)} type="date" displayFormat="dd/MM/yyyy" />
                        </div>

                    </div>

                </Item>

                <Item title="Associazioni" disabled={flagProdottoBancaDati}>
                    &nbsp;
                    <div className=''>
                        {/*<div style={{ marginTop: 10, marginBottom: 20, textAlign: 'center' }} >*/}
                        {/*    <label> PRODOTTI </label>*/}
                        {/*</div>*/}
                        <NewTabella
                            idTabella='gest_oper_centr_popup'
                            sync
                            multyp
                            colonne={columns}
                            keyExpr="id"
                            dataSource={tabellaPrincipale}
                            selectedRowKeys={objctTOsave.prodottiFarmacia}
                            filterRow
                            selectedValue={(datiRow: any) => cambiaValore('prodottiFarmacia', datiRow.selectedRowKeys)}
                            exportT={{
                                enabled: false,
                                fileName: ""
                            }}
                        />



                    </div> 

                </Item>

            </TabPanel>

            

            


        </>
    )

}

export default GestProdGruppoDett