import React, { useState } from 'react'
import Button from 'react-bootstrap/esm/Button'
import Modal from 'react-bootstrap/esm/Modal'
import RicercaProdottiBD from './RicercaProdottiBD'
import { RicercaProdInterface } from '../interfaces/bancaDati'
import { OnRowDblClickInterface } from '../interfaces/tabelle'

const DialogAggiungiProdOrdine = (props: {
    testoRicerca: string,
    cercaNuovoProd: RicercaProdInterface[],
    isOpen: boolean,
    handleClose: () => void,
    svuotaDialog: () => void,
    farmSelez: number[] | undefined,
    insertProdotto: (codiceCsf: number) => void,
    insertCodProd?: (codProd: string) => void,
    insertDescProd?: (descrizione: string) => void,
    insertCodEan?: (codEan: string) => void,
}) => {
    const annullaRicerca = () => {
        props.handleClose();
        props.svuotaDialog();
    }
    const [prodottoSelez, setProdottoSelez] = useState<number>();
    const [codProdSelez, setCodProdSelez] = useState<string>();
    const [descProdSelez, setDescProdSelez] = useState<string>();
    const [codEanSelez, setCodEanSelez] = useState<string>();
    const [codCsfSelez, setCodCsfSelez] = useState<string>();
    const getProdottoSelez = (codCsf: number) => setProdottoSelez(codCsf);
    const getCodProdSelez = (minsan: string) => setCodProdSelez(minsan);
    const getDescProdSelez = (descrizione: string) => setDescProdSelez(descrizione);
    const getCodEanSelez = (ean: string) => setCodEanSelez(ean);

    const confermaNuovoProdotto = () => {
        props.handleClose();
        if (prodottoSelez) props.insertProdotto(prodottoSelez);
        if (codProdSelez && props.insertCodProd) props.insertCodProd(codProdSelez);
        if (descProdSelez && props.insertDescProd) props.insertDescProd(descProdSelez);
        if (codEanSelez && props.insertCodEan) props.insertCodEan(codEanSelez);
    }
    const onRowDblClick = (rowDblClicked: OnRowDblClickInterface) => {
        if (rowDblClicked.data) {
            props.insertProdotto(rowDblClicked.data.codCsf)
            if (props.insertCodProd) props.insertCodProd(rowDblClicked.data.minsan)
            if (props.insertDescProd) props.insertDescProd(rowDblClicked.data.descrizione)
            props.handleClose();
        }
    }

    return (
        <>
            <Modal
                show={props.isOpen}
                onHide={annullaRicerca}
                dialogClassName="dialogCercaProdOrdineRda"
                size="lg"
                centered={true}
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="titoloCercaProdOrdineRda">
                        <h3>Ricerca prodotti</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RicercaProdottiBD
                        testoRicerca={props.testoRicerca}
                        cercaNuovoProd={props.cercaNuovoProd}
                        lstFar={props.farmSelez}
                        getProdottoSelez={getProdottoSelez}
                        getCodProdSelez={getCodProdSelez }
                        getDescProdSelez={getDescProdSelez}
                        getCodEanSelez={getCodEanSelez}
                        onRowDblClick={onRowDblClick}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button id="btnAggiungiProdOrdRda" variant="success" onClick={confermaNuovoProdotto} >
                        OK
                    </Button>
                    <Button id="btnChiudiDialogProdOrdRda" variant="danger" onClick={annullaRicerca}>
                        Annulla
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DialogAggiungiProdOrdine